import { Injectable } from '@angular/core';
import { HotspotGroupRelation } from '@app/core/interfaces/hotspot-group-relation.interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HotspotGroup, HotspotGroupWithRelations } from '../interfaces/hotspot-group.interface';
import { HotspotGroupsRepo } from '../shared/hotspot-groups-repo/hotspot-groups-repo.service';

export const GROUP_TRANSLATE_ID = 'main.hotspots.group';

@Injectable({
  providedIn: 'root',
})
export class HotspotGroupsService {
  constructor(
    private repo: HotspotGroupsRepo,
    private translate: TranslateService
  ) {}

  public getGroups(farmId: number): Observable<HotspotGroup[]> {
    return this.repo.get(farmId);
  }

  public createGroups(farmId: number, groups: HotspotGroup[]): Observable<HotspotGroup[] | null> {
    return this.repo.create(farmId, groups);
  }

  public updateGroups(farmId: number, groups: HotspotGroup[]): Observable<HotspotGroup[] | null> {
    return this.repo.update(farmId, groups);
  }

  public deleteGroup(farmId: number, group: HotspotGroup): Observable<number | null> {
    return this.repo.delete(farmId, group);
  }

  public generateUniqeGroupName(currentGroups: HotspotGroup[], initialValue?: string): string {
    let name = initialValue ? initialValue : `${this.translate.instant(GROUP_TRANSLATE_ID)} ${currentGroups.length + 1}`;
    if (currentGroups.map((g) => g.name).includes(name)) {
      while (currentGroups.map((g) => g.name).includes(name)) {
        name += '-1';
      }
    }
    return name;
  }

  public getGroupsUpdateRequest(hotspot: HotspotDto, selectedGroups: HotspotGroupWithRelations[]): HotspotGroupRelation[] | null {
    if (selectedGroups) {
      return selectedGroups.map((g: HotspotGroupWithRelations) => {
        const existingRelation = g.hotspotGroupRelations ? g.hotspotGroupRelations.find((gr) => gr.hotspotId === hotspot.id) : null;
        const toReturn = {
          farmId: hotspot.farmId,
          hotspotId: hotspot.id,
          hotspotGroupId: g.id,
        } as HotspotGroupRelation;
        return existingRelation ? { ...toReturn, id: existingRelation.id } : toReturn;
      });
    }

    return null;
  }
}
