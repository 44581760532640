<div class="title-row">
  <div class="title">
    <h1 class="title-text">
      <ng-content></ng-content>
    </h1>
    <app-harvest-year-picker [showLabel]="false"></app-harvest-year-picker>
  </div>
  <div class="extras">
    <ng-content select="app-harvest-year-extras"></ng-content>
  </div>
</div>
