import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';

@NgModule({
  declarations: [RadioButtonComponent, RadioGroupComponent],
  imports: [CommonModule, MatRadioModule, IconModule],
  exports: [RadioButtonComponent, RadioGroupComponent],
})
export class RadioModule {}
