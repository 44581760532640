<app-fab
  id="user-info-toggle"
  class="user-info-toggle"
  icon="account_circle_outline"
  [disabled]="disabled"
  (click)="toggleUserInfo()"
  [class.open]="showUserInfo"
  appGTagEventTracker
  eventName="openUserInfo (click)"
  eventCategory="Administration"
>
</app-fab>

<div
  id="user-info"
  class="user-info"
  [hidden]="!showUserInfo"
  (clickOutside)="hideUserInfo()"
  [clickOutsideExclude]="'#user-info, #user-info-toggle, #language-picker'"
>
  <!-- My Profile -->
  <a
    class="user-info-item"
    [href]="myProfileUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="myProfile (click)"
    eventCategory="Administration"
  >
    <app-icon icon="person"></app-icon>
    <div class="text">{{ 'header.user.userInfo.myProfile' | translate }} ({{ currentUserFullName }})</div>
  </a>

  <!-- Subscriptions -->
  <div
    class="user-info-item"
    (click)="subscriptionsClicked()"
    appGTagEventTracker
    eventName="subscriptions (click)"
    eventCategory="Administration"
  >
    <app-icon icon="subscription"></app-icon>
    <div class="text">{{ 'header.user.userInfo.subscription' | translate }} ({{ formattedSubscriptionLevel$ | async }})</div>
  </div>

  <!-- Delegation -->
  <a
    class="user-info-item"
    [ngClass]="{ 'disabled-item': (hasDelegationAccess$ | async) === false }"
    [href]="delegationUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="delegations (click)"
    eventCategory="Administration"
  >
    <app-icon icon="supervisor_account"></app-icon>
    <div class="text">{{ 'header.user.userInfo.delegation' | translate }}</div>
  </a>

  <!-- Change password -->
  <a
    class="user-info-item"
    [href]="changePasswordUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="changePassword (click)"
    eventCategory="Administration"
  >
    <app-icon icon="lock_open"></app-icon>
    <div class="text">{{ 'header.user.userInfo.changePassword' | translate }}</div>
  </a>

  <!-- Language -->
  <app-language-picker
    (onLanguageSelected)="hideUserInfo()"
    id="e2e-language-picker"
    data-testid="language-picker"
    config="languagePickerConfig"
    class="user-info-item language-picker"
    appGTagEventTracker
    eventName="language (click)"
    eventCategory="Administration"
  ></app-language-picker>

  <!-- Customer Centre  -->
  <a
    class="user-info-item"
    [href]="customerCentreUrl"
    target="_blank"
    (click)="hideUserInfo()"
    appGTagEventTracker
    eventName="customerCentre (click)"
    eventCategory="Administration"
  >
    <app-icon icon="help_outline"></app-icon>
    <div class="text">{{ 'header.user.userInfo.customerCentre' | translate }}</div>
  </a>

  <!-- Debug info  -->
  <a
    class="user-info-item"
    (click)="showDebugInformation()"
    appGTagEventTracker
    eventName="debugInfo (click)"
    eventCategory="Administration"
  >
    <app-icon icon="info_outline"></app-icon>
    <div class="text">{{ 'header.user.userInfo.debugInformation' | translate }}</div>
  </a>

  <!-- Log out -->
  <a
    class="user-info-item"
    id="e2e-logout"
    data-testid="logout"
    (click)="logOff()"
    appGTagEventTracker
    eventName="logOut (click)"
    eventCategory="Administration"
  >
    <app-icon icon="exit_to_app"></app-icon>
    <div class="text">{{ 'header.user.userInfo.logout' | translate }}</div>
  </a>
</div>
