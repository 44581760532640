import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-wet-hours-chart-info',
  templateUrl: './water-balance-chart-info.component.html',
  styleUrls: ['./water-balance-chart-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaterBalanceChartInfoComponent {
  constructor(private dialogRef: MatDialogRef<WaterBalanceChartInfoComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
