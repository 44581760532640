import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Farm } from '@app/core/interfaces/farm.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export interface FarmPickerMessages {
  getError: string;
  updateError: string;
  saveError: string;
  deleteError: string;
  noFarmsSelected: string;
}

export interface IFarmsRepo {
  get(): Observable<Farm[]>;
  update(farm: Farm): Observable<Farm | null>;
  delete(farmId: number): Observable<void | null>;
  searchFarms(searchFor: string, maxResults?: number): Observable<Farm[] | null>;
  createFromCvr(cvr: string): Observable<Farm | null>;
  updateFarmImage(farmId: number, file: File): Observable<string | null>;
  deleteFarmImage(url: string): Observable<void | null>;
  getFarm(farmId: number): Observable<Farm | null>;
}

@Injectable({
  providedIn: 'root',
})
export class FarmsRepo implements IFarmsRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService,
    public translate: TranslateService
  ) {}

  /**
   * Fetches a list of farms for the currently logged in user.
   */
  public get(): Observable<Farm[]> {
    const options: any = { withCredentials: true };
    return this.http.get<Farm[]>(`${this.endpoints.foApi}/farms`, options);
  }

  /**
   * Fetches a farm for the cvr number.
   */
  public getByCvr(cvr: string): Observable<Farm> {
    const options: any = { withCredentials: true };
    return this.http.get<Farm>(`${this.endpoints.foApi}/farmcvr/${cvr}`, options);
  }

  /**
   * Update method for given farm
   * @param farm: Farm entity to be updated
   */
  public update(farm: Farm): Observable<Farm | null> {
    const options: any = {
      /* */
    };

    return this.http.put<Farm, Farm>(`${this.endpoints.foApi}/farms/${farm.id}`, farm, options);
  }

  /**
   * Delete farm
   * @param farmId: Id for Farm to delete
   */
  public delete(farmId: number): Observable<void | null> {
    const options: any = { withCredentials: true };

    return this.http.delete<void>(`${this.endpoints.foApi}/farms/${farmId}`, options);
  }

  /**
   * Update image for Farm
   * @param farmId: Id for Farm holding image
   * @param file: File to upload
   */
  public updateFarmImage(farmId: number, file: File | null): Observable<string | null> {
    const options: any = { withCredentials: true };

    const formData = new FormData();
    formData.append('file', file!);

    return this.http.post<string, FormData>(`${this.endpoints.foApi}/farms/${farmId}/farmimages`, formData, options);
  }

  /**
   * Delete image for Farm
   * @param url: The url to get the image - is also the url to delete it
   */
  public deleteFarmImage(url: string): Observable<void | null> {
    const options: any = { withCredentials: true };

    return this.http.delete<void>(this.endpoints.foApi + url, options);
  }

  public getFarm(farmId: number): Observable<Farm | null> {
    const options: any = { withCredentials: true };
    return this.http.get<Farm>(`${this.endpoints.bffApi}/farms/${farmId}?addProductData=true`, options);
  }

  /**
   * Searches in master data for farms, eg. name, address, cvr, etc.
   * Only consultants & advisors can use this feature
   * @param searchTerm Search term
   * @param maxResults Max result to return
   */
  public searchFarms(searchTerm: string, maxResults: number = 10): Observable<Farm[] | null> {
    const options: any = { withCredentials: true };
    const farmSearch = {
      searchTerm: searchTerm,
      skip: 0,
      take: maxResults,
    };

    return this.http.post<Farm[], { searchTerm: string; skip: number; take: number }>(
      `${this.endpoints.foApi}/farms/advisorsearch`,
      farmSearch,
      options
    );
  }

  /**
   * Creates a new farm from a CVR number.
   * The farm is created using NAER information.
   * @param cvr CVR number.
   */
  public createFromCvr(cvr: string): Observable<Farm | null> {
    // Force content-type to json
    const headersWithContentType = new HttpHeaders().append('Content-Type', 'application/json');

    return this.http.post<Farm, string>(`${this.endpoints.foApi}/farms/naerfarm`, cvr, {
      headers: headersWithContentType,
    });
  }
}
