import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { StringHelpers } from '@app/helpers/string-helpers';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, AfterViewInit {
  @Input() public tooltipText?: string;
  @Input() public delayType: 'short' | 'long' = 'short';
  @Input() public position: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' = 'below';
  @Input() public isOneLine = false;
  @Input() public showDelay?: number;
  @Input() public hideDelay?: number;

  fullWidth: boolean = false;
  @Input() set setFullWidth(fullWidth: boolean | '') {
    this.fullWidth = fullWidth === '' || fullWidth;
  }

  private readonly _shortShowDelay = 0;
  private readonly _shortHideDelay = 100;
  private readonly _longShowDelay = 700;
  private readonly _longHideDelay = 100;

  public isViewInit = false;

  public ngAfterViewInit(): void {
    this.isViewInit = true;
  }

  public ngOnInit(): void {
    switch (this.delayType) {
      case 'short':
        if (!this.showDelay) {
          this.showDelay = this._shortShowDelay;
        }
        if (!this.hideDelay) {
          this.hideDelay = this._shortHideDelay;
        }
        break;
      case 'long':
        if (!this.showDelay) {
          this.showDelay = this._longShowDelay;
        }
        if (!this.hideDelay) {
          this.hideDelay = this._longHideDelay;
        }
        break;
      default:
        this.showDelay = this._shortShowDelay;
        this.hideDelay = this._shortHideDelay;
        break;
    }
  }

  StringHelpers = StringHelpers;
}
