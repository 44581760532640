import { Injectable } from '@angular/core';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { Field } from '@app/core/interfaces/field.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { CultivationJournalStateService } from '@app/state/services/cultivation-journal/cultivation-journal-state.service';
import { MapStateService } from '@app/state/services/map/map-state.service';

@Injectable()
export class CultivationJournalStateDispatchers {
  constructor(
    private mapStateService: MapStateService,
    private cultivationJournalStateService: CultivationJournalStateService
  ) {}
  public setMapCoverFlowItems(mapCoverFlowItems: MapCoverFlowItem[]) {
    this.mapStateService.mapCoverFlowItems = mapCoverFlowItems;
  }

  public setMapOptions(isLegendVisible: boolean, isSettingsVisible: boolean) {
    this.mapStateService.fieldMapOptions = {
      isLegendVisible: isLegendVisible,
      isSettingsVisible: isSettingsVisible,
    };
  }

  public setCultivationJournalField(field: Field | undefined) {
    this.cultivationJournalStateService.field = field;
  }

  public setCultivationJournalLegendCropColors(cropColors: CropColor[]) {
    return (this.mapStateService.fieldMapLegendCropColors = cropColors);
  }
}
