import { geometry, Layout, Path, Text } from '@progress/kendo-drawing';
import { Rect as Rect2 } from '@progress/kendo-drawing/geometry';

const { Point } = geometry;

/**
 * Creates a dashed style legend for a Kendo chart.
 */
export function getChartLegendStyle(args: any) {
  const color = typeof args.series.color === 'string' ? args.series.color : args.series.color();
  const path = new Path({
    stroke: { color, opacity: args.series.opacity, dashType: args.series.dashType, width: args.series.type === 'rangeArea' ? 12 : 2 },
  });
  args.series.type === 'column' ? path.moveTo(15, 20).lineTo(15, 0) : path.moveTo(0, 0).lineTo(25, 0);
  const text = new Text(args.series.name, new Point(0, 25), {
    cursor: 'pointer',
    font: '14px "LFPressSans", Arial, Verdana, sans-serif',
    fill: {
      color: 'rgb(101, 101, 101)',
    },
  });
  const layout = new Layout(new Rect2([0, 0], [Number.MAX_VALUE, 0]), {
    alignItems: 'center',
    name: args.series.name,
    spacing: 5,
  });

  layout.append(path, text);
  layout.reflow();

  return layout;
}
