import { SelectionModel } from '@angular/cdk/collections';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { ListOptionComponent, SelectionList } from '../list-option/list-option.component';
import { SELECTION_LIST } from './selection-list.token';

/**
 * This is heavily inspired by the Angular Material SelectionList component.
 * @see https://github.com/angular/components/blob/main/src/material/list/selection-list.ts
 */

/** Change event that is being fired whenever the selected state of an option changes. */
export class SelectionListChange {
  constructor(
    /** Reference to the selection list that emitted the event. */
    public source: SelectionListComponent,
    /** Reference to the options that have been changed. */
    public options: ListOptionComponent[],
    /** List of selected options. */
    public selected: ListOptionComponent[]
  ) {}
}

/**
 * Selection list component
 */
@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  providers: [
    { provide: SELECTION_LIST, useExisting: SelectionListComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectionListComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionListComponent implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, SelectionList {
  /** Header title */
  @Input() title?: string;

  /** Theme color of the selection list. This sets the checkbox color for "select all" */
  @Input() color: ThemePalette = 'accent';

  /** Whether selection is limited to one or multiple items (default multiple). */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    if (value === this._multiple) return;

    if (this._initialized) {
      throw new Error('Cannot change `multiple` mode of mat-selection-list after initialization.');
    }

    this._multiple = value;
    this.selectedOptions = new SelectionModel(this._multiple, this.selectedOptions.selected);
  }

  private _multiple = true;

  /**
   * Function used for comparing an option against the selected value when determining which
   * options should appear as selected. The first argument is the value of an options. The second
   * one is a value from the selected value. A boolean must be returned.
   */
  @Input() compareWith: (o1: any, o2: any) => boolean = (a1, a2) => a1 === a2;

  /**
   * Whether the *entire* selection list is disabled. When true, each list item is also disabled
   * and each list item is removed from the tab order (has tabindex="-1").
   */
  @Input() disabled: boolean = false;

  /**
   * Position of text label. Passed directly to MatCheckbox
   */
  @Input() labelPosition: MatCheckbox['labelPosition'] = 'before';

  /** Emits a change event whenever the selected state of an option changes. */
  @Output() readonly selectionChange: EventEmitter<SelectionListChange> = new EventEmitter<SelectionListChange>();

  @ContentChildren(ListOptionComponent, { descendants: true }) options?: QueryList<ListOptionComponent>;

  /** The currently selected options. */
  public selectedOptions = new SelectionModel<ListOptionComponent>(this._multiple);

  /** Keeps track of the currently-selected values. */
  public value?: unknown[] | null;

  private _initialized = false;

  /** Emits when the list has been destroyed. */
  private _destroyed = new Subject<void>();

  constructor(
    public _element: ElementRef<HTMLElement>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    // Mark the selection list as initialized so that the `multiple`
    // binding can no longer be changed.
    this._initialized = true;

    if (this.value) {
      this._setOptionsFromValues(this.value);
    }

    this._watchForSelectionChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    const disabledChanges = changes['disabled'];

    if (disabledChanges && !disabledChanges.firstChange) {
      this._markOptionsForCheck();
    }
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  /** Selects all of the options. Returns the options that changed as a result. */
  public selectAll(): ListOptionComponent[] {
    return this._setAllOptionsSelected(true);
  }

  /** Deselects all of the options. Returns the options that changed as a result. */
  public deselectAll(): ListOptionComponent[] {
    return this._setAllOptionsSelected(false);
  }

  /** Emits a change event if the selected state of an option changed. */
  public emitChangeEvent(options: ListOptionComponent[]) {
    this.selectionChange.emit(new SelectionListChange(this, options, this.selectedOptions.selected));
    this.cdRef.markForCheck();
  }

  public get isIndeterminate() {
    return this.selectedOptions.selected.length > 0 && !this.isAllSelected;
  }

  public get isAllSelected() {
    return this.selectedOptions.selected.length === this.options?.length;
  }

  /** Select all if none or some are selected, else deselect all  */
  public onSelectAll() {
    // call getter once
    const newVal = !this.isAllSelected;

    this.options?.forEach((option) => option.setSelected(newVal));
    this.reportValueChange();
  }

  /** Sets the selected options based on the specified values. */
  private _setOptionsFromValues(values: unknown[]) {
    this.options?.forEach((option) => option.setSelected(false));

    values.forEach((value) => {
      const correspondingOption = this.options?.find((option) => {
        // Skip options that are already in the model. This allows us to handle cases
        // where the same primitive value is selected multiple times.
        return option.selected ? false : this.compareWith(option.value, value);
      });

      if (correspondingOption) {
        correspondingOption.setSelected(true);
      }
    });
  }

  /** Returns the values of the selected options. */
  private _getSelectedOptionValues(): unknown[] {
    return this.options?.filter((option) => option.selected).map((option) => option.value) ?? [];
  }

  /**
   * Sets the selected state on all of the options
   * and emits an event if anything changed.
   */
  private _setAllOptionsSelected(isSelected: boolean, skipDisabled?: boolean): ListOptionComponent[] {
    // Keep track of whether anything changed, because we only want to
    // emit the changed event when something actually changed.
    const changedOptions: ListOptionComponent[] = [];

    this.options?.forEach((option) => {
      if ((!skipDisabled || !option.disabled) && option.setSelected(isSelected)) {
        changedOptions.push(option);
      }
    });

    if (changedOptions.length) {
      this.reportValueChange();
    }

    return changedOptions;
  }

  /** Watches for changes in the selected state of the options and updates the list accordingly. */
  private _watchForSelectionChange() {
    this.selectedOptions.changed.pipe(takeUntil(this._destroyed)).subscribe((event) => {
      // Sync external changes to the model back to the options.
      for (let item of event.added) {
        item.selected = true;
      }

      for (let item of event.removed) {
        item.selected = false;
      }

      if (!this._containsFocus()) {
        this._resetActiveOption();
      }
    });
  }

  /** Returns whether the focus is currently within the list. */
  private _containsFocus() {
    const activeElement = _getFocusedElementPierceShadowDom();
    return activeElement && this._element.nativeElement.contains(activeElement);
  }

  /**
   * Sets an option as active.
   * @param index Index of the active option. If set to -1, no option will be active.
   */
  private _setActiveOption(index: number) {
    this.options?.forEach((item, itemIndex) => item.setTabindex(itemIndex === index ? 0 : -1));
  }

  /**
   * Resets the active option. When the list is disabled, remove all options from to the tab order.
   * Otherwise, focus the first selected option.
   */
  private _resetActiveOption() {
    if (this.disabled) {
      return;
    }

    const activeItem = this.options?.find((item) => item.selected && !item.disabled) || this.options?.first;
    this._setActiveOption(activeItem && this.options ? this.options.toArray().indexOf(activeItem) : -1);
  }

  /** Marks all the options to be checked in the next change detection run. */
  private _markOptionsForCheck() {
    if (this.options) {
      this.options.forEach((option) => option.markForCheck());
    }
  }

  /**
   *
   * ControlValueAccessor implementation
   *
   */

  /** Reports a value change to the ControlValueAccessor */
  public reportValueChange() {
    // Stop reporting value changes after the list has been destroyed. This avoids
    // cases where the list might wrongly reset its value once it is removed, but
    // the form control is still live.
    if (this.options) {
      const value = this._getSelectedOptionValues();
      this._onChange(value);
      this.value = value;
    }
  }

  writeValue(values: unknown[]): void {
    this.value = values;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  /** View to model callback that should be called whenever the selected options change. */
  private _onChange: (value: any) => void = (_: any) => {};

  /** View to model callback that should be called if the list or its options lost focus. */
  _onTouched: () => void = () => {};
}
