<div class="header-close">
  <h4 *ngIf="!isGrazing">{{ 'grassPrognosis.help.title' | translate }}</h4>
  <h4 *ngIf="isGrazing">{{ 'grassPrognosis.help.grazing.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content *ngIf="!isGrazing">
  <h5>{{ 'grassPrognosis.help.digestibility.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.digestibility.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.protein.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.protein.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.sugar.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.sugar.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.development.title' | translate }}</h5>
  <p [innerHTML]="'grassPrognosis.help.development.text' | translate"></p>

  <p>
    {{ 'grassPrognosis.help.readMore' | translate }}
    <a href="https://www.landbrugsinfo.dk">https://www.landbrugsinfo.dk</a>
  </p>
</mat-dialog-content>

<mat-dialog-content *ngIf="isGrazing">
  <h5>{{ 'grassPrognosis.help.grazing.growth.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.grazing.growth.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.grazing.energy.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.grazing.energy.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.grazing.protein.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.grazing.protein.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.grazing.sugar.title' | translate }}</h5>
  <p>{{ 'grassPrognosis.help.grazing.sugar.text' | translate }}</p>

  <h5>{{ 'grassPrognosis.help.grazing.development.title' | translate }}</h5>
  <p [innerHTML]="'grassPrognosis.help.grazing.development.text' | translate"></p>
</mat-dialog-content>
