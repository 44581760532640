import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-claas-trimble-form',
  templateUrl: './claas-trimble-form.component.html',
  styleUrls: ['./claas-trimble-form.component.scss'],
})
export class ClaasTrimbleFormComponent {
  @Input() public claasTrimbleFormGroup!: UntypedFormGroup;
}
