import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/shared/material/material.module';

import { CheckboxComponent } from './checkbox.component';

@NgModule({
  exports: [CheckboxComponent],
  imports: [CommonModule, MaterialModule],
  declarations: [CheckboxComponent],
})
export class CheckboxModule {}
