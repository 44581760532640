<div class="spinner" *ngIf="loadingState.isLoading$ | async">
  <app-spinner [message]="(loadingState.message$ | async)!" [hasBackdrop]="true"></app-spinner>
</div>
<ng-container *ngIf="!(loadingState.isLoading$ | async)">
  <div class="field-table-root" *ngFor="let tableModel of fieldTables$ | async; trackBy: trackByFn">
    <h5 class="crop-title">{{ tableModel.name }}</h5>
    <table>
      <thead>
        <tr>
          <th>
            <app-checkbox
              [isCircle]="false"
              [checked]="tableModel.isAllSelected"
              (onChange)="onCheckAllClick($event, tableModel)"
            ></app-checkbox>
          </th>
          <th>{{ 'common.field' | translate }}</th>
          <th>{{ 'common.hectareShort' | translate }}</th>
          <th>{{ 'main.growthRegulation.insurance.risk' | translate }}</th>
          <th>{{ 'main.growthRegulation.insurance.price' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of tableModel.table.fields; trackBy: trackByFn">
          <td>
            <app-checkbox
              (onChange)="onCheckClick($event, field, tableModel)"
              [disabled]="field.registered"
              [isCircle]="false"
              [checked]="field.selected"
            ></app-checkbox>
          </td>
          <td>{{ field.fieldNumber }}</td>
          <td>{{ field.area | unit }}</td>
          <td>{{ field.risk }}</td>
          <td>{{ field.price | unit: 'kr' }}</td>
        </tr>
        <tr class="sum">
          <td>{{ 'main.growthRegulation.insurance.sum' | translate }}:</td>
          <td></td>
          <td>{{ tableModel.table.totalArea | unit }}</td>
          <td></td>
          <td>{{ tableModel.table.totalPrice | unit: 'kr' }},-</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
