import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { DroneImageUploadState } from '@app/core/drone-image/drone-image-upload-state';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DirtyCheckDialogComponent } from '@app/shared/dialog/dirty-check-dialog/dirty-check-dialog.component';
import { DirtyCheckDialogAction } from '@app/shared/dialog/dirty-check-dialog/dirty-check-actions.class';

@Component({
  selector: 'app-drone-image-upload-progress-dialog',
  templateUrl: './drone-image-upload-progress-dialog.component.html',
  styleUrls: ['./drone-image-upload-progress-dialog.component.scss'],
})
export class DroneImageUploadProgressDialogComponent implements OnInit {
  public fieldsUploaded$: Observable<number>;
  public fields$: Observable<number>;
  public imagesUploaded$: Observable<number>;
  public images$: Observable<number>;
  public progress$: Observable<number>;

  private cancelDialogRef?: MatDialogRef<DirtyCheckDialogComponent>;

  constructor(
    public dialogRef: MatDialogRef<DroneImageUploadProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DroneImageUploadState,
    private dialogService: DialogService
  ) {
    this.fieldsUploaded$ = data.uploadedFields$;
    this.fields$ = data.totalFields$;
    this.images$ = data.totalImages$;
    this.imagesUploaded$ = data.uploadedImages$;
    this.progress$ = combineLatest([this.imagesUploaded$, this.images$]).pipe(
      map(([imagesUploaded, images]) => (imagesUploaded / images) * 100)
    );
  }

  public ngOnInit(): void {
    this.data.onUploadComplete$.pipe(first()).subscribe(() => this.close());
  }

  public onCancelClicked() {
    this.cancelDialogRef = this.dialogService.openCustomDialog(DirtyCheckDialogComponent, {});
    this.cancelDialogRef
      .afterClosed()
      .pipe(
        first(),
        filter((res: DirtyCheckDialogAction) => res?.isConfirmed),
        takeUntil(this.data.onUploadComplete$)
      )
      .subscribe(() => {
        this.close(false);
      });
  }

  private close(val?: boolean) {
    if (this.cancelDialogRef) {
      this.cancelDialogRef.close();
    }
    this.dialogRef.close(val);
  }
}
