import { ColumnKey } from './column-key';
import { ColumnKeyShared } from './column-key-shared';

export class ColumnKeyNTool implements ColumnKey {
  public static fieldNumber = ColumnKeyShared.fieldNumber;
  public static cropName = ColumnKeyShared.cropName;
  public static taskDate = Symbol(9);
  public static productName = Symbol(10);
  public static productAmount = Symbol(11);
  public static nAssigned = Symbol(2);
  public static nPlanned = Symbol(3);
  public static nNeeds = Symbol(4);
  public static nBalance = Symbol(5);
  public static nZero = Symbol(6);
  public static nextTreatment = Symbol(7);
  public static status = Symbol(8);
  public static update = Symbol(12);

  public static getColumnKeySymbolFrom(index: number) {
    switch (index) {
      case 0:
        return this.fieldNumber;
      case 1:
        return this.cropName;
      case 9:
        return this.taskDate;
      case 10:
        return this.productName;
      case 11:
        return this.productAmount;
      case 2:
        return this.nAssigned;
      case 3:
        return this.nPlanned;
      case 4:
        return this.nNeeds;
      case 5:
        return this.nBalance;
      case 6:
        return this.nZero;
      case 7:
        return this.nextTreatment;
      case 8:
        return this.status;
      case 12:
        return this.update;
      default:
        return;
    }
  }
}
