import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-growth-stages-info',
  templateUrl: './growth-stages-info.component.html',
  styleUrls: ['./growth-stages-info.component.scss'],
})
export class GrowthStagesInfoComponent {
  constructor(
    private dialogRef: MatDialogRef<GrowthStagesInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { helpText: string }
  ) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
