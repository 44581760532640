<div class="list-container">
  <div class="list-container-header">
    <div class="list-container-header-content">
      <div class="list-container-header-select-all">
        <button mat-button class="select-all-btn" (click)="selectAll()" *ngIf="!(allSelected$ | async)">
          <span class="select-all-btn-inner">{{ 'vra.save-settings.select-all' | translate }}</span>
        </button>
        <button mat-button class="select-all-btn" (click)="deselectAll()" *ngIf="allSelected$ | async">
          <span class="select-all-btn-inner">{{ 'vra.save-settings.deselect-all' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="list-container-elements" [formGroup]="form">
    <div formArrayName="logs">
      <div class="list-element" *ngFor="let log of logArray.controls; let i = index" [formGroupName]="i">
        <div class="list-element-content">
          <mat-checkbox class="list-element-checkbox" formControlName="selected">
            <div class="list-element-details">
              <div class="list-element-details-name">
                <span [matTooltip]="log.get('logName')?.value" [matTooltipDisabled]="log.get('logName')?.value.length < 40">
                  {{ log.get('logName')?.value | truncate: [40, '...'] }}
                </span>
              </div>
              <div class="list-element-details-date" *ngIf="log.get('startTime')?.value || log.get('endTime')?.value; else noDates">
                <div class="list-element-details-datetime-start">
                  {{ log.get('startTime')?.value | dateFormat: 1 }}
                </div>
                <div class="list-element-details-datetime-to">
                  <i class="arrow"></i>
                </div>
                <div class="list-element-details-datetime-end">
                  {{ log.get('endTime')?.value | dateFormat: 1 }}
                </div>
              </div>
              <ng-template #noDates>
                {{ 'main.asApplied.taskDetails.noDates' | translate }}
              </ng-template>
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
