import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, Output } from '@angular/core';
import { SELECTION_LIST } from '../selection-list/selection-list.token';
import { LIST_OPTION } from './list-option.token';

/**
 * This is heavily inspired by the Angular Material ListOption component.
 * @see https://github.com/angular/components/blob/main/src/material/list/list-option.ts
 */

/**
 * Interface describing the containing list of an list option. This is used to avoid
 * circular dependencies between the list-option and the selection list.
 * @docs-private
 */
export interface SelectionList {
  multiple: boolean;
  selectedOptions: SelectionModel<ListOptionComponent>;
  compareWith: (o1: any, o2: any) => boolean;
  reportValueChange(): void;
  emitChangeEvent(options: ListOptionComponent[]): void;
  value?: unknown[] | null;
  _onTouched(): void;
}

/**
 * List option component
 */
@Component({
  selector: 'app-list-option',
  templateUrl: './list-option.component.html',
  styleUrls: ['./list-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: LIST_OPTION, useExisting: ListOptionComponent }],
  host: {
    '[class.selected]': 'selected',
    '(click)': '_toggleOnInteraction()',
  },
})
export class ListOptionComponent {
  @Input() disabled = false;

  /** Whether the option is selected. */
  @Input()
  get selected(): boolean {
    return this._selectionList.selectedOptions.isSelected(this);
  }

  set selected(isSelected: boolean) {
    if (isSelected !== this._selected) {
      this.setSelected(isSelected);

      if (isSelected || this._selectionList.multiple) {
        this._selectionList.reportValueChange();
      }
    }
  }

  /** Value of the option */
  @Input()
  get value(): unknown {
    return this._value;
  }

  set value(newValue: unknown) {
    if (this.selected && newValue !== this.value && this._inputsInitialized) {
      this.selected = false;
    }

    this._value = newValue;
  }

  private _value?: unknown;

  /**
   * Emits when the selected state of the option has changed.
   * Use to facilitate two-data binding to the `selected` property.
   * @docs-private
   */
  @Output() readonly selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _selected = false;

  /**
   * This is set to true after the first OnChanges cycle so we don't
   * clear the value of `selected` in the first cycle.
   */
  private _inputsInitialized = false;

  constructor(
    @Inject(SELECTION_LIST) private _selectionList: SelectionList,
    private _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    const list = this._selectionList;

    if (list.value && list.value.some((value) => list.compareWith(this._value, value))) {
      this.setSelected(true);
    }

    const wasSelected = this._selected;

    // List options that are selected at initialization can't be reported properly to the form
    // control. This is because it takes some time until the selection-list knows about all
    // available options. Also it can happen that the ControlValueAccessor has an initial value
    // that should be used instead. Deferring the value change report to the next tick ensures
    // that the form control value is not being overwritten.
    Promise.resolve()
      .then(() => {
        if (this._selected || wasSelected) {
          this.selected = true;
          this._changeDetectorRef.markForCheck();
        }
      })
      .catch((err) => console.warn(err));

    this._inputsInitialized = true;
  }

  /**
   * Sets the selected state of the option.
   * @returns Whether the value has changed.
   */
  public setSelected(selected: boolean): boolean {
    if (selected === this._selected) {
      return false;
    }

    this._selected = selected;

    if (selected) {
      this._selectionList.selectedOptions.select(this);
    } else {
      this._selectionList.selectedOptions.deselect(this);
    }

    this.selectedChange.emit(selected);
    this.markForCheck();

    return true;
  }

  /**
   * Notifies Angular that the option needs to be checked in the next change detection run.
   * Mainly used to trigger an update of the list option if the disabled state of the selection
   * list changed.
   */
  public markForCheck() {
    this._changeDetectorRef.markForCheck();
  }

  /** Toggles the selection state of the option. */
  public toggle(): void {
    this.selected = !this.selected;
  }

  /** Sets the tabindex of the list option. */
  public setTabindex(value: number) {
    this.elementRef.nativeElement.setAttribute('tabindex', value + '');
  }

  /** Toggles the option's value based on a user interaction. */
  private _toggleOnInteraction() {
    if (this.disabled) return;

    if (this._selectionList.multiple) {
      this.selected = !this.selected;
      this._selectionList.emitChangeEvent([this]);
    } else if (!this.selected) {
      this.selected = true;
      this._selectionList.emitChangeEvent([this]);
    }
  }
}
