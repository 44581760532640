export const cmShortcuts: CMShortcuts = {
  edit: {
    description: 'Rediger',
    displayName: 'R',
    key: 'r',
  },
  create: {
    description: 'Opret',
    displayName: 'O',
    key: 'o',
  },
  delete: {
    description: 'Slet',
    displayName: 'S',
    key: 's',
  },
  export: {
    description: 'Eksporter',
    displayName: 'E',
    key: 'e',
  },
  next: {
    description: 'Næste',
    displayName: 'Ctrl & Højre pil',
    key: 'ArrowRight',
    ctrlKey: true,
  },
  prev: {
    description: 'Forrige',
    displayName: 'Ctrl & Venstre pil',
    key: 'ArrowLeft',
    ctrlKey: true,
  },
  cancel: {
    description: 'Annullér',
    displayName: 'Escape',
    key: 'Escape',
  },
  chooseFarm: {
    description: 'Vælg bedrift',
    displayName: 'Ctrl & B',
    key: 'b',
    ctrlKey: true,
  },
  chooseField: {
    description: 'Vælg markblok',
    displayName: 'V',
    key: 'v',
  },
  drawField: {
    description: 'Indtegn mark',
    displayName: 'I',
    key: 'i',
  },
  saveAndClose: {
    description: 'Gem & Luk',
    displayName: 'Ctrl & Enter',
    key: 'Enter',
    ctrlKey: true,
  },
};

export interface CMShortcuts {
  edit: ShortCutKey;
  create: ShortCutKey;
  delete: ShortCutKey;
  export: ShortCutKey;
  next: ShortCutKey;
  prev: ShortCutKey;
  saveAndClose: ShortCutKey;
  cancel: ShortCutKey;
  chooseFarm: ShortCutKey;
  chooseField: ShortCutKey;
  drawField: ShortCutKey;
}

export interface ShortCutKey {
  key: string;
  description: string;
  displayName: string;
  ctrlKey?: boolean;
  shiftKey?: boolean;
}

export function eventIsShortCut(e: KeyboardEvent, s: ShortCutKey) {
  if (s.ctrlKey || s.shiftKey) {
    return e.key === s.key && ((e.ctrlKey && s.ctrlKey) || (e.shiftKey && s.shiftKey));
  } else {
    return e.key === s.key;
  }
}
