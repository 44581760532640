import { Component, Input } from '@angular/core';
import { MatRadioGroup } from '@angular/material/radio';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() public value: any;
  @Input() public disabled = false;
  @Input() public labelPosition: 'before' | 'after' = 'before';

  constructor(private radioGroup: MatRadioGroup) {}

  public get selected() {
    return this.radioGroup.value;
  }
}
