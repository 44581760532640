<ng-container [ngSwitch]="buttonType">
  <ng-container *ngSwitchCase="'Primary'">
    <app-primary-button>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </app-primary-button>
  </ng-container>
  <ng-container *ngSwitchCase="'Secondary'">
    <app-secondary-button>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </app-secondary-button>
  </ng-container>
  <ng-container *ngSwitchCase="'Transparent'">
    <app-transparent-button>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </app-transparent-button>
  </ng-container>
  <ng-container *ngSwitchCase="'CTA'">
    <app-cta-button>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </app-cta-button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <button
      mat-button
      class="default-btn"
      (click)="onClick()"
      type="{{ type }}"
      [ngStyle]="buttonStyle"
      [disabled]="disabled"
      [ngClass]="buttonClass"
    >
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </button>
  </ng-container>
</ng-container>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
