import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasAccessToDirective } from './directive/has-access-to.directive';

@NgModule({
  declarations: [HasAccessToDirective],
  imports: [CommonModule],
  exports: [HasAccessToDirective],
})
export class AccessControlModule {}
