import { Field } from '@app/core/interfaces/field.interface';
import { SimpleOperation, SimpleOperationDto } from '@app/core/interfaces/simple-operation.interface';
import { DateTime } from 'luxon';

export interface SimpleTaskDto {
  id: number;
  farmId: number;
  cropId: number;
  hashCode: string;
  harvestYear: number;
  date: string;
  registered: boolean;
  comment: string;
  operations: SimpleOperationDto[];
  area: number;
}

export class SimpleTask implements Omit<SimpleTaskDto, 'date'> {
  public id!: number;
  public farmId: number;
  public cropId: number;
  public hashCode!: string;
  public harvestYear: number;
  public registered!: boolean;
  public date: DateTime = DateTime.now();
  public comment!: string;
  public operations: SimpleOperation[] = [];
  public area: number;
  public fieldId: number;
  public operationTypeGroupNames?: string;
  public produceNormNames?: string;

  constructor({ id, area, farmId, harvestYear }: Field | undefined = {} as Field, cropId: number) {
    this.area = area;
    this.farmId = farmId;
    this.harvestYear = harvestYear;
    this.cropId = cropId;
    this.fieldId = id;
  }
}
