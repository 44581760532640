import { Layer } from 'ol/layer';
import { Options } from 'ol/layer/Layer';
import WebGLVectorLayerRenderer from 'ol/renderer/webgl/VectorLayer';
import Source from 'ol/source/Source';
import { WebGLStyle } from 'ol/style/webgl';

/**
 * Custom WebGLLayer to support custom style properties for vector layers
 */
export class WebGLLayer extends Layer {
  private style: WebGLStyle | WebGLStyle[];

  /**
   * Constructor
   * @param options - Options for the layer
   */
  constructor(options: Options<Source> & { style?: WebGLStyle | WebGLStyle[] }) {
    super(options);
    this.style = options.style || {
      'stroke-color': ['get', 'stroke'],
      'stroke-width': 2,
      'fill-color': ['get', 'fill'],
      'stroke-miter-limit': 4,
    };
  }

  /**
   * Create renderer
   */
  override createRenderer(): WebGLVectorLayerRenderer {
    return new WebGLVectorLayerRenderer(this, {
      style: this.style,
    });
  }
}
