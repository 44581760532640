<div mat-dialog-title>{{ 'main.settings.dataConnections.johnDeere.select-organization-dialog.title' | translate }}</div>

<div mat-dialog-content>
  <div class="description">
    <span>{{ 'main.settings.dataConnections.johnDeere.select-organization-dialog.description-1' | translate: { name: data.name } }}</span>
    <span>{{ 'main.settings.dataConnections.johnDeere.select-organization-dialog.description-2' | translate }}</span>
  </div>
  <form [formGroup]="form">
    <mat-form-field *skeleton="!organizations.length" appearance="outline">
      <mat-label>{{ 'main.settings.dataConnections.johnDeere.select-organization-dialog.select-organization' | translate }}</mat-label>
      <mat-select [formControl]="form.controls.jdOrganization">
        <mat-option *ngFor="let org of organizations" [value]="org">
          {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button type="button" mat-flat-button color="basic" mat-dialog-close [disabled]="loading">
    {{ 'main.settings.dataConnections.johnDeere.select-organization-dialog.cancel' | translate }}
  </button>
  <button
    mat-button
    [disabled]="!form || form.controls.jdOrganization.errors?.['required']"
    type="button"
    mat-flat-button
    color="primary"
    (click)="save()"
  >
    <mat-progress-spinner *ngIf="loading" diameter="24" mode="indeterminate"></mat-progress-spinner>
    <span *ngIf="!loading">{{ 'main.settings.dataConnections.johnDeere.select-organization-dialog.save-choice' | translate }}</span>
  </button>
</div>
