<form [formGroup]="form">
  <mat-card appearance="outlined">
    <mat-card-title
      >{{ 'main.asApplied.shapefileDetails.configureFile' | translate }}
      <app-remove-button *ngIf="canBeRemoved$ | async" (clicked)="removeConfigForm()"></app-remove-button>
    </mat-card-title>
    <mat-card-content>
      <div class="input-container">
        <app-select
          (valueChange)="setConfigInput()"
          formControlName="quantityColumnName"
          placeholder="{{ 'main.asApplied.shapefileDetails.selectQuantityColumnName' | translate }}"
        >
          <app-select-option *ngFor="let column of this.columnNames" [value]="column.name" [disabled]="column.disabled">
            <div>{{ column.name }}</div>
          </app-select-option>
        </app-select>
        <app-select
          (valueChange)="setConfigInput()"
          formControlName="srid"
          placeholder="{{ 'main.asApplied.shapefileDetails.coordinateSystem' | translate }}"
        >
          <app-select-option *ngFor="let projection of projections" [value]="projection.srid">
            <div>{{ projection.srid }} {{ projection.projection }}</div>
          </app-select-option>
        </app-select>
      </div>
    </mat-card-content>
  </mat-card>
  <br />
</form>
