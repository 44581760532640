<div class="no-images" *ngIf="urls.length === 0">
  <app-icon [icon]="'outline_camera'"></app-icon>
</div>
<div class="scroller-container">
  <div
    class="scroller"
    #scrollerElement
    (panstart)="onPanStart($event)"
    [class.panning]="panning"
    [class.auto-play]="autoPlayMs"
    (panmove)="onPan($event)"
    (panend)="onPanEnd($event)"
    [style.width]="urls.length * 100 + '%'"
    *ngIf="urls.length > 0"
  >
    <div class="image" #imageElement *ngFor="let url of urls; let i = index" (click)="clickSlide(i)" [ngClass]="{ clickable: isClickable }">
      <app-icon *ngIf="canDelete" class="del-btn" (click)="deleteSlide(i)" icon="delete_outline"></app-icon>
      <div ondragstart="return false;" ondrop="return false;" class="slider-image" [style.background-image]="url"></div>
      <div *ngIf="headlines && headlines.length > 0" class="headline">{{ headlines[i] }}</div>
    </div>
  </div>
</div>
<div class="indicators" #indicatorElement [ngClass]="{ hidden: urls.length <= 1, outside: outsideIndicators }">
  <div class="indicator" *ngFor="let url of urls; let i = index" [class.active]="i === activeImageIndex" (click)="gotoSlide(i)"></div>
</div>
