import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'droneImageStatus',
})
export class DroneImageStatus implements PipeTransform {
  constructor(private translateServic: TranslateService) {}
  public transform(value: number, args?: any): string {
    return this.translateServic.instant('main.fieldmap.droneImageImport.statuscodes.' + value);
  }
}
