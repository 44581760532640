<table *ngIf="dataSourceSort" class="table" [ngStyle]="{ background: background }" mat-table [dataSource]="dataSourceSort" matSort>
  <ng-container *ngFor="let key of displayedColumnKeys" [matColumnDef]="key">
    <th class="header-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ $any(displayedColumns)[$any(key)] }}
    </th>
    <td
      class="row-cell"
      mat-cell
      matTooltip="{{ element[key] }}"
      matTooltipClass="table-tooltip-message one-line"
      *matCellDef="let element"
      [ngStyle]="$any(dataStyles)[$any(key)]"
    >
      <ng-container *ngIf="isObject(element[key]); else notObject">
        <div *ngIf="!formatDates || !isDate(element[key]); else formattedDate" class="text-icon">
          <span [ngClass]="{ bold: !!element[key].bold }">{{ element[key].text | truncate: [10, '...', element.noTruncate] }}</span>
          <app-icon *ngIf="element[key].icon" [icon]="element[key].icon"></app-icon>
        </div>
        <ng-template #formattedDate>
          <div class="text-icon">
            <span [ngClass]="{ bold: !!element[key].bold }">{{
              element[key] | dateFormat | truncate: [10, '...', element.noTruncate]
            }}</span>
            <app-icon *ngIf="element[key].icon" [icon]="element[key].icon"></app-icon>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #notObject>
        <ng-container *ngIf="showTooltip && tooltipKey === key; else noTooltip">
          <div>
            {{
              element[tooltipKey].length > charactersBeforeTooltip!
                ? element[tooltipKey].substr(0, charactersBeforeTooltip) + '...'
                : element[key]
            }}
          </div>
        </ng-container>
        <ng-template #noTooltip>
          <div>
            {{ '' + element[key] | truncate: [10, '...', element.noTruncate] }}
          </div>
        </ng-template>
      </ng-template>
    </td>
  </ng-container>
  <tr class="header" mat-header-row *matHeaderRowDef="displayedColumnKeys"></tr>
  <tr
    class="row"
    [ngClass]="{ selectable: selectable, disabled: row.disabled }"
    (click)="rowSelected(row)"
    mat-row
    *matRowDef="let row; columns: displayedColumnKeys"
  ></tr>
</table>
