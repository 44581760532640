import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { BreakPointChange, UserExperienceService } from '@app/core/user-experience/user-experience.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appSideIf]',
})
export class SideIfDirective implements OnInit, OnChanges, OnDestroy {
  /**
   * Index of the sideDrawer currently visible
   */
  @Input() public appSideIf!: number;
  /**
   * The index of this sideDrawer
   */
  @Input() public appSideIfIndex!: number;
  /**
   * zIndex of this sideDrawer. Defaults to 1000 + this.sideDrawerIndex
   */
  @Input() public appSideIfZIndex!: number;
  /**
   * The side this sideDrawer opens from
   */
  @Input() public appSideIfSide: 'left' | 'right' = 'right';
  /**
   * Minumum width of the sidedrawer
   */
  @Input() public appSideIfMinWidth = SideDrawerConfig.widthAsOpened;

  private element: any;
  private CLOSED_STATE!: string;
  private OPEN_STATE = 'translateY(0%)';
  private transition = 'transform .2s ease-out';
  private fullWidth!: boolean;
  private subs: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private ux: UserExperienceService
  ) {}

  public ngOnInit(): void {
    this.subs = [
      ...this.subs,
      this.ux
        .getBreakpointsStream()
        .pipe(debounceTime(50))
        .subscribe((c: BreakPointChange) => this.setDrawerWidth(c)),
    ];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (changes.appSideIf) {
      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      if (changes.appSideIf.firstChange) {
        this.initalize();
      }

      // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      if (changes.appSideIf.currentValue === this.appSideIfIndex) {
        // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        if (this.appSideIfIndex < changes.appSideIf.previousValue) {
          this.enter(false);
        } else {
          this.enter();
        }

        // @ts-ignore - TS4111 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      } else if (changes.appSideIf.currentValue > this.appSideIfIndex) {
        this.leave(true);
      } else {
        this.leave();
      }
    }
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private initalize() {
    this.appSideIfIndex = this.appSideIfIndex === undefined ? 0 : this.appSideIfIndex;
    this.appSideIfZIndex = this.appSideIfZIndex === undefined ? 1000 + this.appSideIfIndex : this.appSideIfZIndex;
    this.CLOSED_STATE = this.appSideIfSide === 'right' ? 'translateX(100%)' : 'translateX(-100%)';
  }

  private enter(animate = true) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    const ref = this.templateRef.elementRef.nativeElement;
    this.element = ref.previousSibling;
    if (this.element) {
      this.setElementStyles(this.element, animate);
      if (animate) {
        setTimeout(() => {
          this.element.style.transform = this.OPEN_STATE;
        });
      }
    }
  }

  private leave(hangAround = false) {
    if (this.element) {
      this.element.style.transform = hangAround ? this.OPEN_STATE : this.CLOSED_STATE;
      setTimeout(() => {
        this.viewContainer.clear();
      }, 250); // Wait for animation to finish
    }
  }

  private setElementStyles(e: any, animate: boolean) {
    e.style.height = '100%';
    e.style.width = this.fullWidth ? '100%' : SideDrawerConfig.widthAsOpened;
    e.style.left = this.fullWidth ? '0' : '';
    e.style.minWidth = this.appSideIfMinWidth;
    e.style.position = 'absolute';
    e.style.transition = this.transition;
    e.style.zIndex = this.appSideIfZIndex;
    e.style.transform = animate ? this.CLOSED_STATE : this.OPEN_STATE;
  }

  private setDrawerWidth(w: BreakPointChange): void {
    this.fullWidth = w.breakpointStr === 'XS';
    if (this.element) {
      this.setElementStyles(this.element, false);
    }
  }
}
