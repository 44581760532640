<form [formGroup]="form">
  <mat-card appearance="outlined">
    <mat-card-title
      >{{ 'main.asApplied.metaData.informationAboutFieldTask' | translate }}
      <app-remove-button *ngIf="!isEditMode" (clicked)="removeChildClicked()"></app-remove-button>
    </mat-card-title>
    <mat-card-content>
      <div class="input-container">
        <!--   POINT TOGGLE   -->
        <div class="toggle-container">
          <span>{{ 'main.asApplied.metaData.showPoints' | translate }}</span>
          <mat-slide-toggle color="primary" formControlName="showPoints" [labelPosition]="'before'" (change)="togglePoints()">{{
            (form.get('showPoints')?.value ? 'main.asApplied.metaData.visible' : 'main.asApplied.metaData.hidden') | translate
          }}</mat-slide-toggle>
        </div>

        <!--  FIELD NUMBER   -->
        <mat-form-field class="field-number-form-field">
          <mat-label>{{ 'main.asApplied.metaData.fieldNumber' | translate }}</mat-label>
          <input type="text" aria-label="Number" matInput [matAutocomplete]="auto" formControlName="fieldNumber" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let field of selectableFields$ | async" [value]="field.number">
              {{ field.number }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!--  Task   -->
        <ng-container
          *ngIf="selectableTasksGroupedByOperationTypeGroup$ | async as selectableTasksGroupedByOperationTypeGroup; else emptySelect"
        >
          <app-select
            formControlName="metadataTask"
            placeholder="{{
              (selectableTasksGroupedByOperationTypeGroup.length === 0
                ? 'main.asApplied.metaData.noTaskOnField'
                : 'main.asApplied.metaData.fieldTask'
              ) | translate
            }}"
          >
            <app-select-option-group
              *ngFor="let operationTypeGroupTasks of selectableTasksGroupedByOperationTypeGroup"
              [label]="operationTypeGroupTasks.operationTypeGroupName"
            >
              <app-select-option *ngFor="let selectableTask of operationTypeGroupTasks.tasks" [value]="selectableTask">
                <!--   Show tooltip if the text is more than 35 characters   -->
                <app-tooltip
                  *ngIf="selectableTask.text.length > 35; else noTooltip"
                  [tooltipText]="selectableTask.text"
                  [delayType]="'long'"
                >
                  {{ selectableTask.text }}
                </app-tooltip>
                <ng-template #noTooltip>
                  {{ selectableTask.text }}
                </ng-template>
              </app-select-option>
            </app-select-option-group>
          </app-select>
        </ng-container>

        <ng-template #emptySelect>
          <app-select [disabled]="true" placeholder="{{ 'main.asApplied.metaData.fieldTask' | translate }}"></app-select>
        </ng-template>

        <!-- UNIT -->
        <mat-form-field>
          <mat-label>{{ 'main.asApplied.metaData.unit' | translate }}</mat-label>
          <mat-select formControlName="unit" placeholder="{{ 'main.asApplied.metaData.unit' | translate }}">
            <mat-option *ngFor="let unit of this.units" [value]="unit">
              <div>{{ unit }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--  DATE  -->
        <mat-form-field [ngClass]="{ simple: true }">
          <mat-label>{{ 'main.asApplied.metaData.date' | translate }}</mat-label>
          <input
            aria-label="Date field"
            matInput
            [matDatepicker]="picker"
            [max]="maxDate"
            (click)="picker.open()"
            autocomplete="off"
            formControlName="appliedDate"
          />
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>
