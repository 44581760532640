<div class="transfer-fields-content">
  <app-spinner
    [message]="(isLoading$ | async) ? ('main.datamanagement.fieldTransfer.transferingFields' | translate) : ''"
    *ngIf="(isLoading$ | async) || (isFetching | async)"
    [hasBackdrop]="true"
  >
  </app-spinner>

  <app-harvest-year-header>{{ 'main.datamanagement.fieldTransfer.title' | translate }}</app-harvest-year-header>

  <div class="table-wrapper">
    <mat-table class="transfer-table" [dataSource]="dataSource">
      <ng-container cdkColumnDef="farmName">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.farm' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.farmId | farmName | async }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="number">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.fieldNumber' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.number }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="name">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.fieldName' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.name }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="area">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.area' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.area | unit: 'ha' : 2 }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="mainCropName">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.crop' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.mainCropName }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="status">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.datamanagement.fieldTransfer.status' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row | transferStatus: externalFields }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="transfer">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.datamanagement.fieldTransfer.transfer' | translate }} </mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <app-square-button *ngIf="isTransferable(row)" (clicked)="onTransferClick(row)">
            <app-icon icon="cloud_upload"></app-icon>
          </app-square-button>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator #paginator [length]="this.fields.length" [pageIndex]="0" [pageSize]="25" [pageSizeOptions]="[5, 25, 100]">
    </mat-paginator>
  </div>

  <p class="no-dataconnections-error" *ngIf="shouldShowNoConnectionsMsg$ | async">{{ 'common.noDataConnection' | translate }}</p>

  <div class="transfer-all-button-wrapper">
    <app-square-button [buttonType]="'Primary'" (click)="transferAll()">
      <app-icon icon="cloud_upload"></app-icon>
      {{ 'main.datamanagement.fieldTransfer.transferAll' | translate }}
    </app-square-button>
  </div>
</div>
