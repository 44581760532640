<div class="main-wrapper">
  <ng-container *ngIf="filterItems$ | async as filterItems">
    <div class="filter-chip remove-all" *ngIf="filterItems.length" (click)="onRemoveAll()">
      <div class="text-container">{{ 'filter.removeAllFilters' | translate }}</div>
    </div>
    <div class="filter-chip" *ngFor="let filterItem of filterItems">
      <ng-container *ngIf="hasCustomTemplate(filterItem.columnKey); else stringTitle">
        <ng-container
          *ngTemplateOutlet="customTemplates.get(filterItem.columnKey)!; context: { $implicit: filterItem.value }"
        ></ng-container>
      </ng-container>
      <ng-template #stringTitle>
        <div class="text-container">{{ filterItem.title }}</div>
      </ng-template>
      <app-icon class="remove-icon" icon="Luk" (click)="onRemove(filterItem)"></app-icon>
    </div>
  </ng-container>
</div>
