import { NormDtoForFarm } from '@app/core/interfaces/norm-dto-for-farm';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { CropNormDTO } from '@app/core/repositories/crops/crop-norm.dto';
import { DateTime } from 'luxon';
import { Type } from 'ol/geom/Geometry';
import { AsAppliedProductDto } from './as-applied-product-dto.interface';
import { MetadataGeometry } from './file-upload/metadata-parent';

export interface ExecutedLocationCollectionDto {
  executedTaskId: number;
  uploadDate: string;
  fileName: string;
  uploadBy: string;
  executedTask: ExecutedTaskDto;
  geometryType: string;
  containedAttributes: ContainedAttributesDto[];
  locations: LocationsDto[];
}

export interface ExecutedTaskDto {
  id: number;
  farmId: number;
  date: string;
  fieldName: string;
  cropNormNumber: number;
  products: AsAppliedProductDto[];
  featureId: number;
  taskId: number;
  operationTypeGroupId: number;
  operationTypeGroup: string;
  fieldYearId: number;
}

export interface ContainedAttributesDto {
  normNumber: number;
  name: string;
  unit: string;
}

export interface LocationsDto {
  geometry: string;
  value: number;
  logTime: DateTime;
}

export class ExecutedLocationCollection {
  public executedTaskId: number;
  public uploadDate: DateTime;
  public fileName: string;
  public uploadBy: string;
  public executedTask: ExecutedTask;
  public geometryType!: Type;
  public containedAttributes: ContainedAttributes[];
  public locations: MetadataGeometry[];

  constructor(
    dto: ExecutedLocationCollectionDto,
    produceNormsForFarms: NormDtoForFarm<ProduceNorm[]>[],
    cropNormsForFarms: NormDtoForFarm<CropNormDTO[]>[]
  ) {
    this.executedTaskId = dto.executedTaskId;
    this.uploadDate = DateTime.fromISO(dto.uploadDate).setZone('utc');
    this.fileName = dto.fileName;
    this.uploadBy = dto.uploadBy;
    this.executedTask = new ExecutedTask(dto.executedTask, produceNormsForFarms, cropNormsForFarms);
    switch (dto.geometryType.toString()) {
      case 'Point':
        this.geometryType = 'Point';
        break;
      case 'Polygon':
        this.geometryType = 'Polygon';
        break;
    }
    this.containedAttributes = dto.containedAttributes.map((containedAttribute) => new ContainedAttributes(containedAttribute));
    this.locations = dto.locations.map((location) => new LocationDtoFromMetadataGeometry(location));
  }
}

export class ExecutedTask {
  public id: number;
  public farmId: number;
  public date: DateTime;
  public fieldName: string;
  public cropNormNumber: number;
  public cropName;
  public products: AsAppliedProduct[];
  public featureId: number;
  public taskId: number;
  public operationTypeGroupId: number;
  public operationTypeGroup: string;
  public fieldYearId: number;

  constructor(
    dto: ExecutedTaskDto,
    produceNormsForFarms: NormDtoForFarm<ProduceNorm[]>[],
    cropNormsForFarms: NormDtoForFarm<CropNormDTO[]>[]
  ) {
    this.id = dto.id;
    this.farmId = dto.farmId;
    this.date = DateTime.fromISO(dto.date);
    this.fieldName = dto.fieldName;
    this.cropNormNumber = dto.cropNormNumber;
    this.products = dto.products.map((product) => {
      return new AsAppliedProduct(product, dto.farmId, produceNormsForFarms);
    });
    this.featureId = dto.featureId;
    this.taskId = dto.taskId;
    this.operationTypeGroupId = dto.operationTypeGroupId;
    this.operationTypeGroup = dto.operationTypeGroup;

    const cropNormForFarm = cropNormsForFarms.find((cropNormsForFarm) => cropNormsForFarm.farmId === dto.farmId);
    const normDto = cropNormForFarm?.normDtos.find((norm) => norm.number === dto.cropNormNumber);
    this.cropName = normDto?.name;

    this.fieldYearId = dto.fieldYearId;
  }
}

export class ContainedAttributes {
  public normNumber!: number;
  public name!: string;
  public unit!: string;

  constructor(dto: ContainedAttributesDto) {
    // if normNumber is 0 or not defined it is because backend return unknown
    if (!dto.normNumber || dto.normNumber === 0) {
      return;
    }

    this.normNumber = dto.normNumber;
    this.name = dto.name;
    this.unit = dto.unit;
  }
}

export class AsAppliedProduct {
  public produceNormNumber: number;
  public quantity: number;
  public productName?: string;
  public unit: string;

  constructor(dto: AsAppliedProductDto, farmId: number, produceNormsForFarms: NormDtoForFarm<ProduceNorm[]>[]) {
    this.produceNormNumber = dto.produceNormNumber;
    this.quantity = dto.quantity;

    const produceNormForFarm = produceNormsForFarms.find((produceNormsForFarm) => produceNormsForFarm.farmId === farmId);
    const produceNorm = produceNormForFarm?.normDtos.find(
      (produceNormsForFarm) => produceNormsForFarm.number === dto.produceNormNumber.toString()
    );
    this.productName = produceNorm?.name;
    this.unit = dto.unit;
  }
}

export class LocationDtoFromMetadataGeometry {
  public geometry: string;
  public quantity: number;
  public type!: Type;
  public color?: string;
  public time?: DateTime;

  constructor(dto: LocationsDto) {
    const typeFromGeometry = dto.geometry.split(' ')[0];

    this.geometry = dto.geometry;
    this.quantity = dto.value;
    this.time = dto?.logTime;

    switch (typeFromGeometry) {
      case 'POINT':
        this.type = 'Point';
        break;
      case 'POLYGON':
        this.type = 'Polygon';
        break;
    }
  }
}
