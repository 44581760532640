<div class="card">
  <div class="description">{{ 'main.asApplied.description' | translate }}</div>
</div>
<div class="card">
  <!--  AS APPLIED GROUPS   -->
  <ng-container *ngIf="asAppliedTaskGroups">
    <ng-container *ngFor="let asAppliedGroup of asAppliedTaskGroups">
      <app-as-applied-task-accordion
        [id]="'e2e-{{asAppliedGroup.id}}'"
        [data]="asAppliedGroup.tasks"
        [noDataMessage]="'main.asApplied.noTasksForCategory'"
        [sortFn]="sortTaskFn"
        [tableColumns]="asAppliedGroupTableColumns"
        [tableStyles]="tableStyles"
        [title]="asAppliedGroup.name"
        [initSortFn]="taskInitSortFn"
        (onItemSelect)="onTaskSelect($event)"
      >
      </app-as-applied-task-accordion>
    </ng-container>
  </ng-container>

  <!--  UNSPECIFIED GROUPS  -->
  <ng-container *ngIf="unspecifiedTaskGroup">
    <app-as-applied-task-accordion
      [id]="'e2e-{{unspecifiedTaskGroup.name}}'"
      [data]="unspecifiedTaskGroup.tasks"
      [noDataMessage]="'main.asApplied.noTasksForCategory'"
      [sortFn]="sortTaskFn"
      [tableColumns]="unspecifiedGroupTableColumn"
      [tableStyles]="tableStyles"
      [title]="unspecifiedTaskGroup.name"
      [initSortFn]="taskInitSortFn"
      (onItemSelect)="onTaskSelect($event)"
    >
    </app-as-applied-task-accordion>
  </ng-container>
</div>
