import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HarvestYearExtrasComponent,
  HarvestYearHeaderComponent,
  HarvestYearTitleComponent,
} from '@app/shared/harvest-year/harvest-year-header/harvest-year-header.component';
import { HarvestYearPickerMapComponent } from '@app/shared/harvest-year/harvest-year-picker-map/harvest-year-picker-map.component';
import { SelectModule } from '@app/shared/select/select.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { HarvestYearPickerMapService } from './harvest-year-picker-map/harvest-year-picker-map.service';
import { MaterialModule } from '../material/material.module';
import { HarvestYearPickerComponent } from './harvest-year-picker/harvest-year-picker.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, SelectModule, MaterialModule],
  providers: [HarvestYearPickerMapService],
  declarations: [
    HarvestYearPickerMapComponent,
    HarvestYearHeaderComponent,
    HarvestYearTitleComponent,
    HarvestYearExtrasComponent,
    HarvestYearPickerComponent,
  ],
  exports: [
    HarvestYearPickerMapComponent,
    HarvestYearHeaderComponent,
    HarvestYearTitleComponent,
    HarvestYearExtrasComponent,
    HarvestYearPickerComponent,
  ],
})
export class HarvestYearModule {}
