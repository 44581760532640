import { Injectable } from '@angular/core';
import { latest } from '@app/shared/constants/rxjs-constants';
import { emissionTickDelay } from '@app/shared/operators';
import { filterEmpty } from '@app/shared/operators/filter-empty';
import { createStore, select, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  getActiveId,
  selectActiveEntity,
  selectAllEntities,
  selectManyByPredicate,
  setActiveId,
  setEntities,
  updateEntities,
  updateEntitiesByPredicate,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import _ from 'lodash';
import { combineLatest, filter, first, map, shareReplay, tap, withLatestFrom } from 'rxjs';
import { CUSTOM_LEVELS, MAX_CUSTOM_LEVELS } from '../domain/custom-level';
import { CustomLevel } from '../interfaces/custom-level.interface';
import { PrescriptionMapCell } from '../interfaces/prescription-map.interface';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

interface CellUpdate {
  quantity?: PrescriptionMapCell['quantity'];
  color?: PrescriptionMapCell['color'];
}

interface ValidProps {
  valid: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CellRepository {
  constructor(private query: PrescriptionMapQuery) {}

  private readonly _cellStore = createStore({ name: 'prescription-map-cells' }, withEntities<PrescriptionMapCell>());
  private readonly _customLevelStore = createStore(
    { name: 'prescription-map-custom-levels' },
    withEntities<CustomLevel, 'id'>({ idKey: 'id' }),
    withProps<ValidProps>({ valid: true }),
    withActiveId()
  );

  private readonly _cellHistory = entitiesStateHistory(this._cellStore);
  private readonly _cellStoreHistory = stateHistory(this._cellStore);
  private readonly _customLevelHistory = entitiesStateHistory(this._customLevelStore);
  private readonly _customLevelStoreHistory = stateHistory(this._customLevelStore);

  private readonly _init$ = this.query.prescriptionMapsByActiveTask$.pipe(
    filterEmpty(),
    map((maps) => maps.flatMap((map) => map.cells)),
    map((cells) => cells.map((cell) => ({ ...cell, quantityOrig: cell.quantity }))),
    tap((cells) => this._cellStore.update(setEntities(cells))),
    map((cells) =>
      _(cells)
        .filter((cell) => cell.customLevel)
        .uniqBy('color')
        .map<CustomLevel>((x) => ({
          id: this.generateUniqueId(),
          color: x.color!,
          quantity: x.quantity,
        }))
        .value()
    ),
    tap((customLevels) => this._customLevelStore.update(setEntities(customLevels), setActiveId(customLevels[0]?.id))),
    tap(() => this.setActiveCustomLevel(null)),
    tap(() => {
      this.clearHistory();
    }),
    shareReplay(latest)
  );

  public readonly cells$ = this._cellStore.pipe(selectAllEntities(), shareReplay(latest));

  public readonly loading$ = this.query.loading$;

  public readonly customCells$ = this._cellStore.pipe(
    selectManyByPredicate((entity) => entity.customLevel === true),
    shareReplay(latest)
  );

  public readonly customLevels$ = this._customLevelStore.pipe(selectAllEntities(), shareReplay(latest));

  public readonly activeCustomLevel$ = this._customLevelStore.pipe(selectActiveEntity(), shareReplay(latest));

  public readonly hasPast$ = this._cellStoreHistory.hasPast$.pipe(shareReplay(latest));

  public readonly hasFuture$ = this._cellStoreHistory.hasFuture$.pipe(shareReplay(latest));

  public readonly customLevelHasPast$ = this._customLevelStoreHistory.hasPast$.pipe(shareReplay(latest));

  public readonly hasHistory$ = combineLatest([this.hasPast$, this.hasFuture$, this.customLevelHasPast$]).pipe(
    emissionTickDelay(),
    map(([hasPast, hasFuture, customLevelHasPast]) => hasPast || hasFuture || customLevelHasPast),
    shareReplay(latest)
  );

  public readonly isValid$ = this._customLevelStore.pipe(
    select(({ valid }) => valid),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public updateCells(ids: PrescriptionMapCell['id'][], value: CellUpdate) {
    this._cellStore.update(updateEntities(ids, (entity) => ({ ...entity, ...value, customLevel: true })));
    this._cellStoreHistory.clear((state) => ({ ...state, future: [] }));
    this._cellHistory.clearFuture();
  }

  public resetCells(ids: PrescriptionMapCell['id'][]) {
    this._cellStore.update(updateEntities(ids, (cell) => ({ ...cell, color: undefined, quantity: cell.quantityOrig, customLevel: false })));
    this._cellStoreHistory.clear((state) => ({ ...state, future: [] }));
    this._cellHistory.clearFuture();
  }

  public resetCellsByActiveColor(ids?: PrescriptionMapCell['id'][], color?: CustomLevel['color']) {
    this.cells$
      .pipe(
        first(),
        map((cells) => cells.filter((cell) => (ids ? ids.includes(cell.id) : cell))),
        withLatestFrom(this.activeCustomLevel$),
        map(([cells, activeLevel]) => cells.filter((cell) => cell.color === (color ? color : activeLevel?.color))),
        tap((filteredCells) => {
          const filteredIds = filteredCells.map((cell) => cell.id);
          this._cellStore.update(
            updateEntities(filteredIds, (cell) => ({ ...cell, color: undefined, quantity: cell.quantityOrig, customLevel: false }))
          );
          this._cellHistory.clearFuture();
          this._cellStoreHistory.clear((state) => ({ ...state, future: [] }));
        })
      )
      .subscribe();
  }

  public updateCustomLevel(id: CustomLevel['id'], color: CustomLevel['color'], quantity: CustomLevel['quantity']) {
    const oldColor = this._customLevelStore.value.entities[id]?.color;

    // Only update if color or quantity is different
    if (oldColor === color && this._customLevelStore.value.entities[id]?.quantity === quantity) return;

    this._customLevelStore.update(updateEntities(id, (entity) => ({ ...entity, color, quantity })));

    this._cellStore.update(
      updateEntitiesByPredicate(
        (entity) => oldColor === entity.color,
        (entity) => ({ ...entity, color, quantity })
      )
    );
  }

  public setActiveCustomLevel(id: CustomLevel['id'] | null) {
    this.activeCustomLevel$
      .pipe(
        first(),
        tap((activeLevel) => {
          if (activeLevel?.id === id) this._customLevelStore.update(setActiveId(null));
          else this._customLevelStore.update(setActiveId(id));
        })
      )
      .subscribe();
  }

  public addCustomLevel() {
    this._customLevelStore
      .pipe(
        selectAllEntities(),
        first(),
        map(
          (customLevels) =>
            // find first available color
            CUSTOM_LEVELS.find((newColor) => !customLevels.some((customLevel) => customLevel.color === newColor)) ??
            // or use last available color
            CUSTOM_LEVELS[MAX_CUSTOM_LEVELS - 1]
        ),
        withLatestFrom(this.query.statistics$),
        tap(([color, { avg }]) => {
          const id = this.generateUniqueId();
          this._customLevelStore.update(addEntities([{ id: id, color, quantity: Math.round(avg) }]), setActiveId(id));
        })
      )
      .subscribe();
  }

  public removeCustomLevel(color: CustomLevel['color'] | null | undefined, id: CustomLevel['id'] | null | undefined) {
    this._customLevelStore
      .pipe(
        selectAllEntities(),
        first(),
        tap(() => color && this.resetCellsByActiveColor(undefined, color)),
        tap(() => id && this._customLevelStore.update(deleteEntities(id))),
        // only update active custom level if the deleted one was active
        filter(() => this._customLevelStore.query(getActiveId) === id),
        map((customLevels) => customLevels[customLevels.length - 2] as CustomLevel | undefined),
        tap((newActiveCustomLevel) => this._customLevelStore.update(setActiveId(newActiveCustomLevel?.id)))
      )
      .subscribe();
  }

  public updateValidity(valid: boolean) {
    this._customLevelStore.update((state) => ({ ...state, valid }));
  }

  public undo() {
    this._cellStoreHistory.undo();
  }

  public redo() {
    this._cellStoreHistory.redo();
  }

  public resetHistory() {
    this._cellHistory.jumpToPast(0);
    this._customLevelStoreHistory.jumpToPast(0);
    this._customLevelHistory.jumpToPast(0);
    this.clearHistory();
  }

  public clearHistory() {
    this._cellHistory.clearPast();
    this._cellHistory.clearFuture();
    this._customLevelHistory.clearPast();
    this._customLevelHistory.clearFuture();

    this._cellStoreHistory.clear((state) => ({ ...state, past: [], future: [] }));
    this._customLevelStoreHistory.clear((state) => ({ ...state, past: [], future: [] }));
  }

  public reset() {
    this._cellStore.reset();
    this._customLevelStore.reset();
    this.clearHistory();
  }

  private generateUniqueId() {
    const storeValue = this._customLevelStore.getValue();
    // first generate a random string
    let idStr = (Math.floor(Math.random() * 10000000) + 1).toString();
    // if it's already used, generate another one
    while (storeValue.entities[idStr]) {
      idStr = (Math.floor(Math.random() * 10000000) + 1).toString();
    }
    return idStr;
  }
}
