import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CreateHotspotComponent } from './create-hotspot.component';
import { CreateHotspotService } from './create-hotspot.service';

@NgModule({
  imports: [SharedModule],
  providers: [CreateHotspotService],
  declarations: [CreateHotspotComponent],
  exports: [CreateHotspotComponent],
})
export class CreateHotspotModule {}
