import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DroneImageInformation, DroneImageSet } from '@app/core/interfaces/drone-image-information';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DroneImageInformationDialogComponent } from './information-dialog/information-dialog.component';

@Component({
  selector: 'app-drone-image-information',
  templateUrl: './drone-image-information.component.html',
  styleUrls: ['./drone-image-information.component.scss'],
})
export class DroneImageInformationComponent implements OnInit, OnDestroy {
  @Input() public droneImage$!: Observable<DroneImageInformation>;

  @Input() public fieldName$!: Observable<string | null>;
  @Output() public onDroneImageDateChange = new EventEmitter<DroneImageSet>();

  public selectedIndex!: number;
  private subscriptions = new Subscription();

  constructor(private dialogService: DialogService) {}

  public ngOnInit() {
    /*
    ? ngOnInit in the component causes an emit of a new value to material snackbar in some cases.
    ? the snackbar has been resolved and shown in the app before the component is instantiated causing ExpressionChangedAfterItHasBeenCheckedError.
    ? For now only a timeout can resolve this issue without enabling prod mode.
    */

    setTimeout(() => {
      this.subscriptions.add(
        this.droneImage$.pipe(filter((droneImages) => !!droneImages)).subscribe((droneImageInformation) => {
          if (droneImageInformation.pictures.length > 0) {
            this.onImageDateClick(droneImageInformation.pictures[0], 0);
          }
        })
      );
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onDroneImageInfoClick(event: Event) {
    this.dialogService.openMd(DroneImageInformationDialogComponent);
    event.stopPropagation();
  }

  public onImageDateClick(droneImageDate: DroneImageSet, index: number) {
    this.selectedIndex = index;
    this.onDroneImageDateChange.emit(droneImageDate);
  }
}
