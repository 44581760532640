<app-side-drawer-wrapper>
  <app-side-drawer-header
    cancelIconName="back"
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
    [showMinimizeButton]="true"
    title="{{ 'main.yieldPrognosis.title' | translate }}"
    ><div></div
  ></app-side-drawer-header>

  <app-side-drawer-body>
    <div class="details-content">
      <div>
        <app-expected-yield [yieldPrognosis]="(yieldPrognosis$ | async)!"></app-expected-yield>
      </div>
    </div>
  </app-side-drawer-body>
</app-side-drawer-wrapper>

<app-ol-map-feature-select [selectedFeatures]="(selectedFeatures$ | async)!"></app-ol-map-feature-select>

<app-legend [hidden]="hideLegend$ | async" [sideDrawerWidth]="sideDrawerWidth">
  <app-scale-legend [settings]="(legend$ | async)!"></app-scale-legend>
</app-legend>
