export const INSURANCE_PRICES = {
  winterWheat: {
    green: 98,
    yellow: 110,
    red: 124,
  } as insurancePrice,
  winterRye: {
    green: 107,
    yellow: 119,
    red: 1098,
  } as insurancePrice,
};

export interface insurancePrice {
  green: number;
  yellow: number;
  red: number;
}
