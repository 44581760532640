import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { BlightLegendComponent } from './blight-legend/blight-legend.component';
import { BlightStateService } from './blight-state.service';
import { PotatoBlightComponent } from './potato-blight.component';
import { BlightDatePickerComponent } from './sidedrawer/blight-date-picker/blight-date-picker.component';
import { BlightFieldInfoComponent } from './sidedrawer/blight-field-info/blight-field-info.component';
import { BlightRegistrationComponent } from './sidedrawer/blight-registration/blight-registration.component';
import { BlightInfoComponent } from './blight-info/blight-info.component';

@NgModule({
  imports: [CommonModule, SharedModule, DateInputsModule],
  declarations: [
    PotatoBlightComponent,
    BlightDatePickerComponent,
    BlightLegendComponent,
    BlightFieldInfoComponent,
    BlightRegistrationComponent,
    BlightInfoComponent,
  ],
  providers: [BlightStateService],
  exports: [PotatoBlightComponent],
})
export class PotatoBlightModule {}
