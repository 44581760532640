import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-soil-sample-radio-label',
  templateUrl: './soil-sample-radio-label.component.html',
  styleUrls: ['./soil-sample-radio-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoilSampleRadioLabelComponent {
  @Input() public measurements = 0;
  @Input() public text = '';
  @Input() public checked = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public change = new EventEmitter<boolean>();
}
