import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputErrorComponent } from '@app/shared/input/input-error/input-error.component';
import { InputComponent } from '@app/shared/input/input.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { NumbersInputComponent } from './numbers-input/numbers-input.component';
import { NumbersOnlyDirective } from './numbers-input/numbers-only.directive';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  declarations: [InputComponent, InputErrorComponent, NumbersInputComponent, NumbersOnlyDirective],
  exports: [InputComponent, InputErrorComponent, NumbersInputComponent, NumbersOnlyDirective],
})
export class InputModule {}
