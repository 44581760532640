<div class="info-box">
  <div class="first">
    <span class="info-line"></span>
    <p class="info-text">
      {{ 'main.benchmark.overview.averageYieldInfo' | translate }} <b>{{ regionalAverage | number: '1.1-1' }} {{ unit }}</b
      >, {{ 'main.benchmark.overview.averagePersonalYieldInfo' | translate }} <b>{{ personalAverage | number }} {{ unit }}</b
      >.
      {{ 'main.benchmark.overview.averageYieldCountInfo' | translate }}
      <b>{{ fields | number }} {{ 'common.fields' | translate }}</b
      >{{ 'main.benchmark.overview.averageYieldAreaInfo' | translate }}
      <b> {{ area | number: '1.0-0' }} {{ 'common.hectareShort' | translate }}</b>
    </p>
  </div>
</div>
