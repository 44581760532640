<app-side-drawer-header
  [title]="
    !(selectedBasisLayer$ | async)
      ? ('main.basis-layer.category.title-create' | translate)
      : ('main.basis-layer.category.title-edit' | translate) + (selectedBasisLayer$ | async)?.category?.name
  "
  (action)="onCloseClick()"
  (onHide)="onHideClick()"
  cancelIconName="back"
  [showMinimizeButton]="true"
>
</app-side-drawer-header>
<app-side-drawer-body>
  <app-spinner *ngIf="loading$ | async" [hasBackdrop]="true"></app-spinner>

  <div>
    <mat-card appearance="outlined" *ngIf="selectedFarms && selectedFarms.length > 1" class="task-card">
      <mat-card-content>
        <form novalidate [formGroup]="farmSelectorForm">
          <app-select
            formControlName="farmId"
            [placeholder]="'main.fieldmap.hotspotInfo.farm' | translate"
            (ngModelChange)="OnFarmSelectionChange($event)"
          >
            <app-select-option *ngFor="let farm of selectedFarms; trackBy: trackByFn" [value]="farm.id">
              {{ farm.name }}
            </app-select-option>
          </app-select>
        </form>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="task-card">
      <mat-card-content>
        <div class="info-header">
          <p>{{ 'main.basis-layer.category.info' | translate }}</p>
        </div>
        <div class="action-buttons">
          <app-square-button id="e2e-redraw-basis-layer" (clicked)="onEditClick()" buttonType="Secondary">
            <span class="mat-button-wrapper">{{ 'main.basis-layer.category.redraw-btn-action' | translate }}</span>
          </app-square-button>
        </div>
        <div class="corrections">
          <div class="correction-heading">
            <div>{{ 'main.basis-layer.category.correction.category-header' | translate }}</div>
            <div class="push">{{ 'main.basis-layer.category.correction.adjustment-header' | translate }}</div>
          </div>
          <div class="correction-body">
            <form [formGroup]="basislayerForm">
              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.seeding' | translate }}</div>

                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="seeding" id="seeding" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('seeding')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('seeding')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>

              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.fertilization' | translate }}</div>
                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="fertilization" id="fertilization" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('fertilization')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('fertilization')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>

              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.weed' | translate }}</div>
                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="weed" id="weed" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('weed')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('weed')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>

              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.pest' | translate }}</div>
                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="pest" id="pest" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('pest')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('pest')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>

              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.disease' | translate }}</div>
                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="disease" id="disease" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('disease')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('disease')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>

              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.growthregulation' | translate }}</div>
                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="growthregulation" id="growthregulation" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('growthregulation')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('growthregulation')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>

              <div class="correction-item">
                <div>{{ 'main.basis-layer.category.correction.lime' | translate }}</div>
                <div class="push">
                  <app-numbers-input [maxLength]="4" formControlName="limeadjustment" id="limeadjustment" class="areal-input">
                    <app-input-error *ngIf="!basislayerForm.get('limeadjustment')?.valid"></app-input-error>
                  </app-numbers-input>
                  <div [ngClass]="basislayerForm.get('limeadjustment')?.valid ? '' : 'error-color'">%</div>
                </div>
              </div>
            </form>
            <div *ngIf="!basislayerForm.valid" class="error-color">
              <p>{{ 'main.basis-layer.category.valid-values-1' | translate }}</p>
              <p>{{ 'main.basis-layer.category.valid-values-2' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="action-buttons" *ngIf="!!(selectedBasisLayer$ | async)">
          <app-square-button
            class="delete-btn"
            (clicked)="onDeleteClick()"
            id="e2e-delete-basis-layer"
            [disabled]="(loading$ | async) ?? false"
          >
            <span class="mat-button-wrapper">{{ 'main.basis-layer.category.remove-btn-action' | translate }}</span>
          </app-square-button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-side-drawer-body>
<app-side-drawer-footer>
  <app-side-drawer-footer-button [disabled]="(loading$ | async) ?? false" (clicked)="onSaveClick()">{{
    'common.save' | translate
  }}</app-side-drawer-footer-button>
</app-side-drawer-footer>
