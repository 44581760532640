<div class="content">
  <div>
    <div>
      <h2 class="header">{{ 'main.fieldAdministration.createField.areYouSureDeleteTaskForCropHeader' | translate }}</h2>
      <div class="description">
        <p>{{ translateDescription | async }}</p>
        <p>{{ 'main.fieldAdministration.createField.areYouSureDeleteTaskForCrop2' | translate }}</p>
        <p>{{ 'main.fieldAdministration.createField.areYouSureDeleteTaskForCrop3' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="buttons">
    <app-square-button [buttonStyle]="{ padding: '0 2.5em' }" [buttonType]="'Primary'" (clicked)="onConfirmClicked($event)">
      {{ 'main.fieldAdministration.createField.yesDelete' | translate }}
    </app-square-button>
    <app-square-button [buttonStyle]="{ padding: '0 2.5em' }" [buttonType]="'Secondary'" (clicked)="onCancelClicked($event)">
      {{ 'main.fieldAdministration.createField.cancel' | translate }}
    </app-square-button>
  </div>
</div>
