<h3 class="legend-title">{{ 'main.potatoBlight.legend.title' | translate }}</h3>

<div class="legend-wrapper">
  <div class="legend-cell">
    <div class="legend-color" [ngStyle]="{ 'background-color': '#4F734A' }"></div>
    <div class="legend-value">{{ 'main.potatoBlight.legend.minimalPressure' | translate: { value: '0' } }}</div>
  </div>
  <div class="legend-cell">
    <div class="legend-color" [ngStyle]="{ 'background-color': '#F9E067' }"></div>
    <div class="legend-value">{{ 'main.potatoBlight.legend.lowPressure' | translate: { value: '1-19' } }}</div>
  </div>
  <div class="legend-cell">
    <div class="legend-color" [ngStyle]="{ 'background-color': '#FC995C' }"></div>
    <div class="legend-value">{{ 'main.potatoBlight.legend.mediumPressure' | translate: { value: '20-39' } }}</div>
  </div>
  <div class="legend-cell">
    <div class="legend-color" [ngStyle]="{ 'background-color': '#F2464A' }"></div>
    <div class="legend-value">{{ 'main.potatoBlight.legend.highPressure' | translate: { value: '40+' } }}</div>
  </div>
  <div class="legend-cell">
    <div class="legend-color" [ngStyle]="{ 'background-color': '#ffffff' }">3</div>
    <div class="legend-value">{{ 'main.potatoBlight.legend.daysFromSpraying' | translate }}</div>
  </div>
  <div class="legend-cell">
    <div class="legend-color" [ngStyle]="{ 'background-color': '#999999' }">{{ 'main.yieldPrognosis.blightMarker' | translate }}</div>
    <div class="legend-value">{{ 'main.potatoBlight.legend.registeredMold' | translate }}</div>
  </div>
</div>
