import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';

const BUFFER_PADDING = 2500;
export class ExtentUtil {
  public static isValidExtend(extent: Coordinate) {
    if (!extent || extent.length < 2) {
      // extent is null, undefined, or does not have 2 or more elements
      return false;
    }

    // check if each element in extent is a finite number
    for (const coordinate of extent) {
      if (!Number.isFinite(coordinate)) {
        // one of the elements is not a finite number
        return false;
      }
    }

    // all checks passed; extent is valid
    return true;
  }

  public static expandExtent(extent: Extent, padding: number): Extent {
    extent[0] -= padding; // minX - padding
    extent[1] -= padding; // minY - padding
    extent[2] += padding; // maxX + padding
    extent[3] += padding; // maxY + padding

    return extent;
  }
}
