import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SearchFieldComponent } from '@app/shared/search-field/search-field.component';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';

@NgModule({
  declarations: [SearchFieldComponent],
  exports: [SearchFieldComponent],
  imports: [MatInputModule, FormsModule, ReactiveFormsModule, TranslateModule, IconModule],
})
export class SearchFieldModule {}
