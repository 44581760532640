import { filter } from 'rxjs/operators';

export class DirtyCheckDialogAction {
  public static confirmed = () => filter<DirtyCheckDialogAction | undefined>((action) => !!action && action.isConfirmed);
  public static cancelled = () => filter<DirtyCheckDialogAction | undefined>((action) => !!action && action.isCancelled);

  public readonly isConfirmed: boolean;
  public readonly isCancelled: boolean;

  constructor(confirm: boolean) {
    this.isConfirmed = confirm;
    this.isCancelled = !confirm;
  }
}
