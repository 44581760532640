import { Injectable } from '@angular/core';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsValidatorService {
  public isCropManagerBasic$ = this.allFarmsHasSubscription$('PlanteITCropManagerBasic');

  public isCropManagerPremium$ = this.allFarmsHasSubscription$('PlanteITCropManagerPremium');

  public isCropManagerBasicOrPremium$ = combineLatest([this.isCropManagerBasic$, this.isCropManagerPremium$]).pipe(
    map(([basicSubscriber, premiumSubscriber]) => basicSubscriber || premiumSubscriber)
  );

  public isAlertsPackSubscriber$ = this.allFarmsHasSubscription$('PlanteITCropmanagerVarsling');

  public isSoilSample$ = this.isCropManagerBasicOrPremium$;

  public isGrowthRegulation$ = this.isCropManagerBasicOrPremium$;

  public isPotatoBlight$ = this.isCropManagerBasicOrPremium$;

  public isFree$ = this.isCropManagerBasicOrPremium$.pipe(map((isCropManagerBasicOrPremium) => !isCropManagerBasicOrPremium));

  public isVraPlantProtection$ = combineLatest([
    this.allFarmsHasSubscription$('PlanteITVraPlantProtection'),
    this.allFarmsHasSubscription$('PlanteITCropmanagerTildelingslag'),
  ]).pipe(map(([vraPlantProtectionSubscriber, vraBundleSubscriper]) => vraPlantProtectionSubscriber || vraBundleSubscriper));

  public isVraFertilizer$ = combineLatest([
    this.allFarmsHasSubscription$('PlanteITVraFertilizer'),
    this.allFarmsHasSubscription$('PlanteITCropmanagerTildelingslag'),
  ]).pipe(map(([vraFertilizerSubscriber, vraBundleSubscriber]) => vraFertilizerSubscriber || vraBundleSubscriber));

  public isVraSeed$ = combineLatest([
    this.allFarmsHasSubscription$('PlanteITVraSeed'),
    this.allFarmsHasSubscription$('PlanteITCropmanagerTildelingslag'),
  ]).pipe(map(([vraSeedSubscriber, vraBundleSubscriber]) => vraSeedSubscriber || vraBundleSubscriber));

  public isVraBundle$ = this.allFarmsHasSubscription$('PlanteITCropmanagerTildelingslag');

  constructor(private farmStateService: FarmStateService) {}

  public isVraSubscriber$ = combineLatest([this.isVraPlantProtection$, this.isVraFertilizer$, this.isVraSeed$, this.isVraBundle$]).pipe(
    map(
      ([vraPlantProtectionSubscriber, vraFertilizerSubscriber, vraSeedSubscriber, vraBundleSubscriber]) =>
        vraPlantProtectionSubscriber || vraFertilizerSubscriber || vraSeedSubscriber || vraBundleSubscriber
    )
  );

  private allFarmsHasSubscription$(subscriptionText: string) {
    return this.farmStateService.selectedFarms$.pipe(
      switchMap((farms) =>
        this.farmStateService.farmsAreSelected$.pipe(
          filterNullish(),
          map(() => farms) // Is this redundant?
        )
      ),
      map((farms) => {
        return farms.every((farm) => farm.subscriptions.includes(subscriptionText));
      })
    );
  }
}
