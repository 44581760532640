import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { FieldSelectorModule } from './field-selector/field-selector.module';
import { OperationSelectorComponent } from './operation-selector/operation-selector.component';
import { OperationLineComponent } from './operation/operation-line/operation-line.component';
import { OperationComponent } from './operation/operation.component';
import { TaskFieldsComponent } from './task-fields/task-fields.component';
import { TaskComponent } from './task.component';

@NgModule({
  imports: [FieldSelectorModule, SharedModule, RouterModule],
  declarations: [TaskComponent, TaskFieldsComponent, OperationComponent, OperationLineComponent, OperationSelectorComponent],
  exports: [TaskComponent],
})
export class TaskModule {}
