import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { FieldService } from '@app/core/field/field.service';
import { CornField } from '@app/core/interfaces/corn-field.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { FeatureUtil } from '@app/new-map/helpers/utils/feature-util';
import { WKTUtil } from '@app/new-map/helpers/utils/WKT-util';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import Feature from 'ol/Feature';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CornHarvestPrognosisRepoService } from '../repository/corn-harvest-prognosis-repo.service';

@Injectable()
export class CornPrognosisService {
  private cornFieldsCache = this.cacheService.create<CornField[]>({ defaultTtl: 10000 });
  private _cornFieldsWithColor = new BehaviorSubject<CornField[]>([]);
  private _cornFields: CornField[] = [];

  public readonly cornFieldsWithColor$ = this._cornFieldsWithColor.asObservable();

  constructor(
    private cornPrognosisRepo: CornHarvestPrognosisRepoService,
    private cacheService: CacheService,
    private fieldService: FieldService
  ) {}

  public getCornPrognosisFieldFeatures(fields: CornField[]) {
    const fieldFeatures: Feature[] = fields
      .filter((field) => !!field.polygon)
      .map((field) => {
        const feat = FeatureUtil.createPolygonFeature(LayerId.CORN_PROGNOSIS, WKTUtil.getPolygonCoordinatesFromWKTString(field.polygon)!);
        feat.setProperties({
          layerId: LayerId.CORN_PROGNOSIS,
          field,
          text: `${field.number}`,
        });

        return feat;
      });
    return fieldFeatures;
  }

  public getCornFields(farms: Farm[], harvestYear: number): Observable<CornField[]> {
    // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return forkJoin(farms.map((farm) => this.getCornFieldsWithCache(farm.id, harvestYear))).pipe(map((item) => [].concat(...item)));
  }

  private getCornFieldsWithCache(farmId: number, harvestYear: number): Observable<CornField[]> {
    const key = `${farmId}-${harvestYear}`;

    return this.cornFieldsCache.getOrSetAsync(key, () => this.cornPrognosisRepo.getCrops(farmId, harvestYear));
  }

  public getCornHarvestPrognosis(cornField: CornField) {
    return this.cornPrognosisRepo.getPrognosis(cornField.farmId, cornField.harvestYear, cornField.fieldId);
  }

  public resetCornFieldsOnMap() {
    this._cornFields = [];
    this._cornFieldsWithColor.next(this._cornFields);
  }

  public setCornFieldToUpdateMap(cornField: CornField) {
    this.fieldService
      .getField(cornField.farmId, cornField.harvestYear, cornField.id)
      .pipe(first())
      .subscribe((field) => {
        cornField.polygon = field.geometry ? field.geometry : '';
        this._cornFields.push(cornField);
        this._cornFieldsWithColor.next(this._cornFields);
      });
  }
}
