import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapPdfService } from '@app/map/map-pdf.service';
import { filterNullOrEmpty } from '@app/shared/operators';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { BehaviorSubject, first, tap } from 'rxjs';
import { OlMapService } from './map-service/ol-map.service';
import { OlMapComponent } from './ol-map/ol-map.component';
import { LayerId } from './services/layer/layer.store';

@Component({
  selector: 'app-new-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class NewMapComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) public map!: OlMapComponent;

  protected canvasReady$ = this.olMapService.canvasReady$;
  protected isMapReadySubject = new BehaviorSubject<boolean>(false);
  public isCreatingPDF$ = this.mapPdfService.isCreatingPDF$;

  private _subs = new SubscriptionArray();

  constructor(
    protected hostElement: ElementRef,
    private farmStateService: FarmStateService,
    private olMapService: OlMapService,
    private mapPdfService: MapPdfService
  ) {}
  ngOnInit(): void {
    this.olMapService.init();

    this.olMapService.mapReady$.pipe(first()).subscribe(() => {
      setTimeout(() => {
        this.isMapReadySubject.next(true);

        this._subs.add(
          this.farmStateService.fieldFeatures$
            .pipe(
              filterNullOrEmpty(),
              tap((fieldFeatures) => {
                if (!this.olMapService.getLayerFromMap(LayerId.FIELDS)) {
                  this.olMapService.fitMapToFieldFeatures(fieldFeatures);
                }
                this.olMapService.addFieldsToMap(fieldFeatures, LayerId.FIELDS, false);
              })
            )
            .subscribe()
        );
      });
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this.olMapService.cleanUp();
  }
}
