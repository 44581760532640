import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';
import { EconomyColumnDataSource, FieldScore, FieldScoreResponse } from '../interface/coarsefodder';
import { CoarseFodderColumn } from './../interface/coarsefodder';

@Injectable({
  providedIn: 'root',
})
export class CoarsefodderRepoService {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getCoarsefodderFields(farmIds: number[], harvestYear: number): Observable<CoarseFodderColumn[]> {
    return this.http.get<CoarseFodderColumn[]>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/coarsefoddereconomy`);
  }

  public getCoarsefodderDataSources(farmIds: number[], harvestYear: number): Observable<EconomyColumnDataSource[]> {
    return this.http.get<EconomyColumnDataSource[]>(
      `${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/coarsefoddereconomy/datasources`
    );
  }

  public saveCoarseFodderColumn(
    farmId: number,
    harvestYear: number,
    coarseFodderColumn: CoarseFodderColumn
  ): Observable<CoarseFodderColumn | null> {
    return this.http.put<CoarseFodderColumn, CoarseFodderColumn>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/coarsefoddereconomy/${coarseFodderColumn.id}`,
      coarseFodderColumn
    );
  }

  public getFieldScore(farmId: number, harvestYear: number, fieldId: number): Observable<FieldScoreResponse> {
    return this.http.get<FieldScoreResponse>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldId}/coarsefodderfieldscore`
    );
  }

  public saveFieldScore(farmId: number, harvestYear: number, fieldscore: FieldScore): Observable<FieldScore | null> {
    return this.http.put<FieldScore, FieldScore>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldscore.fieldYearId}/coarsefodderfieldscore`,
      fieldscore
    );
  }

  public deleteFieldScore(farmId: number, harvestYear: number, fieldId: number): Observable<any> {
    return this.http.delete<any>(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldId}/coarsefodderfieldscore`);
  }
}
