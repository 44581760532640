import { Component, OnDestroy, OnInit } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { PrognosisShownComponent } from '../../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisSideDrawerComponent } from '../../prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisService } from '../../prognosis.service';
import { GrowthRegulationService } from '../growth-regulation.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Component({
  selector: 'app-insurance-side-drawer',
  templateUrl: './insurance-side-drawer.component.html',
  styleUrls: ['./insurance-side-drawer.component.scss'],
})
export class InsuranceSideDrawerComponent implements OnInit, OnDestroy {
  public growthRegulationVm$ = this.growthRegulationService.growthRegulationVm$;
  public sideDrawerWidth$ = this.prognosisService.drawerWidth$;
  public isLegendVisible$ = this.screenSizeService.isMobile().pipe(
    map((isMobile) => {
      return isMobile ? isMobile && this.sideDrawerRef.hidden : true;
    })
  );

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public selectedFarm: Farm = null;
  private subscription = new Subscription();

  constructor(
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private growthRegulationService: GrowthRegulationService,
    private farmStateService: FarmStateService,
    private screenSizeService: ScreenSizeService
  ) {}

  public ngOnInit() {
    this.subscription = this.farmStateService.fieldFeatures$.pipe(skip(1)).subscribe((features) => {
      this.prognosisService.setShownComponent(PrognosisShownComponent.GrowthRegulation);
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onHide() {
    this.sideDrawerRef.hide();
  }

  public onClose() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.GrowthRegulation);
  }

  public onFarmSelect(farm: Farm) {
    this.selectedFarm = farm;
  }

  public onProceedToOrderClick() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.GrowthRegulationInsuranceCheckout);
  }
}
