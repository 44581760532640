import { animate, style, transition, trigger } from '@angular/animations';

const DEFAULT_DELAY = 150;

export type EasingFunctions = 'ease';
export const INITIAL = { opacity: 0 };
export const EVENTUAL = { opacity: 1 };

export function fadeInNgIf(name: string = 'fadeInNgIf', time: number = DEFAULT_DELAY, func: EasingFunctions = 'ease') {
  return trigger(name, [
    transition(':enter', [style({ opacity: 0 }), animate(`${time}ms ${func}`, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(`${time}ms ${func}`, style({ opacity: 0 }))]),
  ]);
}

export function fadeInOut(fadeInDelay: number = DEFAULT_DELAY, fadeOutDelay: number = DEFAULT_DELAY) {
  return trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate(fadeInDelay, style({ opacity: 1 }))]),
    transition(':leave', [animate(fadeOutDelay, style({ opacity: 0 }))]),
  ]);
}
