import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackSideDrawerService {
  private drawerWidthSubject = new ReplaySubject<string>(1);
  public drawerWidth$: Observable<string>;

  constructor() {
    this.drawerWidth$ = this.drawerWidthSubject.asObservable();
  }

  public set drawerWidth(width: string) {
    this.drawerWidthSubject.next(width);
  }
}
