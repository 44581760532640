import { Component, OnInit } from '@angular/core';
import { ConsentStateService } from '@app/core/consent/consent-state.service';
import { IWindow } from '@app/core/interfaces/window.interface';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { Consent, ConsentTypes } from '@app/core/repositories/consent/consent.interface';
import { WindowRefService } from '@app/core/window/window-ref.service';

@Component({
  selector: 'app-no-consent',
  templateUrl: './no-consent.component.html',
  styleUrls: ['./no-consent.component.scss'],
})
export class NoConsentComponent implements OnInit {
  public consents!: Consent[];
  private window: IWindow;
  public consentTypes = ConsentTypes;

  constructor(
    windowRefService: WindowRefService,
    private queryParamService: QueryParamService,
    private consentStateService: ConsentStateService
  ) {
    this.window = windowRefService.nativeWindow;
  }

  public ngOnInit() {
    this.consentStateService.consents$.subscribe((consents: Consent[]) => {
      this.onConsentReady(consents);
    });
  }

  public onConsentReady(consents: Consent[]): void {
    this.consents = consents;
  }

  public onGetConsentClicked(decisionUrl: string): void {
    this.queryParamService.getQueryParamNullable('returnUrl', false).subscribe((returnUrl) => {
      this.window.location.href = this.getConsentBaseUrl(decisionUrl, encodeURIComponent(returnUrl));
    });
  }
  private getConsentBaseUrl(decisionUrl: string, returnUrl: string): string {
    return decisionUrl.replace('{0}', returnUrl);
  }
}
