import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { ChartWrapperModule } from '@app/shared/chart-wrapper/chart-wrapper.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { WetHoursChartComponent } from '@app/shared/wet-hours-chart/wet-hours-chart.component';
import { WetHoursChartService } from '@app/shared/wet-hours-chart/wet-hours-chart.service';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ChartModule } from '@progress/kendo-angular-charts';
import { AppPipesModule } from '../pipes/pipes.module';
import { WetHoursChartInfoComponent } from './wet-hours-chart-info/wet-hours-chart-info.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ChartModule, ChartWrapperModule, ButtonsModule, AppPipesModule, MatDialogModule, IconModule],
  declarations: [WetHoursChartComponent, WetHoursChartInfoComponent],
  providers: [WetHoursChartService],
  exports: [WetHoursChartComponent],
})
export class WetHoursChartModule {}
