import { HttpErrorResponse } from '@angular/common/http';
import { BenchmarkDataService } from '@app/core/benchmark-data/benchmark-data.service';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { Farm } from '@app/core/interfaces/farm.interface';
import { ProduceNormsStorage } from '@app/core/interfaces/produce-norms-storage.interface';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { ProduceNormsRepo } from '@app/core/repositories/produce-norms/produce-norms-repo.service';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export function getProduceNorms(
  year: number,
  benchmarkDataService: BenchmarkDataService,
  produceNormsRepo: ProduceNormsRepo,
  notificationService: NotificationService,
  messageService: MessageService,
  farms: Farm[]
): Observable<ProduceNormsStorage[]> {
  benchmarkDataService.produceNormsStorage = [];

  const requests = farms.map((farm) => produceNormsRepo.get(farm.id, year, [OperationTypeGroupEnum.Harvest]));

  return forkJoin(requests).pipe(
    map((x) => x.flat()),
    tap({
      error: (error: HttpErrorResponse) =>
        notificationService.showError(messageService.getHttpStatusMsg(error.status, messageService.getProduceNormsMsg().getError)),
    })
  );
}
