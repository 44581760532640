import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExtraModuleDialogModule } from '@app/core/extra-module-dialog/extra-module-dialog.module';
import { AppLayoutComponent } from '@app/layouts/app-layout/app-layout.component';
import { BlankLayoutComponent } from '@app/layouts/blank-layout/blank-layout.component';
import { FooterModule } from '@app/layouts/footer/footer.module';
import { HeaderModule } from '@app/layouts/header/header.module';
import { NavigationModule } from '@app/layouts/navigation/navigation.module';
import { SideNavToggleButtonComponent } from '@app/layouts/side-nav-toggle-button/side-nav-toggle-button.component';
import { SharedModule } from '@app/shared/shared.module';
import { AppLayoutService } from '../core/layout/app-layout.service';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';

@NgModule({
  imports: [SharedModule, RouterModule, NavigationModule, HeaderModule, FooterModule, ExtraModuleDialogModule],
  declarations: [BlankLayoutComponent, AppLayoutComponent, SideNavToggleButtonComponent, LoadingScreenComponent],
  providers: [AppLayoutService],
})
export class LayoutsModule {}
