import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { BasisLayerDto } from '@app/core/interfaces/basis-layer/basis-layer-dto.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasisLayerRepoService {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  /**
   * @param farmIds
   * @param categoryIds Optional list of categoryIds to retrieve basis layers that belong to specified categories
   */
  public get(farmIds: number[], categoryIds: number[] = []): Observable<BasisLayerDto[]> {
    return this.http.get<BasisLayerDto[]>(`${this.endpoints.bffApi}/farms/${farmIds}/basislayers`, { params: { categoryIds } });
  }

  public post(basisLayers: BasisLayerDto[], farmId: number, harvestYear?: number): Observable<BasisLayerDto[] | null> {
    return this.http.post<BasisLayerDto[], BasisLayerDto[]>(
      `${this.endpoints.bffApi}/farms/${farmId}/${harvestYear}/basislayers`,
      basisLayers
    );
  }

  /**
   * @param basisLayers
   * @param farmId
   * @param harvestYear
   * @param ids Ids for existing entities that will be overwritten
   */
  public put(basisLayers: BasisLayerDto[], farmId: number, harvestYear: number, ids?: number[]): Observable<BasisLayerDto[] | null> {
    const updatedIds = ids ?? basisLayers.map((b) => b.id);

    return this.http.put<BasisLayerDto[], BasisLayerDto[]>(
      `${this.endpoints.bffApi}/farms/${farmId}/${harvestYear}/basislayers/${updatedIds}`,
      basisLayers
    );
  }

  /**
   * @param basisLayerIds Ids to be deleted
   * @param farmId
   */
  public delete(basisLayerIds: number[], farmId: number): Observable<void | null> {
    return this.http.delete(`${this.endpoints.bffApi}/farms/${farmId}/basislayers/${basisLayerIds}`);
  }

  public validate(basisLayers: BasisLayerDto[], farmId: number, harvestYear?: number): Observable<void | null> {
    return this.http.post<void, BasisLayerDto[]>(
      `${this.endpoints.bffApi}/farms/${farmId}/${harvestYear}/basislayers/validate`,
      basisLayers
    );
  }
}
