import { NgModule } from '@angular/core';
import { IsTime24hourFormatDirective } from '@app/shared/directives/validation/dateTime/is-time-24hour-format.directive';
import { IsTimeMinuteFormatDirective } from '@app/shared/directives/validation/dateTime/is-time-minute-format.directive';
import { IsIntegerDirective } from '@app/shared/directives/validation/value/is-integer.directive';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { HideMissingDirective } from './hide-missing/hide-missing.directive';
import { InCellEditingTabNavigationDirective } from './kendo/grid/inCellEditingTabNavigation.directive';
import { NewFlagDirective } from './new-flag/new-flag.directive';
import { NumbersLengthDirective } from './number-only-length/numbers-only-length.directive';
import { OnlyNumbersDirective } from './numbers-only/numbers-only.directive';
import { ResponsiveClassDirective } from './responsive-class/responsive-class.directive';
import { ShortcutKeyDirective } from './shortcut-key/shortcut-key.directive';
import { SideIfDirective } from './side-if/side-if.directive';

@NgModule({
  declarations: [
    IsTime24hourFormatDirective,
    IsTimeMinuteFormatDirective,
    ShortcutKeyDirective,
    IsIntegerDirective,
    SideIfDirective,
    NewFlagDirective,
    InCellEditingTabNavigationDirective,
    OnlyNumbersDirective,
    NumbersLengthDirective,
    HideMissingDirective,
    ResponsiveClassDirective,
  ],
  imports: [IconModule],
  exports: [
    ShortcutKeyDirective,
    SideIfDirective,
    NewFlagDirective,
    InCellEditingTabNavigationDirective,
    OnlyNumbersDirective,
    NumbersLengthDirective,
    HideMissingDirective,
    ResponsiveClassDirective,
  ],
})
export class AppDirectiveModule {}
