import { NotificationRange } from '@app/core/interfaces/notification-range.interface';

export class NotificationRanges {
  // TODO get dynamically from server
  public static readonly ranges: NotificationRange[] = [
    {
      id: null,
      distance: undefined,
      unit: '',
    },
    {
      id: 1,
      distance: 10,
      unit: 'm',
    },
    {
      id: 3,
      distance: 100,
      unit: 'm',
    },
  ];
}
