import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Field } from '@app/core/interfaces/field.interface';

@Component({
  selector: 'app-water-balance-dialog',
  templateUrl: './water-balance-dialog.component.html',
  styleUrls: ['./water-balance-dialog.component.scss'],
})
export class WaterBalanceDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WaterBalanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Field
  ) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
