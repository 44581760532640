import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '@app/core/notification/notification.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { LoadingState } from '@app/helpers/loading-state';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { PrognosisShownComponent } from '../../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisSideDrawerComponent } from '../../prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisService } from '../../prognosis.service';
import { GrowthRegulationFieldTableVM, GrowthRegulationTableField } from '../growth-regulation-field-table-vm.interface';
import { GrowthRegulationVM } from '../growth-regulation-vm.class';
import { GrowthRegulationService } from '../growth-regulation.service';

@Component({
  selector: 'app-check-out-side-drawer',
  templateUrl: './check-out-side-drawer.component.html',
  styleUrls: ['./check-out-side-drawer.component.scss'],
})
export class CheckOutSideDrawerComponent implements OnInit, OnDestroy {
  public growthRegulationVm$: Observable<GrowthRegulationVM> = this.growthRegulationService.growthRegulationVm$;
  public growthRegulationFieldTableVM!: GrowthRegulationFieldTableVM[];
  public sideDrawerWidth$ = this.prognosisService.drawerWidth$;
  public isOrderEnabled$!: Observable<boolean>;
  public savingState = new LoadingState();
  public isLegendVisible$ = this.screenSizeService.isMobile().pipe(
    map((isMobile) => {
      return isMobile ? isMobile && this.sideDrawerRef.hidden : true;
    })
  );

  private subscription = new Subscription();

  private orderForm!: UntypedFormGroup;

  constructor(
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private growthRegulationService: GrowthRegulationService,
    private screenSizeService: ScreenSizeService,
    private harvestYearStateService: HarvestYearStateService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit() {
    this.growthRegulationFieldTableVM = this.growthRegulationService.growthRegulationFieldTableVM;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public onHide() {
    this.sideDrawerRef.hide();
  }

  public onClose() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.GrowthRegulationInsurance);
  }

  public onInfoChange(form: UntypedFormGroup) {
    this.orderForm = form;
  }

  public onBuyClick() {
    const fields = this.growthRegulationFieldTableVM
      .reduce((prev: GrowthRegulationTableField[], curr) => [...prev, ...curr.table.fields], [])
      .filter((field) => field.selected && !field.registered);

    if (!fields.length) {
      this.notificationService.showError('main.growthRegulation.insurance.noFieldsSelected');
      return;
    }

    if (!this.orderForm || this.orderForm.invalid) {
      this.notificationService.showError('main.growthRegulation.insurance.checkOutForm.invalid');
      return;
    }

    this.savingState.start('main.growthRegulation.insurance.processingOrder');
    this.harvestYearStateService.harvestYear$
      .pipe(
        take(1),
        switchMap((harvestYear) => {
          // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          return this.growthRegulationService.requestInsurance(this.orderForm.value, fields, harvestYear);
        }),
        finalize(() => this.savingState.stop())
      )
      .subscribe((result) => {
        this.notificationService.showSuccess('main.growthRegulation.insurance.orderSuccess');
        this.onClose();
      });
  }
}
