import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Region } from '@app/core/interfaces/map-region-feature.interface';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { Observable, Subject, Subscription } from 'rxjs';

export interface BenchmarkFilters {
  soilTypes: SoilTypes;
  regions: Region[] | null;
  crop: Crop | null;
}

export interface SoilTypes {
  coarse: boolean;
  fine: boolean;
  clay: boolean;
}

@Component({
  selector: 'app-benchmark-chart-filter',
  templateUrl: './chart-filter.component.html',
  styleUrls: ['./chart-filter.component.scss'],
})
export class ChartFilterComponent implements OnInit, OnDestroy {
  public readonly selectedRegions$: Observable<Region[]> = this.benchmarkStateService.selectedRegions$;
  @Output() public filterChange = new EventEmitter<BenchmarkFilters>();
  public crop$ = new Subject<Crop>();
  public soilTypes$ = new Subject<{ isSelected: boolean; type: string }>();

  public filters: BenchmarkFilters = {
    crop: null,
    regions: null,
    soilTypes: {
      clay: true,
      coarse: true,
      fine: true,
    },
  };

  private subscriptions: Subscription[] = [];

  constructor(private benchmarkStateService: BenchmarkStateService) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.crop$.subscribe((crop) => this.onCropChange(crop)),
      this.selectedRegions$.subscribe((regions) => this.onRegionChange(regions)),
      this.soilTypes$.subscribe((soilType) => this.onSoilSelect(soilType))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public onCropChange(crop) {
    this.filters.crop = crop;
    this.filterChange.emit(this.filters);
  }

  public onSoilSelect(selection: { isSelected: boolean; type: string }) {
    // @ts-ignore - TS7053 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.filters.soilTypes[selection.type] = selection.isSelected;
    this.filterChange.emit(this.filters);
  }

  public onRegionChange(regions: Region[]) {
    this.filters.regions = regions;
    this.filterChange.emit(this.filters);
  }
}
