import { Injectable } from '@angular/core';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { ScaleLegendItem } from '@app/core/interfaces/scale-legend-item.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapStateService } from '@app/state/services/map/map-state.service';

@Injectable()
export class PrognosisActionsService {
  constructor(private mapStateService: MapStateService) {}

  public mapCoverflowVisbilityChanged(isVisible: boolean): void {
    this.mapStateService.mapCoverflowVisbilityChanged = isVisible;
  }

  public setMapCoverFlowItems(mapCoverFlowItems: MapCoverFlowItem[]) {
    return (this.mapStateService.mapCoverFlowItems = mapCoverFlowItems);
  }

  public selectedMapCoverFlowItemIdxChanged(idx: number) {
    return (this.mapStateService.selectedMapCoverFlowItemIdx = idx);
  }

  public setMapOptions(isLegendVisible: boolean, isSettingsVisible: boolean) {
    this.mapStateService.fieldInspectorMapOptions = {
      isLegendVisible: isLegendVisible,
      isSettingsVisible: isSettingsVisible,
    };
  }

  public setMapLoaded() {
    this.mapStateService.fieldMapInitialLoad = false;
  }

  public setScaleLegendTitle(scaleLegendTitle: string) {
    this.mapStateService.scaleLegendTitle = scaleLegendTitle;
  }

  public setScaleLegendItems(scaleLegendItems: ScaleLegendItem[]) {
    this.mapStateService.scaleLegendItems = scaleLegendItems;
  }

  public setScaleLegendNote(scaleLegendNote: string) {
    this.mapStateService.scaleLegendNote = scaleLegendNote;
  }

  public setPrognosisMapLegendCropColors(cropColors: CropColor[]) {
    this.mapStateService.fieldMapLegendCropColors = cropColors;
  }

  public setScaleLegendSummaryLines(scaleLegendSummaryLines: string[]) {
    this.mapStateService.scaleLegendSummaryLines = scaleLegendSummaryLines;
  }
}
