import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

export const YieldPrognosisStyles = {
  getFieldStyle: (zoom: number, feature: Feature) => {
    const color = feature.get('field')?.crops[0]?.cropColor;
    return color
      ? [
          new Style({
            stroke: new Stroke({
              color: '#FFF',
              width: 2,
            }),
            fill: new Fill({
              color: color,
            }),
            text: FieldStyles.getTextStyle(zoom, feature, false),
          }),
        ]
      : [];
  },
  getSelectedFieldStyle: (zoom: number, size: number, feature: Feature) => {
    const color = feature.get('field').crops[0].cropColor;
    return [
      new Style({
        stroke: new Stroke({
          color: '#FFF',
          width: 4,
        }),
        fill: new Fill({
          color: color,
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
      }),
    ];
  },
};
