import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CornHarvestPrognosisModule } from './corn-harvest-prognosis/corn-harvest-prognosis.module';
import { GrowthRegulationModule } from './growth-regulation/growth-regulation.module';
import { PotatoBlightModule } from './potato-blight/potato-blight.module';
import { PrognosisRoutingModule } from './prognosis-routing.module';
import { PrognosisPickerComponent } from './prognosis-side-drawer/prognosis-picker/prognosis-picker.component';
import { PrognosisSideDrawerComponent } from './prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisActionsService } from './prognosis.actions.service';
import { PrognosisComponent } from './prognosis.component';
import { PrognosisSelector } from './prognosis.selector';
import { YieldPrognosisModule } from './yield-prognosis/yield-prognosis.module';

@NgModule({
  imports: [
    SharedModule,
    PrognosisRoutingModule,
    CornHarvestPrognosisModule,
    GrowthRegulationModule,
    YieldPrognosisModule,
    PotatoBlightModule,
  ],
  declarations: [PrognosisComponent, PrognosisSideDrawerComponent, PrognosisPickerComponent],
  providers: [PrognosisSelector, PrognosisActionsService],
})
export class PrognosisModule {}
