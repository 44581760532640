<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  <p *ngFor="let text of data.descriptions">{{ text }}</p>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button data-testid="confirm-dialog-cancel" type="button" mat-stroked-button color="primary" [mat-dialog-close]="true">
    {{ 'common.confirmDialog.confirm' | translate }}
  </button>

  <button data-testid="confirm-dialog-confirm" type="button" mat-flat-button color="basic" [mat-dialog-close]="false">
    {{ 'common.confirmDialog.cancel' | translate }}
  </button>
</div>
