import { animate, style, transition, trigger } from '@angular/animations';

const ANIMATION_DELAY = 250;

// Requires trigger in html template. Example: [@contentVisibility]="BooleanVariableInComponent"
export const SIDE_DRAWER_ANIMATION_SETTING = [
  trigger('contentVisibility', [
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate(`${ANIMATION_DELAY}ms ease`, style({ transform: 'translateX(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0)' }),
      animate(`${ANIMATION_DELAY}ms ease`, style({ transform: 'translateX(100%)' })),
    ]),
  ]),
];
