<div class="dialog-header" id="e2e-economy-score-dialog-header">
  <h2 class="title">{{ 'main.economy.score-dialog.title' | translate }}</h2>
  <app-tooltip-overlay size="sm" [tooltipTemplate]="headerTooltip" (click)="$event.stopPropagation()">
    <app-icon icon="info_outline" class="header-info-hover"> </app-icon>
  </app-tooltip-overlay>
  <ng-template #headerTooltip>
    <div class="tooltip-wrapper">
      <h3>{{ 'main.economy.score-dialog.tooltip.header-title' | translate }}</h3>
      <p>{{ 'main.economy.score-dialog.tooltip.header-first-section' | translate }}</p>
      <p>{{ 'main.economy.score-dialog.tooltip.header-second-section' | translate }}</p>
    </div>
  </ng-template>
</div>

<app-dialog-content size="lg">
  <form [formGroup]="form">
    <p>{{ 'main.economy.score-dialog.text1' | translate }}</p>

    <!--  PRICE  -->
    <!-- <div class="price-row">
      <div class="empt"></div>
      <p>{{initialValue | number : '1.0-0'}} kr./ha</p>
    </div> -->

    <!--  FIELD NAME  -->
    <div class="field-name score-row">
      <label for="field-name">{{ 'main.economy.score-dialog.fieldLabel' | translate }}</label>
      <app-input id="field-name" class="no-arrow-number-input" formControlName="fieldName" [simple]="true"> </app-input>
      <div class="unit"></div>
      <div class="empt"></div>
    </div>

    <!--  FIELD AREA  -->
    <div class="field-area score-row">
      <label for="field-area">{{ 'main.economy.score-dialog.areaLabel' | translate }}</label>
      <app-input id="field-area" class="no-arrow-number-input" formControlName="fieldArea" [simple]="true"> </app-input>
      <div class="unit disabled-text">ha.</div>
      <app-tooltip-overlay size="sm" [tooltipTemplate]="areaTooltip" (click)="$event.stopPropagation()">
        <app-icon icon="info_outline" class="info-hover"> </app-icon>
      </app-tooltip-overlay>
      <ng-template #areaTooltip>
        <div class="tooltip-wrapper">
          <h3>{{ 'main.economy.score-dialog.tooltip.areaHeader' | translate }}</h3>
          <p>{{ 'main.economy.score-dialog.tooltip.area' | translate }}</p>
        </div>
      </ng-template>
    </div>

    <!--  FIELD DISTANCE  -->
    <div class="field-distance score-row">
      <label for="field-distance">{{ 'main.economy.score-dialog.distanceLabel' | translate }}</label>

      <app-input
        id="field-distance"
        class="no-arrow-number-input"
        formControlName="fieldDistance"
        [autocomplete]="false"
        [type]="'number'"
        (ngModelChange)="recalculateTotal()"
      >
        <app-input-error *ngIf="form.get('fieldDistance')?.hasError('required')"
          >{{ 'main.economy.score-dialog.validation.required' | translate }}
        </app-input-error>
        <app-input-error *ngIf="form.get('fieldDistance')?.hasError('min')"
          >{{ 'main.economy.score-dialog.validation.minDistance' | translate }}
        </app-input-error>
        <app-input-error *ngIf="form.get('fieldDistance')?.hasError('max')"
          >{{ 'main.economy.score-dialog.validation.maxDistance' | translate }}
        </app-input-error>
        <app-input-error *ngIf="form.get('fieldDistance')?.hasError('maxDigits')"
          >{{ 'main.economy.score-dialog.validation.maxDigits' | translate }}
        </app-input-error>
      </app-input>

      <div class="unit">km</div>
      <app-tooltip-overlay size="sm" [tooltipTemplate]="fieldDistanceTooltip" (click)="$event.stopPropagation()">
        <app-icon icon="info_outline" class="info-hover"> </app-icon>
      </app-tooltip-overlay>
      <ng-template #fieldDistanceTooltip>
        <div class="tooltip-wrapper">
          <h3>{{ 'main.economy.score-dialog.tooltip.fieldDistanceHeader' | translate }}</h3>
          <p>{{ 'main.economy.score-dialog.tooltip.fieldDistance' | translate }}</p>
        </div>
      </ng-template>
    </div>

    <!--  TRANSPORT COST  -->
    <div class="transport-costs score-row">
      <label for="transport-costs">{{ 'main.economy.score-dialog.transportCostsLabel' | translate }}</label>
      <app-input
        id="transport-costs"
        class="no-arrow-number-input"
        formControlName="transportationCosts"
        [type]="'number'"
        [autocomplete]="false"
        (ngModelChange)="recalculateTotal()"
      >
        <app-input-error *ngIf="form.get('transportationCosts')?.hasError('required')"
          >{{ 'main.economy.score-dialog.validation.required' | translate }}
        </app-input-error>
        <app-input-error *ngIf="form.get('transportationCosts')?.hasError('min')"
          >{{ 'main.economy.score-dialog.validation.minTransportation' | translate }}
        </app-input-error>
        <app-input-error *ngIf="form.get('transportationCosts')?.hasError('max')"
          >{{ 'main.economy.score-dialog.validation.maxTransportation' | translate }}
        </app-input-error>
        <app-input-error *ngIf="form.get('transportationCosts')?.hasError('maxDigits')"
          >{{ 'main.economy.score-dialog.validation.maxDigits' | translate }}
        </app-input-error>
      </app-input>

      <div class="unit">{{ 'main.economy.score-dialog.transportationCostUnit' | translate }}</div>
      <app-tooltip-overlay size="sm" [tooltipTemplate]="transportTooltip" (click)="$event.stopPropagation()">
        <app-icon icon="info_outline" class="info-hover"> </app-icon>
      </app-tooltip-overlay>
      <ng-template #transportTooltip>
        <div class="tooltip-wrapper">
          <h3>{{ 'main.economy.score-dialog.tooltip.transportHeader' | translate }}</h3>
          <p>{{ 'main.economy.score-dialog.tooltip.transport' | translate }}</p>
        </div>
      </ng-template>
    </div>

    <!--  CORRECTION  -->
    <div class="correction score-row">
      <label>{{ 'main.economy.score-dialog.correctionLabel' | translate }}</label>
      <span class="disabled-text">{{ 'main.economy.score-dialog.textCheap' | translate }}</span>
      <mat-slider [displayWith]="displayLabel" class="slider" color="accent" [max]="5" [min]="1" [step]="1" discrete
        ><input aria-label="score dialog slider" matSliderThumb formControlName="manualCorrection" (change)="recalculateTotal()" />
      </mat-slider>
      <span class="disabled-text">{{ 'main.economy.score-dialog.textExpensive' | translate }}</span>
      <app-tooltip-overlay size="sm" [tooltipTemplate]="correctionTooltip" (click)="$event.stopPropagation()">
        <app-icon icon="info_outline" class="info-hover"> </app-icon>
      </app-tooltip-overlay>
      <ng-template #correctionTooltip>
        <div class="tooltip-wrapper">
          <h3>{{ 'main.economy.score-dialog.tooltip.correctionHeader' | translate }}</h3>
          <p>{{ 'main.economy.score-dialog.tooltip.correction' | translate }}</p>
        </div>
      </ng-template>
    </div>
  </form>

  <!-- <p>{{'main.economy.score-dialog.text2' | translate}}</p> -->

  <!--  TOTAL  -->
  <!-- <div class="total-row">
    <div class="empt"></div>
    <p>{{totalValue | number : '1.0-0'}} kr./ha</p>
  </div> -->

  <div class="buttons" id="e2e-economy-score-dialog-confirm-button">
    <app-square-button class="confirm-btn" buttonType="Primary" (clicked)="onConfirmClick()">{{
      'main.economy.score-dialog.buttonAccept' | translate
    }}</app-square-button>
    <app-square-button buttonType="Secondary" (clicked)="onCancelClick()">{{
      'main.economy.score-dialog.buttonCancel' | translate
    }}</app-square-button>
  </div>
</app-dialog-content>
