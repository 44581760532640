import { Injectable, OnDestroy } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { MapConstants } from '@app/core/map/map.constants';
import { MapService } from '@app/core/map/map.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { ArrayHelper } from '@app/helpers/arrays/array-helpers';
import { MetadataChild, MetadataGeometry } from '@app/map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { FieldAnalysisSideDrawerService } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.service';
import { cloneDeep } from 'lodash-es';
import { Feature } from 'ol';
import { createEmpty, extend } from 'ol/extent';
import { WKT } from 'ol/format';
import { Type } from 'ol/geom/Geometry';
import { Layer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import VectorSource from 'ol/source/Vector';
import { first } from 'rxjs/operators';
import { AsAppliedService } from '../../as-applied.service';

@Injectable()
export class MetadataMapService implements OnDestroy {
  public airPhotoIsVisible$ = this.mapService.airPhotoIsVisible$;
  private _metadataLayers = new Map<number, Layer>();
  private readonly UNIQUE_LAYER_ID = 42069;
  private _fieldFeatures: Feature[] | null = null;

  // Map paddings
  private readonly SIDE_MAP_PADDING = 50;
  private readonly TOP_BOTTOM_MAP_PADDING = 100;
  private readonly SIDEDRAWER_MAP_PADDING = 400;
  // Takes the part of the map that is hidden behind the sidedrawer into account
  private readonly NON_MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDEDRAWER_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];
  private readonly MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];

  constructor(
    private mapService: MapService,
    private screenSizeService: ScreenSizeService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private asAppliedService: AsAppliedService
  ) {}

  public ngOnDestroy(): void {
    this.clearLayers();
  }

  public clearLayers() {
    this.map.removeLayerFromMap(MapLayerId.AS_APPLIED_POINTS);
    this.map.removeLayerFromMap(MapLayerId.AS_APPLIED);
    this.map.removeLayerFromMap(MapLayerId.DRONE_IMAGE_IMPORT);
    this._metadataLayers = new Map<number, Layer>();
  }

  public removeMetadataChildFromMap(indexId: number) {
    this.map.getMap().removeLayer(this._metadataLayers.get(indexId) as BaseLayer);
    this._metadataLayers.delete(indexId);
  }

  public toggleMetadataChildVisibility(indexId: number, visible: boolean) {
    this._metadataLayers.get(indexId)?.setVisible(visible);

    if (visible) {
      const extend = (this._metadataLayers.get(indexId)?.getSource() as VectorSource).getExtent();

      this.screenSizeService
        .isMobile()
        .pipe(first())
        .subscribe((isMobile) => {
          isMobile
            ? this.map.slideMapToExtent(extend, this.MOBILE_MAP_PADDING)
            : this.map.slideMapToExtent(extend, this.NON_MOBILE_MAP_PADDING);
        });
    }
  }

  public addMetadataChildrenToMap(metadataChildren: MetadataChild[], units?: string, visibleByDefault = true) {
    if (metadataChildren.length === 0 || metadataChildren.find((metadataChild) => metadataChild.geometries.length === 0)) {
      return;
    }

    metadataChildren.forEach((metadataChild, index) => {
      const layer = this.createLayerFromMetadataGeometries(metadataChild.geometries, units, visibleByDefault);
      this._metadataLayers.set(metadataChild.indexId ?? index, layer);
    });
    this.addOutlineToFieldsWithData(metadataChildren);
    this.slideToMetadataExtendAndShowLegend();
  }

  public addMetadataGeometriesToMap(metadataGeometries: MetadataGeometry[], unitType?: string) {
    if (!metadataGeometries?.length) {
      return;
    }

    const layer = this.createLayerFromMetadataGeometries(metadataGeometries, unitType);
    this.screenSizeService
      .isMobile()
      .pipe(first())
      .subscribe((isMobile) => {
        isMobile
          ? this.map.slideMapToExtent((layer.getSource() as VectorSource).getExtent(), this.MOBILE_MAP_PADDING)
          : this.map.slideMapToExtent((layer.getSource() as VectorSource).getExtent(), this.NON_MOBILE_MAP_PADDING);
        this.fieldAnalysisSideDrawerService.setShowLegendState(true);
        this.asAppliedService.loading(true, 'main.asApplied.loadingMapMessage');
      });

    this.airPhotoIsVisible$.subscribe((airPhotoIsVisible: boolean) => {
      this.asAppliedService.loading(true, 'main.asApplied.loadingMapMessage');
      if (airPhotoIsVisible === true) {
        this.asAppliedService.loading(false, 'main.asApplied.loadingMapMessage');
      }
    });
  }

  public addLocationsToMap(locations: MetadataGeometry[] | undefined, unitText?: string) {
    if (!locations) {
      return;
    }

    const layer = this.createLayerFromMetadataGeometries(locations, unitText);
    this._metadataLayers.set(this.UNIQUE_LAYER_ID, layer);
    this.slideToMetadataExtendAndShowLegend();
  }

  public getMapLayerId(geometryType: Type) {
    switch (geometryType) {
      case 'Point':
        return MapLayerId.AS_APPLIED_POINTS;
      case 'Polygon':
        return MapLayerId.AS_APPLIED;
      default:
        return;
    }
  }

  private get map() {
    return this.mapService.getMap();
  }

  private createLayerFromMetadataGeometries(metadataGeometries: MetadataGeometry[], unitText?: string, visibleByDefault = true): Layer {
    const wtkFeatures = this.getFeaturesFromGeometries(metadataGeometries, unitText);
    const mapLayerId = this.getMapLayerId(metadataGeometries[0]?.type);

    const asAppliedLayer = {
      layerId: mapLayerId,
      layerType: mapLayerId == MapLayerId.AS_APPLIED_POINTS ? MapLayerType.POINTS : MapLayerType.VECTOR,
      zIndex: 1,
      isVisible: visibleByDefault,
      size: 10,
    };
    this.map.removeLayerFromMap(MapLayerId.AS_APPLIED_POINTS);
    return this.map.addOrUpdateLayerToMapWithoutOverwrite(asAppliedLayer, wtkFeatures);
  }

  private getFeaturesFromGeometries(metadataGeometries: MetadataGeometry[], unitText?: string): Feature[] {
    return metadataGeometries.map((geom) => {
      const wkt = new WKT().readFeature(geom.geometry, {
        dataProjection: MapConstants.dataProjection,
        featureProjection: MapConstants.mapProjection,
      });
      wkt.set('layerId', this.getMapLayerId(geom.type));
      wkt.set('color', geom.color);
      wkt.set('quantity', geom.quantity);
      if (unitText) {
        wkt.set('unitText', unitText);
      }
      return wkt;
    });
  }

  private slideToMetadataExtendAndShowLegend() {
    let totalExtend = createEmpty();
    this._metadataLayers.forEach((layer) => {
      totalExtend = extend(totalExtend, (layer.getSource() as VectorSource).getExtent());
    });

    this.screenSizeService
      .isMobile()
      .pipe(first())
      .subscribe((isMobile) => {
        isMobile
          ? this.map.slideMapToExtent(totalExtend, this.MOBILE_MAP_PADDING)
          : this.map.slideMapToExtent(totalExtend, this.NON_MOBILE_MAP_PADDING);
        this.fieldAnalysisSideDrawerService.setShowLegendState(true);
      });
  }

  public addOutlineToFieldsWithData(metadataChildren: MetadataChild[]) {
    const uniqueFieldIds = ArrayHelper.unique(
      metadataChildren.reduce((previousValue: Number[], currentValue) => {
        if (currentValue.fieldYearId) {
          previousValue.push(currentValue.fieldYearId);
        }
        return previousValue;
      }, [])
    );
    const allFieldFeatures = this._fieldFeatures ?? this.map.getFeaturesFromLayer(MapLayerId.FIELDS);
    this._fieldFeatures = allFieldFeatures.map((fieldFeatue) => cloneDeep(fieldFeatue));
    const layer = {
      clustered: true,
      isVisible: true,
      layerId: MapLayerId.DRONE_IMAGE_IMPORT,
      layerType: MapLayerType.VECTOR,
      zIndex: 5,
    };

    const featuresWithData = allFieldFeatures.filter((feature) => uniqueFieldIds.includes(feature.get('field').id));

    featuresWithData.forEach((feature) => {
      feature.set('layerId', MapLayerId.DRONE_IMAGE_IMPORT);
    });
    const Featuresfields = featuresWithData.map((droneImageFeature) => cloneDeep(droneImageFeature));
    this.map.addOrUpdateLayerToMap(layer, Featuresfields);

    const fieldLayer = {
      clustered: true,
      isVisible: true,
      layerId: MapLayerId.FIELDS,
      layerType: MapLayerType.VECTOR,
      zIndex: 3,
    };

    const visiblefieldFeatures = allFieldFeatures.filter((feature) => !uniqueFieldIds.includes(feature.get('field').id));
    const fieldFeatures = visiblefieldFeatures.map((visiblefieldFeature) => cloneDeep(visiblefieldFeature));
    this.map.addOrUpdateLayerToMap(fieldLayer, fieldFeatures);
  }

  public resetFeaturesToFields() {
    if (this._fieldFeatures) {
      const layer = {
        clustered: true,
        isVisible: true,
        layerId: MapLayerId.FIELDS,
        layerType: MapLayerType.VECTOR,
        zIndex: 3,
      };
      const fields = this._fieldFeatures.map((droneImageFeature) => cloneDeep(droneImageFeature));

      fields.forEach((features) => {
        features.set('layerId', MapLayerId.FIELDS);
      });
      this._fieldFeatures = fields;

      this.map.addOrUpdateLayerToMap(layer, fields);
    }
  }
}
