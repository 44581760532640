<app-side-drawer-backdrop
  *ngIf="showBackdrop && isVisible()"
  (click)="close($event)"
  [ngStyle]="{ visibility: isContentVisible ? 'visible' : 'hidden' }"
  [class.transparent-backdrop]="backdropShouldBeTransparent()"
></app-side-drawer-backdrop>
<app-side-drawer-content
  *ngIf="isVisible()"
  [@contentVisibility]
  (@contentVisibility.done)="contentFinishedHiding($event)"
  [ngStyle]="{ width: width, 'z-index': this.zIndex }"
  [class.no-backdrop]="!showBackdrop"
>
  <ng-content class="toolbar" select="app-side-drawer-toolbar"></ng-content>
  <div class="side-drawer-template">
    <ng-template *ngIf="isVisible()" [ngTemplateOutlet]="templateRef"> </ng-template>
  </div>
</app-side-drawer-content>
