import { Injectable, OnDestroy } from '@angular/core';
import { BffAuthService, BffUser } from '@app/core/authentication/bff-auth.service';
import { LogService } from '@app/core/log/log.service';
import { UserRepo } from '@app/core/repositories/user/user-repo.service';
import { Observable, Subscription, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { User } from './core/interfaces/user.interface';
import { FeatureToggleService } from './libraries/ng-feature-toggles/services/feature-toggle.service';
import { UserStateService } from './state/services/user/user-state.service';
import { SubscriptionsService } from './subscriptions/subscriptions.service';

@Injectable()
export class AppInitService implements OnDestroy {
  private subscription = new Subscription();

  constructor(
    private featureToggleService: FeatureToggleService,
    private userRepo: UserRepo,
    private logService: LogService,
    private bffAuthService: BffAuthService,
    private subscriptionService: SubscriptionsService,
    private userStateService: UserStateService
  ) {}

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  public async init() {
    try {
      return new Promise((resolve) => {
        this.setUserAndInitializeFeatureToggleService();
        this.logService.initialize();
        this.bffAuthService.beginAuthenticationFlow();
        resolve('Initialization complete');
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error during bootstrap');
      throw err;
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private setUserAndInitializeFeatureToggleService() {
    this.subscription.add(
      this.bffAuthService.isAuthenticated$
        .pipe(
          filter((isAuthenticated) => isAuthenticated),
          switchMap(() => this.setUser())
        )
        .subscribe((user) => {
          this.featureToggleService.init(user.username);
        })
    );
  }

  private setUserSubscription(user: User) {
    this.subscriptionService.getCurrentSubscriptionsForUser(user.username).subscribe((userSubscriptions) => {
      const subscriptionLevel = this.subscriptionService.getCurrentSubscriptionLevelForUser(userSubscriptions);
      const subscriptionName = this.subscriptionService.getSubscriptionName(subscriptionLevel);
      this.userStateService.subscriptionLevel = subscriptionLevel;
      this.userStateService.formattedSubscriptionLevel = subscriptionName;
    });
  }

  private setUser(): Observable<User> {
    return this.bffAuthService.currentUser$.pipe(
      switchMap((currentUser) =>
        // If the user is from Naesgaard, we convert the user to match the existing interface, instead of requesting FieldOnline, because Naesgaard is unauthorized at FieldOnline
        // Otherwise we fetch the user from FieldOnline, and handle user subscriptions.

        // Optionally chained currentUser might be runtimechange
        currentUser?.isNaesgaard
          ? this.createUserFromNaesgaard(currentUser)
          : this.userRepo.getCurrentUser().pipe(
              tap((user) => {
                this.setUserSubscription(user);
              })
            )
      ),
      tap((user) => {
        this.userStateService.currentUser = user;
        this.userStateService.userType = user.userType;
      })
    );
  }

  private createUserFromNaesgaard(user: BffUser) {
    return of({ fullName: user.name, userType: 3, username: user.name } as User);
  }
}
