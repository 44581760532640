<div class="dialog-footer">
  <div class="footer-buttons">
    <app-square-button id="accept" buttonType="Primary" (clicked)="onConfirm()">
      {{ confirmButtonText | translate }}
    </app-square-button>
    <app-square-button *ngIf="cancelButtonText" id="cancel" buttonType="Primary" (clicked)="onCancel()">
      {{ cancelButtonText | translate }}
    </app-square-button>
  </div>
</div>
