import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { ProduceNormNutrients } from '@app/core/enums/produce-norm-nutrients.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { YieldTypeGroupQualityParameter } from '@app/core/interfaces/quality-parameter.interface';
import { ProduceNormsRepo } from '@app/core/repositories/produce-norms/produce-norms-repo.service';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NormDtoForFarm } from '../interfaces/norm-dto-for-farm';

export interface IProduceNormsService {
  getProduceNorms(farmId: number, harvestYear: number): Observable<ProduceNorm[]>;
  getMostUsedProduceNorms(farmId: number, harvestYear: number): Observable<ProduceNorm[]>;
  getQualityParametersForProduceNorm(
    farmId: number,
    harvestYear: number,
    produceNormNumber: number
  ): Observable<YieldTypeGroupQualityParameter[]>;
}

export enum ProduceNormGroupType {
  MostUsed = 'mostUsed',
  Other = 'other',
}

export interface IProduceNormItemGroup {
  produceNorms: ProduceNorm[];
  type: ProduceNormGroupType;
}

@Injectable({
  providedIn: 'root',
})
export class ProduceNormsService implements IProduceNormsService {
  private produceNormsCache = this.cacheService.create<ProduceNorm[]>({
    defaultTtl: 1000 * 60 * 60,
  });
  private mostUsedProduceNormsCache = this.cacheService.create<ProduceNorm[]>({
    defaultTtl: 1000 * 60 * 60,
  });

  constructor(
    private produceNormsRepo: ProduceNormsRepo,
    private cacheService: CacheService
  ) {}

  public getProduceNorms(farmId: number, harvestYear: number, nutrients?: Array<ProduceNormNutrients>): Observable<ProduceNorm[]> {
    const key = `${farmId}-${harvestYear}-${nutrients?.join('-')}`;

    return this.produceNormsCache.getOrSetAsync(key, () => this.produceNormsRepo.getProduceNorms(farmId, harvestYear, nutrients));
  }

  public getProduceNormsForFarms(farmIds: number[], harvestYear: number): Observable<NormDtoForFarm<ProduceNorm[]>[]> {
    const produceNormsForFarms$ = farmIds.map((farmId) => {
      return this.getProduceNorms(farmId, harvestYear).pipe(
        map((cropNormDtos) => {
          return { farmId: farmId, normDtos: cropNormDtos } as NormDtoForFarm<ProduceNorm[]>;
        })
      );
    });

    return combineLatest(produceNormsForFarms$);
  }

  public getMostUsedProduceNorms(farmId: number, harvestYear: number, nutrients?: Array<ProduceNormNutrients>) {
    const key = `${farmId}-${harvestYear}-${nutrients?.join('-')}`;

    return this.mostUsedProduceNormsCache.getOrSetAsync(key, () =>
      this.produceNormsRepo.getMostUsedProduceNorms(farmId, harvestYear, nutrients)
    );
  }

  public getProduceNormItemGroupsByOperationTypeGroupId(
    field: Field,
    operationTypeGroupId: number,
    cropId: number,
    preSelectedProduceNormNumbers: string[] = []
  ) {
    const cropNormNumber = this.findCropNormNumber(field, cropId);
    const varietyGroupNormNumbers = this.findVarietyGroupNormNumbers(field, cropId);

    return this.getProduceNormsCombined(field).pipe(
      map((groups) => {
        return groups.map((group) => {
          return {
            ...group,
            produceNorms: group.produceNorms
              .filter((norm) =>
                this.isValidProduceNorm(norm, operationTypeGroupId, varietyGroupNormNumbers, preSelectedProduceNormNumbers, cropNormNumber)
              )
              .sort((a, b) => this.stringComparer(a && a.name && a.name.toLowerCase(), b && b.name && b.name.toLowerCase())),
          };
        });
      })
    );
  }

  public getQualityParametersForProduceNorm(farmId: number, harvestYear: number, produceNormNumber: number) {
    return this.produceNormsRepo.getQualityParametersForProducenorm(farmId, harvestYear, produceNormNumber);
  }

  private getProduceNormsCombined(field: Field) {
    return combineLatest(this.getMostUsedProduceNormsGroup(field), this.getOtherProduceNormsGroup(field));
  }

  private getMostUsedProduceNormsGroup(field: Field) {
    return this.getMostUsedProduceNorms(field.farmId, field.harvestYear).pipe(
      map((produceNorms) => this.mapProduceNormsToProduceNormItemGroup(ProduceNormGroupType.MostUsed, produceNorms))
    );
  }

  private getOtherProduceNormsGroup(field: Field) {
    return this.getProduceNorms(field.farmId, field.harvestYear).pipe(
      switchMap((produceNorms) => this.filterOutMostUsedProduceNorms(field, produceNorms)),
      map((produceNorms) => this.mapProduceNormsToProduceNormItemGroup(ProduceNormGroupType.Other, produceNorms))
    );
  }

  private filterOutMostUsedProduceNorms(field: Field, produceNorms: ProduceNorm[]) {
    return this.getMostUsedProduceNormsGroup(field).pipe(
      map((mostUsedProduceNormGroup) =>
        produceNorms.filter(
          (produceNorm) =>
            mostUsedProduceNormGroup.produceNorms.find((otherProduceNorm) => otherProduceNorm.number === produceNorm.number) === undefined
        )
      )
    );
  }

  private isValidProduceNorm(
    produceNorm: ProduceNorm,
    operationTypeGroupId: number,
    varietyGroupNormNumbers: number[],
    preSelectedProduceNormNumbers: string[],
    cropNormNumber?: number
  ) {
    // Pre selected produce norms should always be included...
    if (preSelectedProduceNormNumbers.indexOf(produceNorm.number) > -1) {
      return true;
    }

    const operationTypeGroupMatch = () => produceNorm.operationTypeGroupId === operationTypeGroupId;
    const isSelectable = () => produceNorm.isSelectable;
    const hasName = () => produceNorm.name !== undefined;
    const cropNormNumberMatch = () => (produceNorm.cropNormNumber && cropNormNumber ? produceNorm.cropNormNumber === cropNormNumber : true);
    const varietyGroupNormNumberMatch = () =>
      // Not all produce norms has a varietyGroupNormNumber, primarily used for actual crops like wheat,
      // and if the producenorm doesn't have one, we let it through the filter.
      produceNorm.varietyGroupNormNumber && varietyGroupNormNumbers && varietyGroupNormNumbers.length
        ? varietyGroupNormNumbers.includes(produceNorm.varietyGroupNormNumber)
        : true;

    // Functions in order to short circuit early...
    return isSelectable() && hasName() && cropNormNumberMatch() && varietyGroupNormNumberMatch() && operationTypeGroupMatch();
  }

  /**
   * If A is less B return -1
   * Else if A greater than B return 1
   * Else return 0
   */
  private stringComparer = (a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0);

  private findCropNormNumber(field: Field, cropId: number) {
    if (!field || !field.crops || !field.crops.length) {
      return undefined;
    }

    const crop = field.crops.find((c) => c.id === cropId);

    if (!crop || !crop.cropNormNumber) {
      return undefined;
    }

    return crop.cropNormNumber;
  }

  private findVarietyGroupNormNumbers(field: Field, cropId: number) {
    if (!field || !field.crops || !field.crops.length) {
      return [];
    }

    const crop = field.crops.find((c) => c.id === cropId);

    if (!crop || !crop.validVarietyGroupNormNumbers || crop.validVarietyGroupNormNumbers.length === 0) {
      return [];
    }

    return crop.validVarietyGroupNormNumbers;
  }

  private mapProduceNormsToProduceNormItemGroup = (type: ProduceNormGroupType, produceNorms: ProduceNorm[]) => ({
    type,
    produceNorms,
  });
}
