<p class="description">
  {{ 'main.settings.dataConnections.johnDeere.text1' | translate }}
  <br /><br />
  {{ 'main.settings.dataConnections.johnDeere.text2' | translate }}
</p>

<div class="btn-wrapper">
  <app-square-button class="link-btn" buttonType="Primary" (clicked)="onLinkClick()">
    {{ 'main.settings.dataConnections.johnDeere.linkText' | translate }}
  </app-square-button>
</div>
