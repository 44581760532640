import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserTypePipe } from '@app/core/repositories/user/user-type.pipe';
import { CapitalizePipe } from '@app/shared/pipes/capitalize/capitalize.pipe';
import { DateFormatPipe } from '@app/shared/pipes/date-format/date-format.pipe';
import { AppUnitPipe } from '@app/shared/pipes/decimal-separator/unit.pipe';
import { FixedDecimalsPipe } from '@app/shared/pipes/fixedDecimals/fixedDecimals.pipe';
import { RoundPipe } from '@app/shared/pipes/round/round-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConcatPipe } from './concat/concat.pipe';
import { StdDeviationPipe } from './deviation/std-deviation.pipe';
import { DroneImageStatus } from './drone-image-status/drone-image-status.pipe';
import { FirstErrorKeyPipe } from './first-error-key/first-error-pipe';
import { FlatMapPipe } from './flatMap/flat-map.pipe';
import { GTagEventTrackerDirective } from './gTagEventTracker/gTagEventTracker.directive';
import { HasAccessPipe } from './has-access/vra-group-access';
import { JoinPipe } from './join/join.pipe';
import { LastSegmentPipe } from './lastSegment/lastSegment.pipe';
import { OperationTypeGroupTranslateKeyPipe } from './operation-type-group/operation-type-group-translate-key.pipe';
import { RotatePipe } from './rotate/rotate.pipe';
import { SimpleDecimalSeparatorPipe } from './simple-decimal-seperator/simple-decimal-seperator.pipe';
import { DatePropertySortPipe } from './sort/date-property-sort.pipe';
import { DateSortPipe } from './sort/date-sort.pipe';
import { SortStringPipe } from './sort/sort-string.pipe';
import { SortPipe } from './sort/sort.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { UniquePipe } from './unique/unique.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    AppUnitPipe,
    CapitalizePipe,
    ConcatPipe,
    DateFormatPipe,
    DatePropertySortPipe,
    DateSortPipe,
    DroneImageStatus,
    FirstErrorKeyPipe,
    FixedDecimalsPipe,
    SimpleDecimalSeparatorPipe,
    FlatMapPipe,
    HasAccessPipe,
    JoinPipe,
    OperationTypeGroupTranslateKeyPipe,
    RotatePipe,
    RoundPipe,
    SortPipe,
    SortStringPipe,
    StdDeviationPipe,
    TruncatePipe,
    UniquePipe,
    UserTypePipe,
    GTagEventTrackerDirective,
    LastSegmentPipe,
  ],
  exports: [
    AppUnitPipe,
    CapitalizePipe,
    ConcatPipe,
    DateFormatPipe,
    DatePropertySortPipe,
    DateSortPipe,
    DroneImageStatus,
    FirstErrorKeyPipe,
    FixedDecimalsPipe,
    SimpleDecimalSeparatorPipe,
    FlatMapPipe,
    HasAccessPipe,
    JoinPipe,
    OperationTypeGroupTranslateKeyPipe,
    RotatePipe,
    RoundPipe,
    SortPipe,
    SortStringPipe,
    StdDeviationPipe,
    TruncatePipe,
    UniquePipe,
    UserTypePipe,
    GTagEventTrackerDirective,
    LastSegmentPipe,
  ],
})
export class AppPipesModule {}
