import { DateTime } from 'luxon';

export class DateRange {
  public start: DateTime;
  public end: DateTime;

  constructor(start: DateTime, end: DateTime) {
    this.start = start;
    this.end = end;
  }
}
