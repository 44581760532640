import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { OperationTypes } from '@app/core/enums/operation-types.enum';

export interface OperationTypeGroupDTO {
  readonly id: number;
  readonly name: string;
  readonly imageUrl: string;
  readonly operationTypeIds: number[];
}

export class OperationTypeGroup implements OperationTypeGroupDTO {
  public readonly id: OperationTypeGroupEnum;
  public readonly name: string;
  public readonly imageUrl: string;
  public readonly operationTypeIds: OperationTypes[];

  constructor(dto: OperationTypeGroupDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.imageUrl = dto.imageUrl;
    this.operationTypeIds = dto.operationTypeIds;
  }
}
