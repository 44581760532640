import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { CornField } from '@app/core/interfaces/corn-field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CornHarvestPrognosisErrorCodes } from './corn-harvest-prognosis-error-codes.enum';
import { CornHarvestPrognosisSettings } from './corn-harvest-prognosis-settings.class';
import { CornHarvestPrognosisSettingsDTO } from './corn-harvest-prognosis-settings.dto';
import { CornHarvestErrorTuple, CornHarvestPrognosis } from './corn-harvest-prognosis.interface';

@Injectable()
export class CornHarvestPrognosisRepoService {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private languageService: LanguageService
  ) {}

  public getPrognosisWithSettings(farmId: number, harvestYear: number, multiYearId: number, settings?: CornHarvestPrognosisSettings) {
    return this.http

      .post<CornHarvestPrognosis, CornHarvestPrognosisSettings | undefined>(this.prognosisUrl, settings, {
        params: {
          farmId,
          harvestYear,
          fieldId: multiYearId,
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => throwError(() => error)),
        switchMap((response) => {
          if (response && response.errors && response.errors.length) {
            return throwError(() => this.findErrorMessages(response.errors!));
          }

          return of(response);
        })
      );
  }

  public getPrognosis(farmId: number, harvestYear: number, multiYearId: number) {
    return this.http

      .get<CornHarvestPrognosis>(this.prognosisUrl, {
        params: {
          farmId,
          harvestYear,
          fieldId: multiYearId,
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => throwError(() => error)),
        switchMap((response) => {
          if (response && response.errors && response.errors.length) {
            return throwError(() => this.findErrorMessages(response.errors!));
          }

          return of(response);
        })
      );
  }

  public getCrops(farmId: number, harvestYear: number | undefined) {
    return this.http.get<CornField[]>(this.cropsUrl, {
      params: {
        farmId,
        harvestYear,
      },
    });
  }

  public getSettings(farmId: number, harvestYear: number, fieldId: number): Observable<CornHarvestPrognosisSettingsDTO> {
    return this.http.get<CornHarvestPrognosisSettingsDTO>(this.prognosisSettingsUrl, {
      params: {
        farmId,
        harvestYear,
        fieldId,
      },
    });
  }

  private findErrorMessages(errors: CornHarvestErrorTuple[]) {
    return errors.map((error) => {
      switch (error.item1) {
        case CornHarvestPrognosisErrorCodes.SowingDateNotFound:
          return this.languageService.getText('cornHarvestPrognosis.errors.sowingDateNotFound');
        case CornHarvestPrognosisErrorCodes.DMINotResponding:
          return this.languageService.getText('cornHarvestPrognosis.errors.dMINotResponding');
        case CornHarvestPrognosisErrorCodes.CornSortNotSupported:
          return this.languageService.getText('cornHarvestPrognosis.errors.cornSortNotSupported');
        case CornHarvestPrognosisErrorCodes.CornSortNotSupportedGrainOrCob:
          return this.languageService.getText('cornHarvestPrognosis.errors.cornSortNotSupportedGrainOrCob');
        default:
          return this.languageService.getText('cornHarvestPrognosis.errors.default');
      }
    });
  }

  private get cropsUrl() {
    return `${this.endpoints.foApi}/v2/cornHarvestprognosis/crops`;
  }

  private get prognosisUrl() {
    return `${this.endpoints.foApi}/v2/CornHarvestPrognosis/prognosis`;
  }

  private get prognosisSettingsUrl() {
    return `${this.endpoints.foApi}/CornHarvestPrognosisSettings`;
  }
}
