import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardPresentationComponent } from '@app/shared/cards-or-table/cards-presentation/card-presentation/card-presentation.component';
import { CardsPresentationComponent } from '@app/shared/cards-or-table/cards-presentation/cards-presentation.component';
import { AppDirectiveModule } from '@app/shared/directives/app-directive.module';
import { AppPipesModule } from '@app/shared/pipes/pipes.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatRippleModule,
    IconModule,
    AppPipesModule,
    AppDirectiveModule,
  ],
  declarations: [CardsPresentationComponent, CardPresentationComponent],
  exports: [CardsPresentationComponent],
})
export class CardsPresentationModule {}
