import { Injectable } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { SoilSampleLegend } from '@app/core/repositories/soil-samples/soil-sample-legend.class';
import { SampleResult, SoilSampleDTO } from '@app/core/repositories/soil-samples/soil-sample.interface';
import { SoilSamplesRepoService } from '@app/core/repositories/soil-samples/soil-samples-repo.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SoilSampleFeatureService } from '../soil-sample-feature.service';
import { SoilSampleShortName } from './soil-sample-short-name.enum';
import { SoilSampleParameterIds } from './soil-samples-analysis-parameter-ids.enum';

@Injectable({
  providedIn: 'root',
})
export class SoilSampleSideDrawerService {
  constructor(
    private soilSamplesRepo: SoilSamplesRepoService,
    private soilSampleMapFeatureService: SoilSampleFeatureService
  ) {}

  public getSoilSampleLegend(shortName: SoilSampleShortName): Observable<SoilSampleLegend> {
    return this.soilSamplesRepo
      .getSoilSampleLegend(shortName, this.getEntriesAmountForLegend(shortName))
      .pipe(map((legend) => new SoilSampleLegend(shortName, legend)));
  }

  public getSoilSampleGroups(farms: Farm[]) {
    const farmIds = farms ? farms.map((farm) => farm.id) : [];
    return this.soilSamplesRepo.getSoilSampleGroups(farmIds);
  }

  public getSoilSamples(farms: Farm[], dates: DateTime[]) {
    const farmIds = farms ? farms.map((farm) => farm.id) : [];
    return this.soilSamplesRepo.getSoilSamples(farmIds, dates);
  }

  public setSoilSampleMapLayers(
    farms: Farm[],
    samples: SoilSampleDTO[] | null,
    parameterShortName: SoilSampleShortName,
    harvestYear?: number
  ) {
    const excluded = [SoilSampleShortName.NitrogenPercentage, SoilSampleShortName.CarbonPercentage, SoilSampleShortName.OrganicCarbon];
    const isExcluded = excluded.some((shortName) => shortName === parameterShortName);

    if (isExcluded) {
      this.soilSampleMapFeatureService.removeSoilSamplesLayer();
      this.soilSampleMapFeatureService.removeSoilSamplesLabelsLayer();
    }

    if (samples?.length) {
      if (!isExcluded) this.setSoilSamplesMapLayer(farms, samples, parameterShortName, harvestYear);
      this.setSoilSampleLabelsLayer(samples, parameterShortName);
    } else {
      this.soilSampleMapFeatureService.removeSoilSamplesLayer();
      this.soilSampleMapFeatureService.removeSoilSamplesLabelsLayer();
    }
  }

  private setSoilSamplesMapLayer(farms: Farm[], samples: SoilSampleDTO[], parameterShortName: string, harvestYear?: number) {
    if (!farms.length || !harvestYear || !parameterShortName || !samples.length) {
      return;
    }

    this.soilSampleMapFeatureService.addOrUpdateSoilSamplesLayer(samples, farms, harvestYear, parameterShortName);
  }

  private getEntriesAmountForLegend(shortName: SoilSampleShortName) {
    switch (shortName) {
      case SoilSampleShortName.Reaction:
        return 11;
      case SoilSampleShortName.Potassium:
        return 14;
      case SoilSampleShortName.Phosphor:
      case SoilSampleShortName.Magnesium:
      case SoilSampleShortName.SoilType:
        return 12;
      case SoilSampleShortName.Copper:
      case SoilSampleShortName.Boron:
        return 9;
      case SoilSampleShortName.ClayPercentage:
        return 16;
      case SoilSampleShortName.Humus:
        return 11;
      default:
        return 13;
    }
  }

  private setSoilSampleLabelsLayer(samples: SoilSampleDTO[], parameterShortName: SoilSampleShortName) {
    if (!parameterShortName) {
      return;
    } else if (parameterShortName === SoilSampleShortName.SoilType) {
      samples = this.mapSoilSampleIds(samples);
    }

    this.soilSampleMapFeatureService.addOrUpdateSoilSamplesLabelsLayer(samples, parameterShortName);
  }

  private mapSoilSampleIds(samples: SoilSampleDTO[]): SoilSampleDTO[] {
    const toReturn = samples.filter((s) => !!s.jbNoId);
    toReturn.forEach((s: SoilSampleDTO) => {
      s.sampleResults = [
        ...s.sampleResults.filter((sr) => sr.shortName !== SoilSampleShortName.SoilType),
        {
          value: s.jbNoId,
          shortName: SoilSampleShortName.SoilType,
          parameterId: SoilSampleParameterIds.Texture,
        } as SampleResult,
      ];
    });
    return toReturn;
  }

  public getNumberOfMeasurements(soilSamples: SoilSampleDTO[]) {
    const allResults: SampleResult[] = soilSamples.reduce(
      (previous: SampleResult[], current) => [...previous, ...current.sampleResults],
      []
    );
    return {
      reaction: this.filterResultsById(allResults, SoilSampleParameterIds.ReactionNumber).length,
      potassium: this.filterResultsById(allResults, SoilSampleParameterIds.PotassiumNumber).length,
      magnesium: this.filterResultsById(allResults, SoilSampleParameterIds.MagnesiumNumber).length,
      phosphor: this.filterResultsById(allResults, SoilSampleParameterIds.PhosphorNumber).length,
      clayPct: this.filterResultsById(allResults, SoilSampleParameterIds.ClayPercentage).length,
      humus: this.filterResultsById(allResults, SoilSampleParameterIds.Humus).length,
      copper: this.filterResultsById(allResults, SoilSampleParameterIds.CopperNumber).length,
      boron: this.filterResultsById(allResults, SoilSampleParameterIds.BoronNumber).length,
      nitrogen: this.filterResultsById(allResults, SoilSampleParameterIds.NitrogenPercentage).length,
      carbon: this.filterResultsById(allResults, SoilSampleParameterIds.CarbonPercentage).length,
      orgCarbon: this.filterResultsById(allResults, SoilSampleParameterIds.OrganicCarbon).length,
      texture: soilSamples.length,
    };
  }

  private filterResultsById(allResults: SampleResult[], id: SoilSampleParameterIds) {
    return allResults.filter((result) => result.parameterId === id);
  }
}
