import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LanguageService } from '@app/core/language/language.service';

@Injectable()
export class MdPaginatorIntlTranslate extends MatPaginatorIntl {
  public override itemsPerPageLabel = '';
  public override nextPageLabel = '';
  public override previousPageLabel = '';
  public ofLabel = '';

  constructor(private languageService: LanguageService) {
    super();

    this.languageService.languageChanged.subscribe(() => {
      this.getTranslations();
    });

    this.getTranslations();
  }

  private getTranslations() {
    this.itemsPerPageLabel = this.languageService.getText('common.pager.itemsPerPageLabel');
    this.nextPageLabel = this.languageService.getText('common.pager.nextPageLabel');
    this.previousPageLabel = this.languageService.getText('common.pager.previousPageLabel');
    this.ofLabel = this.languageService.getText('common.pager.ofLabel');
  }

  public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };
}
