<app-dialog-header
  size="md"
  [showClose]="false"
  [title]="
    ((transferingFields$ | async) ? 'main.fieldAdministration.copyField.inProgress' : 'main.fieldAdministration.copyField.done') | translate
  "
  (close)="onConfirmClick()"
>
</app-dialog-header>

<app-dialog-content size="md">
  <div class="dialog-content-wrapper">
    <div class="progress">
      <div>
        <app-spinner *ngIf="transferingFields$ | async"></app-spinner>
      </div>
    </div>
    <div class="error-list">
      <div *ngFor="let failedField of failedTransferedFields$ | async">
        {{ 'main.fieldAdministration.copyField.inProgressErrorPre' | translate }}{{ failedField.FieldNumber
        }}{{ 'main.fieldAdministration.copyField.inProgressErrorPost' | translate }}
      </div>
    </div>
    <app-square-button *ngIf="!(transferingFields$ | async)" buttonType="Primary" (clicked)="onConfirmClick()">{{
      'main.fieldAdministration.copyField.complete' | translate
    }}</app-square-button>
  </div>
</app-dialog-content>
