import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { ChartWrapperModule } from '@app/shared/chart-wrapper/chart-wrapper.module';
import { MaterialModule } from '@app/shared/material/material.module';
import { SelectModule } from '@app/shared/select/select.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ChartModule } from '@progress/kendo-angular-charts';
import { AppPipesModule } from '../pipes/pipes.module';
import { SeptoriaChartInfoComponent } from './septoria-chart-info/septoria-chart-info.component';
import { SeptoriaChartComponent } from './septoria-chart.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ChartModule,
    ChartWrapperModule,
    ButtonsModule,
    AppPipesModule,
    MatDialogModule,
    SelectModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
  ],
  declarations: [SeptoriaChartComponent, SeptoriaChartInfoComponent],
  exports: [SeptoriaChartComponent],
})
export class SeptoriaChartModule {}
