<div class="item">
  <mat-form-field *ngIf="editing" class="edit-name" appearance="outline">
    <input
      matInput
      #input
      type="text"
      [formControl]="nameControl"
      name="groupName"
      (keydown.enter)="emitName()"
      (blur)="emitName()"
      autocomplete="off"
    />
    <mat-error *ngIf="nameControl.getError('required')">{{ 'messages.hotspotGroups.nameRequired' | translate }}</mat-error>
    <mat-error *ngIf="nameControl.getError('maxlength')">{{ 'messages.hotspotGroups.nameTooLong' | translate }}</mat-error>
  </mat-form-field>
  <div *ngIf="!editing" class="name" (click)="focusInput()">
    <p>{{ group.name }}</p>
    <app-icon class="icon" icon="edit_outline"></app-icon>
  </div>
  <app-list-button class="delete" buttonType="Delete" (clicked)="deleteGroup.emit()"></app-list-button>
</div>
