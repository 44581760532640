<app-side-drawer-toolbar class="flex-child" [title]="'cornHarvestPrognosis.settings.title' | translate" (action)="onCloseClick()">
</app-side-drawer-toolbar>
<div class="content-wrapper">
  <app-side-drawer-sub-header>
    {{ 'cornHarvestPrognosis.settings.sample' | translate }}
  </app-side-drawer-sub-header>
  <div class="buttons">
    <app-square-button [buttonType]="'Secondary'" [disabled]="dryMatterPctControl.invalid" (clicked)="onSaveClick()">{{
      'cornHarvestPrognosis.settings.save' | translate
    }}</app-square-button>
  </div>
  <div class="sample-settings">
    <form>
      <app-date-picker
        [showIcon]="true"
        [date]="settings.sampleDate"
        (dateChange)="onSampleDateChange($event)"
        placeholder="{{ 'cornHarvestPrognosis.settings.sampleDate' | translate }}"
      ></app-date-picker>
      <app-input
        [formControl]="dryMatterPctControl"
        type="number"
        placeholder="{{ 'cornHarvestPrognosis.settings.sampleDryMatter' | translate }}"
      >
        <app-input-error *ngIf="dryMatterPctControl.invalid">
          {{ 'cornHarvestPrognosis.settings.sampleDryMatterError' | translate }}
        </app-input-error>
      </app-input>
    </form>
  </div>
</div>
