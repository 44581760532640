import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { OperationTypeGroup, OperationTypeGroupDTO } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OperationTypeGroupsMessages {
  getError: string;
}

export interface IOperationTypeGroupsRepo {
  /**
   * Gets a list of operations filtered by subscriptions
   */
  getOperationTypeGroups(language: string): Observable<OperationTypeGroupDTO[]>;
}

@Injectable({
  providedIn: 'root',
})
export class OperationTypeGroupsRepo implements IOperationTypeGroupsRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getOperationTypeGroups(language: string): Observable<OperationTypeGroupDTO[]> {
    const options: any = {
      withCredentials: true,
    };

    return this.http
      .get<OperationTypeGroupDTO[]>(`${this.endpoints.foApi}/operationtypegroups/${language}`, options)
      .pipe(map((dtos) => dtos.map((dto) => new OperationTypeGroup(dto))));
  }
}
