import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInNgIf } from '@app/core/animations/fade-in-ng-if.fn';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { ActionEvent } from '@app/shared/cards-or-table/interfaces/action-event.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { CardsOrTableService } from '@app/shared/cards-or-table/services/cards-or-table.service';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Component({
  selector: 'app-table-presentation',
  templateUrl: './table-presentation.component.html',
  styleUrls: ['./table-presentation.component.scss'],
  animations: [fadeInNgIf('reveal')],
})
export class TablePresentationComponent implements OnInit, OnDestroy, AfterViewInit {
  public fieldDefinitions$ = new BehaviorSubject<FieldDefinition<any>[]>([]);
  public data$ = new BehaviorSubject<any[]>([]);
  public actionDefinitions$ = new BehaviorSubject<ActionDefinition[]>([]);

  public loaded$ = combineLatest(this.fieldDefinitions$, this.data$, this.actionDefinitions$).pipe(
    first(),
    map(([fieldDefinitions, data, actionDefinitions]) => fieldDefinitions.length > 0 && !!data && actionDefinitions.length > 0)
  );

  public noTasks$ = this.data$.pipe(
    first(),
    map((data) => data.length === 0)
  );

  @Output() public readonly action = new EventEmitter<ActionEvent<any>>();

  @Input() public tableRef!: TemplateRef<any>;

  @Input()
  public set actionDefinitions(value: ActionDefinition[]) {
    this.actionDefinitions$.next(value);
  }
  @Input()
  public set data(value: any[]) {
    this.data$.next(value);
  }
  @Input()
  public set fieldDefinitions(value: FieldDefinition<any>[]) {
    this.fieldDefinitions$.next(value);
  }

  public displayedColumns$ = this.cardsOrTableService.mapDisplayedColumns$(this.fieldDefinitions$, this.actionDefinitions$);
  public dataSource = new MatTableDataSource([]);

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @ViewChild(MatSort, { static: false }) public sort: MatSort;

  private subscriptions: Subscription[] = [];

  constructor(private cardsOrTableService: CardsOrTableService) {}

  public ngOnInit() {
    this.subscriptions.push(this.getDataStream());
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public triggerAction(dataItem: { __original: any }, actionDefinition: ActionDefinition, e: any) {
    this.action.emit({
      type: actionDefinition.type,
      dataItem: JSON.parse(JSON.stringify(dataItem.__original)),
    });
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private getDataStream() {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.data$.pipe(filter((data) => data !== undefined)).subscribe((data) => (this.dataSource.data = data));
  }
}
