<app-spinner *ngIf="isGrowthStageSaving" [hasBackdrop]="true" [message]="'growthStage.savingData' | translate"></app-spinner>
<app-square-button
  class="action-buttons"
  [disabled]="!hasObservations"
  [buttonType]="'Secondary'"
  (click)="!hasObservations || onGenerateCorrectionClicked()"
  >{{ 'growthStage.actionButtons.generateChartFromObservation' | translate | capitalize }}</app-square-button
>
<app-chart-wrapper>
  <app-square-button class="info-button" (clicked)="onInfoClick(field)">
    <app-icon icon="info_outline"></app-icon>
    {{ 'common.info' | translate }}
  </app-square-button>
  <app-growth-stages-chart [field]="field" [prognosis]="growthStageResult!" [errorMessage]="errorMessage"></app-growth-stages-chart>
</app-chart-wrapper>
