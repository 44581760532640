import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LanguagePickerComponent } from '@app/shared/language-picker/language-picker.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule, IconModule],
  exports: [LanguagePickerComponent],
  declarations: [LanguagePickerComponent],
})
export class LanguagePickerModule {}
