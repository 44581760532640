import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { FarmGuard } from '@app/core/guards/farm-guard.service';
import { AccessControlGuard, AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';
import { PrognosisComponent } from './prognosis.component';

const routes: AccessControlRoutes = [
  {
    path: '',
    component: PrognosisComponent,
    canActivate: [AuthGuard, ConsentGuard, FarmGuard, AccessControlGuard],
    data: {
      module: 'prognosis',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrognosisRoutingModule {}
