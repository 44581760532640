export class MapPadding {
  // Map paddings
  private static readonly SIDE_MAP_PADDING = 50;
  private static readonly TOP_BOTTOM_MAP_PADDING = 100;
  private static readonly OLD_SIDEDRAWER_MAP_PADDING = 400;
  private static readonly NEW_SIDEDRAWER_MAP_PADDING = 500;

  // Takes the part of the map that is hidden behind the sidedrawer into account
  public static readonly OLD_NON_MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.OLD_SIDEDRAWER_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];

  // For the new sideDrawer Design
  public static readonly NEW_NON_MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.NEW_SIDEDRAWER_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];

  public static readonly MOBILE_MAP_PADDING = [
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
    this.TOP_BOTTOM_MAP_PADDING,
    this.SIDE_MAP_PADDING,
  ];
}
