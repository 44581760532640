import { Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { flyInOut } from '@app/core/animations/fly-in-out.fn';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { FieldCopyStateService } from './../field-copy-state.service';

@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
  animations: [flyInOut],
})
export class FieldSelectComponent {
  public harvestyear = this.harvestYearStateService.harvestYear$;
  public selectedFields$ = this.fieldCopyStateService.selectedFields$;
  public deSelectedFields$ = this.fieldCopyStateService.deSelectedFields$;
  constructor(
    public fieldCopyStateService: FieldCopyStateService,
    public harvestYearStateService: HarvestYearStateService
  ) {}

  public onSelect(fieldNumber: string) {
    this.fieldCopyStateService.selectField(fieldNumber);
  }
  public onDeselect(fieldNumber: string) {
    this.fieldCopyStateService.deSelectField(fieldNumber);
  }

  public onIncludeCrop(includeCrop: boolean, fieldNumber: string) {
    this.fieldCopyStateService.includeCrop(fieldNumber, includeCrop);
  }
  public selectAllCrop($event: MatCheckboxChange) {
    const checked = $event.checked;
    this.fieldCopyStateService.includeAllCrop(checked);
  }
}
