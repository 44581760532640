<div class="pdfForceUnicodeUsage">æøå</div>
<mat-drawer-container class="field-map-container">
  <app-map-controls [map]="map" [hostElement]="hostElement" [showLayerControls]="false"></app-map-controls>

  <app-spinner *ngIf="isCreatingPDF$ | async" [hasBackdrop]="true" [message]="'pdf.createsPDF' | translate"></app-spinner>

  <app-openlayers-map
    #map
    [config]="mapConfig"
    (ready)="handleMapReady()"
    (moveEnd)="onMapMoved($event)"
    id="mapElement"
    [attr.data-testid]="(fieldFeaturesChanged$ | async) ? 'canvas' : 'pre-ready-canvas'"
  >
    <ng-container *ngIf="isMapReady">
      <router-outlet></router-outlet>
    </ng-container>
  </app-openlayers-map>
  <app-map-credits></app-map-credits>
</mat-drawer-container>

<router-outlet name="sideDrawer"></router-outlet>
