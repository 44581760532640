import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';

@Directive({
  selector: '[inCellEditingTabNavigation]',
})
export class InCellEditingTabNavigationDirective implements OnInit, OnDestroy {
  @Input('inCellEditingTabNavigation')
  public createFormGroup!: (args: any) => UntypedFormGroup;

  @Input()
  public wrap = true;

  private unsubKeydown!: () => void;

  constructor(
    private grid: GridComponent,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.unsubKeydown = this.renderer.listen(this.el.nativeElement, 'keydown', (e) => this.onKeydown(e));
  }

  public ngOnDestroy(): void {
    this.unsubKeydown();
  }

  public onKeydown(e: KeyboardEvent): void {
    if (e.key !== 'Tab') {
      // Handle just tabs
      return;
    }

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    const inputName = document.querySelector(':focus').getAttribute('name');
    if ((e.shiftKey === false && inputName === 'fieldNumberPart1') || (e.shiftKey === true && inputName === 'fieldNumberPart2')) {
      // ignore tab
      return;
    }

    let activeRow = this.grid.activeRow;
    if (!activeRow || !activeRow.dataItem) {
      // Not on an editable row
      return;
    }

    if (this.grid.isEditingCell() && !this.grid.closeCell()) {
      // Content validation failed, keep focus in cell
      e.preventDefault();
      return;
    }

    const nav = e.shiftKey ? this.grid.focusPrevCell(this.wrap) : this.grid.focusNextCell(this.wrap);

    if (!nav) {
      // No next cell to navigate to
      return;
    }

    // Prevent the focus from moving to the next element
    e.preventDefault();

    activeRow = this.grid.activeRow;
    const dataItem = activeRow.dataItem;

    if (dataItem) {
      // Edit focused cell
      const formGroup = this.createFormGroup({ dataItem });
      if (formGroup.enabled) {
        this.grid.editCell(activeRow.dataRowIndex, nav.colIndex, formGroup);
      } else {
        this.grid.closeCell();
        this.grid.cancelCell();
      }
    }
  }
}
