import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { FeatureCoordinates } from '@app/core/interfaces/geometry-coordinates.types';
import WKT from 'ol/format/WKT';
import LineString from 'ol/geom/LineString';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';

export function getCoordinatesFromWktString(geometryString: string): FeatureCoordinates {
  const wkt = new WKT();
  const olGeometry = wkt.readGeometry(geometryString);

  switch (olGeometry.getType()) {
    case 'LineString':
      return (olGeometry as LineString).getCoordinates();
    case 'Point':
      return (olGeometry as Point).getCoordinates();
    case 'MultiPolygon':
      return (olGeometry as MultiPolygon).getCoordinates();
    default:
      return (olGeometry as Polygon).getCoordinates();
  }
}

export function getGeometryTypeFromWktString(geometryString: string): GeometryType {
  if (geometryString?.length) {
    const wkt = new WKT();
    const olGeometry = wkt.readGeometry(geometryString);

    switch (olGeometry.getType()) {
      case 'LineString':
        return GeometryType.LINE;
      case 'Point':
        return GeometryType.POINT;
      case 'MultiPolygon':
        return GeometryType.MULTIPOLYGON;
      default:
        return GeometryType.POLYGON;
    }
  } else {
    return GeometryType.POLYGON;
  }
}

export const isIntersectionBetweenLines = (
  line1StartX: number,
  line1StartY: number,
  line1EndX: number,
  line1EndY: number,
  line2StartX: number,
  line2StartY: number,
  Line2EndX: number,
  line2EndY: number
): boolean => {
  const det = (line1EndX - line1StartX) * (line2EndY - line2StartY) - (Line2EndX - line2StartX) * (line1EndY - line1StartY);
  if (det === 0) {
    return false;
  } else {
    const lambda = ((line2EndY - line2StartY) * (Line2EndX - line1StartX) + (line2StartX - Line2EndX) * (line2EndY - line1StartY)) / det;
    const gamma = ((line1StartY - line1EndY) * (Line2EndX - line1StartX) + (line1EndX - line1StartX) * (line2EndY - line1StartY)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
};
