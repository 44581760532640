import { NToolsTaskStatus } from '@app/core/enums/n-tools-task-Status.enum';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { NToolsTableLineDto } from '@app/core/interfaces/n-tools/n-tools-table-line-dto.interface';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { FilterItem } from '@app/new-map/features/cultivation-journal/filter/filter-item';
import { Filterable } from '@app/new-map/features/cultivation-journal/filter/filterable';
import { CropNutrientAvailability } from '@app/shared/n-tool/CropNutrientAvailabilityGraphDataPointDTO';
import { DateTime } from 'luxon';
import { ColumnKeyNTool } from '../../../filter/column/column-key-n-tool';
import { NumberFormater } from '../util/number-formater';
import { generator } from '../util/tableLineIdGenerator';
import { NToolsTableSubLine } from './n-tools-table-sub-line';

export class NToolsTableLine extends Filterable<NToolsTableLine> {
  public field: Field;
  public crop: Crop;
  public subLines: NToolsTableSubLine[];
  public cropNutrientAvailability: CropNutrientAvailability;
  public tableLineId: number;
  private suffix: string | undefined;
  private nTotalNeeds: number | undefined;

  constructor(dto: NToolsTableLineDto, tableLineId?: number) {
    super();
    this.field = dto.field;
    this.crop = dto.crop;
    this.cropNutrientAvailability = dto.cropNutrientAvailability;
    this.suffix = dto.subLines.find((subLine) => !!subLine)?.nStatusUnit;
    this.subLines = dto.subLines.map((subLine) => new NToolsTableSubLine(subLine));
    this.subLines.sort(
      (a: NToolsTableSubLine, b: NToolsTableSubLine) =>
        DateTime.fromISO(a.date.toString()).toMillis() - DateTime.fromISO(b.date.toString()).toMillis()
    );
    this.tableLineId = tableLineId ?? (generator.next().value as number);

    this.nTotalNeeds = dto.nTotalNeed;
  }

  public get status(): NToolsTaskStatus | null {
    if (!this.subLines.length) {
      return null;
    }
    if (this.subLines.every((s) => s.status === NToolsTaskStatus.done)) {
      return NToolsTaskStatus.done;
    }
    if (this.subLines.every((s) => s.status === NToolsTaskStatus.planned)) {
      return NToolsTaskStatus.planned;
    }
    return NToolsTaskStatus.inProgress;
  }

  public get totalN(): number | null {
    if (!this.subLines.length) {
      return null;
    }
    return NumberFormater.Thousands(this.subLines.reduce((total, subLine) => total + Math.round(subLine.nStatus), 0)) as unknown as number;
  }

  public get totalNWithSuffix(): string | undefined {
    return this.totalN ? this.totalN + ' ' + this.suffix : undefined;
  }

  public get nTotalNeed(): number | undefined {
    return this.nTotalNeeds;
  }

  public get nTotalNeedWithSuffix(): string | undefined {
    if (this.suffix) return this.nTotalNeeds + ' ' + this.suffix;

    return this.nTotalNeeds?.toString();
  }

  public get appliedN(): string | null {
    if (!this.subLines.length) {
      return null;
    }
    return NumberFormater.Thousands(
      this.subLines
        .filter((subLine) => subLine.status === NToolsTaskStatus.done)
        .reduce((total, subLine) => total + Math.round(subLine.nStatus), 0)
    );
  }

  public get appliedNWithSuffix(): string | undefined {
    return this.appliedN ? this.appliedN + ' ' + this.suffix : undefined;
  }

  public get appliedNPercent(): number | null {
    if (!this.subLines.length) {
      return null;
    }
    const totalN = this.subLines.reduce((total, subLine) => total + subLine.nStatus, 0);
    const appliedN = this.subLines
      .filter((subLine) => subLine.status === NToolsTaskStatus.done)
      .reduce((total, subLine) => total + subLine.nStatus, 0);
    return (appliedN / totalN) * 100;
  }

  public get nStatus(): string | null {
    if (!this.subLines.length) {
      return null;
    }
    return `${this.appliedN}/${this.totalN} ${this.suffix}`;
  }

  public get nextTreatment(): NToolsTableSubLine | null | undefined {
    if (!this.subLines.length) {
      return null;
    }
    const sortedSubLines = [...this.subLines].sort((a, b) => CompareHelper.compare(a.date, b.date));

    return sortedSubLines.find((subLine) => subLine.status === NToolsTaskStatus.planned);
  }

  public get nZeroDate(): Date | null | undefined {
    const sortedSubLines = [...this.subLines].sort((a, b) => CompareHelper.compare(a.date, b.date, false));
    const firstNZeroSubline = sortedSubLines.find((subline) => subline.status === NToolsTaskStatus.done && !!subline.nZeroDate);

    return firstNZeroSubline?.nZeroDate;
  }

  public getIdentifier(columnKey: ColumnKeyNTool) {
    switch (columnKey) {
      case ColumnKeyNTool.fieldNumber:
        return this.field.id;
      case ColumnKeyNTool.nBalance:
        return this.cropNutrientAvailability;
      default:
        return super.noMatchingIdentitier(columnKey);
    }
  }

  public getTitle(columnKey: ColumnKeyNTool) {
    switch (columnKey) {
      case ColumnKeyNTool.fieldNumber:
        return this.field.number;
      case ColumnKeyNTool.nBalance:
        return this.cropNutrientAvailability;
      default:
        return super.noMatchingTitle(columnKey);
    }
  }

  public override getNestedFilterables(columnKey: ColumnKeyNTool): NToolsTableSubLine[] | null {
    switch (columnKey) {
      case ColumnKeyNTool.productName:
        return this.subLines.map((subLine) => subLine);
      default:
        return super.getNestedFilterables(columnKey);
    }
  }

  public override matchesFilterItem(filterItem: FilterItem<any>): boolean {
    switch (filterItem.columnKey) {
      case ColumnKeyNTool.productName:
        return this.subLines.some((subLine) => subLine.getIdentifier(filterItem.columnKey) === filterItem.identifier);
      default:
        return super.matchesFilterItem(filterItem);
    }
  }
}
