import { Module } from '@app/core/types/module.type';

/**
 * All resources must be prefixed with the module they belong to.
 *
 * TODO: This will only be inferred correctly from const types with TS 4.9 when using `satisfies ResourceName`
 * ! Unused until then
 */
export type ResourceName = `${Module}${string}`;

// this can be used when the above TODO is implemented
export type ModuleResourceMap = Readonly<Record<Module, readonly ResourceName[]>>;

/**
 * All the different types of resources that can be accessed.
 *
 * They are nested under each module that they belong to.
 *
 * Each resource must have a prefix that matches the module it belongs to.
 *
 * Each resource must be unique.
 */
export const RESOURCES = {
  field_plan: [
    'field_plan_information',
    'field_plan_water_balance',
    'field_plan_cultivation_journal_read',
    'field_plan_cultivation_journal_write',
    'field_plan_hay_harvest_prognosis',
    'field_plan_grazings_prognosis',
    'field_plan_corn_harvest_prognosis',
    'field_plan_eyespot',
    'field_plan_growth_stage',
    'field_plan_septoria',
    'field_plan_n_tools',
    'field_plan_potato_blight',
    'field_plan_edit_fields',
  ],
  field_analysis: [
    'field_analysis_as_applied',
    'field_analysis_isoxml',
    'field_analysis_biomass',
    'field_analysis_biomass_benchmark',
    'field_analysis_soil_samples',
    'field_analysis_drone_images',
  ],
  vra: [
    'vra_seeding',
    'vra_fertilization_nitrogen',
    'vra_fertilization_potassium',
    'vra_fertilization_phosphor',
    'vra_plant_protection',
    'vra_plant_protection_spot_spray',
    'vra_lime',
    'vra_send_to_machine',
    'vra_save_to_database',
    'vra_naesgaard_save',
    'vra_download',
    'vra_basis_layer',
  ],
  prognosis: ['prognosis_corn', 'prognosis_growth_regulation', 'prognosis_yield_prognosis', 'prognosis_potato_blight'],
  basis_layer: ['basis_layer'],
  hotspots: ['hotspots_import_shape_file'],
  economy: ['economy', 'economy_price_suggestion'],
  yield_benchmark: ['yield_benchmark'],
  settings: ['settings_data_connections', 'settings_transfer'],
  delegations: ['delegations'],
  reminders: ['reminders'],
  map: ['map_generate_pdf', 'map_harvestyear_picker'],
  naesgaard: ['naesgaard_specific_information'],
} as const; // this can be extended with `as const satisfies ModuleResourceMap` when TS 4.9 is upgraded to - @see ResourceName

/**
 * List of all the different resources that can be accessed.
 *
 * Used for easy iteration and type guards.
 */
export const RESOURCES_LIST = Object.values(RESOURCES).flat();

/**
 * A resource represents a specific feature that can be accessed.
 *
 * A resource might for instance be the field plan or VRA.
 */
export type Resource = (typeof RESOURCES)[keyof typeof RESOURCES][number];

// type guards
export const isResource = (value: unknown): value is Resource => typeof value === 'string' && RESOURCES_LIST.includes(value as Resource);
export const isResources = (value: unknown): value is Resource[] => Array.isArray(value) && value.every(isResource);
