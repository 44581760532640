import { ChangeDetectionStrategy, Component } from '@angular/core';
import { getScoreColor } from '../helpers/score-color.helper';

@Component({
  selector: 'app-growth-regulation-legend-content',
  templateUrl: './growth-regulation-legend-content.component.html',
  styleUrls: ['./growth-regulation-legend-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrowthRegulationLegendContentComponent {
  constructor() {}

  public getScoreColor(nr: number) {
    return getScoreColor(nr);
  }
}
