import { Injectable } from '@angular/core';
import { LocalState } from '@app/helpers/local-state';

@Injectable({
  providedIn: 'root',
})
export class FieldAnalysisService {
  private onFieldClickedState = new LocalState<{ clicked: boolean }>({
    clicked: false,
  });
  public onFieldClicked$ = this.onFieldClickedState.changes$;

  constructor() {}

  public setOnFieldClicked(clicked: boolean) {
    this.onFieldClickedState.setState({ clicked });
  }
}
