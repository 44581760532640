<app-fab
  [disabled]="isLoadingReminders || (disabled$ | async)!"
  icon="notification_outline"
  (click)="openReminders()"
  appGTagEventTracker
  eventName="openReminders (click)"
  eventCategory="Administration"
></app-fab>
<mat-icon
  *ngIf="!(disabled$ | async)"
  [matBadge]="reminderCount"
  matBadgePosition="after"
  matBadgeColor="accent"
  matBadgeSize="medium"
  aria-hidden="false"
></mat-icon>
