export enum OperationTypes {
  OwnSoilTreatment = 5,
  SoilTreatment = 10,
  Sowing = 15,
  Seed = 20,
  Fertilization = 25,
  Fertilizer = 30,
  OwnSowing = 35,
  LivestockManure = 40,
  PlantProtection = 45,
  Weed = 50,
  OwnFertilization = 55,
  Pests = 60,
  OwnPlantProtection = 65,
  Diseases = 70,
  OwnHarvest = 75,
  GrowthRegulation = 80,
  OwnFieldTasks = 85,
  Harvest = 90,
  Forestry = 95,
  Yield = 100,
  OtherMachine = 105,
  Other = 110,
  TaskInStall = 115,
  PropertyTasks = 120,
  OtherTasks = 125,
  AgroChemicals = 1000,
  Machine = 1001,
  _E = 1002,
  _M = 1003,
  OwnTasks = 1004,
  OwnMachine = 1005,
  Employee = 1006,
  Machinery = 1007,
  ResourceMachine = 2100,
  ResourceEngine = 2200,
  ResourcePerson = 2300,
  ResourceTimeType = 2400,
}
