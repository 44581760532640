<div class="wrapper">
  <h2 mat-dialog-title>{{ 'messages.rememberSeptoriaDialog.headline' | translate }}</h2>
  <mat-dialog-content [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <div class="info-box">
      <p class="info-body">{{ 'messages.rememberSeptoriaDialog.body' | translate }}</p>
      <p class="info-note">{{ 'messages.rememberSeptoriaDialog.note' | translate }}</p>
      <img [appResponsiveClass]="{ mobile: 'lt-Medium' }" [attr.src]="'assets/images/prognoses/septremind.jpg'" />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-square-button buttonType="Primary" (click)="close()">
      {{ 'common.close' | translate }}
    </app-square-button>
  </mat-dialog-actions>
</div>
