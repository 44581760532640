import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Region } from '@app/core/interfaces/map-region-feature.interface';
import { Observable } from 'rxjs';

export interface IRegionRepo {
  /**
   * Gets regions from server
   */
  get(): Observable<Region[]>;
}

@Injectable({
  providedIn: 'root',
})
export class RegionRepo implements IRegionRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(): Observable<Region[]> {
    return this.http.get<Region[]>(this.endpoints.foApi + '/benchmark/yieldregions', {});
  }
}
