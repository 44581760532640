import { Component } from '@angular/core';
// Angular material
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@app/core/language/language.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-delete-field-modal',
  templateUrl: './delete-field-modal.component.html',
  styleUrls: ['./delete-field-modal.component.scss'],
})
export class DeleteFieldModalComponent {
  constructor(
    private languageService: LanguageService,
    private dialogRef: MatDialogRef<DeleteFieldModalComponent>,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public onCancelClicked(event: any): void {
    this.dialogRef.close(false);
  }

  public onConfirmClicked(event: any): void {
    this.dialogRef.close(true);
  }

  public get translateHeader() {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      map((year) =>
        this.languageService.getText('main.fieldAdministration.createField.areYouSure', {
          fieldName: !!this.dialogRef._containerInstance._config.data.fieldName
            ? `${this.dialogRef._containerInstance._config.data.number} ${this.dialogRef._containerInstance._config.data.fieldName}`
            : this.dialogRef._containerInstance._config.data.number,
          harvestYear: year,
        })
      )
    );
  }

  public get translateDescription() {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      map((year) => this.languageService.getText('main.fieldAdministration.createField.thisActionCannotBeReverted', { harvestYear: year }))
    );
  }
}
