import { Injectable, TemplateRef } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { SoilSampleLegend } from '@app/core/repositories/soil-samples/soil-sample-legend.class';
import { LocalState } from '@app/helpers/local-state';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { fieldAnalysisMapLayerIdxQueryParamKey } from '../features/field-analysis-features.component';
import { FieldAnalysisFeaturesActionsService } from '../features/selector/field-analysis-features.actions.service';
import { FieldAnalysisShownComponentEnum } from './field-analysis-shown-component.enum';

@Injectable({
  providedIn: 'root',
})
export class FieldAnalysisSideDrawerService {
  private shownComponentState = new LocalState<{ shownComponent: FieldAnalysisShownComponentEnum }>({
    shownComponent: FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent,
  });
  private legendSettingsState = new LocalState<{ legendSettings: ScaleLegendSettings } | null>(null);
  private soilSampleLegendState = new LocalState<{ soilSampleLegend: SoilSampleLegend } | null>(null);
  private drawerWidthSubject = new ReplaySubject<string>(1);
  private _sideDrawerFeatureContentRef!: TemplateRef<any>;

  private showLegendState = new LocalState<{ showLegend: boolean }>({
    showLegend: false,
  });

  public drawerWidth$: Observable<string>;
  public legendNotExcluded$ = new BehaviorSubject<boolean>(true);
  public shownComponentState$ = this.shownComponentState.changes$;
  public legendSettings$ = this.legendSettingsState.changes$;
  public soilSampleLegend$ = this.soilSampleLegendState.changes$;
  public showLegendState$ = this.showLegendState.changes$;

  constructor(
    private queryParamService: QueryParamService,
    private fieldAnalysisFeaturesActionsService: FieldAnalysisFeaturesActionsService
  ) {
    this.drawerWidth$ = this.drawerWidthSubject.asObservable();
  }

  public setShownComponentState(component: FieldAnalysisShownComponentEnum) {
    this.shownComponentState.setState({ shownComponent: component });
  }

  public setLegendSettingsState(legendSettings: ScaleLegendSettings) {
    // Promise resolve so angular detects changes on next microtask to prevent expression changed error.
    Promise.resolve()
      .then(() => this.legendSettingsState.setState({ legendSettings: legendSettings }))
      .catch((err) => console.error(err));
  }

  public setShowLegendState(showLegend: boolean) {
    Promise.resolve()
      .then(() => this.showLegendState.setState({ showLegend: showLegend }))
      .catch((err) => console.error(err));
  }

  public setSoilSampleLegendState(soilSampleLegend: SoilSampleLegend) {
    Promise.resolve()
      .then(() => this.soilSampleLegendState.setState({ soilSampleLegend: soilSampleLegend }))
      .catch((err) => console.error(err));
  }

  public setExcludedLegendState(shouldBeExcluded: boolean) {
    this.legendNotExcluded$.next(!shouldBeExcluded);
  }

  public set drawerWidth(width: string) {
    this.drawerWidthSubject.next(width);
  }

  public get sideDrawerFeatureContentRef() {
    return this._sideDrawerFeatureContentRef;
  }

  public set sideDrawerFeatureContentRef(ref: TemplateRef<any>) {
    this._sideDrawerFeatureContentRef = ref;
  }

  public changeSideDrawerContent(selectedMapFeatureId: MapLayerId) {
    switch (selectedMapFeatureId) {
      case 'rededgendvi':
        this.setShownComponentState(FieldAnalysisShownComponentEnum.NdviFeatureComponent);
        break;
      case 'drone-image-import':
        this.setShownComponentState(FieldAnalysisShownComponentEnum.DroneImageImportComponent);
        break;
      case 'soilsamples':
        this.setShownComponentState(FieldAnalysisShownComponentEnum.SoilSampleMapFeatureComponent);
        break;
      case 'as-applied':
        this.setShownComponentState(FieldAnalysisShownComponentEnum.AsAppliedComponent);
        break;
      default:
        break;
    }
  }

  public clearFieldAnalysisMapLayeridxQueryParam() {
    this.fieldAnalysisFeaturesActionsService.selectedMapCoverFlowItemIdxChanged(null);
    this.queryParamService.removeQueryParam(fieldAnalysisMapLayerIdxQueryParamKey);
  }
}
