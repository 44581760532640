import { Observable, pipe, UnaryFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export function filterNullOrEmpty<T>(): UnaryFunction<Observable<T | null | undefined>, Observable<T>> {
  return pipe(
    filter((x): x is T => {
      if (Array.isArray(x) && x.length < 1) return false;
      else return !!x;
    })
  );
}
