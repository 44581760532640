import { Component, OnInit } from '@angular/core';
import { SIDE_DRAWER_ANIMATION_SETTING } from '@app/map/features/side-drawer-const';

@Component({
  selector: 'app-feedback-side-drawer-content',
  templateUrl: './feedback-side-drawer-content.component.html',
  styleUrls: ['./feedback-side-drawer-content.component.scss'],
  animations: SIDE_DRAWER_ANIMATION_SETTING,
})
export class FeedbackSideDrawerContentComponent implements OnInit {
  public started!: boolean;

  constructor() {}

  public ngOnInit() {
    this.started = true;
  }
}
