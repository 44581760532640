import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoarseFodderColumn, FieldScore, FieldScoreResponse } from '@app/core/economy/interface/coarsefodder';
import { HarvestYearPickerMapService } from '@app/shared/harvest-year/harvest-year-picker-map/harvest-year-picker-map.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { EconomyColumnDataSource } from './../core/economy/interface/coarsefodder';
import { CoarsefodderRepoService } from './../core/economy/service/coarsefodder-repo.service';
import { SourceUtil } from './grid/sourceUtil';

interface EconomyStateServiceInterface {
  coarseFodderColumn$: Observable<CoarseFodderColumn[]>;

  hydrateCoarsefodderColumns(harvestYear: number): void;
}

@Injectable({
  providedIn: 'root',
})
export class EconomyStateService implements EconomyStateServiceInterface {
  private _coarseFodderColumnSubject = new BehaviorSubject<CoarseFodderColumn[]>([]);
  private _showSourcesSubject = new BehaviorSubject<boolean>(false);
  private _useFieldscoreSubject = new BehaviorSubject<boolean>(false);
  private _loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private coarsefodderRepoService: CoarsefodderRepoService,
    private farmStateService: FarmStateService,
    private harvestYearPickerMapService: HarvestYearPickerMapService,
    private globalStateService: GlobalStateService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public hydrateCoarsefodderColumns(harvestYear: number): void {
    this.farmStateService.selectedFarmIds$
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        filter((res) => !!res.length),
        first(),
        switchMap((farmIds) =>
          forkJoin([this.getCoarsefodderColumns(harvestYear, farmIds).pipe(first()), this.getDataSources(harvestYear, farmIds)]).pipe(
            first()
          )
        ),
        catchError((response: HttpErrorResponse) => {
          this.loading = false;
          this._coarseFodderColumnSubject.next([]);
          return throwError(response.error);
        })
      )
      .subscribe(([coarsefodderColums, dataSources]) => {
        coarsefodderColums.forEach((coarsefodder) => {
          coarsefodder.yield = SourceUtil.getYieldFromSources(dataSources, coarsefodder);

          coarsefodder.sources = dataSources.find(
            (datasource) => datasource.fieldCropId === coarsefodder.fieldCropId
          )!.coarseFodderEconomyDataSource;
        });
        this._coarseFodderColumnSubject.next(coarsefodderColums);
      });
  }

  public getCoarsefodderColumns(harvestYear: number, farmIds: number[]): Observable<CoarseFodderColumn[]> {
    return this.coarsefodderRepoService.getCoarsefodderFields(farmIds, harvestYear);
  }

  public getDataSources(harvestYear: number, farmIds: number[]): Observable<EconomyColumnDataSource[]> {
    return this.coarsefodderRepoService.getCoarsefodderDataSources(farmIds, harvestYear);
  }

  public saveCoarseFooderColumn(harvestYear: number, coarseFodderColumn: CoarseFodderColumn, rehydrateForm: boolean = false): void {
    this.coarsefodderRepoService.saveCoarseFodderColumn(coarseFodderColumn.farmId, harvestYear, coarseFodderColumn).subscribe(() => {
      if (rehydrateForm) {
        this.hydrateCoarsefodderColumns(harvestYear);
      }
    });
  }

  public saveFieldScore(
    harvestYear: number,
    fieldId: number,
    transportationCost: number,
    fieldDistance: number,
    manualCorrection: number,
    farmId: number
  ): Observable<FieldScore | null> {
    return this.coarsefodderRepoService.saveFieldScore(farmId, harvestYear, {
      fieldYearId: fieldId,
      distance: fieldDistance,
      transportCosts: transportationCost,
      sizeFormCorrection: manualCorrection,
    });
  }

  public getFieldScore(harvestYear: number, fieldId: number, farmId: number): Observable<FieldScoreResponse> {
    return this.coarsefodderRepoService.getFieldScore(farmId, harvestYear, fieldId);
  }

  public deleteFieldScore(farmId: number, harvestYear: number, fieldId: number) {
    this.loading = true;
    this.coarsefodderRepoService
      .deleteFieldScore(farmId, harvestYear, fieldId)
      .pipe(first())
      .subscribe((res) => {
        this.hydrateCoarsefodderColumns(harvestYear);
      });
  }

  public get coarseFodderColumn$(): Observable<CoarseFodderColumn[]> {
    return this._coarseFodderColumnSubject.asObservable();
  }

  public get selectableHarvestYears$(): Observable<number[]> {
    const year = this.harvestYearPickerMapService.getCurrentHarvestYear();
    return of([year, year - 1, year - 2, year - 3, year - 4, year - 5]);
  }

  public set selectedHarvestYear(harvestYear: number) {
    if (harvestYear) {
      this.harvestYearStateService.harvestYear = harvestYear;
    }
  }

  public set showSources(state: boolean) {
    this._showSourcesSubject.next(state);
  }

  public get showSources$(): Observable<boolean> {
    return this._showSourcesSubject.asObservable();
  }

  public set useFieldScore(state: boolean) {
    this._useFieldscoreSubject.next(state);
  }

  get useFieldScore$(): Observable<boolean> {
    return this._useFieldscoreSubject.asObservable();
  }

  public set loading(state: boolean) {
    this._loadingSubject.next(state);
  }

  public get loading$(): Observable<boolean> {
    return combineLatest([this._loadingSubject.asObservable(), this.globalStateService.combinedIsLoading$]).pipe(
      map(([loading, globalLoading]) => (globalLoading ? false : loading))
    );
  }
}
