<ng-container>
  <table class="as-applied-task-info-products-table">
    <thead>
      <tr>
        <th>{{ 'main.asApplied.taskInfo.productName' | translate }}</th>
        <th>{{ 'main.asApplied.taskInfo.quantity' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of appliedTask.executedTask!.products">
        <td>{{ product.productName }}</td>
        <td>{{ product.quantity | unit }} {{ product.unit }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
