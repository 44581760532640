<div class="main-field-administration-wrapper">
  <app-side-drawer-header
    class="flex-child"
    [title]="'main.fieldAdministration.editField' | translate"
    (action)="onCloseClick()"
    [showMinimizeButton]="true"
    (onHide)="onHideClick()"
  >
  </app-side-drawer-header>

  <app-side-drawer-body>
    <div class="buttons">
      <app-square-button
        data-testid="create-field"
        buttonType="Primary"
        (clicked)="onCreateClick()"
        [appShortcutKey]="shortcuts.create"
        (shortcutPressed)="onCreateClick()"
        [condition]="!dialog.openDialogs.length"
      >
        <app-icon icon="add"></app-icon>
        {{ 'main.fieldAdministration.createField.title' | translate }}
      </app-square-button>
      <app-square-button id="e2e-copy-fields" buttonType="Secondary" (clicked)="onCopyFieldsClick()">
        <app-icon icon="add"></app-icon>
        {{ 'main.fieldAdministration.copyField.copyFields' | translate }}
      </app-square-button>
      <app-square-button
        id="e2e-export-fields"
        buttonType="Secondary"
        (clicked)="onExportClick()"
        [appShortcutKey]="shortcuts.export"
        (shortcutPressed)="onExportClick()"
        [condition]="!dialog.openDialogs.length"
      >
        <app-icon icon="download"></app-icon>
        {{ 'main.fieldAdministration.exportFields.title' | translate }}
      </app-square-button>
    </div>
    <div class="help-text">
      <p>
        {{ 'main.fieldAdministration.createField.fieldAdministration.helpText.descriptionLine1' | translate }}
        <br />
        {{ 'main.fieldAdministration.createField.fieldAdministration.helpText.descriptionLine2' | translate }}
      </p>
    </div>
    <app-field-list></app-field-list>
  </app-side-drawer-body>
</div>
