import { Farm } from '@app/core/interfaces/farm.interface';

export function filterSelectableFarms(farmsToFilter: Farm[], selectedFarms: Farm[], searchFor: string): Farm[] {
  return !!searchFor
    ? farmsToFilter.filter((farm: { [prop: string]: any; id: number }) =>
        selectedFarms.find((selectedFarm) => farm.id === selectedFarm.id)
          ? // leave out the selected farms.
            false
          : // local search on the farm object for the given search term.

            Object.keys(farm).some((key) => farm[key].toString().toLowerCase().indexOf(searchFor.toLowerCase()) > -1)
      )
    : // no search term, just return the farms minus selected ones
      farmsToFilter.filter((farm) => !selectedFarms.find((selectedFarm) => farm.id === selectedFarm.id));
}
