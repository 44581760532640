import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * A directive that applies CSS classes to an element based on viewport size, utilizing Angular Material breakpoint observations.
 * It supports direct breakpoint matching, as well as "less than" and "greater than" conditions for responsive class application.
 *
 * Usage:
 * ```html
 * <div [appResponsiveClass]="{ 'below-large': 'lt-Large', 'above-medium': 'gt-Medium' }">
 *   <!-- Content here will have:
 *        'below-large' class applied when the viewport width is less than the 'Large' breakpoint,
 *        'above-medium' class applied when the viewport width is greater than the 'Medium' breakpoint -->
 * </div>
 * ```
 *
 * The `config` input object maps CSS class names to conditions based on Angular Material `Breakpoints` aliases.
 * Conditions can be specified as direct breakpoint matches (e.g., 'Medium'), 'lt-' (less than) prefixed for widths below a breakpoint
 * (e.g., 'lt-Large'), or 'gt-' (greater than) prefixed for widths above a breakpoint (e.g., 'gt-Small').
 * A class is added to the element when its condition matches the current viewport size and removed when it no longer matches.
 */
@Directive({
  selector: '[appResponsiveClass]',
})
export class ResponsiveClassDirective implements OnInit, OnDestroy {
  @Input('appResponsiveClass') config!: { [klass: string]: string };
  private subscription = new Subscription();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    Object.entries(this.config).forEach(([klass, condition]) => {
      this.applyClassBasedOnCondition(klass, condition);
    });
  }

  private applyClassBasedOnCondition(klass: string, condition: string): void {
    let query: string;
    let breakpointAlias: string;
    let hasPrefix = false;

    if (condition.startsWith('lt-') || condition.startsWith('gt-')) {
      breakpointAlias = condition.substring(3);
      hasPrefix = true;
    } else {
      breakpointAlias = condition;
    }

    const breakpoint = Breakpoints[breakpointAlias as keyof typeof Breakpoints];
    if (!breakpoint) {
      console.warn(`Breakpoint alias ${breakpointAlias} not recognized.`);
      return;
    }

    if (hasPrefix) {
      const boundaries = this.extractBoundaries(breakpoint);
      if (condition.startsWith('lt-')) {
        query = `(max-width: ${boundaries?.min}px)`;
      } else {
        // condition starts with 'gt-'
        query = `(min-width: ${boundaries?.max}px)`;
      }
    } else {
      query = breakpoint;
    }

    if (!query) {
      console.warn(`Breakpoint condition ${condition} not recognized.`);
      return;
    }

    const sub = this.breakpointObserver.observe([query]).subscribe((result) => {
      if (result.matches) {
        this.renderer.addClass(this.el.nativeElement, klass);
      } else {
        this.renderer.removeClass(this.el.nativeElement, klass);
      }
    });

    this.subscription.add(sub);
  }

  private extractBoundaries(breakpoint: string): { min: number; max: number } | undefined {
    const minMatch = breakpoint.match(/min-width: (\d+(\.\d+)?)px/);
    const maxMatch = breakpoint.match(/max-width: (\d+(\.\d+)?)px/);

    if (minMatch && maxMatch) {
      return {
        min: parseFloat(minMatch[1]),
        max: parseFloat(maxMatch[1]),
      };
    }
    return undefined;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
