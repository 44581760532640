<mat-form-field [ngClass]="{ simple: simple }">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    [attr.name]="name"
    matInput
    [type]="localize ? 'text' : type"
    autocomplete="{{ autocomplete ? 'on' : 'off' }}"
    [min]="min"
    [max]="max"
    [step]="step"
    [readonly]="readonly"
    [placeholder]="placeholder!"
    [errorStateMatcher]="errorStateMatcher!"
    (ngModelChange)="onChange($event)"
    [ngModel]="value"
    [disabled]="disabled"
    (blur)="onBlur()"
    [maxlength]="maxlength!"
    [attr.data-testid]="testId"
  />
  <mat-error *ngFor="let errorMsg of errorMsgs">
    <ng-container [ngTemplateOutlet]="errorMsg.templateRef!"></ng-container>
  </mat-error>
</mat-form-field>
