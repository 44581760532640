/**
 * Colors from the CropManager color scheme.
 * If a required color is missing, add it here.
 */
export enum Colors {
  Blue1 = '#076471',
  Blue2 = '#4e808d',
  Gray1 = '#646464',
  Gray5 = '#F1F1F1',
  Orange1 = '#e95d0f',
  Green4 = '#B4C5B0',
  Yellow4 = '#FDF4C9',
  Red4 = '#FDCBBE',
  Black = '#000000',
  White = '#FFFFFF',
  Transparent = 'transparent',
}
