import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tooltip-overlay',
  templateUrl: './tooltip-overlay.component.html',
  styleUrls: ['./tooltip-overlay.component.scss'],
})
export class TooltipOverlayComponent implements OnInit, OnDestroy {
  @Input() public tooltipTemplate?: TemplateRef<any>;
  @Input() public tooltipTitle = '';
  @Input() public size: 'sm' | 'md' = 'sm';

  private overlayRef?: OverlayRef;
  private backdropClickSubscription = new Subscription();

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {}

  public ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
      },
    ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      maxWidth: this.size === 'sm' ? '320px' : '600px',
      minHeight: this.size === 'sm' ? '100px' : '200px',
      panelClass: ['mat-elevation-z2', 'tooltip-overlay'], // located in ng-service.scss
      backdropClass: ['tooltip-overlay-backdrop'], // located in ng-service.scss
    });

    this.backdropClickSubscription = this.overlayRef.backdropClick().subscribe(() => this.toggle());
  }

  public ngOnDestroy() {
    this.backdropClickSubscription.unsubscribe();
  }

  public toggle() {
    const templatePortal = new TemplatePortal(this.tooltipTemplate!, this.viewContainerRef);

    this.overlayRef?.hasAttached() ? this.overlayRef.detach() : this.overlayRef?.attach(templatePortal);
  }
}
