export enum BasisLayerCategoryColors {
  unknown = '#FBFB3D',
  hills = '#C27700',
  hollow = '#147EA8',
  windbreaks = '#36B13A',
  headland = '#BCCC7A',
  waterlogged = '#69D6EC',
  sandySpots = '#EEF3A5',
  other = '#FBFB3D',
}
