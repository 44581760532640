<app-dialog-content size="md">
  <p class="title">{{ title }}</p>
  <div
    class="sources"
    *ngFor="let source of sources"
    (click)="onSourceClick(source.dataSource)"
    [ngClass]="{ 'selected-source': source.dataSource === selectedSource }"
  >
    <span class="source-title"> {{ 'main.economy.sources.' + source.dataSource | translate }}</span>
    <span class="source-value">{{ source.dataSourceValue | number: '1.0-0' }}</span>
    <app-icon class="selected-icon" icon="check" *ngIf="source.dataSource === selectedSource; else notSelected"></app-icon>
    <ng-template #notSelected>
      <span class="empty"></span>
    </ng-template>
    <span class="source-tab" [style]="getStyleForSource(source)"></span>
  </div>
</app-dialog-content>
