import { AfterContentInit, Component, ContentChildren, forwardRef, Input, OnInit, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputErrorStateMatcher } from '@app/shared/input/input-error-state-matcher.class';
import { InputErrorComponent } from '@app/shared/input/input-error/input-error.component';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  @Input() public placeholder = '';
  public touched = false;
  public disabled = false;
  public value = '';
  public errorStateMatcher = new InputErrorStateMatcher(this.errorMsgs);

  @ContentChildren(InputErrorComponent)
  public errorMsgs?: QueryList<InputErrorComponent>;

  constructor() {}

  public ngAfterContentInit(): void {
    setTimeout(() => this.errorMsgs && (this.errorStateMatcher = new InputErrorStateMatcher(this.errorMsgs)));
  }

  public propagateChange: any = (_: any) => {};

  public onTouched: any = (_: any) => {};

  public ngOnInit() {}

  public onChange(event: any) {
    this.propagateChange(event);
  }

  public writeValue(obj: any): void {
    this.propagateChange(obj);
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    const self = this;
    this.onTouched = (arg: any) => {
      self.touched = true;
      fn(arg);
    };
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
