import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @ViewChild('errorMessageRef', { static: false }) public templateRef?: TemplateRef<any>;
}
