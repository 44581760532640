import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { ReadonlyMapWithDefault } from '@app/shared/map/map-with-default';

export type AllGroupsColumns = readonly ['date', 'products', 'crop', 'loading'];
export type ActiveFieldColumns = readonly ['date', 'products', 'group', 'loading'];
export type LimeColumns = readonly ['date', 'products', 'loading'];

export type VraTaskTableColumns = AllGroupsColumns | ActiveFieldColumns | LimeColumns;

export const TASK_TABLE_COLUMNS_BY_OPERATION_TYPE_GROUP = new ReadonlyMapWithDefault<OperationTypeGroupEnum, VraTaskTableColumns>(
  // default columns for all operation type groups
  ['date', 'products', 'crop', 'loading'],
  [[OperationTypeGroupEnum.Lime, ['date', 'products', 'loading']]]
);
