import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

export class DroneImageUploadState {
  private _totalImages = new ReplaySubject<number>(1);
  private _uploadedImages = new BehaviorSubject<number>(0);
  private _totalFields = new ReplaySubject<number>(1);
  private _uploadedFields = new BehaviorSubject<number>(0);
  private _onUploadComplete = new ReplaySubject<void>(1);

  public get totalImages$(): Observable<number> {
    return this._totalImages.asObservable();
  }

  public set totalImages(totalImages: number) {
    this._totalImages.next(totalImages);
  }

  public get uploadedImages$(): Observable<number> {
    return this._uploadedImages.asObservable();
  }

  public onImageUploaded() {
    this._uploadedImages.pipe(first()).subscribe((imagesUploaded) => this._uploadedImages.next(++imagesUploaded));
  }

  public get totalFields$(): Observable<number> {
    return this._totalFields.asObservable();
  }

  public set totalFields(totalFields: number) {
    this._totalFields.next(totalFields);
  }

  public get uploadedFields$(): Observable<number> {
    return this._uploadedFields.asObservable();
  }

  public onFieldUploaded() {
    this._uploadedFields.pipe(first()).subscribe((fieldsUploaded) => this._uploadedFields.next(++fieldsUploaded));
  }

  public get onUploadComplete$() {
    return this._onUploadComplete.asObservable();
  }

  public completeUpload() {
    this._onUploadComplete.next();
  }
}
