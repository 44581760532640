import { Injectable } from '@angular/core';
import { Field, FieldCollection, FieldDetails } from '@app/core/interfaces/field.interface';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { FarmLockedService } from '@app/shared/farm-locked/farm-locked.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Farm } from '../interfaces/farm.interface';

export interface IFieldService {
  setMainCropsForFields(fields: Array<Field>): Array<Field>;
  getField(farmId: number, harvestYear: number, fieldId: number): Observable<Field>;
  getAllFields(farmIds: Array<number>, harvestYear: number): Observable<Array<Field> | null>;
  getByFieldIds(farmIds: number[], harvestYear: number, fieldIds: number[]): Observable<Field[]>;
  getByFieldFeatureIds(farmIds: number[], harvestYear: number, fieldFeatureIds: number[]): Observable<Field[]>;
  getFieldsWithMetadata(farmIds: Array<number>, harvestYear: number): Observable<FieldCollection>;
  getValidFields(farmId: number, harvestYear: number): Observable<Array<Field>>;
  getFieldDetails(farmId: number, harvestYear: number, fieldId: number): Observable<FieldDetails>;
  decorateWithMainCrop(field: Field): Field;
  getFieldsWithSimilarTask(farmIds: number[], harvestYear: number, taskId: number): Observable<Field[]>;
  getFieldsWithSimilarCrop(farmIds: number[], harvestYear: number, fieldId: number): Observable<Field[]>;
  getFieldBlockPolygon(harvestYear: number, latitude: number, longitude: number, farms: Farm[]): Observable<string | null>;
  getFieldPreCrops(farmId: number, harvestYear: number, fieldMultiYearId: number, maxHarvestYears: number): Observable<Field[]>;
  deleteField(farmId: number, fieldId: number): Observable<number>;
}

/**
 * Field Service
 * Logic related to fields is put here.
 */
@Injectable({
  providedIn: 'root',
})
export class FieldService implements IFieldService {
  constructor(
    private fieldsRepo: FieldsRepo,
    private farmLockedService: FarmLockedService
  ) {}

  public getField(farmId: number, harvestYear: number, fieldId: number): Observable<Field> {
    return this.fieldsRepo.getField(farmId, harvestYear, fieldId);
  }

  public getFieldPreCrops(farmId: number, harvestYear: number, fieldMultiYearId: number, maxHarvestYears: number): Observable<Field[]> {
    return this.fieldsRepo.getFieldPreCrops(farmId, harvestYear, fieldMultiYearId, maxHarvestYears);
  }

  /**
   * Gets Fields for provided farms and harvest year
   * @param farmIds Farm ids to get fields by
   * @param harvestYear Harvest year to get fields by
   */
  public getAllFields(farmIds: number[], harvestYear?: number): Observable<Field[] | null> {
    return this.fieldsRepo.getAllFields(farmIds, harvestYear);
  }

  public getByFieldIds(farmIds: number[], harvestYear: number, fieldIds: number[]): Observable<Field[]> {
    return this.getAllFields(farmIds, harvestYear).pipe(map((fields) => fields!.filter((field) => fieldIds.indexOf(field.id) > -1)));
  }

  public getByFieldFeatureIds(farmIds: number[], harvestYear: number, fieldFeatureIds: number[]): Observable<Field[]> {
    return this.getAllFields(farmIds, harvestYear).pipe(
      map((fields) => fields!.filter((field) => fieldFeatureIds.indexOf(field.featureId) > -1))
    );
  }

  /**
   * Gets field information for provided farms and harvest year
   * @param farmIds Farm id's to get field information by
   * @param harvestYear Harvest year to get field information by
   */
  public getFieldsWithMetadata(farmIds: number[], harvestYear?: number): Observable<FieldCollection> {
    return this.fieldsRepo.getFieldsWithMetadata(farmIds, harvestYear);
  }

  /**
   * Gets validated fields
   * @param farmId Farm id to get valid fields by
   * @param harvestYear  Harvest year to get valid fields by
   */
  public getValidFields(farmId: number, harvestYear: number): Observable<Field[]> {
    return this.fieldsRepo.getValidFields(farmId, harvestYear);
  }

  /**
   * Gets details about specified field
   * @param farmId Field id
   * @param harvestYear Harvest year
   * @param fieldId Field id to get details by
   */
  public getFieldDetails(farmId: number, harvestYear: number, fieldId: number): Observable<FieldDetails> {
    return this.fieldsRepo.getFieldDetails(farmId, harvestYear, fieldId);
  }

  /**
   * Finds the main crop of all fields by looking at the succession number.
   * Lowest succession number is the main crop.
   * The main crop will be written in the mainCropName and mainCropId properties of the field.
   *
   * @param fields The list of fields to find
   */
  public setMainCropsForFields(fields: Array<Field> | null): Array<Field> {
    if (!fields) return [];
    return fields.map((field) => this.setMainCropForField(field));
  }

  public setMainCropForField(field: Field): Field {
    if (!field.crops || field.crops.length === 0) {
      return field;
    }

    const mainCrop = field.crops.sort((a, b) => a.successionNo - b.successionNo)[0];

    field.mainCropName = mainCrop.cropName;
    field.mainCropId = mainCrop.id;
    field.mainCropNormNumber = mainCrop.cropNormNumber;

    return field;
  }

  public setIsReadOnlyFields(fields: Array<Field> | null): Array<Field> {
    if (!fields) return [];
    return fields.map((field) => this.setIsReadOnlyField(field));
  }

  public setIsReadOnlyField(field: Field): Field {
    field.isReadOnly = this.farmLockedService.isFieldsFarmLockedInSelectedYear(field);

    return field;
  }
  public decorateWithMainCrop(field: Field): Field {
    return this.setMainCropsForFields([field])[0];
  }

  public getFieldsWithSimilarTask(farmIds: number[], harvestYear: number, taskId: number) {
    return this.fieldsRepo.getFieldsWithSimilarTask(farmIds, harvestYear, taskId);
  }

  public getFieldsWithSimilarCrop(farmIds: number[], harvestYear: number, fieldId: number, cropNormNumber?: string) {
    return this.fieldsRepo.getFieldsWithSimilarCrop(farmIds, harvestYear, fieldId, cropNormNumber);
  }

  public subtractPolygon(
    fieldPolygon: string | null,
    polygonToSubtract: string | null,
    farms: Farm[],
    fieldId: number | null,
    harvestYear?: number
  ) {
    const requests: Observable<string | null>[] = farms.map((farm) =>
      this.fieldsRepo.subtractPolygon(farm.id, fieldPolygon, polygonToSubtract, fieldId, harvestYear)
    );

    // the polygon should be the same for each farm id, so just return the first.
    return forkJoin(requests).pipe(map((polygonWkts) => polygonWkts[0]));
  }

  public validatePolygon(farms: Farm[], polygon: string, fieldId: number | null, harvestYear?: number): Observable<boolean> {
    const requests = farms.map((farm) =>
      fieldId
        ? this.validateExistingFieldPolygon(farm.id, polygon, fieldId, harvestYear)
        : this.validateNewFieldPolygon(farm.id, polygon, harvestYear)
    );

    return forkJoin(requests).pipe(map((isValidArr: (boolean | null)[]) => isValidArr.every((isValid) => isValid === true)));
  }

  public getPolygonShapefile(farmId: number, polygonIds: number[], harvestYear: number) {
    return this.fieldsRepo.getPolygonShapefile(farmId, polygonIds, harvestYear);
  }

  public getFieldBlockPolygon(harvestYear: number, latitude: number, longitude: number, farms: Farm[]): Observable<string | null> {
    return this.fieldsRepo.getFieldBlockPolygon(
      harvestYear,
      latitude,
      longitude,
      farms.map((farm) => farm.id)
    );
  }

  public deleteField(farmId: number, fieldId: number): Observable<number> {
    return this.fieldsRepo.delete(farmId, fieldId);
  }

  private validateNewFieldPolygon(farmId: number, polygon: string, harvestYear?: number) {
    return forkJoin([
      this.fieldsRepo.validatePolygon(farmId, polygon, null, harvestYear),
      this.fieldsRepo.validatePolygon(farmId, polygon, null, harvestYear! - 1),
    ]).pipe(
      map(([currentYear, previousYear]) => currentYear && previousYear),
      catchError(() => of(false))
    );
  }

  private validateExistingFieldPolygon(farmId: number, polygon: string, fieldId: number, harvestYear?: number) {
    return this.fieldsRepo.validatePolygon(farmId, polygon, fieldId, harvestYear).pipe(catchError(() => of(false)));
  }
}
