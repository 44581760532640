import { Injectable } from '@angular/core';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapStateService } from '@app/state/services/map/map-state.service';

@Injectable({
  providedIn: 'root',
})
export class FieldPlanMapSideDrawerActions {
  constructor(private mapStateService: MapStateService) {}

  public setMapCoverFlowItems(mapCoverFlowItems: MapCoverFlowItem[]) {
    return (this.mapStateService.mapCoverFlowItems = mapCoverFlowItems);
  }

  public setMapOptions(isLegendVisible: boolean, isSettingsVisible: boolean) {
    return (this.mapStateService.fieldMapOptions = {
      isLegendVisible: isLegendVisible,
      isSettingsVisible: isSettingsVisible,
    });
  }

  public setFieldPlanLegendCropColors(cropColors: CropColor[]) {
    return (this.mapStateService.fieldInspectorMapLegendCropColors = cropColors);
  }
}
