import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { LanguageService } from '@app/core/language/language.service';
import { AlertComponent } from '@app/shared/notifications/alert/alert.component';
import { BrowserRecommendationComponent } from '@app/shared/notifications/browser-recommendation/browser-recommendation.component';
import { DialogLinkComponent } from '@app/shared/notifications/dialog-link/dialog-link.component';
import { LinkAlertComponent } from '@app/shared/notifications/link-alert/link-alert.component';
import { SubscriptionsDialogComponent } from '@app/subscriptions/subscriptions-dialog/subscriptions-dialog.component';

@Injectable()
export class NotificationService {
  constructor(
    private languageService: LanguageService,
    private snackBar: MatSnackBar
  ) {}

  public showError(message: string, duration = 5000) {
    if (message) {
      this.show(message, duration, 'error-notification-snackbar', 'cancel');
    }
  }

  public showTranslatedError(message: string, duration = 5000) {
    if (message) {
      this.show(this.languageService.getText(message), duration, 'error-notification-snackbar', 'cancel');
    }
  }

  /**
   * Show error notification with a link.
   * @param message The message to show.
   * @param linkTitle The text shown on the link
   * @param link The name of the component to route to, ex '/settings'
   * @param duration Visible duration in milliseconds. Set to 0 for infinite duration.
   */
  public showErrorWithLink(message: string, linkTitle: string, link: string, duration = 5000) {
    this.showCustomNotification<LinkAlertComponent>(LinkAlertComponent, {
      panelClass: 'error-notification-snackbar',
      duration: duration >= 0 ? duration : undefined,
      data: {
        icon: 'cancel',
        text: message,
        link,
        linkTitle,
      },
    });
  }

  public showInfoWithSubscriptionDialogLink(message: string, linkTitle: string, duration = 5000) {
    const options = {
      panelClass: 'subscriptions-dialog',
      minWidth: '52.5vw',
      autoFocus: false,
      disableClose: true,
    };
    const panelClass = 'info-notification-snackbar';
    this.showMessageWithDialogLink(message, linkTitle, panelClass, SubscriptionsDialogComponent, options, duration);
  }

  private showMessageWithDialogLink(
    message: string,
    linkTitle: string,
    panelClass: string,
    dialogComponent: ComponentType<any>,
    options: MatDialogConfig,
    duration = 5000
  ) {
    this.showCustomNotification<DialogLinkComponent>(DialogLinkComponent, {
      panelClass,
      duration: duration >= 0 ? duration : undefined,
      data: {
        icon: 'cancel',
        text: message,
        linkTitle,
        dialogComponent,
        options,
      },
    });
  }

  public showInfoWithLink(message: string, linkTitle: string, link: string, duration = 5000) {
    this.showCustomNotification<LinkAlertComponent>(LinkAlertComponent, {
      panelClass: 'info-notification-snackbar',
      duration: duration >= 0 ? duration : undefined,
      data: {
        icon: 'info_outline',
        text: message,
        link,
        linkTitle,
      },
    });
  }

  public showSuccess(message: string, duration = 5000) {
    this.show(message, duration, 'success-notification-snackbar', 'done_outlined');
  }

  public showInfo(message: string, duration = 5000) {
    this.show(message, duration, 'info-notification-snackbar', 'info_outline');
  }

  public showWarning(message: string, duration = 5000) {
    this.show(message, duration, 'warning-notification-snackbar', 'warning_outline');
  }

  public showCreated(element: string) {
    const elementTranslation = this.languageService.getText(element);
    const message = this.languageService.getText('common.toasts.created', { element: elementTranslation });
    this.showSuccess(message);
  }

  public showUpdated(element: string) {
    const elementTranslation = this.languageService.getText(element);
    const message = this.languageService.getText('common.toasts.updated', { element: elementTranslation });
    this.showSuccess(message);
  }

  public showDeleted(element: string) {
    const elementTranslation = this.languageService.getText(element);
    const message = this.languageService.getText('common.toasts.deleted', { element: elementTranslation });
    this.showSuccess(message);
  }

  public show(message: string, duration = 5000, panelClass: string, icon: string) {
    this.showCustomNotification<AlertComponent>(AlertComponent, {
      panelClass,
      duration: duration >= 0 ? duration : undefined,
      data: {
        icon,
        text: message,
      },
    });
  }

  public showBrowserRecommendation() {
    this.showCustomNotification<BrowserRecommendationComponent>(BrowserRecommendationComponent);
  }

  private showCustomNotification<T>(component: ComponentType<T>, options?: MatSnackBarConfig) {
    this.snackBar.openFromComponent(component, options);
  }

  public dismiss() {
    this.snackBar.dismiss();
  }
}
