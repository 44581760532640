import { Injectable } from '@angular/core';
import { BrowserDetectorService } from '@app/core/browser-detector/browser-detector.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Farm } from '@app/core/interfaces/farm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MapService } from '@app/core/map/map.service';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { LocalState } from '@app/helpers/local-state';
import { UserSelector } from '@app/state/selectors/user.selector.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DebugInformation } from './models/debug-info.interface';

@Injectable({
  providedIn: 'root',
})
export class DebugInfoService {
  // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public info$ = new LocalState<DebugInformation>(null);
  private _info!: DebugInformation;

  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService,
    private windowRef: WindowRefService,
    private userSelector: UserSelector,
    private harvestYearStateService: HarvestYearStateService,
    private languageService: LanguageService,
    private browserDetector: BrowserDetectorService,
    private mapService: MapService,
    private farmStateService: FarmStateService
  ) {
    this.setDebugInfo();
  }

  private setDebugInfo() {
    this._info = {
      time: DateTime.now(),
      apiVersion: this.apiVersion,
      user: this.userSelector.currentUser$,
      subscription: this.userSelector.formattedSubscriptionLevel$,
      aspectRatio: this.windowResolution,
      selectedFarms: this.farmStateService.selectedFarms$.pipe(map((f: Farm[]) => f.map((ff) => ` ${ff.name} (${ff.id})`))),
      harvestYear: this.harvestYearStateService.harvestYear$,
      language: this.languageService.currentLanguage.name,
      browser: this.browserDetector.getVendorAndVersion(),
      device: this.browserDetector.getOperatingSystem(),
      zoomLevel: this.zoomLevel,
      mapZoomLevel: this.mapZoomLevel,
      currentUrl: this.currentUrl,
    } as DebugInformation;
    this.info$.setState(this._info);
  }

  private get apiVersion(): Observable<string> {
    return this.httpClient.get(`${this.endpoints.foApi}/applicationstatus/assemblyversion`).pipe(map((r) => r as string));
  }

  private get windowResolution(): string {
    return `${this.windowRef.nativeWindow.innerWidth}x${this.windowRef.nativeWindow.innerHeight}`;
  }

  private get zoomLevel(): string {
    return `${Math.round(this.windowRef.nativeWindow.devicePixelRatio * 100)}%`;
  }

  private get currentUrl(): string {
    return this.windowRef.nativeWindow.location.href;
  }

  private get mapZoomLevel(): number {
    if (this.mapService.getMap()) {
      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      return this.mapService.getMap().getMap().getView().getZoom();
    } else {
      return 0;
    }
  }
}
