import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '@app/shared/dialog/confirm-dialog-portal/confirm-dialog-data';
import { ConfirmDialogPortalComponent } from '@app/shared/dialog/confirm-dialog-portal/confirm-dialog-portal.component';
import { DirtyCheckDialogAction } from './dirty-check-dialog/dirty-check-actions.class';
import { DirtyCheckDialogComponent } from './dirty-check-dialog/dirty-check-dialog.component';

export interface DialogCbs {
  onDialogConfirmed?: (confirmCbArg: (data?: any) => void) => DialogCbs;
  onDialogCancelled?: (cancelDataArg: () => void) => DialogCbs;
}

const defaultDialogData = {
  title: 'common.confirmDialog.cancelTitle',
  text: 'common.confirmDialog.cancelText',
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  public openNativeConfirmDialog(data: ConfirmDialogData = defaultDialogData): MatDialogRef<any> {
    return this.dialog.open(ConfirmDialogPortalComponent, { data });
  }

  public openDirtyCheckDialog() {
    return this.dialog.open<DirtyCheckDialogComponent, void, DirtyCheckDialogAction>(DirtyCheckDialogComponent).afterClosed();
  }

  public openConfirmDialog(data: ConfirmDialogData = defaultDialogData, disableClose?: boolean): DialogCbs {
    const dialogRef = this.dialog.open(ConfirmDialogPortalComponent, {
      data: data,
      disableClose: disableClose,
    });

    let confirmCb = (confirmdata: any) => {};

    let cancelCb = (cancelData: any) => {};

    const cbObj: DialogCbs = {
      onDialogConfirmed: (confirmCbArg: (confirmdata: any) => void) => {
        confirmCb = confirmCbArg;
        return cbObj;
      },
      onDialogCancelled: (cancelDataArg: () => void) => {
        cancelCb = cancelDataArg;
        return cbObj;
      },
    };

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        confirmCb(result);
      } else {
        cancelCb(result);
      }
    });

    return cbObj;
  }

  public openInfoDialog<T>(component: ComponentType<T>) {
    this.dialog.open(component, {
      maxWidth: '800px',
    });
  }

  public openCustomDialog<T, D = unknown>(component: ComponentType<T>, options: MatDialogConfig<D> = {}) {
    if (!options.maxWidth) {
      options.maxWidth = '800px';
    }

    return this.dialog.open(component, options);
  }

  public openLg<T, R, U>(component: ComponentType<T>, options: MatDialogConfig = {}) {
    options.maxWidth = '1100px';
    options.minHeight = '400px';
    options.panelClass = 'dialog-panel';

    return this.dialog.open<T, R, U>(component, options);
  }

  public openMd<T>(component: ComponentType<T>, options: MatDialogConfig = {}) {
    options.maxWidth = '600px';
    options.minHeight = '200px';
    options.panelClass = 'dialog-panel';

    return this.dialog.open(component, options);
  }

  public openSm<T>(component: ComponentType<T>, options: MatDialogConfig = {}) {
    options.maxWidth = '350px';
    options.minWidth = '200px';
    options.minHeight = '150px';
    options.panelClass = 'dialog-panel';

    return this.dialog.open(component, options);
  }
}
