<div class="side-nav-overlay" [ngClass]="{ open: isMenuOpen }" (click)="onMenuOverlayClicked()"></div>

<a
  class="mat-elevation-z2 menu-fab"
  data-testid="menu-fab"
  (click)="onMenuBtnClicked()"
  [appResponsiveClass]="{ greaterThanSmall: 'gt-Small' }"
>
  <app-icon icon="menu"></app-icon>
</a>
