import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NToolDialogComponent } from '@app/shared/n-tool/dialog/n-tool-dialog.component';
import { NToolComponent } from '@app/shared/n-tool/n-tool.component';
import { FutureTasksPipe } from '@app/shared/n-tool/pipes/future-tasks-pipe';
import { PastTasksPipe } from '@app/shared/n-tool/pipes/past-tasks-pipe';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [NToolDialogComponent, NToolComponent, PastTasksPipe, FutureTasksPipe],
  imports: [CommonModule, SharedModule],
  exports: [NToolDialogComponent, NToolComponent],
})
export class NToolModule {}
