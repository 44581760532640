import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { MapConfig } from '@app/core/interfaces/map-config.interface';
import { MapZoomStateService } from '@app/core/map-zoom-state/map-zoom-state.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { getStyle } from '@app/new-map/helpers/styles/map-styles';
import { OlMapService } from '@app/new-map/map-service/ol-map.service';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { SelectEvent } from 'ol/interaction/Select';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PrognosisShownComponent } from '../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisSideDrawerComponent } from '../prognosis-side-drawer/prognosis-side-drawer.component';
import { PrognosisService } from '../prognosis.service';
import { GrowthRegulationVM } from './growth-regulation-vm.class';
import { GrowthRegulationService } from './growth-regulation.service';
import { GrowthRegulation } from './interfaces/growth-regulation.interface';

@Component({
  selector: 'app-growth-regulation',
  templateUrl: './growth-regulation.component.html',
  styleUrls: ['./growth-regulation.component.scss'],
})
export class GrowthRegulationComponent implements OnInit, OnDestroy {
  @Input() public prognosisFeature!: MapCoverFlowItem | null;

  public isLegendVisible$ = this.screenSizeService.isMobile().pipe(
    map((isMobile) => {
      return isMobile ? isMobile && this.sideDrawerRef.hidden : true;
    })
  );

  public growthRegulation?: GrowthRegulation;
  public field?: Field;
  public isGrowthegulationsEmpty = true;
  public growthRegulationVm$ = this.growthRegulationService.growthRegulationVm$;
  public loadingState = this.prognosisService.mapLoadingState;

  public mapConfig: MapConfig = {
    airphotoMaxZoomLevel: 12,
    center: this.mapStateService.fieldMapCenter,
    defaultZoom: this.mapStateService.fieldMapZoom,
    maxZoom: 19,
    minZoom: 0,
    shouldShowAirphoto: true,
  };

  public sideDrawerWidth$ = this.prognosisService.drawerWidth$;
  private subscriptions = new Subscription();

  constructor(
    private mapStateService: MapZoomStateService,
    private farmStateService: FarmStateService,
    private _mapService: OlMapService,
    private _layerService: OlLayerService,
    private growthRegulationService: GrowthRegulationService,
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private screenSizeService: ScreenSizeService,
    private harvestYearStateService: HarvestYearStateService,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit() {
    this._mapService.mapReady$.pipe(first()).subscribe(() => {
      this.handleMapReady();
    });
    this._layerService.setLayerVisibility(LayerId.FIELDS, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_FILL, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, false);
    this._layerService.setActivePage('prognosis_growth');
  }

  public ngOnDestroy() {
    this._layerService.setLayerVisibility(LayerId.FIELDS, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_FILL, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, true);
    this._mapService.deselectFeatures();
    this.subscriptions.unsubscribe();
  }

  public handleMapReady() {
    setTimeout(() => {
      this.setupSubscriptions();
    });
  }

  public onSelectedFarmsChange() {
    this.resetSelections();
  }

  public onCloseSideDrawer() {
    this.resetSelections();
  }

  public onCloseClick() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.PrognosisPicker);
  }

  public onHideClick() {
    this.sideDrawerRef.hide();
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  public onGrowthRegulationSelect(growthRegulation: GrowthRegulation) {
    this.growthRegulation = growthRegulation;
  }

  public onInsuranceClick() {
    this._mapService.disableSelectInteraction();
    this.prognosisService.setShownComponent(PrognosisShownComponent.GrowthRegulationInsurance);
  }

  public onFieldSelect(field: Field) {
    this.field = field;
    this.sideDrawerRef.show();
  }

  public onEmptyGrowthRegulations(isEmpty: boolean) {
    this.isGrowthegulationsEmpty = isEmpty;
  }

  public resetSelections() {
    this._mapService.deselectFeatures();
  }
  private setupSubscriptions() {
    this.subscriptions.add(
      this._mapService

        .addSelectInteraction(false, [LayerId.GROWTH_REGULATION], [])
        .pipe(
          tap((event) => {
            setTimeout(() => {
              event.deselected.forEach((feature) => {
                feature.setStyle(getStyle(feature.get('features') ? feature.get('features')[0] : feature));
              });
              event.selected.forEach((feature) => {
                feature.setStyle(getStyle(feature.get('features') ? feature.get('features')[0] : feature));
              });
            });
          }),
          map((event) => this.convertSelectEventToField(event))
        )
        .subscribe((selected) => {
          this.onFieldSelect(selected.field);
          this.onGrowthRegulationSelect(selected.growthRegulation);
          this._mapService.magicReRender();
        })
    );

    this.subscriptions.add(this.farmStateService.selectedFarms$.pipe(distinctUntilChanged()).subscribe(() => this.onSelectedFarmsChange()));
    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.subscribe(() => {
        this.field = undefined;
      })
    );

    this.subscriptions.add(
      this.getGrowthRegulations().subscribe((growthRegulationVm: GrowthRegulationVM): void => {
        this.growthRegulationService.setGrowthRegulationState(growthRegulationVm);
        this.isGrowthegulationsEmpty = !growthRegulationVm.growthRegulations.length;
        this.prognosisService.stopLoading();
      })
    );
  }

  private convertSelectEventToField(event: SelectEvent) {
    if (event.selected.length) {
      const selectedField = event.selected[0].get('field');
      const selectedGrowthRegulation = event.selected[0].get('growthRegulation');
      return { field: selectedField, growthRegulation: selectedGrowthRegulation };
    } else {
      return { field: null, growthRegulation: null };
    }
  }

  private getGrowthRegulations() {
    return this.farmStateService.fieldFeatures$.pipe(
      withLatestFrom(this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$),
      tap(() => this.prognosisService.startLoading('main.growthRegulation.loading')),
      filter(([fieldFeatures, farms, harvestYear]) => !!farms && !!harvestYear && !!fieldFeatures),
      switchMap(([fieldFeatures, farms, harvestYear]) =>
        this.growthRegulationService.getGrowthRegulations(farms, fieldFeatures, harvestYear)
      ),
      finalize(() => this.prognosisService.stopLoading())
    );
  }
}
