import { Injectable } from '@angular/core';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HotspotsService } from '../../../hotspots.service';
import { ShownComponentEnum } from '../../hotspots-sidedrawer-showncomponent.enum';
import { ShapeFile } from '../interfaces/shape-file.interface';
import { ShapeFilesRepo } from '../repository/shape-files-repo.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Injectable()
export class ShapeFilesService {
  public readonly selectedFarms$ = this.farmStateService.selectedFarms$;

  constructor(
    private shapeFilesRepo: ShapeFilesRepo,
    private notification: NotificationService,
    private dialogService: DialogService,
    private messages: MessageService,
    private farmStateService: FarmStateService,
    private hotspotsService: HotspotsService
  ) {}

  public getShapeFiles(): Observable<ShapeFile[]> {
    return this.shapeFilesRepo.getShapeFiles();
  }

  public getShapeFile(id: number): Observable<ShapeFile> {
    return this.shapeFilesRepo.getShapeFile(id);
  }

  public createShapeFile(zipFile: File, srid: number): Observable<ShapeFile | null> {
    return this.shapeFilesRepo.createShapeFile(zipFile, srid).pipe(
      catchError((error) => {
        this.notification.showError('messages.shapeFileImport.saveZipFileError');
        return throwError(error);
      }),
      tap((shapeFile: ShapeFile | null) => {
        if (shapeFile) {
          this.notification.showInfo('messages.shapeFileImport.saveZipFileSuccess');
        }
      })
    );
  }

  public importShapeFileWithFieldPolygons(
    id: number,
    farmId: number,
    harvestYear: number,
    fieldNumberColumn: string,
    replace: boolean
  ): Observable<ShapeFile | null> {
    return this.shapeFilesRepo.importShapeFileWithFieldPolygons(id, farmId, harvestYear, fieldNumberColumn, replace);
  }

  public importShapeFileWithHotspots(id: number, farmId: number, hotspotTypeId: number): Observable<HotspotDto[]> {
    return this.shapeFilesRepo.importShapeFileWithHotspots(farmId, id, hotspotTypeId).pipe(
      catchError((error) => {
        this.notification.showError(this.messages.getShapeFileImportMsg().importError);
        return throwError(error);
      }),
      tap(() => {
        this.notification.showInfo(this.messages.getShapeFileImportMsg().importSuccess);
        this.hotspotsService.setShownComponentState(ShownComponentEnum.hotspotDetailsComponent);
      }),
      map((hotspots: HotspotDto[] | null) => hotspots || [])
    );
  }

  public importShapeFile(
    id: number,
    harvestYear: number,
    farmIdentColumn: string,
    farmIdentType: string,
    fieldNumberColumn: string,
    replace: boolean
  ): Observable<ShapeFile | null> {
    return this.shapeFilesRepo.importShapeFile(id, harvestYear, farmIdentColumn, farmIdentType, fieldNumberColumn, replace);
  }

  public getShapeFileImportErrorMsg(errorCode: number) {
    switch (errorCode) {
      case 0:
        return this.messages.getShapeFileImportMsg().shapeFileOk;
      case 1:
        return this.messages.getShapeFileImportMsg().geometryInvalid;
      case 2:
        return this.messages.getShapeFileImportMsg().geometryNotSimple;
      case 3:
        return this.messages.getShapeFileImportMsg().geometryUnsupported;
      case 4:
        return this.messages.getShapeFileImportMsg().missingWriteAccess;
      case 5:
        return this.messages.getShapeFileImportMsg().memberNumbersNotUnique;
      default:
        return '';
    }
  }

  public onSideDrawerCloseRequest(isDirty: boolean) {
    return new Observable<boolean>((observer) => {
      if (isDirty) {
        this.dialogService.openDirtyCheckDialog().subscribe((action) => {
          if (action && action.isConfirmed) {
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
      } else {
        observer.next(true);
      }
    });
  }
}
