import { NgModule } from '@angular/core';
import { ImageSliderModule } from '@app/shared/image-slider/image-slider.module';
import { SharedModule } from '@app/shared/shared.module';
import { CreateHotspotModule } from './create-hotspot/create-hotspot.module';
import { HotspotDetailsPresentationComponent } from './hotspot-details/hotspot-details-presentation/hotspot-details-presentation.component';
import { HotspotDetailsComponent } from './hotspot-details/hotspot-details.component';
import { HotspotDetailsService } from './hotspot-details/hotspot-details.service';
import { HotspotSubtypeItemComponent } from './hotspot-details/hotspot-subtype-select/hotspot-subtype-item/hotspot-subtype-item.component';
import { HotspotSubtypeSelectComponent } from './hotspot-details/hotspot-subtype-select/hotspot-subtype-select.component';
import { GroupItemComponent } from './hotspot-groups/group-item/group-item.component';
import { GroupListComponent } from './hotspot-groups/group-list/group-list.component';
import { HotspotGroupsComponent } from './hotspot-groups/hotspot-groups.component';
import { HotspotGroupsService } from './hotspot-groups/hotspot-groups.service';
import { HotspotsSideDrawerComponent } from './hotspots-side-drawer.component';
import { ShapeFileImportModule } from './shape-file-import/shape-file-import.module';
import { HotspotGroupsRepo } from './shared/hotspot-groups-repo/hotspot-groups-repo.service';
import { HotspotRepo } from './shared/hotspot-repo/hotspot-repo.service';
import { HotspotService } from './shared/hotspot.service';

@NgModule({
  imports: [ImageSliderModule, ShapeFileImportModule, SharedModule, CreateHotspotModule],
  declarations: [
    HotspotsSideDrawerComponent,
    HotspotDetailsComponent,
    HotspotSubtypeSelectComponent,
    HotspotSubtypeItemComponent,
    HotspotDetailsPresentationComponent,
    HotspotGroupsComponent,
    GroupListComponent,
    GroupItemComponent,
  ],
  providers: [HotspotDetailsService, HotspotService, HotspotRepo, HotspotGroupsRepo, HotspotGroupsService],
  exports: [HotspotsSideDrawerComponent],
})
export class HotspotsSideDrawerModule {}
