import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import { Layer } from 'ol/layer';
import { BehaviorSubject } from 'rxjs';

export type PagesWithLayer =
  | 'none'
  | 'all'
  | 'cultivation-journal'
  | 'field-plan'
  | 'vra'
  | 'prognosis'
  | 'prognosis_yield'
  | 'prognosis_blight'
  | 'prognosis_corn'
  | 'prognosis_growth'
  | 'hotspots'
  | 'basis-layers'
  | 'yield-benchmark';
export type LayerGroup = 'permanent' | 'generel' | 'overlay' | 'field' | 'cell-draw';

export interface LayerBundle {
  id: LayerId;
  layerObjects: Layer[];
  opacity: number; // 0-100
  order: number;
  group: LayerGroup;
  adjustable: boolean;
  enabled: boolean;
  allowedPages: PagesWithLayer[];
  translationKey: string;
  uiColor: string;
}

export const enum LayerId {
  NONE = 0,
  OSM = 1,
  AIRPHOTO_DK = 2,
  AIRPHOTO_FOREIGN = 3,
  GEO_LOCATION = 4,
  BES_NATURE = 5,
  BES_WATERCOURSES = 6,
  BES_STONES = 7,
  BUFFER_MARGINS = 8,
  GLM_MEMORIES = 9,
  GLM_LAKES = 10,
  FIELD_SHRUBS = 11,
  BNBO_STATUS = 12,
  DIGI_SOIL = 13,
  FIELD_BLOCKS = 14,
  FIELD_PLANS = 15,
  FIELDS = 16,
  FIELD_LABELS = 17,
  FIELD_FILL = 18,
  FIELD_MARKERS = 19,
  HOTSPOTS = 20,
  HOTSPOT_MARKERS = 21,
  BIOMASS = 22,
  VRA_CELLS = 23,
  BASIS_LAYERS = 24,
  HEIGHT_MAP = 25,
  SHADOW_MAP = 26,
  CELL_HOVER = 27,
  CELL_DRAW = 28,
  SOILSAMPLE = 29,
  SOILSAMPLE_LABELS = 30,
  GROWTH_REGULATION = 31,
  CORN_PROGNOSIS = 32,
  HIGH_RES_FIELD_MARKERS = 33,
  HIGH_RES_POTATO_MARKERS = 34,
  BLIGHT_FIELDS = 35,
  BLIGHT_POLYGONS = 36,
  BLIGHT_FIELD_POLYGONS = 37,
  POTATO_BLIGHT = 38,
  VRA_PROGNOSIS = 39,
  YIELD_PROGNOSIS = 40,
}

export interface LayerState {
  entities: Record<LayerId, LayerBundle>;
  ids: LayerId[];
}
@Injectable({ providedIn: 'root' })
export class OlLayerStore {
  public readonly store = createStore({ name: 'map-layers' }, withEntities<LayerBundle>());
  public readonly entitiesHistory = entitiesStateHistory(this.store);
  public readonly stateHistory = stateHistory(this.store);
  public activePageSubject = new BehaviorSubject<PagesWithLayer>('all');

  constructor() {}
}
