<app-side-drawer-wrapper>
  <app-side-drawer-header
    (action)="onCloseClick()"
    [showMinimizeButton]="true"
    cancelIconName="back"
    (onHide)="onHideClick()"
    title="{{ 'main.fieldmap.layers.redEdgeNdvi' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <app-ndvi></app-ndvi>
  </app-side-drawer-body>
</app-side-drawer-wrapper>

<app-map-feature-select
  [disabled]="!isFeatureSelectEnabled"
  (event)="onFeatureSelected($event)"
  [layers]="selectableLayers"
></app-map-feature-select>
