import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { VraModelNorms } from '@app/core/enums/VraModelNormNumbers.enum';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { ScaleSummaryLine } from '@app/core/interfaces/scale-summary-line.interface';
import { Unit } from '@app/core/interfaces/unit.type';
import { LanguageService } from '@app/core/language/language.service';
import { ScaleColorPerceptualService } from '@app/core/scale-color-perceptual/scale-color-perceptual.service';
import { PrescriptionMap } from '@app/new-map/features/vra/interfaces/prescription-map.interface';
import { VraPrescriptionMap } from '@app/new-map/features/vra/interfaces/vra-prescription-map.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';
import { AppUnitPipe } from '@app/shared/pipes/decimal-separator/unit.pipe';
import { ScaleLegendOptions, ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class ScaleLegendService {
  private _unitPipe = new AppUnitPipe(this.decimalService);

  constructor(
    private languageService: LanguageService,
    private scaleColorPerceptualService: ScaleColorPerceptualService,
    private decimalService: DecimalService
  ) {}

  public getScaleLegendSettings(
    mapCoverFlowItem: MapCoverFlowItem,
    options?: ScaleLegendOptions,
    operationTypeGroupId?: OperationTypeGroupEnum | null,
    unit: Unit | null = 'kg'
  ): ScaleLegendSettings {
    if (!mapCoverFlowItem || !mapCoverFlowItem.isVisible || !options) {
      return this.getEmptyLegend();
    }

    switch (mapCoverFlowItem.mapCoverFlowLayersId) {
      case MapLayerId.VRA:
        return this.getVraLegendSettings(
          options.selectedLegend!,
          options.selectedAvgQuantity!,
          options.selectedTotalQuantity!,
          options.selectedPrescriptionMaps!,
          operationTypeGroupId!,
          unit!
        );
      case MapLayerId.REDEDGENDVI:
        return this.getRedEdgeNdviLegendSettings(options.selectedNdviDate!);
      case MapLayerId.YIELD_PROGNOSIS:
        return this.getYieldPrognosisLegend(options.selectedLegend!, options.selectedAvgQuantity!, options.selectedTotalQuantity!, unit!);
      default:
        return this.getEmptyLegend();
    }
  }

  private getRedEdgeNdviLegendSettings(selectedDate: DateTime): ScaleLegendSettings {
    if (!selectedDate) {
      return this.getEmptyLegend();
    }
    const steps = 20;
    const values: number[] = [];
    for (let i = steps; i >= 0; i--) {
      values.push(i / steps);
    }
    const items = values.map((v) => ({
      color: this.scaleColorPerceptualService.getViridisColor(1 - v) as string,
      text: this.decimalService.toLocaleString(v, undefined, 2),
    }));
    const dateString = new DatePipe(this.languageService.currentLanguage.longKey).transform(selectedDate.toJSDate(), 'dd. MMM');
    const title = this.languageService.getText('main.fieldInspector.scaleLegendTitleNdvi') + '\n\n' + dateString;
    const visible = true;

    return {
      items,
      title,
      visible,
      note: undefined,
      summaryLines: undefined,
      unit: '',
    };
  }

  private getYieldPrognosisLegend(
    selectedLegend: LegendColorValue[],
    selectedAvgQuantity: number,
    selectedTotalQuantity: number,
    unit: string
  ) {
    if (!selectedLegend) {
      return this.getEmptyLegend();
    }
    const items = selectedLegend.map((legendColor) => ({
      color: legendColor.color,
      // Convert to decimal following unit rules
      text: this._unitPipe.transform(Number(legendColor.value) / 1000, 'ton'),
    }));
    const scaleLegendSummaryLines: ScaleSummaryLine[] = [
      {
        description: this.languageService.getText('main.fieldInspector.scaleLegendSummaryVraTotal'),
        quantity: this.decimalService.toLocaleString(selectedTotalQuantity, 'ton') ?? '0',
        unit: unit,
      },
      {
        description: this.languageService.getText('main.fieldInspector.scaleLegendSummaryVraAverage'),
        quantity: this.decimalService.toLocaleString(selectedAvgQuantity, 'ton') ?? '0',
        unit: unit,
      },
    ];

    return {
      items,
      title: `${unit}/${this.languageService.getText('common.hectareShort')}`,
      visible: true,
      summaryLines: scaleLegendSummaryLines,
      note: '',
      unit: '',
    };
  }

  private getVraLegendSettings(
    selectedLegend: LegendColorValue[],
    selectedAvgQuantity: number,
    selectedTotalQuantity: number,
    selectedPrescriptionMaps: VraPrescriptionMap[] | PrescriptionMap[],
    operationTypeGroupId: OperationTypeGroupEnum,
    unit: Unit
  ): ScaleLegendSettings {
    if (!selectedLegend || !selectedPrescriptionMaps) {
      return this.getEmptyLegend();
    }

    const items = selectedLegend.map((legendColor) => ({
      color: legendColor.color,
      text: this.decimalService.toLocaleString(legendColor.value, unit)?.replace('-', ''),
    }));
    const scaleLegendSummaryLines: ScaleSummaryLine[] = [
      {
        description: this.languageService.getText('main.fieldInspector.scaleLegendSummaryVraTotal'),
        quantity:
          parseFloat(this.decimalService.toLocaleString(selectedTotalQuantity, unit)) >= 0
            ? this.decimalService.toLocaleString(selectedTotalQuantity, unit)
            : '0',
        unit: unit.charAt(0).toLowerCase() + unit.slice(1),
      },
    ];
    // AvgQuantity is not added to scaleLegendSummaryLines if it is spotspraying
    if (selectedPrescriptionMaps.some((prescriptionMap) => prescriptionMap.vraModelNormNumber !== VraModelNorms.SpotSpraying)) {
      scaleLegendSummaryLines.push({
        description: this.languageService.getText('main.fieldInspector.scaleLegendSummaryVraAverage'),
        quantity: this.decimalService.toLocaleString(selectedAvgQuantity, unit) ?? '0',
        unit: unit.charAt(0).toLowerCase() + unit.slice(1),
      });
    }

    const legendNote =
      items.length === 0 ? this.languageService.getText('main.fieldInspector.scaleLegendNoteVraNoGraduatedNeed') : undefined;

    return {
      items,
      title: `${unit.charAt(0).toUpperCase() + unit.slice(1)}/${this.languageService.getText('common.hectareShort')}`,
      visible: true,
      summaryLines: scaleLegendSummaryLines,
      note: legendNote,
      unit: '',
      operationTypeGroupId: operationTypeGroupId,
    };
  }

  private setNumberOfDecimals(quantity: number, unit: Unit) {
    const numberOfDecimals = this.decimalService.getNumberOfDecimals(quantity, unit) as number;
    return this.decimalService.toLocaleString(quantity, unit, numberOfDecimals);
  }

  private getEmptyLegend(): ScaleLegendSettings {
    return {
      items: [],
      title: '',
      visible: false,
      note: undefined,
      summaryLines: [],
      unit: '',
    };
  }

  private addDelimiter(input: string, delimiter?: string) {
    if (!!delimiter) {
      return input + delimiter;
    }
    return input;
  }
}
