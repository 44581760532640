import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isNullOrUndefined, isNumber } from '@app/shared/utils/utils';

/**
 * Validators for numbers
 */
export class NumberValidators implements Validators {
  /**
   * Validator that checks if control value is greater than given number
   * @param number Limiter
   * @returns Validator function
   */
  public static greaterThan(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) return null;

      const value = Number(control.value.toString().replace(',', '.'));
      const isGreaterThan = value > number;

      if (!isGreaterThan) return { greaterThan: true };

      return null;
    };
  }

  /**
   * Validator that checks if control value is less than given number
   * @param number Limiter
   * @returns Validator function
   */
  public static lessThan(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) return null;

      const value = Number(control.value.toString().replace(',', '.'));
      const isLessThan = value < number;

      if (!isLessThan) return { lesserThan: true };

      return null;
    };
  }

  public static min(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) return null;

      const value = Number(control.value.toString().replace(',', '.'));
      const isMin = value >= number;

      if (!isMin) return { min: { actual: value, min: number } };

      return null;
    };
  }

  public static max(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) return null;

      const value = Number(control.value.toString().replace(',', '.'));
      const isMax = value <= number;

      if (!isMax) return { max: { actual: value, max: number } };

      return null;
    };
  }

  public static greaterThanOrEqual(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) return null;

      const value = Number(control.value.toString().replace(',', '.'));
      const isGreaterThanOrEqual = value >= number;

      if (!isGreaterThanOrEqual) return { greaterThanOrEqual: true };

      return null;
    };
  }

  public static lessThanOrEqual(number: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isNullOrUndefined(control.value)) return null;

      const value = Number(control.value.toString().replace(',', '.'));
      const isLessThanOrEqual = value <= number;

      if (!isLessThanOrEqual) return { lesserThanOrEqual: true };

      return null;
    };
  }

  /**
   * Validator that checks if control value is NaN
   */
  public static nan(control: AbstractControl): ValidationErrors | null {
    const isValueNumber = isNumber(control.value);
    const isValueNaN = isNaN(control.value);

    if (isValueNumber && isValueNaN) return { isNaN: true };

    return null;
  }

  /**
   * Validator that checks if control value is numeric
   */
  public static numeric(control: AbstractControl): ValidationErrors | null {
    if (isNullOrUndefined(control.value)) return null;

    const isNumeric = new RegExp(/^-?\d+(\.?\d)?/).test(control.value.toString());

    return isNumeric ? null : { number: true };
  }

  /**
   * Validator that checks if control value is integer
   */
  public static integer(control: AbstractControl): ValidationErrors | null {
    if (isNullOrUndefined(control.value)) return null;

    const isInteger = Number.isInteger(control.value);

    return isInteger ? null : { integer: true };
  }

  /**
   * Validator that checks if control value is whole number
   */
  public static wholeNumber(control: AbstractControl): ValidationErrors | null {
    if (isNullOrUndefined(control.value)) return null;

    const isWholeNumber = new RegExp(/^-?\d+$/).test(control.value.toString());

    return isWholeNumber ? null : { wholeNumber: true };
  }

  /**
   * Validator that checks if value of first control is less than value of second control
   * @param minControlKey Key of control with minimum value
   * @param maxControlKey Key of control with maximum value
   */
  public static lessThanControl(minControlKey: string, maxControlKey: string): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      if (!(group instanceof FormGroup)) throw new Error('LessThanControl validator can be used only with FormGroups');
      if (!group.controls[minControlKey]) throw new Error(`Control with key ${minControlKey} does not exist on FormGroup`);
      if (!group.controls[maxControlKey]) throw new Error(`Control with key ${maxControlKey} does not exist on FormGroup`);

      const min = group.controls[minControlKey];
      const max = group.controls[maxControlKey];

      if (isNullOrUndefined(min.value) || isNullOrUndefined(max.value)) return null;
      const minNum = Number(min.value.toString().replace(',', '.'));
      const maxNum = Number(max.value.toString().replace(',', '.'));

      if (minNum > maxNum) return { minMax: 'Mininum value is greater than maximum value' };

      return null;
    };
  }

  public static zeroOrBetween(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      if (isNullOrUndefined(value)) return null;
      if (Number.isNaN(value)) return null;

      return value === 0 || (value >= min && value <= max) ? null : { zeroOrBetween: { min, max } };
    };
  }

  public static nonZeroOrBetween(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      if (isNullOrUndefined(value)) return null;
      if (Number.isNaN(value)) return null;

      return value !== 0 && value >= min && value <= max ? null : { nonZeroOrBetween: { min, max } };
    };
  }
}
