<div class="wrapper" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
  <app-icon class="close-btn" [icon]="'Luk'" (click)="close()"></app-icon>
  <mat-dialog-content class="content-wrapper" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <b>Vilkår for anskaffelse og brug af Dansk Markdatabase og tilhørende programmoduler</b>
    <br /><br />

    <b>Programmoduler</b><br />
    Ved programmoduler forstås i nærværende tekst enhver kombination af software, kortmateriale og hardware fra PlanteIT under Landbrug og
    Fødevarer, SEGES Innovation P/S samt fra Geodatastyrelsen, Fødevareministeriet og COWI.
    <br /><br />

    <b>Licenstager, landbrugsbedrift og bedriftsareal</b><br />
    Licenstageren skal tegne ét abonnement (licens) pr. landbrugsbedrift (virksomhed). Der skal tegnes abonnement for landbrugsbedriftens
    samlede matrikulerede areal plus areal af eventuelle forpagtninger.
    <br />
    Med en landbrugsbedrift forstås en økonomisk driftsenhed (en virksomhed med samme CVR-nummer), der driver landbrug, skovbrug, gartneri,
    frugtplantage eller lignende jordbrugsvirksomhed og hvor driften forestås af samme fysiske eller juridiske person. Med licenstager
    forstås den, som giver accept i sin egenskab af ejer eller forpagter af en landbrugsbedrift
    <br /><br />

    <b>Rettigheder</b><br />
    Abonnementet giver licenstager brugsret til de omfattede programmoduler. Brugsretten er begrænset til kun at omfatte intern anvendelse
    indenfor de opgaver, der følger af drift, administration og lignende for den konkrete landbrugsbedrift. Herunder hører bl.a. opmåling af
    arealer som grundlag for anmodning om enkeltbetalingsstøtte. Kun arealer, der hører til den konkrete landbrugsbedrift er omfattet af
    licensen. Såfremt man ønsker at benytte programmodulet udenfor den konkrete landbrugsbedrift forudsætter det et særskilt abonnement.
    <br /><br />
    Licenstageren har ikke ret til at videresælge, videregive eller på anden måde overdrage brugsretten til programmodulerne til brug for
    tredjemand. Det accepteres dog, at en konsulent eller maskinstation rekvireret af landmanden, kan få adgang til data for den pågældende
    landbrugsbedrift. Med konsulent menes en professionel rådgiver indenfor jordbrugsvirksomhed, f.eks. en planteavlskonsulent eller
    lignende. Ligeledes gælder, at der må lægges én skærmkopi med kort i max. 800 * 800 pixels på én hjemmeside på internettet. Dog
    accepteres kort til internettet ikke for produkter fra COWI.
    <br /><br />

    <b>Priser</b><br />
    Priser er angivet i danske kroner og eksklusiv moms. Betaling for kurser, installation og anden form for service i relation til
    programmodulerne er ikke indregnet.
    <br /><br />
    Betalingen for at anskaffe og anvende programmodulerne består hhv. af en oprettelsespris, der betales én gang ved oprettelse og et
    årligt abonnement, der giver ret til at anvende programmodulerne.
    <br /><br />
    Ved oprettelse faktureres oprettelsesprisen plus abonnement for resten af kalenderåret. Derefter faktureres abonnement én gang årligt
    forud for et helt kalenderår ad gangen.
    <br /><br />
    Hvis driftsenhedens samlede areal forøges, fremsendes en ny opkrævning for oprettelsespris og abonnement vedrørende det fremtidige
    areal. Allerede indbetalt oprettelsespris og abonnement for det pågældende programmodul vil blive modregnet i den nye opkrævning.
    Ændringen træder øjeblikkeligt i kraft.
    <br /><br />
    Hvis driftsenhedens areal formindskes, refunderes ikke indbetalt oprettelsespris og abonnement.
    <br /><br />
    Opsigelse af abonnementet kan ske med 1 måneds varsel til udløb af et kalenderår ved skriftlig henvendelse til Landbrug og Fødevarer,
    SEGES Innovation P/S. Ved opsigelse bortfalder retten til at anvende programmodulerne. Der tilbagebetales aldrig forudbetalt abonnement.
    Dette gælder også selvom opsigelsen sker midt i et kalenderår.
    <br /><br />
    Ved genoprettelse betales oprettelsesprisen en gang til.
    <br /><br />

    <b>Ansvar</b><br />
    Landbrug og Fødevarer, SEGES Innovation P/S er under ingen omstændigheder ansvarlig for erhvervsmæssigt driftstab, tab af omsætning, tab
    af forventet fortjeneste, tabt arbejdsfortjeneste eller noget andet direkte eller indirekte tab, uanset årsag. SEGES Innovation P/S
    hæfter ikke for produktansvar, medmindre dette pålægges af lovgivningen på objektivt grundlag.
    <br /><br />
    Enhver brug af programmodulerne sker således på brugerens eget ansvar og brugeren accepterer derfor, at der ikke vil kunne rejses noget
    krav mod Geodatastyrelsen, COWI eller Landbrug og Fødevarer, SEGES Innovation P/S som følge af brug af programmodulerne.
    Geodatastyrelsen, COWI eller Landbrug og Fødevarer, SEGES Innovation P/S er heller ikke pligtig til at erstatte tab af nogen art som
    følge af eventuelle fejl og unøjagtigheder i programmodulerne.
    <br /><br />
    Landbrug og Fødevarer, SEGES Innovation P/S erstatningsansvar er i enhver henseende begrænset til oprettelsesprisen plus et års
    abonnement.
    <br /><br />

    <b>Ophavsret</b><br />
    Landbrug og Fødevarer, SEGES Innovation P/S har den fulde ophavsret til programmoduler samt det tilhørende dokumentationsmateriale med
    mindre andet udtrykkeligt er angivet. Geodatastyrelsen har den fulde ophavsret til styrelsens kort og produkter. COWI har den fulde
    ophavsret til COWI’s kort og produkter. Fødevareministeriet har den fulde ophavsret til Fødevareministeriets kort og produkter.
    <br /><br />
    Ved gengivelse af produkterne i analog eller digital form, skal der gøres tydeligt opmærksom på ophavsretten (Copyright Landbrug og
    Fødevarer, SEGES Innovation P/S; Copyright COWI; Copyright Geodatastyrelsen; Copyright Fødevareministeriet).
    <br /><br />

    <b>Misligholdelse</b><br />
    Ved misligholdelse af ovennævnte bestemmelser ophæves adgangen til programmodulerne med øjeblikkelig virkning og licenstager kan
    retsforfølges i henhold til dansk ret.
    <br /><br />

    <b>Forbehold for ændringer i priser og vilkår</b>
    Landbrug og Fødevarer, SEGES Innovation P/S forbeholder sig ret til at ændre i priser og vilkår.
    <br /><br />

    16. Dec. 2015. Landbrug og Fødevarer, SEGES Innovation P/S, Agro Food Park 15, Skejby, 8200 Århus N.
  </mat-dialog-content>
</div>
