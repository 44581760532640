<table
  mat-table
  matSort
  (matSortChange)="sortData($event)"
  [dataSource]="dataSource"
  matSort
  matSortActive="date"
  matSortDirection="asc"
  class="mat-elevation-z2"
  *ngIf="tasks && tasks.length > 0"
>
  <ng-container [matColumnDef]="fieldDefinition.property!.toString()" *ngFor="let fieldDefinition of fieldDefinitions$ | async">
    <ng-container *ngIf="fieldDefinition.hide !== 'hide-on-table'">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="!fieldDefinition.sortable"
        [disableClear]="true"
        class="task-header"
        [style.width]="fieldDefinition.tableCellWidth"
      >
        {{ fieldDefinition.name! | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        class="definition"
        [style.width]="fieldDefinition.tableCellWidth"
        [class.no-overflow]="fieldDefinition.tableCellWidth"
      >
        <span *ngIf="fieldDefinition.type === 'text'">{{ element[fieldDefinition.property!]?.toString() | translate }}</span>
        <span *ngIf="fieldDefinition.type === 'date'">{{ element[fieldDefinition.property!]?.toString() | dateFormat }}</span>
        <span *ngIf="fieldDefinition.type === 'statusText'">{{
          (element[fieldDefinition.property!] ? 'taskGridItem.done' : 'taskGridItem.planned') | translate
        }}</span>
        <span *ngIf="fieldDefinition.unit">{{ fieldDefinition.unit | translate }}</span>
        <span *ngIf="fieldDefinition.icon && fieldDefinition.icon.condition === element[fieldDefinition.property!]">
          <app-icon
            [icon]="fieldDefinition.icon.name"
            [style.color]="fieldDefinition.icon.color"
            [style.backgroundColor]="fieldDefinition.icon.backgroundColor"
          ></app-icon>
        </span>
      </td>
    </ng-container>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-menu #taskRowMenu="matMenu">
        <button class="task-menu-item" mat-menu-item (click)="editButtonClicked(element)">
          <app-icon icon="edit_outline"></app-icon>
          <span>{{ 'taskCards.tooltips.editTask' | translate }}</span>
        </button>
        <button class="task-menu-item" [disabled]="disable" mat-menu-item (click)="deleteButtonClicked(element)">
          <app-icon icon="delete_outline"></app-icon>
          <span>{{ 'taskCards.tooltips.deleteTask' | translate }}</span>
        </button>
        <button
          *ngIf="element.registered"
          [disabled]="disable"
          class="task-menu-item"
          mat-menu-item
          (click)="scheduleButtonClicked(element)"
        >
          <app-icon icon="schedule"></app-icon>
          <span>{{ 'taskCards.tooltips.scheduleTask' | translate }}</span>
        </button>
        <button
          *ngIf="!element.registered"
          [disabled]="disable"
          class="task-menu-item"
          mat-menu-item
          (click)="completeButtonClicked(element)"
        >
          <app-icon icon="check"></app-icon>
          <span>{{ 'taskCards.tooltips.completeTask' | translate }}</span>
        </button>
      </mat-menu>
      <div class="waves-effect waves-circle transparent more-btn" (click)="$event.stopPropagation()">
        <button mat-icon-button [matMenuTriggerFor]="taskRowMenu">
          <app-icon icon="more_vert"></app-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let index" class="e2e-task" (click)="editButtonClicked(row)"></tr>
</table>
