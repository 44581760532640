import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() public diameter = 100;
  @Input() public message? = '';
  @Input() public hasBackdrop = false;
  @HostBinding('class.show-backdrop')
  public get showBackdrop() {
    return this.hasBackdrop;
  }

  constructor() {}
}
