import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppPipesModule } from '@app/shared/pipes/pipes.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { PopupModule } from '@progress/kendo-angular-popup';
import { KendoHeaderComponent } from './kendo-sort-header/kendo-header.component';
import { TableComponent } from './table.component';

@NgModule({
  imports: [CommonModule, MatTableModule, MatSortModule, MatTooltipModule, IconModule, AppPipesModule, PopupModule, TranslateModule],
  declarations: [TableComponent, KendoHeaderComponent],
  exports: [TableComponent, KendoHeaderComponent],
})
export class TableModule {}
