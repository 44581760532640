import { ComponentType } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PartnersComponent } from '@app/layouts/footer/partners/partners.component';
import { AppLayoutService } from '../../core/layout/app-layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
  public modalRef?: MatDialogRef<PartnersComponent>;
  public menuMinimized$ = this.layoutService.menuMinimized$;

  constructor(
    private dialog: MatDialog,
    private layoutService: AppLayoutService
  ) {}

  public openSponsorModal() {
    this.openModal(PartnersComponent);
  }

  public openModal(component: ComponentType<PartnersComponent>) {
    this.modalRef = this.dialog.open(component, { panelClass: 'partners-pane' });

    this.modalRef.afterClosed().subscribe(() => (this.modalRef = undefined));
  }

  public goToPage(link: string) {
    window.open(link, '_blank');
  }

  public toggleMenu() {
    this.layoutService.toggleMenuMimized();
  }
}
