import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermaLinkNotFoundComponent } from '@app/perma/perma-link-not-found/perma-link-not-found.component';
import { LandmandDkResolverService } from '@app/perma/resolvers/landmand-dk-resolver.service';
import { SubscriptionsResolverService } from './resolvers/subscriptions-resolver.service';

@NgModule({
  imports: [CommonModule],
  providers: [LandmandDkResolverService, SubscriptionsResolverService],
  declarations: [PermaLinkNotFoundComponent],
})
export class PermaModule {}
