import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DirtyCheckDialogAction } from '@app/shared/dialog/dirty-check-dialog/dirty-check-actions.class';
import { FileUploadComponent } from '@app/shared/file-upload/file-upload.component';
import { FeedBackStateService } from '@app/state/services/feed-back/feed-back-state.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FeedbackService } from './service/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent {
  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @ViewChild('fileUpload', { static: false }) public fileUploadRef: FileUploadComponent;
  @Output() public closeFeedback = new EventEmitter<void>();
  public filesToBeSaved: File[] = [];
  public isSaving$ = new BehaviorSubject<boolean>(false);
  public isFeedbackSent$ = new BehaviorSubject<boolean>(false);

  public feedbackForm = this.fb.group({
    feedback: this.fb.control('', [Validators.required]),
  });

  constructor(
    private feedbackService: FeedbackService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    private feedBackStateService: FeedBackStateService
  ) {}

  public onFileInputChange(files: File[]) {
    this.filesToBeSaved = files;
  }

  public onSendFeedback() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (this.feedbackControl.invalid) {
      return;
    }

    this.isSaving$.next(true);

    this.feedbackService

      // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      .saveFeedback(this.feedbackControl.value, this.filesToBeSaved)
      .pipe(finalize(() => this.isSaving$.next(false)))
      .subscribe(() => this.onSaveFeedback());
  }

  public onSaveFeedback() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.feedbackControl.reset();

    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.feedbackControl.markAsPristine();
    this.filesToBeSaved = [];
    this.isFeedbackSent$.next(true);
  }

  public onCloseFeedback() {
    // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (!this.feedbackControl.dirty) {
      this.setFeedbackClosed();
    } else {
      this.dialogService
        .openDirtyCheckDialog()
        .pipe(DirtyCheckDialogAction.confirmed())
        .subscribe((action) => this.setFeedbackClosed());
    }
  }

  public footerClicked(event: any) {
    const methodName = event.methodName;

    // @ts-ignore - TS7053 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    if (this[methodName]) {
      if (event.hasOwnProperty('param1')) {
        const param1 = event.param1;

        // @ts-ignore - TS7053 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        this[methodName](param1);
      } else {
        // @ts-ignore - TS7053 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        this[methodName]();
      }
    }
  }

  get feedbackControl() {
    return this.feedbackForm.get('feedback');
  }

  get isSpinnerVisible$() {
    return this.isSaving$.asObservable();
  }

  private setFeedbackClosed() {
    this.feedBackStateService.isFeedBackOpen = false;
  }
}
