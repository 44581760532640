import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DirtyCheckDialogAction } from '@app/shared/dialog/dirty-check-dialog/dirty-check-actions.class';
import noop from 'lodash-es/noop';
import { Observable, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { FieldPlanSideDrawerService } from '../../field-plan-side-drawer.service';
import { ShownComponentEnum } from '../../shown-component-in-side-drawer.enum';
import { FieldPlanContentService } from '../field-plan-content.service';
import { CreateFieldFormService } from './create-field-form.service';
import { DeleteFieldModalComponent } from './delete-field-modal/delete-field-modal.component';

@Component({
  selector: 'app-create-field-form',
  templateUrl: './create-field-form.component.html',
  styleUrls: ['./create-field-form.component.scss'],
  providers: [CreateFieldFormService],
})
export class CreateFieldFormComponent implements OnInit, OnDestroy {
  public selectedFarms$ = this.createFieldFormService.selectedFarms$;
  public drawnPolygons$ = this.createFieldFormService.drawnPolygons$;
  public isDrawing$ = this.createFieldFormService.isDrawing$;
  public isValidatingFieldNumber$ = this.createFieldFormService.isValidatingFieldNumber$;
  public isLoading$ = this.createFieldFormService.isLoading$;
  public varietiesSelectPlaceholder$ = this.createFieldFormService.varietiesSelectPlaceholder$;
  public cropsSelectPlaceHolder$ = this.createFieldFormService.cropsSelectPlaceHolder$;
  public preCropsSelectPlaceHolder$ = this.createFieldFormService.preCropsSelectPlaceHolder$;
  public loadingMessage$ = this.createFieldFormService.loadingMessage$;
  public crops$ = this.createFieldFormService.crops$;
  public preCrops$ = this.createFieldFormService.preCrops$;
  public varieties$ = this.createFieldFormService.varieties$;
  public soilTypes$ = this.createFieldFormService.soilTypes$;
  public isDescriptionVisible$ = this.createFieldFormService.isDescriptionVisible$;
  public isRedrawVisible$ = this.createFieldFormService.isRedrawVisible$;
  public isSaveEnabled$!: Observable<boolean>;
  public fieldArea$ = this.createFieldFormService.fieldArea$;
  public formGroup!: UntypedFormGroup;
  public isInfoVisible = false;
  public featureIsFromFieldBlock = this.fieldPlanSideDrawerService.featureIsFromFieldBlock;

  private subscriptions = new Subscription();

  constructor(
    private dirtyCheck: DirtyCheckService,
    private createFieldFormService: CreateFieldFormService,
    private sideDrawerRef: SideDrawerRef<CreateFieldFormComponent, any, any>,
    private dialogService: DialogService,
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    private fieldPlanContentService: FieldPlanContentService
  ) {}

  public ngOnInit() {
    this.fieldPlanContentService.stopWhenComponentIsDestroyed = false;
    this.formGroup = this.createFieldFormService.getAndSetFormGroup();

    this.fillFormWithSelectedFieldChange();
    this.closeOnHarvestYearChange();

    this.isSaveEnabled$ = this.createFieldFormService.isSaveEnabled$;
    if (this.createFieldFormService.isEditingField) {
      this.closeOnFarmForCurrentFieldDeselected();
    }
  }

  public ngOnDestroy() {
    this.fieldPlanContentService.stopWhenComponentIsDestroyed = true;
    this.createFieldFormService.destroy();
    this.subscriptions.unsubscribe();
  }

  public onSaveClick() {
    this.createFieldFormService.saveField().subscribe((isDialogCancelled) => {
      isDialogCancelled ? noop() : this.closeAndReturnToFieldAdmin(true);
    });
  }

  public onDeleteFieldClicked() {
    this.dialogService
      .openCustomDialog(DeleteFieldModalComponent, {
        maxWidth: '650px',
        data: {
          fieldName: this.createFieldFormService.editedField?.name,
          number: this.createFieldFormService.editedField?.number,
        },
      })
      .afterClosed()
      .subscribe((isConfirmed) => {
        isConfirmed
          ? this.createFieldFormService.deleteField().subscribe(
              () => this.closeAndReturnToFieldAdmin(false),
              () => this.closeAndReturnToFieldAdmin(false)
            )
          : noop();
      });
  }

  public onHideClick() {
    this.sideDrawerRef.hide();
  }

  public onCloseClick() {
    this.tryClose(true);
  }

  public onInfoHover() {
    this.isInfoVisible = true;
  }

  public onRedrawClick(isNewField: boolean) {
    if (isNewField) {
      this.createFieldFormService.resetDrawnPolygons();
    } else {
      this.createFieldFormService.resetCurrentPolygon();
    }
  }

  public onBlockReselectClick() {
    this.createFieldFormService.resetDrawnFieldBlockLayer();
    this.createFieldFormService.stopDrawingPolygons();
    this.createFieldFormService.resetDrawnPolygons(true);
  }

  public onCancelClick() {
    this.createFieldFormService.startDrawingPolygons();
  }

  public trackByFn(index: any, item: any) {
    return item.number;
  }

  public get isInvalidFieldNumberFormatMessageVisible() {
    return this.fieldNumberFormControl?.getError('pattern');
  }

  public get isFieldNumberTakenMessageVisible() {
    return this.fieldNumberFormControl?.getError('fieldNoTaken');
  }

  public get isEditing() {
    return this.createFieldFormService.isEditingField;
  }

  public get varietyIdFormControl() {
    return this.createFieldFormService.varietyIdFormControl;
  }

  public get cropFormControl() {
    return this.createFieldFormService.cropFormControl;
  }

  public get preCropIdFormControl() {
    return this.createFieldFormService.preCropIdFormControl;
  }

  public get soilTypeIdFormControl() {
    return this.createFieldFormService.soilTypeIdFormControl;
  }

  public get nameFormControl() {
    return this.createFieldFormService.nameFormControl;
  }

  public get fieldNumberFormControl() {
    return this.createFieldFormService.fieldNumberFormControl;
  }

  private fillFormWithSelectedFieldChange() {
    this.subscriptions.add(
      this.fieldPlanSideDrawerService.shownComponent$.subscribe((shownComponent) => {
        if (!this.dirtyCheck.isAppDirty) {
          this.createFieldFormService.init(shownComponent.feature);
        }
      })
    );
  }

  private closeOnHarvestYearChange() {
    this.subscriptions.add(
      this.createFieldFormService.selectedHarvestYear$.pipe(skip(1)).subscribe(() => {
        this.closeAndReturnToFieldAdmin(true);
      })
    );
  }

  private closeOnFarmForCurrentFieldDeselected() {
    this.subscriptions.add(this.createFieldFormService.deselectedFarmForCurrentField$.subscribe(() => this.tryClose(true)));
  }

  private tryClose(resetFeature: boolean) {
    if (this.dirtyCheck.isAppDirty) {
      this.dialogService
        .openDirtyCheckDialog()
        .pipe(DirtyCheckDialogAction.confirmed())
        .subscribe((action) => this.closeAndReturnToFieldAdmin(resetFeature));
    } else {
      this.closeAndReturnToFieldAdmin(resetFeature);
    }
  }

  private closeAndReturnToFieldAdmin(resetFeatureToOriginal: boolean) {
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(false);
    this.createFieldFormService.stopDrawingPolygons();
    this.createFieldFormService.resetDrawnPolygons(resetFeatureToOriginal);
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.fieldAdministrationComponent);
  }
}
