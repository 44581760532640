import { Component } from '@angular/core';
import { flyInOut } from '@app/core/animations/fly-in-out.fn';
import { ImageFeature } from '@app/core/drone-image/image-feature';
import { DroneImageImportLogicService } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-import-logic.service';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-field-picker',
  templateUrl: './field-picker.component.html',
  styleUrls: ['./field-picker.component.scss'],
  animations: [flyInOut],
})
export class FieldPickerComponent {
  public selectedImageFeatures$ = this.droneImageImportLogicService.selectedImageFeatures$;
  public imageFeatures$: Observable<ImageFeature[] | null> = this.droneImageImportLogicService.fieldsWithImages$;
  public imagesCount$ = this.droneImageImportLogicService.selectedFiles$.pipe(map((files) => files.length));
  public deSelectedImageFeatures$ = combineLatest([this.selectedImageFeatures$, this.imageFeatures$]).pipe(
    map(([selectedFeatures, imageFeatures]) => {
      if (!imageFeatures) return [];

      return imageFeatures
        .filter((f) => !selectedFeatures.find((imageFeature) => imageFeature.featureId === f.featureId))
        .sort((a, b) => b.imagesForField.length - a.imagesForField.length);
    })
  );
  public loading$ = this.droneImageImportLogicService.loading$;

  constructor(private droneImageImportLogicService: DroneImageImportLogicService) {}

  public add(imageFeature: ImageFeature) {
    this.selectedImageFeatures$
      .pipe(first())
      .subscribe((imageFeatures) => (this.droneImageImportLogicService.selectedImageFeatures = [...imageFeatures, imageFeature]));
  }

  public remove(imageFeature: ImageFeature) {
    this.selectedImageFeatures$
      .pipe(first())
      .subscribe(
        (imageFeatures) => (this.droneImageImportLogicService.selectedImageFeatures = imageFeatures.filter((i) => i !== imageFeature))
      );
  }
}
