import { Component, ContentChild, Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapService } from '@app/core/map/map.service';
import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import { Feature, MapBrowserEvent, Overlay } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Subscription, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, pairwise, sampleTime, shareReplay, tap } from 'rxjs/operators';
import { mapAs } from '../operators';
import { CellFeatureDirective } from './cell-feature.directive';

/**
 * Provides a handle for the hover component to grab the view container's ng-container to insert the feature
 */
@Directive({ selector: '[cellOutlet]' })
export class CellOutletDirective {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef
  ) {}
}

@Component({
  selector: 'app-cell-hover',
  templateUrl: './cell-hover.component.html',
  styleUrls: ['./cell-hover.component.scss'],
})
export class CellHoverComponent implements OnInit, OnDestroy {
  @Input() public zoomEditThreshold?: number;
  @Input() public hoverOpacity?: number = 1;
  @Input() public mapLayerId?: MapLayerId | MapLayerId[];

  @ViewChild('popup', { static: true }) public popup?: ElementRef;
  @ViewChild(CellOutletDirective, { static: true }) private _cellOutlet?: CellOutletDirective;
  @ContentChild(CellFeatureDirective) private _cellFeatureDirective?: CellFeatureDirective;

  private _highlightLayer!: VectorLayer<VectorSource<Feature<Geometry>>>;
  private _overlay!: Overlay;

  private _subs = new Subscription();

  private _defaultZoomEditThreshold = this.mapService.zoomEditThreshold;
  private getZoom = () => this.mapService.getMap().getMap().getView().getZoom();

  private eventToFeatureStream = (event: MapBrowserEvent<UIEvent>) =>
    of(event).pipe(
      map((event) => this.mapService.getEventPixel(event.originalEvent)),
      map((pixel) =>
        this.mapService.getMapComponent().getFeaturesAtPixel(pixel, {
          layerFilter: (layer) => {
            const id = layer.get('id');
            return Array.isArray(this.mapLayerId)
              ? this.mapLayerId.map((l) => l.valueOf).includes(id) && layer.getVisible()
              : this.mapLayerId === layer.get('id') && layer.getVisible();
          },

          hitTolerance: 1,
        })
      ),
      map((features) => features[0])
    );

  /**
   * Buffer stream with last two (distinct) features at given pixel
   */

  private featureBuffer$ = this.mapService
    .addHoverInteraction()
    .pipe(
      sampleTime(5),
      map((event) => [event, this.getZoom()] as const),
      mergeMap(([event, zoom]) => {
        if (zoom && zoom >= (this.zoomEditThreshold ?? this._defaultZoomEditThreshold)) return this.eventToFeatureStream(event);
        else return of(null);
      })
    )
    .pipe(distinctUntilChanged(), pairwise(), shareReplay({ refCount: true }));

  /**
   * Side effect stream to clear tooltip when moving away from a feature point
   */
  private clearFeatureSideEffect$ = this.featureBuffer$.pipe(
    filter(([prev, _curr]) => !!prev),
    map(([prev, _curr]) => prev),
    mapAs<Feature>(),
    tap((feature) => this.clearFeature(feature))
  );

  /**
   * Side effect stream to add tooltip when moving onto a feature point
   */
  private addFeatureSideEffect$ = this.featureBuffer$.pipe(
    filter(([_prev, curr]) => !!curr),
    map(([_prev, curr]) => curr),
    mapAs<Feature>(),
    tap((feature) => this.addFeature(feature))
  );

  /**
   * Opacity stream
   */
  public popupOpacity$ = this.featureBuffer$.pipe(map(([_, newFeature]) => (newFeature ? 1 : 0)));

  constructor(private mapService: MapService) {}

  public ngOnInit() {
    this.addOverlay();
    this.addHighlightLayer();

    this._subs.add(this.clearFeatureSideEffect$.subscribe());
    this._subs.add(this.addFeatureSideEffect$.subscribe());
  }

  public ngOnDestroy() {
    (Array.isArray(this.mapLayerId) ? this.mapLayerId : [this.mapLayerId]).forEach((layerId) =>
      this.mapService.getMap().removeLayerFromMap(layerId!)
    );
    if (this.mapLayerId) this.mapService.getMap().removeLayerFromMap(this.mapLayerId as MapLayerId);

    this.mapService.getMap().getMap().removeLayer(this._highlightLayer);
    this.mapService.getMap().getMap().removeOverlay(this._overlay);

    this._subs.unsubscribe();
  }

  private addOverlay() {
    this._overlay = new Overlay({
      element: this.popup?.nativeElement,
      autoPan: false,
    });

    this.mapService.getMap().getMap().addOverlay(this._overlay);
  }

  private addHighlightLayer() {
    const featureOverlay = new VectorLayer({
      source: new VectorSource(),
      style: FieldStyles.fieldStyle,
      zIndex: 7,
      declutter: true,
    });

    // ? Why AS_APPLIED?
    featureOverlay.set('id', MapLayerId.AS_APPLIED_HIGHLIGHT);

    featureOverlay.set('opacity', this.hoverOpacity);

    this.mapService.getMap().getMap().addLayer(featureOverlay);

    this._highlightLayer = featureOverlay;
  }

  private clearFeature(feature: Feature) {
    const hasFeature = this._highlightLayer.getSource()?.hasFeature(feature);

    if (hasFeature) this._highlightLayer.getSource()?.removeFeature(feature);

    this._overlay.setPosition(undefined);

    // clear old view template
    this._cellOutlet?.viewContainer.clear();
  }

  private addFeature(feature: Feature) {
    this._highlightLayer.getSource()?.addFeature(feature);

    this._overlay.setPosition(feature.getGeometry()?.getExtent());

    // add view template
    if (this._cellFeatureDirective)
      this._cellOutlet?.viewContainer.createEmbeddedView(this._cellFeatureDirective.templateRef, { $implicit: feature });
  }
}
