import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { InfoHoverComponent } from './info-hover.component';

@NgModule({
  declarations: [InfoHoverComponent],
  imports: [CommonModule, ButtonsModule, IconModule],
  exports: [InfoHoverComponent],
})
export class InfoHoverModule {}
