import { ColumnKeyShared } from './column-key-shared';

export class ColumnKeyBlight {
  public static fieldNumber = ColumnKeyShared.fieldNumber;
  public static cropName = ColumnKeyShared.cropName;
  public static latestTreatment = Symbol(2);
  public static risk = Symbol(3);
  public static registeredBlight = Symbol(4);
  public static taskDate = Symbol(5);
  public static status = Symbol(6);
  public static productName = Symbol(7);
  public static productAmount = Symbol(8);
}
