import { Injectable } from '@angular/core';
import { PathNameHelper } from '@app/helpers/feature-branch/path-name-helper';
import { environment } from 'environments/environment';
import trimEnd from 'lodash-es/trimEnd';

@Injectable({
  providedIn: 'root',
})
export class EndpointsService {
  private pathName = PathNameHelper.getFeatureBranchRegulatedPathName(location.pathname.toLowerCase());

  get elasticSearchForward() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['elasticSearchForward']), '/');
  }

  get foApi() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['foApi']), '/');
  }

  get cnhApi() {
    return trimEnd(environment.endpoints['cnhApi'], '/');
  }

  get bffCnhApi() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['bffCnhApi']), '/');
  }

  get bffApi() {
    return this.adjustWithFeatureBranchName('/api/bff');
  }

  get cmApi() {
    return this.adjustWithFeatureBranchName('/api/cmApi');
  }

  get weatherDataApi() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['weatherDataApi']), '/');
  }

  get ftApi() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['ftApi']), '/');
  }

  get dataExchangeApi() {
    return trimEnd(environment.endpoints['dataExchangeApi'], '/');
  }

  get gatewayCNHApi() {
    return trimEnd(environment.endpoints['gatewayCNHApi'], '/');
  }

  get agrirouterManagerApi() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['agrirouterManagerApi']), '/');
  }

  get climateModelsApi() {
    return trimEnd(this.adjustWithFeatureBranchName(environment.endpoints['climateModelsApi']), '/');
  }

  get myProfile() {
    return trimEnd(environment.endpoints['userProfile'], '/');
  }

  get changePassword() {
    return trimEnd(environment.endpoints['userProfile'], '/') + '/ChangePassword?UserName=';
  }

  get delegation() {
    return trimEnd(environment.endpoints['delegation'], '/');
  }

  get customerCentre() {
    return trimEnd(environment.endpoints['customerCentre'], '/');
  }

  get siUserCreate() {
    return trimEnd(environment.endpoints['userProfile'], '/') + '/RequestNewAccount';
  }

  get siUserForgotPass() {
    return trimEnd(environment.endpoints['userProfile'], '/') + '/ForgotPassword';
  }

  get unleashUrl() {
    return trimEnd(environment.endpoints['unleashUrl'], '/') + '/proxy';
  }

  get siUserForgotUser() {
    return trimEnd(environment.endpoints['userProfile'], '/') + '/ForgotUserId';
  }

  get segesStatus() {
    return trimEnd(environment.endpoints['customerCentre'], '/') + '/category/generel-driftsstatus';
  }

  get notAuthenticatedRedirectUrl() {
    return trimEnd(`${location.origin}${this.adjustWithFeatureBranchName(environment.endpoints['notAuthenticatedRedirectUrl'])}`, '/');
  }

  private adjustWithFeatureBranchName(baseUrl: string) {
    return `${this.pathName}${baseUrl}`;
  }
}
