import { Injectable } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { CardProjection } from '../interfaces/card-projection.interface';

@Injectable()
export class UtmCardProjectionService {
  constructor(private languageService: LanguageService) {}

  public getCardProjections(): CardProjection[] {
    return [
      { srid: 32601, projection: 'UTM zone 1N' },
      { srid: 32602, projection: 'UTM zone 2N' },
      { srid: 32603, projection: 'UTM zone 3N' },
      { srid: 32604, projection: 'UTM zone 4N' },
      { srid: 32605, projection: 'UTM zone 5N' },
      { srid: 32606, projection: 'UTM zone 6N' },
      { srid: 32607, projection: 'UTM zone 7N' },
      { srid: 32608, projection: 'UTM zone 8N' },
      { srid: 32609, projection: 'UTM zone 9N' },
      { srid: 32610, projection: 'UTM zone 10N' },
      { srid: 32611, projection: 'UTM zone 11N' },
      { srid: 32612, projection: 'UTM zone 12N' },
      { srid: 32613, projection: 'UTM zone 13N' },
      { srid: 32614, projection: 'UTM zone 14N' },
      { srid: 32615, projection: 'UTM zone 15N' },
      { srid: 32616, projection: 'UTM zone 16N' },
      { srid: 32617, projection: 'UTM zone 17N' },
      { srid: 32618, projection: 'UTM zone 18N' },
      { srid: 32619, projection: 'UTM zone 19N' },
      { srid: 32620, projection: 'UTM zone 20N' },
      { srid: 32621, projection: 'UTM zone 21N' },
      { srid: 32622, projection: 'UTM zone 22N' },
      { srid: 32623, projection: 'UTM zone 23N' },
      { srid: 32624, projection: 'UTM zone 24N' },
      { srid: 32625, projection: 'UTM zone 25N' },
      { srid: 32626, projection: 'UTM zone 26N' },
      { srid: 32627, projection: 'UTM zone 27N' },
      { srid: 32628, projection: 'UTM zone 28N' },
      { srid: 32629, projection: 'UTM zone 29N' },
      { srid: 32630, projection: 'UTM zone 30N' },
      { srid: 32631, projection: 'UTM zone 31N' },
      { srid: 32632, projection: 'UTM zone 32N' },
      { srid: 32633, projection: 'UTM zone 33N' },
      { srid: 32634, projection: 'UTM zone 34N' },
      { srid: 32635, projection: 'UTM zone 35N' },
      { srid: 32636, projection: 'UTM zone 36N' },
      { srid: 32637, projection: 'UTM zone 37N' },
      { srid: 32638, projection: 'UTM zone 38N' },
      { srid: 32639, projection: 'UTM zone 39N' },
      { srid: 32640, projection: 'UTM zone 40N' },
      { srid: 32641, projection: 'UTM zone 41N' },
      { srid: 32642, projection: 'UTM zone 42N' },
      { srid: 32643, projection: 'UTM zone 43N' },
      { srid: 32644, projection: 'UTM zone 44N' },
      { srid: 32645, projection: 'UTM zone 45N' },
      { srid: 32646, projection: 'UTM zone 46N' },
      { srid: 32647, projection: 'UTM zone 47N' },
      { srid: 32648, projection: 'UTM zone 48N' },
      { srid: 32649, projection: 'UTM zone 49N' },
      { srid: 32650, projection: 'UTM zone 50N' },
      { srid: 32651, projection: 'UTM zone 51N' },
      { srid: 32652, projection: 'UTM zone 52N' },
      { srid: 32653, projection: 'UTM zone 53N' },
      { srid: 32654, projection: 'UTM zone 54N' },
      { srid: 32655, projection: 'UTM zone 55N' },
      { srid: 32656, projection: 'UTM zone 56N' },
      { srid: 32657, projection: 'UTM zone 57N' },
      { srid: 32658, projection: 'UTM zone 58N' },
      { srid: 32659, projection: 'UTM zone 59N' },
      { srid: 32660, projection: 'UTM zone 60N' },
      { srid: 32701, projection: 'UTM zone 1S' },
      { srid: 32702, projection: 'UTM zone 2S' },
      { srid: 32703, projection: 'UTM zone 3S' },
      { srid: 32704, projection: 'UTM zone 4S' },
      { srid: 32705, projection: 'UTM zone 5S' },
      { srid: 32706, projection: 'UTM zone 6S' },
      { srid: 32707, projection: 'UTM zone 7S' },
      { srid: 32708, projection: 'UTM zone 8S' },
      { srid: 32709, projection: 'UTM zone 9S' },
      { srid: 32710, projection: 'UTM zone 10S' },
      { srid: 32711, projection: 'UTM zone 11S' },
      { srid: 32712, projection: 'UTM zone 12S' },
      { srid: 32713, projection: 'UTM zone 13S' },
      { srid: 32714, projection: 'UTM zone 14S' },
      { srid: 32715, projection: 'UTM zone 15S' },
      { srid: 32716, projection: 'UTM zone 16S' },
      { srid: 32717, projection: 'UTM zone 17S' },
      { srid: 32718, projection: 'UTM zone 18S' },
      { srid: 32719, projection: 'UTM zone 19S' },
      { srid: 32720, projection: 'UTM zone 20S' },
      { srid: 32721, projection: 'UTM zone 21S' },
      { srid: 32722, projection: 'UTM zone 22S' },
      { srid: 32723, projection: 'UTM zone 23S' },
      { srid: 32724, projection: 'UTM zone 24S' },
      { srid: 32725, projection: 'UTM zone 25S' },
      { srid: 32726, projection: 'UTM zone 26S' },
      { srid: 32727, projection: 'UTM zone 27S' },
      { srid: 32728, projection: 'UTM zone 28S' },
      { srid: 32729, projection: 'UTM zone 29S' },
      { srid: 32730, projection: 'UTM zone 30S' },
      { srid: 32731, projection: 'UTM zone 31S' },
      { srid: 32732, projection: 'UTM zone 32S' },
      { srid: 32733, projection: 'UTM zone 33S' },
      { srid: 32734, projection: 'UTM zone 34S' },
      { srid: 32735, projection: 'UTM zone 35S' },
      { srid: 32736, projection: 'UTM zone 36S' },
      { srid: 32737, projection: 'UTM zone 37S' },
      { srid: 32738, projection: 'UTM zone 38S' },
      { srid: 32739, projection: 'UTM zone 39S' },
      { srid: 32740, projection: 'UTM zone 40S' },
      { srid: 32741, projection: 'UTM zone 41S' },
      { srid: 32742, projection: 'UTM zone 42S' },
      { srid: 32743, projection: 'UTM zone 43S' },
      { srid: 32744, projection: 'UTM zone 44S' },
      { srid: 32745, projection: 'UTM zone 45S' },
      { srid: 32746, projection: 'UTM zone 46S' },
      { srid: 32747, projection: 'UTM zone 47S' },
      { srid: 32748, projection: 'UTM zone 48S' },
      { srid: 32749, projection: 'UTM zone 49S' },
      { srid: 32750, projection: 'UTM zone 50S' },
      { srid: 32751, projection: 'UTM zone 51S' },
      { srid: 32752, projection: 'UTM zone 52S' },
      { srid: 32753, projection: 'UTM zone 53S' },
      { srid: 32754, projection: 'UTM zone 54S' },
      { srid: 32755, projection: 'UTM zone 55S' },
      { srid: 32756, projection: 'UTM zone 56S' },
      { srid: 32757, projection: 'UTM zone 57S' },
      { srid: 32758, projection: 'UTM zone 58S' },
      { srid: 32759, projection: 'UTM zone 59S' },
      { srid: 32760, projection: 'UTM zone 60S' },
      { srid: 4326, projection: `WGS 84 ${this.languageService.getText('main.shapeFileImport.longitude-latitude')}` },
    ];
  }
}
