import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-drone-image-upload-dialog',
  templateUrl: './drone-image-upload-dialog.component.html',
  styleUrls: ['./drone-image-upload-dialog.component.scss'],
})
export class DroneImageUploadDialogComponent {
  constructor(public dialogRef: MatDialogRef<DroneImageUploadDialogComponent>) {}

  private close(val?: boolean) {
    this.dialogRef.close(val);
  }

  public onCancelClicked() {
    this.close(false);
  }

  public onConfirmClicked() {
    this.close(true);
  }
}
