import { Colors } from '@app/core/enums/colors.enum';
import { PlotBandColors } from '@app/core/enums/plot-band-colors.enum';

export function getPlotBandColor(color: PlotBandColors) {
  switch (color) {
    case PlotBandColors.Green:
      return Colors.Green4;
    case PlotBandColors.Yellow:
      return Colors.Yellow4;
    case PlotBandColors.Red:
      return Colors.Red4;
    default:
      return Colors.Black;
  }
}
