import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguagePickerService } from '@app/core/language-picker/language-picker.service';
import { OperationTypeGroupsRepo } from '@app/core/repositories/operation-type-groups/operation-type-groups-repo.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CacheService } from '../cache/cache.service';

export interface IOperationTypeGroupsService {
  get(): Observable<OperationTypeGroup[]>;
  getByOperationId(): Observable<{ [operationTypeId: number]: OperationTypeGroup }>;
}

@Injectable({
  providedIn: 'root',
})
export class OperationTypeGroupsService implements IOperationTypeGroupsService {
  private operationTypeGroupsCache = this.cacheService.create<OperationTypeGroup[]>({
    defaultTtl: 1000 * 60 * 60,
  });

  constructor(
    private operationTypeGroupsRepo: OperationTypeGroupsRepo,
    private cacheService: CacheService,
    private languagePickerService: LanguagePickerService,
    private sanitizer: DomSanitizer
  ) {}

  public get(): Observable<OperationTypeGroup[]> {
    return this.languagePickerService.getLangShortCode$().pipe(
      switchMap((lang) =>
        this.operationTypeGroupsCache.getOrSetAsync(lang, () => this.operationTypeGroupsRepo.getOperationTypeGroups(lang))
      ),
      tap((groups) => groups!.forEach((group) => this.sanitizer.bypassSecurityTrustUrl((group as any)!.imageUrl)))
    ) as unknown as Observable<OperationTypeGroup[]>;
  }

  public getByOperationId(): Observable<{ [operationTypeId: number]: OperationTypeGroup }> {
    return this.get().pipe(map((groups) => this.keyOperationTypeGroupsByOperationTypeId(groups)));
  }

  private keyOperationTypeGroupsByOperationTypeId(operationTypeGroups: OperationTypeGroup[]): {
    [operationTypeId: number]: OperationTypeGroup;
  } {
    return operationTypeGroups.reduce(
      (res, operationTypeGroup) => ({
        // Apply what we already have
        ...res,
        // Apply the next map from the current group
        ...this.transformToMap(operationTypeGroup),
      }),
      {}
    );
  }

  private transformToMap(operationTypeGroup: OperationTypeGroup): { [operationTypeId: number]: OperationTypeGroup } {
    return (
      operationTypeGroup.operationTypeIds
        // Pretty straight forward way of keying an array by some property
        .reduce((result, operationTypeId) => ({ ...result, [operationTypeId]: operationTypeGroup }), {})
    );
  }
}
