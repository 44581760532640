<div class="export-fields-main-wrapper">
  <app-side-drawer-wrapper>
    <app-side-drawer-header
      class="flex-child"
      [title]="'main.fieldAdministration.exportFields.drawerTitle' | translate"
      (action)="onCloseClick()"
      cancelIconName="back"
    ></app-side-drawer-header>

    <app-side-drawer-body>
      <div class="export-fields-content">
        <p>{{ 'main.fieldAdministration.exportFields.chooseText' | translate }}</p>
        <p>{{ 'main.fieldAdministration.exportFields.conditional' | translate }}</p>
      </div>
      <div class="content-wrapper">
        <app-select
          #selectComponent
          id="e2e-export-farm-select"
          data-testid="dropdown-select"
          [width]="'150px'"
          [ngModel]="selectedFarmID"
          (valueChange)="onFarmSelect($event)"
          [error]=""
          [disabled]="false"
        >
          <app-select-option
            attr.data-testid="select-option-{{ index }}"
            *ngFor="let farm of farms$ | async; let index = index; trackBy: farmsTrackByFn"
            [value]="farm.id"
          >
            {{ farm.name }}
          </app-select-option>
        </app-select>
        <app-export-fields-list [fields$]="fields$"></app-export-fields-list>
      </div>
    </app-side-drawer-body>
    <app-side-drawer-footer>
      <app-side-drawer-footer-button data-testid="download-button" (clicked)="onDownloadClick()">
        {{ 'main.fieldAdministration.exportFields.downloadFile' | translate }}
      </app-side-drawer-footer-button>
    </app-side-drawer-footer>
  </app-side-drawer-wrapper>
</div>
