<mat-card appearance="outlined">
  <mat-card-content>
    <div class="price-info-text">
      <b class="info-text">{{ 'main.fieldmap.droneImageImport.pricing.values-info' | translate }}</b>
      <div class="info-box">
        <app-icon icon="info_outline" class="info-hover" (click)="onInfoClick()"> </app-icon>
      </div>
    </div>
    <div class="price-wrapper">
      <div class="price" *ngIf="(totalPricing$ | async)! > 0; else noValues">
        {{ totalPricing$ | async }} <span>{{ 'common.unitType.kr' | translate }}</span>
      </div>
      <ng-template #noValues>
        <span class="no-values">{{ 'main.fieldmap.droneImageImport.pricing.no-values' | translate }}</span>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>
