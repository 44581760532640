import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export interface IStorageService {
  getFromStorage<T>(key: string): T;
  saveToStorage<T>(key: string, value: T): void;
  removeFromStorage(key: string): void;
  getSelectedFarmIds(): number[];
  setSelectedFarmIds(selectedFarmIds: number[]): any;
  removeSelectedFarmIds(): any;
  shouldShowIE11Message(): boolean;
  setIE11Timestamp(): void;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService implements IStorageService {
  private prefix = 'cropmanager';

  /**
   * Saves data to localstorage.
   * Removes existing data with same name first.
   */
  public saveToStorage(key: string, value: any) {
    this.setValue(key, value);
  }

  /**
   * Gets specific data from localstorage.
   */
  public getFromStorage<T>(key: string): T {
    return this.getValue(key);
  }

  /**
   * Remove key and value in localestorage
   */
  public removeFromStorage(key: string): void {
    this.remove(key);
  }

  public getSelectedFarmIds(): number[] {
    return this.getValue(this.prefixedKey('selectedFarmIds'));
  }

  public setSelectedFarmIds(selectedFarmIds: number[]) {
    this.setValue('selectedFarmIds', selectedFarmIds);
  }

  public removeSelectedFarmIds() {
    this.remove('selectedFarmIds');
  }

  public shouldShowIE11Message() {
    // Wait at least 30 days (1 day = 8640000ms) before showing notification again.
    const timestamp = localStorage.getItem('IE11Timestamp');

    return timestamp === null || (DateTime.now().toMillis() - JSON.parse(timestamp)) / 86400000 > 1;
  }

  public setIE11Timestamp() {
    localStorage.setItem('IE11Timestamp', JSON.stringify(DateTime.now().toMillis()));
  }

  private setValue(key: string, value: any) {
    const serialized = JSON.stringify(value);

    localStorage.setItem(this.prefixedKey(key), serialized);
  }

  private getValue(key: string) {
    try {
      const serialized = localStorage.getItem(this.prefixedKey(key));

      const deserialized = JSON.parse(serialized!);

      return deserialized;
    } catch (e) {
      return undefined;
    }
  }

  private remove(key: any) {
    localStorage.removeItem(this.prefixedKey(key));
  }

  private prefixedKey(key: string) {
    if (key.includes(`${this.prefix}.`)) {
      return key;
    }

    const prefixedKey = `${this.prefix}.${key}`;

    return prefixedKey;
  }
}
