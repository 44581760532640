import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NaesgaardGuard {
  constructor(private bffAuthService: BffAuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.bffAuthService.isCurrentUserFromNaesgaard$.pipe(map((isNaesgaard) => !isNaesgaard));
  }

  /**
   * Prevents Næsgaard users from leaving the route it is implemented in.
   */
  canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.bffAuthService.isCurrentUserFromNaesgaard$.pipe(map((isNaesgaard) => !isNaesgaard));
  }
}
