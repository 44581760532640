import { BasisLayerCategory } from '@app/core/interfaces/basis-layer/basis-layer-category';
import { BasisLayerDto } from '@app/core/interfaces/basis-layer/basis-layer-dto.interface';

export class BasisLayer implements BasisLayerDto {
  public categoryId?: number;
  public farmId?: number;
  public geometry?: string;
  public id?: number;

  private _category?: BasisLayerCategory;

  public get category(): BasisLayerCategory | undefined {
    return this._category;
  }

  public set category(category: BasisLayerCategory | undefined) {
    this._category = category;
    this.categoryId = category?.id;
  }

  constructor(basisLayerDto?: BasisLayerDto, category?: BasisLayerCategory) {
    this.farmId = basisLayerDto?.farmId;
    this.geometry = basisLayerDto?.geometry;
    this.id = basisLayerDto?.id;
    this.category = category;
  }
}
