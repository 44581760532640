<div class="content">
  <form>
    <div class="columnOne">
      {{ fieldNumber }}
    </div>
    <div class="columnTwo">
      {{ fieldName }}
    </div>
    <div class="columnThree">
      <span *ngIf="fieldCrop">{{ fieldCrop }}</span>
      <span *ngIf="!fieldCrop">{{ 'main.fieldAdministration.createField.dropDownAdditionalContent.noCrop' | translate }}</span>
    </div>
    <!-- <div class="columnFour">
      {{fieldCropSort}}
     </div> -->
    <div class="columnFive">
      <mat-checkbox (change)="includeCropChange($event)" [checked]="includeCrop"></mat-checkbox>
    </div>
    <div class="columnSix">
      <app-add-button *ngIf="!isIncluded" size="sm" [invert]="true" color="#969696" (clicked)="add()"></app-add-button>
      <app-remove-button *ngIf="isIncluded" size="sm" (clicked)="remove()"></app-remove-button>
    </div>
  </form>
</div>
