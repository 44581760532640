import { Directive, OnInit } from '@angular/core';
import { MapService } from '@app/core/map/map.service';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { Subscription } from 'rxjs';

/*
  Base class for a map feature used for plugging in new functionality to the map
*/
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseMapFeature implements OnInit {
  protected subscriptions: Subscription[] = [];

  protected get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }

  constructor(protected mapService: MapService) {}

  /**
   * Due to Angular's lifecycle squence where all components ngOnInit hook is called before all ngOnDestroy hooks each component must clean up the map in their init method.
   * Otherwise the new initialized component will receive state of the map before the previous component has had a chance to clean up after itself.
   */
  public ngOnInit(): void {
    this.map.deselectFeatures();
    this.map.cleanup();
    this.map.forceRerender();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
