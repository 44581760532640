<p class="legend-title">{{ legend.title | translate }}</p>

<div class="legend-content">
  <div class="colors">
    <div class="legend-entry" *ngFor="let entry of legend.entries">
      <div class="color" [ngStyle]="{ 'background-color': entry.color }"></div>
    </div>
  </div>

  <div class="numbers">
    <div class="eight pos-relative">8</div>
    <div class="seven pos-relative">7</div>
    <div class="six pos-relative">6</div>
    <div class="five pos-relative">5</div>
  </div>
</div>
