import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { AsAppliedTooltipComponent } from './as-applied-tooltip.component';

@NgModule({
  declarations: [AsAppliedTooltipComponent],
  imports: [SharedModule],
  exports: [AsAppliedTooltipComponent],
})
export class AsAppliedTooltipModule {}
