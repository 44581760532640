import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapZoomStateService {
  private mapZoom!: number;
  private mapCenter!: [number, number];

  constructor() {}

  set fieldMapZoom(zoom: number) {
    this.mapZoom = zoom;
  }

  get fieldMapZoom(): number {
    return this.mapZoom;
  }

  set fieldMapCenter(center: [number, number]) {
    this.mapCenter = center;
  }

  get fieldMapCenter(): [number, number] {
    return this.mapCenter;
  }
}
