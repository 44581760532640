import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { SoilSampleMapFeatureComponent } from './soil-sample-feature.component';
import { SoilSampleFeatureService } from './soil-sample-feature.service';
import { SoilSampleLegendComponent } from './soil-sample-legend/soil-sample-legend.component';
import { SoilSampleLegendModule } from './soil-sample-legend/soil-sample-legend.module';
import { SoilSampleRadioLabelComponent } from './soil-sample-side-drawer/soil-sample-radio-label/soil-sample-radio-label.component';
import { SoilSampleSideDrawerComponent } from './soil-sample-side-drawer/soil-sample-side-drawer.component';
import { SoilSampleTableComponent } from './soil-sample-side-drawer/soil-sample-table/soil-sample-table.component';

@NgModule({
  declarations: [SoilSampleMapFeatureComponent, SoilSampleSideDrawerComponent, SoilSampleRadioLabelComponent, SoilSampleTableComponent],
  exports: [SoilSampleMapFeatureComponent, SoilSampleLegendComponent],
  providers: [SoilSampleFeatureService],
  imports: [SharedModule, SoilSampleLegendModule],
})
export class SoilSampleMapFeatureModule {}
