import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Class for handling a local state.
 * This is currently based on RxJS BehaviorSubject
 */
export class LocalState<T> {
  private readonly state$: BehaviorSubject<T>;
  public changes$: Observable<T>;

  constructor(initialState: T) {
    this.state$ = new BehaviorSubject<T>(initialState);
    this.changes$ = this.state$.asObservable();
  }

  public setState(state: T) {
    this.state$.next(state);
  }
}
