import { environment } from './environment';

export class Endpoints {
  public static customerCentre = environment.endpoints['customerCentre'];
  public static dataExchangeAapi = environment.endpoints['dataExchangeApi'];
  public static agrirouterManagerApi = environment.endpoints['agrirouterManagerApi'];
  public static weatherDataApi = environment.endpoints['weatherDataApi'];
  public static ftApi = environment.endpoints['ftApi'];
  public static climateModelsApi = environment.endpoints['climateModelsApi'];
  public static machineApi = environment.endpoints['machineApi'];
  public static elasticSearchForward = environment.endpoints['elasticSearchForward'];
  public static delegation = environment.endpoints['delegation'];
  public static userProfile = environment.endpoints['userProfile'];
  public static unleashUrl = environment.endpoints['unleashUrl'];
  public static dataforsyningApi = environment.endpoints['dataforsyningApi'];
  public static foApi = environment.endpoints['foApi'];
  public static cnhApi = environment.endpoints['cnhApi'];
  public static bffCnhApi = environment.endpoints['bffCnhApi'];
  public static cmApi = environment.endpoints['cmApi'];
}

export const endpoints = Endpoints;
