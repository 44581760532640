<div class="side-drawer-wrapper">
  <app-side-drawer-header
    [title]="'fieldSelector.title' | translate"
    [showInfoHoverButton]="true"
    (action)="onCloseClick()"
    cancelIconName="Luk"
    [showMinimizeButton]="false"
  >
  </app-side-drawer-header>

  <app-side-drawer-body>
    <app-spinner *ngIf="(loading$ | async)!.isLoading" [hasBackdrop]="true" [message]="(loading$ | async)!.message"></app-spinner>
    <div class="content-wrapper">
      <h5>{{ 'fieldSelector.fieldsWithSameTask' | translate }}</h5>
      <div>
        <app-field-collection-item class="base-field" [crop]="selectedCrop"></app-field-collection-item>
        <ng-container *ngIf="cropsWithSimilarTask.length">
          <div class="field-list" [@flyInOut]="'in'" *ngFor="let item of cropsWithSimilarTask; let first = first">
            <app-field-collection-item
              class="field"
              [ngClass]="{ first: first }"
              [crop]="item"
              [canAdd]="false"
              [canRemove]="true"
              (onRemove)="deselectFromFieldsWithSimilarTask(item)"
            ></app-field-collection-item>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="newlyAdded.length">
        <h5 class="subheader">{{ 'fieldSelector.newlySelectedFields' | translate }}</h5>
        <div class="field-list" [@flyInOut]="'in'" *ngFor="let item of newlyAdded; let first = first">
          <app-field-collection-item
            class="field"
            [ngClass]="{ first: first }"
            [crop]="item"
            [canRemove]="true"
            (onRemove)="deselect(item)"
          ></app-field-collection-item>
        </div>
      </ng-container>

      <ng-container *ngIf="cropsWithSimilarCrop.length">
        <h5 class="subheader">{{ 'fieldSelector.fieldsWithSimilarCrop' | translate }}</h5>
        <div class="field-list" [@flyInOut]="'in'" *ngFor="let item of cropsWithSimilarCrop; let first = first">
          <app-field-collection-item
            class="field"
            [ngClass]="{ first: first }"
            [crop]="item"
            (onAdd)="select(item)"
            [canAdd]="true"
          ></app-field-collection-item>
        </div>
      </ng-container>
      <ng-container *ngIf="otherCrops.length">
        <h5 class="subheader">{{ 'fieldSelector.otherFields' | translate }}</h5>
        <div class="field-list" [@flyInOut]="'in'" *ngFor="let item of otherCrops; let first = first">
          <app-field-collection-item class="field" [ngClass]="{ first: first }" [crop]="item" (onAdd)="select(item)" [canAdd]="true">
          </app-field-collection-item>
        </div>
      </ng-container>
    </div>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button (clicked)="onSaveClick()">{{ 'fieldSelector.save' | translate }} </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</div>
