import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
/**
 * Adds the x-csrf header to requests to the bff
 */
export class BffAuthInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      httpRequest.url.includes('api') ? httpRequest.clone({ setHeaders: { 'x-csrf': '1' }, withCredentials: true }) : httpRequest
    );
  }
}
