<div class="wrapper" *ngIf="growthRegulationFieldTableVM">
  <h5 class="checkOutTableTitle">{{ 'main.growthRegulation.insurance.checkOutTable.title' | translate }}</h5>
  <table>
    <thead>
      <tr>
        <th>{{ 'main.growthRegulation.insurance.field' | translate }}</th>
        <th>{{ 'main.growthRegulation.insurance.area' | translate }}</th>
        <th>{{ 'main.growthRegulation.insurance.risk' | translate }}</th>
        <th>{{ 'main.growthRegulation.insurance.crop' | translate }}</th>
        <th>{{ 'main.growthRegulation.insurance.price' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of fields">
        <td>{{ field.fieldNumber }}</td>
        <td>{{ field.area | unit }}</td>
        <td>{{ field.risk }}</td>
        <td>{{ field.crop | translate }}</td>
        <td>{{ field.price | unit: 'kr' }}</td>
      </tr>
      <tr class="sum">
        <td>{{ 'main.growthRegulation.insurance.sum' | translate }}:</td>
        <td>{{ totalArea | unit }}</td>
        <td></td>
        <td></td>
        <td>{{ totalPrice | unit: 'kr' }},-</td>
      </tr>
    </tbody>
  </table>
</div>
