import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { FileUploadComponent } from './file-upload.component';

@NgModule({
  imports: [CommonModule, ButtonsModule, IconModule],
  declarations: [FileUploadComponent],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
