import { Injectable } from '@angular/core';
import { DataConnectionState } from '@app/core/enums/data-connection-state.enum';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { DataConnectionType } from '@app/core/interfaces/data-connection-type.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

interface DataConnection {
  dataConnectionId: number;
  dataConnectionState: DataConnectionState;
}

@Injectable({
  providedIn: 'root',
})
export class DatamanagementStateService {
  private _dataConnectionSettingsSubject = new BehaviorSubject<DataConnectionSettingDTO[]>([]);
  private _dataConnectionTypesSubject = new BehaviorSubject<DataConnectionType[]>([]);
  private _dataConnectionStatesSubject = new BehaviorSubject<DataConnection[]>([]);

  public get dataConnectionSettings$(): Observable<DataConnectionSettingDTO[]> {
    return this._dataConnectionSettingsSubject.asObservable();
  }

  public set dataConnectionSettings(dataConnectionSettings: DataConnectionSettingDTO[]) {
    this._dataConnectionSettingsSubject.next(dataConnectionSettings);
  }

  public get dataConnectionTypes$(): Observable<DataConnectionType[]> {
    return this._dataConnectionTypesSubject.asObservable();
  }

  public set dataConnectionTypes(dataConnectionTypes: DataConnectionType[]) {
    this._dataConnectionTypesSubject.next(dataConnectionTypes);
  }

  public get dataConnectionStates$() {
    return this._dataConnectionStatesSubject.asObservable();
  }

  public set dataConnectionStates(dataConnectionStates: { dataConnectionId: number; dataConnectionState: DataConnectionState }[]) {
    this._dataConnectionStatesSubject.next(dataConnectionStates);
  }

  constructor() {}

  public updateDataconnection(dataConnection: DataConnectionSettingDTO) {
    this.dataConnectionSettings$
      .pipe(
        first(),
        map((dataConnectionSettings) => {
          let index = this.findIndexOnDataConnection(dataConnectionSettings, dataConnection);

          if (index > -1) {
            dataConnectionSettings.splice(index, 1, dataConnection);
          } else {
            dataConnectionSettings.push(dataConnection);
          }

          return dataConnectionSettings;
        })
      )
      .subscribe((updatedDataConnections) => {
        this.dataConnectionSettings = updatedDataConnections;
      });
  }

  public deleteDataConnection(dataConnection: DataConnectionSettingDTO) {
    this.dataConnectionSettings$
      .pipe(
        first(),
        map((dataConnectionSettings) => {
          let index = this.findIndexOnDataConnection(dataConnectionSettings, dataConnection);
          if (index > -1) {
            dataConnectionSettings.splice(index, 1);
          }
          return dataConnectionSettings;
        })
      )
      .subscribe((dataConnectionsWithoutDeleted) => {
        this.dataConnectionSettings = dataConnectionsWithoutDeleted;
      });
  }

  public updateDataConnectionStates(dataConnectionState: { dataConnectionId: number; dataConnectionState: DataConnectionState }) {
    this.dataConnectionStates$
      .pipe(
        first(),
        map((dataConnectionStates: { dataConnectionId: number; dataConnectionState: DataConnectionState }[]) => {
          let index = this.findIndexOnDataConnectionState(dataConnectionStates, dataConnectionState);
          if (index > -1) {
            dataConnectionStates.splice(index, 1, dataConnectionState);
          } else {
            dataConnectionStates.push(dataConnectionState);
          }
          return dataConnectionStates;
        })
      )
      .subscribe((dataConnectionStates) => {
        this.dataConnectionStates = dataConnectionStates;
      });
  }

  public deleteDataConnectionState(dataConnectionState: { dataConnectionId: number; dataConnectionState: DataConnectionState }) {
    this.dataConnectionStates$
      .pipe(
        first(),
        map((dataConnectionStates) => {
          let index = this.findIndexOnDataConnectionState(dataConnectionStates, dataConnectionState);
          if (index > -1) {
            dataConnectionStates.splice(index, 1);
          }
          return dataConnectionStates;
        })
      )
      .subscribe((dataConnectionStatesWithoutDeleted) => {
        this.dataConnectionStates = dataConnectionStatesWithoutDeleted;
      });
  }

  private findIndexOnDataConnection(dataConnectionSettings: DataConnectionSettingDTO[], dataConnection: DataConnectionSettingDTO): number {
    return dataConnectionSettings.findIndex((dataConnectionSetting) => dataConnectionSetting.id === dataConnection.id);
  }

  private findIndexOnDataConnectionState(
    dataConnectionStates: { dataConnectionId: number; dataConnectionState: DataConnectionState }[],
    dataConnectionState: { dataConnectionId: number; dataConnectionState: DataConnectionState }
  ): number {
    return dataConnectionStates.findIndex((connectionState) => connectionState.dataConnectionId === dataConnectionState.dataConnectionId);
  }
}
