import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MapService } from '@app/core/map/map.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Subscription } from 'rxjs';
import { map, skip, startWith } from 'rxjs/operators';
import { FieldAnalysisSideDrawerContentComponent } from '../../../field-analysis-side-drawer/field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from '../../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { AsAppliedFieldTaskListTableData } from '../AsAppliedFieldTaskListTableData.interface';
import { AsAppliedOperationTypeGroup } from '../as-applied-group.class';
import { AsAppliedShownComponentEnum } from '../as-applied-shown-component.enum';
import { AsAppliedTask } from '../as-applied-task.class';
import { AsAppliedService } from '../as-applied.service';

@Component({
  selector: 'app-as-applied-field-tasks',
  templateUrl: './as-applied-field-tasks.component.html',
  styleUrls: ['./as-applied-field-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsAppliedFieldTasksComponent implements OnChanges, OnDestroy, OnInit {
  @Input() public field: Field | null = null;
  @Input() public asAppliedTaskGroups!: AsAppliedOperationTypeGroup[];
  @Input() public unspecifiedTaskGroup!: AsAppliedOperationTypeGroup;
  @Output() public taskSelect = new EventEmitter<AsAppliedTask>();

  public get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }

  public tableData: AsAppliedFieldTaskListTableData[] = [];

  public tableColumns$ = this.languageService.languageChanged.asObservable().pipe(
    startWith({
      displayDate: this.languageService.getText('main.fieldmap.vra.date'),
      productName: this.languageService.getText('main.fieldmap.vra.product'),
      operationTypeGroup: this.languageService.getText('main.fieldmap.vra.taskType'),
    }),
    map(() => {
      return {
        displayDate: this.languageService.getText('main.fieldmap.vra.date'),
        productName: this.languageService.getText('main.fieldmap.vra.product'),
        operationTypeGroup: this.languageService.getText('main.fieldmap.vra.taskType'),
      };
    })
  );

  public readonly tableStyles = {
    productName: {
      color: '#4e808d',
      fontWeight: 'bold',
      padding: '0.7rem 1.5rem 0.7rem 0',
      textAlign: 'left',
    },
    operationTypeGroup: {
      color: '#4e808d',
      fontWeight: 'bold',
      padding: '0.7rem 1.5rem 0.7rem 0',
      textAlign: 'left',
    },
    displayDate: {
      whiteSpace: 'nowrap',
      padding: '0.7rem 0.5rem',
      textAlign: 'left',
    },
  };

  private subscriptions = new Subscription();

  constructor(
    private asAppliedService: AsAppliedService,
    private languageService: LanguageService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>,
    private mapService: MapService,
    private harvestYearStateService: HarvestYearStateService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService
  ) {}

  public ngOnInit() {
    this.map.enableSelectInteraction();
    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.pipe(skip(1)).subscribe(() => {
        this.fieldAnalysisSideDrawerService.setShowLegendState(false);
        this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
      })
    );
  }

  public ngOnDestroy() {
    this.map.disableSelectInteraction();
    this.map.deselectFeatures();
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['field']?.currentValue) {
      this.tableData = this.getVraFieldTaskListData(changes['field'].currentValue, this.asAppliedTaskGroups, this.unspecifiedTaskGroup);
    }
  }

  public onTaskSelect(task: AsAppliedTask) {
    this.taskSelect.emit(task);
  }

  private getVraFieldTaskListData(
    field: Field,
    asAppliedOperationTypeGroups: AsAppliedOperationTypeGroup[],
    unspecifiedTaskGroup: AsAppliedOperationTypeGroup
  ) {
    let filteredGroups = this.asAppliedService.filterTasksForField(field, asAppliedOperationTypeGroups);
    const filteredUnspecifiedGroups = this.asAppliedService.filterUnspecifiedTasksForField(field, unspecifiedTaskGroup);
    filteredGroups = [...filteredGroups, filteredUnspecifiedGroups];
    return this.asAppliedService.mapAsAppliedTasksToTableData(filteredGroups);
  }

  public readonly sortFn = (item: any, property: any) => {
    switch (property) {
      case 'displayDate':
        return item.date;
      default:
        return item[property];
    }
  };
}
