<app-spinner *ngIf="loading" [hasBackdrop]="true"></app-spinner>
<div class="table-wrapper">
  <app-filter-chip-container [scope]="SCOPE" [customTemplates]="getTemplateMapForNBalance(nBalanceCustomTemplate)">
  </app-filter-chip-container>
  <form [formGroup]="formGroup" *ngIf="!!formGroup">
    <kendo-treelist
      *ngIf="displayData$ | async as displayData"
      [kendoTreeListFlatBinding]="displayData"
      #treeList
      kendoTreeListExpandable
      idField="tableLineId"
      [fetchChildren]="fetchChilren"
      [hasChildren]="hasChildren"
      [rowClass]="rowCallback"
      [expandedKeys]="(expandedIds$ | async)!"
      (expandedKeysChange)="onExpandedIdsChange()"
      [selectable]="settings"
      (selectionChange)="onSelectionChange($event)"
      (cellClick)="cellClick($event)"
      (cellClose)="cellCloseHandler($event)"
      [isSelected]="isSelected"
      expandBy="field.id"
    >
      <kendo-treelist-messages noRecords="{{ 'nTool.table.noRecords' | translate }}"> </kendo-treelist-messages>
      <!--   FIELD NUMBER   -->
      <kendo-treelist-column [expandable]="true" field="field.number" [width]="100">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.fieldsColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.fieldNumber))"
            (sortChange)="sortChange($event)"
            (filterChange)="onFilterChange($any($event), COLUMN_KEY.fieldNumber)"
            [filterables$]="getAvailableFilterablesForColumn$(COLUMN_KEY.fieldNumber)"
            [columnKey]="COLUMN_KEY.fieldNumber"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ hasChildren(dataItem) ? dataItem.field.number : NO_DATA }}
        </ng-template>
      </kendo-treelist-column>
      <!--   CROP NAME   -->
      <kendo-treelist-column field="crop.cropname" [width]="110">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.cropColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.cropName))"
            (sortChange)="sortChange($event)"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ hasChildren(dataItem) ? dataItem.crop.cropName : NO_DATA }}
        </ng-template>
      </kendo-treelist-column>

      <!--   N ASSIGNED   -->
      <kendo-treelist-column field="_" [width]="160">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.nAssignedColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.nAssigned))"
            (sortChange)="sortChange($event)"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ hasChildren(dataItem) ? dataItem.appliedNWithSuffix : '' }}
        </ng-template></kendo-treelist-column
      >

      <!--   N PLANNED   -->
      <kendo-treelist-column field="_" [width]="160">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.nPlannedColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.nPlanned))"
            (sortChange)="sortChange($event)"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ hasChildren(dataItem) ? dataItem.totalNWithSuffix : '' }}
        </ng-template></kendo-treelist-column
      >

      <!--   N NEEDS   -->
      <kendo-treelist-column field="_" [width]="160" title="{{ 'nTool.table.header.nNeedsColumn' | translate }}">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.nNeedsColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.nNeeds))"
            (sortChange)="sortChange($event)"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ dataItem.nTotalNeedWithSuffix }}
        </ng-template></kendo-treelist-column
      >

      <!--  N Balance and N Zero cells have no content, until new business rules are implemented.  -->
      <!--  This is done because it is hard to remove the columns, we rely on column index in isColumnEditable(), handleSpecificColumnLogic() and maybe elsewhere.   -->
      <!--  To reapply the original logic, search 'reapply-nbalance' in this file and follow instructions  -->
      <!---  N BALANCE -->
      <kendo-treelist-column field="_" [width]="120" title="{{ 'nTool.table.header.nBalanceColumn' | translate }}">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.nBalanceColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.nBalance))"
            (sortChange)="sortChange($event)"
            (filterChange)="onFilterChange($any($event), COLUMN_KEY.nBalance)"
            [filterables$]="getAvailableFilterablesForColumn$(COLUMN_KEY.nBalance)"
            [customTemplate]="nBalanceCustomTemplate"
            [columnKey]="COLUMN_KEY.nBalance"
            [filterHideThreshold]="1"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <ng-container
            *ngTemplateOutlet="
              hasChildren(dataItem) ? nBalanceCustomTemplateWithParentWarning : nBalanceChild;
              context: { $implicit: dataItem }
            "
          >
          </ng-container>
        </ng-template>
      </kendo-treelist-column>

      <!--      -  N ZERO  -->
      <!--  To reapply the original logic, search 'reapply-nzero' in this file and follow instructions  -->
      <kendo-treelist-column field="_" title="{{ 'nTool.table.header.nZeroColumn' | translate }}" [width]="120">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ dataItem?.nZeroDate ? (dataItem.nZeroDate | dateFormat) : NO_DATA }}
        </ng-template>
      </kendo-treelist-column>

      <!---  NEXT TREATMENT -->
      <kendo-treelist-column field="_" [width]="220">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.nextTreatmentColumn'"
            [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.nextTreatment))"
            (sortChange)="sortChange($event)"
          ></app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{
            hasChildren(dataItem) && dataItem?.nextTreatment
              ? (dataItem?.nextTreatment?.date | dateFormat) + ' (' + dataItem?.nextTreatment?.NStatusWithSuffix + ')'
              : NO_DATA
          }}
        </ng-template>
      </kendo-treelist-column>

      <!--  STATUS  -->
      <kendo-treelist-column field="status" title="{{ 'nTool.table.header.statusColumn' | translate }}" [width]="120">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <div class="status-container" *ngIf="!hasChildren(dataItem)">
            <div>
              <span class="editable-cell">{{ dataItem.status | taskStatus }}</span>
            </div>
            <ng-container [ngSwitch]="dataItem.status">
              <app-icon *ngSwitchCase="N_TOOLS_TASK_STATUS.done" icon="done_outlined" class="status-done"></app-icon>
              <app-icon *ngSwitchCase="N_TOOLS_TASK_STATUS.planned" icon="pending_outlined" class="status-planned"></app-icon>
              <ng-container *ngSwitchDefault [ngTemplateOutlet]="noData"></ng-container>
            </ng-container>
          </div>
        </ng-template>
      </kendo-treelist-column>

      <!--   DATE   -->
      <kendo-treelist-column field="date" [width]="130" title="{{ 'nTool.table.header.dateColumn' | translate }}">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <span class="editable-cell">{{ hasChildren(dataItem) ? NO_DATA : (dataItem.date | dateFormat) }}</span>
        </ng-template>
        <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column" let-row="row">
          <kendo-datepicker
            [formControl]="formGroup.get(column.field)"
            format="dd/MM/yy"
            [formatPlaceholder]="formGroup.get(column.field)"
            [max]="maxDate"
            [min]="minDate"
          >
            <kendo-datepicker-messages today="{{ 'datePicker.today' | translate }}"> </kendo-datepicker-messages>
          </kendo-datepicker>
          <app-cell-error-container [errors]="getFormGroupErrorTranslatables(formGroup, COLUMN_KEY.taskDate)"></app-cell-error-container>
          <ng-template kendoTextBoxSuffixTemplate> </ng-template>
        </ng-template>
      </kendo-treelist-column>

      <!--   PRODUCT   -->
      <kendo-treelist-column field="product" [width]="220">
        <ng-template kendoTreeListHeaderTemplate>
          <app-kendo-sort-header
            [title]="'nTool.table.header.productColumn'"
            (filterChange)="onFilterChange($any($event), COLUMN_KEY.productName)"
            [filterables$]="getAvailableFilterablesForColumn$(COLUMN_KEY.productName)"
            [columnKey]="COLUMN_KEY.productName"
          >
          </app-kendo-sort-header>
        </ng-template>
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <span class="editable-cell">{{ hasChildren(dataItem) ? NO_DATA : dataItem.product }}</span>
        </ng-template>
        <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column" let-row="row">
          <kendo-dropdownlist
            #dropdownlist
            kendoGridFocusable
            (focus)="onFocus(dropdownlist)"
            [data]="selectableNorms$ | async"
            [formControl]="formGroup.get(column.field)"
            [valuePrimitive]="false"
            textField="name"
            (valueChange)="onEnterClick()"
            [filterable]="true"
            (filterChange)="filterNormList($event)"
            valueField="number"
          >
          </kendo-dropdownlist>
          <ng-template kendoTextBoxSuffixTemplate> </ng-template>
        </ng-template>
      </kendo-treelist-column>
      <!--   AMOUNT   -->
      <kendo-treelist-column field="amount" title="{{ 'nTool.table.header.amountColumn' | translate }}" [width]="100">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <span class="editable-cell">{{ hasChildren(dataItem) ? NO_DATA : dataItem.amountWithSuffix }}</span>
        </ng-template>
        <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column" let-row="row">
          <kendo-numerictextbox
            class="numerictextbox"
            [maxlength]="20"
            [decimals]="0"
            format="0"
            kendoTreeListFocusable
            [formControl]="formGroup.get(column.field)"
            [value]="dataItem?.amount"
          >
            <ng-template kendoTextBoxSuffixTemplate> </ng-template>
          </kendo-numerictextbox>
          <app-cell-error-container
            [errors]="getFormGroupErrorTranslatables(formGroup, COLUMN_KEY.productAmount)"
          ></app-cell-error-container>
        </ng-template>
      </kendo-treelist-column>

      <!--   AMOUNT kgn  -->
      <kendo-treelist-column field="_" title="{{ 'nTool.table.header.amountColumnKgN' | translate }}" [width]="140">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ hasChildren(dataItem) ? '' : dataItem.NStatusWithSuffix }}
        </ng-template>
      </kendo-treelist-column>

      <!-- MORE  -->
      <kendo-treelist-column field="_" title="" [width]="65">
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <ng-container matColumnDef="actions">
            <mat-menu #taskRowMenu="matMenu">
              <button class="task-menu-item" mat-menu-item (click)="editButtonClicked(dataItem)">
                <app-icon icon="edit_outline"></app-icon>
                <span>{{ 'taskCards.tooltips.editTask' | translate }}</span>
              </button>
              <button class="task-menu-item" mat-menu-item (click)="deleteButtonClicked(dataItem)">
                <app-icon icon="delete_outline"></app-icon>
                <span>{{ 'taskCards.tooltips.deleteTask' | translate }}</span>
              </button>
            </mat-menu>
            <div class="waves-effect waves-circle transparent more-btn" (click)="$event.stopPropagation()">
              <app-icon icon="more_vert" [matMenuTriggerFor]="taskRowMenu" *ngIf="!hasChildren(dataItem)"></app-icon>
            </div>
          </ng-container>
        </ng-template>
      </kendo-treelist-column>
    </kendo-treelist>
  </form>
</div>

<!--   Reusable templates   -->

<ng-template #nBalanceCustomTemplate let-nToolTableLine>
  <ng-container
    *ngTemplateOutlet="
      (shouldNBalanceShowWarning$(nToolTableLine) | async) ? nBalanceWarningIcon : nBalanceStatusColor;
      context: { $implicit: nToolTableLine }
    "
  ></ng-container>
</ng-template>

<ng-template #nBalanceCustomTemplateWithParentWarning let-nToolTableLine>
  <ng-container
    *ngTemplateOutlet="
      (shouldNBalanceShowWarning$(nToolTableLine) | async) ? nBalanceWarningIcon : nBalanceStatusColor;
      context: { $implicit: nToolTableLine }
    "
  ></ng-container>
</ng-template>

<ng-template #nBalanceWarningIcon let-nToolTableLine>
  <app-icon
    *ngIf="nToolTableLine.cropNutrientAvailability !== CROP_NUTRIENT_AVAILABILITY.None; else nBalanceOutsidePeriod"
    icon="brightness_1"
    [ngClass]="getNBalanceStyleClass(nToolTableLine.cropNutrientAvailability)"
  ></app-icon>
  <app-tooltip tooltipText="{{ 'nTool.table.nBalanceWarning' | translate }}">
    <app-icon icon="warning_outline"></app-icon>
  </app-tooltip>
</ng-template>

<ng-template #nBalanceStatusColor let-nToolTableLine>
  <app-icon
    *ngIf="nToolTableLine.cropNutrientAvailability !== CROP_NUTRIENT_AVAILABILITY.None; else nBalanceOutsidePeriod"
    icon="brightness_1"
    [ngClass]="getNBalanceStyleClass(nToolTableLine.cropNutrientAvailability)"
  ></app-icon>
</ng-template>

<ng-template #nBalanceChild let-dataItem>
  <app-tooltip
    *ngIf="dataItem.cropNutrientAvailability === CROP_NUTRIENT_AVAILABILITY.Warning; else noData"
    tooltipText="{{ 'nTool.table.nBalanceWarning' | translate }}"
  >
    <app-icon icon="warning_outline" class="child-warning-margin"></app-icon>
  </app-tooltip>
</ng-template>

<ng-template #nBalanceOutsidePeriod>
  <app-tooltip tooltipText="{{ 'nTool.table.outsideSeason' | translate }}">
    <app-icon icon="brightness_1" class="n-balance-none"></app-icon>
  </app-tooltip>
</ng-template>

<ng-template #noData>
  {{ NO_DATA }}
</ng-template>
