import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { DateTime } from 'luxon';

export enum DATEFORMAT {
  DATE = 0,
  DATE_TIME = 1,
  TIME = 2,
}
@Pipe({
  name: 'dateFormat',
})

/**
 * localization is "platform independent", ECMAscript provides specifications and each Javascript engine provides its own implementation.
 * There is also a compatibility implementation, Intl.js, which will provide the API if it doesn't already exist.
 * You can see the implementation for the locale Danish (Denmark) here:
 *  https://github.com/andyearnshaw/Intl.js/blob/master/locale-data/json/da-DK.json
 *
 * you will see the available formats, and the day always returns the period after.
 *  "availableFormats": {
                "d": "d.", <-------here is the problem
                "E": "ccc",
                "Ed": "E 'den' d.",
                "Ehm": "E h.mm a",
                "EHm": "E HH.mm",
                "Ehms": "E h.mm.ss a",
                "EHms": "E HH.mm.ss",
                "Gy": "y G",
                "GyMMM": "MMM y G",
                "GyMMMd": "d. MMM y G",
 */

/**
 * Removes period in the date if language is set to danish,
 * otherwise it uses the default "shortDate" version
 */
export class DateFormatPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  public transform(
    value: DateTime | Date | string | undefined | null,
    dateFormat?: DATEFORMAT,
    seperator?: any,
    languageShortKey: string = this.languageService.currentLanguage.shortKey
  ): string | null {
    if (!value || !languageShortKey) return null;

    const date = value instanceof Date ? DateTime.fromJSDate(value) : DateTime.isDateTime(value) ? value : DateTime.fromISO(value);

    const datePipe = new DatePipe(languageShortKey);

    // TODO: remove period if danish?
    const sep = seperator ?? (languageShortKey.toUpperCase() === 'da'.toUpperCase() ? '-' : '/');

    switch (dateFormat) {
      case DATEFORMAT.DATE:
        return datePipe.transform(date.toJSDate(), `dd${sep}MM${sep}yyyy`);
      case DATEFORMAT.DATE_TIME:
        return datePipe.transform(date.toJSDate(), `dd${sep}MM${sep}yyyy  HH:mm:ss`);
      case DATEFORMAT.TIME:
        return datePipe.transform(date.toJSDate(), `HH:mm:ss`);
      default:
        return datePipe.transform(date.toJSDate(), `dd${sep}MM${sep}yyyy`);
    }
  }
}
