<div class="blight-info-content" *ngIf="blightInfoForField$ | async as blightInfoForField">
  <h3>{{ blightInfoForField.number }} {{ blightInfoForField.name }}</h3>
  <p>
    {{ 'main.potatoBlight.fieldInfo.area' | translate }} : {{ blightInfoForField.area | number: '1.0-2' }}
    {{ 'common.hectareShort' | translate }}
  </p>
  <p>{{ 'main.potatoBlight.fieldInfo.crop' | translate }}: {{ blightInfoForField.cropName }}</p>

  <div class="productInfo" *ngIf="!!blightInfoForField.blightProductUnit; else noTreatment">
    <h4>{{ 'main.potatoBlight.fieldInfo.infoTitle' | translate }}</h4>
    <p>{{ 'main.potatoBlight.fieldInfo.productName' | translate }}: {{ blightInfoForField.blightProductName }}</p>
    <p>
      {{ 'main.potatoBlight.fieldInfo.productQuantity' | translate }} : {{ blightInfoForField.blightProductQuantity }}
      {{ blightInfoForField.blightProductUnit }}
    </p>
    <p>{{ 'main.potatoBlight.fieldInfo.daysSince' | translate }}: {{ blightInfoForField.blightTreatmentDaysSince }}</p>
  </div>
  <ng-template #noTreatment>
    {{ 'main.potatoBlight.fieldInfo.noTreatment' | translate }}
  </ng-template>

  <div class="registration">
    <app-blight-registration
      [blightDiscovered]="!!blightInfoForField.blightRegistrationDate"
      [blightDiscoveredDate]="DateTime.fromISO(blightInfoForField.blightRegistrationDate)"
      (onblightRegistration)="registerBlight(blightInfoForField.farmId, blightInfoForField.cropId, $event)"
    ></app-blight-registration>
  </div>

  <ng-container *ngIf="showPrognosis; else PrognosisNotAvailable">
    <div *ngIf="!!blightInfoForField.history" class="history">
      <h4>{{ 'main.potatoBlight.fieldInfo.historyTitle' | translate }}</h4>
      <div class="prognosis-scale">
        <div *ngFor="let prognosis of blightInfoForField.history">
          <div class="prognosis-date">{{ prognosis.date | date: ('main.potatoBlight.fieldInfo.dateFormat' | translate) }}</div>
          <div class="prognosis-color" [ngStyle]="{ 'background-color': prognosis.color }"></div>
        </div>
      </div>
    </div>

    <div class="prognosis">
      <h4>{{ 'main.potatoBlight.fieldInfo.prognosisTitle' | translate }}</h4>
      <div class="prognosis-scale">
        <div *ngFor="let prognosis of blightInfoForField.prognoses">
          <div class="prognosis-date">{{ prognosis.date | date: ('main.potatoBlight.fieldInfo.dateFormat' | translate) }}</div>
          <div class="prognosis-color" [ngStyle]="{ 'background-color': prognosis.color }"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #PrognosisNotAvailable>
    <h4>{{ 'main.potatoBlight.fieldInfo.prognosisTitle' | translate }}</h4>
    {{ 'main.potatoBlight.fieldInfo.prognosisNotAvailable' | translate }}
  </ng-template>
</div>
