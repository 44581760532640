import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BenchmarkDataService } from '@app/core/benchmark-data/benchmark-data.service';
import { DatamanagementService } from '@app/core/datamanagement/datamanagement.service';
import { DatamanagementHarvestTypes } from '@app/core/enums/datamanagement-harvest-types.enum';
import { DatamanagementProductNames } from '@app/core/enums/datamanagement-product-names.enum';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { ProduceNormUnits } from '@app/core/enums/produce-norm-units.enum';
import { TransferStatus } from '@app/core/enums/transfer-status.enum';
import { YieldTypeGroups } from '@app/core/enums/yield-type-groups.enum';
import { FieldService } from '@app/core/field/field.service';
import { ClaasLog } from '@app/core/interfaces/claas-log.interface';
import { ClaasWork } from '@app/core/interfaces/claas-work.interface';
import { Crop } from '@app/core/interfaces/crop.interface';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { ProduceNormsStorage } from '@app/core/interfaces/produce-norms-storage.interface';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { ClaasWorkLogRepo } from '@app/core/repositories/claas-work-log/claas-work-log-repo.service';
import { DataExchangeRepo } from '@app/core/repositories/data-exchange/data-exchange-repo.service';
import { ProduceNormsRepo } from '@app/core/repositories/produce-norms/produce-norms-repo.service';
import { TasksRepo } from '@app/core/repositories/tasks/tasks-repo.service';
import { convertToHkg, convertToKg, convertToTons } from '@app/helpers/convert/units.helper';
import { segment } from '@app/helpers/rxjs-operators/segment';
import { getProduceNorms } from '@app/settings/datamanagement/transfer-tasks/helpers/get-produce-norms';
import { DatamanagementStateService } from '@app/state/services/data-management/datamanagement-state.service';
import { DateTime } from 'luxon';
import { Observable, forkJoin, from, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class TransferTaskService {
  private fields: Field[] = [];
  private produceNormsStorage: ProduceNormsStorage[] = [];
  private readonly sumOfDryMatterPctAndWaterPct = 100;

  constructor(
    private claasWorkLogRepo: ClaasWorkLogRepo,
    private dataExchangeRepo: DataExchangeRepo,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private benchmarkDataService: BenchmarkDataService,
    private produceNormsRepo: ProduceNormsRepo,
    private fieldService: FieldService,
    private tasksRepo: TasksRepo,
    private datamanagementService: DatamanagementService,
    private datamanagementStateService: DatamanagementStateService
  ) {}

  public getProduceNormsForFarmsAndHarvestYear(farms: Farm[], startDate: DateTime) {
    return getProduceNorms(
      startDate.year,
      this.benchmarkDataService,
      this.produceNormsRepo,
      this.notificationService,
      this.messageService,
      farms
    ).pipe(tap((produceNorms) => (this.produceNormsStorage = produceNorms)));
  }

  public getConnectionSettings(): Observable<DataConnectionSettingDTO[]> {
    return this.datamanagementStateService.dataConnectionSettings$;
  }

  public getFieldsForFarmsAndHarvestYear(farms: Farm[], startDate: DateTime) {
    const farmIds = farms.map((farm) => farm.id);

    return of(farmIds).pipe(
      segment(25),
      switchMap((chunks) => from(chunks)),
      mergeMap((ids) => this.fieldService.getAllFields(ids, startDate.year)),
      tap((fields) => (this.fields = fields || [])),
      catchError((error: HttpErrorResponse) => {
        this.notificationService.showError(`${this.messageService.getDataConnectionCredentialMsg().loginError}`);
        return throwError(error);
      })
    );
  }

  public getClaasWorksForConnections = (
    connections: DataConnectionSettingDTO[],
    startDate: DateTime,
    endDate: DateTime
  ): Observable<ClaasWork[]> =>
    !!connections.length
      ? forkJoin(...connections.map((connection) => this.getClaasWorks(connection, startDate, endDate))).pipe(map((res) => res[0]))
      : of([]);

  public getTransferedWorks() {
    return this.claasWorkLogRepo.get().pipe(
      map((claasLog) => claasLog),
      catchError((error: HttpErrorResponse) => {
        this.notificationService.showError(`${this.messageService.getDataConnectionCredentialMsg().loginError}`);
        return throwError(error);
      })
    );
  }

  public getClaasWorks(settings: DataConnectionSettingDTO, startDate: DateTime, endDate: DateTime) {
    return this.dataExchangeRepo.getWorks(startDate, endDate, settings).pipe(
      // Add the connection id for the work
      map((works) => works.map((work) => ({ ...work, connectionId: settings.connectionId }))),
      catchError((error: HttpErrorResponse) => {
        this.notificationService.showError(`${this.messageService.getDataConnectionCredentialMsg().loginError}`);
        return throwError(error);
      })
    );
  }

  public transferAllWorks(works: ClaasWork[]) {
    return forkJoin(
      ...works
        .filter((work) => this.datamanagementService.isWorkTransferable(work) && this.isNewWork(work))
        .map((work) => this.transferWork(work))
    ).pipe(
      map((transferedWorks) =>
        transferedWorks.map((work) =>
          this.isTransfering(work)
            ? {
                ...work,
                transferStatus: TransferStatus.DONE,
              }
            : { ...work }
        )
      )
    );
  }

  public transferWork(work: ClaasWork) {
    work.transferStatus = TransferStatus.TRANSFERING;
    return this.datamanagementService.transferTask(work).pipe(
      tap((_) => this.datamanagementService.logClaasWork(work)),
      map((_) => work),
      catchError((error) => {
        work.transferStatus = TransferStatus.ERROR;
        if (error['code']) {
          // We know the error, we have added the code in task-repo.service.ts
          work.transferError = error.text.toString();
        } else {
          work.transferError = error.text ? JSON.parse(error.text)?.message : '';
        }
        return of(work);
      })
    );
  }

  /**
   * Show only works that match the current user's farms
   * @param works Lsit of claas works.
   * @param farms List of current user's farms.
   */
  public filterClaasTasksForCurrentUserFarms = (works: ClaasWork[], farms: Farm[]) =>
    works.filter((claasWork) => farms.find((farm) => farm.id.toString() === claasWork.externalFarmId));

  /**
   * Show only works that are not already imported.
   * @param claasWorks The raw claas works list
   * @param claasWorkLog The log of already imported works.
   */
  public filterClaasWorksToNotImportedWorks = (claasWorks: ClaasWork[], claasWorkLog: ClaasLog[]) =>
    claasWorks.filter((work) => !claasWorkLog.some((log) => log.internalId === work.internalId));

  /**
   * DON´T CHANGE LOGIC
   * Finding ProduceNorms is different for harvesting and cutting. This means that it can not be generalized further without changes in the backend
   * @param works
   */
  public enrichWorks(works: ClaasWork[]) {
    works.map((work) => {
      const crop = this.findCropByFieldNumber(work);
      let product: ProduceNorm | null;

      switch (work.type) {
        case DatamanagementHarvestTypes.HARVESTING:
          product = crop && this.findHarvestingProductByCrop(crop);

          this.setHarvestingValuesOnWorkItem(work, product);
          break;
        case DatamanagementHarvestTypes.CUTTING:
          product = crop && this.findCuttingProductByCrop(crop);

          this.setCuttingValuesOnWorkItem(work);
          break;
        default:
          product = crop && this.findCuttingProductByCrop(crop);
          work.quantityUnit = work.quantityUnit;
          work.quantity = work.quantity;
          break;
      }

      this.setSharedValuesOnWorkItem(work, crop, product);
    });

    return works;
  }

  public isTransfered = (work: ClaasWork) => work.transferStatus === TransferStatus.DONE;

  public isTransfering = (work: ClaasWork) => work.transferStatus === TransferStatus.TRANSFERING;

  public isNewWork = (work: ClaasWork) => work.transferStatus === TransferStatus.NEW;

  private extractFieldNumberFromFieldName(work: ClaasWork) {
    if (!work.fieldName) {
      return null;
    }

    // Extract only the field number from the field name received from the Claas API
    let resultArray = /([0-9]+)-([0-9]+)/g.exec(work.fieldName);
    // First element is the whole match. Its what we want
    let result = resultArray ? resultArray[0] : null;

    return result;
  }

  private setHarvestingValuesOnWorkItem(work: ClaasWork, product: ProduceNorm | null): void {
    if (product && product.name) {
      work.productName = product?.name;
      work.productId = product?.number;
      work.waterPctCalculated = this.sumOfDryMatterPctAndWaterPct - work.dryMatterPct;
      switch (product.name) {
        case DatamanagementProductNames.GRAIN:
        case DatamanagementProductNames.BEANS:
          work.quantity = convertToHkg(work.quantity, work.quantityUnit);
          work.quantityUnit = ProduceNormUnits.HKG;
          break;
        case DatamanagementProductNames.SEED:
          work.quantity = convertToKg(work.quantity, work.quantityUnit);
          work.quantityUnit = ProduceNormUnits.KG;
          break;
        default:
          work.quantityUnit = work.quantityUnit;
          work.quantity = work.quantity;
          break;
      }
    }
  }

  private setCuttingValuesOnWorkItem(work: ClaasWork): void {
    work.quantity = convertToTons(work.quantity, work.quantityUnit);
    work.quantityUnit = ProduceNormUnits.T;
  }

  private setSharedValuesOnWorkItem(work: ClaasWork, crop: Crop | null, product: ProduceNorm | null): void {
    if (!crop || !product) {
      return;
    }
    work.crop = crop;

    work.productId = product.number;

    work.productName = product.name;

    work.fieldArea = this.findFieldArea(work) ?? 0;

    work.cropId = crop.id;
    work.transferStatus = TransferStatus.NEW;
    work.transferError = '';

    work.fieldNumber = this.extractFieldNumberFromFieldName(work) ?? '';

    work.productUnit = product.unitText ?? '';
  }

  /**
   * Find field area for a work based on the field number.
   * @param work ClaasWork
   */
  private findFieldArea(work: ClaasWork) {
    const field = this.fields.find((f) => f.number === this.extractFieldNumberFromFieldName(work));

    return field ? field.area : null;
  }

  private findHarvestingProductByCrop(crop: Crop) {
    const produceNormStorage = this.produceNormsStorage.find((storage) => storage.farmId === crop.farmId);

    if (!produceNormStorage) {
      return null;
    }

    const produceNorm = produceNormStorage.produceNorms.find(
      (_produceNorm) =>
        _produceNorm.isMainProduct && _produceNorm.isHarvestedMainProduct && _produceNorm.cropNormNumber === crop.cropNormNumber
    );

    return produceNorm ? produceNorm : null;
  }

  private findCuttingProductByCrop(crop: Crop) {
    const produceNormStorage = this.produceNormsStorage.find((storage) => storage.farmId === crop.farmId);

    if (!produceNormStorage) {
      return null;
    }

    const produceNorm = produceNormStorage.produceNorms.find(
      (_produceNorm) =>
        _produceNorm.cropNormNumber === crop.cropNormNumber &&
        _produceNorm.operationTypeGroupId === OperationTypeGroupEnum.Harvest &&
        _produceNorm.operationTypeId === OperationTypes.Yield &&
        _produceNorm.yieldTypeGroupNormNumber === YieldTypeGroups.GreenMass
    );

    return produceNorm ? produceNorm : null;
  }

  private findCropByFieldNumber(work: ClaasWork) {
    const fieldNumber = this.extractFieldNumberFromFieldName(work);
    const field = this.fields.find((_field) => _field.number === fieldNumber && _field.farmId === Number(work.externalFarmId));

    if (!field) {
      return null;
    }

    const produceNormStorage = this.produceNormsStorage.find((storage) => storage.farmId === field.farmId);

    if (!produceNormStorage) {
      return null;
    }

    const crop = field.crops.find((_crop) => !!this.findHarvestingProductByCrop(_crop));

    return crop ? crop : null;
  }

  public setDataConnectionSettings(settings: DataConnectionSettingDTO[]) {
    this.datamanagementStateService.dataConnectionSettings = settings;
  }
}
