import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { OperationTypeGroupsService } from '@app/core/operation-type-groups/operation-type-groups.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-tasks-filter',
  templateUrl: './tasks-filter.component.html',
  styleUrls: ['./tasks-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksFilterComponent implements OnInit, OnDestroy {
  @Output() public operationTypeIdSelect = new EventEmitter<number>();

  public operationTypeGroups$ = new BehaviorSubject<OperationTypeGroup[]>([]);
  public selectedOperationTypeGroupId: number | undefined = undefined; // undefined is used for the 'all' filter
  public menu = new UntypedFormControl(-1); // since the mat-selector doesn't like 'undefined' we use -1 here for the 'all' filter

  private subscriptions = new Subscription();

  constructor(private operationTypeGroupsService: OperationTypeGroupsService) {}

  public ngOnInit() {
    this.setupSubscriptions();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public setupSubscriptions() {
    this.subscriptions.add(
      this.operationTypeGroupsService.get().subscribe((operationTypeGroups) => this.operationTypeGroups$.next(operationTypeGroups))
    );
    this.subscriptions.add(
      this.menu.valueChanges.subscribe((operationTypeGroup) => {
        this.selectedOperationTypeGroupId = operationTypeGroup === -1 ? undefined : operationTypeGroup.id;
        this.operationTypeIdSelect.emit(this.selectedOperationTypeGroupId);
      })
    );
  }

  public select(operationTypeGroup?: OperationTypeGroup) {
    this.menu.setValue(operationTypeGroup === undefined ? -1 : operationTypeGroup);
    this.operationTypeIdSelect.emit(operationTypeGroup === undefined ? -1 : operationTypeGroup.id);
  }
}
