import { CoarseFodderDataSource } from '@app/core/economy/enum/coarseFodderDataSource.enum';
import { CoarseFodderColumn, EconomyColumnDataSource } from './../../core/economy/interface/coarsefodder';

export class SourceUtil {
  public static getTabColorFromSource(source: CoarseFodderDataSource): string {
    switch (source) {
      case CoarseFodderDataSource.Manual:
        return '#0CC273';
      case CoarseFodderDataSource.FieldOnline:
        return '#2FA9FB';
      default:
        return '#ffffff';
    }
  }

  public static getYieldFromSources(dataSources: EconomyColumnDataSource[], coarsefodder: CoarseFodderColumn): number {
    return (
      dataSources
        .find((datasource) => datasource.fieldCropId === coarsefodder.fieldCropId)
        ?.coarseFodderEconomyDataSource.find(
          (datasource) => datasource.coarseFodderEconomyColumn === Source.Yield && datasource.dataSource === coarsefodder.yieldDataSource
        )?.dataSourceValue ??
      coarsefodder.yield ??
      0
    );
  }
}

export class Source {
  public static Yield = 'Yield';

  public static UnitCosts = 'UnitCosts';

  public static MachineAndLaborCosts = 'MachineCosts';
}
