import { Injectable } from '@angular/core';
import { IImageRequestService } from '@app/core/image-request/image-request.service.interface';
import { Base64Convert } from '@app/helpers/convert/base64-convert';
import { MethodTypes } from '@app/method-types.enum';
import { FeatureBranchConfig } from '@app/shared/environment-indicator/feature-branch-config';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageRequestService implements IImageRequestService {
  constructor() {}

  /**
   * Fetches an image from the provided URL and returns it as a base64 encoded data URL.
   *
   * This method utilizes the 'XMLHttpRequest' to fetch the image. The use of 'XMLHttpRequest'
   * is necessary since Angular's 'Http' does not support the 'overrideMimeType' function,
   * which is essential to ensure the binary image data isn't treated as text.
   *
   * Once the image is fetched, it's converted to a base64 encoded string and then transformed
   * into a data URL format for image/png. This is useful for applications that need to inline
   * images as data URLs, especially in scenarios like WebGL.
   *
   * If there's a saved feature branch in the session storage under the key 'FeatureBranchConfig.StorageKey',
   * an additional header 'x-featurebranch' will be appended to the request with the saved feature branch value.
   *
   * For an improved approach, that works with WebGL see {@link getImageAsDataUrlWebGl} for details.
   *
   * @param {string} url - The URL from which the image is to be fetched.
   * @param {MethodTypes} [method=MethodTypes.GET] - The HTTP method for the request (default is GET).
   * @param {any} [body] - Optional request body, especially useful for POST requests.
   * @returns {Observable<string>} An Observable that emits the base64 encoded data URL of the fetched image.
   *
   * @example
   * const imageUrl = 'https://example.com/myimage.png';
   * this.getImageAsDataUrl(imageUrl).subscribe(dataUrl => {
   *   console.log('Data URL:', dataUrl);
   * });
   */
  public getImageAsDataUrl(url: string, method: MethodTypes = MethodTypes.GET, body?: any): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      // Must use 'XMLHttpRequest', because Angular's 'Http' lacks function 'overrideMimeType'
      const xhr = new XMLHttpRequest();
      xhr.open(method, url);

      const savedSelectedBranch: string | null = sessionStorage.getItem(FeatureBranchConfig.StorageKey);

      if (savedSelectedBranch) xhr.setRequestHeader('x-featurebranch', savedSelectedBranch);
      xhr.setRequestHeader('Content-Type', `application/json`);

      xhr.onload = (ev) => {
        // The loaded tile is converted to base64 encoded data-url
        const responseBase64 = new Base64Convert().toBase64String(xhr.responseText);
        const dataUrl = 'data:image/png;base64,' + responseBase64;

        observer.next(dataUrl);
      };

      // overrideMimeType() stops the server response from being truncated
      // which would otherwise happen when trying to treat binary data as text
      xhr.overrideMimeType('text/plain; charset=x-user-defined');
      body ? xhr.send(JSON.stringify(body)) : xhr.send();
    });
  }

  /**
   * Fetches an image from a given URL and returns it as a base64 encoded data URL.
   *
   * This method leverages XMLHttpRequest to fetch the image as a Blob (binary data),
   * which is then converted to a data URL using FileReader. This approach is taken
   * to ensure that binary image data isn't corrupted during the fetch and conversion process.
   *
   * The previous approach ({@link getImageAsDataUrl}) tried to treat the binary image data as a string using XHR
   * with a specific MIME type. That approach had the risk of corrupting the binary image
   * data during the fetch and conversion process, which led to issues when using the
   * resulting data URL with WebGL's texImage2D function.
   *
   * @param {string} url - The URL of the image to fetch.
   * @param {MethodTypes} [method=MethodTypes.GET] - The HTTP method to use (default is GET).
   * @param {any} [body] - Optional request body for POST requests.
   * @returns {Observable<string>} - An observable emitting the base64 encoded data URL of the fetched image.
   *
   * @example
   * const imageUrl = 'https://example.com/image.png';
   * this.getImageAsDataUrlWebGl(imageUrl).subscribe(dataUrl => {
   *   console.log('Base64 Data URL:', dataUrl);
   * });
   */
  public getImageAsDataUrlWebGl(url: string, method: MethodTypes = MethodTypes.GET, body?: any): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      const xhr = new XMLHttpRequest();

      xhr.open(method, url);
      xhr.responseType = 'blob'; // Set response type as blob
      const savedSelectedBranch: string | null = sessionStorage.getItem(FeatureBranchConfig.StorageKey);
      if (savedSelectedBranch) xhr.setRequestHeader('x-featurebranch', savedSelectedBranch);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onload = (ev) => {
        const blob: Blob = xhr.response; // Response is a blob due to responseType setting

        const reader = new FileReader();
        reader.onloadend = function () {
          observer.next(reader.result as string);
          observer.complete();
        };
        reader.readAsDataURL(blob); // Convert the blob to a data URL
      };

      body ? xhr.send(JSON.stringify(body)) : xhr.send();
    });
  }
}
