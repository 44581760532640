interface Log {
  target: string;
}

export class Environment {
  public appName: string;
  public env: string;
  public production: boolean;
  public isLocalhost: boolean;
  public log: Log;
  public maxUploadFileSizeMB: number;
  public endpoints: Record<string, string>;
  public featureToggleClientKey = 'uQXD4ggpXuBlFpFL9ffWHHRv2hC7JlFqhe'; // TODO: Maybe read this from secret on build
  public hmr: boolean;

  constructor(
    endpoints: Record<string, string>,
    env: string,
    production: boolean,
    isLocalhost: boolean,
    log: Log,
    hmr = false,
    maxUploadFileSizeMB = 10,
    appName = 'CropManagerFE'
  ) {
    this.endpoints = endpoints;
    this.env = env;
    this.production = production;
    this.isLocalhost = isLocalhost;
    this.log = log;
    this.hmr = hmr;
    this.maxUploadFileSizeMB = maxUploadFileSizeMB;
    this.appName = appName;
  }
}
