import { Subscription } from 'rxjs';

export function removePropFromObject<T extends object>(o: T, propName: keyof T) {
  return Object.keys(o)
    .filter((key) => key !== propName)
    .map((key) => key as keyof T)
    .reduce<T>((obj, key) => {
      obj[key] = o[key];
      return obj;
    }, {} as T);
}

export function isNullOrUndefined<T>(o: T | null | undefined): o is null | undefined {
  return o === null || o === undefined;
}

export function isNumber(o: unknown): o is number {
  return typeof o === 'number';
}

export function isArray<T>(o: unknown): o is Array<T> {
  return Array.isArray(o);
}

/**
 * Insert or update element into array
 * @param arr Array to insert or update element in
 * @param element Element to insert or update
 * @param key Key to compare element with<
 * @returns The modified array
 */
export function upsert<T>(arr: Array<T>, element: T, key?: keyof T) {
  const i = arr.findIndex((_element) => (key ? _element[key] === element[key] : _element === element));

  if (i === -1) arr.push(element);
  else arr[i] = element;

  return arr;
}

/**
 * Checks a given element to determine if it is an empty array
 * @param element Element to check
 * @returns True if the element is an array with zero items. False otherwise
 */
export function isEmptyArray<T>(element: T) {
  return Array.isArray(element) && element.length === 0;
}

/**
 * An array of subscriptions that can be unsubscribed all at once.
 * @example const subscriptions = new SubscriptionArray(sub1, sub2, sub3);
 * @example subscriptions.unsubscribe();
 * @example subscriptions.add(sub4, sub5);
 * @example subscriptions.unsubscribe();
 */
export class SubscriptionArray<T extends Subscription> extends Array<T> {
  /**
   * Adds the provided subscriptions to the array
   * @param sub Subscriptions to add to the array
   * @returns The new length of the array after adding the subscriptions
   */
  add(...sub: T[]) {
    return super.push(...sub);
  }

  /**
   * Unsubscribes all subscriptions in the array
   */
  unsubscribe() {
    super.forEach((sub) => {
      sub.unsubscribe?.();
    });
  }
}

/**
 * Check if a given item is an array of arrays
 * @param value Value to check. Can be any value.
 * @returns Whether the value is an array of arrays
 */
export function isArrayOfArrays(value: unknown): value is Array<Array<unknown>> {
  if (!isArray(value)) return false;

  return value.every((x) => Array.isArray(x));
}

/**
 * Checks if a one and only one of the provided arguments is truthy
 * @param args List of arguments to check for truthiness
 * @returns True if one and only one of the arguments is truthy. False otherwise
 */
export function isExclusivelyTruthy(...args: unknown[]) {
  return args.filter(Boolean).length === 1;
}

/**
 * Checks if a one and only one of the provided arguments is falsy
 * @param args List of arguments to check for falsiness
 * @returns True if one and only one of the arguments is falsy. False otherwise
 */
export function isExclusivelyFalsy(...args: unknown[]) {
  return args.length - args.filter(Boolean).length === 1;
}

/**
 * Return a random ID.
 *
 * Based on crypto.randomUUID()
 */
export function generateRandomId(): ReturnType<(typeof crypto)['randomUUID']>;

/**
 * Return a random ID.
 *
 * Based on crypto.randomUUID()
 */
export function generateRandomId(type: 'uuid', bitSize?: number): ReturnType<(typeof crypto)['randomUUID']>;

/**
 * Return a random ID.
 * @param type Type of ID. Can be 'uuid' or 'number'
 * @param bitSize Size of the number in bits. Only used when type is 'number'
 */
export function generateRandomId(type: 'number', bitSize?: number): number;
export function generateRandomId(type: 'uuid' | 'number' = 'uuid', bitSize = 32) {
  if (type === 'uuid') return crypto.randomUUID();
  if (type === 'number') return Math.floor(Math.random() * 2 ** bitSize);
  return crypto.randomUUID();
}

/**
 * Find the closest element to a given value in the number array
 *
 * When providing a midpoint, it returns the lower value
 * @param arr The array to search
 * @param goal The goal to find the closest value to
 * @returns The closest value to the goal
 * @example [1, 2, 3, 4, 5].closestTo(3.1) // 3
 */
export function closestTo(arr: number[], goal: number | undefined) {
  if (arr.isEmpty()) return undefined;
  if (goal === undefined) return undefined;

  return arr.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));
}
