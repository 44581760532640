import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ExtraModuleDialogComponent } from './extra-module-dialog.component';
import { NewInCropmanagerDialogComponent } from './new-in-cropmanager-dialog/new-in-cropmanager-dialog.component';
import { RememberSeptoriaDialogComponent } from './remember-septoria-dialog/remember-septoria-dialog.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [NewInCropmanagerDialogComponent, ExtraModuleDialogComponent, RememberSeptoriaDialogComponent],
  providers: [],
  exports: [ExtraModuleDialogComponent],
})
export class ExtraModuleDialogModule {}
