<app-side-drawer-header
  [title]="'main.basis-layer.category-picker.title' | translate"
  (action)="onCloseClick()"
  (onHide)="onHideClick()"
  cancelIconName="minimize"
>
</app-side-drawer-header>
<app-side-drawer-body>
  <app-spinner *ngIf="loading$ | async" [hasBackdrop]="true"></app-spinner>
  <mat-card appearance="outlined" class="category-picker-card">
    <mat-card-content>
      <div class="basis-layer-category-header">
        <p>{{ 'main.basis-layer.category-picker.info' | translate }}</p>
        <div class="info">
          <app-icon icon="info_outline" class="info-hover" (click)="onBasisLayerInfoClick($event)"></app-icon>
        </div>
      </div>
      <div class="basis-layer-category-picker-wrapper">
        <div class="category-types">
          <div class="category-type" *ngFor="let type of categoryTypes | async" (click)="onCategoryTypeClick(type)">
            <app-icon class="category-type-icon" icon="{{ type.icon }}"></app-icon>
            <span class="category-type-name">{{ type.name }}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-side-drawer-body>
