import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GrassPrognosisResultData } from '@app/core/repositories/grass-prognosis/grass-prognosis.interface';
import { DateTime } from 'luxon';
import { GrassPrognosisOptions } from '../grass-prognosis-settings/grass-prognosis-settings.interface';

@Component({
  selector: 'app-grass-prognosis-card',
  templateUrl: './grass-prognosis-card.component.html',
  styleUrls: ['./grass-prognosis-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrassPrognosisCardComponent {
  @Input() public cardData?: GrassPrognosisResultData;
  @Input() public options?: GrassPrognosisOptions;
  @Input() public title = '';
  @Input() public valueDigitsInfo = '1.0-0';

  public readonly today = DateTime.now();

  get difValueToFixed() {
    return Math.round(this.cardData!.difValue);
  }
}
