import { Injectable } from '@angular/core';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { UserType } from '@app/core/repositories/user/user-type.enum';
import { SubscriptionsValidatorService } from '@app/core/subcriptions-validator/subscriptions-validator.service';
import { UserStateService } from '@app/state/services/user/user-state.service';
import { firstValueFrom } from 'rxjs';

export declare let gtag: Function;

export type CategoryTypes =
  | 'Field Plan'
  | 'Field Analysis'
  | 'Prognosis'
  | 'Prescription Maps (New)'
  | 'Hotspots'
  | 'Custom Maps'
  | 'Yield Benchmark'
  | 'Production Cost'
  | 'Settings'
  | 'Prescription Maps (Legacy)'
  | 'Administration'
  | 'Navigation'
  | 'Other';

@Injectable({
  providedIn: 'root',
})
export class GTagEventTrackerService {
  constructor(
    private userStateService: UserStateService,
    private subService: SubscriptionsValidatorService
  ) {}

  async trackEvent(eventName?: string, eventCategory?: CategoryTypes) {
    // Fetch user data and subscription info
    const userType = await firstValueFrom(this.userStateService.userType$);
    const subscriptionLevel = await firstValueFrom(this.userStateService.subscriptionLevel$);
    const alertsPack = await firstValueFrom(this.subService.isAlertsPackSubscriber$);
    const vraBundle = await firstValueFrom(this.subService.isVraSubscriber$);

    //* If event name is 'title', then change it to 'settings'. For some reason it is the only navigation element that has a different event name scheme
    if (eventName === 'title (click)') {
      eventName = 'settings (click)';
    }

    // Prepare event data
    const eventData = {
      subscription_level: subscriptionLevel !== null && SubscriptionLevel[subscriptionLevel],
      user_type: userType !== null && UserType[userType],
      alerts_pack: alertsPack,
      vra_bundle: vraBundle,
      category: eventCategory,
      send_to: 'G-26KDT2ZFG8',
    };

    // Send event data to Google Analytics
    gtag('event', eventName ?? 'unknown', eventData);
  }
}
