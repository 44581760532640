import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-insurance-button',
  templateUrl: './insurance-button.component.html',
  styleUrls: ['./insurance-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceButtonComponent {
  @Output() public insuranceClick = new EventEmitter<void>();
  public isCurrentHarvestYear$ = this.harvestYearStateService.isCurrentHarvestYear$;
  public selectedHarvestYear$ = this.harvestYearStateService.harvestYear$;
  public isFeatureActive$ = this.featureToggleService.get$('plant_cropmanager_insurance');

  constructor(
    private harvestYearStateService: HarvestYearStateService,
    private featureToggleService: FeatureToggleService
  ) {}

  public onInsuranceClick() {
    this.isCurrentHarvestYear$
      .pipe(
        take(1),
        filter((isCurrent) => isCurrent)
      )
      .subscribe(() => {
        this.insuranceClick.emit();
      });
  }
}
