import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { HttpClient } from '@app/core/http/http-client';
import { PatchedOperationLineResult } from '@app/core/repositories/tasks/tasks-repo.service';
import { WATER_PRODUCE_NORM_NUMBER } from '@app/shared/constants/norm-numbers';
import { latest } from '@app/shared/constants/rxjs-constants';
import { filterNullOrEmpty } from '@app/shared/operators';
import { createStore } from '@ngneat/elf';
import {
  getEntitiesIds,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  selectEntities,
  selectEntity,
  selectManyByPredicate,
  setActiveId,
  setEntities,
  UIEntitiesRef,
  unionEntities,
  updateEntities,
  updateEntitiesByPredicate,
  upsertEntities,
  withActiveId,
  withEntities,
  withUIEntities,
} from '@ngneat/elf-entities';
import { deleteRequestResult, joinRequestResult, trackRequestResult } from '@ngneat/elf-requests';
import _ from 'lodash';
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, first, map, shareReplay, switchMap, tap } from 'rxjs';
import { HttpResponse } from 'selenium-webdriver/devtools/networkinterceptor';
import { TASK_TABLE_COLUMNS_BY_OPERATION_TYPE_GROUP, VraTaskTableColumns } from '../domain/task-table';
import { PrescriptionMap } from '../interfaces/prescription-map.interface';
import { VraOperationLine } from '../interfaces/vra-operation-line.interface';
import { VraOperationTypeGroup, VraOperationTypeGroupDto } from '../interfaces/vra-operation-type-group.interface';
import { VraPutOperationLineModelDto } from '../interfaces/vra-put-operation-line-mode.interface';
import { VraTaskField } from '../interfaces/vra-task-field.interface';
import { VraTask } from '../interfaces/vra-task.interface';
import { mapVraOperationTypeGroups } from '../mapping/vra-task.mapping';
import { ApiParamsService } from './_api-params.service';
import { ActiveFieldService } from './active-field.state';
import { OperationLinePatch } from './prescription-map/prescription-map-dtos';

interface VraOperationTypeGroupAccordion {
  // we would like to type this in reference to VraOperationTypeGroup
  // but as of now unionEntities() breaks with idKey as enum
  type: number; // VraOperationTypeGroup['type'];
  expanded: boolean;
}

interface VraOperationTypeGroupTable {
  type: VraOperationTypeGroupAccordion['type'];
  columns: VraTaskTableColumns;
}

@Injectable({
  providedIn: 'root',
})
export class VraRepository {
  constructor(
    private http: HttpClient,
    private apiParams: ApiParamsService,
    private endpoints: EndpointsService,
    private activeFieldService: ActiveFieldService
  ) {}

  private readonly _groupStore = createStore(
    { name: 'vra-operation-type-groups' },
    withEntities<VraOperationTypeGroup, 'type'>({ idKey: 'type' }),
    withUIEntities<VraOperationTypeGroupAccordion, 'type'>({ idKey: 'type' }),
    withUIEntities<VraOperationTypeGroupTable, 'type'>({ idKey: 'type' })
  );

  private readonly _taskStore = createStore({ name: 'vra-tasks' }, withEntities<VraTask>(), withActiveId());

  private readonly _apiUrl = this.endpoints.bffApi;

  private readonly _groupStoreData = this.apiParams.farmAndHarvestYear$.pipe(
    switchMap(([farmIds, harvestYear]) =>
      this._groupStore
        .combine({
          entities: this._groupStore.pipe(selectAllEntities()),
          UIEntities: this._groupStore.pipe(selectEntities({ ref: UIEntitiesRef })),
        })
        .pipe(unionEntities('type'), joinRequestResult(['vra-operation-type-groups', farmIds, harvestYear]))
    ),
    shareReplay(latest)
  );

  private readonly _taskStoreData = this._taskStore.pipe(selectAllEntities(), shareReplay(latest));

  public readonly loading$ = this._groupStoreData.pipe(map(({ isLoading }) => isLoading));

  public readonly operationTypeGroups$ = this._groupStoreData.pipe(map(({ data }) => data));

  public readonly tasks$ = this._taskStoreData;

  private readonly _refetch$ = new BehaviorSubject<void>(undefined);

  public readonly activeTask$ = this._taskStore.pipe(selectActiveEntity(), shareReplay(latest), distinctUntilChanged());

  public readonly activeFieldTasks$ = this.activeFieldService.activeField$.pipe(
    switchMap((field) => this._taskStore.pipe(selectManyByPredicate((task) => task.fields.some((f) => f.fieldId === field?.id)))),
    shareReplay(latest)
  );

  public readonly taskById = (id: VraTask['id']) => this._taskStore.pipe(selectEntity(id), shareReplay(latest));

  // TODO: Figure out better solution
  public readonly refetchException$ = new BehaviorSubject<boolean>(false); // set to true if you should always refetch tasks again

  private readonly _init$ = this._refetch$.pipe(
    switchMap(() => this.apiParams.farmAndHarvestYear$),
    switchMap(([farmIds, harvestYear]) =>
      this.http.get<VraOperationTypeGroupDto[]>(`${this._apiUrl}/farms/${farmIds}/${harvestYear}/vratasks`).pipe(
        trackRequestResult(['vra-operation-type-groups', farmIds, harvestYear], { cacheResponseData: true }),
        map((groups) => mapVraOperationTypeGroups(groups)),
        tap(([vraOperationTypeGroups]) => {
          // only add new groups to UI store
          // this preserves the expanded state of existing accordions
          const newUiEntities = vraOperationTypeGroups
            .filter((group) => !this._groupStore.query(getEntitiesIds()).some((type) => type === group.type))
            .map<VraOperationTypeGroupAccordion & VraOperationTypeGroupTable>((operationGroup) => {
              const columns = TASK_TABLE_COLUMNS_BY_OPERATION_TYPE_GROUP.get(operationGroup.type);

              return {
                type: operationGroup.type,
                expanded: false,
                columns,
              };
            });

          this._groupStore.update(setEntities(vraOperationTypeGroups), upsertEntities(newUiEntities, { ref: UIEntitiesRef }));
        }),
        tap(([_, vraTasks]) => this._taskStore.update(setEntities(vraTasks)))
      )
    ),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public refetch() {
    this.apiParams.farmAndHarvestYear$.pipe(first()).subscribe(([farmIds, harvestYear]) => {
      deleteRequestResult(['vra-operation-type-groups', farmIds, harvestYear]);
      this._groupStore.reset();
      this._taskStore.reset();
      this._refetch$.next();
    });
  }

  public addWaterToTasks(taskIds: number[], amount: number) {
    return this.apiParams.farmAndHarvestYear$.pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.http.patch<PatchedOperationLineResult, number[]>(
          `${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/fields/crops/tasks/addwater?waterQuantity=${amount}`,
          taskIds
        )
      )
    );
  }

  public addOperationLineToTask(task: VraTask, operationLines: PatchedOperationLineResult | null) {
    if (!operationLines) return;

    // Deep copy task to avoid mutating the original task
    let taskCopy = _.cloneDeep(task);

    taskCopy.fields = taskCopy.fields.map((field) => {
      const addedLines = operationLines.addedOperationLines;
      const deletedLines = operationLines.deletedOperationLines;
      const updatedLines = operationLines.updateOperationLines;

      // Filter operation lines for the current field
      const linesToAdd = addedLines.filter((line) => field.operationLines.some((l) => l.taskId === line.taskId));
      const linesToDelete = deletedLines.filter((line) => field.operationLines.some((l) => l.taskId === line.taskId));
      const linesToUpdate = updatedLines.filter((line) => field.operationLines.some((l) => l.taskId === line.taskId));

      // Delete operation lines from the field
      field.operationLines = field.operationLines.filter((line) => !linesToDelete.some((l) => l.taskId === line.taskId));

      // Update operation lines in the field
      field.operationLines = field.operationLines.map((line) => {
        const lineToUpdate = linesToUpdate.find((l) => l.id === line.id);
        if (lineToUpdate) line = lineToUpdate;

        return line;
      });

      // Add new operation lines to the field
      field.operationLines.push(...linesToAdd);

      this.refetchException$.next(true);

      return field;
    });

    // Remove error from task
    taskCopy.errorCode = 0;

    // Update the task in the store with the modified deep copy
    this._taskStore.update(updateEntities(taskCopy.id, taskCopy));
  }

  public updateOperationLineQuantities(task: VraTask, operationLinePatches: Omit<OperationLinePatch, 'vraPrescriptionMapId'>[] | null) {
    if (!operationLinePatches) return;

    // Deep copy task to avoid mutating the original task
    let taskCopy = _.cloneDeep(task);

    taskCopy.fields = taskCopy.fields.map((field) => {
      field.operationLines = field.operationLines.map((line) => {
        const patch = operationLinePatches.find((p) => p.operationLineId === line.id);
        if (patch) line.quantity = patch.quantity;

        return line;
      });

      return field;
    });

    // Update the task in the store with the modified deep copy
    this._taskStore.update(updateEntities(taskCopy.id, taskCopy));
  }

  public updateTask(task: VraTask) {
    this._taskStore.update(updateEntities(task.id, task));
  }

  public openGroup(group: VraOperationTypeGroup) {
    this._groupStore.update(
      updateEntitiesByPredicate((entity) => entity.type === group.type && !entity.expanded, { expanded: true }, { ref: UIEntitiesRef })
    );
  }

  public closeGroup(group: VraOperationTypeGroup) {
    this._groupStore.update(
      updateEntitiesByPredicate((entity) => entity.type === group.type && entity.expanded, { expanded: false }, { ref: UIEntitiesRef })
    );
  }

  public toggleGroup(group: VraOperationTypeGroup) {
    this._groupStore.update(updateEntities(group.type, (entity) => ({ ...entity, expanded: !entity.expanded }), { ref: UIEntitiesRef }));
  }

  public setActiveTask(id: VraTask['id'] | null) {
    if (!id) this._taskStore.update(resetActiveId());

    this._taskStore.update(setActiveId(id));
  }

  public removeTaskFields(fieldNumbers: string[]) {
    this.activeTask$.pipe(first()).subscribe((task) => {
      if (!task) return;
      const taskCopy = _.cloneDeep(task);

      taskCopy.fields = taskCopy.fields.filter((field) => !fieldNumbers.includes(field.fieldNumber));

      this._taskStore.update(updateEntities(taskCopy.id, taskCopy));
    });
  }

  public updateVraTask(maps: PrescriptionMap[]) {
    const options = {
      withCredentials: true,
    };

    return combineLatest([this.apiParams.farms$, this.activeTask$.pipe(filterNullOrEmpty())]).pipe(
      first(),
      switchMap(([farmIds, activeTask]) => {
        const putOperationLineModels: VraPutOperationLineModelDto[] = activeTask.fields.flatMap((field) =>
          field.operationLines.map((line) => {
            // Find the map for the current line
            const map = maps.find((map) => map.taskId === line.taskId);
            // Calculate the total product quantity (which is the carrier material for plant-protection, water) pr. ha
            const totalProductQuantityPrHa = map ? map.totalProductQuantity / map.area : 0;

            const quantity =
              activeTask.group === OperationTypeGroupEnum.PlantProtection
                ? // if the active task is plant protection, calculate the quantity based on new relative water quantity and product share of the line.
                  this._calculateNewProductQuantityForPlantProtection(field, line, map)
                : // otherwise, use the total product quantity pr. ha
                  totalProductQuantityPrHa;

            const { taskId, id, produceNormNumber, unit } = line;

            const operationLineModel = {
              FarmId: field.farmId,
              TaskId: taskId,
              OperationLineId: id,
              ProduceNormNumber: produceNormNumber,
              Quantity: quantity,
              Unit: unit,
            };

            return operationLineModel;
          })
        );

        return this.http.put<HttpResponse, VraPutOperationLineModelDto[]>(
          `${this.endpoints.bffApi}/farms/${farmIds}/vratask`,
          putOperationLineModels,
          options
        );
      }),
      catchError(() => 'Error')
    );
  }

  private _calculateNewProductQuantityForPlantProtection(field: VraTaskField, line: VraOperationLine, map: PrescriptionMap | undefined) {
    if (!map) return 0;

    // Find the operation line for water
    const waterLine = field.operationLines.find((line) => line.produceNormNumber === WATER_PRODUCE_NORM_NUMBER);
    // Find the new relative water quantity after map has been calculated
    const relativeQuantity = waterLine?.totalQuantity ? (map?.totalProductQuantity ?? 0) / waterLine?.totalQuantity : 1;
    // Calculate the product share of the line compared to the total quantity
    const productShare = line.totalQuantity / (map?.totalProductQuantity ?? 0);

    // If water, the quantity is the total product quantity (carrier material, water), otherwise maintain product to carrier ratio and product share
    const quantity =
      line.produceNormNumber === WATER_PRODUCE_NORM_NUMBER
        ? map.totalProductQuantity / map.area
        : (map.totalProductQuantity / map.area) * relativeQuantity * productShare;

    return quantity;
  }
}
