<div class="soil-sample-measurement" [class.disabled]="!measurements">
  <span>{{ text }}</span>
  <span *ngIf="!measurements" class="measurements">({{ 'main.soilSamples.noMeasurements' | translate }})</span>
  <span *ngIf="measurements" class="measurements"
    >({{ measurements }}
    <span *ngIf="measurements > 1">{{ 'main.soilSamples.measurements' | translate }}</span>
    <span *ngIf="measurements === 1">{{ 'main.soilSamples.measurement' | translate }}</span
    >)
  </span>
</div>
