<div class="farm-picker-fab">
  <app-fab
    [disabled]="(disabled$ | async)!"
    [icon]="(numberOfSelectedFarms$ | async)! > 1 ? 'home_more_outline' : 'home_outline'"
    id="e2e-farm-picker-modal"
    (click)="openFarmPickerModal()"
    [appShortcutKey]="shortcuts.chooseFarm"
    (shortcutPressed)="openFarmPickerModal()"
    appGTagEventTracker
    eventName="openFarmPicker (click)"
    eventCategory="Administration"
  >
  </app-fab>
</div>
