import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
export class TaskCropVM {
  public crop: Crop;
  public number: string;
  public name: string;
  public type: 'similar-crop' | 'similar-task' | 'none';

  constructor(field: Field | null, crop: Crop, type: 'similar-crop' | 'similar-task' | 'none') {
    this.crop = crop;
    this.name = field!.name;
    this.number = field!.number;
    this.type = type;
  }
}
