import { AbstractControl } from '@angular/forms';
import { isNullOrUndefined } from '@app/shared/utils/utils';

export function zeroOrBetween(min: number, max: number) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (isNullOrUndefined(control.value)) {
      return null;
    }
    const value = +control.value;
    return value === 0 || (value >= min && value <= max) ? null : { zeroOrBetween: true };
  };
}
