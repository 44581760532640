<div class="table-wrapper">
  <app-filter-chip-container [scope]="SCOPE"> </app-filter-chip-container>
  <kendo-treelist
    *ngIf="displayData$ | async as displayData; else spinner"
    [kendoTreeListFlatBinding]="displayData"
    #treeList
    kendoTreeListExpandable
    idField="id"
    [fetchChildren]="fetchChilren"
    [hasChildren]="hasChildren"
    [rowClass]="rowCallback"
    [expandedKeys]="(expandedIds$ | async)!"
    (expandedKeysChange)="onExpandedIdsChange()"
    [selectable]="settings"
    (selectionChange)="onSelectionChange($event)"
    [isSelected]="isSelected"
    (cellClick)="cellClick($event)"
    expandBy="id"
  >
    <!--   FIELD NUMBER   -->
    <kendo-treelist-column [expandable]="true" field="number" [width]="COLUMN_WIDTH">
      <ng-template kendoTreeListHeaderTemplate>
        <app-kendo-sort-header
          [title]="'main.potatoBlight.table.header.field-number'"
          [filterHideThreshold]="1"
          [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.fieldNumber))"
          (sortChange)="sortChange($event)"
          (filterChange)="onFilterChange($event, COLUMN_KEY.fieldNumber)"
          [filterables$]="getAvailableFilterablesForColumn$(COLUMN_KEY.fieldNumber)"
          [columnKey]="COLUMN_KEY.fieldNumber"
        >
        </app-kendo-sort-header>
      </ng-template>
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? dataItem.number : NO_DATA }}
      </ng-template>
    </kendo-treelist-column>
    <!--   CROP  -->
    <kendo-treelist-column [expandable]="true" field="cropName" [width]="COLUMN_WIDTH">
      <ng-template kendoTreeListHeaderTemplate>
        <app-kendo-sort-header
          [title]="'main.potatoBlight.table.header.crop-name'"
          [filterHideThreshold]="1"
          [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.cropName))"
          (sortChange)="sortChange($event)"
          (filterChange)="onFilterChange($event, COLUMN_KEY.cropName)"
          [filterables$]="getAvailableFilterablesForColumn$(COLUMN_KEY.cropName)"
          [columnKey]="COLUMN_KEY.cropName"
        >
        </app-kendo-sort-header>
      </ng-template>
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? dataItem.name : NO_DATA }}
      </ng-template>
    </kendo-treelist-column>

    <!--   LATEST TREATMENT  -->
    <kendo-treelist-column [expandable]="true" field="_" [width]="COLUMN_WIDTH">
      <ng-template kendoTreeListHeaderTemplate>
        <app-kendo-sort-header
          [title]="'main.potatoBlight.table.header.latest-treatment'"
          [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.latestTreatment))"
          (sortChange)="sortChange($event)"
        ></app-kendo-sort-header>
      </ng-template>
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? (dataItem.latestTreatmentDate | dateFormat) : NO_DATA }}
      </ng-template>
    </kendo-treelist-column>
    <!--   RISK  -->
    <kendo-treelist-column [expandable]="true" field="_" [width]="COLUMN_WIDTH">
      <ng-template kendoTreeListHeaderTemplate>
        <app-kendo-sort-header
          [title]="'main.potatoBlight.table.header.risk'"
          [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.risk))"
          (sortChange)="sortChange($event)"
        ></app-kendo-sort-header>
      </ng-template>
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <app-icon *ngIf="hasChildren(dataItem) && dataItem.risc" icon="warning_outline"></app-icon>
      </ng-template>
    </kendo-treelist-column>
    <!--   REGISTERED BLIGHT  -->
    <kendo-treelist-column
      [expandable]="true"
      field="_"
      title="{{ 'main.potatoBlight.table.header.registered-blight' | translate }}"
      [width]="COLUMN_WIDTH"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? (dataItem.blightRegisteredDate | dateFormat) : NO_DATA }}
      </ng-template>
    </kendo-treelist-column>
    <!--   DATE  -->
    <kendo-treelist-column
      [expandable]="true"
      field="_"
      title="{{ 'main.potatoBlight.table.header.date' | translate }}"
      [width]="COLUMN_WIDTH"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? NO_DATA : (dataItem.date | dateFormat) }}
      </ng-template>
    </kendo-treelist-column>
    <!--   STATUS  -->
    <kendo-treelist-column [expandable]="true" field="_" [width]="75">
      <ng-template kendoTreeListHeaderTemplate>
        <div class="status-info-header">
          {{ 'main.potatoBlight.table.header.status' | translate }}
        </div>
      </ng-template>

      <ng-template kendoTreeListCellTemplate let-dataItem>
        <div class="status-container" *ngIf="!hasChildren(dataItem)">
          <div>
            <span class="editable-cell">{{ dataItem.registered | blightTaskStatus }}</span>
          </div>
          <app-icon *ngIf="dataItem.registered" icon="done_outlined" class="status-done"></app-icon>
          <app-icon *ngIf="!dataItem.registered" icon="pending_outlined" class="status-planned"></app-icon>
        </div>
      </ng-template>
    </kendo-treelist-column>

    <!--   PRODUCT  -->
    <kendo-treelist-column [expandable]="true" field="_" [width]="COLUMN_WIDTH">
      <ng-template kendoTreeListHeaderTemplate>
        <app-kendo-sort-header
          [title]="'main.potatoBlight.table.header.product'"
          [filterHideThreshold]="1"
          [columnSorter]="$any(getColumnSorterByKey(COLUMN_KEY.productName))"
          (sortChange)="sortChange($event)"
          (filterChange)="onFilterChange($event, COLUMN_KEY.productName)"
          [filterables$]="getAvailableFilterablesForColumn$(COLUMN_KEY.productName)"
          [columnKey]="COLUMN_KEY.productName"
        >
        </app-kendo-sort-header>
      </ng-template>
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? NO_DATA : dataItem.productName }}
      </ng-template>
    </kendo-treelist-column>
    <!--   AMOUNT  -->
    <kendo-treelist-column [expandable]="true" field="_" [width]="COLUMN_WIDTH">
      <ng-template kendoTreeListHeaderTemplate>
        <div class="status-info-header">
          {{ 'main.potatoBlight.table.header.amount' | translate }}
          <div class="info">
            <app-icon icon="info_outline" class="info-hover" (click)="onBlightTableInfoClick($event)"></app-icon>
          </div>
        </div>
      </ng-template>
      <ng-template kendoTreeListCellTemplate let-dataItem>
        {{ hasChildren(dataItem) ? NO_DATA : dataItem.amountWithSuffix }}
      </ng-template>
    </kendo-treelist-column>
  </kendo-treelist>
</div>

<ng-template #spinner>
  <app-spinner></app-spinner>
</ng-template>
