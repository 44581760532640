import { Injectable } from '@angular/core';
import { BenchmarkTypeId } from '@app/core/enums/benchmark-types.enum';
import { BenchmarkType } from '@app/core/interfaces/benchmark-type.interface';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Region } from '@app/core/interfaces/map-region-feature.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BenchmarkStateService {
  private _regionsSubject = new BehaviorSubject<Region[]>([]);
  private _validCropsSubject = new BehaviorSubject<Crop[]>([]);
  private _selectedRegionsSubject = new BehaviorSubject<Region[]>([]);
  private _benchmarkTypesSubject = new BehaviorSubject<BenchmarkType[]>([
    {
      id: BenchmarkTypeId.ECONOMY,
      name: 'main.benchmark.types.economy',
    },
    {
      id: BenchmarkTypeId.YIELD,
      name: 'main.benchmark.types.yield',
    },
  ]);
  private _selectedBenchmarkTypeSubject: BehaviorSubject<BenchmarkType> = new BehaviorSubject<BenchmarkType>({
    id: BenchmarkTypeId.YIELD,
    name: 'main.benchmark.types.yield',
  });

  public get selectedBenchmarkType$(): Observable<BenchmarkType> {
    return this._selectedBenchmarkTypeSubject.asObservable();
  }

  public set selectedBenchmarkType(selectedBenchmarkType: BenchmarkType) {
    this._selectedBenchmarkTypeSubject.next(selectedBenchmarkType);
  }

  public get benchmarkTypes$(): Observable<BenchmarkType[]> {
    return this._benchmarkTypesSubject.asObservable();
  }

  public set benchmarkTypes(benchmarkTypes: BenchmarkType[]) {
    this._benchmarkTypesSubject.next(benchmarkTypes);
  }

  public get selectedRegions$(): Observable<Region[]> {
    return this._selectedRegionsSubject.asObservable();
  }

  public set selectedRegions(selectedRegions: Region[]) {
    this._selectedRegionsSubject.next(selectedRegions);
  }

  public set selectRegion(regionId: number) {
    this.selectedRegions$
      .pipe(
        first(),
        map((selectedRegions) => selectedRegions.filter((region) => region?.id !== regionId)),
        withLatestFrom(this.regions$)
      )
      .subscribe(([selectedRegions, regions]) => {
        const selectedRegion = regions ? regions.filter((region) => region?.id === regionId) : [];
        this.selectedRegions = selectedRegions.concat(selectedRegion);
      });
  }

  public set deSelectRegion(regionId: number) {
    this.selectedRegions$
      .pipe(
        first(),
        map((selectedRegions) => selectedRegions.filter((region) => region?.id !== regionId))
      )
      .subscribe((selectedRegions) => {
        this.selectedRegions = selectedRegions;
      });
  }

  public get validCrops$(): Observable<Crop[]> {
    return this._validCropsSubject.asObservable();
  }

  public set validCrops(validCrops: Crop[]) {
    this._validCropsSubject.next(validCrops);
  }

  public get regions$(): Observable<Region[]> {
    return this._regionsSubject.asObservable();
  }

  public set regions(regions: Region[]) {
    this._regionsSubject.next(regions);
  }
}
