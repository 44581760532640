<div class="content">
  <h2>
    {{ 'main.fieldAdministration.copyField.fieldsInHarvestYearCopied' | translate }} {{ harvestYear$ | async }}
    {{ 'main.fieldAdministration.copyField.fieldsInHarvestYearCopied2' | translate }} {{ (harvestYear$ | async)! + 1 }}
  </h2>

  <form [formGroup]="formGroup" *ngIf="!!formGroup">
    <kendo-grid
      class="k-grid-named k-grid-content-named"
      [data]="gridView"
      [kendoGridInCellEditing]="createFormGroup"
      [inCellEditingTabNavigation]="createFormGroup"
      (cellClick)="editHandler($event)"
      (cellClose)="cellCloseHandler($event)"
      [rowClass]="rowCallback"
      [navigable]="true"
      kendoGridSelectBy="id"
      [selectedKeys]="[]"
    >
      <kendo-grid-column field="fieldNumber" [headerStyle]="{ width: '10%', background: 'white' }" [style]="{ width: '10%' }">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ 'main.fieldAdministration.fieldList.headerColumns.fieldNumber' | translate }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <app-field-number-input kendoGridFocusable [formControl]="formGroup.controls['fieldNumber']"></app-field-number-input>
          <div *ngIf="!formGroup.controls['fieldNumber'].valid" #anchor>
            <kendo-popup [anchor]="anchor" [animate]="false" *ngIf="isInvalidFieldNumber || isFieldNumberInUse">
              <div class="errors-validation">
                <div *ngIf="isInvalidFieldNumber">{{ 'main.fieldAdministration.createField.fieldNoError' | translate }}</div>
                <div *ngIf="isFieldNumberInUse">{{ 'main.fieldAdministration.createField.invalidFieldNumber' | translate }}</div>
                <div *ngIf="isValidating"></div>
              </div>
            </kendo-popup>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <span>{{ dataItem.fieldNumber }}</span
          ><app-icon icon="edit_outline" class="edit-icon"></app-icon
        ></ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fieldName" [headerStyle]="{ width: '38%', background: 'white' }" [style]="{ width: '38%' }">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ 'main.fieldAdministration.fieldList.headerColumns.fieldName' | translate }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
          <kendo-textbox
            class="textbox"
            [maxlength]="20"
            kendoGridFocusable
            [formControl]="formGroup.get(column.field)"
            placeholder="{{ 'main.fieldAdministration.fieldList.edit.placeholder.fieldName' | translate }}"
          >
            <ng-template kendoTextBoxSuffixTemplate> </ng-template>
          </kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <span>{{ dataItem.fieldName }}</span
          ><app-icon icon="edit_outline" class="edit-icon"></app-icon>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fieldCrop" [headerStyle]="{ width: '30%', background: 'white' }" [style]="{ width: '30%' }">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ 'main.fieldAdministration.fieldList.headerColumns.crop' | translate }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
          <kendo-dropdownlist
            #dropdownlist
            kendoGridFocusable
            (focus)="onFocus(dropdownlist)"
            [data]="crops$ | async"
            [formControl]="formGroup.get(column.field)"
            [valuePrimitive]="false"
            [defaultItem]="getDefaultItem(dataItem)"
            textField="name"
            valueField="number"
          >
          </kendo-dropdownlist>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ getCropName(dataItem) }}
          <div class="arrow-down"></div
        ></ng-template>
      </kendo-grid-column>
      <!--<kendo-grid-column field="fieldCrop"  [headerStyle]="{'width': '30%', 'background': 'white'}" [style]="{'width': '30%'}">
          <ng-template kendoGridHeaderTemplate let-column>
            {{'main.fieldAdministration.fieldList.headerColumns.crop' | translate}}
          </ng-template>
          <ng-template kendoGridCellTemplate>
            <app-field-number-input kendoGridFocusable [formControl]="formGroup.controls['fieldNumber']"></app-field-number-input>
           <app-select class="form-select"[formControl]="formGroup.get('fieldCrop')">
            <app-select-option *ngFor="let crop of crops$ | async; trackBy: trackByFn">
              <span [ngClass]="{'dimmed': crop.number === undefined || null}">{{crop.name}} r</span>
            </app-select-option>
          </app-select>
          </ng-template>
          </kendo-grid-column> -->
      <!--<kendo-grid-column field="fieldCropSort" [headerStyle]="{'width': '30%', 'background': 'white'}" [style]="{'width': '30%'}">
            <ng-template kendoGridHeaderTemplate let-column>
              {{'main.fieldAdministration.fieldList.headerColumns.variety' | translate}}
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <app-select class="form-select" [formControl]="formGroup.controls['fieldCropSort']" [placeholder]="dataItem.fieldCropSort">
                <app-select-option *ngFor="let crop of crops$ | async; trackBy: trackByFn">
                  <span [ngClass]="{'dimmed': crop.number === undefined || null}">{{crop.name}} r</span>
                </app-select-option>
              </app-select>
            </ng-template>
            </kendo-grid-column> -->
    </kendo-grid>
  </form>
</div>
