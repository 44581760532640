import { Injectable } from '@angular/core';
import { latest } from '@app/shared/constants/rxjs-constants';
import { filterEmpty } from '@app/shared/operators/filter-empty';
import { createStore, select, withProps } from '@ngneat/elf';
import { stateHistory } from '@ngneat/elf-state-history';
import { map, shareReplay, tap } from 'rxjs';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

interface AdjustPlannedAmount {
  adjust: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AdjustAmountRepository {
  constructor(private pmQuery: PrescriptionMapQuery) {}
  private readonly _store = createStore({ name: 'adjust-planned-amount' }, withProps<AdjustPlannedAmount>({ adjust: true }));

  private readonly _history = stateHistory(this._store);

  public readonly adjustPlannedAmount$ = this._store.pipe(
    select(({ adjust }) => adjust),
    shareReplay(1)
  );

  public readonly hasPast$ = this._history.hasPast$;

  private readonly _init$ = this.pmQuery.prescriptionMapsByActiveTask$.pipe(
    filterEmpty(),
    map((maps) => maps.some((map) => map.adjustPlannedAmount)),
    tap((adjustPlannedAmount) => {
      this._history.pause();
      this.update(adjustPlannedAmount);
    }),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public update(adjust: boolean) {
    this._store.update((state) => ({ ...state, adjust }));
    this._history.resume();
  }

  public undo() {
    this._history.undo();
  }

  public redo() {
    this._history.redo();
  }

  public resetHistory() {
    this._history.jumpToPast(0);
    this._history.clear((history) => ({ ...history, past: [], future: [] }));
  }

  public clearHistory() {
    this._history.clear((history) => ({ ...history, past: [], future: [] }));
  }
}
