import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeSv from '@angular/common/locales/sv';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppInitService } from '@app/app-init.service';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/core/app-routing.module';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { NaesgaardGuard } from '@app/core/guards/naesgaard.guard';
import { BlobErrorHttpInterceptor } from '@app/core/interceptors/BlobErrorInterceptor';
import { GlobalErrorHandler } from '@app/global-error-handler.service';
import { LayoutsModule } from '@app/layouts/layouts.module';
import { PermaModule } from '@app/perma/perma.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { TranslateModule as NgxTranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/da/all';
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/pl/all';
import '@progress/kendo-angular-intl/locales/sv/all';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { environment } from '../environments/environment';
import { BffAuthInterceptor } from './core/authentication/bff-auth.interceptor';
import { EndpointsService } from './core/endpoints/endpoints.service';
import { FeatureBranchInterceptor } from './core/feature-branch/feature-branch.interceptor';
import { getFeatureToggleClient } from './core/feature-toggle-client-factory/feature-toggle-client-factory.fn';
import { FrontPageRedirectGuard } from './core/guards/frontpage-redirect.guard';
import { LoginRedirectGuard } from './core/guards/login-redirect.guard';
import { LoginSubscriptionsRedirectGuard } from './core/guards/login-subscriptions-redirect.guard';
import { LogDeprecatedInterceptor } from './core/interceptors/log-deprecated.service';
import { SideDrawerOverlayModule } from './core/side-drawer-overlay/side-drawer-overlay.module';
import { MdPaginatorIntlTranslate } from './helpers/Translate/MdPaginatorIntlTranslate';
import { FEATURE_TOGGLE_CLIENT } from './libraries/ng-feature-toggles/tokens/feature-toggle-client.token';
import { SharedModule } from './shared/shared.module';
import { SideDrawerModule } from './shared/side-drawer/side-drawer.module';

registerLocaleData(localeDa);
registerLocaleData(localeDe);
registerLocaleData(localePl);
registerLocaleData(localeSv);

export function init_app(appInitService: AppInitService) {
  return () => appInitService.init();
}
// AoT requires an exported function for factories
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IntlModule,
    BrowserAnimationsModule,
    TranslateModule,
    AppRoutingModule,
    PermaModule,
    SharedModule,
    SideDrawerOverlayModule,
    SideDrawerModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: !environment.isLocalhost }),
    LayoutsModule,
    NgxTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HammerModule,
    PDFExportModule,
    TreeListModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'da' },
    AppInitService,
    AuthGuard,
    FrontPageRedirectGuard,
    LoginRedirectGuard,
    LoginSubscriptionsRedirectGuard,
    NaesgaardGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogDeprecatedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BffAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FeatureBranchInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BffAuthInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      useClass: MdPaginatorIntlTranslate,
    },
    {
      provide: FEATURE_TOGGLE_CLIENT,
      useFactory: getFeatureToggleClient,
      deps: [EndpointsService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(mdIconRegistry: MatIconRegistry) {
    mdIconRegistry.registerFontClassAlias('fa', 'fa');
  }
}
