import { Injectable } from '@angular/core';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { ArrayHelper } from '@app/helpers/arrays/array-helpers';
import { removeObjectArrayItem } from '@app/state/reducers/functions/remove-object-array-item.fn';
import { updateObjectArrayItem } from '@app/state/reducers/functions/update-object-array-item.fn';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { UserStateService } from '../user/user-state.service';

@Injectable({
  providedIn: 'root',
})
export class FarmStateService {
  private _selectedFarms = new BehaviorSubject<Farm[]>([]);
  private _farms = new BehaviorSubject<Farm[] | null>(null);
  private _searchTerm = new BehaviorSubject<string>('');
  private _fields = new BehaviorSubject<Field[]>([]);
  private _isFarmsLoading = new BehaviorSubject<boolean>(false);
  private _farmsAreSelected = new BehaviorSubject<boolean>(false);
  private _fieldFeatures = new BehaviorSubject<FieldFeatures | null>(null);
  private _farmSearchTerm = new BehaviorSubject<string>('');

  public readonly userType$ = this.userStateService.userType$;

  constructor(private userStateService: UserStateService) {}

  public get selectedFarmIds$(): Observable<number[]> {
    return this.selectedFarms$.pipe(
      filter((farms) => !!farms),
      map((farms) => farms.map((farm) => farm.id))
    );
  }

  public get selectedFarms$(): Observable<Farm[]> {
    return this._selectedFarms.asObservable();
  }

  public get farms$() {
    return this._farms.asObservable();
  }

  public get searchTerm$(): Observable<string> {
    return this._searchTerm.asObservable();
  }

  public get isFarmsLoading$(): Observable<boolean> {
    return this._isFarmsLoading.asObservable();
  }

  public get farmsAreSelected$(): Observable<boolean> {
    return this._farmsAreSelected.asObservable();
  }

  public set farmsAreSelected(farmsAreSelected: boolean) {
    this._farmsAreSelected.next(farmsAreSelected);
  }

  public get fieldFeatures$() {
    return this._fieldFeatures.asObservable();
  }

  public set fieldFeatures(fieldFeatures: FieldFeatures) {
    this._fieldFeatures.next(fieldFeatures);
  }

  public set selectedFarms(selectedFarms: Farm[]) {
    this.farmsAreSelected = selectedFarms && selectedFarms.length > 0;
    this._selectedFarms.next(selectedFarms);
  }

  public set farms(farms: Farm[]) {
    this._farms.next(farms);
  }

  public resetFarms() {
    this._selectedFarms.next([]);
  }

  public updateFarm(updatedFarm: Farm) {
    this.farms$.pipe(withLatestFrom(this.selectedFarms$), first()).subscribe(([stateFarms, stateSelectedFarms]) => {
      this.farms = updateObjectArrayItem<Farm>(stateFarms ?? [], (farm) => farm.id === updatedFarm.id, updatedFarm, [
        'hasDataShareSubscription',
        'hasWriteAccess',
        'subscriptions',
      ]);
      this.selectedFarms = updateObjectArrayItem<Farm>(stateSelectedFarms, (farm) => farm.id === updatedFarm.id, updatedFarm, [
        'hasDataShareSubscription',
        'hasWriteAccess',
        'subscriptions',
      ]);
    });
  }

  public deleteFarm(farmToDelete: Farm) {
    this.farms$.pipe(withLatestFrom(this.selectedFarms$), first()).subscribe(([stateFarms, stateSelectedFarms]) => {
      this.farms = removeObjectArrayItem<Farm>(stateFarms ?? [], (farm) => farm.id === farmToDelete.id);
      this.selectedFarms = removeObjectArrayItem<Farm>(stateSelectedFarms, (farm) => farm.id === farmToDelete.id);
    });
  }

  public set searchTerm(searchTerm: string) {
    this._searchTerm.next(searchTerm);
  }

  public get fields$() {
    return this.fieldFeatures$.pipe(
      filter((fieldFeatures) => !!fieldFeatures && !!fieldFeatures.fieldFeatures),
      map((fieldFeatures) => fieldFeatures?.fieldFeatures.map((feature) => feature.field)),
      map((fields) => fields?.filter(ArrayHelper.notEmpty))
    );
  }

  public set fields(fields: Field[]) {
    this._fields.next(fields);
  }

  public deleteField(fieldLayers: FieldLayerItem[]) {
    this.fields$.pipe(withLatestFrom(this.fieldFeatures$), first()).subscribe(([stateFields, stateFieldFeatures]) => {
      this.fields = removeObjectArrayItem<Field>(stateFields ?? [], (field) => field === fieldLayers[0].field);
      this.fieldFeatures = {
        ...stateFieldFeatures!,
        fieldFeatures: removeObjectArrayItem<FieldLayerItem>(
          stateFieldFeatures?.fieldFeatures ?? [],
          (fieldLayerItem) => fieldLayerItem.fieldId === fieldLayers[0].fieldId
        ),
      };
    });
  }

  public set isFarmsLoading(isFarmsLoading: boolean) {
    this._isFarmsLoading.next(isFarmsLoading);
  }

  // TODO: KRIV - When refactoring field picker, this state should be local to the new field picker component
  public set farmSearchTerm(searchString: string) {
    this._farmSearchTerm.next(searchString);
  }

  public fieldUpdated(fieldLayers: FieldLayerItem[]) {
    this.fields$.pipe(withLatestFrom(this.fieldFeatures$), first()).subscribe(([stateFields, stateFieldFeatures]) => {
      if (fieldLayers?.length > 0) {
        this.fields = [...stateFields!.filter((field) => field.id !== fieldLayers[0].field!.id), fieldLayers[0].field!];
        this.fieldFeatures = {
          ...stateFieldFeatures!,
          fieldFeatures: [...stateFieldFeatures!.fieldFeatures.filter((feat) => feat.fieldId !== fieldLayers[0].fieldId), ...fieldLayers],
        };
      }
    });
  }

  public fieldCreated(fieldLayerItems: FieldLayerItem[]) {
    this.fields$.pipe(withLatestFrom(this.fieldFeatures$), first()).subscribe(([stateFields, stateFieldFeatures]) => {
      this.fields = [...stateFields!, fieldLayerItems[0].field!];
      this.fieldFeatures = {
        ...stateFieldFeatures!,
        fieldFeatures: [...stateFieldFeatures!.fieldFeatures, ...fieldLayerItems],
      };
    });
  }
}
