<div class="soil-sample-table-content">
  <h3 class="analysis-header">Analyse-tabel</h3>
  <table>
    <thead>
      <th>{{ 'main.soilSamples.table.analysis' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.veryLow' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.low' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.middle' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.high' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.veryHigh' | translate }}</th>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'main.soilSamples.table.phosphor' | translate }}</td>
        <td>< 1,0</td>
        <td>1,0-2,0</td>
        <td>2,1-4,0</td>
        <td>4,1-6,0</td>
        <td>> 6,0</td>
      </tr>
      <tr>
        <td>{{ 'main.soilSamples.table.potassium1' | translate }}</td>
        <td>< 3,0</td>
        <td>3,0-5,0</td>
        <td>5,1-8,0</td>
        <td>8,1-12,0</td>
        <td>> 12,0</td>
      </tr>
      <tr>
        <td>{{ 'main.soilSamples.table.potassium2' | translate }}</td>
        <td>< 4,0</td>
        <td>4,0-7,0</td>
        <td>7,1-10,0</td>
        <td>10,1-15,0</td>
        <td>> 15,0</td>
      </tr>
      <tr>
        <td>{{ 'main.soilSamples.table.magnesium' | translate }}</td>
        <td>< 2,0</td>
        <td>2,0-4,0</td>
        <td>4,1-8,0</td>
        <td>8,1-12,0</td>
        <td>> 12,0</td>
      </tr>
      <tr>
        <td>{{ 'main.soilSamples.table.copper' | translate }}</td>
        <td>< 0,8</td>
        <td>0,8-2,0</td>
        <td>2,1-5,0</td>
        <td>5,1-8,0</td>
        <td>> 8,0</td>
      </tr>
    </tbody>
    <tr>
      <td>{{ 'main.soilSamples.table.borium' | translate }}</td>
      <td>< 1,5</td>
      <td>1,5-3,0</td>
      <td>3,1-5,0</td>
      <td>5,1-8,0</td>
      <td>> 8,0</td>
    </tr>
  </table>

  <h3 class="reaction-number-header">{{ 'main.soilSamples.results.reactionNumber' | translate }}</h3>

  <table>
    <thead>
      <th>{{ 'main.soilSamples.table.soilType' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.veryLow' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.low' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.middle' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.high' | translate }}</th>
      <th>{{ 'main.soilSamples.legend.veryHigh' | translate }}</th>
    </thead>
    <tbody>
      <tr>
        <td>1-4</td>
        <td>< 5,5</td>
        <td>5,5-5,9</td>
        <td>6,0-6,3</td>
        <td>6,4-6,7</td>
        <td>> 6,7</td>
      </tr>
      <tr>
        <td>5-6</td>
        <td>< 5,5</td>
        <td>5,5-6,2</td>
        <td>6,3-6,7</td>
        <td>6,8-7,1</td>
        <td>> 7,1</td>
      </tr>
      <tr>
        <td>7-9</td>
        <td>< 5,5</td>
        <td>5,6-6,5</td>
        <td>6,6-6,9</td>
        <td>7,0-7,4</td>
        <td>> 7,4</td>
      </tr>
      <tr>
        <td>11</td>
        <td>< 4,5</td>
        <td>4,5-4,9</td>
        <td>5,0-5,4</td>
        <td>5,5-5,9</td>
        <td>> 5,9</td>
      </tr>
    </tbody>
  </table>

  <p>* {{ 'main.soilSamples.table.starText' | translate }}</p>
</div>
