import { Component, Input } from '@angular/core';
import { FeedBackStateService } from '@app/state/services/feed-back/feed-back-state.service';
import { Observable, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['feedback-button.component.scss'],
})
export class FeedbackButtonComponent {
  public readonly isFeedbackOpen$: Observable<boolean | null> = this.feedBackStateService.isFeedBackOpen$;
  @Input() public disabled$: Observable<boolean | undefined> = of(false);

  constructor(private feedBackStateService: FeedBackStateService) {}

  public openFeedbackDrawer() {
    this.disabled$
      .pipe(
        first(),
        filter((isDisabled) => !isDisabled)
      )
      .subscribe(() => {
        this.openSideDrawer();
      });
  }

  private openSideDrawer() {
    this.feedBackStateService.isFeedBackOpen = true;
  }
}
