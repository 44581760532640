import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BenchmarkDataService } from '@app/core/benchmark-data/benchmark-data.service';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { FeatureService } from '@app/core/feature/feature.service';
import { Field } from '@app/core/interfaces/field.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { CropNormDTO } from '@app/core/repositories/crops/crop-norm.dto';
import { CropsRepoService } from '@app/core/repositories/crops/crops-repo.service';
import { VarietyNormDTO } from '@app/core/repositories/crops/variety-norm.dto';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { SoilTypeDTO } from '@app/core/repositories/fields/soil-types.dto';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { TaskService } from '@app/core/task/task.service';
import { WKTUtil } from '@app/new-map/helpers/utils/WKT-util';
import { OlMapService } from '@app/new-map/map-service/ol-map.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { FarmLockedService } from '@app/shared/farm-locked/farm-locked.service';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import Feature from 'ol/Feature';
import { BehaviorSubject, NEVER, Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { FieldPlanSideDrawerService } from '../../field-plan-side-drawer.service';
import { CreateFieldDTO } from '../../interfaces/create-field-dto.interface';
import { UpdateFieldDTO } from '../../interfaces/update-field-dto.interface';
import { FieldPlanContentService } from '../field-plan-content.service';
import { DeleteCropTaskComponent } from './DeleteCropTask/DeleteCropTask.component';
import { ChangeCrop } from './change-crop-modal/change-crop-modal-events.enum';
import { ChangeCropModalComponent } from './change-crop-modal/change-crop-modal.component';
import { CreateFieldFormComponent } from './create-field-form.component';

@Injectable({
  providedIn: 'root',
})
export class CreateFieldFormService {
  public readonly harvestYear$: Observable<number | undefined> = this.harvestYearStateService.harvestYear$;

  private subscriptions = new Subscription();
  private formGroup!: UntypedFormGroup;

  private _originalFieldFeature: Feature | null = null;
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _preCropsSelectPlaceHolder$ = new BehaviorSubject<string>('common.preCrop');
  private _varietiesSelectPlaceholder$ = new BehaviorSubject<string>('common.cropType');
  private _cropsSelectPlaceHolder$ = new BehaviorSubject<string>('common.crop');
  private _loadingMessage$ = new BehaviorSubject<string>('common.loadingData');
  private _crops$ = new BehaviorSubject<CropNormDTO[]>([]);
  private _preCrops$ = new BehaviorSubject<CropNormDTO[]>([]);
  private _soilTypes$ = new BehaviorSubject<SoilTypeDTO[]>([]);
  private _varieties$ = new BehaviorSubject<VarietyNormDTO[]>([]);
  private _isValidatingFieldNumber$ = new BehaviorSubject<boolean>(false);
  private _fieldArea$ = new BehaviorSubject<string>('0');

  public selectedHarvestYear$ = this.harvestYearStateService.harvestYear$;
  public selectedFarms$ = this.farmStateService.selectedFarms$;
  public drawnPolygons$ = this.fieldPlanSideDrawerService.drawnPolygons$;
  public isFieldPolygonValid$ = this.fieldPlanSideDrawerService.isFieldPolygonValid$;
  public isLoading$ = this._isLoading$.asObservable();
  public preCropsSelectPlaceHolder$ = this._preCropsSelectPlaceHolder$.asObservable();
  public varietiesSelectPlaceholder$ = this._varietiesSelectPlaceholder$.asObservable();
  public cropsSelectPlaceHolder$ = this._cropsSelectPlaceHolder$.asObservable();
  public loadingMessage$ = this._loadingMessage$.asObservable();
  public crops$ = this._crops$.asObservable();
  public preCrops$ = this._preCrops$.asObservable();
  public soilTypes$ = this._soilTypes$.asObservable();
  public varieties$ = this._varieties$.asObservable();
  public isValidatingFieldNumber$ = this._isValidatingFieldNumber$.asObservable();
  public fieldArea$ = this._fieldArea$.asObservable();

  constructor(
    private mapService: OlMapService,
    private featureService: FeatureService,
    private cropsRepo: CropsRepoService,
    private fieldsRepo: FieldsRepo,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    private benchmarkDataService: BenchmarkDataService,
    private taskService: TaskService,
    private dialogService: DialogService,
    private dirtyCheckService: DirtyCheckService,
    private sideDrawerRef: SideDrawerRef<CreateFieldFormComponent, any, any>,
    private fieldPlanContentService: FieldPlanContentService,
    private farmLockedService: FarmLockedService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  public get editedField() {
    return this.fieldPlanSideDrawerService.editedField;
  }

  public set editedField(field: Field | null) {
    this.fieldPlanSideDrawerService.editedField = field;
  }

  public get isDrawing$() {
    return this.mapService.isDrawing;
  }

  public get farmIdFormControl() {
    return this.formGroup.get('farmId');
  }

  public get fieldNumberFormControl() {
    return this.formGroup.get('fieldNumber');
  }

  public get varietyIdFormControl() {
    return this.formGroup.get('varietyId');
  }

  public get cropFormControl() {
    return this.formGroup.get('crop');
  }

  public get preCropIdFormControl() {
    return this.formGroup.get('preCropId');
  }

  public get soilTypeIdFormControl() {
    return this.formGroup.get('soilTypeId');
  }

  public get nameFormControl() {
    return this.formGroup.get('name');
  }

  public get harvestYearChange$() {
    return this.selectedHarvestYear$.pipe(filter((harvestYear) => this.editedField! && this.editedField.harvestYear !== harvestYear));
  }

  public get deselectedFarmForCurrentField$() {
    return this.selectedFarms$.pipe(filter((farms) => farms.every((farm) => farm.id !== this.editedField?.farmId)));
  }

  /**
   * Validates if any selected farm with the given farmId is locked in the selected or previous harvest year.
   * Only validates when creating new fields, not on edit.
   */
  private farmLockedValidator(farmId: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      // Returns null if _originalFieldFeature contains an id, because then we are editing.
      if (!control || (this._originalFieldFeature && this._originalFieldFeature.getId())) {
        return null;
      }

      const controlValue = control.get(farmId)?.value;
      if (!controlValue) {
        return null;
      }

      const farmIdNumber = Number(controlValue);
      if (!farmIdNumber || isNaN(farmIdNumber)) {
        return null;
      }

      const lockedHarvestYear = this.farmLockedService.getFarmLockedMessageById(farmIdNumber);

      return lockedHarvestYear ? { lockedHarvestYear: lockedHarvestYear as unknown as string } : null;
    };
  }

  public getAndSetFormGroup() {
    this.formGroup = new UntypedFormGroup(
      {
        soilTypeId: new UntypedFormControl(null, Validators.required),
        preCropId: new UntypedFormControl(null, Validators.required),
        crop: new UntypedFormControl(null, Validators.required),
        varietyId: new UntypedFormControl(null),
        name: new UntypedFormControl(''),
        fieldNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(/^([1-9]|[1-9][0-9]{1,2})[-+]([0-9]{1,2})$/)]),
        farmId: new UntypedFormControl(null, Validators.required),
      },
      { validators: [this.farmLockedValidator('farmId')] }
    );
    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(debounceTime(200)).subscribe(() => {
        this.dirtyCheckService.setIsFeatureDirty(this, this.formGroup.dirty);
      })
    );
    this.formGroup.markAsPristine();
    return this.formGroup;
  }

  public init(editedFeature: Feature | null) {
    this.editedField = editedFeature ? editedFeature.get('field') : null;

    this.getAndSetVarietiesOnCropChange();

    this.getAndSetCropsOnFarmChange();

    this.getAndSetPreCropsOnFarmChange();

    this.resetFieldNumberValidationOnFarmChange();

    this.validateFieldNumberOnFarmIdAndFieldNumberChange();

    this.farmIdFormControl?.setValue(this.editedField ? this.editedField.farmId : null);
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();

    this.getSoilTypeOnDrawnFieldChange();

    this.setFarmSelectOnSelectedFarmsChange();

    this.getAndSetSoilTypes();

    this.getAndSetFieldAreaOnPolygonChange();

    if (this.isEditingField) {
      this.farmIdFormControl?.disable();
      this.setFormData(this.editedField);
      this._originalFieldFeature = cloneDeep(editedFeature);
      this.fieldPlanSideDrawerService.startEditingPolygon(editedFeature!);
    }

    this.disableInputsOnInvalidFarmChange();
  }

  public destroy() {
    this.subscriptions.unsubscribe();

    this.editedField = null;
    this.fieldPlanSideDrawerService.editedFieldCUD = false;
    this.resetDrawnPolygons();
    this.fieldPlanContentService.unSubscribeClickFromMap();
    this.dirtyCheckService.setIsFeatureDirty(this, false);
    this.mapService.enableSelectInteraction();
  }

  public get isEditingField() {
    return this.fieldPlanSideDrawerService.isEditingField;
  }

  public stopDrawingPolygons() {
    this.fieldPlanSideDrawerService.stopDrawingPolygons();
  }

  public resetDrawnPolygons(toOriginal: boolean = false) {
    this.fieldPlanSideDrawerService.resetDrawnPolygons(toOriginal ? this._originalFieldFeature : null);
  }

  public resetCurrentPolygon() {
    this.fieldPlanSideDrawerService.resetDrawnPolygons(this._originalFieldFeature);
    this.farmStateService.deleteField(this.featureService.getFieldsLayers([this.editedField!]));
    this.fieldPlanSideDrawerService.startDrawingPolygons();
  }

  public resetDrawnFieldBlockLayer() {
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(false);
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(true);
    this.fieldPlanContentService.subscribeClickOnMap();
  }

  public clearDrawnPolygons() {
    this.fieldPlanSideDrawerService.resetDrawnPolygons(null);
  }

  public startDrawingPolygons() {
    this.fieldPlanSideDrawerService.startDrawingPolygons();
  }

  public deleteField() {
    this.stopDrawingPolygons();
    this.stopModifyingPolygons();
    this._loadingMessage$.next('main.fieldAdministration.createField.deletingField');
    this._isLoading$.next(true);
    return this.fieldsRepo.delete(this.editedField!.farmId, this.editedField!.id).pipe(
      tap((statusCode) => {
        if (statusCode === 200) {
          this.fieldPlanSideDrawerService.editedFieldCUD = true;
          this.notificationService.showDeleted('common.field');
          this.farmStateService.deleteField(this.featureService.getFieldsLayers([this.editedField!]));
        }
        this._isLoading$.next(false);
        this._loadingMessage$.next('common.loadingData');
      })
    );
  }

  // public isLoading(loading: boolean) {
  //   this._isLoading$.next(loading);
  // }

  public get isSaveEnabled$() {
    return combineLatest([this.isFieldPolygonValid$, this.formGroup.statusChanges, this.isValidatingFieldNumber$]).pipe(
      map(([isFieldPolygonValid, formStatus, isValidatingFieldNumber]) => {
        return formStatus === 'VALID' && isFieldPolygonValid && !isValidatingFieldNumber;
      })
    );
  }

  public get isDescriptionVisible$() {
    return combineLatest([this.drawnPolygons$, this.mapService.isDrawing]).pipe(
      map(([field, isDrawing]) => {
        return !field!.fieldPolygon && !isDrawing;
      })
    );
  }

  public get isRedrawVisible$() {
    return combineLatest([this.drawnPolygons$, this.mapService.isDrawing]).pipe(
      map(([field, isDrawing]) => {
        return !!field!.fieldPolygon && !isDrawing;
      })
    );
  }

  public get isReselectVisible$() {
    return combineLatest([this.drawnPolygons$, this.mapService.isDrawing]).pipe(
      map(([field, isDrawing]) => {
        return !!field!.fieldPolygon && !isDrawing;
      })
    );
  }

  public saveField() {
    if (this.isNoCropSelected() && this.hadCropPreviously()) {
      return this.openConfirmDeleteTasksDialog();
    } else if (this.isEditingField && this.hasCropChanged() && this.hadCropPreviously()) {
      return this.openConfirmEditFieldDialog();
    } else if (this.isEditingField) {
      return this.updateIfChanges();
    } else {
      return this.createField().pipe(map(() => false));
    }
  }

  private isNoCropSelected() {
    const selectedCropNorm: CropNormDTO = this.cropFormControl?.value;
    return !selectedCropNorm?.number;
  }

  private hadCropPreviously() {
    if (this.editedField) {
      return !!this.editedField.crops.length;
    } else {
      return false;
    }
  }
  private updateField(changeCropEvent: ChangeCrop) {
    if (changeCropEvent === ChangeCrop.Cancel || !changeCropEvent) {
      return NEVER;
    }

    this.stopDrawingPolygons();
    this.stopModifyingPolygons();
    this._loadingMessage$.next('main.fieldAdministration.createField.updatingField');
    this._isLoading$.next(true);

    return this.getUpdateFieldDTO().pipe(
      switchMap((field) => this.fieldsRepo.updateField(field, this.shouldTasksBeDeleted(changeCropEvent))),
      filterNullish(),
      switchMap((field) => this.fieldCreatedOrUpdated(field)),
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of(error);
      })
    );
  }

  private isEqualToOriginal() {
    const original: Field = this._originalFieldFeature ? this._originalFieldFeature.get('field') : null;

    return original && this.editedField
      ? this.crops$.pipe(
          first(),
          withLatestFrom(this.fieldArea$),
          switchMap(([crops, fieldArea]) =>
            this.findVariety(this.editedField!).pipe(map((varietyNorm) => ({ crops, fieldArea, varietyNorm })))
          ),
          map(({ crops, fieldArea, varietyNorm }) => {
            const selectedCrop = crops.find((c) => (this.cropFormControl?.value ? c.number === this.cropFormControl?.value.number : false));
            const selectedCropName = selectedCrop ? selectedCrop.name : '';

            const originalVarietyNumber = varietyNorm ? parseInt(varietyNorm.number.replace('p', ''), 10) : null;

            return (
              parseFloat(fieldArea.replace(',', '.')) === original.area &&
              this.soilTypeIdFormControl?.value === original.jb &&
              selectedCropName === (original.crops.length ? original.crops[0].cropName : '') &&
              this.varietyIdFormControl?.value === originalVarietyNumber &&
              this.fieldNumberFormControl?.value === original.number &&
              this.nameFormControl?.value === original.name
            );
          })
        )
      : of(false);
  }

  private hasCropChanged() {
    const selectedCropNorm: CropNormDTO = this.cropFormControl?.value;
    if (!this.editedField?.crops.length && !selectedCropNorm?.number) {
      return false;
    }
    return this.editedField?.crops.length && selectedCropNorm.number
      ? this.editedField?.crops[0].cropNormNumber !== selectedCropNorm.number
      : true;
  }

  private updateIfChanges() {
    return this.isEqualToOriginal().pipe(
      switchMap((isEqual) => {
        return isEqual ? of(false) : this.updateField(ChangeCrop.KeepTasks).pipe(map(() => false));
      })
    );
  }

  private createField() {
    this.stopDrawingPolygons();
    this.stopModifyingPolygons();
    this._loadingMessage$.next('main.fieldAdministration.createField.creatingField');
    this._isLoading$.next(true);

    return this.getCreateFieldDTO().pipe(
      switchMap((field) => this.fieldsRepo.createField(field)),
      filterNullish(),
      switchMap((field) => this.fieldCreatedOrUpdated(field)),
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of({});
      })
    );
  }

  private fieldCreatedOrUpdated(field: Field) {
    return this.afterFieldCreatedOrUpdated(field).pipe(
      finalize(() => {
        this.fieldPlanSideDrawerService.editedFieldCUD = true;
        this._isLoading$.next(false);
        this._loadingMessage$.next('common.loadingData');
      })
    );
  }

  private getUpdateFieldDTO() {
    return this.drawnPolygons$.pipe(
      first(),
      withLatestFrom(this.selectedHarvestYear$, this.fieldArea$),
      map(([drawnPolygons, harvestYear, fieldArea]) => {
        const field: UpdateFieldDTO = {
          id: this.isEditingField ? this.editedField?.id : undefined,
          area: parseFloat(fieldArea.replace(',', '.')),
          cropNormNumber: this.cropFormControl?.value?.number,
          cropNormNumberPreCrop: this.preCropIdFormControl?.value,
          farmId: this.farmIdFormControl!.value,
          geometry: WKTUtil.getWktFromFeature(drawnPolygons!.fieldPolygon),
          harvestYear,
          jb: this.soilTypeIdFormControl!.value,
          name: this.nameFormControl!.value,
          number: this.fieldNumberFormControl!.value,
          varietyNormNumber: this.varietyIdFormControl!.value ? this.varietyIdFormControl!.value : undefined,
          cropId: this.editedField!.crops.length > 0 && this.cropFormControl?.value?.number ? this.editedField?.crops[0]?.id : undefined,
          fieldBlockId: this.getFieldBlockId(drawnPolygons!.fieldPolygon),
          featureId: this.editedField?.featureId,
        };

        return field;
      })
    );
  }

  private getFieldBlockId(feature: Feature | null) {
    const fieldText: string = feature?.get('text');
    if (this.editedField?.fieldBlocks?.length! > 0) {
      const subDivision = fieldText.replace(this.editedField!.number, '');
      return this.editedField?.fieldBlocks!.find((fieldBlock) => fieldBlock.subDivision === subDivision)?.id;
    }
    return undefined;
  }

  private getCreateFieldDTO() {
    return this.drawnPolygons$.pipe(
      first(),
      withLatestFrom(this.selectedHarvestYear$, this.fieldArea$),
      map(([drawnPolygons, harvestYear, fieldArea]) => {
        const field: CreateFieldDTO = {
          id: this.isEditingField ? this.editedField?.id : undefined,
          area: parseFloat(fieldArea.replace(',', '.')),
          cropNormNumberCrop: this.formGroup.value?.crop?.number,
          cropNormNumberPreCrop: this.formGroup.value?.preCropId >= 0 ? this.formGroup.value?.preCropId : null,
          farmId: this.formGroup.value.farmId,
          geometry: WKTUtil.getWktFromFeature(drawnPolygons!.fieldPolygon),
          harvestYear,
          jb: this.formGroup.value.soilTypeId,
          name: this.formGroup.value.name ? this.formGroup.value.name : '',
          number: this.formGroup.value.fieldNumber,
          varietyNormNumberCrop: this.formGroup.value?.varietyId,
        };
        return field;
      })
    );
  }

  private openConfirmEditFieldDialog() {
    return this.dialogService
      .openCustomDialog(ChangeCropModalComponent, {
        data: this.editedField,
        maxWidth: '650px',
      })
      .afterClosed()
      .pipe(switchMap((event: ChangeCrop) => this.updateField(event).pipe(map(() => event === ChangeCrop.Cancel))));
  }

  private openConfirmDeleteTasksDialog() {
    return this.dialogService
      .openCustomDialog(DeleteCropTaskComponent, {
        data: this.editedField,
        maxWidth: '650px',
      })
      .afterClosed()
      .pipe(switchMap((event: ChangeCrop) => this.updateField(event).pipe(map(() => event === ChangeCrop.Cancel))));
  }

  private shouldTasksBeDeleted(changeCropEvent: ChangeCrop) {
    switch (changeCropEvent) {
      case ChangeCrop.DeleteTasks:
        return true;
      case ChangeCrop.KeepTasks:
        return false;
      default:
        return;
    }
  }

  private afterFieldCreatedOrUpdated(field: Field) {
    this.mapService.disableSelectInteraction();
    this.dirtyCheckService.setIsFeatureDirty(this, false);
    !this._originalFieldFeature
      ? this.notificationService.showCreated('common.field')
      : this.notificationService.showUpdated('common.field');

    this._originalFieldFeature = null;
    this.updateStoreWithField(field);
    this.stopModifyingPolygons();
    this.stopDrawingPolygons();
    this.clearDrawnPolygons();
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.mapService.enableSelectInteraction();
    return of(field);
  }

  private getAndSetFieldAreaOnPolygonChange() {
    this.subscriptions.add(
      this.drawnPolygons$
        .pipe(
          switchMap((feature) => this.getFieldAreaInHa(feature!.fieldPolygon)),
          map((area) => area.toString().replace('.', ','))
        )
        .subscribe((area) => {
          this._fieldArea$.next(area);
          // If minimezed on mobile
          this.sideDrawerRef.show();
        })
    );
  }

  private getAndSetSoilTypes() {
    this.getSoilTypes().subscribe((soilTypes) => {
      this._soilTypes$.next(soilTypes);
      setTimeout(() => {
        if (this.editedField) {
          this.soilTypeIdFormControl?.setValue(this.editedField.jb);
          this.soilTypeIdFormControl?.markAsPristine();
        }
      });
    });
  }

  private getSoilTypeOnDrawnFieldChange() {
    let isFirstTimeload = this.isEditingField;

    if (isFirstTimeload) {
      this.soilTypeIdFormControl?.setValue(this.editedField?.jb);
      this.formGroup.markAsPristine();
    }

    this.subscriptions.add(
      this.determinedSoilType$.subscribe((soilTypeId) => {
        if (isFirstTimeload) {
          isFirstTimeload = false;
        } else {
          this.soilTypeIdFormControl?.setValue(soilTypeId);
        }
      })
    );
  }

  private getAndSetVarietiesOnCropChange() {
    this.subscriptions.add(
      this.cropFormControl?.valueChanges
        .pipe(
          distinctUntilChanged(),
          filterNullish(),
          withLatestFrom(this.selectedHarvestYear$),
          switchMap(([cropNorm, harvestYear]) => this.getVarieties(cropNorm.varietyGroupNumber, harvestYear))
        )
        .subscribe((varieties) => {
          this._varieties$.next(varieties);
          if (this.isEditingField) {
            this.setSelectedVariety(varieties);
          }
        })
    );
  }

  private setSelectedVariety(varieties: VarietyNormDTO[]) {
    this.varietyIdFormControl?.disable();
    this._varietiesSelectPlaceholder$.next('main.fieldAdministration.createField.loadingVarieties');

    this.findVariety(this.editedField!)
      .pipe(
        first(),
        finalize(() => {
          this._varietiesSelectPlaceholder$.next('common.cropType');
          if (!this.farmIdFormControl?.hasError('lockedHarvestYear')) {
            this.varietyIdFormControl?.enable();
          }
        })
      )
      .subscribe((variety) => {
        if (!variety) {
          this.varietyIdFormControl?.setValue(null);
          this.formGroup.markAsPristine();
        } else {
          const varietyNorm = varieties.find((norm) => norm.name === variety.name);

          this.varietyIdFormControl?.setValue(varietyNorm ? varietyNorm.number : null);
          this.formGroup.markAsPristine();
        }
      });
  }

  private resetFieldNumberValidationOnFarmChange() {
    this.subscriptions.add(this.farmIdFormControl?.valueChanges.subscribe(() => this.resetFieldNumberValidation()));
  }

  private disableInputsOnInvalidFarmChange() {
    this.subscriptions.add(
      this.farmIdFormControl?.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter((farmId) => !!farmId)
        )
        .subscribe(() => {
          if (this.formGroup.hasError('lockedHarvestYear')) {
            this.disableAllFieldsButFarmId();
          } else {
            this.formGroup.enable();
          }
        })
    );
  }

  private disableAllFieldsButFarmId() {
    this.preCropIdFormControl?.disable();

    this.cropFormControl?.disable();

    this.nameFormControl?.disable();

    this.varietyIdFormControl?.disable();

    this.soilTypeIdFormControl?.disable();

    this.fieldNumberFormControl?.disable();
  }

  private resetFieldNumberValidation() {
    this.fieldNumberFormControl?.markAsPristine();

    this.fieldNumberFormControl?.markAsUntouched();
  }

  private setFarmSelectOnSelectedFarmsChange() {
    this.subscriptions.add(
      this.selectedFarms$.subscribe((farms) => {
        if (this.isEditingField) {
          this.farmIdFormControl?.setValue(this.editedField?.farmId);
          this.formGroup.markAsPristine();
        } else if (farms.length === 1) {
          this.farmIdFormControl?.setValue(farms[0].id);
          this.formGroup.markAsPristine();
        } else {
          this.farmIdFormControl?.setValue(null);
          this.formGroup.markAsPristine();
        }
      })
    );
  }

  private getAndSetCropsOnFarmChange() {
    this.subscriptions.add(
      this.farmIdFormControl?.valueChanges
        .pipe(distinctUntilChanged(), filterNullish(), withLatestFrom(this.selectedHarvestYear$))
        .subscribe(([farmId, harvestYear]) => this.getAndSetCrops(farmId, harvestYear))
    );
  }

  private getAndSetPreCropsOnFarmChange() {
    this.subscriptions.add(
      this.farmIdFormControl?.valueChanges
        .pipe(distinctUntilChanged(), filterNullish(), withLatestFrom(this.selectedHarvestYear$))
        .subscribe(([farmId, harvestYear]) => {
          const previousHarvestYear = harvestYear !== undefined ? harvestYear - 1 : undefined;
          return this.getAndSetPreCrops(farmId, previousHarvestYear);
        })
    );
  }

  private getAndSetCrops(farmId: number, harvestYear?: number) {
    const noCrop: CropNormDTO = {
      farmId: farmId,
      harvestYear: harvestYear,
      name: this.translateService.instant('main.fieldAdministration.createField.dropDownAdditionalContent.noCrop'),
    };

    this.cropFormControl?.reset();

    this._preCropsSelectPlaceHolder$.next('main.fieldAdministration.createField.loadingPreCrops');
    this._cropsSelectPlaceHolder$.next('main.fieldAdministration.createField.loadingCrops');

    this.preCropIdFormControl?.disable();

    this.cropFormControl?.disable();

    this.getCropsAndPreCrops(farmId, harvestYear)
      .pipe(
        finalize(() => {
          if (this.formGroup.hasError('lockedHarvestYear')) {
            this.cropFormControl?.disable();
          } else {
            this.cropFormControl?.enable();
          }
          this._cropsSelectPlaceHolder$.next('common.crop');
        })
      )
      .subscribe((crops) => {
        crops.unshift(noCrop);
        this._crops$.next(crops);

        if (this.isEditingField) {
          this.setCropAndPreCrop();
        }
      });
  }

  private getAndSetPreCrops(farmId: number, preHarvestYear?: number) {
    const noPreCrop: CropNormDTO = {
      farmId: farmId,
      harvestYear: preHarvestYear,
      name: this.translateService.instant('main.fieldAdministration.createField.dropDownAdditionalContent.noPreCrop'),
      number: -1,
    };

    this.preCropIdFormControl?.reset();

    this._preCropsSelectPlaceHolder$.next('main.fieldAdministration.createField.loadingPreCrops');

    this.preCropIdFormControl?.disable();

    this.getCropsAndPreCrops(farmId, preHarvestYear)
      .pipe(
        finalize(() => {
          if (this.editedField || this.formGroup.hasError('lockedHarvestYear')) {
            this.preCropIdFormControl?.disable();
          } else {
            this.preCropIdFormControl?.enable();
          }
          this._preCropsSelectPlaceHolder$.next('common.preCrop');
        })
      )
      .subscribe((preCrops) => {
        preCrops.unshift(noPreCrop);
        this._preCrops$.next(preCrops);

        if (this.isEditingField) {
          this.setCropAndPreCrop();
        }
      });
  }

  private setCropAndPreCrop() {
    const cropname = this.editedField?.crops.length ? this.editedField?.crops[0].cropName : '';
    forkJoin([this.getCropByName(cropname), this.getCropByName(this.editedField!.preCropName)]).subscribe(([crop, preCrop]) => {
      setTimeout(() => {
        this.cropFormControl?.setValue(crop);
        this.preCropIdFormControl?.setValue(preCrop ? preCrop.number : null);
        this.formGroup.markAsPristine();
      });
    });
  }

  private setFormData(field: Field | null) {
    this.soilTypeIdFormControl?.setValue(field?.jb);
    this.fieldNumberFormControl?.setValue(field?.number);
    this.nameFormControl?.setValue(field?.name);
    this.formGroup.markAsPristine();
  }

  private getCropByName(name: string) {
    return this.crops$.pipe(
      first(),
      map((crops) => crops.find((crop) => crop.name === name))
    );
  }

  private validateFieldNumberOnFarmIdAndFieldNumberChange() {
    const fieldNumberFC = this.fieldNumberFormControl;
    const farmIdFC = this.farmIdFormControl;
    const id = this.isEditingField && this.editedField ? this.editedField.id : null;

    if (!fieldNumberFC || !farmIdFC) return;

    this.subscriptions.add(
      combineLatest([fieldNumberFC.valueChanges, farmIdFC.valueChanges])
        .pipe(
          filter(([fieldNumber, farmId]) => !!fieldNumber && !!farmId),
          filter(() => fieldNumberFC.valid),
          switchMap(([fieldNumber, farmId]) => this.validateFieldNumber(fieldNumber, id, farmId))
        )
        .subscribe((isValid) => {
          if (isValid) {
            fieldNumberFC.setErrors(null);
          } else {
            fieldNumberFC.setErrors({ fieldNoTaken: true });
          }
        })
    );
  }

  private getCropsAndPreCrops(farmId: number, harvestYear?: number) {
    return this.cropsRepo.getCrops(farmId, harvestYear).pipe(
      map((crops) => crops.filter((crop) => !!crop.varietyGroupNumber)),
      map((crops) => crops.filter((crop) => crop.isSelectable)),
      map((crops) =>
        crops.sort((a, b) => {
          if (a.name && b.name) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
          }

          return 0;
        })
      )
    );
  }

  private getSoilTypes() {
    return this.fieldsRepo.getSoilTypes().pipe(
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of([{} as SoilTypeDTO]);
      })
    );
  }

  private getVarieties(varietyGroupNumber: number, harvestYear?: number) {
    this.varietyIdFormControl?.disable();
    this._varietiesSelectPlaceholder$.next('main.fieldAdministration.createField.loadingVarieties');
    return this.cropsRepo.getVarietiesForCrop(harvestYear, varietyGroupNumber).pipe(
      finalize(() => {
        this._varietiesSelectPlaceholder$.next('common.cropType');
        if (this.formGroup.hasError('lockedHarvestYear')) {
          this.varietyIdFormControl?.disable();
        } else {
          this.varietyIdFormControl?.enable();
        }
      })
    );
  }

  private stopModifyingPolygons() {
    this.fieldPlanSideDrawerService.disableModifyInteraction();
  }

  private validateFieldNumber(fieldNumber: string, fieldId: number | null, farmId: number) {
    return this.isEditingField
      ? this.validateFieldNumberForExistingField(fieldNumber, fieldId, farmId)
      : this.validateFieldNumberForNewField(fieldNumber, fieldId, farmId);
  }

  private validateFieldNumberForNewField(fieldNumber: string, fieldId: number | null, farmId: number) {
    return this.selectedHarvestYear$.pipe(
      first(),
      tap(() => this._isValidatingFieldNumber$.next(true)),
      filterNullish(),
      switchMap((harvestYear) =>
        forkJoin([
          this.fieldsRepo.validateFieldNumber(farmId, harvestYear, fieldNumber, fieldId),
          this.fieldsRepo.validateFieldNumber(farmId, harvestYear - 1, fieldNumber, fieldId),
        ])
      ),
      finalize(() => this._isValidatingFieldNumber$.next(false)),
      map(([currentYear, previousYear]) => currentYear && previousYear),
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of({});
      })
    );
  }

  private validateFieldNumberForExistingField(fieldNumber: string, fieldId: number | null, farmId: number) {
    return this.selectedHarvestYear$.pipe(
      first(),
      tap(() => this._isValidatingFieldNumber$.next(true)),
      filterNullish(),
      switchMap((harvestYear) => this.fieldsRepo.validateFieldNumber(farmId, harvestYear, fieldNumber, fieldId)),
      finalize(() => this._isValidatingFieldNumber$.next(false)),
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of({});
      })
    );
  }

  private updateStoreWithField(field: Field) {
    if (this.isEditingField) {
      this.fieldPlanSideDrawerService.updateFieldInStore(field);
    } else {
      this.fieldPlanSideDrawerService.createFieldInStore(field);
    }
  }

  private get determinedSoilType$() {
    return this.drawnPolygons$.pipe(
      filter((feature) => !!feature!.fieldPolygon),
      switchMap((feature) => this.getDeterminedSoilType(feature!.fieldPolygon))
    );
  }

  private findVariety(field: Field) {
    return this.taskService
      .getTasksByField(field.farmId, field.harvestYear, field.id, {
        bustCache: true,
      })
      .pipe(
        map((tasks) => {
          return this.benchmarkDataService.findSelectedVariety(tasks);
        })
      );
  }

  private getDeterminedSoilType(feature: Feature | null) {
    return this.fieldsRepo.getDeterminedSoilSample(WKTUtil.getWktFromFeature(feature)).pipe(
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of({});
      })
    );
  }

  private getFieldAreaInHa(feature: Feature | null) {
    if (!feature) {
      return of(0);
    }

    const wktPolygon = WKTUtil.getWktFromFeature(feature);

    return this.fieldsRepo.getFieldPolygonArea(wktPolygon).pipe(
      map((area) => this.getSquareMetersToHectare(area)),
      catchError((error: HttpErrorResponse) => {
        this._isLoading$.next(false);
        return of({});
      })
    );
  }

  private getSquareMetersToHectare(number: number | null) {
    return Math.round((number! / 10000) * 100) / 100;
  }
}
