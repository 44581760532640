<div class="card-root mat-elevation-z2">
  <div class="top">
    <h5 class="card-title">{{ title }}</h5>
  </div>
  <div *ngIf="cardData !== null">
    <div class="target">
      <div *ngIf="cardData!.targetMax && cardData!.targetMin">
        <span>{{ 'grassPrognosis.target' | translate }}: {{ cardData!.targetMin }} - {{ cardData!.targetMax }}</span>
      </div>
    </div>
    <div class="val mb-15 mt-10">{{ cardData!.value | number: valueDigitsInfo }}</div>

    <div class="diff">
      <div class="arrow positive" *ngIf="difValueToFixed > 0">&#x25B2;</div>
      <div class="arrow negative" *ngIf="difValueToFixed < 0">&#x25BC;</div>
      <div class="diff-val">{{ cardData!.difValue | number: '1.0-0' }}</div>
    </div>
  </div>
</div>
