<mat-expansion-panel
  hideToggle
  [ngClass]="{ disabled: isDisabled }"
  (opened)="open()"
  (closed)="close()"
  [disabled]="isDisabled"
  #accordion
>
  <mat-expansion-panel-header [expandedHeight]="elHeight" [collapsedHeight]="elHeight">
    <ng-template *ngIf="titleTemplateRef" [ngTemplateOutlet]="titleTemplateRef"></ng-template>
    <div *ngIf="!titleTemplateRef" class="title-wrapper">
      <mat-panel-title>
        <div class="overflow-box">
          <b>{{ boldTitle }}</b
          ><span>{{ normalTitle }}</span>
          <img *ngIf="isAxel" class="axel-icon" alt="axel_icon" src="assets/images/axel/icons/aXel_icon.svg" />
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <div class="toggle-icon">
          <app-icon icon="navigate_next" [ngClass]="{ open: accordion.expanded }"></app-icon>
        </div>
      </mat-panel-description>
    </div>
  </mat-expansion-panel-header>

  <ng-container *ngIf="!templateRef">
    <ng-content></ng-content>
  </ng-container>

  <ng-template *ngIf="templateRef" matExpansionPanelContent>
    <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
  </ng-template>
</mat-expansion-panel>
