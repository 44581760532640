import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart-info',
  templateUrl: './chart-info.component.html',
  styleUrls: ['./chart-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartInfoComponent {
  @Input() public regionalAverage = 0;
  @Input() public personalAverage = 0;
  @Input() public fields = 0;
  @Input() public area = 0;
  @Input() public unit = '';
}
