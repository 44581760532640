import { UntypedFormControl } from '@angular/forms';
import { NumberValidator } from '@app/helpers/validators/number-validator';

/**
 * Validate if control value is a integer or not
 * @param control The form control to validate
 */
export function IsNotIntegerValidator(control: UntypedFormControl) {
  const isInteger = NumberValidator.isInteger(control.value);
  // ignore empty string
  if (!control.value || (<string>control.value).length === 0) {
    return null;
  }

  return isInteger ? null : { isNotInteger: true };
}
