<div class="side-drawer-wrapper">
  <app-side-drawer-header
    [title]="(task!.id ? 'taskGridItem.editTaskTitle' : 'main.cultivationJournal.fieldTasks.addTask') | translate"
    [showInfoHoverButton]="true"
    (action)="onCloseClick()"
    cancelIconName="Luk"
    [showMinimizeButton]="false"
  >
  </app-side-drawer-header>

  <app-side-drawer-body>
    <app-spinner
      [hasBackdrop]="true"
      *ngIf="savingState.isLoading$ | async"
      [message]="(savingState.message$ | async)!"
      class="flex-child flex-align-center"
    ></app-spinner>
    <app-accordion class="accordion" boldTitle="{{ 'fieldSelector.fieldsInTask' | translate }}" [isExpanded]="{ expanded: true }">
      <app-task-fields
        (onSave)="onSave($event)"
        [task]="task"
        [field]="field"
        [disabled]="isFarmLocked || isWriteDisabled"
      ></app-task-fields>
    </app-accordion>

    <app-accordion class="accordion margin-top" boldTitle="{{ 'editTask.taskInfo' | translate }}" [isExpanded]="isTaskInfoExpanded">
      <div class="card task-info">
        <form [formGroup]="taskFormGroup">
          <div class="areal-wrapper" [ngClass]="{ error: taskFormGroup.controls.area.invalid && taskFormGroup.controls.area.touched }">
            <div class="areal-label">{{ 'main.fieldmap.crops.area' | translate }}</div>
            <div class="value-wrapper" [ngClass]="{ error: taskFormGroup.controls.area.invalid && taskFormGroup.controls.area.touched }">
              <app-tooltip
                *ngIf="(this.fieldWithSameTaskCount$ | async)! > 0; else noTooltip"
                [tooltipText]="'editTask.toolTips.areaNotChangeable' | translate"
              >
                <app-input
                  formControlName="area"
                  class="areal-input"
                  type="number"
                  [simple]="true"
                  [localize]="true"
                  [testId]="'area-input'"
                ></app-input>
              </app-tooltip>
              <ng-template #noTooltip>
                <app-input
                  formControlName="area"
                  class="areal-input"
                  type="number"
                  [simple]="true"
                  [localize]="true"
                  [testId]="'area-input'"
                >
                  <app-input-error
                    disabled
                    *ngIf="taskFormGroup.controls.area.hasError('areaTooLarge') && taskFormGroup.controls.area.touched"
                    >{{ 'editTask.messages.areaTooBig' | translate }}</app-input-error
                  >
                  <app-input-error
                    disabled
                    *ngIf="taskFormGroup.controls.area.hasError('required') && taskFormGroup.controls.area.touched"
                    >{{ 'editTask.messages.areaRequired' | translate }}</app-input-error
                  >
                  <app-input-error disabled *ngIf="taskFormGroup.controls.area.hasError('number') && taskFormGroup.controls.area.touched">{{
                    'editTask.messages.numbersOnly' | translate
                  }}</app-input-error>
                  <app-input-error disabled *ngIf="taskFormGroup.controls.area.hasError('min') && taskFormGroup.controls.area.touched">{{
                    'editTask.messages.taskMin' | translate
                  }}</app-input-error>
                </app-input>
              </ng-template>
              <div class="unit">{{ 'common.hectareShort' | translate }}</div>
            </div>
          </div>
          <div class="date-wrapper">
            <div class="date-label">{{ 'main.cultivationJournal.fieldTasks.taskDate' | translate }}</div>
            <div class="date-picker">
              <app-date-picker formControlName="date" [simple]="true"></app-date-picker>
              @if (taskFormGroup.controls.date.hasError('required')) {
                <mat-error class="date-error">{{ 'editTask.messages.invalidDateFormat' | translate }}</mat-error>
              }
            </div>
          </div>
          <div class="status-wrapper">
            <div class="status-label">{{ 'main.cultivationJournal.fieldTasks.taskStatus' | translate }}</div>
            <span class="toggle-label">{{
              (taskFormGroup.controls.registered.value ? 'taskGridItem.done' : 'taskGridItem.planned') | translate
            }}</span>
            <app-tooltip [delayType]="'long'" [tooltipText]="'main.cultivationJournal.fieldTasks.registerTask' | translate">
              <mat-slide-toggle color="primary" formControlName="registered" (change)="sliderChanged($event)"></mat-slide-toggle>
            </app-tooltip>
          </div>
          <div class="comment-wrapper">
            <div class="comment-label">{{ 'editTaskHeader.comment' | translate }}</div>
            <mat-form-field>
              <textarea matInput formControlName="comment" aria-label="input-field" data-testid="comment">
                <app-input-error *ngIf="commentControl.hasError('maxlength')">{{
                  'editTask.messages.commentTooLong' | translate
                }}</app-input-error>
              </textarea>
            </mat-form-field>
          </div>
        </form>
      </div>
    </app-accordion>

    <app-accordion
      class="accordion margin-top"
      boldTitle="{{ 'editTask.productsAndActions.title' | translate }}"
      [isExpanded]="isOperationsExpanded"
    >
      <div class="card">
        <p class="error-message" *ngIf="operationsFormArray.hasError('required') && operationsFormArray.touched">
          {{ 'editTask.messages.productOrActionRequired' | translate }}
        </p>
        <div class="operation" *ngFor="let operationForm of operationsFormArray.controls">
          <app-operation
            [addTooltipText]="'main.cultivationJournal.fieldTasks.addProduct' | translate"
            [removeTooltipText]="'main.cultivationJournal.fieldTasks.removeProduct' | translate"
            [operationForm]="operationForm"
            [field]="field!"
            [task]="task!"
            (remove)="onRemoveOperation($event)"
            [disabled]="isFarmLocked"
            [canWrite]="!isWriteDisabled || operationForm.value.operationTypeGroupId === OperationTypeGroupEnum.Harvest"
          ></app-operation>
        </div>
        <div class="add-btn" *ngIf="isFarmLocked === false">
          <app-add-button
            (clicked)="onAddOperation()"
            [toolTipDelay]="'long'"
            [tooltipText]="'main.cultivationJournal.fieldTasks.addProductOrAction' | translate"
          ></app-add-button>
        </div>
      </div>
    </app-accordion>
  </app-side-drawer-body>

  <app-side-drawer-footer>
    <app-side-drawer-footer-button [disabled]="(savingState.isLoading$ | async)!" (clicked)="onSaveClick()"
      >{{ 'common.save' | translate }}
    </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</div>
