import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapService } from '@app/core/map/map.service';
import { DroneImageImportLogicService } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-import-logic.service';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { combineLatest, Subscription } from 'rxjs';
import { FieldAnalysisShownComponentEnum } from '../../field-analysis-side-drawer/field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerService } from '../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { DroneImageImportStateService } from './drone-image-import-state.service';

@Component({
  selector: 'app-drone-image-import',
  templateUrl: './drone-image-import.component.html',
  styleUrls: ['./drone-image-import.component.scss'],
  providers: [DroneImageImportLogicService, DroneImageImportStateService],
})
export class DroneImageImportComponent implements OnDestroy, OnInit {
  public readonly selectableMapLayers = [MapLayerId.DRONE_IMAGE_IMPORT];

  public get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }
  private subscription: Subscription = new Subscription();

  constructor(
    private mapService: MapService,
    private droneImageImportStateService: DroneImageImportStateService,
    private droneImageImportLogicService: DroneImageImportLogicService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService
  ) {}
  public ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.mapService.mapReady$, this.droneImageImportStateService.droneImageInformation$]).subscribe(
        ([_, droneImageInformations]) => {
          setTimeout(() => {
            this.droneImageImportLogicService.addFeaturesToMap(
              droneImageInformations.map((droneImageInformation) => droneImageInformation.featureId)
            );
            this.droneImageImportStateService.resetSelectedField();
          }, 100);
        }
      )
    );
  }

  public ngOnDestroy(): void {
    this.fieldAnalysisSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
    this.subscription.unsubscribe();
  }

  public onFieldSelect($event: any) {
    if (!$event) return;
    this.droneImageImportStateService.selectedField = +$event.featureId ?? null;
    const { number, name } = $event;

    if (!number && !name) return;
    this.droneImageImportStateService.selectedFieldName = `${number} ${name}`;
  }
}
