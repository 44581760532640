<p class="legend-title">{{ legend.title | translate }}</p>

<div class="legend-content">
  <div class="labels">
    <div class="very-high flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.veryHigh' | translate }}</div>
    </div>
    <div class="high flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.high' | translate }}</div>
    </div>
    <div class="middle flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.middle' | translate }}</div>
    </div>
    <div class="low flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.low' | translate }}</div>
    </div>
    <div class="very-low flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.veryLow' | translate }}</div>
    </div>
  </div>

  <div class="label-lines">
    <div class="very-high flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="high flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="middle flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="low flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="very-low flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
  </div>
  <div class="colors">
    <div class="legend-entry" *ngFor="let entry of legend.entries">
      <div class="color" [ngStyle]="{ 'background-color': entry.color }"></div>
    </div>
  </div>

  <div class="numbers">
    <div class="twelve">
      <div>12</div>
    </div>
    <div class="eleven">
      <div>11</div>
    </div>
    <div class="ten">
      <div>10</div>
    </div>
    <div class="nine">
      <div>9</div>
    </div>
    <div class="eight">
      <div>8</div>
    </div>
    <div class="seven">
      <div>7</div>
    </div>
    <div class="six">
      <div>6</div>
    </div>
    <div class="five">
      <div>5</div>
    </div>
    <div class="four">
      <div>4</div>
    </div>
    <div class="three">
      <div>3</div>
    </div>
    <div class="two">
      <div>2</div>
    </div>
  </div>
</div>
