import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { SoilSampleShortName } from '@app/map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-short-name.enum';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SoilSampleGroup } from './soil-sample-group.class';
import { SoilSampleGroupDTO } from './soil-sample-group.dto';
import { SoilSampleLegendEntryDTO } from './soil-sample-legend.dto';
import { SoilSampleDTO } from './soil-sample.interface';

@Injectable({
  providedIn: 'root',
})
export class SoilSamplesRepoService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService,
    private languageService: LanguageService
  ) {}

  public getSoilSampleLegend(shortName: SoilSampleShortName, entriesAmount: number): Observable<SoilSampleLegendEntryDTO[]> {
    const options: any = {
      withCredentials: true,
      params: {
        shortName,
        entriesAmount,
      },
    };

    return this.httpClient.get<SoilSampleLegendEntryDTO[]>(`${this.endpoints.foApi}/soilsamples/legend`, options);
  }

  public getSoilSampleGroups(farmIds: number[]) {
    const options = {
      params: {
        farmIds,
      },
    };

    return this.httpClient
      .get<SoilSampleGroupDTO[]>(`${this.endpoints.foApi}/v2/soilsamples/groups`, options)
      .pipe(
        map((groups) =>
          groups.map(
            (group) => new SoilSampleGroup(group, LanguageConstants.getDateFormat(this.languageService!.currentLanguage.shortKey)['L'])
          )
        )
      );
  }

  public getSoilSamples(farmIds: number[], dates: DateTime[], isValid?: boolean): Observable<SoilSampleDTO[] | null> {
    const requestBody = {
      farmIds: farmIds,
      sampleDates: dates,
      isValid: isValid,
    };
    const options = {
      withCredentials: true,
    };
    return this.httpClient.post<SoilSampleDTO[], typeof requestBody>(`${this.endpoints.foApi}/v2/soilsamples`, requestBody, options);
  }

  public getSoilSample(soilSampleId: number): Observable<SoilSampleDTO[]> {
    const options: any = {
      withCredentials: true,
    };

    return this.httpClient.get<SoilSampleDTO[]>(`${this.endpoints.foApi}/soilsamples/${soilSampleId}`, options);
  }

  public getSoilSampleTilesUrl(harvestYear: number, parameterShortName: string) {
    return `${this.endpoints.foApi}/maps/overlays/soilsamples/{z}/{x}/{y}?harvestYear=${harvestYear}&shortName=${parameterShortName}`;
  }
}
