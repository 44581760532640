import { Injectable } from '@angular/core';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { BenchmarkChartRepo } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data-repo.service';
import { BenchmarkChartData } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import uniqBy from 'lodash-es/uniqBy';
import { combineLatest, Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { BenchmarkFilters } from '../../chart-filter/chart-filter.component';
import { IYieldService } from './yield.service.interface';

@Injectable()
export class YieldService implements IYieldService {
  private selectedFarms$: Observable<Farm[]> = this.farmStateService.selectedFarms$;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly harvestYear$: Observable<number> = this.harvestYearStateService.harvestYear$;

  private subscriptions: Subscription[] = [];

  constructor(
    private fieldsRepo: FieldsRepo,
    private chartRepo: BenchmarkChartRepo,
    private notification: NotificationService,
    private messages: MessageService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private benchmarkStateService: BenchmarkStateService
  ) {}

  public initialize() {
    this.subscriptions.push(this.getHarvestYearAndFarmIdsStream().subscribe((val) => this.onHarvestYearAndFarmsChange(val)));
  }

  public destroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onHarvestYearAndFarmsChange(farmsAndYear: { farmIds: number[]; year: number }) {
    this.getCrops(farmsAndYear.farmIds, farmsAndYear.year);
  }

  public getChartData(farmIds: number[], filters: BenchmarkFilters): Observable<BenchmarkChartData> {
    return this.chartRepo.getYieldData(farmIds, filters, 3);
  }

  public getCrops(farmIds: number[], harvestYear: number): void {
    this.fieldsRepo
      .getValidFields(farmIds, harvestYear)
      .pipe(
        map((fields) => {
          // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          const crops = fields.reduce((result, field) => [...result, ...field.crops], []);

          // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          return uniqBy(crops, 'lawDK.directorateCropNormNumber');
        }),
        catchError((error) => {
          this.showGetError(error);
          return observableThrowError(error);
        })
      )
      .subscribe((crops) => {
        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        this.setCrops(crops);
      });
  }

  public showGetError(error: any) {
    this.notification.showError(this.messages.getHttpStatusMsg(error.status, this.messages.getBenchmarkMsg().getError));
  }

  public getHarvestYearAndFarmIdsStream() {
    return combineLatest([this.selectedFarms$, this.harvestYear$]).pipe(
      map(([selectedFarms, harvestYear]) => ({ farmIds: selectedFarms.map((farm) => farm.id), year: harvestYear })),
      filter((value) => value.farmIds.length > 0)
    );
  }

  public setCrops(crops: Crop[]) {
    this.benchmarkStateService.validCrops = crops;
  }
}
