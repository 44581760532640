import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EndpointsService } from '../endpoints/endpoints.service';

@Injectable({
  providedIn: 'root',
})
export class FrontPageRedirectGuard {
  constructor(private endpointService: EndpointsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isExternalUrl = route.data['redirectUrl'].startsWith('http');
    let url = isExternalUrl ? route.data['redirectUrl'] : this.endpointService.notAuthenticatedRedirectUrl;
    window.location.href = url;
    return true;
  }
}
