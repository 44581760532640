<button
  class="app-fab-button"
  mat-fab
  *ngIf="!mini"
  [color]="theme"
  [disabled]="disabled"
  [class.sm]="isSmall"
  [class.md]="isMedium"
  [class.lg]="isLarge"
>
  <app-icon [icon]="icon"></app-icon>
  <ng-content></ng-content>
</button>
