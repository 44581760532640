import { LogFields } from '@app/core/log/log-data.interface';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

export type LogType = 'Error' | 'Warning' | 'Information';

interface LogEntry {
  type: LogType;
  message: string;
  data: LogFields;
}

enum LoggerEvents {
  Flush = 1,
}

export class Logger {
  private readonly APP_FIELD = 'Application';
  private readonly ENV_FIELD = 'Environment';
  private readonly VERSION_FIELD = 'Version';
  private readonly USER_NAME_FIELD = 'UserName';
  private readonly ELAPSED_MS_FIELD = 'ElapsedMilliseconds';
  private readonly REQUEST_PATH_FIELD = 'RequestPath';
  private readonly URL_FIELD = 'Url';
  private readonly UNIQUE_URL_FIELD = 'UniqueUrl';
  private readonly HEADERS = 'Headers';
  private readonly BROWSER_FIELD = 'Browser';
  private readonly CORRELATION_ID = 'CorrelationId';
  private readonly FARMS_FIELD = 'FarmNames';

  private buffer: LogEntry[] = [];
  private flush = new Subject<LoggerEvents>();

  constructor(
    private appName: string,
    private logEndpoint: string
  ) {
    this.flush
      .pipe(
        debounceTime(5000),
        filter((event) => event === LoggerEvents.Flush)
      )
      .subscribe(() => this.flushBuffer());
  }

  public log(type: LogType, message: string, data: LogFields) {
    if (!this.appName || !this.logEndpoint) {
      return;
    }
    this.buffer.push({
      type,
      message,
      data,
    });
    this.flush.next(LoggerEvents.Flush);
  }

  private flushBuffer() {
    const data = this.buffer.splice(0);

    if (data.length === 0) {
      return;
    }

    const body = data.map((entry) => this.buildLogString(entry)).reduce((sum, entry) => (sum += entry), '');

    if (environment.isLocalhost) {
      // This is nested to make sure we always end up in here when running locally
      // as in do not && this to the above if...
      if (environment.log && environment.log.target === 'console') {
        // eslint-disable-next-line no-console
        console.log({
          body,
          data,
        });
      }
    } else {
      const xobj = new XMLHttpRequest();
      // eslint-disable-next-line no-console
      xobj.onerror = (err) => console.error(err);
      xobj.open('POST', this.logEndpoint, true);
      xobj.send(body);
    }
  }

  private buildLogString(entry: LogEntry): string {
    const index = this.buildIndexChunk();
    const body = this.buildBodyChunk(entry);

    return `${index}\n${body}\n`;
  }

  private buildIndexChunk() {
    const date = DateTime.now();
    const index = {
      index: {
        _index: `logstash-${date.toFormat('yyyy.MM.dd')}`,
        _type: 'logevent',
      },
    };

    return JSON.stringify(index);
  }

  private buildBodyChunk(entry: LogEntry) {
    const { type, message, data } = entry;
    const level = type;
    const date = DateTime.now();
    const messageTemplate = this.getMessageTemplate(data, message);
    const fields = this.getFields(data);
    const body = {
      '@timestamp': `${date.setZone('utc').toISO()}`,
      level,
      messageTemplate,
      message,
      fields,
    };

    return JSON.stringify(body);
  }

  private getMessageTemplate(data: LogFields, message: string) {
    let errorMessage = this.removeStackTrace(message);
    errorMessage = this.urlParamReplacer(errorMessage);

    const fields: string[] = [data.uniqueUrl!, errorMessage];
    const template = fields.map((field) => field).join(' - ');

    //? Removing URL for now in message template. So it is same as message, due to unique URL paths in VRA
    return errorMessage;
  }

  private removeStackTrace(message: string) {
    // Short message, no need for Stack trace check
    // No Stack trace in message.
    if (message.length < 20 || message.indexOf('Stack trace') === -1) {
      return message;
    }

    // If stacktrace is included it is from global-error-handler.service.ts
    return message.split('Stack trace')[0].trimEnd();
  }

  private urlParamReplacer(message: string) {
    // Short message or no HTTP ERROR and https in message, no need for replacement of searchParams in url
    // If HTTP ERROR is in message it is from http-client.ts
    if (message.length < 30 || (message.indexOf('HTTP ERROR https') === -1 && message.indexOf('Http failure response') === -1)) {
      return message;
    }

    // 11 ---> 'HTTP ERROR '.length
    let startIndex = 11;
    if (message.indexOf('Error message: Http failure response for') !== -1) {
      // 41 ---> 'Error message: Http failure response for '.length
      startIndex = 41;
    }

    const error = message.substring(0, startIndex);
    const status = message.slice(-6);
    const justUrl = message.slice(startIndex, -7);

    const url = new URL(justUrl);
    return (
      error +
      url.origin +
      url.pathname
        .split('/')
        .map((part) => (+part.replace(new RegExp(',', 'g'), '') > 0 ? 'replaced' : part))
        .join('/') +
      '?' +
      url.search
        .slice(1)
        .split('&')
        .map((param) => param.substring(0, param.indexOf('=')) + '=replaced')
        .join('&') +
      ' ' +
      status
    );
  }

  private getFields(data: LogFields) {
    return {
      [this.APP_FIELD]: this.appName,
      [this.ENV_FIELD]: data.environment,
      [this.VERSION_FIELD]: data.appVersion,
      [this.USER_NAME_FIELD]: data.userId,
      [this.ELAPSED_MS_FIELD]: data.elapsedTime,
      [this.REQUEST_PATH_FIELD]: data.requestPath,
      [this.CORRELATION_ID]: data.correlationId,
      [this.URL_FIELD]: data.url,
      [this.UNIQUE_URL_FIELD]: data.uniqueUrl,
      [this.HEADERS]: data.headers,
      [this.BROWSER_FIELD]: data.browser,
      [this.FARMS_FIELD]: data.farmNames,
    };
  }
}
