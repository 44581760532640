// FROM: https://stackoverflow.com/a/44669515
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | undefined | null, args: any[]): string | undefined | null {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    const ignore = args.length > 2 ? args[2] : false;

    if (ignore) return value;

    const valueWithTrail = value?.substring(0, limit) + trail;
    const isOverLimit = value && value.length > limit;

    if (isOverLimit) return valueWithTrail;
    else return value;
  }
}
