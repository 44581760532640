import { Injectable } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { FieldChangeCropResponse } from '@app/core/interfaces/FieldChangeCropResponse';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { forkJoin, Observable } from 'rxjs';
import { first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UpdateFieldDTO } from './../field-plan-side-drawer/interfaces/update-field-dto.interface';
import { FieldCopyStateService } from './field-copy-state.service';
import { FieldCopyItem } from './fieldCopyItem';
import { filterNullish } from '@app/shared/operators';

@Injectable({
  providedIn: 'root',
})
export class FieldCopyLogicService {
  constructor(
    private fieldsRepo: FieldsRepo,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private fieldCopyStateService: FieldCopyStateService
  ) {}

  public MoveFieldsToNextHarvestYear(gridData: FieldCopyItem[]): Observable<FieldChangeCropResponse[]> {
    this.fieldCopyStateService.loading = true;
    const fieldIds = gridData.map((field) => field.id);
    const fieldsToUpdate = gridData;

    return this.CopyFieldsToNextHarvestYear(fieldIds).pipe(
      filterNullish(),
      map((resultFields: Field[]) => {
        const updateFieldDTOs = fieldsToUpdate.map((field) => this.toUpdateFieldDTO(field, resultFields));
        return updateFieldDTOs;
      }),
      switchMap((dtos) => {
        return forkJoin([...this.SplitAndChangeCropTypeForFields(dtos)]);
      })
    );
  }

  public registerErrorFields(response: FieldChangeCropResponse): void {
    if (response.failedFields.length > 0) {
      this.fieldCopyStateService.addToFailedTransferedFields = response.failedFields;
    }
  }
  public SplitAndChangeCropTypeForFields(updateFieldDtos: UpdateFieldDTO[]): Observable<FieldChangeCropResponse>[] {
    const updateFieldDtosBatches = [];
    if (updateFieldDtos.length > 5) {
      while (updateFieldDtos.length > 0) {
        updateFieldDtosBatches.push(updateFieldDtos.splice(0, 5));
      }
    } else {
      updateFieldDtosBatches.push(updateFieldDtos);
    }
    return updateFieldDtosBatches.map((f) =>
      this.ChangeCropTypeForFields(f).pipe(
        filterNullish(),
        tap((response) => this.registerErrorFields(response))
      )
    );
  }

  private CopyFieldsToNextHarvestYear(fieldIds: number[]): Observable<Field[] | null> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      filterNullish(),
      withLatestFrom(this.farmStateService.selectedFarms$),
      switchMap(([harvestYear, farms]) => {
        return this.fieldsRepo.copyFieldsToNextHarvestYear(
          fieldIds,
          farms.map((farm) => farm.id),
          harvestYear
        );
      })
    );
  }

  private ChangeCropTypeForFields(updateFieldDtos: UpdateFieldDTO[]): Observable<FieldChangeCropResponse | null> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      filterNullish(),
      withLatestFrom(this.farmStateService.selectedFarms$),
      switchMap(([harvestYear, farms]) => {
        return this.fieldsRepo.changeCropTypeForFields(
          farms.map((farm) => farm.id),

          harvestYear + 1,
          updateFieldDtos
        );
      })
    );
  }

  private toUpdateFieldDTO(fieldCopyItem: FieldCopyItem, newlyCreatedFields: Field[]): UpdateFieldDTO {
    const data = fieldCopyItem.fieldData;
    const field = newlyCreatedFields.find((field) => field.number === data.number);

    return {
      farmId: data.farmId,
      id: field?.id,
      harvestYear: data.harvestYear + 1,
      number: fieldCopyItem.fieldNumber,
      name: fieldCopyItem.fieldName,
      jb: data.jb,
      geometry: data.geometry,
      cropNormNumber: fieldCopyItem.fieldCrop?.number ?? fieldCopyItem.fieldCrop?.cropNormNumber,
    } as UpdateFieldDTO;
  }
}
