<div class="header">
  <h3>{{ title }}</h3>
  <mat-checkbox
    *ngIf="multiple"
    [color]="color"
    [labelPosition]="labelPosition"
    [checked]="isAllSelected"
    [indeterminate]="isIndeterminate"
    (change)="onSelectAll()"
  >
    {{ 'common.list.selection-list.selectAll' | translate }}
  </mat-checkbox>
</div>
<ng-content></ng-content>
