import { Injectable } from '@angular/core';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { User } from '@app/core/interfaces/user.interface';
import { UserType } from '@app/core/repositories/user/user-type.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  private _userTypeSubject = new BehaviorSubject<UserType | null>(null);
  private _currentUserSubject = new BehaviorSubject<User | null>(null);
  private _subscriptionLevelSubject = new BehaviorSubject<SubscriptionLevel | null>(null);
  private _formattedSubscriptionLevelSubject = new BehaviorSubject<string | null>(null);
  private _currentUser?: User;

  public get userType$() {
    return this._userTypeSubject.asObservable();
  }

  public set userType(userType: UserType) {
    this._userTypeSubject.next(userType);
  }

  public get currentUser$() {
    return this._currentUserSubject.asObservable();
  }

  public set currentUser(currentUser: User) {
    this._currentUser = currentUser;
    this._currentUserSubject.next(currentUser);
  }

  public get subscriptionLevel$() {
    return this._subscriptionLevelSubject.asObservable();
  }

  public set subscriptionLevel(subscriptionLevel: SubscriptionLevel) {
    this._subscriptionLevelSubject.next(subscriptionLevel);
  }

  public get formattedSubscriptionLevel$() {
    return this._formattedSubscriptionLevelSubject.asObservable();
  }

  public set formattedSubscriptionLevel(formattedSubscriptionLevel: string) {
    this._formattedSubscriptionLevelSubject.next(formattedSubscriptionLevel);
  }

  public getCurrentUser() {
    return this._currentUser;
  }

  constructor() {}
}
