import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import {
  SubscriptionLevel,
  SUBSCRIPTIONLEVEL_BASIC,
  SUBSCRIPTIONLEVEL_FREE,
  SUBSCRIPTIONLEVEL_PREMIUM,
} from '@app/core/enums/subscription-level.enum';
import { SubscriptionDto } from '@app/core/repositories/subscriptions/subscriptions-repo.interface';
import { SubscriptionsRepoService } from '@app/core/repositories/subscriptions/subscriptions-repo.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CropManagerProductModules } from './crop-manager-product-Modules.enum';
import { PriceModels } from './price-model-ids.enum';
import { SubscriptionForUser, SubscriptionProducts } from './subscriptions.interface';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  private subscriptionsCache = this.cacheService.create<SubscriptionDto[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    private subscriptionsRepo: SubscriptionsRepoService,
    private translate: TranslateService,
    private cacheService: CacheService
  ) {}

  public getSubscriptions(): Observable<SubscriptionProducts> {
    const getValue = this.subscriptionsRepo.getSubscriptions();
    return this.subscriptionsCache
      .getOrSetAsync('subscriptionsRepo', () => getValue)
      .pipe(
        map((data) => {
          const subs = data.map((sub) => {
            return {
              bought: sub.bought,
              id: sub.id,
              name: this.translateName(sub.productModule, sub.priceModel, sub.subscriptionPrice, sub.name),
              subscriptionPrice: sub.subscriptionPrice,
              info: this.translateInfo(sub.productModule),
              productModule: sub.productModule,
              areaHa: sub.areaHa,
              startPrice: sub.startPrice,
              priceModel: sub.priceModel,
            };
          });
          const res: SubscriptionProducts = {
            cropManager: subs
              .filter(
                (sub) =>
                  sub.productModule === CropManagerProductModules.MarkOnlineBasic ||
                  sub.productModule === CropManagerProductModules.MarkOnlinePremium
              )
              .reverse(),
            extras: subs
              .filter(
                (sub) =>
                  sub.productModule !== CropManagerProductModules.MarkOnlineBasic &&
                  sub.productModule !== CropManagerProductModules.MarkOnlinePremium
              )
              .sort((a, b) => (a.productModule < b.productModule ? -1 : 1)),
          };
          res.cropManager.unshift({
            bought: res.cropManager.find((prod) => prod.bought) ? false : true,
            id: 'CM Basic Placeholder',
            name: this.translateId(0, 'CM Basic'),
            subscriptionPrice: 0,
            info: this.translateInfo(0),
            productModule: 0,
            areaHa: res.cropManager[0] ? res.cropManager[0].areaHa : 0,
            startPrice: 0,
            priceModel: PriceModels.AreaBased,
          });
          return res;
        })
      );
  }

  public updateSubscriptions(products: SubscriptionProducts | undefined) {
    if (!products) return;

    const subs = [
      ...products.cropManager
        .filter((prod) => prod.productModule !== 0)
        .map((prod) => {
          return {
            id: prod.id,
            productModule: prod.productModule,
            bought: prod.bought,
          };
        }),
      ...products.extras.map((prod) => {
        return {
          id: prod.id,
          productModule: prod.productModule,
          bought: prod.bought,
        };
      }),
    ];

    return this.subscriptionsRepo.setSubscriptions(subs);
  }

  public getBasicAndPremiumStartFee(products: SubscriptionProducts) {
    const premiumProduct = products.cropManager.find((item) => {
      return (
        item.productModule === CropManagerProductModules.MarkOnlinePremium ||
        item.productModule === CropManagerProductModules.MarkOnlineBasic
      );
    });

    return premiumProduct ? premiumProduct.startPrice : 0;
  }

  public getCurrentSubscriptionsForUser(userName: string): Observable<SubscriptionForUser[]> {
    return this.subscriptionsRepo.getSubscriptionsForUser(userName);
  }

  public getSubscriptionName(subscriptionLevel: SubscriptionLevel) {
    return SubscriptionLevel[subscriptionLevel];
  }

  public getCurrentSubscriptionLevelForUser(subscriptionsForUser: SubscriptionForUser[]): SubscriptionLevel {
    let userSubscriptionLevel = SUBSCRIPTIONLEVEL_FREE;

    if (subscriptionsForUser.filter((x) => x.productModule === CropManagerProductModules.MarkOnlinePremium).length > 0) {
      userSubscriptionLevel = SUBSCRIPTIONLEVEL_PREMIUM;
    } else if (subscriptionsForUser.filter((x) => x.productModule === CropManagerProductModules.MarkOnlineBasic).length > 0) {
      userSubscriptionLevel = SUBSCRIPTIONLEVEL_BASIC;
    }

    return userSubscriptionLevel;
  }

  private translateName(id: number, priceModel: number, price: number, fallback: string) {
    switch (priceModel) {
      case PriceModels.AreaBased || PriceModels.AreaBased2:
        return this.translateId(id, fallback);
      case PriceModels.FixedPrice:
        return this.translateIdWithPrice(id, price, fallback);
      default:
        return this.translateId(id, fallback);
    }
  }

  private translateId(id: number, fallback: string) {
    const key = 'subscriptions.products.p-' + id;
    const trans = this.translate.instant(key);
    return trans === key ? fallback : trans;
  }

  private translateIdWithPrice(id: number, price: number, fallback: string) {
    const key = 'subscriptions.products.p-' + id;
    const trans = this.translate.instant(key, { price: price });
    return trans === key ? fallback : trans;
  }

  private translateInfo(id: number) {
    // Add e.g. "i-0": "TEXT" to subscriptions.products in the translations file to add info fields automatically
    const key = 'subscriptions.products.i-' + id;
    const trans = this.translate.instant(key);
    return trans === key ? null : trans;
  }
}
