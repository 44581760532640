import { QueryList } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { InputErrorComponent } from './input-error/input-error.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class InputErrorStateMatcher implements ErrorStateMatcher {
  constructor(
    private errorMessageContentChildren: QueryList<InputErrorComponent> | undefined,
    private checkInstantly = false
  ) {}

  public isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const hasErrors = this.errorMessageContentChildren?.some((errorMsg) => !!errorMsg);
    const hasErrorAndNoForm = this.errorMessageContentChildren && hasErrors && !form;
    const isSubmitted = form && form.submitted;

    return !!hasErrorAndNoForm || !!(control && !!hasErrors && (this.checkInstantly || control.dirty || control.touched || isSubmitted));
  }
}
