import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { OperationType } from '@app/core/interfaces/operation.type';
import { SimpleOperationLine } from '@app/core/interfaces/simple-operation-line.interface';
import { SimpleOperation } from '@app/core/interfaces/simple-operation.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { TaskEditTemplate } from '@app/core/interfaces/task-edit-template.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { SimpleTaskService } from '@app/core/task/simple-task/simple-task.service';
import { LoadingState } from '@app/helpers/loading-state';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { FarmLockedService } from '@app/shared/farm-locked/farm-locked.service';
import { negate } from '@app/shared/operators/negate';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import * as _ from 'lodash';
import { cloneDeep, round } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { finalize, first, map, switchMap, take, tap } from 'rxjs/operators';
import { TaskCropVM } from './field-selector/task-crop-vm';
import { OperationSelectorComponent } from './operation-selector/operation-selector.component';
import { TaskFormService } from './task-form.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskComponent implements OnInit, OnDestroy {
  public field = this.sideDrawerRef.data.field;
  public task = this.sideDrawerRef.data.task;

  private _selectedCrops: Crop[] = [];
  private _cropsWithSimilarTask: Crop[] = [];
  private _allCropsWithSimilarTask: Crop[] = [];
  private _fieldWithSameTaskCount = new BehaviorSubject<number>(0);
  public taskCropVMsWithSimilarCrop: TaskCropVM[] = [];
  public newCropsWithSimilarTask: TaskCropVM[] = [];
  public fieldWithSameTaskCount$ = this._fieldWithSameTaskCount.asObservable();
  private _cropIds: number[] = [this.task.cropId];

  private _allowedArea$ = new BehaviorSubject<number | null>(null);

  public isTaskInfoExpanded = { expanded: true };
  public isOperationsExpanded = { expanded: true };

  protected isWriteDisabled = false;

  protected isFarmLocked = this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.task.farmId);

  protected writeDisabled$ = this.accessControlService.hasAccessTo('field_plan_cultivation_journal_write').pipe(
    negate(),
    tap((isDisabled) => (this.isWriteDisabled = isDisabled))
  );

  public taskFormGroup = this.taskFormService.createTaskForm(this._allowedArea$.asObservable(), this.isFarmLocked);

  public savingState = new LoadingState();

  private _subs = new SubscriptionArray();

  private _operationSideDrawerRef: SideDrawerRef<OperationSelectorComponent, SimpleTask, OperationType> | null = null;

  constructor(
    private dialogService: DialogService,
    private sideDrawerRef: SideDrawerRef<TaskComponent, { field: Field; creating: boolean; task: SimpleTask }, SimpleTask>,
    private sideDrawerService: SideDrawerOverlayService,
    private changeDetectorRef: ChangeDetectorRef,
    private taskFormService: TaskFormService,
    private notificationService: NotificationService,
    private simpleTaskService: SimpleTaskService,
    private farmStateService: FarmStateService,
    private farmLockedService: FarmLockedService,
    private accessControlService: AccessControlService
  ) {}

  public ngOnInit() {
    this._subs.add(this.writeDisabled$.subscribe());

    if (this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.task.farmId)) {
      this.farmLockedService.showCurrentHarvestYearLockedErrorForFarmId(this.task.farmId);
    }

    this._selectedCrops = this.field.crops.filter((x) => x.id === this.task.cropId);

    this.task.operations.forEach((operation) => {
      operation.operationLines.forEach((line) => {
        line.totalQuantity = this.calculateTotalQuantity(line.quantity);
      });
    });

    this.taskFormService.updateTaskForm(this.taskFormGroup, this.task, this.isFarmLocked);

    this._subs.add(
      // grab raw value to include disabled fields
      this.taskFormGroup.valueChanges.pipe(map(() => this.taskFormGroup.getRawValue())).subscribe((values) => {
        this.task = { ...this.task, ...values } as SimpleTask;
      })
    );

    this._subs.add(
      this.fieldWithSameTaskCount$.subscribe((count) => {
        if (count > 0) {
          this.taskFormGroup.controls.area.disable();
        } else {
          this.taskFormGroup.controls.area.enable();
        }
      })
    );

    if (!this.task.id) {
      this.operationsFormArray.clear();
    }

    this._allowedArea$.next(this.field.area);
    this.taskFormGroup.markAsPristine();
  }

  public get operationsFormArray() {
    return this.taskFormGroup.controls.operations;
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public onRemoveOperation(operation: SimpleOperation) {
    const index = this.task?.operations.indexOf(operation);
    if (index === -1) {
      return;
    }
    this.operationsFormArray.removeAt(index as number);
  }

  public onAddOperation() {
    this._operationSideDrawerRef = this.sideDrawerService.openCustomSideDrawer<OperationSelectorComponent, SimpleTask, OperationType>(
      OperationSelectorComponent,
      {
        width: '500px',
        maxWidth: '100vw',
        panelClass: 'edit-task-side-drawer',
        data: this.task,
        hasBackdrop: false,
      }
    );

    this._operationSideDrawerRef.onClose.subscribe((operationTypeGroup: OperationTypeGroup) => {
      if (!operationTypeGroup) {
        return;
      }
      this.addOperation(operationTypeGroup);

      if (this.sideDrawerRef.overlayRef) {
        this.restoreBackdrop(this.sideDrawerRef.overlayRef);
      }
      this._operationSideDrawerRef = null; // Clear the reference when closed
    });
  }

  public onSaveClick() {
    if (this.farmLockedService.isFarmLockedInSelectedHarvestYear(this.task!.farmId)) {
      this.farmLockedService.showCurrentHarvestYearLockedErrorForFarmId(this.task!.farmId);
      return;
    }
    if (!this.isTaskValid()) {
      this.showValidationMessages();
      return;
    }
    this.handleSideDrawerSaveClick();
  }

  public onCloseClick() {
    if (this.taskFormGroup.pristine) {
      this.sideDrawerRef.close();
      return;
    }
    this.dialogService.openDirtyCheckDialog().subscribe((result) => {
      if (result && result.isConfirmed) {
        this.sideDrawerRef.close();
      }
    });
  }

  public onSave(taskCropVMs: { cropsWithSimilarTask: TaskCropVM[]; newItems: TaskCropVM[] }) {
    const concatenated = this._selectedCrops.concat(
      taskCropVMs.cropsWithSimilarTask
        .map((cropWithSimilarTask) => cropWithSimilarTask.crop)
        .concat(taskCropVMs.newItems.map((newItem) => newItem.crop))
    );

    const tempAllCropsUniq = _.uniqWith(concatenated, (arrVal, othVal) => arrVal.fieldId === othVal.fieldId);
    this._cropIds = concatenated.map((crop) => crop.id);

    this.taskCropVMsWithSimilarCrop = taskCropVMs.cropsWithSimilarTask;
    this._cropsWithSimilarTask = this.taskCropVMsWithSimilarCrop.map((taskCropVM) => taskCropVM.crop);
    this.newCropsWithSimilarTask = taskCropVMs.newItems;
    this._fieldWithSameTaskCount.next(this._cropsWithSimilarTask.length + this.newCropsWithSimilarTask.length);

    this._allCropsWithSimilarTask = concatenated;
    this.updateTaskArea(tempAllCropsUniq);
  }

  public handleSideDrawerSaveClick() {
    if (this.task.id) {
      this.updateTasks(this.task);
    } else {
      this.saveTask(this.task, this._cropIds);
    }
  }

  private calculateTotalQuantity(quantity: number) {
    return round(quantity * Number(this.task.area.toString().replace(',', '.')), 2);
  }

  updateTaskArea(crops?: Crop[]) {
    const used = crops ? crops : this._selectedCrops;

    // Area from fields. Used for area validation
    let totalFieldsArea: number = 0;

    if (used.length > 0) {
      const mapped = used.map((a: Crop) => {
        const areaWithDot = a.area.toString().replace(',', '.');
        return Number(areaWithDot);
      });
      const sumAsTwoDecimals = mapped.reduce((a, b) => a + b).toFixed(2);
      totalFieldsArea = Number(sumAsTwoDecimals);
    }

    const correctArea = totalFieldsArea;

    this.taskFormGroup.controls.area.setValue(correctArea);
    if (this.isWriteDisabled) this.taskFormGroup.controls.area.disable();
    this._allowedArea$.next(correctArea);
    this.taskFormGroup.controls.area.updateValueAndValidity();

    this.changeDetectorRef.detectChanges();
  }

  private showValidationMessages() {
    this.taskFormGroup.markAllAsTouched();
    const isOperationsValid = this.taskFormService.isOperationsValid(this.taskFormGroup);
    const isTaskInfoValid = this.taskFormService.isTaskInfoValid(this.taskFormGroup);
    const isProductsValid = this.taskFormService.isProductsValid(this.taskFormGroup);

    if (!isOperationsValid) {
      this.isOperationsExpanded = { expanded: true };
    }

    if (!isTaskInfoValid) {
      this.isTaskInfoExpanded = { expanded: true };
    }

    if (!isTaskInfoValid || !isOperationsValid) {
      this.notificationService.showError('editTask.messages.taskInvalid');
    }

    if (!isProductsValid && isTaskInfoValid && isOperationsValid) {
      this.notificationService.showError('editTask.messages.taskProductsInvalid');
    }

    return isOperationsValid && isTaskInfoValid;
  }

  private isTaskValid() {
    const isOperationsValid = this.taskFormService.isOperationsValid(this.taskFormGroup);
    const isTaskInfoValid = this.taskFormService.isTaskInfoValid(this.taskFormGroup);
    const isProductsValid = this.taskFormService.isProductsValid(this.taskFormGroup);

    return isOperationsValid && isTaskInfoValid && isProductsValid;
  }

  public sliderChanged($event: MatSlideToggleChange) {
    if ($event.checked && this.taskFormGroup.controls.date.untouched) {
      this.setDateToToday();
    }
  }

  private setDateToToday() {
    this.taskFormGroup.controls.date.setValue(DateTime.now().setZone('utc'));
  }

  private addOperation(operationTypeGroup: OperationTypeGroup) {
    this.operationsFormArray.push(this.taskFormService.createOperationForm(operationTypeGroup, this.isFarmLocked));

    this.changeDetectorRef.detectChanges();
  }

  private saveTask(task: SimpleTask, cropIds: number[]) {
    this.savingState.start('editTask.saving');
    this.farmStateService.selectedFarms$
      .pipe(
        take(1),
        map((farms) => farms.map((farm) => farm.id)),
        switchMap((farmIds) => {
          return this.simpleTaskService.createSimpleTaskOnCrops(task, farmIds, cropIds, this.task!.harvestYear);
        }),
        finalize(() => {
          this.savingState.stop();
        })
      )
      .subscribe((res) => {
        const createdTask = res.find((t) => t.cropId === task.cropId);
        this.notificationService.showSuccess('editTask.messages.saveSuccess');
        this.sideDrawerRef.close(createdTask);
      });
  }

  private updateTasks(task: SimpleTask) {
    const upDatedTasks = {
      template: task,
      addedCrops: this.newCropsWithSimilarTask.map((newItem) => {
        return {
          farmId: newItem.crop.farmId,
          harvestYear: newItem.crop.harvestYear,
          id: newItem.crop.id,
        };
      }),
      updatedTasks: this.getTasksToUpdate(task),
    } as TaskEditTemplate;

    this.savingState.start('editTask.saving');

    this.simpleTaskService
      .updateTaskEditTemplate(upDatedTasks)
      .pipe(
        first(),
        finalize(() => this.savingState.stop())
      )
      .subscribe((res) => {
        const updatedTask = res.find((t) => t.id === task.id);
        this.notificationService.showSuccess('editTask.messages.updateSuccess');
        this.sideDrawerRef.close(updatedTask);
      });
  }

  private getTasksToUpdate(inputTask: SimpleTask): SimpleTask[] {
    const clone = cloneDeep(inputTask);

    const reduced = this._allCropsWithSimilarTask.reduce((tasks: SimpleTask[], crop) => {
      return [...tasks, ...(crop.tasks as SimpleTask[])];
    }, []);

    return reduced.map((simpleTask: SimpleTask) => {
      const originalOperationLines: SimpleOperationLine[] = simpleTask.operations.reduce(
        (operationLines: SimpleOperationLine[], operation) => [...operationLines, ...operation.operationLines],
        []
      );

      return {
        ...clone,
        id: simpleTask.id,
        farmId: simpleTask.farmId,
        cropId: simpleTask.cropId,
        area: simpleTask.area,
        operations: this.replaceOperationLineIds(clone, originalOperationLines),
      };
    });
  }

  private replaceOperationLineIds(inputTask: SimpleTask, overrideOperationLines: SimpleOperationLine[]): SimpleOperation[] {
    return inputTask.operations.map((operation) => {
      return {
        ...operation,
        operationLines: operation.operationLines.map((operationLine) => {
          if (!operationLine.produceNormNumber) {
            return operationLine;
          }

          const overrideOperationLine = overrideOperationLines.find((o) => operationLine.produceNormNumber.includes(o.produceNormNumber));
          return {
            ...operationLine,
            id: overrideOperationLine ? overrideOperationLine.id : operationLine.id,
          };
        }),
      };
    });
  }

  OperationTypeGroupEnum = OperationTypeGroupEnum;

  private restoreBackdrop(overlayRef: OverlayRef) {
    if (overlayRef.backdropElement) {
      overlayRef.backdropElement.style.display! = 'block';
    }
  }
}
