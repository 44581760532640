import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '@app/shared/input/input.component';
import { DecimalService } from '@app/shared/pipes/decimal-separator/decimal.service';

@Component({
  selector: 'app-numbers-input',
  templateUrl: './numbers-input.component.html',
  styleUrls: ['../input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumbersInputComponent),
      multi: true,
    },
  ],
})
export class NumbersInputComponent extends InputComponent {
  @Input() public maxLength?: number;
  @Input() public maxValue?: number;
  @Input() public minValue?: number;
  @Input() public clearOnEnter = false;
  @Input() public defaultValue?: string;
  @Input() public override textAlign = 'center';

  constructor(changeDetectorRef: ChangeDetectorRef, decimalService: DecimalService) {
    super(changeDetectorRef, decimalService);
  }

  public onFocus() {
    if (this.clearOnEnter && this.value && +this.value === 0) {
      this.value = '';
    }
  }

  public onFocusOut() {
    if (this.defaultValue && +this.value === 0) {
      this.onChange(this.defaultValue);
      this.value = this.defaultValue;
    }
  }
}
