import { formatNumber } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { EntryGroup } from '@app/shared/cards-or-table/interfaces/entry-group.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { GroupedEntries } from '@app/shared/cards-or-table/interfaces/grouped-entries.interface';
import { SearchService } from '@app/shared/search/search.service';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import isFunction from 'lodash-es/isFunction';
import values from 'lodash-es/values';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CardsOrTableService {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private searchService: SearchService
  ) {}

  /**
   * Will search through the given data, and regroup the entries, leaving out groups with zero results
   *
   * @param groupedEntries$
   * @param regroupProperty
   * @param searchTerm
   * @param searcher Optional - defaults to using the fulltextSearch from the SearchService
   */
  public searchGroupedDataAndRegroup$<TGroup extends EntryGroup, TEntry>(
    groupedEntries$: Observable<GroupedEntries<TGroup, TEntry>>,
    regroupProperty: keyof TEntry,
    searchTerm: string,
    searcher?: (searchTerm: string, objs: TEntry[]) => TEntry[]
  ): Observable<GroupedEntries<TGroup, TEntry>> {
    const searchFn = (term: string, objs: TEntry[]) =>
      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      isFunction(searcher) ? searcher(term, objs) : this.searchService.fulltextSearch(term, objs);

    return groupedEntries$.pipe(
      first(),
      map(({ groups, groupedEntries }) => ({
        groups,

        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        groupedEntries: this.groupData(groups, searchFn(searchTerm, [].concat(...values(groupedEntries))), regroupProperty),
      })),
      map(({ groups, groupedEntries }) => ({
        groupedEntries,
        groups: groups.filter((entry) => entry && groupedEntries[entry.id] && groupedEntries[entry.id].length > 0),
      }))
    );
  }

  /**
   *  Groups the data according to the given groups and the key by which to group the data
   *
   * @param groups$
   * @param entries$
   * @param groupProperty
   */
  public groupData$<TGroup extends EntryGroup, TEntry>(
    groups$: Observable<TGroup[]>,
    entries$: Observable<TEntry[] | { [key: string]: TEntry[] }>,
    groupProperty: keyof TEntry
  ): Observable<GroupedEntries<TGroup, TEntry>> {
    return combineLatest(groups$, entries$).pipe(
      map(([groups, entries]) => ({
        groups,

        // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        groupedEntries: this.groupData<TEntry>(groups, [].concat(...values(entries)), groupProperty),
      }))
    );
  }

  /**
   * Will map the data to new entities reflecting the field definitions given, but still
   * keeping a copy, and populating an object describing which fields that are empty, in
   * order to avoid calculating this in templates.
   *
   * @param fieldDefinitions$
   * @param rawData$
   */
  public mapData$<TOriginal extends object>(
    fieldDefinitions$: Observable<FieldDefinition<any>[]>,
    rawData$: Observable<TOriginal[]>
  ): Observable<
    {
      [key: string]: any;
      __original: TOriginal;
      __empty: {
        [key: string]: any;
      };
    }[]
  > {
    return combineLatest(fieldDefinitions$, rawData$).pipe(
      filter(([fieldDefinitions, rawData]) => fieldDefinitions && fieldDefinitions.length > 0),
      map(([fieldDefinitions, rawData]) => this.mapData(fieldDefinitions, rawData))
    );
  }

  /**
   * Given the field definitions and action definitions, return a list describing which columns
   * should be used in a table view.
   *
   * @param fieldDefinitions$
   * @param actionDefinitions$
   */
  public mapDisplayedColumns$(
    fieldDefinitions$: Observable<FieldDefinition<any>[]>,
    actionDefinitions$: Observable<ActionDefinition[]>
  ): Observable<(string | number | symbol)[]> {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return combineLatest(fieldDefinitions$, actionDefinitions$).pipe(
      map(([fieldDefinitions, actionDefinitions]) => {
        const columns = fieldDefinitions
          .filter((fieldDefinition) => !fieldDefinition.hide && !fieldDefinition.isCardAvatar)
          .map((fieldDefinition) => fieldDefinition.property);

        if (actionDefinitions.length) {
          columns.push('actions');
        }

        return columns;
      })
    );
  }

  private readValue(fieldDefinition: FieldDefinition<any>, element: any, useDefaultValue = true) {
    // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    const value = this.transformValue(fieldDefinition, get(element, fieldDefinition.property));

    if (!value) {
      return useDefaultValue ? fieldDefinition.defaultValue : value;
    }

    if (fieldDefinition.type === 'number' && isFinite(value)) {
      return formatNumber(value, this.locale);
    }

    return value;
  }

  private groupData<TEntry>(groups: EntryGroup[], entries: TEntry[], groupProperty: keyof TEntry) {
    return groups.reduce(
      (sum, group) => ({
        ...sum,
        [group.id]: entries.filter((entry) => entry[groupProperty] === group.id),
      }),
      {} as { [key: string]: TEntry[] }
    );
  }

  private mapData(fieldDefinitions: FieldDefinition<any>[], rawData: any[]) {
    return rawData.map((data) =>
      fieldDefinitions.reduce(
        (obj, current) => {
          return {
            ...data,
            ...obj,
            __empty: {
              ...obj.__empty,
              [current.property!]: !this.readValue(current, data, false),
            },
            [current.property!]: this.readValue(current, data),
          };
        },
        {
          __original: cloneDeep(data),
          __empty: {},
        }
      )
    );
  }

  private transformValue(fieldDefinition: FieldDefinition<any>, value: any) {
    if (fieldDefinition && fieldDefinition.transform && isFunction(fieldDefinition.transform)) {
      return fieldDefinition.transform(value);
    }

    return value;
  }
}
