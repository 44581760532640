import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureToggleClient } from '../model/feature-toggle-client.interface';
import { FEATURE_TOGGLE_CLIENT } from '../tokens/feature-toggle-client.token';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  private _unleashClientSubject = new ReplaySubject<FeatureToggleClient>(1);

  constructor(@Inject(FEATURE_TOGGLE_CLIENT) private client: FeatureToggleClient) {}

  public init(userId?: string) {
    if (userId) {
      this.client.updateContext({ userId });
    }
    this.client
      .start()
      .then(() => this._unleashClientSubject.next(this.client))
      .catch((err) => console.error(err));
  }

  /**
   * Get value for the given feature flag asynchronously
   * @param key Feature flag key
   */
  public get$(key: string): Observable<boolean> {
    return this._unleashClientSubject.pipe(map((client) => client.isEnabled(key)));
  }
}
