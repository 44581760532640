import { Injectable } from '@angular/core';
import { latest } from '@app/shared/constants/rxjs-constants';
import { filterEmpty } from '@app/shared/operators/filter-empty';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntities, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import { combineLatest, map, shareReplay, tap } from 'rxjs';
import { PrescriptionMap } from '../interfaces/prescription-map.interface';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

interface ValidProps {
  valid: boolean;
}

interface LoadingProps {
  loading: boolean;
}

export interface MinMax {
  prescriptionMapId: number;
  maxAmount?: number | null;
  maxAmountDefault?: number | null;
  minAmount?: number | null;
  minAmountDefault?: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class MinMaxRepository {
  constructor(private pmQuery: PrescriptionMapQuery) {}

  private readonly _store = createStore(
    { name: 'min-max' },
    withEntities<MinMax, 'prescriptionMapId'>({ idKey: 'prescriptionMapId' }),
    withProps<ValidProps>({ valid: true }),
    withProps<LoadingProps>({ loading: true })
  );

  private readonly _history = entitiesStateHistory(this._store);
  private readonly _storeHistory = stateHistory(this._store);

  public readonly minMax$ = this._store.pipe(selectAllEntities(), shareReplay(1));

  public readonly loading$ = combineLatest([this.pmQuery.loading$, this._store]).pipe(
    map(([pmLoading, { loading }]) => pmLoading || loading),
    shareReplay(latest)
  );

  public readonly hasPast$ = this._storeHistory.hasPast$.pipe(shareReplay(latest));

  public isValid$ = this._store.pipe(
    select(({ valid }) => valid),
    shareReplay(latest)
  );

  private readonly _init$ = this.pmQuery.prescriptionMapsByActiveTask$.pipe(
    filterEmpty(),
    tap(() => this._store.update((state) => ({ ...state, loading: true }))),
    map((maps) => maps.map((map) => this._createMinMax(map))),
    tap((minMax) => {
      this._setStore(minMax);
    }),
    tap(() => this._store.update((state) => ({ ...state, loading: false }))),
    tap(() => this.clearHistory()),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public update(minMax: (Partial<MinMax> & Pick<MinMax, 'prescriptionMapId'>)[]) {
    minMax.forEach((minMax) => {
      // If both are default values, set to undefined so we can differentiate between default and user set values
      if (
        minMax.maxAmount === this._store.getValue().entities[minMax.prescriptionMapId].maxAmountDefault &&
        minMax.minAmount === this._store.getValue().entities[minMax.prescriptionMapId].minAmountDefault
      ) {
        minMax.minAmount = undefined;
        minMax.maxAmount = undefined;
      }

      this._store.update(updateEntities(minMax.prescriptionMapId, minMax));
    });
  }

  public updateValidity(valid: boolean) {
    this._store.update((state) => ({ ...state, valid }));
  }

  public undo() {
    this._history.undo();
  }

  public redo() {
    this._history.redo();
  }

  public resetHistoryOnEntity(id: number) {
    this._history.jumpToPast(0, id);
  }

  public resetFieldHistory() {
    this._history.jumpToPast(0);
    this.clearHistory();
  }

  public resetHistory() {
    this._history.jumpToPast(0);
    this._storeHistory.jumpToPast(0);
    this.clearHistory();
  }

  public clearHistory() {
    this._history.clearPast();
    this._history.clearFuture();

    this._storeHistory.clear((state) => ({ ...state, past: [], future: [] }));
    this._storeHistory.clear((state) => ({ ...state, past: [], future: [] }));
  }

  public reset() {
    this._store.reset();
    this.clearHistory();
  }
  private _setStore(minMax: (Partial<MinMax> & Pick<MinMax, 'prescriptionMapId'>)[]) {
    this._store.update(setEntities(minMax));
  }

  private _createMinMax(prescriptionMap: PrescriptionMap): MinMax {
    return {
      prescriptionMapId: prescriptionMap.id,
      maxAmount: prescriptionMap.maxAmount,
      maxAmountDefault: this.toThreeDecimalPlaces(prescriptionMap.maxAmountDefault),
      minAmount: prescriptionMap.minAmount,
      minAmountDefault: this.toThreeDecimalPlaces(prescriptionMap.minAmountDefault),
    };
  }

  private toThreeDecimalPlaces(num: number | null | undefined): number | null | undefined {
    if (num === null || num === undefined) {
      return num;
    }
    return Math.round(num * 1000) / 1000;
  }
}
