import { Injectable } from '@angular/core';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { ScaleLegendItem } from '@app/core/interfaces/scale-legend-item.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapStateService } from '@app/state/services/map/map-state.service';

@Injectable()
export class FieldAnalysisFeaturesActionsService {
  constructor(private mapStateService: MapStateService) {}

  public mapCoverflowVisbilityChanged(isVisible: boolean) {
    return (this.mapStateService.mapCoverflowVisbilityChanged = isVisible);
  }

  public setMapCoverFlowItems(mapCoverFlowItems: MapCoverFlowItem[]) {
    return (this.mapStateService.mapCoverFlowItems = mapCoverFlowItems);
  }

  public selectedMapCoverFlowItemIdxChanged(idx: number | null) {
    return (this.mapStateService.selectedMapCoverFlowItemIdx = idx);
  }

  public setMapOptions(isLegendVisible: boolean, isSettingsVisible: boolean) {
    return (this.mapStateService.fieldInspectorMapOptions = {
      isLegendVisible: isLegendVisible,
      isSettingsVisible: isSettingsVisible,
    });
  }

  public setMapLoaded() {
    return (this.mapStateService.fieldMapInitialLoad = false);
  }

  public setScaleLegendTitle(scaleLegendTitle: string) {
    return (this.mapStateService.scaleLegendTitle = scaleLegendTitle);
  }

  public setScaleLegendItems(scaleLegendItems: ScaleLegendItem[]) {
    return (this.mapStateService.scaleLegendItems = scaleLegendItems);
  }

  public setScaleLegendNote(scaleLegendNote: string) {
    return (this.mapStateService.scaleLegendNote = scaleLegendNote);
  }

  public setFieldInspectorMapLegendCropColors(cropColors: CropColor[]) {
    return (this.mapStateService.fieldMapLegendCropColors = cropColors);
  }

  public setScaleLegendSummaryLines(scaleLegendSummaryLines: string[]) {
    return (this.mapStateService.scaleLegendSummaryLines = scaleLegendSummaryLines);
  }
}
