<p class="legend-title">{{ legend.title | translate }}</p>
<div class="legend-content">
  <div class="labels">
    <div class="very-high flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.veryHigh' | translate }}</div>
    </div>
    <div class="high flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.high' | translate }}</div>
    </div>
    <div class="middle flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.middle' | translate }}</div>
    </div>
    <div class="low flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.low' | translate }}</div>
    </div>
    <div class="very-low flex-center-content">
      <div class="text">{{ 'main.soilSamples.legend.veryLow' | translate }}</div>
    </div>
  </div>

  <div class="label-lines">
    <div class="very-high flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="high flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="middle flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="low flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
    <div class="very-low flex-align-center">
      <div class="range">&nbsp;</div>
    </div>
  </div>
  <div class="colors">
    <div class="legend-entry" *ngFor="let entry of legend.entries">
      <div class="color" [ngStyle]="{ 'background-color': entry.color }"></div>
    </div>
  </div>

  <div class="numbers">
    <div class="six pos-relative">6</div>
    <div class="five pos-relative">5</div>
    <div class="four pos-relative">4</div>
    <div class="three pos-relative">3</div>
    <div class="two pos-relative">2</div>
    <div class="one pos-relative">1</div>
  </div>
</div>
