<div class="header-close">
  <h4>{{ 'waterBalance.help.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <pre>{{ 'waterBalance.help.description' | translate }}</pre>
  <h5>{{ 'waterBalance.help.header1' | translate }}</h5>
  <p>{{ 'waterBalance.help.text1' | translate }}</p>
  <h5>{{ 'waterBalance.help.header2' | translate }}</h5>
  <p>{{ 'waterBalance.help.text2' | translate }}</p>
  <h5>{{ 'waterBalance.help.header3' | translate }}</h5>
  <p>{{ 'waterBalance.help.text3' | translate }}</p>
  <h5>{{ 'waterBalance.help.header4' | translate }}</h5>
  <p>{{ 'waterBalance.help.text4' | translate }}</p>
  <h5>{{ 'waterBalance.help.header5' | translate }}</h5>
  <p>{{ 'waterBalance.help.text5' | translate }}</p>
</mat-dialog-content>
