import { Injectable } from '@angular/core';
import { RemindersRepoService } from '@app/core/repositories/reminders/reminders-repo.service';
import { Reminder } from '@app/core/repositories/reminders/reminders.interface';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ReminderWindowService {
  private reminderCountSubject = new Subject<number>();
  private isLoadingSubject = new Subject<boolean>();
  private remindersLoadedSubject = new Subject<Reminder[]>();
  private remindersCount: number = 0;
  private reminders: Reminder[] = [];

  public get allReminders(): Reminder[] {
    return this.reminders;
  }

  constructor(private remindersRepoService: RemindersRepoService) {}

  private incrementRemindersCount(): void {
    this.remindersCount++;
    this.sendReminderCount(this.remindersCount);
  }

  private decrementRemindersCount(): void {
    if (this.remindersCount > 0) {
      this.remindersCount--;
      this.sendReminderCount(this.remindersCount);
    } else {
      this.clearReminderCount();
    }
  }

  private sendReminderCount(reminderCount: number) {
    this.remindersCount = reminderCount;
    this.reminderCountSubject.next(reminderCount);
  }

  public clearReminderCount() {
    this.remindersCount = 0;
    this.reminderCountSubject.next(this.remindersCount);
  }

  public setReminderReadState(reminder: Reminder) {
    this.isLoadingSubject.next(true);

    this.remindersRepoService
      .updateIsRead(reminder)
      .pipe(
        catchError((error: any) => {
          throw new Error(error);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      )
      // @ts-ignore - TS2769 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      .subscribe((updatedReminder: Reminder) => {
        if (updatedReminder.isRead === true) {
          this.decrementRemindersCount();
        } else {
          this.incrementRemindersCount();
        }
      });
  }

  public getAllReminders(): Observable<Reminder[]> {
    const startDate = DateTime.now().minus({ years: 2 });

    this.isLoadingSubject.next(true);

    const getReminders = this.remindersRepoService.getReminders(startDate).pipe(
      catchError((error: any) => {
        throw new Error(error);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );

    getReminders.pipe(first()).subscribe((reminders) => {
      const startDatesDescending = reminders.sort(
        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        (a, b) => DateTime.fromISO(b.startDate)?.toMillis() - DateTime.fromISO(a.startDate)?.toMillis()
      );
      this.reminders = startDatesDescending;
      this.sendReminderCount(reminders.filter((reminder) => reminder.isRead === false).length);
      this.remindersLoadedSubject.next(this.reminders);
    });

    return this.remindersLoadedSubject.asObservable();
  }

  public isLoading(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  public getReminderCount(): Observable<number> {
    return this.reminderCountSubject.asObservable();
  }
}
