import { NgModule } from '@angular/core';
import { FooterComponent } from '@app/layouts/footer/footer.component';
import { PartnersComponent } from '@app/layouts/footer/partners/partners.component';
import { SharedModule } from '@app/shared/shared.module';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';

@NgModule({
  imports: [SharedModule],
  exports: [FooterComponent],
  declarations: [FooterComponent, PartnersComponent, CookiePolicyComponent],
  providers: [],
})
export class FooterModule {}
