import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/shared/material/material.module';
import { FabModule } from './fab/fab.module';
import { RoundButtonComponent } from './round-button.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FabModule],
  declarations: [RoundButtonComponent],
  exports: [FabModule, RoundButtonComponent],
})
export class RoundButtonModule {}
