import { Injectable } from '@angular/core';
import { ActivatedRoute, GuardsCheckStart, Router } from '@angular/router';
import { QueryParamServiceInterface } from '@app/core/query-param/query-param.interface';
import keys from 'lodash-es/keys';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, first, map, mergeMap, pluck, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QueryParamService implements QueryParamServiceInterface {
  public static stringifyObjectValues(obj: { [key: string]: any }): { [key: string]: string } {
    return keys(obj).reduce(
      (o, key) => ({
        ...o,
        [key]: JSON.stringify(obj[key]),
      }),
      {}
    );
  }

  private routerIsReadySubject: BehaviorSubject<boolean>;
  private routerIsReady$: Observable<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.routerIsReadySubject = new BehaviorSubject(false);
    this.routerIsReady$ = this.routerIsReadySubject.asObservable().pipe(
      filter((isReady: boolean) => isReady),
      take(1)
    );
    this.router.events
      .pipe(
        filter((event) => event instanceof GuardsCheckStart),
        first()
      ) // run after guards
      .subscribe(() => {
        // because this can run before router is ready which gives no query params: https://github.com/angular/angular/issues/12157
        this.routerIsReadySubject.next(true);
      });
  }

  public getQueryParams(...queryParamKeys: string[]): Observable<any[]> {
    return combineLatest(queryParamKeys.map((key) => this.getQueryParam(key)));
  }

  public getQueryParam(queryParamKey: string): Observable<any> | undefined {
    return this.routerIsReady$.pipe(
      mergeMap(() => this.activatedRoute.queryParams),
      filter((params) => {
        return params[queryParamKey];
      }),
      map((params) => JSON.parse(params[queryParamKey]))
    );
  }

  public getQueryParamNullable(queryParamKey: string, parseJson = true): Observable<any> {
    return this.routerIsReady$.pipe(
      mergeMap(() => {
        return this.activatedRoute.queryParams;
      }),
      pluck(queryParamKey),
      map((value: string) => {
        if (parseJson && value !== undefined) {
          return JSON.parse(value);
        }
        return value;
      })
    );
  }

  public setQueryParam(queryParamKey: string, queryParamValue: unknown): any {
    this.setQueryParams({ [queryParamKey]: queryParamValue });
  }

  public setQueryParams(queryParams: { [key: string]: any }): any {
    // Because queryParams is readonly
    // because this can run before router is ready,
    // which gives no query params even if they are set: https://github.com/angular/angular/issues/12157
    this.routerIsReady$.pipe(first()).subscribe(() => this.setQueryParamsFn(queryParams));
  }

  public removeQueryParam(queryParamKey: string) {
    this.routerIsReady$.subscribe(() => {
      this.removeQueryParamsFn(queryParamKey);
    });
  }

  public clear(): void {
    this.navigate({});
  }

  private removeQueryParamsFn(queryParamKey: string) {
    const queryParams = { ...this.activatedRoute.snapshot.queryParams };

    delete queryParams[queryParamKey];
    this.navigate(queryParams);
  }

  private setQueryParamsFn(queryParams: { [key: string]: any }) {
    const params = QueryParamService.stringifyObjectValues(queryParams);

    this.navigate({
      ...this.activatedRoute.snapshot.queryParams,
      ...params,
    });
  }

  private navigate(queryParams: { [key: string]: any } = {}) {
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
      })
      .catch((err) => console.error(err));
  }
}
