import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GrowthStageConstants, GrowthStageCorrectionsInput, GrowthStageInput, GrowthStageResult } from './growthstage.interface';

@Injectable({
  providedIn: 'root',
})
export class GrowthstageRepoService {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getGrowthStageConstants(): Observable<GrowthStageConstants> {
    return this.http
      .get<GrowthStageConstants>(`${this.endpoints.foApi}/GrowthStage`)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }

  public getGrowthStages(input: GrowthStageInput): Observable<GrowthStageResult | null> {
    const options = {};

    return this.http
      .post<GrowthStageResult, GrowthStageInput>(`${this.endpoints.foApi}/GrowthStage`, input, options)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }

  public setGrowthStageCorrections(input: GrowthStageCorrectionsInput): Observable<GrowthStageResult | null> {
    const options = {};

    return this.http
      .post<GrowthStageResult, GrowthStageCorrectionsInput>(`${this.endpoints.foApi}/GrowthStage`, input, options)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }
}
