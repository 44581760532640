import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NToolsTaskStatus } from '@app/core/enums/n-tools-task-Status.enum';
import { ProduceNormNutrients } from '@app/core/enums/produce-norm-nutrients.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { NToolsTableLineDto } from '@app/core/interfaces/n-tools/n-tools-table-line-dto.interface';
import { NToolsTablePatchDto } from '@app/core/interfaces/n-tools/n-tools-table-patch-dto.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { SimpleTaskService } from '@app/core/task/simple-task/simple-task.service';
import { TaskService } from '@app/core/task/task.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { CultivationJournalStateDispatchers } from '@app/new-map/features/cultivation-journal/cultivation-journal-state-dispatchers.service';
import { FilterLogicService } from '@app/new-map/features/cultivation-journal/filter/filter-logic.service';
import { FilterStateService } from '@app/new-map/features/cultivation-journal/filter/filter-state.service';
import { Filterable } from '@app/new-map/features/cultivation-journal/filter/filterable';
import { ScopeItem } from '@app/new-map/features/cultivation-journal/filter/scope-item';
import { TaskComponent } from '@app/new-map/features/cultivation-journal/map-features/tasks/task/task.component';
import { NToolsTableRepositoryService } from '@app/new-map/features/cultivation-journal/n-tools/n-tools-table/n-tools-table-repository.service';
import { TableFilterManager } from '@app/shared/treelist-table/table-filter-manager';
import { TableSorter } from '@app/shared/treelist-table/table-sorter';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { merge } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject, ReplaySubject, Subscription, combineLatest, of, race, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { ColumnKeyNTool } from '../../filter/column/column-key-n-tool';
import { NToolsTableLine } from './models/n-tools-table-line';
import { NToolsTableSubLine } from './models/n-tools-table-sub-line';
import { ColumnSorter } from './util/ColumnSorter';

@Injectable()
export class NToolsTableService extends TableSorter<NToolsTableLine> implements OnDestroy {
  private _tableFilterManager: TableFilterManager<NToolsTableLine>;
  private _tableData = new ReplaySubject<NToolsTableLine[]>(1);
  private _expandedIds = new BehaviorSubject<number[]>([]);
  private _loading = new BehaviorSubject<boolean>(false);
  private nProduceNormsSubject = new BehaviorSubject<ProduceNorm[]>([]);
  private subscriptions = new Subscription();
  private readonly N_TOOL_SCOPE = ScopeItem.NToolTableComponent;

  public static refresh: boolean = false;

  constructor(
    private nToolsTableRepositoryService: NToolsTableRepositoryService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private produceNormsService: ProduceNormsService,
    private filterLogicService: FilterLogicService,
    private filterStateService: FilterStateService,
    private notificationService: NotificationService,
    private cultivationJournalActionDispatchers: CultivationJournalStateDispatchers,
    private simpleTaskService: SimpleTaskService,
    private sideDrawerOverlayService: SideDrawerOverlayService,
    private taskService: TaskService
  ) {
    super();
    this._tableFilterManager = new TableFilterManager(filterStateService, filterLogicService);
    this.initializeSorting();
    this.subscriptions.add(this.loadTableData());
    this.loadProduceNorms();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    // This removes all filters from the NTool scope.
    // If the filters should be used outside this scope, this should be removed, and the filters should be added without a designated scope.
    this.filterStateService.removeAllFilters(this.N_TOOL_SCOPE);
  }

  /**
   * Return filtered and sorted table data
   */
  public get tableData$(): Observable<NToolsTableLine[]> {
    return this._tableData.asObservable().pipe(
      switchMap((tableLines) => this._tableFilterManager.filterData(tableLines, this.N_TOOL_SCOPE)),
      switchMap((tableLines) => this.sortTableLines(tableLines))
    );
  }

  public get expandedIds$(): Observable<number[]> {
    return this._expandedIds.asObservable();
  }

  public set expandedIds(expandedIds: number[]) {
    this._expandedIds.next(expandedIds);
  }

  public get nProduceNorm$(): Observable<ProduceNorm[]> {
    return this.nProduceNormsSubject.asObservable();
  }

  public get loading$() {
    return this._loading.asObservable();
  }

  public set loading(loading: boolean) {
    this._loading.next(loading);
  }

  public toggleFilterItem(tableLine: NToolsTableLine, columnKey: ColumnKeyNTool) {
    this._tableFilterManager.toggleFilterItem(tableLine, columnKey, this.N_TOOL_SCOPE);
  }

  public getAvailableFilterablesForColumn$(columnKey: ColumnKeyNTool): Observable<Filterable<NToolsTableLine>[]> {
    return this._tableFilterManager.getAvailableFilterablesForColumn$(columnKey, this.N_TOOL_SCOPE);
  }

  public loadProduceNorms() {
    this.subscriptions.add(
      combineLatest([this.harvestYearStateService.harvestYear$, this.farmStateService.selectedFarmIds$])
        .pipe(
          switchMap(([harvestYear, farmIds]) =>
            this.produceNormsService.getProduceNorms(farmIds[0], harvestYear!, [ProduceNormNutrients.N])
          ),
          map((result) =>
            result
              .filter((produceNorm) => produceNorm.operationTypeId === 30 || produceNorm.operationTypeId === 40)
              .sort((a, b) => CompareHelper.compare(a.name, b.name))
          )
        )
        .subscribe((r) => {
          this.nProduceNormsSubject.next(r);
        })
    );
  }

  public update(dataItem: NToolsTableSubLine, column: ColumnKeyNTool): Observable<NToolsTableLineDto | null> {
    switch (column) {
      case ColumnKeyNTool.productAmount:
        return this.updateProductAmount(dataItem.amount, dataItem.taskId, dataItem.operationLineId, dataItem.fieldId);
      case ColumnKeyNTool.status:
        return this.updateStatus(dataItem.status, dataItem.taskId, dataItem.operationLineId, dataItem.fieldId);
      case ColumnKeyNTool.productName:
        return this.updateProduct(dataItem.productNormNumber, dataItem.taskId, dataItem.operationLineId, dataItem.fieldId);
      case ColumnKeyNTool.taskDate:
        return this.updateDate(DateTime.fromJSDate(dataItem.date), dataItem.taskId, dataItem.operationLineId, dataItem.fieldId);
      case ColumnKeyNTool.update:
        return this.forceUpdate(dataItem.taskId, dataItem.operationLineId, dataItem.fieldId);
    }

    return of(null);
  }

  public forceUpdate(taskId: number, operationLineId: number, fieldId: number): Observable<NToolsTableLineDto> {
    return this.updateTableRow({ operationLineId: operationLineId, taskId: taskId, forceUpdate: true, fieldId: fieldId });
  }

  public updateProductAmount(amount: number, taskId: number, operationLineId: number, fieldId: number): Observable<NToolsTableLineDto> {
    return this.updateTableRow({ operationLineId: operationLineId, taskId: taskId, amount: amount, fieldId: fieldId });
  }

  public updateStatus(status: NToolsTaskStatus, taskId: number, operationLineId: number, fieldId: number): Observable<NToolsTableLineDto> {
    return this.updateTableRow({ operationLineId: operationLineId, taskId: taskId, status: status, fieldId: fieldId });
  }

  public updateProduct(
    productNormNumber: string,
    taskId: number,
    operationLineId: number,
    fieldId: number
  ): Observable<NToolsTableLineDto> {
    return this.updateTableRow({
      operationLineId: operationLineId,
      taskId: taskId,
      produceNormNumber: productNormNumber,
      fieldId: fieldId,
    });
  }

  public updateDate(date: DateTime, taskId: number, operationLineId: number, fieldId: number): Observable<NToolsTableLineDto> {
    return this.updateTableRow({
      operationLineId: operationLineId,
      taskId: taskId,
      date: date.setZone('utc'),
      fieldId: fieldId,
    });
  }

  public handleUpdate(nToolTableSubLine: NToolsTableSubLine, columnKey: ColumnKeyNTool, displayToast = true) {
    this._tableData
      .pipe(
        first(),
        switchMap((nToolTableLines) => {
          this.loading = true;
          nToolTableSubLine.editable = false;

          return combineLatest([of(nToolTableLines), this.expandedIds$, this.update(nToolTableSubLine, columnKey)]).pipe(
            first(),
            finalize(() => {
              nToolTableSubLine.editable = true;
              this.loading = false;
              this._tableData.next(nToolTableLines);
              this.loadTableData();
            })
          );
        })
      )
      .subscribe(
        ([displayData, expandedIds, updatedLine]) => {
          if (!updatedLine) {
            return;
          }
          // Sets the updated lines field as the field in context for cultivationJournal
          if (expandedIds.includes(updatedLine.field.id)) {
            this.setCultivationJournalField(updatedLine.field);
          }

          const actualLine = displayData.filter((x) => x.field.id === updatedLine?.field.id)[0];

          if (actualLine) {
            updatedLine.nTotalNeed = actualLine.nTotalNeed;
          }

          const newLine = new NToolsTableLine(updatedLine, nToolTableSubLine.tableLineId);

          this._tableData.next([...displayData.filter((data) => data.field.id !== updatedLine.field.id), newLine]);
          if (displayToast) {
            this.notificationService.showSuccess('editTask.messages.updateSuccess', 1000);
          }
        },
        () => {
          if (displayToast) {
            this.notificationService.showError('editTask.messages.updateError', 1000);
          }
        }
      );
  }

  public setCultivationJournalField(field: Field) {
    this.cultivationJournalActionDispatchers.setCultivationJournalField(field);
  }

  public editTask(nToolsTableSubLine: NToolsTableSubLine) {
    this._tableData
      .pipe(
        first(),
        tap(() => (this.loading = true)),
        map((nToolTableLines) => {
          return this.findParent(nToolTableLines, nToolsTableSubLine);
        }),
        // Combine latest is used to pass on the parent value
        switchMap((parent) =>
          combineLatest([
            of(parent),
            this.simpleTaskService.getTasksByField(parent.field, parent.crop.id, { bustCache: true }).pipe(
              map((tasks: SimpleTask[]) => {
                const matchingTask = tasks.find((task) => task.id === nToolsTableSubLine.taskId);
                return merge(new SimpleTask(parent.field, parent.crop.id), matchingTask);
              })
            ),
          ])
        ),
        first(),
        switchMap(([parent, task]) => {
          const sideDrawerRef: SideDrawerRef<TaskComponent, { field: Field; task: SimpleTask }, SimpleTask> =
            this.sideDrawerOverlayService.openCustomSideDrawer<TaskComponent, { field: Field; task: SimpleTask }, SimpleTask>(
              TaskComponent,
              {
                width: '500px',
                maxWidth: '100vw',
                panelClass: 'edit-task-side-drawer',
                data: {
                  field: parent.field,
                  task: task,
                },
              }
            );

          // If the backdrop is clicked, that is handled instead of updating the task.
          return race(
            sideDrawerRef.backdropClicked.pipe(
              map(() => {
                sideDrawerRef.component.onCloseClick();
              })
            ),
            sideDrawerRef.onClose
          );
        })
      )
      .subscribe((task) => {
        if (!!task) {
          this.handleUpdate(nToolsTableSubLine, ColumnKeyNTool.update, false);
        } else {
          this.loading = false;
        }
      });
  }

  public deleteTask(nToolTableSubLine: NToolsTableSubLine) {
    this._tableData
      .pipe(
        first(),
        switchMap((nToolTableLines) => {
          this.loading = true;
          return this.taskService.deleteTask(nToolTableSubLine.taskId).pipe(
            catchError((e) => {
              this.loading = false;
              this.notificationService.showError('editTask.messages.deleteError');
              return throwError(e);
            })
          );
        })
      )
      .subscribe(() => {
        this.handleUpdate(nToolTableSubLine, ColumnKeyNTool.update);
      });
  }

  public changesHasBeenMade(formGroup: UntypedFormGroup, dataItem: NToolsTableSubLine, column: ColumnKeyNTool) {
    switch (column) {
      case ColumnKeyNTool.productName:
        return dataItem.product !== formGroup.value.product.name;
      case ColumnKeyNTool.productAmount:
        return dataItem.amount !== formGroup.value.amount;
      case ColumnKeyNTool.taskDate:
        return dataItem.date !== formGroup.value.date;
      default:
        return true;
    }
  }

  protected initializeSorting() {
    this.initializeSortingForColumn(ColumnKeyNTool.fieldNumber, (array: NToolsTableLine[], asc) =>
      array.sort((a, b) => CompareHelper.compareFieldNumbers(a.field.number, b.field.number, asc))
    );
    this.initializeSortingForColumn(ColumnKeyNTool.cropName);

    this.initializeSortingForColumn(ColumnKeyNTool.nAssigned, (array: NToolsTableLine[], asc) =>
      array.sort((a, b) => CompareHelper.compare(a.appliedN, b.appliedN, asc))
    );

    this.initializeSortingForColumn(ColumnKeyNTool.nPlanned, (array: NToolsTableLine[], asc) =>
      array.sort((a, b) => CompareHelper.compare(a.totalN, b.totalN, asc))
    );

    this.initializeSortingForColumn(ColumnKeyNTool.nNeeds, (array: NToolsTableLine[], asc) =>
      array.sort((a, b) => CompareHelper.compare(a.nTotalNeed, b.nTotalNeed, asc))
    );

    this.initializeSortingForColumn(ColumnKeyNTool.nextTreatment, (array: NToolsTableLine[], asc) =>
      array.sort((a, b) => CompareHelper.compare(a.nextTreatment?.date, b.nextTreatment?.date, asc))
    );
    this.initializeSortingForColumn(ColumnKeyNTool.nBalance, (array: NToolsTableLine[], asc) =>
      array.sort((a, b) => CompareHelper.compare(a.cropNutrientAvailability, b.cropNutrientAvailability, asc))
    );

    this.sortChange(this.getColumnSorterByKey(ColumnKeyNTool.fieldNumber) as ColumnSorter<NToolsTableLine>);
  }

  public loadTableData() {
    this.subscriptions.add(
      combineLatest([this.harvestYearStateService.harvestYear$, this.farmStateService.selectedFarmIds$])
        .pipe(
          switchMap(([harvestYear, farmIds]) => this.nToolsTableRepositoryService.get(farmIds, harvestYear!)),
          map((tableLines) => tableLines.map((tableLine) => new NToolsTableLine(tableLine)))
        )
        .subscribe((tableData) => {
          this._tableData.next(tableData);
          NToolsTableService.refresh = false;
        })
    );
  }

  private updateTableRow(updateDto: NToolsTablePatchDto): Observable<any> {
    return this.farmStateService.selectedFarmIds$.pipe(
      first(),
      switchMap((farmIds) => this.nToolsTableRepositoryService.patch(farmIds, updateDto))
    );
  }

  private findParent(nToolsTableLines: NToolsTableLine[], nToolsTableSubLine: NToolsTableSubLine): NToolsTableLine {
    return nToolsTableLines.find((data) => data.field.id === nToolsTableSubLine.fieldId) as NToolsTableLine;
  }
}
