import { animate, AnimationEvent, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { SideDrawerLayer, SideDrawerService } from '@app/shared/side-drawer/side-drawer.service';
import { VisibilityState } from '@app/shared/side-drawer/visibility-state.enum';
import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { SideDrawerConfig } from './side-drawer-config';

const ANIMATION_DELAY = 250;

export interface ISideDrawerCloseRequest {
  allow(): void;
  deny(): void;
}

@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss'],
  animations: [
    trigger('backdropVisibility', [
      transition(':enter', [style({ opacity: 0 }), animate(`${ANIMATION_DELAY}ms ease`, style({ opacity: 0.5 }))]),
      transition(':leave', [style({ opacity: 0.5 }), animate(`${ANIMATION_DELAY}ms ease`, style({ opacity: 0 }))]),
    ]),
    trigger('contentVisibility', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate(`${ANIMATION_DELAY}ms ease`, style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate(`${ANIMATION_DELAY}ms ease`, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SideDrawerComponent implements OnDestroy, OnChanges {
  @Input() public templateRef?: TemplateRef<any>;

  private _visibilityState: VisibilityState = VisibilityState.Closed;
  public get visibilityState(): VisibilityState {
    return this._visibilityState;
  }
  public set visibilityState(v: VisibilityState) {
    this._visibilityState = v;
  }

  @HostBinding('class.visible') public hostVisible = false;
  @HostBinding('style.z-index') public zIndex?: number;
  @Input() public showBackdrop = true;
  @Input() public useParentBaseWidth = false;
  @Output() public onClose = new EventEmitter();
  @Output() public onOpen = new EventEmitter();
  @Output() public onCloseRequest = new EventEmitter<ISideDrawerCloseRequest>();
  @Output() public onWidthChanged = new EventEmitter<string>();

  private minWidth = SideDrawerConfig.minWidthAsOpened;

  private _width = this.minWidth;
  public get width(): string {
    return this._width;
  }
  @Input()
  public set width(width: string) {
    this._width = width;
    this.onWidthChanged.emit(width);
  }

  public readonly defaultIndex = 900;
  public isContentVisible = true;

  private currentLayer?: SideDrawerLayer;
  private subscriptions: Subscription[] = [];

  constructor(private sideDrawerService: SideDrawerService) {
    const onOpenSubscription = this.onOpen.subscribe(() => (this.hostVisible = true));
    const onCloseSubscription = this.onClose.pipe(delay(ANIMATION_DELAY)).subscribe(() => (this.hostVisible = false));
    const onCloseRequestSubscription = this.onCloseRequest
      // We only want to trigger default implementation if no one else is observing the event
      .pipe(filter(() => this.onCloseRequest.observers.length === 1))
      .subscribe((req) => req.allow());

    this.subscriptions.push(onOpenSubscription, onCloseSubscription, onCloseRequestSubscription);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());

    if (this.currentLayer) {
      this.forceClose();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['width'] && this.currentLayer) {
      this.sideDrawerService.updateWidth(this.getCurrentLayerIndex(), changes['width'].currentValue);
      this.width = this.currentLayer.width;
    }
  }

  @HostListener('document:keydown', ['$event'])
  public keydown($event?: KeyboardEvent) {
    if ($event && $event.code !== 'Escape') {
      return;
    }

    if (!this.isVisible()) {
      return;
    }

    if (!this.sideDrawerService.isTopMostLayer(this.getCurrentLayerIndex())) {
      return;
    }

    this.close($event);
  }

  public open() {
    if (this.visibilityState === VisibilityState.Opened) {
      return;
    }

    const layer = this.sideDrawerService.push(
      {
        close: () => this.internalClose(),
      },
      this.width,
      10,
      this.useParentBaseWidth
    );

    this.currentLayer = layer;

    this.zIndex = layer.zIndex;
    this.width = layer.width;

    this.visibilityState = VisibilityState.Opened;
    this.isContentVisible = true;
    this.onOpen.emit();
  }

  public close($event?: Event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.onCloseRequest.emit({
      allow: () => this.forceClose(),
      deny: () => {},
    });
  }

  public forceClose() {
    const currentLayerIndex = this.getCurrentLayerIndex();
    this.sideDrawerService.remove(currentLayerIndex);
  }

  public backdropShouldBeTransparent = () =>
    !this.currentLayer ? false : !this.sideDrawerService.isTopMostLayer(this.getCurrentLayerIndex());

  public isVisible(): boolean {
    return this.visibilityState === VisibilityState.Opened;
  }

  public contentFinishedHiding($event: AnimationEvent) {
    if ($event.toState === 'void') {
      this.isContentVisible = false;
    }
  }

  /**
   * Closes THIS side drawer, nothing else
   */
  private internalClose() {
    this.zIndex = this.defaultIndex;
    this.visibilityState = VisibilityState.Closed;
    this.onWidthChanged.emit('0');
    this.onClose.emit();
  }

  private getCurrentLayerIndex() {
    if (!this.currentLayer) {
      // eslint-disable-next-line no-console
      console.error(new Error('Cannot find current layer index because current layer is undefined, falling back to index 0'));

      return 0;
    }

    return this.currentLayer.layerIndex;
  }
}
