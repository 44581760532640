import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { FieldCrop } from './models/field-crop';

@Injectable({
  providedIn: 'root',
})
export class BlightOverviewStateService {
  public set selectedFieldCrop(fieldCrop: FieldCrop) {
    this._selectedFieldCrop.next(fieldCrop);
  }

  public get selectedFieldCrop$(): Observable<FieldCrop> {
    return this._selectedFieldCrop.asObservable();
  }

  private _selectedFieldCrop = new ReplaySubject<FieldCrop>(1);
  constructor() {}

  public clearSelectedFieldCrop() {
    this._selectedFieldCrop.unsubscribe();
    this._selectedFieldCrop = new ReplaySubject<FieldCrop>(1);
  }
}
