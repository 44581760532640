import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface SideDrawerLayer {
  width: string;
  layerIndex: number;
  zIndex: number;
}

export interface SideDrawerLayerHandle {
  close: () => void;
}

export interface ISideDrawerService {
  push(layer: SideDrawerLayerHandle, baseWidth: string, maxCount: number): SideDrawerLayer;
  remove(layerIndex: number): void;
  isTopMostLayer(layerIndex: number): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SideDrawerService implements ISideDrawerService {
  private nextIndex = 1000;
  private visibleCount = 0;
  private count = 0;
  public sideDrawerWidthSubject = new BehaviorSubject<string>('0');
  public sideDrawerWidthChanged$ = this.sideDrawerWidthSubject.asObservable();

  private layerHandles: SideDrawerLayerHandle[] = [];
  private layers: SideDrawerLayer[] = [];

  public push(layer: SideDrawerLayerHandle, baseWidth: string = '300px', maxCount: number = 10, useParentBaseWidth: boolean = false) {
    const newLength = this.layerHandles.push(layer);
    const index = newLength - 1;
    const newLayer = this.newLayer(index, baseWidth, useParentBaseWidth, maxCount);
    this.layers.push(newLayer);

    return newLayer;
  }

  public updateWidth(layerIndex: number, newWidth: string) {
    const layer = this.layers.length >= layerIndex ? this.layers[layerIndex] : undefined;

    if (!layer) {
      return;
    }

    layer.width = newWidth;
  }

  public remove(layerIndex: number) {
    const layers = this.layerHandles.splice(layerIndex, this.layerHandles.length);
    this.layers.splice(layerIndex, this.layers.length);

    layers.forEach((layer) => this.closeLayer(layer));
  }

  public removeAll = () => this.remove(0);

  public isTopMostLayer(layerIndex: number) {
    return this.layerHandles.length - 1 === layerIndex;
  }

  private newLayer(layerIndex: number, baseWidth: string, useParentBaseWidth: boolean, maxCount: number): SideDrawerLayer {
    const count = this.increment(maxCount);

    const newLayerWidth = this.getWidthOfNewLayer(layerIndex, useParentBaseWidth, baseWidth);

    // TODO: should we stop shrinking side drawers?
    // const width = this.getWidthAccordingToLayers(newLayerWidth, maxCount, count);

    const zIndex = this.getNextIndex();

    return { width: newLayerWidth, zIndex, layerIndex };
  }

  private getWidthOfNewLayer(layerIndex: number, useParentBaseWidth: boolean, baseWidth: string) {
    if (useParentBaseWidth) {
      const layerUnderNewLayer = this.layers[layerIndex - 1];

      if (!!layerUnderNewLayer) {
        return layerUnderNewLayer.width;
      }
    }

    return baseWidth;
  }

  private closeLayer(layer: SideDrawerLayerHandle) {
    layer.close();
    this.decrement();
  }

  private getWidthAccordingToLayers(baseWidth: number, maxCount: number, count: number) {
    // We want our side drawer widths to shrink when we open more and more

    const width = baseWidth * ((maxCount - (count - 0.5)) / maxCount);

    return width;
  }

  private getNextIndex(): number {
    return this.nextIndex++;
  }

  private increment(maxCount: number) {
    if (this.visibleCount < maxCount) {
      this.visibleCount += 1;
    }

    this.count += 1;

    return this.visibleCount;
  }

  private decrement() {
    this.visibleCount -= 1;
    this.count -= 1;

    if (this.count === 0) {
      this.nextIndex = 1000;
    }

    return this.visibleCount;
  }
}
