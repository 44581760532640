/*
  Create guid
  http://guid.us/GUID/JavaScript
*/

export const createGuid = () => {
  function S4() {
    // eslint-disable-next-line no-bitwise
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  // then to call it, plus stitch in '4' in the third group
  const guid = (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();

  return guid;
};
