<div class="content">
  <div>
    <div>
      <h2 class="header">{{ translateHeader | async }}</h2>
      <p class="description">{{ translateDescription | async }}</p>
    </div>
  </div>
  <div class="buttons">
    <app-square-button [buttonStyle]="{ padding: '0 2.5em' }" [buttonType]="'Primary'" (clicked)="onConfirmClicked($event)">
      {{ 'main.fieldAdministration.createField.delete' | translate }}
    </app-square-button>
    <app-square-button [buttonStyle]="{ padding: '0 2.5em' }" [buttonType]="'Secondary'" (clicked)="onCancelClicked($event)">
      {{ 'main.fieldAdministration.createField.cancel' | translate }}
    </app-square-button>
  </div>
</div>
