import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { ClaasLog } from '@app/core/interfaces/claas-log.interface';
import { ClaasWork } from '@app/core/interfaces/claas-work.interface';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export interface IClaasWorkLogRepo {
  get(): Observable<ClaasLog[]>;
  create(claasWork: ClaasWork): Observable<ClaasLog | null>;
}

@Injectable({
  providedIn: 'root',
})
export class ClaasWorkLogRepo implements IClaasWorkLogRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public create(claasWork: ClaasWork): Observable<ClaasLog | null> {
    const claasSync: ClaasLog = {
      syncTime: DateTime.now(),
      internalId: claasWork.internalId,
      internalTaskType: claasWork.internalTaskType,
      claasUserName: claasWork.connectionId,
    };

    const options: any = {
      /* */
    };

    return this.http.post<ClaasLog, ClaasLog>(`${this.endpoints.foApi}/claasworksynclogs`, claasSync, options);
  }

  public get(): Observable<ClaasLog[]> {
    const options: any = {
      withCredentials: true,
    };
    return this.http.get<ClaasLog[]>(`${this.endpoints.foApi}/claasworksynclogs`, options);
  }
}
