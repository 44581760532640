import { CompareHelper } from '@app/helpers/compare/compare-helper';

export type SortDirection = 'asc' | 'desc';

export class ColumnSorter<T> {
  public sortDirection: SortDirection | null = null;

  private readonly sortFunction: (array: T[], asc: boolean) => T[];

  constructor(sortFunction = (array: T[], asc: boolean) => array.sort((a, b) => CompareHelper.compare(a, b, asc))) {
    this.sortFunction = sortFunction;
  }

  public next() {
    switch (this.sortDirection) {
      case null: {
        this.sortDirection = 'asc';
        break;
      }
      case 'asc': {
        this.sortDirection = 'desc';
        break;
      }
      case 'desc': {
        this.sortDirection = null;
        break;
      }
    }
  }

  private get isAscending(): boolean {
    return this.sortDirection !== 'desc';
  }

  public sort(toBeSorted: T[]): T[] {
    return [...this.sortFunction(toBeSorted, this.isAscending)];
  }
}
