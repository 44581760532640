<ng-container *ngIf="_cornFields$ | async as cornFields">
  <div *ngIf="cornFields?.length === 0">
    <div class="wrapper">
      <div class="main">
        <div class="placeholder">
          <app-icon icon="error_outline"></app-icon>
          <div>{{ 'main.cornPrognosis.legend.noCornField' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="cornFields.length > 0" class="corn-harvest-legend">
    <h3 class="corn-header">{{ 'main.cornPrognosis.legend.title' | translate }}</h3>
    <p>
      {{
        'main.cornPrognosis.legend.description'
          | translate: { numberOfCornFields: cornFields.length, fields: cornFields.length > 1 ? (language === 'da' ? 'er' : 's') : '' }
      }}
    </p>
    <table class="legend-table">
      <thead>
        <tr>
          <th>{{ 'main.cornPrognosis.legend.status' | translate }}</th>
          <th>{{ 'main.cornPrognosis.legend.fieldNo' | translate }}</th>
          <th>{{ 'main.cornPrognosis.legend.fieldArea' | translate }}</th>
          <th>{{ 'main.cornPrognosis.legend.firstHarvestDate' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tableRow of _calculatePrognoses$ | async">
          <td>
            <div
              class="maturity"
              [ngClass]="{
                red: tableRow.plotBandColor === plotBandColors.Red,
                yellow: tableRow.plotBandColor === plotBandColors.Yellow,
                green: tableRow.plotBandColor === plotBandColors.Green
              }"
              matTooltip="{{
                tableRow.plotBandColor === plotBandColors.Red
                  ? ('main.cornPrognosis.legend.tooltips.not-ready' | translate)
                  : tableRow.plotBandColor === plotBandColors.Yellow
                    ? ('main.cornPrognosis.legend.tooltips.almost-ready' | translate)
                    : ('main.cornPrognosis.legend.tooltips.ready' | translate)
              }}"
              matTooltipPosition="right"
            >
              &nbsp;
            </div>
          </td>
          <td>{{ tableRow.fieldNumber }}</td>
          <td>{{ tableRow.fieldArea | unit }}</td>
          <td>{{ tableRow.harvestDate | dateFormat }}</td>
        </tr>
      </tbody>
    </table>
    <app-spinner *ngIf="isLoading" [hasBackdrop]="false"></app-spinner>
  </div>
</ng-container>
