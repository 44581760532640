<div class="add-farm mat-elevation-z2">
  <div class="content">
    <h2>{{ 'header.farmPicker.create.title' | translate }}</h2>
    <app-input type="text" [formControl]="cvr" [placeholder]="'header.farmPicker.create.input.cvr.name' | translate">
      <app-input-error *ngIf="cvr.touched && cvr.hasError('required')">
        {{ 'header.farmPicker.create.input.cvr.validation.required' | translate }}
      </app-input-error>
      <app-input-error *ngIf="cvr.touched && cvr.hasError('hasWhitespace')">
        {{ 'header.farmPicker.create.input.cvr.validation.hasWhitespace' | translate }}
      </app-input-error>
      <app-input-error *ngIf="cvr.touched && cvr.hasError('isNotInteger')">
        {{ 'header.farmPicker.create.input.cvr.validation.isNotInteger' | translate }}
      </app-input-error>
      <app-input-error *ngIf="cvr.touched && cvr.hasError('minlength')">
        {{ 'header.farmPicker.create.input.cvr.validation.minlength' | translate }}
      </app-input-error>
      <app-input-error *ngIf="cvr.touched && cvr.hasError('maxlength')">
        {{ 'header.farmPicker.create.input.cvr.validation.maxlength' | translate }}
      </app-input-error>
    </app-input>
  </div>
  <div class="actions" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <app-square-button
      class="cancel-btn"
      [appResponsiveClass]="{ mobile: 'lt-Medium' }"
      buttonType="Primary"
      (clicked)="onCancelClicked()"
      *ngIf="!isLoading"
    >
      {{ 'common.cancel' | translate }}
    </app-square-button>
    <app-square-button buttonType="Primary" (clicked)="onConfirmClicked()" *ngIf="!isLoading" [disabled]="cvr.pristine || cvr.invalid">
      {{ 'common.create' | translate }}
    </app-square-button>
  </div>
  <app-spinner [hasBackdrop]="true" *ngIf="isLoading"></app-spinner>
</div>
