import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { Region } from '@app/core/interfaces/map-region-feature.interface';
import { MapService } from '@app/core/map/map.service';
import { RegionService } from '@app/core/region/region.service';
import { SideDrawerComponent } from '@app/shared/side-drawer/side-drawer.component';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Feature } from 'ol';
import { SelectEvent } from 'ol/interaction/Select';
import { Observable, Subscription } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { BaseMapFeature } from '../base-map-feature';

@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark.component.html',
  styleUrls: ['./benchmark.component.scss'],
})
export class BenchmarkComponent extends BaseMapFeature implements OnInit, OnDestroy {
  public readonly regions$: Observable<Region[]> = this.benchmarkStateService.regions$;
  public readonly selectedRegions$: Observable<Region[]> = this.benchmarkStateService.selectedRegions$;

  @ViewChild('sideDrawer', { static: false }) public sideDrawer?: SideDrawerComponent;

  private subscription = new Subscription();
  public selectableLayers = [MapLayerId.REGION];
  private regionFeatures!: Feature[];

  public sideDrawerWidth = '40vw';

  constructor(
    mapService: MapService,
    private globalStateService: GlobalStateService,
    private regionService: RegionService,
    private harvestYearStateService: HarvestYearStateService,
    private benchmarkStateService: BenchmarkStateService
  ) {
    super(mapService);
  }

  public ngOnDestroy(): void {
    this.closeAndDeselect();
    this.subscription.unsubscribe();
    this.map.showLayer(MapLayerId.HIGH_RES_FIELD_MARKERS);
  }

  // @ts-ignore - TS4114 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public ngOnInit() {
    this.map.cleanup();
    this.subscription.add(this.mapService.mapReady$.pipe(take(1)).subscribe(() => this.onMapReady()));
    this.subscription.add(
      this.harvestYearStateService.harvestYear$
        .pipe(
          withLatestFrom(this.getMultipleRegionsSelectedStream()),
          filter(([year, regions]) => !!regions)
        )
        .subscribe(([year, regions]) => {
          this.reselectRegions(regions);
        })
    );
  }

  public onMapReady() {
    // Fix ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    setTimeout(() => {
      super.ngOnInit();
      this.map.slideMapToPoint([10, 56], 7, true);

      this.regionService.initialize();

      this.initSubscriptions();
    });
  }

  // event handlers

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public onCloseSideDrawer($event) {
    $event.allow();
    this.closeAndDeselect();
  }

  public onRegionSelectedOnMap(event: SelectEvent) {
    event.selected.forEach((selectedFeature) => this.selectRegion(selectedFeature.getId() as number));
    event.deselected.forEach((deselectedFeature) => this.deselectRegion(deselectedFeature.getId() as number));
  }

  private reselectRegions(regions: Region[]) {
    if (this.regionFeatures) {
      const regionIds = regions.map((r) => r.id);
      const selectedFeatures = this.regionFeatures.filter((rf) => regionIds.includes(Number(rf.getId())));
      this.globalStateService.isLoading$
        .pipe(
          filter((loading) => !loading),
          take(1)
        )
        .subscribe(() => {
          setTimeout(() => {
            this.map.selectFeatures(selectedFeatures);
          });
        });
    }
  }

  private initSubscriptions() {
    this.subscription.add(this.getRegionsSubscription());
    this.subscription.add(
      this.getMultipleRegionsSelectedStream().subscribe((regions) => {
        if (!regions.length) {
          if (this.sideDrawer?.isVisible()) {
            this.sideDrawer?.close();
            this.map.slideMapToPoint([10, 56], 7, true);
          }
        } else if (!this.sideDrawer?.isVisible()) {
          this.sideDrawer?.open();
          this.map.slideMapToPoint([13, 56], 7, true);
        }
      })
    );
  }

  private getRegionsSubscription(): Subscription {
    return this.regions$.pipe(filter((regions) => regions !== undefined)).subscribe((regions) => this.onRegionsChangedInStore(regions));
  }

  private getMultipleRegionsSelectedStream = (): Observable<Region[]> =>
    this.selectedRegions$.pipe(filter((regions) => regions !== undefined));

  private closeAndDeselect() {
    this.map.slideMapToPoint([10, 56], 7, true);
    this.subscription.add(
      this.selectedRegions$.pipe(take(1)).subscribe((regions) => {
        if (regions) {
          this.setSelectedRegions([]);
          this.map.deselectFeatures();
        }
      })
    );
  }

  private onRegionsChangedInStore(regions: Region[]) {
    this.regionFeatures = regions.map((region) => {
      const feature = this.mapService.createMultiPolygonFeature(
        MapLayerId.REGION,
        this.mapService.getMultiPolygonCoordinatesFromWKTString(region.geometry)!
      );

      feature.set('fill', '#' + region.color);
      feature.set('text', region.name);
      feature.setId(region.id);

      return feature;
    });

    this.map.addOrUpdateLayerToMap(
      {
        displayName: 'regions',
        layerId: MapLayerId.REGION,
        layerType: MapLayerType.VECTOR,
        isVisible: true,
        zIndex: 6,
      },
      this.regionFeatures
    );

    this.map.hideLayer(MapLayerId.FIELDS);
    this.map.hideLayer(MapLayerId.HIGH_RES_FIELD_MARKERS);
  }

  public selectRegion(id: number) {
    this.benchmarkStateService.selectRegion = id;
  }

  public deselectRegion(id: number) {
    this.benchmarkStateService.deSelectRegion = id;
  }

  public setSelectedRegions(regions: Region[]) {
    this.benchmarkStateService.selectedRegions = regions;
  }
}
