export class PathNameHelper {
  // Returns empty string or string with / at start and end position if input is window.location.pathname.
  public static getFeatureBranchRegulatedPathName(pathName: string): string {
    let regulatedPathName = '';

    if (pathName?.length) {
      const slashCount = (pathName.match(/\//g) || []).length;
      switch (slashCount) {
        case 0:
          regulatedPathName = '';
          break;
        case 1:
          // pathName has to be slash and one more character before it is a pathName and it should not start with /bff. There are always a slash at start position
          // if input is from window.location.pathname
          regulatedPathName = pathName.length > 1 && pathName !== '/bff' ? `${pathName}` : '';
          break;
        default:
          // If more than two slashes and it should not start with /bff/. PathName is the content between the first two slashes
          regulatedPathName = !pathName.startsWith('/bff/')
            ? `${pathName.substring(0, pathName.indexOf('/', pathName.indexOf('/') + 1))}`
            : '';
          break;
      }
    }
    return `${regulatedPathName}`;
  }
}
