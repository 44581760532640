<ng-container *ngIf="tableRef; else defaultTable" [ngTemplateOutlet]="tableRef"></ng-container>

<ng-template #defaultTable>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z2"
    *ngIf="(loaded$ | async) && dataSource.data.length > 0; else noContent"
    [@reveal]
  >
    <ng-container [matColumnDef]="fieldDefinition.property!.toString()" *ngFor="let fieldDefinition of fieldDefinitions$ | async">
      <ng-container *ngIf="fieldDefinition.hide !== 'hide-on-table'">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="!fieldDefinition.sortable"
          class="task-header"
          [style.width]="fieldDefinition.tableCellWidth"
        >
          {{ fieldDefinition.name! | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="definition"
          [style.width]="fieldDefinition.tableCellWidth"
          [class.no-overflow]="fieldDefinition.tableCellWidth"
        >
          <span *ngIf="fieldDefinition.type === 'text'">{{ element[fieldDefinition.property!]?.toString() | translate }}</span>
          <span *ngIf="fieldDefinition.type === 'date'">{{ element[fieldDefinition.property!]?.toString() | dateFormat }}</span>
          <span *ngIf="fieldDefinition.type === 'statusText'">{{
            (element[fieldDefinition.property!] ? 'taskGridItem.done' : 'taskGridItem.planned') | translate
          }}</span>
          <span *ngIf="fieldDefinition.unit">{{ fieldDefinition.unit | translate }}</span>
          <span *ngIf="fieldDefinition.icon && fieldDefinition.icon.condition === element[fieldDefinition.property!]">
            <app-icon
              [icon]="fieldDefinition.icon.name"
              [style.color]="fieldDefinition.icon.color"
              [style.backgroundColor]="fieldDefinition.icon.backgroundColor"
            ></app-icon>
          </span>
        </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-menu #taskRowMenu="matMenu">
          <button
            *ngFor="let actionDefinition of actionDefinitions$ | async"
            class="task-menu-item"
            mat-menu-item
            (click)="triggerAction(element, actionDefinition, null)"
          >
            <app-icon [icon]="actionDefinition.icon"></app-icon>
            <span>{{ actionDefinition.title! | translate }}</span>
          </button>
        </mat-menu>
        <div class="waves-effect waves-circle transparent more-btn" (click)="$event.stopPropagation()">
          <app-icon icon="more_vert" [matMenuTriggerFor]="taskRowMenu"></app-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns$ | async; let index"
      (click)="triggerAction(row, { type: 'edit' }, index)"
    ></tr>
  </table>
  <ng-template #noContent>
    <div class="no-data">
      <p>{{ 'taskCards.noData' | translate }}</p>
      <p>{{ 'taskCards.addTaskGuidance' | translate }}</p>
    </div>
  </ng-template>
</ng-template>
