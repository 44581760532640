import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { max } from 'lodash-es';
import min from 'lodash-es/min';
import { combineLatest, Subscription } from 'rxjs';

/**
 * Add routes to this list, that the user cannot be on when changing to a locked harvestYear
 * Redirects to cultivation journal if this is the case.
 */
const blacklistedRoutes = ['field-plan'];

@Injectable({
  providedIn: 'root',
})
export class FarmLockedService {
  private subs = new Subscription();
  private selectedFarms: Farm[] = [];
  private selectedYear!: number;
  private lockedFarms: Farm[] = [];
  private latestLockedYear!: number;
  private earliestLockedYear!: number;
  private selectedYearLocked!: boolean;

  constructor(
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.subs.add(
      combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$]).subscribe(
        ([selectedFarms, year]) => {
          this.selectedFarms = selectedFarms;

          // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          this.selectedYear = year;
          this.setLockedStatus();
        }
      )
    );
  }

  public destroy() {
    this.subs.unsubscribe();
  }

  public isSelectedHarvestYearUnlockedForAnyOfTheSelectedFarms(): boolean {
    if (this.earliestLockedYear) {
      return this.earliestLockedYear < this.selectedYear;
    } else {
      return true;
    }
  }

  /**
   * Returns an error message based on the year the farm matching the given farmId is locked.
   * If no farm with the given farmId exists in lockedFarms, null is returned
   */
  public getFarmLockedMessageById(farmId: number): string {
    const farm = this.lockedFarms.find((f) => f.id === farmId);
    if (farm) {
      if (farm.lockedHarvestYear >= this.selectedYear) {
        return this.translateService.instant('main.fieldplan.lockedHarvestYearError', {
          year: farm.lockedHarvestYear,
          farm: farm.name,
        });
      }
    }

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return null;
  }

  public isFarmLockedInSelectedHarvestYear(farmId: number | undefined): boolean {
    return this.lockedFarms.some((farm) => farm.id === farmId && farm.lockedHarvestYear >= this.selectedYear);
  }

  public isFarmLockedInPreviousHarvestYear(farmId: number): boolean {
    return this.lockedFarms.some((farm) => farm.id === farmId && farm.lockedHarvestYear >= this.selectedYear - 1);
  }

  public isFieldsFarmLockedInSelectedYear(field: Field): boolean {
    return this.lockedFarms.some((farm) => farm.id === field.farmId && farm.lockedHarvestYear >= this.selectedYear);
  }

  public isSelectedOrPreviousHarvestYearLockedForAllOfTheSelectedFarms(): boolean {
    return this.earliestLockedYear >= this.selectedYear - 1;
  }

  public showLockedHarvestYearErrorForFarm(farmId: number) {
    const farm = this.lockedFarms.find((f) => f.id === farmId);
    if (farm) {
      if (farm.lockedHarvestYear >= this.selectedYear) {
        this.showCurrentHarvestYearLockedError(farm);
      } else {
        if (farm.lockedHarvestYear >= this.selectedYear - 1) {
          this.showPreviousHarvestYearLockedError(farm);
        }
      }
    }
  }

  public showCurrentHarvestYearLockedError(farm?: Farm) {
    if (farm && farm.name) {
      this.notificationService.showError(`
      ${this.translateService.instant('main.fieldplan.lockedHarvestYearErrorWithFarmName', {
        year: farm.lockedHarvestYear,
        farm: farm.name,
      })}`);
    } else {
      this.notificationService.showError(`
    ${this.translateService.instant('main.fieldplan.lockedHarvestYearError', { year: this.latestLockedYear })}`);
    }
  }
  public showCurrentHarvestYearLockedErrorForFarmId(farmId: number) {
    const targetFarm = this.lockedFarms.find((farm) => farm.id === farmId);
    this.notificationService.showError(`
      ${this.translateService.instant('main.fieldplan.lockedHarvestYearErrorWithFarmName', {
        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        year: targetFarm.lockedHarvestYear,

        // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        farm: targetFarm.name,
      })}`);
  }

  public showPreviousHarvestYearLockedError(farm: Farm) {
    this.notificationService.showError(`
      ${this.translateService.instant('main.fieldplan.previousHarvestYearLockedError', {
        year: farm.lockedHarvestYear,
        farm: farm.name,
      })}`);
  }

  public showFarmsLockedInSelectedHarvestYearError() {
    if (this.lockedFarms && this.lockedFarms.length > 1) {
      this.notificationService.showError(`
      ${this.translateService.instant('main.fieldplan.allFarmsLockedError')}`);
    } else {
      this.showCurrentHarvestYearLockedError(this.lockedFarms[0]);
    }
  }
  public showMissingWaterAndLockedHarvestYearError() {
    this.notificationService.showError(`
    ${this.translateService.instant('main.fieldplan.missingWaterOnLockedHarvestYear')}`);
  }

  /**
   * Event handler for checking if selected harvestYear within a locked period of the selected farms
   */
  private setLockedStatus() {
    this.lockedFarms = this.selectedFarms ? this.selectedFarms.filter((f) => !!f.lockedHarvestYear) : [];

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.earliestLockedYear = min(this.lockedFarms.map((f) => f.lockedHarvestYear));

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.latestLockedYear = max(this.lockedFarms.map((f) => f.lockedHarvestYear));

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.selectedYearLocked = this.earliestLockedYear && this.earliestLockedYear >= this.selectedYear;
    if (this.selectedYearLocked) {
      this.showErrorAndRedirect();
    }
  }

  private showErrorAndRedirect() {
    const route = this.router.url;
    if (blacklistedRoutes.some((r) => route.includes(r))) {
      this.showFarmsLockedInSelectedHarvestYearError();
      // Handle NgZone the only right way...
      setTimeout(() => {
        void this.router.navigate([''], { replaceUrl: true, queryParamsHandling: 'preserve' });
      });
    }
  }
}
