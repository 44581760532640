import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';

@Component({
  templateUrl: './scale-legend.component.html',
  selector: 'app-scale-legend',
  styleUrls: ['./scale-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleLegendComponent implements OnInit {
  @Input()
  public settings: ScaleLegendSettings = {
    items: [],
    note: '',
    summaryLines: [],
    title: '',
    visible: false,
    unit: '',
  };

  public isSummaryVisible = false;

  public ngOnInit() {
    this.isSummaryVisible = !!this.settings.operationTypeGroupId;
  }
}
