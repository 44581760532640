import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { HotspotsService } from '../../hotspots.service';
import { ShownComponentEnum } from '../hotspots-sidedrawer-showncomponent.enum';
import { HotspotGroup } from '../interfaces/hotspot-group.interface';
import { HotspotGroupsService } from './hotspot-groups.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Component({
  selector: 'app-hotspot-groups',
  templateUrl: './hotspot-groups.component.html',
  styleUrls: ['./hotspot-groups.component.scss'],
})
export class HotspotGroupsComponent implements OnInit, OnDestroy {
  public farmControl = new UntypedFormControl();
  private subs: Subscription[] = [];
  public groups: HotspotGroup[] = [];

  public farms: Farm[] = [];
  public selectedFarm!: Farm | null;

  public loading = false;

  public buttonDisabled = false;

  constructor(
    private notification: NotificationService,
    private hotspotGroupService: HotspotGroupsService,
    private hotspotsMapSideDrawerService: HotspotsService,
    private farmStateService: FarmStateService
  ) {}

  public ngOnInit() {
    this.loading = true;
    this.subs.push(
      this.farmStateService.selectedFarms$.subscribe((farms) => {
        this.farms = farms;
        this.selectedFarm = farms[0];
        this.farmControl.setValue(this.selectedFarm);
        this.hotspotGroupService
          .getGroups(this.selectedFarm.id)
          .pipe(
            take(1),
            finalize(() => (this.loading = false))
          )
          .subscribe((groups) => {
            this.groups = groups;
          });
      }),
      this.farmControl.valueChanges.subscribe((v) => this.selectedFarmChanged(v))
    );
  }

  public ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public selectedFarmChanged(farm: Farm) {
    this.loading = true;
    this.selectedFarm = farm;
    this.hotspotGroupService
      .getGroups(this.selectedFarm.id)
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe((groups) => {
        this.groups = groups;
      });
  }

  public closeClicked() {
    this.hotspotsMapSideDrawerService.setShownComponentState(ShownComponentEnum.createHotspotComponent);
  }

  public addGroup() {
    if (!this.buttonDisabled) {
      this.buttonDisabled = true;
      const name = this.hotspotGroupService.generateUniqeGroupName(this.groups);
      const toAdd = {
        name,
        farmId: this.selectedFarm?.id,
      } as HotspotGroup;

      this.hotspotGroupService.createGroups(this.selectedFarm!.id, [toAdd]).subscribe((res) => {
        if (res) this.groups.push(res[0]);
        this.notification.showCreated('main.hotspots.group');
        this.buttonDisabled = false;
      });
    }
  }

  public deleteGroup(index: number) {
    if (!this.buttonDisabled) {
      this.buttonDisabled = true;
      this.hotspotGroupService.deleteGroup(this.selectedFarm!.id, this.groups[index]).subscribe((res) => {
        this.groups.splice(index, 1);
        this.notification.showDeleted('main.hotspots.group');
        this.buttonDisabled = false;
      });
    }
  }

  public nameChanged(g: { name: string; index: number }) {
    this.hotspotGroupService
      .updateGroups(this.selectedFarm!.id, [
        {
          ...this.groups[g.index],
          name: g.name,
        },
      ])
      .subscribe((res) => {
        // Write the updated value to groups array
        if (res) this.groups[g.index] = res[0];
        this.notification.showUpdated('main.hotspots.group');
      });
  }
}
