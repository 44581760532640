import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Field } from '@app/core/interfaces/field.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { TaskEditTemplate } from '@app/core/interfaces/task-edit-template.interface';
import { ErrorCodes } from '@app/error-codes.enum';
import { filterNullish } from '@app/shared/operators';
import { isString } from 'lodash-es';
import { DateTime } from 'luxon';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ISimpleTasksRepo {
  createSimpleTaskOnCrops(simpleTask: SimpleTask, farmIds: number[], cropIds: number[], harvestYear: number): Observable<SimpleTask[]>;
  updateSimpleTasks(simpleTasks: SimpleTask[]): Observable<SimpleTask[]>;
  updateTaskEditTemplate(taskEditTemplate: TaskEditTemplate): Observable<SimpleTask[]>;
  getTasksByField(field: Field): Observable<SimpleTask[]>;
}

const handleError = (response: HttpErrorResponse) => {
  if (response.status) {
    const error = response.error ? response.error : null;
    if (error && error.message && isString(error.message) && error.message.toLowerCase().indexOf('dublet') > -1) {
      return throwError({
        code: ErrorCodes.Duplicate,
        text: error,
      });
    }
    return throwError(response);
  } else {
    return throwError(response);
  }
};

@Injectable({
  providedIn: 'root',
})
export class SimpleTaskRepo implements ISimpleTasksRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public createSimpleTaskOnCrops(
    simpleTask: SimpleTask,
    farmIds: number[],
    cropIds: number[],
    harvestYear: number
  ): Observable<SimpleTask[]> {
    const simpleTaskWithCorrectDate: SimpleTask = { ...simpleTask, date: simpleTask.date.setZone('utc', { keepLocalTime: true }) };

    return this.http
      .post<
        SimpleTask[],
        { simpleTask: SimpleTask; cropIds: number[] }
      >(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/tasks`, { simpleTask: simpleTaskWithCorrectDate, cropIds })
      .pipe(
        catchError((err) => handleError(err)),
        filterNullish(),
        map((res) => res.map((task) => ({ ...task, date: DateTime.fromISO(task.date as unknown as string) })))
      );
  }

  public updateSimpleTasks(simpleTasks: SimpleTask[]): Observable<SimpleTask[]> {
    const updatedTasksWithCorrectDate = simpleTasks.map(
      (task) => ({ ...task, date: task.date.setZone('utc', { keepLocalTime: true }) }) as SimpleTask
    );

    const templateWithCorrectDate: TaskEditTemplate = {
      addedCrops: [],
      template: updatedTasksWithCorrectDate.first()!,
      updatedTasks: updatedTasksWithCorrectDate,
    };
    return this.http
      .put<SimpleTask[], TaskEditTemplate>(`${this.endpoints.foApi}/farms/fields/crops/updatetasks`, templateWithCorrectDate)
      .pipe(
        catchError((err) => handleError(err)),
        filterNullish(),
        map((res) => res.map((task) => ({ ...task, date: DateTime.fromISO(task.date as unknown as string) })))
      );
  }

  public updateTaskEditTemplate(taskEditTemplate: TaskEditTemplate): Observable<SimpleTask[]> {
    const options = {
      withCredentials: true,
    };

    // make sure dates are converted to UTC time zone
    const updatedTemplate: SimpleTask = {
      ...taskEditTemplate.template,
      date: taskEditTemplate.template.date.setZone('utc', { keepLocalTime: true }),
    };
    const updatedTasks = taskEditTemplate.updatedTasks.map((task) => ({
      ...task,
      date: task.date.setZone('utc', { keepLocalTime: true }),
    }));

    const templateWithCorrectDate: TaskEditTemplate = {
      ...taskEditTemplate,
      template: updatedTemplate,
      updatedTasks: updatedTasks,
    };

    return this.http
      .put<SimpleTask[], TaskEditTemplate>(`${this.endpoints.foApi}/farms/fields/crops/updatetasks`, templateWithCorrectDate, options)
      .pipe(
        catchError((err) => handleError(err)),
        filterNullish(),
        map((res) => res.map((task) => ({ ...task, date: DateTime.fromISO(task.date as unknown as string) })))
      );
  }

  public getTasksByField(field: Field): Observable<SimpleTask[]> {
    return this.http.get(`${this.endpoints.foApi}/farms/${[field.farmId]}/${field.harvestYear}/fields/${field.id}/crops/simpletasks`);
  }
}
