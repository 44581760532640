<div class="farm-card mat-elevation-z2" [ngClass]="{ selected: isSelected }">
  <div class="farm-image-container" [style.background-image]="farmLogoUrl" [ngClass]="{ 'default-img': !farm.imageUrl }"></div>
  <div class="seges-card-content">
    <div class="seges-card-title">
      {{ farm.name }}
    </div>
    <div class="seges-card-text">
      {{ farm.address1 }}
    </div>
    <div class="seges-card-text">
      {{ farm.address2 }}
    </div>
    <div class="seges-card-text">
      {{ farm.address3 }}
    </div>
    <div class="seges-card-text">{{ farm.postalcode }} {{ farm.postalDistrict }}</div>

    <div class="seges-card-info">
      <div class="seges-card-info-row">
        <span>{{ 'header.farmPicker.grid.telephone' | translate }}</span>
        <span *ngIf="farm.telephoneNo === ''">{{ 'header.farmPicker.notAvailable' | translate }}</span>
        <span *ngIf="farm.telephoneNo !== ''">{{ farm.telephoneNo }}</span>
      </div>
      <div class="seges-card-info-row">
        <span>{{ 'header.farmPicker.grid.cvr' | translate }}</span>
        <span *ngIf="farm.cvr === null">{{ 'header.farmPicker.notAvailable' | translate }}</span>
        <span *ngIf="farm.cvr !== null">{{ farm.cvr }}</span>
      </div>
    </div>

    <a
      class="edit-farm-button mat-elevation-z2"
      (click)="onEditFarmClicked($event)"
      [appResponsiveClass]="{ mobile: 'lt-Medium' }"
      appGTagEventTracker
      eventName="editFarm (click)"
      eventCategory="Administration"
    >
      <app-icon icon="edit_outline"></app-icon>
    </a>
  </div>
</div>
