<div class="hotspot-group-main-wrapper">
  <app-side-drawer-header
    title="{{ 'main.hotspots.createGroup' | translate }}"
    [showInfoHoverButton]="true"
    (action)="closeClicked()"
    cancelIconName="back"
  >
  </app-side-drawer-header>
  <app-spinner *ngIf="loading" [hasBackdrop]="true"></app-spinner>
  <app-side-drawer-body #createHotspotDrawer>
    <div class="hotspot-group-header-body">
      <p class="description">{{ 'main.hotspots.createGroupDescription' | translate }}</p>
      <div *ngIf="farms.length > 1" class="select">
        <app-select [formControl]="farmControl" placeholder="{{ 'main.shapeFileImport.farmSelector.selectFarm' | translate }}">
          <app-select-option *ngFor="let farm of farms" [value]="farm">
            <div>{{ farm.name }}</div>
          </app-select-option>
        </app-select>
      </div>
    </div>
    <div class="hotspot-group-body">
      <app-group-list [groups]="groups" (deleteGroup)="deleteGroup($event)" (nameChanged)="nameChanged($event)"> </app-group-list>
      <app-list-button
        class="add-btn"
        buttonType="Add"
        (clicked)="addGroup()"
        [buttonStyle]="{ color: '#43b05c', 'font-size': '3rem' }"
      ></app-list-button>
    </div>
  </app-side-drawer-body>
</div>
