import { Injectable } from '@angular/core';
import { ProduceNormUnits } from '@app/core/enums/produce-norm-units.enum';
import { QualityParameterNormNumbers } from '@app/core/enums/quality-parameter-norm-numbers.enum';
import { TransferStatus } from '@app/core/enums/transfer-status.enum';
import { ClaasField } from '@app/core/interfaces/claas-field.interface';
import { ClaasLog } from '@app/core/interfaces/claas-log.interface';
import { ClaasWork } from '@app/core/interfaces/claas-work.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { OperationLine } from '@app/core/interfaces/operation-line.interface';
import { Operation } from '@app/core/interfaces/operation.interface';
import { Task } from '@app/core/interfaces/task.interface';
import { ClaasWorkLogRepo } from '@app/core/repositories/claas-work-log/claas-work-log-repo.service';
import { DataConnectionSetting } from '@app/core/repositories/data-connections/interfaces/data-connection-setting.interface';
import { DataExchangeRepo } from '@app/core/repositories/data-exchange/data-exchange-repo.service';
import { TasksRepo } from '@app/core/repositories/tasks/tasks-repo.service';
import { convertToHkg, convertToKg, convertToTons } from '@app/helpers/convert/units.helper';
import { UserSelector } from '@app/state/selectors/user.selector.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DatamanagementService {
  constructor(
    private dataExchangeRepo: DataExchangeRepo,
    private tasksRepo: TasksRepo,
    private claasWorkLogRepo: ClaasWorkLogRepo,
    private translate: TranslateService,
    private userSelector: UserSelector
  ) {}

  public transferField(field: Field, farmName: string, settings: DataConnectionSetting): Observable<ClaasField> {
    return this.userSelector.currentUser$.pipe(
      map((currentUser) => {
        const claasField: ClaasField = {
          harvestYear: field.harvestYear,
          area: field.area,
          externalFarmId: field.farmId,
          externalId: `${currentUser!.username}-${field.id}`,
          farmName,
          geometry: field.geometry,
          name: field.name,
        };
        return claasField;
      }),
      switchMap((claasField) => {
        return combineLatest([of(claasField), this.dataExchangeRepo.saveField(field, settings, claasField)]);
      }),
      map(([claasField, result]) => {
        return claasField;
      })
    );
  }

  public transferTask(work: ClaasWork): Observable<Task | null> {
    const task: Task | null = this.convertToFoTask(work);
    work.transferStatus = TransferStatus.TRANSFERING;

    return this.tasksRepo.createTask(task!);
  }

  public logClaasWork(work: ClaasWork): Observable<ClaasLog | null> {
    return this.claasWorkLogRepo.create(work).pipe(catchError((error: string) => throwError(error)));
  }

  /**
   * Checks if a field is transferable to the data exchange gateway.
   * @param field {Field} The field to check.
   */
  public isFieldTransferable(field: Field): boolean {
    return !!field.geometry && !!field.crops.length;
  }

  /**
   * Checks if a work is transferable (and convertable) to Field Online.
   * @param work {ClaasWork} The work to check.
   */
  public isWorkTransferable(work: ClaasWork): boolean {
    return !!work.productId && !!work.cropId && !!work.externalFarmId && !!work.area;
  }

  /**
   * Returns a transfer status string for a given field.
   * Compares a set of external fields with the given field.
   * Currently only supports Claas as external field service.
   * @param field {Field} Field to compare.
   * @param externalFields {Array<ClaasField>} The set of external fields to compare with.
   */
  public getFieldTransferStatus(field: Field, externalFields: Array<ClaasField>): string {
    let errorString: string;

    // We shall handle two types of externalIds from Claas, because we have changed how we generate externalId
    // 1: externalId = field.id (old version)
    // 2: externalId = `${currentUser.username}-${field.id}` (new version)
    if (externalFields?.length) {
      for (const extField of externalFields) {
        // we are checking if externalId starts with AgroId
        const splittedExternalId = extField.externalId.split('-');
        const externalIdStartsWithAgroId = splittedExternalId.length > 1;
        // Match if new version of exterNalId. Agroid is removed from ExternalId
        if (externalIdStartsWithAgroId) {
          // index 0 is AgroId and index 1 is fieldId. We only compare fieldId
          if (splittedExternalId[1] === field.id.toString()) {
            return this.translate.instant('main.datamanagement.fieldTransfer.statusMessages.transfered');
          }
        } else {
          // Match if old version of externalId
          if (extField.externalId === field.id.toString()) {
            return this.translate.instant('main.datamanagement.fieldTransfer.statusMessages.transfered');
          }
        }
      }
    }
    errorString = this.translate.instant('main.datamanagement.fieldTransfer.statusMessages.notTransfered');

    if (!field.geometry) {
      errorString += this.translate.instant('main.datamanagement.fieldTransfer.statusMessages.missingGeometry');
    }

    if (!field.crops.length) {
      errorString += this.translate.instant('main.datamanagement.fieldTransfer.statusMessages.missingCrop');
    }

    return errorString;
  }

  /**
   * Convert Claas Task to Field Online Task.
   * @param claasTask The Claas task.
   */
  public convertToFoTask(claasTask: ClaasWork): Task | null {
    const foTask = {} as Task;
    const startTime = DateTime.fromISO(claasTask.startTime);

    foTask.date = startTime;
    foTask.harvestYear = startTime.year;
    foTask.farmId = Number(claasTask.externalFarmId);
    foTask.cropId = claasTask.cropId;
    foTask.area = this.getArea(claasTask).toString();
    foTask.registered = true;

    if (!foTask.operations) {
      foTask.operations = [];
    }

    foTask.operations.push(this.createOperation(foTask, claasTask));

    return foTask;
  }

  /**
   * Get Area.
   * @param claasTask The Claas task.
   */
  public getArea(claasTask: ClaasWork): number {
    return Number(claasTask.crop.area) < claasTask.area ? Number(claasTask.crop.area) : claasTask.area;
  }

  /**
   * Create operation from Field Online Task with data from Claas task.
   * @param foTask The Field Online task.
   * @param claasTask The Claas task.
   */
  public createOperation(foTask: Task, claasTask: ClaasWork): Operation {
    const op: Operation = {
      id: undefined,
      farmId: foTask.farmId,
      harvestYear: foTask.harvestYear,
      index: 1,

      issues: undefined,
      operationTypeId: 100,
      taskId: foTask.id,
      operationLines: [this.createOperationLine(foTask, claasTask)],
    };

    return op;
  }

  /**
   * Create operation line from Field Online Task with data from Claas task.
   * @param foTask The Field Online task.
   * @param claasWork The Claas task.
   */
  public createOperationLine(foTask: Task, claasWork: ClaasWork): OperationLine {
    const opLine: OperationLine = {
      id: undefined,
      comment: 'Fra Claas',
      farmId: foTask.farmId,
      harvestYear: foTask.harvestYear,
      hoursUsed: undefined,
      index: 1,
      nutrientSupply: undefined,
      operationId: undefined,
      produceNormNumber: claasWork.productId,
      qualityParameters: [this.createQualityParameter(foTask, claasWork)],
      quantity: this.getQuantity(claasWork),
      theTime: undefined,
      pricePerUnit: 0,
    };

    return opLine;
  }

  public createQualityParameter(foTask: Task, claasTask: ClaasWork) {
    return {
      normNumber: claasTask.waterPctCalculated ? QualityParameterNormNumbers.WATERPCT : QualityParameterNormNumbers.DRYMATTERPCT,
      value: claasTask.waterPctCalculated ? claasTask.waterPctCalculated : claasTask.dryMatterPct,
      farmId: foTask.farmId,
      harvestYear: foTask.harvestYear,
      operationLineId: undefined,
      id: undefined,
    };
  }

  private getQuantity(claasWork: ClaasWork) {
    switch (claasWork.productUnit) {
      case ProduceNormUnits.TON:
        return convertToTons(claasWork.quantity, claasWork.quantityUnit) / this.getArea(claasWork);
      case ProduceNormUnits.HKG:
        return convertToHkg(claasWork.quantity, claasWork.quantityUnit) / this.getArea(claasWork);
      case ProduceNormUnits.KG:
        return convertToKg(claasWork.quantity, claasWork.quantityUnit) / this.getArea(claasWork);
      default:
        return claasWork.quantity / this.getArea(claasWork);
    }
  }
}
