<div class="transfer-tasks-content">
  <h1 class="transfer-tasks-title">{{ 'main.datamanagement.taskTransfer.title' | translate }}</h1>
  <app-spinner *ngIf="isTasksLoading | async" [hasBackdrop]="true"></app-spinner>

  <div class="interval-btn-wrapper">
    <app-square-button [buttonType]="'Primary'" class="interval-btn" (click)="onPickIntervalClicked()">
      {{ startDate | dateFormat }} - {{ endDate | dateFormat }}
    </app-square-button>
  </div>
  <div class="table-wrapper">
    <mat-table class="transfer-table" [dataSource]="(dataSource | async)!">
      <ng-container cdkColumnDef="farmName">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.farm' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.farmName }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="fieldNumber">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.benchmark.registrations.fieldNumber' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.fieldNumber }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="fieldArea">
        <mat-header-cell *cdkHeaderCellDef>{{ 'common.area.field' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.fieldArea | unit: 'ha' : 2 }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="startTime">
        <mat-header-cell *cdkHeaderCellDef>{{ 'common.date' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.startTime | dateFormat }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="area">
        <mat-header-cell *cdkHeaderCellDef>{{ 'common.area.treated' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.area | unit: 'ha' : 2 }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="productName">
        <mat-header-cell *cdkHeaderCellDef>{{ 'common.product' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.productName }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="quantity">
        <mat-header-cell *cdkHeaderCellDef>{{ 'common.quantity' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.quantity | unit: row.quantityUnit : 2 }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="dryMatter">
        <mat-header-cell *cdkHeaderCellDef>{{ 'common.drymatter.percentage' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">{{ row.dryMatterPct ? (row.dryMatterPct | unit: '' : 2) : 0 }}</mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="transfer">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.datamanagement.fieldTransfer.transfer' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <div>
            <app-icon *ngIf="isTransfered(row)" icon="check"></app-icon>
            <app-icon *ngIf="hasTransferError(row)" (click)="displayErrorMessage(row)" icon="error_outline"></app-icon>
            <app-spinner *ngIf="isTransfering(row)" [diameter]="20"></app-spinner>
            <app-square-button (clicked)="transferWork(row)" *ngIf="shouldShowTransferButton(row)">
              <app-icon icon="cloud_upload"></app-icon>
            </app-square-button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator #paginator [length]="numberOfTasks" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"> </mat-paginator>
  </div>

  <p class="no-dataconnections-error" *ngIf="shouldShowNoConnectionsMsg()">{{ 'common.noDataConnection' | translate }}</p>

  <div class="transfer-all-btn-wrapper">
    <app-square-button
      [buttonType]="'Primary'"
      *ngIf="!isTransferAllHidden()"
      (clicked)="transferAllWorks()"
      [disabled]="isTransferAllDisabled()"
    >
      <app-icon icon="cloud_upload"></app-icon>
      {{ 'main.datamanagement.fieldTransfer.transferAll' | translate }}
    </app-square-button>
  </div>
</div>
