import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { first } from 'rxjs/operators';

@Directive({
  selector: '[appFeatureToggle]',
})
export class FeatureToggleDirective {
  private hasView = false;

  @Input() set appFeatureToggle(featureKey: string) {
    this.featureToggleService
      .get$(featureKey)
      .pipe(first())
      .subscribe((isFeatureEnabled) => {
        if (isFeatureEnabled) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureToggleService: FeatureToggleService
  ) {}
}
