<div class="content">
  <div class="field-info crop-number" *ngIf="crop && crop.number">{{ crop.number }}</div>
  <div class="field-info field-name" *ngIf="crop && crop.name">{{ crop.name }}</div>
  <div class="field-info" *ngIf="crop.crop.cropName">{{ crop.crop.cropName }}</div>
  <div class="field-info" *ngIf="crop && crop.crop.area">({{ crop.crop.area | unit }} {{ 'common.hectareShort' | translate }})</div>
</div>
<div>
  <app-add-button
    [toolTipDelay]="'long'"
    [tooltipText]="'main.cultivationJournal.fieldTasks.addFieldToTask' | translate"
    size="sm"
    [invert]="true"
    color="#969696"
    (clicked)="add()"
    *ngIf="canAdd"
  ></app-add-button>
  <app-remove-button
    [toolTipDelay]="'long'"
    [tooltipText]="'main.cultivationJournal.fieldTasks.removeFieldFromTask' | translate"
    size="sm"
    (clicked)="remove()"
    *ngIf="canRemove"
  ></app-remove-button>
</div>
