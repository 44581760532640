import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/utils/utils';

@Pipe({
  name: 'sortString',
})
export class SortStringPipe implements PipeTransform {
  public transform(array: any[], property: string): any {
    if (isNullOrUndefined(array)) {
      return array;
    }

    if (!Array.isArray(array)) {
      throw new Error('Input value must be an array');
    }

    if (!property) {
      return array;
    }

    return array.sort((a, b) => {
      if (isNullOrUndefined(a[property]) || isNullOrUndefined(b[property]) || a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  }
}
