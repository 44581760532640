import { Route } from '@angular/router';
import { AppLayoutComponent } from '@app/layouts/app-layout/app-layout.component';
import { BlankLayoutComponent } from '@app/layouts/blank-layout/blank-layout.component';
import { mapLazyRoute } from '@app/map/map.routes';
import { noConsentLazyRoute } from '@app/no-consent/no-consent.routes';
import { permaRoutes } from '@app/perma/perma.routes';
import { settingsLazyRoute } from '@app/settings/settings.routes';
import { environment } from 'environments/environment';
import { FrontPageRedirectGuard } from './core/guards/frontpage-redirect.guard';
import { LoginRedirectGuard } from './core/guards/login-redirect.guard';
import { LoginSubscriptionsRedirectGuard } from './core/guards/login-subscriptions-redirect.guard';
import { economyLazyRoute } from './economy/economy.routing';
import { LoadingScreenComponent } from './layouts/loading-screen/loading-screen.component';
import { newMapLazyRoute } from './new-map/map.routes';

export const routes: Route[] = [
  /* Fill in components */
  {
    path: '',
    component: BlankLayoutComponent,
    children: [...permaRoutes],
  },
  { path: 'cultivation-journal', redirectTo: 'newmap/cultivation-journal', pathMatch: 'full' },
  /* Redirects from old map to new map */
  { path: 'map/cultivation-journal', redirectTo: 'newmap/cultivation-journal', pathMatch: 'full' },
  { path: 'map/field-plan', redirectTo: 'newmap/field-plan', pathMatch: 'full' },
  { path: 'map/vra', redirectTo: 'newmap/vra', pathMatch: 'full' },
  { path: 'map/prognosis', redirectTo: 'newmap/prognosis', pathMatch: 'full' },
  { path: 'map/hotspots', redirectTo: 'newmap/hotspots', pathMatch: 'full' },
  { path: 'map/basis-layer', redirectTo: 'newmap/basis-layer', pathMatch: 'full' },
  /* Children routes */
  {
    path: '',
    component: AppLayoutComponent,
    children: [mapLazyRoute, newMapLazyRoute, economyLazyRoute, settingsLazyRoute, noConsentLazyRoute],
  },
  /* Misc routes */
  {
    path: 'unauthorized',
    canActivate: [FrontPageRedirectGuard],
    component: FrontPageRedirectGuard,
    data: {
      redirectUrl: environment.endpoints['notAuthenticatedRedirectUrl'],
    },
  },
  {
    path: 'login',
    canActivate: [LoginRedirectGuard],
    component: LoginRedirectGuard,
  },
  {
    path: 'login-subscriptions',
    canActivate: [LoginSubscriptionsRedirectGuard],
    component: LoginSubscriptionsRedirectGuard,
  },
  {
    path: 'loading-screen',
    component: LoadingScreenComponent,
  },
];
