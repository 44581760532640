<div class="content-wrapper">
  <div>
    <app-field-collection-item class="base-field bold" [crop]="selectedCrop"></app-field-collection-item>
  </div>
  <app-spinner *ngIf="(loading$ | async)!.isLoading" [message]="(loading$ | async)!.message"></app-spinner>
  <ng-container *ngIf="cropsWithSimilarTask.length">
    <div [@flyInOut]="'in'" *ngFor="let item of cropsWithSimilarTask; let first = first">
      <app-field-collection-item class="base-field" [ngClass]="{ first: first }" [crop]="item"></app-field-collection-item>
    </div>
  </ng-container>
  <div class="field-list" [@flyInOut]="'in'" *ngFor="let item of newCropsWithSimilarTask; let first = first">
    <app-field-collection-item class="field" [ngClass]="{ first: first }" [crop]="item"></app-field-collection-item>
  </div>

  <div class="add-btn" *ngIf="!disabled">
    <app-add-button
      [ngClass]="{ 'add-btn-disabled': disabled }"
      size="lg"
      [toolTipDelay]="'long'"
      [disabled]="disabled"
      (clicked)="openSideDrawer()"
      [tooltipText]="'main.cultivationJournal.fieldTasks.addFieldToTask' | translate"
    ></app-add-button>
  </div>
</div>
