<ng-container *ngIf="queryParams$ | async as queryParams">
  <div *ngIf="farmsSelected$ | async">
    <div *ngFor="let parent of items$ | async" class="route" routerLinkActive="active">
      <app-accordion
        attr.data-testid="navigation-element-{{ parent.translationString }}"
        [isDisabled]="parent.disabled"
        [templateRef]="accBody"
        [titleTemplateRef]="accTitle"
        [elHeight]="'64px'"
        [isExpanded]="{ expanded: isLinkActive(parent.route) }"
        appGTagEventTracker
        eventName="{{ parent.translationString | lastSegment }} (click)"
        eventCategory="Navigation"
      >
      </app-accordion>
      <!-- New Flag Directive should be placed in the subsequent div. This ensures that new flags are positioned correctly,
        both when the menu is minimized and when the menu is displayed normally -->
      <div class="new-flag-directive" [ngClass]="{ minimized: (menuMinimized$ | async) }"></div>
      <ng-template #accTitle>
        <a
          class="nav-btn"
          (click)="navigate(parent.route, queryParams, parent.disabled)"
          [appResponsiveClass]="{ mobile: 'lt-Medium' }"
          [ngClass]="{
            'no-children': !parent.items.length,
            'parent-selected': isLinkActive(parent.route),
            minimized: (menuMinimized$ | async),
            disabled: parent.disabled
          }"
          id="e2e-{{ parent.translationString }}"
          matTooltip="{{ parent.translationString | translate }}"
          matTooltipPosition="after"
          [matTooltipDisabled]="!(menuMinimized$ | async)"
          matTooltipClass="nav-tooltip"
        >
          <span class="nav-item-icon" *ngIf="parent.icon" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
            <app-icon [icon]="parent.icon"></app-icon>
          </span>
          <span
            class="nav-item-text"
            [appResponsiveClass]="{ mobile: 'lt-Medium' }"
            [ngClass]="{ hidden: (menuMinimized$ | async), disabled: parent.disabled }"
            >{{ parent.translationString | translate }}</span
          >
        </a>
      </ng-template>

      <ng-template #accBody>
        <div *ngFor="let item of parent.items">
          <a
            class="nav-btn inner-nav"
            (click)="navigate(item.route, queryParams, item.disabled)"
            [ngClass]="{ selected: isLinkActive(item.route), disabled: item.disabled }"
            appGTagEventTracker
            eventName="{{ item.translationString | lastSegment }} (click)"
            eventCategory="Navigation"
          >
            <app-icon *ngIf="item.icon" [icon]="item.icon"></app-icon>
            <span class="nav-item-icon" *ngIf="item.icon">
              <app-icon [icon]="item.icon"></app-icon>
            </span>
            {{ item.translationString | translate }}
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
