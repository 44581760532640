<div class="table-container">
  <app-spinner *ngIf="loading$ | async" [hasBackdrop]="true"></app-spinner>
  <table mat-table [dataSource]="dataRows" matSort (matSortChange)="sortData($event)">
    <!-- Fields cell definition -->
    <ng-container matColumnDef="name" sticky>
      <th class="header" mat-header-cell *matHeaderCellDef>
        <app-economy-table-sort-header>
          {{ 'main.economy.table.fieldsTitle' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell class="element-name" *matCellDef="let element" (click)="onScoreDialogClick($event, element)">
        {{ element.cropName }} {{ element.fieldNumber }}
      </td>
    </ng-container>

    <!--  Field score definition-->
    <ng-container matColumnDef="fieldScore">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.fieldScore.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.fieldScore.line1' | translate
        }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.columnName.fieldScore' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell class="element-field-score" *matCellDef="let element" (click)="onScoreDialogClick($event, element)">
        <ng-container *ngIf="element.hasFieldScore; else noFieldScore">
          <div class="field-score-cell-container">
            <div class="has-field-score">
              {{ 'main.economy.table.fieldScoreFilled' | translate | uppercase }}
            </div>
            <div class="delete-icon">
              <app-remove-button
                [toolTipDelay]="'long'"
                [tooltipText]="'main.economy.tooltip.deleteFieldScore' | translate"
                (click)="deleteFieldScore(element, $event)"
              ></app-remove-button>
            </div>
          </div>
        </ng-container>
        <ng-template #noFieldScore>
          <div class="has-no-field-score">
            {{ 'main.economy.table.fieldScoreNotFilled' | translate | uppercase }}
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Manufacturing price cell definition -->
    <ng-container matColumnDef="productionPrice">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.manufacturingPrice.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.manufacturingPrice.line1' | translate
        }}&#13;{{ 'main.economy.tooltip.manufacturingPrice.line2' | translate }}&#13;{{
          'main.economy.tooltip.manufacturingPrice.line3' | translate
        }}&#13;{{ 'main.economy.tooltip.manufacturingPrice.line4' | translate }}&#13;{{
          'main.economy.tooltip.manufacturingPrice.line5' | translate
        }}&#13;{{ 'main.economy.tooltip.manufacturingPrice.line6' | translate }}&#13;{{
          'main.economy.tooltip.manufacturingPrice.line7' | translate
        }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.manufacturingPriceTitle1' | translate | uppercase }}
          <br />
          {{ 'main.economy.table.manufacturingPriceTitle2' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.productionPrice | fixedDecimals"
          [canEdit]="false"
          [suffix]="'main.economy.table.manufacturingPriceSuffix' | translate"
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.productionPrice)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.productionPrice)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Yield cell definition -->
    <ng-container matColumnDef="yield">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.yield.title' | translate }}&#13;&#13;{{ 'main.economy.tooltip.yield.line1' | translate }}"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.yieldTitle' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [fieldName]="element.fieldNumber"
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.yield | fixedDecimals"
          [cellColumnName]="'main.economy.table.columnName.yield' | translate"
          [canEdit]="
            $any((cellError$ | async)?.fieldId === element.id && (cellError$ | async)?.columnId === ColumnKey.yield) || (editable$ | async)
          "
          [suffix]="'main.economy.table.yieldSuffix' | translate"
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.yield)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.yield)"
          [sources]="getSourcesForElement(element.sources, ColumnKey.yield)"
          [currentSource]="element.yieldDataSource"
          (onSourceChange)="onSourceChange($event, element.id, ColumnKey.yield)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Unit costs cell definition -->
    <ng-container matColumnDef="unitCost">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.unitCosts.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.unitCosts.line1' | translate
        }}&#13;{{ 'main.economy.tooltip.unitCosts.line2' | translate }}&#13;{{ 'main.economy.tooltip.unitCosts.line3' | translate }}&#13;{{
          'main.economy.tooltip.unitCosts.line4' | translate
        }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.unitCostsTitle1' | translate | uppercase }}
          <br />{{ 'main.economy.table.unitCostsTitle2' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [fieldName]="element.fieldNumber"
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.unitCosts | fixedDecimals"
          [cellColumnName]="'main.economy.table.columnName.unitCost' | translate"
          [canEdit]="
            $any((cellError$ | async)?.fieldId === element.id && (cellError$ | async)?.columnId === ColumnKey.unitCost) ||
            (editable$ | async)
          "
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.unitCost)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.unitCost)"
          [sources]="getSourcesForElement(element.sources, ColumnKey.unitCost)"
          [currentSource]="element.unitCostsDataSource"
          (onSourceChange)="onSourceChange($event, element.id, ColumnKey.unitCost)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Machine and labour costs cell definition -->
    <ng-container matColumnDef="machineCosts">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.machineAndLabourCosts.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.machineAndLabourCosts.line1' | translate
        }}&#13;{{ 'main.economy.tooltip.machineAndLabourCosts.line2' | translate }}&#13;{{
          'main.economy.tooltip.machineAndLabourCosts.line3' | translate
        }}&#13;{{ 'main.economy.tooltip.machineAndLabourCosts.line4' | translate }}&#13;{{
          'main.economy.tooltip.machineAndLabourCosts.line5' | translate
        }}&#13;{{ 'main.economy.tooltip.machineAndLabourCosts.line6' | translate }}&#13;{{
          'main.economy.tooltip.machineAndLabourCosts.line7' | translate
        }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.machineAndLabourCostsTitle1' | translate | uppercase }}
          <br />{{ 'main.economy.table.machineAndLabourCostsTitle2' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [fieldName]="element.fieldNumber"
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.machineCosts | fixedDecimals"
          [cellColumnName]="'main.economy.table.columnName.machineCosts' | translate"
          [canEdit]="
            $any((cellError$ | async)?.fieldId === element.id && (cellError$ | async)?.columnId === ColumnKey.machineCosts) ||
            (editable$ | async)
          "
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.machineCosts)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.machineCosts)"
          [sources]="getSourcesForElement(element.sources, ColumnKey.machineCosts)"
          [currentSource]="element.machineAndLaborCostsDataSource"
          (onSourceChange)="onSourceChange($event, element.id, ColumnKey.machineCosts)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <ng-container matColumnDef="fieldscoreCost">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.fieldscoreCost.title' | translate }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.fieldscoreCostTitle' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell [value]="element.fieldScoreCorrection | fixedDecimals" [canEdit]="false"> </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Land rent cell definition -->
    <ng-container matColumnDef="landLease">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.landRent.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.landRent.line1' | translate
        }}&#13;{{ 'main.economy.tooltip.landRent.line2' | translate }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.landRentTitle' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [fieldName]="element.fieldNumber"
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.landLease | fixedDecimals"
          [canEdit]="
            $any((cellError$ | async)?.fieldId === element.id && (cellError$ | async)?.columnId === ColumnKey.landLease) ||
            (editable$ | async)
          "
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.landLease)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.landLease)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Others cell definition -->
    <ng-container matColumnDef="otherCosts">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.others.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.others.line1' | translate
        }}&#13;{{ 'main.economy.tooltip.others.line2' | translate }}&#13;{{ 'main.economy.tooltip.others.line3' | translate }}&#13;{{
          'main.economy.tooltip.others.line4' | translate
        }}&#13;{{ 'main.economy.tooltip.others.line5' | translate }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.othersTitle' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [fieldName]="element.fieldNumber"
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.otherCosts | fixedDecimals"
          [canEdit]="
            $any((cellError$ | async)?.fieldId === element.id && (cellError$ | async)?.columnId === ColumnKey.otherCosts) ||
            (editable$ | async)
          "
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.otherCosts)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.otherCosts)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Other revenue as well as EU subsidies cell definition -->
    <ng-container matColumnDef="OtherIncome">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.OtherRevenueAsWellAsEuSubsidies.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.OtherRevenueAsWellAsEuSubsidies.line1' | translate
        }}&#13;{{ 'main.economy.tooltip.OtherRevenueAsWellAsEuSubsidies.line2' | translate }}&#13;{{
          'main.economy.tooltip.OtherRevenueAsWellAsEuSubsidies.line3' | translate
        }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.OtherRevenueAsWellAsEuSubsidiesTitle1' | translate | uppercase }}
          <br />{{ 'main.economy.table.OtherRevenueAsWellAsEuSubsidiesTitle2' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell
          [fieldName]="element.fieldNumber"
          [validationFunctions]="[isValueBetweenZeroAndMax, isInteger]"
          [value]="element.otherIncome | fixedDecimals"
          [canEdit]="
            $any((cellError$ | async)?.fieldId === element.id && (cellError$ | async)?.columnId === ColumnKey.otherIncome) ||
            (editable$ | async)
          "
          (onEditCellComplete)="onEditChange($event, element.id, ColumnKey.otherIncome)"
          (onEditStateChange)="onEditStateChange($event, element.id, ColumnKey.otherIncome)"
        >
        </app-editable-grid-cell>
      </td>
    </ng-container>

    <!-- Total costs cell definition -->
    <ng-container matColumnDef="totalCosts" *ngIf="isFieldScoreEnabled$ | async; else fieldScoreDisabled">
      <th
        class="header"
        matTooltip="{{ 'main.economy.tooltip.totalCosts.title' | translate }}&#13;&#13;{{
          'main.economy.tooltip.totalCosts.line1' | translate
        }}"
        matTooltipPosition="below"
        mat-header-cell
        *matHeaderCellDef
      >
        <app-economy-table-sort-header>
          {{ 'main.economy.table.totalCostsTitle1' | translate | uppercase }}
          <br />{{ 'main.economy.table.totalCostsTitle2' | translate | uppercase }}
        </app-economy-table-sort-header>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-editable-grid-cell [value]="element.totalCostsWithCorrection | fixedDecimals" [canEdit]="false"> </app-editable-grid-cell>
      </td>
    </ng-container>

    <ng-template #fieldScoreDisabled>
      <ng-container matColumnDef="totalCosts">
        <th
          class="header"
          matTooltip="{{ 'main.economy.tooltip.totalCosts.title' | translate }}&#13;&#13;{{
            'main.economy.tooltip.totalCosts.line1' | translate
          }}"
          matTooltipPosition="below"
          mat-header-cell
          *matHeaderCellDef
        >
          <app-economy-table-sort-header>
            {{ 'main.economy.table.totalCostsTitle1' | translate | uppercase }}
            <br />{{ 'main.economy.table.totalCostsTitle2' | translate | uppercase }}
          </app-economy-table-sort-header>
        </th>
        <td mat-cell *matCellDef="let element">
          <app-editable-grid-cell [value]="element.totalCosts | fixedDecimals" [canEdit]="false"> </app-editable-grid-cell>
        </td>
      </ng-container>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></tr>
    <tr mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS"></tr>
  </table>
</div>
