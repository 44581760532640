import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExecutedLocationCollection } from '../../../executed-task.class';

@Component({
  selector: 'app-as-applied-task-info-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsAppliedTaskInfoProductTableComponent {
  @Input() public appliedTask!: ExecutedLocationCollection;
}
