import { MapConstants } from '@app/core/map/map.constants';
import { Coordinate } from 'ol/coordinate';
import { Geometry } from 'ol/geom';
import { transform } from 'ol/proj';

export class GeometryUtil {
  /** transforms a geometry from data projection (EPSG:4326) to map projection (EPSG:3857)*/
  public static transformGeometryToMap(geometry: Geometry): Geometry {
    return geometry.transform(MapConstants.dataProjection, MapConstants.mapProjection);
  }

  /** transforms a coordinate from map projection (EPSG:3857) to data projection (EPSG:4326)*/
  public static transformCoordinateFromMap(coordinate: Coordinate): Coordinate {
    return transform(coordinate, MapConstants.mapProjection, MapConstants.dataProjection) as Coordinate;
  }
}
