<div class="info">
  <app-square-button
    class="info-btn"
    [buttonStyle]="{ color: 'white' }"
    (mouseenter)="isInfoVisible = true"
    (mouseleave)="isInfoVisible = false"
  >
    <app-icon icon="info_outline"></app-icon>
    {{ buttonText }}
  </app-square-button>
  <div class="info-content mat-elevation-z2" [ngClass]="{ visible: isInfoVisible }">
    <ng-content class="info-content"></ng-content>
  </div>
</div>
