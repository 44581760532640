import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { PriceInfoDialogComponent } from '@app/map/features/field-analysis/features/drone-image-import/drone-side-drawer/price/price-info-dialog/price-info-dialog.component';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  @Input() public haPrice = 5.0;
  @Input() public totalHa$!: Observable<number>;
  public totalPricing$!: Observable<number>;

  constructor(private dialogService: DialogService) {}

  public ngOnInit() {
    this.totalPricing$ = this.totalHa$.pipe(map((ha: number) => ha * this.haPrice));
  }

  public onInfoClick() {
    this.dialogService.openLg(PriceInfoDialogComponent);
  }
}
