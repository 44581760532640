import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SoilSampleLegend } from '@app/core/repositories/soil-samples/soil-sample-legend.class';

@Component({
  selector: 'app-bt-cut-legend',
  templateUrl: './bt-cut-legend.component.html',
  styleUrls: ['./bt-cut-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtCutLegendComponent {
  @Input() public legend!: SoilSampleLegend;
}
