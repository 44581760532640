import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedBackStateService {
  private _isFeedBackOpenSubject = new BehaviorSubject<boolean | null>(null);

  public get isFeedBackOpen$() {
    return this._isFeedBackOpenSubject.asObservable();
  }

  public set isFeedBackOpen(isOpen: boolean) {
    this._isFeedBackOpenSubject.next(isOpen);
  }
}
