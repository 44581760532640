import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { StorageService } from '@app/core/storage/storage.service';

@Component({
  selector: 'app-browser-recommendation',
  templateUrl: './browser-recommendation.component.html',
  styleUrls: ['./browser-recommendation.component.scss'],
})
export class BrowserRecommendationComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<BrowserRecommendationComponent>,
    private storageService: StorageService
  ) {}

  public close() {
    this.snackBarRef.dismiss();
    this.storageService.setIE11Timestamp();
  }
}
