<div class="wrapper">
  <h2 mat-dialog-title>{{ 'messages.NewInCropmanagerDialog.headline' | translate }}</h2>
  <mat-dialog-content [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <div class="info-box">
      <p>{{ 'messages.NewInCropmanagerDialog.body1' | translate }}</p>
      <p>{{ 'messages.NewInCropmanagerDialog.body1-1' | translate }}</p>
      <p>
        <a class="pointer" (click)="showInfoDialog()">{{ 'messages.NewInCropmanagerDialog.bodyLink' | translate }}</a>
      </p>
      <p>{{ 'messages.NewInCropmanagerDialog.body2' | translate }}</p>
      <ol class="browser-default">
        <li>
          {{ 'messages.NewInCropmanagerDialog.bodyList1_1' | translate
          }}<b>{{ 'messages.NewInCropmanagerDialog.bodyList1_2' | translate }}</b
          >{{ 'messages.NewInCropmanagerDialog.bodyList1_3' | translate
          }}<b>{{ 'messages.NewInCropmanagerDialog.bodyList1_4' | translate }}</b>
        </li>
        <li>
          {{ 'messages.NewInCropmanagerDialog.bodyList2_1' | translate
          }}<b>{{ 'messages.NewInCropmanagerDialog.bodyList2_2' | translate }}</b>
        </li>
        <li>{{ 'messages.NewInCropmanagerDialog.bodyList3' | translate }}</li>
        <li>
          {{ 'messages.NewInCropmanagerDialog.bodyList4_1' | translate
          }}<b>{{ 'messages.NewInCropmanagerDialog.bodyList4_2' | translate }}</b>
        </li>
        <li>
          {{ 'messages.NewInCropmanagerDialog.bodyList5_1' | translate
          }}<b>{{ 'messages.NewInCropmanagerDialog.bodyList5_2' | translate }}</b>
        </li>
        <li>{{ 'messages.NewInCropmanagerDialog.bodyList6' | translate }}</li>
      </ol>
    </div>
    <img [appResponsiveClass]="{ mobile: 'lt-Medium' }" [attr.src]="'messages.NewInCropmanagerDialog.gif' | translate" />
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-square-button id="e2e-septoria-close" buttonType="Primary" (click)="close()">
      {{ 'common.close' | translate }}
    </app-square-button>
  </mat-dialog-actions>
</div>
