import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import {
  MetadataTaskWithText,
  OperationTypeGroupTaskCollection,
} from '@app/map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/meta-data-child-form/meta-data-child-form.component';
import { MetadataTask } from '@app/map/features/field-analysis/features/as-applied/metadata-task.class';

export class AsAppliedMetadataHelper {
  public static groupAndSortTasks(tasks: MetadataTask[], languageService?: LanguageService): OperationTypeGroupTaskCollection[] {
    return this.sortOperationTypeGroupTasksByDate(this.groupTasksByOperationTypeGroupAndAddText(tasks, languageService));
  }

  public static convertMetadataTaskToMetadataTaskWithText(
    metadataTask: MetadataTask,
    languageService?: LanguageService
  ): MetadataTaskWithText {
    const dateString = metadataTask.appliedDate.toFormat(LanguageConstants.getDateFormat(languageService!.currentLanguage.shortKey)['L']);
    const productsString = metadataTask.produceNorms.map((produceNorm) => produceNorm.name).join(', ');
    return { ...metadataTask, text: `${dateString} - ${productsString}` } as MetadataTaskWithText;
  }

  public static groupTasksByOperationTypeGroupAndAddText(
    tasks: MetadataTask[],
    languageService?: LanguageService
  ): OperationTypeGroupTaskCollection[] {
    const operationTypeGroupTaskCollections = new Array<OperationTypeGroupTaskCollection>();
    tasks.forEach((task) => {
      const taskWithText = this.convertMetadataTaskToMetadataTaskWithText(task, languageService);
      const operationElement = operationTypeGroupTaskCollections.find(
        (element) => element.operationTypeGroupName === taskWithText.operationTypeGroup.name
      );
      if (operationElement) {
        operationElement.tasks.push(taskWithText);
      } else {
        operationTypeGroupTaskCollections.push({ operationTypeGroupName: task.operationTypeGroup.name, tasks: [taskWithText] });
      }
    });
    return operationTypeGroupTaskCollections;
  }

  public static sortOperationTypeGroupTasksByDate(
    operationTypeGroupTaskCollections: OperationTypeGroupTaskCollection[]
  ): OperationTypeGroupTaskCollection[] {
    operationTypeGroupTaskCollections.forEach((operationTypeGroupCollection) =>
      operationTypeGroupCollection.tasks.sort((a, b) => CompareHelper.compare(a.appliedDate, b.appliedDate))
    );
    return operationTypeGroupTaskCollections;
  }
}
