import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@app/shared/tooltip/tooltip.module';
import { ButtonsModule as FeButtonsModule } from '@fe-shared/ui-components/buttons/buttons.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { AddButtonComponent } from './add-button/add-button.component';
import { PriorityDownButtonComponent } from './priority-down-button/priority-down-button.component';
import { PriorityUpButtonComponent } from './priority-up-button/priority-up-button.component';
import { RemoveButtonComponent } from './remove-button/remove-button.component';
@NgModule({
  imports: [CommonModule, FeButtonsModule, TooltipModule, IconModule],
  declarations: [AddButtonComponent, RemoveButtonComponent, PriorityUpButtonComponent, PriorityDownButtonComponent],
  exports: [FeButtonsModule, AddButtonComponent, RemoveButtonComponent, PriorityUpButtonComponent, PriorityDownButtonComponent],
})
export class ButtonsModule {}
