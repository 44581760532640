import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent {
  @Input() public disable: boolean = false;

  private _task!: SimpleTask;
  public get task(): SimpleTask {
    return this._task;
  }
  @Input()
  public set task(v: SimpleTask) {
    this._task = v;
  }

  @Output() public itemEdit = new EventEmitter<SimpleTask>();
  @Output() public itemDelete = new EventEmitter<SimpleTask>();
  @Output() public itemComplete = new EventEmitter<SimpleTask>();
  @Output() public itemSchedule = new EventEmitter<SimpleTask>();

  /**
   * editButtonClicked
   */
  public editButtonClicked(task: SimpleTask) {
    this.itemEdit.emit(task);
  }

  /**
   * deleteBUttonClicked
   */
  public deleteButtonClicked(task: SimpleTask) {
    this.itemDelete.emit(task);
  }

  public completeButtonClicked(task: SimpleTask) {
    this.itemComplete.emit(task);
  }

  public scheduleButtonClicked(task: SimpleTask) {
    this.itemSchedule.emit(task);
  }
}
