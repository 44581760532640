<app-spinner *ngIf="loading" [hasBackdrop]="true"></app-spinner>
<app-dialog-header size="lg" [title]="'nTool.calcDialog.title' | translate" [showClose]="false" [showInfo]="false"> </app-dialog-header>
<div class="info">
  <app-info-hover [buttonText]="'nTool.calcDialog.info.info' | translate">
    <div class="info-box">
      <p>{{ 'nTool.calcDialog.info.infoText' | translate }}</p>
      <span class="info-box-field-name">{{ 'nTool.calcDialog.info.priceConditionsHeader' | translate }}</span>
      <p>{{ 'nTool.calcDialog.info.priceConditionsText' | translate }}</p>
      <p>
        <u>{{ 'nTool.calcDialog.info.priceConditionsSection1Header' | translate }}</u>
        {{ 'nTool.calcDialog.info.priceConditionsSection1Text' | translate }}
      </p>
      <u>{{ 'nTool.calcDialog.info.priceConditionsSection2Header' | translate }}</u>
      {{ 'nTool.calcDialog.info.priceConditionsSection2Text' | translate }}
      <p>
        <u>{{ 'nTool.calcDialog.info.priceConditionsSection3Header' | translate }}</u>
        {{ 'nTool.calcDialog.info.priceConditionsSection3Text' | translate }}
      </p>
      <span class="info-box-field-name">{{ 'nTool.calcDialog.info.expectedDividendHeader' | translate }}</span>
      <p>{{ 'nTool.calcDialog.info.expectedDividendText' | translate }}</p>
      <span class="info-box-field-name">{{ 'nTool.calcDialog.info.latestCalculatedNeedHeader' | translate }}</span>
      <p>{{ 'nTool.calcDialog.info.latestCalculatedNeedText' | translate }}</p>
      <span class="info-box-field-name">{{ 'nTool.calcDialog.info.currentNeedHeader' | translate }}</span>
      <p>{{ 'nTool.calcDialog.info.currentNeedText' | translate }}</p>
      <span class="info-box-field-name">{{ 'nTool.calcDialog.info.rememberCheckQuotaHeader' | translate }}</span>
      <p>{{ 'nTool.calcDialog.info.rememberCheckQuotaText' | translate }}</p>
    </div>
  </app-info-hover>
</div>

<app-dialog-content size="lg">
  <div class="dialog-content">
    <div>
      <div class="main-container">
        <div class="left-container">
          <div class="topLeft-container">
            <mat-card appearance="outlined" style="margin: 5px">
              <mat-card-title>
                <div class="title">{{ 'nTool.calcDialog.calcInput.title' | translate }}</div>
              </mat-card-title>
              <mat-card-content>
                <form [formGroup]="reCalcFormGroup">
                  <div class="area-wrapper">
                    <div class="label-wrapper">{{ 'nTool.calcDialog.calcInput.nitrogenPrice' | translate }}</div>
                    <div class="value-wrapper">
                      <kendo-numerictextbox
                        class="numerictextbox"
                        [maxlength]="10"
                        [decimals]="2"
                        format="n2"
                        formControlName="nitrogenPrice"
                        [value]="reCalcInputData.nitrogenPrice!"
                      >
                        <ng-template kendoTextBoxSuffixTemplate> </ng-template>
                      </kendo-numerictextbox>
                      <div class="unit">{{ 'common.unitType.krPrKgN' | translate }}</div>
                    </div>
                  </div>

                  <app-cell-error-container [errors]="getNitrogenPriceErrorsTranslatables(reCalcFormGroup)"> </app-cell-error-container>

                  <div class="area-wrapper">
                    <div class="label-wrapper">{{ 'nTool.calcDialog.calcInput.cropPrice' | translate }}</div>
                    <div class="value-wrapper">
                      <kendo-numerictextbox class="numerictextbox" [maxlength]="10" [decimals]="2" format="n2" formControlName="cropPrice">
                      </kendo-numerictextbox>
                      <div class="unit">{{ 'common.unitType.krHkg' | translate }}</div>
                    </div>
                  </div>

                  <app-cell-error-container [errors]="getCropPriceErrorsTranslatables(reCalcFormGroup)"> </app-cell-error-container>

                  <div class="area-wrapper">
                    <div class="label-wrapper">{{ 'nTool.calcDialog.calcInput.proteinPrice' | translate }}</div>
                    <div class="value-wrapper">
                      <kendo-numerictextbox
                        class="numerictextbox"
                        [maxlength]="10"
                        [decimals]="2"
                        format="n2"
                        formControlName="proteinPrice"
                      >
                      </kendo-numerictextbox>
                      <div class="unit">{{ 'common.unitType.krPrPctProtein' | translate }}</div>
                    </div>
                  </div>
                  <app-cell-error-container [errors]="getProteinPriceErrorsTranslatables(reCalcFormGroup)"> </app-cell-error-container>
                  <p>
                    <app-square-button
                      buttonType="Secondary"
                      (clicked)="reCalculate(true)"
                      [disabled]="hasCalcFormGroupError(reCalcFormGroup)"
                    >
                      {{ 'nTool.calcDialog.reCalcButton' | translate }}
                    </app-square-button>
                  </p>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="bottomLeft-container">
            <mat-card appearance="outlined" style="margin: 5px">
              {{ 'nTool.calcDialog.reWriteText' | translate }}
              <p></p>
              <p>
                <app-square-button buttonType="Secondary" (clicked)="reCalculate(false)">
                  {{ 'nTool.calcDialog.reWriteButton' | translate }}
                </app-square-button>
              </p></mat-card
            >
          </div>
        </div>
        <div class="right-container">
          <mat-card appearance="outlined" style="margin-top: 5px; margin-right: 5px; margin-bottom: 5px">
            <div class="table-wrapper">
              <form [formGroup]="tableFormGroup" *ngIf="!!tableFormGroup">
                <kendo-treelist
                  *ngIf="displayData$ | async as displayData"
                  idField="fieldYearID"
                  scrollable="scrollable"
                  [kendoTreeListFlatBinding]="displayData.nitrogenNeeds"
                  (cellClick)="cellClick($event)"
                  (cellClose)="cellCloseHandler($event)"
                  #treeList
                >
                  <kendo-treelist-messages noRecords="{{ 'nTool.table.noRecords' | translate }}"> </kendo-treelist-messages>
                  <!--   Fields   -->
                  <kendo-treelist-column field="fieldNumber" [width]="60">
                    <ng-template kendoTreeListHeaderTemplate>
                      <app-kendo-sort-header [title]="'nTool.calcDialog.calcTable.fields'"></app-kendo-sort-header>
                    </ng-template>
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                      {{ dataItem.fieldNumber }}
                    </ng-template>
                  </kendo-treelist-column>
                  <!--   Expected yield   -->
                  <kendo-treelist-column field="expectedYield" [width]="120">
                    <ng-template kendoTreeListHeaderTemplate>
                      <app-kendo-sort-header [title]="'nTool.calcDialog.calcTable.expectedYield'"> </app-kendo-sort-header>
                    </ng-template>
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                      {{ dataItem.expectedYield }}
                    </ng-template>
                    <ng-template
                      kendoTreeListEditTemplate
                      let-dataItem="dataItem"
                      let-formGroup="tableFormGroup"
                      let-column="column"
                      let-row="row"
                    >
                      <kendo-numerictextbox
                        class="numerictextbox"
                        [maxlength]="3"
                        [decimals]="0"
                        format="0"
                        kendoTreeListFocusable
                        formControlName="expectedYield"
                        [value]="dataItem?.expectedYield"
                      >
                        <ng-template kendoTextBoxSuffixTemplate> </ng-template>
                      </kendo-numerictextbox>
                      <div *ngIf="!tableFormGroup.controls['expectedYield'].valid" #anchor>
                        <kendo-popup [anchor]="anchor" [animate]="false">
                          <div class="cell-error-validation">
                            <div *ngIf="isNotGreatherThan0">
                              {{ 'nTool.calcDialog.calcTable.expectedYieldGreatherThan0Validation' | translate }}
                            </div>
                          </div>
                        </kendo-popup>
                      </div>
                    </ng-template>
                  </kendo-treelist-column>
                  <!-- New calculated N-needs -->
                  <kendo-treelist-column field="nTotalNeedCalculated" [width]="170">
                    <ng-template kendoTreeListHeaderTemplate>
                      <app-kendo-sort-header [title]="'nTool.calcDialog.calcTable.newCalculatedN-needs'"> </app-kendo-sort-header>
                    </ng-template>
                    <ng-template kendoTreeListCellTemplate calculatedTemplate let-dataItem>
                      <span *ngIf="dataItem.warning; then warningTemplate; else calculatedTemplate"></span>
                      <ng-template #warningTemplate> - </ng-template>
                      <ng-template #calculatedTemplate>{{ dataItem.nTotalNeedCalculated }}</ng-template>
                    </ng-template>
                  </kendo-treelist-column>
                  <!-- warning & explanation-->
                  <kendo-treelist-column [width]="40">
                    <ng-template kendoTreeListHeaderTemplate>
                      <app-kendo-sort-header title="info-info" style="color: transparent"> </app-kendo-sort-header>
                    </ng-template>
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                      <span *ngIf="dataItem.warning; then warningTemplate; else infoTemplate"></span>
                      <ng-template #warningTemplate>
                        <app-tooltip tooltipText="{{ dataItem.warning }}">
                          <app-icon icon="warning_outline" class="warning"></app-icon>
                        </app-tooltip>
                      </ng-template>
                      <ng-template #infoTemplate>
                        <app-tooltip tooltipText="{{ dataItem.explanation }}">
                          <app-icon icon="help_outline" class="explanation"></app-icon>
                        </app-tooltip>
                      </ng-template>
                    </ng-template>
                  </kendo-treelist-column>
                  <!-- Current N-needs -->
                  <kendo-treelist-column field="nTotalNeedCalculated" [width]="130">
                    <ng-template kendoTreeListHeaderTemplate>
                      <app-kendo-sort-header [title]="'nTool.calcDialog.calcTable.currentN-needs'"> </app-kendo-sort-header>
                    </ng-template>
                    <ng-template kendoTreeListCellTemplate let-dataItem>
                      {{ dataItem.nTotalNeedActual }}
                    </ng-template>
                    <ng-template
                      kendoTreeListEditTemplate
                      let-dataItem="dataItem"
                      let-formGroup="tableFormGroup"
                      let-column="column"
                      let-row="row"
                    >
                      <kendo-numerictextbox
                        class="numerictextbox"
                        [maxlength]="3"
                        [decimals]="0"
                        format="0"
                        kendoTreeListFocusable
                        formControlName="nTotalNeedActual"
                        [value]="dataItem?.nTotalNeedActual"
                      >
                        <ng-template kendoTextBoxSuffixTemplate></ng-template>
                      </kendo-numerictextbox>
                      <div *ngIf="!tableFormGroup.controls['nTotalNeedActual'].valid" #anchor>
                        <kendo-popup [anchor]="anchor" [animate]="false">
                          <div class="cell-error-validation">
                            <div *ngIf="isNotGreaterThanOrEqual0">
                              {{ 'nTool.calcDialog.calcTable.currentN-needsGreaterThanOrEqual0Validation' | translate }}
                            </div>
                          </div>
                        </kendo-popup>
                      </div>
                    </ng-template>
                  </kendo-treelist-column>
                </kendo-treelist>
              </form>
            </div>
            <!--   Reusable templates   -->
            <ng-template #spinner>
              <app-spinner></app-spinner>
            </ng-template>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</app-dialog-content>
<app-dialog-footer
  [confirmButtonText]="'common.save'"
  [cancelButtonText]="'common.cancel'"
  (cancel)="onCancelClick()"
  (confirm)="onConfirmClick()"
></app-dialog-footer>
