import { Farm } from '@app/core/interfaces/farm.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { DateTime } from 'luxon';
import { Type } from 'ol/geom/Geometry';
import { MetadataTask } from '../metadata-task.class';

export interface ExecutedArchiveFileUploadResponse {
  id: number;
  executedArchiveFileType: number;
}
export interface MetadataParentDto {
  farmId: number;
  harvestYear: number;
  name: string;
  id: number;
  createdDate: string;
  items: MetadataChildDto[];
}

export interface MetadataChildDto {
  processId: number;
  fieldName: string;
  date: DateTime;
  taskId: number | null;
  fieldYearId: number;
  operationTypeGroup: number;
  metadataTask: MetadataTask | null;
  geometries: MetadataGeometryDto[];
  unit: string;
}

export interface MetadataParentInterface {
  farm?: Farm;
  harvestYear: number;
  name: string;
  metadataChildren: MetadataChild[];
  id: number;
  createdDate: DateTime;
  displayDate: string;
  units: string[];
}

export class MetadataParent implements MetadataParentInterface {
  public farm?: Farm;
  public harvestYear: number;
  public name: string;
  public metadataChildren: MetadataChild[];
  public id: number;
  public createdDate: DateTime;
  public displayDate: string;

  constructor(dto: MetadataParentDto, languageService: LanguageService, farm?: Farm) {
    this.farm = farm;
    this.harvestYear = dto.harvestYear;
    this.name = dto.name;
    this.metadataChildren = dto.items?.map((item, index) => new MetadataChild(item, index)) ?? [];
    this.id = dto.id;
    this.createdDate = DateTime.fromISO(dto.createdDate.toString());
    this.displayDate = DateTime.fromISO(dto.createdDate.toString()).toFormat(
      LanguageConstants.getDateFormat(languageService.currentLanguage.shortKey).L
    );
  }

  public get units() {
    return this.metadataChildren.map((child) => child.unit);
  }
}

export class MetadataChild {
  public processId: number;
  public fieldName: string;
  public date: DateTime;
  public taskId?: number | null;
  public fieldYearId?: number;
  public OperationTypeGroup?: number;
  public metadataTask?: MetadataTask | null;
  public geometries: MetadataGeometry[];
  // Used to tell the MetadataChild objects apart, only used in FE
  public indexId?: number;
  public unit: string;

  constructor(dto: MetadataChildDto, indexId?: number) {
    this.indexId = indexId;
    this.processId = dto.processId;
    this.fieldName = dto.fieldName;
    this.date = dto.date;
    this.taskId = dto.taskId;
    this.fieldYearId = dto.fieldYearId;
    this.OperationTypeGroup = dto.operationTypeGroup;
    this.metadataTask = dto.metadataTask;
    this.unit = dto.unit;
    this.geometries = dto.geometries.map((geometry) => new MetadataGeometry(geometry));
  }
}

export interface MetadataFileDto {
  tasks: MetadataChild[];
  rates: MetadataGeometryDto[];
  units: string[];
}

export class MetadataFile implements MetadataFileDto, MetadataParentInterface {
  public tasks: MetadataChild[];
  public rates: MetadataGeometry[];
  public createdDate: DateTime;
  public displayDate: string;
  public farm?: Farm;
  public harvestYear: number;
  public id: number;
  public metadataChildren: MetadataChild[];
  public name: string;
  public units: string[];

  constructor(metadataFileDto: MetadataFileDto, metadataParent: MetadataParent) {
    this.tasks = metadataFileDto.tasks;
    this.rates = metadataFileDto.rates.map((rate) => new MetadataGeometry(rate));
    this.createdDate = metadataParent.createdDate;
    this.displayDate = metadataParent.displayDate;
    this.farm = metadataParent.farm;
    this.harvestYear = metadataParent.harvestYear;
    this.id = metadataParent.id;
    this.metadataChildren = metadataParent.metadataChildren;
    this.name = metadataParent.name;
    this.units = metadataFileDto.units;
  }
}

export interface MetadataGeometryDto {
  geometry: string;
  quantity: number | undefined;
  type: string;
  unitText?: string;
  color?: string;
  decimals?: number;
  ddiName?: string;
  minMax?: { min: number; max: number };
  quantityText?: string;
  scale?: number;
  time?: DateTime;
}

export class MetadataGeometry {
  public geometry: string;
  public quantity: number | undefined;
  public type!: Type;
  public unitText?: string;
  public color?: string;
  public decimals?: number;
  public ddiName?: string;
  public minMax?: { min: number; max: number };
  public quantityText?: string;
  public scale?: number;
  public time?: DateTime;

  constructor(dto: MetadataGeometryDto) {
    this.geometry = dto.geometry;
    this.quantity = dto.quantity;
    this.color = dto.color;
    this.unitText = dto.unitText;
    this.decimals = dto.decimals;
    this.ddiName = dto.ddiName;
    this.minMax = dto.minMax;
    this.quantityText = dto.quantityText;
    this.scale = dto.scale;
    this.time = dto.time;

    switch (dto.type.toUpperCase()) {
      case 'POINT':
        this.type = 'Point';
        break;
      case 'POLYGON':
        this.type = 'Polygon';
        break;
    }
  }
}

export interface ShapefileConfigInput {
  configsInput: TemporalShapefileConfigInput[];
}

export interface TemporalShapefileConfigInput {
  index?: number;
  srid?: number;
  quantityColumnName?: string;
  fileNames?: string[];
}

export interface ShapefileAnalysisDto {
  groups: ShapefileGroupDto[];
}

export interface ShapefileGroupDto {
  shapefiles: string[];
  columnNames: ShapefileColumnDto[];
  matchExistingProfile: boolean;
  selected: string;
  srid: number;
}

export interface ShapefileColumnDto {
  name: string;
  disabled: boolean;
}
