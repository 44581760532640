import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { ChartService } from '@app/core/chart/chart.service';
import { PrognosisDataSource } from '@app/core/enums/prognosis-data-source.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { LanguageService } from '@app/core/language/language.service';
import { LeafwetHoursDto, WetHourDto } from '@app/core/repositories/weather/leaf-wet-hours.interface';
import { WeatherRepoService } from '@app/core/repositories/weather/weather-repo.service';
import { DiseaseTypes } from '@app/core/repositories/weather/wet-hours-disease-types.enum';
import { getPlotBandColor } from '@app/helpers/plot-band-colors/get-plot-band-color.fn';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { WetHoursChartVM } from '@app/shared/wet-hours-chart/wet-hours-chart-vm.interface';
import { WindowStateService } from '@app/state/services/window/window-state.service';
import { CategoryAxis } from '@progress/kendo-angular-charts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WetHoursChartInfoComponent } from './wet-hours-chart-info/wet-hours-chart-info.component';

@Injectable({
  providedIn: 'root',
})
export class WetHoursChartService {
  public screenSize$ = this.windowSelector.screenSize$;
  private leafWetHoursCache = this.cacheService.create<LeafwetHoursDto>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    private weatherRepo: WeatherRepoService,
    private cacheService: CacheService,
    private languageService: LanguageService,
    private windowSelector: WindowStateService,
    private chartService: ChartService,
    private dialogService: DialogService
  ) {}

  public getLeafWetHours(farmId: number, harvestYear: number, fieldId: number): Observable<WetHoursChartVM> {
    const key = `${farmId}-${harvestYear}-${fieldId}`;
    const getValue = () => {
      return this.weatherRepo.getDmiLeafWetHours(farmId, harvestYear, fieldId, DiseaseTypes.CornEyeSpot);
    };

    return this.leafWetHoursCache
      .getOrSetAsync(key, () => getValue())
      .pipe(
        map((data) => ({
          aggregated: data.leafwetHours,
          observed: data.leafwetHours.filter((wetHour) => wetHour.dataSource === PrognosisDataSource.Observed),
          forecast: data.leafwetHours.filter((wetHour) => wetHour.dataSource === PrognosisDataSource.Forecast),
          plotBands: data.leafwetRanges?.map((range) => ({
            color: getPlotBandColor(range.color),
            from: range.min,
            to: range.max,
            opacity: 0.8,
          })),
        }))
      );
  }

  public getValueAxistitle() {
    return this.languageService.getText('wetHoursChart.value-axis.title');
  }

  public calculateNumberOfStepsByScreenSize(screenSize: ScreenSize, aggregatedData: WetHourDto[], categoryAxis: CategoryAxis) {
    return this.chartService.calculateNumberOfStepsByScreenSize<WetHourDto>(screenSize, aggregatedData, categoryAxis);
  }

  public showInfoModal() {
    this.dialogService.openInfoDialog(WetHoursChartInfoComponent);
  }
}
