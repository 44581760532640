import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { EconomyTableSortHeaderComponent } from './economy-table/economy-table-sort-header/economy-table-sort-header.component';
import { EconomyTableComponent } from './economy-table/economy-table.component';
import { EconomyComponent } from './economy.component';
import { economyRoutes } from './economy.routing';
import { CellErrorContainerComponent } from './grid/cell-error-container/cell-error-container.component';
import { EditableGridCellComponent } from './grid/editable-grid-cell/editable-grid-cell.component';
import { SourcepickerComponent } from './grid/sourcepicker/sourcepicker.component';
import { ScoreDialogComponent } from './score-dialog/score-dialog.component';
import { EditableGridCellValuePipe } from '@app/economy/grid/editable-grid-cell/editable-grid-cell-value.pipe';

@NgModule({
  imports: [RouterModule, SharedModule, RouterModule.forChild(economyRoutes)],
  exports: [CellErrorContainerComponent],
  declarations: [
    EconomyComponent,
    EditableGridCellComponent,
    EconomyTableComponent,
    EconomyTableSortHeaderComponent,
    ScoreDialogComponent,
    CellErrorContainerComponent,
    SourcepickerComponent,
    EditableGridCellValuePipe,
  ],
})
export class EconomyModule {}
