import { Injectable } from '@angular/core';
import { ConsentRepo } from '@app/core/repositories/consent/consent-repo.service';
import { Consent } from '@app/core/repositories/consent/consent.interface';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConsentStateService {
  private _consentsSubject: ReplaySubject<Consent[]> = new ReplaySubject<Consent[]>(1);

  public get consents$(): Observable<Consent[]> {
    return this._consentsSubject.asObservable();
  }

  constructor(
    private consentRepo: ConsentRepo,
    private bffAuthService: BffAuthService
  ) {
    this.bffAuthService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => !!isAuthenticated),
        switchMap(() => this.bffAuthService.isCurrentUserFromNaesgaard$),
        switchMap((isNaesgaard) => {
          // If the user is from Næsgaard, no consents are needed.
          if (isNaesgaard) {
            return of([]);
          }
          return this.consentRepo.get(ConsentRepo.consentParam);
        })
      )
      .subscribe((consents: Consent[]) => {
        this._consentsSubject.next(consents);
      });
  }
}
