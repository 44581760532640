export class Base64Convert {
  /**
   * Encodes binary string into base64 string - Fast
   * @param inputStr Binary string to encode
   */
  public toBase64String(inputStr: any): string {
    const bbLen = 3;
    const enCharLen = 4;
    const inpLen = inputStr.length;
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    const bytebuffer = new Array(bbLen);
    const encodedCharIndexes = new Array(enCharLen);

    let output = '';
    let inx = 0;
    while (inx < inpLen) {
      for (let jnx = 0; jnx < bbLen; ++jnx) {
        /*--- Throw away high-order byte, as documented at:
                  https://developer.mozilla.org/En/Using_XMLHttpRequest#Handling_binary_data
                */

        // eslint-disable-next-line no-bitwise
        bytebuffer[jnx] = inx < inpLen ? inputStr.charCodeAt(inx++) & 0xff : 0;
      }
      // eslint-disable-next-line no-bitwise
      encodedCharIndexes[0] = bytebuffer[0] >> 2;
      // eslint-disable-next-line no-bitwise
      encodedCharIndexes[1] = ((bytebuffer[0] & 0x3) << 4) | (bytebuffer[1] >> 4);
      // eslint-disable-next-line no-bitwise
      encodedCharIndexes[2] = ((bytebuffer[1] & 0x0f) << 2) | (bytebuffer[2] >> 6);
      // eslint-disable-next-line no-bitwise
      encodedCharIndexes[3] = bytebuffer[2] & 0x3f;

      // --- Determine whether padding happened, and adjust accordingly.
      const paddingBytes = inx - (inpLen - 1);
      switch (paddingBytes) {
        case 1:
          // Set last character to padding char
          encodedCharIndexes[3] = 64;
          break;
        case 2:
          // Set last 2 characters to padding char
          encodedCharIndexes[3] = 64;
          encodedCharIndexes[2] = 64;
          break;
        default:
          break; // No padding - proceed
      }
      for (let jnx = 0; jnx < enCharLen; ++jnx) {
        output += keyStr.charAt(encodedCharIndexes[jnx]);
      }
    }
    return output;
  }
}
