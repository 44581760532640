import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FarmPickerStateService {
  private _isAddFarmOpen = new BehaviorSubject<boolean>(false);
  private _isFarmPickerModalOpen = new BehaviorSubject<boolean>(false);

  constructor() {}

  public get isAddFarmOpen$() {
    return this._isAddFarmOpen.asObservable();
  }

  public openAddFarmModal() {
    this._isAddFarmOpen.next(true);
  }

  public closeAddFarmModal() {
    this._isAddFarmOpen.next(false);
  }

  public get isFarmPickerModalOpen$() {
    return this._isFarmPickerModalOpen.asObservable();
  }

  public openFarmPickerModal() {
    this._isFarmPickerModalOpen.next(true);
  }

  public closeFarmPickerModal() {
    this._isFarmPickerModalOpen.next(false);
  }
}
