<ng-container *ngIf="(yieldCropType | async) === 'maize'; else wheat">
  <div class="header-close">
    <h4>{{ 'main.yieldPrognosis.maize.infoDialogTitle' | translate }}</h4>
    <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
  </div>
  <mat-dialog-content>
    <div>
      {{ 'main.yieldPrognosis.maize.infoDialogTextSection1' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.maize.infoDialogTextSection2' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.maize.infoDialogTextSection3' | translate }}
    </div>
  </mat-dialog-content>
</ng-container>

<ng-template #wheat>
  <div class="header-close">
    <h4>{{ 'main.yieldPrognosis.wheat.infoDialogTitle' | translate }}</h4>
    <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
  </div>
  <mat-dialog-content>
    <div>
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection1' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection2' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection3' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection4' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection5' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection6' | translate }}
      <br />
      <br />
      {{ 'main.yieldPrognosis.wheat.infoDialogTextSection7' | translate }}
    </div>
  </mat-dialog-content>
</ng-template>
