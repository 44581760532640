import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { HttpClient } from '@app/core/http/http-client';
import { Task } from '@app/core/interfaces/task.interface';
import { ErrorCodes } from '@app/error-codes.enum';
import { VraOperationLineDto } from '@app/new-map/features/vra/interfaces/vra-operation-line.interface';
import isString from 'lodash-es/isString';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const tasksRepoModule = 'cm.repositories.tasks';

export interface TasksMessages {
  getError: string;
  updateError: string;
  duplicateError: string;
}

export interface ITasksRepo {
  getTasksForFarms(farmIds: number[], harvestYear: number): Observable<Array<Task>>;
  addWaterToTasks(
    farmIds: number[],
    harvestYear: number,
    taskIds: number[],
    waterQuantity: number
  ): Observable<PatchedOperationLineResult | null>;
  updateTask(task: Task): Observable<Task | null>;
  createTask(task: Task): Observable<Task | null>;
  deleteTask(taskId: number): Observable<number>;
}

const handleError = (response: HttpErrorResponse) => {
  if (response.status) {
    const error = response.error ? response.error : null;
    if (error && error.message && isString(error.message) && error.message.toLowerCase().indexOf('dublet') > -1) {
      return throwError({
        code: ErrorCodes.Duplicate,
        text: error,
      });
    }
    return throwError(response);
  } else {
    return throwError(response);
  }
};

@Injectable({
  providedIn: 'root',
})
export class TasksRepo implements ITasksRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getTasksForFarms(farmIds: number[], harvestYear: number): Observable<Task[]> {
    const options = {
      withCredentials: true,
      params: {
        calculateHashCode: true,
      },
    };
    return this.http.get<Task[]>(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/fields/crops/tasks`, options);
  }

  public getAllTasks(year: number) {
    return this.http.get<Task[]>(`${this.endpoints.foApi}/farms/${year}/fields/crops/tasks`, {
      params: {
        calculateHashCode: true,
      },
    });
  }

  public addWaterToTasks(
    farmIds: number[],
    harvestYear: number,
    taskIds: number[],
    waterQuantity: number
  ): Observable<PatchedOperationLineResult | null> {
    const options = {
      params: {
        waterQuantity,
      },
    };

    const body = [...taskIds];

    return this.http
      .patch<
        PatchedOperationLineResult,
        number[]
      >(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/fields/crops/tasks/addwater?waterQuantity=${waterQuantity}`, body, options)
      .pipe(catchError((err) => handleError(err)));
  }

  public updateTask(task: Task): Observable<Task | null> {
    const options = {
      withCredentials: true,
    };
    return this.http
      .put<Task, Task>(`${this.endpoints.foApi}/farms/fields/crops/tasks/${task.id}`, task, options)
      .pipe(catchError((err) => handleError(err)));
  }

  public updateTasks(tasks: Task[]): Observable<Task[] | null> {
    if (!tasks || tasks.length === 0) {
      return of([]);
    }

    return this.http
      .put<Task[], Task[]>(`${this.endpoints.foApi}/farms/fields/crops/tasks/list`, tasks)
      .pipe(catchError((err) => handleError(err)));
  }

  public createTask(task: Task): Observable<Task | null> {
    // If the task has a new seed operation caused by added a variety, it needs to be posted correctly.
    if (task.operations.some((op) => op.operationTypeId === OperationTypes.Seed)) {
      const reqOptions = {
        params: {
          setDefaultSeedingDate: true,
          setDefaultSeedingQuantity: true,
        },
      };
      return this.http
        .post<Task, Task>(`${this.endpoints.foApi}/farms/fields/crops/tasks`, task, reqOptions)
        .pipe(catchError((err) => handleError(err)));
    }

    const reqOptionsArg = {
      /* */
    };
    return this.http
      .post<Task, Task>(`${this.endpoints.foApi}/farms/fields/crops/tasks`, task, reqOptionsArg)

      .pipe(catchError((err) => handleError(err)));
  }

  public createTasks(tasks: Task[]): Observable<Task[] | null> {
    if (!tasks || tasks.length === 0) {
      return of([]);
    }

    return this.http
      .post<Task[], Task[]>(`${this.endpoints.foApi}/farms/fields/crops/tasks/list`, tasks)
      .pipe(catchError((err) => handleError(err)));
  }

  public deleteTask = (taskId: number): Observable<number> =>
    this.http.delete(`${this.endpoints.foApi}/farms/fields/crops/tasks/${taskId}`).pipe(map(() => taskId));

  public getTasksForField(farmId: number, harvestYear: number, fieldId: number): Observable<Task[]> {
    return this.http.get<Task[]>(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldId}/crops/tasks`, {
      withCredentials: true,
      params: {
        calculateHashCode: true,
      },
    });
  }

  public updateOperationLineQuantity(farmIds: number[], harvestYear: number, body: { operationLineId: number; quantity: number }[]) {
    const operationLineIds = body.map((item) => item.operationLineId).join(',');
    return this.http.patch<{ operationLineId: number; quantity: number }[], { operationLineId: number; quantity: number }[]>(
      `${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/operationlines/${operationLineIds}`,
      body
    );
  }
}

export interface PatchedOperationLineResult {
  addedOperationLines: VraOperationLineDto[];
  deletedOperationLines: VraOperationLineDto[];
  updateOperationLines: VraOperationLineDto[];
}
