import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';
import { GrowthRegulation } from '../interfaces/growth-regulation.interface';
import { InsuranceRequestDTO } from '../interfaces/insurance-request.interface';

@Injectable()
export class GrowthRegulationRepository {
  constructor(
    private endpoints: EndpointsService,
    private httpClient: HttpClient
  ) {}

  public getGrowthRegulations(farmIds: number[], crop: string, harvestYear?: number): Observable<GrowthRegulation[]> {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.httpClient.post<GrowthRegulation[], { ids: number[] }>(
      `${this.endpoints.foApi}/farms/${harvestYear}/growthregulation/${crop}`,
      {
        ids: farmIds,
      }
    );
  }

  public getInsuredFields(farmIds: number[], harvestYear: number, fieldIds: number[]) {
    return this.httpClient.get<number[]>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/fields/${fieldIds}/fieldinsurance`);
  }

  public requestInsurance(harvestYear: number, body: InsuranceRequestDTO) {
    return this.httpClient.post<any, InsuranceRequestDTO>(`${this.endpoints.foApi}/farms/${harvestYear}/fields/fieldinsurance`, body);
  }
}
