import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { OperationType } from '@app/core/interfaces/operation.type';
import { OperationTypesRepo } from '@app/core/repositories/operation-types/operation-types-repo.service';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

export interface IOperationTypesService {
  get(): Observable<OperationType[]>;
}

@Injectable({
  providedIn: 'root',
})
export class OperationTypesService implements IOperationTypesService {
  private operationTypesCache = this.cacheService.create<OperationType[]>({
    defaultTtl: 1000 * 60 * 60,
  });

  constructor(
    private operationTypesRepo: OperationTypesRepo,
    private cacheService: CacheService,
    private farmStateService: FarmStateService
  ) {}

  public get() {
    return this.farmStateService.selectedFarmIds$.pipe(
      first(),
      switchMap((selectedFarmIds) => {
        const result = this.operationTypesCache.getOrSetAsync('operationTypes', () => {
          const filtered = this.operationTypesRepo.getOperationTypesSubscriptionFiltered(selectedFarmIds);
          return filtered as Observable<OperationType[]>;
        });
        return result.pipe(map((operationTypes) => this.trimOperationTypeNames(operationTypes)));
      })
    );
  }

  private trimOperationTypeNames(operationTypes: OperationType[]): OperationType[] {
    return operationTypes.map((operationType) => ({
      ...operationType,
      name: operationType.name.trim(),
    }));
  }
}
