import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() public label?: string;
  @Input() public disabled = false;
  @Input() public checked: boolean | undefined = false;
  @Input() public labelPosition: 'before' | 'after' = 'after';
  @Input() public indeterminate = false; // True renders indeterminate state regardless of checked value. Interactions (e.g. clicking) sets this to false.
  @Input() public isCircle = true;
  @Output() public onChange = new EventEmitter<boolean>();

  public propagateChange: any = (_: any) => {};

  public onTouched: any = (_: any) => {};

  public writeValue(obj: boolean): void {
    if (!obj) return;

    this.checked = obj;
    this.onChanged({ checked: obj });
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChanged($event: { checked: boolean }) {
    this.propagateChange($event.checked);
    this.onChange.emit($event.checked);
  }
}
