import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router, RouterStateSnapshot } from '@angular/router';
import { FarmService } from '@app/core/farm/farm.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Observable, of, race } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

interface LandmandDkParams {
  farmId: string;
  harvestYear: string;
  fieldId: string;
}

@Injectable()
export class LandmandDkResolverService {
  constructor(
    private router: Router,
    private farmService: FarmService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const params: LandmandDkParams = {
      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      farmId: route.paramMap.get('farmId'),

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      harvestYear: route.paramMap.get('harvestYear'),

      // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      fieldId: route.paramMap.get('fieldId'),
    };

    return race(this.onSuccess(params), this.onFailure(params));
  }

  private onSuccess(params: LandmandDkParams) {
    return of(this.validParams(params)).pipe(
      filter((isValid) => isValid),
      tap(() => this.successRedirect(params))
    );
  }

  private onFailure(params: LandmandDkParams) {
    return of(this.invalidParams(params)).pipe(
      filter((isInvalid) => isInvalid),
      tap(() => void this.navigate())
    );
  }

  private validParams(params: LandmandDkParams) {
    return !!(params && params.farmId && params.fieldId && params.harvestYear);
  }

  private invalidParams(params: LandmandDkParams) {
    return !this.validParams(params);
  }

  private mapParamsToQueryParams(params: LandmandDkParams) {
    const farmIds = JSON.stringify([+params.farmId]);
    const harvestYear = +params.harvestYear;
    const fieldId = +params.fieldId;

    return {
      harvestYear,
      fieldId,
      farmIds,
    };
  }

  private successRedirect(params: LandmandDkParams) {
    const queryParams = this.mapParamsToQueryParams(params);
    const farms$ = this.farmService.getFarmsFromIds([+params.farmId]);

    farms$
      .pipe(
        first(),
        tap((farms) => (this.farmStateService.selectedFarms = farms)),
        tap(() => (this.harvestYearStateService.harvestYear = queryParams.harvestYear))
      )
      .subscribe(() => void this.navigate({ queryParams, queryParamsHandling: 'merge' }));
  }

  private async navigate(extras: NavigationExtras = {}) {
    return await this.router.navigate(['map', 'cultivation-journal'], extras).catch((err) => console.warn(err));
  }
}
