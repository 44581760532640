import { Injectable } from '@angular/core';
import { HttpClient } from '@app/core/http/http-client';
import { ISecureUrlService } from '@app/core/secure-url/secure-url.service.interface';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class SecureUrlService implements ISecureUrlService {
  constructor(private http: HttpClient) {}

  public get(url: string): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      let objectUrl: string | null = null;

      const options = { responseType: 'blob' };

      this.http.get<Blob | MediaSource>(url, options).subscribe((res) => {
        objectUrl = URL.createObjectURL(res);
        observer.next(objectUrl);
        observer.complete();
      });
    });
  }
}
