import { Injectable } from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { Farm } from '@app/core/interfaces/farm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MapService } from '@app/core/map/map.service';
import { SoilSampleDTO } from '@app/core/repositories/soil-samples/soil-sample.interface';
import { SoilSamplesRepoService } from '@app/core/repositories/soil-samples/soil-samples-repo.service';
import { MethodTypes } from '@app/method-types.enum';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { Coordinate } from 'ol/coordinate';
import { SoilSampleShortName } from './soil-sample-side-drawer/soil-sample-short-name.enum';

const ValueKey = 'value';
const SampleTypeKey = 'sample-type';

@Injectable({
  providedIn: 'root',
})
export class SoilSampleFeatureService {
  constructor(
    private soilSamplesRepo: SoilSamplesRepoService,
    private mapService: MapService,
    private languageService: LanguageService
  ) {}

  public getSoilSamplesMapCoverFlowItem(isDisabled = false) {
    const soilSamplesMapCoverFlowItem = new MapCoverFlowItem({
      mapCoverFlowLayersId: MapLayerId.SOILSAMPLES,
      displayName: 'main.fieldmap.layers.soilSamples',
      name: this.languageService.getText('main.fieldmap.layers.soilSamples'), // for language specific sorting
      isVisible: true,
      isDisabled,
      layers: [
        {
          layerId: MapLayerId.SOILSAMPLES,
          layerType: MapLayerType.TILE,
          zIndex: 2,
          useAuthHeaders: true,
          isVisible: true,
          url: undefined,
        },
        {
          layerId: MapLayerId.SOILSAMPLELABELS,
          layerType: MapLayerType.VECTOR,
          zIndex: 3,
          isVisible: true,
        },
      ],
    });

    return soilSamplesMapCoverFlowItem;
  }

  public addOrUpdateSoilSamplesLabelsLayer(soilSamples: SoilSampleDTO[], parameterShortName: SoilSampleShortName) {
    const features = soilSamples.map((sample) => {
      const coordinates: Coordinate = [parseFloat(sample.longitude.replace(',', '.')), parseFloat(sample.latitude.replace(',', '.'))];

      const feature = this.mapService.getMapFeature(MapLayerId.SOILSAMPLELABELS, GeometryType.POINT, coordinates);

      const sampleResult = sample.sampleResults.find((result) => result.shortName === parameterShortName);

      const value = sampleResult ? sampleResult.value.toString() : '';
      feature.set(ValueKey, value);
      feature.set(SampleTypeKey, parameterShortName);

      return feature;
    });

    this.mapService.getMap().addOrUpdateLayerToMap(this.getSoilSamplesMapCoverFlowItem().layers[1], features);
  }

  public addOrUpdateSoilSamplesLayer(soilSamples: SoilSampleDTO[], farms: Farm[], harvestYear: number, parameterShortName: string) {
    const layer = { ...this.getSoilSamplesMapCoverFlowItem().layers[0] };

    layer.requestBody = {
      sampleDates: soilSamples.map((sample) => sample.sampleDate).filter((value, index, self) => self.indexOf(value) === index),
      farmIds: farms.map((farm) => farm.id),
    };
    layer.method = MethodTypes.POST;
    layer.url = this.soilSamplesRepo.getSoilSampleTilesUrl(harvestYear, parameterShortName);

    this.mapService.getMap().addOrUpdateLayerToMap(layer);
  }

  public removeSoilSamplesLayer() {
    this.mapService.getMap().removeLayerFromMap(MapLayerId.SOILSAMPLES);
  }

  public removeSoilSamplesLabelsLayer() {
    this.mapService.getMap().removeLayerFromMap(MapLayerId.SOILSAMPLELABELS);
  }
}
