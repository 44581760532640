<ng-container *appFeatureToggle="'plant_cropmanager_n-tool'">
  <app-accordion
    [isAxel]="true"
    [templateRef]="nTool"
    boldTitle="{{ 'nTool.boldTitle' | translate }}"
    normalTitle="{{ 'nTool.normalTitle' | translate: { fieldNumber: (field$ | async)?.number } }}"
  >
  </app-accordion>
</ng-container>
<ng-template #nTool>
  <app-n-tool [field$]="$any(field$)"></app-n-tool>
</ng-template>
<div>
  <app-component-selector [initialComponent]="selectedValue" (onValueChange)="valueChange($event)"></app-component-selector>
  <app-square-button
    class="action-buttons"
    [buttonType]="'Primary'"
    (clicked)="onCalcNNeedsClick()"
    appGTagEventTracker
    eventName="calculateNitrogenNeed (click)"
    eventCategory="Field Plan"
    >{{ 'nTool.calc-N-Needs' | translate | capitalize }}</app-square-button
  >
</div>
<app-n-tools-table [initialField$]="$any(field$)"></app-n-tools-table>
