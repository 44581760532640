<app-benchmark-chart-filter (filterChange)="filters$.next($event)"></app-benchmark-chart-filter>
<app-spinner *ngIf="!isReady"></app-spinner>

<ng-container *ngIf="cropName && cropName !== '' && isReady">
  <h5>{{ 'main.benchmark.regionalComparison' | translate }} {{ regionTitle }}</h5>
  <app-regional-chart
    *ngIf="chartData.benchmarkDistribution.length"
    [avgValue]="chartData.avgFieldBenchmarkValue"
    [series]="chartData.benchmarkDistribution"
    [chartTitle]="cropName"
    unit="{{ 'common.unitType.kr' | translate }}"
    xAxisTitle="{{ 'main.benchmark.price' | translate }} kr. per hkg"
  ></app-regional-chart>
  <p *ngIf="!chartData.benchmarkDistribution.length">{{ 'main.benchmark.insufficientData' | translate }}</p>
  <app-chart-info
    *ngIf="chartData.benchmarkDistribution.length"
    [regionalAverage]="chartData.benchmarkValue!"
    [personalAverage]="chartData.avgFieldBenchmarkValue"
    [distribution]="chartData.benchmarkDistribution"
    [unit]="chartData.unit"
  >
  </app-chart-info>
</ng-container>
