<app-spinner *ngIf="isSpinnerVisible$ | async" [hasBackdrop]="true"></app-spinner>
<div class="wrapper">
  <app-side-drawer-header (action)="onCloseFeedback()" title="{{ 'main.feedback.title' | translate }}"></app-side-drawer-header>
  <app-side-drawer-body>
    <div class="feedback-sent-content" *ngIf="isFeedbackSent$ | async">
      <p class="feedback-sent-message">{{ 'main.feedback.thankYou' | translate }}</p>
      <app-icon icon="paper_plane"></app-icon>
    </div>
    <div class="feedback-content" *ngIf="!(isFeedbackSent$ | async)">
      <p class="contact">{{ 'main.feedback.contactLine1' | translate }}</p>
      <p class="contact">{{ 'main.feedback.contactLine2' | translate }}</p>
      <p class="contact phone">{{ 'main.feedback.contactPhone' | translate }}</p>

      <h5 class="opening-hours">{{ 'main.feedback.openingHours.title' | translate }}</h5>
      <p class="contact">{{ 'main.feedback.openingHours.mondayToThursday' | translate }}</p>
      <p class="contact">{{ 'main.feedback.openingHours.friday' | translate }}</p>

      <form class="feedback-form" [formGroup]="feedbackForm" *ngIf="feedbackForm">
        <app-textarea formControlName="feedback" [placeholder]="'main.feedback.typeYourFeedback' | translate"></app-textarea>
        <app-file-upload
          #fileUpload
          labelText="{{ 'main.feedback.addFiles' | translate }}"
          placeholderText="{{ 'main.feedback.noFilesAdded' | translate }}"
          [allowMultipleFiles]="true"
          (fileInputChange)="onFileInputChange($event)"
        >
        </app-file-upload>
      </form>
    </div>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button
      [disabled]="feedbackControl!.invalid"
      (clicked)="footerClicked({ methodName: 'onSendFeedback', param1: $event })"
      appGTagEventTracker
      eventName="sendFeedback (click)"
      eventCategory="Administration"
      >{{ 'main.feedback.send' | translate }}</app-side-drawer-footer-button
    >
  </app-side-drawer-footer>
</div>
