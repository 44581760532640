import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldInfoElement } from './field-info-element.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-field-info-element]',
  templateUrl: './field-info-element.component.html',
  styleUrls: ['./field-info-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldInfoElementComponent {
  @Input() public data!: FieldInfoElement;
}
