<mat-card appearance="outlined" class="task-card" (click)="editButtonClicked(task)">
  <mat-card-title>
    <div class="title">{{ task.operationTypeGroupNames! | translate }}</div>
    <mat-menu #taskCardMenu="matMenu">
      <button class="task-menu-item" mat-menu-item (click)="editButtonClicked(task)">
        <app-icon icon="edit_outline"></app-icon>
        <span>{{ 'taskCards.tooltips.editTask' | translate }}</span>
      </button>
      <button class="task-menu-item" [disabled]="disable" mat-menu-item (click)="deleteButtonClicked(task)">
        <app-icon icon="delete_outline"></app-icon>
        <span>{{ 'taskCards.tooltips.deleteTask' | translate }}</span>
      </button>
      <button *ngIf="task.registered" [disabled]="disable" class="task-menu-item" mat-menu-item (click)="scheduleButtonClicked(task)">
        <app-icon icon="schedule"></app-icon>
        <span>{{ 'taskCards.tooltips.scheduleTask' | translate }}</span>
      </button>
      <button *ngIf="!task.registered" [disabled]="disable" class="task-menu-item" mat-menu-item (click)="completeButtonClicked(task)">
        <app-icon icon="check"></app-icon>
        <span>{{ 'taskCards.tooltips.completeTask' | translate }}</span>
      </button>
    </mat-menu>
    <div class="waves-effect waves-circle transparent more-btn" (click)="$event.stopPropagation()">
      <app-icon icon="more_vert" [matMenuTriggerFor]="taskCardMenu"></app-icon>
    </div>
  </mat-card-title>

  <mat-card-content>
    <div class="list-item">
      <div>{{ task.date! | dateFormat }}</div>
      <div>
        <span *ngIf="!task?.registered">
          {{ 'taskGridItem.planned' | translate }}
        </span>
        <span *ngIf="task?.registered">
          {{ 'taskGridItem.done' | translate }}
          <app-icon icon="check" class="check-icon"></app-icon>
        </span>
      </div>
    </div>
    <div class="list-items">
      <div *ngFor="let operation of task?.operations">
        <div class="list-item" *ngFor="let line of operation.operationLines">
          <div class="item-name">{{ line.produceNorm?.name }}</div>
          <div class="item-amount">
            {{ line.quantity | number: '0.0-2' }} {{ line.produceNorm?.unitText }}/{{ 'common.hectareShort' | translate }}
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
