<div class="drone-image-information-wrapper">
  <mat-card appearance="outlined" class="drone-image-card">
    <mat-card-content>
      <div class="drone-image-information-header">
        <p class="header">{{ 'main.fieldmap.droneImageImport.information.header' | translate }} {{ fieldName$ | async }}</p>
        <div class="info"><app-icon icon="info_outline" class="info-hover" (click)="onDroneImageInfoClick($event)"></app-icon></div>
      </div>
      <div class="drone-image-picker-wrapper">
        <div class="drone-items">
          <div
            class="drone-item"
            *ngFor="let droneImageItem of (droneImage$ | async)!.pictures; let i = index"
            (click)="onImageDateClick(droneImageItem, i)"
            [ngClass]="{ 'drone-item-selected': i === selectedIndex }"
          >
            <span class="drone-item-date">{{ droneImageItem.imageDate | dateFormat }}</span>
            <span class="drone-item-name">{{ droneImageItem.name }}</span>
            <span class="drone-item-status">{{ droneImageItem.statusId! | droneImageStatus }}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
