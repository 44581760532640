import { Component } from '@angular/core';
import { CropModels } from '@app/core/enums/crop-models.enum';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { map } from 'rxjs/operators';
import { DecisionSupportService } from './decision-support.service';

@Component({
  selector: 'app-decision-support',
  templateUrl: './decision-support.component.html',
  styleUrls: ['./decision-support.component.scss'],
})
export class DecisionSupportComponent {
  protected field$ = this.decisionSupportService.field$;

  protected hasHayHarvestPrognosis$ = this.decisionSupportService.fieldHasModel(CropModels.HayHarvestPrognosis);
  protected hasGrazingpotential$ = this.decisionSupportService.fieldHasModel(CropModels.Grazingpotential);
  protected hasCornEyeSpot$ = this.decisionSupportService.fieldHasModel(CropModels.CornEyeSpot);
  protected hasCornHarvestPrognosis$ = this.decisionSupportService.fieldHasModel(CropModels.CornHarvestPrognosis);
  protected hasWaterBalance$ = this.decisionSupportService.fieldHasModel(CropModels.WaterBalance);
  protected hasGrowthStages$ = this.decisionSupportService.fieldHasModel(CropModels.Growthstage);
  protected hasWheatSeptoria$ = this.decisionSupportService.fieldHasModel(CropModels.WheatSeptoria);

  protected disableFieldData$ = this.accessControlService.hasAccessTo('field_plan_information').pipe(map((x) => !x));
  protected disableWaterBalance$ = this.accessControlService.hasAccessTo('field_plan_water_balance').pipe(map((x) => !x));

  protected disableHayHarvestPrognosis$ = this.accessControlService.hasAccessTo('field_plan_hay_harvest_prognosis').pipe(map((x) => !x));
  protected disableGrazingPrognosis$ = this.accessControlService.hasAccessTo('field_plan_grazings_prognosis').pipe(map((x) => !x));
  protected disableCornHarvestPrognosis$ = this.accessControlService.hasAccessTo('field_plan_corn_harvest_prognosis').pipe(map((x) => !x));
  protected disableEyespot$ = this.accessControlService.hasAccessTo('field_plan_eyespot').pipe(map((x) => !x));
  protected disableGrowthStage$ = this.accessControlService.hasAccessTo('field_plan_growth_stage').pipe(map((x) => !x));
  protected disableWheatSeptoria$ = this.accessControlService.hasAccessTo('field_plan_septoria').pipe(map((x) => !x));
  protected disableEditFields$ = this.accessControlService.hasAccessTo('field_plan_edit_fields').pipe(map((x) => !x));

  constructor(
    private decisionSupportService: DecisionSupportService,
    private accessControlService: AccessControlService
  ) {}
}
