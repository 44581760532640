import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { AsAppliedFilePickerComponent } from './as-applied-file-picker.component';

@NgModule({
  declarations: [AsAppliedFilePickerComponent],
  imports: [CommonModule, SharedModule],
  exports: [AsAppliedFilePickerComponent],
})
export class AsAppliedFilePickerModule {}
