<form class="data-connection-form" novalidate (ngSubmit)="onFormSubmit(settingFormGroup)" [formGroup]="settingFormGroup">
  <app-spinner [hasBackdrop]="true" [message]="loadingMessage" *ngIf="isLoading"></app-spinner>
  <h4 class="add-edit-modal-title">{{ 'main.settings.dataConnections.modalTitle' | translate }}</h4>
  <app-select formControlName="connectionTypeId" placeholder="{{ 'main.settings.dataConnections.type' | translate }}">
    <app-select-option *ngFor="let type of dataConnectionTypes$ | async" [value]="type.id">
      {{ type.name }}
    </app-select-option>
    <mat-error *ngIf="connectionTypeIdControl?.hasError('min')">
      {{ 'main.settings.dataConnections.validationMsg.missingDataConnections' | translate }}
    </mat-error>
  </app-select>

  <app-input type="text" formControlName="name" *ngIf="!isCNHConnection" [placeholder]="'main.settings.dataConnections.name' | translate">
    <app-input-error *ngIf="connectionNameControl?.hasError('maxlength')"
      >{{ 'common.validationMsg.maxLength50' | translate }}
    </app-input-error>
    <app-input-error *ngIf="connectionNameControl?.hasError('required')"
      >{{ 'common.validationMsg.required' | translate }}
    </app-input-error>
    <app-input-error *ngIf="connectionNameControl?.errors?.['nameIsUsed']">{{
      connectionNameControl?.errors?.['nameIsUsed'] | translate
    }}</app-input-error>
  </app-input>

  @if (isJDConnection && !settingFormGroup.get('dataIsMissing')?.value && settingFormGroup.get('additionalInfo')?.value) {
    <app-input
      type="text"
      formControlName="additionalInfo"
      [placeholder]="'main.settings.dataConnections.johnDeere.organization' | translate"
      [disabled]="true"
      [readonly]="true"
    ></app-input>
  }

  <app-ranch-form [ranchFormGroup]="$any(settingFormGroup.get('credentials'))" *ngIf="isRanchConnection"></app-ranch-form>

  <app-field-sense-form
    [fieldSenseFormGroup]="$any(settingFormGroup.get('credentials'))"
    *ngIf="isFieldSenseConnection"
  ></app-field-sense-form>
  <app-claas-trimble-form
    [claasTrimbleFormGroup]="$any(settingFormGroup.get('credentials'))"
    *ngIf="isClaasConnection || isTrimbleConnection"
  >
  </app-claas-trimble-form>

  <app-john-deere-form (linkClick)="onJDLinkClick()" *ngIf="isJDConnection"> </app-john-deere-form>
  <app-cnh-form *ngIf="isCNHConnection"></app-cnh-form>

  <app-agri-router-form
    (linkClick)="onAgriRouterLinkClick()"
    *ngIf="isAgriRouterConnection"
    [disabled]="!!connectionNameControl?.errors?.['nameIsUsed']"
  ></app-agri-router-form>

  <div class="dialog-actions">
    <div class="info-hover">
      <app-info-hover *ngIf="isRanchConnection || isFieldSenseConnection" [buttonText]="'main.settings.dataConnections.info' | translate">
        <div *ngIf="isRanchConnection" class="info-ranch">
          <p>{{ 'main.settings.dataConnections.ranchSystems.info.text1' | translate }}</p>
        </div>
        <div *ngIf="isFieldSenseConnection" class="info-fieldSence">
          <p>{{ 'main.settings.dataConnections.fieldSence.info.text1' | translate }}</p>
          <p>{{ 'main.settings.dataConnections.fieldSence.info.text2' | translate }}</p>
        </div>
      </app-info-hover>
    </div>
    <div class="add-edit-buttons">
      <app-square-button class="cancel" (click)="onCancelClicked()" *ngIf="!isLoading">
        {{ 'common.cancel' | translate }}
      </app-square-button>
      <app-square-button
        [buttonType]="'Primary'"
        [disabled]="settingFormGroup.invalid"
        (click)="onFormSubmit(settingFormGroup)"
        class="confirm"
        *ngIf="!isLoading && (isClaasConnection || isTrimbleConnection || isRanchConnection || isFieldSenseConnection)"
      >
        {{ isEditing ? ('main.settings.dataConnections.save' | translate) : ('main.settings.dataConnections.add' | translate) }}
      </app-square-button>
    </div>
  </div>
</form>
