<div class="file-input">
  <app-square-button [disabled]="disabled" buttonType="Secondary" (clicked)="input.click()">{{ labelText }}</app-square-button>
  <div class="file-path-wrapper" (click)="input.click()">
    <p>{{ fileName ? fileName : placeholderText }}</p>
    <app-icon *ngIf="fileName" class="clear-btn" icon="Luk" (click)="clearFiles(); input.value = ''; $event.stopPropagation()"></app-icon>
  </div>
  <input
    #input
    class="input-overlay"
    type="file"
    title="overlay"
    (change)="onFileInputChange($event)"
    [accept]="acceptFileExtensions.join(',')"
    [multiple]="allowMultipleFiles"
  />
  <p *ngIf="!areFileTypesValid">{{ invalidFileTypesMessage }}</p>
</div>
