import { MatDateFormats } from '@angular/material/core';
import { LanguageService } from './language.service';

export class LanguageConstants {
  public static getDateFormat(languageShortKey: string | undefined) {
    switch (languageShortKey) {
      case 'da':
        return {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'dd-MM-yyyy',
          LL: 'd. MMMM yyyy',
          LLL: 'd. MMMM yyyy HH:mm',
          LLLL: 'EEEE [d.] d. MMMM yyyy [kl.] HH:mm',
          l: 'dd-MM',
          ll: 'dd-MM-yyyy HH:mm',
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'dd-MM-yyyy',
            LL: 'd. MMMM yyyy',
            LLL: 'd. MMMM yyyy HH:mm',
            LLLL: "EEEE [d.] d. MMMM yyyy 'kl.' HH:mm",
            l: 'dd-MM-yyyy',
            ll: 'dd-MM-yyyy HH:mm',
          },
        };
      case 'en':
        return {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'dd/MM/yyyy',
          LL: 'd MMMM yyyy',
          LLL: 'd MMMM yyyy HH:mm',
          LLLL: 'EEEE, d MMMM yyyy HH:mm',
          l: 'dd/MM',
          ll: 'dd/MM/yyyy HH:mm',
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'dd/MM/yyyy',
            LL: 'd MMMM yyyy',
            LLL: 'd MMMM yyyy HH:mm',
            LLLL: 'EEEE, d MMMM yyyy HH:mm',
            l: 'dd/MM/yyyy',
            ll: 'dd/MM/yyyy HH:mm',
          },
        };
      default:
        // da-dk
        return {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'dd-MM-yyyy',
          LL: 'd. MMMM yyyy',
          LLL: 'd. MMMM yyyy HH:mm',
          LLLL: 'EEEE [d.] d. MMMM yyyy [kl.] HH:mm',
          l: 'dd-MM',
          ll: 'dd-MM-yyyy HH:mm',
          longDateFormat: {
            LT: 'HH:mm',
            LTS: 'HH:mm:ss',
            L: 'dd-MM-yyyy',
            LL: 'd. MMMM yyyy',
            LLL: 'd. MMMM yyyy HH:mm',
            LLLL: 'EEEE [d.] d. MMMM yyyy [kl.] HH:mm',
            l: 'dd-MM-yyyy',
            ll: 'dd-MM-yyyy HH:mm',
          },
        };
    }
  }

  public static getKendoDateFormat(languageShortKey: string) {
    switch (languageShortKey) {
      case 'da':
        return {
          L: 'dd-MM-yyyy',
        };
      case 'en':
        return {
          L: 'dd/MM/yyyy',
        };
      default:
        // da-dk
        return {
          L: 'dd-MM-yyyy',
        };
    }
  }

  public static getAppliedFileDateFormat(languageShortKey: string) {
    switch (languageShortKey) {
      case 'da':
        return {
          LS: 'dd-M-yyyy',
        };
      case 'en':
        return {
          LS: 'dd_M_yyyy',
        };
      default:
        // da-dk
        return {
          LS: 'dd-M-yyyy',
        };
    }
  }

  public static getMatDateFormat(languageService: LanguageService): MatDateFormats {
    switch (languageService.currentLanguage.shortKey) {
      case 'da':
        return {
          parse: {
            dateInput: 'dd-MM-yyyy',
          },
          display: {
            dateInput: 'dd-MM-yyyy',
            monthYearLabel: 'MMM yyyy',
            dateA11yLabel: 'dd-MM-yyyy',
            monthYearA11yLabel: 'MMMM yyyy',
          },
        };
      case 'en':
        return {
          parse: {
            dateInput: 'dd/MM/yyyy',
          },
          display: {
            dateInput: 'dd/MM/yyyy',
            monthYearLabel: 'MMM yyyy',
            dateA11yLabel: 'dd/MM/yyyy',
            monthYearA11yLabel: 'MMMM yyyy',
          },
        };
      default:
        return {
          parse: {
            dateInput: 'dd-MM-yyyy',
          },
          display: {
            dateInput: 'dd-MM-yyyy',
            monthYearLabel: 'MMM yyyy',
            dateA11yLabel: 'dd-MM-yyyy',
            monthYearA11yLabel: 'MMMM yyyy',
          },
        };
    }
  }

  constructor() {}
}
