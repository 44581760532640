export class DirtyState {
  private _className: string;
  private _moduleName?: string;
  private _isDirty: boolean;
  constructor(className: string, isDirty: boolean, moduleName?: string) {
    this._isDirty = isDirty;
    this._className = className;
    this._moduleName = moduleName;
  }

  public get className() {
    return this._className;
  }

  public get moduleName() {
    return this._moduleName;
  }

  public get isDirty() {
    return this._isDirty;
  }
}
