import { Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Directive()
export abstract class ButtonParentComponent<ButtonTypes = any> {
  @Input() public type: 'button' | 'submit' = 'button';
  @Input() public buttonType?: ButtonTypes;
  @Input() public iconButton = false;
  @Input() public text = '';

  @HostBinding('attr.disabled')
  @Input()
  public disabled: boolean | null = false;

  @Input() public buttonStyle?: { [key: string]: string };
  @Input() public buttonClass?: { [key: string]: boolean };

  @Output() public clicked = new EventEmitter<void>();

  public onClick() {
    if (!this.disabled) this.clicked.emit();
  }
}
