export enum BasisLayerCategoryType {
  unknown,
  hills,
  hollow,
  windbreaks,
  headland,
  waterlogged,
  sandySpots,
  other,
}
