<div class="search ml-15">
  <input
    class="search-input"
    id="e2e-farms-search"
    type="text"
    [formControl]="term"
    placeholder="{{ 'searchField.search' | translate }}"
    value="{{ initValue }}"
  />
  <div class="search-icon-container">
    <app-icon icon="search"></app-icon>
  </div>
</div>
