import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExecutedLocationCollection } from '../../executed-task.class';

@Component({
  selector: 'app-as-applied-task-info',
  templateUrl: './as-applied-task-info.component.html',
  styleUrls: ['./as-applied-task-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsAppliedTaskInfoComponent {
  @Input() public appliedTasks?: ExecutedLocationCollection[] | null;
}
