import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FarmService } from '@app/core/farm/farm.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LanguageService } from '../language/language.service';
import { NotificationService } from '../notification/notification.service';

/**
 * Use this guard for disabling routes if farm is a pre condition.
 */
@Injectable({
  providedIn: 'root',
})
export class FarmGuard {
  constructor(
    private farmStateService: FarmStateService,
    private farmService: FarmService,
    private router: Router,
    private notificationService: NotificationService,
    private languageService: LanguageService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    return this.farmStateService.selectedFarms$.pipe(
      switchMap((farms) => {
        const hasFarms = farms.length > 0;
        if (!hasFarms) {
          const farmIdsRaw = route.queryParams['farmIds'] || null;
          const farmIds: number[] = JSON.parse(farmIdsRaw);

          if (farmIds && farmIds.length > 0) {
            return this.hasFarmsFromQueryParam(farmIds);
          }

          this.notificationService.showError(this.languageService.getText('navigation.noFarms'));
          this.router.navigate(['']).catch((err) => console.error(err));
          return of(false);
        }

        return of(true);
      })
    );
  }

  private hasFarmsFromQueryParam(farmIds: number[]) {
    return this.farmService.getFarmsFromIds(farmIds).pipe(
      map((farmsFromBE) => {
        const hasFarmsFromBE = farmsFromBE.length > 0;

        if (!hasFarmsFromBE) {
          this.router.navigate(['']).catch((err) => console.error(err));
          return false;
        }

        return true;
      })
    );
  }
}
