<app-chart-wrapper [showSpinner]="isLoading" loadingMessage="cornHarvestPrognosis.fetchingData">
  <app-square-button (clicked)="onSettingsClick()">
    <app-icon icon="navigate_next"></app-icon>
    {{ 'cornHarvestPrognosis.openSettings' | translate }}
  </app-square-button>
  <app-square-button class="info-button" (clicked)="toggleInfo()">
    <app-icon class="info" icon="info_outline"></app-icon>
    {{ 'cornHarvestPrognosis.openInfo' | translate }}
  </app-square-button>
  <kendo-chart
    [categoryAxis]="categoryAxis"
    [valueAxis]="valueAxis"
    (render)="onRender($event)"
    data-testid="cornHarvestPrognosis.kendo-chart"
  >
    <kendo-chart-tooltip>
      <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
        {{ category | dateFormat }}
        <br />
        {{ value | number: '1.1-1' }}% {{ tooltipUnit | capitalize }}
      </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend [visible]="true" position="bottom" orientation="horizontal"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        [style]="'smooth'"
        type="line"
        [markers]="prognosisMarkers"
        [data]="prognosisSeries"
        [name]="prognosisTitle"
        color="#4E808D"
      ></kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>

  <div class="error-messages" *ngIf="!isLoading && errorMessages.length">
    <p *ngFor="let msg of errorMessages">{{ msg }}</p>
  </div>
</app-chart-wrapper>
