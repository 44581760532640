import { Circle } from 'ol/style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

export const BasisStyles = {
  StandardStyle: {
    'stroke-color': '#ffffff',
    'stroke-width': ['match', ['get', 'selectedBinary'], 1, 3, 1],
    'fill-color': ['get', 'fill'],
    'stroke-miter-limit': 4,
  },

  DrawingStyle: [
    new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: [255, 0, 0, 0.5],
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 1,
        }),
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
      }),
      fill: new Fill({
        color: [224, 224, 212, 0.8],
      }),
      zIndex: 3,
    }),
  ],
};
