import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxLength]',
})
export class MaxLengthDirective {
  @Input('appMaxLength') maxLengthInput: string | number | null = null;
  private maxLength: number = 100;

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  ngOnInit() {
    if (this.maxLengthInput !== null) {
      if (typeof this.maxLengthInput === 'string') {
        this.maxLength = parseInt(this.maxLengthInput, 10);
      } else {
        this.maxLength = this.maxLengthInput;
      }
      if (isNaN(this.maxLength)) {
        this.maxLength = 100; // Fallback if parsing failed
      }
    }
    this.checkLength(this.el.nativeElement.value); // Initial check
  }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    this.checkLength(this.el.nativeElement.value);
  }

  private checkLength(value: string) {
    if (value.length > this.maxLength) {
      const trimmedValue = value.slice(0, this.maxLength);
      this.el.nativeElement.value = trimmedValue;
      this.control.control?.setValue(trimmedValue, { emitEvent: false });
    }
  }
}
