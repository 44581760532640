import { Component, Input, OnInit } from '@angular/core';
import { BenchmarkDistributionPct } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-regional-comparison-chart',
  templateUrl: './regional-comparison-chart.component.html',
  styleUrls: ['./regional-comparison-chart.component.scss'],
})
export class RegionalComparisonChartComponent implements OnInit {
  @Input() public userYeldAverage = 0;
  @Input() public unit!: string;
  @Input()
  set series(series: BenchmarkDistributionPct[]) {
    this._series = this.fixBoundaries(series);
  }
  get series(): BenchmarkDistributionPct[] {
    return this._series;
  }

  // @ts-ignore - TS7008 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public userAverage;

  // @ts-ignore - TS7008 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public xAxisTitle;

  // @ts-ignore - TS7008 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public yAxisTitle;
  private _series: BenchmarkDistributionPct[] = [];

  constructor(private translate: TranslateService) {}

  public ngOnInit() {
    this.userAverage = this.findClosestEl(this.series, this.userYeldAverage);
    this.setupAxisTitles();
  }

  public markerVisual = (args: any): any => {
    if (args.dataItem === this.userAverage) {
      const point = args.createVisual();
      point.stroke('rgb(233, 93, 15)', 10).fill('rgb(233, 93, 15)');
      return point;
    }
  };

  public markerHighlight = (args: any): any => {
    if (args.dataItem === this.userAverage) {
      const point = args.createVisual();
      point.stroke('rgb(233, 93, 15)', 8).fill('rgb(233, 93, 15)');
      return point;
    } else {
      return args.createVisual();
    }
  };

  // To make a pretty graph we ensure that there is a value at 0% and 100%

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private fixBoundaries(seriesInput) {
    const series = seriesInput.slice();
    if (series.length < 1) {
      return series;
    }

    // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    series.sort((a, b) => {
      return a.aggPctOfFarmsWithYieldOrBelow - b.aggPctOfFarmsWithYieldOrBelow;
    });
    if (series[0].aggPctOfFarmsWithYieldOrBelow > 0) {
      series.unshift({
        yieldQuantity: series[0].yieldQuantity,
        aggPctOfFarmsWithYieldOrBelow: 0,
      });
    }
    if (series[series.length - 1].aggPctOfFarmsWithYieldOrBelow < 100) {
      series.push({
        yieldQuantity: series[series.length - 1].yieldQuantity,
        aggPctOfFarmsWithYieldOrBelow: 100,
      });
    }
    return series;
  }

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private findClosestEl(series, yieldAverage) {
    if (series.length) {
      let closestEl = series[0];

      // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      series.forEach((el) => {
        if (Math.abs(el.yieldQuantity - yieldAverage) < Math.abs(closestEl.yieldQuantity - yieldAverage)) {
          closestEl = el;
        }
      });
      return closestEl;
    }
  }

  private setupAxisTitles() {
    this.translate.get(['main.benchmark.regionalComp.xAxis', 'main.benchmark.regionalComp.yAxis']).subscribe((translations) => {
      this.xAxisTitle = translations['main.benchmark.regionalComp.xAxis'];
      this.yAxisTitle = translations['main.benchmark.regionalComp.yAxis'] + ' ' + this.unit + '/ha';
    });
  }
}
