<p class="legend-title">{{ legend.title | translate }}</p>

<div class="legend-content">
  <div class="colors">
    <div class="legend-entry" *ngFor="let entry of legend.entries">
      <div class="color" [ngStyle]="{ 'background-color': entry.color }"></div>
      <div>{{ entry.value }}</div>
    </div>
  </div>
</div>
