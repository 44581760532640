import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { NToolData } from '@app/shared/n-tool/n-tool-data';

@Injectable({
  providedIn: 'root',
})
export class NToolRepoService {
  constructor(
    private http: HttpClient,
    private endpointService: EndpointsService
  ) {}

  public get(harvestYear: number, farmId: number, fieldYearId: number): Observable<NToolData> {
    return this.http.get<NToolData>(`${this.endpointService.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldYearId}/NRequirement`);
  }
}
