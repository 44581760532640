import { Feature } from 'ol';
import { Point } from 'ol/geom';

interface RGBColor {
  red: number;
  green: number;
  blue: number;
  alpha?: number;
}

export class ColorUtil {
  /**
   * Maps the feature's color attribute to individual RGB components.
   * This ensures WebGL shaders correctly interpret the color.
   * Default color is set to `255, 1, 1` (red).
   */
  public static mapFeatureColors(features: Feature<Point>[]) {
    features.forEach((feature) => {
      const color = this.parseColor(feature.get('fill') ?? feature.get('color'));
      feature.set('red', color.red);
      feature.set('green', color.green);
      feature.set('blue', color.blue);
      feature.set('alpha', color.alpha ?? 1);
    });
  }

  /**
   * Parses a color value into its RGB components.
   *
   * @param colorValue - The color value to parse.
   * @returns Parsed RGB/RGBA values.
   */
  public static parseColor(colorValue: string | number[]): RGBColor {
    const DEFAULT_COLOR: RGBColor = { red: 255, green: 1, blue: 1 };

    if (typeof colorValue === 'string') {
      if (colorValue.startsWith('#')) {
        return this.parseHexColor(colorValue);
      } else if (colorValue.startsWith('rgb')) {
        return this.parseRgbColor(colorValue);
      }
    } else if (Array.isArray(colorValue) && (colorValue.length === 3 || colorValue.length === 4)) {
      return this.parseArrayColor(colorValue);
    }

    // Log a warning for unrecognized color formats.
    console.warn(`Unrecognized color format: ${colorValue}`);
    return DEFAULT_COLOR;
  }

  /**
   * Parses a HEX color string into its RGB components.
   *
   * @param colorValue - HEX color string to parse.
   * @returns Parsed RGB/RGBA values.
   */
  private static parseHexColor(colorValue: string): RGBColor {
    // Handle standard 6-digit (RRGGBB) or 8-digit (RRGGBBAA) HEX colors.
    if (colorValue.length === 7 || colorValue.length === 9) {
      return {
        red: parseInt(colorValue.substring(1, 3), 16),
        green: parseInt(colorValue.substring(3, 5), 16),
        blue: parseInt(colorValue.substring(5, 7), 16),
        alpha: colorValue.length === 9 ? parseInt(colorValue.substring(7, 9), 16) / 255 : 1,
      };
    }
    return { red: 255, green: 1, blue: 1 };
  }

  /**
   * Parses an RGB or RGBA color string into its RGB components.
   *
   * @param colorValue - RGB/RGBA color string to parse.
   * @returns Parsed RGB/RGBA values.
   */
  private static parseRgbColor(colorValue: string): RGBColor {
    // Using regex to capture both integer and percentage color values.
    const regex = /(\d+\.?\d*%?)/g;
    const values = colorValue.match(regex);

    // Ensure we have the right amount of captured values (3 for RGB, 4 for RGBA).
    if (values && (values.length === 3 || values.length === 4)) {
      return {
        red: this.parseColorValue(values[0]),
        green: this.parseColorValue(values[1]),
        blue: this.parseColorValue(values[2]),
        alpha: values.length === 4 ? parseFloat(values[3]) : 1,
      };
    }

    return { red: 255, green: 1, blue: 1 };
  }

  /**
   * Parses an array of RGB or RGBA values.
   *
   * @param colorValue - Array of RGB/RGBA values.
   * @returns Parsed RGB/RGBA values.
   */
  private static parseArrayColor(colorValue: number[]): RGBColor {
    return {
      red: colorValue[0],
      green: colorValue[1],
      blue: colorValue[2],
      alpha: colorValue.length === 4 ? colorValue[3] : 1,
    };
  }

  /**
   * Converts an individual color value (either an integer or percentage) to its 0-255 range representation.
   *
   * @param value - The color component value to parse.
   * @returns Parsed value in 0-255 range.
   */
  private static parseColorValue(value: string): number {
    if (value.endsWith('%')) {
      // Convert percentage values to the 0-255 range.
      return Math.round(parseFloat(value) * 2.55);
    }
    return parseInt(value);
  }
}
