import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { GrassPrognosisService } from '@app/shared/grass-prognosis/grass-prognosis.service';
import { CheckboxModule } from '@fe-shared/ui-components/checkbox/checkbox.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ButtonsModule } from '../buttons/buttons.module';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { SelectModule } from '../select/select.module';
import { SideDrawerModule } from '../side-drawer/side-drawer.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TranslateModule } from '../translate/translate.module';
import { GrassPrognosisCardComponent } from './grass-prognosis-card/grass-prognosis-card.component';
import { GrassPrognosisInfoComponent } from './grass-prognosis-info/grass-prognosis-info.component';
import { GrassPrognosisResultsChartComponent } from './grass-prognosis-results-chart/grass-prognosis-results-chart.component';
import { GrassPrognosisSettingsComponent } from './grass-prognosis-settings/grass-prognosis-settings.component';
import { GrassPrognosisComponent } from './grass-prognosis.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    TranslateModule,
    ButtonsModule,
    SideDrawerModule,
    SelectModule,
    CheckboxModule,
    ReactiveFormsModule,
    DatePickerModule,
    SpinnerModule,
    MatDialogModule,
    ChartsModule,
    AppPipesModule,
  ],
  declarations: [
    GrassPrognosisComponent,
    GrassPrognosisCardComponent,
    GrassPrognosisSettingsComponent,
    GrassPrognosisInfoComponent,
    GrassPrognosisResultsChartComponent,
  ],
  exports: [GrassPrognosisComponent],
  providers: [GrassPrognosisService],
})
export class GrassPrognosisModule {}
