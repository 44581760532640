import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ChartWrapperModule } from '@app/shared/chart-wrapper/chart-wrapper.module';
import { RainChartComponent } from '@app/shared/rain-chart/rain-chart.component';
import { RainChartService } from '@app/shared/rain-chart/rain-chart.service';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ChartModule } from '@progress/kendo-angular-charts';
import { ButtonsModule } from '../buttons/buttons.module';
import { RainChartInfoComponent } from './rain-chart-info/rain-chart-info.component';

@NgModule({
  imports: [CommonModule, ChartWrapperModule, ChartModule, TranslateModule, ButtonsModule, MatDialogModule, IconModule],
  declarations: [RainChartComponent, RainChartInfoComponent],
  exports: [RainChartComponent],
  providers: [RainChartService],
})
export class RainChartModule {}
