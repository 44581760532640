import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { FieldCollectionItemComponent } from './field-collection-item/field-collection-item.component';
import { FieldSelectorComponent } from './field-selector.component';
import { FieldSelectorService } from './field-selector.service';

@NgModule({
  imports: [SharedModule],
  providers: [FieldSelectorService],
  declarations: [FieldSelectorComponent, FieldCollectionItemComponent],
  exports: [FieldSelectorComponent, FieldCollectionItemComponent],
})
export class FieldSelectorModule {}
