import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-n-tool-dialog',
  templateUrl: './n-tool-dialog.component.html',
  styleUrls: ['./n-tool-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NToolDialogComponent {
  constructor(private dialogRef: MatDialogRef<NToolDialogComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
