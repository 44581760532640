import { ColumnKey } from '@app/new-map/features/cultivation-journal/filter/column/column-key';
import { FilterItem } from '@app/new-map/features/cultivation-journal/filter/filter-item';
import { FilterLogicService } from '@app/new-map/features/cultivation-journal/filter/filter-logic.service';
import { FilterStateService } from '@app/new-map/features/cultivation-journal/filter/filter-state.service';
import { Filterable } from '@app/new-map/features/cultivation-journal/filter/filterable';
import { ScopeItem } from '@app/new-map/features/cultivation-journal/filter/scope-item';
import { FilterHelper } from '@app/new-map/features/cultivation-journal/filter/util/filter-helper';
import { Observable, ReplaySubject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

/**
 * Handles filtering for TreeListTables
 * For usage example see NToolsTableService
 */
export class TableFilterManager<T extends Filterable<T>> {
  private _originalData = new ReplaySubject<Filterable<T>[]>(1);
  constructor(
    private filterStateService: FilterStateService,
    private filterLogicService: FilterLogicService
  ) {}

  /**
   * Filters away @filterables that doesnt match at least one of the active filterItems for each column in the given scope.
   * If there are no active filterItems, the entire dataset is returned.
   * @param filterables
   * @param scope
   */
  public filterData(filterables: T[], scope?: ScopeItem): Observable<T[]> {
    this._originalData.next(filterables);
    return this.filterStateService.getFilterItems$(scope).pipe(
      map((filterItems) => {
        if (!filterItems.length) {
          return filterables;
        }
        const filterItemsByColumnKeys = this.groupFilterItemsByColumnKey(filterItems);
        return filterables.filter((filterable) =>
          filterItemsByColumnKeys.every((columnFilterItems) => this.filterItemsContainsMatchingFilterable(columnFilterItems, filterable))
        );
      })
    );
  }

  /**
   * Creates an array of arrays, containing filterItems grouped by columnKey.
   */
  private groupFilterItemsByColumnKey(filterItems: FilterItem<T>[]): FilterItem<T>[][] {
    const filterItemsByColumnKeys = new Map<ColumnKey, FilterItem<T>[]>();
    filterItems.forEach((filterItem) => {
      const columnKeyFilterItems = filterItemsByColumnKeys.get(filterItem.columnKey) ?? [];
      filterItemsByColumnKeys.set(filterItem.columnKey, [...columnKeyFilterItems, filterItem]);
    });
    return Array.from(filterItemsByColumnKeys.values());
  }

  /**
   * Checks if any of @filterItems matches @filterable
   * @param filterItems
   * @param filterable
   */
  private filterItemsContainsMatchingFilterable(filterItems: FilterItem<T>[], filterable: Filterable<T>): boolean {
    return filterItems.some((filterItem) => filterable.matchesFilterItem(filterItem));
  }

  public getAvailableFilterablesForColumn$(columnKey: ColumnKey, scope?: ScopeItem) {
    return this._originalData.pipe(
      withLatestFrom(this.filterStateService.getFilterItems$(scope)),
      map(([filterables, filterItems]) => FilterHelper.asAvailableFilters(columnKey, filterables, filterItems))
    );
  }

  /**
   * If a FilterItem matching @filterable exists, the FilterItem is removed from filter state.
   * Otherwise it is added to filter state.
   */
  public toggleFilterItem(filterable: T, columnKey: ColumnKey, scope?: ScopeItem) {
    this.filterLogicService.toggleFilterItem(filterable.asFilterItem(columnKey, scope ? [scope] : []));
  }
}
