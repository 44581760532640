import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { NToolsTableLineDto } from '@app/core/interfaces/n-tools/n-tools-table-line-dto.interface';
import { NToolsTablePatchDto } from '@app/core/interfaces/n-tools/n-tools-table-patch-dto.interface';
import {
  NitrogenNeedCalculateInputDto,
  NitrogenNeedInputDto,
  NitrogenNeedResultDto,
} from '@app/core/interfaces/n-tools/nitrogenNeed-TableDataDto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NToolsTableRepositoryService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(farmIds: number[], harvestYear: number): Observable<NToolsTableLineDto[]> {
    return this.httpClient.get<NToolsTableLineDto[]>(`${this.endpoints.foApi}/NTools/${farmIds}/${harvestYear}/NToolsTableData`, {});
    // .pipe(map((res) => this.randomizeColors(res)));
  }

  public patch(farmIds: number[], tableLineToUpdate: NToolsTablePatchDto): Observable<NToolsTableLineDto> {
    const fieldId = tableLineToUpdate.fieldId;

    return this.httpClient.patch<NToolsTableLineDto, NToolsTablePatchDto>(
      `${this.endpoints.foApi}/NTools/${farmIds}/${fieldId}/patchTableRow`,
      tableLineToUpdate
    ) as Observable<NToolsTableLineDto>;
  }

  public getNitrogenNeedCalculate(
    farmIds: number[],
    harvestYear: number,
    useActualNeed: boolean,
    nitrogenNeedCalculateInputDto: NitrogenNeedCalculateInputDto
  ): Observable<NitrogenNeedResultDto> {
    return this.httpClient.post<NitrogenNeedResultDto, NitrogenNeedCalculateInputDto>(
      `${this.endpoints.foApi}/NitrogenNeed/${farmIds}/${harvestYear}/calculate/?useActualNeed=${useActualNeed}`,
      nitrogenNeedCalculateInputDto
    ) as Observable<NitrogenNeedResultDto>;
  }

  public saveCropsNitrogenNeed(
    farmIds: number[],
    harvestYear: number,
    nitrogenNeedInputDtos: NitrogenNeedInputDto[]
  ): Observable<NitrogenNeedResultDto> {
    return this.httpClient.post<NitrogenNeedResultDto, NitrogenNeedInputDto[]>(
      `${this.endpoints.foApi}/NitrogenNeed/${farmIds}/${harvestYear}`,
      nitrogenNeedInputDtos
    ) as Observable<NitrogenNeedResultDto>;
  }

  /**
   * Used only for testing
   */
  private randomizeColors(res: NToolsTableLineDto[]) {
    const max = 3;
    const min = 1;
    res.forEach((item) => {
      item.cropNutrientAvailability = Math.floor(Math.random() * (max - min + 1)) + min;
    });
    return res;
  }
}
