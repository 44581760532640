import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BlightInfectionDataInfo } from '@app/core/interfaces/blight-infection-data-info.interface';
import { BlightInfoForField } from '@app/core/interfaces/blight-info-for-field.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { FeatureUtil } from '@app/new-map/helpers/utils/feature-util';
import { OlMapService } from '@app/new-map/map-service/ol-map.service';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { filterNullOrEmpty, filterNullish } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { distinct, finalize, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { PrognosisShownComponent } from '../prognosis-side-drawer/prognosis-shown-component.enum';
import { PrognosisService } from '../prognosis.service';
import { PrognosisSideDrawerComponent } from './../prognosis-side-drawer/prognosis-side-drawer.component';
import { BlightInfoComponent } from './blight-info/blight-info.component';
import { BlightLogicService } from './blight-logic.service';
import { BlightStateService } from './blight-state.service';

@Component({
  selector: 'app-potato-blight',
  templateUrl: './potato-blight.component.html',
  styleUrls: ['./potato-blight.component.scss'],
})
export class PotatoBlightComponent implements OnInit, OnDestroy, AfterContentChecked {
  protected readonly selectableMapLayers = [LayerId.BLIGHT_FIELDS];
  protected readonly hoverMapLayerId = [LayerId.BLIGHT_POLYGONS, LayerId.BLIGHT_FIELD_POLYGONS];
  protected readonly sideDrawerWidth$ = this.prognosisService.drawerWidth$;
  protected readonly loading$ = this.blightStateService.loading$.pipe(startWith(true));
  protected blightInfoForField$!: Observable<BlightInfoForField | null>;
  protected blightInfectionDataInfo!: BlightInfectionDataInfo;

  // If the screensize is mobile, and the sidedrawer is not hidden, the legend should not be visible
  protected hideLegend$ = combineLatest([
    this.screenSizeService.isMobile(),
    this.sideDrawerRef.onHide.pipe(startWith({ hidden: false })),
  ]).pipe(map(([mobile, hidden]) => (mobile ? !hidden.hidden : false)));

  private _subs = new Subscription();

  constructor(
    private blightLogicService: BlightLogicService,
    private blightStateService: BlightStateService,
    private prognosisService: PrognosisService,
    private sideDrawerRef: SideDrawerRef<PrognosisSideDrawerComponent, any, any>,
    private _mapService: OlMapService,
    private _layerService: OlLayerService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private screenSizeService: ScreenSizeService,
    private dialogService: DialogService,
    private cdRef: ChangeDetectorRef,
    private _mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit() {
    this._layerService.setLayerVisibility(LayerId.FIELDS, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_FILL, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, false);
    this._layerService.setLayerVisibility(LayerId.FIELD_MARKERS, false);
    this._layerService.setActivePage('prognosis_blight');

    this._subs.add(this._mapService.mapReady$.subscribe(this.onMapReady.bind(this)));
    this._subs.add(this.blightStateService.hydrateFields().subscribe());

    this._subs.add(
      this.blightStateService.selectedBlightDate$
        .pipe(
          map((date) => DateTime.fromJSDate(date).set({ second: 0 }).toJSDate()),
          distinct(),
          switchMap((date) => this.handleBlightDateChange(date))
        )
        .subscribe()
    );

    this._subs.add(
      this.blightStateService.getBlightInfectionDataInfo().subscribe((blightInfectionDataInfo) => {
        this.blightInfectionDataInfo = blightInfectionDataInfo;
      })
    );

    this._subs.add(
      this.harvestYearStateService.harvestYear$.subscribe(() => {
        this.blightInfoForField$ = of(null);
      })
    );

    this._subs.add(this.blightStateService.loading$.subscribe((loading) => this.cdRef.detectChanges()));
  }

  private handleBlightDateChange(date: Date): Observable<any> {
    this._layerService.removeLayers([LayerId.BLIGHT_POLYGONS, LayerId.BLIGHT_FIELD_POLYGONS]);

    const polygons$ = this.blightStateService.getBlightPolygonsForMap(date).pipe(
      first(),
      tap((features) => {
        this.blightLogicService.createBlightMapLayer(features);
      })
    );

    const fields$ = combineLatest([
      this.harvestYearStateService.harvestYear$.pipe(filterNullish()),
      this.farmStateService.selectedFarmIds$.pipe(filterNullOrEmpty()),
    ]).pipe(
      first(),
      switchMap(([harvestYear, farms]) =>
        this.blightStateService.getBlightFieldsForMap(date, farms, harvestYear).pipe(
          first(),
          tap((features) => {
            this.blightLogicService.createBlightFieldLayer(features);
            const extent = FeatureUtil.getExtentOfFeatures(features);

            this._mapService.fitMapToExtent(extent);
          })
        )
      ),
      finalize(() => {})
    );

    return forkJoin([polygons$, fields$]);
  }

  public ngOnDestroy(): void {
    this.blightLogicService.resetHighResFieldMarkers();
    this._subs.unsubscribe();
    this._layerService.removeLayers([LayerId.BLIGHT_POLYGONS, LayerId.BLIGHT_FIELDS]);
    this._layerService.setLayerVisibility(LayerId.FIELDS, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_FILL, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_LABELS, true);
    this._layerService.setLayerVisibility(LayerId.FIELD_MARKERS, false);
    this._layerService.setLayerVisibility(LayerId.AIRPHOTO_DK, true);
    this._layerService.setLayerVisibility(LayerId.AIRPHOTO_FOREIGN, true);
    this._layerService.setLayerVisibility(LayerId.OSM, true);
    this._mapService.magicReRender();
  }

  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public onCloseClick() {
    this.prognosisService.setShownComponent(PrognosisShownComponent.PrognosisPicker);
  }

  public onHideClick() {
    this.sideDrawerRef.hide();
    this._mapLayerControlService.setSidedrawerWidth(SideDrawerConfig.widthAsClosedPx);
  }

  public onFieldSelect(field: Field) {
    if (field) {
      this.blightInfoForField$ = this.blightStateService.getBlightInfoForField(field.id);
      this.blightStateService.activeFeature = this._mapService.getFeaturesFromLayer(LayerId.BLIGHT_FIELDS).find((feature) => {
        return feature.get('field').id === field.id;
      });
    } else {
      this.blightInfoForField$ = of(null);
    }
  }

  private onMapReady() {
    this._subs.add(this.blightLogicService.startRenderingFieldsToMap());
    this.blightLogicService.enableSelectInteraction();
    // Only set zoomlevel at init
    if (!this.blightInfectionDataInfo) {
      this._mapService.slideMapToPoint([13, 56], 7, true);
    }
  }

  public toggleInfo() {
    this.dialogService
      .openCustomDialog(BlightInfoComponent, {
        maxWidth: '900px',
      })
      .afterClosed();
  }
}
