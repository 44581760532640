import { Component, OnDestroy, OnInit } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Region } from '@app/core/interfaces/map-region-feature.interface';
import { BenchmarkChartData } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { Observable, Subject, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, finalize, map, withLatestFrom } from 'rxjs/operators';
import { BenchmarkFilters } from '../chart-filter/chart-filter.component';
import { YieldService } from './service/yield.service';

@Component({
  selector: 'app-benchmark-yield',
  templateUrl: './yield.component.html',
  styleUrls: ['./yield.component.scss'],
})
export class YieldComponent implements OnInit, OnDestroy {
  private selectedFarms$!: Observable<Farm[]>;
  public readonly selectedRegions$: Observable<Region[]> = this.benchmarkStateService.selectedRegions$;

  private subscriptions: Subscription[] = [];
  private loadingCount = 0;

  public filters$ = new Subject<BenchmarkFilters>();
  public cropName = '';
  public regionTitle = '';

  public chartData: BenchmarkChartData = {
    benchmarkDistribution: [],
    benchmarkDistributionPct: [],
    farms: [],
    avgFieldBenchmarkValue: 0,
    unit: '',
    benchmarkFieldArea: 0,
    benchmarkFieldCount: 0,
    benchmarkValue: 0,
    name: '',
    normNumber: 0,
    totalFieldArea: 0,
    totalFieldCount: 0,
  };

  constructor(
    private yieldService: YieldService,
    private farmStateService: FarmStateService,
    private benchmarkStateService: BenchmarkStateService
  ) {}

  public ngOnInit() {
    this.selectedFarms$ = this.farmStateService.selectedFarms$;
    this.subscriptions.push(
      this.getFilterStream().subscribe((farmsAndFilters) => this.onFilterChange(farmsAndFilters.filters, farmsAndFilters.farmIds))
    );
    this.subscriptions.push(this.selectedRegions$.subscribe((regions) => this.onSelectedRegionsChange(regions)));
    this.subscriptions.push(this.yieldService.getHarvestYearAndFarmIdsStream().subscribe((val) => this.onHarvestYearAndFarmsChange(val)));
    this.yieldService.initialize();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.yieldService.destroy();
  }

  public onFilterChange(filters: BenchmarkFilters, farmsIds: number[]) {
    this.cropName = filters.crop!.cropName;
    this.getChartData(filters, farmsIds);
  }

  public onSelectedRegionsChange(regions: Region[]) {
    this.regionTitle = regions.map((region) => region.name).join(', ');
  }

  public onHarvestYearAndFarmsChange(farmsAndYear: { farmIds: number[]; year: number }) {
    this.clearData();
  }

  public getChartData(filters: BenchmarkFilters, farmIds: number[]) {
    this.loadingCount++;

    this.yieldService
      .getChartData(farmIds, filters)
      .pipe(
        finalize(() => this.loadingCount--),
        catchError((error) => {
          this.yieldService.showGetError(error);
          return observableThrowError(error);
        })
      )
      .subscribe((chartData) => {
        this.chartData = chartData;
      });
  }

  get isReady() {
    return !this.loadingCount;
  }

  private getFilterStream() {
    return this.filters$.pipe(
      withLatestFrom(this.selectedFarms$),
      map((value) => ({ filters: value[0], farmIds: value[1].map((farm) => farm.id) })),
      filter((value) => !!value.filters.crop)
    );
  }

  private clearData() {
    this.cropName = '';
    this.chartData = {
      benchmarkDistribution: [],
      benchmarkDistributionPct: [],
      farms: [],
      avgFieldBenchmarkValue: 0,
      unit: '',
      benchmarkFieldArea: 0,
      benchmarkFieldCount: 0,
      benchmarkValue: 0,
      name: '',
      normNumber: 0,
      totalFieldArea: 0,
      totalFieldCount: 0,
    };
  }
}
