import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { ClaasField } from '@app/core/interfaces/claas-field.interface';
import { ClaasWork } from '@app/core/interfaces/claas-work.interface';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { JohnDeereMachine } from '@app/core/interfaces/john-deere-machine.interface';
import { TrimbleMachine } from '@app/core/interfaces/trimble-machine.interface';
import { VraTaskFieldDto } from '@app/new-map/features/vra/interfaces/vra-task-field.interface';
import { DateTime } from 'luxon';
import { Observable, of, switchMap } from 'rxjs';

export interface DataExchangeMessages {
  saveFieldError: string;
  getWorksError: string;
  saveTaskSuccess: string;
  saveTaskError: string;
  missingPermissionError: string;
  saveAllTasksError: string;
  saveAllTasksSuccess: string;
}

export interface IDataExchangeRepo {
  getFields(settings: DataConnectionSettingDTO): Observable<Field[]>;

  getFieldsByFieldIds(fieldIds: string[], settings: DataConnectionSettingDTO): Observable<ClaasField[] | null>;

  saveField(field: Field, settings: DataConnectionSettingDTO, claasField: ClaasField): Observable<Response>;

  /**
   * Get works.
   * @param settings {DataConnectionSettingDTO} The settings to use for getting claas works.
   */
  getWorks(start: DateTime, end: DateTime, settings: DataConnectionSettingDTO): Observable<ClaasWork[]>;
  getJohnDeereMachines(settings: DataConnectionSettingDTO): Observable<JohnDeereMachine[]>;
}

@Injectable({
  providedIn: 'root',
})
export class DataExchangeRepo implements IDataExchangeRepo {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private dataConnectionsService: DataConnectionsService
  ) {}

  /**
   * Get fields.
   * Returns promise that resolves to array of fields.
   */
  public getFields(settings: DataConnectionSettingDTO) {
    return this.dataConnectionsService.getSettingById(settings.id).pipe(
      switchMap((setting) => {
        if (!setting) return of([]);

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = { headers: headersWithAuth };
        return this.http.get<Field[]>(`${this.endpoints.dataExchangeApi}/claas/getfields`, options);
      })
    );
  }

  public getFieldsByFieldIds(fieldIds: string[], setting: DataConnectionSettingDTO): Observable<ClaasField[] | null> {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (!setting) return of([]);

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = { headers: headersWithAuth };
        return this.http.post<ClaasField[], { val: string[] }>(
          `${this.endpoints.dataExchangeApi}/claas/getfields`,
          { val: fieldIds },
          options
        );
      })
    );
  }

  /**
   * Sends a field to Claas, overwriting the existing references to this field in Claas.
   * @param field {Field} - The field to save.
   */
  public saveField(field: Field, setting: DataConnectionSettingDTO, claasField: ClaasField) {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (!setting) return of(null);

        const fieldName = field.name || '';

        claasField.name = `${field.number} ${fieldName} [${field.harvestYear}]`;

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = { headers: headersWithAuth };
        return this.http.post<any, ClaasField>(`${this.endpoints.dataExchangeApi}/claas/postfields`, claasField, options);
      })
    );
  }

  /**
   * Get works.
   */
  public getWorks(start: DateTime, end: DateTime, setting: DataConnectionSettingDTO) {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (!setting) return of([]);

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = {
          headers: headersWithAuth,
          params: {
            from: start.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
            to: end.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'"),
          },
        };
        return this.http.get<ClaasWork[]>(`${this.endpoints.dataExchangeApi}/claas/works`, options);
      })
    );
  }

  /**
   * John Deere
   */

  /**
   * Get John Deere machines.
   */
  public getJohnDeereMachines(setting: DataConnectionSettingDTO): Observable<JohnDeereMachine[]> {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (!setting) return of([]);

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = {
          headers: headersWithAuth,
        };
        return this.http.get<JohnDeereMachine[]>(`${this.endpoints.foApi}/gateways/johndeere/machines`, options);
      })
    );
  }

  /**
   * Send Individual John Deere Machine
   */
  public sendIndividualJohnDeereMachine(setting: DataConnectionSettingDTO, field: VraTaskFieldDto, harvestYear: number, machineId: string) {
    const { farmId, vraPrescriptionMapId } = field;

    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (!setting) return of([]);

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = {
          headers: headersWithAuth,
        };

        return this.http.post<never, {}>(
          `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/prescriptionmaps/${vraPrescriptionMapId}/export/johnDeere/${machineId}`,
          {},
          options
        );
      })
    );
  }

  /**
   * Trimble
   */

  /**
   * Get Trimble devices.
   */
  public getTrimbleDevices(setting: DataConnectionSettingDTO): Observable<TrimbleMachine[]> {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (!setting) return of([]);

        const headers = new HttpHeaders();
        const headersWithAuth = headers.append('Authorization', this.encodeCredentials(setting.connectionId, setting.connectionCode));
        const options = {
          headers: headersWithAuth,
        };
        return this.http.get<TrimbleMachine[]>(`${this.endpoints.dataExchangeApi}/trimble/devices`, options);
      })
    );
  }

  /**
   * Encode credentials with base64
   */
  private encodeCredentials = (user: string, pass: string) => `Basic ${btoa(`${user}:${pass}`)}`;
}
