import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckboxModule } from '@fe-shared/ui-components/checkbox/checkbox.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '../buttons/buttons.module';
import { ChartWrapperModule } from '../chart-wrapper/chart-wrapper.module';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { DialogModule } from '../dialog/dialog.module';
import { InfoHoverModule } from '../info-hover/info-hover.module';
import { InputModule } from '../input/input.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { SelectModule } from '../select/select.module';
import { SideDrawerModule } from '../side-drawer/side-drawer.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TranslateModule } from '../translate/translate.module';
import { GrowthStagesChartComponent } from './growth-stages-chart/growth-stages-chart.component';
import { GrowthStagesInfoComponent } from './growth-stages-info/growth-stages-info.component';
import { GrowthstageCorrectionDialogComponent } from './growthstage-correction-dialog/growthstage-correction-dialog.component';
import { GrowthstageComponent } from './growthstage.component';
import { GrowthstageService } from './growthstage.service';
@NgModule({
  imports: [
    CommonModule,
    IconModule,
    TranslateModule,
    ButtonsModule,
    SideDrawerModule,
    SelectModule,
    CheckboxModule,
    ReactiveFormsModule,
    DatePickerModule,
    SpinnerModule,
    MatDialogModule,
    ChartsModule,
    AppPipesModule,
    ChartWrapperModule,
    DialogModule,
    InputModule,
    FormsModule,
    InfoHoverModule,
    DateInputsModule,
  ],
  declarations: [GrowthstageComponent, GrowthStagesChartComponent, GrowthStagesInfoComponent, GrowthstageCorrectionDialogComponent],
  exports: [GrowthstageComponent],
  providers: [GrowthstageService],
})
export class GrowthstageModule {}
