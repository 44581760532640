<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <textarea
    matInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    [errorStateMatcher]="errorStateMatcher"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    data-testid="mat-form-field"
  >
  </textarea>
  <mat-error *ngFor="let errorMsg of errorMsgs">
    <ng-container [ngTemplateOutlet]="errorMsg.templateRef!"></ng-container>
  </mat-error>
</mat-form-field>
