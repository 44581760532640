import { Injectable } from '@angular/core';
import { createGuid } from '@app/helpers/guid/guid-helper';

@Injectable({
  providedIn: 'root',
})
export class CorrelationIdService {
  public correlationId;

  constructor() {
    this.correlationId = createGuid();
  }
}
