/**
 * Returns the translation string for the soil type.
 * @param soilTypeId id of the soil type.
 */
export function getSoilType(soilTypeId: number) {
  switch (soilTypeId) {
    case 1:
    case 3:
      return 'main.growthRegulation.coarseSand';
    case 2:
    case 4:
      return 'main.growthRegulation.fineSand';
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return 'main.growthRegulation.clay';
    default:
      return 'main.growthRegulation.unknown';
  }
}
