import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Logger } from '@app/core/log/logger';
import { handleLegacyInternetExplorer, isInternetExplorer } from '@app/helpers/legacy-browser.helper';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { environment } from 'environments/environment';
import { AppModule } from './app/app.module';
import './app/core/extensions';

if (isInternetExplorer()) {
  handleLegacyInternetExplorer();
} else {
  if (environment.production) {
    enableElfProdMode();
    enableProdMode();
  } else {
    // enable elf dev tools in development mode
    devTools();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
      const environmenForBotstrappLogning = environment.production
        ? 'https://esfs.seges.dk/api/log/_bulk'
        : 'https://preprod-esfs.seges.dk/api/log/_bulk';
      const logger = new Logger(environment.appName, environmenForBotstrappLogning);
      logger.log('Error', err, {
        userId: 'NotAuthenticated',
        environment: environment.production ? 'Prod' : 'Developer',
      });
      // eslint-disable-next-line no-console
      console.error(err);
    });
}
