import { Route } from '@angular/router';
import { PermaLinkNotFoundComponent } from '@app/perma/perma-link-not-found/perma-link-not-found.component';
import { LandmandDkResolverService } from '@app/perma/resolvers/landmand-dk-resolver.service';
import { SubscriptionsResolverService } from './resolvers/subscriptions-resolver.service';

export const permaRoutes: Route[] = [
  {
    path: 'perma/landmanddk/eyespot/:harvestYear/:farmId/:fieldId',
    resolve: {
      data: LandmandDkResolverService,
    },
    /**
     * We have to use a component because 'redirectTo' routes does not run through resolvers, even
     * when we set runGuardsAndResolvers to 'always'.
     */
    component: PermaLinkNotFoundComponent,
  },
  {
    path: 'perma/subscriptions',
    /**
     * We have to use a component because 'redirectTo' routes does not run through resolvers, even
     * when we set runGuardsAndResolvers to 'always'.
     */
    resolve: {
      data: SubscriptionsResolverService,
    },
    component: PermaLinkNotFoundComponent,
  },
];
