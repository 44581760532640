import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OlMapFeatureSelectComponent } from '@app/new-map/ol-map/ol-map-feature-select.component';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { MaterialModule } from '@app/shared/material/material.module';
import { LegendComponent } from '@app/shared/openlayers-map/legend/legend.component';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { MapFeatureSelectComponent } from './map-feature-select/map-feature-select.component';
import { LayerService } from './services/layer/layer.service';

@NgModule({
  imports: [CommonModule, MaterialModule, ButtonsModule, IconModule],
  declarations: [OpenLayersMapComponent, LegendComponent, MapFeatureSelectComponent, OlMapFeatureSelectComponent],
  providers: [LayerService],
  exports: [OpenLayersMapComponent, LegendComponent, MapFeatureSelectComponent, OlMapFeatureSelectComponent],
})
export class OpenLayersMapModule {}
