import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { TranslateModule } from '../translate/translate.module';
import { ListOptionComponent } from './list-option/list-option.component';
import { SelectionListComponent } from './selection-list/selection-list.component';

@NgModule({
  declarations: [ListOptionComponent, SelectionListComponent],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [ListOptionComponent, SelectionListComponent],
})
export class ListModule {}
