import { ColumnKey } from '@app/new-map/features/cultivation-journal/filter/column/column-key';
import { ColumnSorter } from '@app/new-map/features/cultivation-journal/n-tools/n-tools-table/util/ColumnSorter';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Template class for handling sorting of TreelistTable components.
 * This class should be implemented by the table components service.
 * See NToolsTableService for usage example
 */
export abstract class TableSorter<T> {
  private _customColumnSorters: Map<ColumnKey, ColumnSorter<T>>;
  private _activeSorter$ = new BehaviorSubject<ColumnSorter<T> | null>(null);

  constructor() {
    this._customColumnSorters = new Map<ColumnKey, ColumnSorter<T>>();
  }

  /**
   * initializeSortingForColumn should be called here for every sortable column
   */
  protected abstract initializeSorting(): void;

  public getColumnSorterByKey(columnKey: ColumnKey) {
    return this._customColumnSorters.get(columnKey);
  }

  /**
   * Resets sorting for all columnSorters but @customColumnSorter.
   * Calls next on @customColumnSorters sort direction.
   * Sets _activeSorter$s value to @customColumnSorter.
   * @param customColumnSorter
   */
  public sortChange(customColumnSorter: ColumnSorter<T>) {
    this.resetSortDirectionForAllButTheGiven(customColumnSorter);
    customColumnSorter.next();
    this._activeSorter$.next(customColumnSorter);
  }

  /**
   * Runs the active sorters sort function on the given array.
   * If no active sorter is set, the array is return as given.
   * @param t
   */
  protected sortTableLines(t: T[]) {
    return this._activeSorter$.pipe(map((columnSorter) => columnSorter?.sort(t) ?? t));
  }

  protected initializeSortingForColumn(columnKey: ColumnKey, customSortFunction?: (array: T[], asc: boolean) => T[]) {
    this._customColumnSorters.set(columnKey, new ColumnSorter<T>(customSortFunction));
  }

  private resetSortDirectionForAllButTheGiven(notToBeResat: ColumnSorter<T>) {
    this._customColumnSorters.forEach((customColumnSorter) => {
      if (customColumnSorter !== notToBeResat) {
        customColumnSorter.sortDirection = null;
      }
    });
  }
}
