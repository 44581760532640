import { HttpErrorResponse, HttpHeaders, HttpResponse, HttpClient as NgHttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorHelper } from '@app/core/http/http-error-helper';
import { CorrelationIdService } from '@app/core/log/correlationid.service';
import { LogService } from '@app/core/log/log.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { isString } from 'lodash-es';
import { DateTime } from 'luxon';
import { Observable, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LanguageService } from '../language/language.service';

export interface IHttpClient {
  get<T>(url: string, options: any): Observable<T>;
  post<T, R>(url: string, data: R, options: any): Observable<T | null>;
  postWithResponse<T, R>(url: string, data: R, options: any): Observable<HttpResponse<T>>;
  put<T, R>(url: string, data: R, options: any): Observable<T | null>;
  patch<T, R>(url: string, data: R, options: any): Observable<T | null>;
  delete<T>(url: string, options: any): Observable<T | null>;
}

/**
 * Base repository implementing request methods for API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpClient implements IHttpClient {
  public onForbidden = new Subject();
  public avoidSigningOut: string[] = ['dataexchangegateway'];

  // 1001 is the generic error code for trying to write with read access, or read with no access.
  // 'RequiredAccessNotGranted' is returned when trying to create HotSpots with read access.
  private readonly WRITE_WITH_READ_ACTION_ERROR_CODES = [1001, 'RequiredAccessNotGranted'];

  constructor(
    public ngHttpClient: NgHttpClient,
    private notificationService: NotificationService,
    private logService: LogService,
    private correlationIdService: CorrelationIdService,
    private languageService: LanguageService
  ) {}

  public get<T>(url: string, options: any = {}, includeLanguage = true): Observable<T> {
    const requestBeginTime = DateTime.now();

    // @ts-ignore - This ignore only exists because not all of the endpoints of type GET have a body. Implementing a fix would require a lot of work.
    return this.ngHttpClient
      .get<T>(this.addLanguageCodeToUrl(url, includeLanguage), { ...this.addHeaders(options), observe: 'response' })
      .pipe(
        tap({
          next: () => this.logTime(requestBeginTime, `${url}`, 'GET', options.headers),
          error: (response) => this.handleError(response),
        }),
        map((res) => (res as HttpResponse<T>).body)
      );
  }

  public post<T, R = unknown>(
    url: string,
    data: R,
    options: any = {},
    includeLanguage = true,
    useDefaultErrorHandling = true
  ): Observable<T | null> {
    const requestBeginTime = DateTime.now();

    return this.ngHttpClient
      .post<T>(this.addLanguageCodeToUrl(url, includeLanguage), data, { ...this.addHeaders(options), observe: 'response' })
      .pipe(
        tap({
          next: () => this.logTime(requestBeginTime, `${url}`, 'POST', options.headers),
          error: (response) => (useDefaultErrorHandling ? this.handleError(response) : throwError(response)),
        }),
        map((res) => (res as HttpResponse<T>).body)
      );
  }

  public postWithResponse<T, R>(
    url: string,
    data: R,
    options: any = {},
    includeLanguage = true,
    useDefaultErrorHandling = true
  ): Observable<HttpResponse<T>> {
    const requestBeginTime = DateTime.now();

    return this.ngHttpClient
      .post(this.addLanguageCodeToUrl(url, includeLanguage), data, { ...this.addHeaders(options), observe: 'response' })
      .pipe(
        tap({
          next: () => this.logTime(requestBeginTime, `${url}`, 'POST', options.headers),
          error: (response) => (useDefaultErrorHandling ? this.handleError(response) : throwError(response)),
        }),
        map((response: unknown) => response as HttpResponse<T>)
      );
  }

  public put<T, R>(url: string, data: R, options: any = {}, includeLanguage = true): Observable<T | null> {
    const requestBeginTime = DateTime.now();

    return this.ngHttpClient
      .put<T>(this.addLanguageCodeToUrl(url, includeLanguage), data, { ...this.addHeaders(options), observe: 'response' })
      .pipe(
        tap({
          next: () => this.logTime(requestBeginTime, `${url}`, 'PUT', options.headers),
          error: (response) => this.handleError(response),
        }),
        map((res) => (res as HttpResponse<T>).body)
      );
  }

  public patch<T, R>(url: string, data: R, options: any = {}, includeLanguage = true): Observable<T | null> {
    options.headers = options.headers ?? this.defaultHeaders;
    const requestBeginTime = DateTime.now();

    return this.ngHttpClient
      .patch<T>(this.addLanguageCodeToUrl(url, includeLanguage), data, { ...this.addHeaders(options), observe: 'response' })
      .pipe(
        tap({
          next: () => this.logTime(requestBeginTime, `${url}`, 'PATCH', options.headers),
          error: (response) => this.handleError(response),
        }),
        map((res) => (res as HttpResponse<T>).body)
      );
  }

  public delete<T>(url: string, options: any = {}, includeLanguage = true): Observable<T | null> {
    options.headers = options.headers ?? this.defaultHeaders;
    const requestBeginTime = DateTime.now();

    return this.ngHttpClient
      .delete<T>(this.addLanguageCodeToUrl(url, includeLanguage), { ...this.addHeaders(options), observe: 'response' })
      .pipe(
        tap({
          next: () => this.logTime(requestBeginTime, `${url}`, 'DELETE', options.headers),
          error: (response) => this.handleError(response),
        }),
        map((res) => (res as HttpResponse<T>).body)
      );
  }

  /**
   * If options have no headers, basic headers are added.
   */
  private addHeaders(options: any) {
    options.headers = options?.headers ?? this.defaultHeaders;
    return options;
  }

  private logTime(start: DateTime, url: string, method: string, headers: HttpHeaders) {
    const requestDuration = DateTime.now().diff(start).milliseconds;

    this.logService.logHttpInfo(`HTTP ${method}`, requestDuration, url, headers);
  }

  private get defaultHeaders() {
    return new HttpHeaders().append('CorrelationId', this.correlationIdService.correlationId);
  }

  private handleError(response: HttpErrorResponse): Observable<never> {
    const shouldNotSignOut = this.avoidSigningOut.some((urlFragment) => this.responseUrlContainsFragment(response, urlFragment));

    if (shouldNotSignOut) {
      return throwError(response);
    }

    if (response.status === 401 || response.status === 403) {
      if (this.WRITE_WITH_READ_ACTION_ERROR_CODES.includes(response.error?.errorCode)) {
        // User is warned that he tried to perform a write action with read only permissions
        this.notificationService.showWarning('messages.common.farmWithReadPermission', 15000);
      } else if (response.status === 401 && !HttpErrorHelper.isAuthCheck(response.url!)) {
        this.onForbidden.next(null);
      }
      return throwError(response);
    }

    if (response.error?.errorCode === 150001) {
      this.notificationService.showInfo(HttpErrorHelper.getErrorMessage(response.error), 10000);
    } else {
      this.notificationService.showError(HttpErrorHelper.getErrorMessage(response.error), 10000);
    }

    return throwError(response);
  }

  private responseUrlContainsFragment(response: HttpErrorResponse, urlFragment: string) {
    return response && response.url && isString(response.url) && response.url.indexOf(urlFragment) > -1;
  }

  private addLanguageCodeToUrl(url: string, includeLanguage = true) {
    if (includeLanguage) {
      if (url.indexOf('?') > -1) {
        return `${url}&language=${this.languageService.currentLanguage.shortKey}`;
      } else {
        return `${url}?language=${this.languageService.currentLanguage.shortKey}`;
      }
    } else {
      return url;
    }
  }
}
