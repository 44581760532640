import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { DroneImageSet, DroneImageStatus } from '@app/core/interfaces/drone-image-information';
import { MapService } from '@app/core/map/map.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { DateValidators } from '@app/helpers/validators/forms/date-validators';
import { DroneImageImportLogicService } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-import-logic.service';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first, map, take, withLatestFrom } from 'rxjs/operators';
import { FieldAnalysisShownComponentEnum } from '../../../field-analysis-side-drawer/field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerContentComponent } from '../../../field-analysis-side-drawer/field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from '../../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { DroneImageImportStateService } from '../drone-image-import-state.service';

@Component({
  selector: 'app-drone-image-import-side-drawer',
  templateUrl: './drone-side-drawer.component.html',
  styleUrls: ['./drone-side-drawer.component.scss'],
})
export class DroneSideDrawerComponent implements OnInit, OnDestroy {
  public get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }

  public form!: UntypedFormGroup;
  public maxDate!: DateTime;
  public minDate!: DateTime;
  private subscriptions = new Subscription();
  public droneImageInformation$ = this.droneImageStateService.selectedField$.pipe(
    withLatestFrom(this.droneImageStateService.droneImageInformation$),
    map(([selectedField, droneImageInformations]) => {
      return this.sortPicturesByDate(
        droneImageInformations.find((droneImageInformation) => droneImageInformation.featureId === selectedField)
      );
    })
  );
  public selectedMarkName$ = this.droneImageStateService.selectedFieldName$;

  constructor(
    private mapService: MapService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>,
    private droneImageImportLogicService: DroneImageImportLogicService,
    private droneImageStateService: DroneImageImportStateService,
    private harvestYearStateService: HarvestYearStateService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.mapService.mapReady$.subscribe(() => {
        this.mapService.showLayer(MapLayerId.FIELDS);
        this.mapService.showLayer(MapLayerId.DRONE_IMAGE_IMPORT);
      })
    );

    this.maxDate = DateTime.now().setZone('utc');
    this.minDate = this.maxDate.minus({ years: 5 });

    this.subscriptions.add(
      combineLatest([this.droneImageImportLogicService.selectedHarvestYear$, this.droneImageImportLogicService.selectableFarms$])
        .pipe(first())
        .subscribe(([year, farms]) => {
          this.form = this.createForm(year, farms[0].id);
          this.subscriptions.add(this.registerHarvestYearChange());
          this.subscriptions.add(
            this.form.valueChanges.pipe(first()).subscribe(() => {
              this.droneImageImportLogicService.dirty = true;
            })
          );
        })
    );

    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.subscribe(() => {
        this.droneImageStateService.hydrateDroneImageInformation();
      })
    );
  }

  public ngOnDestroy() {
    this.fieldAnalysisSideDrawerService.clearFieldAnalysisMapLayeridxQueryParam();
    this.subscriptions.unsubscribe();
    this.droneImageImportLogicService.resetDroneFeaturesToFieldsOnDestroy();
    this.map.cleanup();
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHandler() {
    try {
      return !this.droneImageImportLogicService.dirty;
    } catch (e) {
      return true;
    }
  }

  public onCloseClick() {
    if (this.droneImageImportLogicService.dirty) {
      this.droneImageImportLogicService
        .openDirtyCheckDialog()
        .pipe(
          map((action) => action?.isConfirmed),
          first(),
          filter((denied) => !!denied)
        )
        .subscribe(() => {
          this.fieldAnalysisSideDrawerService.clearFieldAnalysisMapLayeridxQueryParam();
          this.fieldAnalysisSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
          this.droneImageImportLogicService.resetDroneFeaturesToFields();
        });
    } else {
      this.fieldAnalysisSideDrawerService.clearFieldAnalysisMapLayeridxQueryParam();
      this.fieldAnalysisSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
      this.droneImageImportLogicService.resetDroneFeaturesToFields();
    }
  }

  public onHideClick() {
    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  public onSaveClick() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.droneImageImportLogicService
        .upload(this.form.value)
        .pipe(take(1))
        .subscribe(
          () => {
            this.resetForm();
            this.droneImageImportLogicService.onSuccessfulUpload();
          },
          () => {
            this.droneImageImportLogicService.onFailedUpload();
          }
        );
    }
  }

  public onDroneImageDateChange(droneImageSet: DroneImageSet) {
    this.mapService.getMap().removeLayerFromMap(MapLayerId.DRONE_IMAGE_TILES);
    switch (droneImageSet.statusId) {
      case DroneImageStatus.Failed:
        this.notificationService.showError('main.fieldmap.droneImageImport.droneImageState.failed');
        break;
      case DroneImageStatus.Created:
        this.notificationService.showInfo('main.fieldmap.droneImageImport.droneImageState.created');
        break;
      case DroneImageStatus.Processing:
        this.notificationService.showInfo('main.fieldmap.droneImageImport.droneImageState.processing');
        break;
      default:
        this.notificationService.dismiss();
        const droneTilesLayerSetting = {
          layerId: MapLayerId.DRONE_IMAGE_TILES,
          layerType: MapLayerType.TILE,
          zIndex: 2,
          useAuthHeaders: true,
          isVisible: true,
          url: this.mapService.getTileLayerUrl(MapLayerId.DRONE_IMAGE_TILES, droneImageSet.projectId),
        };
        this.mapService.getMap().addOrUpdateLayerToMap(droneTilesLayerSetting);
        break;
    }
  }

  private createForm(harvestYear: number, farmId: number) {
    return this.formBuilder.group({
      farmId: [farmId, [Validators.required]],
      name: ['', [Validators.required, Validators.maxLength(25)]],
      date: [
        DateTime.now().setZone('utc'),
        [Validators.required, DateValidators.minDate(this.minDate), DateValidators.maxDate(this.maxDate)],
      ],
      harvestYear: [harvestYear, [Validators.required]],
    });
  }

  private registerHarvestYearChange() {
    return this.form.get('harvestYear')!.valueChanges.subscribe((harvestYear) => {
      this.droneImageImportLogicService.selectedHarvestYear = harvestYear;
    });
  }

  private resetForm() {
    combineLatest([this.droneImageImportLogicService.selectedHarvestYear$, this.droneImageImportLogicService.selectableFarms$])
      .pipe(first())
      .subscribe(([year, farms]) => {
        this.form.reset({ name: '', farmId: farms[0].id, date: DateTime.now().setZone('utc'), harvestYear: year });
      });
  }

  private sortPicturesByDate(droneImageInformationWithoutSortedPictures: any) {
    if (droneImageInformationWithoutSortedPictures) {
      return {
        ...droneImageInformationWithoutSortedPictures,
        pictures: droneImageInformationWithoutSortedPictures.pictures?.sort((a: any, b: any) => (b.imageDate > a.imageDate ? 1 : -1)),
      };
    } else {
      return droneImageInformationWithoutSortedPictures;
    }
  }
}
