import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { IWindow } from '@app/core/interfaces/window.interface';
import { LanguagePickerService } from '@app/core/language-picker/language-picker.service';
import { ILanguage, LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { VraDirtyCheckService } from '@app/new-map/features/vra/guards/vra-dirty-check.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { LanguageStateService } from '@app/state/services/language/language-state.service';
import trimEnd from 'lodash-es/trimEnd';
import trimStart from 'lodash-es/trimStart';
import { Observable, Subscription } from 'rxjs';
import { defaultIfEmpty, first, withLatestFrom } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
  public window: IWindow;
  public languages?: ILanguage[];
  public selectedLanguage?: ILanguage;
  public showLanguageList?: boolean;
  @Output() public onLanguageSelected = new EventEmitter();
  public readonly currentLanguageState$: Observable<string | null> = this.languageStateService.currentLanguage$;

  private subscriptions: Subscription[] = [];
  public RELOAD_TIMEOUT = 1000;

  constructor(
    public languagePickerService: LanguagePickerService,
    public languageService: LanguageService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private windowRefService: WindowRefService,
    private notificationService: NotificationService,
    private dirtyCheckService: DirtyCheckService,
    private dialogService: DialogService,
    private languageStateService: LanguageStateService,
    private dirtyCheckServiceVra: VraDirtyCheckService
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  public ngOnInit() {
    this.languages = this.languageService.getLanguages().filter((x) => x.show);
    this.subscriptions.push(
      this.currentLanguageState$.subscribe((language) => {
        if (language) {
          this.selectedLanguage = this.languageService.getLanguage(language);
        } else {
          this.languagePickerService.getLangShortCode$().subscribe((lang) => {
            this.selectedLanguage = this.languageService.getLanguage(lang);
          });
        }
      })
    );

    this.languagePickerService.setLanguage(this.selectedLanguage!.shortKey);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   *  Language selected from GUI
   */
  public languageClicked(language: ILanguage): void {
    if (this.dirtyCheckService.isAppDirty) {
      this.dialogService.openDirtyCheckDialog().subscribe((action) => {
        if (action?.isConfirmed) {
          this.dirtyCheckService.clearAllStates();
          this.changeLanguage(language);
        } else {
          this.hideLanguageList();
        }
      });
    } else {
      this.dirtyCheckServiceVra
        .checkDirtyAndAct()
        .pipe(defaultIfEmpty(true), first())
        .subscribe((isNotDirtyOrUserAccepted) => {
          if (isNotDirtyOrUserAccepted) this.changeLanguage(language);
        });
    }
  }

  /**
   *  Changes show/hide state of the language list box
   */
  public languageMenuClicked($event: MouseEvent): void {
    $event.preventDefault();
    this.showLanguageList = !this.showLanguageList;
  }

  /**
   *  Hides the language list box
   */
  public hideLanguageList(): void {
    this.showLanguageList = false;
  }

  private showReloadInfo() {
    this.notificationService.showInfo(this.languageService.getText('header.user.userInfo.languageIsChangedReload'));
  }

  private setHrefWithSelectedLanguage(language: ILanguage, farms: Farm[], harvestYear: number) {
    const farmIds = farms.length > 0 ? `farmIds=${JSON.stringify(farms.map((f) => f.id))}` : '';
    const origin = trimEnd(this.window.location.origin, '/');
    let path = trimEnd(this.window.location.pathname, '/');
    path = trimStart(this.window.location.pathname, '/');
    const baseUrl = path.length > 0 ? `${origin}/${path}` : `${origin}`;

    this.window.location.href = `${baseUrl}#/newmap/cultivation-journal?currentLanguage="${language.shortKey}"&harvestYear=${harvestYear}&${farmIds}`;
  }

  private changeLanguage(language: ILanguage) {
    this.farmStateService.selectedFarms$
      .pipe(withLatestFrom(this.harvestYearStateService.harvestYear$))
      .subscribe(([farms, harvestYear]) => {
        this.showReloadInfo();
        this.hideLanguageList();
        this.setHrefWithSelectedLanguage(language, farms, harvestYear!);
        this.onLanguageSelected.emit();
        setTimeout(() => {
          this.window.location.reload();
        }, this.RELOAD_TIMEOUT);
      });
  }
}
