import { LocalState } from '@app/helpers/local-state';
import { map } from 'rxjs/operators';

export class LoadingState extends LocalState<{ isLoading: boolean; message: string }> {
  public readonly isLoading$ = this.changes$.pipe(map((state) => state.isLoading));
  public readonly message$ = this.changes$.pipe(map((state) => state.message));
  constructor() {
    super({ isLoading: false, message: '' });
  }

  public stop() {
    this.setState({ isLoading: false, message: '' });
  }

  public start(message: string) {
    this.setState({ isLoading: true, message });
  }
}
