<div class="wrapper">
  <div class="main">
    <div class="info-header">
      <div class="header-text">
        {{ 'main.growthRegulation.insurance.info.header' | translate }}
      </div>
      <div class="info">
        <app-square-button class="info-button" (click)="showInfoDialog()">
          <app-icon class="info" icon="info_outline"></app-icon>
        </app-square-button>
      </div>
    </div>
    <div class="info-body">
      <p>{{ 'main.growthRegulation.insurance.info.body1' | translate }}</p>
      <p>{{ 'main.growthRegulation.insurance.info.body2' | translate }}</p>
    </div>
  </div>
</div>
