import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { HttpClient } from '@app/core/http/http-client';
import { Farm } from '@app/core/interfaces/farm.interface';
import { FeatureCoordinates, LineStringCoordinates, PointCoordinates } from '@app/core/interfaces/geometry-coordinates.types';
import { MethodTypes } from '@app/method-types.enum';
import { FeatureBranchConfig } from '@app/shared/environment-indicator/feature-branch-config';
import { ImageUrlSource } from '@app/shared/map-layer-controls/map-layer-control-biomass/biomass-url.service';
import { SoilSampleImageUrlSource } from '@app/shared/map-layer-controls/map-layer-control-soilsample/soilsample-url.service';
import { endpoints } from 'environments/endpoints';
import { ImageTile } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Geometry, Point } from 'ol/geom';
import { Layer } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import TileLayer from 'ol/layer/WebGLTile.js';
import { bbox } from 'ol/loadingstrategy.js';
import { OSM, TileWMS, Vector, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Observable, Subscriber } from 'rxjs';
import { LayerBundle, LayerId } from '../../services/layer/layer.store';
import { PRELOAD_VALUES, WFS_PADDING_VALUE } from '../constants/general-map.consts';
import { PROJECTION } from '../constants/projection-consts';
import { BasisStyles } from '../styles/basis-styles';
import { FieldStyles } from '../styles/field-styles';
import { getStyle } from '../styles/map-styles';
import { VraStyles } from '../styles/vra-styles';
import { WebGLLayer } from '../webgl/webgl-layer';
import { WfsLayerType } from '../wfs/wfs-consts';
import { WfsLayer, WfsLayerConfig } from '../wfs/wfs-layer-config';
import { WMSLayerType, wmsLayerUrl } from '../wms/wms-layer-url';
import { ColorUtil } from './color-util';
import { ExtentUtil } from './extent-util';
import { FeatureUtil } from './feature-util';
import { GeometryUtil } from './geometry-util';
import { LayerUtil } from './layer-utils';

//! Must be require because of the way the data is exported
const DenmarkBoundary = require('../../data/denmark-boundary.json');

export class CreateLayerUtil {
  /**
   * Create a permanent layer (OSM, Airphoto_DK, Airphoto_Foreign)
   * @param layerId - The ID of the layer to create
   * @param [url=''] - The URL for the Airphoto_DK layer
   */
  public static createPermLayer(
    layerId: LayerId | null,
    url = '',
    endpointsService: EndpointsService,
    httpClient: HttpClient
  ): LayerBundle | undefined {
    switch (layerId) {
      case LayerId.OSM:
        return LayerUtil.getPermLayerSettings([this._createOSMLayer()]);
      case LayerId.AIRPHOTO_DK:
        return LayerUtil.getPermLayerSettings([this._createAirPhotoDKLayer(url)]);
      case LayerId.AIRPHOTO_FOREIGN:
        return LayerUtil.getPermLayerSettings([this._createAirPhotoForeignLayer(endpointsService, httpClient)]);
      default:
        return undefined;
    }
  }

  /**
   * Create a generic layer based on the layerId
   * @param layerId - The ID of the layer to create
   * @param zIndex - The z-index for the layer
   * @param features - The features to add to the layer
   */
  public static createFeatureLayer(
    layerId: LayerId,
    zIndex?: number,
    features?: Feature<Geometry>[]
  ): LayerBundle[] | LayerBundle | undefined {
    switch (layerId) {
      case LayerId.FIELDS:
        const fieldAndTextLayers = LayerUtil.getFieldLayerSettings(this._createFieldsLayers(features, zIndex), ['all'], false);
        const fieldFillLayer = LayerUtil.getFieldLayerSettings(this._createFieldFillLayer(features), ['all'], true);
        return [...fieldAndTextLayers, ...fieldFillLayer];
      case LayerId.BASIS_LAYERS:
        return LayerUtil.getFieldLayerSettings(this._createBasisLayer(features, zIndex), ['basis-layers']);
      case LayerId.GEO_LOCATION:
        return LayerUtil.getFieldLayerSettings(this._createGeoLocationLayer(features), ['all'], false);
      case LayerId.VRA_CELLS:
        return LayerUtil.getFieldLayerSettings(this._createVraCellsLayer(features, zIndex), ['vra']);
      case LayerId.CELL_HOVER:
        return LayerUtil.getFieldLayerSettings(this._createCellHoverLayer(features, zIndex), ['all'], false);
      case LayerId.CELL_DRAW:
        return LayerUtil.getCellDrawLayerSettings(this._createCellDrawLayer(features, zIndex), ['vra'], false);
      case LayerId.CORN_PROGNOSIS:
        return LayerUtil.getFieldLayerSettings(this._createCornPrognosisLayer(features), ['prognosis_corn'], true);
      case LayerId.GROWTH_REGULATION:
        return LayerUtil.getFieldLayerSettings(this._createGrowthRegulationLayer(features), ['prognosis_growth'], true);
      case LayerId.YIELD_PROGNOSIS:
        return LayerUtil.getFieldLayerSettings(this._createYieldRegulationLayer(features), ['prognosis_yield'], true);
      case LayerId.VRA_PROGNOSIS:
        return LayerUtil.getFieldLayerSettings(this._createVRAPrognosisLayer(features), ['prognosis_yield'], true);
      case LayerId.BLIGHT_FIELDS:
        return LayerUtil.getFieldLayerSettings(this._createBlightFieldsPrognosisLayer(features), ['prognosis_blight'], false);
      case LayerId.BLIGHT_POLYGONS:
        return LayerUtil.getFieldLayerSettings(this._createBlightPolygonPrognosisLayer(features), ['prognosis_blight'], false);
      case LayerId.BLIGHT_FIELD_POLYGONS:
        return LayerUtil.getFieldLayerSettings(this._createBlightFieldPolygonPrognosisLayer(features), ['prognosis_blight'], true);
      case LayerId.HIGH_RES_POTATO_MARKERS:
        return LayerUtil.getFieldLayerSettings(this._createHighResPotatoMarkersPrognosisLayer(features), ['prognosis_blight'], false);
      case LayerId.HIGH_RES_FIELD_MARKERS:
        return LayerUtil.getFieldLayerSettings(this._createHighResFieldMarkersPrognosisLayer(features), ['all'], false);
      default:
        return undefined;
    }
  }

  public static createHotspotLayers(hotspotFeatures: Feature<Geometry>[], hotspotMarkerFeatures: Feature<Geometry>[]): LayerBundle {
    const hotspotLayer = this._createHotspotLayer(hotspotFeatures);
    const hotspotMarkerLayer = this._createHotspotMarkerLayer(hotspotMarkerFeatures);
    return LayerUtil.getHotspotLayerSettings([hotspotLayer, hotspotMarkerLayer]);
  }

  /**
   * Create a WFS layer based on the layerId
   * @param layerId - The ID of the layer to create
   * @param zIndex - The z-index for the layer
   */
  public static createWFSLayer(layerId: LayerId, zIndex?: number): LayerBundle | undefined {
    switch (layerId) {
      case LayerId.BES_NATURE:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.Nature, zIndex)]);
      case LayerId.BES_STONES:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.ProtectedStoneAndEarth, zIndex)]);
      case LayerId.BES_WATERCOURSES:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.Watercourses, zIndex)]);
      case LayerId.GLM_MEMORIES:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.GLMAncientMonuments, zIndex)]);
      case LayerId.GLM_LAKES:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.GLMLakes, zIndex)]);
      case LayerId.BNBO_STATUS:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.BNBO, zIndex)]);
      case LayerId.FIELD_SHRUBS:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.FieldShrubbery, zIndex)]);
      case LayerId.BUFFER_MARGINS:
        return LayerUtil.getWfsLayerSettings([this._createWFSLayerBasedOnType(layerId, WfsLayerType.ThreeMeterBuffer, zIndex)]);

      default:
        return undefined;
    }
  }

  /**
   * Create a field layer item layer
   * @param layerId - The ID of the layer to create
   * @param zIndex - The z-index for the layer
   * @param fieldLayerItem - The field layer items to add to the layer
   */
  public static createFieldLayerItemLayer(layerId: LayerId, zIndex?: number, fieldLayerItem?: FieldLayerItem[]): LayerBundle[] | undefined {
    switch (layerId) {
      case LayerId.FIELD_PLANS:
        return LayerUtil.getFieldLayerSettings(this._createFieldPlansLayer(fieldLayerItem, zIndex), ['field-plan'], false);
      case LayerId.FIELD_MARKERS:
        return LayerUtil.getFieldLayerSettings(this._createFieldMarkersLayer(fieldLayerItem, zIndex), ['all'], false);
      default:
        return undefined;
    }
  }

  public static createTileLayer(layerId: LayerId, zIndex?: number): LayerBundle | undefined {
    switch (layerId) {
      case LayerId.FIELD_BLOCKS:
        return LayerUtil.getWMSLayerSettings([this._createFieldBlocksLayer(zIndex)]);
      default:
        return undefined;
    }
  }

  public static createSoilsampleTileLayer(urlObserver: Observable<SoilSampleImageUrlSource | undefined>): LayerBundle | undefined {
    return LayerUtil.getTileLayerSettings([this._createSoilsampleTileLayer(urlObserver), this._createSoilsampleLabelLayer(urlObserver)]);
  }

  public static createBiomassTileLayer(
    urlObserver: Observable<ImageUrlSource | undefined>,
    fieldFeatureObserver: Observable<{ fieldFeatures: FieldFeatures; farms: Farm[] } | null>
  ): LayerBundle | undefined {
    return LayerUtil.getTileLayerSettings([this._createBiomassTileLayer(urlObserver, fieldFeatureObserver)]);
  }

  public static createWMSLayer(layerId: LayerId, zIndex?: number): LayerBundle | undefined {
    switch (layerId) {
      case LayerId.SHADOW_MAP:
        return LayerUtil.getWMSLayerSettings([this._createWMSLayerBasedOnType(layerId, WMSLayerType.SHADOW, zIndex)]);
      case LayerId.HEIGHT_MAP:
        return LayerUtil.getWMSLayerSettings([this._createWMSHeightLayer(layerId, WMSLayerType.HEIGHT, zIndex)]);
      default:
        return undefined;
    }
  }

  private static _createOSMLayer(): TileLayer {
    return new TileLayer({
      source: new OSM({
        maxZoom: 19,
      }),
      className: LayerId.OSM.toString(),
      visible: true,
      zIndex: LayerUtil.getInitialZIndex(LayerId.OSM),
      preload: PRELOAD_VALUES.OSM,
      properties: {
        id: LayerId.OSM,
      },
    });
  }

  private static _createAirPhotoDKLayer(url: string): TileLayer {
    return new TileLayer({
      source: new XYZ({
        url: url,
        crossOrigin: 'Anonymous',
        maxZoom: 19,
        reprojectionErrorThreshold: 5,
        attributions: LayerUtil.getAttributionByLayerId(LayerId.AIRPHOTO_DK),
      }),
      className: LayerId.AIRPHOTO_DK.toString(),
      zIndex: LayerUtil.getInitialZIndex(LayerId.AIRPHOTO_DK),
      preload: PRELOAD_VALUES.AIRPHOTO,
      properties: {
        id: LayerId.AIRPHOTO_DK,
      },
    });
  }

  private static _createAirPhotoForeignLayer(endpointsService: EndpointsService, httpClient: HttpClient): TileLayer {
    // Create the XYZ source
    const xyzSource = new XYZ({
      attributions: `© ${new Date().getFullYear()} TomTom, Microsoft`,
      maxZoom: 19,
      reprojectionErrorThreshold: 5,
      crossOrigin: 'Anonymous',
      url: endpointsService.bffApi + '/azuremaps/tile?z={z}&x={x}&y={y}',
    });

    // Override the tileLoadFunction to use HttpClient for every request
    xyzSource.setTileLoadFunction((tile, url) => {
      httpClient.get<Blob>(url, { responseType: 'blob' as 'json' }).subscribe(
        (response: Blob) => {
          const imageUrl = URL.createObjectURL(response);
          ((tile as ImageTile).getImage() as HTMLImageElement).src = imageUrl;
        },
        (error) => {
          console.error('Error fetching tile data', error);
          // Handle the error, maybe set a fallback tile image
        }
      );
    });

    // Return the TileLayer with the custom source
    return new TileLayer({
      source: xyzSource,
      className: LayerId.AIRPHOTO_FOREIGN.toString(),
      visible: false,
      zIndex: LayerUtil.getInitialZIndex(LayerId.AIRPHOTO_FOREIGN),
      preload: PRELOAD_VALUES.AIRPHOTO,
      properties: {
        id: LayerId.AIRPHOTO_FOREIGN,
      },
    });
  }

  private static _createCornPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.CORN_PROGNOSIS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.CORN_PROGNOSIS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.CORN_PROGNOSIS,
      },
    });

    return vectorLayer;
  }
  private static _createGrowthRegulationLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.GROWTH_REGULATION.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.GROWTH_REGULATION),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.GROWTH_REGULATION,
      },
    });

    return vectorLayer;
  }

  private static _createBlightFieldsPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.BLIGHT_FIELDS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.BLIGHT_FIELDS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.BLIGHT_FIELDS,
      },
    });

    return vectorLayer;
  }

  private static _createBlightPolygonPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.BLIGHT_POLYGONS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.BLIGHT_POLYGONS),
      visible: true,
      maxZoom: 12,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.BLIGHT_POLYGONS,
      },
    });

    return vectorLayer;
  }

  private static _createBlightFieldPolygonPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.BLIGHT_FIELD_POLYGONS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.BLIGHT_FIELD_POLYGONS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.BLIGHT_FIELD_POLYGONS,
      },
    });

    return vectorLayer;
  }

  private static _createHighResFieldMarkersPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.HIGH_RES_FIELD_MARKERS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.HIGH_RES_FIELD_MARKERS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.HIGH_RES_FIELD_MARKERS,
      },
    });

    return vectorLayer;
  }

  private static _createHighResPotatoMarkersPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    let featureMarkers = features?.map((feat) => {
      const centerPoint = FeatureUtil.getCenterOfFeature(feat);
      const feature = new Feature();

      const point = new Point(centerPoint);
      feature.setGeometry(point);
      feature.set('centerCoords', getCenter(point.getExtent()));
      feature.set('fill', '#840000');
      return feature;
    });

    const featureMarkerPoints = featureMarkers?.map((f) => f as Feature<Point>) as Feature<Point>[];

    ColorUtil.mapFeatureColors(featureMarkerPoints);

    const vectorSource = new VectorSource({
      features: featureMarkerPoints,
    });
    return new WebGLPointsLayer({
      source: vectorSource,
      className: LayerId.HIGH_RES_POTATO_MARKERS.toString(),
      maxZoom: 11,
      style: FieldStyles.getPointsStyle(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.HIGH_RES_POTATO_MARKERS),
      visible: true,
      properties: {
        id: LayerId.HIGH_RES_POTATO_MARKERS,
      },
    });
  }

  private static _createVRAPrognosisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.VRA_PROGNOSIS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.VRA_PROGNOSIS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.VRA_PROGNOSIS,
      },
    });

    return vectorLayer;
  }

  private static _createYieldRegulationLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.YIELD_PROGNOSIS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.YIELD_PROGNOSIS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.YIELD_PROGNOSIS,
      },
    });

    return vectorLayer;
  }
  private static _createFieldsLayers(features?: Feature<Geometry>[], zIndex?: number): Layer[] {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new WebGLLayer({
      source: vectorSource,
      className: LayerId.FIELDS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.FIELDS),
      visible: true,
      minZoom: 10,
      style: FieldStyles.getFieldStyle(),
      properties: {
        id: LayerId.FIELDS,
      },
    });

    const textLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.FIELD_LABELS.toString(),
      zIndex: (zIndex || LayerUtil.getInitialZIndex(LayerId.FIELDS)) + 1,
      visible: true,
      minZoom: 13,
      declutter: false,
      renderBuffer: 1000,
      properties: {
        id: LayerId.FIELD_LABELS,
      },
      style: (feature) => getStyle(feature),
    });

    return [vectorLayer, textLayer];
  }

  private static _createFieldFillLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new WebGLLayer({
      source: vectorSource,
      className: LayerId.FIELD_FILL.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.FIELD_FILL),
      visible: true,
      minZoom: 10,
      style: FieldStyles.getFieldFillStyle(),
      properties: {
        id: LayerId.FIELD_FILL,
      },
    });

    return vectorLayer;
  }

  private static _createVraCellsLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new WebGLLayer({
      source: vectorSource,
      className: LayerId.VRA_CELLS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.VRA_CELLS),
      visible: true,
      style: VraStyles.getCellStyle(),
      properties: {
        id: LayerId.VRA_CELLS,
      },
    });

    return vectorLayer;
  }

  private static _createCellHoverLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new WebGLLayer({
      source: vectorSource,
      className: LayerId.CELL_HOVER.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.CELL_HOVER),
      visible: true,
      style: VraStyles.getCellHoverStyle(),
      properties: {
        id: LayerId.CELL_HOVER,
      },
    });

    return vectorLayer;
  }

  private static _createCellDrawLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      useSpatialIndex: false,
    });

    const vectorLayer = new WebGLLayer({
      source: vectorSource,
      className: LayerId.CELL_DRAW.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.CELL_DRAW),
      visible: true,
      style: VraStyles.getCellDrawStyle(),
      properties: {
        id: LayerId.CELL_DRAW,
      },
    });

    return vectorLayer;
  }

  private static _createHotspotLayer(features?: Feature<Geometry>[]) {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.HOTSPOTS.toString(),
      zIndex: LayerUtil.getInitialZIndex(LayerId.HOTSPOTS),
      visible: true,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.HOTSPOTS,
      },
    });

    return vectorLayer;
  }

  private static _createHotspotMarkerLayer(features?: Feature<Geometry>[]) {
    const vectorSource = new VectorSource({
      features: features,
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.HOTSPOT_MARKERS.toString(),
      zIndex: LayerUtil.getInitialZIndex(LayerId.HOTSPOT_MARKERS),
      visible: true,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.HOTSPOT_MARKERS,
      },
    });

    return vectorLayer;
  }

  private static _createGeoLocationLayer(features?: Feature<Geometry>[]) {
    const vectorSource = new VectorSource({
      features: features,
    });

    // Use normal vector layer for now, while geoLocation is used in old and new map
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.GEO_LOCATION.toString(),
      zIndex: LayerUtil.getInitialZIndex(LayerId.GEO_LOCATION),
      visible: true,
      properties: {
        id: LayerId.GEO_LOCATION,
      },
    });

    return vectorLayer;
  }

  private static _createFieldMarkersLayer(fieldLayerItems?: FieldLayerItem[], zIndex?: number): Layer {
    const fieldMarkers = fieldLayerItems?.map((layerItem) => {
      const coordinates = layerItem.coordinates as LineStringCoordinates;
      const pointFeature = FeatureUtil.getMapFeature(
        LayerId.FIELD_MARKERS,
        GeometryType.POINT,
        coordinates[0][0] as unknown as FeatureCoordinates
      );

      const cropModels = layerItem.field?.crops?.flatMap((crop) => crop.models);
      pointFeature.set('cropModels', cropModels);
      pointFeature.set('fill', layerItem.fill);
      pointFeature.set('stroke', layerItem.stroke);

      return pointFeature;
    });

    const features = fieldMarkers as Feature<Point>[];

    ColorUtil.mapFeatureColors(features);

    const vectorSource = new VectorSource({
      features: fieldMarkers,
    });
    return new WebGLPointsLayer({
      source: vectorSource,
      className: LayerId.FIELD_MARKERS.toString(),
      maxZoom: 10,
      style: FieldStyles.getPointsStyle(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.FIELD_MARKERS),
      visible: true,
      properties: {
        id: LayerId.FIELD_MARKERS,
      },
    });
  }

  private static _createFieldPlansLayer(fieldLayerItems?: FieldLayerItem[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: fieldLayerItems?.map((layerItem) => {
        const coordinates = layerItem.coordinates;
        const feature = FeatureUtil.getMapFeature(LayerId.FIELD_PLANS, GeometryType.POLYGON, coordinates);
        feature.set('field', layerItem.field);
        feature.set('layerId', LayerId.FIELD_PLANS);
        return feature;
      }),
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.FIELD_PLANS.toString(),
      zIndex: LayerUtil.getInitialZIndex(LayerId.FIELD_PLANS),
      visible: true,
      minZoom: 10,
      style: (feature) => getStyle(feature),
      properties: {
        id: LayerId.FIELD_PLANS,
      },
    });

    return vectorLayer;
  }

  private static _createFieldBlocksLayer(zIndex?: number): Layer {
    const tileSource = new XYZ({
      url: this._getTileLayerUrl(LayerId.FIELD_BLOCKS),
      crossOrigin: 'Anonymous',
    });

    return new TileLayer({
      source: tileSource,
      className: LayerId.FIELD_BLOCKS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.FIELD_BLOCKS),
      maxZoom: 19,
      visible: false,
      properties: {
        id: LayerId.FIELD_BLOCKS,
      },
    });
  }

  private static _createBasisLayer(features?: Feature<Geometry>[], zIndex?: number): Layer {
    const vectorSource = new VectorSource({
      features: features?.map((feature) => {
        feature.set('fill', feature.get('basisLayer').category.color);
        feature.set('selectedBinary', 0);
        return feature;
      }),
    });

    return new WebGLLayer({
      source: vectorSource,
      className: LayerId.BASIS_LAYERS.toString(),
      zIndex: zIndex || LayerUtil.getInitialZIndex(LayerId.BASIS_LAYERS),
      visible: true,
      style: BasisStyles.StandardStyle,
      properties: {
        id: LayerId.BASIS_LAYERS,
      },
    });
  }

  private static _createWMSHeightLayer(layerId: LayerId, layerType: WMSLayerType, zIndex?: number): Layer {
    const standardHeightWMS = this._getWMSLayerUrl(WMSLayerType.HEIGHT);
    const height05mWMS = this._getWMSLayerUrl(WMSLayerType.HEIGHT05);

    //TODO: Add padding to the loading extent of WMS layers
    const source = new TileWMS({
      projection: PROJECTION.FEATURE,
      url: `${standardHeightWMS}`,
      params: { TILED: true, projection: PROJECTION.FEATURE, TRANSPARENT: 'TRUE' },
      attributions: LayerUtil.getAttributionByLayerId(layerId),
      transition: 0,
    });

    const layer = new TileLayer({
      zIndex: zIndex || LayerUtil.getInitialZIndex(layerId),
      source: source,
      className: layerId.toString(),
      visible: false,
      minZoom: 14,
      properties: {
        id: layerId,
      },
    });

    layer.on('prerender', (event) => {
      const map = layer.get('map');
      if (map) {
        const view = map.getView();
        const zoom = view.getZoom();

        if (zoom >= 17.6) {
          source.setUrl(height05mWMS!);
        } else {
          source.setUrl(standardHeightWMS!);
        }
      }
    });
    return layer;
  }

  private static _createWMSLayerBasedOnType(layerId: LayerId, layerType: WMSLayerType, zIndex?: number): Layer {
    const wmsLayerUrl = this._getWMSLayerUrl(layerType);

    //TODO: Add padding to the loading extent of WMS layers
    const layer = new TileLayer({
      zIndex: zIndex || LayerUtil.getInitialZIndex(layerId),
      source: new TileWMS({
        projection: PROJECTION.UTM_ZONE_32N,
        url: `${wmsLayerUrl}`,
        params: { TILED: true, projection: PROJECTION.UTM_ZONE_32N, TRANSPARENT: 'TRUE' },
        attributions: LayerUtil.getAttributionByLayerId(layerId),
        transition: 0,
      }),
      className: layerId.toString(),
      visible: false,
      minZoom: 14,
      properties: {
        id: layerId,
      },
    });
    return layer;
  }

  private static _createWFSLayerBasedOnType(layerId: LayerId, layerType: WfsLayerType, zIndex?: number): Layer {
    const wfsLayer = this._getWfsLayer(layerType);

    const vectorSource = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection, success, failure) => {
        const expandedExtent = ExtentUtil.expandExtent(extent, WFS_PADDING_VALUE);

        const url = wfsLayer?.url.getURLFunction(expandedExtent);
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url!);
        const onError = () => {
          vectorSource.removeLoadedExtent(expandedExtent);
          failure && failure();
        };
        xhr.onerror = onError;
        xhr.onload = () => {
          if (xhr.status == 200) {
            const features = vectorSource?.getFormat()?.readFeatures(xhr.responseText) as Feature<Geometry>[];
            vectorSource.addFeatures(features);
            success && success(features);
          } else {
            onError();
          }
        };
        xhr.send();
      },
      strategy: bbox,
      attributions: LayerUtil.getAttributionByLayerId(layerId),
    });

    return new WebGLLayer({
      source: vectorSource,
      className: layerId.toString(),
      style: wfsLayer?.style,
      zIndex: zIndex || LayerUtil.getInitialZIndex(layerId),
      visible: false,
      minZoom: 14,
      properties: {
        id: layerId,
      },
    });
  }

  private static _createBiomassTileLayer(
    urlObserver: Observable<ImageUrlSource | undefined>,
    farmFieldFeatureObserver: Observable<{ fieldFeatures: FieldFeatures; farms: Farm[] } | null>
  ): Layer {
    let url = '';

    const source = new XYZ({
      url: url,
      crossOrigin: 'Anonymous',
      minZoom: 12,
      maxZoom: 19,
      reprojectionErrorThreshold: 5,
    });
    let sourc = source;
    const layer = new TileLayer({
      zIndex: LayerUtil.getInitialZIndex(LayerId.BIOMASS),
      source: source,
      className: LayerId.BIOMASS.toString(),
      visible: false,
      minZoom: 12,
      properties: {
        id: LayerId.BIOMASS,
      },
    });

    farmFieldFeatureObserver.subscribe((ff) => {
      const newSource = new XYZ({
        url: url,
        crossOrigin: 'Anonymous',
        minZoom: 12,
        maxZoom: 19,
        reprojectionErrorThreshold: 5,
      });
      sourc = newSource;
      layer.setSource(newSource);
      sourc.clear();
      sourc.refresh();
      sourc.dispatchEvent('change');
      sourc.setTileLoadFunction((tile, url) => {
        const body = ff?.fieldFeatures?.fieldFeatures.map((f) => f.field?.geometry);
        CreateLayerUtil.getImageAsDataUrlWebGl(url, MethodTypes.POST, body).subscribe(
          (dataUrl: string) => {
            const image = (tile as any).getImage() as HTMLImageElement;
            image.src = dataUrl;
          },
          (error: any) => {
            console.error('Error loading tile:', error);
          }
        );
      });
    });

    urlObserver.subscribe((urlSource) => {
      sourc.clear();
      sourc.refresh();
      sourc.dispatchEvent('change');

      sourc.setUrl(urlSource?.url + `&_=${new Date().getTime()}` ?? '');
      url = urlSource?.url + `&_=${new Date().getTime()}` ?? '';
      sourc.setAttributions(LayerUtil.getAttributionByLayerId(LayerId.BIOMASS, urlSource?.imageYear, urlSource?.source));
      sourc.clear();
      sourc.refresh();
    });

    return layer;
  }

  private static _createSoilsampleTileLayer(urlObserver: Observable<SoilSampleImageUrlSource | undefined>): Layer {
    const source = new XYZ({
      url: ``,
      crossOrigin: 'Anonymous',
      minZoom: 12,
      maxZoom: 19,
      reprojectionErrorThreshold: 5,
    });

    urlObserver.subscribe((urlSource) => {
      source.clear();
      source.refresh();
      source.dispatchEvent('change');
      source.setUrl(urlSource?.url + `&_=${new Date().getTime()}` ?? '');

      source.clear();
      source.setTileLoadFunction((tile, url) => {
        const body = {
          farmIds: urlSource?.farmIds,
          sampleDates: urlSource?.sampleDates.map((date) => date.toISODate()),
          fieldNumbers: urlSource?.fields,
        };
        CreateLayerUtil.getImageAsDataUrlWebGl(url, MethodTypes.POST, body).subscribe(
          (dataUrl: string) => {
            const image = (tile as any).getImage() as HTMLImageElement;
            image.src = dataUrl;
          },
          (error: any) => {
            console.error('Error loading tile:', error);
          }
        );
      });
      source.clear();
    });

    const layer = new TileLayer({
      zIndex: LayerUtil.getInitialZIndex(LayerId.SOILSAMPLE),
      source: source,
      className: LayerId.SOILSAMPLE.toString(),
      visible: false,
      minZoom: 12,
      properties: {
        id: LayerId.SOILSAMPLE,
      },
    });
    return layer;
  }

  private static _createSoilsampleLabelLayer(urlObserver: Observable<SoilSampleImageUrlSource | undefined>): Layer {
    let vectorSource = new VectorSource({
      features: [],
    });
    const textLayer = new VectorLayer({
      source: vectorSource,
      className: LayerId.SOILSAMPLE_LABELS.toString(),
      zIndex: LayerUtil.getInitialZIndex(LayerId.SOILSAMPLE_LABELS),
      visible: true,
      minZoom: 12,
      declutter: false,
      renderBuffer: 1000,
      properties: {
        id: LayerId.SOILSAMPLE_LABELS,
      },
      style: (feature) => getStyle(feature),
    });

    urlObserver.subscribe((urlSource) => {
      if (urlSource === undefined || urlSource?.url === '') {
        return;
      }
      const features = urlSource?.samples.map((sample) => {
        const coordinates: Coordinate = [parseFloat(sample.longitude.replace(',', '.')), parseFloat(sample.latitude.replace(',', '.'))];

        const point = new Point(coordinates as PointCoordinates);
        const geo = GeometryUtil.transformGeometryToMap(point);
        const feature = new Feature(geo);

        const sampleResult = sample.sampleResults.find((result) => result.shortName === urlSource.type);
        const value = sampleResult ? sampleResult.value.toString() : '';
        feature.set('centerCoords', getCenter(point.getExtent()));
        feature.set('value', value);
        feature.set('layerId', LayerId.SOILSAMPLE_LABELS);
        feature.set('sample-type', urlSource.type);
        return feature;
      });
      vectorSource.clear();
      (vectorSource as Vector).addFeatures(features!);
    });
    return textLayer;
  }

  private static _getWMSLayerUrl(layerType: WMSLayerType): string | undefined {
    switch (layerType) {
      case WMSLayerType.SHADOW:
        return wmsLayerUrl.shadowmapUrl.getURL;
      case WMSLayerType.HEIGHT:
        return wmsLayerUrl.heightmapUrl.getURL;
      case WMSLayerType.HEIGHT05:
        return wmsLayerUrl.heightmapUrl05m.getURL;
      default:
        return undefined;
    }
  }

  private static _getWfsLayer(layerType: WfsLayerType): WfsLayer | undefined {
    switch (layerType) {
      case WfsLayerType.FieldShrubbery:
        return WfsLayerConfig.fieldShrubberyLayer;
      case WfsLayerType.GLMAncientMonuments:
        return WfsLayerConfig.GLMAncientMonumentsLayer;
      case WfsLayerType.GLMLakes:
        return WfsLayerConfig.GLMLakesLayer;
      case WfsLayerType.ThreeMeterBuffer:
        return WfsLayerConfig.ThreeMeterBufferLayer;
      case WfsLayerType.Watercourses:
        return WfsLayerConfig.WatercoursesLayerLayer;
      case WfsLayerType.Nature:
        return WfsLayerConfig.NatureLayerLayer;
      case WfsLayerType.ProtectedStoneAndEarth:
        return WfsLayerConfig.ProtectedStoneAndEarthLayer;
      case WfsLayerType.BNBO:
        return WfsLayerConfig.BNBOLayer;
      default:
        return undefined;
    }
  }

  private static _getTileLayerUrl(layerBundle: LayerId): string {
    switch (layerBundle) {
      case LayerId.FIELD_BLOCKS:
        return `${endpoints.foApi}/maps/overlays/fieldblocks/{z}/{x}/{y}`;
      default:
        return '';
    }
  }

  public static getImageAsDataUrlWebGl(url: string, method: MethodTypes = MethodTypes.GET, body?: any): Observable<string> {
    return new Observable((observer: Subscriber<string>) => {
      const xhr = new XMLHttpRequest();

      const uniqueUrl = url.includes('?') ? `${url}&_=${new Date().getTime()}` : `${url}?_=${new Date().getTime()}`;
      xhr.open(method, uniqueUrl); // Use uniqueUrl to prevent caching
      xhr.responseType = 'blob'; // Set response type as blob
      const savedSelectedBranch: string | null = sessionStorage.getItem(FeatureBranchConfig.StorageKey);
      if (savedSelectedBranch) xhr.setRequestHeader('x-featurebranch', savedSelectedBranch);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate');
      xhr.setRequestHeader('Pragma', 'no-cache');
      xhr.setRequestHeader('Expires', '0');

      xhr.onload = (ev) => {
        const blob: Blob = xhr.response; // Response is a blob due to responseType setting

        const reader = new FileReader();
        reader.onloadend = function () {
          observer.next(reader.result as string);
          observer.complete();
        };
        reader.readAsDataURL(blob); // Convert the blob to a data URL
      };
      xhr.onerror = (ev) => {
        console.error('Network error occurred', xhr.status, xhr.statusText);
        observer.error('Network error');
      };

      body ? xhr.send(JSON.stringify(body)) : xhr.send();
    });
  }

  /**
   * Create a VectorLayer with Denmark boundary from GeoJSON file.
   */
  public static createDenmarkBoundary(): VectorLayer<VectorSource<Feature<Geometry>>> {
    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(DenmarkBoundary, {
        dataProjection: PROJECTION.DATA,
        featureProjection: PROJECTION.FEATURE,
      }),
    });

    return new VectorLayer({
      source: vectorSource,
    });
  }
}
