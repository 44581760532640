import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { Module } from '@app/core/types/module.type';
import { Resource } from '@app/shared/access-control/resources';

export class NavigationItem {
  public translationString: string = '';
  public items: NavigationItem[] = [];
  public route?: string;
  public subscriptionLevel: SubscriptionLevel = SubscriptionLevel.FREE;
  public missingSubscriptionRedirectRoute = '/newmap/cultivation-journal';
  public icon?: string;
  public disabled = false;

  /** Access control
   * The user must have access to the module (if any) and/or any of the resource/s to see the item.
   * A resource or a list of resources can be passed, but not both.
   * If no module or resources are passed, the user is assumed to have access.
   */
  public module?: Module; // The module that the user may need to access the item
  public resource?: Resource; // A single resource that the user may need to access the item
  public resources?: Resource[]; // A list of resources, where "ANY" should be accessible, that the user may need to access the item

  constructor(obj: Partial<NavigationItem>) {
    Object.assign(this, obj);
  }
}
