<div class="debug-content">
  <h2>{{ 'header.debugInfo.title' | translate }}</h2>
  <mat-dialog-content>
    <table #table id="debug-info-table" *ngIf="info$ | async; let info">
      <tr>
        <th>{{ 'header.debugInfo.time' | translate }}</th>
        <td>{{ info.time! | dateFormat }} {{ info.time?.toJSDate() | date: 'HH:mm' }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.apiVersion' | translate }}</th>
        <td>{{ info.apiVersion | async }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.userName' | translate }}</th>
        <td>{{ (info.user | async)?.fullName }} ({{ (info.user | async)?.username }})</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.userRole' | translate }}</th>
        <td>{{ (info.user! | async)?.userType! | userTypeToString | translate }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.subscription' | translate }}</th>
        <td>{{ info.subscription | async }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.farms' | translate }}</th>
        <td>{{ info.selectedFarms | async }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.cropYear' | translate }}</th>
        <td>{{ info.harvestYear | async }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.language' | translate }}</th>
        <td>{{ info.language }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.browser' | translate }}</th>
        <td>{{ info.browser }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.device' | translate }}</th>
        <td>{{ info.device }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.aspectRatio' | translate }}</th>
        <td>{{ info.aspectRatio }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.zoomLevel' | translate }}</th>
        <td>{{ info.zoomLevel }}</td>
      </tr>
      <tr *ngIf="info.mapZoomLevel">
        <th>{{ 'header.debugInfo.mapZoomLevel' | translate }}</th>
        <td>{{ info.mapZoomLevel }}</td>
      </tr>
      <tr>
        <th>{{ 'header.debugInfo.currentUrl' | translate }}</th>
        <td>{{ info.currentUrl }}</td>
      </tr>
    </table>
    <textarea #selectArea class="select-area"></textarea>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <div class="left-ones">
      <app-square-button (clicked)="mailInfo()" buttonType="Primary">
        <app-icon icon="mail_outline"></app-icon>
        {{ 'common.email' | translate }}
      </app-square-button>
      <app-square-button (clicked)="copyInfo()" buttonType="Primary">
        <app-icon icon="content_copy"></app-icon>
        {{ 'common.copy' | translate }}
      </app-square-button>
    </div>
    <app-square-button (clicked)="close()" buttonType="Secondary">{{ 'common.close' | translate }}</app-square-button>
  </mat-dialog-actions>
</div>
