<form [formGroup]="parentForm">
  <mat-card appearance="outlined">
    <mat-card-title>
      {{ 'main.asApplied.metaData.parentTitle' | translate }}
      <app-tooltip-overlay size="sm" [tooltipTemplate]="infoTooltip" (click)="$event.stopPropagation()">
        <app-icon icon="info_outline" class="info-hover"> </app-icon>
      </app-tooltip-overlay>
      <ng-template #infoTooltip>
        <div class="tooltip-wrapper">
          <p>{{ 'main.asApplied.metaData.transferredFilesInfo' | translate }}</p>
        </div>
      </ng-template>
    </mat-card-title>
    <mat-card-content>
      <!--  FILE NAME  -->
      <app-input id="fileName" formControlName="fileName" [placeholder]="'main.asApplied.metaData.fileName' | translate"> </app-input>

      <!--  FARM  -->
      <app-input id="farm" formControlName="farm" [placeholder]="'main.asApplied.metaData.farm' | translate"> </app-input>

      <!--  HARVEST YEAR -->
      <app-input id="harvestYear" formControlName="harvestYear" [placeholder]="'main.asApplied.metaData.harvestYear' | translate">
      </app-input>
    </mat-card-content>
  </mat-card>

  <!-- ? File transfer details should not should information about field tasks CT2-7203 -->
  <!-- <br> -->

  <!-- <app-meta-data-child-form [@flyInOut]="'in'" *ngFor="let childForm of childForms" [form]="childForm"
                            [isEditMode]="true"
                            ></app-meta-data-child-form> -->

  <!-- <br> -->

  <app-square-button class="action-btn download-btn" buttonType="Secondary" (clicked)="downloadZipClicked()">
    <span class="mat-button-wrapper">{{ 'main.asApplied.metaData.downloadZipFile' | translate | uppercase }}</span>
  </app-square-button>
  <br />

  <app-square-button class="action-btn delete-btn" (clicked)="removeClicked()">
    <span class="mat-button-wrapper">{{ 'main.asApplied.metaData.deleteTitle' | translate | capitalize }}</span>
  </app-square-button>
</form>

<app-cell-hover [mapLayerId]="(mapLayerId$ | async)!">
  <app-as-applied-tooltip *cellFeature="let feature" [feature]="feature"></app-as-applied-tooltip>
</app-cell-hover>
