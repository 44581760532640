import { Inject, Injectable } from '@angular/core';
import { DEFAULT_CULTURE } from '@app/core/variables';
import { Observable, ReplaySubject } from 'rxjs';

export interface ILanguagePickerService {
  setLanguage(language: string): void;
  getLangShortCode$(): Observable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class LanguagePickerService implements ILanguagePickerService {
  private languageShortCodeSubject: ReplaySubject<string>;

  constructor(@Inject(DEFAULT_CULTURE) public defaultCulture: string) {
    this.languageShortCodeSubject = new ReplaySubject<string>(1);
    this.languageShortCodeSubject.next(defaultCulture);
  }

  public setLanguage(language: string) {
    this.languageShortCodeSubject.next(language);
  }

  public getLangShortCode$() {
    return this.languageShortCodeSubject.asObservable();
  }
}
