import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { LanguageService } from '@app/core/language/language.service';
import { AsAppliedShownComponentEnum } from '@app/map/features/field-analysis/features/as-applied/as-applied-shown-component.enum';
import { AsAppliedService } from '@app/map/features/field-analysis/features/as-applied/as-applied.service';
import { MetadataView } from '@app/map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/metadata-view';
import { AsAppliedFileUploadService } from '@app/map/features/field-analysis/features/as-applied/file-upload/services/as-applied-file-upload.service';
import { MetadataMapService } from '@app/map/features/field-analysis/features/as-applied/file-upload/services/metadata-map.service';
import { FieldAnalysisSideDrawerService } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.service';
import { ColorService } from '@app/new-map/features/vra/state/color.state';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { ReplaySubject } from 'rxjs';
import { first, skip } from 'rxjs/operators';

@Component({
  selector: 'app-as-applied-file-details',
  templateUrl: './as-applied-file-details.component.html',
  styleUrls: ['../metadata-view.scss', './as-applied-file-details-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MetadataMapService],
  animations: [MetadataView.FLY_IN_OUT],
})
export class AsAppliedFileDetailsComponent extends MetadataView implements OnInit {
  private _mapLayerId!: ReplaySubject<MapLayerId>;

  constructor(
    private asAppliedFileUploadService: AsAppliedFileUploadService,
    private formBuilder: UntypedFormBuilder,
    private metadataMapService: MetadataMapService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private languageService: LanguageService,
    private vraColorService: ColorService,
    private harvestYearStateService: HarvestYearStateService,
    private asAppliedService: AsAppliedService
  ) {
    super(formBuilder, languageService, fieldAnalysisSideDrawerService, true);
  }

  public ngOnInit(): void {
    this._mapLayerId = new ReplaySubject<MapLayerId>(1);
    this.harvestYearStateService.harvestYear$.pipe(skip(1), first()).subscribe(() => {
      this.fieldAnalysisSideDrawerService.setShowLegendState(false);
      this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
    });
    this.asAppliedFileUploadService.uploadedFile$.pipe(first()).subscribe((metadataFile) => {
      this.metadataParent = metadataFile;
      const metadataGeometries = metadataFile.rates;
      const legend = this.vraColorService.getAsAppliedLegendColorValues(metadataGeometries);
      this.vraColorService.setAsAppliedPointsAndCellColors(metadataGeometries, legend);
      const unitsString = this.joinUniqueUnits(metadataFile.units);
      this.setLegendSettings(legend, unitsString);
      this.metadataMapService.addMetadataGeometriesToMap(metadataFile.rates, unitsString);
      this.createParentForm(metadataFile);
      this.populateChildrenFormArray(metadataFile.tasks);

      const layerId = this.metadataMapService.getMapLayerId(metadataFile.rates[0]?.type);
      if (layerId) this._mapLayerId.next(layerId);
    });
  }

  public get mapLayerId$() {
    return this._mapLayerId.asObservable();
  }

  public removeClicked() {
    this.asAppliedFileUploadService.deleteMetadataParent(this.metadataParent);
  }

  public downloadZipClicked() {
    this.asAppliedFileUploadService.downloadZipFile(this.metadataParent);
  }
}
