import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { ExecutedLocationCollection } from '../executed-task.class';
import { MetadataGeometry } from '../file-upload/metadata-parent';

@Component({
  selector: 'app-add-additional-logs-modal',
  templateUrl: './add-additional-logs-modal.component.html',
  styleUrls: ['./add-additional-logs-modal.component.scss'],
})
export class AddAdditionalLogsModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddAdditionalLogsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      logs: this.fb.array(
        this.data.tasks!.map((task: ExecutedLocationCollection) => {
          const logTimes = this.calculateLogStartAndEnd(task.locations);
          const group = this.fb.group({
            selected: false,
            logName: task.fileName,
            startTime: logTimes.start,
            endTime: logTimes.end,
            log: task,
          });

          return group;
        })
      ),
    });
  }

  public form!: FormGroup;
  public logCount: number = 0;
  private _allSelectedSubject = new BehaviorSubject(false);
  protected allSelected$ = this._allSelectedSubject.asObservable();

  private _anySelectedSubject = new BehaviorSubject(false);
  protected anySelected$ = this._anySelectedSubject.asObservable();

  private _subs = new SubscriptionArray();

  ngOnInit() {
    this.logCount = this.logArray.length;
    this._subs.add(
      this.logArray!.valueChanges.pipe(debounceTime(10)).subscribe(() => {
        this._allSelectedSubject.next(this.isAllSelected());
        this.logCount = this.logArray.length;
      })
    );

    this.form.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this._anySelectedSubject.next(this.isAnySelected());
    });
  }

  get logArray() {
    return this.form.get('logs') as FormArray;
  }

  private isAnySelected() {
    return this.logArray.controls.some((control) => {
      return control.get('selected')?.value;
    });
  }

  private calculateLogStartAndEnd(locations: MetadataGeometry[]) {
    const timestamps = locations.map((l) => l.time).filter((l) => l !== undefined);
    if (timestamps.length === 0) {
      return { start: null, end: null };
    }

    const start = timestamps.reduce((earliest, current) => {
      return current! < earliest! ? current : earliest;
    }, timestamps[0]);

    const end = timestamps.reduce((latest, current) => {
      return current! > latest! ? current : latest;
    }, timestamps[0]);

    return { start: start, end: end };
  }

  protected selectAll() {
    this.logArray.controls.forEach((control) => {
      control.patchValue({ selected: true });
    });
  }

  protected deselectAll() {
    this.logArray.controls.forEach((control) => {
      control.patchValue({ selected: false });
    });
  }
  protected isAllSelected() {
    return this.logArray.controls.every((control) => {
      const selected = control.get('selected')?.value;
      return selected;
    });
  }

  protected onCancelClicked() {
    this.dialogRef.close(undefined);
  }

  protected onConfirmClicked() {
    const selectedControls = this.logArray.controls.filter((control) => control.get('selected')?.value === true);

    const logsToDownload = selectedControls.map((control) => control.get('log')?.value);
    this.dialogRef.close({ addedLogs: logsToDownload });
  }
}
