import { Month } from '@app/core/enums/month.enum';
import { DateTime } from 'luxon';

export class HarvestYear {
  private static HARVESTYEARS_FORWARD = 6;

  public static get currentHarvestYear() {
    const currentDate = DateTime.now();
    const then = DateTime.fromObject({ day: 1, month: Month.August });
    return currentDate >= then ? currentDate.year + 1 : currentDate.year;
  }

  public static get HARVEST_YEAR_START(): number {
    return 2001;
  }
  public static get HARVEST_YEAR_END(): number {
    return HarvestYear.currentHarvestYear + HarvestYear.HARVESTYEARS_FORWARD;
  }
}
