<form [formGroup]="form" [ngClass]="{ 'active-element': editing }">
  <div class="cell">
    <input
      *ngIf="editing; else unabridged"
      formControlName="cellValue"
      appNumbersOnly
      [ngClass]="{ 'error-input': errors.length }"
      #cellinput
      (focus)="onInputFocus($event)"
      name="cellinput"
      class="cell-edit-input"
      (focusout)="onInputFocusOut($event)"
      (keyup.enter)="onKeyUpEnter($event)"
      (input)="validate()"
      autocomplete="off"
    />
    <ng-template #unabridged>
      <div class="cell-wrapper">
        <div class="value" [ngStyle]="{ cursor: canEdit ? 'text' : 'default' }" (click)="onValueClick()">
          {{ $any(value) | editableGridCellValue: suffix }}
        </div>
        <div
          *ngIf="$any(sources?.length) > 1 && (showSources$ | async)"
          class="source"
          (click)="onSourceClick()"
          [style]="getStyleForSource(currentSource)"
        ></div>
      </div>
    </ng-template>
  </div>
  <app-cell-error-container [errors]="errors"></app-cell-error-container>
</form>
