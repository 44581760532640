<div class="side-drawer-wrapper">
  <app-side-drawer-header
    [title]="'operationSelector.title' | translate"
    (action)="onCloseClick()"
    [showInfoHoverButton]="true"
    cancelIconName="Luk"
    [showMinimizeButton]="false"
  >
  </app-side-drawer-header>

  <app-side-drawer-body>
    <div
      class="line"
      [ngClass]="{ disabled: operationType.disabled || operationType.exists }"
      *ngFor="let operationType of operationTypes$ | async | sort: 'name'"
      (click)="onOperationTypeSelect(operationType)"
    >
      <app-tooltip *ngIf="operationType.disabled" fullWidth [tooltipText]="'operationSelector.disabled' | translate">
        <div class="operation-type-name">{{ operationType.name }}</div>
      </app-tooltip>

      <app-tooltip
        *ngIf="!operationType.disabled && operationType.exists"
        fullWidth
        [tooltipText]="'operationSelector.alreadySelected' | translate"
      >
        <div class="operation-type-name">{{ operationType.name }}</div>
      </app-tooltip>

      <div *ngIf="!operationType.disabled && !operationType.exists" class="operation-type-name">{{ operationType.name }}</div>
    </div>
  </app-side-drawer-body>
</div>
