<app-fab
  id="help-fab"
  icon="textsms_outline"
  [disabled]="(disabled$ | async)!"
  (click)="openFeedbackDrawer()"
  appGTagEventTracker
  eventName="openFeedback (click)"
  eventCategory="Administration"
></app-fab>
<app-feedback-side-drawer *ngIf="isFeedbackOpen$ | async"></app-feedback-side-drawer>
