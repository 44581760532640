import { ScreenSize } from '@app/core/enums/screen-size.enum';
import * as pbi from 'powerbi-client';
import { ReportNameHeight } from './models/report-name-height.interface';

export const REPORT_NAME = 'cropmanager';
export const GROUP_ID = '4f3a6149-6af9-4e84-8634-31dab80b4a12';
export const REPORT_ID = '063f610b-d7c2-4ea8-8d04-eeab5470f641';

export const MOBILE_PAGE_NAME = 'ReportSection733facadc71e84b048d6';
export const DESKTOP_PAGE_NAME = 'ReportSectionaf556a78a72ae2a41cd1';

export const screenSizePageNameMap = new Map<ScreenSize, ReportNameHeight>([
  [ScreenSize.XS, { name: MOBILE_PAGE_NAME, height: 380 }],
  [ScreenSize.S, { name: MOBILE_PAGE_NAME, height: 720 }],
  [ScreenSize.M, { name: DESKTOP_PAGE_NAME, height: 320 }],
  [ScreenSize.L, { name: DESKTOP_PAGE_NAME, height: 420 }],
  [ScreenSize.XL, { name: DESKTOP_PAGE_NAME, height: 530 }],
]);

export const EMBED_CONFIG: pbi.IEmbedConfiguration = {
  type: 'report',
  accessToken: '',
  tokenType: pbi.models.TokenType.Embed,
  embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${REPORT_ID}&groupId=${GROUP_ID}`,
  id: REPORT_ID,
  viewMode: pbi.models.ViewMode.View,
  permissions: pbi.models.Permissions.Read,
  settings: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    bookmarksPaneEnabled: false,
    layoutType: pbi.models.LayoutType.Custom,
    customLayout: {
      displayOption: pbi.models.DisplayOption.FitToWidth,
    },
    background: pbi.models.BackgroundType.Transparent,
  },
};
