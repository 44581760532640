<p class="legend-title">{{ legend.title | translate }}</p>

<div class="legend-content">
  <div class="colors">
    <div class="legend-entry" *ngFor="let entry of legend.entries">
      <div class="color" [ngStyle]="{ 'background-color': entry.color }"></div>
    </div>
  </div>

  <div class="numbers">
    <div class="ten">
      <div>10</div>
    </div>
    <div class="nine">
      <div>9</div>
    </div>
    <div class="eight">
      <div>8</div>
    </div>
    <div class="seven">
      <div>7</div>
    </div>
    <div class="six">
      <div>6</div>
    </div>
    <div class="five">
      <div>5</div>
    </div>
    <div class="four">
      <div>4</div>
    </div>
    <div class="three">
      <div>3</div>
    </div>
    <div class="two">
      <div>2</div>
    </div>
    <div class="one">
      <div>1</div>
    </div>
  </div>
</div>
