<app-spinner *ngIf="isLoading && !isGrazing" [hasBackdrop]="false" [message]="'grassPrognosis.fetchingData' | translate"> </app-spinner>

<app-spinner *ngIf="isLoading && isGrazing" [hasBackdrop]="false" [message]="'grassPrognosis.grazing.fetchingData.grazingData' | translate">
</app-spinner>

<div class="top-bar" *ngIf="options && !isLoading">
  <div class="date-cut-settings">
    <app-date-picker
      *ngIf="!isGrazing"
      [date]="options.testRunDate"
      [showIcon]="true"
      placeholder="{{ 'grassPrognosis.settings.date' | translate }}"
      (dateChange)="onDateChange($event)"
      [minDate]="minDate"
      [maxDate]="maxDate"
    ></app-date-picker>
    <div *ngIf="!isGrazing" class="cut-number">{{ options.cutNumber }}. {{ 'grassPrognosis.cut' | translate }}</div>
  </div>

  <div class="buttons">
    <app-square-button *ngIf="!isGrazing" (clicked)="onSettingsClick()">
      <app-icon icon="navigate_next"></app-icon>{{ 'grassPrognosis.editModelButton' | translate }}
    </app-square-button>
    <app-square-button (clicked)="onInfoClick(isGrazing!)">
      <app-icon icon="info_outline"></app-icon>{{ 'grassPrognosis.info' | translate }}
    </app-square-button>
  </div>
</div>
<p *ngIf="isErrorMessageVisible">{{ errorMessage }}</p>
<ng-container *ngIf="isPrognosisVisible">
  <div class="cards-wrapper">
    <app-grass-prognosis-card
      [@scaleInOut]
      [options]="options"
      [title]="'grassPrognosis.fen' | translate"
      [cardData]="prognosis?.fenData"
    ></app-grass-prognosis-card>
    <app-grass-prognosis-card
      [@scaleInOut]
      [options]="options"
      [title]="'grassPrognosis.protein' | translate"
      [cardData]="prognosis?.rpData"
    ></app-grass-prognosis-card>
    <app-grass-prognosis-card
      [@scaleInOut]
      [options]="options"
      [title]="'grassPrognosis.sugar' | translate"
      [cardData]="prognosis?.sugarData"
    ></app-grass-prognosis-card>
    <app-grass-prognosis-card
      *ngIf="!isGrazing"
      [@scaleInOut]
      [options]="options"
      [title]="'grassPrognosis.fkOrg' | translate"
      [cardData]="prognosis?.fkOrgData"
    ></app-grass-prognosis-card>
    <app-grass-prognosis-card
      *ngIf="isGrazing"
      [@scaleInOut]
      [options]="options"
      [title]="'grassPrognosis.nel20' | translate"
      [cardData]="prognosis?.neL20Data"
      [valueDigitsInfo]="'1.0-2'"
    ></app-grass-prognosis-card>
    <app-checkbox [isCircle]="false" [checked]="options?.useStressDaysCorrection" (onChange)="onModelChange($event)">
      {{ 'grassPrognosis.settings.useStressDaysCorrection' | translate }}</app-checkbox
    >
  </div>
  <div class="help-text">&#x25B2; &#x25BC; {{ 'grassPrognosis.helpText' | translate }}</div>
</ng-container>

<ng-container *ngIf="isGrazing">
  <app-grass-prognosis-results-chart [field]="field" [options]="options"></app-grass-prognosis-results-chart>
</ng-container>
