<app-chart-wrapper [showSpinner]="(isLoading$ | async)!" loadingMessage="nTool.fetchingData">
  <app-square-button class="info-button" (click)="toggleInfo()">
    <app-icon class="info" icon="info_outline"></app-icon>
    {{ 'common.info' | translate }}
  </app-square-button>

  <kendo-chart [transitions]="false" [categoryAxis]="categoryAxis" [valueAxis]="valueAxis" [legend]="hidden">
    <kendo-chart-series>
      <ng-container *ngIf="nToolData$ | async as data">
        <!--    Color ranges    -->
        <kendo-chart-series-item
          *ngFor="let range of data.ranges"
          type="rangeArea"
          [markers]="hidden"
          [highlight]="hidden"
          [data]="range.rangeData"
          fromField="min"
          toField="max"
          categoryField="day"
          [opacity]="range.opacity"
          color="{{ range.color }}"
          name="{{ range.name }}"
        >
        </kendo-chart-series-item>

        <!--    Past tasks    -->
        <kendo-chart-series-item
          type="column"
          [gap]="columnGap"
          color="{{ colorGrey }}"
          [data]="data.taskGraphPoints | pastTasks"
          field="totalNutrientAmount"
          categoryField="day"
          [highlight]="hidden"
        >
          <kendo-chart-series-item-labels [color]="colorGrey" [content]="labelContent"> </kendo-chart-series-item-labels>

          <kendo-chart-series-item-tooltip [background]="colorTooltipGrey">
            <ng-template let-dataItem="dataItem">
              <p>
                {{ 'common.date' | translate }} : {{ dataItem.fromPast ? ('nTool.before' | translate) + ' ' : ''
                }}{{ dataItem.day | date: 'dd-MM-yyyy' }}
              </p>
              <div *ngFor="let product of dataItem.productsUsed">
                <p>
                  {{ product.productName }} - {{ product.productAmount | round }} {{ product.unit }} /{{ 'common.unitType.ha' | translate }}
                </p>
              </div>
            </ng-template>
          </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>

        <!--    Future tasks    -->
        <kendo-chart-series-item
          type="column"
          [gap]="columnGap"
          color="{{ colorLightGrey }}"
          [data]="data.taskGraphPoints | futureTasks"
          field="totalNutrientAmount"
          categoryField="day"
          [highlight]="hidden"
        >
          <kendo-chart-series-item-labels [color]="colorLightGrey" [content]="labelContent"> </kendo-chart-series-item-labels>

          <kendo-chart-series-item-tooltip [background]="colorTooltipGrey">
            <ng-template let-dataItem="dataItem">
              <p>
                {{ 'common.date' | translate }} : {{ dataItem.fromPast ? ('nTool.before' | translate) + ' ' : ''
                }}{{ dataItem.day | date: 'dd-MM-yyyy' }}
              </p>
              <div *ngFor="let product of dataItem.productsUsed">
                <p>
                  {{ product.productName }} - {{ product.productAmount | round }} {{ product.unit }} /{{ 'common.unitType.ha' | translate }}
                </p>
              </div>
            </ng-template>
          </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>

        <!--    Main line    -->
        <kendo-chart-series-item
          type="line"
          [width]="lineWidth"
          color="{{ colorBrightGreen }}"
          name="{{ 'nTool.lineName' | translate }}"
          [markers]="hidden"
          [highlight]="hidden"
          [data]="data.nutrientRequirementGraphPoints"
          field="nutrientRequirement"
          categoryField="day"
        >
        </kendo-chart-series-item>
      </ng-container>
    </kendo-chart-series>
  </kendo-chart>
  <div class="legend-container">
    <div class="legend-item">
      <div class="legend-color" [ngStyle]="{ 'background-color': colorBrightGreen }"></div>
      <div class="legend-text">{{ 'nTool.lineName' | translate }}</div>
    </div>
    <div class="legend-item">
      <div class="legend-color tall" [ngStyle]="{ 'background-color': colorGreen }"></div>
      <div class="legend-text">{{ 'nTool.rangeNames.green' | translate }}</div>
    </div>
    <div class="legend-item">
      <div class="legend-color tall" [ngStyle]="{ 'background-color': colorYellow }"></div>
      <div class="legend-text">{{ 'nTool.rangeNames.yellow' | translate }}</div>
    </div>
    <div class="legend-item">
      <div class="legend-color tall" [ngStyle]="{ 'background-color': colorRed }"></div>
      <div class="legend-text">{{ 'nTool.rangeNames.red' | translate }}</div>
    </div>
  </div>
  <div class="sub-info">
    <p>{{ 'nTool.chartInfo' | translate }}</p>
  </div>

  <ng-container *ngIf="nToolData$ | async as data">
    <div *ngIf="!(isLoading$ | async) && !!data && data.nutrientRequirementGraphPoints.length <= 0" class="chart-message">
      {{ 'nTool.noData' | translate }}
    </div>
  </ng-container>
</app-chart-wrapper>
