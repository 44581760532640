<div class="map-controls">
  <div class="data-controls">
    <div class="harvestyear-controls">
      <app-harvest-year-picker-map
        [disabled]="(disableHarvestYearPicker$ | async) === true"
        [start]="HarvestYear.HARVEST_YEAR_START"
        [end]="HarvestYear.HARVEST_YEAR_END"
      ></app-harvest-year-picker-map>
    </div>
    <div class="btn-controls">
      <app-map-button-controls [map]="map" [hostElement]="hostElement"></app-map-button-controls>
    </div>
  </div>
  @if (showLayerControls) {
    <div class="layer-controls">
      <app-map-layer-controls></app-map-layer-controls>
    </div>
  }
</div>
