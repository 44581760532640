import { Component, ContentChild, Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { mapAs } from '@app/shared/operators/mapAs';
import { Feature, MapBrowserEvent, Overlay } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Subscription, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, pairwise, retryWhen, sampleTime, shareReplay, switchMap, tap } from 'rxjs/operators';
import { OlMapService } from '../map-service/ol-map.service';
import { OlLayerService } from '../services/layer/layer.service';
import { LayerId } from '../services/layer/layer.store';
import { OlCellFeatureDirective } from './ol-cell-feature.directive';

/**
 * Provides a handle for the hover component to grab the view container's ng-container to insert the feature
 */
@Directive({ selector: '[olCellOutlet]' })
export class OlCellOutletDirective {
  constructor(
    public viewContainer: ViewContainerRef,
    public elementRef: ElementRef
  ) {}
}

@Component({
  selector: 'app-ol-cell-hover',
  templateUrl: './ol-cell-hover.component.html',
  styleUrls: ['./ol-cell-hover.component.scss'],
})
export class OlCellHoverComponent implements OnInit, OnDestroy {
  @Input() public zoomEditThreshold?: number;
  @Input() public mapLayerId?: LayerId | LayerId[];

  @ViewChild('popup', { static: true }) public popup?: ElementRef;
  @ViewChild(OlCellOutletDirective, { static: true }) private _cellOutlet?: OlCellOutletDirective;
  @ContentChild(OlCellFeatureDirective) private _cellFeatureDirective?: OlCellFeatureDirective;

  private _overlay!: Overlay;
  private _highlightLayer!: VectorLayer<VectorSource<Feature<Geometry>>>;

  private _subs = new Subscription();
  private _defaultZoomEditThreshold = this.mapService.zoomEditThreshold;
  private getZoom = () => this.mapService.zoomLevel;

  private eventToFeatureStream = (event: MapBrowserEvent<UIEvent>) =>
    of(event).pipe(
      map((event) => this.mapService.getEventPixel(event.originalEvent)),
      filter((pixel) => !!pixel),
      map((pixel) =>
        this.mapService.getFeaturesAtPixel(pixel!, {
          layerFilter: (layer) =>
            Array.isArray(this.mapLayerId)
              ? this.mapLayerId.includes(layer.get('id')) && layer.getVisible()
              : this.mapLayerId === layer.get('id') && layer.getVisible(),
          hitTolerance: 1,
        })
      ),
      map((features) => features?.[0])
    );

  private layerFilterfunction(layer: any) {}
  private featureBuffer$ = this.mapService.addPointerMoveInteraction().pipe(
    sampleTime(5),
    map((event) => [event, this.getZoom()] as const),
    switchMap(([event, zoom]) => {
      if (zoom && zoom >= (this.zoomEditThreshold ?? this._defaultZoomEditThreshold)) {
        return this.eventToFeatureStream(event).pipe(
          retryWhen((errors) =>
            errors.pipe(
              delay(200) // wait for a short period before retrying
            )
          )
        );
      } else {
        return of(null);
      }
    }),
    distinctUntilChanged(),
    pairwise(),
    shareReplay({ refCount: true })
  );

  private clearFeatureSideEffect$ = this.featureBuffer$.pipe(
    filter(([prev, _curr]) => !!prev),
    map(([prev, _curr]) => prev),
    mapAs<Feature>(),
    tap((feature) => this._clearFeature(feature))
  );

  private addFeatureSideEffect$ = this.featureBuffer$.pipe(
    filter(([_prev, curr]) => !!curr),
    map(([_prev, curr]) => curr),
    mapAs<Feature>(),
    tap((feature) => this._addFeature(feature))
  );

  public popupOpacity$ = this.featureBuffer$.pipe(map(([_, newFeature]) => (newFeature ? 1 : 0)));

  constructor(
    private mapService: OlMapService,
    private layerService: OlLayerService
  ) {}

  public ngOnInit() {
    this._addOverlay();
    this._addHighlightLayer();

    this._subs.add(this.clearFeatureSideEffect$.subscribe());
    this._subs.add(this.addFeatureSideEffect$.subscribe());
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();

    (Array.isArray(this.mapLayerId) ? this.mapLayerId : [this.mapLayerId]).forEach((layerId) => this.layerService.removeLayers(layerId!));
    if (this.mapLayerId) this.layerService.removeLayers(this.mapLayerId!);

    this.layerService.removeLayers(LayerId.CELL_HOVER);
    this.mapService.removeOverlayFromMap(this._overlay);
  }

  private _addOverlay() {
    this._overlay = new Overlay({
      element: this.popup?.nativeElement,
      autoPan: false,
    });

    this.mapService.addOverlayToMap(this._overlay);
    this.mapService.magicReRender();
  }

  private _addHighlightLayer() {
    //this layer should always only have one layerobject, therefore we can first it and get the one we want
    this._highlightLayer = this.layerService.createHighlightLayer().layerObjects.first() as VectorLayer<VectorSource<Feature<Geometry>>>;
  }

  private _clearFeature(feature: Feature) {
    if (!this._highlightLayer)
      this._highlightLayer = this.mapService.getLayerFromMap(LayerId.CELL_HOVER) as VectorLayer<VectorSource<Feature<Geometry>>>;

    this._highlightLayer?.getSource()?.removeFeature(feature);

    this._overlay.setPosition(undefined);

    // clear old view template
    this._cellOutlet?.viewContainer.clear();
  }

  private _addFeature(feature: Feature) {
    if (!this._highlightLayer)
      this._highlightLayer = this.mapService.getLayerFromMap(LayerId.CELL_HOVER) as VectorLayer<VectorSource<Feature<Geometry>>>;

    this._highlightLayer?.getSource()?.addFeature(feature);

    this._overlay.setPosition(feature.getGeometry()?.getExtent());

    // add view template
    if (this._cellFeatureDirective)
      this._cellOutlet?.viewContainer.createEmbeddedView(this._cellFeatureDirective.templateRef, { $implicit: feature });
  }
}
