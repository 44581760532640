<app-accordion
  [templateRef]="fieldData"
  boldTitle="{{ 'common.field' | translate | capitalize }}"
  normalTitle="{{ 'common.information' | translate }}"
  [isDisabled]="disableFieldData$ | async"
  appGTagEventTracker
  eventName="fieldInformation (click)"
  eventCategory="Field Plan"
>
</app-accordion>
<ng-template #fieldData>
  <app-journal-side></app-journal-side>
</ng-template>

<app-accordion
  *ngIf="hasCornHarvestPrognosis$ | async"
  [isAxel]="true"
  id="e2e-corn-prognosis"
  [templateRef]="cornHarvestPrognosisChart"
  boldTitle="{{ 'common.crops.corn' | translate | capitalize }}"
  normalTitle="{{ 'cornHarvestPrognosis.harvestPrognosis' | translate }}"
  [isDisabled]="disableCornHarvestPrognosis$ | async"
  appGTagEventTracker
  eventName="cornHarvestPrognosis (click)"
  eventCategory="Field Plan"
>
</app-accordion>
<ng-template #cornHarvestPrognosisChart>
  <app-corn-harvest-prognosis-chart [field]="(field$ | async)!"></app-corn-harvest-prognosis-chart>
</ng-template>

<ng-container *ngIf="hasCornEyeSpot$ | async">
  <app-accordion
    [templateRef]="wetHours"
    boldTitle="{{ 'common.crops.corn' | translate | capitalize }}"
    normalTitle="{{ 'wetHoursChart.title' | translate }}"
    [isDisabled]="disableEyespot$ | async"
    appGTagEventTracker
    eventName="cornEyespot (click)"
    eventCategory="Field Plan"
  >
  </app-accordion>
  <ng-template #wetHours>
    <app-wet-hours-chart [field]="(field$ | async)!"></app-wet-hours-chart>
  </ng-template>
</ng-container>

<app-accordion
  *appFeatureToggle="'plant_cropmanager_rain-chart'"
  [templateRef]="rain"
  boldTitle="{{ 'rainChart.title' | translate | capitalize }}"
>
</app-accordion>
<ng-template #rain>
  <app-rain-chart [field]="(field$ | async)!"></app-rain-chart>
</ng-template>

<ng-container *ngIf="hasHayHarvestPrognosis$ | async">
  <app-accordion
    [templateRef]="grass"
    [boldTitle]="'grassPrognosis.grass' | translate"
    [normalTitle]="'grassPrognosis.prognosis' | translate"
    [isDisabled]="disableHayHarvestPrognosis$ | async"
    appGTagEventTracker
    eventName="grassPrognosis (click)"
    eventCategory="Field Plan"
  >
  </app-accordion>
  <ng-template #grass>
    <app-grass-prognosis [field]="(field$ | async)!" data-testid="grass-prognosis"></app-grass-prognosis>
  </ng-template>
</ng-container>

<ng-container *ngIf="hasGrazingpotential$ | async">
  <app-accordion
    [templateRef]="grass"
    [boldTitle]="'grassPrognosis.grazings' | translate"
    [normalTitle]="'grassPrognosis.prognosis' | translate"
    [isDisabled]="disableGrazingPrognosis$ | async"
    appGTagEventTracker
    eventName="grazingPrognosis (click)"
    eventCategory="Field Plan"
  >
  </app-accordion>
  <ng-template #grass>
    <app-grass-prognosis [field]="(field$ | async)!" [isGrazing]="true"></app-grass-prognosis>
  </ng-template>
</ng-container>

<app-accordion
  *ngIf="hasWheatSeptoria$ | async"
  [isAxel]="true"
  [templateRef]="septoria"
  boldTitle="{{ 'septoriaChart.title' | translate | capitalize }}"
  [isDisabled]="disableWheatSeptoria$ | async"
  appGTagEventTracker
  eventName="septoria (click)"
  eventCategory="Field Plan"
>
</app-accordion>
<ng-template #septoria>
  <app-septoria-chart [field]="(field$ | async)!"></app-septoria-chart>
</ng-template>

<ng-template #nTool>
  <app-n-tool [field$]="field$"></app-n-tool>
</ng-template>

<ng-container>
  <app-accordion
    *ngIf="hasWaterBalance$ | async"
    [isAxel]="true"
    [templateRef]="water"
    boldTitle="{{ 'waterBalance.titleBold' | translate }}"
    normalTitle="{{ 'waterBalance.titleNormal' | translate }}"
    [isDisabled]="disableWaterBalance$ | async"
    appGTagEventTracker
    eventName="waterBalance (click)"
    eventCategory="Field Plan"
  >
  </app-accordion>
  <ng-template #water>
    <app-water-balance [field]="(field$ | async)!"></app-water-balance>
  </ng-template>
</ng-container>

<app-accordion
  *ngIf="hasGrowthStages$ | async"
  [templateRef]="growthStage"
  [boldTitle]="'growthStage.growth' | translate"
  [normalTitle]="'growthStage.stage' | translate"
  [isDisabled]="disableGrowthStage$ | async"
  appGTagEventTracker
  eventName="growthStage (click)"
  eventCategory="Field Plan"
>
</app-accordion>
<ng-template #growthStage>
  <app-growthstage [field]="(field$ | async)!"></app-growthstage>
</ng-template>
