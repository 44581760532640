import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  NitrogenNeedCalculateInputDto,
  NitrogenNeedDto,
  NitrogenNeedInputDto,
  NitrogenNeedResultDto,
} from '@app/core/interfaces/n-tools/nitrogenNeed-TableDataDto';
import { NumberValidators } from '@app/helpers/validators/forms/number-validators';
import { zeroOrBetween } from '@app/helpers/validators/forms/zero-or-between.validator';
import { CellClickEvent, CellCloseEvent } from '@progress/kendo-angular-treelist';
import { Observable, Subscription } from 'rxjs';
import { NToolsTableService } from '../n-tools-table/n-tools-table.service';
import { NToolsCalcService } from './n-tools-calc.service';

@Component({
  selector: 'app-n-tools-calc-dialog',
  templateUrl: './n-tools-calc-dialog.component.html',
  styleUrls: ['./n-tools-calc-dialog.component.scss'],
  providers: [NToolsCalcService, NToolsTableService],
})
export class NToolsCalcDialogComponent implements OnInit, OnDestroy {
  @ViewChild('treeList', { static: false }) public treeList: any;
  public reCalcFormGroup!: UntypedFormGroup;
  public tableFormGroup!: UntypedFormGroup;
  public displayData$!: Observable<NitrogenNeedResultDto>;
  public loading = true;
  public subscriptions: Subscription = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<NToolsCalcDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private nToolsCalcService: NToolsCalcService,
    public nToolsTableService: NToolsTableService
  ) {}

  public ngOnInit() {
    this.displayData$ = this.nToolsCalcService.tableData$;
    this.displayData$.subscribe(() => (this.loading = false));
    this.createRecalcFormGroup();
    this.createTableFormGroup();
  }

  public cellClick({ sender, rowIndex, dataItem, isEdited, columnIndex }: CellClickEvent) {
    if (!isEdited) {
      sender.editCell(dataItem, columnIndex, this.createTableFormGroup(dataItem));
    } else {
      sender.closeCell();
      sender.cancelCell();
    }
  }

  public cellCloseHandler(e: CellCloseEvent): void {
    if (!this.tableFormGroup.valid) {
      e.sender.cancelCell();
    } else {
      Object.assign(e.dataItem, {
        ...e.formGroup.value,
        expectedYield: e.formGroup.value.expectedYield,
        nTotalNeedActual: e.formGroup.value.nTotalNeedActual,
      });
    }
  }

  public reCalcInputData: { nitrogenPrice?: number | null; cropPrice?: number | null; proteinPrice?: number | null } = {
    nitrogenPrice: null,

    cropPrice: null,

    proteinPrice: null,
  };

  public reCalculate(useActualNeed: boolean) {
    let nitrogenNeedCalculateInputDto = {} as NitrogenNeedCalculateInputDto;

    nitrogenNeedCalculateInputDto.priceYield = this.reCalcFormGroup.get('cropPrice')?.value;

    nitrogenNeedCalculateInputDto.priceFertilizerN = this.reCalcFormGroup.get('nitrogenPrice')?.value;

    nitrogenNeedCalculateInputDto.priceProtein = this.reCalcFormGroup.get('proteinPrice')?.value;
    nitrogenNeedCalculateInputDto.yields = [];
    this.displayData$.subscribe((nitrogenNeedData) => {
      if (nitrogenNeedData) {
        nitrogenNeedData.nitrogenNeeds.forEach((element) => {
          nitrogenNeedCalculateInputDto.yields.push({
            fieldYearID: element.fieldYearID,
            yield: element.expectedYield,
          });
        });
      }
    });
    this.loading = true;
    this.nToolsCalcService.loadFieldTableData(useActualNeed, nitrogenNeedCalculateInputDto);
  }

  public createRecalcFormGroup() {
    return (this.reCalcFormGroup = this.formBuilder.group({
      nitrogenPrice: [
        this.reCalcInputData?.nitrogenPrice ?? null,
        [NumberValidators.numeric, NumberValidators.greaterThan(0), zeroOrBetween(1, 999)],
      ],
      cropPrice: [
        this.reCalcInputData?.cropPrice ?? null,
        [NumberValidators.numeric, NumberValidators.greaterThan(0), zeroOrBetween(1, 999)],
      ],
      proteinPrice: [
        this.reCalcInputData?.proteinPrice ?? null,
        [NumberValidators.numeric, NumberValidators.greaterThan(0), zeroOrBetween(1, 999)],
      ],
    }));
  }

  public get isNotGreatherThan0() {
    return this.tableFormGroup.controls['expectedYield'].getError('greaterThan');
  }

  public get isNotGreaterThanOrEqual0() {
    return this.tableFormGroup.controls['nTotalNeedActual'].getError('greaterThanOrEqual');
  }

  public createTableFormGroup(nitrogenNeedTableData?: NitrogenNeedDto) {
    return (this.tableFormGroup = this.formBuilder.group({
      expectedYield: [
        nitrogenNeedTableData?.expectedYield ?? null,
        [NumberValidators.numeric, NumberValidators.greaterThan(0), zeroOrBetween(1, 999)],
      ],
      nTotalNeedActual: [
        nitrogenNeedTableData?.nTotalNeedActual ?? null,
        [NumberValidators.numeric, NumberValidators.greaterThanOrEqual(0), zeroOrBetween(1, 999)],
      ],
    }));
  }

  public getNitrogenPriceErrorsTranslatables(reCalcFormGroup: UntypedFormGroup): string[] {
    const errors: string[] = [];
    const nitrogenPrice = reCalcFormGroup.get('nitrogenPrice');
    const cropPrice = reCalcFormGroup.get('cropPrice');

    if (nitrogenPrice?.hasError('greaterThan')) {
      errors.push('nTool.calcDialog.calcInput.nitrogenGreaterThanValidation');
    }

    if (nitrogenPrice?.hasError('numbersOnly')) {
      errors.push('nTool.calcDialog.calcInput.nitrogenNumbersOnlyValidation');
    }

    if (nitrogenPrice?.hasError('zeroOrBetween')) {
      errors.push('nTool.calcDialog.calcInput.nitrogenBetweenValidation');
    }

    if (nitrogenPrice?.value === null && cropPrice?.value) {
      errors.push('nTool.calcDialog.calcInput.nitrogenAndCropPriceBothMustBeFilled');
    }

    return errors;
  }

  public getCropPriceErrorsTranslatables(reCalcFormGroup: UntypedFormGroup): string[] {
    const errors: string[] = [];
    const cropPrice = reCalcFormGroup.get('cropPrice');
    const nitrogenPrice = reCalcFormGroup.get('nitrogenPrice');

    if (cropPrice?.hasError('greaterThan')) {
      errors.push('nTool.calcDialog.calcInput.cropPriceGreaterThanValidation');
    }

    if (cropPrice?.hasError('number')) {
      errors.push('nTool.calcDialog.calcInput.cropPriceNumbersOnlyValidation');
    }

    if (cropPrice?.hasError('zeroOrBetween')) {
      errors.push('nTool.calcDialog.calcInput.cropPriceBetweenValidation');
    }

    if (cropPrice?.value === null && nitrogenPrice?.value) {
      errors.push('nTool.calcDialog.calcInput.nitrogenAndCropPriceBothMustBeFilled');
    }

    return errors;
  }

  public getProteinPriceErrorsTranslatables(reCalcFormGroup: UntypedFormGroup): string[] {
    const errors: string[] = [];
    const proteinPrice = reCalcFormGroup.get('proteinPrice');

    if (proteinPrice?.hasError('greaterThan')) {
      errors.push('nTool.calcDialog.calcInput.proteinPriceGreaterThanValidation');
    }

    if (proteinPrice?.hasError('number')) {
      errors.push('nTool.calcDialog.calcInput.proteinPriceNumbersOnlyValidation');
    }

    if (proteinPrice?.hasError('zeroOrBetween')) {
      errors.push('nTool.calcDialog.calcInput.proteinPriceBetweenValidation');
    }

    return errors;
  }

  public hasCalcFormGroupError(reCalcFormGroup: UntypedFormGroup): boolean {
    if (
      this.getNitrogenPriceErrorsTranslatables(reCalcFormGroup).length > 0 ||
      this.getCropPriceErrorsTranslatables(reCalcFormGroup).length > 0 ||
      this.getProteinPriceErrorsTranslatables(reCalcFormGroup).length > 0
    )
      return true;

    return false;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCancelClick() {
    this.dialogRef.close();
  }

  public onConfirmClick() {
    this.treeList.closeCell();
    let nitrogenNeedInputDtos = [] as NitrogenNeedInputDto[];

    this.displayData$.subscribe((nitrogenNeedResults) => {
      nitrogenNeedResults.nitrogenNeeds.forEach((nitrogenNeedResult) => {
        nitrogenNeedInputDtos.push({
          fieldYearID: nitrogenNeedResult.fieldYearID,
          cropId: nitrogenNeedResult.cropId,
          nTotalNeed: nitrogenNeedResult.nTotalNeedActual,
          yield: nitrogenNeedResult.expectedYield,
        });
      });
    });

    NToolsTableService.refresh = true;
    this.dialogRef.close();
    this.nToolsCalcService.saveCropsNitrogenNeeds(nitrogenNeedInputDtos);
  }

  @HostListener('document:keydown.enter', ['$event'])
  public onEnterClick() {
    this.treeList.closeCell();
  }
}
