<h1 mat-dialog-title>{{ data.title! | translate }}</h1>
<div mat-dialog-content>
  <p>{{ data.text! | translate }}</p>
</div>
<div mat-dialog-actions class="dialog-actions">
  <app-square-button [buttonType]="'Primary'" (click)="onConfirmClicked($event)">{{
    data.confirmText && data.confirmText !== '' ? (data.confirmText | translate) : ('common.ok' | translate)
  }}</app-square-button>
  <app-square-button [buttonType]="'Secondary'" (click)="onCancelClicked($event)">
    {{ data.cancelText && data.cancelText !== '' ? (data.cancelText | translate) : ('common.back' | translate) }}</app-square-button
  >
</div>
