import { Injectable } from '@angular/core';
import { CropModels } from '@app/core/enums/crop-models.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { CultivationJournalStateService } from '@app/state/services/cultivation-journal/cultivation-journal-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DecisionSupportService {
  public field$ = this.cultivationJournalStateService.field$;

  constructor(
    private farmStateService: FarmStateService,
    private cultivationJournalStateService: CultivationJournalStateService
  ) {}

  /**
   * Checks the current field for the given crop model.
   */
  public fieldHasModel(cropModel: CropModels) {
    return this.field$.pipe(
      filter((field) => !!field && !!field.crops && field.crops.length > 0),
      map((field) => this.modelExistsOnFieldCrops(field!, cropModel))
    );
  }

  public fieldOnSelectedFarmsHasModel(cropModel: CropModels) {
    return this.farmStateService.fields$.pipe(
      map((fields) => fields!.filter((field) => field?.crops?.length).some((field) => this.modelExistsOnFieldCrops(field, cropModel)))
    );
  }

  private modelExistsOnFieldCrops(field: Field | null, cropModel: CropModels) {
    if (!field || !field.crops) {
      return false;
    }

    let cropModels: CropModels[] = [];

    field.crops.forEach((crop) => {
      cropModels.push(...crop.models);
    });

    return cropModels.includes(cropModel);
  }
}
