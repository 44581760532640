import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ConsentStateService } from '@app/core/consent/consent-state.service';
import { PathNameHelper } from '@app/helpers/feature-branch/path-name-helper';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Consent, ConsentTypes } from '../repositories/consent/consent.interface';

@Injectable({
  providedIn: 'root',
})
export class ConsentGuard {
  constructor(
    private consentStateService: ConsentStateService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      this.consentStateService.consents$.subscribe((consents: Consent[]) => {
        if (consents) {
          if (consents.every((consent) => consent.statusId === ConsentTypes.Given)) {
            observer.next(true);
          } else {
            if (environment.production) {
              void this.router.navigate(['no-consent'], {
                queryParams: { returnUrl: window.location.origin },
              });
            } else {
              const pathName = PathNameHelper.getFeatureBranchRegulatedPathName(location.pathname.toLowerCase());
              void this.router.navigate(['no-consent'], {
                queryParams: { returnUrl: pathName !== '' ? window.location.origin + pathName : window.location.origin },
              });
            }
            observer.next(false);
          }
          observer.complete();
        }
      });
    });
  }
}
