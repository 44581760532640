<div class="header-close">
  <h4>{{ 'growthStage.help.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <p>{{ dialogData.helpText }}</p>
  <a href="https://plantevaernonline.dlbr.dk/cp/SeasonPlan/CropScale.asp?id=djf&language=da" target="_blank">{{
    'growthStage.help.growstagesLinkName' | translate
  }}</a>
  <p>{{ 'growthStage.help.commonText' | translate }}</p></mat-dialog-content
>
