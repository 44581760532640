import { UntypedFormControl } from '@angular/forms';

export function MaxDecimals(maxDecimals: number): any {
  return (control: UntypedFormControl) => {
    // Returns null if maxDecimals is invalid
    if (!maxDecimals || isNaN(maxDecimals) || maxDecimals < 0) {
      return null;
    }
    // Returns null if the controllers value is invalid
    if (!control?.value?.length || isNaN(control.value)) {
      return null;
    }
    // Is the number an integer
    if (Math.floor(+control.value) !== +control.value) {
      const stringValue = control.value.toString();
      // Finds the last index of either '.' or ','
      const separatorIndex = stringValue.lastIndexOf('.') ?? control.value.toString().lastIndexOf(',');
      // An error is returned if a separator exists, and its not the last character in the string, and the number of characters after the separator exceeds maxDecimals
      if (separatorIndex !== -1 && separatorIndex < stringValue.length && stringValue.substring(separatorIndex + 1).length > maxDecimals) {
        return {
          maxDigits: {
            valid: false,
          },
        };
      }
    }
    return true;
  };
}
