import Feature from 'ol/Feature';

export class DrawnFieldPolygons {
  public fieldPolygon: Feature | null;
  public polygonToSubtract: Feature | null;

  constructor(fieldPolygon: Feature | null = null, polygonToSubtract: Feature | null = null) {
    this.fieldPolygon = fieldPolygon;
    this.polygonToSubtract = polygonToSubtract;
  }
}
