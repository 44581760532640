import { Feature } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

export const PointStyles = {
  getPointStyle: (feature: Feature): Style[] => {
    return [
      new Style({
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({ color: feature.get('color') }),
          stroke: new Stroke({ color: feature.get('color'), width: 1 }),
        }),
      }),
    ];
  },
};
