import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Field } from '@app/core/interfaces/field.interface';
import { FieldChangeCropResponse } from '@app/core/interfaces/FieldChangeCropResponse';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { FieldCopyCompleteDialogComponent } from '../field-copy-complete-dialog/field-copy-complete-dialog.component';
import { FieldModifyComponent } from '../field-modify/field-modify.component';
import { NotificationService } from './../../../../../core/notification/notification.service';
import { FieldCopyLogicService } from './../field-copy-logic.service';
import { FieldCopyStateService } from './../field-copy-state.service';

@Component({
  selector: 'app-field-copy-dialog',
  templateUrl: './field-copy-dialog.component.html',
  styleUrls: ['./field-copy-dialog.component.scss'],
})
export class FieldCopyDialogComponent implements OnInit, OnDestroy {
  public loading$ = this.fieldCopyStateService.loading$;

  @ViewChild('fieldModifyElement', { static: true }) public fieldModifyElement?: FieldModifyComponent;

  private subscriptions: Subscription = new Subscription();
  constructor(
    private dialogRef: MatDialogRef<FieldCopyDialogComponent>,
    public fieldCopyStateService: FieldCopyStateService,
    public fieldCopyLogicService: FieldCopyLogicService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {}

  public ngOnInit() {
    this.fieldCopyStateService.initializeFields();
    this.subscriptions.add(
      this.fieldCopyStateService.allFields.pipe(skip(1), first()).subscribe((fields) => {
        if (fields.length < 1) {
          this.dialogRef.close();
          this.notificationService.showWarning('main.fieldAdministration.copyField.notifications.noFieldsToCopy');
        }
      })
    );
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCancelClick() {
    this.dialogRef.close();
  }

  public onConfirmClick() {
    if (!this.fieldModifyElement) return;

    if (this.fieldModifyElement.gridData.length < 1) {
      this.notificationService.showInfo('main.fieldAdministration.copyField.notifications.noFieldsSelected');
      return;
    }
    this.fieldCopyStateService.transferingFields = true;
    this.dialogRef.close();

    this.fieldCopyLogicService.MoveFieldsToNextHarvestYear(this.fieldModifyElement.gridData).subscribe(
      (response: FieldChangeCropResponse[]) => {
        const updatedFields = response.reduce((initialFields: Field[][], item) => {
          return [...initialFields, item.updatedFields];
        }, []);
        const successFields = updatedFields.flat();
        this.notificationService.showSuccess(
          successFields.length + this.translateService.instant('main.fieldAdministration.copyField.notifications.CopySuccess')
        );
        this.fieldCopyStateService.transferingFields = false;
      },
      (error) => {
        this.dialogRef.close();
        this.notificationService.showError('main.fieldAdministration.copyField.notifications.CopyError');
        this.fieldCopyStateService.transferingFields = false;
      }
    );

    const config: MatDialogConfig = {
      data: {},
      panelClass: 'copy-field-complete-dialog',
      autoFocus: false,
      disableClose: true,
    };
    this.dialogService.openMd(FieldCopyCompleteDialogComponent, config);
  }
}
