import { Injectable } from '@angular/core';
import { ClimateModels } from '@app/core/enums/climate-models.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { GrassPrognosisErrorCodes } from '@app/core/repositories/grass-prognosis/grass-prognosis-error-codes.enum';
import { GrassPrognosisError } from '@app/core/repositories/grass-prognosis/grass-prognosis-error.interface';
import { GrassPrognosisRepoService } from '@app/core/repositories/grass-prognosis/grass-prognosis-repo.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GrassPrognosisInfoComponent } from './grass-prognosis-info/grass-prognosis-info.component';
import { GrassPrognosisOptions } from './grass-prognosis-settings/grass-prognosis-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class GrassPrognosisService {
  constructor(
    private languageService: LanguageService,
    private grassPrognosisRepo: GrassPrognosisRepoService,
    private dialogService: DialogService
  ) {}

  public getOptions(model: ClimateModels) {
    return this.grassPrognosisRepo.getOptions(model).pipe(
      map((options) => {
        return {
          ...options,
          testRunDate: options.testRunDate ? DateTime.fromISO(options.testRunDate as string).setZone('utc') : DateTime.now().setZone('utc'),
          previousCutDate: options.previousCutDate
            ? DateTime.fromISO(options.previousCutDate as string).setZone('utc')
            : DateTime.now().setZone('utc'),
        };
      })
    );
  }

  public getPrognosisResults(model: ClimateModels, field: Field, today: DateTime, options: GrassPrognosisOptions) {
    const currentCrop = field.crops.reduce((prev, curr) => (prev.successionNo < curr.successionNo ? prev : curr));

    return this.grassPrognosisRepo
      .getPrognosisResults(model, field.farmId, field.harvestYear, field.featureId, currentCrop.id, options)
      .pipe(
        catchError((error: GrassPrognosisError) => {
          const errorMessage = this.findErrorMessageByCode(error.errorCode, today);
          throw new Error(errorMessage);
        })
      );
  }

  public getPrognosis(model: ClimateModels, field: Field, today: DateTime, options: GrassPrognosisOptions) {
    const currentCrop = field.crops.reduce((prev, curr) => (prev.successionNo < curr.successionNo ? prev : curr));

    return this.grassPrognosisRepo.getPrognosis(model, field.farmId, field.harvestYear, field.featureId, currentCrop.id, options).pipe(
      catchError((error: GrassPrognosisError) => {
        const errorMessage = this.findErrorMessageByCode(error.errorCode, today);
        throw new Error(errorMessage);
      })
    );
  }

  public getSampleValues() {
    return forkJoin(
      this.grassPrognosisRepo.getCutNumbers(),
      this.grassPrognosisRepo.getSampleFKOrgValues(),
      this.grassPrognosisRepo.getSampleRPValues(),
      this.grassPrognosisRepo.getSampleSugarValues(),
      this.grassPrognosisRepo.getPreviousCutYieldValues()
    );
  }

  public get settingsLoadingMessage() {
    return this.languageService.getText('grassPrognosis.settings.loading');
  }

  public showInfoDialog(isGrazing: boolean) {
    GrassPrognosisInfoComponent.isGrazing = isGrazing;
    this.dialogService.openInfoDialog(GrassPrognosisInfoComponent);
  }

  private findErrorMessageByCode(errorCode: GrassPrognosisErrorCodes | null, date: DateTime) {
    switch (errorCode?.toString()) {
      case GrassPrognosisErrorCodes.VarietyUnknown:
        return this.languageService.getText('grassPrognosis.errors.varietyUnknown');
      case GrassPrognosisErrorCodes.Error1012:
        return this.languageService.getText('grassPrognosis.errors.harvestYearMismatch');
      case GrassPrognosisErrorCodes.Error1009:
        return this.languageService.getText('grassPrognosis.errors.VarietyMissing');
      case GrassPrognosisErrorCodes.Error1010:
        return this.languageService.getText('grassPrognosis.errors.varietyUnknown'); // ukendt norm
      case GrassPrognosisErrorCodes.DMINotResponding:
      case GrassPrognosisErrorCodes.ModelNotValidForActualParameters:
      case GrassPrognosisErrorCodes.VarietyMissing:
      case GrassPrognosisErrorCodes.CutNumberNotOne:
      case GrassPrognosisErrorCodes.Error1007:
      case GrassPrognosisErrorCodes.Error1008:
      default:
        return this.languageService.getText('grassPrognosis.errors.default');
    }
  }
}
