import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { SessionService } from '@app/core/session/session.service';
import { View } from 'ol';
import Map from 'ol/Map';
import { defaults as defaultControls } from 'ol/control.js';
import { easeOut } from 'ol/easing';
import { Extent } from 'ol/extent';
import { Layer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Vector } from 'ol/source';
import { ExtentUtil } from '../helpers/utils/extent-util';
import { OlMapService } from '../map-service/ol-map.service';
import { LayerId } from '../services/layer/layer.store';
@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss'],
})
export class OlMapComponent implements AfterViewInit, OnDestroy {
  private _fieldMapCenter = this._sessionService.fieldMapCenter;
  private _fieldMapZoom = this._sessionService.fieldMapZoom;

  @ViewChild('mapContainer') mapContainer!: ElementRef;

  private map!: Map;

  constructor(
    private _mapService: OlMapService,
    private _sessionService: SessionService
  ) {}

  ngAfterViewInit() {
    const view = new View({
      center: this._fieldMapCenter ?? fromLonLat([10, 56]),
      zoom: this._fieldMapZoom ?? 10,
      maxZoom: 20,
      minZoom: 7,
    });
    this.map = new Map({
      target: this.mapContainer.nativeElement,
      view: view,
      controls: defaultControls({
        zoom: false,
        rotate: false,
        attributionOptions: {
          collapsible: false,
        },
      }),
    });

    view.on('change:resolution', () => {
      const zoomLevel = view.getZoom();
      this._mapService.zoomLevelSubject.next(zoomLevel);
    });
    this._mapService.setMapComponent(this);
    this._mapService.setMapReady();
  }

  ngOnDestroy(): void {
    this.map.dispose();
    this._mapService.cleanUp();
  }

  /** Returns the map instance */
  public getMap() {
    return this.map;
  }

  /**
   * Zooms in or out the map by a given increment
   * @param increment - positive or negative number to zoom in or out
   */
  public zoomIncrementally(increment: number) {
    this.zoom(this.map?.getView().getZoom()! + increment);
  }

  /**
   * Zooms the map to a specific zoom level
   * @param zoomLevel - the zoom level to set the map to
   */
  public zoom(zoomLevel: number = 7) {
    !zoomLevel && (zoomLevel = 7);

    this.map?.getView()?.animate({
      zoom: zoomLevel,
      duration: 100,
    });
  }

  /**
   * Zooms the map to a specific extent
   * @param extent - the extent to zoom the map to
   * @param padding - padding to add to the extent
   */
  public slideMapToExtent(extent: Extent | undefined, padding?: number[]) {
    if (!extent || !ExtentUtil.isValidExtend(extent)) return;

    this.map?.getView().fit(extent, {
      padding,
      easing: easeOut,
      duration: 500,
    });
  }

  /** Returns all layers presently in the map array */
  public getLayersFromMap() {
    return this.map.getLayers().getArray();
  }
  /**
   * Returns a layer from the map array by its id
   * @param id - the id of the layer to return
   */
  public getLayerFromMap(id: LayerId): Layer | undefined {
    return this.map
      .getLayers()
      .getArray()
      .find((layer) => layer.get('id') === id) as Layer;
  }

  public getFeaturesFromLayer(id: LayerId) {
    return (this.getLayerFromMap(id)?.getSource() as Vector)?.getFeatures();
  }

  /**
   * Checks if a layer is in the map array
   * @param id - the id of the layer to check
   */
  public isLayerInMap(id: LayerId): boolean {
    return this.getLayerFromMap(id) !== undefined;
  }

  /**
   * Adds a layer to the map
   * @param layer - the layer to add
   * @returns
   */
  public addLayer(layer: Layer) {
    if (this.isLayerInMap(layer.get('id'))) return;
    this.map.addLayer(layer);
  }

  /**
   * Removes a layer from the map
   * @param id - the id of the layer to remove
   */
  public removeLayer(id: LayerId): void {
    const permanantLayerIds = [LayerId.OSM, LayerId.AIRPHOTO_DK, LayerId.AIRPHOTO_FOREIGN];
    if (!this.isLayerInMap(id) || permanantLayerIds.includes(id)) return;
    const layerToRemove = this.getLayerFromMap(id);
    layerToRemove && this.map?.removeLayer(layerToRemove);
  }
}
