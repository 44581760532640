import { Injectable } from '@angular/core';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { ScaleLegendItem } from '@app/core/interfaces/scale-legend-item.interface';
import { CultivationJournalHotspotFeature } from '@app/new-map/features/cultivation-journal/interfaces/map/cultivation-journal-hotspot-feature.interface';
import { CultivationJournalOptions } from '@app/new-map/features/cultivation-journal/interfaces/map/cultivation-journal-options.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { Coordinate } from 'ol/coordinate';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapStateService {
  private _fieldInspectorMapFieldFeaturesSubject = new BehaviorSubject<FieldLayerItem[] | null>(null);
  private _hotspotFeaturesSubject = new BehaviorSubject<CultivationJournalHotspotFeature[] | null>(null);
  private _fieldMapFieldFeaturesSubject = new BehaviorSubject<FieldLayerItem[] | null>(null);
  private _fieldMapFieldFeaturesExtentSubject = new BehaviorSubject<number[] | null>(null);
  private _fieldMapLegendCropColorsSubject = new BehaviorSubject<CropColor[] | null>(null);
  private _fieldMapLayerSettingsSubject = new BehaviorSubject<MapLayerSetting[] | null>(null);
  private _mapCoverFlowItemsSubject = new BehaviorSubject<MapCoverFlowItem[] | null>(null);
  private _selectedMapCoverFlowItemIdxSubject = new BehaviorSubject<number | null>(null);
  private _fieldMapOptionsSubject = new BehaviorSubject<CultivationJournalOptions | null>(null);
  private _fieldMapInitialLoadSubject = new BehaviorSubject<boolean | null>(null);
  private _fieldInspectorMapFieldFeaturesExtentSubject = new BehaviorSubject<number[] | null>(null);
  private _fieldInspectorMapLegendCropColorsSubject = new BehaviorSubject<CropColor[] | null>(null);
  private _fieldInspectorMapLayerSettingsSubject = new BehaviorSubject<MapLayerSetting[] | null>(null);
  private _fieldInspectorMapOptionsSubject = new BehaviorSubject<CultivationJournalOptions | null>(null);
  private _fieldInspectorClickedCoordsSubject = new BehaviorSubject<number[] | null>(null);
  private _clickedCoordsSubject = new BehaviorSubject<number[] | null>(null);
  private _scaleLegendTitleSubject = new BehaviorSubject<string | null>(null);
  private _scaleLegendItemsSubject = new BehaviorSubject<ScaleLegendItem[] | null>(null);
  private _scaleLegendSummaryLinesSubject = new BehaviorSubject<string[] | null>(null);
  private _scaleLegendNoteSubject = new BehaviorSubject<string | null>(null);
  private _mapCoverflowVisbilityChangedSubject = new BehaviorSubject<boolean | null>(null);

  public get fieldInspectorMapFieldFeatures$() {
    return this._fieldInspectorMapFieldFeaturesSubject.asObservable();
  }

  public set fieldInspectorMapFieldFeatures(fieldInspectorMapFieldFeatures: FieldLayerItem[]) {
    this._fieldInspectorMapFieldFeaturesSubject.next(fieldInspectorMapFieldFeatures);
  }

  public get hotspotFeatures$() {
    return this._hotspotFeaturesSubject.asObservable();
  }

  public set hotspotFeatures(hotspotFeatures: CultivationJournalHotspotFeature[]) {
    this._hotspotFeaturesSubject.next(hotspotFeatures);
  }

  public get fieldMapFieldFeatures$() {
    return this._fieldMapFieldFeaturesSubject.asObservable();
  }

  public set fieldMapFieldFeatures(fieldMapFieldFeatures: FieldLayerItem[]) {
    this._fieldMapFieldFeaturesSubject.next(fieldMapFieldFeatures);
  }

  public get fieldMapFieldFeaturesExtent$() {
    return this._fieldMapFieldFeaturesExtentSubject.asObservable();
  }

  public set fieldMapFieldFeaturesExtent(fieldMapFieldFeaturesExtent: Coordinate) {
    this._fieldMapFieldFeaturesExtentSubject.next(fieldMapFieldFeaturesExtent);
  }

  public get fieldMapLegendCropColors$() {
    return this._fieldMapLegendCropColorsSubject.asObservable();
  }

  public set fieldMapLegendCropColors(fieldMapLegendCropColors: CropColor[]) {
    this._fieldMapLegendCropColorsSubject.next(fieldMapLegendCropColors);
  }

  public get fieldMapLayerSettings$() {
    return this._fieldMapLayerSettingsSubject.asObservable();
  }

  public set fieldMapLayerSettings(fieldMapLayerSettings: MapLayerSetting[]) {
    this._fieldMapLayerSettingsSubject.next(fieldMapLayerSettings);
  }

  public get mapCoverFlowItems$() {
    return this._mapCoverFlowItemsSubject.asObservable();
  }

  public set mapCoverFlowItems(mapCoverFlowItems: MapCoverFlowItem[]) {
    this._mapCoverFlowItemsSubject.next(mapCoverFlowItems);
  }

  public get selectedMapCoverFlowItemIdx$() {
    return this._selectedMapCoverFlowItemIdxSubject.asObservable();
  }

  public set selectedMapCoverFlowItemIdx(selectedMapCoverFlowItemIdx: number | null) {
    this._selectedMapCoverFlowItemIdxSubject.next(selectedMapCoverFlowItemIdx);
  }

  public get fieldMapOptions$() {
    return this._fieldMapOptionsSubject.asObservable();
  }

  public set fieldMapOptions(fieldMapOptions: CultivationJournalOptions) {
    this._fieldMapOptionsSubject.next(fieldMapOptions);
  }

  public get fieldMapInitialLoad$() {
    return this._fieldMapInitialLoadSubject.asObservable();
  }

  public set fieldMapInitialLoad(fieldMapInitialLoad: boolean) {
    this._fieldMapInitialLoadSubject.next(fieldMapInitialLoad);
  }

  public get fieldInspectorMapFieldFeaturesExtent$() {
    return this._fieldInspectorMapFieldFeaturesExtentSubject.asObservable();
  }

  public set fieldInspectorMapFieldFeaturesExtent(fieldInspectorMapFieldFeaturesExtent: [number, number, number, number]) {
    this._fieldInspectorMapFieldFeaturesExtentSubject.next(fieldInspectorMapFieldFeaturesExtent);
  }

  public get fieldInspectorMapLegendCropColors$() {
    return this._fieldInspectorMapLegendCropColorsSubject.asObservable();
  }

  public set fieldInspectorMapLegendCropColors(fieldInspectorMapLegendCropColors: CropColor[]) {
    this._fieldInspectorMapLegendCropColorsSubject.next(fieldInspectorMapLegendCropColors);
  }

  public get fieldInspectorMapLayerSettings$() {
    return this._fieldInspectorMapLayerSettingsSubject.asObservable();
  }

  public set fieldInspectorMapLayerSettings(fieldInspectorMapLayerSettings: MapLayerSetting[]) {
    this._fieldInspectorMapLayerSettingsSubject.next(fieldInspectorMapLayerSettings);
  }

  public get fieldInspectorMapOptions$() {
    return this._fieldInspectorMapOptionsSubject.asObservable();
  }

  public set fieldInspectorMapOptions(fieldInspectorMapOptions: CultivationJournalOptions) {
    this._fieldInspectorMapOptionsSubject.next(fieldInspectorMapOptions);
  }

  public get fieldInspectorClickedCoords$() {
    return this._fieldInspectorClickedCoordsSubject.asObservable();
  }

  public set fieldInspectorClickedCoords(fieldInspectorClickedCoords: number[]) {
    this._fieldInspectorClickedCoordsSubject.next(fieldInspectorClickedCoords);
  }

  public get clickedCoords$() {
    return this._clickedCoordsSubject.asObservable();
  }

  public set clickedCoords(clickedCoords: number[]) {
    this._clickedCoordsSubject.next(clickedCoords);
  }

  public get scaleLegendTitle$() {
    return this._scaleLegendTitleSubject.asObservable();
  }

  public set scaleLegendTitle(scaleLegendTitle: string) {
    this._scaleLegendTitleSubject.next(scaleLegendTitle);
  }

  public get scaleLegendItems$() {
    return this._scaleLegendItemsSubject.asObservable();
  }

  public set scaleLegendItems(scaleLegendItems: ScaleLegendItem[]) {
    this._scaleLegendItemsSubject.next(scaleLegendItems);
  }

  public get scaleLegendSummaryLines$() {
    return this._scaleLegendSummaryLinesSubject.asObservable();
  }

  public set scaleLegendSummaryLines(scaleLegendSummaryLines: string[]) {
    this._scaleLegendSummaryLinesSubject.next(scaleLegendSummaryLines);
  }

  public get scaleLegendNote$() {
    return this._scaleLegendNoteSubject.asObservable();
  }

  public set scaleLegendNote(scaleLegendNote: string) {
    this._scaleLegendNoteSubject.next(scaleLegendNote);
  }

  public get mapCoverflowVisbilityChanged$() {
    return this._mapCoverflowVisbilityChangedSubject.asObservable();
  }

  public set mapCoverflowVisbilityChanged(mapCoverflowVisbilityChanged: boolean) {
    this._mapCoverflowVisbilityChangedSubject.next(mapCoverflowVisbilityChanged);
  }
}
