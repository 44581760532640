<div class="header-close">
  <h4>{{ 'rainChart.help.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <ul class="info-bullets">
    <li>{{ 'rainChart.help.bullets.1' | translate }}</li>
    <li>{{ 'rainChart.help.bullets.2' | translate }}</li>
  </ul>
</mat-dialog-content>
