import { UntypedFormControl } from '@angular/forms';
import { StringValidator } from '@app/helpers/validators/string-validator';

/**
 * Validate if control value has whitespace or not
 * @param control The form control to validate
 */
export function NoWhitespaceValidator(control: UntypedFormControl) {
  const hasWhitespace = StringValidator.hasWhitespaces(control.value || '');

  return !hasWhitespace ? null : { hasWhitespace: true };
}
