import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
})
export class DialogHeaderComponent {
  @Input() public title = '';
  @Input() public size: 'md' | 'lg' = 'lg';
  @Input() public showClose = true;
  @Input() public showInfo = false;
  @Input() public infoText = '';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter<void>();

  public onClose() {
    this.close.emit();
  }
}
