<p *ngIf="reminders.length === 0" class="no-reminders-message">{{ 'reminders.header.noReminders' | translate }}</p>
<mat-accordion>
  <mat-expansion-panel hideToggle="true" #expansionPanel *ngFor="let reminder of reminders">
    <mat-expansion-panel-header (click)="setReadState(reminder)" expandedHeight="45px" collapsedHeight="80px">
      <mat-panel-title>
        <div class="panel-title-container">
          <span class="panel-title-header" [ngClass]="{ read: reminder.isRead }">{{ reminder.title }}</span>
          <div *ngIf="!expansionPanel.expanded">{{ reminder.text }}</div>
        </div>
      </mat-panel-title>
      <mat-panel-description *ngIf="reminder.startDate" class="right-aligned">
        {{ reminder.startDate | dateFormat }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    {{ reminder.text }}
    <div *ngIf="reminder.url && reminder.url.length > 0">
      <br />
      <span *ngIf="reminder.urlText && reminder.urlText.length > 0; else noUrlText"
        >{{ reminder.urlText }}: <a [href]="reminder.url" target="_blank">{{ reminder.url }}</a>
      </span>
      <ng-template #noUrlText>
        <a [href]="reminder.url" target="_blank">{{ reminder.url }}</a>
      </ng-template>
    </div>
  </mat-expansion-panel>
</mat-accordion>
