import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonParentComponent } from '@fe-shared/ui-components/buttons/button-parent-component';

@Directive()
export abstract class ButtonChildComponent<ButtonTypes = any> {
  @Input() public parent?: ButtonParentComponent<ButtonTypes>;

  public get iconButton() {
    return this.parent?.iconButton;
  }

  public get type() {
    return this.parent?.type;
  }

  public get text() {
    return this.parent?.text;
  }

  @HostBinding('attr.disabled')
  public get disabled() {
    return this.parent?.disabled;
  }

  public get buttonStyle() {
    return this.parent?.buttonStyle;
  }

  public get buttonClass() {
    return this.parent?.buttonClass;
  }

  public clicked() {
    this.parent?.clicked.emit();
  }

  public onClick() {
    if (!this.disabled) this.clicked();
  }
}
