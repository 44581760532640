<div class="info-text">
  {{ userAverage.aggPctOfFarmsWithYieldOrBelow | number: '1.0-1' }} {{ 'main.benchmark.regionalComp.infoText' | translate }}
</div>
<kendo-chart>
  <kendo-chart-tooltip [background]="'rgba(0,0,0,0.80)'" [color]="'white'">
    <ng-template kendoChartSeriesTooltipTemplate let-value="value" [>
      <b>{{ value.x | number: '1.0-1' }} %</b> {{ 'main.benchmark.regionalComp.tooltipA' | translate }} <br />{{
        'main.benchmark.regionalComp.tooltipB' | translate
      }}
      <br />{{ 'main.benchmark.regionalComp.tooltipC' | translate }}
      <b>{{ value.y | number: '1.0-1' }} {{ unit }}/ha</b>
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <kendo-chart-series-item
      [color]="'rgb(9, 86, 44)'"
      [markers]="{ visual: markerVisual }"
      [highlight]="{ visual: markerHighlight }"
      [type]="'scatterLine'"
      [data]="series"
      xField="aggPctOfFarmsWithYieldOrBelow"
      yField="yieldQuantity"
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-x-axis>
    <kendo-chart-x-axis-item [title]="{ text: xAxisTitle }" [max]="100"> </kendo-chart-x-axis-item>
  </kendo-chart-x-axis>
  <kendo-chart-y-axis>
    <kendo-chart-y-axis-item [title]="{ text: yAxisTitle }"> </kendo-chart-y-axis-item>
  </kendo-chart-y-axis>
</kendo-chart>
