<form [formGroup]="form">
  <mat-card appearance="outlined">
    <mat-card-title class="file-picker-title">{{ 'main.asApplied.filePicker.header' | translate }}</mat-card-title>
    <mat-card-content>
      <app-select
        *ngIf="(selectableFarms$ | async)?.length! > 1"
        formControlName="farm"
        [placeholder]="'main.fieldmap.hotspotInfo.farm' | translate"
      >
        <app-select-option *ngFor="let farm of selectableFarms$ | async; trackBy: trackByFn" [value]="farm">
          {{ farm.name }}
        </app-select-option>
      </app-select>
      <!--  Farm error  -->
      <ng-container *ngIf="form.get('farm') as farm">
        <div *ngIf="farm.touched && farm.hasError('required')" class="image-button-error">
          {{ 'main.asApplied.filePicker.farmRequired' | translate }}
        </div>
      </ng-container>

      <!-- Harvest year -->
      <div class="input-line">
        {{ 'main.asApplied.filePicker.harvestYear' | translate }}
        <label>{{ selectedHarvestYear$ | async }}</label>
        <app-tooltip-overlay size="sm" [tooltipTemplate]="harvestYearTooltip" (click)="$event.stopPropagation()">
          <app-icon icon="info_outline" class="info-hover"> </app-icon>
        </app-tooltip-overlay>
        <ng-template #harvestYearTooltip>
          <div class="tooltip-wrapper">
            <h3>{{ 'main.asApplied.filePicker.harvestYear-info-header' | translate }}</h3>
            <p>{{ 'main.asApplied.filePicker.harvestYear-info-body' | translate }}</p>
          </div>
        </ng-template>
      </div>

      <br />
      <br />
      <div class="upload-wrapper">
        <app-square-button buttonType="Primary" (clicked)="asAppliedFilePicker.click()">{{
          'main.asApplied.filePicker.selectFiles' | translate
        }}</app-square-button>
        <input hidden #asAppliedFilePicker type="file" (change)="onFileInputChange($event)" [accept]="'application/x-zip-compressed'" />
      </div>

      <div class="upload-wrapper">
        <app-square-button class="upload-btn" buttonType="Primary" (clicked)="uploadClicked()">{{
          'main.asApplied.filePicker.upload' | translate
        }}</app-square-button>
      </div>

      <ng-container *ngIf="form.get('files')!.value as files">
        <p *ngFor="let file of files">{{ file.name }}</p>
      </ng-container>

      <!--  Errors  -->
      <ng-container *ngIf="form.get('files') as files">
        <p *ngIf="files.touched && files.hasError('required')" class="image-button-error">
          {{ 'main.asApplied.filePicker.filesRequired' | translate }}
        </p>
        <p *ngIf="files.touched && files.hasError('invalidFileExtensions')" class="image-button-error">
          {{ 'main.asApplied.filePicker.invalidFileExtension' | translate }}
        </p>
      </ng-container>
    </mat-card-content>
  </mat-card>
</form>
