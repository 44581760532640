import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { BlightRiskDto } from '@app/core/interfaces/BlightRiskDto';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { BlightTableSubLineDto } from './models/blight-table-sub-line-dto';

@Injectable({
  providedIn: 'root',
})
export class BlightRepositoryService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(farmIds: number[], harvestYear: number): Observable<BlightTableSubLineDto[]> {
    return this.httpClient.get<BlightTableSubLineDto[]>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/blight/table`, {});
  }

  public getBlightRisks(
    farmId: number,
    harvestYear: number | undefined,
    fieldId: number,
    cropId: number,
    startDate: DateTime,
    endDate: DateTime
  ): Observable<BlightRiskDto[]> {
    return this.httpClient.get<BlightRiskDto[]>(
      `${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/fields/${fieldId}/crops/${cropId}/blight/prognoses`,
      {
        params: { startDate: startDate.setZone('utc'), endDate: endDate.setZone('utc') },
      }
    );
  }
}
