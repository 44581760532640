import { Component, Inject } from '@angular/core';
import { ButtonChildComponent } from '../../button-child-component';
import { SquareButtonComponent, SquareButtonTypes } from '../square-button.component';

@Component({
  selector: 'app-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
})
export class CTAButtonComponent extends ButtonChildComponent<SquareButtonTypes> {
  constructor(@Inject(SquareButtonComponent) parent: SquareButtonComponent) {
    super();
    this.parent = parent;
  }
}
