<div class="farmpicker-modal">
  <app-farm-picker-modal-create-farm *ngIf="isAddFarmOpen"></app-farm-picker-modal-create-farm>
  <div class="toolbar" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <h1 mat-dialog-title class="toolbar-title">
      {{ 'header.farmPicker.windowTitle' | translate }}
    </h1>
    <div class="toolbar-actions" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
      <app-square-button buttonType="Primary" (clicked)="onAddFarmClicked()" *ngIf="showCreateButton">{{
        'header.farmPicker.buttons.new' | translate
      }}</app-square-button>
      <app-search-field
        [appResponsiveClass]="{ mobile: 'lt-Medium' }"
        [initValue]="initialSearchValue"
        (searchFor)="onSearchValueChange($event)"
      >
      </app-search-field>
    </div>
  </div>
  <mat-dialog-content class="modal-content" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <app-spinner *ngIf="isLoading" [hasBackdrop]="true" [message]="'header.farmPicker.loading' | translate"></app-spinner>
    <div class="no-farms" *ngIf="(hasZeroFarms$ | async) && !isConsultant() && !isLoading">
      <p>{{ 'header.farmPicker.noFarms.noFarmsText' | translate }}</p>
      <p>{{ 'header.farmPicker.noFarms.createFarmText' | translate }}</p>
    </div>
    <mat-grid-list cols="{{ farmGridCols }}" rowHeight="1:1.4">
      <mat-grid-tile *ngFor="let farm of selectedFarms" [colspan]="1" [rowspan]="1">
        <app-farm-card [farm]="farm" [isSelected]="true" (click)="onFarmClicked(farm)"></app-farm-card>
      </mat-grid-tile>
      <mat-grid-tile *ngFor="let farm of selectableFarms" [colspan]="1" [rowspan]="1">
        <app-farm-card [farm]="farm" [isSelected]="false" (click)="onFarmClicked(farm)"></app-farm-card>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-dialog-content>
  <mat-dialog-actions [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <app-square-button
      class="accept-btn"
      [appResponsiveClass]="{ mobile: 'lt-Medium' }"
      [buttonType]="'Primary'"
      id="e2e-choose-farms"
      [appShortcutKey]="shortcuts.saveAndClose"
      [condition]="!!selectedFarms.length"
      (shortcutPressed)="onConfirmFarmsClicked()"
      [disabled]="selectedFarms.length === 0"
      (clicked)="onConfirmFarmsClicked()"
      >{{ 'header.farmPicker.buttons.selectFarm' | translate }}
    </app-square-button>
    <app-square-button
      id="e2e-farm-picker-cancel-button"
      [appShortcutKey]="shortcuts.cancel"
      (shortcutPressed)="onCancelFarmsClicked()"
      [buttonType]="'Secondary'"
      (clicked)="onCancelFarmsClicked()"
    >
      {{ 'common.cancel' | translate }}
    </app-square-button>
  </mat-dialog-actions>
</div>
