import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { BlightInfectionDataInfo } from '@app/core/interfaces/blight-infection-data-info.interface';
import { BlightInfoForField } from '@app/core/interfaces/blight-info-for-field.interface';
import { BlightMapPolygon } from '@app/core/interfaces/blight-map-polygons.interface';
import { BligtInfectionRegistration } from '@app/core/interfaces/bligt-infection-registration';
import { Field } from '@app/core/interfaces/field.interface';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export interface IPotatoFieldRepo {
  getPotatoFields(farmId: number, year: number): Observable<Field[]>;

  getBlightInfoForField(farmIds: number[], year: number, fieldId: number): Observable<Field[]>;

  getBlightInfectionDataInfo(): Observable<BlightInfectionDataInfo>;
}

@Injectable({
  providedIn: 'root',
})
export class PotatoFieldRepoService {
  constructor(
    public httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getPotatoFields(farmIds: number[], year: number): Observable<Field[]> {
    return this.httpClient.get<Field[]>(`${this.endpoints.foApi}/farms/${farmIds}/${year}/fieldswithpotatoes`);
  }

  public getBlightInfoForField(farmIds: number[], year: number, fieldId: number): Observable<BlightInfoForField> {
    return this.httpClient.get<BlightInfoForField>(`${this.endpoints.foApi}/farms/${farmIds}/${year}/fieldswithpotatoes/${fieldId}`);
  }

  public getBlightInfectionDataInfo(): Observable<BlightInfectionDataInfo> {
    return this.httpClient.get<BlightInfectionDataInfo>(`${this.endpoints.foApi}/blightinfectiondatainfo`);
  }

  public saveBlightInfectionRegistration(blightInfectionRegistration: BligtInfectionRegistration): Observable<any> {
    return this.httpClient.post<BligtInfectionRegistration, {}>(`${this.endpoints.foApi}/blightregistration`, blightInfectionRegistration);
  }

  public getBlightPolygonsForMap(date: Date): Observable<BlightMapPolygon[]> {
    const shortDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    return this.httpClient.get<BlightMapPolygon[]>(`${this.endpoints.foApi}/blight/denmark?date=${shortDate}`);
  }

  public getBlightFieldsForMap(date: Date, farmIds: number[], harvestYear: number): Observable<BlightMapPolygon[]> {
    const shortDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    return this.httpClient.get<BlightMapPolygon[]>(`${this.endpoints.foApi}/blight/farm/${farmIds}/${harvestYear}?date=${shortDate}`);
  }
}
