<app-dialog-header size="lg" [title]="'main.fieldmap.basis-layer.dialog.title' | translate" (close)="close()"> </app-dialog-header>

<app-dialog-content size="lg">
  <p class="text">
    {{ 'main.fieldmap.basis-layer.dialog.text1' | translate }}
  </p>
  <p class="text">
    {{ 'main.fieldmap.basis-layer.dialog.text2' | translate }}
  </p>
  <p class="text">
    {{ 'main.fieldmap.basis-layer.dialog.text3' | translate }}
  </p>
  <p class="text">
    {{ 'main.fieldmap.basis-layer.dialog.text4' | translate }}
  </p>
</app-dialog-content>
