import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataConnectionsService } from '@app/core/data-connections/data-connections.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { LeafwetHoursDto, WetHourDto } from '@app/core/repositories/weather/leaf-wet-hours.interface';
import { WeatherDataObj } from '@app/core/repositories/weather/weather-data.interface';
import { WetHoursDataProviderStation } from '@app/shared/septoria/wet-hours-data-provider-station';
import { WetHoursDataProvider } from '@app/shared/septoria/wetHoursDataProvider';
import { DateTime } from 'luxon';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WeatherRepoService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService,
    private dataConnectionsService: DataConnectionsService
  ) {}

  public getRainData(
    farmId: number,
    harvestYear: number,
    featureId: number,
    startDate: DateTime,
    endDate: DateTime
  ): Observable<WeatherDataObj> {
    return this.httpClient.get<WeatherDataObj>(`${this.endpoints.foApi}/weatherdata/${farmId}/${harvestYear}`, {
      params: {
        featureId,
        dateFrom: startDate.toISO(),
        dateTo: endDate.toISO(),
        dataSources: [0, 1].toString(),
        interval: 1,
        dataParameters: 7,
      },
    });
  }

  public getDmiLeafWetHours(farmId: number, harvestYear: number, fieldId: number, diseaseType: number): Observable<LeafwetHoursDto> {
    return this.httpClient.get<LeafwetHoursDto>(`${this.endpoints.foApi}/leafwet/${farmId}/${harvestYear}/field/${fieldId}`, {
      params: {
        deceaseType: diseaseType,
      },
    });
  }

  public getRanchStations(setting: DataConnectionSettingDTO): Observable<WetHoursDataProviderStation[]> {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((dataConnectionSetting) => {
        if (!dataConnectionSetting) return of([]);

        return this.httpClient.get<WetHoursDataProviderStation[]>(`${this.endpoints.weatherDataApi}/ranch/stations`, {
          headers: this.createRanchAuthorizationHeader(dataConnectionSetting.connectionId, dataConnectionSetting.connectionCode),
        });
      })
    );
  }

  public getFieldSenseStation(setting: DataConnectionSettingDTO): Observable<WetHoursDataProviderStation[]> {
    return this.dataConnectionsService.getSettingById(setting.id).pipe(
      switchMap((dataConnectionSetting) => {
        if (!dataConnectionSetting) return of([]);

        return this.httpClient.get<WetHoursDataProviderStation[]>(`${this.endpoints.weatherDataApi}/fieldsense/stations`, {
          headers: this.createFieldSenseAuthorizationHeader(dataConnectionSetting.connectionCode),
        });
      })
    );
  }

  public getRanchLeafWetHours(provider: WetHoursDataProvider, from: DateTime, to: DateTime): Observable<WetHourDto[]> {
    return this.dataConnectionsService.getSettingById(provider.dataConnection.id).pipe(
      switchMap((dataConnectionSetting) => {
        if (!dataConnectionSetting) return of([]);

        return this.httpClient.get<WetHourDto[]>(`${this.endpoints.weatherDataApi}/ranch/wethour`, {
          headers: this.createRanchAuthorizationHeader(dataConnectionSetting.connectionId, dataConnectionSetting.connectionCode),
          params: {
            stationId: provider.stationId,
            unitId: provider.unitId,
            from: from.toISO(),
            to: to.toISO(),
          },
        });
      })
    );
  }

  public getFieldSenseLeafWetHours(provider: any, from: DateTime, to: DateTime): Observable<WetHourDto[]> {
    return this.httpClient.get<WetHourDto[]>(`${this.endpoints.weatherDataApi}/fieldsense/wethour`, {
      headers: this.createFieldSenseAuthorizationHeader(provider.dataConnection.connectionCode),
      params: {
        stationId: provider.stationId,
        from: from.toISO(),
        to: to.toISO(),
      },
    });
  }

  private createRanchAuthorizationHeader(username: string, password: string): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Authorization', this.encodeCredentials(username, password));
  }

  private createFieldSenseAuthorizationHeader(connectionCode: string): HttpHeaders {
    const headers = new HttpHeaders();
    return headers.append('Authorization', 'bearer ' + connectionCode);
  }

  private encodeCredentials(userName: string, password: string): string {
    return `Basic ${btoa(userName + ':' + password)}`;
  }
}
