<app-ol-map-feature-select
  (event)="onHotspotClick($event)"
  [layers]="selectableLayers"
  [disabled]="isSelectDisabled"
></app-ol-map-feature-select>
<div class="main-wrapper">
  <app-spinner *ngIf="isLoading$ | async" [hasBackdrop]="true" [message]="(loadingMessage$ | async)!"></app-spinner>

  <app-create-hotspot
    #createHotspot
    *appSideIf="(shownComponent$ | async)!; index: components.createHotspotComponent"
    (newHotspotDrawn)="onNewHotspotDrawn($event)"
    (importShapeFileClicked)="onImportShapeFileClicked()"
    (createHotspotGroupClicked)="onCreateHotspotGroupClicked()"
  >
  </app-create-hotspot>

  <app-hotspot-details
    *appSideIf="(shownComponent$ | async)!; index: components.hotspotDetailsComponent"
    class="selected-hotspot-overlay"
    [hotspot]="selectedHotspot!"
    [hotspotTypes]="hotspotTypes"
    [hotspotSubTypes]="hotspotSubTypes"
    (close)="onCloseHotspotDetails($event)"
    (update)="onHotspotUpdated($event)"
    (deleted)="onHotspotDeleted($event)"
    (created)="onHotspotCreated($event)"
  >
  </app-hotspot-details>

  <app-shape-file-import
    *appSideIf="(shownComponent$ | async)!; index: components.importShapefileComponent"
    (close)="onCloseShapeFileImportClick()"
    (onShapeImported)="onShapeFileImported($event)"
    [hotspotTypes]="hotspotTypes"
  >
  </app-shape-file-import>

  <app-hotspot-groups *appSideIf="(shownComponent$ | async)!; index: components.hotspotGroupComponent"> </app-hotspot-groups>
</div>
