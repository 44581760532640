import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilePickerModule } from '@app/map/features/field-analysis/features/drone-image-import/drone-side-drawer/file-picker/file-picker.module';
import { DroneImageUploadDialogComponent } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-upload-dialog/drone-image-upload-dialog.component';
import { FileUploadModule } from '@app/shared/file-upload/file-upload.module';
import { AppPipesModule } from '@app/shared/pipes/pipes.module';
import { SharedModule } from '@app/shared/shared.module';
import { DroneSideDrawerComponent } from './drone-side-drawer/drone-side-drawer.component';
import { DroneImageInformationComponent } from './drone-side-drawer/drone-image-information/drone-image-information.component';
import { DroneImageInformationDialogComponent } from './drone-side-drawer/drone-image-information/information-dialog/information-dialog.component';
import { PriceInfoDialogComponent } from './drone-side-drawer/price/price-info-dialog/price-info-dialog.component';
import { DroneImageImportComponent } from './drone-image-import.component';
import { DroneImageUploadProgressDialogComponent } from './drone-image-upload-progress-dialog/drone-image-upload-progress-dialog.component';

@NgModule({
  imports: [CommonModule, SharedModule, FileUploadModule, FilePickerModule, AppPipesModule],
  declarations: [
    DroneImageImportComponent,
    DroneSideDrawerComponent,
    DroneImageUploadDialogComponent,
    DroneImageUploadProgressDialogComponent,
    PriceInfoDialogComponent,
    DroneImageInformationComponent,
    DroneImageInformationDialogComponent,
  ],
  exports: [DroneImageImportComponent, DroneImageUploadDialogComponent, DroneImageUploadProgressDialogComponent, DroneSideDrawerComponent],
})
export class DroneImageImportModule {}
