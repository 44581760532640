<img
  src="assets/images/logos/SEGES_Innovation_logo_RGB_crop.svg"
  class="seges-logo"
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  [ngClass]="{ hidden: menuMinimized$ | async }"
  (click)="goToPage('https://www.seges.dk/')"
/>
<span
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  [ngClass]="{ hidden: menuMinimized$ | async }"
  class="partners"
  (click)="openSponsorModal()"
>
  {{ 'footer.title' | translate }}
</span>

<app-icon
  [appResponsiveClass]="{ mobile: 'lt-Medium' }"
  [ngClass]="{ expand: menuMinimized$ | async }"
  icon="expand"
  class="expand-icon"
  (click)="toggleMenu()"
></app-icon>
