import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { scaleInOut } from '@app/core/animations/scale-ng-if.fn';
import { ClimateModels } from '@app/core/enums/climate-models.enum';
import { Month } from '@app/core/enums/month.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { GrassPrognosis } from '@app/core/repositories/grass-prognosis/grass-prognosis.interface';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { GrassPrognosisSettingsComponent } from '@app/shared/grass-prognosis/grass-prognosis-settings/grass-prognosis-settings.component';
import { GrassPrognosisOptions } from '@app/shared/grass-prognosis/grass-prognosis-settings/grass-prognosis-settings.interface';
import { GrassPrognosisService } from '@app/shared/grass-prognosis/grass-prognosis.service';
import isEqual from 'lodash-es/isEqual';
import { DateTime } from 'luxon';
import { filter, finalize } from 'rxjs/operators';
import { SideDrawerComponent } from '../side-drawer/side-drawer.component';
import { GrassPrognosisResultsChartComponent } from './grass-prognosis-results-chart/grass-prognosis-results-chart.component';

@Component({
  selector: 'app-grass-prognosis',
  templateUrl: './grass-prognosis.component.html',
  styleUrls: ['./grass-prognosis.component.scss'],
  animations: [scaleInOut],
})
export class GrassPrognosisComponent implements OnInit {
  @Input() public field: Field = {} as Field;
  @Input() public isGrazing?: boolean;

  @ViewChild(GrassPrognosisResultsChartComponent, { static: false })
  public grassPrognosisResultsChart?: GrassPrognosisResultsChartComponent;

  @ViewChild('sideDrawer', { static: false }) public sideDrawer?: SideDrawerComponent;

  public prognosis?: GrassPrognosis;
  public options?: GrassPrognosisOptions;
  public hasInsufficientData = false;
  public errorMessage = '';
  public maxDate!: DateTime;
  public minDate!: DateTime;
  private isPrognosisLoading = false;
  private isOptionsLoading = false;

  constructor(
    private grassPrognosisService: GrassPrognosisService,
    private sidedrawerOverlayService: SideDrawerOverlayService
  ) {}

  public ngOnInit() {
    this.maxDate = DateTime.fromObject({ year: this.field.harvestYear, month: Month.August, day: 31 });
    this.minDate = DateTime.fromObject({ year: this.field.harvestYear, month: Month.May, day: 1 });
    this.getOptions();
  }

  public onSettingsClick() {
    this.openSettings()
      .onClose.pipe(
        filter((options) => !!options),
        filter((options) => !isEqual(options, this.options))
      )
      .subscribe((options) => {
        this.options = options;
        this.getPrognosis();
      });
  }

  public onInfoClick(isGrazing: boolean) {
    this.grassPrognosisService.showInfoDialog(isGrazing);
  }

  public onDateChange(date: DateTime) {
    this.options!.testRunDate = date.setZone('utc');
    this.getPrognosis();
  }

  public onModelChange(checked: boolean) {
    if (checked) {
      this.options!.useStressDaysCorrection = true;
    } else {
      this.options!.useStressDaysCorrection = false;
    }

    this.getPrognosis();
    if (this.isGrazing) {
      this.grassPrognosisResultsChart?.onUpdateResults(this.options);
    }
  }

  public get isPrognosisVisible() {
    return !this.isPrognosisLoading && !this.errorMessage && this.prognosis;
  }

  public get isErrorMessageVisible() {
    return !this.isPrognosisLoading && this.errorMessage;
  }

  public get isLoading() {
    return this.isOptionsLoading || this.isPrognosisLoading;
  }

  private getOptions() {
    this.isOptionsLoading = true;
    let model: ClimateModels;

    model = this.isGrazing ? ClimateModels.Grazingprognosis : ClimateModels.Hayharvestprognosis;

    return this.grassPrognosisService
      .getOptions(model)
      .pipe(finalize(() => (this.isOptionsLoading = false)))
      .subscribe((options) => {
        this.options = options;
        this.getPrognosis();
      });
  }

  private getPrognosis() {
    this.errorMessage = '';
    this.isPrognosisLoading = true;
    let model: ClimateModels;

    model = this.isGrazing ? ClimateModels.Grazingprognosis : ClimateModels.Hayharvestprognosis;

    if (model === ClimateModels.Grazingprognosis) {
      this.options!.testRunDate = undefined;
    }

    return this.grassPrognosisService
      .getPrognosis(model, this.field, DateTime.now(), this.options!)
      .pipe(finalize(() => (this.isPrognosisLoading = false)))
      .subscribe({
        // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        next: (prognosis) => (this.prognosis = prognosis),
        error: (error: Error) => (this.errorMessage = error.message),
      });
  }

  private openSettings() {
    return this.sidedrawerOverlayService.openCustomSideDrawer<
      GrassPrognosisSettingsComponent,
      GrassPrognosisOptions,
      GrassPrognosisOptions
    >(GrassPrognosisSettingsComponent, {
      data: this.options,
      panelClass: 'grass-prognosis-settings-side-drawer',
      width: '500px',
      maxWidth: '100vw',
    });
  }
}
