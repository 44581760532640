<div class="wrapper">
  <div class="title">
    <b>{{ 'blight.blightRisk.boldTitle' | translate }}</b
    ><span>{{ 'blight.blightRisk.normalTitle' | translate }} {{ harvestYear$ | async }}</span>
    <app-spinner *ngIf="(loading$ | async) && (isPeriodValid$ | async)"></app-spinner>
  </div>
  <div class="content-wrapper" [ngClass]="{ loading: (loading$ | async) }" *ngIf="isPeriodValid$ | async; else invalidPeriod">
    <div class="risk-area">
      <div class="label-container"></div>
      <div class="identifier column">
        <div class="row identifier date">{{ 'blight.blightRisk.columns.date' | translate }}</div>
        <div class="row identifier wind-speed">{{ 'blight.blightRisk.columns.windSpeed' | translate }}</div>
        <div class="row identifier precipitation">{{ 'blight.blightRisk.columns.precipitation' | translate }}</div>
        <div class="row identifier blight-risk">
          <span>{{ 'blight.blightRisk.columns.potatoBlightLine1' | translate }}</span>
          <br />
          <span>{{ 'blight.blightRisk.columns.potatoBlightLine2' | translate }}</span>
        </div>
        <div class="row identifier spraying">{{ 'blight.blightRisk.columns.spraying' | translate }}</div>
      </div>
    </div>
    <div class="navigation-arrow" [ngClass]="{ disabled: (loading$ | async) || !(this.canNavigateBack$ | async) }">
      <app-icon (click)="navigateBack()" [icon]="'arrow_left'"></app-icon>
    </div>
    <div class="risk-container">
      <ng-container *ngTemplateOutlet="riskRange; context: { $implicit: pastRisks$ | async }"></ng-container>
      <ng-container *ngIf="prognosisRisks$ | async as prognosisRisks">
        <ng-container *ngTemplateOutlet="riskRange; context: { $implicit: prognosisRisks, prognosis: true }"></ng-container>
      </ng-container>
    </div>
    <div class="navigation-arrow" [ngClass]="{ disabled: (loading$ | async) || !(this.canNavigateForward$ | async) }">
      <app-icon (click)="navigateForward()" [icon]="'arrow_right'"></app-icon>
    </div>
  </div>
</div>

<ng-template #invalidPeriod>
  <div class="invalid-period-container">
    <h3>{{ 'blight.blightRisk.prognosisUnavailable' | translate }}</h3>
  </div>
</ng-template>

<ng-template #riskRange let-risks let-prognosis="prognosis">
  <div class="risk-area" *ngIf="risks?.length" [ngClass]="{ prognosis: prognosis }">
    <div class="label-container">
      <label *ngIf="prognosis" class="prognosis-label">{{ 'blight.blightRisk.prognosis' | translate }}</label>
    </div>
    <div class="column-container">
      <ng-container *ngFor="let blightRisk of risks">
        <div class="data column" [ngClass]="{ today: isToday(blightRisk.date) }">
          <div class="row data date">
            <div>{{ blightRisk.date | date: 'E' : undefined : lang }}</div>
            <div class="sub-line">{{ blightRisk.date | date: 'dd-MM' }}</div>
          </div>
          <div class="row data wind-speed">
            <div [ngStyle]="{ transform: blightRisk.windDirection | rotate: 90 }">
              <app-icon class="wind-direction-icon data" [icon]="'slim_arrow'"></app-icon>
            </div>
            <div class="sub-line">{{ blightRisk.windSpeed | number: '1.0-0' }} m/s</div>
          </div>
          <div class="row precipitation">{{ blightRisk.precipitation | number: '1.0-0' }} mm</div>
          <div class="row blight-risk">
            <app-tooltip tooltipText="{{ blightRisk.riskText | translate }}">
              <div class="color-square" [ngStyle]="{ 'background-color': blightRisk.riskHexColor }"></div>
            </app-tooltip>
          </div>
          <div class="row spraying">
            <div class="color-square" [style]="this.getProtectionStyle(blightRisk.remainingProtectionDays)"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
