import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { ProduceNormNutrients } from '@app/core/enums/produce-norm-nutrients.enum';
import { HttpClient } from '@app/core/http/http-client';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { ProduceNormsStorage } from '@app/core/interfaces/produce-norms-storage.interface';
import { YieldTypeGroupQualityParameter } from '@app/core/interfaces/quality-parameter.interface';
import { StorageService } from '@app/core/storage/storage.service';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ProduceNormsMessages {
  getError: string;
}

export interface IProduceNormsRepo {
  /**
   * Get produce norms from farm ID, harvest year, optionally filtered by filter keys.
   * @param farmId The farm ID to get norms for
   * @param year The harvest year to get norms for
   * @param filters Optional produce norms filters.
   */
  get(farmId: number, year: number, filters: Array<OperationTypeGroupEnum>): Observable<Array<ProduceNormsStorage>>;
  getQualityParametersForProducenorm(
    farmId: number,
    harvestYear: number,
    produceNormNumber: number
  ): Observable<YieldTypeGroupQualityParameter[]>;
  isStorageValid(storage: ProduceNormsStorage, filters: Array<OperationTypeGroupEnum>): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProduceNormsRepo implements IProduceNormsRepo {
  constructor(
    public http: HttpClient,
    public endpoints: EndpointsService,
    public storageService: StorageService
  ) {}

  public getProduceNorms(farmId: number, harvestYear: number, nutrients?: Array<ProduceNormNutrients>): Observable<ProduceNorm[]> {
    let url = `/farms/${farmId}/${harvestYear}/producenorms`;
    if (nutrients && nutrients.length > 0) {
      url += `?nutrients=${nutrients[0]}`;
      for (let i = 1; i < nutrients.length; i++) {
        url += `&nutrients=${nutrients[i]}`;
      }
    }

    return this.http.get<ProduceNorm[]>(this.endpoints.foApi + url).pipe(catchError((err) => []));
  }

  public getMostUsedProduceNorms(farmId: number, harvestYear: number, nutrients?: Array<ProduceNormNutrients>): Observable<ProduceNorm[]> {
    let url = `/farms/${farmId}/${harvestYear}/producenorms/mostused`;
    if (nutrients && nutrients.length > 0) {
      url += `?nutrients=${nutrients[0]}`;
      for (let i = 1; i < nutrients.length; i++) {
        url += `&nutrients=${nutrients[i]}`;
      }
    }

    return this.http.get<ProduceNorm[]>(this.endpoints.foApi + url).pipe(catchError((err) => []));
  }

  public get(farmId: number, year: number, filters: Array<OperationTypeGroupEnum>): Observable<ProduceNormsStorage[]> {
    const produceNormsStorage: Array<ProduceNormsStorage> =
      this.storageService.getFromStorage<Array<ProduceNormsStorage>>('producenorms_' + year.toString()) || [];

    const farmProduceNorms: ProduceNormsStorage | null = this.findProduceNormsForFarm(farmId, produceNormsStorage);

    if (this.hasProduceNormsForFarm(farmId, produceNormsStorage) && this.isStorageValid(farmProduceNorms, filters)) {
      return of(produceNormsStorage);
    } else {
      const ret = this.fetchProduceNorms(farmId, year, filters).pipe(
        map((newProduceNorms) => {
          const newProduceNormsStorage: ProduceNormsStorage = {
            produceNorms: newProduceNorms,
            date: DateTime.now(),
            farmId,
            harvestYear: year,
            filters,
          };

          produceNormsStorage.push(newProduceNormsStorage);
          return produceNormsStorage;
        })
      );
      return ret;
    }
  }

  public getQualityParametersForProducenorm(
    farmId: number,
    harvestYear: number,
    produceNormNumber: number
  ): Observable<YieldTypeGroupQualityParameter[]> {
    return this.http
      .get<
        YieldTypeGroupQualityParameter[]
      >(this.endpoints.foApi + `/farm/${farmId}/${harvestYear}/yieldtypegroupqualityparamnorms/${produceNormNumber}`)
      .pipe(
        catchError((err) => {
          // 2018-05-01: Lets handle the error instead of turning it off. Will stop failing sometime next week.
          // eslint-disable-next-line no-console
          console.error(err);

          return of([]);
        })
      );
  }
  public isStorageValid(storage: ProduceNormsStorage | null, filters: Array<OperationTypeGroupEnum>): boolean {
    if (!storage) {
      return false;
    }

    const today = DateTime.now();
    const date = storage.date;

    const difference = today.diff(date, 'days');
    const differenceAsObject = difference.toObject();
    return differenceAsObject.days! < 1 && this.compareFilters(storage.filters, filters);
  }

  public hasProduceNormsForFarm(farmId: number, storage: Array<ProduceNormsStorage>): boolean {
    for (const produceNormsStorage of storage) {
      if (produceNormsStorage.farmId === farmId) {
        return true;
      }
    }
    return false;
  }

  public findProduceNormsForFarm(farmId: number, storage: Array<ProduceNormsStorage>): ProduceNormsStorage | null {
    if (!storage) {
      return null;
    }

    return storage.filter((store: ProduceNormsStorage) => {
      return store.farmId === farmId;
    })[0];
  }

  public fetchProduceNorms(farmId: number, year: number, filters: Array<OperationTypeGroupEnum>): Observable<ProduceNorm[]> {
    let url = `/farms/${farmId}/${year}/producenorms`;
    if (filters && filters.length > 0) {
      url += `?operationTypeGroupIds=${filters[0]}`;
      for (let i = 1; i < filters.length; i++) {
        url += `&operationTypeGroupIds=${filters[i]}`;
      }
    }

    const options: any = { withCredentials: true };
    return this.http.get<ProduceNorm[]>(this.endpoints.foApi + url, options);
  }

  private compareFilters(primaryFilters: Array<OperationTypeGroupEnum>, secondaryFilters: Array<OperationTypeGroupEnum>): boolean {
    return primaryFilters.every((filter) => secondaryFilters.indexOf(filter) !== -1);
  }
}
