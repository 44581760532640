import { Injectable } from '@angular/core';
import { BasisLayer } from '@app/core/interfaces/basis-layer/basis-layer';
import { BasisLayerCategory } from '@app/core/interfaces/basis-layer/basis-layer-category';
import { BasisLayerCategoryDto } from '@app/core/interfaces/basis-layer/basis-layer-category-dto.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { BasisLayerCategoryRepoService } from '@app/core/repositories/basis-layer/basis-layer-category-repo.service';
import { BasisLayerRepoService } from '@app/core/repositories/basis-layer/basis-layer-repo.service';
import { LocalState } from '@app/helpers/local-state';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import Feature from 'ol/Feature';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BasisLayerShownComponentEnum } from './basis-layer-shown-component.enum';

@Injectable({
  providedIn: 'root',
})
export class BasisLayerStateService {
  private drawerWidthSubject = new ReplaySubject<string>(1);
  private selectedBasisLayerCategorySubject = new ReplaySubject<BasisLayerCategory | undefined>(1);
  private basisLayerCategoriesSubject = new ReplaySubject<BasisLayerCategory[]>(1);

  private selectedBasisLayerSubject = new BehaviorSubject<BasisLayer | undefined>(undefined);
  private basisLayersSubject = new ReplaySubject<BasisLayer[]>(1);
  private shownComponentState = new LocalState<{ shownComponent: BasisLayerShownComponentEnum }>({
    shownComponent: BasisLayerShownComponentEnum.categoryComponent,
  });
  private _basisLayerFeaturesSubject = new BehaviorSubject<Feature[]>([]);
  private _loading$ = new BehaviorSubject<boolean>(false);

  public get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  public set loading(loading: boolean) {
    this._loading$.next(loading);
  }

  public shownComponentState$ = this.shownComponentState.changes$;

  public get basisLayerFeatures$(): Observable<Feature[]> {
    return this._basisLayerFeaturesSubject.asObservable();
  }

  public set basisLayerFeatures(features: Feature[]) {
    this._basisLayerFeaturesSubject.next(features);
  }

  public get basisLayerCategories$(): Observable<BasisLayerCategory[]> {
    return this.basisLayerCategoriesSubject.asObservable();
  }

  public set basisLayerCategories(basisLayerCategories: BasisLayerCategory[]) {
    this.basisLayerCategoriesSubject.next(basisLayerCategories);
  }

  public get selectedBasisLayer$(): Observable<BasisLayer | undefined> {
    return this.selectedBasisLayerSubject.asObservable();
  }

  public set selectedBasisLayer(basisLayer: BasisLayer | undefined) {
    this.selectedBasisLayerSubject.next(basisLayer);
  }

  public get basisLayers$(): Observable<BasisLayer[]> {
    return this.basisLayersSubject.asObservable();
  }

  public set basisLayers(basisLayers: BasisLayer[]) {
    this.basisLayersSubject.next(basisLayers);
  }
  public get selectedBasisLayerCategory$(): Observable<BasisLayerCategory | undefined> {
    return this.selectedBasisLayerCategorySubject.asObservable();
  }

  public set selectedBasisLayerCategory(basisLayerCategory: BasisLayerCategory | undefined) {
    this.selectedBasisLayerCategorySubject.next(basisLayerCategory);
  }

  public get drawerWidth$(): Observable<string> {
    return this.drawerWidthSubject.asObservable();
  }

  public set drawerWidth(width: string) {
    this.drawerWidthSubject.next(width);
  }

  constructor(
    private basisLayerCategoryRepoService: BasisLayerCategoryRepoService,
    private basisLayerRepoService: BasisLayerRepoService,
    private farmStateService: FarmStateService,
    private notificationService: NotificationService,
    private languageService: LanguageService
  ) {}

  public addBasisLayerFeatures(features: Feature[]) {
    this._basisLayerFeaturesSubject.pipe(first()).subscribe((res) => this._basisLayerFeaturesSubject.next([...res, ...features]));
  }

  public setShownComponentState(component: BasisLayerShownComponentEnum) {
    this.shownComponentState.setState({ shownComponent: component });
  }

  public hydrateBasisLayersAndCategories(): void {
    this.farmStateService.selectedFarms$
      .pipe(
        filter((res) => !!res.length),
        tap(() => (this.loading = true)),
        first(),
        map((farms) => farms.map((farm) => farm.id)),
        switchMap((farmIds) =>
          this.getCategories(farmIds).pipe(
            switchMap((categories) => {
              this.basisLayerCategories = categories;
              return this.getBasisLayers(farmIds, categories);
            })
          )
        ),
        first()
      )
      .subscribe((basisLayers) => {
        this.basisLayers = basisLayers;
        this.loading = false;
      });
  }

  public createBasisLayer(basisLayers: BasisLayer[], farmId: number, harvestYear?: number): Observable<BasisLayer[] | null> {
    return this.basisLayerRepoService.post(basisLayers, farmId, harvestYear).pipe(
      withLatestFrom(this.basisLayers$, this.basisLayerCategories$),
      map(([createdBasisLayers, stateBasisLayers, categories]) => {
        const convertedBasisLayers =
          createdBasisLayers &&
          createdBasisLayers.map(
            (basisLayerDto) =>
              new BasisLayer(
                basisLayerDto,
                categories.find((c) => c.id === basisLayerDto.categoryId)
              )
          );

        if (convertedBasisLayers) this.basisLayers = [...convertedBasisLayers, ...stateBasisLayers];

        return convertedBasisLayers;
      })
    );
  }

  public validateGeometry(basisLayers: BasisLayer[], farmId: number, harvestYear?: number): Observable<void | null> {
    return this.basisLayerRepoService.validate(basisLayers, farmId, harvestYear);
  }

  public deleteBasisLayer(farmId: number, basisLayerId: number): Observable<void> {
    return this.farmStateService.selectedFarmIds$.pipe(
      first(),
      switchMap((farmIds) => this.basisLayerRepoService.delete([basisLayerId], farmId)),
      switchMap(() =>
        this.basisLayers$.pipe(
          first(),
          map((basisLayers) => {
            this.basisLayers = basisLayers.filter((b) => basisLayerId !== b.id);
          })
        )
      )
    );
  }

  public updateCategories(basisLayerCategoryDtos: BasisLayerCategoryDto[], farmId: number): Observable<BasisLayerCategoryDto[] | null> {
    return this.basisLayerCategoryRepoService
      .put(basisLayerCategoryDtos, farmId)
      .pipe(map((basisLayerCategories) => basisLayerCategories && basisLayerCategories.map((b) => new BasisLayerCategory(b))));
  }

  private getCategories(farmIds: number[]): Observable<BasisLayerCategory[]> {
    return this.basisLayerCategoryRepoService
      .get(farmIds)
      .pipe(map((categories) => categories.map((category) => new BasisLayerCategory(category))));
  }

  private getBasisLayers(farmIds: number[], categories: BasisLayerCategory[]) {
    return this.basisLayerRepoService.get(farmIds).pipe(
      map((basisLayers) =>
        basisLayers.map((basisLayer) => {
          const basisLayerCategory = categories.find((c) => c.id === basisLayer.categoryId);

          if (!basisLayerCategory) this.notificationService.showError(this.languageService.getText('messages.common.unacceptableError'));

          return new BasisLayer(basisLayer, basisLayerCategory);
        })
      )
    );
  }

  public deleteSelectedBasisLayer(farmId: number): Observable<void> {
    return this.selectedBasisLayer$.pipe(
      first(),
      switchMap((basisLayer) => this.deleteBasisLayer(farmId, basisLayer!.id!))
    );
  }
}
