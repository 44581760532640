import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDataConnectionsService } from '@app/core/data-connections/data-connections.service.interface';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { DataConnectionType } from '@app/core/interfaces/data-connection-type.interface';
import { DataConnectionsRepo } from '@app/core/repositories/data-connections/data-connections-repo.service';
import { JdOrganization } from '@app/settings/data-connections/select-jd-organization-dialog/select-jd-organization-dialog.component';
import { UserSelector } from '@app/state/selectors/user.selector.service';
import { DatamanagementStateService } from '@app/state/services/data-management/datamanagement-state.service';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { EndpointsService } from '../endpoints/endpoints.service';
import { IWindow } from '../interfaces/window.interface';
import { LogService } from '../log/log.service';
import { DataConnectionCredentialsRepo } from '../repositories/data-connection-credentials/data-connection-credentials-repo.service';
import { AgriRouterConnectionSettingsDTO } from '../repositories/data-connections/interfaces/agrirouter-connection-settings.interface';
import { JDConnectionSettingsDTO } from '../repositories/data-connections/interfaces/jd-connection-settings.interface';
import { WindowRefService } from '../window/window-ref.service';
import { DataConnectionTypes } from './data-connection-types.enum';

@Injectable({
  providedIn: 'root',
})
export class DataConnectionsService implements IDataConnectionsService {
  private dataConnectionsSettings$!: Observable<DataConnectionSettingDTO[]> | null;
  public window: IWindow;

  public refreshBehaviourSubject = new BehaviorSubject(false);

  constructor(
    private dataRepo: DataConnectionsRepo,
    private userSelector: UserSelector,
    private dataConnectionCredentialsRepo: DataConnectionCredentialsRepo,
    private windowRefService: WindowRefService,
    private endpointsService: EndpointsService,
    private datamanagementStateService: DatamanagementStateService,
    private logService: LogService
  ) {
    this.window = this.windowRefService.nativeWindow;
  }

  public get filteredDataConnectionTypes$() {
    return this.datamanagementStateService.dataConnectionTypes$.pipe(
      map((types) => {
        return types;
      })
    );
  }

  public goToJDLogin(jdConnectionUrl: string) {
    this.window.location.assign(jdConnectionUrl);
  }

  public goToAgriRouterLogin(agriRouterConnectionUrl: string) {
    this.window.location.assign(agriRouterConnectionUrl);
  }

  public getTypes(): Observable<Array<DataConnectionType>> {
    return this.dataRepo.getTypes();
  }

  public getSettings(): Observable<DataConnectionSettingDTO[]> {
    if (!this.dataConnectionsSettings$) {
      this.dataConnectionsSettings$ = this.dataRepo.getSettings().pipe(shareReplay(1));
    }
    return this.dataConnectionsSettings$;
  }

  public getSettingById(id: number): Observable<DataConnectionSettingDTO | null> {
    return this.dataRepo.getSettingById(id);
  }

  public clearDataConnectionsSettings() {
    this.dataConnectionsSettings$ = null;
  }

  public createSetting(connectionSetting: DataConnectionSettingDTO): Observable<DataConnectionSettingDTO | null> {
    switch (connectionSetting.connectionTypeId) {
      case DataConnectionTypes.Ranch:
      case DataConnectionTypes.Claas:
      case DataConnectionTypes.Trimble:
      case DataConnectionTypes.FieldSense:
        this.clearDataConnectionsSettings();
        return this.dataRepo.createUsernamePasswordSetting(connectionSetting);
      default:
        this.clearDataConnectionsSettings();
        return throwError(new Error('messages.dataConnection.postSettingError'));
    }
  }

  public updateSetting(connectionSetting: DataConnectionSettingDTO): Observable<DataConnectionSettingDTO | null> | undefined {
    switch (connectionSetting.connectionTypeId) {
      case DataConnectionTypes.Ranch:
      case DataConnectionTypes.FieldSense:
      case DataConnectionTypes.Claas:
      case DataConnectionTypes.Trimble:
        this.clearDataConnectionsSettings();
        return this.dataRepo.updateUsernamePasswordSetting(connectionSetting);
      default:
        this.clearDataConnectionsSettings();
        return;
    }
  }

  public deleteSetting(dataConnectionSetting: DataConnectionSettingDTO): Observable<number | null> {
    switch (dataConnectionSetting.connectionTypeId) {
      case DataConnectionTypes.AgriRouter:
        return this.dataRepo.revokeAgriRouterSetting(dataConnectionSetting.id).pipe(
          catchError((error: HttpErrorResponse) => {
            // We delete the AgriRouter connection in CropManger if we get a status code 400 from AgriRouter (bad reqest).
            // This is because the error may occur when the connection is already deleted in AgriRouter
            return this.dataRepo.deleteUsernamePasswordSetting(dataConnectionSetting.id);
          })
        );

      case DataConnectionTypes.JohnDeere:
        return this.dataRepo.revokeJohnDeereSettings(dataConnectionSetting.connectionId).pipe(
          first(),
          catchError(() => {
            this.logService.logError('Failed to revoke John Deere settings');
            return EMPTY;
          }),
          switchMap(() => this.dataRepo.deleteJdUser()),
          catchError(() => {
            this.logService.logError('Failed to delete JD user');
            return EMPTY;
          }),
          switchMap(() => this.dataRepo.deleteUsernamePasswordSetting(dataConnectionSetting.id)),
          catchError(() => {
            this.logService.logError('Failed to delete username and password setting');
            return EMPTY;
          })
        );
      case DataConnectionTypes.CNH:
        return this.dataRepo.deleteCnhCompany().pipe(
          first(),
          catchError(() => {
            this.logService.logError('Failed to delete CNH company');
            return EMPTY;
          }),
          switchMap(() => this.dataRepo.deleteUsernamePasswordSetting(dataConnectionSetting.id)),
          catchError(() => {
            this.logService.logError('Failed to delete username and password setting');
            return EMPTY;
          })
        );
      default:
        return this.dataRepo.deleteUsernamePasswordSetting(dataConnectionSetting.id);
    }
  }

  public createOrUpdateJDSettings(name: string) {
    this.clearDataConnectionsSettings();

    const trimmedCallBackUrl = this.trimCallBackUrl('callbackresult');
    return this.userSelector.currentUser$.pipe(
      first(),
      map((user) => {
        const jdSettings: JDConnectionSettingsDTO = {
          username: user!.username,
          name: name,
          callback: trimmedCallBackUrl,
        };
        return jdSettings;
      }),
      switchMap((jdUrlRequest) => this.dataRepo.createOrUpdateJDSettings(jdUrlRequest))
    );
  }

  public createOrUpdateAgriRouterSettings(name: string) {
    this.clearDataConnectionsSettings();

    const trimmedCallBackUrl = this.trimCallBackUrl('connectionName');
    return this.userSelector.currentUser$.pipe(
      first(),
      map((user) => {
        const agriRouterSettings: AgriRouterConnectionSettingsDTO = {
          username: user!.username,
          name: name,
          callback: `${trimmedCallBackUrl}&connectionName=${encodeURIComponent(name)}`,
        };
        return agriRouterSettings;
      }),
      switchMap((agriRouterUrlRequest) => this.dataRepo.createOrUpdateAgriRouterSettings(agriRouterUrlRequest))
    );
  }

  public getJdOrganizations(): Observable<JdOrganization[]> {
    return this.dataRepo.getJdOrganizations();
  }

  public saveJdOrganization(id: number): Observable<boolean> {
    return this.dataRepo.saveJdOrganization(id).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   *
   * @param setting the data connection setting to validate
   * @param type the associated connection type
   * @return boolean indicating whether or not connection works
   */
  public validateConnection(setting: DataConnectionSettingDTO, type: DataConnectionType): Observable<boolean> {
    return this.getSettingById(setting.id).pipe(
      switchMap((setting) => {
        if (setting) {
          switch (type.id) {
            case DataConnectionTypes.Ranch:
              return this.dataConnectionCredentialsRepo
                .validateRanchConnection(type.pingUrl, setting.connectionId, setting.connectionCode)
                .pipe(this.mapResponseToBoolean());
            case DataConnectionTypes.FieldSense:
              return this.dataConnectionCredentialsRepo
                .validateFieldSenseConnection(type.pingUrl, setting.connectionCode)
                .pipe(this.mapResponseToBoolean());
            case DataConnectionTypes.JohnDeere:
              return this.dataConnectionCredentialsRepo
                .validateConnection(`${this.endpointsService.dataExchangeApi}/deere/ping`, setting.connectionId, setting.connectionCode)
                .pipe(this.mapResponseToBoolean());
            case DataConnectionTypes.CNH:
              return this.dataConnectionCredentialsRepo
                .validateCnhConnection(`${this.endpointsService.bffCnhApi}/ping`)
                .pipe(this.mapResponseToBoolean());
            default:
              return this.dataConnectionCredentialsRepo
                .validateConnection(type.pingUrl, setting.connectionId, setting.connectionCode)
                .pipe(this.mapResponseToBoolean());
          }
        } else {
          return of(false);
        }
      })
    );
  }

  private mapResponseToBoolean =
    () =>
    <T>(source: Observable<T>) => {
      return source.pipe(
        catchError((err) => of(false)),
        map((res) => !!res)
      );
    };

  private getIndexWithTextInStringArray(searchText: string, arr: string[]) {
    return arr.findIndex((elem) => elem.includes(searchText));
  }

  private trimCallBackUrl(queryParamName: string) {
    const hrefSplitted = this.window.location.href.split('&');
    const index = this.getIndexWithTextInStringArray(queryParamName, hrefSplitted);
    if (index > -1) {
      hrefSplitted.splice(index);
    }
    const trimmedCallBackUrl = hrefSplitted.join('&');
    return trimmedCallBackUrl;
  }
}
