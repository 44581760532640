import { LocalState } from '@app/helpers/local-state';
import { take } from 'rxjs/operators';
import { GrowthRegulationFieldTableVM, GrowthRegulationTableField } from '../growth-regulation-field-table-vm.interface';
import { CompareHelper } from '@app/helpers/compare/compare-helper';

export class FieldTableState extends LocalState<GrowthRegulationFieldTableVM[]> {
  constructor() {
    super([]);
  }

  public checkOrUncheckField(checked: boolean, field: GrowthRegulationTableField, model: GrowthRegulationFieldTableVM) {
    this.checkField(checked, field, model);

    this.changes$.pipe(take(1)).subscribe((state) => {
      const newModel = state.find((m) => m.name === model.name);

      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      if (this.isAllCheckedOrUnchecked(newModel)) {
        this.checkOrUncheckAll(checked, model);
      }
    });
  }

  public checkOrUncheckAll(checked: boolean, model: GrowthRegulationFieldTableVM) {
    this.changes$.pipe(take(1)).subscribe((state) => {
      const newState = [
        ...state.filter((m) => m.name !== model.name),
        {
          ...model,
          isAllSelected: checked,
          table: {
            ...model.table,
            fields: model.table.fields.map((field) => ({
              ...field,
              selected: field.registered || checked,
            })),
          },
        },
      ].sort((a, b) => CompareHelper.compare(a.name, b.name));
      this.setState(newState);
    });
  }

  private checkField(checked: boolean, field: GrowthRegulationTableField, model: GrowthRegulationFieldTableVM) {
    this.changes$.pipe(take(1)).subscribe((state) => {
      const newState = [
        ...state.filter((m) => m.name !== model.name),
        {
          ...model,
          isAllSelected: false,
          table: {
            ...model.table,
            fields: [
              ...model.table.fields.filter((f) => f.fieldNumber !== field.fieldNumber),
              {
                ...field,
                selected: checked,
              },
            ].sort((a, b) => {
              if (a.fieldNumber < b.fieldNumber) {
                return -1;
              }
              if (a.fieldNumber > b.fieldNumber) {
                return 1;
              }
              return 0;
            }),
          },
        },
      ].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.setState(newState);
    });
  }

  private isAllCheckedOrUnchecked(model: GrowthRegulationFieldTableVM) {
    return model.table.fields.every((f) => f.selected) || model.table.fields.every((f) => !f.selected);
  }
}
