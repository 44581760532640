import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartService } from '@app/core/chart/chart.service';
import { ClimateModels } from '@app/core/enums/climate-models.enum';
import { Colors } from '@app/core/enums/colors.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { ResultsData } from '@app/core/repositories/grass-prognosis/grass-prognosis.interface';
import { CategoryAxis, RenderEvent, SeriesMarkers } from '@progress/kendo-angular-charts';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GrassPrognosisOptions } from '../grass-prognosis-settings/grass-prognosis-settings.interface';
import { GrassPrognosisService } from '../grass-prognosis.service';

@Component({
  selector: 'app-grass-prognosis-results-chart',
  templateUrl: './grass-prognosis-results-chart.component.html',
  styleUrls: ['./grass-prognosis-results-chart.component.scss'],
})
export class GrassPrognosisResultsChartComponent implements OnInit, OnDestroy {
  @Input() public field: Field = {} as Field;
  @Input() public options?: GrassPrognosisOptions;

  public errorMessage = '';
  public prognosis: ResultsData | null = null;
  public categoryAxis: CategoryAxis = {
    baseUnit: 'days',
    type: 'date',
    majorGridLines: {
      visible: false,
    },
    min: 0,
    labels: {
      dateFormats: {
        days: LanguageConstants.getKendoDateFormat(this.languageService.currentLanguage.shortKey).L,
      },
      position: 'start',
    },
  };

  public prognosisMarkers: SeriesMarkers = {
    visible: false,
  };

  public prognosisAttributeValue = {
    colors: {
      toolTipBackgroundColor: Colors.Blue2,
      forecastAreaColor: Colors.Gray5,
      forecastLineColor: Colors.Blue2,
      observationAreaColor: Colors.Green4,
      observationLineColor: Colors.Blue2,
    },
    opacities: {
      notTansparent: 1,
    },
    lineWidths: {
      forecastLineWidth: 3,
      observationLineWidth: 3,
    },
  };

  private subscriptions: Subscription[] = [];
  private isPrognosisLoading = false;
  private isOptionsLoading = false;

  constructor(
    private languageService: LanguageService,
    private chartService: ChartService,
    private grassPrognosisService: GrassPrognosisService
  ) {}

  public ngOnInit(): void {
    this.getOptions();
  }

  public onUpdateResults(options: GrassPrognosisOptions | undefined): void {
    this.options = options;
    this.getAndSetPrognosis();
  }

  public get isPrognosisVisible() {
    return !this.isPrognosisLoading && !this.errorMessage && this.prognosis;
  }

  public get isErrorMessageVisible() {
    return !this.isPrognosisLoading && this.errorMessage;
  }

  public get isLoading() {
    return this.isOptionsLoading || this.isPrognosisLoading;
  }

  private getLastObservedDate() {
    if (this.prognosis) {
      const lastObservedDate = this.prognosis.fenData.observed[this.prognosis.fenData.observed.length - 1]?.date;
      // return as zero based
      return lastObservedDate;
    }

    return null;
  }

  private getOptions() {
    this.isOptionsLoading = true;

    return this.grassPrognosisService
      .getOptions(ClimateModels.Grazingprognosis)
      .pipe(finalize(() => (this.isOptionsLoading = false)))
      .subscribe((options) => {
        this.options = options;
        this.getAndSetPrognosis();
      });
  }

  private getAndSetPrognosis() {
    this.isPrognosisLoading = true;
    if (this.options) this.options.testRunDate = undefined;
    this.subscriptions.push(
      this.grassPrognosisService

        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        .getPrognosisResults(ClimateModels.Grazingprognosis, this.field, DateTime.now(), this.options)
        .pipe(finalize(() => (this.isPrognosisLoading = false)))
        .subscribe({
          next: (resultsData) => {
            this.prognosis = resultsData;
            // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
            // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
            this.prognosis.fenData.forecast.push(...this.prognosis.fenData.observed);
            // @ts-ignore - TS2531 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
            if (this.categoryAxis.labels) this.categoryAxis.labels.step = Math.round(this.prognosis.fenData.forecast.length / 5);
          },
          error: (error: Error) => (this.errorMessage = error.message),
        })
    );
  }

  public onRender(e: RenderEvent) {
    // only show if in current year
    if (DateTime.now().year === this.field.harvestYear) {
      const chartElement = e.sender;
      const valueAxis = chartElement.findAxisByName('valueAxis');
      const categoryAxis = chartElement.findAxisByName('resultsCategoryAxis');

      let lineWidth = 3;
      let yOffset = 5;

      const lastObservedDate = this.getLastObservedDate();

      if (!lastObservedDate) return;

      const verticalLineGroup = this.chartService.creatVerticalLineWithLabel(
        new Date(lastObservedDate),
        valueAxis,
        categoryAxis,
        Colors.Orange1,
        lineWidth,
        DateTime.fromISO(lastObservedDate).toFormat(LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey)['L']),
        yOffset
      );

      // Draw on the Chart surface to overlay the series
      chartElement.surface.draw(verticalLineGroup);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
