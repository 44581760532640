import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * With searching and pagination
 * In the component:
 * Imports:
 * import { ElementRef, ViewChild } from '@angular/core';
 * // Angular material
 * import { MdPaginator } from '@angular/material';
 * // Angular material helper
 * import { GridDataSource } from '@app/helpers'
 *
 * add these properties. Example:
 *      @ViewChild('filter') filter: ElementRef;
 *      @ViewChild(MdPaginator) paginator: MdPaginator;
 *      filterColumnNames = ['number', 'directorateCropNormName', 'varietyName']        // or alternatively
 *      filterColumnNames = ['dataConnectionSetting.name', 'dataConnectionType.name'];  // if search in objects
 *      dataSource: GridDataSource<T>;
 *      private dataChange: BehaviorSubject<T[]>;
 * and initialize the datasource with filterColumnNames as third parameter. Example:
 *      this.dataSource = new GridDataSource(this.dataChange, this.paginator, this.filterColumnNames);
 * and finally in the search method. Example width app-search-field component
 *      handleSearchTermUpdated(searchFor: string) {
 *          if (!this.dataSource) { return; }
 *          this.dataSource.filter = searchFor;
 *      }
 *
 * Without search. As described above with this change
 * Initialize the datasource without filterColumnNames as third parameter. Example:
 *       this.dataSource = new GridDataSource(this.dataChange, this.paginator);
 *
 */
export class GridDataSource<T> extends DataSource<T> {
  public _filterChange = new BehaviorSubject('');
  get filter(): string {
    return this._filterChange.value;
  }
  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  constructor(
    private gridData: BehaviorSubject<T[]>,
    private _paginator: MatPaginator,
    private filterColumnNames: string[] = []
  ) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  public connect(): Observable<T[]> {
    const displayDataChanges = [this.gridData, this._paginator.page, this._filterChange];

    return merge(...displayDataChanges).pipe(
      map(() => {
        let data: T[];
        // no search
        if (this.filterColumnNames.length === 0) {
          data = this.gridData.value.slice();
        } else {
          // search
          data = this.gridData.value.slice().filter((item: T) => {
            let searchStr = '';
            this.filterColumnNames.forEach((name) => {
              const value = name.split('.').reduce((o, i) => (o as any)[i], item);

              if (value) {
                searchStr += (value as string).toLowerCase();
              }
            });
            return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
          });
        }

        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  public disconnect() {}
}
