import { Injectable } from '@angular/core';

import { DialogService } from '@app/shared/dialog/dialog.service';
import { map } from 'rxjs/operators';
import { DirtyCheckService } from '../dirty-check/dirty-check.service';

@Injectable({
  providedIn: 'root',
})
export class DirtyGuard {
  constructor(
    private dialogService: DialogService,
    private dirtyCeckService: DirtyCheckService
  ) {}

  public async canDeactivate(): Promise<boolean> {
    if (this.dirtyCeckService.isAppDirty) {
      // This has to return boolean | UrlTree, but it does not
      const resultingPromise = await this.dialogService
        .openDirtyCheckDialog()
        .pipe(map((action) => action!.isConfirmed))
        .toPromise();

      return resultingPromise!;
    } else {
      return Promise.resolve(true);
    }
  }
}
