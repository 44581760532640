/**
 * Function removes the item which fullfills the given predicate from the array
 * @param array The array to update
 * @param predicate function to determine if array item is the one to update
 */
export function removeObjectArrayItem<ArrayType extends object>(array: ArrayType[], predicate: (item: ArrayType) => boolean): ArrayType[] {
  const arrayObj = array as object[];

  return arrayObj
    .map((item) => (predicate(item as ArrayType) ? undefined : { ...item }))
    .filter((item) => item !== undefined) as ArrayType[];
}
