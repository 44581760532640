import { DateTime } from 'luxon';

export class DateHelper {
  /**
   * Compares the day, month and year of @date to today's date.
   * If all matches true is returned, else false is returned.
   * If @date is not truthy, false is returned.
   * @param date
   */
  public static isToday(date?: DateTime | null) {
    if (!date) {
      return false;
    }
    const today = this.today;
    return date.day === today.day && date.month === today.month && date.year === today.year;
  }

  public static getShortDateString(date: DateTime) {
    return `${date.day}-${date.month}/${date.year}`;
  }

  /**
   * Can be mocked out for testing purposes
   */
  public static get today() {
    return DateTime.now().startOf('day');
  }

  public static get todayAsLuxon() {
    return DateTime.now().startOf('day');
  }

  public static get yesterdayAsLuxon() {
    return DateTime.now().minus({ days: 1 }).startOf('day');
  }
}
