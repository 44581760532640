import { Injectable } from '@angular/core';
import { FeatureService } from '@app/core/feature/feature.service';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { InsuranceOrder } from '@app/core/interfaces/insurance-order.interface';
import { MapConstants } from '@app/core/map/map.constants';
import { ArrayHelper } from '@app/helpers/arrays/array-helpers';
import { LocalState } from '@app/helpers/local-state';
import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GrowthRegulationCropsEnum } from './growth-regulation-crops-enum.enum';
import { GrowthRegulationFieldTableVM, GrowthRegulationTableField } from './growth-regulation-field-table-vm.interface';
import { GrowthRegulationVM } from './growth-regulation-vm.class';
import { FieldProperties } from './interfaces/field-properties.interface';
import { GrowthRegulation } from './interfaces/growth-regulation.interface';
import { InsuranceRequestDTO } from './interfaces/insurance-request.interface';
import { GrowthRegulationRepository } from './repository/growth-regulation.repository';
import { LayerId } from '@app/new-map/services/layer/layer.store';

@Injectable()
export class GrowthRegulationService {
  private growthRegulationState = new LocalState<GrowthRegulationVM>(new GrowthRegulationVM());
  public growthRegulationVm$ = this.growthRegulationState.changes$;
  private _growthRegulationFieldTableVM!: GrowthRegulationFieldTableVM[];

  public get growthRegulationFieldTableVM() {
    return this._growthRegulationFieldTableVM;
  }

  constructor(
    private growthRegRepo: GrowthRegulationRepository,
    private featureService: FeatureService
  ) {}

  public setGrowthRegulationState(growthRegulationVm: GrowthRegulationVM) {
    this.growthRegulationState.setState(growthRegulationVm);
  }

  public setGrowthRegulationFieldTableVM(growthRegulationFieldTableVM: GrowthRegulationFieldTableVM[]) {
    this._growthRegulationFieldTableVM = growthRegulationFieldTableVM;
  }

  public getGrowthRegulations(farms: Farm[], fieldFeatures: FieldFeatures | null, harvestYear?: number) {
    return forkJoin(
      this.growthRegRepo
        .getGrowthRegulations(
          farms.map((farm) => farm.id),
          GrowthRegulationCropsEnum.WinterWheat,
          harvestYear
        )
        .pipe(map((growthRegulations) => growthRegulations.map((dto) => new GrowthRegulation(dto)))),
      this.growthRegRepo
        .getGrowthRegulations(
          farms.map((farm) => farm.id),
          GrowthRegulationCropsEnum.WinterRye,
          harvestYear
        )
        .pipe(map((growthRegulations) => growthRegulations.map((dto) => new GrowthRegulation(dto))))
    ).pipe(
      map(([winterWheat, winterRye]) => {
        return {
          fields: fieldFeatures!.fieldFeatures.map((ff) => ff.field).filter(ArrayHelper.notEmpty),
          growthRegulations: [...winterWheat, ...winterRye],
          winterWheat,
          winterRye,
        };
      })
    );
  }

  public getAndSetFieldFeatures(fields: Field[], growthRegulations: GrowthRegulation[], mapFeature: LayerId) {
    const wktFormat = new WKT();
    return this.featureService.getFieldsLayers(fields).map((fieldLayer) => {
      const feat = wktFormat.readFeature(this.getGeometryByFeatureId(fieldLayer.field, fieldLayer.featureId), {
        dataProjection: MapConstants.dataProjection,
        featureProjection: MapConstants.mapProjection,
      });
      const growthReg = growthRegulations.find((gr) => gr.featureId === fieldLayer.featureId || gr.fieldNumber === fieldLayer.fieldNumber);
      this.setFieldProperties(
        {
          layerId: mapFeature,
          text: fieldLayer.text,
          growthRegulation: growthReg ? this.enrichGrowthRegulation(growthReg, fieldLayer) : null,
          field: fieldLayer.field,
        },
        feat
      );
      return feat;
    });
  }

  public getInsuredFields(farms: Farm[], harvestYear: number, fieldIds: number[]) {
    if (!fieldIds.length) {
      return of([]);
    }
    return this.growthRegRepo.getInsuredFields(
      farms.map((farm) => farm.id),
      harvestYear,
      fieldIds
    );
  }

  public requestInsurance(insuranceOrder: InsuranceOrder, fieldTableModels: GrowthRegulationTableField[], harvestYear: number) {
    const requestBody: InsuranceRequestDTO = {
      farmName: insuranceOrder.farmName,
      farmAddress: insuranceOrder.farmAddress,
      cvr: insuranceOrder.cvrNumber,
      email: insuranceOrder.email,
      phoneNumber: insuranceOrder.telephoneNo,
      fields: fieldTableModels,
    };

    return this.growthRegRepo.requestInsurance(harvestYear, requestBody);
  }

  private enrichGrowthRegulation(growthRegulation: GrowthRegulation, fieldLayer: FieldLayerItem) {
    growthRegulation.area = this.getAreaByFeatureId(fieldLayer.field, fieldLayer.featureId);
    growthRegulation.headerText = `${fieldLayer.fieldNumber}${fieldLayer.fieldBlockSubDivision} ${fieldLayer.field?.name || ''}`;
    return growthRegulation;
  }

  private getAreaByFeatureId(field: Field | null, featureId: number | null) {
    if (field && field.geometry) {
      return field.area;
    }

    const { fieldBlocks } = field!;
    return fieldBlocks!.find((bl) => bl.featureId === featureId)!.area;
  }

  private getGeometryByFeatureId(field: Field | null, featureId: number | null) {
    if (field && field.geometry) {
      return field.geometry;
    }

    const { fieldBlocks } = field!;
    return fieldBlocks!.find((bl) => bl.featureId === featureId)!.geometry;
  }

  private setFieldProperties(props: FieldProperties, feature: Feature) {
    feature.setProperties(props);
  }
}
