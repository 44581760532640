import { InjectionToken } from '@angular/core';
import { ListOptionComponent } from './list-option.component';

/**
 * Injection token that can be used to reference instances of an `ListOption`. It serves
 * as alternative token to an actual implementation which could result in undesired
 * retention of the class or circular references breaking runtime execution.
 * @docs-private
 */
export const LIST_OPTION = new InjectionToken<ListOptionComponent>('ListOptionComponent');
