export enum PrognosisShownComponent {
  PrognosisPicker = 0,
  GrowthRegulation,
  CornHarvest,
  GrowthRegulationInsurance,
  GrowthRegulationInsuranceCheckout,
  YieldPrognosis,
  YieldPrognosisDetails,
  PotatoBlight,
}
