import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { OperationTypes } from '@app/core/enums/operation-types.enum';
import { SimpleOperationLine } from '@app/core/interfaces/simple-operation-line.interface';
import { SimpleOperation } from '@app/core/interfaces/simple-operation.interface';
import { SimpleQualityParameter } from '@app/core/interfaces/simple-quality-parameter.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { NumberValidators } from '@app/helpers/validators/forms/number-validators';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { negate } from '@app/shared/operators/negate';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface OperationForm {
  operationTypeGroupId: FormControl<OperationTypeGroupEnum | null>;
  operationTypeGroup: FormGroup<OperationTypeForm>;
  operationLines: FormArray<FormGroup<OperationLineForm>>;
}

export interface OperationTypeForm {
  id: FormControl<number | null | undefined>;
  name: FormControl<string | null | undefined>;
}

export interface OperationLineForm {
  id: FormControl<number | null>;
  operationTypeId: FormControl<OperationTypes | null | undefined>;
  produceNormNumber: FormControl<string | null>;
  quantity: FormControl<number | null>;
  totalQuantity: FormControl<number | null>;
  qualityParameters: FormControl<SimpleQualityParameter[] | null>;
}

export interface TaskForm {
  id: FormControl<number | null>;
  harvestYear: FormControl<number | null>;
  farmId: FormControl<number | null>;
  cropId: FormControl<number | null>;
  fieldId: FormControl<number | null>;
  produceNormNames: FormControl<string | null>;
  operationTypeGroupNames: FormControl<string | null>;
  area: FormControl<number | null>;
  date: FormControl<DateTime | null>;
  registered: FormControl<boolean | null>;
  comment: FormControl<string | null>;
  operations: FormArray<FormGroup<OperationForm>>;
}

@Injectable({
  providedIn: 'root',
})
export class TaskFormService {
  private _writeDisabled = false;
  private writeDisabled$ = this.accessControlService
    .hasAccessTo('field_plan_cultivation_journal_write')
    .pipe(
      negate(),
      tap((x) => (this._writeDisabled = x))
    )
    .subscribe();

  constructor(private accessControlService: AccessControlService) {}

  ngOnDestroy() {
    this.writeDisabled$.unsubscribe();
  }

  public createTaskForm(allowedArea$: Observable<number | null>, isFarmLocked: boolean): FormGroup<TaskForm> {
    const disableInput = this.disableInput(isFarmLocked);

    return new FormGroup<TaskForm>({
      id: new FormControl(null),
      harvestYear: new FormControl(null),
      farmId: new FormControl(null),
      cropId: new FormControl(null),
      fieldId: new FormControl(null),
      produceNormNames: new FormControl(null),
      operationTypeGroupNames: new FormControl(null),
      area: new FormControl(
        { value: null, disabled: disableInput },
        {
          validators: [Validators.required, Validators.min(0), NumberValidators.numeric],
          asyncValidators: [this.taskAreaValidator(allowedArea$)],
        }
      ),
      date: new FormControl({ value: null, disabled: disableInput }, { validators: [Validators.required] }),
      registered: new FormControl({ value: false, disabled: disableInput }),
      comment: new FormControl({ value: '', disabled: disableInput }),
      operations: new FormArray<FormGroup<OperationForm>>([], { validators: [Validators.required] }),
    });
  }

  public updateTaskForm(form: FormGroup<TaskForm>, task: SimpleTask, isFarmLocked: boolean) {
    form.patchValue(task);

    task.operations.forEach((operation) => {
      const disableInput = this.disableInput(isFarmLocked, operation.operationTypeGroup);
      form.controls.operations.push(this.pushOperationForm(operation, disableInput));
    });
  }

  public createOperationForm(operationTypeGroup: OperationTypeGroup, isFarmLocked: boolean): FormGroup<OperationForm> {
    const disableInput = this.disableInput(isFarmLocked, operationTypeGroup);

    return new FormGroup<OperationForm>({
      operationTypeGroupId: new FormControl<number>(operationTypeGroup.id),
      operationTypeGroup: new FormGroup<OperationTypeForm>({
        id: new FormControl(operationTypeGroup.id),
        name: new FormControl(operationTypeGroup.name),
      }),
      operationLines: new FormArray<FormGroup<OperationLineForm>>([this.createOperationLineForm(disableInput)]),
    });
  }

  public createOperationLineForm(isFarmLocked: boolean): FormGroup<OperationLineForm> {
    return new FormGroup({
      id: new FormControl<number | null>(null),
      operationTypeId: new FormControl<number | null | undefined>({ value: null, disabled: isFarmLocked }),
      produceNormNumber: new FormControl<string | null>({ value: null, disabled: isFarmLocked }, { validators: [Validators.required] }),
      quantity: new FormControl<number | null>(
        { value: null, disabled: true },
        { validators: [Validators.required, NumberValidators.numeric, NumberValidators.greaterThan(0)] }
      ),
      totalQuantity: new FormControl<number | null>(
        { value: null, disabled: true },
        { validators: [Validators.required, NumberValidators.numeric, NumberValidators.greaterThan(0)] }
      ),
      qualityParameters: new FormControl<SimpleQualityParameter[] | null>({ value: null, disabled: isFarmLocked }),
    });
  }

  public isTaskInfoValid(taskForm: FormGroup<TaskForm>) {
    const areaCtrl = taskForm.controls.area;
    const dateCtrl = taskForm.controls.date;
    const commentCtrl = taskForm.controls.comment;

    return !areaCtrl.errors && !dateCtrl.errors && !commentCtrl.errors;
  }

  public isOperationsValid(taskForm: FormGroup<TaskForm>) {
    const operationsFormArray = taskForm.controls.operations;

    return !operationsFormArray.errors;
  }

  public isProductsValid(taskForm: FormGroup<TaskForm>) {
    return taskForm.value.operations?.every((operation) => {
      const products = operation.operationLines?.map((ol) => ol.produceNormNumber);

      const uniqueProducts = new Set(products ?? []);

      if (uniqueProducts.size !== (products ?? []).length) {
        return false;
      }
      return true;
    });
  }
  private pushOperationForm(operation: SimpleOperation, disableInput: boolean) {
    return new FormGroup<OperationForm>({
      operationTypeGroupId: new FormControl(operation.operationTypeGroupId),
      operationLines: new FormArray<FormGroup<OperationLineForm>>(
        operation.operationLines.map((operationLine) => this.pushOperationLineForm(operationLine, disableInput))
      ),
      operationTypeGroup: new FormGroup<OperationTypeForm>({
        id: new FormControl(operation.operationTypeGroup?.id),
        name: new FormControl(operation.operationTypeGroup?.name),
      }),
    });
  }

  private pushOperationLineForm(operationLine: SimpleOperationLine, disabled: boolean) {
    return new FormGroup<OperationLineForm>({
      id: new FormControl(operationLine.id),

      operationTypeId: new FormControl({ value: operationLine.operationTypeId, disabled: disabled || this._writeDisabled }),

      produceNormNumber: new FormControl(
        { value: operationLine.produceNormNumber, disabled: disabled || this._writeDisabled },
        { validators: [Validators.required] }
      ),

      quantity: new FormControl(
        { value: operationLine.quantity ?? 0, disabled: disabled || this._writeDisabled },
        { validators: [Validators.required, NumberValidators.numeric, NumberValidators.greaterThan(0)] }
      ),

      totalQuantity: new FormControl(
        { value: operationLine.totalQuantity ?? 0, disabled: disabled || this._writeDisabled },
        { validators: [Validators.required, NumberValidators.numeric, NumberValidators.greaterThan(0)] }
      ),

      qualityParameters: new FormControl({ value: null, disabled: disabled || this._writeDisabled }),
    });
  }

  public taskAreaValidator(allowedArea$: Observable<number | null>): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const area = Number(control.value);

      return allowedArea$.pipe(
        map((allowedArea) => !!allowedArea && area > allowedArea),
        map((isTooLarge) => (isTooLarge ? { areaTooLarge: true } : null))
      );
    };
  }

  private disableInput(isFarmLocked: boolean, operationTypeGroup?: OperationTypeGroup) {
    if (!operationTypeGroup) return isFarmLocked || this._writeDisabled;

    return isFarmLocked || (operationTypeGroup.id !== OperationTypeGroupEnum.Harvest && this._writeDisabled);
  }
}
