import { RegionId } from '@app/core/enums/region-id.enum';
import times from 'lodash-es/times';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style, { GeometryFunction } from 'ol/style/Style';
import Text from 'ol/style/Text';

export const BenchmarkStyles = {
  getRegionStyle: (feature: Feature): Style[] => {
    return [
      new Style({
        text: new Text({
          text: feature.get('text'),
          scale: 2.3,
          stroke: new Stroke({
            color: [255, 255, 255, 0.5],
            width: 1,
          }),
          // offset the 'Østjylland-Fyn' label region to avoid it covering part of the 'Vestjylland' label
          offsetY: feature.getId() === RegionId.EAST_JUTLAND_FUEN ? -50 : 0,
          offsetX: feature.getId() === RegionId.EAST_JUTLAND_FUEN ? 25 : 0,
        }),

        geometry: function (f: Feature) {
          // expecting a MultiPolygon here
          const interiorPoints = (f.getGeometry() as MultiPolygon).getInteriorPoints();
          return interiorPoints.getPoint(0);
        } as GeometryFunction,
      }),
      new Style({
        stroke: new Stroke({
          color: '#fff',
          width: 2,
        }),
        fill: new Fill({
          color: feature.get('fill'),
        }),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      }),
    ];
  },

  getSelectedRegionStyle: (feature: Feature): Style[] => {
    const styleArray: Style[] = times(5, Number).map(
      (i) =>
        new Style({
          stroke: new Stroke({
            color: [0, 0, 0, 0.08 * i],
            width: 17 - i * 2.5,
          }),
        })
    );

    styleArray.push(
      new Style({
        text: new Text({
          text: feature.get('text'),
          scale: 2.7,
          stroke: new Stroke({
            color: [255, 255, 255, 0.5],
            width: 1,
          }),
          // offset the 'Østjylland-Fyn' label region to avoid it covering part of the 'Vestjylland' label
          offsetY: feature.getId() === RegionId.EAST_JUTLAND_FUEN ? -50 : 0,
          offsetX: feature.getId() === RegionId.EAST_JUTLAND_FUEN ? 25 : 0,
        }),

        geometry: function (f: Feature) {
          // expecting a MultiPolygon here
          const interiorPoints = (f.getGeometry() as MultiPolygon).getInteriorPoints();
          return interiorPoints.getPoint(0);
        } as GeometryFunction,
      })
    );
    styleArray.push(
      new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 1],
          width: 2,
        }),
        fill: new Fill({
          color: feature.get('fill'),
        }),
        geometry: function (featureGeom) {
          if (featureGeom.get('features')) {
            return featureGeom.get('features')[0].getGeometry();
          } else {
            return featureGeom.getGeometry();
          }
        },
      })
    );

    return styleArray;
  },
};
