import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FileExtensionHelper } from '@app/helpers/file/file-extension-helper';

export function fileExtensionValidator(allowedFileExtensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control?.value?.length) {
      return null;
    }
    const files = control.value as File[];
    return FileExtensionHelper.validateFileExtensions(files, allowedFileExtensions) ? null : { invalidFileExtensions: { value: true } };
  };
}
