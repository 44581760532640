export interface DroneImageInformation {
  featureId: number;
  fieldNumber: string;
  fieldName: string;
  pictures: DroneImageSet[];
}

export interface DroneImageSet {
  errorCode: number;
  uploadDateTime: Date;
  projectId: number;
  statusId: DroneImageStatus;
  name: string;
  imageDate: Date;
  maxThreshold: number;
}

export enum DroneImageStatus {
  Failed = 0,
  Created = 1,
  Processing = 2,
  Processed = 3,
}
