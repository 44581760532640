import trim from 'lodash-es/trim';

export class StringHelpers {
  public static isNullOrEmpty(str: string | undefined | null) {
    return !str || trim(str) === '';
  }

  public static removeWhitespaces(str: string) {
    return str?.replace(/\s/g, '');
  }
}
