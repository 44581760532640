import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-remember-septoria-dialog',
  templateUrl: './remember-septoria-dialog.component.html',
  styleUrls: ['./remember-septoria-dialog.component.scss'],
})
export class RememberSeptoriaDialogComponent {
  constructor(public dialogRef: MatDialogRef<RememberSeptoriaDialogComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
