import { ProduceNormUnits } from '@app/core/enums/produce-norm-units.enum';
import { Unit } from '@app/core/interfaces/unit.type';
import { ExtractGuard } from '@app/core/types/extract';

/**
 * Converts a given value from the given unit to tons.
 * Currently supports converting Kg and hKg.
 * @param value {number} The value to convert.
 * @param unit {Unit} The unit to convert from.
 */
export function convertToTons(value: number | undefined, unit: Unit | undefined) {
  if (!value) {
    return 0;
  }

  switch (unit) {
    case ProduceNormUnits.KG:
      return value / 1000;
    case ProduceNormUnits.HKG:
      return value / 100;
    default:
      return value;
  }
}

/**
 * Converts a given value from the given unit to hkg.
 * Currently supports converting Kg and tons.
 * @param value {number} The value to convert.
 * @param unit {Unit} The unit to convert from.
 */
export function convertToHkg(value: number | undefined, unit: Unit | undefined) {
  if (!value) {
    return 0;
  }

  switch (unit) {
    case ProduceNormUnits.KG:
      return value / 100;
    case ProduceNormUnits.T:
      return value * 10;
    default:
      return value;
  }
}

/**
 * Converts a given value from the given unit to kg.
 * Currently supports converting hKg and tons.
 * @param value {number} The value to convert.
 * @param unit {Unit} The unit to convert from.
 */
export function convertToKg(value: number | undefined, unit: Unit | undefined) {
  if (!value) {
    return 0;
  }

  switch (unit) {
    case ProduceNormUnits.HKG:
      return value * 100;
    case ProduceNormUnits.T:
      return value * 1000;
    default:
      return value;
  }
}

const MASS_RATIO = new Map<Mass, number>([
  ['g', 1],
  ['kg', 1_000],
  ['hkg', 100_000],
  ['t', 1_000_000],
  ['ton', 1_000_000],
]);

type Mass = ExtractGuard<Unit, 'g' | 'hkg' | 'kg' | 't' | 'ton'>;

/**
 * Convert a value from unit to another (mass) unit.
 * @param value The value to convert.
 * @param from The unit to convert from.
 * @param to The mass unit to convert to.
 */
export function convertUnitToMass(value: number | undefined | null, from: Unit | undefined | null, to: Mass): number | undefined {
  if (!value || !from) return;

  if (from !== 'g' && from !== 'hkg' && from !== 'kg' && from !== 't' && from !== 'ton') {
    return;
  }

  const ratio = MASS_RATIO.get(from)! / MASS_RATIO.get(to)!;

  return value * ratio;
}
