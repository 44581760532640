import { SoilSampleShortName } from '@app/map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-short-name.enum';
import { SoilSampleLegendEntryDTO } from './soil-sample-legend.dto';

export class SoilSampleLegend {
  public entries: SoilSampleLegendEntryDTO[] = [];
  public shortName: SoilSampleShortName;

  constructor(shortName: SoilSampleShortName, soilSampleLegendEntries: SoilSampleLegendEntryDTO[]) {
    this.entries = soilSampleLegendEntries.reverse();
    this.shortName = shortName;
  }

  get title() {
    switch (this.shortName) {
      case SoilSampleShortName.Reaction:
        return 'main.soilSamples.results.reactionNumber';
      case SoilSampleShortName.Potassium:
        return 'main.soilSamples.results.potassiumNumber';
      case SoilSampleShortName.Phosphor:
        return 'main.soilSamples.results.phosphorNumber';
      case SoilSampleShortName.Magnesium:
        return 'main.soilSamples.results.magnesiumNumber';
      case SoilSampleShortName.Copper:
        return 'main.soilSamples.results.copperNumber';
      case SoilSampleShortName.Boron:
        return 'main.soilSamples.results.boronNumber';
      case SoilSampleShortName.ClayPercentage:
        return 'main.soilSamples.results.clayPercentage';
      case SoilSampleShortName.Humus:
        return 'main.soilSamples.results.humus';
      case SoilSampleShortName.SoilType:
        return 'main.soilSamples.results.soilType';
      case SoilSampleShortName.NitrogenPercentage:
        return 'main.soilSamples.results.totalNitrogen';
      case SoilSampleShortName.CarbonPercentage:
        return 'main.soilSamples.results.totalCarbon';
      case SoilSampleShortName.OrganicCarbon:
        return 'main.soilSamples.results.organicCarbon';

      default:
        return '';
    }
  }
}
