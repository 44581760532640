import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlightInfoForField } from '@app/core/interfaces/blight-info-for-field.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import {
  BLIGHT_PROGNOSIS_END_DAY,
  BLIGHT_PROGNOSIS_END_MONTH,
  BLIGHT_PROGNOSIS_START_DAY,
  BLIGHT_PROGNOSIS_START_MONTH,
} from '@app/new-map/features/cultivation-journal/blight/blight.config';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Observable, Subscription, finalize, first } from 'rxjs';
import { BlightStateService } from '../../blight-state.service';

@Component({
  selector: 'app-blight-field-info',
  templateUrl: './blight-field-info.component.html',
  styleUrls: ['./blight-field-info.component.scss'],
})
export class BlightFieldInfoComponent implements OnInit, OnDestroy {
  @Input() public blightInfoForField$!: Observable<BlightInfoForField | null>;

  public showPrognosis = true;
  private subscription = new Subscription();
  constructor(
    private blightStateService: BlightStateService,
    private harvestYearStateService: HarvestYearStateService,
    private notificationService: NotificationService
  ) {}
  public ngOnInit(): void {
    this.subscription.add(
      this.harvestYearStateService.harvestYear$.subscribe((harvestyear) => {
        this.showPrognosis =
          DateTime.now().year === +harvestyear! &&
          DateTime.now() >=
            DateTime.fromObject({ year: +harvestyear!, month: BLIGHT_PROGNOSIS_START_MONTH, day: BLIGHT_PROGNOSIS_START_DAY }) &&
          DateTime.now() <= DateTime.fromObject({ year: +harvestyear!, month: BLIGHT_PROGNOSIS_END_MONTH, day: BLIGHT_PROGNOSIS_END_DAY });
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public registerBlight(farmId: number, cropId: number, $event: { blight: boolean; date: any }) {
    this.blightStateService.saveBlightInfectionRegistration(farmId, cropId, this.getDateFromEmitted($event)).subscribe(
      (res) => {
        this.blightStateService
          .hydrateFields()
          .pipe(
            first(),
            finalize(() => this.blightStateService.reselectActiveFeature())
          )
          .subscribe();
        this.notificationService.showUpdated('main.yieldPrognosis.potatoBlightTitle');
      },
      (err) => {}
    );
  }

  public getDateFromEmitted = (event: { blight: boolean; date: any }) => {
    return event.blight ? (event.date instanceof DateTime ? event.date.toJSDate() : event.date) : null;
  };

  DateTime = DateTime;
}
