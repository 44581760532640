import { Component } from '@angular/core';
// Angular material
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@app/core/language/language.service';
import { ChangeCrop } from './change-crop-modal-events.enum';

@Component({
  selector: 'app-change-crop-modal',
  templateUrl: './change-crop-modal.component.html',
  styleUrls: ['./change-crop-modal.component.scss'],
})
export class ChangeCropModalComponent {
  constructor(
    private languageService: LanguageService,
    private dialogRef: MatDialogRef<ChangeCropModalComponent>
  ) {}

  public onCancelClicked(event: any): void {
    this.dialogRef.close(ChangeCrop.Cancel);
  }

  public onYesDeleteClicked(event: any): void {
    this.dialogRef.close(ChangeCrop.DeleteTasks);
  }

  public onNoKeepClicked(event: any): void {
    this.dialogRef.close(ChangeCrop.KeepTasks);
  }

  public get translateHeader() {
    return this.languageService.getText('main.fieldAdministration.createField.areYouSureChangingCrop', {
      fieldName: !!this.dialogRef._containerInstance._config.data.name
        ? `${this.dialogRef._containerInstance._config.data.number} ${this.dialogRef._containerInstance._config.data.name}`
        : this.dialogRef._containerInstance._config.data.number,
    });
  }
}
