/**
 * Predefined user types.
 */
export enum UserType {
  /**
   * Landmand
   */

  Farmer = 1,
  /**
   * Firma
   */
  Company = 2,

  /**
   * Konsulent
   */
  Consultant = 3,

  /**
   * Landbrugsskolelærer
   */
  Teacher = 5,

  /**
   * Landbrugsskoleelev
   */
  Student = 6,

  /**
   * Privat konsulent
   */
  PrivateConsultant = 7,

  /**
   * Andre
   */
  Other = 99,
}
