import { Component, Inject } from '@angular/core';
import { ButtonChildComponent } from '@fe-shared/ui-components/buttons/button-child-component';
import { ListButtonComponent, ListButtonTypes } from '@fe-shared/ui-components/buttons/list-button/list-button.component';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent extends ButtonChildComponent<ListButtonTypes> {
  constructor(@Inject(ListButtonComponent) parent: ListButtonComponent) {
    super();
    this.parent = parent;
  }
}
