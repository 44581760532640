import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { latest } from '@app/shared/constants/rxjs-constants';
import { emissionTickDelay, filterNullish, filterNullOrEmpty } from '@app/shared/operators';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntities, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities';
import { deleteRequestResult, joinRequestResult, trackRequestResult } from '@ngneat/elf-requests';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import { isEqual } from 'lodash-es';
import { combineLatest, distinctUntilChanged, first, map, shareReplay, switchMap, tap } from 'rxjs';
import { BasisLayerCategory } from '../interfaces/basis-layer-category.interface';
import { PrescriptionMap } from '../interfaces/prescription-map.interface';
import { ApiParamsService } from './_api-params.service';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';
import { VraRepository } from './vra.repository';

interface ValidProps {
  valid: boolean;
}

interface BasisLayerCategoryRequest {
  val: Array<PrescriptionMap['taskId']>;
}

interface BasisLayerCategorySaveRequest {
  val: Array<BasisLayerCategory>;
}

@Injectable({ providedIn: 'root' })
export class BasisLayerRepository {
  constructor(
    private http: HttpClient,
    private apiParams: ApiParamsService,
    private endpoints: EndpointsService,
    private vraRepo: VraRepository,
    private pmQuery: PrescriptionMapQuery
  ) {}

  private readonly _bffApiUrl = this.endpoints.bffApi;
  private readonly _foApiUrl = this.endpoints.foApi;

  private readonly _apiParams = combineLatest([
    this.apiParams.farmAndHarvestYear$,
    this.vraRepo.activeTask$.pipe(filterNullish()),
    this.pmQuery.prescriptionMapsByActiveTask$.pipe(
      filterNullOrEmpty(),
      map((maps) => maps.flatMap((map) => map.taskId))
    ),
  ]).pipe(
    emissionTickDelay(),
    map(([[farmIds, harvestYear], { group }, taskIds]) => [farmIds, harvestYear, group, taskIds] as const),
    shareReplay(latest)
  );

  private readonly _store = createStore(
    { name: 'basis-layer-categories' },
    withEntities<BasisLayerCategory, 'basisLayerCategoryId'>({ idKey: 'basisLayerCategoryId' }),
    withProps<ValidProps>({ valid: true })
  );

  private readonly _storeData = this._apiParams.pipe(
    switchMap((params) => this._store.pipe(selectAllEntities(), joinRequestResult(['basis-layer-categories', ...params]))),
    shareReplay(latest)
  );

  private readonly _history = entitiesStateHistory(this._store);
  private readonly _storeHistory = stateHistory(this._store);

  private readonly _init$ = this._apiParams.pipe(
    distinctUntilChanged((a, b) => isEqual(a, b)),
    switchMap(([farmIds, harvestYear, group, taskIds]) =>
      this.http
        .post<BasisLayerCategory[], BasisLayerCategoryRequest>(
          `${this._bffApiUrl}/farms/${farmIds}/${harvestYear}/tasks/${group}/basislayercategories`,
          {
            val: taskIds,
          }
        )
        .pipe(
          first(),
          trackRequestResult(['basis-layer-categories', farmIds, harvestYear, group, taskIds], { cacheResponseData: true }),
          filterNullish(),
          tap((categories) => this._store.update(setEntities(categories))),
          tap(() => this._storeHistory.clear())
        )
    ),
    shareReplay(latest)
  );

  public readonly loading$ = this._storeData.pipe(
    map(({ fetchStatus }) => fetchStatus === 'fetching'),
    shareReplay(latest)
  );

  public readonly categories$ = this._storeData.pipe(
    map(({ data }) => data),
    shareReplay(latest)
  );

  public readonly hasPast$ = this._storeHistory.hasPast$.pipe(shareReplay(latest));

  public isValid$ = this._store.pipe(
    select(({ valid }) => valid),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public reset() {
    this._store.reset();
    this._history.clear();
  }

  public save() {
    combineLatest([this.apiParams.farmAndHarvestYear$, this.pmQuery.prescriptionMapsByActiveTask$, this.categories$])
      .pipe(
        first(),
        switchMap(([[farmIds, harvestYear], maps, categories]) =>
          this.http.post<void, BasisLayerCategorySaveRequest>(
            `${this._foApiUrl}/farms/${farmIds}/${harvestYear}/prescriptionmaps/${maps.map((map) => map.id)}/basislayercategories`,
            this.createCategorySaveRequest(categories)
          )
        ),
        tap(() => this.deleteRequestResults())
      )
      .subscribe();
  }

  private deleteRequestResults() {
    this._apiParams.pipe(first()).subscribe(([farmIds, harvestYear, group, taskIds]) => {
      deleteRequestResult(['basis-layer-categories', farmIds, harvestYear, group, taskIds]);
    });
  }

  private createCategorySaveRequest(categories: BasisLayerCategory[]): BasisLayerCategorySaveRequest {
    return { val: categories.filter((category) => category.priority && category.priority > 0) };
  }

  public update(category: Partial<BasisLayerCategory> & Pick<BasisLayerCategory, 'basisLayerCategoryId'>) {
    this._store.update(updateEntities(category.basisLayerCategoryId, category));
  }

  public updateAll(categories: (Partial<BasisLayerCategory> & Pick<BasisLayerCategory, 'basisLayerCategoryId'>)[]) {
    categories.forEach((category) => this._store.update(updateEntities(category.basisLayerCategoryId, category)));
  }

  public updateValidity(valid: boolean) {
    this._store.update((state) => ({ ...state, valid }));
  }

  public undo(id: BasisLayerCategory['basisLayerCategoryId']) {
    this._history.undo(id);
  }

  public redo(id: BasisLayerCategory['basisLayerCategoryId']) {
    this._history.redo(id);
  }

  public resetHistory() {
    this._history.jumpToPast(0);
    this.clearHistory();
  }

  public clearHistory() {
    this._history.clearPast();
    this._history.clearFuture();

    this._storeHistory.clear();
  }
}
