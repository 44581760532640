<div class="wrapper">
  <div class="main">
    <div class="info-header">
      {{ 'common.area.field' | translate }}: {{ growthRegulation.area! | unit }} ha <br />
      {{ 'common.crop' | translate }}: {{ field.crops[0].cropName }} <br />
      {{ 'main.growthRegulation.droughtIndex' | translate }}:
      <a (click)="openWaterBalanceDialog()">{{ 'main.growthRegulation.clickHere' | translate }}</a>
    </div>
    <table class="field-info-table" *ngIf="growthRegulation">
      <thead>
        <tr>
          <th>{{ 'main.growthRegulation.subject' | translate }}</th>
          <th>{{ 'main.growthRegulation.name' | translate }}</th>
          <th class="center">{{ 'main.growthRegulation.score' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of tableData" app-field-info-element [data]="row"></tr>
        <tr>
          <td class="total-text" colspan="2">{{ 'main.growthRegulation.total' | translate }}</td>
          <td>
            <div
              class="score total"
              [ngStyle]="{ backgroundColor: getBackgroundColor(growthRegulation.score), color: getColor(growthRegulation.score) }"
            >
              {{ growthRegulation.score }}
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <hr />
    <div>
      <h5 class="risk-of-lodging-title">{{ 'main.growthRegulation.info.title' | translate }}</h5>
      <div class="links">
        <a href="https://www.seges.tv/video/49618075/se-vaekstreguleringsprognosen-for" target="_blank">{{
          'main.growthRegulation.links.video' | translate
        }}</a>
        <a
          href="https://www.landbrugsinfo.dk/public/a/4/5/plantebeskyttelse_forsikring_mod_lejesad_vinterhvede_vinterrug"
          target="_blank"
          >{{ 'main.growthRegulation.links.text' | translate }}</a
        >
      </div>
      <div *ngIf="isWheat">
        {{ 'main.growthRegulation.info.bodyStartWinterWheat' | translate }}
      </div>
      <div *ngIf="isRye">
        {{ 'main.growthRegulation.info.bodyStartWinterRye' | translate }}
      </div>
      <div class="info-text">
        <div class="info-row">
          <div class="color color-green"></div>
          <div>{{ 'main.growthRegulation.info.lowRisk' | translate }}</div>
        </div>
        <div class="info-row">
          <div class="color color-yellow"></div>
          <div>{{ 'main.growthRegulation.info.mediumRisk' | translate }}</div>
        </div>
        <div class="info-row">
          <div class="color color-red"></div>
          <div>{{ 'main.growthRegulation.info.highRisk' | translate }}</div>
        </div>
        <div class="info-row">
          <div class="color white-outline"></div>
          <div>{{ 'main.growthRegulation.info.noData' | translate }}</div>
        </div>
      </div>
      <div *ngIf="isWheat">
        {{ 'main.growthRegulation.info.bodyEndWinterWheat' | translate }}
      </div>
      <div *ngIf="isRye">
        {{ 'main.growthRegulation.info.bodyEndWinterRye' | translate }}
      </div>
    </div>
  </div>
</div>
