<div class="header-close">
  <h4>{{ 'nTool.info.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  {{ 'nTool.info.section1' | translate }}
  {{ 'nTool.info.section2' | translate }}
  {{ 'nTool.info.section3' | translate }}
  <br />
  <br />
  {{ 'nTool.info.section4' | translate }}
  {{ 'nTool.info.section5' | translate }}
  {{ 'nTool.info.section6' | translate }}
</mat-dialog-content>
