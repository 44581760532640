import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LogService } from '@app/core/log/log.service';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  public async handleError(error: any) {
    const logService = this.injector.get(LogService);
    const message = error.message ?? error.toString();

    if (error.status) {
      // eslint-disable-next-line no-console
      console.error(error);
      error = new Error(message);
    }

    try {
      const stackString = await this.getStackTrace(error);

      const errorTraceStr = `Error message:\n${message}.\nStack trace: ${stackString}`;

      logService.logError(errorTraceStr);
    } catch (e) {
      // parsing stack trace might throw an error, in which case we ignore it
      throw error;
    }

    throw error;
  }

  private async getStackTrace(error: Error) {
    const stackframes = await StackTrace.fromError(error);

    // for getting stackTrace with sourcemaps
    const stackString = stackframes
      .splice(0, 10)
      .map((sf) => sf.toString())
      .toString();

    return stackString;
  }
}
