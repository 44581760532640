<app-accordion [templateRef]="accordionContent" [titleTemplateRef]="accordionTitle" #accordion></app-accordion>
<ng-template #accordionTitle>
  <div class="as-applied-accordion-title" id="e2e-{{ id }}" [ngClass]="{ open: accordion.isOpen }">
    <div class="title">{{ title }}</div>
    <div class="toggle-icon">
      <app-icon icon="navigate_next" [ngClass]="{ open: accordion.isOpen }"></app-icon>
    </div>
  </div>
</ng-template>
<ng-template #accordionContent>
  <div class="no-tasks" *ngIf="!data.length">
    <app-icon icon="error_outline"></app-icon>
    <p class="message">{{ noDataMessage | translate }}</p>
  </div>
  <app-table
    *ngIf="data.length"
    [sortFn]="sortFn"
    [dataSource]="data"
    [displayedColumns]="tableColumns"
    headerAlignment="left"
    [dataStyles]="tableStyles"
    [selectable]="true"
    (selected)="onItemSelect.emit($event)"
    [formatDates]="true"
    [showTooltip]="showTooltip"
    [tooltipKey]="tooltipKey"
    [charactersBeforeTooltip]="charactersBeforeTooltip"
  ></app-table>
</ng-template>
