import { Injectable, OnDestroy } from '@angular/core';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { Month } from '@app/core/enums/month.enum';
import { FeatureService } from '@app/core/feature/feature.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { LanguageService } from '@app/core/language/language.service';
import { QuerySyncService, SyncedQueryParam } from '@app/core/query-param/query-sync.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { LocalState } from '@app/helpers/local-state';
import { HotspotStyles } from '@app/new-map/helpers/styles/hotspots-styles';
import { getStyle } from '@app/new-map/helpers/styles/map-styles';
import { WKTUtil } from '@app/new-map/helpers/utils/WKT-util';
import { FeatureUtil } from '@app/new-map/helpers/utils/feature-util';
import { OlMapService } from '@app/new-map/map-service/ol-map.service';
import { OlLayerService } from '@app/new-map/services/layer/layer.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { DateTime } from 'luxon';
import Feature from 'ol/Feature';
import { boundingExtent, getCenter } from 'ol/extent';
import Point from 'ol/geom/Point';
import Cluster from 'ol/source/Cluster';
import Source from 'ol/source/Source';
import Vector from 'ol/source/Vector';
import Style from 'ol/style/Style';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { CreateHotspotComponent } from './hotspot-side-drawer/create-hotspot/create-hotspot.component';
import { ShownComponentEnum } from './hotspot-side-drawer/hotspots-sidedrawer-showncomponent.enum';
import { ModifiedHotspot } from './hotspot-side-drawer/interfaces/modified-hotspot.interface';
import { HotspotTypes } from './hotspot-side-drawer/shape-file-import/interfaces/hotspot-types.enum';
import { HotspotRepo } from './hotspot-side-drawer/shared/hotspot-repo/hotspot-repo.service';
import { HotspotService } from './hotspot-side-drawer/shared/hotspot.service';

const hotspotIdQueryParamKey = 'hotspot';
@Injectable({
  providedIn: 'root',
})
export class HotspotsService implements OnDestroy {
  public readonly selectedFarms$ = this.farmStateService.selectedFarms$;
  public readonly isHotspotsLoading$ = new BehaviorSubject<boolean>(false);
  public readonly loadingMessage$ = new BehaviorSubject<string>('');
  public readonly isHotpotDrawing$ = new BehaviorSubject<boolean>(false);

  public selectedHotspotSubject = new Subject<HotspotDto | undefined>();
  private drawerWidthSubject = new ReplaySubject<string>(1);
  public drawerWidth$: Observable<string>;

  private shownComponentState = new LocalState<{ shownComponent: ShownComponentEnum }>({
    shownComponent: ShownComponentEnum.createHotspotComponent,
  });
  public shownComponentState$ = this.shownComponentState.changes$;

  public hotspotFeaturesSubject = new BehaviorSubject<Feature[] | null>(null);
  public hotspotMarkerFeaturesSubject = new BehaviorSubject<Feature[] | null>(null);

  private _selectedHotspot?: HotspotDto;

  private _subs = new SubscriptionArray();

  public get selectedHotspot(): HotspotDto | undefined {
    return this._selectedHotspot;
  }
  public set selectedHotspot(v: HotspotDto | undefined) {
    this.selectedHotspotSubject.next(v);
    this._selectedHotspot = v;
  }
  public set drawerWidth(width: string) {
    this.drawerWidthSubject.next(width);
  }
  public set isDirty(isDirty: boolean) {
    this.dirtyCheckService.setIsFeatureDirty(this, isDirty, 'hotspots');
  }
  public get isDirty(): boolean {
    return this.dirtyCheckService.isAppDirty;
  }

  public originalHotspots: HotspotDto[] = [];
  public hotspots: HotspotDto[] = [];
  public selectedHotspotMapFeature!: Feature;
  public hotspotTypes: HotspotType[] = [];
  public hotspotSubTypes: HotspotSubType[] = [];

  constructor(
    private mapService: OlMapService,
    private hotspotService: HotspotService,
    private languageService: LanguageService,
    private featureService: FeatureService,
    private hotspotRepo: HotspotRepo,
    private querySyncService: QuerySyncService,
    private sidedrawerService: SideDrawerOverlayService,
    private dirtyCheckService: DirtyCheckService,
    private farmStateService: FarmStateService,
    private endpointService: EndpointsService,
    private layerService: OlLayerService
  ) {
    this.drawerWidth$ = this.drawerWidthSubject.asObservable();
  }

  public ngOnDestroy(): void {
    this.stopSelectedHotspotQueryParamSync();
    this._subs.unsubscribe();
  }

  public setShownComponentState(c: ShownComponentEnum) {
    this.shownComponentState.setState({ shownComponent: c });
  }

  private enableModifyForMapFeature(drawnFeature: Feature) {
    const modifyInteraction$ = this.createEnableModifyForMapFeatureInteraction(drawnFeature);

    if (modifyInteraction$) this._subs.add(modifyInteraction$.subscribe((feature: Feature) => this.onGeometryUpdated(feature)));
  }

  private onGeometryUpdated(modifiedFeature: Feature) {
    this.isDirty = true;
    this.updateHotspotMarkerFeatureGeometry(modifiedFeature);
    if (this.selectedHotspot) this.selectedHotspot.geometry = this.findHotspotFeatureWkt(modifiedFeature);
  }

  public addToHotspotListAndSet(savedHotspot: HotspotDto) {
    const newHotspots = this.addHotspotToList(this.hotspots, savedHotspot);

    this.mapService.removeModifyInteraction();
    this.mapService.enableSelectInteraction();

    this.setHotspotsAndUpdateMap(newHotspots);
  }

  public setupSelectedHotspotQueryParamSync() {
    const syncedParam: SyncedQueryParam = {
      selectorToUpdateQueryParam$: this.selectedHotspotSubject.pipe(
        map((hotspot) => {
          if (!hotspot) {
            return null;
          }

          return hotspot.id;
        })
      ),
      actionTriggeredOnQueryParamChange: (hotspotId) => {
        if (typeof hotspotId !== 'string') return;

        // get current hotspot feature and call set hotspot
        combineLatest([this.hotspotFeaturesSubject, this.hotspotMarkerFeaturesSubject])
          .pipe(
            filter(([hotspotFeatures, hotspotMarkerFeatures]) => {
              return !!hotspotFeatures && !!hotspotMarkerFeatures;
            }),
            tap(([hotspotFeatures, hotspotMarkerFeatures]) => {
              const selectedHotspotFeature = hotspotFeatures?.find((feature) => {
                const featureHotspotId = feature?.get('hotspotId');
                return featureHotspotId === hotspotId;
              });
              const selectedHotspotMarkerFeature = hotspotMarkerFeatures?.find((feature) => {
                const featureHotspotId = feature?.get('hotspotId');
                return featureHotspotId === hotspotId;
              });

              if (!selectedHotspotFeature || !selectedHotspotMarkerFeature) return;

              this.setSelectedHotspot(selectedHotspotFeature, selectedHotspotMarkerFeature);
            }),
            first()
          )
          .subscribe();
      },
      queryKey: hotspotIdQueryParamKey,
    };

    this.querySyncService.addSyncedQueryParam(syncedParam);
  }

  private stopSelectedHotspotQueryParamSync() {
    this.querySyncService.unsyncQueryParam(hotspotIdQueryParamKey);
  }

  public setupHotspotsSubscription() {
    this._subs.add(
      this.getHotspotsTypesAndSubTypes$.subscribe(([hotspotTypes, hotspotSubTypes, hotspots]) =>
        this.setHotspotTypesAndUpdateMap(hotspots, hotspotSubTypes, hotspotTypes)
      )
    );
  }

  public refreshTypesAndSubtypes() {
    this.getTypesAndSubtypes().subscribe(([hotspotTypes, hotspotSubTypes]) => {
      this.hotspotSubTypes = hotspotSubTypes;
      this.hotspotTypes = hotspotTypes;
    });
  }

  private setHotspotTypesAndUpdateMap(hotspots: HotspotDto[], hotspotSubTypes: HotspotSubType[], hotspotTypes: HotspotType[]) {
    this.hotspotSubTypes = hotspotSubTypes;
    this.hotspotTypes = hotspotTypes;
    this.onHotspotsChange(hotspots);
    this.setHotspotsLoading(false);
  }

  private onHotspotsChange(hotspots: HotspotDto[]) {
    this.setHotspotsAndUpdateMap(hotspots);
  }

  public setHotspotsAndUpdateMap(hotspots: HotspotDto[]) {
    this.setHotspots(hotspots);
    this.addOrUpdateHotspotMapFeatures(this.hotspots);
  }

  public setHotspots(hotspots: HotspotDto[]) {
    this.originalHotspots = cloneDeep(hotspots);
    this.hotspots = cloneDeep(hotspots);
  }

  public onCloseHotspotDetails(isNewHotspot: boolean, createHotspotComponent: CreateHotspotComponent | undefined) {
    // if new or editing, revert on cancel
    this.mapService.enableSelectInteraction();

    if (isNewHotspot || this.selectedHotspot) {
      this.revertHotspots();
    }

    this.selectedHotspot = undefined;
    this.deselectHotspot(this.selectedHotspotMapFeature);
    this.mapService.removeModifyInteraction();

    if (isNewHotspot) {
      createHotspotComponent?.enableDrawCurrentHotspot();
    }
  }

  public onNewHotspotDrawn(drawnFeature: Feature, markerFeature: Feature, hotspotTypeId: HotspotTypes) {
    this.selectedHotspot = {
      hotspotTypeId: hotspotTypeId,
      description: '',
      farmId: undefined,
      hotspotSubTypeIds: [],
      geometry: this.findHotspotFeatureWkt(drawnFeature),
      id: 0,
      notificationRangeType: null,
      imageUrls: [],
    };

    this.selectedHotspotMapFeature = markerFeature;
    this.enableModifyForMapFeature(drawnFeature);
    this.mapService.disableSelectInteraction();
  }

  public onHotspotDeleted(deletedHotspot: HotspotDto) {
    this.hotspots = this.removeHotspotFromList(this.hotspots, deletedHotspot);
    this.addOrUpdateHotspotMapFeatures(this.hotspots);
    this.mapService.deselectFeatures();
    this.selectedHotspot = undefined;
    this.originalHotspots = cloneDeep(this.hotspots);
  }

  public setSelectedHotspot(clickedHotspot: Feature, clickedHotspotMarker: Feature) {
    const hotspotDetailAndFeature = this.findHotspotFeatureAndDetail(
      clickedHotspot,
      clickedHotspotMarker,
      this.selectedHotspotMapFeature,
      this.hotspots
    );

    this.selectedHotspotMapFeature = hotspotDetailAndFeature.hotspotMapFeature;

    const selectedHotspotClone = cloneDeep(hotspotDetailAndFeature.selectedHotspot);
    // Image urls on hotspots are absolute when comming from BE, but after introducing BFF we need to convert them to relative URLS, because CM no longer holds auth tokens for FT api.

    if (!selectedHotspotClone || !selectedHotspotClone.imageUrls) return;

    selectedHotspotClone.imageUrls = selectedHotspotClone.imageUrls.map(
      (imageUrl) => this.endpointService.ftApi + '/' + imageUrl.substring(imageUrl.indexOf('images'))
    );

    this.selectedHotspot = selectedHotspotClone;
    this.setShownComponentState(ShownComponentEnum.hotspotDetailsComponent);
  }

  public onHotspotClick(clickedHotspot: Feature) {
    if (!clickedHotspot) {
      return;
    }

    const hotspotFeature = this.findCompanionFeature(clickedHotspot, LayerId.HOTSPOTS);
    const markerFeature = this.findCompanionFeature(clickedHotspot, LayerId.HOTSPOT_MARKERS);

    if (!hotspotFeature || !markerFeature) {
      return;
    }

    this.enableModifyForMapFeature(hotspotFeature);

    this.setSelectedHotspot(hotspotFeature, markerFeature);

    this.sidedrawerService.showAllSideDrawers();
  }

  /**
   * Finds the companion feature for the given hotspot or hotspot marker.
   * If the feature input is the same type as the type input, the feature input is just returned.
   * @param feature Hotspot feature or marker feature
   * @param type The type you are looking for.
   */
  public findCompanionFeature(feature: Feature, type: LayerId.HOTSPOT_MARKERS | LayerId.HOTSPOTS) {
    if (feature.get('layerId') === type) {
      return feature;
    } else {
      const companionFeatures = this.getCompanionFeatures(feature);

      return companionFeatures.find((feat) => feat.get('companionFeatureId') === feature.get('companionFeatureId'));
    }
  }

  public setHotspotFeatureState(hotspotFeature: Feature, isSelected: boolean) {
    isSelected ? hotspotFeature.setStyle(HotspotStyles.hotspotsSelectedStyle) : hotspotFeature.setStyle(HotspotStyles.hotspotsStyle);
  }

  public setHotspotMarkerState(marker: Feature) {
    const zoom = this.mapService.zoomLevel;

    const style = zoom ? getStyle(marker, zoom) : undefined;

    marker.setStyle(style);
  }

  public removeHotspotFromList(hotspots: HotspotDto[], deletedHotspot: HotspotDto) {
    return [...hotspots.filter((hotspot) => hotspot.id !== deletedHotspot.id)];
  }

  public addHotspotToList(hotspots: HotspotDto[], updatedHotspot: HotspotDto) {
    return [...hotspots.filter((hotspot) => hotspot.id !== updatedHotspot.id), updatedHotspot];
  }

  public setHotspotsLoading(isLoading: boolean, message?: string) {
    this.isHotspotsLoading$.next(isLoading);
    this.loadingMessage$.next(message || this.languageService.getText('main.fieldInspector.loadingHotspots'));
  }

  public setHotspotDrawing(isDrawing: boolean) {
    this.isHotpotDrawing$.next(isDrawing);
  }
  public get getHotspotsTypesAndSubTypes$() {
    return this.selectedFarms$.pipe(
      tap(() => this.setHotspotsLoading(true)),
      switchMap((farms) => this.getHotspotsTypesAndSubTypes(farms))
    );
  }

  public deselectHotspot(selectedHotspotFeature: Feature) {
    if (!selectedHotspotFeature) {
      return;
    }

    // Selected feature is on the companion layer. Just set style and return
    if (selectedHotspotFeature.get('companionLayerId') === LayerId.HOTSPOT_MARKERS) {
      selectedHotspotFeature.setStyle(HotspotStyles.hotspotsStyle);
    } else {
      // Selected feature is not on companion layer. Remove and set styles from corresponding companion layer
      this.getCompanionFeatures(selectedHotspotFeature)
        .filter((feature) => feature.getStyle !== null)
        .map((feature) => feature.setStyle(HotspotStyles.hotspotsStyle));
    }

    this.mapService.deselectFeatures();
  }

  public updateAllEditedHotspotMapFeatures(
    hotspotsToUpdate: ModifiedHotspot[],
    hotspots: HotspotDto[],
    originalHotspots: HotspotDto[]
  ): Observable<HotspotDto[]> {
    const filteredTempResult = this.filterInvalidEditedHotspots(hotspotsToUpdate, hotspots);

    this.mapService.removeModifyInteraction();
    this.mapService.enableSelectInteraction();

    return forkJoin(
      filteredTempResult.map((hotspotToUpdate) => {
        if (!hotspotToUpdate.hotspot) return of(null); // Skip if hotspot is undefined

        hotspotToUpdate.hotspot.geometry = hotspotToUpdate.feature.wktCoordinates;

        return this.hotspotService.update(hotspotToUpdate.hotspot, [], []).pipe(
          catchError((error) => {
            // Handle errors for individual hotspot updates
            return throwError(error);
          })
        );
      })
    ).pipe(
      map((results: (HotspotDto | null)[]) => {
        // Map null values to a default value
        return results.map((result) => result || {});
      }),
      catchError((error) => {
        // Handle errors
        this.revertHotspotMapFeatureChanges(originalHotspots);
        return throwError(error);
      })
    );
  }

  public revertHotspots(hotspotsToRevertTo: HotspotDto[] = this.originalHotspots) {
    this.revertHotspotMapFeatureChanges(hotspotsToRevertTo);
  }

  public revertHotspotMapFeatureChanges(originalHotspots: HotspotDto[]) {
    this.addOrUpdateHotspotMapFeatures(originalHotspots);
  }

  private addOrUpdateHotspotMapFeatures(hotspots: HotspotDto[]) {
    const hotspotFeatures: Feature[] = this.createHotspotFeatures(hotspots);

    const hotspotMarkerFeatures: Feature[] = this.createHotspotMarkerFeatures(hotspotFeatures);

    // used for query param sync
    this.hotspotFeaturesSubject.next(hotspotFeatures);
    this.hotspotMarkerFeaturesSubject.next(hotspotMarkerFeatures);
    this.layerService.createHotspotLayers(hotspotFeatures, hotspotMarkerFeatures);
    this.mapService.e2eSetCanvasReady(true);
  }

  public addHotspotsToMap(hotspotsToBeAdded: HotspotDto[]) {
    const hotspotFeatures = this.createHotspotFeatures(hotspotsToBeAdded);
    const hotspotMarkerFeatures = this.createHotspotMarkerFeatures(hotspotFeatures);

    combineLatest([this.hotspotFeaturesSubject.pipe(first()), this.hotspotMarkerFeaturesSubject.pipe(first())]).subscribe(
      ([originalHotspotFeatures, originalHotspotMarkerFeatures]) => {
        const combinedHotspotFeatures = [...originalHotspotFeatures!, ...hotspotFeatures];
        const combinedHotspotMarkerFeatures = [...originalHotspotMarkerFeatures!, ...hotspotMarkerFeatures];

        this.hotspotFeaturesSubject.next(combinedHotspotFeatures);
        this.hotspotMarkerFeaturesSubject.next(combinedHotspotMarkerFeatures);

        this.layerService.createHotspotLayers(combinedHotspotFeatures, combinedHotspotMarkerFeatures);

        this.mapService.e2eSetCanvasReady(true);
      }
    );

    this.slideMapToHotspots(hotspotMarkerFeatures);
  }

  private slideMapToHotspots(hotspotMarkerFeatures: Feature[]) {
    const points = hotspotMarkerFeatures.map((f) => f.get('geometry')) as Point[];
    const coordinates = points.map((p) => p.getCoordinates());
    const extent = boundingExtent(coordinates);
    this.mapService.fitMapToExtent(extent);
  }

  private getHotspotsTypesAndSubTypes(farms: Farm[]) {
    const date = DateTime.fromObject({ year: 1970, month: Month.January, day: 1 }).setZone('utc', { keepLocalTime: true });
    return forkJoin(this.getTypes(date), this.getSubTypes(), this.hotspotService.get(farms.map((farm) => farm.id)));
  }

  public getSubTypes(): Observable<HotspotSubType[]> {
    return this.hotspotRepo.getSubTypes();
  }

  public getTypes(from: DateTime): Observable<HotspotType[]> {
    return this.hotspotRepo.getTypes(from);
  }

  public getTypesAndSubtypes(
    date: DateTime = DateTime.fromObject({ year: 1970, month: Month.January, day: 1 }).setZone('utc', { keepLocalTime: true })
  ) {
    return forkJoin(this.getTypes(date), this.getSubTypes());
  }

  /**
   * Create ol map features for hotspot markers.
   * @param features ol hotspot features.
   */
  public createHotspotMarkerFeatures(features: Feature[]): Feature[] {
    return features.map((hotspotFeature) => {
      const featureProps = {
        companionLayerId: LayerId.HOTSPOTS,
        layerId: LayerId.HOTSPOT_MARKERS,
        hotspotTypeId: hotspotFeature?.get('hotspotTypeId'),
        companionFeatureId: hotspotFeature?.get('companionFeatureId'),
        hotspotId: hotspotFeature?.get('hotspotId'),
      };
      const featureGeometry = new Point(FeatureUtil.getCenterOfFeature(hotspotFeature!));
      const featureId = hotspotFeature?.get('hotspotId');

      return this.featureService.createFeature(featureProps, featureId, featureGeometry)!;
    });
  }

  public updateEditedHotspotMapFeatures(modifiedFeature: Feature, hotspotsToUpdate: ModifiedHotspot[]): ModifiedHotspot[] {
    const _hotspotsToUpdate = cloneDeep(hotspotsToUpdate);
    // update marker feature geometry
    this.mapService.updateFeatureGeometry(
      LayerId.HOTSPOT_MARKERS,
      modifiedFeature.getId(),
      new Point(FeatureUtil.getCenterOfFeature(modifiedFeature))
    );

    // get new coordinates
    const coordinates: number[] | number[][] = FeatureUtil.getCoordinatesFromFeature(modifiedFeature);
    const hotspotToUpddateIndex = _hotspotsToUpdate.findIndex((item) => item.id === modifiedFeature.getId());
    const geometryType = FeatureUtil.getGeometryTypeFromFeature(modifiedFeature);

    if (hotspotToUpddateIndex > -1) {
      _hotspotsToUpdate.splice(hotspotToUpddateIndex, 1);
    }

    const featureId = modifiedFeature.getId();
    const id = featureId ? parseInt(featureId.toString(), 10) : undefined;
    const wkt = WKTUtil.getWktFromFeature(modifiedFeature);

    _hotspotsToUpdate.push({
      id: id,
      coordinates: coordinates,
      wktCoordinates: wkt,
      geometryType: geometryType,
    });

    return _hotspotsToUpdate;
  }

  public updateHotspotMarkerFeatureGeometry(modifiedFeature: Feature) {
    this.mapService.updateFeatureGeometry(
      LayerId.HOTSPOT_MARKERS,
      modifiedFeature.getId(),
      new Point(FeatureUtil.getCenterOfFeature(modifiedFeature))
    );
  }

  public findHotspotFeatureWkt(modifiedFeature: Feature) {
    return WKTUtil.getWktFromFeature(modifiedFeature);
  }

  public createEnableModifyForMapFeatureInteraction(drawnFeature: Feature) {
    return this.mapService.addModifyInteractionForFeature(drawnFeature);
  }

  public addDrawnHotspotToMap(feature: Feature, hotspotTypeId: HotspotTypes) {
    // set temp id to avoid null reference errors until correct id is supplied by server
    const { drawnFeature, markerFeature } = this.createHotspotMapFeatures(feature, HotspotStyles.hotspotsSelectedStyle, hotspotTypeId);

    combineLatest([this.hotspotFeaturesSubject, this.hotspotMarkerFeaturesSubject])
      .pipe(take(1))
      .subscribe(([hotspots, markers]) => {
        this.mapService.removeDrawingInteraction();
        this.layerService.createHotspotLayers([...hotspots!, drawnFeature], [...markers!, markerFeature]);
        this.mapService.e2eSetCanvasReady(true);
      });

    return { drawnFeature, markerFeature };
  }

  public addDrawingInteraction(hotspotTypes: HotspotType[], typeId: number, geometryType?: GeometryType) {
    // remove potential existing drawing interaction
    this.mapService.removeDrawingInteraction();

    const hotspotType = hotspotTypes.find((type: HotspotType) => type.id === typeId);

    if (!hotspotType) {
      return;
    }

    // Disable select interaction because user should not be able to select features while creating a new one
    this.mapService.disableSelectInteraction();

    if (!geometryType) {
      geometryType = hotspotType.geometryType;
    }

    // add drawing interaction
    const vectorSourceEvent$ = this.mapService.addDrawingInteraction(LayerId.HOTSPOTS, geometryType, HotspotStyles.hotspotsDrawingStyle);

    return vectorSourceEvent$?.pipe(
      filter((event) => !!event.feature),
      first(),
      map((event) => ({ event, hotspotTypeId: hotspotType.id }))
    );
  }

  public removeHotspotMapFeatures() {
    this.mapService.deselectFeatures();
    this.layerService.removeLayers([LayerId.HOTSPOTS, LayerId.HOTSPOT_MARKERS]);
    this.mapService.removeModifyInteraction();
  }

  /**
   * Create ol map features from hotspots.
   * @param hotspots Hotspots
   */
  public createHotspotFeatures(hotspots: HotspotDto[]): Feature[] {
    return hotspots.map((hotspot) => {
      const feature = FeatureUtil.getMapFeature(
        LayerId.HOTSPOTS,
        WKTUtil.getGeometryTypeFromWktString(hotspot.geometry!),
        WKTUtil.getCoordinatesFromWktString(hotspot.geometry!)
      );
      feature.setProperties({
        companionLayerId: LayerId.HOTSPOT_MARKERS,
        companionFeatureId: hotspot.id,
        hotspotId: hotspot.id,
        hotspotTypeId: hotspot.hotspotTypeId,
      });
      feature.setId(hotspot.id);

      return feature;
    });
  }

  public findHotspotFeatureAndDetail(
    clickedHotspot: Feature,
    clickedHotspotMarker: Feature,
    selectedHotspotMapFeature: Feature,
    hotspots: HotspotDto[]
  ) {
    if (selectedHotspotMapFeature) {
      this.setHotspotFeatureState(selectedHotspotMapFeature, false);
    }
    this.setHotspotFeatureState(clickedHotspot, true);
    if (clickedHotspotMarker) {
      this.setHotspotMarkerState(clickedHotspotMarker);
    }

    const hotspotDetail = hotspots.find((hotspot) => hotspot.id === parseInt(clickedHotspot.get('hotspotId'), 10));

    return {
      selectedHotspot: hotspotDetail,
      hotspotMapFeature: clickedHotspot,
    };
  }

  public getCompanionFeatures(hotspotFeature: Feature) {
    const companionLayerId = hotspotFeature.get('companionLayerId');
    const companionLayer = this.mapService.getLayerFromMap(companionLayerId);
    let companionFeatures: Feature[] = [];
    if (companionLayer) {
      const mapSource: Source | null = companionLayer.getSource();

      if (mapSource instanceof Cluster) {
        const clusterSource = mapSource as Cluster;
        clusterSource.getFeatures();
      } else if (mapSource instanceof Vector) {
        const vectorSource = mapSource as Vector;
        companionFeatures = vectorSource.getFeatures();
      }
    }

    return companionFeatures;
  }

  private filterInvalidEditedHotspots(hotspotsToUpdate: ModifiedHotspot[], hotspots: HotspotDto[]) {
    return hotspotsToUpdate
      .map((feature) => ({
        feature: feature,
        hotspot: hotspots.find((hotspot: HotspotDto) => hotspot.id === feature.id),
      }))
      .filter((result) => result.hotspot !== null || result.hotspot !== undefined);
  }

  private createHotspotMapFeatures(feature: Feature, featureStyle: Style[], hotspotTypeId: HotspotTypes) {
    const drawnFeature: Feature = feature;
    const markerFeature: Feature = new Feature();

    drawnFeature.setStyle(featureStyle);
    drawnFeature.set('hotspotId', -1);
    markerFeature.set('hotspotId', -1);

    drawnFeature.set('newlyCreatedHotspot', true);
    markerFeature.set('newlyCreatedHotspot', true);

    markerFeature.setGeometry(new Point(getCenter(drawnFeature.getGeometry()!.getExtent()!)));
    markerFeature.set('layerId', LayerId.HOTSPOT_MARKERS);
    markerFeature.set('hotspotTypeId', hotspotTypeId);

    return { drawnFeature, markerFeature };
  }

  private _findHotspotMapFeature(feature: Feature): Feature[] {
    const isGroupFeature = feature.get('features') !== undefined;

    const featureLayerId: LayerId = isGroupFeature ? feature.get('features')[0].get('layerId') : feature.get('layerId');

    return featureLayerId === LayerId.HOTSPOTS || featureLayerId === LayerId.HOTSPOT_MARKERS
      ? isGroupFeature
        ? feature.get('features')
        : [feature]
      : [];
  }
}
