import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-blight-table-info',
  templateUrl: './blight-table-info.component.html',
  styleUrls: ['./blight-table-info.component.scss'],
})
export class BlightTableInfoComponent {
  constructor(private dialogRef: MatDialogRef<BlightTableInfoComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
