import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '@app/core/http/http-client';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { Observable } from 'rxjs';

export interface DataConnectionCredentialMessages {
  loginError: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataConnectionCredentialsRepo {
  constructor(
    public http: HttpClient,
    public windowRef: WindowRefService
  ) {}

  /**
   * Check the users credentials.
   * @param url is the data connection type ping url
   * @param userName is the data connection setting id
   * @param password is the data connection setting code
   */
  public validateConnection(url: string, userName: string, password: string): Observable<string> {
    const headers = new HttpHeaders();
    const headersWithAuth = headers.append('Authorization', this.encodeCredentials(userName, password));
    const options = { headers: headersWithAuth };

    return this.http.get<string>(url, options);
  }

  /**
   * Check the users credentials for ranch users.
   * @param url is the data connection type ping url
   * @param userName is the data connection setting id
   * @param password is the data connection setting code
   */
  public validateRanchConnection(url: string, userName: string, password: string): Observable<string> {
    const headers = new HttpHeaders();
    const headersWithAuth = headers.append('Authorization', this.encodeCredentials(userName, password));
    const options = { headers: headersWithAuth };
    return this.http.get<string>(url, options);
  }

  /**
   * Check the users credentials for Field Sense users.
   * @param url is the data connection type ping url
   * @param apiKey is the api key connecting the user to FieldSense
   */
  public validateFieldSenseConnection(url: string, apiKey: string): Observable<string> {
    const headers = new HttpHeaders();
    const headersWithAuth = headers.append('Authorization', 'bearer ' + apiKey);
    const options = { headers: headersWithAuth };
    return this.http.get<string>(url, options);
  }

  public validateCnhConnection(bffCnhApiUrl: string): Observable<boolean> {
    return this.http.get<boolean>(bffCnhApiUrl, { withCredentials: false });
  }

  /**
   * Encode credentials with base64
   * @param userName to encode
   * @param password to encode
   */
  private encodeCredentials(userName: string, password: string): string {
    return `Basic ${btoa(userName + ':' + password)}`;
  }
}
