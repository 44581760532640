import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from '@app/shared/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef | null = null;

  constructor(private overlay: Overlay) {}

  public show(message = 'common.loadingData') {
    // Returns an OverlayRef (which is a PortalHost)

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);

    // run in async context for triggering "tick", thus avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      if (!this.overlayRef?.hasAttached()) {
        const component = this.overlayRef?.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        if (component) component.instance.message = message;
      }
    });
  }

  public hide() {
    if (!!this.overlayRef) {
      setTimeout(() => {
        this.overlayRef?.detach();
      });
    }
  }
}
