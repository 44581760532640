import { Injectable } from '@angular/core';
import { CropNormNumbers } from '@app/core/enums/crop-norm-number.enums';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { GrowthstageErrorCodes } from '@app/core/repositories/growthstage/growthstage-error-codes.enum';
import { GrowthstageError } from '@app/core/repositories/growthstage/growthstage-error.interface';
import { GrowthstageRepoService } from '@app/core/repositories/growthstage/growthstage-repo.service';
import {
  GrowthStageCorrection,
  GrowthStageCorrectionsInput,
  GrowthStageDay,
  GrowthStageInput,
  GrowthStageResult,
} from '@app/core/repositories/growthstage/growthstage.interface';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { GrowthStagesInfoComponent } from './growth-stages-info/growth-stages-info.component';
import { GrowthstageCorrectionDialogComponent } from './growthstage-correction-dialog/growthstage-correction-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class GrowthstageService {
  private growthStageResult?: GrowthStageResult;
  private hasObservationsSubject = new Subject<boolean>();

  public get getGrowthStageResults(): GrowthStageResult | undefined {
    return this.growthStageResult;
  }

  constructor(
    private languageService: LanguageService,
    private growthstageRepo: GrowthstageRepoService,
    private dialogService: DialogService
  ) {}

  public isExistingCorrection(updateCorrection: GrowthStageCorrection): GrowthStageCorrection {
    const growthStageCorrections: GrowthStageCorrection[] = this.growthStageResult?.corrections ?? [];

    const updateCorrectionDatePart = DateTime.fromISO(updateCorrection.dateTime).toFormat(
      LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey)['L']
    );

    return growthStageCorrections.filter((correction: GrowthStageCorrection) => {
      const correctionDatePart = DateTime.fromISO(correction.dateTime).toFormat(
        LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey)['L']
      );

      return updateCorrectionDatePart === correctionDatePart;
    })[0];
  }

  public getGrowthStageConstants() {
    return this.growthstageRepo.getGrowthStageConstants().pipe(
      catchError((error: GrowthstageError) => {
        const errorMessage = this.findErrorMessageByCode(error.errorCode);
        throw new Error(errorMessage);
      })
    );
  }

  public getGrowthStages(field: Field) {
    let errorMessage: string;

    const growthStageInput: GrowthStageInput = {
      fieldCropId: field.crops[0].id,
    };

    this.hasObservationsSubject.next(false);

    return this.growthstageRepo.getGrowthStages(growthStageInput).pipe(
      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      map((val: GrowthStageResult) => {
        if (val.growthStageListObservations.length > 0) {
          this.hasObservationsSubject.next(true);
        }
        this.growthStageResult = val;
        return val;
      }),
      catchError((error: GrowthstageError) => {
        errorMessage = this.findErrorMessageByCode(error.errorCode);
        throw new Error(errorMessage);
      })
    );
  }

  public hasObservations(): Observable<boolean> {
    return this.hasObservationsSubject.asObservable();
  }

  public setGrowthStageCorrection(field: Field, growthStageCorrections: GrowthStageCorrection[]) {
    let errorMessage: string;

    const growthStageCorrectionsInput: GrowthStageCorrectionsInput = {
      fieldCropId: field.crops[0].id,
      corrections: growthStageCorrections,
    };

    return this.growthstageRepo.setGrowthStageCorrections(growthStageCorrectionsInput).pipe(
      catchError((error: GrowthstageError) => {
        if (error) {
          errorMessage = this.findErrorMessageByCode(error.errorCode);
        } else {
        }
        errorMessage = this.findErrorMessageByCode(null);

        throw new Error(errorMessage);
      })
    );
  }

  public showInfoDialog(field: Field) {
    const helpText: string = this.findHelpTextByField(field);

    this.dialogService.openCustomDialog<GrowthStagesInfoComponent>(GrowthStagesInfoComponent, {
      data: {
        helpText,
      },
    });
  }

  public openCorrectionDialog(
    growthStageDay: GrowthStageDay | null,
    onlyEnableGrowthStageDayDate: boolean
  ): Observable<GrowthStageDay> | undefined {
    let minDate;
    let maxDate;
    let focusDate;

    if (onlyEnableGrowthStageDayDate && growthStageDay && growthStageDay.date) {
      minDate = DateTime.fromISO(growthStageDay.date);
      maxDate = DateTime.fromISO(growthStageDay.date);
    } else {
      if (this.growthStageResult && this.growthStageResult.growthStageListObservations) {
        const observationsDates = this.growthStageResult.growthStageListObservations.map((observations) =>
          DateTime.fromISO(observations.date!)
        );
        minDate = DateTime.min(...observationsDates); // EARLIEST
        maxDate = DateTime.max(...observationsDates); // LATEST
        focusDate = minDate;

        let now = DateTime.now();

        if (minDate < now && now < maxDate) {
          // IF NOW IS IN BETWEEN MIN AND MAX
          // use actual date
          focusDate = now;
        }
      }
    }

    return this.dialogService
      .openCustomDialog<GrowthstageCorrectionDialogComponent>(GrowthstageCorrectionDialogComponent, {
        panelClass: 'custom-dialog-container',
        width: 'auto',
        minWidth: '400px',
        data: {
          growthStageDay,
          minDate,
          maxDate,
          focusDate,
        },
      })
      .afterClosed();
  }

  private findErrorMessageByCode(errorCode?: GrowthstageErrorCodes | null) {
    if (errorCode) {
      switch (errorCode.toString()) {
        case GrowthstageErrorCodes.Error1114:
          return this.languageService.getText('growthStage.errors.outOfPeriod');
        case GrowthstageErrorCodes.NoFieldCrops:
          return this.languageService.getText('growthStage.errors.noFieldCrops');
        case GrowthstageErrorCodes.Error0:
        case GrowthstageErrorCodes.Error1118:
        case GrowthstageErrorCodes.Error5001:
        case GrowthstageErrorCodes.Error15001:
          return ''; // No error text for errors: the error is shown in Toast notification (handled in Http-Client)
        default:
          return this.languageService.getText('growthStage.errors.default');
      }
    }

    return this.languageService.getText('messages.common.internalServerError');
  }

  private findHelpTextByField(field: Field): string {
    if (field && field.crops) {
      switch (field.crops[0].cropNormNumber) {
        case CropNormNumbers.WinterWheat:
          return this.languageService.getText('growthStage.help.winterWheatText');
        case CropNormNumbers.SpringBarley:
          return this.languageService.getText('growthStage.help.springBarleyText');
        default:
          return this.languageService.getText('growthStage.help.noHelpTextForCrop');
      }
    }

    return this.languageService.getText('messages.common.errorCodes.500');
  }
}
