export enum SoilSampleParameterIds {
  None = 0,
  ReactionNumber = 1,
  PotassiumNumber = 3,
  CopperNumber = 4,
  MagnesiumNumber = 6,
  BoronNumber = 8,
  PhosphorNumber = 11,
  Texture = 14,
  Humus = 17,
  ClayPercentage = 22,
  NitrogenPercentage = 33,
  CarbonPercentage = 181,
  OrganicCarbon = 999, // Arbitrarily high number, because it is a faux property
}
