<div class="economy-wrapper">
  <ng-container *ngIf="showEconomy$ | async; else mobileNotAvailable">
    <div class="header-element">
      <div class="header">
        <h1 class="title">{{ 'main.economy.title' | translate }}</h1>
        <div class="filter">
          <div class="checkbox-container">
            <div class="source-checkbox-container">
              <mat-checkbox
                class="sources-checkbox"
                [(ngModel)]="showSources"
                (change)="showSourcesChange(showSources)"
                [disabled]="(disableSourcesCheckbox$ | async)!"
                >{{ 'main.economy.filter.source-checkbox' | translate }}</mat-checkbox
              >
              <div class="source"></div>
            </div>

            <mat-checkbox class="fieldscore-checkbox" [(ngModel)]="useFieldScore" (change)="useFieldScoreChange(useFieldScore)">{{
              'main.economy.filter.fieldscore-checkbox' | translate
            }}</mat-checkbox>
          </div>
          <div class="info-container">
            <app-tooltip-overlay size="sm" [tooltipTemplate]="sourcesTooltip" (click)="$event.stopPropagation()">
              <app-icon icon="info_outline" class="info-hover"> </app-icon>
            </app-tooltip-overlay>
            <ng-template #sourcesTooltip>
              <div class="tooltip-wrapper">
                <h3>{{ 'main.economy.filter.source-header' | translate }}</h3>
                <p>{{ 'main.economy.filter.source-text' | translate }}</p>
                <p *ngIf="disableSourcesCheckbox$ | async" class="tooltip-subscription-notice">
                  {{ 'main.economy.filter.source-disabled' | translate }}
                </p>
              </div>
            </ng-template>

            <app-tooltip-overlay size="sm" [tooltipTemplate]="fieldscoreTooltip" (click)="$event.stopPropagation()">
              <app-icon icon="info_outline" class="info-hover"> </app-icon>
            </app-tooltip-overlay>
            <ng-template #fieldscoreTooltip>
              <div class="tooltip-wrapper">
                <h3>{{ 'main.economy.filter.fieldscore-header' | translate }}</h3>
                <p>{{ 'main.economy.filter.fieldscore-text' | translate }}</p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <app-select width="60%" class="economy-harvest-year-picker" [ngModel]="selectedHarvestYear" (valueChange)="harvestYearChange($event)">
        <app-select-option *ngFor="let harvestYear of selectableHarvestYears$ | async" [value]="harvestYear">
          {{ 'main.economy.harvest-year.text' | translate | uppercase }} {{ harvestYear }}
        </app-select-option>
      </app-select>
    </div>
    <app-economy-table></app-economy-table>
  </ng-container>
</div>

<ng-template #mobileNotAvailable>
  <div class="no-mobile-wrapper">
    <div>{{ 'main.economy.no-mobile' | translate }}</div>
    <app-square-button buttonType="Primary" (clicked)="onShowEconomyClick()">
      {{ 'main.economy.no-mobile-continue' | translate }}
    </app-square-button>
  </div>
</ng-template>
