import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { LanguageService } from '@app/core/language/language.service';

@Component({
  selector: 'app-hotspot-subtype-select',
  templateUrl: './hotspot-subtype-select.component.html',
  styleUrls: ['./hotspot-subtype-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotspotSubtypeSelectComponent implements OnInit, OnChanges {
  @Input() public hotspotSubTypes: HotspotSubType[] = [];
  @Input() public selectedHotspotSubTypeIds?: number[] = [];
  @Output() public hotspotSubtypesChange = new EventEmitter<number[]>();
  public selectedHotspotSubTypes: HotspotSubType[] = [];

  public hotspotSubTypeIdsControl = this.fb.control(null);
  constructor(
    private fb: UntypedFormBuilder,
    private languageService: LanguageService
  ) {}

  public ngOnInit() {
    this.onSelectedHotspotIdsChange();
    this.setSubtypeNamesBasedOnLanguage();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const Ids = changes['selectedHotspotSubTypeIds'];

    if (Ids && Ids.currentValue !== Ids.previousValue) {
      this.onSelectedHotspotIdsChange();
    }
  }

  public onAddHotspotSubType(event: MatAutocompleteSelectedEvent): void {
    const selected = event.option.value as HotspotSubType;

    // if subtype is already added, just ignore it.
    if (!selected || this.selectedHotspotSubTypes.includes(selected)) {
      this.hotspotSubTypeIdsControl.setValue(null, { emitEvent: false });
      return;
    }

    this.hotspotSubTypeIdsControl.markAsDirty();
    this.selectedHotspotSubTypes.push(selected);
    this.hotspotSubTypeIdsControl.setValue(null, { emitEvent: false });
    this.hotspotSubtypesChange.emit(this.selectedHotspotSubTypes.map((type) => type!.id));
  }

  public onRemoveSubType(subType: HotspotSubType) {
    const index = this.selectedHotspotSubTypes.findIndex((type) => type?.id === subType.id);

    if (index === -1) {
      return;
    }

    this.hotspotSubTypeIdsControl.markAsDirty();
    this.selectedHotspotSubTypes.splice(index, 1);

    this.hotspotSubtypesChange.emit(this.selectedHotspotSubTypes.map((type) => type!.id));
  }

  /**
   * Display function passed to the autocomplete input.
   */
  public autoCompleteDisplay = (hotspotSubType: HotspotSubType) => (hotspotSubType ? hotspotSubType.name : '');

  public get isAnySubTypeSelected() {
    return this.selectedHotspotSubTypes.length > 0;
  }

  public trackByFn(index: number, value: HotspotSubType) {
    return value ? value.name : index;
  }

  private onSelectedHotspotIdsChange() {
    if (this.selectedHotspotSubTypeIds)
      this.selectedHotspotSubTypes = this.selectedHotspotSubTypeIds
        .map((id) => this.hotspotSubTypes.find((subType) => subType.id === id))
        .filter<HotspotSubType>((subType): subType is HotspotSubType => subType !== undefined);
  }

  private setSubtypeNamesBasedOnLanguage() {
    const currentLanguageShortKey = this.languageService.currentLanguage.shortKey;
    switch (currentLanguageShortKey) {
      case 'da':
        this.hotspotSubTypes = this.hotspotSubTypes.map((subType) => ({
          ...subType,
          name: subType.nameDa,
        }));
        break;
      case 'de':
        this.hotspotSubTypes = this.hotspotSubTypes.map((subType) => ({
          ...subType,
          name: subType.nameDe,
        }));
        break;
      default:
        this.hotspotSubTypes = this.hotspotSubTypes.map((subType) => ({
          ...subType,
          name: subType.nameEn,
        }));
        break;
    }
  }
}
