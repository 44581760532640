import { Component, OnInit } from '@angular/core';
import { PlotBandColors } from '@app/core/enums/plot-band-colors.enum';
import { CornField } from '@app/core/interfaces/corn-field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { filterNullish } from '@app/shared/operators';
import { SubscriptionArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { combineLatest, map } from 'rxjs';
import { concatMap, filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CornHarvestPrognosis } from '../repository/corn-harvest-prognosis.interface';
import { CornPrognosisService } from '../service/corn-prognosis.service';

export interface PrognosisTableRow {
  plotBandColor: PlotBandColors;
  fieldNumber: string;
  fieldArea: number;
  harvestDate?: DateTime;
}

@Component({
  selector: 'app-corn-harvest-prognosis-legend',
  templateUrl: './corn-harvest-prognosis-legend.component.html',
  styleUrls: ['./corn-harvest-prognosis-legend.component.scss'],
})
export class CornHarvestPrognosisLegendComponent implements OnInit {
  public _cornFields$ = this.getCornFields();
  protected language = this.languageService.currentLanguage.shortKey;
  public isOpen = true;
  public isLoading = false;

  public plotBandColors = PlotBandColors;

  private readonly _subs = new SubscriptionArray();
  private prognosesTableRows: PrognosisTableRow[] = [];

  constructor(
    private cornPrognosisService: CornPrognosisService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.cornPrognosisService.resetCornFieldsOnMap();
  }

  public onToggleLegend(): void {
    this.isOpen = !this.isOpen;
  }

  protected _calculatePrognoses$ = this._cornFields$.pipe(
    tap(() => (this.isLoading = true)),
    filterNullish(),
    mergeMap((cornFields) => cornFields),
    concatMap((cornField) => {
      this.isLoading = true;
      return this.getCornHarvestPrognosis(cornField).pipe(
        map((cornHarvestPrognosis) => {
          return { cornField, cornHarvestPrognosis };
        })
      );
    }),
    filterNullish(),
    map((fieldPrognosis) => {
      const harvestDate = this.findHarvestDate(fieldPrognosis.cornHarvestPrognosis);
      const plotBandColor = this.calculatePlotBandColor(fieldPrognosis.cornHarvestPrognosis);
      const tableRow = {
        plotBandColor: plotBandColor,
        fieldNumber: fieldPrognosis.cornField.number,
        fieldArea: fieldPrognosis.cornField.area,
        harvestDate: harvestDate && plotBandColor !== PlotBandColors.Error ? DateTime.fromISO(harvestDate) : undefined,
      };
      this.prognosesTableRows.push(tableRow);
      this.isLoading = false;
      fieldPrognosis.cornField.plotBandColor = tableRow.plotBandColor?.valueOf();
      fieldPrognosis.cornField.polygon = fieldPrognosis.cornField.polygon;
      this.cornPrognosisService.setCornFieldToUpdateMap(fieldPrognosis.cornField);
      return this.prognosesTableRows;
    })
  );

  private getCornFields() {
    return combineLatest([this.harvestYearStateService.harvestYear$, this.farmStateService.selectedFarms$]).pipe(
      filter(([harvestYear, farms]) => !!harvestYear && !!farms && !!farms.length),
      switchMap(([harvestYear, farms]) => {
        harvestYear = harvestYear as number;
        return this.cornPrognosisService.getCornFields(farms, harvestYear);
      })
    );
  }

  private getCornHarvestPrognosis(cornField: CornField) {
    return this.cornPrognosisService.getCornHarvestPrognosis(cornField);
  }

  // The function calculates the color of the plot band based on the dry matter prognosis for the given corn harvest prognosis.
  // This is done by finding the dry matter prognosis for today and checking if the value is below the max value for the red plot band.
  // If the value is below the max value for the red plot band, the color is red.
  // If the value is between the max value for the red plot band and the max value for the yellow plot band, the color is yellow.
  // If the value is above the max value for the yellow plot band, the color is green.
  private calculatePlotBandColor(cornHarvestPrognosis: CornHarvestPrognosis | null) {
    if (!cornHarvestPrognosis || !cornHarvestPrognosis.harvestPrognosis) {
      return PlotBandColors.Error;
    }
    const dryMatterPct = cornHarvestPrognosis.harvestPrognosis.find((prognosis) =>
      DateTime.fromISO(prognosis.harvestDate).toLocal()?.hasSame(DateTime.now(), 'day')
    )?.dryMatterPrognosis;
    const redMax = cornHarvestPrognosis.dryMatterRanges.find((range) => range.name === 'Lav tørstofprocent rød')?.max || 30;
    const yellowMax = cornHarvestPrognosis.dryMatterRanges.find((range) => range.name === 'Lav tørstofprocent gul')?.max || 31;

    if (!dryMatterPct) return PlotBandColors.Error;

    if (dryMatterPct < redMax) {
      return PlotBandColors.Red;
    } else if (dryMatterPct >= redMax && dryMatterPct < yellowMax) {
      return PlotBandColors.Yellow;
    }
    return PlotBandColors.Green;
  }

  // This function finds the harvest date for a corn harvest prognosis. This is done by finding the dry matter prognosis with the highest dry matter percentage, which is above the treshold for the yellow stage. Then the harvest date of this prognosis is returned.
  private findHarvestDate(cornHarvestPrognosis: CornHarvestPrognosis | null) {
    if (!cornHarvestPrognosis || !cornHarvestPrognosis.harvestPrognosis) {
      return undefined;
    }

    const yellowMax = cornHarvestPrognosis.dryMatterRanges.find((range) => range.name === 'Lav tørstofprocent gul')?.max || 31;
    return cornHarvestPrognosis.harvestPrognosis.find((prognosis) => prognosis.dryMatterPrognosis >= yellowMax)?.harvestDate;
  }
}
