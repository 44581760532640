import { Injectable, OnDestroy } from '@angular/core';
import {
  NitrogenNeedCalculateInputDto,
  NitrogenNeedInputDto,
  NitrogenNeedResultDto,
} from '@app/core/interfaces/n-tools/nitrogenNeed-TableDataDto';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NToolsTableRepositoryService } from '../n-tools-table/n-tools-table-repository.service';

@Injectable()
export class NToolsCalcService implements OnDestroy {
  private _tableData = new ReplaySubject<NitrogenNeedResultDto>(1);
  private _savedData = new ReplaySubject<NitrogenNeedResultDto>(1);
  private subscriptions = new Subscription();

  constructor(
    private nToolsTableRepositoryService: NToolsTableRepositoryService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService
  ) {
    this.subscriptions.add(this.loadFieldTableData(true));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public loadFieldTableData(useActualNeed: boolean, nitrogenNeedCalculateInputDto?: NitrogenNeedCalculateInputDto) {
    this.subscriptions.add(
      combineLatest([this.harvestYearStateService.harvestYear$, this.farmStateService.selectedFarmIds$])
        .pipe(
          switchMap(([harvestYear, farmIds]) =>
            this.nToolsTableRepositoryService.getNitrogenNeedCalculate(farmIds, harvestYear!, useActualNeed, nitrogenNeedCalculateInputDto!)
          )
        )
        .subscribe((tableData) => {
          this._tableData.next(tableData);
          return this._tableData.next(tableData);
        })
    );
  }

  public saveCropsNitrogenNeeds(nitrogenNeedInputDtos: NitrogenNeedInputDto[]) {
    this.subscriptions.add(
      combineLatest([this.harvestYearStateService.harvestYear$, this.farmStateService.selectedFarmIds$])
        .pipe(
          switchMap(([harvestYear, farmIds]) =>
            this.nToolsTableRepositoryService.saveCropsNitrogenNeed(farmIds, harvestYear!, nitrogenNeedInputDtos)
          )
        )
        .subscribe((savedData) => {
          this._savedData.next(savedData);
          return this._savedData.next(savedData);
        })
    );
  }

  /**
   * Return data
   */
  public get tableData$(): Observable<NitrogenNeedResultDto> {
    return this._tableData;
  }

  public get savedData$(): Observable<NitrogenNeedResultDto> {
    return this._savedData;
  }
}
