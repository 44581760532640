import { Directive, HostListener, Input } from '@angular/core';
import { CategoryTypes, GTagEventTrackerService } from './gTagEventTracker.service';

@Directive({
  selector: '[appGTagEventTracker]',
})
export class GTagEventTrackerDirective {
  @Input() eventName?: string;
  @Input() eventCategory?: CategoryTypes;

  constructor(private gTagEventTrackingService: GTagEventTrackerService) {}

  @HostListener('click', ['$event'])
  async onClick(event: any): Promise<void> {
    // Delegating the tracking event to the service
    await this.gTagEventTrackingService.trackEvent(this.eventName ?? 'unknown', this.eventCategory);
  }
}
