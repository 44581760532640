import { NgModule } from '@angular/core';
import { FieldAnalysisModule } from '@app/map/features/field-analysis/field-analysis.module';
import { BasisLayerSideDrawerModule } from '@app/new-map/features/basis-layer/basis-layer-side-drawer/basis-layer-side-drawer.module';
import { CultivationJournalModule } from '@app/new-map/features/cultivation-journal/cultivation-journal.module';
import { FieldPlanSideDrawerModule } from '@app/new-map/features/field-plan/field-plan-side-drawer/field-plan-side-drawer.module';
import { HotspotsSideDrawerModule } from '@app/new-map/features/hotspots/hotspot-side-drawer/hotspots-side-drawer.module';
import { PrognosisModule } from '@app/new-map/features/prognosis/prognosis.module';
import { SharedModule } from '@app/shared/shared.module';
import { SideDrawerBackdropComponent } from './side-drawer-backdrop/side-drawer-backdrop.component';
import { SideDrawerContentComponent } from './side-drawer-content/side-drawer-content.component';
import { SideDrawerOverlayComponent } from './side-drawer-overlay.component';

@NgModule({
  declarations: [SideDrawerOverlayComponent, SideDrawerBackdropComponent, SideDrawerContentComponent],
  imports: [
    SharedModule,
    FieldAnalysisModule,
    CultivationJournalModule,
    FieldPlanSideDrawerModule,
    HotspotsSideDrawerModule,
    BasisLayerSideDrawerModule,
    PrognosisModule,
  ],
  exports: [SideDrawerOverlayComponent],
})
export class SideDrawerOverlayModule {}
