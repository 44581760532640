import { Injectable } from '@angular/core';
import { ChartService } from '@app/core/chart/chart.service';
import { LanguageService } from '@app/core/language/language.service';
import { TDateISO } from '@app/core/types/iso-date.type';
import { getPlotBandColor } from '@app/helpers/plot-band-colors/get-plot-band-color.fn';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { CategoryAxis, PlotBand } from '@progress/kendo-angular-charts';
import { DateTime } from 'luxon';
import { map, switchMap } from 'rxjs/operators';
import { CornHarvestPrognosisRepoService } from '../repository/corn-harvest-prognosis-repo.service';
import { CornHarvestPrognosisSettings } from '../repository/corn-harvest-prognosis-settings.class';
import { CornHarvestPrognosis, DryMatterRange } from '../repository/corn-harvest-prognosis.interface';
import { CornHarvestPrognosisInfoComponent } from './corn-harvest-prognosis-info/corn-harvest-prognosis-info.component';
import { CornTypes } from './corn-types.enum';

@Injectable()
export class CornHarvestPrognosisChartService {
  constructor(
    private cornHarvestRepo: CornHarvestPrognosisRepoService,
    private languageService: LanguageService,
    private chartService: ChartService,
    private dialogService: DialogService
  ) {}

  public getPrognosisAndSettings(farmId: number, harvestYear: number, multiYearId: number) {
    return this.getSettings(farmId, harvestYear, multiYearId).pipe(
      switchMap((settings) =>
        this.getPrognosis(farmId, harvestYear, multiYearId, new CornHarvestPrognosisSettings(settings)).pipe(
          map((prognosis) => ({ prognosis, settings }))
        )
      ),
      map(({ prognosis, settings }) => ({
        prognosis,

        settings: new CornHarvestPrognosisSettings(settings),
      }))
    );
  }

  public getPrognosis(farmId: number, harvestYear: number, multiYearId: number, settings?: CornHarvestPrognosisSettings) {
    return this.cornHarvestRepo.getPrognosisWithSettings(farmId, harvestYear, multiYearId, settings);
  }

  public getSettings(farmId: number, harvestYear: number, multiYearId: number) {
    return this.cornHarvestRepo.getSettings(farmId, harvestYear, multiYearId);
  }

  /**
   * Maps corn harvest prognosis data to y-axis and x-axis values.
   * @param prognosis Corn harvest prognosis data model
   */
  public findAxesData(prognosis: CornHarvestPrognosis) {
    return prognosis.harvestPrognosis?.reduce(
      (dataSerie, dataPoint) => {
        dataSerie.xAxis.push(DateTime.fromISO(dataPoint.harvestDate));
        dataSerie.yAxis.push(parseFloat(dataPoint.dryMatterPrognosis?.toString()!).toFixed(2));

        return dataSerie;
      },
      { xAxis: [] as DateTime[], yAxis: [] as string[] }
    );
  }

  public findYAxisTitle(prognosis: CornHarvestPrognosis) {
    switch (prognosis.standardCrop) {
      case CornTypes.GrainMaize:
        return this.languageService.getText('cornHarvestPrognosis.waterPct');
      default:
        return this.languageService.getText('cornHarvestPrognosis.dryMatterPct');
    }
  }

  public findTooltipTitle(prognosis: CornHarvestPrognosis) {
    switch (prognosis.standardCrop) {
      case CornTypes.GrainMaize:
        return this.languageService.getText('cornHarvestPrognosis.water');
      default:
        return this.languageService.getText('cornHarvestPrognosis.dryMatter');
    }
  }

  /**
   * Finds the number of label steps for the x axis.
   * Currently based on the screen size.
   * @param screenSize
   * @param aggregatedData Array of x-axis values
   * @param categoryAxis Kendo Charts category axis model
   */
  public findXAxisSteps(screenSize: number, aggregatedData: DateTime[], categoryAxis: CategoryAxis) {
    return this.chartService.calculateNumberOfStepsByScreenSize<DateTime>(screenSize, aggregatedData, categoryAxis);
  }

  /**
   * Returns a plot band array matching the Kendo Charts plotband model.
   * @param prognosis The corn harvest prognosis model
   */
  public findYAxisPlotBands(prognosis: CornHarvestPrognosis): PlotBand[] {
    return prognosis.dryMatterRanges.map(
      (range) =>
        ({
          color: getPlotBandColor(range.color),
          from: range.min,
          to: range.max,
          opacity: 0.8,
        }) as PlotBand
    );
  }

  public findFirstHarvestDate(prognosis: CornHarvestPrognosis): TDateISO {
    const firstHarvest = this.findFirstHarvest(prognosis);

    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return firstHarvest ? firstHarvest.harvestDate : null;
  }

  public findYAxisMaxValue(dryMatterRanges: DryMatterRange[]) {
    return dryMatterRanges.reduce((max, current) => (current.max! > max ? current.max! : max), 0);
  }

  public findYAxisMinValue(dryMatterRanges: DryMatterRange[]) {
    return dryMatterRanges.reduce((min, current) => (current.min! < min ? current.min! : min), 100);
  }

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public createLine(date: DateTime, chart, totalMin: number, totalMax: number) {
    return this.chartService.createLine(date, chart, totalMin, totalMax);
  }

  public createLineLabel(line: any, date: DateTime, prognosis: CornHarvestPrognosis) {
    const firstHarvest = this.findFirstHarvest(prognosis);
    const labelText = this.findLineLabelText(prognosis);
    const dateString = date.toFormat('dd-MM-yyyy');

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.chartService.createLineLabel(`${dateString}: ${firstHarvest.dryMatterPrognosis.toFixed()}% ${labelText}`, line);
  }

  public showInfoDialog(prognosis: CornHarvestPrognosis) {
    this.dialogService.openCustomDialog(CornHarvestPrognosisInfoComponent, {
      data: prognosis,
    });
  }

  private findLineLabelText(prognosis: CornHarvestPrognosis) {
    switch (prognosis.standardCrop) {
      case CornTypes.GrainMaize:
        return this.languageService.getText('cornHarvestPrognosis.water');
      default:
        return this.languageService.getText('cornHarvestPrognosis.dryMatter');
    }
  }

  private findFirstHarvest(prognosis: CornHarvestPrognosis) {
    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return prognosis.harvestPrognosis.find((dataPoint) => {
      switch (prognosis.standardCrop) {
        case CornTypes.CobCorn:
          // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          return dataPoint.dryMatterPrognosis >= 54;
        case CornTypes.GrainMaize:
          // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          return dataPoint.dryMatterPrognosis >= 38 && dataPoint.dryMatterPrognosis <= 40;
        default:
          // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          return dataPoint.dryMatterPrognosis >= 31 && dataPoint.dryMatterPrognosis <= 33;
      }
    });
  }
}
