export enum WfsLayerType {
  Nature = 1,
  Watercourses = 2,
  ThreeMeterBuffer = 3,
  GLMAncientMonuments = 4,
  GLMLakes = 5,
  ProtectedStoneAndEarth = 6,
  FieldShrubbery = 7,
  BNBO = 8,
  Height = 9,
}

export class WfsUrlRoots {
  static readonly fvm = 'https://geodata.fvm.dk/geoserver';
  static readonly geo = 'https://b0902-prod-dist-app.azurewebsites.net/geoserver';
}
