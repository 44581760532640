import { Observable, pipe, UnaryFunction } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Splits an array into chunks of the given size.
 * @param size {number} The size of the chunks.
 */
export function segment<T>(size: number): UnaryFunction<Observable<T[]>, Observable<T[][]>> {
  return pipe(
    map((array) => {
      const chunks = [];

      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }

      return chunks;
    })
  );
}
