<div class="header-close">
  <h4>{{ 'main.potatoBlight.info.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <p>{{ 'main.potatoBlight.info.section1' | translate }}</p>
  <p>{{ 'main.potatoBlight.info.section2' | translate }}</p>
  <p>{{ 'main.potatoBlight.info.section3' | translate }}</p>
  <ul class="browser-default">
    <li>{{ 'main.potatoBlight.info.item1' | translate }}</li>
    <li>
      {{ 'main.potatoBlight.info.item2' | translate }}
    </li>
    <li>{{ 'main.potatoBlight.info.item3' | translate }}</li>
    <li>{{ 'main.potatoBlight.info.item4' | translate }}</li>
  </ul>
  <ul class="browser-default">
    <li>{{ 'main.potatoBlight.info.item5' | translate }}</li>
    <li>{{ 'main.potatoBlight.info.item6' | translate }}</li>
    <li>{{ 'main.potatoBlight.info.item7' | translate }}</li>
  </ul>

  <p>{{ 'main.potatoBlight.info.section4' | translate }}</p>
  <p>
    {{ 'main.potatoBlight.info.section5' | translate }}
    <a href="{{ 'main.potatoBlight.info.link' | translate }}" target="_blank" rel="noopener noreferrer">{{
      'main.potatoBlight.info.section5LinkText' | translate
    }}</a>
  </p>
  <p class="disclaimer">{{ 'main.potatoBlight.info.disclaimer' | translate }}</p>
</mat-dialog-content>
