import { Injectable } from '@angular/core';
import isArray from 'lodash-es/isArray';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';
import values from 'lodash-es/values';
import { DateInput, DateTime } from 'luxon';

const YEAR_FORMATS: string[] = ['YYYY'];

const MONTH_FORMATS: string[] = ['MM-YYYY', 'MM/YYYY', 'YYYY-MM', 'YYYY/MM'];

const SPECIFIC_DATE_FORMATS: string[] = ['DD-MM-YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'];

@Injectable()
export class SearchService {
  public fulltextSearch<TDataType extends DateInput>(searchTerm: string, objs: TDataType[]) {
    return !isString(searchTerm) || searchTerm.length === 0
      ? objs
      : objs.filter((obj) => this.fulltextObjectMatch<TDataType>(searchTerm, obj));
  }

  public fulltextObjectMatch<TDataType>(searchTerm: string, obj: TDataType): boolean {
    return values(obj)
      .filter((value) => value !== undefined && value !== null)
      .some((value: any) => this.valueMatchesSearchTerm(this.normalizeSearchTerm(searchTerm), value));
  }

  private valueMatchesSearchTerm<TValueType extends DateInput>(searchTerm: string, value: TValueType): boolean {
    if (isArray(value)) {
      return this.fulltextSearch(searchTerm, value).length > 0;
    }

    if (isObject(value)) {
      return this.fulltextObjectMatch<TValueType>(searchTerm, value);
    }

    return new RegExp(searchTerm, 'g').test((value! as any).toString().toLowerCase()) || this.isSameDateMatch(value, searchTerm);
  }

  private normalizeSearchTerm(searchTerm: string) {
    if (!isString(searchTerm)) {
      return '';
    }

    return searchTerm.toLowerCase();
  }

  private isSameDateMatch<TValueType extends { toString(): string }>(value: TValueType, searchTerm: string) {
    const valueAsDateTime = DateTime.fromISO(value.toString());

    if (!valueAsDateTime.isValid) {
      return false;
    }

    if (searchTerm.length === 4 && /[0-9]{4}/gm.test(searchTerm)) {
      return valueAsDateTime.year === Number(searchTerm);
    }

    if (searchTerm.length === 7 && this.specificMonthRegex(searchTerm)) {
      const searchTermAsMonth = DateTime.fromObject({ month: Number(searchTerm) });

      return valueAsDateTime.month === searchTermAsMonth.month;
    }

    const searchTermAsDateTime = DateTime.fromISO(searchTerm);

    if (!searchTermAsDateTime.isValid) {
      return false;
    }

    return true;
  }

  private specificMonthRegex(searchTerm: string) {
    return (
      /[0-9]{4}-[0-3]{1}[0-9]{1}/gm.test(searchTerm) || // YYYY-MM
      /[0-9]{4}\/[0-3]{1}[0-9]{1}/gm.test(searchTerm) || // YYYY/MM
      /[0-3]{1}[0-9]{1}-[0-9]{4}/gm.test(searchTerm) || // MM-YYYY
      /[0-3]{1}[0-9]{1}\/[0-9]{4}/gm.test(searchTerm) // MM/YYYY
    );
  }
}
