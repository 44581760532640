import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FieldCopyCompleteDialogComponent } from '../field-copy/field-copy-complete-dialog/field-copy-complete-dialog.component';
import { FieldCopyDialogComponent } from '../field-copy/field-copy-dialog/field-copy-dialog.component';
import { FieldPlanService } from '../field-plan.service';
import { FieldModifyComponent } from './../field-copy/field-modify/field-modify.component';
import { FieldItemComponent } from './../field-copy/field-select/field-item/field-item.component';
import { FieldSelectComponent } from './../field-copy/field-select/field-select.component';
import { ChangeCropModalComponent } from './field-plan-content/create-field-form/change-crop-modal/change-crop-modal.component';
import { CreateFieldFormComponent } from './field-plan-content/create-field-form/create-field-form.component';
import { DeleteFieldModalComponent } from './field-plan-content/create-field-form/delete-field-modal/delete-field-modal.component';
import { DeleteCropTaskComponent } from './field-plan-content/create-field-form/DeleteCropTask/DeleteCropTask.component';
import { FieldNumberInputComponent } from './field-plan-content/create-field-form/field-number-input/field-number-input.component';
import { CreateFieldComponent } from './field-plan-content/create-field/create-field.component';
import { FieldAdministrationComponent } from './field-plan-content/field-administration/field-administration.component';
import { FieldListComponent } from './field-plan-content/field-administration/field-list/field-list.component';
import { FieldPlanContentComponent } from './field-plan-content/field-plan-content.component';
import { FieldPlanMapSideDrawerActions } from './field-plan-map-side-drawer-actions.service';
import { FieldPlanSideDrawerComponent } from './field-plan-side-drawer.component';
import { FieldPlanSideDrawerService } from './field-plan-side-drawer.service';
import { ExportFieldsComponent } from './field-plan-content/export-fields/export-fields.component';
import { ExportFieldsListComponent } from './field-plan-content/export-fields/export-fields-list/export-fields-list.component';

@NgModule({
  declarations: [
    FieldPlanSideDrawerComponent,
    CreateFieldComponent,
    FieldAdministrationComponent,
    CreateFieldFormComponent,
    FieldNumberInputComponent,
    DeleteFieldModalComponent,
    DeleteCropTaskComponent,
    ChangeCropModalComponent,
    FieldPlanContentComponent,
    FieldListComponent,
    FieldCopyDialogComponent,
    FieldSelectComponent,
    FieldModifyComponent,
    FieldItemComponent,
    FieldCopyCompleteDialogComponent,
    ExportFieldsComponent,
    ExportFieldsListComponent,
  ],
  imports: [SharedModule, GridModule, InputsModule, DropDownListModule, PopupModule, ButtonsModule],
  exports: [FieldPlanSideDrawerComponent],
})
export class FieldPlanSideDrawerModule {
  public static forRoot(): ModuleWithProviders<FieldPlanSideDrawerModule> {
    return {
      ngModule: FieldPlanSideDrawerModule,
      providers: [FieldPlanMapSideDrawerActions, FieldPlanService, FieldPlanSideDrawerService],
    };
  }
}
