<div *ngIf="(imageFeatures$ | async) && (imagesCount$ | async)! > 0">
  <ng-container *ngIf="(imageFeatures$ | async)?.length! > 0; else noFields">
    <br /><br />
    <p>
      {{ imagesCount$ | async }}
      {{ 'main.fieldmap.droneImageImport.imagesOnFields' | translate }}
    </p>

    <div class="feature-image-list" *ngIf="(selectedImageFeatures$ | async)?.length! > 0">
      <h3>{{ 'main.fieldmap.droneImageImport.fieldForStitching' | translate }}</h3>
      <div *ngFor="let imageFeature of selectedImageFeatures$ | async" class="field-image-item" [@flyInOut]="'in'">
        <div>
          {{ imageFeature.number }}
        </div>
        <div>({{ imageFeature.area | number: '1.0-2' }} {{ 'common.hectareShort' | translate }})</div>
        <div>{{ imageFeature.imagesForField.length }} {{ 'common.images' | translate }}</div>
        <div class="add-remove-button">
          <app-remove-button (clicked)="remove(imageFeature)"></app-remove-button>
        </div>
      </div>
    </div>

    <div class="feature-image-list" *ngIf="(deSelectedImageFeatures$ | async)!.length! > 0">
      <h3>{{ 'main.fieldmap.droneImageImport.deselectedFields' | translate }}</h3>
      <ng-container *ngFor="let imageFeature of deSelectedImageFeatures$ | async; let first = first">
        <div
          *ngIf="imageFeature.imagesForField.length >= 15; else disabledImageFeature"
          class="field-image-item"
          [class.first]="first"
          [@flyInOut]="'out'"
        >
          <div>
            {{ imageFeature.number }}
          </div>
          <div>({{ imageFeature.area | number: '1.0-2' }} {{ 'common.hectareShort' | translate }})</div>
          <div [style]="imageFeature.imagesForField.length < 15 ? 'error-color' : ''">
            {{ imageFeature.imagesForField.length }} {{ 'common.images' | translate }}
          </div>
          <div class="add-remove-button">
            <app-add-button
              [disabled]="imageFeature.imagesForField.length < 15"
              size="sm"
              [invert]="true"
              color="#969696"
              (clicked)="add(imageFeature)"
            ></app-add-button>
          </div>
        </div>

        <ng-template #disabledImageFeature>
          <app-tooltip
            class="tooltip"
            tooltipText="{{ 'main.fieldmap.droneImageImport.minImagesOnField' | translate }}"
            [delayType]="'long'"
          >
            <div class="field-image-item disabled-field-image" [class.first]="first" [@flyInOut]="'out'">
              <div>
                {{ imageFeature.number }}
              </div>
              <div>({{ imageFeature.area | number: '1.0-2' }} {{ 'common.hectareShort' | translate }})</div>
              <div class="error-color">{{ imageFeature.imagesForField.length }} {{ 'common.images' | translate }}</div>
              <div class="add-remove-button disabled-button">
                <app-add-button [disabled]="true" size="sm" [invert]="true" color="#969696"></app-add-button>
              </div>
            </div>
          </app-tooltip>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noFields>
    <ng-container *ngIf="!(loading$ | async); else loading">
      <p class="error-color">{{ 'main.fieldmap.droneImageImport.noFields' | translate }}</p>
    </ng-container>
  </ng-template>

  <ng-template #loading>
    <app-spinner></app-spinner>
  </ng-template>
</div>
