import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { UserStateService } from '@app/state/services/user/user-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard {
  private routeLevel!: SubscriptionLevel;
  private missingSubscriptionRedirect!: string;

  constructor(
    private router: Router,
    private userStateService: UserStateService
  ) {
    this.userStateService.subscriptionLevel$.subscribe((subscriptionLevel) => {
      if (subscriptionLevel && this.routeLevel > subscriptionLevel) {
        this.router.navigateByUrl(this.missingSubscriptionRedirect).catch((err) => console.error(err));
      }
    });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivateRoute(route);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivateRoute(childRoute);
  }

  private canActivateRoute(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.routeLevel = route.data['subscription'] ?? SubscriptionLevel.FREE;
    this.missingSubscriptionRedirect = route.data['missingSubscriptionRedirect'];

    return this.userStateService.subscriptionLevel$.pipe(map((subscriptionLevel) => subscriptionLevel! >= this.routeLevel));
  }
}
