<div class="water-balance-dialog-wrapper">
  <div class="header">
    <div class="title">
      <b>{{ 'waterBalance.titleBold' | translate }}</b
      ><span>{{ 'waterBalance.titleNormal' | translate }}</span>
    </div>
    <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
  </div>
  <app-water-balance [field]="data"></app-water-balance>
</div>
