import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Module } from '@app/core/types/module.type';
import { TypedActivatedRouteSnapshot, TypedRoute, TypedRoutes } from '@app/core/types/route.type';
import { isExclusivelyTruthy } from '@app/shared/utils/utils';
import { of } from 'rxjs';
import { Resource } from '../resources';
import { AccessControlService } from '../services/access-control.service';

export interface AccessControlRouteData {
  module?: Module;
  resource?: Resource;
  resources?: Resource[];
}

export type AccessControlRoute = TypedRoute<AccessControlRouteData>;
export type AccessControlRoutes = TypedRoutes<AccessControlRouteData>;

/**
 * Guard that checks if the user has access to the resources required for the route
 *
 * The guard can be used in 3 ways:
 * 1. By specifying the module property in the route data
 * 2. By specifying the resource property in the route data
 * 3. By specifying the resources property in the route data
 *
 * The guard will throw an error if more than one of the properties is specified
 *
 * ! Using access control guard currently can cause issues with query params on initial load
 * ! Query params are used to get the farm id from the url which gets the subscriptions
 */
@Injectable({
  providedIn: 'root',
})
export class AccessControlGuard {
  constructor(private service: AccessControlService) {}

  canActivate(route: TypedActivatedRouteSnapshot<AccessControlRouteData>, _state: RouterStateSnapshot) {
    const module = route.data['module'];
    const resource = route.data['resource'];
    const resources = route.data['resources'];

    const noData = !module && !resource && !resources;
    const moreThanOneData = isExclusivelyTruthy(module, resource, resources) === false;

    if (noData) throw new Error('Required route data is not defined. Missing one data property @see AccessControlRouteData');
    if (moreThanOneData)
      throw new Error('More than one route data property is defined. Only one data property is allowed @see AccessControlRouteData');

    // type casting since RESOURCE[module] is infered as its literal type and not as Resource
    // when TS 4.9 is upgraded the `satisfies` keyword can be used and the type will be Resource
    // if (module) return this.service.hasAccessTo(RESOURCES[module] as Readonly<Resource[]> as Resource[], 'ANY');

    // return this.service.hasAccessTo(resource ?? resources);

    // ! AccessControlService does not emit on initial page load causing guard to halt
    // ! This is caused by query params state on initial load which is used to determine subscriptions
    // ! Disabling for now
    return of(true);
  }
}
