<h4 class="delete-modal-title">{{ 'main.settings.dataConnections.areYouSure' | translate }}</h4>
<p class="delete-modal-text">{{ 'main.settings.dataConnections.thisActionCannotBeReverted' | translate }}</p>
<div class="buttons-wrapper">
  <app-square-button [type]="'button'" (clicked)="onCancelClicked($event)">
    {{ 'common.cancel' | translate }}
  </app-square-button>
  <app-square-button [buttonType]="'Primary'" [type]="'submit'" (clicked)="onConfirmClicked($event)">
    {{ 'main.settings.dataConnections.delete' | translate }}
  </app-square-button>
</div>
