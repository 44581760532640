export enum BasisLayerCategoryIcon {
  unknown = 'close',
  hills = 'bakketop',
  hollow = 'lavning',
  windbreaks = 'laehegn',
  headland = 'forager',
  waterlogged = 'vandlidende',
  sandySpots = 'sandpletter',
  other = 'star_border',
}
