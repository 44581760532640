<h5 class="operation-title">{{ operation.operationTypeGroup?.name }}</h5>
<app-spinner *ngIf="(loading$ | async)!.isLoading" [message]="(loading$ | async)!.message"></app-spinner>
<form [formGroup]="operationForm!" *ngIf="!(loading$ | async)!.isLoading">
  <div *ngFor="let opLineForm of operationLinesFormArray?.controls">
    <app-operation-line
      [disabled]="disabled || !canWrite"
      (remove)="onRemoveOperationLine($any($event))"
      [operationLineForm]="opLineForm"
      [tooltipPosition]="removeTooltipPosition"
      [tooltipText]="removeTooltipText"
      [produceNormGroups]="produceNormGroups"
      [task]="task"
    ></app-operation-line>
  </div>
  <div class="add-btn" *ngIf="!disabled && canWrite">
    <app-add-button
      size="sm"
      [toolTipDelay]="'long'"
      [tooltipText]="addTooltipText"
      [tooltipPosition]="addTooltipPosition"
      [invert]="true"
      (clicked)="onAddClick()"
    ></app-add-button>
  </div>
</form>

<!--
<div class="qualities" *ngIf="showQualityParameters">
  <div class="qualities-label">{{'editOperations.qualityParameters' | translate}}</div>
  <ng-container *ngFor="let operationData of operationData$ | async">
    <div *ngIf="operationData.hasOperationLines && operationData.hasQualityParameters" class="card mat-elevation-z2">
      <div class="quality-label">{{operationData.name}}</div>
      <ng-container *ngFor="let operationLine of operationData.operationLines">
        <app-edit-operation-line-quality-parameters [operationIndex]="operationData.index" [operationLineIndex]="operationLine.index"
          *ngIf="operationLine.qualityParametersAllowed"></app-edit-operation-line-quality-parameters>
      </ng-container>
    </div>
  </ng-container>
</div>

<div class="economies" *ngIf="(taskHasOperationLines$ | async)">
  <div class="economies-label">
    <div class="operations-title">{{'editOperations.economy' | translate}}</div>
    <div class="total-price">{{pricePerHectare$ | async}} {{'common.unitType.kr' | translate}}/{{'common.hectareShort' | translate}}</div>
  </div>
  <ng-container *ngFor="let operationData of operationData$ | async">
    <div class="card mat-elevation-z2">
      <div class="economy-label">{{operationData.name}}</div>
      <ng-container *ngFor="let operationLine of operationData.operationLines">
        <app-edit-operation-economy [operationIndex]="operationData.index" [operationLineIndex]="operationLine.index">
        </app-edit-operation-economy>
      </ng-container>
    </div>
  </ng-container>
</div> -->
