<div
  class="legend"
  [ngClass]="{ open: isLegendVisible }"
  [style.width.px]="width"
  [style.height.px]="height"
  [style.right]="sideDrawerWidth"
>
  <div class="toggle-legend-btn" id="e2e-map-legend" data-testid="map-legend" (click)="onToggleLegend()">
    <app-icon class="big" *ngIf="!isLegendVisible" icon="info_outline"></app-icon>
    <app-icon class="big" *ngIf="isLegendVisible" icon="highlight_off"></app-icon>
  </div>
  <div *ngIf="isLegendVisible" class="scroll-y">
    <ng-content></ng-content>
  </div>
</div>
