<div class="wrapper">
  <app-tooltip [position]="'left'" [tooltipText]="'main.growthRegulation.picker.previousDate' | translate">
    <app-icon
      class="btn-wrapper last"
      data-testid="satellite-nav-prev"
      [ngClass]="{ disabled: currentIndex < 1, 'waves-effect': currentIndex >= 1 }"
      (click)="previousDate()"
      icon="navigate_before"
    ></app-icon>
  </app-tooltip>

  <div class="text-wrapper">
    <app-icon icon="satellite"></app-icon>
    <span>{{ currentDate | dateFormat }}</span>
  </div>

  <app-tooltip [position]="'right'" [tooltipText]="'main.growthRegulation.picker.nextDate' | translate">
    <app-icon
      class="next btn-wrapper"
      [ngClass]="{
        disabled: currentIndex >= availableImageDates!.length - 1,
        'waves-effect': currentIndex < availableImageDates!.length - 1
      }"
      (click)="nextDate()"
      icon="navigate_next"
    ></app-icon>
  </app-tooltip>
</div>
