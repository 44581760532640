import { NgModule } from '@angular/core';
import { CultivationJournalStateDispatchers } from '@app/new-map/features/cultivation-journal/cultivation-journal-state-dispatchers.service';
import { SharedModule } from '@app/shared/shared.module';
import { CultivationJournalRoutingModule } from './cultivation-journal-routing.module';
import { CultivationJournalComponent } from './cultivation-journal.component';
import { CultivationJournalMapFeatureModule } from './map-features/cultivation-journal-map-feature.module';

@NgModule({
  imports: [CultivationJournalMapFeatureModule, SharedModule, CultivationJournalRoutingModule],
  declarations: [CultivationJournalComponent],
  providers: [CultivationJournalStateDispatchers],
})
export class CultivationJournalModule {}
