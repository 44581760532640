import { Injectable } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { StorageService } from '@app/core/storage/storage.service';
import { BehaviorSubject } from 'rxjs';

export interface ISessionService {
  fieldMapZoom: number;
  fieldMapCenter: [number, number];
  fieldInspectorMapZoom: number;
  fieldInspectorMapCenter: [number, number];
  hasSavedFieldPlanPosition(): boolean;
  hasSavedFieldInspectorMapPosition(): boolean;
}

/**
 * SessionService
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService implements ISessionService {
  public static farmsKey = 'selectedFarms';

  public farmsObservable!: BehaviorSubject<Farm[]>;

  constructor(private storageService: StorageService) {
    this.init();
  }

  public init(): void {
    const storedFarms = this.storageService.getFromStorage<Farm[]>(SessionService.farmsKey);
    this.farmsObservable = new BehaviorSubject<Farm[]>(storedFarms || []);
  }

  public hasSavedFieldPlanPosition(): boolean {
    const isSet =
      this.fieldMapCenter !== undefined && this.fieldMapCenter !== null && this.fieldMapZoom !== undefined && this.fieldMapZoom !== null;
    return isSet;
  }

  public hasSavedFieldInspectorMapPosition(): boolean {
    const isSet =
      this.fieldInspectorMapCenter !== undefined &&
      this.fieldInspectorMapCenter !== null &&
      this.fieldInspectorMapZoom !== undefined &&
      this.fieldInspectorMapZoom !== null;
    return isSet;
  }

  set fieldMapZoom(zoom: number) {
    this.storageService.saveToStorage('fieldMapZoom', zoom);
  }

  get fieldMapZoom(): number {
    return this.storageService.getFromStorage('fieldMapZoom');
  }

  set fieldMapCenter(center: [number, number]) {
    this.storageService.saveToStorage('fieldMapCenter', center);
  }

  get fieldMapCenter(): [number, number] {
    return this.storageService.getFromStorage('fieldMapCenter');
  }

  set fieldInspectorMapZoom(zoom: number) {
    this.storageService.saveToStorage('fieldInspectorMapZoom', zoom);
  }

  get fieldInspectorMapZoom(): number {
    return this.storageService.getFromStorage('fieldInspectorMapZoom');
  }

  set fieldInspectorMapCenter(center: [number, number]) {
    this.storageService.saveToStorage('fieldInspectorMapCenter', center);
  }

  get fieldInspectorMapCenter(): [number, number] {
    return this.storageService.getFromStorage('fieldInspectorMapCenter');
  }
}
