import { Injectable } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HarvestYear } from './harvest-year';

@Injectable({
  providedIn: 'root',
})
export class HarvestYearStateService {
  private _harvestYearSubject = new BehaviorSubject<number | undefined>(undefined);

  public get harvestYear$() {
    return this._harvestYearSubject.asObservable();
  }

  public set harvestYear(harvestYear: number) {
    if (harvestYear >= HarvestYear.HARVEST_YEAR_START && harvestYear <= HarvestYear.HARVEST_YEAR_END) {
      this._harvestYearSubject.next(harvestYear);
    } else {
      this.setHarvestYearToCurrent();
    }
  }

  public readonly isCurrentHarvestYear$ = this.harvestYear$.pipe(map((selectedYear) => this.isEqualToCurrentHarvestYear(selectedYear)));

  /**
   * The current harvest year
   * NOT THE CURRENTLY SELECTED HARVEST YEAR
   */
  public setHarvestYearToCurrent() {
    this.harvestYear = HarvestYear.currentHarvestYear;
  }

  public constructor() {}

  public isEqualToCurrentHarvestYear(harvestYear?: number): boolean {
    const currentDate = DateTime.now();
    const then = DateTime.fromObject({ day: 1, month: Month.August });

    if (currentDate < then && currentDate.year === harvestYear) {
      return true;
    }

    return currentDate >= then && currentDate.year + 1 === harvestYear;
  }

  public isDateInHarvestYear(date: DateTime, harvestYear: number | null) {
    const then = DateTime.fromObject({ day: 1, month: Month.August, year: date.year });

    if (date < then && date.year === harvestYear) {
      return true;
    }

    return date >= then && date.year + 1 === harvestYear;
  }
}
