import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cell-error-container',
  templateUrl: './cell-error-container.component.html',
  styleUrls: ['./cell-error-container.component.scss'],
})
export class CellErrorContainerComponent {
  @Input() public errors: string[] = [];
}
