export class ArrayHelper {
  public static areArraysEqual(oldArray: any[], newArray: any[]): boolean {
    return oldArray.length === newArray.length && oldArray.every((v, i) => v === newArray[i]);
  }

  public static unique<T>(inputArray: T[]): T[] {
    return Array.from(new Set(inputArray));
  }

  /**
   * Returns the element with the largest property value
   * @param array - Needs to be defined and not empty, otherwise undefined is returned
   * @param propertyName - the name of the property to evaluate by. Needs to exist on every element in the given array.
   * Example:
   *  Input: array = [{a: 3, b:4}, {a: 2, b:8}], propertyName = 'a'
   *  Output: {a:3, b:4}
   * @param printErrors if false, errors are not printed to the console
   */
  public static getElementWithLargestProperty<T extends Record<string, any>>(
    array: T[],
    propertyName: string,
    printErrors = true
  ): T | undefined {
    // Invalid array
    if (!array || array.length === 0) {
      if (printErrors) {
        // eslint-disable-next-line no-console
        console.error('Invalid array. Array should be defined and not empty', array);
      }

      return undefined;
    }
    // Invalid property name
    if (!propertyName || propertyName === '') {
      if (printErrors) {
        // eslint-disable-next-line no-console
        console.error('Invalid property name. Property name should be defined and not empty.', propertyName);
      }

      return undefined;
    }

    // Elements missing property
    // propertyName in element is used instead of element.hasOwnProperty(propertyName) so it can handle inherited properties
    if (!array.every((element) => propertyName in element)) {
      if (printErrors) {
        // eslint-disable-next-line no-console
        console.error('Invalid property name. Every elements needs to have a property with the given name:', propertyName, array);
      }

      return undefined;
    }

    return array.reduce((max, element) => (element[propertyName] > max[propertyName] ? element : max));
  }

  // From https://stackoverflow.com/a/46700791
  public static notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false;
    return true;
  }
}
