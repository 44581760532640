<div class="main-wrapper">
  <form class="content-form" novalidate [formGroup]="insuranceBuyerFormGroup" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <app-input
      type="text"
      formControlFarm="farmName"
      [formControl]="$any(insuranceBuyerFormGroup.get('farmName'))"
      [placeholder]="'main.growthRegulation.insurance.checkOutForm.farm.name' | translate"
    >
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('farmName')?.hasError('required')">{{
        'main.growthRegulation.insurance.checkOutForm.required' | translate
      }}</app-input-error>
    </app-input>
    <app-input
      type="text"
      formControlName="farmAddress"
      [formControl]="$any(insuranceBuyerFormGroup.get('farmAddress'))"
      [placeholder]="'main.growthRegulation.insurance.checkOutForm.address.name' | translate"
    >
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('farmAddress')?.hasError('required')">{{
        'main.growthRegulation.insurance.checkOutForm.required' | translate
      }}</app-input-error>
    </app-input>
    <app-input type="text" formControlName="cvrNumber" [placeholder]="'main.growthRegulation.insurance.checkOutForm.cvr.name' | translate">
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('cvrNumber')?.hasError('hasWhitespace')">{{
        'main.growthRegulation.insurance.checkOutForm.cvr.hasWhitespace' | translate
      }}</app-input-error>
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('cvrNumber')?.hasError('isNotInteger')">{{
        'main.growthRegulation.insurance.checkOutForm.cvr.isNotInteger' | translate
      }}</app-input-error>
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('cvrNumber')?.hasError('minlength')"
        >{{ 'main.growthRegulation.insurance.checkOutForm.cvr.minlength' | translate }}
      </app-input-error>
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('cvrNumber')?.hasError('maxlength')"
        >{{ 'main.growthRegulation.insurance.checkOutForm.cvr.maxlength' | translate }}
      </app-input-error>
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('cvrNumber')?.hasError('required')"
        >{{ 'main.growthRegulation.insurance.checkOutForm.required' | translate }}
      </app-input-error>
      <app-input-error *ngIf="insuranceBuyerFormGroup.get('cvrNumber')?.hasError('min')"
        >{{ 'main.growthRegulation.insurance.checkOutForm.cvr.minValue' | translate }}
      </app-input-error>
    </app-input>
    <app-input
      type="email"
      formControlName="email"
      [formControl]="$any(insuranceBuyerFormGroup.get('email'))"
      [placeholder]="'main.growthRegulation.insurance.checkOutForm.email.name' | translate"
    >
      <app-input-error
        *ngIf="insuranceBuyerFormGroup.get('email')?.hasError('email') || insuranceBuyerFormGroup.get('email')?.hasError('required')"
        >{{ 'main.growthRegulation.insurance.checkOutForm.email.invalid' | translate }}</app-input-error
      >
    </app-input>
    <app-input
      type="text"
      formControlName="telephoneNo"
      [formControl]="$any(insuranceBuyerFormGroup.get('telephoneNo'))"
      [placeholder]="'main.growthRegulation.insurance.checkOutForm.telephoneNo.name' | translate"
    >
      <app-input-error
        *ngIf="
          insuranceBuyerFormGroup.get('telephoneNo')?.hasError('isNotInteger') ||
          insuranceBuyerFormGroup.get('telephoneNo')?.hasError('required')
        "
        >{{ 'main.growthRegulation.insurance.checkOutForm.telephoneNo.invalid' | translate }}</app-input-error
      >
    </app-input>
    <div class="terms-wrapper">
      <app-checkbox [isCircle]="false" formControlName="termsAccepted">Jeg accepterer </app-checkbox>
      <a
        class="styledaslink"
        target="_blank"
        href="https://www.topdanmark.dk/binaries/content/assets/pdf/topdanmark/landbrug/vilkar-dokumenter/8608-2-vilkaar-for-lejesaedforsikring-februar-2021.pdf/8608-2-vilkaar-for-lejesaedforsikring-februar-2021.pdf"
        target="_blank"
      >
        vilkår</a
      >
      og håndtering af <a class="styledaslink" href="https://www.topdanmark.dk/persondatapolitik/" target="_blank">persondata</a>.
      <div *ngIf="insuranceBuyerFormGroup.get('termsAccepted')?.hasError('requiredTrue')" class="error-message">
        <p>{{ 'main.growthRegulation.insurance.checkOutForm.termsNotAccepted' | translate }}</p>
      </div>
    </div>
  </form>
</div>
