import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ExpectedYieldTooltipComponent } from './yield-prognosis-details/expected-yield/expected-yield-tooltip/expected-yield-tooltip.component';
import { ExpectedYieldComponent } from './yield-prognosis-details/expected-yield/expected-yield.component';
import { YieldInfoDialogComponent } from './yield-prognosis-details/yield-info-dialog/yield-info-dialog.component';
import { YieldPrognosisDetailsComponent } from './yield-prognosis-details/yield-prognosis-details.component';
import { YieldPrognosisComponent } from './yield-prognosis.component';
import { YieldPrognosisService } from './yield-prognosis.service';

@NgModule({
  declarations: [
    YieldPrognosisComponent,
    YieldPrognosisDetailsComponent,
    ExpectedYieldComponent,
    YieldInfoDialogComponent,
    ExpectedYieldTooltipComponent,
  ],
  imports: [SharedModule],
  providers: [YieldPrognosisService],
  exports: [YieldPrognosisComponent, YieldPrognosisDetailsComponent],
})
export class YieldPrognosisModule {}
