import { BlightRiskDto } from '@app/core/interfaces/BlightRiskDto';

export class BlightRisk {
  public date: Date;
  public precipitation: number;
  public protectionDays: number;
  public remainingProtectionDays: number;
  public riskHexColor: string;
  public riskLevel: number;
  public windDirection: number;
  public windSpeed: number;
  public riskText: string;

  constructor(dto: BlightRiskDto) {
    this.date = new Date(dto.date);
    this.precipitation = dto.precipitation;
    this.protectionDays = dto.protectionDays;
    this.remainingProtectionDays = dto.remainingProtectionDays;
    this.riskHexColor = dto.riskHexColor;
    this.riskLevel = dto.riskLevel;
    this.windDirection = dto.windDirection;
    this.windSpeed = dto.windSpeed;
    this.riskText = this.getTooltipTextFromRiskLevel(dto.riskLevel)!;
  }

  private getTooltipTextFromRiskLevel(riskLevel: number) {
    if (riskLevel >= 40) {
      return 'main.potatoBlight.legend.highRisk';
    }
    if (riskLevel >= 20) {
      return 'main.potatoBlight.legend.mediumRisk';
    }
    if (riskLevel > 0) {
      return 'main.potatoBlight.legend.lowRisk';
    }
    if (riskLevel === 0) {
      return 'main.potatoBlight.legend.minimalRisk';
    }
    return;
  }
}
