<div class="content">
  <h2>{{ 'main.fieldAdministration.copyField.fieldsInHarvestYearCopied' | translate }} {{ harvestyear | async }}</h2>
  <div class="selected-fields">
    <div class="selected-fields-header">
      <div class="columnOne">{{ 'main.fieldAdministration.fieldList.headerColumns.fieldNumber' | translate }}</div>
      <div class="columnTwo">{{ 'main.fieldAdministration.fieldList.headerColumns.fieldName' | translate }}</div>
      <div class="columnThree">{{ 'main.fieldAdministration.fieldList.headerColumns.crop' | translate }}</div>
      <!--<div class="columnFour">Sort</div> -->
      <div class="columnFive"><mat-checkbox (change)="selectAllCrop($event)"></mat-checkbox></div>
      <div class="columnsix">&nbsp;</div>
    </div>
    <div class="selected-fields-body">
      <div class="field-list" [@flyInOut]="'in'" *ngFor="let item of selectedFields$ | async; let first = first; let last = last">
        <app-field-item
          [@flyInOut]="'in'"
          [fieldNumber]="item.fieldNumber"
          [fieldName]="item.fieldName"
          [fieldCrop]="item.fieldCropName"
          [fieldCropSort]="item.fieldCropSort"
          [includeCrop]="item.includeCrop"
          [isIncluded]="true"
          (onRemove)="onDeselect(item.fieldNumber)"
          (onIncludeCrop)="onIncludeCrop($event, item.fieldNumber)"
        ></app-field-item>
      </div>
    </div>
  </div>
  <h4>{{ 'main.fieldAdministration.copyField.fieldsNotCopied' | translate }}</h4>
  <div class="unselected-fields-body">
    <div class="field-list" [@flyInOut]="'out'" *ngFor="let item of deSelectedFields$ | async; let first = first; let last = last">
      <app-field-item
        [@flyInOut]="'out'"
        [fieldNumber]="item.fieldNumber"
        [fieldName]="item.fieldName"
        [fieldCrop]="item.fieldCropName"
        [fieldCropSort]="item.fieldCropSort"
        [includeCrop]="item.includeCrop"
        [isIncluded]="false"
        (onAdd)="onSelect(item.fieldNumber)"
        (onIncludeCrop)="onIncludeCrop($event, item.fieldNumber)"
      ></app-field-item>
    </div>
  </div>
</div>
