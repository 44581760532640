import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class FormHelper {
  /**
   * Returns an array with unique error strings from the given forms controls
   */
  public static getAllErrorsFromForm(form: UntypedFormGroup): string[] {
    const errors = new Set<string>();
    Object.keys(form.controls).forEach((key) => {
      this.getUniqueErrorKeysFromFormControl(form.get(key)!).forEach((error) => errors.add(error));
    });
    return Array.from(errors);
  }

  public static getUniqueErrorKeysFromFormControl(control: AbstractControl): Array<string> {
    const errors = new Set<string>();

    const controlErrors: ValidationErrors | null = control.errors;

    if (controlErrors != null) {
      Object.keys(controlErrors).forEach((keyError) => {
        if (controlErrors[keyError].valid !== true) {
          errors.add(keyError);
        }
      });
    }
    return Array.from(errors);
  }
}
