import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { MaterialModule } from '../material/material.module';
import { TooltipOverlayModule } from '../tooltip-overlay/tooltip-overlay.module';
import { TranslateModule } from '../translate/translate.module';
import { ConfirmDialogPortalComponent } from './confirm-dialog-portal/confirm-dialog-portal.component';
import { ConfirmDialogComponent } from './confirm/confirm.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DirtyCheckDialogComponent } from './dirty-check-dialog/dirty-check-dialog.component';

@NgModule({
  declarations: [
    DialogHeaderComponent,
    DialogContentComponent,
    ConfirmDialogPortalComponent,
    DirtyCheckDialogComponent,
    DialogFooterComponent,
    ConfirmDialogComponent,
  ],
  imports: [CommonModule, TranslateModule, MaterialModule, ButtonsModule, IconModule, TooltipOverlayModule],
  exports: [DialogHeaderComponent, DialogContentComponent, DialogFooterComponent],
})
export class DialogModule {}
