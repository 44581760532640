import { Route } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { DataConnectionsComponent } from '@app/settings/data-connections/data-connections.component';
import { TransferFieldsComponent } from '@app/settings/datamanagement/transfer-fields/transfer-fields.component';
import { TransferTasksComponent } from '@app/settings/datamanagement/transfer-tasks/transfer-tasks.component';
import { SettingsComponent } from '@app/settings/settings.component';
import { AccessControlGuard, AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';

export const settingsLazyRoute: Route = {
  path: 'settings',
  loadChildren: () => import('./settings.module').then((m) => m.SettingsModule),
};

export const settingsRoutes: AccessControlRoutes = [
  {
    path: '',
    component: SettingsComponent,
    canActivate: [AuthGuard, ConsentGuard, AccessControlGuard],
    data: { resource: 'settings_data_connections' },
    canLoad: [],
    children: [
      { path: '', redirectTo: 'connections', pathMatch: 'full' },
      {
        path: 'connections',
        component: DataConnectionsComponent,
      },
      {
        canActivate: [AccessControlGuard],
        data: { resource: 'settings_transfer' },
        path: 'datamanagement',
        children: [
          { path: '', redirectTo: 'fields', pathMatch: 'full' },
          {
            path: 'fields',
            component: TransferFieldsComponent,
          },
          {
            path: 'tasks',
            component: TransferTasksComponent,
          },
        ],
      },
    ],
  },
];
