<div class="header">
  <div class="text">
    <h2>{{ 'downloadManager.title' | translate }}</h2>
    <span>{{ 'downloadManager.description' | translate }}</span>
  </div>

  <app-select *ngIf="farms.length > 1" [formControl]="selectedFarm" [compareWith]="compareFarms">
    <app-select-prefix>
      <app-icon icon="home"></app-icon>
    </app-select-prefix>

    <app-select-option
      *ngFor="let farm of farms"
      [value]="farm"
      [disabled]="farm.disabled"
      [tooltip]="farm.disabled ? ('downloadManager.empty' | translate) : undefined"
    >
      {{ farm.name }}
    </app-select-option>
  </app-select>
</div>

<mat-dialog-content *ngIf="items$ | async as items" class="content">
  <!-- If array of array make lists for each -->
  <ng-container *ngIf="(isListOfLists$ | async) === true">
    <ng-container *ngFor="let list of asListOfLists(items); let index = index">
      <app-selection-list
        *ngIf="list.items.length > 0"
        [title]="list.title"
        [ngModel]="selectedItems[index]"
        (ngModelChange)="onModelChange($event, list, index)"
      >
        <app-list-option *ngFor="let item of list.items" [value]="item">
          <ng-container [ngTemplateOutlet]="option" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </app-list-option>
      </app-selection-list>
    </ng-container>
  </ng-container>

  <!-- If simple array make one list with items for each -->
  <ng-container *ngIf="(isListOfLists$ | async) === false">
    <app-selection-list
      [title]="asList(items).title"
      [ngModel]="selectedItems[0]"
      (ngModelChange)="onModelChange($event, asList(items), 0)"
    >
      <app-list-option *ngFor="let item of asList(items).items" [value]="item">
        <ng-container [ngTemplateOutlet]="option" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </app-list-option>
    </app-selection-list>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <app-square-button data-testid="download-button" buttonType="Primary" (click)="onSave()" class="action">{{
    'downloadManager.download' | translate
  }}</app-square-button>
  <app-square-button data-testid="cancel-button" buttonType="Secondary" [mat-dialog-close]="null">
    {{ 'downloadManager.cancel' | translate }}</app-square-button
  >
</mat-dialog-actions>

<!-- List option template -->
<ng-template #option let-item>
  <div class="option-container">
    <span *ngIf="item.fieldName" [matTooltip]="item.fieldName">{{ item.fieldName }}</span>
    <span *ngIf="item.productName">{{ item.productName }}</span>
    <span *ngIf="item.displayDate">{{ item.displayDate }}</span>
  </div>
</ng-template>
