import { Route, Routes } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { fieldAnalysisRoutes } from '@app/map/features/field-analysis/field-analysis.routes';
import { MapComponent } from '@app/map/map.component';
import { benchmarkRoutes } from './features/yield-benchmark/benchmark.routes';
export const mapLazyRoute: Route = {
  path: 'map',
  loadChildren: () => import('./map.module').then((m) => m.MapModule),
};

export const mapRoutes: Routes = [
  {
    path: '',
    canActivate: [ConsentGuard],
    component: MapComponent,
    children: [
      ...fieldAnalysisRoutes,
      ...benchmarkRoutes,
      {
        path: 'iso',
        loadChildren: () => import('./features/field-analysis/features/isoxml/isoxml.module').then((m) => m.IsoModule),
      },
    ],
  },
];
