import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeatureBranchRepoService } from '@app/core/repositories/feature-branch/feature-branch-repo.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { FeatureBranchConfig } from './feature-branch-config';

@Component({
  selector: 'app-environment-indicator',
  templateUrl: './environment-indicator.component.html',
  styleUrls: ['./environment-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentIndicatorComponent {
  private default = 'Default';

  public environment = environment.env;
  public visible = this.environment !== 'prod';

  public branches$ = this.service.getFeatureBranches().pipe(map((branches) => [this.default, ...branches]));
  public selectedBranch: string = sessionStorage.getItem(FeatureBranchConfig.StorageKey) ?? this.default;

  constructor(private service: FeatureBranchRepoService) {}

  public changeFeatureBranch(event: Event) {
    const target = event.target as HTMLOptionElement;
    const branch = target.value;

    if (branch === this.default) sessionStorage.removeItem(FeatureBranchConfig.StorageKey);
    else sessionStorage.setItem(FeatureBranchConfig.StorageKey, branch);

    this.selectedBranch = branch;
  }
}
