<div class="browser-recommendation-container">
  <div>
    {{ 'messages.browserDetection.IE11' | translate }}
    <a class="external-link" href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Edge</a>
    {{ 'messages.browserDetection.or' | translate }}
    <a class="external-link" href="https://www.google.com/chrome" target="_blank">Chrome</a>
  </div>
  <app-button class="close-btn" (clicked)="close()" [iconButton]="true">
    <app-icon icon="close"></app-icon>
  </app-button>
</div>
