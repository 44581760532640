import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapService } from '@app/core/map/map.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { Subscription } from 'rxjs';
import { FieldAnalysisShownComponentEnum } from './field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerContentComponent } from './field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from './field-analysis-side-drawer.service';

@Component({
  selector: 'app-field-analysis-side-drawer',
  templateUrl: './field-analysis-side-drawer.component.html',
  styleUrls: ['./field-analysis-side-drawer.component.scss'],
})
export class FieldAnalysisSideDrawerComponent implements OnInit, OnDestroy {
  public sideDrawerRef!: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>;
  public fieldAnalysisShownComponentEnum = FieldAnalysisShownComponentEnum;
  public shownComponentState$ = this.fieldAnalysisSideDrawerService.shownComponentState$;
  public scaleLegendSettingsState$ = this.fieldAnalysisSideDrawerService.legendSettings$;
  public showLegendState$ = this.fieldAnalysisSideDrawerService.showLegendState$;
  public soilSampleLegendState$ = this.fieldAnalysisSideDrawerService.soilSampleLegend$;
  public legendNotExcludedState$ = this.fieldAnalysisSideDrawerService.legendNotExcluded$;
  public sideDrawerWidth$ = this.fieldAnalysisSideDrawerService.drawerWidth$;
  private subscriptions = new Subscription();

  constructor(
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private sideDrawerService: SideDrawerOverlayService,
    private mapService: MapService,
    private farmStateService: FarmStateService
  ) {}

  public ngOnInit() {
    setTimeout(() => this.openSideDrawer());
    this.subscriptions.add(this.mapService.mapReady$.subscribe(() => this.onMapReady()));
  }

  public ngOnDestroy() {
    this.sideDrawerRef.close();
    this.subscriptions.unsubscribe();
  }

  public openSideDrawer() {
    if (this.sideDrawerRef && this.sideDrawerService.sideDrawerRefIsDefined()) {
      this.sideDrawerRef.show();
      this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsOpened;
      return;
    }

    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsOpened;

    this.sideDrawerRef = this.sideDrawerService.openCustomSideDrawer(FieldAnalysisSideDrawerContentComponent, {
      panelClass: 'field-analysis-side-drawer',
      width: SideDrawerConfig.widthAsOpened,
      hasBackdrop: false,
    });

    this.sideDrawerRef.onClose.subscribe(() => {
      this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    });
  }

  private onMapReady() {
    this.subscriptions.add(
      this.farmStateService.fieldFeatures$.subscribe((fieldFeatures) => {
        this.mapService.getMap().addFieldsToMap(fieldFeatures, MapLayerId.FIELDS, true, false);
        this.mapService.showLayer(MapLayerId.FIELDS);
      })
    );
  }
}
