import { CoarseFodderDataSource } from '../enum/coarseFodderDataSource.enum';
export interface CoarseFodderField {
  id: number;
  name: string;
  farmId: number;
  harvestYear: number;
  fieldYearId: number;
  fieldCropId: number;
  taskId?: number;
  cropName: string;
  fieldNumber: string;
  fieldName: string;
  productName: string;
}

export class CoarseFodderColumn implements CoarseFodderField {
  public get productionPrice() {
    if (this.isFieldScoreEnabled) return this.yield && this.yield > 0 ? (this.totalCostsWithCorrection / this.yield) * 100 : null;

    return this.yield && this.yield > 0 ? (this.totalCosts / this.yield) * 100 : null;
  }

  public get totalCosts() {
    return this.unitCosts! + this.machineCosts! + this.landLease! + this.otherCosts! - this.otherIncome!;
  }

  public get totalCostsWithCorrection() {
    return this.unitCosts! + this.machineCosts! + this.landLease! + this.otherCosts! + this.fieldScoreCorrection! - this.otherIncome!;
  }

  public yield?: number;
  public unitCosts?: number;
  public machineCosts?: number;
  public fieldScoreCorrection?: number;
  public landLease?: number;
  public otherCosts?: number;
  public otherIncome?: number;
  public yieldDataSource: CoarseFodderDataSource;
  public unitCostsDataSource: CoarseFodderDataSource;
  public machineAndLaborCostsDataSource: CoarseFodderDataSource;
  public sources: CoarseFodderEconomyDataSource[];
  public cropName: string;
  public farmId: number;
  public fieldCropId: number;
  public fieldName: string;
  public fieldNumber: string;
  public fieldYearId: number;
  public harvestYear: number;
  public id: number;
  public name: string;
  public productName: string;
  public hasFieldScore: boolean;
  public isFieldScoreEnabled: boolean;

  constructor(coarseFodderColumn: CoarseFodderColumn, isFieldScoreEnabled: boolean) {
    this.yield = coarseFodderColumn.yield;
    this.unitCosts = coarseFodderColumn.unitCosts;
    this.machineCosts = coarseFodderColumn.machineCosts;
    this.fieldScoreCorrection = coarseFodderColumn.fieldScoreCorrection;
    this.landLease = coarseFodderColumn.landLease;
    this.otherCosts = coarseFodderColumn.otherCosts;
    this.otherIncome = coarseFodderColumn.otherIncome;
    this.yieldDataSource = coarseFodderColumn.yieldDataSource;
    this.unitCostsDataSource = coarseFodderColumn.unitCostsDataSource;
    this.machineAndLaborCostsDataSource = coarseFodderColumn.machineAndLaborCostsDataSource;
    this.sources = coarseFodderColumn.sources;
    this.cropName = coarseFodderColumn.cropName;
    this.farmId = coarseFodderColumn.farmId;
    this.fieldCropId = coarseFodderColumn.fieldCropId;
    this.fieldName = coarseFodderColumn.fieldName;
    this.fieldNumber = coarseFodderColumn.fieldNumber;
    this.fieldYearId = coarseFodderColumn.fieldYearId;
    this.harvestYear = coarseFodderColumn.harvestYear;
    this.id = coarseFodderColumn.id;
    this.name = coarseFodderColumn.name;
    this.productName = coarseFodderColumn.productName;
    this.hasFieldScore = coarseFodderColumn.hasFieldScore;
    this.isFieldScoreEnabled = isFieldScoreEnabled;
  }
}

export interface CoarseFodderEconomyDataSource {
  coarseFodderEconomyColumn: string;
  dataSource: number;
  dataSourceValue?: number;
}

export interface EconomyColumnDataSource {
  fieldCropId: number;
  coarseFodderEconomyDataSource: CoarseFodderEconomyDataSource[];
}

export interface FieldScore {
  id?: number;
  farmId?: number;
  fieldYearId?: number;
  distance: number;
  transportCosts: number;
  sizeFormCorrection: number;
}

export interface FieldScoreResponse {
  fieldNumber: string;
  fieldName: string;
  area: number;
  avgFieldCapacity: number;
  id: number;
  machineCosts: number;
  fieldScoreCorrection: number;
  farmId?: number;
  harvestYear?: number;
  fieldYearId?: number;
  distance?: number;
  transportCosts?: number;
  sizeFormCorrection?: number;
}
