export interface Consent {
  username: string;
  identifier: string;
  displayName: string;
  statusId: number;
  statusText: string;
  decisionUrl: string;
}

export enum ConsentTypes {
  Missing = 1,
  Given = 2,
  Refused = 3,
  Postponed = 4,
}
