import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { FieldAnalysisFeaturePickerComponent } from './field-analysis-feature-picker.component';
import { FieldAnalysisPickerService } from './field-analysis-picker.service';

@NgModule({
  imports: [SharedModule],
  declarations: [FieldAnalysisFeaturePickerComponent],
  providers: [FieldAnalysisPickerService],
  exports: [FieldAnalysisFeaturePickerComponent],
})
export class FieldAnalysisPickerModule {}
