export interface EditStateEvent {
  status: EditStateStatus;
}

export enum EditStateStatus {
  Avaiable = 0,
  Editing = 1,
  EditingComplete = 2,
  Errored = 3,
}
