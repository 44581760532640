import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataConnectionTypes } from '@app/core/data-connections/data-connection-types.enum';
import { DatamanagementService } from '@app/core/datamanagement/datamanagement.service';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { FarmService } from '@app/core/farm/farm.service';
import { FieldService } from '@app/core/field/field.service';
import { ClaasField } from '@app/core/interfaces/claas-field.interface';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { ProduceNormsStorage } from '@app/core/interfaces/produce-norms-storage.interface';
import { Task } from '@app/core/interfaces/task.interface';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { DataConnectionsRepo } from '@app/core/repositories/data-connections/data-connections-repo.service';
import { DataExchangeRepo } from '@app/core/repositories/data-exchange/data-exchange-repo.service';
import { ProduceNormsRepo } from '@app/core/repositories/produce-norms/produce-norms-repo.service';
import { TasksRepo } from '@app/core/repositories/tasks/tasks-repo.service';
import { ICache } from '@app/helpers/cache/cache.interface';
import { segment } from '@app/helpers/rxjs-operators/segment';
import { DatamanagementStateService } from '@app/state/services/data-management/datamanagement-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { GlobalStateService } from '@app/state/services/global/global-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, Observable, combineLatest, forkJoin, from, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TransferFieldsService {
  public dataConnectionsCache!: ICache<DataConnectionSettingDTO[]>;
  private _isLoadingSubject = new BehaviorSubject(false);

  constructor(
    private datamanagementService: DatamanagementService,
    private farmService: FarmService,
    private dataConnectionsRepo: DataConnectionsRepo,
    private notificationService: NotificationService,
    private messageService: MessageService,
    private tasksRepo: TasksRepo,
    private fieldService: FieldService,
    private produceNormsRepo: ProduceNormsRepo,
    private dataExchangeRepo: DataExchangeRepo,
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private dataManagementStateService: DatamanagementStateService,
    private globalStateservice: GlobalStateService
  ) {}

  public isLoading$(): Observable<boolean> {
    return this._isLoadingSubject.asObservable();
  }

  public set isLoading(loading: boolean) {
    this._isLoadingSubject.next(loading);
  }

  public getClaasSettings(): Observable<DataConnectionSettingDTO[]> {
    return this.dataManagementStateService.dataConnectionSettings$.pipe(
      switchMap((dataConnectionSettings: DataConnectionSettingDTO[]) => {
        if (dataConnectionSettings === null || dataConnectionSettings.length === 0) {
          this.isLoading = true;
          return this.dataConnectionsRepo.getSettings();
        } else {
          return of(dataConnectionSettings);
        }
      }),
      map((dataConnectionSettings: DataConnectionSettingDTO[]) => {
        this.isLoading = false;
        return dataConnectionSettings.filter((setting) => setting.connectionTypeId === DataConnectionTypes.Claas);
      })
    );
  }

  /**
   * Checks if a field is transferable.
   * @param field {Field} The field to check.
   */
  public isTransferable(field: Field): boolean {
    return this.datamanagementService.isFieldTransferable(field);
  }

  public transferField(field: Field): Observable<ClaasField> {
    return this.farmService.findFarmNameById(field.farmId).pipe(
      tap(() => (this.isLoading = true)),
      switchMap((farmName) => this.postField(field, farmName)),
      finalize(() => (this.isLoading = false))
    );
  }

  public getData(): Observable<TransferFieldViewData> {
    return combineLatest([
      this.harvestYearStateService.harvestYear$,
      this.farmStateService.selectedFarmIds$.pipe(filter((farmIds) => !!farmIds.length)),
    ]).pipe(
      tap(() => {
        this.globalStateservice.loadingStarted();
      }),

      // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      switchMap(([harvestYear, selectedFarmIds]) => this.getNormsFieldsTasks(harvestYear, selectedFarmIds))
    );
  }

  private getTasks(harvestYear: number, farmIds: number[]): Observable<Task[]> {
    return this.tasksRepo.getTasksForFarms(farmIds, harvestYear).pipe(
      catchError((error: any) => {
        this.notificationService.showError(this.messageService.getHttpStatusMsg(error.status, this.messageService.getTasksMsg().getError));
        return throwError(error);
      })
    );
  }

  private getFields(harvestYear: number, farmIds: number[]): Observable<Field[] | null> {
    return of(farmIds).pipe(
      segment(25),
      switchMap((chunks) => from(chunks)),
      mergeMap((array) => this.fieldService.getAllFields(array, harvestYear)),
      catchError((error: HttpErrorResponse) => {
        this.notificationService.showError(`${this.messageService.getDataConnectionCredentialMsg().loginError}`);
        return throwError(error);
      })
    );
  }

  private getExternalFields(fields: Field[]): Observable<ClaasField[] | null> {
    return this.getClaasSettings().pipe(
      // replaces the array with an observable emitting each value
      switchMap((connections: DataConnectionSettingDTO[]) => from(connections)),
      mergeMap((dataConnectionSetting) => {
        return this.dataExchangeRepo.getFieldsByFieldIds(
          fields.map((field) => `${field.id}`),
          dataConnectionSetting
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.notificationService.showError(`${this.messageService.getDataConnectionCredentialMsg().loginError}`);
        this.globalStateservice.loadingCompleted();
        return throwError(error);
      })
    );
  }

  /**
   * Get produce norms, fields and tasks to construct the view model for the table.
   * @param harvestYear The harvest year.
   * @param farmIds The ids of the selected farms
   */
  private getNormsFieldsTasks(harvestYear: number, farmIds: number[]): Observable<TransferFieldViewData> {
    return forkJoin([
      this.getProduceNorms(harvestYear, farmIds),
      this.getFields(harvestYear, farmIds),
      this.getTasks(harvestYear, farmIds),
    ]).pipe(
      tap(() => (this.isLoading = true)),
      switchMap(([produceNorms, fields, tasks]) =>
        this.getExternalFields(fields || []).pipe(
          map((externalFields) => new TransferFieldViewData(produceNorms, externalFields || [], fields || [], tasks))
        )
      ),
      finalize(() => {
        this.isLoading = false;
        this.globalStateservice.loadingCompleted();
      })
    );
  }

  private postField(field: Field, farmName: string): Observable<ClaasField> {
    return this.getClaasSettings().pipe(
      // replaces the array with an observable emitting each value
      switchMap((connections: DataConnectionSettingDTO[]) => from(connections)),
      switchMap((dataConnectionSetting) => this.datamanagementService.transferField(field, farmName, dataConnectionSetting)),
      catchError((error: any) => {
        this.notificationService.showError(
          this.messageService.getHttpStatusMsg(error.status, this.messageService.getDataExchangeMsg().saveFieldError)
        );
        return throwError(error);
      })
    );
  }

  private getProduceNorms(harvestYear: number, farmIds: number[]): Observable<ProduceNormsStorage[]> {
    return from(farmIds).pipe(
      mergeMap((farmId) => {
        return this.produceNormsRepo.get(farmId, harvestYear, [OperationTypeGroupEnum.Seeding, OperationTypeGroupEnum.Harvest]).pipe(
          catchError((error: any) => {
            this.notificationService.showError(
              this.messageService.getHttpStatusMsg(error.status, this.messageService.getProduceNormsMsg().getError)
            );
            return throwError(error);
          })
        );
      })
    );
  }
}

export class TransferFieldViewData {
  public produceNorms: ProduceNormsStorage[];
  public externalFields: ClaasField[];
  public fields: Field[];
  public tasks: Task[];

  constructor(produceNorms: ProduceNormsStorage[], externalFields: ClaasField[], fields: Field[], tasks: Task[]) {
    this.produceNorms = produceNorms;
    this.externalFields = externalFields;
    this.fields = fields;
    this.tasks = tasks;
  }
}

export class ClaasFieldTransferResult {
  public claasField: ClaasField;
  public field: Field;

  constructor(claasField: ClaasField, field: Field) {
    this.field = field;
    this.claasField = claasField;
  }
}
