import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { FeatureCoordinates, MultiPolygonCoordinates, PolygonCoordinates } from '@app/core/interfaces/geometry-coordinates.types';
import { MapConstants } from '@app/core/map/map.constants';
import { Feature } from 'ol';
import { WKT } from 'ol/format';
import LineString from 'ol/geom/LineString';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';

export class WKTUtil {
  public static getCoordinatesFromWktString(geometryString: string): FeatureCoordinates {
    const wkt = new WKT();
    const olGeometry = wkt.readGeometry(geometryString);

    switch (olGeometry.getType()) {
      case 'LineString':
        return (olGeometry as LineString).getCoordinates();
      case 'Point':
        return (olGeometry as Point).getCoordinates();
      case 'MultiPolygon':
        return (olGeometry as MultiPolygon).getCoordinates();
      default:
        return (olGeometry as Polygon).getCoordinates();
    }
  }

  public static getGeometryTypeFromWktString(geometryString: string): GeometryType {
    if (geometryString?.length) {
      const wkt = new WKT();
      const olGeometry = wkt.readGeometry(geometryString);

      switch (olGeometry.getType()) {
        case 'LineString':
          return GeometryType.LINE;
        case 'Point':
          return GeometryType.POINT;
        case 'MultiPolygon':
          return GeometryType.MULTIPOLYGON;
        default:
          return GeometryType.POLYGON;
      }
    } else {
      return GeometryType.POLYGON;
    }
  }
  public getGeometryTypeFromWktString(geometryString: string) {
    return WKTUtil.getGeometryTypeFromWktString(geometryString);
  }

  public getCoordinatesFromWktString(geometryString: string) {
    return WKTUtil.getCoordinatesFromWktString(geometryString);
  }

  public static getWktStringFromCoordinates(coordinates: any, geometryType: GeometryType): string {
    const wkt = new WKT();
    let geometry;

    switch (geometryType) {
      case GeometryType.LINE:
        geometry = new LineString(coordinates);
        break;
      case GeometryType.POINT:
        geometry = new Point(coordinates);
        break;
      case GeometryType.MULTIPOLYGON:
        geometry = new MultiPolygon(coordinates);
        break;
      case GeometryType.POLYGON:
        // Check if coordinates have the structure of a multipolygon
        if (
          Array.isArray(coordinates) &&
          Array.isArray(coordinates[0]) &&
          Array.isArray(coordinates[0][0]) &&
          Array.isArray(coordinates[0][0][0])
        ) {
          geometry = new MultiPolygon(coordinates);
        } else {
          geometry = new Polygon(coordinates);
        }
        break;
      default:
        throw new Error('Unsupported Geometry Type');
    }

    return wkt.writeGeometry(geometry);
  }

  public static getWktFromFeature(feature: Feature | null): string {
    const wkt = new WKT();
    return wkt.writeFeature(feature!, {
      dataProjection: MapConstants.dataProjection,
      featureProjection: MapConstants.mapProjection,
    });
  }

  public static getFeatureFromWkt(wktString: string) {
    const wkt = new WKT();
    return wkt.readFeature(wktString, {
      dataProjection: MapConstants.dataProjection,
      featureProjection: MapConstants.mapProjection,
    });
  }

  public static getPolygonCoordinatesFromWKTString(wktString: string): PolygonCoordinates | null {
    const coordinates = this.getCoordinatesFromWktString(wktString);
    return coordinates ? (coordinates as PolygonCoordinates) : null;
  }

  public static getMultiPolygonCoordinatesFromWKTString(wktString: string): MultiPolygonCoordinates | null {
    const coordinates = this.getCoordinatesFromWktString(wktString);
    return coordinates ? (coordinates as MultiPolygonCoordinates) : null;
  }
}
