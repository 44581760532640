import { DateTime } from 'luxon';

export interface CropNutrientAvailabilityGraphDataPointDTO {
  availableNutrient: number;
  cropNutrientAvailability: CropNutrientAvailability;
  day: DateTime;
  nutrientRequirement: number;
}

export enum CropNutrientAvailability {
  None = 0,
  Green = 1,
  Yellow = 2,
  Red = 3,
  Warning = 4,
}
