import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-drone-image-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
})
export class DroneImageInformationDialogComponent {
  constructor(private dialogRef: MatDialogRef<DroneImageInformationDialogComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
