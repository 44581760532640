import { Injectable } from '@angular/core';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { MapStateService } from '@app/state/services/map/map-state.service';
import { UserStateService } from '@app/state/services/user/user-state.service';
import { Coordinate } from 'ol/coordinate';
import { Observable } from 'rxjs';
import { combineLatest as combineLatestOperator, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapSelector {
  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly mapOptions$: Observable<CultivationJournalOptions> = this.mapStateService.fieldInspectorMapOptions$;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly mapCoverFlowItems$: Observable<MapCoverFlowItem[]> = this.mapStateService.mapCoverFlowItems$;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly selectedMapCoverFlowItemIdx$: Observable<number> = this.mapStateService.selectedMapCoverFlowItemIdx$;
  public readonly selectedMapCoverFlowItem$ = this.selectedMapCoverFlowItemIdx$.pipe(
    combineLatestOperator(this.mapCoverFlowItems$.pipe(filter((mapCoverFlowItems) => mapCoverFlowItems?.length > 0))),
    filter((idx) => idx !== null),
    map(([idx, mapCoverFlowItems]) => mapCoverFlowItems[idx])
  );

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly clickedCoords$: Observable<Coordinate> = this.mapStateService.fieldInspectorClickedCoords$;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly subscriptionLevel$: Observable<SubscriptionLevel> = this.userStateService.subscriptionLevel$;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly harvestYear$: Observable<number> = this.harvestYearStateService.harvestYear$;

  constructor(
    private harvestYearStateService: HarvestYearStateService,
    private mapStateService: MapStateService,
    private userStateService: UserStateService
  ) {}
}
