<mat-toolbar class="flexSpaceBetween header">
  <app-icon class="close-btn" icon="Luk" (click)="onClose()"></app-icon>
  <!-- show/hide (ngIf) logic will be implemented soon.  -->
  <app-icon *ngIf="false" icon="back" class="header-button"></app-icon>
  <span *ngIf="true">{{ 'reminders.header.title' | translate }}</span>
  <!-- Is not to be used for now. Will be implemented soon -->
  <span *ngIf="false">{{ 'reminders.header.settings' | translate }}</span>
  <!-- Is to hidden for now . Will be implemented soon-->
  <app-icon hidden icon="Indstillinger" class="header-element-hide"></app-icon>
</mat-toolbar>
<mat-dialog-content>
  <app-reminder-list></app-reminder-list>
</mat-dialog-content>
