<td [ngClass]="{ 'no-data': data.score === null }">
  {{ data.subject | translate }}
</td>
<td [ngClass]="{ 'no-data': data.score === null }">
  <div *ngIf="data.score !== null" class="name-field">{{ data.name | translate }}</div>
  <div *ngIf="data.score === null">{{ 'main.growthRegulation.notFilledIn' | translate }}</div>
</td>
<td [ngClass]="{ 'no-data': data.score === null }">
  <div *ngIf="data.score !== null" class="center">{{ data.score }}</div>
  <div *ngIf="data.score === null" class="center">-</div>
</td>
<td>
  <app-more-info *ngIf="data.info">
    <h3>{{ data.info.headline | translate }}</h3>
    <p>{{ data.info.body | translate }}</p>
    <table *ngIf="data.info.table">
      <tr *ngFor="let row of data.info.table">
        <td>{{ row.name | translate }}</td>
        <td>{{ row.value | translate }}</td>
      </tr>
    </table>
  </app-more-info>
</td>
