<div
  class="wrapper"
  [@reveal]
  *ngIf="(data$ | async)!.length > 0; else noContent"
  [appResponsiveClass]="{ mobile: 'XSmall', tablet: 'Small' }"
>
  <div class="item" *ngFor="let dataItem of data$ | async">
    <ng-container *ngIf="cardRef; else renderDefaultCard" [ngTemplateOutlet]="cardRef" [ngTemplateOutletContext]="{ task: dataItem }">
    </ng-container>

    <ng-template #renderDefaultCard>
      <app-card-presentation
        [dataItem]="dataItem"
        [fieldDefinitions]="(fieldDefinitions$ | async)!"
        [actionDefinitions]="(actionDefinitions$ | async)!"
        (action)="forwardActionEvent($event)"
        #card
      ></app-card-presentation>
    </ng-template>
  </div>
</div>

<ng-template #noContent>
  <div class="no-data">
    <p>{{ 'taskCards.noData' | translate }}</p>
    <p>{{ 'taskCards.addTaskGuidance' | translate }}</p>
  </div>
</ng-template>
