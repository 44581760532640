<div class="legend">
  <div [ngSwitch]="legend.shortName">
    <app-kt-legend *ngSwitchCase="'Kt'" [legend]="legend"></app-kt-legend>
    <app-rt-legend *ngSwitchCase="'Rt'" [legend]="legend"></app-rt-legend>
    <app-pt-legend *ngSwitchCase="'Pt'" [legend]="legend"></app-pt-legend>
    <app-mgt-legend *ngSwitchCase="'Mgt'" [legend]="legend"></app-mgt-legend>
    <app-bt-cut-legend *ngSwitchCase="'Bt'" [legend]="legend"></app-bt-cut-legend>
    <app-bt-cut-legend *ngSwitchCase="'Cut'" [legend]="legend"></app-bt-cut-legend>
    <app-humus-legend *ngSwitchCase="'Humus'" [legend]="legend"></app-humus-legend>
    <app-clay-legend *ngSwitchCase="'Ler%'" [legend]="legend"></app-clay-legend>
    <app-jb-legend *ngSwitchCase="'Jb'" [legend]="legend"></app-jb-legend>
    <div *ngSwitchDefault></div>
  </div>
</div>
