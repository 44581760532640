import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlightTableComponent } from './blight-table/blight-table.component';
import { SharedModule } from '@app/shared/shared.module';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { FilterModule } from '../filter/filter.module';
import { BlightRiskComponent } from './blight-risk/blight-risk.component';
import { BlightTableInfoComponent } from './blight-table/blight-table-info/blight-table-info.component';
import { BlightTaskStatusPipe } from './blight-table/util/blight-task-status.pipe';
import { BlightWrapperComponent } from './blight-wrapper/blight-wrapper.component';
import { AppPipesModule } from '@app/shared/pipes/pipes.module';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';

@NgModule({
  declarations: [BlightWrapperComponent, BlightTableComponent, BlightRiskComponent, BlightTaskStatusPipe, BlightTableInfoComponent],
  exports: [BlightWrapperComponent],
  imports: [CommonModule, AppPipesModule, SpinnerModule, SharedModule, TreeListModule, FilterModule],
})
export class BlightModule {}
