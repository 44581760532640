import { Injectable } from '@angular/core';
import { ChartService } from '@app/core/chart/chart.service';
import { Month } from '@app/core/enums/month.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { DialogService } from '@app/shared/dialog/dialog.service';
import {
  CropNutrientAvailability,
  CropNutrientAvailabilityGraphDataPointDTO,
} from '@app/shared/n-tool/CropNutrientAvailabilityGraphDataPointDTO';
import { DateRange } from '@app/shared/n-tool/date-range';
import { NToolDialogComponent } from '@app/shared/n-tool/dialog/n-tool-dialog.component';
import { NToolData, NToolRange, NToolTask } from '@app/shared/n-tool/n-tool-data';
import { NToolRepoService } from '@app/shared/n-tool/n-tool-repo.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { CategoryAxis } from '@progress/kendo-angular-charts';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { filterNullish } from '../operators';

@Injectable({
  providedIn: 'root',
})
export class NToolService {
  public get isLoading$(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public get dateRange$(): Observable<DateRange> {
    return this.harvestYearStateService.harvestYear$.pipe(
      filterNullish(),
      map(
        (harvestYear) =>
          new DateRange(
            DateTime.fromObject({ year: harvestYear, month: this.START_MONTH, day: this.START_DAY }),
            DateTime.fromObject({ year: harvestYear, month: this.END_MONTH, day: this.END_DAY })
          )
      )
    );
  }

  private readonly START_DAY = 1;
  private readonly START_MONTH = Month.March;
  private readonly END_DAY = 31;
  private readonly END_MONTH = Month.May;

  public readonly colorGreen = '#B4C5B0';
  public readonly colorYellow = '#FDF4C9';
  public readonly colorRed = '#FDCBBE';
  public readonly fullColorOpacity = 0.8;
  public readonly yellowFullColorOpacity = 1;
  public readonly nonFullColorOpacity = 0.4;

  private isLoading = new BehaviorSubject(false);

  constructor(
    private chartService: ChartService,
    private harvestYearStateService: HarvestYearStateService,
    private dialogService: DialogService,
    private nToolRepoService: NToolRepoService,
    private produceNormsService: ProduceNormsService,
    private languageService: LanguageService
  ) {}

  public calculateNumberOfStepsByScreenSize(screenSize: ScreenSize, data: any, categoryAxis: CategoryAxis): CategoryAxis {
    return this.chartService.calculateNumberOfStepsByScreenSize(screenSize, data, categoryAxis);
  }

  public showInfoModal(): void {
    this.dialogService.openCustomDialog(NToolDialogComponent, {
      maxWidth: '900px',
    });
  }

  public getChartData(field: Field): Observable<NToolData> {
    return this.harvestYearStateService.harvestYear$.pipe(
      filterNullish(),
      first(),
      tap(() => this.isLoading.next(true)),
      switchMap((harvestYear) =>
        this.nToolRepoService.get(harvestYear, field.farmId, field.id).pipe(
          switchMap((nToolData) => this.enrichNToolDataWithProductNameAndUnit(nToolData, harvestYear, field.farmId)),
          map((nToolData) => this.generateRanges(nToolData)),
          map((nToolData) => this.createTaskWithDataBeforeChartStart(nToolData, harvestYear))
        )
      ),
      finalize(() => this.isLoading.next(false))
    );
  }

  public getValueAxisTitle() {
    return this.languageService.getText('nTool.valueAxisTitle');
  }

  /**
   * Finds product name and unit from produceNormNumber and adds it to all tasks.
   */
  private enrichNToolDataWithProductNameAndUnit(nToolData: NToolData, harvestYear: number, farmId: number): Observable<NToolData> {
    return this.getProduceNormsByNumbers(farmId, harvestYear, this.getProduceNormNumbersFromNToolData(nToolData)).pipe(
      map((produceNorms) => {
        nToolData.taskGraphPoints.forEach((taskGraphPoint) =>
          taskGraphPoint.productsUsed.forEach((nToolProduct) => {
            const matchingProduceNorm = produceNorms.find((produce) => produce.number === nToolProduct.produceNormNumber);
            nToolProduct.productName = matchingProduceNorm?.name!;
            nToolProduct.unit = matchingProduceNorm?.unitText!;
          })
        );
        return nToolData;
      })
    );
  }

  /**
   * Returns all produceNormNumbers in the given NToolData's tasks.
   */
  private getProduceNormNumbersFromNToolData(nToolData: NToolData) {
    return nToolData.taskGraphPoints
      .map((taskGraphPoint) => taskGraphPoint.productsUsed)
      .reduce((accumulator, value) => accumulator.concat(value), [])
      .map((nToolProduct) => nToolProduct.produceNormNumber);
  }

  /**
   * Returns produce norms from the @harvestYear and @farmId with number property included in @produceNormNumbers
   */
  private getProduceNormsByNumbers(farmId: number, harvestYear: number, produceNormNumbers: string[]): Observable<ProduceNorm[]> {
    return this.produceNormsService
      .getProduceNorms(farmId, harvestYear)
      .pipe(map((produceNorms) => produceNorms.filter((produceNorm) => produceNormNumbers.includes(produceNorm.number))));
  }

  /**
   * Populates the given NToolData's range property
   * Groups CropNutrientAvailabilityGraphPoints by cropNutrientAvailability and dates.
   * Each NToolRange presents a date range with the same cropNutrientAvailability.
   * Several ranges can have the same cropNutrientAvailability, if they are separated by a range with a different cropNutrientAvailability.
   * @param nToolData
   */
  private generateRanges(nToolData: NToolData): NToolData {
    const today = DateTime.now();
    const ranges: NToolRange[] = [];
    nToolData.nutrientAvailabilityGraphPoints.sort((a, b) => CompareHelper.compare(a.day, b.day));
    nToolData.taskGraphPoints.sort((a, b) => CompareHelper.compare(a.date, b.date));

    // @ts-ignore - TS7034 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    let lastCropNutrientAvailability;
    let range: CropNutrientAvailabilityGraphDataPointDTO[] = [];
    let previousTask: NToolTask | null = null;

    nToolData.nutrientAvailabilityGraphPoints.forEach((nutrientAvailabilityGraphPoint) => {
      if (nToolData.taskGraphPoints.length > 0 && !previousTask) {
        // Find first task that is after nutrientAvailabilityGraphPoint
        const nextTaskIndex = nToolData.taskGraphPoints.findIndex((t) => t.date > nutrientAvailabilityGraphPoint.day);
        // If the next task is not the first, set previous task to the task before the next task
        if (nextTaskIndex > 0) {
          previousTask = nToolData.taskGraphPoints[nextTaskIndex - 1];
          // If next task is the first task or not existing, previous task is set to the last task in the array
        } else {
          previousTask = nToolData.taskGraphPoints[nToolData.taskGraphPoints.length - 1];
        }
      }

      // Is the current elements day property the same as a tasks day property
      const matchesTaskDay = nToolData.taskGraphPoints.find((t) => t.date === nutrientAvailabilityGraphPoint.day);
      // Is the current element not the first, and availability different from the previous elements availability
      const availabilityChanged =
        // @ts-ignore - TS7005 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        lastCropNutrientAvailability && nutrientAvailabilityGraphPoint.cropNutrientAvailability !== lastCropNutrientAvailability;
      // If range isn't empty, and either availability has changed, or the nutrientAvailabilityGraphPoint is on the same day as a task, we create a new range to make the ranges snap together.
      // @ts-ignore - TS7005 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      if (range.length && (availabilityChanged || matchesTaskDay) && lastCropNutrientAvailability) {
        // This point makes the range snap together visually with the next range.
        const snappingPoint = {
          ...nutrientAvailabilityGraphPoint,
          availableNutrient: range[range.length - 1].availableNutrient,
        };
        const { color, rangeName, rangeOpacity } = this.extractValues(lastCropNutrientAvailability, previousTask, today);

        if (!color || !rangeName) return;

        ranges.push(
          new NToolRange(
            this.getColorFromCropNutrientAvailability(lastCropNutrientAvailability)!,
            [...range, snappingPoint],

            this.getRangeNameFormCropNutrientAvailability(lastCropNutrientAvailability)!,

            this.getRangeOpacity(previousTask!, today, lastCropNutrientAvailability)
          )
        );
        range = [];
        previousTask = null;
      }
      range.push(nutrientAvailabilityGraphPoint);
      lastCropNutrientAvailability = nutrientAvailabilityGraphPoint.cropNutrientAvailability;
    });

    // If there are any elements left in the ranges array when the loop is finished, the final range is added to ranges.
    if (range.length > 0) {
      ranges.push(
        new NToolRange(
          // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          this.getColorFromCropNutrientAvailability(lastCropNutrientAvailability)!,
          [...range],

          // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          this.getRangeNameFormCropNutrientAvailability(lastCropNutrientAvailability)!,

          // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          this.getRangeOpacity(previousTask!, today, lastCropNutrientAvailability)
        )
      );
    }

    // To avoid duplicate legend names, all repeated names are removed.
    const names: string[] = [];
    ranges.forEach((r) => {
      if (names.includes(r.name)) {
        r.name = '';
      } else {
        names.push(r.name);
      }
    });

    return { ...nToolData, ranges: ranges };
  }

  private extractValues(lastCropNutrientAvailability: CropNutrientAvailability, previousTask: NToolTask | null, today: DateTime) {
    const color = this.getColorFromCropNutrientAvailability(lastCropNutrientAvailability);
    const rangeName = this.getRangeNameFormCropNutrientAvailability(lastCropNutrientAvailability);
    const rangeOpacity = this.getRangeOpacity(previousTask!, today, lastCropNutrientAvailability);
    return { color, rangeName, rangeOpacity };
  }

  /**
   * Returns the color string matching @cropNutrientAvailability
   * @param cropNutrientAvailability
   */
  private getColorFromCropNutrientAvailability(cropNutrientAvailability: CropNutrientAvailability): string | undefined {
    switch (cropNutrientAvailability) {
      case CropNutrientAvailability.Green:
        return this.colorGreen;
      case CropNutrientAvailability.Yellow:
        return this.colorYellow;
      case CropNutrientAvailability.Red:
        return this.colorRed;
      default:
        return undefined;
    }
  }

  /**
   * Returns the translated name string matching @cropNutrientAvailability
   * @param cropNutrientAvailability
   */
  private getRangeNameFormCropNutrientAvailability(cropNutrientAvailability: CropNutrientAvailability) {
    switch (cropNutrientAvailability) {
      case CropNutrientAvailability.Green:
        return this.languageService.getText('nTool.rangeNames.green');
      case CropNutrientAvailability.Yellow:
        return this.languageService.getText('nTool.rangeNames.yellow');
      case CropNutrientAvailability.Red:
        return this.languageService.getText('nTool.rangeNames.red');
      default:
        return undefined;
    }
  }

  /**
   * Returns a string representing the relevant data for the given NToolTask
   * @param nToolTask
   */
  public getLabelContent(nToolTask: NToolTask) {
    const startText = nToolTask.isTaskCompleted
      ? this.languageService.getText('nTool.applied')
      : this.languageService.getText('nTool.planned');
    const totalN = Math.round(nToolTask.productsUsed.reduce((prev, curr) => prev + curr.nutrientAmount, 0));
    return `${startText} ${totalN} kg/N`;
  }

  private createTaskWithDataBeforeChartStart(nToolData: NToolData, harvestYear: number) {
    const startDate = DateTime.fromObject({ year: harvestYear, month: this.START_MONTH, day: this.START_DAY });
    const taskBeforeStart = nToolData.taskGraphPoints.findIndex((t) => t.date < startDate);
    if (taskBeforeStart !== -1) {
      nToolData.taskGraphPoints[taskBeforeStart].date = startDate;
      nToolData.taskGraphPoints[taskBeforeStart].fromPast = true;
      nToolData.taskGraphPoints[taskBeforeStart].isTaskCompleted = true;
    }
    return nToolData;
  }

  private getRangeOpacity(previousTask: NToolTask, today: DateTime, lastCropNutrientAvailability: CropNutrientAvailability): number {
    if (previousTask?.isTaskCompleted && CompareHelper.compare(previousTask?.date, today) === -1) {
      return lastCropNutrientAvailability === CropNutrientAvailability.Yellow ? this.yellowFullColorOpacity : this.fullColorOpacity;
    } else {
      return this.nonFullColorOpacity;
    }
  }
}
