import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FieldAnalysisFeaturesComponent } from '@app/map/features/field-analysis/features/field-analysis-features.component';
import { NdviMapFeatureModule } from '@app/map/features/field-analysis/features/ndvi-feature/ndvi-feature.module';
import { FieldAnalysisFeaturesActionsService } from '@app/map/features/field-analysis/features/selector/field-analysis-features.actions.service';
import { FieldAnalysisFeaturesSelector } from '@app/map/features/field-analysis/features/selector/field-analysis-features.selector';
import { SharedModule } from '@app/shared/shared.module';
import { AsAppliedModule } from './as-applied/as-applied.module';
import { DroneImageImportModule } from './drone-image-import/drone-image-import.module';
import { FieldAnalysisFeaturesService } from './field-analysis-features.service';
import { SoilSampleMapFeatureModule } from './soil-sample-feature/soil-sample-feature.module';

@NgModule({
  imports: [SharedModule, RouterModule, NdviMapFeatureModule, SoilSampleMapFeatureModule, AsAppliedModule, DroneImageImportModule],
  declarations: [FieldAnalysisFeaturesComponent],
  providers: [FieldAnalysisFeaturesSelector, FieldAnalysisFeaturesService, FieldAnalysisFeaturesActionsService],
})
export class FieldAnalysisFeaturesModule {}
