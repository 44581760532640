import { Component, OnInit } from '@angular/core';
import { Reminder } from '@app/core/repositories/reminders/reminders.interface';
import { ReminderWindowService } from '../reminder-window.service';

@Component({
  selector: 'app-reminder-list',
  templateUrl: './reminder-list.component.html',
  styleUrls: ['./reminder-list.component.scss'],
})
export class ReminderListComponent implements OnInit {
  public reminders: Reminder[] = [];

  constructor(private reminderService: ReminderWindowService) {}

  public ngOnInit() {
    this.reminders = this.reminderService.allReminders;
  }

  public setReadState(reminder: Reminder) {
    if (!reminder.isRead) {
      reminder.isRead = !reminder.isRead;
      this.reminderService.setReminderReadState(reminder);
    }
  }
}
