<div class="header-close">
  <h4>{{ 'wetHoursChart.help.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <ul class="info-bullets">
    <li>{{ 'wetHoursChart.help.bullets.1' | translate }}</li>
    <li>{{ 'wetHoursChart.help.bullets.2' | translate }}</li>
    <li>{{ 'wetHoursChart.help.bullets.3' | translate }}</li>
    <li>{{ 'wetHoursChart.help.bullets.4' | translate }}</li>
    <li>{{ 'wetHoursChart.help.bullets.5' | translate }}</li>
  </ul>
</mat-dialog-content>
