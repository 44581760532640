import { NgModule } from '@angular/core';
import { NToolModule } from '@app/shared/n-tool/n-tool.module';
import { SharedModule } from '@app/shared/shared.module';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { BlightModule } from '../blight/blight.module';
import { NToolsModule } from '../n-tools/n-tools.module';
import { CultivationJournalMapFeatureComponent } from './cultivation-journal-map-feature.component';
import { DecisionSupportModule } from './decision-support/decision-support.module';
import { FieldInfoComponent } from './field-info/field-info.component';
import { PreCropTimelineComponent } from './pre-crop-timeline/pre-crop-timeline.component';
import { TasksModule } from './tasks/tasks.module';

@NgModule({
  imports: [SharedModule, TasksModule, DecisionSupportModule, DropDownListModule, NToolModule, NToolsModule, BlightModule],
  exports: [CultivationJournalMapFeatureComponent],
  declarations: [CultivationJournalMapFeatureComponent, PreCropTimelineComponent, FieldInfoComponent],
})
export class CultivationJournalMapFeatureModule {}
