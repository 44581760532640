<div class="info">
  <div>{{ 'main.asApplied.selectField' | translate }}</div>
  <div>'{{ field?.number }}' ({{ field?.crops?.[0] ? field?.crops?.[0]?.cropName : ('main.asApplied.noCrop' | translate) }})</div>
</div>

<ng-container *ngIf="tableData">
  <div class="no-tasks" *ngIf="!tableData.length">
    <app-icon icon="error_outline"></app-icon>
    <p class="message">{{ 'main.asApplied.noTasks' | translate }}</p>
  </div>
  <app-table
    *ngIf="tableData.length"
    [dataSource]="tableData!"
    headerAlignment="left"
    [sortFn]="sortFn"
    [displayedColumns]="$any(tableColumns$ | async)"
    [dataStyles]="tableStyles"
    [selectable]="true"
    (selected)="onTaskSelect($event)"
  >
  </app-table>
</ng-container>
