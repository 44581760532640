import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CheckOutFormComponent } from './check-out-form/check-out-form.component';
import { CheckOutSideDrawerComponent } from './check-out-side-drawer/check-out-side-drawer.component';
import { CheckOutTableComponent } from './check-out-table/check-out-table.component';
import { FarmSelectorComponent } from './farm-selector/farm-selector.component';
import { FieldInfoContentComponent } from './field-info/field-info-content/field-info-content.component';
import { FieldInfoElementComponent } from './field-info/field-info-content/field-info-element/field-info-element.component';
import { FieldInfoComponent } from './field-info/field-info.component';
import { FieldTableComponent } from './field-table/field-table.component';
import { FieldsComponent } from './fields/fields.component';
import { GrowthRegulationLegendContentComponent } from './growth-regulation-legend-content/growth-regulation-legend-content.component';
import { GrowthRegulationComponent } from './growth-regulation.component';
import { GrowthRegulationService } from './growth-regulation.service';
import { InsuranceButtonComponent } from './insurance-button/insurance-button.component';
import { InfoDialogContentComponent } from './insurance-info/info-dialog-content/info-dialog-content.component';
import { InsuranceInfoComponent } from './insurance-info/insurance-info.component';
import { InsuranceSideDrawerComponent } from './insurance-side-drawer/insurance-side-drawer.component';
import { GrowthRegulationRepository } from './repository/growth-regulation.repository';

@NgModule({
  imports: [SharedModule],
  providers: [GrowthRegulationService, GrowthRegulationRepository],
  declarations: [
    GrowthRegulationComponent,
    FieldsComponent,
    GrowthRegulationLegendContentComponent,
    FieldInfoComponent,
    FieldInfoContentComponent,
    FieldInfoElementComponent,
    FieldTableComponent,
    FarmSelectorComponent,
    InsuranceInfoComponent,
    InfoDialogContentComponent,
    CheckOutFormComponent,
    CheckOutTableComponent,
    CheckOutSideDrawerComponent,
    InsuranceSideDrawerComponent,
    InsuranceButtonComponent,
    CheckOutTableComponent,
  ],
  exports: [
    FieldsComponent,
    GrowthRegulationComponent,
    FieldInfoComponent,
    InfoDialogContentComponent,
    CheckOutSideDrawerComponent,
    InsuranceSideDrawerComponent,
    FieldTableComponent,
  ],
})
export class GrowthRegulationModule {}
