<button
  class="transparent-btn"
  (click)="onClick()"
  mat-raised-button
  type="{{ type }}"
  [ngStyle]="buttonStyle"
  [ngClass]="buttonClass"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
