import { Component } from '@angular/core';
import { MapService } from '@app/core/map/map.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-map-credits',
  templateUrl: './map-credits.component.html',
  styleUrls: ['./map-credits.component.scss'],
})
export class MapCreditsComponent {
  public airPhotoIsVisible$: Observable<boolean>;

  constructor(private mapComponent: MapService) {
    this.airPhotoIsVisible$ = this.mapComponent.airPhotoIsVisible$;
  }
}
