<app-fab icon="field_analysis" (click)="openSideDrawer()"></app-fab>
<app-ndvi-date-picker
  *ngIf="(shownComponentState$ | async)?.shownComponent === fieldAnalysisShownComponentEnum.NdviFeatureComponent"
></app-ndvi-date-picker>

<!-- NDVI and As applied legend -->
<app-legend
  *ngIf="(showLegendState$ | async)?.showLegend && (scaleLegendSettingsState$ | async)?.legendSettings?.items?.length"
  height="100%"
  [sideDrawerWidth]="(sideDrawerWidth$ | async)!"
>
  <app-scale-legend [settings]="(scaleLegendSettingsState$ | async)!.legendSettings"></app-scale-legend>
</app-legend>

<!-- SoilSample legend -->
<app-legend
  *ngIf="
    (shownComponentState$ | async)?.shownComponent === fieldAnalysisShownComponentEnum.SoilSampleMapFeatureComponent &&
    (soilSampleLegendState$ | async)?.soilSampleLegend &&
    (legendNotExcludedState$ | async)
  "
  [sideDrawerWidth]="(sideDrawerWidth$ | async)!"
>
  <app-soil-sample-legend [legend]="(soilSampleLegendState$ | async)!.soilSampleLegend"></app-soil-sample-legend>
</app-legend>
