<app-tasks-filter (operationTypeIdSelect)="onOperationTypeSelect($event)"></app-tasks-filter>
<div class="wrapper">
  <app-tasks-crop-filter [field]="field" (cropIdSelect)="onCropIdSelect($event)"></app-tasks-crop-filter>
  <div class="inner-wrapper">
    <app-cards-or-table-toggle [standalone]="true" #toggle></app-cards-or-table-toggle>
    <div class="tasks-grid">
      <ng-container *ngIf="filteredTasks as tasks; else loadingSpinner">
        <ng-container *ngIf="!(loading$ | async)?.isLoading; else loadingSpinner">
          <app-cards-or-table
            displayMode="flat"
            multi="true"
            noElevation="true"
            [presentationMode]="toggle.value"
            [hidePresentationModeSwitch]="true"
            [fieldDefinitions]="fieldDefinitions"
            [actionDefinitions]="actionDefinitions"
            [cardRef]="taskCard"
            [tableRef]="taskTable"
            (action)="onTableAction($event)"
            [data]="filteredTasks"
          >
          </app-cards-or-table>
        </ng-container>
      </ng-container>
      <ng-template #loadingSpinner>
        <app-spinner [message]="(loading$ | async)?.message" [hasBackdrop]="true"></app-spinner>
      </ng-template>
    </div>
  </div>
  <app-tooltip
    class="add-task-btn mat-elevation-z2"
    [tooltipText]="(noWriteAccess$ | async) ? ('operationSelector.disabled' | translate) : undefined"
  >
    <app-square-button [buttonType]="'Primary'" [disabled]="noWriteAccess$ | async" (click)="onAddTaskClick()">
      <app-icon icon="add" class="white-text">add</app-icon>
      {{ 'main.cultivationJournal.fieldTasks.addTask' | translate }}
    </app-square-button>
  </app-tooltip>
</div>

<ng-template #taskTable>
  <app-task-table
    [tasks]="filteredTasks"
    [disable]="(noWriteAccess$ | async) === true"
    (itemEdit)="onTaskEditClick($event)"
    (itemDelete)="onDelete($event)"
    (itemComplete)="completeTask($event)"
    (itemSchedule)="scheduleTask($event)"
  ></app-task-table>
</ng-template>

<ng-template let-task="task" #taskCard>
  <app-task-card
    [task]="task"
    [disable]="(noWriteAccess$ | async) === true"
    (itemEdit)="onTaskEditClick($event)"
    (itemDelete)="onDelete($event)"
    (itemComplete)="completeTask($event)"
    (itemSchedule)="scheduleTask($event)"
  ></app-task-card>
</ng-template>
