import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/storage/storage.service';
import { LocalState } from '@app/helpers/local-state';
import { filterNullish } from '@app/shared/operators';
import { map, take } from 'rxjs/operators';

const MENU_STATE = 'MENU_STATE';

@Injectable({
  providedIn: 'root',
})
export class AppLayoutService {
  private menuState = new LocalState<{ menuMinimized: boolean } | null>(null);
  public menuMinimized$ = this.menuState.changes$.pipe(
    filterNullish(),
    map((s) => s.menuMinimized)
  );

  constructor(private storage: StorageService) {
    const menuMinimized: boolean = storage.getFromStorage(MENU_STATE) ?? false;

    this.menuState.setState({ menuMinimized });
  }

  public toggleMenuMimized() {
    this.menuMinimized$.pipe(take(1)).subscribe((m) => {
      this.menuState.setState({ menuMinimized: !m });
      this.storage.saveToStorage(MENU_STATE, !m);
    });
  }
}
