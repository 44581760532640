import { SoilSampleShortName } from '@app/map/features/field-analysis/features/soil-sample-feature/soil-sample-side-drawer/soil-sample-short-name.enum';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

const typesWithWhiteTextAtHighValues = [
  SoilSampleShortName.Boron,
  SoilSampleShortName.ClayPercentage,
  SoilSampleShortName.Humus,
  SoilSampleShortName.SoilType,
];
const colorWhite = '#ffffff';
const colorBlack = '#000000';

export const SoilSampleLabelsStyle = {
  getLabelStyle: (feature: Feature, zoom: number = 13) => {
    const text = feature.get('value');
    const numericValue = Number(text);
    const sampleType = feature.get('sample-type');

    let color = typesWithWhiteTextAtHighValues.includes(sampleType) && numericValue > 7 ? colorWhite : colorBlack;
    // Exceptions to the rule above
    if (sampleType === SoilSampleShortName.SoilType) {
      if (numericValue === 6 || numericValue === 7) {
        color = colorWhite;
      } else if (numericValue === 8 || numericValue === 11) {
        color = colorBlack;
      }
    }

    return [
      new Style({
        text: new Text({
          text,
          fill: new Fill({
            color,
          }),
          stroke: new Stroke({
            color: color === colorWhite ? colorBlack : colorWhite,
            width: 2,
          }),
          scale: 1.2,
        }),
        zIndex: 11,
      }),
    ];
  },
};
