<div class="fixed-action-btn">
  <app-square-button
    [buttonType]="'Primary'"
    *ngFor="let mapCoverFlowItem of mapCoverFlowItems"
    class="cover-buttons"
    (click)="onToggleCoverFlowItem(mapCoverFlowItem)"
    [class.active]="mapCoverFlowItem.isVisible"
    [buttonStyle]="{
      backgroundColor: 'rgba(78, 128, 141, 0.4)',
      borderColor: 'white',
      fontWeight: 'normal',
      textShadow: '1px 1px 1px #000'
    }"
  >
    {{ mapCoverFlowItem.displayName! | translate }}</app-square-button
  >
</div>
