<div>
  <div class="header-close">
    <h2 mat-dialog-title>{{ 'footer.title' | translate }}</h2>
    <app-icon icon="Luk" (click)="close()" class="close-btn">
      {{ 'common.close' | translate }}
    </app-icon>
  </div>
  <mat-dialog-content [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <div>
      <div class="partner">
        <img src="assets/images/sponsor/innovationsfonden.png" />
        <h3>{{ 'footer.supportedBy' | translate }} Innovationsfonden</h3>
      </div>
      <div class="partner">
        <img src="assets/images/sponsor/gudp.png" />
        <h3>{{ 'footer.supportedBy' | translate }} gudp</h3>
      </div>
    </div>
    <div>
      <div class="partner">
        <img src="assets/images/sponsor/copernicus.png" />
        <h3>Copernicus Sentinel data</h3>
      </div>
      <div class="partner">
        <img src="assets/images/sponsor/promilleafgiftsfonden.png" />
        <h3>{{ 'footer.supportedBy' | translate }} Promilleafgiftsfonden</h3>
      </div>
    </div>
  </mat-dialog-content>
</div>
