import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-field-item',
  templateUrl: './field-item.component.html',
  styleUrls: ['./field-item.component.scss'],
})
export class FieldItemComponent {
  @Input() public fieldNumber?: string;
  @Input() public fieldName?: string;
  @Input() public fieldCrop?: string;
  @Input() public fieldCropSort?: number;
  @Input() public includeCrop?: boolean;
  @Input() public isIncluded?: boolean;
  @Output() public onRemove = new EventEmitter<string>();
  @Output() public onAdd = new EventEmitter<string>();
  @Output() public onIncludeCrop = new EventEmitter<boolean>();

  public add() {
    this.onAdd.emit();
  }

  public remove() {
    this.onRemove.emit();
  }

  public includeCropChange($event: MatCheckboxChange) {
    this.onIncludeCrop.emit($event.checked);
  }
}
