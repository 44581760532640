import { WebGLStyle } from 'ol/style/webgl';
import { WfsHeightLayerUrl, WfsLayerUrl } from './wfs-layer-url';

export interface WfsLayer {
  url: WfsLayerUrl;
  style: WebGLStyle;
}
export class WfsLayerConfig {
  static readonly fieldShrubberyLayer = {
    url: WfsLayerUrl.fieldShrubberyLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#000080',
      'fill-color': 'rgba(0, 0, 128 ,0.3)',
    },
  } as WfsLayer;

  static readonly GLMAncientMonumentsLayer = {
    url: WfsLayerUrl.GLMAncientMonumentsLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#932092',
      'fill-color': 'rgba(147, 32, 146,0.3)',
    },
  } as WfsLayer;

  static readonly GLMLakesLayer = {
    url: WfsLayerUrl.GLMLakesLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#0432FF',
      'fill-color': 'rgba(4, 50, 255,0.3)',
    },
  } as WfsLayer;

  static readonly ThreeMeterBufferLayer = {
    url: WfsLayerUrl.ThreeMeterBufferLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#FEFB00',
      'fill-color': 'rgba(50, 168, 82, 0.3)',
    },
  } as WfsLayer;

  static readonly WatercoursesLayerLayer = {
    url: WfsLayerUrl.WatercoursesLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#0432FF',
      'fill-color': 'rgba(4, 50, 255,0.3)',
    },
  } as WfsLayer;

  static readonly NatureLayerLayer = {
    url: WfsLayerUrl.NatureLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#FF9200',
      'fill-color': 'rgba(255, 146, 0,0.3)',
    },
  } as WfsLayer;

  static readonly BNBOLayer = {
    url: WfsLayerUrl.BNBOLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#AA7941',
      'fill-color': 'rgba(170, 121, 65,0.3)',
    },
  } as WfsLayer;

  static readonly ProtectedStoneAndEarthLayer = {
    url: WfsLayerUrl.ProtectedStoneAndEarthLayerUrl,
    style: {
      'stroke-width': 2,
      'stroke-color': '#FF2600',
      'fill-color': 'rgba(255, 38, 0, 0.3)',
    },
  } as WfsLayer;
}
