import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppUpdaterService } from '@app/core/updater/app-updater.service';
import { NavigationComponent } from '@app/layouts/navigation/navigation.component';
import { NavigationService } from '@app/layouts/navigation/navigation.service';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
  imports: [RouterModule, SharedModule],
  providers: [NavigationService, AppUpdaterService],
})
export class NavigationModule {}
