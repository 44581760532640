<div class="growth-curve-content">
  <h5 class="no-selection" *ngIf="!(selectedNdviField$ | async)">
    {{ 'mapFieldInspectorItem.ndviInfo.noSelectedField' | translate }}
  </h5>

  <h5 *ngIf="(selectedNdviField$ | async) && (selectedNdviHistory$ | async)" data-testid="section-header">{{ directorateCropNormName }}</h5>

  <p>{{ (selectedNdviField$ | async)?.number }} {{ (selectedNdviField$ | async)?.name }}</p>

  <ng-container *ngIf="selectedNdviHistory$ | async as ndviHistory">
    <p *ngIf="ndviHistory.catchCropPrevYearCropName" class="prev-crop-year-name">
      {{ ndviHistory.catchCropPrevYearCropName }} ({{ 'mapFieldInspectorItem.ndviInfo.fall' | translate }}
      {{ ndviHistory.harvestYear! - 1 }})
    </p>
    <p *ngIf="ndviHistory.catchCropPrevYearNConsumption" class="prev-crop-year-n">
      {{ ndviHistory.catchCropPrevYearNConsumption | unit }} {{ 'mapFieldInspectorItem.ndviInfo.kgNAbsorbed' | translate }}
    </p>
  </ng-container>

  <p *ngIf="(selectedNdviField$ | async) && !(selectedNdviHistory$ | async)">
    {{ 'mapFieldInspectorItem.ndviInfo.missingNdvi' | translate }}
  </p>

  <ng-container *ngIf="selectedNdviHistory$ | async">
    <kendo-chart [categoryAxis]="categoryAxis" [valueAxis]="valueAxis" (render)="onRender($event)">
      <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
      <kendo-chart-tooltip
        [shared]="true"
        format="{{ 'mapFieldInspectorItem.ndviInfo.date' | translate }}
        {0:{{ 'mapFieldInspectorItem.ndviInfo.formatXAxisTooltipDate' | translate }}}
    <br>{{ 'mapFieldInspectorItem.ndviInfo.value' | translate }} {1:0.00}"
      >
      </kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          *ngFor="let dataSerie of dataSeries"
          type="scatterLine"
          [style]="'smooth'"
          [data]="dataSerie.data"
          [name]="dataSerie.name | translate"
          xField="date"
          yField="ndvi"
        >
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
    <div>
      {{ 'mapFieldInspectorItem.ndviInfo.infoText1A' | translate }} {{ benchmarkCropCountAverage }}
      {{ 'mapFieldInspectorItem.ndviInfo.infoText1B' | translate }}
    </div>
  </ng-container>
</div>
