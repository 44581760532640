<div class="field-info-root">
  <div *ngIf="(harvestYear$ | async)! < 2018">
    <div class="wrapper">
      <div class="main">
        <div class="placeholder">
          <app-icon icon="error_outline"></app-icon>
          <div>{{ 'main.growthRegulation.before2018' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!((harvestYear$ | async)! < 2018) && !field && !isGrowthRegulationsEmpty">
    <div class="wrapper">
      <div class="main">
        <div class="placeholder">
          <app-icon icon="map"></app-icon>
          <div>{{ 'main.growthRegulation.noFieldSelected.body' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!((harvestYear$ | async)! < 2018) && field && !growthRegulation">
    <div class="wrapper">
      <div class="main">
        <div class="placeholder">
          <app-icon icon="error_outline"></app-icon>
          <div>{{ 'main.growthRegulation.noFieldData.body' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!((harvestYear$ | async)! < 2018) && !field && isGrowthRegulationsEmpty">
    <div class="wrapper">
      <div class="main">
        <div class="placeholder">
          <app-icon icon="error_outline"></app-icon>
          <div>{{ 'main.growthRegulation.noFieldsWithCropData.noDataHarvestYear' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!((harvestYear$ | async)! < 2018) && field && growthRegulation">
    <app-field-info-content [field]="field" [growthRegulation]="growthRegulation"></app-field-info-content>
  </div>
</div>
