import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IScreenSizeService, ScreenSize } from './screen-size.interface';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService implements IScreenSizeService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public getSize(): Observable<ScreenSize> {
    return this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(
        map((state: BreakpointState) => {
          if (state.breakpoints[Breakpoints.XSmall]) {
            return 'xs';
          } else if (state.breakpoints[Breakpoints.Small]) {
            return 'sm';
          } else if (state.breakpoints[Breakpoints.Medium]) {
            return 'md';
          } else if (state.breakpoints[Breakpoints.Large]) {
            return 'lg';
          } else if (state.breakpoints[Breakpoints.XLarge]) {
            return 'xl';
          }
          return 'lg'; // Default or error case
        })
      );
  }

  public isActive(query: ScreenSize): boolean {
    const breakpointMap = {
      xs: Breakpoints.XSmall,
      sm: Breakpoints.Small,
      md: Breakpoints.Medium,
      lg: Breakpoints.Large,
      xl: Breakpoints.XLarge,
    };

    const breakpoint = breakpointMap[query];
    return this.breakpointObserver.isMatched(breakpoint);
  }

  public isMobile(): Observable<boolean> {
    return this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map((result) => result.matches));
  }

  public isTablet(): Observable<boolean> {
    return this.breakpointObserver.observe([Breakpoints.Small]).pipe(map((result) => result.matches));
  }

  public isPortable(): Observable<boolean> {
    return this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(map((result) => result.matches));
  }
}
