import { formatNumber } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { LegendColorValue } from '@app/core/interfaces/legend-color-value.interface';
import { ScaleLegendItem } from '@app/core/interfaces/scale-legend-item.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { AsAppliedMetadataHelper } from '@app/helpers/as-applied-metadata-helper.ts';
import { DownloadHelper } from '@app/helpers/file/download-helper';
import { AsAppliedShownComponentEnum } from '@app/map/features/field-analysis/features/as-applied/as-applied-shown-component.enum';
import { OperationTypeGroupTaskCollection } from '@app/map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/meta-data-child-form/meta-data-child-form.component';
import { AsAppliedFileUploadService } from '@app/map/features/field-analysis/features/as-applied/file-upload/services/as-applied-file-upload.service';
import { ColorService } from '@app/new-map/features/vra/state/color.state';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { filterNullish } from '@app/shared/operators';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { BehaviorSubject, forkJoin, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, first, map, skip, tap } from 'rxjs/operators';
import { FieldAnalysisSideDrawerService } from '../../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { AddAdditionalLogsModalComponent } from '../add-additional-logs-modal/add-additional-logs-modal.component';
import { AsAppliedService } from '../as-applied.service';
import { ExecutedLocationCollection, ExecutedTask } from '../executed-task.class';
import { MetadataGeometry } from '../file-upload/metadata-parent';
import { MetadataMapService } from '../file-upload/services/metadata-map.service';
import { AsAppliedTaskDetailsService } from './services/as-applied-task-details.service';

@Component({
  selector: 'app-as-applied-task-details',
  templateUrl: './as-applied-task-details.component.html',
  styleUrls: ['./as-applied-task-details.component.scss'],
  providers: [AsAppliedTaskDetailsService],
})
export class AsAppliedTaskDetailsComponent implements OnInit, OnDestroy {
  @Input() public appliedTasks: ExecutedLocationCollection[] | null = null;
  @Input() public possibleAdditionLogs: ExecutedLocationCollection[] | null = [];
  public linkToTaskMode = false;

  public taskSuggestions$ = new ReplaySubject<OperationTypeGroupTaskCollection[]>(1);

  public legendSettings: ScaleLegendSettings = {
    title: '',
    items: [],
    visible: true,
    unit: 'kg',
  };

  private _mapLayerId!: BehaviorSubject<MapLayerId>;

  private subscriptions = new Subscription();

  public multipleLogs = false;

  public logSelectForm!: FormGroup;

  public executedTasks: ExecutedTask[] = [];
  private _anySelectedSubject = new BehaviorSubject(true);
  protected anySelected$ = this._anySelectedSubject.asObservable();

  constructor(
    public asAppliedService: AsAppliedService,
    private harvestYearStateService: HarvestYearStateService,
    private metadataMapService: MetadataMapService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private languageService: LanguageService,
    private vraColorService: ColorService,
    private asAppliedTaskDetailsService: AsAppliedTaskDetailsService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private asAppliedFileUploadService: AsAppliedFileUploadService,
    private fb: FormBuilder
  ) {}

  public ngOnInit() {
    if (this.appliedTasks !== null && this.appliedTasks.length > 1) {
      this.multipleLogs = true;
    }

    this.logSelectForm = this.fb.group({
      logs: this.fb.array(
        this.appliedTasks!.map((task) => {
          const logTimes = this.calculateLogStartAndEnd(task.locations);
          const group = this.fb.group({
            selected: true,
            logName: task.fileName,
            startTime: logTimes.start,
            endTime: logTimes.end,
            log: task,
          });

          return group;
        })
      ),
    });
    this.logSelectForm.valueChanges.pipe(debounceTime(10)).subscribe(() => {
      this.updateMapAndSelectedWithSelections();

      this._anySelectedSubject.next(this.isAnySelected());
    });

    this.asAppliedFileUploadService
      .getSelectableTasks(this.appliedTasks?.first()?.executedTask.farmId!)
      .pipe(
        map((tasks) =>
          AsAppliedMetadataHelper.groupAndSortTasks(
            tasks.filter((task) => task.fieldId === this.appliedTasks?.first()?.executedTask.fieldYearId),
            this.languageService
          )
        )
      )
      .subscribe((taskSuggestions) => this.taskSuggestions$.next(taskSuggestions));

    this.executedTasks = this.appliedTasks?.map((log) => log.executedTask) ?? [];
    const geometryType = !this.multipleLogs ? this.appliedTasks?.first()?.geometryType! : 'Point';
    const id = this.metadataMapService.getMapLayerId(geometryType);
    this._mapLayerId = new BehaviorSubject<MapLayerId>(id!);

    const locations = this.appliedTasks?.flatMap((appliedMap) => appliedMap.locations);
    const legend = this.vraColorService.getAsAppliedLegendColorValues(locations);

    this.vraColorService.setAsAppliedPointsAndCellColors(locations, legend);

    this.setLegendSettings(legend);

    this.metadataMapService.addLocationsToMap(locations, this.appliedTasks?.first()?.containedAttributes[0].unit);

    this.metadataMapService.airPhotoIsVisible$.subscribe((airPhotoIsVisible: boolean) => {
      if (airPhotoIsVisible === true) {
        this.asAppliedService.loading(false, 'main.asApplied.loadingMapMessage');
      }
    });

    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.pipe(skip(1)).subscribe(() => {
        this.fieldAnalysisSideDrawerService.setShowLegendState(false);
        this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private calculateLogStartAndEnd(locations: MetadataGeometry[]) {
    const timestamps = locations.map((l) => l.time).filter((l) => l !== undefined);
    if (timestamps.length === 0) {
      return { start: null, end: null };
    }

    const start = timestamps.reduce((earliest, current) => {
      return current! < earliest! ? current : earliest;
    }, timestamps[0]);

    const end = timestamps.reduce((latest, current) => {
      return current! > latest! ? current : latest;
    }, timestamps[0]);

    return { start: start, end: end };
  }

  public AddAdditionalLogs() {
    const data = { tasks: this.possibleAdditionLogs };
    this.dialogService
      .openCustomDialog(AddAdditionalLogsModalComponent, { data: data, disableClose: true })
      .afterClosed()
      .subscribe((result ) => {
        if (result && result.addedLogs?.length > 0) {
          const addedLogIds = result.addedLogs.map((addedLog: ExecutedLocationCollection) => addedLog.executedTaskId)
          this.possibleAdditionLogs = this.possibleAdditionLogs
          ? this.possibleAdditionLogs.filter(log => !addedLogIds.includes(log.executedTaskId))
          : null;
          // Loop over each added log and add it to the form array
          result.addedLogs.forEach((newLog: ExecutedLocationCollection) => {
            const logTimes = this.calculateLogStartAndEnd(newLog.locations);

            // Create a new FormGroup for the new log
            const newLogGroup = this.fb.group({
              selected: true,
              logName: newLog.fileName,
              startTime: logTimes.start,
              endTime: logTimes.end,
              log: newLog,
            });

            (this.logSelectForm.get('logs') as FormArray).push(newLogGroup);

            this.appliedTasks!.push(newLog);
          });

          this._anySelectedSubject.next(this.isAnySelected());
        }
      });
  }

  public downloadShapeFile() {
    if (this.multipleLogs) {
      const selectedControls = this.logArray.controls.filter((control) => control.get('selected')?.value === true);

      const logsToDownload = selectedControls.map((control) => control.get('log')?.value);
      const filename = logsToDownload.first().executedTask.fieldName;
      const farmId = logsToDownload.first().executedTask.farmId;
      this.asAppliedTaskDetailsService
        .exportShapeFiles(
          farmId,
          logsToDownload.map((log) => log.executedTaskId)
        )
        .pipe(
          filterNullish(),
          tap((file) => {
            DownloadHelper.downloadBlob(`${filename.trim().replace(/\s/g, '_')}.zip`, file); // trim string & replace all white-spaces with underscores
            this.notificationService.showSuccess('main.asApplied.taskDetails.downLoadSuccess');
          })
        )
        .subscribe();
    } else {
      this.asAppliedTaskDetailsService
        .exportShapeFile(this.appliedTasks?.first()!)
        .pipe(
          filterNullish(),
          tap((data) => {
            const fileName = `${this.appliedTasks?.first()?.executedTask.operationTypeGroup}_${
              this.appliedTasks?.first()?.executedTask.fieldName
            }_${this.appliedTasks
              ?.first()
              ?.uploadDate.toFormat(
                LanguageConstants.getAppliedFileDateFormat(this.languageService?.currentLanguage?.shortKey)['LS']
              )}_shape.zip`;
            DownloadHelper.downloadBlob(fileName, data);
          })
        )
        .subscribe((data) => {
          this.notificationService.showSuccess('main.asApplied.taskDetails.downLoadSuccess');
        });
    }
  }

  public deleteAsApplied() {
    const data = {
      title: 'main.asApplied.taskDetails.deleteDialog.title',
      text: 'main.asApplied.taskDetails.deleteDialog.text',
      confirmText: 'main.asApplied.taskDetails.deleteDialog.deleteConfirm',
      cancelText: 'main.asApplied.taskDetails.deleteDialog.cancelDelete',
    };

    if (this.multipleLogs) {
      this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => {
        const selectedTask = this.logArray.controls
          .filter((control) => control.get('selected')?.value === true)
          .map((control) => control.get('log')?.value);
        const streams = selectedTask.map((task) => this.asAppliedService.deleteExecutedTask(task).pipe(first()));
        forkJoin(streams).subscribe(() => {
          this.notificationService.showSuccess('main.asApplied.taskDetails.deleteSuccess');
          this.asAppliedService.updateTasksAndGroups();

          for (let i = this.logArray.length - 1; i >= 0; i--) {
            if (this.logArray.at(i).get('selected')?.value === true) {
              this.logArray.removeAt(i);
            }
          }

          const allDeleted = this.logArray.length === 0;
          if (allDeleted) {
            this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
          }
        });
      });
    } else {
      this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => {
        this.asAppliedService
          .deleteExecutedTask(this.appliedTasks?.first()!)
          .pipe(first())
          .subscribe(() => {
            this.notificationService.showSuccess('main.asApplied.taskDetails.deleteSuccess');
            this.asAppliedService.updateTasksAndGroups();
            this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
          });
      });
    }
  }

  public get mapLayerId$() {
    return this._mapLayerId.asObservable();
  }

  private setLegendSettings(legend: LegendColorValue[]) {
    this.fieldAnalysisSideDrawerService.setLegendSettingsState({
      ...this.legendSettings,
      title: this.appliedTasks?.first()?.containedAttributes[0].unit ?? '',
      items: legend.map((li) => {
        return {
          text: formatNumber(Number(li.value), this.languageService.currentLanguage.shortKey, '1.1-1'),
          color: li.color,
        } as ScaleLegendItem;
      }),
      operationTypeGroupId: this.appliedTasks?.first()?.executedTask.operationTypeGroupId,
    });
  }

  get logArray() {
    return this.logSelectForm.get('logs') as FormArray;
  }

  private updateMapAndSelectedWithSelections() {
    const selectedControls = this.logArray.controls.filter((control) => control.get('selected')?.value === true);

    const selectedLogs = selectedControls.map((control) => control.get('log')?.value as ExecutedLocationCollection);
    const locations = selectedLogs?.flatMap((appliedMap) => appliedMap.locations);
    const legend = this.vraColorService.getAsAppliedLegendColorValues(locations);

    this.vraColorService.setAsAppliedPointsAndCellColors(locations, legend);

    this.setLegendSettings(legend);
    this.metadataMapService.addLocationsToMap(locations, this.appliedTasks?.first()?.containedAttributes[0].unit);

    this.executedTasks = selectedLogs.map((log) => log.executedTask);
  }

  private isAnySelected() {
    return this.logArray.controls.some((control) => {
      return control.get('selected')?.value;
    });
  }
}
