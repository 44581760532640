import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BenchmarkDataService } from '@app/core/benchmark-data/benchmark-data.service';
import { FieldService } from '@app/core/field/field.service';
import { ClaasField } from '@app/core/interfaces/claas-field.interface';
import { DataConnectionSettingDTO } from '@app/core/interfaces/data-connection-setting.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { TaskService } from '@app/core/task/task.service';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { GridDataSource } from '@app/helpers/ui-helpers/grid-data-source';
import { TransferFieldViewData, TransferFieldsService } from '@app/settings/datamanagement/transfer-fields/transfer-fields.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { BehaviorSubject, Observable, Subscription, from, of } from 'rxjs';
import { concatMap, filter, first, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-fields',
  templateUrl: './transfer-fields.component.html',
  styleUrls: ['./transfer-fields.component.scss'],
})
export class TransferFieldsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) public paginator!: MatPaginator;
  public displayedColumns = ['farmName', 'number', 'name', 'area', 'mainCropName', 'status', 'transfer'];
  public dataSource!: GridDataSource<Field>;
  public fields: Field[] = [];
  public externalFields: ClaasField[] = [];
  public isLoading$: Observable<boolean> = of(false);
  public isFetching = new BehaviorSubject<boolean>(true);
  public shouldShowNoConnectionsMsg$!: Observable<boolean>;
  public dataChange = new BehaviorSubject<Field[]>([]);
  private subscriptions: Subscription = new Subscription();

  public hasClaasDataConnections = false;

  constructor(
    private benchmarkDataService: BenchmarkDataService,
    private taskService: TaskService,
    private transferFieldsService: TransferFieldsService,
    private fieldService: FieldService
  ) {}

  public async ngOnInit() {
    this.isLoading$ = this.transferFieldsService.isLoading$();
    this.shouldShowNoConnectionsMsg$ = this.isLoading$.pipe(map((loading) => !loading && !this.hasClaasDataConnections));

    this.subscriptions.add(
      this.transferFieldsService
        .getClaasSettings()
        .pipe(
          map((claasConnections: DataConnectionSettingDTO[]) => {
            if (claasConnections.length === 0) {
              this.hasClaasDataConnections = false;
            } else {
              this.hasClaasDataConnections = true;
              return claasConnections;
            }
            this.isFetching.next(false);
            return [] as DataConnectionSettingDTO[];
          }),
          filter((claasConnections: DataConnectionSettingDTO[]) => claasConnections.length > 0),
          switchMap(() => this.transferFieldsService.getData())
        )
        .subscribe((produceNormsFieldsTasks) => {
          this.onDataCompleted(produceNormsFieldsTasks);
          this.isFetching.next(false);
        })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onTransferClick(field: Field) {
    this.transferFieldsService
      .transferField(field)
      .pipe(first())
      .subscribe((claasField) => {
        this.updateFields(field, claasField);
      });
  }

  /**
   * Checks if a field is transferable.
   * @param field {Field} The field to check.
   */
  public isTransferable = (field: Field) => this.transferFieldsService.isTransferable(field);

  /**
   * Transfer all fields able to be transfered.
   */
  public transferAll() {
    from(this.fields)
      .pipe(
        filter((field) => this.isTransferable(field)),
        tap(() => {
          this.transferFieldsService.isLoading = true;
        }),
        concatMap((field) => {
          return this.transferFieldsService.transferField(field).pipe(
            first(),
            switchMap((claasField) => {
              this.updateFields(field, claasField);
              return of(claasField);
            })
          );
        })
      )
      .subscribe(() => {
        this.transferFieldsService.isLoading = false;
      });
  }

  /**
   * Updates and sorts this.fields with @field
   * Adds @claasFields to this.externalFields
   * Updates dataChange with this.fields
   * @param field
   * @param claasField
   */
  private updateFields(field: Field, claasField: ClaasField) {
    this.fields = this.updateAndSortFieldsByFieldNumber(this.fields, field);
    this.externalFields.push(claasField);
    this.dataChange.next(this.fields);
  }

  private updateAndSortFieldsByFieldNumber(fields: Field[], field?: Field) {
    const sortingFn = (fieldA: Field, fieldB: Field) => CompareHelper.compareFieldNumbers(fieldA.number, fieldB.number);

    if (!field) {
      return fields.sort(sortingFn);
    } else {
      return [...fields.filter((f) => f.id !== field.id), cloneDeep(field)].sort(sortingFn);
    }
  }

  /**
   * Callback for when all required data has been fetched.
   * @param produceNormsFieldsTasks The required data to display the table.
   */
  private onDataCompleted(produceNormsFieldsTasks: TransferFieldViewData) {
    this.externalFields = produceNormsFieldsTasks.externalFields;
    this.fields = this.fieldService.setMainCropsForFields(produceNormsFieldsTasks.fields);
    this.fields = this.taskService.mapTasksToField(produceNormsFieldsTasks.fields, produceNormsFieldsTasks.tasks);
    this.fields = this.fields.map((field) => ({
      ...field,
      selectedVarieties: this.benchmarkDataService.findSelectedVarieties(field),
    }));

    this.fields = this.updateAndSortFieldsByFieldNumber(this.fields);

    this.dataChange.next(this.fields);
    this.dataSource = new GridDataSource(this.dataChange, this.paginator);
  }
}
