import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguageService } from '@app/core/language/language.service';
import { SelectOptionGroupComponent } from '@app/shared/select/select-option-group/select-option-group.component';
import { SelectOptionGroup } from '@app/shared/select/select-option-group/select-option-group.interface';
import { SelectOptionComponent } from '@app/shared/select/select-option/select-option.component';
import { SelectOption } from '@app/shared/select/select-option/select-option.interface';
import { merge } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { isArray } from '../utils/utils';
import { SELECT_PREFIX, SelectPrefixDirective } from './select-prefix/select-prefix.component';
import { SELECT_TRIGGER, SelectTriggerDirective } from './select-trigger/select-trigger.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements AfterContentInit, ControlValueAccessor, OnChanges {
  @Input() public placeholder?: string;
  @Input() public width = '100%';
  @Input() public multiple = false;
  @Input() public disabled = false;
  @Input() public panelClass: string | string[] = '';
  @Input() public error?: string;
  @Input() public compareWith = (o1: any, o2: any) => o1 === o2;

  @Output() public valueChange = new EventEmitter();

  @ContentChild(SELECT_PREFIX) customPrefix?: SelectPrefixDirective;
  @ContentChild(SELECT_TRIGGER) customTrigger?: SelectTriggerDirective;
  @ContentChildren(SelectOptionComponent) public selectOptions?: QueryList<SelectOptionComponent>;
  @ContentChildren(SelectOptionGroupComponent) public selectOptionGroups?: QueryList<SelectOptionGroupComponent>;

  public options?: SelectOption[];
  public optionsGroups?: SelectOptionGroup[];
  public selected: any = null;
  public touched = false;

  public placeholderText$ = merge(
    this.language.onLangChange.pipe(switchMap(() => this.language.get(['common.choose']))),
    this.language.get(['common.choose']).pipe(take(1))
  ).pipe(
    map((trans) => trans['common.choose']),
    map((translation: string) => {
      const hasSelected = isArray(this.selected) ? this.selected.length > 0 : !!this.selected;
      const showTrans = !this.disabled && !hasSelected;

      if (!showTrans) return this.placeholder;
      if (showTrans && this.placeholder) return `${translation} ${this.placeholder.toLowerCase()}`;

      return '';
    })
  );

  private internalValue?: SelectOption;

  constructor(private language: LanguageService) {}

  public onChange: any = (_: any) => {
    /*Empty*/
  };

  public onTouched: any = (_: any) => {};

  public onSelected($event: any) {
    const isSame = this.selected === $event.value;

    if (isSame) {
      // this is fixing setting dirty on init
      return;
    }

    this.selected = $event.value;
    this.onChange($event.value);
    this.onTouched();
    this.valueChange.emit($event.value);
  }

  public onBlur(_event: FocusEvent) {
    this.onTouched();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      if (changes['options'].isFirstChange()) return;

      if (changes['options'].currentValue !== changes['options'].previousValue) {
        this.selected = null; // Resetting the model to show placeholder
        this.onChange(null);
      }
    }
  }

  public ngAfterContentInit() {
    this.options = this.getOptions(this.selectOptions);
    this.optionsGroups = this.getOptionGroups(this.selectOptionGroups);
    this.autoSelectIfOnlyOneOption(this.options);

    this.selectOptionGroups?.changes.subscribe(
      (optionGroups: QueryList<SelectOptionGroupComponent>) => (this.optionsGroups = this.getOptionGroups(optionGroups))
    );

    this.selectOptions?.changes.subscribe((options: QueryList<SelectOption>) => {
      this.options = options.length ? this.getOptions(options) : [];
      const optionValues = this.options.map((option) => option.value);

      if (this.multiple) {
        // If multiple is true, selected will be an array of objects, therefore we have to iterate over every selected element, and check if it exists in options
        if (!this.selected?.every((x: any) => optionValues.includes(x))) {
          this.selected = null;
        }
      } else if (!optionValues.includes(this.selected)) {
        this.selected = null;
      }

      this.autoSelectIfOnlyOneOption(this.options);
    });
  }

  private getOptionGroups(list: QueryList<SelectOptionGroupComponent> | undefined) {
    return (
      list?.map<SelectOptionGroup>((group) => ({
        label: group.label,
        options: this.getOptions(group.selectOptions),
      })) ?? []
    );
  }

  private getOptions(list: QueryList<SelectOptionComponent> | undefined): SelectOption[] {
    return (
      list?.map<SelectOption>((item: SelectOptionComponent) => ({
        ...item,
      })) ?? []
    );
  }

  private autoSelectIfOnlyOneOption(options: any) {
    if (options.length === 1 && !this.multiple) {
      // Autoselect if there is only 1 option. Use timeout to fix ChangedAfterCheckedError
      setTimeout(() => this.onSelected(options[0]), 1);
    }
  }

  // CONTROL VALUE ACCESSOR

  public writeValue(value: any): void {
    this.internalValue = value;
    this.onChange(this.internalValue);
    this.valueChange.emit(value);

    this.selected = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    const self = this;
    this.onTouched = (arg: any) => {
      self.touched = true;
      fn(arg);
    };
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
