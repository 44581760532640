<div class="loading-spinner" *ngIf="loading$ | async"></div>
<app-spinner *ngIf="loading$ | async" [hasBackdrop]="true"></app-spinner>
<app-dialog-header
  size="lg"
  [title]="'main.fieldAdministration.copyField.title' | translate"
  [showClose]="false"
  [showInfo]="true"
  [infoText]="'main.fieldAdministration.copyField.fieldCopyHeaderInfo' | translate"
></app-dialog-header>
<app-dialog-content size="lg">
  <div class="dialog-content">
    <app-field-select></app-field-select>
    <div class="dialog-divider">
      <div class="arrow"></div>
    </div>
    <app-field-modify #fieldModifyElement></app-field-modify>
  </div>
</app-dialog-content>
<app-dialog-footer
  [confirmButtonText]="'main.fieldAdministration.copyField.copyFields'"
  [cancelButtonText]="'main.fieldAdministration.copyField.cancel'"
  (cancel)="onCancelClick()"
  (confirm)="onConfirmClick()"
></app-dialog-footer>
