<app-chart-wrapper [showSpinner]="isLoading" loadingMessage="wetHoursChart.fetchingData">
  <app-square-button class="info-button" (click)="toggleInfo()">
    <app-icon class="info" icon="info_outline"></app-icon>
    {{ 'wetHoursChart.openInfo' | translate }}
  </app-square-button>
  <kendo-chart
    [transitions]="false"
    [categoryAxis]="categoryAxis"
    [valueAxis]="valueAxis"
    [pannable]="{ lock: 'y' }"
    [zoomable]="{ mousewheel: { lock: 'y' } }"
    data-testid="wetHoursChart.kendo-chart"
  >
    <kendo-chart-tooltip>
      <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
        {{ category | dateFormat }}
        <br />
        {{ value }} {{ 'common.unitType.hours' | translate }}
      </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend position="bottom" orientation="horizontal" [item]="{ visual: legendItemVisual }"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="line"
        color="#4E808D"
        name="{{ 'wetHoursChart.observed' | translate }}"
        [markers]="seriesMarkers"
        [data]="observed"
        field="accumulatedWetHours"
        categoryField="dateDay"
      >
      </kendo-chart-series-item>
      <kendo-chart-series-item
        type="line"
        color="#4E808D"
        name="{{ 'wetHoursChart.forecast' | translate }}"
        [markers]="seriesMarkers"
        [data]="forecast"
        field="accumulatedWetHours"
        categoryField="dateDay"
        dashType="dash"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <div *ngIf="!isLoading && observed.length + forecast.length <= 0" class="no-data">{{ 'wetHoursChart.noData' | translate }}</div>
</app-chart-wrapper>
