<app-spinner *ngIf="isLoading" [hasBackdrop]="false" [message]="'growthStage.fetchingData' | translate"></app-spinner>
<app-spinner *ngIf="isGrowthStageSaving" [hasBackdrop]="true" [message]="'growthStage.savingData' | translate"></app-spinner>
<ng-container *ngIf="!isLoading">
  <kendo-chart
    [transitions]="false"
    (seriesClick)="updateGrowthStage($event)"
    [valueAxis]="valueAxis"
    [style.cursor]="cursor"
    (seriesOver)="setCursorMouseOver($event)"
    (seriesLeave)="setCursorMouseLeave()"
    data-testid="growthStage.kendo-chart"
  >
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        name="resultsCategoryAxis"
        [baseUnit]="categoryAxis.baseUnit!"
        [type]="categoryAxis.type!"
        [labels]="categoryAxis.labels!"
        [majorGridLines]="categoryAxis.majorGridLines!"
      >
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        *ngIf="prognosis.growthStageListObservations && prognosis.growthStageListObservations.length > 0"
        [name]="'growthStage.chartSeriesLegend.calculatedGrowthStage' | translate"
        categoryAxis="resultsCategoryAxis"
        [data]="prognosis.growthStageListObservations"
        field="growthStage"
        categoryField="date"
        type="line"
        [color]="prognosisAttributeValue.colors.observationLineColor"
        [markers]="prognosisMarkers"
        [width]="prognosisAttributeValue.lineWidths.observationLineWidth"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [visible]="isTooltipVisible" [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template let-value="value" let-category="category">
            <div class="tooltip-root">
              <h5 class="tooltip-title">{{ category | dateFormat }}</h5>
              <div class="val">
                {{ value | number: '1.0-0' }}
              </div>
              <br />
              {{ 'growthStage.chartSeries.tooltipText' | translate }}
            </div>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      <kendo-chart-series-item
        *ngIf="prognosis.growthStageListWithCorrections && prognosis.growthStageListWithCorrections.length > 0"
        [name]="'growthStage.chartSeriesLegend.correctedGrowthStage' | translate"
        categoryAxis="resultsCategoryAxis"
        [data]="prognosis.growthStageListWithCorrections"
        field="growthStage"
        categoryField="date"
        type="line"
        [color]="correctionsAttributeValue.colors.correctionLineColor"
        [markers]="correctionsMarkers"
        [width]="correctionsAttributeValue.lineWidths.correctionLineWidth"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template let-value="value" let-category="category">
            <div class="tooltip-root">
              <h5 class="tooltip-title">{{ category | dateFormat }}</h5>
              <div class="val">
                {{ value | number: '1.0-0' }}
              </div>
            </div>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      <kendo-chart-series-item
        *ngIf="prognosis.corrections && prognosis.corrections.length > 0"
        categoryAxis="resultsCategoryAxis"
        [data]="prognosis.corrections"
        field="growthStage"
        categoryField="dateTime"
        type="line"
        [color]="correctionsAttributeValue.colors.correctionLineColorTransparent"
        [markers]="correctionPointMarkers"
        [width]="correctionsAttributeValue.lineWidths.correctionLineWidth"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template let-value="value" let-category="category">
            <div class="tooltip-root">
              <h5 class="tooltip-title">{{ category | dateFormat }}</h5>
              <div class="val">
                {{ value | number: '1.0-0' }}
              </div>
            </div>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      <kendo-chart-series-item
        *ngIf="prognosis.growthStageListPrognosis && prognosis.growthStageListPrognosis.length > 0"
        [name]="'growthStage.chartSeriesLegend.prognosisGrowthStage' | translate"
        categoryAxis="resultsCategoryAxis"
        [data]="prognosis.growthStageListPrognosis"
        field="growthStage"
        categoryField="date"
        type="line"
        [color]="listPrognosisAttributeValue.colors.prognosisLineColor"
        [markers]="prognosisMarkers"
        [width]="listPrognosisAttributeValue.line.prognosisLineWidth"
        [opacity]="listPrognosisAttributeValue.opacities.notTansparent"
        [dashType]="listPrognosisAttributeValue.line.dashType"
      >
        <kendo-chart-series-item-tooltip [background]="listPrognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template let-value="value" let-category="category">
            <div class="tooltip-root">
              <h5 class="tooltip-title">{{ category | dateFormat }}</h5>
              <div class="val">
                {{ value | number: '1.0-0' }}
              </div>
            </div>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</ng-container>
<div *ngIf="errorMessage && errorMessage.length" class="error-message">
  <p>{{ errorMessage }}</p>
</div>
