import { Pipe, PipeTransform } from '@angular/core';
import { FarmService } from '@app/core/farm/farm.service';
import { of } from 'rxjs';

@Pipe({
  name: 'farmName',
})
export class FarmNamePipe implements PipeTransform {
  constructor(private farmService: FarmService) {}

  public transform(value: number, args?: any): any {
    if (!value) {
      return of('');
    }
    return this.farmService.findFarmNameById(value);
  }
}
