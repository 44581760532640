import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BenchmarkTypeId } from '@app/core/enums/benchmark-types.enum';
import { BenchmarkType } from '@app/core/interfaces/benchmark-type.interface';
import { LanguageService } from '@app/core/language/language.service';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { Observable, Subscription } from 'rxjs';

const ANIMATION_DELAY = 250;

@Component({
  selector: 'app-benchmark-sidedrawer',
  templateUrl: './benchmark-sidedrawer.component.html',
  styleUrls: ['./benchmark-sidedrawer.component.scss'],
  animations: [
    trigger('contentVisibility', [
      transition(':enter', [style({ opacity: 0 }), animate(`${ANIMATION_DELAY}ms ease`, style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate(`${ANIMATION_DELAY}ms ease`, style({ opacity: 0 }))]),
    ]),
  ],
})
export class BenchmarkSidedrawerComponent implements OnInit, OnDestroy {
  public readonly benchmarkTypes$: Observable<BenchmarkType[]> = this.benchmarkStateService.benchmarkTypes$;

  public benchmarkTypesControl = new UntypedFormControl();
  public selectedBenchmarkType!: BenchmarkType;
  public benchmarkTypes: BenchmarkType[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private languageService: LanguageService,
    private benchmarkStateService: BenchmarkStateService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.benchmarkTypes$.subscribe((benchmarkTypes) => {
        this.benchmarkTypes = benchmarkTypes.map((benchmark) => ({
          ...benchmark,
          name: this.languageService.getText(benchmark.name),
        }));
      }),
      this.benchmarkTypesControl.valueChanges.subscribe((benchmarkType: BenchmarkType) => this.onBenchmarkTypeSelected(benchmarkType))
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public onBenchmarkTypeSelected = (type: BenchmarkType) => {
    this.setSelectedBenchmarkType(type);
    this.selectedBenchmarkType = type;
  };

  public isEconomyBenchmarkType = () => (this.selectedBenchmarkType ? this.selectedBenchmarkType.id === BenchmarkTypeId.ECONOMY : false);
  public isYieldBenchmarkType = () => (this.selectedBenchmarkType ? this.selectedBenchmarkType.id === BenchmarkTypeId.YIELD : false);

  public setSelectedBenchmarkType(type: BenchmarkType) {
    this.benchmarkStateService.selectedBenchmarkType = type;
  }
}
