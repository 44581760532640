import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Farm } from '@app/core/interfaces/farm.interface';
import { MapConfig } from '@app/core/interfaces/map-config.interface';
import { MapZoomStateService } from '@app/core/map-zoom-state/map-zoom-state.service';
import { MapConstants } from '@app/core/map/map.constants';
import { MapService } from '@app/core/map/map.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { SessionService } from '@app/core/session/session.service';
import { MapSelector } from '@app/map/map.selector';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import MapEvent from 'ol/MapEvent';
import { transform } from 'ol/proj';
import { Subject, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { MapPdfService } from './map-pdf.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) public map!: OpenLayersMapComponent;

  public mapControlsVisible$ = new Subject<boolean>();
  public isCreatingPDF$ = this.mapPdfService.isCreatingPDF$;

  public mapConfig: MapConfig = {
    airphotoMaxZoomLevel: 12,
    center: this.sessionService.fieldMapCenter,
    defaultZoom: this.sessionService.fieldMapZoom,
    maxZoom: 20,
    minZoom: 7,
    shouldShowAirphoto: true,
  };

  public isMapReady = false;
  public isMapSpinnerVisible = false;
  protected fieldFeaturesChanged$ = this.mapService.fieldFeaturesChanged$;
  private subscriptions: Subscription[] = [];
  constructor(
    public mapService: MapService,
    private mapStateService: MapZoomStateService,
    private sessionService: SessionService,
    private farmStateService: FarmStateService,
    private accessControlService: AccessControlService,
    private mapPdfService: MapPdfService,
    protected hostElement: ElementRef
  ) {}

  public ngOnInit(): void {
    this.mapService.mapReady$.pipe(first()).subscribe(() => {
      this.mapService.fitMapToFieldFeatures();
    });
  }

  public ngOnDestroy(): void {
    this.map.cleanup();
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public handleMapReady() {
    setTimeout(() => {
      this.mapService.setMap(this.map);
      this.initMapSubscriptions();
      this.isMapReady = true;
    });
  }

  /**
   * Init store subscriptions
   */
  private initMapSubscriptions(): void {
    this.setupFieldFeaturesSubscriptions();
  }

  private setupFieldFeaturesSubscriptions() {
    this.subscriptions.push(
      this.farmStateService.fieldFeatures$
        .pipe(
          tap(() => (this.isMapSpinnerVisible = true)),
          filterNullish()
        )
        .subscribe((featureModels) => {
          this.mapService.onFieldFeaturesChange(
            featureModels.fieldFeatures,
            featureModels.extent as [number, number, number, number],
            this.map
          );

          this.isMapSpinnerVisible = false;
          this.mapService.mapReadySubject.next();
        })
    );
  }
  /**
   * Eventhandler for user change map position
   * @param event openlayers mapevent
   */
  public onMapMoved(event: MapEvent): void {
    // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.mapStateService.fieldMapZoom = event.map.getView().getZoom();
    const center = event.map.getView().getCenter();

    // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.mapStateService.fieldMapCenter = transform(center, MapConstants.mapProjection, MapConstants.dataProjection) as [number, number];
  }
}
