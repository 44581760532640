<div class="wrapper">
  <div
    class="crop waves-effect"
    *ngFor="let crop of field.crops | sort: 'successionNo'"
    [class.active]="isSelectedCrop(crop)"
    (click)="selectCrop(crop)"
  >
    {{ crop.cropName }}
  </div>
  <div class="filler"></div>
</div>
