import { UntypedFormControl } from '@angular/forms';

export function IsValueBetweenZeroAndMax(maxValue: number): any {
  return (control: UntypedFormControl) => {
    if (!control.value?.length) {
      return null;
    }
    if (control.value < 0) {
      return {
        valueLessThanZero: {
          valid: false,
        },
      };
    }
    if (control.value > maxValue) {
      return {
        valueLargerThanMax: {
          valid: false,
        },
      };
    }
    return true;
  };
}

export function IsInteger(): any {
  return (control: UntypedFormControl) => {
    if (!control.value?.length) {
      return null;
    }
    if (!Number.isInteger(+control.value)) {
      return {
        valueNotInteger: {
          valid: false,
        },
      };
    }
    return true;
  };
}
