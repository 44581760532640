<div class="dialog-header">
  <h2 class="hideMe"></h2>
  <h2>{{ 'growthStage.correctionDialog.headerTitle' | translate }}</h2>
  <div class="info">
    <app-info-hover [buttonText]="'growthStage.correctionDialog.infoHover' | translate | capitalize">
      <div class="info-box">
        <h4 class="info-box-header">{{ 'growthStage.correctionDialog.infoHeaderText' | translate }}</h4>
        <p class="info-box-text">
          {{ 'growthStage.correctionDialog.infoText' | translate }}
        </p>
      </div>
    </app-info-hover>
  </div>
</div>
<mat-dialog-content>
  <div>
    <form novalidate [formGroup]="dialogFormGroup!">
      <div class="form-field" *ngIf="useCalander">
        <div class="left-space">{{ 'growthStage.correctionDialog.dateField' | translate }}</div>
        <div class="left-space">
          <kendo-calendar
            [min]="minDate!"
            [max]="maxDate!"
            [focusedDate]="startDate"
            [value]="startDate"
            (valueChange)="onDateChange($event)"
          ></kendo-calendar>
        </div>
        <app-input
          class="left-right-space form-field"
          formControlName="growthStage"
          type="number"
          placeholder="{{ 'growthStage.correctionDialog.growthStageField' | translate }}"
        >
          <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('min')">
            {{ 'growthStage.validation.growthStageFieldMin' | translate }}
          </app-input-error>
          <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('max')">
            {{ 'growthStage.validation.growthStageFieldMax' | translate }}
          </app-input-error>
          <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('required')">
            {{ 'growthStage.validation.growthStageFieldRequired' | translate }}
          </app-input-error>
          <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('isNotInteger')">
            {{ 'growthStage.validation.growthStageFieldNotInteger' | translate }}
          </app-input-error>
        </app-input>
      </div>

      <div *ngIf="!useCalander" class="col-container">
        <div class="col form-field-disabled">
          {{ 'growthStage.correctionDialog.selectedDateField' | translate }}
        </div>
        <div class="col-wide form-field-disabled">
          <label>{{ startDate | dateFormat }}</label>
        </div>
      </div>

      <div *ngIf="!useCalander" class="col-container">
        <div class="col">
          {{ 'growthStage.correctionDialog.growthStageField' | translate }}
        </div>
        <div class="col-wide">
          <app-input class="form-field-appearance-legacy" formControlName="growthStage" type="number">
            <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('min')">
              {{ 'growthStage.validation.growthStageFieldMin' | translate }}
            </app-input-error>
            <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('max')">
              {{ 'growthStage.validation.growthStageFieldMax' | translate }}
            </app-input-error>
            <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('required')">
              {{ 'growthStage.validation.growthStageFieldRequired' | translate }}
            </app-input-error>
            <app-input-error *ngIf="dialogFormGroup?.get('growthStage')?.hasError('isNotInteger')">
              {{ 'growthStage.validation.growthStageFieldNotInteger' | translate }}
            </app-input-error>
          </app-input>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex">
  <app-square-button
    class="flex-item"
    [buttonType]="'Primary'"
    [disabled]="dialogFormGroup?.invalid!"
    (click)="!dialogFormGroup?.invalid && onSaveClicked()"
    >{{ 'growthStage.correctionDialog.saveButton' | translate | capitalize }}</app-square-button
  >
  <app-square-button class="flex-item" [buttonType]="'Secondary'" (click)="onCancelClicked()">{{
    'growthStage.correctionDialog.cancelButton' | translate | capitalize
  }}</app-square-button>
</mat-dialog-actions>
