import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { OperationType } from '@app/core/interfaces/operation.type';
import { Observable } from 'rxjs';

/**
 * Gets a list of operationtypes filtered by subscriptions
 */
export interface IOperationTypesRepo {
  getOperationTypesSubscriptionFiltered(farmIds: number[]): Observable<OperationType[] | null>;
}

@Injectable({
  providedIn: 'root',
})
export class OperationTypesRepo implements IOperationTypesRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getOperationTypesSubscriptionFiltered(farmIds: number[]): Observable<OperationType[] | null> {
    const options: any = {
      withCredentials: true,
    };

    const postBody = {
      val: farmIds,
    };

    return this.http.post<OperationType[], { val: number[] }>(
      `${this.endpoints.foApi}/operationtypes/subscriptionfilter`,
      postBody,
      options
    );
  }
}
