import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { Resource } from '../resources';
import { AccessControlService } from '../services/access-control.service';

@Directive({
  selector: '[hasAccessTo]',
})
export class HasAccessToDirective implements OnInit, OnDestroy {
  @Input() hasAccessTo: Resource | Resource[] | null = null;

  private _viewRef: EmbeddedViewRef<any> | null = null;

  private _sub = new Subscription();

  constructor(
    private service: AccessControlService,
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>
  ) {}

  ngOnInit() {
    this._sub = this.service
      .hasAccessTo(this.hasAccessTo, 'ANY')
      .pipe(
        tap((hasAccess) => hasAccess === true && this._viewRef === null && this.createView()), // only create view if it doesn't exist
        tap((hasAccess) => hasAccess === false && this._viewRef !== null && this.clearView()) // only clear view if it exists
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private createView() {
    this._viewRef = this.vcr.createEmbeddedView(this.tpl);
  }

  private clearView() {
    this.vcr.clear();
    this._viewRef = null;
  }
}
