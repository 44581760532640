import { Injectable } from '@angular/core';
import { ChartService } from '@app/core/chart/chart.service';
import { Month } from '@app/core/enums/month.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { CropAnnotation } from '@app/core/repositories/water-balance/crop-annotation.interface';
import { WaterBalanceDay } from '@app/core/repositories/water-balance/water-balance-day.interface';
import { WaterBalanceDTO } from '@app/core/repositories/water-balance/water-balance-dto.interface';
import { WaterBalanceRepo } from '@app/core/repositories/water-balance/water-balance-repo.service';
import { CategoryAxis, ChartComponent, ValueAxis } from '@progress/kendo-angular-charts';
import { Group, Text } from '@progress/kendo-drawing';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import { WaterBalanceChartInfoComponent } from './water-balance-chart-info/water-balance-chart-info.component';

const divisionsMap = new Map<ScreenSize, number>([
  [ScreenSize.XL, 30],
  [ScreenSize.L, 20],
  [ScreenSize.M, 15],
  [ScreenSize.S, 10],
  [ScreenSize.XS, 5],
]);

export interface DateAndAnnotation {
  date: DateTime;
  annotation: string;
}

@Injectable({
  providedIn: 'root',
})
export class WaterBalanceService {
  constructor(
    private waterBalanceRepo: WaterBalanceRepo,
    private chartService: ChartService,
    private dialogService: DialogService
  ) {}

  public showInfoModal() {
    this.dialogService.openInfoDialog(WaterBalanceChartInfoComponent);
  }

  public getWaterBalanceData(fieldYearId: number | undefined): Observable<WaterBalanceDTO> {
    return this.waterBalanceRepo.getWaterBalanceData(fieldYearId);
  }

  public mapBalanceData(data: WaterBalanceDTO): WaterBalanceDTO {
    const historicalData = this.mapInvertValues(data.waterBalanceDayList);

    let prognosisData: WaterBalanceDay[] = [];

    if (data.waterBalanceDayPrognosisList && data.waterBalanceDayPrognosisList.length) {
      const connectPrognosisAndHistoricalData = historicalData[historicalData.length - 1];
      prognosisData = [connectPrognosisAndHistoricalData, ...this.mapInvertValues(data.waterBalanceDayPrognosisList)];
    }

    return {
      ...data,
      waterBalanceDayPrognosisList: prognosisData,
      waterBalanceDayList: historicalData,
    };
  }

  private mapInvertValues(list: WaterBalanceDay[]): WaterBalanceDay[] {
    return list.map((wbd) => {
      return {
        ...wbd,
        capacity: -wbd.capacity,
        waterDeficit: wbd.waterDeficit > 0.1 ? -wbd.waterDeficit : 0,
        acceptableRootZoneWaterDeficit: -wbd.acceptableRootZoneWaterDeficit,
        unAcceptableRootZoneWaterDeficit: -wbd.unAcceptableRootZoneWaterDeficit,
      };
    });
  }

  public mapCropAnnotations(cropAnnotations: CropAnnotation[]) {
    return cropAnnotations.map((ca) => {
      return {
        date: ca.date,
        annotation: `${ca.annotation} - ${ca.cropName}`,
      };
    });
  }

  public getValueAxis(wbo: WaterBalanceDTO, initialValue: ValueAxis): ValueAxis {
    const interval = -wbo.waterBalanceFieldInfo.maxRootCapacity < -120 ? 20 : 10;
    const roundedMinValue = this.roundUpToNearestFive(wbo.waterBalanceFieldInfo.maxRootCapacity);
    return {
      ...initialValue,
      min: -roundedMinValue,
      labels: {
        position: 'start',
        content: (arg) => (arg.value % interval === 0 ? arg.value : ''),
      },
    } as ValueAxis;
  }

  public getRootCapacityAreaValues(wbo: WaterBalanceDTO) {
    let rootCapacityArea = this.getCapacityArea(wbo.waterBalanceDayList, wbo.waterBalanceFieldInfo.maxRootCapacity);
    // Add prognosis values if they exist
    if (wbo.waterBalanceDayPrognosisList.length) {
      rootCapacityArea = [
        ...rootCapacityArea,
        ...this.getCapacityArea(wbo.waterBalanceDayPrognosisList, wbo.waterBalanceFieldInfo.maxRootCapacity),
      ];
    }
    return rootCapacityArea;
  }

  private getCapacityArea(data: WaterBalanceDay[], maxRootCapacity: number): number[][] {
    const roundedMinValue = this.roundUpToNearestFive(maxRootCapacity);
    return data.map((wbd) => {
      return [-roundedMinValue, wbd.capacity];
    });
  }

  public getDroughtAreas(wbo: WaterBalanceDTO): { red: number[][] | null; yellow: number[][] | null; green: number[][] | null } {
    let red = this.getRedArea(wbo.waterBalanceDayList);
    if (wbo.waterBalanceDayPrognosisList.length) {
      red = [...red, ...this.getRedArea(wbo.waterBalanceDayPrognosisList)];
    }
    let yellow = this.getYellowArea(wbo.waterBalanceDayList);
    if (wbo.waterBalanceDayPrognosisList.length) {
      yellow = [...yellow, ...this.getYellowArea(wbo.waterBalanceDayPrognosisList)];
    }
    let green = this.getGreenArea(wbo.waterBalanceDayList);
    if (wbo.waterBalanceDayPrognosisList.length) {
      green = [...green, ...this.getGreenArea(wbo.waterBalanceDayPrognosisList)];
    }
    return { red, yellow, green };
  }

  private getRedArea(data: WaterBalanceDay[]): number[][] {
    return data.map((wbd) => {
      return [wbd.capacity, wbd.unAcceptableRootZoneWaterDeficit];
    });
  }
  private getYellowArea(data: WaterBalanceDay[]): number[][] {
    return data.map((wbd) => {
      return [wbd.unAcceptableRootZoneWaterDeficit, wbd.acceptableRootZoneWaterDeficit];
    });
  }
  private getGreenArea(data: WaterBalanceDay[]): number[][] {
    return data.map((wbd) => {
      return [wbd.acceptableRootZoneWaterDeficit, 5 /* Max value on Y axis */];
    });
  }

  public getMaxMinValuesFromData(wbo: WaterBalanceDTO, categoryAxis: CategoryAxis): CategoryAxis {
    const valuesForYear = wbo.waterBalanceDayList.filter((item) => item.dateDay.year === wbo.waterBalanceFieldInfo.harvestYear);
    const lastValueForCurrentYear = valuesForYear[valuesForYear.length - 1];
    const maxDateDay = lastValueForCurrentYear ? lastValueForCurrentYear.dateDay : null;

    const max = DateTime.fromObject({ year: wbo.waterBalanceFieldInfo.harvestYear, month: Month.December, day: 31 });
    const min = DateTime.fromObject({ year: wbo.waterBalanceFieldInfo.harvestYear, month: Month.March, day: 1 });

    const maxDate = maxDateDay ? maxDateDay.plus({ days: 10 }) : max;

    return {
      ...categoryAxis,
      min: min.toJSDate(),
      max: maxDate.toJSDate(),
    };
  }

  public createGraphLines(chart: ChartComponent | undefined, annotations: DateAndAnnotation[]): Group {
    const chartElement = (chart?.getPlotArea().backgroundVisual as any).chartElement;
    const viewMin = chartElement.axisX.dataRange.displayStart;
    const viewMax = chartElement.axisX.dataRange.displayEnd;
    const group = new Group();

    let lastLabel: Text;
    annotations.forEach((d) => {
      // If we recieve an ISO string, convert it to DateTime.
      let dt = DateTime.fromISO(d.date as unknown as string);
      d.date = dt.isValid ? dt : d.date;

      if (d && d.date.toMillis() >= viewMin && d.date.toMillis() <= viewMax) {
        const line = this.chartService.createLineInChartPane(
          d.date,
          chartElement.namedCategoryAxes.timeAxis,
          chartElement.namedValueAxes.waterBalanceAxis,
          viewMin,
          viewMax
        );
        let offset = 0;
        const labelBbox = lastLabel ? lastLabel.bbox() : null;
        if (labelBbox && labelBbox.center().getX() + labelBbox.width() > line.bbox().center().getX()) {
          offset = 20;
        }
        const label = this.chartService.createLineLabel(d.annotation, line, offset);
        const bg = this.chartService.createBackgroundForLabel(label, 'white', 8, 0.7);
        group.append(line, bg, label);
        lastLabel = label;
      }
    });

    return group;
  }

  public getNoOfDivisionsFromScreenSize(screenSize: ScreenSize) {
    return divisionsMap.get(screenSize);
  }

  private roundUpToNearestFive(x: number) {
    return Math.ceil(0.2 * x) / 0.2;
  }
}
