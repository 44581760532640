<div class="insurance-box">
  <h5 class="title">{{ 'main.growthRegulation.insurance.info.header' | translate }}</h5>
  <div *ngIf="!(isCurrentHarvestYear$ | async)" class="disabled">&nbsp;</div>
  <div
    class="img-wrapper"
    *ngIf="isFeatureActive$ | async"
    [ngClass]="{ 'image-hover': (isCurrentHarvestYear$ | async) }"
    (click)="onInsuranceClick()"
  >
    <img src="assets/images/prognoses/td-logo.svg" />
  </div>
  <h4 *ngIf="isFeatureActive$ | async; else featureUnavaiable">
    {{
      ((isCurrentHarvestYear$ | async)
        ? 'main.growthRegulation.insurance.info.footer'
        : 'main.growthRegulation.insurance.info.notAvaiableInSelectedHarvestYear'
      ) | translate
    }}
  </h4>
  <ng-template #featureUnavaiable>
    <h4>{{ 'main.growthRegulation.insurance.notPossibleOrderInsurance' | translate: { harvestYear: selectedHarvestYear$ | async } }}</h4>
  </ng-template>
</div>
