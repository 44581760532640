<div class="main-wrapper">
  <app-category-picker *appSideIf="(basisLayerShownComponent$ | async)!; index: components.categoryPickerComponent"></app-category-picker>
  <app-category *appSideIf="(basisLayerShownComponent$ | async)!; index: components.categoryComponent"></app-category>
</div>
<app-legend class="legend" [hidden]="false" [sideDrawerWidth]="sideDrawerWidth">
  <div class="legend-container">
    <h3 class="legend-header">{{ 'main.basis-layer.legend-header' | translate }}</h3>
    <div class="legend-item" *ngFor="let category of basisLayerCategories$ | async">
      <div class="color" [ngStyle]="{ 'background-color': category.color }">&nbsp;</div>
      <span class="info-line">{{ category.name }}</span>
    </div>
  </div>
</app-legend>
