import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { MapCoverFlowComponent } from '@app/shared/map-cover-flow/map-cover-flow.component';
import { MapCoverFlowSelector } from '@app/shared/map-cover-flow/selector/map-cover-flow.selector';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';
import { TooltipModule } from '@app/shared/tooltip/tooltip.module';
import { TranslateModule } from '@app/shared/translate/translate.module';

@NgModule({
  imports: [CommonModule, TranslateModule, TooltipModule, SpinnerModule, ButtonsModule],
  exports: [MapCoverFlowComponent],
  declarations: [MapCoverFlowComponent],
  providers: [MapCoverFlowSelector],
})
export class MapCoverFlowModule {}
