import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { MapConfig } from '@app/core/interfaces/map-config.interface';
import { MapService } from '@app/core/map/map.service';
import { QuerySyncService } from '@app/core/query-param/query-sync.service';
import { SessionService } from '@app/core/session/session.service';
import { BaseMapFeature } from '@app/map/features/base-map-feature';
import { FieldAnalysisFeaturesActionsService } from '@app/map/features/field-analysis/features/selector/field-analysis-features.actions.service';
import { FieldAnalysisFeaturesSelector } from '@app/map/features/field-analysis/features/selector/field-analysis-features.selector';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { ScaleLegendSettings } from '@app/shared/scale-legend/scale-legend-options.interface';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { FieldAnalysisFeaturesService } from './field-analysis-features.service';
import { filterNullish } from '@app/shared/operators';

export const fieldAnalysisMapLayerIdxQueryParamKey = 'field-analysis-map-layer-idx';

@Component({
  selector: 'app-field-analysis-map',
  templateUrl: './field-analysis-features.component.html',
  styleUrls: ['./field-analysis-features.component.scss'],
})
export class FieldAnalysisFeaturesComponent extends BaseMapFeature implements OnInit, OnDestroy {
  public scaleLegendSettings: ScaleLegendSettings = {
    items: [],
    title: '',
    note: '',
    summaryLines: [],
    visible: false,
    unit: '',
  };

  public mapCoverFlowItems: MapCoverFlowItem[] = [];

  public selectedMapCoverFlowItem: MapCoverFlowItem | null = null;
  public isMapCoverFlowVisible? = false;
  public isMapCoverFlowLoading = false;

  public canSeeSelectedField!: boolean;

  public mapLayerId = MapLayerId;

  public selectedMapFeature?: MapLayerId = MapLayerId.NONE;

  public isScaleLegendVisible = false;

  public mapConfig: MapConfig = {
    airphotoMaxZoomLevel: 12,
    center: this.sessionService.fieldMapCenter,
    defaultZoom: this.sessionService.fieldMapZoom,
    maxZoom: 19,
    minZoom: 0,
    shouldShowAirphoto: true,
  };

  public isMapReady = false;
  public isMapSpinnerVisible = false;

  constructor(
    private fieldAnalysisMapService: FieldAnalysisFeaturesService,
    public fieldAnalysisMapSelector: FieldAnalysisFeaturesSelector,
    public fieldInspectorActionDispatchers: FieldAnalysisFeaturesActionsService,
    private sessionService: SessionService,
    mapService: MapService,
    private querySyncService: QuerySyncService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService
  ) {
    super(mapService);
    const syncedParam = {
      selectorToUpdateQueryParam$: this.fieldAnalysisMapSelector.selectedMapCoverFlowItemIdx$.pipe(
        filterNullish(),
        map((selectedMapCoverFlowItemIdx) => {
          const idx = selectedMapCoverFlowItemIdx > 0 ? selectedMapCoverFlowItemIdx : null;
          return idx;
        })
      ),
      actionTriggeredOnQueryParamChange: (payload: unknown) =>
        typeof payload === 'number' && this.fieldInspectorActionDispatchers.selectedMapCoverFlowItemIdxChanged(payload),
      queryKey: fieldAnalysisMapLayerIdxQueryParamKey,
    };

    querySyncService.addSyncedQueryParam(syncedParam);
  }

  public override ngOnInit(): void {
    this.mapService.mapReady$.pipe(first()).subscribe(() => {
      this.handleMapReady();
    });
  }

  public ngOnDestroy(): void {
    this.fieldInspectorActionDispatchers.selectedMapCoverFlowItemIdxChanged(null);
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.querySyncService.unsyncQueryParam(fieldAnalysisMapLayerIdxQueryParamKey);
  }

  public handleMapReady() {
    setTimeout(() => {
      super.ngOnInit();
      this.setupMapCoverFlowSubscriptions();
      this.isMapReady = true;
      this.initMapSubscriptions();
    });
  }

  /**
   * Init store subscriptions
   */
  private initMapSubscriptions(): void {
    this.setupMapOptionsSubscriptions();

    this.setupSubscriptionLevelSubscriptions();

    this.setupSelectedMapCoverFlowSubscriptions();
  }

  private setupSelectedMapCoverFlowSubscriptions() {
    this.subscriptions.push(
      this.fieldAnalysisMapSelector.selectedMapCoverFlowItem$.subscribe((mapCoverFlowItem) => {
        if (!mapCoverFlowItem) {
          this.mapCoverFlowItems.forEach((coverFlowItem) => (coverFlowItem.isVisible = false));
        } else {
          mapCoverFlowItem.isVisible = true;
          this.selectedMapCoverFlowItem = mapCoverFlowItem;

          this.selectedMapFeature = mapCoverFlowItem.mapCoverFlowLayersId;

          this.mapCoverFlowItems
            .filter((coverFlowItem) => coverFlowItem !== mapCoverFlowItem && coverFlowItem.isVisible)
            .forEach((coverFlowItem) => (coverFlowItem.isVisible = false));
          this.isMapCoverFlowVisible = true;
        }
        this.map.updateMapCoverFlowLayersVisibility(this.mapCoverFlowItems);
      })
    );
  }

  private setupSubscriptionLevelSubscriptions() {
    this.subscriptions.push(
      this.fieldAnalysisMapSelector.subscriptionLevel$.subscribe((level) => {
        if (level !== null) this.canSeeSelectedField = level >= SubscriptionLevel.BASIC;
      })
    );
  }

  private setupMapOptionsSubscriptions() {
    this.subscriptions.push(
      this.fieldAnalysisMapSelector.mapOptions$.subscribe((options) => {
        this.isMapCoverFlowVisible = options?.isSettingsVisible;
      })
    );
  }

  private setupMapCoverFlowSubscriptions() {
    this.subscriptions.push(
      combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$])
        .pipe(
          tap(() => (this.isMapCoverFlowLoading = true)),
          switchMap(() => this.fieldAnalysisMapService.getCoverFlowLayersItems())
        )
        .subscribe((coverFlowItems) => {
          this.updateMapCoverFlowItems(coverFlowItems);
        })
    );
  }

  private updateMapCoverFlowItems(coverFlowItems: MapCoverFlowItem[]) {
    this.mapCoverFlowItems = coverFlowItems;
    this.map.updateMapCoverFlowLayersVisibility(coverFlowItems);
    this.fieldInspectorActionDispatchers.setMapCoverFlowItems(coverFlowItems);
    this.isMapCoverFlowLoading = false;
  }

  public getMapCoverFlowItem(mapLayerId: MapLayerId) {
    return this.mapCoverFlowItems.find((mapCoverFlowItem) => mapCoverFlowItem.mapCoverFlowLayersId === mapLayerId);
  }

  public isLayerSelected(mapLayerId: MapLayerId) {
    const isSelected =
      !!this.selectedMapCoverFlowItem &&
      this.selectedMapCoverFlowItem.mapCoverFlowLayersId === mapLayerId &&
      this.selectedMapCoverFlowItem.isVisible;

    return isSelected;
  }
}
