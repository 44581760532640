import { NgModule } from '@angular/core';
import { FieldAnalysisFeaturesModule } from '@app/map/features/field-analysis/features/field-analysis-features.module';
import { FieldAnalysisSideDrawerModule } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.module';
import { SortStringPipe } from '@app/shared/pipes/sort/sort-string.pipe';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  providers: [SortStringPipe],
  imports: [SharedModule, FieldAnalysisFeaturesModule, FieldAnalysisSideDrawerModule],
})
export class FieldAnalysisModule {}
