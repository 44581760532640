import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

interface TableData {
  produceName: string;
  quantity: string;
  totalQuantity: string;
}

interface TableColumns {
  produceName: string;
  quantity: string;
  totalQuantity: string;
}

interface TableStyles {
  produceName: any;
  quantity: any;
  totalQuantity: any;
}

interface DataStylesInterface {
  productName: {
    color: string;
    fontWeight: string;
    padding: string;
    textAlign: string;
  };
  operationTypeGroup: {
    color: string;
    fontWeight: string;
    padding: string;
    textAlign: string;
  };
  displayDate: {
    whiteSpace: string;
    padding: string;
    textAlign: string;
  };
  logFileCount?: {
    whiteSpace: string;
    padding: string;
    textAlign: string;
  };
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() public dataSource?: object[];
  @Input() public dataStyles: TableStyles | DataStylesInterface | undefined;
  @Input() public selectable = false;
  @Input() public displayedColumns: TableColumns | undefined;
  @Input() public headerAlignment: 'left' | 'right' | 'center' = 'center';
  @Input() public background: 'white' | 'transparent' = 'white';
  @Input() public sortFn?: (item: any, property: any) => number | string;
  @Input() public formatDates = false;
  @Input() public showTooltip = false;
  @Input() public tooltipKey?: string;
  @Input() public charactersBeforeTooltip?: number;
  @Output() public selected = new EventEmitter<any>();
  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @ViewChild(MatSort, { static: false }) public sort: MatSort;

  public dataSourceSort?: MatTableDataSource<any>;
  public displayedColumnKeys: string[] = [];

  constructor() {}

  public ngAfterViewInit() {
    if (this.dataSourceSort) {
      this.dataSourceSort.sort = this.sort;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['dataSource'] && changes['dataSource'].currentValue) {
      this.dataSourceSort = new MatTableDataSource(changes['dataSource'].currentValue);
      if (this.sortFn) {
        this.dataSourceSort.sortingDataAccessor = this.sortTaskFn;
      }
      if (this.sort) {
        this.dataSourceSort.sort = this.sort;
      }

      this.displayedColumnKeys = Object.keys(this.displayedColumns || {});
    }
  }

  public rowSelected(row: any) {
    if (this.selectable) {
      this.selected.emit(row);
    }
  }

  public isObject(item: any) {
    return item === Object(item);
  }

  public isDate(item: Object) {
    return item instanceof Date;
  }

  public readonly sortTaskFn = (item: any, property: any) => {
    switch (property) {
      case 'displayDate':
        return item.date;
      case 'logFileCount':
        return item.logFileCount;
      case 'fieldName':
        return item.fieldName;
      default:
        return item[property];
    }
  };
}
