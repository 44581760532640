import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ChartModule } from '@progress/kendo-angular-charts';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ButtonsModule } from '../buttons/buttons.module';
import { ChartWrapperModule } from '../chart-wrapper/chart-wrapper.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '../translate/translate.module';
import { WaterBalanceDialogComponent } from './dialog/water-balance-dialog.component';
import { WaterBalanceChartInfoComponent } from './water-balance-chart-info/water-balance-chart-info.component';
import { WaterBalanceComponent } from './water-balance.component';
import { WaterBalanceService } from './water-balance.service';

@NgModule({
  imports: [
    CommonModule,
    ChartWrapperModule,
    ChartModule,
    PopupModule,
    TranslateModule,
    ButtonsModule,
    AppPipesModule,
    MatDialogModule,
    IconModule,
  ],
  declarations: [WaterBalanceComponent, WaterBalanceChartInfoComponent, WaterBalanceDialogComponent],
  providers: [WaterBalanceService],
  exports: [WaterBalanceComponent],
})
export class WaterBalanceModule {}
