import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private bffAuthService: BffAuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.bffAuthService.isAuthenticated$.pipe(
      map((isAuthenticated) => (isAuthenticated ? isAuthenticated : this.router.parseUrl('/')))
    );
  }
}
