import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { FarmGuard } from '@app/core/guards/farm-guard.service';
import { AccessControlGuard, AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';
import { BenchmarkComponent } from './benchmark.component';

export const benchmarkRoutes: AccessControlRoutes = [
  {
    path: 'yield-benchmark',
    canActivate: [AuthGuard, ConsentGuard, FarmGuard, AccessControlGuard],
    data: {
      resource: 'yield_benchmark',
    },
    canLoad: [],
    component: BenchmarkComponent,
  },
];
