<app-side-drawer-wrapper>
  <app-side-drawer-header
    (action)="onCloseClick()"
    [showMinimizeButton]="true"
    cancelIconName="back"
    (onHide)="onHideClick()"
    title="{{
      ((droneImageInformation$ | async) ? 'main.fieldmap.layers.droneImage' : 'main.fieldmap.layers.droneImageImport') | translate
    }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <mat-card appearance="outlined" class="card">
      <mat-card-content>
        <div class="header">
          <p class="header-content">{{ 'main.fieldmap.droneImageImport.information-on-usage' | translate }}</p>
          <p class="header-disclaimer">{{ 'main.fieldmap.droneImageImport.information-on-usage-disclaimer' | translate }}</p>
        </div>
      </mat-card-content>
    </mat-card>
    <app-file-picker *ngIf="!(droneImageInformation$ | async)" [form]="form" [minDate]="minDate" [maxDate]="maxDate"></app-file-picker>
    <app-drone-image-information
      *ngIf="droneImageInformation$ | async"
      (onDroneImageDateChange)="onDroneImageDateChange($event)"
      [droneImage$]="droneImageInformation$"
      [fieldName$]="selectedMarkName$"
    ></app-drone-image-information>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button *ngIf="!(droneImageInformation$ | async)" (clicked)="onSaveClick()">{{
      'main.fieldmap.droneImageImport.generateImages' | translate | capitalize
    }}</app-side-drawer-footer-button>
  </app-side-drawer-footer>
</app-side-drawer-wrapper>
