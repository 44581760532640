export enum SubscriptionLevel {
  FREE = 0,
  BASIC = 1,
  PREMIUM = 2,
}

// Temporary fix because of bug in AOT build:
// https://github.com/angular/angular/issues/18170#issuecomment-323192077
export const SUBSCRIPTIONLEVEL_FREE = SubscriptionLevel.FREE;
export const SUBSCRIPTIONLEVEL_BASIC = SubscriptionLevel.BASIC;
export const SUBSCRIPTIONLEVEL_PREMIUM = SubscriptionLevel.PREMIUM;
