import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { DateTime } from 'luxon';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Reminder } from './reminders.interface';

@Injectable()
export class RemindersRepoService {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getReminders(startDate: DateTime): Observable<Reminder[]> {
    return this.http
      .get<Reminder[]>(`${this.endpoints.ftApi}/reminders/bydate?from=${startDate.setZone('utc').toISO()}`)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }

  public updateIsRead(reminder: Reminder): Observable<Reminder | null> {
    const isRead = {
      IsRead: reminder.isRead, // Must start with a Capital I, otherwise it will not work!
    };

    return this.http
      .patch<Reminder, unknown>(`${this.endpoints.ftApi}/reminders/${reminder.id}`, isRead, {})
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }
}
