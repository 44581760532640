<h4>{{ 'cornHarvestPrognosis.help.title' | translate }}</h4>
<ul class="info-bullets">
  <li>
    <div [ngSwitch]="data.standardCrop">
      <div *ngSwitchCase="CornTypes.CornWholeSeed">{{ 'cornHarvestPrognosis.help.bullets.cornMaize.1' | translate }}</div>
      <div *ngSwitchCase="CornTypes.CobCorn">{{ 'cornHarvestPrognosis.help.bullets.cobCorn.1' | translate }}</div>
      <div *ngSwitchCase="CornTypes.GrainMaize">{{ 'cornHarvestPrognosis.help.bullets.grainMaize.1' | translate }}</div>
      <div *ngSwitchDefault>{{ 'cornHarvestPrognosis.help.bullets.cornMaize.1' | translate }}</div>
    </div>
  </li>
  <li>{{ 'cornHarvestPrognosis.help.bullets.2' | translate }}</li>
  <li>{{ 'cornHarvestPrognosis.help.bullets.3' | translate }}</li>
  <li>{{ 'cornHarvestPrognosis.help.bullets.4' | translate }}</li>
  <li>{{ 'cornHarvestPrognosis.help.bullets.5' | translate }}</li>
</ul>
<p>
  {{ 'cornHarvestPrognosis.help.readMore' | translate }}
  <a href="https://www.landbrugsinfo.dk">https://www.landbrugsinfo.dk</a>
</p>

<div class="button-wrapper">
  <app-square-button [buttonType]="'Primary'" (clicked)="onConfirmClick()">{{
    'cornHarvestPrognosis.help.confirm' | translate
  }}</app-square-button>
</div>
