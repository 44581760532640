import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { FeatureService } from '@app/core/feature/feature.service';
import { FieldFeatures } from '@app/core/feature/field-features.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { ArrayElement } from '@app/core/types/array-element.type';
import { OverloadedParameters } from '@app/core/types/overloads.type';
import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import { filterNullish, filterNullOrEmpty } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { Collection, Feature, MapBrowserEvent, MapEvent, Map as OlMap, Overlay } from 'ol';
import { easeOut } from 'ol/easing';
import BaseEvent from 'ol/events/Event';
import { click, never } from 'ol/events/condition';
import { Extent } from 'ol/extent';
import { Geometry } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { DoubleClickZoom, Draw, Modify } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import PointerInteraction from 'ol/interaction/Pointer';
import Select, { SelectEvent } from 'ol/interaction/Select';
import { Layer } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import { Pixel } from 'ol/pixel';
import { transform } from 'ol/proj';
import { Cluster } from 'ol/source';
import VectorSource, { default as Vector, VectorSourceEvent } from 'ol/source/Vector';
import { Style } from 'ol/style';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  fromEventPattern,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { MapPadding } from '../config/map-padding';
import { PROJECTION } from '../helpers/constants/projection-consts';
import { CreateLayerUtil } from '../helpers/utils/create-layer-util';
import { OlMapComponent } from '../ol-map/ol-map.component';
import { OlLayerQuery } from '../services/layer/layer.query';
import { OlLayerService } from '../services/layer/layer.service';
import { LayerBundle, LayerId, PagesWithLayer } from '../services/layer/layer.store';

type MapEventTypes = ArrayElement<OverloadedParameters<OlMap['on']>>[0];

@Injectable({ providedIn: 'root' })
export class OlMapService {
  private _mapComponent!: OlMapComponent;
  private _denmarkBoundary = CreateLayerUtil.createDenmarkBoundary().getSource();
  private _layersManagedFlag = false;

  private _selectObservable$?: Observable<SelectEvent>;
  private _drawInteraction?: Draw | null;
  private _modifyInteraction?: Modify | null;
  private _hoverInteraction?: PointerInteraction | null;
  private _selectInteraction?: Select | null;
  private _modifyFeaturesRevisions: [any, number][] = [];

  private _mapReadySubject: Subject<boolean> = new ReplaySubject(1);

  private canvasReadySubject: Subject<boolean> = new ReplaySubject(1);
  public canvasReady$ = this.canvasReadySubject.asObservable();

  private _olMap?: OlMap;

  private _vraMapToggleSubject = new BehaviorSubject<boolean>(true);
  public vraMapToggle$ = this._vraMapToggleSubject.asObservable();

  public zoomLevelSubject = new BehaviorSubject<number | undefined>(undefined);

  public zoomEditThreshold: Readonly<number> = 10;

  public isDrawing = new BehaviorSubject<boolean>(false);

  public mapReady$ = this._mapReadySubject.asObservable();

  public zoomLevel$ = this.zoomLevelSubject.asObservable().pipe(filterNullish());

  private _zoomWarningShown = false;

  constructor(
    private featureService: FeatureService,
    private layerService: OlLayerService,
    private layerQuery: OlLayerQuery,
    private screenSizeService: ScreenSizeService,
    private notificationService: NotificationService,
    private farmStateService: FarmStateService
  ) {
    this._manageMapLayers();
    this._giveNotificationIfLayerIsOutsideZoomLevel();
  }

  /** Initializes the layer services. */
  public init() {
    this.layerService.init();
  }

  /** Clears up the layerService, and resets flag.  */
  public cleanUp() {
    this.layerService.clear();
    this._layersManagedFlag = false;
    this._mapReadySubject.next(false);
  }

  /** Sets the map component instance.
   * @param map - The map component instance.
   */
  public setMapComponent(map: OlMapComponent) {
    this._mapComponent = map;
  }

  /** Returns the current ol map component */
  public get mapComponent() {
    return this._mapComponent;
  }

  /** Marks the map as ready and sets reference to map instance. */
  public setMapReady() {
    this._olMap = this._mapComponent.getMap();
    this._mapReadySubject.next(true);

    setTimeout(() => {
      this.farmStateService.fieldFeatures$.pipe(filterNullOrEmpty(), first()).subscribe((fieldFeatures) => {
        this.fitMapToFieldFeatures(fieldFeatures);
      });
    });
  }

  /**
   * Sets a new active page in layer service and removes select interaction.
   * @param activePage - The new active page.
   */
  public setNewActivePage(activePage: PagesWithLayer) {
    this.removeSelectInteraction();
    this.layerService.setActivePage(activePage);
  }

  /** Returns the current zoomlevel of the map */
  public get zoomLevel() {
    return this._olMap?.getView().getZoom() ?? 0;
  }

  /** Get the */
  public getEventPixel(event: UIEvent) {
    return this._olMap?.getEventPixel(event);
  }

  /** Retrieve a layer from the map based on ID.
   * @param layerId - The ID of the layer.
   */
  public getLayerFromMap(layerId: LayerId) {
    return this._mapComponent.getLayerFromMap(layerId);
  }

  /**
   * Retrieve features from a layer from the map based on ID.
   * @param layerId
   */
  public getFeaturesFromLayer(layerId: LayerId): Feature[] {
    const layer = this.getLayerFromMap(layerId);
    return (layer?.getSource() as Vector)?.getFeatures() ?? [];
  }

  /**
   * Retrieve features from the map based on pixel and options.
   * @param pixel - The pixel to retrieve features from.
   * @param options - The options to use when retrieving features.
   * @returns
   */
  public getFeaturesAtPixel(pixel: number[] | Pixel, options: { layerFilter: (layer: any) => boolean; hitTolerance: number }) {
    return this._olMap?.getFeaturesAtPixel(pixel, options);
  }

  public enableDoubleClickZoom() {
    this._olMap?.getInteractions().forEach((interaction) => {
      if (interaction instanceof DoubleClickZoom) {
        interaction.setActive(true);
      }
    });
  }

  public disableDoubleClickZoom() {
    this._olMap?.getInteractions().forEach((interaction) => {
      if (interaction instanceof DoubleClickZoom) {
        interaction.setActive(false);
      }
    });
  }

  /**
   * Fits the map view to the extent of the provided field features.
   * Takes into account the active page, to determine if it is old or new padding.
   * @param fieldFeatures - The field features to fit the map to.
   * @param newPadding - Whether to use new padding for non-mobile view. Defaults to false.
   */
  public fitMapToFieldFeatures(fieldFeatures: FieldFeatures) {
    this.screenSizeService
      .isMobile()
      .pipe(take(1), withLatestFrom(this.layerQuery.activePage$))
      .subscribe(([mobile, activePage]) => {
        if (!fieldFeatures.extent) return;
        const newPadding = activePage === 'vra';
        const mapPadding = newPadding ? MapPadding.NEW_NON_MOBILE_MAP_PADDING : MapPadding.OLD_NON_MOBILE_MAP_PADDING;
        const padding = mobile ? MapPadding.MOBILE_MAP_PADDING : mapPadding;

        this._mapComponent?.slideMapToExtent(fieldFeatures.extent, padding);
      });
  }

  /**
   * Fits the map view to rendered features on the field layer.
   * Takes into account the active page, to determine if it is old or new padding.
   */
  public centerMapOnFields() {
    const fields = this.mapComponent.getFeaturesFromLayer(LayerId.FIELDS);
    this.screenSizeService
      .isMobile()
      .pipe(take(1), withLatestFrom(this.layerQuery.activePage$))
      .subscribe(([mobile, activePage]) => {
        if (fields.length === 0) return;
        const newPadding = activePage === 'vra';
        const mapPadding = newPadding ? MapPadding.NEW_NON_MOBILE_MAP_PADDING : MapPadding.OLD_NON_MOBILE_MAP_PADDING;
        const padding = mobile ? MapPadding.MOBILE_MAP_PADDING : mapPadding;

        this.mapComponent?.slideMapToExtent(
          (this.mapComponent.getLayerFromMap(LayerId.FIELDS)?.getSource() as VectorSource).getExtent(),
          padding
        );
      });
  }

  /**
   * Fits the map view to the provided extent.
   * @param extent - The extent to fit the map to.
   * @param newPadding - Whether to use padding for new side-drawer. Defaults to false.
   */
  public fitMapToExtent(extent?: number[] | Extent, newPadding = false) {
    if (!extent) return;
    const mapPadding = newPadding ? MapPadding.NEW_NON_MOBILE_MAP_PADDING : MapPadding.OLD_NON_MOBILE_MAP_PADDING;
    this.screenSizeService
      .isMobile()
      .pipe(take(1))
      .subscribe((mobile) => {
        const padding = mobile ? MapPadding.MOBILE_MAP_PADDING : mapPadding;
        this._mapComponent?.slideMapToExtent(extent, padding);
      });
  }

  /**
   * Slides the map to the provided longLat and zoom level.
   * @param longLat - The longLat to slide the map to.
   * @param zoomLevel - The zoom level to slide the map to.
   * @param doTransform - Whether to transform the projection. Defaults to true.
   */
  public slideMapToPoint(longLat: number[], zoomLevel?: number, doTransform: boolean = true) {
    this._olMap?.getView().animate({
      center: doTransform ? transform(longLat, PROJECTION.DATA, PROJECTION.FEATURE) : longLat,
      zoom: zoomLevel,
      easing: easeOut,
      duration: 500,
    });
  }

  /**
   * Adds the provided field features to the map, through feature and layer services.
   * @param fields - The field features to add.
   * @param layerId - The ID of the layer.
   * @param fitMap- Whether to fit the map to the extent of the field features. Defaults to false.
   */
  public addFieldsToMap(fields: FieldFeatures | null, layerId: LayerId, fitMap: boolean = false): void {
    const features = this.featureService.getFieldFeatures(fields!.fieldFeatures, layerId);
    if (fitMap) this._mapComponent.slideMapToExtent(fields!.extent!);
    this.layerService.createFeatureLayer(layerId, features);
  }

  /** Adds an overlay to the map */
  public addOverlayToMap(overlay: Overlay): void {
    this._olMap?.addOverlay(overlay);
  }

  /** Removes specified overlay from the map */
  public removeOverlayFromMap(overlay: Overlay): void {
    this._olMap?.removeOverlay(overlay);
  }

  /** Toggles the visibility of the VRA map overlays. */
  public vraToggleMap(bool: boolean): void {
    this._vraMapToggleSubject.next(bool);
  }

  /**
   * Adds a pointer move interaction to the map and returns an observable.
   */
  public addPointerMoveInteraction() {
    return fromEventPattern<MapBrowserEvent<UIEvent>>(
      (handler) => this._olMap?.on('pointermove', handler),
      (handler) => this._olMap?.un('pointermove', handler)
    );
  }

  /**
   * Handles the display of a pointer cursor when hovering over specific map features.
   * @param ev - The map browser event.
   * @param mapFeature - The IDs of the map features to show the pointer for.
   */
  public showPointerOnMapFeature(ev: MapBrowserEvent<PointerEvent>, mapFeature: LayerId[]): void {
    try {
      if (!ev?.map) return;

      const pointerOverFieldFeature = ev.map.forEachFeatureAtPixel(ev.pixel, (feature: any, layer: any) => {
        try {
          if (!layer) return;
          const currentMapLayerId = layer.get('id');
          if (mapFeature?.includes(currentMapLayerId) && feature instanceof Feature) {
            return feature;
          }
          return null;
        } catch (error) {
          return null;
        }
      });

      const cursorStyle = pointerOverFieldFeature ? 'pointer' : '';
      const vpHtmlElement = ev.map.getViewport() as HTMLElement;
      vpHtmlElement.style.cursor = cursorStyle;
    } catch (error) {}
  }

  /**
   * Selects the given features on the map.
   * @param features - The features to select.
   */
  public selectFeatures(features: Feature[]): void {
    this.deselectFeatures();
    features.forEach((feature) => this._selectInteraction?.getFeatures().push(feature));
  }

  /** Deselects all selected features on the map. */
  public deselectFeatures(): void {
    const features = this._selectInteraction?.getFeatures();
    if (!features) return;
    features.forEach((feature) => feature.set('selectedBinary', 0));
    features.clear();
  }

  /**
   * Adds a drawing interaction to the map.
   * @param layerId - The ID of the layer.
   * @param drawType - The type of geometry to draw.
   * @param drawingStyle - The style of the drawing interaction.
   */
  public addDrawingInteraction(
    layerId: LayerId,
    drawType: GeometryType,
    drawingStyle: Style[]
  ): Observable<VectorSourceEvent<Feature<Geometry>>> | undefined {
    const mapLayer = this._mapComponent.getLayerFromMap(layerId);

    if (!mapLayer?.getSource()) return;

    let type: Type;
    const mapSource = mapLayer.getSource() as Vector;

    switch (drawType) {
      case GeometryType.POINT:
        type = 'Point';
        break;
      case GeometryType.LINE:
        type = 'LineString';
        break;
      case GeometryType.POLYGON:
        type = 'Polygon';
        break;
      case GeometryType.MULTIPOLYGON:
        type = 'MultiPolygon';
        break;
    }

    this._drawInteraction = new Draw({ source: mapSource, type, style: drawingStyle });

    this._olMap?.addInteraction(this._drawInteraction);

    // A flag to indicate that a drawing operation is in progress
    let drawingInProgress = false;

    this._drawInteraction.on('drawstart', () => {
      this.isDrawing.next(true);
      drawingInProgress = true;
    });

    this._drawInteraction.on('drawend', () => {
      this.isDrawing.next(false);
      // Delay the flag reset to ensure the feature is completely added
      setTimeout(() => {
        drawingInProgress = false;
      }, 0);
    });

    return fromEventPattern<VectorSourceEvent>(
      (handler) =>
        mapSource.on('addfeature', (event) => {
          if (drawingInProgress) {
            handler(event);
          }
        }),
      (handler) => mapSource.un('addfeature', handler)
    ).pipe(tap((event) => event.feature?.set('layerId', layerId)));
  }

  /** Removes the current drawing interaction from the map. */
  public removeDrawingInteraction(): void {
    this.isDrawing.next(false);

    if (this._drawInteraction) {
      this._olMap?.removeInteraction(this._drawInteraction);
      this._drawInteraction = null;
    }
  }

  /** Add modify interaction to current feature in specified layer */
  public addModifyInteractionForFeature(featureArg: Feature | null): Observable<Feature> | undefined {
    this.removeModifyInteraction();
    this._modifyFeaturesRevisions = [];

    if (!featureArg) return;

    const features = new Collection([featureArg]);
    this._modifyFeaturesRevisions.push([featureArg.getId(), featureArg.getRevision()]);

    this._modifyInteraction = new Modify({
      features,
      pixelTolerance: 5,
      style: FieldStyles.getFieldDrawingStyle(),
    });

    this._olMap?.addInteraction(this._modifyInteraction);

    return fromEventPattern<ModifyEvent>(
      (handler) => this._modifyInteraction?.on('modifyend', handler),
      (handler) => this._modifyInteraction?.un('modifyend', handler)
    ).pipe(
      map((event) => this._mapModifyEvent(event, this._modifyFeaturesRevisions)),
      filterNullish(),
      map((res) => res.feature)
    );
  }

  /** Removes the modify interaction currently on the map */
  public removeModifyInteraction() {
    if (this._modifyInteraction) {
      this._olMap?.removeInteraction(this._modifyInteraction);
      this._modifyInteraction = null;
    }
  }

  /**
   * Adds select interaction to the map.
   * @param toggle - Whether to enable toggle selection. Defaults to false
   * @param layers - The IDs of the layers to enable selection for.
   * @param selectedFeatures - The initial selected features.
   */
  public addSelectInteraction(toggle = false, layers?: LayerId[], selectedFeatures: Feature[] = []): Observable<SelectEvent> {
    this._selectInteraction && this.removeSelectInteraction();

    this._selectInteraction = new Select({
      condition: (event: MapBrowserEvent<PointerEvent>) => click(event),
      style: null,
      toggleCondition: (event: MapBrowserEvent<PointerEvent>) => (toggle ? click(event) : never()),
      features: new Collection(selectedFeatures),
      layers: (selectedLayer) => {
        return !layers ? true : layers.includes(selectedLayer.get('id'));
      },
    });

    this._olMap?.addInteraction(this._selectInteraction);
    this._addHoverInteraction(layers);

    this._selectObservable$ = fromEventPattern<SelectEvent>(
      (handler) => this._selectInteraction?.on('select', handler),
      (handler) => this._selectInteraction?.un('select', handler)
    ).pipe(
      tap((event) => {
        // 1 = selected, 0 ? unselected for WebGL styling
        event.selected.forEach((feature) => {
          feature.set('selectedBinary', 1);
        });
        event.deselected.forEach((feature) => {
          feature.set('selectedBinary', 0);
        });
        event.mapBrowserEvent.map.getViewport().style.cursor = '';
      })
    );

    return this._selectObservable$;
  }

  /** Removes select interaction from the map. */
  public removeSelectInteraction() {
    this._olMap?.removeInteraction(this._selectInteraction!);
    this._olMap?.removeInteraction(this._hoverInteraction!);
    this._selectInteraction = null;
    this._hoverInteraction = null;
  }

  /** Disables select interaction on the map */
  public disableSelectInteraction() {
    if (!this._selectInteraction) return;

    this._hoverInteraction?.setActive(false);
    this._selectInteraction.setActive(false);
  }

  /** Enables select interaction on the map */
  public enableSelectInteraction() {
    if (!this._selectInteraction) return;

    this._hoverInteraction?.setActive(true);
    this._selectInteraction.setActive(true);
  }

  /** Adds a layer directly to the map, without going through layer service */
  public addLayerToMap(layer: Layer) {
    this._mapComponent?.addLayer(layer);
  }

  /** Creates am empty layer with VRA styling for drawing. */
  public createVraDrawLayer() {
    const existingLayer = this._mapComponent.getLayerFromMap(LayerId.CELL_DRAW);
    if (existingLayer) return existingLayer;
    const layer = CreateLayerUtil.createFeatureLayer(LayerId.CELL_DRAW) as LayerBundle[];
    this.addLayerToMap(layer[0].layerObjects.first()!);
    this.magicReRender();
    return layer[0].layerObjects.first()!;
  }

  public getMapEvent$(pointerEvent: MapEventTypes) {
    // type casting due to function overloading
    return fromEventPattern<BaseEvent>(
      (handler) => this._olMap?.on(pointerEvent as any, handler),
      (handler) => this._olMap?.un(pointerEvent as any, handler)
    );
  }

  /**
   * Updates a feature with a new geomatry
   * @param layerId The layer in which the features exists
   * @param featureId The unique 'id' key of the feature
   * @param newGeometry the new geometry to apply to the feature
   */
  public updateFeatureGeometry(layerId: LayerId, featureId: any, newGeometry: Geometry): void {
    const mapLayer = this.getLayerFromMap(layerId);

    if (!mapLayer) {
      return;
    }

    // find feature
    let feature: Feature | undefined;

    if (mapLayer.getSource() instanceof Cluster) {
      const vectorLayer = mapLayer as VectorLayer<any>;
      const clusterSource = vectorLayer.getSource() as Cluster;
      feature = clusterSource
        .getSource()
        ?.getFeatures()
        .find((f: Feature) => {
          return f.get('features') ? f.get('features')[0].getId() === featureId : f.getId() === featureId;
        });
    } else if (mapLayer.getSource() instanceof Vector) {
      const vectorSource = mapLayer.getSource() as Vector;
      feature = vectorSource.getFeatures().find((f: Feature) => {
        return f.get('features') ? f.get('features')[0].getId() === featureId : f.getId() === featureId;
      });
    }

    if (!feature) {
      return;
    }

    // update feature geometry
    feature.setGeometry(newGeometry);
  }

  /** Initiates subscription, which manages the layers on the map based on the layer store. */
  private _manageMapLayers() {
    this.mapReady$
      .pipe(
        takeUntilDestroyed(),
        switchMap((ready) =>
          this.layerQuery.mapLayersByActivePageUnique$.pipe(
            filter(() => ready),
            filter((layers) => layers.length > 0),
            withLatestFrom(this.isDrawing)
          )
        )
      )
      .subscribe(([layers, isDrawing]) => {
        this._syncMapLayers(layers);
        this._initializeAirphotoVisibilityManagement();
      });

    this.layerQuery.reRender$.pipe(takeUntilDestroyed()).subscribe(() => {
      setTimeout(() => {
        this.magicReRender();
      }, 100); // make sure it deos not interrupt panning to fields
    });

    // re-render when there are large changes in layers
    this.mapReady$
      .pipe(
        takeUntilDestroyed(),
        switchMap((ready) =>
          this.layerQuery.mapLayersByActivePageUnique$.pipe(
            filter(() => ready),
            filter((layers) => layers.length > 0),
            map((layers) => layers.length),
            distinctUntilChanged()
          )
        )
      )
      .subscribe(() => {
        setTimeout(() => {
          this.magicReRender();
        }, 100); // make sure it deos not interrupt panning to fields
      });
  }

  /** Displays a notification if layers are not visible on current zoom level.*/
  private _giveNotificationIfLayerIsOutsideZoomLevel() {
    combineLatest([
      this.zoomLevel$.pipe(takeUntilDestroyed(), distinctUntilChanged(), debounceTime(500)),
      this.layerQuery.mapLayersByActivePage$,
    ]).subscribe(([zoom, bundles]) => {
      const noLayersOutsideZoom =
        bundles.find(
          (bundle) =>
            (bundle.group === 'generel' || bundle.group === 'overlay') &&
            bundle.layerObjects[0].getMinZoom() &&
            bundle.layerObjects[0].getMinZoom() > zoom &&
            bundle.enabled
        ) === undefined;

      if (noLayersOutsideZoom) {
        this._zoomWarningShown = false;
        return;
      }

      if (!this._zoomWarningShown) {
        this._zoomWarningShown = true;
        this.notificationService.showInfo('main.map.layerControl.someLayersNotVisible');
      }
    });
  }

  /** Synchronizes map layers with the store layers. */
  private _syncMapLayers(layers: LayerBundle[]) {
    const excludedLayerIds = new Set([LayerId.CELL_DRAW]);
    const mapLayerIds = this._mapComponent.getLayersFromMap().map((layer) => layer.get('id'));
    const layerIds = new Set(layers.map((layer) => layer.layerObjects.map((l) => l.get('id'))).flat());

    // Remove layers not coming from store
    mapLayerIds.forEach((layerId) => {
      if (!layerIds.has(layerId) && !excludedLayerIds.has(layerId)) {
        this._mapComponent.removeLayer(layerId);
      }
    });

    // Add or update layers from the store
    layers.forEach((layer) => this._addOrUpdateLayer(layer));
  }

  /** Adds or updates a layer on the map. */
  private _addOrUpdateLayer(bundle: LayerBundle) {
    const currentLayers = bundle.layerObjects.map((layer) => this._mapComponent.getLayerFromMap(layer.get('id')));

    if (bundle.group === 'cell-draw') {
      this._updateCellDrawLayer(bundle);
      return;
    }

    let addOrUpdate = false;

    // Check if we need to add or update any layer in the bundle
    if (
      currentLayers.length === 0 ||
      currentLayers.some((currentLayer, index) => !currentLayer || currentLayer.getSource() !== bundle.layerObjects[index].getSource())
    ) {
      addOrUpdate = true;
    }

    if (addOrUpdate) {
      // Remove the entire bundle if it's not permanent
      if (bundle.group !== 'permanent') {
        currentLayers.forEach((currentLayer) => {
          if (currentLayer) {
            this._mapComponent.removeLayer(currentLayer.get('id'));
          }
        });
      }

      // Add all layers in the bundle
      bundle.layerObjects.forEach((layer) => {
        this._mapComponent.addLayer(layer);
      });
    }
  }

  /** Updates a vector layer on the map. */
  private _updateCellDrawLayer(layer: LayerBundle) {
    const currentMapLayers = layer.layerObjects.map((layerObject) => this._mapComponent.getLayerFromMap(layerObject.get('id')));

    layer.layerObjects.forEach((updatedLayer, index) => {
      const currentMapLayer = currentMapLayers[index];
      if (currentMapLayer) {
        const currentMapLayerSource = currentMapLayer.getSource() as VectorSource;
        const updatedLayerSource = updatedLayer.getSource() as VectorSource;

        const newFeatures = updatedLayerSource.getFeatures();
        const currentFeatures = currentMapLayerSource.getFeatures();

        if (currentFeatures.length !== newFeatures.length || !currentFeatures.every((f, i) => f === newFeatures[i])) {
          currentMapLayerSource.clear();
          currentMapLayerSource.addFeatures(newFeatures);
        }
      } else {
        this._mapComponent.addLayer(updatedLayer);
      }
    });
  }

  /** Initializes airphoto visibility management if not already set up. */
  private _initializeAirphotoVisibilityManagement() {
    if (!this._layersManagedFlag) {
      this._layersManagedFlag = true;
      this._manageAirphotoVisibility();
    }
  }

  /** Initiates subscription, which manages the visibility of the airphoto layers based on zoom level and location. */
  private _manageAirphotoVisibility() {
    fromEventPattern<MapEvent>(
      (handler) => this._olMap?.on('moveend', handler),
      (handler) => this._olMap?.un('moveend', handler)
    ).subscribe((event) => {
      const zoom = event.map.getView().getZoom() ?? 0;
      const showAirPhotos = zoom > 12;
      let insideDK = false;

      if (showAirPhotos) {
        const coordinates = event.frameState?.viewState.center ?? [];
        insideDK =
          this._denmarkBoundary?.getFeatures().some((feature) => feature.getGeometry()?.intersectsCoordinate(coordinates)) ?? false;
      }

      this.layerService.setLayerVisibility(LayerId.OSM, !showAirPhotos);

      const airphotoLayers = [LayerId.AIRPHOTO_DK, LayerId.AIRPHOTO_FOREIGN];
      airphotoLayers.forEach((layerId) => {
        this.layerService.setLayerVisibility(layerId, showAirPhotos && (layerId === LayerId.AIRPHOTO_DK ? insideDK : !insideDK));
      });
    });
  }

  /**
   * Adds hover interaction to the map.
   * @param layers - The IDs of the layers to enable hover interaction for.
   */
  private _addHoverInteraction(layers?: LayerId[]) {
    this._hoverInteraction && this._olMap?.removeInteraction(this._hoverInteraction);

    this._hoverInteraction = new PointerInteraction({
      handleMoveEvent: (event) => {
        this.showPointerOnMapFeature(event, layers!);
      },
    });

    this._olMap?.addInteraction(this._hoverInteraction);
  }

  // Determine which feature has changed
  private _mapModifyEvent(
    event: ModifyEvent,
    modifyFeaturesRevisions: [string | number | undefined, number][]
  ): { feature: Feature; revision: number } | undefined {
    // Create a Map for quick lookups
    const revisionsMap = new Map<any, number>();
    modifyFeaturesRevisions.forEach(([id, revision]) => revisionsMap.set(id, revision));

    // Find the modified feature
    for (const feature of event.features.getArray()) {
      const featureId = feature.getId();
      const initialRevision = revisionsMap.get(featureId);
      if (initialRevision !== undefined && initialRevision !== feature.getRevision()) {
        return { feature, revision: initialRevision };
      }
    }

    return undefined;
  }

  // Magic re-render to fix rendering issues in the map
  public magicReRender() {
    this.mapComponent?.zoomIncrementally(0.0000001);
    this.mapComponent?.zoomIncrementally(-0.0000001);
  }

  // Only relevant for PlayWright e2e tests. Data-testid='canvas' in map.component.html template is delayed
  // to ensure the canvas element is clickable after the component is rendered.
  public e2eSetCanvasReady(isReady: boolean) {
    setTimeout(() => {
      this.canvasReadySubject.next(isReady);
    }, 1000);
  }
}
