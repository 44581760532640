<div class="filter-full" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
  <div class="filter" [class.active]="selectedOperationTypeGroupId === undefined" (click)="select(undefined)">
    {{ 'tasksFilter.all' | translate }}
  </div>
  <div
    *ngFor="let operationTypeGroup of operationTypeGroups$ | async"
    class="filter"
    [class.active]="selectedOperationTypeGroupId === operationTypeGroup?.id"
    (click)="select(operationTypeGroup)"
    attr.data-testid="task-filter-{{ operationTypeGroup?.id }}"
  >
    {{ operationTypeGroup.name }}
  </div>
</div>
<div class="filter-min" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
  <app-icon icon="menu_toggle "></app-icon>
  <mat-select [formControl]="menu">
    <mat-select-trigger>
      <span *ngIf="menu.value !== -1">{{ menu.value.name }}</span>
      <span *ngIf="menu.value === -1">{{ 'tasksFilter.all' | translate }}</span>
    </mat-select-trigger>
    <mat-option [value]="-1">{{ 'tasksFilter.all' | translate }}</mat-option>
    <mat-option *ngFor="let operationTypeGroup of operationTypeGroups$ | async" [value]="operationTypeGroup"
      >{{ operationTypeGroup.name }}
    </mat-option>
  </mat-select>
</div>
