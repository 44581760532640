import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeedbackSideDrawerContentModule } from './feedback-side-drawer-content/feedback-side-drawer-content.module';
import { FeedbackSideDrawerComponent } from './feedback-side-drawer.component';

@NgModule({
  declarations: [FeedbackSideDrawerComponent],
  imports: [CommonModule, FeedbackSideDrawerContentModule],
  exports: [FeedbackSideDrawerComponent],
})
export class FeedbackSideDrawerModule {}
