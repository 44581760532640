import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { Feature } from 'ol';

export const BasisLayerMapStyles = {
  basisLayerMapStyle: (feature: Feature): Style[] => [
    new Style({
      image: new Circle({
        radius: 7,
        fill: new Fill({
          color: [255, 0, 0, 0.5],
        }),
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
        lineDash: [4, 4],
      }),
      fill: new Fill({
        color: feature.get('basisLayer')?.category?.color,
      }),
      zIndex: 1,
      geometry: feature.getGeometry(),
    }),
  ],
  clusteredStyle: (text: string): Style[] => {
    return [
      new Style({
        image: new Circle({
          radius: 10,
          stroke: new Stroke({
            color: '#000000',
            width: 1,
          }),
          fill: new Fill({
            color: [255, 0, 0, 0.5],
          }),
        }),
        text: new Text({
          text: text,
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    ];
  },
  basisLayerDrawingStyle: [
    new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: [255, 0, 0, 0.5],
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 1,
        }),
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
      }),
      fill: new Fill({
        color: [224, 224, 212, 0.8], // color-sand
      }),
      zIndex: 3,
    }),
  ],
  basisLayerSelectedMapStyle: (feature: Feature): Style[] => [
    new Style({
      image: new Circle({
        radius: 7,
        fill: new Fill({
          color: [255, 0, 0, 0.5],
        }),
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 4,
      }),
      fill: new Fill({
        color: feature.get('basisLayer')?.category?.color,
      }),
      zIndex: 1,
      geometry: feature.getGeometry(),
    }),
  ],
};
