import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Crop } from '@app/core/interfaces/crop.interface';
import { NormDtoForFarm } from '@app/core/interfaces/norm-dto-for-farm';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CropNormDTO } from './crop-norm.dto';
import { VarietyNormDTO } from './variety-norm.dto';

@Injectable({
  providedIn: 'root',
})
export class CropsRepoService {
  private cropNormsCache = this.cacheService.create<CropNormDTO[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  private varietyNormsCache = this.cacheService.create<VarietyNormDTO[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private cacheService: CacheService
  ) {}

  public getById(farmIds: number[], harvestYear: number, cropId: number): Observable<Crop> {
    return this.http.get<Crop>(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/fields/crops/${cropId}`);
  }

  public getCrops(farmId: number, harvestYear?: number): Observable<CropNormDTO[]> {
    const key = `${farmId}-${harvestYear}`;
    const getValue = () => {
      return this.http.get<CropNormDTO[]>(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/cropnorms`);
    };

    return this.cropNormsCache.getOrSetAsync(key, () => getValue());
  }

  public getCropsForFarms(farmIds: number[], harvestYear: number) {
    const cropsForFarms$ = farmIds.map((farmId) => {
      return this.getCrops(farmId, harvestYear).pipe(
        map((cropNormDtos) => {
          return { farmId: farmId, normDtos: cropNormDtos } as NormDtoForFarm<CropNormDTO[]>;
        })
      );
    });

    return combineLatest(cropsForFarms$);
  }

  public cropNormsCacheClear() {
    this.cropNormsCache.clear();
  }

  public getVarietiesForCrop(harvestYear?: number, varietyGroupNumber?: number): Observable<VarietyNormDTO[]> {
    if (!varietyGroupNumber) {
      return of([{} as VarietyNormDTO]);
    }

    const key = `${varietyGroupNumber}-${harvestYear}`;

    const getvalue = () => {
      return this.http.get<VarietyNormDTO[]>(`${this.endpoints.foApi}/VarietyNorms`, {
        params: {
          harvestYear,
          varietyGroupNumber,
        },
      });
    };

    return this.varietyNormsCache.getOrSetAsync(key, () => getvalue());
  }
}
