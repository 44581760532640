import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/shared/material/material.module';
import { SelectOptionGroupComponent } from '@app/shared/select/select-option-group/select-option-group.component';
import { SelectOptionComponent } from '@app/shared/select/select-option/select-option.component';
import { SelectPrefixDirective } from '@app/shared/select/select-prefix/select-prefix.component';
import { SelectTriggerDirective } from '@app/shared/select/select-trigger/select-trigger.component';
import { SelectComponent } from '@app/shared/select/select.component';

@NgModule({
  exports: [SelectComponent, SelectOptionComponent, SelectOptionGroupComponent, SelectTriggerDirective, SelectPrefixDirective],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, ScrollingModule],
  declarations: [SelectComponent, SelectOptionComponent, SelectOptionGroupComponent, SelectTriggerDirective, SelectPrefixDirective],
})
export class SelectModule {}
