import { NgModule } from '@angular/core';
import { NdviRepo } from '@app/core/ndvis/ndvis-repo.service';
import { SharedModule } from '@app/shared/shared.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GrowthCurveComponent } from './growth-curve/growth-curve.component';
import { NdviDateImgComponent } from './ndvi-date-img/ndvi-date-img.component';
import { NdviDatePickerComponent } from './ndvi-date-picker/ndvi-date-picker.component';
import { NdviFeatureComponent } from './ndvi-feature.component';
import { NdviComponent } from './ndvi.component';
import { NdviService } from './service/ndvi.service';

@NgModule({
  imports: [SharedModule, DateInputsModule],
  providers: [NdviService, NdviRepo],
  declarations: [NdviFeatureComponent, NdviComponent, GrowthCurveComponent, NdviDatePickerComponent, NdviDateImgComponent],
  exports: [NdviFeatureComponent, NdviDatePickerComponent],
})
export class NdviMapFeatureModule {}
