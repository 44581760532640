import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss'],
})
export class SelectOptionComponent {
  @Input() public value: any;
  @Input() public disabled?: boolean;
  @Input() public tooltip?: string;

  @ViewChild('label', { static: false }) public templateRef?: TemplateRef<any>;
}
