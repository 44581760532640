import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { NormDtoForFarm } from '@app/core/interfaces/norm-dto-for-farm';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { CropNormDTO } from '@app/core/repositories/crops/crop-norm.dto';
import { AsAppliedTask, AsAppliedTaskDto } from './as-applied-task.class';

export interface AsAppliedOperationTypeGroupDto {
  tasks: AsAppliedTaskDto[];
  id: OperationTypeGroupEnum;
  name: string;
}

export class AsAppliedOperationTypeGroup {
  public readonly tasks: AsAppliedTask[] = [];
  public readonly id: OperationTypeGroupEnum;
  public readonly name: string;

  constructor(
    dto: AsAppliedOperationTypeGroupDto,
    produceNormsForFarms: NormDtoForFarm<ProduceNorm[]>[],
    cropNormsForFarms: NormDtoForFarm<CropNormDTO[]>[],
    languageService: LanguageService
  ) {
    this.id = dto.id;
    this.name = dto.name;

    this.tasks = dto.tasks.map((task) => new AsAppliedTask(task, produceNormsForFarms, cropNormsForFarms, languageService));
  }
}
