<app-side-drawer
  #sideDrawer
  [showBackdrop]="false"
  [width]="'40vw'"
  (onCloseRequest)="onCloseSideDrawer($event)"
  [templateRef]="sideDrawerContentRef"
>
  <app-side-drawer-toolbar title="{{ 'main.benchmark.title' | translate }}"></app-side-drawer-toolbar>
</app-side-drawer>
<ng-template #sideDrawerContentRef>
  <app-benchmark-sidedrawer></app-benchmark-sidedrawer>
</ng-template>
<app-map-feature-select
  *hasAccessTo="'yield_benchmark'"
  (event)="onRegionSelectedOnMap($event)"
  [layers]="selectableLayers"
  [disabled]="false"
  [toggle]="true"
></app-map-feature-select>
