<div class="info-box">
  <div class="first">
    <span class="info-line"></span>
    <p class="info-text">
      {{ 'main.benchmark.overview.averageYieldInfo' | translate }}
      <b>{{ regionalAverage | number }} {{ 'common.unitType.kr' | translate }}/{{ unit }}</b
      >, {{ 'main.benchmark.overview.averagePersonalYieldInfo' | translate }}
      <b>{{ personalAverage | number }} {{ 'common.unitType.kr' | translate }}/{{ unit }}</b
      >. {{ 'main.benchmark.overview.averageYieldCountInfo' | translate }} <b>{{ farmCount | number }} {{ 'common.farms' | translate }}</b
      >.
    </p>
  </div>
</div>
