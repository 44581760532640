<div class="wrapper">
  <app-icon class="close-btn" [icon]="'Luk'" (click)="close()"></app-icon>
  <mat-dialog-content class="content-wrapper">
    <div class="text">
      <div class="logo"></div>
      <h3 class="info-text">{{ data.title | translate }}</h3>
    </div>
    <p *ngIf="data.body">{{ data.body | translate }}</p>
    <div class="actions">
      <app-square-button [buttonType]="'CTA'" (click)="updateSubscriptions()">{{ data.ok | translate }}</app-square-button>
      <app-square-button *ngIf="data.cancel" [buttonType]="'Secondary'" (click)="close()">{{ data.cancel | translate }}</app-square-button>
    </div>
  </mat-dialog-content>
</div>
