import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MapService } from '@app/core/map/map.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { MetadataView } from '@app/map/features/field-analysis/features/as-applied/file-upload/as-applied-meta-data/metadata-view';
import { MetadataChild, MetadataGeometry } from '@app/map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { AsAppliedFileUploadService } from '@app/map/features/field-analysis/features/as-applied/file-upload/services/as-applied-file-upload.service';
import { MetadataMapService } from '@app/map/features/field-analysis/features/as-applied/file-upload/services/metadata-map.service';
import { MetadataTask } from '@app/map/features/field-analysis/features/as-applied/metadata-task.class';
import { FieldAnalysisSideDrawerService } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.service';
import { ColorService } from '@app/new-map/features/vra/state/color.state';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { AsAppliedShownComponentEnum } from '../../../as-applied-shown-component.enum';
import { AsAppliedService } from '../../../as-applied.service';

@Component({
  selector: 'app-as-applied-meta-data',
  templateUrl: './as-applied-meta-data.component.html',
  styleUrls: ['../metadata-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MetadataMapService],
  animations: [MetadataView.FLY_IN_OUT],
})
export class AsAppliedMetaDataComponent extends MetadataView implements OnInit, OnDestroy {
  @Input() public saveClicked$!: Observable<void>;

  public selectableFields$!: Observable<Field[]>;
  public selectableTasks$!: Observable<MetadataTask[]>;
  public shownComponent$ = this.asAppliedService.shownComponent$;
  public asAppliedShownComponentEnum = AsAppliedShownComponentEnum;
  private subscriptions = new Subscription();
  private _mapLayerId!: ReplaySubject<MapLayerId>;
  private _mapLayerPointId!: ReplaySubject<MapLayerId>;

  constructor(
    private asAppliedFileUploadService: AsAppliedFileUploadService,
    private formBuilder: UntypedFormBuilder,
    private metadataMapService: MetadataMapService,
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private languageService: LanguageService,
    private vraColorService: ColorService,
    private harvestYearStateService: HarvestYearStateService,
    private asAppliedService: AsAppliedService,
    private dirtyCheckService: DirtyCheckService,
    private notificationService: NotificationService,
    private mapService: MapService,
    public dialogService: DialogService
  ) {
    super(formBuilder, languageService, fieldAnalysisSideDrawerService);
  }

  public ngOnInit() {
    this.mapService.disableFeatureSelection();
    this._mapLayerId = new ReplaySubject(1);
    this._mapLayerPointId = new ReplaySubject(1);
    this.subscriptions.add(this.saveClicked$?.subscribe(() => this.saveClicked()));
    this.selectableFields$ = this.asAppliedFileUploadService.selectableFields$;
    this.selectableTasks$ = this.asAppliedFileUploadService.selectableTasks$;
    this.asAppliedFileUploadService.metaDataParent$.pipe(first()).subscribe((metadataParent) => {
      this.metadataParent = metadataParent;
      this.createParentForm(metadataParent);
      if (!metadataParent.metadataChildren?.length) {
        this.addChildForm();
      } else {
        this.populateChildrenFormArray(metadataParent.metadataChildren);
      }

      if (this.asAppliedService.getShownComponent() !== AsAppliedShownComponentEnum.AsAppliedConfigFile) {
        const metadataGeometries = metadataParent.metadataChildren.map((child) => child.geometries).flat();
        const legend = this.vraColorService.getAsAppliedLegendColorValues(metadataGeometries);
        const pointsVisibleByDefault = metadataGeometries.length < this.DEFAULT_POINT_THRESHOLD;
        this.vraColorService.setAsAppliedPointsAndCellColors(metadataGeometries, legend);
        const unitsString = this.joinUniqueUnits(metadataParent.units);
        this.setLegendSettings(legend, unitsString);
        this.metadataMapService.addMetadataChildrenToMap(metadataParent.metadataChildren, unitsString, pointsVisibleByDefault);

        this.metadataParent.metadataChildren.forEach((item) => {
          const geometryTypes = item.geometries.filter((x: MetadataGeometry) => x.type);
          const isPositive = metadataGeometries.length > 0;
          const layerId = this.metadataMapService.getMapLayerId(geometryTypes[0].type);
          const nextLayerId = isPositive ? layerId : null;

          if (geometryTypes[0].type === 'Polygon') {
            this._mapLayerId.next(nextLayerId!);
          } else {
            this._mapLayerPointId.next(nextLayerId!);
          }
        });
      }

      this.subscriptions.add(
        this.parentForm.valueChanges.subscribe(() => {
          // Mark this feature as dirty if the form is not pristine, or a child has been removed
          if (!this.parentForm.pristine || metadataParent.metadataChildren.length !== this.childrenFormArray.length) {
            this.dirtyCheckService.setIsFeatureDirty(this, true);
          } else {
            this.dirtyCheckService.setIsFeatureDirty(this, false);
          }
        })
      );
      this.subscriptions.add(
        this.harvestYearStateService.harvestYear$.pipe(skip(1)).subscribe(() => {
          this.fieldAnalysisSideDrawerService.setShowLegendState(false);
          this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
        })
      );
    });
  }

  public ngOnDestroy(): void {
    this.metadataMapService.clearLayers();
    this.metadataMapService.resetFeaturesToFields();
    this.subscriptions.unsubscribe();
  }

  public get mapLayerPointId$() {
    return this._mapLayerPointId.asObservable();
  }

  public get mapLayerId$() {
    return this._mapLayerId.asObservable();
  }

  public saveClicked() {
    this.parentForm.markAllAsTouched();
    if (this.parentForm.valid) {
      const metadataChildren = this.getMetadataChildrenFromForm();

      this.harvestYearStateService.harvestYear$.pipe(first()).subscribe((harvestYear) => {
        let isEqualToCurrentHarvestYear = false;

        isEqualToCurrentHarvestYear = metadataChildren.some((metadataChildren) =>
          this.harvestYearStateService.isDateInHarvestYear(metadataChildren.date, harvestYear!)
        );

        if (!isEqualToCurrentHarvestYear) {
          const data = {
            title: 'main.asApplied.metaData.notEqualHarvestYearDialog.text',
            confirmText: 'main.asApplied.metaData.notEqualHarvestYearDialog.confirmText',
            cancelText: 'main.asApplied.metaData.notEqualHarvestYearDialog.cancelText',
          };

          this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => {
            this.asAppliedFileUploadService.createMetadataChildren(this.metadataParent.id, metadataChildren);
          });
        } else {
          this.asAppliedFileUploadService.createMetadataChildren(this.metadataParent.id, metadataChildren);
        }
      });
    } else {
      this.childForms?.forEach((childForm) => {
        // If uploaded file is a shapefile appliedDate is undefined and we have to
        // call updateValueAndValidity to see the validation error
        childForm.get('appliedDate')?.updateValueAndValidity();
        childForm.get('unit')?.updateValueAndValidity();
      });
    }
  }

  public removeChildForm(formGroup: UntypedFormGroup) {
    if (this.childForms.length <= 1) {
      this.notificationService.showError('main.asApplied.metaData.deleteLastChildError');
    } else {
      this.dirtyCheckService.setIsFeatureDirty(this, true);
      const indexId = formGroup.get('indexId')?.value;
      if (indexId !== null && indexId !== undefined) {
        this.metadataMapService.removeMetadataChildFromMap(indexId);
      }
      this.childrenFormArray.removeAt(this.childrenFormArray.controls.indexOf(formGroup));
      this.updateDataFieldOutlines();
    }
  }

  private getMetadataChildrenFromForm(): MetadataChild[] {
    return this.childForms.map((childForm) => {
      const fieldNumber = childForm.get('fieldNumber')?.value;
      const appliedDate = childForm.get('appliedDate')?.value ? DateTime.fromISO(childForm.get('appliedDate')?.value) : DateTime.now();
      const metadataTask = childForm.get('metadataTask')?.value;
      const processId = childForm.get('processId')?.value;
      const fieldYearId = childForm.get('fieldYearId')?.value;
      const unit = childForm.get('unit')?.value;
      return { fieldName: fieldNumber, date: appliedDate, metadataTask, processId, fieldYearId, unit } as MetadataChild;
    });
  }

  public removeClicked() {
    this.asAppliedFileUploadService.deleteMetadataParent();
  }

  public togglePoint(formGroup: UntypedFormGroup) {
    const indexId = formGroup.get('indexId')?.value;
    const showPoints = formGroup.get('showPoints')?.value;
    if (indexId !== null && indexId !== undefined) {
      this.metadataMapService.toggleMetadataChildVisibility(indexId, showPoints);
    }
  }

  public updateDataFieldOutlines() {
    this.metadataMapService.addOutlineToFieldsWithData(this.getMetadataChildrenFromForm());
  }
}
