import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { ActionEvent } from '@app/shared/cards-or-table/interfaces/action-event.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

type ItemType = string | number | symbol;

@Component({
  selector: 'app-card-presentation',
  templateUrl: './card-presentation.component.html',
  styleUrls: ['./card-presentation.component.scss'],
})
export class CardPresentationComponent {
  public fieldDefinitions$ = new BehaviorSubject<FieldDefinition<any>[]>([]);
  public filteredFieldDefinitions$ = this.fieldDefinitions$.pipe(
    map((fieldDefinitions) =>
      fieldDefinitions.filter(
        (fieldDefinition) =>
          !fieldDefinition.isCardTitle &&
          !fieldDefinition.isCardSubTitle &&
          !fieldDefinition.isCardAvatar &&
          fieldDefinition.hide !== 'hide-on-card'
      )
    )
  );
  public dataItem$ = new BehaviorSubject([] as ItemType[]);

  public cardTitle$ = combineLatest(this.fieldDefinitions$, this.dataItem$).pipe(
    map(([fieldDefinitions, dataItem]) => this.mapCardTitle(fieldDefinitions, dataItem))
  );

  public cardSubTitle$ = combineLatest(this.fieldDefinitions$, this.dataItem$).pipe(
    map(([fieldDefinitions, dataItem]) => this.mapCardSubTitle(fieldDefinitions, dataItem))
  );

  public avatar$ = combineLatest(this.fieldDefinitions$, this.dataItem$).pipe(
    map(([fieldDefinitions, dataItem]) => ({
      avatarDefinition: fieldDefinitions.find((fieldDefinition) => fieldDefinition.isCardAvatar),
      dataItem,
    })),
    map(({ avatarDefinition, dataItem }) => (avatarDefinition ? this.mapAvatar(avatarDefinition, dataItem) : undefined))
  );

  @Input()
  public readonly minHeight!: string;
  @Input()
  public actionDefinitions: ActionDefinition[] = [];

  @Input()
  public set dataItem(value: any[]) {
    this.dataItem$.next(value);
  }

  @Input()
  public set fieldDefinitions(value: FieldDefinition<any>[]) {
    this.fieldDefinitions$.next(value);
  }

  @Output()
  public readonly action = new EventEmitter<ActionEvent<any>>();

  constructor(private domSanitizer: DomSanitizer) {}

  public triggerAction(dataItem: any, actionDefinition: ActionDefinition) {
    this.action.emit({
      type: actionDefinition.type,
      dataItem: dataItem,
    });

    if (actionDefinition.callback) {
      actionDefinition.callback(dataItem);
    }
  }

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public getItemProperty(item, property) {
    const val = item[property];
    return val.toString();
  }

  private mapAvatar(avatarDefinition: FieldDefinition<any>, dataItem: any) {
    if (dataItem[avatarDefinition.property!]) {
      return this.mapToCssUrl(dataItem[avatarDefinition.property!]);
    }

    // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    return this.mapToCssUrl(avatarDefinition.defaultAvatarUrl);
  }

  private mapCardTitle(fieldDefinitions: FieldDefinition<any>[], dataItem: any) {
    const cardTitleDefinition = fieldDefinitions.find((fieldDefinition) => fieldDefinition.isCardTitle);

    if (!cardTitleDefinition) {
      return '';
    }

    return dataItem[cardTitleDefinition.property!];
  }

  private mapCardSubTitle(fieldDefinitions: FieldDefinition<any>[], dataItem: any) {
    const cardTitleDefinition = fieldDefinitions.find((fieldDefinition) => fieldDefinition.isCardSubTitle);

    if (!cardTitleDefinition) {
      return '';
    }

    return dataItem[cardTitleDefinition.property!];
  }

  private mapToCssUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
}
