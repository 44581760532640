<app-side-drawer-wrapper>
  <app-side-drawer-header
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
    cancelIconName="minimize"
    title="{{ 'navigation.fieldAnalysis' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <h5 class="title">{{ 'main.fieldInspector.chooseFieldAnalysis' | translate }}</h5>
    <div class="field-analysis-list" id="e2e-analysis-select">
      <app-tooltip
        *ngFor="let fieldAnalysisMapFeature of fieldAnalysisMapFeatures"
        class="tooltip"
        [delayType]="'long'"
        [tooltipText]="fieldAnalysisMapFeature.isDisabled ? ('main.fieldmap.vra.requiresVraSubscription' | translate) : null"
        fullWidth
      >
        <div
          *ngIf="fieldAnalysisMapFeature.mapCoverFlowLayersId !== 'isoxml'; else new"
          class="field-analysis-item"
          [ngClass]="{ disabled: fieldAnalysisMapFeature.isDisabled }"
          (click)="onSelect(fieldAnalysisMapFeature.mapCoverFlowLayersId!)"
          appGTagEventTracker
          eventName="{{ fieldAnalysisMapFeature.displayName! | lastSegment }} (click)"
          eventCategory="Field Analysis"
          matTooltip="{{
            fieldAnalysisMapFeature.isDisabled
              ? fieldAnalysisMapFeature.displayName === 'main.fieldmap.layers.soilSamples'
                ? ('common.map-controls.soilsample.requiredAccess' | translate)
                : ('main.fieldmap.vra.requiresVraSubscription' | translate)
              : null
          }}"
        >
          {{ fieldAnalysisMapFeature.displayName! | translate }}
        </div>
        <ng-template #new>
          <div
            *ngIf="queryParams$ | async as queryParams"
            class="field-analysis-item"
            [ngClass]="{ disabled: fieldAnalysisMapFeature.isDisabled }"
            (click)="navigate(fieldAnalysisMapFeature.mapCoverFlowLayersId!, queryParams)"
            appGTagEventTracker
            eventName="{{ fieldAnalysisMapFeature.displayName! | lastSegment }} (click)"
            eventCategory="Field Analysis"
          >
            {{ fieldAnalysisMapFeature.displayName! | translate }}
            <img alt="beta flag" src="/assets/images/new-flags/label_beta.svg" />
          </div>
        </ng-template>
      </app-tooltip>
    </div>
  </app-side-drawer-body>
</app-side-drawer-wrapper>
