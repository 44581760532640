import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';
import { SubscriptionDto, SubscriptionForUserDTO } from './subscriptions-repo.interface';

@Injectable({ providedIn: 'root' })
export class SubscriptionsRepoService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getSubscriptions(): Observable<SubscriptionDto[]> {
    return this.httpClient.get<SubscriptionDto[]>(`${this.endpoints.foApi}/subscriptions/cropmanagerproducts`);
  }

  public setSubscriptions(subs: any[] | null) {
    return this.httpClient.put<SubscriptionDto[], any[]>(`${this.endpoints.foApi}/subscriptions/cropmanagerproducts`, subs!);
  }

  public getSubscriptionsForUser(userName: string): Observable<SubscriptionForUserDTO[]> {
    return this.httpClient.get<SubscriptionForUserDTO[]>(`${this.endpoints.foApi}/subscriptions/foruser/${userName}`);
  }
}
