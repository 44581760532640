import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import {
  FeatureCoordinates,
  LineStringCoordinates,
  MultiPolygonCoordinates,
  PointCoordinates,
  PolygonCoordinates,
} from '@app/core/interfaces/geometry-coordinates.types';
import { MapConstants } from '@app/core/map/map.constants';
import { Feature } from 'ol';
import { Extent, getCenter, extend as olExtend } from 'ol/extent';
import { Geometry, LineString, MultiPolygon, Point, Polygon } from 'ol/geom';
import { LayerId } from '../../services/layer/layer.store';
import { GeometryUtil } from './geometry-util';

export class FeatureUtil {
  public static getMapFeature(featureType: LayerId | MapLayerId, geometryType: GeometryType, coordinates?: FeatureCoordinates): Feature {
    const feature = new Feature();
    switch (geometryType) {
      case GeometryType.POINT:
        const point = new Point(coordinates as PointCoordinates);
        feature.setGeometry(this._transformGeometryToMap(point));
        feature.set('centerCoords', getCenter(point.getExtent()));
        break;
      case GeometryType.LINE:
        const lineString = new LineString(coordinates as LineStringCoordinates);
        feature.setGeometry(this._transformGeometryToMap(lineString));
        feature.set('centerCoords', lineString.getCoordinateAt(0.5));
        break;
      case GeometryType.MULTIPOLYGON:
        const multiPolygon = new MultiPolygon(coordinates as MultiPolygonCoordinates);
        feature.setGeometry(this._transformGeometryToMap(multiPolygon));
        feature.set('centerCoords', multiPolygon.getInteriorPoints().getCoordinates());
        break;

      default:
        const polygon = new Polygon(coordinates as PolygonCoordinates);
        feature.setGeometry(this._transformGeometryToMap(polygon));
        feature.set('centerCoords', polygon.getInteriorPoint().getCoordinates());
        break;
    }

    feature.set('layerId', featureType);
    return feature;
  }

  public static createPolygonFeature(layerId: LayerId, coordinates: PolygonCoordinates): Feature {
    const feature = new Feature();
    feature.setGeometry(this._transformGeometryToMap(new Polygon(coordinates)));
    feature.set('layerId', layerId);
    return feature;
  }

  public static createMultiPolygonFeature(layerId: LayerId, coordinates: MultiPolygonCoordinates): Feature {
    const feature = new Feature();
    feature.setGeometry(this._transformGeometryToMap(new MultiPolygon(coordinates)));
    feature.set('layerId', layerId);
    return feature;
  }

  public static getGeometryTypeFromFeature(feature: Feature): GeometryType | undefined {
    const olGeometryType = feature.getGeometry()!.getType();
    return this._getGeometryType(olGeometryType);
  }

  public static getCoordinatesFromFeature(feature: Feature): number[] | number[][] {
    const geometry = feature.getGeometry();

    let coordinates: number[] | number[][] = [];

    if (geometry instanceof Point) {
      coordinates = this._getCoordinatesFromPoint(geometry);
    } else if (geometry instanceof LineString) {
      coordinates = this._getCoordinatesFromLineString(geometry);
    } else if (geometry instanceof Polygon) {
      coordinates = this._getCoordinatesFromPolygon(geometry);
    }

    return coordinates;
  }

  public static getCenterOfFeature(feature: Feature): PointCoordinates {
    return feature.get('centerCoords') ? feature.get('centerCoords') : getCenter(feature.getGeometry()!.getExtent());
  }

  public static getExtentOfFeatures(features: Feature<Geometry>[]): Extent {
    return features.reduce(
      (extent: Extent, feature: Feature<Geometry>) => {
        const featureExtent = feature.getGeometry()!.getExtent();
        return olExtend(extent, featureExtent);
      },
      [Infinity, Infinity, -Infinity, -Infinity] as Extent
    );
  }

  private static _transformGeometryToMap(geometry: Geometry): Geometry {
    return geometry.transform(MapConstants.dataProjection, MapConstants.mapProjection);
  }

  private static _getGeometryType(geometry: string): GeometryType | undefined {
    // get expected geometrytype from geometry
    switch (geometry) {
      case 'Polygon':
        return GeometryType.POLYGON;
      case 'LineString':
        return GeometryType.LINE;
      case 'Point':
        return GeometryType.POINT;
      default:
        return;
    }
  }

  private static _getCoordinatesFromPoint(geom: Point): number[] {
    let coordinate = geom.getCoordinates();

    coordinate = GeometryUtil.transformCoordinateFromMap(coordinate);

    return coordinate;
  }

  private static _getCoordinatesFromLineString(geom: LineString): number[][] {
    let coordinates = geom.getCoordinates();

    coordinates = coordinates.map((coordinate) => GeometryUtil.transformCoordinateFromMap(coordinate));

    return coordinates;
  }

  private static _getCoordinatesFromPolygon(geom: Polygon): number[][] {
    let coordinates = geom.getCoordinates()[0];

    coordinates = coordinates.map((coordinate) => GeometryUtil.transformCoordinateFromMap(coordinate));

    return coordinates;
  }
}
