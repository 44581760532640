<kendo-chart>
  <kendo-chart-title [text]="farm.name"></kendo-chart-title>
  <kendo-chart-axis-defaults-title [text]="yAxisTitle"></kendo-chart-axis-defaults-title>
  <kendo-chart-legend position="bottom"></kendo-chart-legend>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item>
      <kendo-chart-category-axis-item-title text="{{ 'common.fields' | translate }}"></kendo-chart-category-axis-item-title>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-series>
    <kendo-chart-series-item
      color="#076471"
      type="column"
      [data]="farm.fields"
      [field]="'value'"
      [categoryField]="'fieldNumber'"
      [labels]="xLabels"
    >
    </kendo-chart-series-item>
    <kendo-chart-series-item
      color="#E95D0F"
      type="line"
      [data]="average"
      [markers]="averageMarkers"
      [width]="3"
      [name]="'main.benchmark.average' | translate"
    >
      <kendo-chart-series-item-tooltip>
        <ng-template let-dataItem="dataItem"> {{ 'main.benchmark.average' | translate }}: {{ dataItem | json }} </ng-template>
      </kendo-chart-series-item-tooltip>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
