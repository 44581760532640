import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapLayerControlService {
  private sidedrawerWidth = new BehaviorSubject(0);
  public sidedrawerWidth$ = this.sidedrawerWidth.asObservable();
  constructor() {}

  public setSidedrawerWidth(widthPx: number) {
    this.sidedrawerWidth.next(widthPx);
  }
}
