import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RememberSeptoriaDialogComponent } from '@app/core/extra-module-dialog/remember-septoria-dialog/remember-septoria-dialog.component';
import { FarmPickerService } from '@app/layouts/header/farm-picker/farm-picker.service';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { NewInCropmanagerDialogComponent } from './new-in-cropmanager-dialog/new-in-cropmanager-dialog.component';

@Component({
  selector: 'app-extra-module-dialog',
  template: '',
})
export class ExtraModuleDialogComponent implements OnInit, OnDestroy {
  // septoria
  private insuranceDialogRef!: MatDialogRef<NewInCropmanagerDialogComponent> | null;
  private rememberSeptoriaDialogRef!: MatDialogRef<RememberSeptoriaDialogComponent> | null;
  private subscriptions = new Subscription();

  constructor(
    private dialogService: DialogService,
    private featureToggleService: FeatureToggleService,
    private farmPickerService: FarmPickerService
  ) {}

  public ngOnInit() {
    this.farmPickerService.numberOfSelectedFarms$
      .pipe(
        filter((numberOfSelectedFarms) => numberOfSelectedFarms > 0),
        first()
      )
      .subscribe(() => {
        this.openInsuranceDialog();
        this.openRememberSeptoriaDialog();
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private openInsuranceDialog() {
    setTimeout(() => {
      this.subscriptions.add(
        this.featureToggleService.get$('plant_cropmanager_remember_insurance_dialog').subscribe((insuranceEnabled) => {
          if (!localStorage.getItem('seenInsuranceDialog') && insuranceEnabled) {
            localStorage.removeItem('seenSeptoriaExtraDialog');
            this.insuranceDialogRef = this.dialogService.openCustomDialog(NewInCropmanagerDialogComponent, {
              hasBackdrop: true,
              maxWidth: '94vw',
            });

            this.insuranceDialogRef.afterClosed().subscribe(() => {
              localStorage.setItem('seenInsuranceDialog', DateTime.now().toMillis() + '');

              this.insuranceDialogRef = null;
            });
          }
          if (!insuranceEnabled) {
            localStorage.removeItem('seenInsuranceDialog');
          }
        })
      );
    });
  }

  private openRememberSeptoriaDialog() {
    setTimeout(() => {
      this.subscriptions.add(
        this.featureToggleService.get$('plant_cropmanager_remember_septoria').subscribe((septoriaEnabled) => {
          if (!localStorage.getItem('seenRememberSeptoriaDialog') && septoriaEnabled) {
            this.rememberSeptoriaDialogRef = this.dialogService.openCustomDialog(RememberSeptoriaDialogComponent, {
              hasBackdrop: true,
              maxWidth: '94vw',
            });

            this.rememberSeptoriaDialogRef.afterClosed().subscribe(() => {
              localStorage.setItem('seenRememberSeptoriaDialog', DateTime.now().toMillis() + '');

              this.rememberSeptoriaDialogRef = null;
            });
          }
          if (!septoriaEnabled) {
            localStorage.removeItem('seenRememberSeptoriaDialog');
          }
        })
      );
    });
  }
}
