import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { MoreInfoComponent } from './more-info.component';

@NgModule({
  imports: [CommonModule, ButtonsModule, IconModule],
  declarations: [MoreInfoComponent],
  exports: [MoreInfoComponent],
})
export class MoreInfoModule {}
