import { EventEmitter, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LanguagePickerService } from '@app/core/language-picker/language-picker.service';
import { DEFAULT_CULTURE } from '@app/core/variables';
import { LanguageStateService } from '@app/state/services/language/language-state.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export interface ILanguage {
  shortKey: string;
  longKey: string;
  name: string;
  show: boolean;
}

export interface ILanguageService {
  onLangChange: EventEmitter<LangChangeEvent>;
  languageChanged: Subject<void>;
  getLanguages(): Array<ILanguage>;
  getLanguage(key: string): ILanguage;
  setLanguage(language: ILanguage | string): void;
  get(arr: string[]): Observable<any>;
  getText(text: string): string;
}

/**
 * LanguageService
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService implements ILanguageService {
  public languageChanged!: Subject<void>;
  public currentLanguage!: ILanguage;
  public onLangChange = this.translate.onLangChange;
  private languages!: Array<ILanguage>;

  constructor(
    public languagePickerService: LanguagePickerService,
    public translate: TranslateService,
    private languageStateService: LanguageStateService,
    @Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    @Inject(DEFAULT_CULTURE) public defaultCulture: string
  ) {
    this.init();
  }

  public init(): void {
    this.languageChanged = new Subject<void>();

    this.languages = [
      {
        shortKey: 'da',
        longKey: 'da-DK',
        name: 'Dansk',
        show: true,
      },
      {
        shortKey: 'en',
        longKey: 'en-GB',
        name: 'English',
        show: true,
      },
      {
        shortKey: 'de',
        longKey: 'de-DE',
        name: 'Deutsch',
        show: true,
      },
      {
        shortKey: 'pl',
        longKey: 'pl-PL',
        name: 'Polski',
        show: false,
      },
      {
        shortKey: 'sv',
        longKey: 'sv-SE',
        name: 'Svenska',
        show: false,
      },
    ];

    this.setLanguage(this.defaultCulture);
    this.languagePickerService.setLanguage(this.defaultCulture);

    this.languagePickerService.getLangShortCode$().subscribe((langShortCode: string) => {
      if (langShortCode) {
        this.setLanguage(langShortCode);
      }
    });
  }

  public setLanguage(language: ILanguage | string): void {
    if (typeof language === 'string') {
      language = this.getLanguage(language as string);
    }

    this.translate
      .use((language as ILanguage).shortKey)
      .pipe(take(1))
      .subscribe(() => {
        if (this.languageChanged) {
          this.languageChanged.next();
        }
      });

    this.currentLanguage = language;
    this.languageStateService.currentLanguage = language.shortKey;
    (<CldrIntlService>this.intlService).localeId = language.shortKey;
  }

  public getLanguages(): Array<ILanguage> {
    return this.languages;
  }

  public getLanguage(key: string): ILanguage {
    const lookupKey = key.length === 2 ? 'shortKey' : 'longKey';

    for (const language of this.languages) {
      if (language[lookupKey] === key) {
        return language;
      }
    }

    // set default if not found
    this.languagePickerService.setLanguage(this.defaultCulture);
    this.languageStateService.currentLanguage = this.defaultCulture;
    return this.getLanguage(this.defaultCulture);
  }

  public getText(key: string, interPolateParams?: Object): string {
    return this.translate.instant(key, interPolateParams);
  }

  public get(arr: string[]): Observable<string | any> {
    return this.translate.get(arr);
  }
}
