import { Component, OnInit } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { Router } from '@angular/router';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { BrowserDetectorService } from '@app/core/browser-detector/browser-detector.service';
import { ConsentStateService } from '@app/core/consent/consent-state.service';
import { FarmService } from '@app/core/farm/farm.service';
import { HttpClient } from '@app/core/http/http-client';
import { LogService } from '@app/core/log/log.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { StorageService } from '@app/core/storage/storage.service';
import { SpinnerOverlayService } from '@app/shared/spinner-overlay/spinner-overlay.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { FeatureService } from './core/feature/feature.service';
import { QuerySyncService } from './core/query-param/query-sync.service';
import { GlobalStateService } from './state/services/global/global-state.service';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isMenuOpen!: boolean;
  public isConsentReady$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = new Subject<boolean>();

  constructor(
    public router: Router,
    private bffAuthService: BffAuthService,
    private httpClient: HttpClient,
    private featureService: FeatureService,
    private querySync: QuerySyncService,
    private consentStateService: ConsentStateService,
    notificationService: NotificationService,
    browserDetectorService: BrowserDetectorService,
    farmService: FarmService,
    logService: LogService,
    spinnerOverlayService: SpinnerOverlayService,
    private globalStateService: GlobalStateService,
    storageService: StorageService,
    public intl: IntlService
  ) {
    logService.initialize();
    if (environment.isLocalhost) {
      // eslint-disable-next-line no-console
      console.log(environment);
    }

    farmService.init();

    this.globalStateService.isLoading$
      .pipe(withLatestFrom(this.globalStateService.specificLoadings$))
      .subscribe(([isLoading, specificLoadings]) => {
        if (isLoading) {
          specificLoadings.length
            ? spinnerOverlayService.show(specificLoadings.find((specificLoading) => !!specificLoading))
            : spinnerOverlayService.show();
        } else {
          spinnerOverlayService.hide();
        }
      });
    if ((browserDetectorService.isIE11() || browserDetectorService.isOldEdgeVersion()) && storageService.shouldShowIE11Message()) {
      notificationService.showBrowserRecommendation();
    }
  }

  public ngOnInit() {
    this.consentStateService.consents$.subscribe((consents) => {
      this.isConsentReady$.next(!!consents);
    });

    this.featureService.subscribeFieldFeatures();
    this.bffAuthService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated === true),
        tap(() => {
          this.isAuthenticated$.next(true);
        }),
        first(),
        switchMap(() => this.httpClient.onForbidden.pipe(first())),
        switchMap(() => this.bffAuthService.forceGetUser())
      )
      .subscribe({
        error: () => {
          this.bffAuthService.logout();
        },
      });

    this.querySync.startSyncQueryParams();
  }
}
