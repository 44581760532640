import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export type PresentationMode = 'cards' | 'table';

@Component({
  selector: 'app-cards-or-table-toggle',
  templateUrl: './cards-or-table-toggle.component.html',
  styleUrls: ['./cards-or-table-toggle.component.scss'],
})
export class CardsOrTableToggleComponent implements OnInit, OnDestroy {
  public windowWidth$ = new BehaviorSubject<number>(window.innerWidth);

  public get storedState(): PresentationMode {
    const storedValue = localStorage.getItem('preferedCardsOrTablePresentationMode') as PresentationMode;

    if (storedValue === null) {
      return 'table';
    }

    return storedValue;
  }

  public set storedState(value: PresentationMode) {
    localStorage.setItem('preferedCardsOrTablePresentationMode', value);
  }

  @Input() public value: PresentationMode = this.storedState;
  @Input() public standalone = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public readonly change = new EventEmitter<PresentationMode>();

  private subscriptions: Subscription[] = [];
  private lastSeenWindiwWidth!: number;

  public ngOnInit() {
    this.subscriptions.push(
      this.windowWidth$
        .pipe(distinctUntilChanged(), debounceTime(50))
        .subscribe((width) => this.handleResponsiveness(this.lastSeenWindiwWidth, width))
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public valueChange($event: PresentationMode) {
    // this.standalone is Readonly, and is therefore always false
    if (this.standalone) {
      this.value = $event;
    }

    this.storedState = $event;
    this.change.emit($event);
  }

  public useCards() {
    this.valueChange('cards');
  }

  public useTable() {
    this.valueChange('table');
  }

  @HostListener('window:resize', ['$event'])
  public onResize($event: { currentTarget: { innerWidth: number } }) {
    if (!$event || !$event.currentTarget || !$event.currentTarget.innerWidth) {
      return;
    }

    this.windowWidth$.next($event.currentTarget.innerWidth);
  }

  private handleResponsiveness(lastWidth: number, newWidth: number) {
    if (this.isInitialResizeLessThanBreakpoint(lastWidth, newWidth)) {
      this.useCards();
    } else if (this.isInitialResizeGreaterThanBreakpoint(lastWidth, newWidth)) {
      // this.useTable();
    } else if (this.isSubsequentResizeGoingFromLessTowardsBiggerThanBreakpoint(lastWidth, newWidth)) {
      // this.useTable();
    } else if (this.isSubsequentResizeGoingFromBiggerTowardsLessThanBreakpoint(lastWidth, newWidth)) {
      this.useCards();
    }

    this.lastSeenWindiwWidth = newWidth;
  }

  private isInitialResizeLessThanBreakpoint = (lastWidth: number, newWidth: number, breakpoint: number = 768) =>
    lastWidth === undefined && newWidth <= breakpoint;

  private isInitialResizeGreaterThanBreakpoint = (lastWidth: number, newWidth: number, breakpoint: number = 768) =>
    lastWidth === undefined && newWidth > breakpoint;

  private isSubsequentResizeGoingFromLessTowardsBiggerThanBreakpoint = (lastWidth: number, newWidth: number, breakpoint: number = 768) =>
    lastWidth <= breakpoint && newWidth > breakpoint;

  private isSubsequentResizeGoingFromBiggerTowardsLessThanBreakpoint = (lastWidth: number, newWidth: number, breakpoint: number = 768) =>
    lastWidth > breakpoint && newWidth <= breakpoint;
}
