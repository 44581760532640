import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Consent } from '@app/core/repositories/consent/consent.interface';
import { Observable } from 'rxjs';

export interface IConsentRepo {
  get(application: string): Observable<Consent[]>;
}

export interface ConsentMessages {
  getError: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConsentRepo implements IConsentRepo {
  public static consentParam = 'cropmanager';

  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  /**
   * Gets the consent object for the given application.
   * @param application The application to get consent for.
   */
  public get(application: string): Observable<Consent[]> {
    const options = {
      withCredentials: true,
    };
    return this.http.get<Consent[]>(`${this.endpoints.foApi}/consentlist/${application}`, options);
  }
}
