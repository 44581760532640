import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CornHarvestPrognosis } from '../../repository/corn-harvest-prognosis.interface';
import { CornTypes } from '../corn-types.enum';

@Component({
  selector: 'app-corn-harvest-prognosis-info',
  templateUrl: './corn-harvest-prognosis-info.component.html',
  styleUrls: ['./corn-harvest-prognosis-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CornHarvestPrognosisInfoComponent {
  public CornTypes = CornTypes;

  constructor(
    private dialogRef: MatDialogRef<CornHarvestPrognosisInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CornHarvestPrognosis
  ) {}

  public onConfirmClick() {
    this.dialogRef.close();
  }
}
