declare global {
  interface Array<T> {
    /**
     * Returns true if the array is empty. False otherwise.
     */
    isEmpty(): boolean;

    /**
     * Returns the first element of the array
     */
    first(): T | undefined;

    /**
     * Returns the last element of the array
     */
    last(): T | undefined;
  }
}

if (!Array.prototype.isEmpty) {
  Array.prototype.isEmpty = function () {
    return this.length === 0;
  };
}

if (!Array.prototype.first) {
  Array.prototype.first = function () {
    return this[0];
  };
}

if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this[this.length - 1];
  };
}

export {};
