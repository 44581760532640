import { Injectable } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { distinctUntilChanged, map, mergeScan } from 'rxjs/operators';
import { Breakpoints } from '../enums/breakpoints.enum';

export interface BreakPointChange {
  last: Breakpoints;
  current: Breakpoints;
  direction: BreakpointDirection;
  breakpointStr: string;
}

export type BreakpointDirection = 'up' | 'down' | 'equal';

@Injectable({
  providedIn: 'root',
})
export class UserExperienceService {
  private windowResize$ = fromEvent(window, 'resize').pipe(
    map((event: any) => event && event.currentTarget && (event.currentTarget.innerWidth as number))
  );

  private breakpoints$ = merge(of(window.innerWidth), this.windowResize$).pipe(
    map((size) => this.mapToBreakpoint(size)!),
    distinctUntilChanged(),
    mergeScan<Breakpoints, BreakPointChange>(
      (lastChange, current) => this.mapToNewNewAccumulatedChange(lastChange, current),
      this.getBreakpointChangeSeed() as BreakPointChange
    )
  );

  private lastBreakpointChange?: BreakPointChange = undefined;

  constructor() {
    this.getBreakpointsStream().subscribe((change) => (this.lastBreakpointChange = change));
  }

  public getBreakpointsStream() {
    return this.breakpoints$.pipe();
  }

  public isSmallScreen() {
    return (
      this.lastBreakpointChange!.current === Breakpoints.XS ||
      this.lastBreakpointChange!.current === Breakpoints.SM ||
      this.lastBreakpointChange!.current === Breakpoints.MD
    );
  }

  public isLargeScreen() {
    return this.lastBreakpointChange!.current === Breakpoints.LG || this.lastBreakpointChange!.current === Breakpoints.XL;
  }

  public getDefaultModalWidth() {
    return this.pick({
      sm: '80vw',
      md: '60vw',
      lg: '50vw',
      xl: '40vw',
    });
  }

  public pick<TData>({ xs, sm, md, lg, xl }: { xs?: TData; sm: TData; md?: TData; lg?: TData; xl?: TData }): TData {
    switch (this.lastBreakpointChange!.current) {
      case Breakpoints.XS:
        return xs || sm;
      case Breakpoints.SM:
        return sm || xs || md!;
      case Breakpoints.MD:
        return md || sm || xs || lg!;
      case Breakpoints.LG:
        return lg || md || sm || xs! || xl!;
      case Breakpoints.XL:
        return xl || lg || md || sm || xs!;
      default:
        return xs!;
    }
  }

  private getBreakpointChangeSeed() {
    const last = 0;
    const current = this.mapToBreakpoint(window.innerWidth);
    const direction = this.mapToDirection(last, current!);
    const breakpointStr = this.mapToBreakpointString(current!);

    return {
      last,
      current,
      direction,
      breakpointStr,
    };
  }

  private mapToNewNewAccumulatedChange(lastChange: BreakPointChange, current: Breakpoints) {
    const last = lastChange.current;
    const direction = this.mapToDirection(lastChange.current, current);

    return of({
      last,
      current,
      direction,

      breakpointStr: this.mapToBreakpointString(this.mapToBreakpoint(window.innerWidth)!),
    });
  }

  private mapToDirection(last: number, current: number): BreakpointDirection {
    if (last > current) {
      return 'down';
    } else if (last < current) {
      return 'up';
    } else {
      return 'equal';
    }
  }

  private mapToBreakpoint(size: number): Breakpoints | undefined {
    if (size < Breakpoints.SM) {
      return Breakpoints.XS;
    } else if (size >= Breakpoints.SM && size < Breakpoints.MD) {
      return Breakpoints.SM;
    } else if (size >= Breakpoints.MD && size < Breakpoints.LG) {
      return Breakpoints.MD;
    } else if (size >= Breakpoints.LG && size < Breakpoints.XL) {
      return Breakpoints.LG;
    } else if (size >= Breakpoints.XL) {
      return Breakpoints.XL;
    }
    return;
  }

  private mapToBreakpointString(breakpoint: Breakpoints): string {
    return Breakpoints[breakpoint];
  }
}
