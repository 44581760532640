import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FabComponent } from '@fe-shared/ui-components/buttons/round-button/fab/fab.component';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, IconModule],
  exports: [FabComponent],
  declarations: [FabComponent],
})
export class FabModule {}
