export class HttpErrorHelper {
  // Map of error codes and their corresponding messages.
  // The messages for these error codes are defined in the language json files.
  static errorMessages: ReadonlyMap<string | number, string> = new Map<string | number, string>([
    [2, 'messages.common.errorCodes.2'],
    [101, 'messages.common.errorCodes.101'],
    [102, 'messages.common.errorCodes.102'],
    [413, 'messages.common.errorCodes.413'],
    [500, 'messages.common.errorCodes.500'],
    [502, 'messages.common.errorCodes.502'],
    [1005, 'messages.common.errorCodes.1005'],
    [1007, 'messages.common.errorCodes.1007'],
    [1008, 'messages.common.errorCodes.1008'],
    [1009, 'messages.common.errorCodes.1009'],
    [1010, 'messages.common.errorCodes.1010'],
    [1012, ''],
    [1024, 'messages.common.errorCodes.1024'],
    [1025, 'messages.common.errorCodes.1025'],
    [1026, 'messages.common.errorCodes.1026'],
    [1114, 'messages.commom.errorCodes.1114'],
    [1115, 'messages.common.errorCodes.1115'],
    [1117, 'messages.common.errorCodes.1117'],
    [1119, 'messages.common.errorCodes.1119'],
    [1122, 'messages.common.errorCodes.1122'],
    [2003, 'messages.common.errorCodes.2003'],
    [2004, 'messages.common.errorCodes.2004'],
    [2005, 'messages.common.errorCodes.2005'],
    [4005, 'messages.common.errorCodes.4005'],
    [4009, 'messages.common.errorCodes.4009'],
    [4011, 'messages.common.errorCodes.4011'],
    [5004, 'messages.common.errorCodes.5004'],
    [50007, 'messages.common.errorCodes.50007'],
    [50015, 'messages.common.errorCodes.50015'],
    [50025, 'messages.common.errorCodes.50025'],
    [50026, 'messages.common.errorCodes.50026'],
    [50029, 'messages.common.errorCodes.50029'],
    [50030, 'messages.common.errorCodes.50030'],
    [50031, 'messages.common.errorCodes.50031'],
    [50032, 'messages.common.errorCodes.50032'],
    [6003, 'messages.common.errorCodes.6003'],
    [6008, 'messages.common.errorCodes.6008'],
    [100007, 'messages.common.errorCodes.100007'],
    [100010, 'messages.common.errorCodes.100010'],
    [11002, 'messages.common.errorCodes.11002'],
    [11003, 'messages.common.errorCodes.11003'],
    [11004, 'messages.common.errorCodes.11004'],
    [12003, 'messages.common.missingErrorError'],
    [12007, 'messages.common.errorCodes.12007'],
    [12008, 'messages.common.errorCodes.12008'],
    [12009, 'messages.common.errorCodes.12009'],
    [13001, 'messages.common.errorCodes.13001'],
    [140003, 'messages.common.errorCodes.140003'],
    [150001, 'messages.common.errorCodes.150001'],
    [150002, 'messages.common.errorCodes.150002'],
    [160001, 'messages.common.errorCodes.160001'],
    [170001, 'messages.common.errorCodes.170001'],
    [170202, 'messages.common.errorCodes.170202'],
    [170204, 'messages.common.errorCodes.170204'],
    ['ModelNotValidForActualParameters', 'messages.common.errorCodes.ModelNotValidForActualParameters'],
    ['VarietyMissing', 'messages.common.errorCodes.VarietyMissing'],
    ['DMINotResponding', 'messages.common.errorCodes.DMINotResponding'],
    ['InvalidHotspotGeometry', 'main.hotspots.InvalidHotspotGeometry'],
  ]);

  static getErrorMessageFromCode(errorCode: number) {
    return HttpErrorHelper.errorMessages.get(errorCode) ?? 'messages.common.missingErrorError';
  }
  /**
   * Get the error message for the given response.
   * @param responseJson The response json.
   * @returns The error message.
   */
  static getErrorMessage(responseJson: any): string {
    // If the response does not have an error code, return a message for missing error.
    if (!responseJson?.errorCode) {
      return 'messages.common.missingErrorError';
    }

    // Specific error handling for error code 'NoDataForQuery'.
    if (responseJson.errorKey?.toUpperCase() === 'NoDataForQuery'.toUpperCase() && responseJson.errorCode === 1114) {
      return 'growthStage.errors.default';
    }

    const errorMessage = HttpErrorHelper.errorMessages.get(responseJson.errorCode);
    const defaultMessage = `Error: ${
      responseJson.message ? `${responseJson.message} (Code: ${responseJson.errorCode})` : responseJson.errorCode
    }`;

    // Check if the error code is present in the map of error messages.
    // If not return a generic error message.
    return errorMessage ?? defaultMessage;
  }

  // Check if the given URL is for an authentication request.
  static isAuthCheck(url: string) {
    return url.includes('/bff/user');
  }
}
