import { Routes } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { FarmGuard } from '@app/core/guards/farm-guard.service';
import { AccessControlGuard, AccessControlRoute } from '@app/shared/access-control/guards/access-control.guard';
import { EconomyComponent } from './economy.component';

export const economyLazyRoute: AccessControlRoute = {
  path: 'economy',
  loadChildren: () => import('@app/economy/economy.module').then((m) => m.EconomyModule),
};

export const economyRoutes: Routes = [
  {
    path: 'table',
    component: EconomyComponent,
    canActivate: [AuthGuard, ConsentGuard, FarmGuard, AccessControlGuard],
    data: {
      resource: 'economy',
    },
    canLoad: [],
  },
];
