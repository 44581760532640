import { Injectable } from '@angular/core';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { createStore, select, withProps } from '@ngneat/elf';
import { BehaviorSubject } from 'rxjs';

interface FieldProps {
  field: Field | null;
}

@Injectable({ providedIn: 'root' })
export class ActiveFieldService {
  private activeFieldsInTaskSubject_ = new BehaviorSubject<FieldLayerItem[] | undefined>(undefined);
  private _store = createStore({ name: 'activeField' }, withProps<FieldProps>({ field: null }));

  public activeField$ = this._store.pipe(select((state) => state.field));

  public activeFieldsInTask$ = this.activeFieldsInTaskSubject_.asObservable();

  setActiveFieldsInTask(fields: FieldLayerItem[] | undefined) {
    this.activeFieldsInTaskSubject_.next(fields);
  }

  setActiveField(field: Field | null) {
    this._store.update((state) => ({ ...state, field }));
  }
}
