import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/shared/material/material.module';
import { CTAButtonComponent } from './cta-button/cta-button.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { SecondaryButtonComponent } from './secondary-button/secondary-button.component';
import { SquareButtonComponent } from './square-button.component';
import { TransparentButtonComponent } from './transparent-button/transparent-button.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [PrimaryButtonComponent, SecondaryButtonComponent, TransparentButtonComponent, CTAButtonComponent, SquareButtonComponent],
  exports: [SquareButtonComponent],
})
export class SquareButtonModule {}
