import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BffAuthService } from '../authentication/bff-auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginSubscriptionsRedirectGuard {
  constructor(private bffAuthService: BffAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.bffAuthService.login('/#/perma/subscriptions');
    return true;
  }
}
