<div class="wrapper" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
  <app-icon class="close-btn" [icon]="'Luk'" (click)="close()"></app-icon>
  <mat-dialog-content class="content-wrapper" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <div class="header">
      <div fxHide.lt-md class="logo"></div>
      <h3 class="header-text">{{ 'subscriptions.header' | translate }}</h3>
    </div>
    <div class="content">
      <app-spinner *ngIf="!(products$ | async)" [message]="'common.loadingData' | translate" [hasBackdrop]="true"></app-spinner>
      <div *ngIf="products$ | async as products">
        <div>
          <p *ngIf="!(isDlbrSubcribed$ | async)" class="disclaimer">{{ 'subscriptions.priceDisclaimer' | translate }}</p>
          <div class="section-header">{{ 'subscriptions.subHeader' | translate }}</div>
          <div class="mat-elevation-z1">
            <div *ngFor="let sub of products?.cropManager" class="section-row" (click)="changeSubscriptionLevel(sub.id)">
              <div class="section-box" [ngClass]="{ disable: (isDlbrSubcribed$ | async) }">
                <app-checkbox class="no-interaction" [checked]="sub.bought"></app-checkbox>
              </div>
              <div class="section-name">{{ sub.name }}</div>
              <div *ngIf="!(isDlbrSubcribed$ | async)" class="section-price">
                {{ sub.subscriptionPrice | number: '1.0-0' }} {{ 'subscriptions.price' | translate }}
              </div>
              <div *ngIf="!(isDlbrSubcribed$ | async)" class="section-info">
                <app-square-button class="info-button" *ngIf="sub.info" (click)="onInfoClicked(sub, $event)">
                  <app-icon class="info" icon="info_outline"></app-icon>
                  {{ 'common.info' | translate }}
                </app-square-button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="section-header">{{ 'subscriptions.extras' | translate }}</div>
          <div class="mat-elevation-z1">
            <div
              *ngFor="let extra of products?.extras"
              class="section-row"
              (click)="toggleExtraSubscription(extra.id, $event)"
              [ngClass]="{ disable: products?.cropManager?.[0]?.bought && extra.productModule === alertPackProductModuleId }"
            >
              <div class="section-box" [ngClass]="{ disable: (isDlbrSubcribed$ | async) }">
                <app-checkbox class="no-interaction" [isCircle]="false" [checked]="extra.bought"> </app-checkbox>
              </div>
              <div class="section-name">{{ extra.name }}</div>
              <div *ngIf="extra.priceModel !== priceModels.FixedPrice && !(isDlbrSubcribed$ | async)" class="section-price">
                {{ extra.subscriptionPrice | number: '1.0-0' }} {{ 'subscriptions.price' | translate }}
              </div>
              <div *ngIf="extra.priceModel === priceModels.FixedPrice && !(isDlbrSubcribed$ | async)" class="section-price">
                - {{ 'subscriptions.price' | translate }}
              </div>
              <div *ngIf="!(isDlbrSubcribed$ | async)" class="section-info">
                <app-square-button class="info-button" *ngIf="extra.info" (click)="onInfoClicked(extra, $event)">
                  <app-icon class="info" icon="info_outline"></app-icon>
                </app-square-button>
              </div>
            </div>
          </div>
          <div *ngIf="!(isDlbrSubcribed$ | async)" class="selector-reminder">
            {{ 'subscriptions.noBasicInfo' | translate }}
            <br /><br />
            {{ 'subscriptions.startPriceInfo' | translate: { startPrice: basicAndPremiumStartFee | number: '1.0-0' } }}
            <br /><br />
            {{ 'subscriptions.campaignInfo' | translate }}
            <br /><br />
            {{ 'subscriptions.campaignInfo1' | translate }}
          </div>
        </div>
        <div *ngIf="!(isDlbrSubcribed$ | async)" class="summary">
          <div class="summary-text">{{ 'subscriptions.totalPrice' | translate: { areaHa: totalHa$ | async | number: '1.0-2' } }}</div>
          <div class="price">{{ totalPrice$ | async | number: '1.0-0' }} {{ 'subscriptions.price' | translate }}</div>
        </div>
        <div *ngIf="!(isDlbrSubcribed$ | async)" class="terms-wrapper">
          <app-checkbox [isCircle]="false" (onChange)="onTermsChange($event)">{{ 'subscriptions.acceptOf' | translate }} </app-checkbox>
          <span (click)="openTerms()">{{ 'subscriptions.termsAndConditions' | translate }}</span>
        </div>
      </div>
      <div *ngIf="!(isDlbrSubcribed$ | async)" class="actions">
        <app-square-button [disabled]="!hasAcceptedTerms" [buttonType]="'CTA'" (click)="onConfirmClicked()">
          {{ 'subscriptions.accept' | translate }}</app-square-button
        >
      </div>
    </div>
  </mat-dialog-content>
</div>
