import { Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent {
  @Input() public sideDrawerWidth?: string | null;

  public isLegendVisible = false;

  // Since it is not possible to animate auto <=> 0, we use max-width combined with custom values for the inner element for show/hide
  public width?: number;
  public height?: number;

  constructor(private el: ElementRef) {}

  public onToggleLegend(): void {
    this.width = this.isLegendVisible ? this.el.nativeElement.querySelector('.legend').offsetWidth : undefined;
    this.height = this.isLegendVisible ? this.el.nativeElement.querySelector('.legend').offsetHeight : undefined;
    this.isLegendVisible = !this.isLegendVisible;
  }
}
