import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { LanguageStateService } from '@app/state/services/language/language-state.service';
import * as pbi from 'powerbi-client';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EMBED_CONFIG } from './embed-config.const';
import { TokenResponse } from './token-response.interface';
import { filterNullish } from '../operators';

@Injectable()
export class FpPowerbiService {
  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private currentLanguage: Observable<string> = this.languageStateService.currentLanguage$;

  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private languageStateService: LanguageStateService
  ) {}

  public getEmbedTokenForReport(name: string): Observable<TokenResponse> {
    return this.http.get(`${this.endpoints.foApi}/powerbi/embedtoken/${name}`);
  }

  public getEmbedConfiguration(featureId: number): Observable<pbi.IEmbedConfiguration> {
    return this.currentLanguage.pipe(
      filterNullish(),
      take(1),
      map((lang: string) => {
        return {
          ...EMBED_CONFIG,
          settings: {
            ...EMBED_CONFIG.settings,
            localeSettings: {
              language: lang,
            },
          },
          filters: [this.getBasicFilter(featureId, 'ForeignId'), this.getBasicFilter(lang, 'LanguageId')],
        } as pbi.IEmbedConfiguration;
      })
    );
  }

  private getBasicFilter(val: any, col: string): pbi.models.IBasicFilter {
    return {
      $schema: 'http://powerbi.com/product/schema#basic',
      filterType: pbi.models.FilterType.Basic,
      requireSingleSelection: true,
      values: [val],
      operator: 'In',
      target: {
        column: col,
        table: 'Database',
      },
    };
  }
}
