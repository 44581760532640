import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LogService } from '@app/core/log/log.service';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { WindowRefService } from '../window/window-ref.service';

@Injectable()
export class AppUpdaterService {
  private updateReadySubject = new BehaviorSubject(false);
  constructor(
    private swUpdate: SwUpdate,
    private windowRef: WindowRefService,
    private logService: LogService
  ) {
    if (this.swUpdate.isEnabled) {
      // If an update becomes ready, updateReadySubject is set to true.
      // This is done because SwUpdate lacks a handle to tell if a new update is available synchronously.
      this.swUpdate.versionUpdates
        .pipe(
          filter((versionEvent) => versionEvent.type === 'VERSION_READY'),
          first()
        )
        .subscribe(() => this.updateReadySubject.next(true));
    }
  }

  /**
   * Updates and reloads if an update is ready and service workers are enabled
   */
  public checkForUpdate() {
    if (this.swUpdate.isEnabled) {
      // If an update is ready, we proceed
      this.updateReadySubject
        .pipe(
          first(),
          filter((updateReady) => updateReady)
        )
        .subscribe(
          () =>
            // If the update is successfully installed we reload the page, otherwise the error gets logged.
            void this.swUpdate
              .activateUpdate()
              .then(() => this.windowRef.nativeWindow.location.reload())
              .catch((e) => this.logService.logError('Failed to activate serviceworker update with error: ' + e))
        );
    }
  }
}
