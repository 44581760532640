<app-checkbox
  label="{{ 'benchmarkChartFilter.filters.coarseSand' | translate }}"
  [checked]="true"
  (onChange)="onCoarseChange($event)"
></app-checkbox>
<app-checkbox
  label="{{ 'benchmarkChartFilter.filters.fineSand' | translate }}"
  [checked]="true"
  (onChange)="onFineChange($event)"
></app-checkbox>
<app-checkbox
  label="{{ 'benchmarkChartFilter.filters.clay' | translate }}"
  [checked]="true"
  (onChange)="onClayChange($event)"
></app-checkbox>
