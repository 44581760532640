<app-dialog-header
  size="md"
  [title]="'main.fieldmap.droneImageImport.pricing.tooltip-header' | translate"
  (close)="close()"
></app-dialog-header>
<app-dialog-content size="md">
  <p class="text">{{ 'main.fieldmap.droneImageImport.pricing.paragraph1' | translate }}</p>
  <p class="text">{{ 'main.fieldmap.droneImageImport.pricing.paragraph2' | translate }}</p>
  <br />
  <p class="text">{{ 'main.fieldmap.droneImageImport.pricing.paragraph3' | translate }}</p>
  <p class="text">{{ 'main.fieldmap.droneImageImport.pricing.paragraph4' | translate }}</p>
  <br />
  <p class="text">{{ 'main.fieldmap.droneImageImport.pricing.paragraph5' | translate }}</p>
  <p class="text">{{ 'main.fieldmap.droneImageImport.pricing.paragraph6' | translate }}</p>
</app-dialog-content>
