import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { ChartService } from '@app/core/chart/chart.service';
import { Month } from '@app/core/enums/month.enum';
import { PrognosisDataSource } from '@app/core/enums/prognosis-data-source.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { LanguageService } from '@app/core/language/language.service';
import { WeatherData, WeatherDataObj } from '@app/core/repositories/weather/weather-data.interface';
import { WeatherRepoService } from '@app/core/repositories/weather/weather-repo.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { RainChartVM } from '@app/shared/rain-chart/rain-chart-vm.interface';
import { WindowStateService } from '@app/state/services/window/window-state.service';
import { CategoryAxis } from '@progress/kendo-angular-charts';
import { DateTime, Interval } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RainChartInfoComponent } from './rain-chart-info/rain-chart-info.component';

@Injectable({
  providedIn: 'root',
})
export class RainChartService {
  public readonly screenSize$ = this.windowSelector.screenSize$;
  private rainDataCache = this.cacheService.create<WeatherDataObj>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    private weatherRepo: WeatherRepoService,
    private cacheService: CacheService,
    private windowSelector: WindowStateService,
    private chartService: ChartService,
    private languageService: LanguageService,
    private dialogService: DialogService
  ) {}

  public getRainData(farmId: number, harvestYear: number, featureId: number): Observable<RainChartVM> {
    const key = `${farmId}-${harvestYear}-${featureId}`;
    const getValue = () => {
      const today = DateTime.now();
      const startDate = DateTime.fromObject({ year: harvestYear, month: Month.June, day: 1 });
      const endDate = DateTime.fromObject({ year: harvestYear, month: Month.September, day: 15 });
      const actualEndDate = Interval.fromDateTimes(startDate, endDate).contains(today) ? today.plus({ days: 4 }) : endDate;

      return this.weatherRepo.getRainData(farmId, harvestYear, featureId, startDate, actualEndDate);
    };

    return this.rainDataCache
      .getOrSetAsync(key, () => getValue())
      .pipe(
        map((data) => ({
          observed: data.weatherData.filter((weatherItem) => weatherItem.dataSource === PrognosisDataSource.Observed),
          forecast: data.weatherData.filter((weatherItem) => weatherItem.dataSource === PrognosisDataSource.Forecast),
          aggregated: data.weatherData,
        }))
      );
  }

  public calculateNumberOfStepsByScreenSize(screenSize: ScreenSize, aggregatedData: WeatherData[], categoryAxis: CategoryAxis) {
    return this.chartService.calculateNumberOfStepsByScreenSize<WeatherData>(screenSize, aggregatedData, categoryAxis);
  }

  public findYAxisTitle() {
    return this.languageService.getText('rainChart.valueAxis.title');
  }

  public showInfoModal() {
    this.dialogService.openInfoDialog(RainChartInfoComponent);
  }
}
