<div
  class="tooltip-wrapper"
  [ngClass]="{ 'full-width': fullWidth }"
  *ngIf="!StringHelpers.isNullOrEmpty(tooltipText); else noTooltip"
  [matTooltipPosition]="position"
  matTooltip="{{ tooltipText }}"
  [matTooltipClass]="isOneLine ? 'app-tooltip-message one-line' : 'app-tooltip-message'"
  [matTooltipShowDelay]="showDelay"
  [matTooltipHideDelay]="hideDelay"
>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>

<ng-template #noTooltip>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
