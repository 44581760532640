import { Injectable } from '@angular/core';
import { Region } from '@app/core/interfaces/map-region-feature.interface';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { take } from 'rxjs/operators';
import { CacheService } from '../cache/cache.service';
import { RegionRepo } from '../repositories/region/region-repo.service';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private regionCache = this.cacheService.create<Region[]>({
    defaultTtl: 60 * 60 * 1000,
  });

  constructor(
    private cacheService: CacheService,
    private regionRepo: RegionRepo,
    private benchmarkStateService: BenchmarkStateService
  ) {}

  public initialize() {
    this.getRegions()
      .pipe(take(1))
      .subscribe((regions) => this.setRegions(regions));
  }

  public getRegions() {
    const key = 'regions';

    return this.regionCache.getOrSetAsync(key, () => this.regionRepo.get());
  }

  public setRegions(regions: Region[]) {
    this.benchmarkStateService.regions = regions;
  }
}
