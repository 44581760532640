import { Injectable } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';
import { MessageAndType } from './messageAndType.interface';

@Injectable()
export class AgrirouterErrorCodeService {
  private messageAndType: MessageAndType = {
    text: '',
    type: '',
  };

  constructor(private languageService: LanguageService) {}

  public getErrorOrInfoText(errorCode: string) {
    switch (errorCode) {
      case '0010':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0010');
        return this.messageAndType;
      case '0011':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0011');
        return this.messageAndType;
      case '0020':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0020');
        return this.messageAndType;
      case '0021':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0021');
        return this.messageAndType;
      case '0022':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0022');
        return this.messageAndType;
      case '0023':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0023');
        return this.messageAndType;
      case '0024':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0024');
        return this.messageAndType;
      case '0100':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0100');
        return this.messageAndType;
      case '0101':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0101');
        return this.messageAndType;
      case '0102':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0102');
        return this.messageAndType;
      case '0103':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0103');
        return this.messageAndType;
      case '0104':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0104');
        return this.messageAndType;
      case '0105':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0105');
        return this.messageAndType;
      case '0106':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0106');
        return this.messageAndType;
      case '0107':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0107');
        return this.messageAndType;
      case '0108':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0108');
        return this.messageAndType;
      case '0109':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0109');
        return this.messageAndType;
      case '0110':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0110');
        return this.messageAndType;
      case '0111':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0111');
        return this.messageAndType;
      case '0112':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0112');
        return this.messageAndType;
      case '0113':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0113');
        return this.messageAndType;
      case '0114':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0114');
        return this.messageAndType;
      case '0115':
        this.messageAndType.text = this.languageService.getText('main.settings.dataConnections.agriRouter.onBoarding.errorCodes.0115');
        return this.messageAndType;
      case 'request_declined':
        this.messageAndType.text = this.languageService.getText(
          'main.settings.dataConnections.agriRouter.onBoarding.errorCodes.request_declined'
        );
        this.messageAndType.type = 'info';
        return this.messageAndType;
      case 'account_id_is_used_before':
        this.messageAndType.text = this.languageService.getText(
          'main.settings.dataConnections.agriRouter.onBoarding.errorCodes.account_id_is_used_before'
        );
        return this.messageAndType;
      case 'user_state_not_found':
        this.messageAndType.text = this.languageService.getText(
          'main.settings.dataConnections.agriRouter.onBoarding.errorCodes.user_state_not_found'
        );
        return this.messageAndType;
      case 'name_is_used_before_for_user':
        this.messageAndType.text = this.languageService.getText(
          'main.settings.dataConnections.agriRouter.onBoarding.errorCodes.name_is_used_before_for_user'
        );
        return this.messageAndType;
      case 'user_different_account':
        this.messageAndType.text = this.languageService.getText(
          'main.settings.dataConnections.agriRouter.onBoarding.errorCodes.user_different_account'
        );
        return this.messageAndType;
      default:
        this.messageAndType.text = this.languageService.getText(
          'main.settings.dataConnections.agriRouter.onBoarding.errorCodes.unknown_error',
          { msg: errorCode }
        );
        return this.messageAndType;
    }
  }
}
