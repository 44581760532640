import { Injectable } from '@angular/core';
import { IFarmService } from '@app/core/farm/farm.service.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { FarmsRepo } from '@app/core/repositories/farms/farms-repo.service';
import { UserType } from '@app/core/repositories/user/user-type.enum';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { TranslateService } from '@ngx-translate/core';
import isObject from 'lodash-es/isObject';
import uniqBy from 'lodash-es/uniqBy';
import { forkJoin, Observable } from 'rxjs';
import { filter, finalize, map, switchMap, withLatestFrom } from 'rxjs/operators';

export const farmIdsQueryParamKey = 'farmIds';

@Injectable({
  providedIn: 'root',
})
export class FarmService implements IFarmService {
  public singleFarmNameLength = 30;
  public multipleFarmNamesLength = 20;
  private initCalled = false;

  constructor(
    public farmsRepo: FarmsRepo,
    private translateService: TranslateService,
    private farmStateService: FarmStateService
  ) {
    this.farmStateService.selectedFarms$
      .pipe(
        filter((farms) => farms.length > 0),
        switchMap((farms) => {
          return this.farmStateService.farmsAreSelected$.pipe(
            filter((farmsAreSel) => {
              return farmsAreSel;
            }),
            map(() => farms)
          );
        })
      )
      .subscribe();

    this.farmStateService.userType$
      .pipe(
        filterNullish(),
        filter((userType) => !this.isConsultant(userType)),
        switchMap(() => this.farmsRepo.get())
      )
      .subscribe((farms) => {
        this.farmStateService.farms = farms;
      });
  }

  public init() {
    if (this.initCalled) {
      return;
    }

    this.initCalled = true;
  }

  public createFarmFromCVR(cvr: string): Observable<Farm | null> {
    return this.farmsRepo.createFromCvr(cvr);
  }

  public addFarmToState(farm: Farm) {
    this.farmStateService.farms$.subscribe((farms) => (this.farmStateService.farms = [...(farms || []), farm]));
  }

  public updateFarm(farm: Farm): Observable<Farm | null> {
    return this.farmsRepo.update(farm);
  }

  public deleteFarm(farmId: number): Observable<void | null> {
    return this.farmsRepo.delete(farmId);
  }

  /**
   * Update image for Farm
   * @param farmId: Id for Farm holding image
   * @param file: File to update
   */
  public updateFarmImage(farmId: number, file: File | null): Observable<string | null> {
    return this.farmsRepo.updateFarmImage(farmId, file);
  }

  /**
   * Delete image for Farm
   * @param url: The url to get the image - is also the url to delete it
   */
  public deleteFarmImage(url: string): Observable<void | null> {
    return this.farmsRepo.deleteFarmImage(url);
  }

  public findFarmNameById(id: number): Observable<string> {
    return this.farmStateService.farms$.pipe(
      map((farms) => (farms ? farms : [])),
      withLatestFrom(this.farmStateService.selectedFarms$),
      map(([farms, selectedFarms]) => uniqBy(farms.concat(selectedFarms), 'id')),
      map((farms) => farms.find((farm) => farm.id === id)),
      map((farm) => {
        return isObject(farm) ? farm.name : '';
      })
    );
  }

  public searchFarms(searchTerm: string) {
    return this.farmsRepo.searchFarms(searchTerm, 10).pipe(
      filterNullish(),
      map((farms) =>
        farms.map((farm) => ({
          ...farm,
          name: farm.name === '' || farm.name === null ? (farm.name = this.translateService.instant('searchField.noName')) : farm.name,
        }))
      )
    );
  }

  public getFarmsFromIds(selectedFarmIds: Array<number>): Observable<Farm[]> {
    this.farmStateService.isFarmsLoading = true;

    const farms$ = selectedFarmIds.map((formId) => {
      return this.farmsRepo.getFarm(formId);
    });

    return forkJoin(farms$).pipe(
      // @ts-ignore
      finalize(() => {
        this.farmStateService.isFarmsLoading = false;
      })
    );
  }

  private isConsultant(userType: UserType) {
    return userType === UserType.Consultant || userType === UserType.Teacher || userType === UserType.PrivateConsultant;
  }
}
