import { Injectable } from '@angular/core';
import { HttpClient } from '@app/core/http/http-client';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { ImageMetaData } from '@app/core/exif/image-meta-data';
import { merge, Observable } from 'rxjs';
import { ImageFeature } from '@app/core/drone-image/image-feature';
import { ProjectFeatureDto } from '@app/core/drone-image/project-feature-dto';
import { DroneImageFormData } from '@app/core/drone-image/drone-image-form-data.interface';
import { SolviBeginUploadResponseDto } from '@app/core/drone-image/solvi-begin-upload-response-dto';
import { retry } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class SolviService {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getFieldsFromImageMetaData(farmId: number, harvestYear: number, metaData: ImageMetaData[]): Observable<ImageFeature[] | null> {
    return this.httpClient.post(`${this.endpoints.foApi}/farms/${farmId}/${harvestYear}/solvi/fields/images`, metaData);
  }

  public createProjects(formData: DroneImageFormData, featureIds: number[]): Observable<ProjectFeatureDto[] | null> {
    return this.httpClient.post<ProjectFeatureDto[], { name: string; imageDate: DateTime }>(
      `${this.endpoints.foApi}/farms/${formData.farmId}/${formData.harvestYear}/feature/${featureIds}/solvi/project`,
      { name: formData.name, imageDate: formData.date }
    );
  }

  public beginUpload(farmId: number, projectId: number): Observable<SolviBeginUploadResponseDto | null> {
    return this.httpClient.post<SolviBeginUploadResponseDto, {}>(
      `${this.endpoints.foApi}/farms/${farmId}/solvi/project/${projectId}/beginupload`,
      {}
    );
  }

  public uploadFiles(files: File[], beginUploadObject: any): Observable<unknown> {
    return merge(...files.map((f) => this.uploadFile(beginUploadObject.imagery_upload_data, f)));
  }

  private uploadFile(upload_imagery_data: any, file: any): Observable<unknown> {
    const form = new FormData();
    Object.keys(upload_imagery_data.fields).forEach((field) => {
      form.append(field, upload_imagery_data.fields[field]);
    });
    const key = upload_imagery_data.key_prefix + file.name;
    form.append('key', key);
    form.append('Content-Type', 'image/jpeg');
    form.append('file', file);

    return this.httpClient.post(upload_imagery_data.url, form, { headers: {} }, false, false).pipe(retry(5));
  }

  public completeUpload(farmId: number, projectId: number): Observable<unknown> {
    return this.httpClient.post(`${this.endpoints.foApi}/farms/${farmId}/solvi/project/${projectId}/completeupload`, {});
  }
}
