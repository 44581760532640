import { WebGLStyle } from 'ol/style/webgl';

export const VraStyles = {
  getCellStyle: (): WebGLStyle => {
    return {
      'fill-color': ['get', 'color'],
    };
  },

  getCellDrawStyle: (): WebGLStyle => {
    return {
      'fill-color': ['get', 'color'],
    };
  },

  getCellHoverStyle: (): WebGLStyle => {
    return {
      'stroke-color': '#ffffffff',
      'stroke-width': 2,
      'fill-color': ['get', 'color'],
    };
  },
};
