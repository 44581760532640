<div *hasAccessTo="'yield_benchmark'" class="benchmark-container-wrapper">
  <app-select [formControl]="benchmarkTypesControl" placeholder="{{ 'main.benchmark.selectBenchmarkType' | translate }}">
    <app-select-option *ngFor="let type of benchmarkTypes" [value]="type">
      {{ type.name }}
    </app-select-option>
  </app-select>
  <div class="benchmark-content-container">
    <div *ngIf="isEconomyBenchmarkType()" [@contentVisibility]>
      <app-benchmark-price></app-benchmark-price>
    </div>
    <div *ngIf="isYieldBenchmarkType()" [@contentVisibility]>
      <app-benchmark-yield></app-benchmark-yield>
    </div>
  </div>
</div>
