import { NgModule } from '@angular/core';
import { NotificationService } from '@app/core/notification/notification.service';
import { SecureUrlService } from '@app/core/secure-url/secure-url.service';
import { FarmPickerModalCreateFarmComponent } from '@app/layouts/header/farm-picker/farm-picker-modal/create/create.component';
import { FarmPickerModalEditFarmComponent } from '@app/layouts/header/farm-picker/farm-picker-modal/edit/edit.component';
import { FarmCardComponent } from '@app/layouts/header/farm-picker/farm-picker-modal/farm-card/farm-card.component';
import { FarmPickerModalComponent } from '@app/layouts/header/farm-picker/farm-picker-modal/farm-picker-modal.component';
import { FarmPickerSearchService } from '@app/layouts/header/farm-picker/farm-picker-modal/farm-picker-search-service/farm-picker-search.service';
import { FarmPickerComponent } from '@app/layouts/header/farm-picker/farm-picker.component';
import { FeedbackButtonComponent } from '@app/layouts/header/feedback-button/feedback-button.component';
import { HeaderComponent } from '@app/layouts/header/header.component';
import { UserInfoComponent } from '@app/layouts/header/user-info/user-info.component';
import { SharedModule } from '@app/shared/shared.module';
import { SubscriptionsModule } from '@app/subscriptions/subscriptions.module';
import { ReminderButtonComponent } from './reminder-button/reminder-button.component';

@NgModule({
  imports: [SharedModule, SubscriptionsModule],
  exports: [HeaderComponent],
  declarations: [
    HeaderComponent,
    FarmPickerComponent,
    FarmPickerModalComponent,
    FarmPickerModalCreateFarmComponent,
    FarmPickerModalEditFarmComponent,
    FarmCardComponent,
    ReminderButtonComponent,
    UserInfoComponent,
    FeedbackButtonComponent,
  ],
  providers: [NotificationService, SecureUrlService, FarmPickerSearchService],
})
export class HeaderModule {}
