import { FeatureToggleClient } from '@app/libraries/ng-feature-toggles/model/feature-toggle-client.interface';
import { environment } from 'environments/environment';
import { UnleashClient } from 'unleash-proxy-client';
import { EndpointsService } from '../endpoints/endpoints.service';

export function getFeatureToggleClient(endPointsService: EndpointsService): FeatureToggleClient {
  return new UnleashClient({
    clientKey: environment.featureToggleClientKey,
    url: endPointsService.unleashUrl,
    appName: environment.appName,
    environment: environment.env,
  });
}
