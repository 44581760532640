import { DataSource } from '@angular/cdk/table';
import { MatPaginator } from '@angular/material/paginator';
import { ClaasWork } from '@app/core/interfaces/claas-work.interface';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TransferTasksDataSource extends DataSource<any> {
  constructor(
    private gridData: BehaviorSubject<ClaasWork[]>,
    private _paginator: MatPaginator
  ) {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  public connect(): Observable<ClaasWork[]> {
    const displayDataChanges = [this.gridData, this._paginator.page];

    return merge(...displayDataChanges).pipe(
      map(() => {
        const data = this.gridData.value.slice();

        // Grab the page's slice of data.
        const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        return data.splice(startIndex, this._paginator.pageSize);
      })
    );
  }

  public disconnect() {}
}
