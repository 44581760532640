import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  public transform(value: string, args?: any): any {
    return value.replace(/^\w/, (char) => char.toUpperCase());
  }
}
