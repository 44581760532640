import { CropNutrientRequirementGraphDataPointDTO } from '@app/shared/n-tool/crop-nutrient-requirement-graph-data-point-DTO';
import { CropNutrientAvailabilityGraphDataPointDTO } from '@app/shared/n-tool/CropNutrientAvailabilityGraphDataPointDTO';
import { DateTime } from 'luxon';

export interface NToolData {
  ranges: NToolRange[];
  taskGraphPoints: NToolTask[];
  nutrientRequirementGraphPoints: CropNutrientRequirementGraphDataPointDTO[];
  nutrientAvailabilityGraphPoints: CropNutrientAvailabilityGraphDataPointDTO[];
}

export interface NToolTask {
  fromPast: boolean;
  date: DateTime;
  totalNutrientAmount: number;
  productsUsed: NToolProduct[];
  isTaskCompleted: boolean;
}

interface NToolProduct {
  productAmount: number;
  nutrientAmount: number;
  produceNormNumber: string;
  productName: string;
  unit: string;
}

export class NToolRange {
  public color: string;
  public name: string;
  public rangeData: NToolRangeData[];
  public opacity: number;

  constructor(
    color: string,
    cropNutrientAvailabilityGraphDataPointDTOs: CropNutrientAvailabilityGraphDataPointDTO[],
    name: string,
    opacity: number
  ) {
    this.name = name;
    this.color = color;
    this.opacity = opacity;
    this.rangeData = cropNutrientAvailabilityGraphDataPointDTOs.map(
      (cropNutrientAvailabilityGraphDataPointDTO) => new NToolRangeData(cropNutrientAvailabilityGraphDataPointDTO)
    );
  }
}

class NToolRangeData {
  public day: DateTime;
  public min: number;
  public max: number;

  constructor(cropNutrientAvailabilityGraphDataPoint: CropNutrientAvailabilityGraphDataPointDTO) {
    this.day = cropNutrientAvailabilityGraphDataPoint.day;
    if (cropNutrientAvailabilityGraphDataPoint.nutrientRequirement > cropNutrientAvailabilityGraphDataPoint.availableNutrient) {
      this.min = cropNutrientAvailabilityGraphDataPoint.availableNutrient;
      this.max = cropNutrientAvailabilityGraphDataPoint.nutrientRequirement;
    } else {
      this.min = cropNutrientAvailabilityGraphDataPoint.nutrientRequirement;
      this.max = cropNutrientAvailabilityGraphDataPoint.availableNutrient;
    }
  }
}
