import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TablePresentationComponent } from '@app/shared/cards-or-table/table-presentation/table-presentation.component';
import { AppPipesModule } from '@app/shared/pipes/pipes.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    TranslateModule,
    IconModule,
    MatProgressBarModule,
    MatMenuModule,
    AppPipesModule,
  ],
  declarations: [TablePresentationComponent],
  exports: [TablePresentationComponent],
})
export class TablePresentationModule {}
