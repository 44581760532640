import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BtCutLegendComponent } from './bt-cut-legend/bt-cut-legend.component';
import { KtLegendComponent } from './kt-legend/kt-legend.component';
import { MgtLegendComponent } from './mgt-legend/mgt-legend.component';
import { PtLegendComponent } from './pt-legend/pt-legend.component';
import { RtLegendComponent } from './rt-legend/rt-legend.component';
import { SoilSampleLegendComponent } from './soil-sample-legend.component';
import { HumusLegendComponent } from './humus-legend/humus-legend.component';
import { ClayLegendComponent } from './clay-legend/clay-legend.component';
import { JbLegendComponent } from './jb-legend/jb-legend.component';

@NgModule({
  declarations: [
    SoilSampleLegendComponent,
    KtLegendComponent,
    RtLegendComponent,
    PtLegendComponent,
    MgtLegendComponent,
    BtCutLegendComponent,
    HumusLegendComponent,
    ClayLegendComponent,
    JbLegendComponent,
  ],
  imports: [SharedModule],
  exports: [SoilSampleLegendComponent],
})
export class SoilSampleLegendModule {}
