import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { ILanguagePickerConfig } from '@app/core/interfaces/languagePicker.interfaces';
import { LanguageService } from '@app/core/language/language.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { DebugInfoComponent } from '@app/shared/debug-info/debug-info.component';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { UserStateService } from '@app/state/services/user/user-state.service';
import { SubscriptionsDialogComponent } from '@app/subscriptions/subscriptions-dialog/subscriptions-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['user-info.component.scss'],
})

/**
 * UserInfo
 */
export class UserInfoComponent implements OnInit, OnDestroy {
  @Input() public disabled: boolean | undefined | null = false;

  public languagePickerConfig!: ILanguagePickerConfig;
  public showUserInfo!: boolean;
  public myProfileUrl = this.endpoints.myProfile;
  public changePasswordUrl = this.endpoints.changePassword;
  public delegationUrl = this.endpoints.delegation;
  public customerCentreUrl = this.endpoints.customerCentre;
  public currentUserFullName!: string;

  public hasDelegationAccess$ = this.accessControlService.hasAccessTo('delegations');

  public readonly currentUserObservable = this.userStateService.currentUser$;
  public readonly subscriptionLevel = this.userStateService.subscriptionLevel$;
  public readonly formattedSubscriptionLevel$ = this.userStateService.formattedSubscriptionLevel$;

  private debugInfoOpen = false;
  private subs: Subscription[] = [];

  constructor(
    private bffAuthService: BffAuthService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private endpoints: EndpointsService,
    private userStateService: UserStateService,
    private accessControlService: AccessControlService
  ) {}

  @HostListener('document:keydown', ['$event'])
  public handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'i' && e.ctrlKey && e.altKey && !this.debugInfoOpen) {
      this.showDebugInformation();
    }
  }

  public ngOnInit() {
    this.initSubscriptions();

    this.showUserInfo = false;
    this.languagePickerConfig = {
      dataTextField: 'name',
      dataValueField: 'shortKey',
      languages: this.languageService.getLanguages(),
    };
  }

  private initSubscriptions() {
    this.subs = [
      this.currentUserObservable.subscribe((currentUser) => {
        if (currentUser != null) {
          this.currentUserFullName = currentUser.fullName;
          this.changePasswordUrl = this.endpoints.changePassword + currentUser.username;
        }
      }),
    ];
  }

  public ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public toggleUserInfo() {
    if (!this.disabled) this.showUserInfo = !this.showUserInfo;
  }

  public hideUserInfo() {
    this.showUserInfo = false;
  }

  public showDebugInformation() {
    this.debugInfoOpen = true;
    this.subs = [
      ...this.subs,
      this.dialogService
        .openCustomDialog(DebugInfoComponent, {})
        .afterClosed()
        .subscribe(() => (this.debugInfoOpen = false)),
    ];
  }

  public logOff() {
    this.bffAuthService.logout();
  }

  public subscriptionsClicked() {
    this.hideUserInfo();
    this.dialogService.openCustomDialog(SubscriptionsDialogComponent, {
      panelClass: 'subscriptions-dialog',
      minWidth: '52.5vw',
      autoFocus: false,
      disableClose: true,
    });
  }
}
