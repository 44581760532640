export class ProtectionColorUtil {
  private static BASE_BACKGROUND_COLOR = '#4F734A';
  private static NO_PROTECTION_COLOR = '#F1F1F1';
  private static NO_PROTECTION_DAYS_STYLE = `background-color: ${ProtectionColorUtil.NO_PROTECTION_COLOR}`;

  public static getProtectionStyle(remainingProtectionDays: number): string {
    // If there are 0 days of protection left, background color is set to NO_PROTECTION_DAYS_STYLE.
    if (remainingProtectionDays === 0) {
      return this.NO_PROTECTION_DAYS_STYLE;
    } else {
      const maxProtectionDays = 7;
      // 7 days gives no opacity, after that opacity is increased by 10% pr day.
      const opacity = 1 - (maxProtectionDays - remainingProtectionDays) * 0.1;
      return `background-color: ${this.BASE_BACKGROUND_COLOR}; opacity: ${opacity}`;
    }
  }
}
