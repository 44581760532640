import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  public static isValidFormats(acceptedFormats: string[], files: File[]) {
    if (!acceptedFormats.length) {
      return true;
    }

    return files.every((file) => acceptedFormats.includes(file.type));
  }
}
