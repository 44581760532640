<div class="dialog-header">
  <h2 class="title" *ngIf="size === 'lg'">{{ title | translate }}</h2>
  <h3 class="title" *ngIf="size === 'md'">{{ title | translate }}</h3>
  <app-icon *ngIf="showClose" icon="Luk" (click)="onClose()"></app-icon>
  <div class="header-info" *ngIf="showInfo">
    <app-tooltip-overlay size="sm" [tooltipTemplate]="infoTextTooltip" (click)="$event.stopPropagation()">
      <app-icon icon="info_outline" class="info-hover"></app-icon>
      <div class="info-text">INFO</div>
    </app-tooltip-overlay>
    <ng-template #infoTextTooltip>
      <div class="infoText-wrapper">
        <p>{{ infoText }}</p>
      </div>
    </ng-template>
  </div>
</div>
