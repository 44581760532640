import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BasisLayerSideDrawerComponent } from './basis-layer-side-drawer.component';
import { BasisLayerInformationDialogComponent } from './category-picker/basis-layer-information-dialog/basis-layer-information-dialog.component';
import { CategoryPickerComponent } from './category-picker/category-picker.component';
import { CategoryComponent } from './category/category.component';

@NgModule({
  imports: [SharedModule],
  declarations: [BasisLayerSideDrawerComponent, CategoryPickerComponent, CategoryComponent, BasisLayerInformationDialogComponent],
})
export class BasisLayerSideDrawerModule {}
