import { Injectable } from '@angular/core';
import { CompareHelper } from '@app/helpers/compare/compare-helper';
import { latest } from '@app/shared/constants/rxjs-constants';
import { filterEmpty } from '@app/shared/operators/filter-empty';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntities, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities';
import { entitiesStateHistory, stateHistory } from '@ngneat/elf-state-history';
import { BehaviorSubject, combineLatest, map, shareReplay, tap } from 'rxjs';
import { PrescriptionMap } from '../interfaces/prescription-map.interface';
import { PrescriptionMapQuery } from './prescription-map/prescription-map.query';

interface ValidProps {
  valid: boolean;
}

interface LoadingProps {
  loading: boolean;
}

export interface SoilSampleAdjustment {
  prescriptionMapId: number;
  afterEffect: number | null;
  scaleAmount: number | null;
  yieldCorrection: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class SoilSampleAdjustmentsRepository {
  constructor(private pmQuery: PrescriptionMapQuery) {}

  private readonly _store = createStore(
    { name: 'soil-sample-adjustment' },
    withEntities<SoilSampleAdjustment, 'prescriptionMapId'>({ idKey: 'prescriptionMapId' }),
    withProps<ValidProps>({ valid: true }),
    withProps<LoadingProps>({ loading: true })
  );

  private readonly _history = entitiesStateHistory(this._store);
  private readonly _storeHistory = stateHistory(this._store);

  public readonly adjustments$ = this._store.pipe(selectAllEntities(), shareReplay(1));

  public readonly loading$ = combineLatest([this.pmQuery.loading$, this._store]).pipe(
    map(([pmLoading, { loading }]) => pmLoading || loading),
    shareReplay(latest)
  );

  public readonly hasPast$ = new BehaviorSubject<boolean>(false);

  public isValid$ = this._store.pipe(
    select(({ valid }) => valid),
    shareReplay(latest)
  );

  private readonly _init$ = this.pmQuery.prescriptionMapsByActiveTask$.pipe(
    filterEmpty(),
    tap((maps) => maps.some((map) => map.state === 'saved' && this.clearHistory())),
    tap(() => this._history.pause()),
    tap(() => this._store.update((state) => ({ ...state, loading: true }))),
    map((maps) => maps.map((map) => this._createSoilSampleAdjustment(map))),
    tap((soilSampleAdjustment) => {
      this._setStore(soilSampleAdjustment);
    }),
    tap(() => this._store.update((state) => ({ ...state, loading: false }))),
    tap(() => this.hasPast$.next(false)),
    tap(() => this._history.resume()),
    shareReplay(latest)
  );

  public init() {
    return this._init$;
  }

  public update(soilSampleAdjustment: (Partial<SoilSampleAdjustment> & Pick<SoilSampleAdjustment, 'prescriptionMapId'>)[]) {
    this.hasPast$.next(true);
    const storeEntities = this._store.getValue().entities;

    soilSampleAdjustment.forEach((adjustment) => {
      // only update if there is a difference between the old and new value
      if (CompareHelper.compareObjectValues(storeEntities[adjustment.prescriptionMapId], adjustment)) return;

      // TODO: This is a hack to fix the issue with the history not being updated when the user changes the value of a field the first time..
      if (!this.hasHistoryOnEntity(adjustment.prescriptionMapId))
        this._store.update(updateEntities(adjustment.prescriptionMapId, adjustment));

      this._store.update(updateEntities(adjustment.prescriptionMapId, adjustment));
    });
  }

  public updateValidity(valid: boolean) {
    this._store.update((state) => ({ ...state, valid }));
  }

  public resetHistoryOnEntity(id?: number | null) {
    if (id === null || id === undefined) return;
    this._history.jumpToPast(0, id);
    this.hasPast$.next(true);
  }

  public hasHistoryOnEntity(id?: number | null) {
    if (id === null || id === undefined) return false;
    return this._history.hasPast(id);
  }

  public resetHistory() {
    this._history.jumpToPast(0);
    this.hasPast$.next(false);
  }

  public clearHistory() {
    this._history.clearPast();
    this._history.clearFuture();

    this._storeHistory.clear((state) => ({ ...state, past: [], future: [] }));
    this._storeHistory.clear((state) => ({ ...state, past: [], future: [] }));

    this.hasPast$.next(false);
  }

  public reset() {
    this._store.reset();
    this.clearHistory();

    this.hasPast$.next(false);
  }

  private _setStore(pNeed: (SoilSampleAdjustment & Pick<SoilSampleAdjustment, 'prescriptionMapId'>)[]) {
    this._store.update(setEntities(pNeed));
  }

  private _createSoilSampleAdjustment(prescriptionMap: PrescriptionMap): SoilSampleAdjustment {
    return {
      prescriptionMapId: prescriptionMap.id,
      afterEffect: this.toThreeDecimalPlaces(prescriptionMap.soilSampleAdjustments?.afterEffect) ?? 0,
      scaleAmount: this.toThreeDecimalPlaces(prescriptionMap.soilSampleAdjustments?.scaleAmount) ?? 0,
      yieldCorrection: this.toThreeDecimalPlaces(prescriptionMap.soilSampleAdjustments?.yieldCorrection) ?? 0,
    };
  }

  private toThreeDecimalPlaces(num: number | undefined): number | undefined {
    if (num === null || num === undefined) {
      return num;
    }
    return Math.round(num * 1000) / 1000;
  }
}
