import { DateTime } from 'luxon';
import { INSURANCE_PRICES } from '../growth-regulation-prices';

export interface GrowthRegulationDTO {
  avgNdvi?: number;
  climateRiscZone?: number;
  climateRiscZoneName?: string;
  directorateCropNormNumber: number;
  farmId: number;
  featureId: number;
  fieldId: number;
  fieldYearId: number;
  fieldNumber?: string;
  irrigation: boolean;
  jb: number;
  jbScore?: number;
  municipalityName?: string;
  municipalityNumber?: number;
  ndviScore?: number;
  preCropDirectorateCropNormName: string;
  preCropDirectorateCropNormNumber: number;
  preCropScore: number;
  score: number;
  sortScore?: number;
  sowingDate?: DateTime;
  sowingDateScore?: number;
  varietyNormName?: string;
  varietyNormNumber?: number;
  area?: number;
  headerText?: string;
  nNormDiffScore?: number;
  nBeforeAprilScore?: number;
  aftereffectsNScore?: number;
  nNormDiff?: number;
  nBeforeApril?: number;
  aftereffectsN?: number;
}

export class GrowthRegulation implements GrowthRegulationDTO {
  public avgNdvi?: number;
  public climateRiscZone?: number;
  public climateRiscZoneName?: string;
  public directorateCropNormNumber: number;
  public farmId: number;
  public featureId: number;
  public fieldId: number;
  public fieldYearId: number;
  public fieldNumber?: string;
  public irrigation: boolean;
  public jb: number;
  public jbScore?: number;
  public municipalityName?: string;
  public municipalityNumber?: number;
  public ndviScore?: number;
  public preCropDirectorateCropNormName: string;
  public preCropDirectorateCropNormNumber: number;
  public preCropScore: number;
  public score: number;
  public sortScore?: number;
  public sowingDate?: DateTime;
  public sowingDateScore?: number;
  public varietyNormName?: string;
  public varietyNormNumber?: number;
  public area?: number;
  public headerText?: string;
  public nNormDiffScore?: number;
  public nBeforeAprilScore?: number;
  public aftereffectsNScore?: number;
  public nNormDiff?: number;
  public nBeforeApril?: number;
  public aftereffectsN?: number;

  constructor(dto?: GrowthRegulationDTO) {
    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.avgNdvi = dto.avgNdvi;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.climateRiscZone = dto.climateRiscZone;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.climateRiscZoneName = dto.climateRiscZoneName;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.directorateCropNormNumber = dto.directorateCropNormNumber;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.farmId = dto.farmId;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.featureId = dto.featureId;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.fieldId = dto.fieldId;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.fieldYearId = dto.fieldYearId;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.fieldNumber = dto.fieldNumber;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.irrigation = dto.irrigation;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.jb = dto.jb;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.jbScore = dto.jbScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.municipalityName = dto.municipalityName;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.municipalityNumber = dto.municipalityNumber;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.ndviScore = dto.ndviScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.preCropDirectorateCropNormName = dto.preCropDirectorateCropNormName;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.preCropDirectorateCropNormNumber = dto.preCropDirectorateCropNormNumber;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.preCropScore = dto.preCropScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.score = dto.score;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.sortScore = dto.sortScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.sowingDate = dto.sowingDate;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.sowingDateScore = dto.sowingDateScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.varietyNormName = dto.varietyNormName;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.varietyNormNumber = dto.varietyNormNumber;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.area = dto.area;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.headerText = dto.headerText;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.nNormDiffScore = dto.nNormDiffScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.nBeforeAprilScore = dto.nBeforeAprilScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.aftereffectsNScore = dto.aftereffectsNScore;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.nNormDiff = dto.nNormDiff;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.nBeforeApril = dto.nBeforeApril;

    // @ts-ignore - TS2532 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
    this.aftereffectsN = dto.aftereffectsN;
  }

  public getCropName() {
    switch (this.directorateCropNormNumber) {
      case 11:
      case 13:
        return 'Vinterhvede';
      case 14:
      case 15:
        return 'Vinterrug';
      default:
        return;
    }
  }

  public getScorePrice() {
    switch (this.directorateCropNormNumber) {
      case 11:
      case 13:
        return this.getWheatPrice();
      case 14:
      case 15:
        return this.getRyePrice();
      default:
        return;
    }
  }

  public getScoreColorName() {
    if (this.score === undefined || this.score === null) {
      return 'rgba(255, 255, 255, 0.1)';
    } else if (this.score < -2) {
      return 'Grøn'; // color-green
    } else if (this.score > 3) {
      return 'Rød'; // color-red
    } else if (this.score >= -2 && this.score <= 3) {
      return 'Gul'; // color-yellow
    } else {
      return 'rgba(255, 255, 255, 0.1)';
    }
  }

  private getWheatPrice() {
    if (this.score === undefined || this.score === null) {
      return null;
    } else if (this.score < -2) {
      return INSURANCE_PRICES.winterWheat.green; // color-green
    } else if (this.score > 3) {
      return INSURANCE_PRICES.winterWheat.red; // color-red
    } else if (this.score >= -2 && this.score <= 3) {
      return INSURANCE_PRICES.winterWheat.yellow; // color-yellow
    } else {
      return null;
    }
  }

  private getRyePrice() {
    if (this.score === undefined || this.score === null) {
      return null;
    } else if (this.score < -2) {
      return INSURANCE_PRICES.winterRye.green; // color-green
    } else if (this.score > 3) {
      return INSURANCE_PRICES.winterRye.red; // color-red
    } else if (this.score >= -2 && this.score <= 3) {
      return INSURANCE_PRICES.winterRye.yellow; // color-yellow
    } else {
      return null;
    }
  }
}
export enum scoreColor {
  Green = 'Grøn',
  Yellow = 'Gul',
  Red = 'Rød',
}
