import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Hoverable } from '@app/shared/buttons/hoverable';

@Component({
  selector: 'app-remove-button',
  templateUrl: './remove-button.component.html',
  styleUrls: ['./remove-button.component.scss'],
})
export class RemoveButtonComponent extends Hoverable {
  @Input() public disabled: boolean | null = false;
  @Output() public clicked = new EventEmitter<void>();

  public onClick() {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }
}
