import { Injectable } from '@angular/core';
import { NotificationService } from '@app/core/notification/notification.service';
import { AsAppliedShownComponentEnum } from '@app/map/features/field-analysis/features/as-applied/as-applied-shown-component.enum';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { Observable } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { AsAppliedService } from '../../as-applied.service';
import { ExecutedLocationCollection, ExecutedTask } from '../../executed-task.class';
import { AsAppliedTaskDetailsRepositoryService } from './as-applied-task-details-repository.service';
import { DateTime } from 'luxon';

@Injectable()
export class AsAppliedTaskDetailsService {
  constructor(
    private asAppliedTaskDetailsRepositoryService: AsAppliedTaskDetailsRepositoryService,
    private harvestYearStateService: HarvestYearStateService,
    private asAppliedService: AsAppliedService,
    private notificationService: NotificationService
  ) {}

  public exportShapeFile(appliedTask: ExecutedLocationCollection | null): Observable<Blob | null> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      tap(() => this.asAppliedService.loading(true, 'main.asApplied.filePicker.uploadMessage')),
      switchMap((harvestYear) => this.asAppliedTaskDetailsRepositoryService.exportShapeFile(harvestYear, appliedTask)),
      finalize(() => {
        this.asAppliedService.loading(false, 'main.asApplied.filePicker.uploadMessage');
      })
    );
  }

  public exportShapeFiles(farmId: number, tasks: number[]): Observable<Blob | null> {
    return this.harvestYearStateService.harvestYear$.pipe(
      first(),
      tap(() => this.asAppliedService.loading(true, 'main.asApplied.filePicker.downloadMessage')),
      switchMap((harvestYear) => this.asAppliedTaskDetailsRepositoryService.exportShapeFiles(farmId, harvestYear, tasks)),
      finalize(() => {
        this.asAppliedService.loading(false, 'main.asApplied.filePicker.downloadMessage');
      })
    );
  }

  public updateExecutedTask(executedTasks: ExecutedTask[], date: DateTime, taskId: number, operationTypeGroupId: number) {
    this.harvestYearStateService.harvestYear$
      .pipe(
        first(),
        tap(() => this.asAppliedService.loading(true, 'main.asApplied.taskDetails.executedTask.loading')),
        switchMap((harvestYear) => {
          if (executedTasks.length > 1) {
            return this.asAppliedTaskDetailsRepositoryService.updateExecutedTasks(executedTasks, harvestYear, taskId, operationTypeGroupId);
          } else {
            return this.asAppliedTaskDetailsRepositoryService.updateExecutedTask(
              executedTasks.first()!,
              harvestYear,
              taskId,
              operationTypeGroupId
            );
          }
        }),
        finalize(() => {
          this.asAppliedService.loading(false, 'main.asApplied.taskDetails.executedTask.loading');
        })
      )
      .subscribe(() => {
        this.notificationService.showUpdated('main.asApplied.taskDetails.executedTask.task');
        this.asAppliedService.updateTasksAndGroups();
        this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
      });
  }
}
