import { Component, Input } from '@angular/core';
import { BenchmarkDistribution } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';

@Component({
  selector: 'app-chart-info',
  templateUrl: './chart-info.component.html',
  styleUrls: ['./chart-info.component.scss'],
})
export class ChartInfoComponent {
  @Input() public regionalAverage = 0;
  @Input() public personalAverage = 0;
  @Input() public distribution: BenchmarkDistribution[] = [];
  @Input() public area = 0;
  @Input() public unit = '';

  get farmCount() {
    return this.distribution.reduce((previous, next) => previous + next.count, 0);
  }
}
