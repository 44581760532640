import { NgModule } from '@angular/core';
import { FileUploadModule } from '@app/shared/file-upload/file-upload.module';
import { SharedModule } from '@app/shared/shared.module';
import { ShapeFilesRepo } from './repository/shape-files-repo.service';
import { ShapeFilesService } from './service/shape-files.service';
import { UtmCardProjectionService } from './service/utm-card-projection.service';
import { ShapeFileImportComponent } from './shape-file-import.component';

@NgModule({
  imports: [FileUploadModule, SharedModule],
  declarations: [ShapeFileImportComponent],
  providers: [ShapeFilesRepo, ShapeFilesService, UtmCardProjectionService],
  exports: [ShapeFileImportComponent],
})
export class ShapeFileImportModule {}
