import { Injectable } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';

/**
 * Custom Luxon date adapter
 * Used to format day names and date names differently
 */
@Injectable()
export class DatePickerAdapter extends LuxonDateAdapter {
  /**
   * Override week names to show two letters on 'narrow' style
   * @param style Format style of day name
   * @returns List of the 7 day names
   */

  // @ts-ignore - TS4114 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    // convert 'narrow' style to 'long' to get full day names (keep 'short')
    const convertedStyle = style === 'narrow' ? 'long' : style;

    const days = super.getDayOfWeekNames(convertedStyle);

    // shorten 'long' day names to two characters
    // 'monday' -> 'mo'
    // 'sunday' -> 'su'
    // etc.
    if (style === 'narrow') return days.map((day) => day.slice(0, 2));

    return days;
  }

  /**
   * Override date names to remove period
   * @returns List of date names (1, 2, 3, ... 31)
   */

  // @ts-ignore - TS4114 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  getDateNames(): string[] {
    const names = super.getDateNames();

    return names.map((name) => name.replace('.', ''));
  }

  /**
   * Override the parseValue function to handle both 'dd-mm-yyyy' and 'dd/mm/yyyy' formats
   * @param value The value to parse
   * @returns Parsed DateTime object or null if parsing fails
   */

  override parse(value: any, parseFormat: any): DateTime | null {
    if (!value) return null;
    if (value instanceof DateTime) return value;

    if (typeof value === 'string') {
      // check for match  - 'dd-mm-yyyy' or 'dd/mm/yyyy' or 'dd/mm-yyyy'
      const dateRegex = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/;
      const matches = value.match(dateRegex);

      if (matches) {
        const day = parseInt(matches[1], 10);
        const month = parseInt(matches[2], 10);
        const year = parseInt(matches[3], 10);
        //hour 12 ensure that winter-summertime doens't change the data
        if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
          return DateTime.fromObject({ year, month, day, hour: 12 });
        }
      }

      const isoDate = DateTime.fromISO(value);

      if (isoDate.isValid) return isoDate;

      //standard luxonDateAdapter Implementation for strong handling - used here for validator
      return DateTime.fromString(value, parseFormat, { locale: this.locale });
    }
    return null;
  }
}
