import { PlotBandColors } from '@app/core/enums/plot-band-colors.enum';
import { CornField } from '@app/core/interfaces/corn-field.interface';
import { FieldStyles } from '@app/helpers/map/map-styles/fields.map.styles';
import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

export const CornPrognosisStyles = {
  getFieldStyle: (zoom = 13, feature: Feature) => {
    const field: CornField = feature.get('field');
    return [
      new Style({
        stroke: new Stroke({
          color: '#FFF',
          width: 2,
        }),
        fill: new Fill({
          color: getColor(field),
        }),
        text: FieldStyles.getTextStyle(zoom, feature, false),
      }),
    ];
  },
  getSelectedFieldStyle: (zoom: number, size: number, feature: Feature) => {
    const field: CornField = feature.get('field');
    return [
      new Style({
        stroke: new Stroke({
          color: '#FFF',
          width: 4,
        }),
        fill: new Fill({
          color: getColor(field),
        }),
        text: FieldStyles.getTextStyle(zoom, feature, true),
      }),
    ];
  },
};

export function getColor(field: CornField) {
  switch (field.plotBandColor) {
    case PlotBandColors.Red:
      return 'red';
    case PlotBandColors.Yellow:
      return 'yellow';
    case PlotBandColors.Green:
      return 'green';
    default:
      return 'transparent';
  }
}
