import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { User } from '@app/core/interfaces/user.interface';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getCurrentUser(): Observable<User> {
    const options: any = {
      withCredentials: true,
    };
    return this.http.get<User>(`${this.endpoints.foApi}/users/current`, options);
  }
}
