import { Unit } from '@app/core/interfaces/unit.type';
import { SoilSampleCorrections } from './phosphorus-samples.interface';

export interface LimeSetting {
  cutoff: number | undefined | null;
  limeTypeNeed: LimeTypeNeed | undefined | null;
}

export interface VraSettings {
  limeSetting?: LimeSetting;
  unitText: Unit | null;
  soilSampleCorrections: SoilSampleCorrections;
}

export enum LimeTypeNeed {
  None = 0,
  Type1Need = 1,
  Type2Need = 2,
}
