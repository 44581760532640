import { Component, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { AppUpdaterService } from '@app/core/updater/app-updater.service';
import { NavigationService } from '@app/layouts/navigation/navigation.service';
import { AppLayoutService } from '../../core/layout/app-layout.service';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
  providers: [AppUpdaterService],
})
export class NavigationComponent {
  public items$ = this.navigationService.getNavigationItems();
  public queryParams$ = this.navigationService.getQueryParamsWhenNavigating$();
  public menuMinimized$ = this.layoutService.menuMinimized$;
  public farmsSelected$ = this.navigationService.farmsAreSelected$;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private appUpdater: AppUpdaterService,
    private layoutService: AppLayoutService
  ) {}

  public isLinkActive(url: string | undefined) {
    return this.navigationService.isLinkActive(url);
  }

  /**
   * navigate
   * Calls swUpadter to check for a new version
   * App Updater Service will reload the app if an update is available
   */
  public async navigate(url: string | undefined, queryParams: Params, disabled: boolean) {
    if (disabled) return;

    const params = QueryParamService.stringifyObjectValues(queryParams);
    this.appUpdater.checkForUpdate();
    await this.router.navigate([url], {
      queryParams: params,
    });
  }
}
