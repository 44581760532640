import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FileFormats } from '@app/core/file/file-formats.enum';
import { DroneImageImportLogicService } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-import-logic.service';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent implements OnDestroy {
  @Input() public form!: UntypedFormGroup;
  @Input() public maxDate!: DateTime;
  @Input() public minDate!: DateTime;

  public fileFormats = FileFormats;
  public selectableFarms$ = this.droneImageImportLogicService.selectableFarms$;
  public selectableHarvestYears$ = this.droneImageImportLogicService.selectableHarvestYears$;
  public totalHa$ = this.droneImageImportLogicService.selectedHa$;
  public imageError$ = this.droneImageImportLogicService.imageError$;
  private subscriptions = new Subscription();

  @ViewChild('filePicker', { static: false }) public filePicker!: ElementRef;

  constructor(private droneImageImportLogicService: DroneImageImportLogicService) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onFileInputChange(event: any) {
    const files = Array.from(event?.target?.files) as File[];
    if (files?.length > 0) {
      this.droneImageImportLogicService.selectedFiles = files;
    }
    // Resets the file inputs internal value, so the same files can be picked more than once.
    this.filePicker.nativeElement.value = null;
  }

  public OnFarmSelectionChange($event: any) {
    this.droneImageImportLogicService.selectedFarm = $event;
  }

  public trackByFn(index: any) {
    return index;
  }
}
