import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { DialogService } from '@app/shared/dialog/dialog.service';

@Component({
  selector: 'app-dialog-link',
  templateUrl: './dialog-link.component.html',
  styleUrls: ['./dialog-link.component.scss'],
})
export class DialogLinkComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public snackBarData: any,
    private _snackRef: MatSnackBarRef<DialogLinkComponent>,
    private dialogService: DialogService
  ) {}

  public openDialog() {
    this.dialogService.openCustomDialog(this.snackBarData.dialogComponent, this.snackBarData.options);
    this._snackRef.dismiss();
  }
}
