import { Injectable } from '@angular/core';
import { SecureUrlService } from '@app/core/secure-url/secure-url.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HotspotTypes } from '../../shape-file-import/interfaces/hotspot-types.enum';

@Injectable({
  providedIn: 'root',
})
export class HotspotDetailsPresentationService {
  constructor(private secureUrlService: SecureUrlService) {}
  public getImagesForUrls(imageUrls: string[], maxSize: number) {
    return forkJoin(
      imageUrls.map((url) =>
        this.secureUrlService.get(`${url}?size=${maxSize}`).pipe(map((sanitizedUrl) => ({ originalUrl: url, sanitizedUrl })))
      )
    );
  }

  public isSubTypeAllowed(hotspotTypeId: HotspotTypes) {
    if (!hotspotTypeId) {
      return false;
    }

    return (
      parseInt(hotspotTypeId.toString(), 10) === HotspotTypes.Weed ||
      parseInt(hotspotTypeId.toString(), 10) === HotspotTypes.Bug ||
      parseInt(hotspotTypeId.toString(), 10) === HotspotTypes.Disease
    );
  }
}
