<app-side-drawer-wrapper>
  <app-side-drawer-header
    cancelIconName="back"
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
    [showMinimizeButton]="true"
    title="{{ 'main.fieldmap.layers.corn' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <app-corn-harvest-prognosis-legend></app-corn-harvest-prognosis-legend>
  </app-side-drawer-body>
</app-side-drawer-wrapper>
