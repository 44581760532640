<app-side-drawer-wrapper>
  <app-side-drawer-header
    cancelIconName="back"
    [showMinimizeButton]="true"
    (action)="onClose()"
    (onHide)="onHide()"
    title="{{ 'main.growthRegulation.insurance.info.header' | translate }}"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <app-spinner [hasBackdrop]="true" *ngIf="savingState.isLoading$ | async" [message]="(savingState.message$ | async)!"></app-spinner>
    <app-check-out-table [growthRegulationFieldTableVM]="growthRegulationFieldTableVM"></app-check-out-table>
    <app-check-out-form [farm]="growthRegulationFieldTableVM[0].farm" (infoChange)="onInfoChange($event)"></app-check-out-form>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button (clicked)="onBuyClick()"
      >{{ 'main.growthRegulation.insurance.orderInsurance' | translate }}
    </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</app-side-drawer-wrapper>

<app-legend *ngIf="isLegendVisible$ | async" [sideDrawerWidth]="(sideDrawerWidth$ | async)!">
  <app-growth-regulation-legend-content></app-growth-regulation-legend-content>
</app-legend>
<app-fields [growthRegulations]="(growthRegulationVm$ | async)!"></app-fields>
