import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-soil-type-select',
  templateUrl: './soil-type-select.component.html',
  styleUrls: ['./soil-type-select.component.scss'],
})
export class SoilTypeSelectComponent {
  @Output() public coarseSelect = new EventEmitter<boolean>();
  @Output() public fineSelect = new EventEmitter<boolean>();
  @Output() public claySelect = new EventEmitter<boolean>();

  constructor() {}

  public onCoarseChange(isSelected: boolean) {
    this.coarseSelect.emit(isSelected);
  }

  public onFineChange(isSelected: boolean) {
    this.fineSelect.emit(isSelected);
  }

  public onClayChange(isSelected: boolean) {
    this.claySelect.emit(isSelected);
  }
}
