import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RemindersRepoService } from '@app/core/repositories/reminders/reminders-repo.service';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { TranslateModule } from '../translate/translate.module';
import { ReminderListComponent } from './reminder-list/reminder-list.component';
import { ReminderWindowComponent } from './reminder-window.component';
import { ReminderWindowService } from './reminder-window.service';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatToolbarModule, IconModule, MatExpansionModule, TranslateModule, AppPipesModule],
  declarations: [ReminderWindowComponent, ReminderListComponent],
  providers: [ReminderWindowService, RemindersRepoService],
})
export class ReminderWindowModule {}
