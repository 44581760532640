<div class="header-container">
  <!-- Normal header -->
  <img
    *ngIf="!(isNaesgaard$ | async); else naesgaardHaeder"
    class="cropmanager-logo"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    [ngClass]="{ hidden: (menuMinimized$ | async) }"
    src="assets/images/logos/cropmanager-logo.svg"
    (click)="navigate()"
  />
  <div class="toolbar-wrap" [appResponsiveClass]="{ mobile: 'lt-Medium' }" [ngClass]="{ hidden: (menuMinimized$ | async) }">
    <app-user-info [disabled]="isNaesgaard$ | async" data-testid="user-info-toggle"></app-user-info>
    <app-farm-picker [disabled$]="isNaesgaard$"></app-farm-picker>
    <app-reminder-button [disabled$]="isNaesgaard$"></app-reminder-button>
    <app-feedback-button [disabled$]="isNaesgaard$"></app-feedback-button>
  </div>

  <!-- Collapsed header -->
  <img
    *ngIf="!(isNaesgaard$ | async); else naesgaardHaederCollapsed"
    src="assets/images/logos/cm-logo.svg"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    class="big-logo"
    [ngClass]="{ 'hide-logo': !(menuMinimized$ | async) }"
    (click)="navigate(true)"
  />
</div>

<ng-template #naesgaardHaeder>
  <img
    class="cropmanager-logo disabled"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    [ngClass]="{ hidden: (menuMinimized$ | async) }"
    src="assets/images/logos/cropmanager-logo.svg"
  />
</ng-template>

<ng-template #naesgaardHaederCollapsed>
  <img
    src="assets/images/logos/cm-logo.svg"
    [appResponsiveClass]="{ mobile: 'lt-Medium' }"
    class="big-logo disabled"
    [ngClass]="{ 'hide-logo': !(menuMinimized$ | async) }"
  />
</ng-template>
