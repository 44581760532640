import { VraOperationTypeGroup, VraOperationTypeGroupDto } from '../interfaces/vra-operation-type-group.interface';
import { VraTask } from '../interfaces/vra-task.interface';

type MappingResult = readonly [VraOperationTypeGroup[], VraTask[]];

/**
 * Map API DTO's to store models
 */
export function mapVraOperationTypeGroups(groups: VraOperationTypeGroupDto[]): MappingResult {
  const groupsWithTasks = groups.map<VraOperationTypeGroup>((group) => ({
    ...group,
    type: group.id,
    tasks: group.tasks.map<VraTask>((task) => ({ ...task, id: `${group.id}-${task.hashCode}`, group: group.id })),
  }));

  const tasks = groupsWithTasks.flatMap((x) => x.tasks);

  return [groupsWithTasks, tasks] as const;
}
