@if (appliedTasks) {
  <div class="as-applied-task-info pdf-as-applied-task-info">
    @if (appliedTasks.length === 1) {
      <div class="as-applied-task-info-row">
        <div class="name">{{ 'main.asApplied.taskDetails.fileName' | translate }}</div>
        @if (appliedTasks.first()?.fileName?.length! >= 20) {
          <app-tooltip class="tooltip value" [tooltipText]="appliedTasks.first()?.fileName" [delayType]="'long'" [isOneLine]="true">
            <div class="fileName">{{ appliedTasks.first()?.fileName }}</div>
          </app-tooltip>
        } @else {
          <ng-template>
            <div class="fileName">{{ appliedTasks.first()?.fileName }}</div>
          </ng-template>
        }
      </div>
    }

    @if (appliedTasks.length === 1) {
      <div class="as-applied-task-info-row">
        <div class="name">{{ 'main.asApplied.taskDetails.uploadDate' | translate }}</div>
        <div class="value">
          {{ appliedTasks.first()?.uploadDate | dateFormat }}
        </div>
      </div>
    }
    @if (appliedTasks.length === 1) {
      <div class="as-applied-task-info-row">
        <div class="name">{{ 'main.asApplied.taskDetails.uploadBy' | translate }}</div>
        <div class="value">{{ appliedTasks.first()?.uploadBy }}</div>
      </div>
    }
    <br />
    @if (appliedTasks.length === 1) {
      <div class="as-applied-task-info-row">
        <div class="name">{{ 'main.asApplied.taskDetails.taskType' | translate }}</div>
        <div class="value">{{ appliedTasks.first()?.executedTask?.operationTypeGroup }}</div>
      </div>
    }
    <div class="as-applied-task-info-row">
      <div class="name">{{ 'main.asApplied.taskDetails.date' | translate }}</div>
      <div class="value">{{ appliedTasks.first()?.executedTask?.date | dateFormat }}</div>
    </div>
    <div class="as-applied-task-info-row">
      <div class="name">{{ 'main.asApplied.taskDetails.field' | translate }}</div>
      <div class="value">{{ appliedTasks.first()?.executedTask?.fieldName }}</div>
    </div>
    @if (appliedTasks.length > 1) {
      <div class="as-applied-task-info-row">
        <div class="name">{{ 'main.asApplied.taskDetails.files' | translate }}</div>
        <div class="value">{{ appliedTasks.length }}</div>
      </div>
    }
    @if (appliedTasks.length === 1 && appliedTasks.first()?.executedTask?.cropName) {
      <div class="as-applied-task-info-row">
        <div class="name">{{ 'main.asApplied.taskDetails.crop' | translate }}</div>
        <div class="value">{{ appliedTasks.first()?.executedTask?.cropName }}</div>
      </div>
    }

    @if (appliedTasks.length === 1 && (appliedTasks.first()?.executedTask?.products?.length ?? 0) > 0) {
      <app-as-applied-task-info-product-table [appliedTask]="appliedTasks.first()!"></app-as-applied-task-info-product-table>
    }
  </div>
}
