import { Injectable } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { FieldsRepo } from '@app/core/repositories/fields/fields-repo.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CropNormDTO } from '../../../../core/repositories/crops/crop-norm.dto';
import { FailedFields } from './../../../../core/interfaces/FailedFields';
import { CropsRepoService } from './../../../../core/repositories/crops/crops-repo.service';
import { CompareHelper } from './../../../../helpers/compare/compare-helper';
import { FarmStateService } from './../../../../state/services/farm/farm-state.service';
import { FieldCopyItem } from './fieldCopyItem';
import { ItemIncludeCrop } from './itemIncludeCrop';
import { filterNullish } from '@app/shared/operators';

@Injectable({
  providedIn: 'root',
})
export class FieldCopyStateService {
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  private _transferingFieldsSubject = new BehaviorSubject<boolean>(false);
  private _failedTransferedFieldsSubject = new BehaviorSubject<FailedFields[]>([]);
  private _cropSubject = new BehaviorSubject<CropNormDTO[]>([]);
  private _selectedFieldsSubject = new BehaviorSubject<FieldCopyItem[]>([]);
  private _deSelectedFieldsSubject = new BehaviorSubject<FieldCopyItem[]>([]);

  private _includeCropSubject = new BehaviorSubject<ItemIncludeCrop[] | null>(null);
  private _allFields = new BehaviorSubject<Field[]>([]);

  constructor(
    private fieldsRepo: FieldsRepo,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private cropsRepo: CropsRepoService,
    private translateService: TranslateService
  ) {}

  public initializeFields(): void {
    this._loadingSubject.next(true);
    this.harvestYearStateService.harvestYear$
      .pipe(
        first(),
        filterNullish(),
        withLatestFrom(this.farmStateService.selectedFarms$),
        switchMap(([harvestYear, farms]) => {
          return this.fieldsRepo.getFieldsNotPresentNextYear(
            farms.map((farm) => farm.id),
            harvestYear
          );
        }),
        filterNullish()
      )
      .subscribe((result) => {
        const fields = result
          .map((field) => ({
            id: field.id,
            fieldNumber: field.number,
            fieldName: field.name,
            fieldCrop: field.crops.slice(-1)[0],
            fieldCropName: field.crops.slice(-1)[0]?.cropName,
            fieldCropNormNumber: field.crops.slice(-1)[0]?.cropNormNumber,
            includeCrop: false,
            fieldCropSort: undefined,
            fieldData: field,
          }))
          .sort((x, y) => CompareHelper.compareFieldNumbers(x.fieldNumber, y.fieldNumber));

        this._allFields.next(result);
        this._selectedFieldsSubject.next(fields);
        this._deSelectedFieldsSubject.next([]);
        this._loadingSubject.next(false);
      });

    this.harvestYearStateService.harvestYear$
      .pipe(
        first(),
        filterNullish(),
        withLatestFrom(this.farmStateService.selectedFarms$),
        switchMap(([harvestYear, farms]) => {
          return this.getCropsAndPreCrops(farms.map((farm) => farm.id)[0], harvestYear);
        })
      )
      .subscribe((result) => {
        this._cropSubject.next(result);
      });
  }

  public selectField(fieldNumber: string): void {
    this._selectedFieldsSubject
      .pipe(
        first(),
        withLatestFrom(this.deSelectedFields$),
        map(([selected, deselected]) => ({ selected: selected, deselected: deselected }))
      )
      .subscribe(({ selected, deselected }) => {
        const targetFieldIndex = deselected.findIndex((field) => field.fieldNumber === fieldNumber);
        const targetField = deselected.splice(targetFieldIndex, 1)[0];

        const newSelectedList = [...selected, { ...targetField }].sort((x, y) =>
          CompareHelper.compareFieldNumbers(x.fieldNumber, y.fieldNumber)
        );
        this._selectedFieldsSubject.next(newSelectedList);
        this._deSelectedFieldsSubject.next(deselected);
      });
  }

  public deSelectField(fieldNumber: string): void {
    this._selectedFieldsSubject
      .pipe(
        first(),
        withLatestFrom(this.deSelectedFields$),
        map(([selected, deselected]) => ({ selected: selected, deselected: deselected }))
      )
      .subscribe(({ selected, deselected }) => {
        const targetFieldIndex = selected.findIndex((field) => field.fieldNumber === fieldNumber);
        const targetField = selected.splice(targetFieldIndex, 1)[0];
        const newDeselectedList = deselected.concat([{ ...targetField }]);
        const sorted = newDeselectedList.sort((x, y) => CompareHelper.compareFieldNumbers(x.fieldNumber, y.fieldNumber));
        this._deSelectedFieldsSubject.next(sorted);
        this._selectedFieldsSubject.next(selected);
      });
  }

  public includeCrop(fieldNumber: string, includeCrop: boolean): void {
    this.emitCropChange([fieldNumber], includeCrop);
    this._selectedFieldsSubject
      .pipe(
        first(),
        withLatestFrom(this.deSelectedFields$),
        map(([selected, deselected]) => ({ selected: selected, deselected: deselected }))
      )
      .subscribe(({ selected, deselected }) => {
        let targetFieldIndex = selected.findIndex((field) => field.fieldNumber === fieldNumber);
        if (targetFieldIndex >= 0) {
          selected[targetFieldIndex].includeCrop = includeCrop;
          this._selectedFieldsSubject.next(selected);
          return;
        }

        targetFieldIndex = deselected.findIndex((field) => field.fieldNumber === fieldNumber);
        if (targetFieldIndex >= 0) {
          deselected[targetFieldIndex].includeCrop = includeCrop;
          this._deSelectedFieldsSubject.next(deselected);
          return;
        }
      });
  }

  public includeAllCrop(includeCrops: boolean): void {
    this._selectedFieldsSubject.pipe(first()).subscribe((selectedFields) => {
      selectedFields.forEach((element) => {
        element.includeCrop = includeCrops;
      });

      this.emitCropChange(
        selectedFields.map((field) => field.fieldNumber),
        includeCrops
      );
      this._selectedFieldsSubject.next(selectedFields);
    });
  }

  private emitCropChange(fieldNumber: string[], includeCrop: boolean): void {
    this._allFields.pipe(first()).subscribe((result) => {
      const CropChangeEvents: ItemIncludeCrop[] = [];
      fieldNumber.forEach((element) => {
        const targetField = result.find((field) => field.number === element);

        if (targetField?.crops[0]) {
          CropChangeEvents.push({ id: targetField.id, includeCrop: includeCrop, crop: targetField.crops[0] });
        }
      });

      if (CropChangeEvents.length > 0) {
        this._includeCropSubject.next(CropChangeEvents);
      }
    });
  }
  public get selectedFields$(): Observable<FieldCopyItem[]> {
    return this._selectedFieldsSubject.asObservable();
  }
  public get deSelectedFields$(): Observable<FieldCopyItem[]> {
    return this._deSelectedFieldsSubject.asObservable();
  }

  public get includesCrop$(): Observable<ItemIncludeCrop[] | null> {
    return this._includeCropSubject.asObservable();
  }
  public set includesCrop(itemIncludeCrop: ItemIncludeCrop[]) {
    this._includeCropSubject.next(itemIncludeCrop);
  }

  public get loading$(): Observable<boolean> {
    return this._loadingSubject.asObservable();
  }

  public set loading(loading: boolean) {
    this._loadingSubject.next(loading);
  }

  public get transferingFields$(): Observable<boolean> {
    return this._transferingFieldsSubject.asObservable();
  }

  public set transferingFields(isTransfering: boolean) {
    this._transferingFieldsSubject.next(isTransfering);
  }

  public get failedTransferedFields$(): Observable<FailedFields[]> {
    return this._failedTransferedFieldsSubject.asObservable();
  }

  public set addToFailedTransferedFields(fields: FailedFields[]) {
    this.failedTransferedFields$.pipe(first()).subscribe((currentFailedFields) => {
      this._failedTransferedFieldsSubject.next([...currentFailedFields, ...fields]);
    });
  }
  public resetTransferedFields() {
    this._failedTransferedFieldsSubject.next([]);
  }

  public get crops$(): Observable<CropNormDTO[]> {
    return this._cropSubject.asObservable();
  }

  public get allFields(): Observable<Field[]> {
    return this._allFields.asObservable();
  }

  private getCropsAndPreCrops(farmId: number, harvestYear: number) {
    const noCrop: CropNormDTO = {
      farmId: farmId,
      harvestYear: harvestYear,
      name: this.translateService.instant('main.fieldAdministration.createField.dropDownAdditionalContent.noCrop'),
      number: undefined,
      directorateCropNumber: undefined,
    };
    return this.cropsRepo.getCrops(farmId, harvestYear).pipe(
      map((crops) => crops.filter((crop) => !!crop.varietyGroupNumber)),
      map((crops) => crops.filter((crop) => crop.isSelectable)),
      map((crops) =>
        crops.sort((a, b) => {
          if (a.name && b.name) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
          }
          return 0;
        })
      ),
      map((crops) => {
        crops.unshift(noCrop);
        return crops;
      })
    );
  }
}
