import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { CardsOrTableComponent } from '@app/shared/cards-or-table/cards-or-table/cards-or-table.component';
import { CardsPresentationModule } from '@app/shared/cards-or-table/cards-presentation/cards-presentation.module';
import { CardsOrTableService } from '@app/shared/cards-or-table/services/cards-or-table.service';
import { TablePresentationModule } from '@app/shared/cards-or-table/table-presentation/table-presentation.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { CardsOrTableToggleComponent } from './cards-or-table-toggle/cards-or-table-toggle.component';
import { GroupedCardsOrTablesComponent } from './grouped-cards-or-tables/grouped-cards-or-tables.component';

@NgModule({
  imports: [
    CommonModule,
    CardsPresentationModule,
    TablePresentationModule,
    MatButtonToggleModule,
    MatIconModule,
    TranslateModule,
    MatExpansionModule,
    MatButtonModule,
    IconModule,
  ],
  declarations: [CardsOrTableComponent, CardsOrTableToggleComponent, GroupedCardsOrTablesComponent],
  exports: [CardsOrTableComponent, CardsOrTableToggleComponent, GroupedCardsOrTablesComponent],
  providers: [CardsOrTableService],
})
export class CardsOrTableModule {}
