<app-side-drawer-wrapper>
  <app-side-drawer-header
    (action)="onCloseClick()"
    title="{{ 'navigation.prognosis' | translate }}"
    cancelIconName="minimize"
  ></app-side-drawer-header>
  <app-side-drawer-body>
    <h5 class="title">{{ 'main.prognosis.choosePrognosis' | translate }}</h5>
    <div class="prognosis-list">
      <ng-container *ngFor="let prognosisMapFeature of mapCoverFlowItems$ | async" [ngSwitch]="prognosisMapFeature.displayName">
        <ng-container *ngSwitchCase="'main.fieldmap.layers.yieldPrognosis'">
          <div
            [ngClass]="{ 'disabled-list-item': prognosisMapFeature.isDisabled }"
            class="prognosis-list-item"
            (click)="onSelect(prognosisMapFeature)"
            appGTagEventTracker
            eventName="{{ prognosisMapFeature.displayName! | lastSegment }} (click)"
            eventCategory="Prognosis"
          >
            <app-tooltip fullWidth [tooltipText]="prognosisMapFeature.isDisabled ? ('operationSelector.disabled' | translate) : null">
              {{ prognosisMapFeature.displayName! | translate }}
            </app-tooltip>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'main.fieldmap.layers.potatoBlight'">
          <app-tooltip
            fullWidth
            [tooltipText]="prognosisMapFeature.isDisabled ? ('common.tooltips.missing-alerts-pack' | translate) : null"
          >
            <div
              [ngClass]="{ 'disabled-list-item': prognosisMapFeature.isDisabled }"
              class="prognosis-list-item"
              (click)="onSelect(prognosisMapFeature)"
              appGTagEventTracker
              eventName="{{ prognosisMapFeature.displayName! | lastSegment }} (click)"
              eventCategory="Prognosis"
            >
              {{ prognosisMapFeature.displayName! | translate }}
              <img class="axel-icon" alt="axel_icon" src="assets/images/axel/icons/aXel_icon.svg" />
            </div>
          </app-tooltip>
        </ng-container>

        <ng-container *ngSwitchCase="'main.fieldmap.layers.growthRegulation'">
          <app-tooltip
            fullWidth
            [tooltipText]="prognosisMapFeature.isDisabled ? ('common.tooltips.missing-alerts-pack' | translate) : null"
          >
            <div
              *ngIf="'plant_cropmanager_growth-regulation' | featureToggle | async; else disabledGrowthRegulation"
              [ngClass]="{ 'disabled-list-item': prognosisMapFeature.isDisabled }"
              class="prognosis-list-item"
              (click)="onSelect(prognosisMapFeature)"
              appGTagEventTracker
              eventName="{{ prognosisMapFeature.displayName! | lastSegment }} (click)"
              eventCategory="Prognosis"
            >
              {{ prognosisMapFeature.displayName! | translate }}
              <img class="axel-icon" alt="axel_icon" src="assets/images/axel/icons/aXel_icon.svg" />
            </div>

            <ng-template #disabledGrowthRegulation>
              <!-- *ngIf helps us make the message variable, and will always be true -->
              <div
                class="prognosis-list-item disabled-list-item"
                [ngClass]="{ 'disabled-list-item': prognosisMapFeature.isDisabled }"
                *ngIf="'main.growthRegulation.prognosisClosed' | translate: { harvestYear: harvestYear$ | async } as message"
                (click)="onDisabledSelect(message)"
                appGTagEventTracker
                eventName="{{ prognosisMapFeature.displayName! | lastSegment }} (click)"
                eventCategory="Prognosis"
              >
                {{ prognosisMapFeature.displayName! | translate }}
              </div>
            </ng-template>
          </app-tooltip>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <app-tooltip fullWidth [tooltipText]="prognosisMapFeature.isDisabled ? ('operationSelector.disabled' | translate) : null">
            <div
              [ngClass]="{ disabled: prognosisMapFeature.isDisabled }"
              class="prognosis-list-item"
              (click)="onSelect(prognosisMapFeature)"
              appGTagEventTracker
              eventName="{{ prognosisMapFeature.displayName! | lastSegment }} (click)"
              eventCategory="Prognosis"
            >
              {{ prognosisMapFeature.displayName! | translate }}
            </div>
          </app-tooltip>
        </ng-container>
      </ng-container>
    </div>
  </app-side-drawer-body>
</app-side-drawer-wrapper>
