<app-tooltip [delayType]="toolTipDelay" [tooltipText]="tooltipText" [position]="tooltipPosition">
  <app-icon
    *ngIf="!invert"
    icon="cm_add_filled"
    (click)="onClick()"
    [ngClass]="{ lg: size === 'lg', sm: size === 'sm' }"
    [ngStyle]="{ color: color }"
  ></app-icon>
  <app-icon
    *ngIf="invert"
    icon="cm_add"
    (click)="onClick()"
    [ngClass]="{ lg: size === 'lg', sm: size === 'sm' }"
    [ngStyle]="{ color: color }"
  ></app-icon>
</app-tooltip>
