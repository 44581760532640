<div class="wrapper" [appResponsiveClass]="{ portable: 'lt-Medium' }">
  <div class="header">
    <app-icon class="cancel-btn" icon="Luk" (click)="onClose()"></app-icon>
    <br />
    <!--<app-icon class="expand-btn" icon="minimize" (click)="onMinimize()"></app-icon>-->
  </div>
  <div class="content" [ngSwitch]="shownComponentState$ | async">
    <div class="cultivationJournal" *ngSwitchCase="components.CultivationJournal">
      <app-decision-support></app-decision-support>
      <app-component-selector [initialComponent]="selectedValue" (onValueChange)="valueChange($event)"></app-component-selector>
      <div class="journal" [appResponsiveClass]="{ mobile: 'XSmall' }">
        <app-tasks-overview [appResponsiveClass]="{ mobile: 'XSmall' }" [field]="field"></app-tasks-overview>
      </div>
    </div>
    <div class="nTools" *ngSwitchCase="components.NTools">
      <app-n-tool-wrapper></app-n-tool-wrapper>
    </div>
    <div class="blight" *ngSwitchCase="components.Blight">
      <app-blight-wrapper>
        <app-component-selector [initialComponent]="selectedValue" (onValueChange)="valueChange($event)"></app-component-selector>
      </app-blight-wrapper>
    </div>
  </div>
</div>
