<app-side-drawer-header
  (action)="onCloseClick()"
  [showMinimizeButton]="true"
  cancelIconName="back"
  (onHide)="onHideClick()"
  title="{{ 'main.fieldmap.layers.asApplied' | translate }}"
></app-side-drawer-header>

<app-side-drawer-body>
  <div class="as-applied-wrapper" [ngSwitch]="shownComponent$ | async">
    <ng-container *ngSwitchCase="asAppliedShownComponentEnum.AsAppliedTaskComponent">
      <app-as-applied-tasks
        [asAppliedTaskGroups]="(asAppliedTasks$ | async)!"
        [unspecifiedTaskGroup]="(unspecifiedGroups$ | async)!"
        (taskSelect)="onTaskSelect($any($event))"
      ></app-as-applied-tasks>
      <app-as-applied-file-picker></app-as-applied-file-picker>
      <app-as-applied-uploaded-files
        [metadataParents]="(metadataParentDtos$ | async)!"
        (uploadSelect)="onMetadataParentSelect($event)"
      ></app-as-applied-uploaded-files>
    </ng-container>
    <app-as-applied-task-details
      *ngSwitchCase="asAppliedShownComponentEnum.AsAppliedTaskDetailsComponent"
      [appliedTasks]="selectedTasks"
      [possibleAdditionLogs]="logsOnSelectedTaskField"
    ></app-as-applied-task-details>
    <app-as-applied-field-tasks
      *ngSwitchCase="asAppliedShownComponentEnum.AsAppliedFieldTasksComponent"
      [asAppliedTaskGroups]="(asAppliedTasks$ | async)!"
      [field]="selectedField"
      [unspecifiedTaskGroup]="(unspecifiedGroups$ | async)!"
      (taskSelect)="onTaskSelect($event)"
    ></app-as-applied-field-tasks>
    <app-as-applied-meta-data
      *ngSwitchCase="asAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent"
      [saveClicked$]="saveClicked$"
    ></app-as-applied-meta-data>
    <app-as-applied-file-details *ngSwitchCase="asAppliedShownComponentEnum.AsAppliedMetaDataFile"></app-as-applied-file-details>

    <div *ngSwitchCase="asAppliedShownComponentEnum.AsAppliedConfigFile">
      <app-as-applied-meta-data [saveClicked$]="saveClicked$"></app-as-applied-meta-data>

      <ng-container *ngFor="let group of asAppliedService.shapeFileAnalysis.groups; let indexOfelement = index">
        <app-as-applied-config-file
          [index]="indexOfelement"
          [fileNames]="group.shapefiles"
          [columnNames]="group.columnNames"
          [srid]="group.srid"
        ></app-as-applied-config-file>
      </ng-container>
    </div>

    <div *ngIf="selectedTasks === null && (showDownloadManager$ | async)" class="downloadShapeFilesButton">
      <app-square-button
        buttonType="Primary"
        [matTooltip]="'main.asApplied.metaData.downloadZipFiles.tooltip' | translate"
        (clicked)="openDownloadShapeFilesDialog()"
      >
        <span class="mat-button-wrapper">{{ 'main.asApplied.metaData.downloadZipFiles.button' | translate | uppercase }}</span>
      </app-square-button>
    </div>
  </div>
  <app-spinner *ngIf="loadingState.isLoading$ | async" [message]="(loadingState.message$ | async)!" [hasBackdrop]="true"></app-spinner>
</app-side-drawer-body>
<ng-container *ngIf="shownComponent$ | async as shownComponent">
  <app-side-drawer-footer *ngIf="shownComponent === asAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent">
    <app-side-drawer-footer-button (click)="saveClicked()">{{ 'common.save' | translate }} </app-side-drawer-footer-button>
  </app-side-drawer-footer>

  <app-side-drawer-footer *ngIf="shownComponent === asAppliedShownComponentEnum.AsAppliedConfigFile">
    <app-side-drawer-footer-button [disabled]="isAllShapefileGroupsNotSet()" (click)="nextClicked()"
      >{{ 'main.asApplied.shapefileDetails.nextPage' | translate | uppercase }}
    </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</ng-container>

<app-map-feature-select (field)="onFieldSelect($event)" [disabled]="!!selectedTasks" [layers]="selectableMapLayers">
</app-map-feature-select>
