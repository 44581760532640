<mat-dialog-content>
  <h4>{{ 'header.farmPicker.edit.title' | translate }}</h4>
  <div class="content" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <form class="content-form" novalidate [formGroup]="farmFormGroup" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
      <app-input
        type="text"
        class="input-field"
        formControlName="name"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.name.name' | translate"
      >
        <app-input-error *ngIf="farmFormGroup.get('name')?.hasError('required')"
          >{{ 'header.farmPicker.edit.name.required' | translate }}
        </app-input-error>
      </app-input>
      <app-input
        type="text"
        class="input-field"
        formControlName="address1"
        [formControl]="$any(farmFormGroup.get('address1'))"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.address1.name' | translate"
      >
      </app-input>
      <app-input
        type="text"
        class="input-field"
        formControlName="address2"
        [formControl]="$any(farmFormGroup.get('address2'))"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.address2.name' | translate"
      >
      </app-input>
      <app-input
        type="text"
        class="input-field"
        formControlName="address3"
        [formControl]="$any(farmFormGroup.get('address3'))"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.address3.name' | translate"
      >
      </app-input>
      <app-input
        type="text"
        class="input-field"
        formControlName="postalCode"
        [formControl]="$any(farmFormGroup.get('postalCode'))"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.postalCode.name' | translate"
      >
      </app-input>
      <app-input
        type="text"
        class="input-field"
        formControlName="postalDistrict"
        [formControl]="$any(farmFormGroup.get('postalDistrict'))"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.postalDistrict.name' | translate"
      >
      </app-input>
      <app-input
        type="text"
        class="input-field"
        formControlName="telephoneNo"
        [formControl]="$any(farmFormGroup.get('telephoneNo'))"
        [readonly]="isLoading"
        [placeholder]="'header.farmPicker.edit.telephoneNo.name' | translate"
      >
      </app-input>
      <app-input type="text" formControlName="cvr" [readonly]="isLoading" [placeholder]="'header.farmPicker.edit.cvr.name' | translate">
        <app-input-error *ngIf="farmFormGroup.get('cvr')?.hasError('hasWhitespace')">{{
          'header.farmPicker.edit.cvr?.hasWhitespace' | translate
        }}</app-input-error>
        <app-input-error *ngIf="farmFormGroup.get('cvr')?.hasError('isNotInteger')">{{
          'header.farmPicker.edit.cvr.isNotInteger' | translate
        }}</app-input-error>
        <app-input-error *ngIf="farmFormGroup.get('cvr')?.hasError('minlength')"
          >{{ 'header.farmPicker.edit.cvr.minlength' | translate }}
        </app-input-error>
        <app-input-error *ngIf="farmFormGroup.get('cvr')?.hasError('maxlength')"
          >{{ 'header.farmPicker.edit.cvr.maxlength' | translate }}
        </app-input-error>
      </app-input>
    </form>

    <div class="content-img" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
      <div
        (dragover)="onImageDragOver($event)"
        (dragleave)="onImageDragLeave($event)"
        (drop)="onImageDrop($event)"
        class="farm-image-frame"
        [class.drag-over]="imageDragOver"
      >
        <app-icon *ngIf="isDefaultImage" [icon]="'outline_farm'" class="default-img"></app-icon>
        <div *ngIf="isDefaultImage" class="farm-image-description">{{ 'header.farmPicker.edit.image.description' | translate }}</div>
        <img *ngIf="!isDefaultImage" class="farm-img" [src]="farmImageUrl" />
      </div>
      <div class="image-upload-actions">
        <app-square-button [buttonType]="'Primary'" (click)="showFilePicker()" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
          <app-icon icon="file_upload"></app-icon>
          {{ 'header.farmPicker.edit.filePicker.name' | translate }}
        </app-square-button>
        <app-square-button
          [buttonType]="'Secondary'"
          [appResponsiveClass]="{ mobile: 'lt-Medium' }"
          [disabled]="isDefaultImage"
          class="square-delete-button mh-a"
          [buttonClass]="$any('square-delete-button')"
          (clicked)="onDeleteFarmImage($event)"
        >
          <app-icon icon="delete_outline"></app-icon>
          {{ 'header.farmPicker.edit.deleteImage' | translate }}
        </app-square-button>
      </div>
      <input #fileInput type="file" style="display: none" (change)="onFileChange($event)" />
    </div>
  </div>
  <hr />
  <div class="actions" [appResponsiveClass]="{ mobile: 'lt-Medium' }">
    <app-square-button
      [buttonType]="'Secondary'"
      class="delete-btn"
      [appResponsiveClass]="{ mobile: 'lt-Medium' }"
      (clicked)="onDeleteFarmClicked($event)"
    >
      <app-icon icon="delete_outline"></app-icon>
      {{ 'header.farmPicker.edit.buttons.deleteFarm' | translate }}
    </app-square-button>
    <app-square-button
      [buttonType]="'Secondary'"
      class="cancel-btn"
      [appResponsiveClass]="{ mobile: 'lt-Medium' }"
      (clicked)="onCancelClicked($event)"
    >
      {{ 'common.cancel' | translate }}
    </app-square-button>
    <app-square-button [buttonType]="'Primary'" [disabled]="farmFormGroup.invalid" (clicked)="onConfirmClicked($event)" [type]="'submit'">
      {{ 'common.save' | translate }}
    </app-square-button>
  </div>
  <app-spinner [message]="loadingMessage" [hasBackdrop]="true" *ngIf="isLoading"></app-spinner>
</mat-dialog-content>
