import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';
import { WaterBalanceDTO } from './water-balance-dto.interface';

@Injectable({
  providedIn: 'root',
})
export class WaterBalanceRepo {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getWaterBalanceData(fieldYearId: number | undefined): Observable<WaterBalanceDTO> {
    return this.httpClient.get<WaterBalanceDTO>(`${this.endpoints.foApi}/waterbalance`, {
      params: { fieldYearId },
    });
  }
}
