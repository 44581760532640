/**
 * Function removes the item which fullfills the given predicate from the array
 * @param array The array to update
 * @param predicate function to determine if array item is the one to update
 * @param newItem the new item
 */
export function updateObjectArrayItem<ArrayType extends object>(
  array: ArrayType[],
  predicate: (item: ArrayType) => boolean,
  newItem: ArrayType,
  excludeKeys: (keyof ArrayType)[] = [] // Array of keys to exclude from updating
): ArrayType[] {
  return array.map((item) =>
    predicate(item)
      ? {
          ...item,
          ...Object.fromEntries(Object.entries(newItem).filter(([key]) => !excludeKeys.includes(key as keyof ArrayType))),
        }
      : item
  );
}
