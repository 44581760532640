export enum PdfContentAlignment {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
}

export interface PDFLineItem {
  text: string;
  hideLine: boolean;
  addLine: boolean;
}
