<app-cards-or-table-toggle
  *ngIf="!hidePresentationModeSwitch"
  [value]="presentationMode"
  (change)="presentationModeChanged($event)"
></app-cards-or-table-toggle>
<app-cards-presentation
  *ngIf="shouldUseCards && (rawData$ | async)?.length"
  [fieldDefinitions]="(fieldDefinitions$ | async)!"
  [actionDefinitions]="actionDefinitions"
  [data]="(data$ | async)!"
  (action)="forwardActionEvent($event)"
  [cardRef]="cardRef"
></app-cards-presentation>
<app-table-presentation
  *ngIf="shouldUseTable && (rawData$ | async)?.length"
  [fieldDefinitions]="(fieldDefinitions$ | async)!"
  [actionDefinitions]="actionDefinitions"
  [data]="(data$ | async)!"
  (action)="forwardActionEvent($event)"
  [tableRef]="tableRef"
></app-table-presentation>

<div *ngIf="!(rawData$ | async)?.length" class="no-data">
  <p>{{ 'taskCards.noTasks' | translate }}</p>
  <p>{{ 'taskCards.addTaskGuidance' | translate }}</p>
</div>
