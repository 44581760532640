import { Route, Routes } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { NewMapComponent } from './map.component';

export const newMapLazyRoute: Route = {
  path: 'newmap',
  loadChildren: () => import('./map.module').then((m) => m.MapModule),
};

export const mapRoutes: Routes = [
  {
    path: '',
    canActivate: [ConsentGuard],
    component: NewMapComponent,
    children: [
      {
        path: 'basis-layer',
        loadChildren: () => import('./features/basis-layer/basis-layer.module').then((m) => m.BasisLayerModule),
      },
      {
        path: 'vra',
        loadChildren: () => import('./features/vra/vra.module').then((m) => m.VraModule),
      },
      {
        path: 'cultivation-journal',
        loadChildren: () => import('./features/cultivation-journal/cultivation-journal.module').then((m) => m.CultivationJournalModule),
      },
      {
        path: 'field-plan',
        loadChildren: () => import('./features/field-plan/field-plan.module').then((m) => m.FieldPlanModule),
      },
      {
        path: 'hotspots',
        loadChildren: () => import('./features/hotspots/hotspots.module').then((m) => m.HotspotsModule),
      },
      {
        path: 'prognosis',
        loadChildren: () => import('./features/prognosis/prognosis.module').then((m) => m.PrognosisModule),
      },
    ],
  },
];
