import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { DirtyGuard } from '@app/core/guards/dirty-guard.service';
import { FarmGuard } from '@app/core/guards/farm-guard.service';
import { FieldAnalysisFeaturesComponent } from '@app/map/features/field-analysis/features/field-analysis-features.component';
import { FieldAnalysisSideDrawerComponent } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.component';
import { sideDrawerOutlet } from '@app/map/map.constants';
import { AccessControlGuard, AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';

export const fieldAnalysisRoutes: AccessControlRoutes = [
  {
    path: 'field-analysis',
    canActivate: [AuthGuard, ConsentGuard, FarmGuard, AccessControlGuard],
    data: {
      module: 'field_analysis',
    },
    canDeactivate: [DirtyGuard],
    canLoad: [],
    children: [
      {
        path: '',
        component: FieldAnalysisFeaturesComponent,
      },
      {
        path: '',
        component: FieldAnalysisSideDrawerComponent,
        outlet: sideDrawerOutlet,
      },
    ],
  },
];
