import { ICache } from '@app/helpers/cache/cache.interface';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';

interface CacheItem<TValueType> {
  /**
   * Time to live in milliseconds
   */
  ttl?: number;
  birthDate: DateTime;
  value?: TValueType;
}

export interface TtlCacheConfig {
  /**
   * Time to live in milliseconds
   */
  defaultTtl?: number;
}

const defaultTtlConfig: TtlCacheConfig = {
  defaultTtl: 1000 * 60 * 2, // 2 Minutes
};

/**
 * Makes it possible to actively use undefined as a cache value...
 */
const internalDoesNotExistDefaultValue = '#{internal}-does-not-exist-1337';

export class TtlCache<TValueType> implements ICache<TValueType> {
  private scheduledInserts = new Map<string, Observable<TValueType>>();
  private cache = new Map<string, CacheItem<TValueType>>();
  private config: TtlCacheConfig;

  constructor(config: TtlCacheConfig = {}) {
    this.config = { ...defaultTtlConfig, ...config };
  }

  public get(key: string, defaultValue?: TValueType): TValueType {
    const cacheItem = this.cache.get(key);

    if (this.hasExpired(key, cacheItem!)) {
      return defaultValue!;
    }

    return cacheItem!.value!;
  }

  public getAsync(key: string, defaultValue?: TValueType): Observable<TValueType> {
    return of(this.get(key, defaultValue))!;
  }

  public set(key: string, value: TValueType | undefined, ttl?: number) {
    const birthDate = DateTime.now();
    const cacheItem: CacheItem<TValueType> = { value, birthDate, ttl: ttl || this.config.defaultTtl };

    this.cache.set(key, cacheItem);
  }

  public getOrSet(key: string, valueProvider: () => TValueType, defaultValue?: TValueType, ttl?: number): TValueType {
    if (this.has(key)) {
      return this.get(key)!;
    }

    try {
      const value = valueProvider();

      this.set(key, value, ttl);

      return value;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      return defaultValue!;
    }
  }

  public getOrSetAsync(key: string, valueProvider: () => Observable<TValueType>, ttl?: number): Observable<TValueType> {
    if (this.scheduledInserts.has(key)) {
      return this.scheduledInserts.get(key)!;
    }

    if (this.has(key)) {
      return this.getAsync(key)!;
    }

    const scheduledInsert = valueProvider().pipe(
      tap((value) => this.set(key, value, ttl)),
      tap(() => this.scheduledInserts.delete(key)),
      share()
    );

    this.scheduledInserts.set(key, scheduledInsert);

    return scheduledInsert;
  }

  public has(key: string): boolean {
    return this.get(key, internalDoesNotExistDefaultValue as any) !== (internalDoesNotExistDefaultValue as any);
  }

  public clear() {
    this.cache.clear();
  }

  private hasExpired(key: string, cacheItem: CacheItem<TValueType>) {
    if (!this.cache.has(key)) {
      return true;
    }

    const now = DateTime.now().toMillis();
    const birthDate = cacheItem.birthDate.toMillis();
    const timeAlive = now - birthDate;
    const hasExpired = timeAlive > cacheItem.ttl!;

    if (hasExpired) {
      this.cache.delete(key);
    }

    return hasExpired;
  }

  public delete(key: string) {
    this.cache.delete(key);
  }
}
