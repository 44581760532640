<app-chart-wrapper [showSpinner]="isLoading" loadingMessage="septoriaChart.fetchingData">
  <app-square-button class="info-button" (click)="toggleInfo()">
    <app-icon class="info" icon="info_outline"></app-icon>
    {{ 'common.info' | translate }}
  </app-square-button>

  <app-select
    class="providerSelector"
    [formControl]="providerControl"
    (valueChange)="onProvidersChange($event)"
    [multiple]="true"
    [placeholder]="'septoriaChart.dataSources' | translate"
  >
    <app-select-option *ngFor="let provider of wetHoursDataProvider" [value]="provider">
      {{ provider.name }}
    </app-select-option>
  </app-select>

  <kendo-chart
    class="septoriaChart"
    [transitions]="false"
    [categoryAxis]="categoryAxis"
    [valueAxis]="valueAxis"
    (render)="onRender($event)"
    (legendItemClick)="$event.preventDefault()"
    (legendItemHover)="$event.preventDefault()"
    data-testid="septoriaChart.kendo-chart"
  >
    <kendo-chart-tooltip>
      <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-category="category">
        {{ category | dateFormat }}
        <br />
        {{ dataItem.accumulatedWetHours }} {{ 'common.unitType.hours' | translate }}
      </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend position="bottom" orientation="horizontal" [item]="{ visual: legendItemVisual }"></kendo-chart-legend>
    <kendo-chart-series>
      <ng-container *ngFor="let provider of providerControl.value; let i = index">
        <kendo-chart-series-item
          *ngIf="provider.dataType === 'observed'; else forecast"
          type="line"
          color="{{ provider.chartLineColor }}"
          name="{{ provider.name }}"
          [markers]="seriesMarkers"
          [data]="provider.data"
          field="max"
          categoryField="dateDay"
        >
        </kendo-chart-series-item>
        <ng-template #forecast>
          <kendo-chart-series-item
            type="line"
            color="{{ provider.chartLineColor }}"
            name="{{ 'septoriaChart.forecast' | translate }}"
            [markers]="seriesMarkers"
            [data]="provider.data"
            field="max"
            categoryField="dateDay"
            dashType="dash"
          >
          </kendo-chart-series-item>
        </ng-template>
      </ng-container>
    </kendo-chart-series>
  </kendo-chart>
  <div *ngIf="!isLoading && aggregated.length <= 0; else noProvider" class="chart-message">{{ 'septoriaChart.noData' | translate }}</div>
  <ng-template #noProvider>
    <div *ngIf="!isLoading && providerControl.value && providerControl.value.length <= 0" class="chart-message">
      {{ 'septoriaChart.noDataSource' | translate }}
    </div>
  </ng-template>
  <div class="sub-info">{{ 'septoriaChart.infoText' | translate }}</div>
</app-chart-wrapper>
