import { Injectable } from '@angular/core';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { IWindow } from '@app/core/interfaces/window.interface';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowStateService {
  public get screenSize$() {
    return this._screenSizeSubject.asObservable();
  }

  private set screenSize(screenSize: ScreenSize) {
    this._screenSizeSubject.next(screenSize);
  }

  private _screenSizeSubject: BehaviorSubject<ScreenSize>;
  private _window: IWindow;

  constructor(private windowRefService: WindowRefService) {
    this._window = this.windowRefService.nativeWindow;
    this._window.addEventListener('resize', (event) => this.onResize(event));

    this._screenSizeSubject = new BehaviorSubject(this.getCurrentScreenSize());
  }

  // window resize event handler to set correct screensize val in store
  private onResize(event: UIEvent) {
    const screenSize = this.getCurrentScreenSize((event.target as Window).innerWidth);

    this.screenSize$.pipe(first()).subscribe((screenSizeState) => {
      if (screenSize !== screenSizeState && screenSize) {
        this.screenSize = screenSize;
      }
    });
  }

  private getCurrentScreenSize(width: number = this._window.innerWidth): ScreenSize {
    if (width < 400) {
      return ScreenSize.XS;
    }

    if (width < 768) {
      return ScreenSize.S;
    }

    if (width < 1100) {
      return ScreenSize.M;
    }

    if (width < 1500) {
      return ScreenSize.L;
    }

    if (width >= 1500) {
      return ScreenSize.XL;
    }

    return ScreenSize.XL;
  }
}
