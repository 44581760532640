import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { HotspotGroup } from '../../interfaces/hotspot-group.interface';

export interface IHotspotGroupsRepo {
  get(farmId: number, from: DateTime): Observable<HotspotGroup[]>;
  create(farmId: number, groups: HotspotGroup[]): Observable<HotspotGroup[] | null>;
  update(farmId: number, groups: HotspotGroup[]): Observable<HotspotGroup[] | null>;
  delete(farmId: number, group: HotspotGroup): Observable<number | null>;
}

@Injectable()
export class HotspotGroupsRepo implements IHotspotGroupsRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(farmId: number): Observable<HotspotGroup[]> {
    return this.http.get<HotspotGroup[]>(`${this.endpoints.ftApi}/farms/${farmId}/hotspotgroups`, {});
  }

  public create(farmId: number, groups: HotspotGroup[]): Observable<HotspotGroup[] | null> {
    return this.http.post<HotspotGroup[], HotspotGroup[]>(`${this.endpoints.ftApi}/farms/${farmId}/hotspotgroups`, groups);
  }

  public update(farmId: number, groups: HotspotGroup[]): Observable<HotspotGroup[] | null> {
    return this.http.put<HotspotGroup[], HotspotGroup[]>(
      `${this.endpoints.ftApi}/farms/${farmId}/hotspotgroups/${groups.map((g) => g.id)}`,
      groups
    );
  }

  public delete(farmId: number, group: HotspotGroup): Observable<number | null> {
    return this.http.delete(`${this.endpoints.ftApi}/farms/${farmId}/hotspotgroups/${group.id}`);
  }
}
