import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-field-sense-form',
  templateUrl: './field-sense-form.component.html',
  styleUrls: ['./field-sense-form.component.scss'],
})
export class FieldSenseFormComponent {
  @Input() public fieldSenseFormGroup!: UntypedFormGroup;
}
