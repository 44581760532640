import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/overlay/index';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { SideDrawerConfig } from './side-drawer-config.interface';
import { SideDrawerOverlayComponent } from './side-drawer-overlay.component';
import { SideDrawerRef } from './side-drawer-ref';

export interface ISideDrawerOverlayService {
  open<TComponent, TData, TResult>(
    component: ComponentType<TComponent>,
    overlayConfig?: SideDrawerConfig<TData>
  ): SideDrawerRef<TComponent, TData, TResult>;
}

@Injectable({
  providedIn: 'root',
})
export class SideDrawerOverlayService {
  private sideDrawerRefs: SideDrawerRef<any, any, any>[] = [];

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) {}

  public openCustomSideDrawer<TComponent, TData, TResult>(
    component: ComponentType<TComponent>,
    overlayConfig?: SideDrawerConfig<TData>
  ): SideDrawerRef<TComponent, TData, TResult> {
    const defaultConfig: SideDrawerConfig<TData> = {
      hasBackdrop: true,
      backdropClass: 'global-side-drawer-backdrop',
      panelClass: 'side-drawer-content',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().right().centerVertically(),
      height: '100%',
    };

    const sideDrawerConfig = { ...defaultConfig, ...overlayConfig };
    const overlayRef = this.createOverlay(sideDrawerConfig);

    const sideDrawerRef = new SideDrawerRef<TComponent, TData, TResult>(overlayRef, sideDrawerConfig.data as TData);
    const componentRef = this.attachSideDrawerContainer(component, overlayRef, sideDrawerRef);
    sideDrawerRef.setComponent(componentRef.instance);

    this.sideDrawerRefs.push(sideDrawerRef);

    return sideDrawerRef;
  }

  public openSideDrawer<TComponent, TData, TResult>(
    sideDrawerContentComponentRef: TemplateRef<TComponent>,
    overlayConfig?: SideDrawerConfig<TData>
  ): SideDrawerRef<TComponent, TData, TResult> {
    const defaultConfig: SideDrawerConfig<TData> = {
      hasBackdrop: true,
      backdropClass: 'side-drawer-backdrop',
      panelClass: 'side-drawer-content',
      positionStrategy: this.overlay.position().global().right().centerVertically(),
      width: '500px',
      height: '100%',
    };
    const sideDrawerConfig = { ...defaultConfig, ...overlayConfig };
    const overlayRef = this.createOverlay(sideDrawerConfig);

    const sideDrawerRef = new SideDrawerRef<TComponent, TData, TResult>(overlayRef, sideDrawerConfig.data as TData);

    const componentRef = this.attachSideDrawerContainer(SideDrawerOverlayComponent, overlayRef, sideDrawerRef);

    // run in async context for triggering "tick", thus avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      componentRef.instance.templateRef = sideDrawerContentComponentRef;
    });

    this.sideDrawerRefs.push(sideDrawerRef);

    return sideDrawerRef;
  }

  public sideDrawerRefIsDefined() {
    return this.sideDrawerRefs.length > 0;
  }

  private createOverlay<TData>(config: SideDrawerConfig<TData>) {
    return this.overlay.create(config);
  }

  private attachSideDrawerContainer<TComponent, TData, TResult>(
    component: ComponentType<TComponent>,
    overlayRef: OverlayRef,
    sideDrawerRef: SideDrawerRef<any, TData, TResult>
  ) {
    const injector = this.createInjector(sideDrawerRef);

    const containerPortal = new ComponentPortal(component, null, injector);
    const componentRef: ComponentRef<TComponent> = overlayRef.attach(containerPortal);

    return componentRef;
  }

  public closeAllSideDrawers() {
    this.sideDrawerRefs.forEach((sideDrawerRef) => {
      sideDrawerRef.close();
    });

    this.sideDrawerRefs = [];
  }

  public hideAllSideDrawers() {
    this.sideDrawerRefs.forEach((sideDrawerRef) => {
      sideDrawerRef.hide();
    });
  }

  public showAllSideDrawers() {
    this.sideDrawerRefs.forEach((sideDrawerRef) => {
      sideDrawerRef.show();
    });
  }

  private createInjector<TComponent, TData, TResult>(sideDrawerRef: SideDrawerRef<TComponent, TData, TResult>): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(SideDrawerRef, sideDrawerRef);

    return new PortalInjector(this.injector, injectionTokens);
  }
}
