import { ModuleWithProviders, NgModule } from '@angular/core';
import { FieldAnalysisPickerModule } from '@app/map/features/field-analysis/field-analysis-feature-picker/field-analysis-picker.module';
import { FieldAnalysisSideDrawerComponent } from '@app/map/features/field-analysis/field-analysis-side-drawer/field-analysis-side-drawer.component';
import { SharedModule } from '@app/shared/shared.module';
import { AsAppliedModule } from '../features/as-applied/as-applied.module';
import { DroneImageImportModule } from '../features/drone-image-import/drone-image-import.module';
import { NdviMapFeatureModule } from '../features/ndvi-feature/ndvi-feature.module';
import { SoilSampleMapFeatureModule } from '../features/soil-sample-feature/soil-sample-feature.module';
import { FieldAnalysisSideDrawerContentComponent } from './field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from './field-analysis-side-drawer.service';

@NgModule({
  imports: [
    SharedModule,
    FieldAnalysisPickerModule,
    DroneImageImportModule,
    NdviMapFeatureModule,
    SoilSampleMapFeatureModule,
    AsAppliedModule,
  ],
  declarations: [FieldAnalysisSideDrawerComponent, FieldAnalysisSideDrawerContentComponent],
  exports: [FieldAnalysisSideDrawerContentComponent],
})
export class FieldAnalysisSideDrawerModule {
  public static forRoot(): ModuleWithProviders<FieldAnalysisSideDrawerModule> {
    return {
      ngModule: FieldAnalysisSideDrawerModule,
      providers: [FieldAnalysisSideDrawerService],
    };
  }
}
