<ng-container *ngIf="hotspot">
  <div>
    <form novalidate [formGroup]="hotspotFormGroup">
      <app-select
        *ngIf="selectedFarms && selectedFarms.length > 1"
        formControlName="farmId"
        [placeholder]="'main.fieldmap.hotspotInfo.farm' | translate"
      >
        <app-select-option *ngFor="let farm of selectedFarms; trackBy: trackByFn" [value]="farm.id">
          {{ farm.name }} - {{ farm.id }}
        </app-select-option>
      </app-select>

      <app-select formControlName="notificationRangeType" [placeholder]="'main.fieldmap.hotspotInfo.notificationRange' | translate">
        <app-select-option *ngFor="let range of notificationRanges; trackBy: trackByFn" [value]="range.id">
          {{ range.distance }} {{ range.distance ? range.unit : ('main.fieldmap.hotspotInfo.noNotificationRange' | translate) }}
        </app-select-option>
      </app-select>

      <div *ngIf="isSubTypeAllowed">
        <app-hotspot-subtype-select
          [hotspotSubTypes]="hotspotSubTypes"
          [selectedHotspotSubTypeIds]="hotspot.hotspotSubTypeIds"
          (hotspotSubtypesChange)="onHotspotSubTypeChange($event)"
        ></app-hotspot-subtype-select>
      </div>

      <app-textarea [placeholder]="'main.fieldmap.hotspotInfo.description' | translate" formControlName="description"></app-textarea>
      <mat-error *ngIf="descriptionControl?.hasError('maxLength')">
        {{ 'main.fieldmap.hotspotInfo.validation.note.maxLength' | translate }}</mat-error
      >

      <app-date-picker
        formControlName="registeredDate"
        placeholder="{{ 'common.date' | translate }}"
        minDate="01/01/2019"
        [maxDate]="today"
      >
      </app-date-picker>
      <mat-error *ngIf="registeredDateControl?.hasError('minDate') || registeredDateControl?.hasError('maxDate')">
        {{ 'main.fieldmap.hotspotInfo.validation.date.invalid' | translate }} {{ today | dateFormat }}
      </mat-error>
      <mat-error *ngIf="registeredDateControl?.hasError('required')">
        {{ 'common.validationMsg.required' | translate }}
      </mat-error>

      <app-select [multiple]="true" formControlName="hotspotGroups" [placeholder]="'main.fieldmap.hotspotInfo.selectGroups' | translate">
        <app-select-option *ngFor="let g of hotspotGroupOptions" [value]="g">
          {{ g.name }}
        </app-select-option>
      </app-select>

      <label class="validation-error" *ngIf="descriptionControl?.invalid && (descriptionControl?.dirty || descriptionControl?.touched)">
        {{ 'main.fieldmap.hotspotInfo.validation.note.maxLength' | translate }}
      </label>
    </form>
    <div class="img-label">
      {{ 'main.fieldmap.hotspotInfo.hotspotImages' | translate }}
    </div>
    <app-spinner *ngIf="imagesLoading"></app-spinner>
    <div class="hotspot-images-container" [hidden]="!imageUrls.length">
      <app-image-slider #imageSlider [urls]="imageUrls" (onDeleteImage)="onDeleteImage($event)"></app-image-slider>
      <input
        aria-label="file-upload-input"
        type="file"
        accept=".jpg, .png, .jpeg, |image/*"
        class="hide"
        #fileUploadInput
        (change)="onPhotoFileInputChange($event)"
      />
    </div>
  </div>

  <div>
    <div class="btn-container">
      <app-square-button [buttonType]="'Secondary'" (click)="onUploadPhotoClicked()">
        <app-icon [icon]="'add'"></app-icon> {{ 'main.fieldmap.hotspotInfo.addImage' | translate }}
      </app-square-button>
      <app-square-button *ngIf="!isNewHotspot" [buttonType]="'Secondary'" (clicked)="onDeleteClicked($event)">
        <app-icon icon="delete_outline"></app-icon> {{ 'main.fieldmap.hotspotInfo.deleteHotspot' | translate }}
      </app-square-button>
    </div>
    <label class="validation-error" *ngIf="fileUploadError">{{ 'main.fieldmap.hotspotInfo.fileTypeError' | translate }}</label>
  </div>
</ng-container>
