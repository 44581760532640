import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit, OnDestroy {
  @Input() public urls: string[] = [];
  @Input() public headlines: string[] = [];
  @Input() public canDelete = true;
  @Input() public outsideIndicators = false;
  @Input() public isClickable = false;
  @Input() public lockAspectTo?: string; // Format: eg '16:9'
  @Input() public autoPlayMs: number | null = null;

  @Output() public onDeleteImage: EventEmitter<number> = new EventEmitter();
  @Output() public onImageClick: EventEmitter<number> = new EventEmitter();

  @ViewChild('scrollerElement', { static: false }) public scrollerElement?: ElementRef;
  @ViewChild('indicatorElement', { static: false }) public indicatorElement?: ElementRef;

  public activeImageIndex = 0;
  public previousOffset = 0;
  public newOffset = 0;
  public panning = false;

  private panningEvent?: Event | null;
  private subscriptions = new Subscription();

  constructor() {
    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(500))
        .subscribe(() => this.resetScroll())
    );
  }

  /**
   * When resizing window, the caroussel loses track of its items
   * Therefore, we must reset it to the first image when resizing window
   */
  public resetScroll() {
    this.gotoSlide(0);
  }

  public ngOnInit() {
    if (!this.urls) {
      this.urls = [];
    }
    if (this.autoPlayMs) {
      setTimeout(() => this.autoSlide(), this.autoPlayMs);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private autoSlide() {
    if (!this.panning) {
      if (this.activeImageIndex >= this.urls.length - 1) {
        this.gotoSlide(0);
      } else {
        this.gotoSlide(this.activeImageIndex + 1);
      }
    }

    setTimeout(() => this.autoSlide(), this.autoPlayMs ?? 0);
  }

  public onPanStart(event: Event): void {
    this.panning = true;
    this.panningEvent = event;
  }

  public onPan(event: any): void {
    if (!this.allowScrolling()) {
      return;
    }

    this.newOffset = this.getNewOffSet(event.deltaX);
    this.transformScroller(this.newOffset);
  }

  public onPanEnd(event: any): void {
    if (!this.allowScrolling()) {
      return;
    }

    this.snapToClosestImage(event.deltaX);
  }

  private transformScroller(offset: number) {
    if (this.scrollerElement) {
      this.scrollerElement.nativeElement.style.transform = `translateX(${offset}px)`;
    }
  }

  private snapToClosestImage(currentOffset: number): void {
    this.panning = false;
    const scrollingForwards = this.newOffset <= this.previousOffset;

    // if user has scrolled more than halfways to next imgage, change active image based on direction
    this.activeImageIndex =
      Math.abs(currentOffset) > this.indicatorElement?.nativeElement.clientWidth / 2
        ? scrollingForwards
          ? ++this.activeImageIndex
          : --this.activeImageIndex
        : this.activeImageIndex;

    // handle if user tries to scroll past end or beginning of scroller
    if (this.activeImageIndex + 1 > this.urls.length) {
      this.activeImageIndex = this.urls.length - 1;
    } else if (this.activeImageIndex < 0) {
      this.activeImageIndex = 0;
    }

    this.gotoSlide(this.activeImageIndex);
  }

  private getNewOffSet(eventOffset: number) {
    return this.previousOffset + eventOffset;
  }

  private allowScrolling() {
    return this.urls.length > 1;
  }

  public gotoSlide(slideIndex: number) {
    const newOffset = -(this.indicatorElement?.nativeElement.clientWidth * slideIndex);
    this.previousOffset = newOffset;
    this.transformScroller(newOffset);
    this.activeImageIndex = slideIndex;
  }

  public deleteSlide(slideIndex: number) {
    if (!this.canDelete) {
      return;
    }
    this.onDeleteImage.emit(slideIndex);
    this.gotoSlide(this.urls.length - 1);
  }

  public clickSlide(slideIndex: number) {
    if (!this.isClickable || this.panningEvent) {
      this.panningEvent = null;
      return;
    }
    this.onImageClick.emit(slideIndex);
  }
}
