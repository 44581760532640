import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { WindowRefService } from '@app/core/window/window-ref.service';
import { UserStateService } from '@app/state/services/user/user-state.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-cnh-form',
  templateUrl: './cnh-form.component.html',
  styleUrls: ['./cnh-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CnhFormComponent {
  constructor(
    private endpoints: EndpointsService,
    private windowRef: WindowRefService,
    private userStateService: UserStateService
  ) {}

  protected url$ = this.userStateService.currentUser$.pipe(
    map((user) => {
      // this.windowRef.location.href is already encoded and needs decoding before re-encoding
      const currentUrl = this.windowRef.location.href;
      const decodedUrl = decodeURIComponent(currentUrl);
      const encodedRedirectUrl = encodeURIComponent(decodedUrl);
      return `${this.endpoints.cnhApi}/authentication?redirecturl=${encodedRedirectUrl}&username=${user?.username.toLowerCase()}`;
    })
  );

  @Output() public linkClick = new EventEmitter<void>();

  public onLinkClick() {
    this.linkClick.emit();
  }
}
