export class FileExtensionHelper {
  public static getFileExtension(file: File): string {
    const fileName = file?.name;
    if (!fileName) {
      throw new Error('Invalid file. Files should contain a name property.');
    }
    const lastDot = fileName.lastIndexOf('.');
    if (lastDot === -1) {
      throw new Error('Invalid file extension. File names should contain a dot.');
    } else if (fileName.endsWith('.')) {
      throw new Error('Invalid file extension. File names should not end on a dot.');
    }
    return fileName.substr(lastDot + 1, fileName.length).toLowerCase();
  }

  public static validateFileExtensions(files: File[], allowedFileExtensions: string[]): boolean {
    return files.every((file) => allowedFileExtensions.includes(this.getFileExtension(file)));
  }

  public static areExtensionsIdentical(files?: File[]): boolean {
    if (!files) {
      throw new Error('Invalid files. Files should be an array of file objects.');
    } else if (files.length === 0) {
      return true;
    }
    const firstFileExtension = this.getFileExtension(files[0]);
    return files.every((file) => this.getFileExtension(file) === firstFileExtension);
  }
}
