import { Injectable } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import range from 'lodash-es/range';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class HarvestYearPickerMapService {
  private readonly HARVESTYEARS_IN_FUTURE_TO_DISPLAY = 6;

  public getCurrentHarvestYear(currentDate: DateTime = DateTime.now()): number {
    const currentHarvestYear = currentDate.month >= Month.August ? currentDate.year + 1 : currentDate.year;
    return currentHarvestYear;
  }

  public generateYears(start: number, end: number) {
    if (start > end) {
      return [start];
    }
    return range(start, end + 1).sort((a, b) => b - a);
  }

  public get endHarvestYear() {
    return this.getCurrentHarvestYear() + this.HARVESTYEARS_IN_FUTURE_TO_DISPLAY;
  }

  public getScrollTopForMenu(currentHarvestYear: number, endHarvestYear: number, menuPointHeightPx: number, offsetMenuPoints = 2): number {
    const difference = endHarvestYear - currentHarvestYear;
    return difference * menuPointHeightPx - (difference > 1 ? offsetMenuPoints * menuPointHeightPx : 0);
  }
}
