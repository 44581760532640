import { Injectable } from '@angular/core';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { CategoryAxis, ChartAxis } from '@progress/kendo-angular-charts';
import { Element, geometry, Group, Path, Rect as Rect2, Text } from '@progress/kendo-drawing';
import { DateTime } from 'luxon';

const { Point, Rect } = geometry;

interface PlotData {
  title: string;
  duration: string;
  unit: string;
  date: string;
  padding: number;
  offsetY: number;
  plotArea: any;
}

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private readonly msPerDay = 86400000;
  constructor() {}

  public calculateNumberOfStepsByScreenSize<T>(screenSize: ScreenSize, aggregatedData: T[], categoryAxis: CategoryAxis) {
    switch (screenSize) {
      case ScreenSize.XS:
        return {
          ...categoryAxis,
          labels: {
            ...categoryAxis.labels,
            step: this.calculateNumberOfSteps(aggregatedData.length, 8),
          },
        };
      case ScreenSize.S:
        return {
          ...categoryAxis,
          labels: {
            ...categoryAxis.labels,
            step: this.calculateNumberOfSteps(aggregatedData.length, 4),
          },
        };
      case ScreenSize.M:
        return {
          ...categoryAxis,
          labels: {
            ...categoryAxis.labels,
            step: this.calculateNumberOfSteps(aggregatedData.length, 2),
          },
        };
      default:
        return {
          ...categoryAxis,
          labels: {
            ...categoryAxis.labels,
            step: this.calculateNumberOfSteps(aggregatedData.length),
          },
        };
    }
  }

  /**
   * Creates a label for a custom line on a Kendo chart.
   * @param text The label text to show
   * @param line The line on the chart. See createLine() method.
   */
  public createLineLabel(text: string, line: Path, yOffset: number = 0): Text {
    const x = line.bbox().origin.x + line.bbox().size.width;
    const y = line.bbox().origin.y + yOffset;
    const p = new Point(x, y);

    const label = new Text(text, p, {
      fill: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      font: '14px "LFPressSans", Arial, Verdana, sans-serif',
    });

    const offsetY = -3;
    label.position([x - label.bbox().size.width / 2, y - label.bbox().size.height + offsetY]);

    // If we want to rotate the text:
    // const offsetX = 2;
    // const offsetY = -10;
    // label.transform(transform().rotate(-90, p));
    // label.position([x - label.bbox().size.height + offsetY, y + offsetX]); // X and Y are switched, since its rotated.

    return label;
  }

  /**
   * Creates a label with line for a Kendo chart.
   * @param title Title part of the label.
   * @param duration Duration part of the label.
   * @param unit Unit part of the label.
   * @param date Date part of the label (as string).
   * @param padding Padding around label.
   * @param offsetY Distance between label and line.
   * @param plotArea The chart, gotten through e.sender.getPlotArea().backgroundVisual.chartElement.
   */
  public createDetailedLineLabel(data: PlotData) {
    const group = new Group();

    const { plotArea, title, duration, unit, date, padding, offsetY } = data;

    // Check if needed data is available
    if (!plotArea.axisX || !plotArea.axisX.dataRange || !plotArea.axisX.dataRange.displayStart) {
      return group;
    }

    // Creating line for platGraph
    const minEpoch = plotArea.axisX.dataRange.displayStart.getTime();
    const maxEpoch = plotArea.axisX.dataRange.displayEnd.getTime();
    const now = DateTime.now();

    // Check if now is inside the given period
    if (minEpoch > now.toMillis() || maxEpoch < now.toMillis()) {
      return group;
    }
    const line = this.createLine(now, plotArea, minEpoch, maxEpoch);

    // Finding startpoint for labels and setting default text options
    const x = line.bbox().origin.x + line.bbox().size.width;
    const y = line.bbox().origin.y + offsetY;
    const p = new Point(x, y);
    const textOptions = {
      fill: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      font: '14px "LFPressSans", Arial, Verdana, sans-serif',
    };

    // TitleLabel
    const titleL = new Text(title, p, textOptions);
    const titleLB = titleL.bbox();

    // DurationLabel
    const durationTO = { ...textOptions };
    durationTO.font = 'bold 18px "LFPressSans", Arial, Verdana, sans-serif';
    const durationL = new Text(' ' + duration + ' ', [0, 0], durationTO);
    const durationLB = durationL.bbox();

    // UnitLabel
    const unitTO = { ...textOptions };
    unitTO.font = '12px "LFPressSans", Arial, Verdana, sans-serif';
    const unitL = new Text(unit, p, unitTO);
    const dateL = new Text(date!, p, textOptions);
    const unitLB = unitL.bbox();

    /* Positioning of elements, relative to TitleLabel */
    // TitleLabel position
    titleL.position([x - (titleLB.size.width + durationLB.size.width + unitLB.size.width) / 2, y - titleLB.size.height]);
    const titleLP = titleL.position();

    // DurationLabel position
    const durationYO = -2;
    durationL.position([titleLP.getX() + titleLB.width(), titleLP.getY() + durationYO]);
    const durationLP = durationL.position();

    // UnitLabel position
    const unitYO = -durationYO + 2;
    unitL.position([durationLP.getX() + durationLB.width(), durationLP.getY() + unitYO]);

    // DateLabel & positioning
    const dateLB = dateL.bbox();
    dateL.position([
      titleLP.getX() + (titleLB.width() + durationLB.width() + unitLB.width()) / 2 - dateLB.width() / 2,
      y - dateLB.size.height + titleLB.height() + 5,
    ]);

    // Setting texts into group
    group.append(titleL, durationL, unitL, dateL);

    // Create backgournd box, based on the text-groups size and position
    const rb = group.bbox();
    const rect = new Rect2(
      new Rect([rb.topLeft().getX() - padding / 2, rb.topLeft().getY() - padding / 2], [rb.width() + padding, rb.height() + padding]),
      {
        fill: {
          color: 'rgba(222, 222, 222, 1)',
        },
        stroke: {
          color: 'none',
        },
      }
    );

    // Insert line and rect into group and return
    group.insert(0, line);
    group.insert(0, rect);
    return group;
  }

  public createBackgroundForLabel(label: Text, background: string, padding: number = 8, opacity: number = 1): Rect2 {
    const rb = label.bbox();
    const rect = new Rect2(
      new Rect([rb.topLeft().getX() - padding / 2, rb.topLeft().getY() - padding / 2], [rb.width() + padding, rb.height() + padding]),
      {
        fill: {
          color: background,
        },
        stroke: {
          color: 'none',
        },
        opacity,
      }
    );

    return rect;
  }

  /**
   * Creates a line for a Kendo chart.
   * Used for the render function on a Kendo chart.
   * @param date The date to create the line at
   * @param chart Kendo chartElement (found by calling e.sender.getPlotArea().backgroundVisual.chartElement)
   * @param totalMin The maximum value on the chart (epoch timestamp)
   * @param totalMax The minimum value on the chart (epoch timestamp)
   */

  public createLine(date: DateTime, chart: { axisX: any; axisY: any }, totalMin: number, totalMax: number) {
    const expandY = -3;
    const xAxis = chart.axisX;
    const yAxis = chart.axisY;
    const rangeInDays = (totalMax - totalMin) / this.msPerDay;
    const width = xAxis.box.x2 - xAxis.box.x1;
    const tick = width / rangeInDays;
    const timeOffset = (date.toMillis() - totalMin) / this.msPerDay;
    const tickOffset = tick * timeOffset;
    const absolutOffsetX = xAxis.box.x1 + tickOffset;

    const line = new Path({
      stroke: {
        color: 'rgba(0, 0, 0, 0.87)',
        width: 1,
      },
    });
    const from = new Point(absolutOffsetX, yAxis.box.y2);
    const to = new Point(absolutOffsetX, yAxis.box.y1 + expandY);
    line.moveTo(from).lineTo(to);
    return line;
  }

  /**
   * Creates a vertical line for a Kendo chart with label at top
   * Used for the render function on a Kendo chart.
   * @param date The date to create the line at
   * @param chart Kendo chartElement
   * @param valueAxis The ValueAxis
   * @param categoryAxis The CategoryAxis
   * @param lineColor The color for the line
   * @param lineWidth The Width for the line
   * @param lineText The text for the line label
   */
  public creatVerticalLineWithLabel(
    date: Date,
    valueAxis: ChartAxis,
    categoryAxis: ChartAxis,
    lineColor: string,
    lineWidth: number,
    lineText: string,
    yOffset: number
  ): Group {
    const group = new Group();
    const categorySlot = categoryAxis.slot(date);
    const valueRange = valueAxis.range();
    const valueSlot = valueAxis.slot(valueRange.min as any, valueRange.max as any);
    const path = new Path({
      stroke: {
        color: lineColor,
        width: lineWidth,
      },
    })
      .moveTo((categorySlot as any).origin.x + (categorySlot as any).size.width / 2, (valueSlot as any).origin.y)
      .lineTo((categorySlot as any).origin.x + (categorySlot as any).size.width / 2, (valueSlot as any).bottomRight().y);

    group.append(path, this.createLineLabel(lineText, path, yOffset));

    return group;
  }

  /**
   * Creates a line for a Kendo chart.
   * Used for the render function on a Kendo chart.
   * @param date The date to create the line at
   * @param axisX the categoryAxis element (found on chartElement.namedCategoryAxes.[NAME])
   * @param axisY the categoryAxis element (found on chartElement.namedValueAxes.[NAME])
   * @param totalMin The maximum value on the chart (epoch timestamp)
   * @param totalMax The minimum value on the chart (epoch timestamp)
   */
  public createLineInChartPane(date: DateTime, axisX: any, axisY: any, totalMin: number, totalMax: number) {
    const expandY = -3;

    const rangeInDays = (totalMax - totalMin) / this.msPerDay;
    const width = axisX.box.x2 - axisX.box.x1;
    const tick = width / rangeInDays;
    const timeOffset = (date.toMillis() - totalMin) / this.msPerDay;
    const tickOffset = tick * timeOffset;
    const absolutOffsetX = axisX.box.x1 + tickOffset;

    const line = new Path({
      stroke: {
        color: 'rgba(0, 0, 0, 0.6)',
        width: 1,
      },
    });
    const from = new Point(absolutOffsetX, axisY.box.y2);
    const to = new Point(absolutOffsetX, axisY.box.y1 + expandY);
    line.moveTo(from).lineTo(to);
    return line;
  }

  /**
   * Creates a vertical plotline for a Kendo chart.
   * @param from Start date of band
   * @param to End date of band
   * @param chart Kendo chartElement (found by calling e.sender.getPlotArea().backgroundVisual.chartElement)
   * @param color Background color, use string for rgb, rgba or color-name
   */
  public createVerticalPlotline(from: DateTime, to: DateTime, chart: any, color: string) {
    if (!chart.axisX || !chart.axisX.dataRange.displayStart) {
      return new Rect2(new Rect([0, 0], [0, 0]));
    }
    const xAxis = chart.axisX;
    const yAxis = chart.axisY;
    const totalMin = xAxis.dataRange.displayStart.getTime();
    const totalMax = xAxis.dataRange.displayEnd.getTime();
    const rangeInDays = (totalMax - totalMin) / this.msPerDay;
    const width = xAxis.box.x2 - xAxis.box.x1;
    const tick = width / rangeInDays;
    const offsetStart = xAxis.box.x1 + (tick * (from.toMillis() - totalMin)) / this.msPerDay;
    const offsetEnd = xAxis.box.x1 + (tick * (to.toMillis() - totalMin)) / this.msPerDay;
    const rect = new Rect2(new Rect([offsetStart, yAxis.box.y1], [offsetEnd - offsetStart, yAxis.box.y2 - yAxis.box.y1]), {
      fill: {
        color: color,
      },
      stroke: {
        color: 'none',
      },
    });

    return rect;
  }

  /**
   * Creates a horizontal plotline for a Kendo chart.
   * If no xFrom is given, the rectangle will stretch to the width of the chart.
   * @param xFrom Start date for the x-axis
   * @param yFrom Start value for the y-axis
   * @param yTo End value for the y-axis
   * @param chart Kendo chartElement (found by e.sender.getPlotArea().backgroundVisual.chartElement)
   * @param color Background color, use string for rgb, rgba or color-name
   */

  public createHorizontalPlotline(xFrom: DateTime | null, yFrom: number, yTo: number, chart: { axisX: any; axisY: any }, color: string) {
    const xAxis = chart.axisX;
    const yAxis = chart.axisY;

    // Calculate Y
    const yMax = Math.min(yAxis.totalMax, yAxis.options.max);
    const yMin = Math.max(yAxis.totalMin, yAxis.options.min);
    const range = yMax - yMin;
    const height = yAxis.box.y2 - yAxis.box.y1;
    const heightTick = height / range;
    const yStart = height - heightTick * yTo + yAxis.box.y1;
    const ySpan = heightTick * yTo - heightTick * yFrom;

    // Calculate X
    const xMin = xAxis.dataRange.displayStart ? xAxis.dataRange.displayStart.getTime() : xAxis.dataRange.options.min.getTime();
    const xMax = xAxis.dataRange.displayEnd ? xAxis.dataRange.displayEnd.getTime() : xAxis.dataRange.options.max.getTime();
    const rangeInDays = (xMax - xMin) / this.msPerDay;
    const width = xAxis.box.x2 - xAxis.box.x1;
    const widthTick = width / rangeInDays;

    // If no xFrom is given, the rectangle will begin from the leftmost side of the chart
    let xStart: number | null = null;
    let xFromDt = DateTime.fromISO(xFrom as unknown as string); // If we recieve an ISO, convert to DateTime
    if (xFromDt.isValid) {
      xStart = xFromDt ? xAxis.box.x1 + (widthTick * (xFromDt.toMillis() - xMin)) / this.msPerDay : xAxis.box.x1;
    } else {
      xStart = xFrom ? xAxis.box.x1 + (widthTick * (xFrom.toMillis() - xMin)) / this.msPerDay : xAxis.box.x1;
    }

    const xSpan: number = xAxis.box.x2 - xStart!;

    const rect = new Rect2(new Rect([xStart, yStart], [xSpan, ySpan]), {
      fill: {
        color: color,
      },
      stroke: {
        color: 'none',
      },
    });
    return rect;
  }

  /**
   * Creates a vertical plotline with label for a Kendo chart.
   * @param from Start date of band
   * @param to End date of band
   * @param chart Kendo chartElement (found by calling e.sender.getPlotArea().backgroundVisual.chartElement)
   * @param color Background color, use string for rgb, rgba or color-name
   * @param texts Texts used in the label
   */

  public createVerticalPlotlineWithText(
    from: DateTime,
    to: DateTime,
    chart: { axisX: { dataRange: { displayStart: any } } },
    color: string,
    texts: string[]
  ) {
    if (!chart.axisX || !chart.axisX.dataRange.displayStart) {
      return new Group();
    }
    const rect = this.createVerticalPlotline(from, to, chart, color);
    const textOptions = {
      fill: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      font: '14px "LFPressSans", Arial, Verdana, sans-serif',
    };
    const padding = 7;
    const textLables = texts.map((text, index) => {
      const x = rect.bbox().topLeft().getX();
      const y = rect.bbox().topLeft().getY();
      const position = [x + padding, y + index * 15 + padding];
      return new Text(text, position, textOptions);
    });

    const group = new Group();
    group.append(rect, ...textLables);

    return group;
  }

  /**
   * Creates a vertical plotline with label for a Kendo chart.
   * @param chart Kendo chartElement (found by calling e.sender.getPlotArea().backgroundVisual.chartElement)
   * @param elements Array of elements to be within borders
   * @returns Group with clipping mask including elements
   */
  public clipOutsideGraph(chart: any, elements: Element[]) {
    const clipPath = new Path();
    clipPath
      .moveTo(chart.axisX.box.x1, chart.axisY.box.y1)
      .lineTo(chart.axisX.box.x2, chart.axisY.box.y1)
      .lineTo(chart.axisX.box.x2, chart.axisY.box.y2)
      .lineTo(chart.axisX.box.x1, chart.axisY.box.y2)
      .close();
    const group = new Group({ clip: clipPath });
    group.append(...elements);
    return group;
  }

  private calculateNumberOfSteps(
    totalLength: number,
    screenSizeFactor = 2,
    lowerLimitX = 200,
    upperLimitX = 250,
    lowerLimitY = 10,
    upperLimitY = 60
  ) {
    if (totalLength <= lowerLimitX) {
      return lowerLimitY * screenSizeFactor;
    } else if (totalLength >= upperLimitX) {
      return upperLimitY * screenSizeFactor;
    } else {
      const x = totalLength;
      const a = (upperLimitY - lowerLimitY) / (upperLimitX - lowerLimitX);
      const b = lowerLimitY - a * lowerLimitX;
      const y = a * x + b;
      return parseInt(y.toString(), 10) * screenSizeFactor;
    }
  }
}
