import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Month } from '@app/core/enums/month.enum';
import { BlightInfectionDataInfo } from '@app/core/interfaces/blight-infection-data-info.interface';
import { NotificationService } from '@app/core/notification/notification.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { BlightStateService } from '../../blight-state.service';

@Component({
  selector: 'app-blight-date-picker',
  templateUrl: './blight-date-picker.component.html',
  styleUrls: ['./blight-date-picker.component.scss'],
})
export class BlightDatePickerComponent implements OnInit, OnDestroy {
  @Input() public blightInfectionDataInfo!: BlightInfectionDataInfo;
  public maxDate!: Date;
  public minDate!: Date;
  public startDate!: Date;
  private subscriptions = new Subscription();
  private currentDate = DateTime.now();

  constructor(
    private blightStateService: BlightStateService,
    private harvestYearStateService: HarvestYearStateService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.harvestYearStateService.harvestYear$.subscribe((harvestYear) => {
        // date range must make room for the selected year
        this.maxDate = DateTime.fromObject({ year: 2099, month: Month.December, day: 31 }).toJSDate();
        this.minDate = DateTime.fromObject({ year: 1900, month: Month.January, day: 1 }).toJSDate();

        // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
        const regulatedHarvestYear = this.regulatedHarvestYear(harvestYear);
        this.startDate = this.regulatedDefaultDate(regulatedHarvestYear).toJSDate();
        this.onDateSelect(this.startDate);

        if (harvestYear !== regulatedHarvestYear) {
          this.notificationService.showInfo('main.potatoBlight.datePicker.regulatedHarvestYear', 10000);
        }

        // delay is required for the calendar to display the selected date
        setTimeout(() => {
          if (this.regulatedMinDate(regulatedHarvestYear) < this.regulatedMaxDate(regulatedHarvestYear)) {
            this.maxDate = this.regulatedMaxDate(regulatedHarvestYear).toJSDate();
            this.minDate = this.regulatedMinDate(regulatedHarvestYear).toJSDate();
          }
        });
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onDateSelect(date: Date) {
    this.blightStateService.selectedBlightDate = date;
  }

  private regulatedHarvestYear(harvestYear: number) {
    if (harvestYear < this.blightInfectionDataInfo.minDate.year) {
      return this.blightInfectionDataInfo.minDate.year;
    }
    if (harvestYear > this.blightInfectionDataInfo.maxDate.year) {
      return this.blightInfectionDataInfo.maxDate.year;
    }
    return harvestYear;
  }

  private regulatedDefaultDate(regulatedHarvestYear: number) {
    let defaultDate = DateTime.fromObject({ year: regulatedHarvestYear, month: Month.June, day: 15 });

    if (this.harvestYearStateService.isEqualToCurrentHarvestYear(regulatedHarvestYear)) {
      defaultDate = DateTime.now();
    }
    if (this.currentDate < defaultDate) {
      defaultDate = this.currentDate;
    }
    if (defaultDate < this.blightInfectionDataInfo.minDate) {
      defaultDate = this.blightInfectionDataInfo.minDate;
    }
    if (defaultDate > this.blightInfectionDataInfo.maxDate) {
      defaultDate = this.blightInfectionDataInfo.maxDate;
    }
    return defaultDate;
  }

  private regulatedMinDate(regulatedHarvestYear: number): DateTime {
    let dt = DateTime.fromISO(this.blightInfectionDataInfo.minDate.toString());
    if (dt.year < regulatedHarvestYear) {
      return DateTime.fromObject({ year: regulatedHarvestYear, month: Month.January, day: 1 });
    }
    return dt;
  }

  private regulatedMaxDate(regulatedHarvestYear: number): DateTime {
    let dt = DateTime.fromISO(this.blightInfectionDataInfo.maxDate.toString());
    if (dt.year > regulatedHarvestYear) {
      return DateTime.fromObject({ year: regulatedHarvestYear, month: Month.December, day: 31 });
    }
    return dt;
  }
}
