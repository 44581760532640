import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { DateTime } from 'luxon';

export interface MetadataTaskDto {
  date: string;
  fieldYearId: number;
  operationTypeGroup: number;
  produceNormNumbers: string[];
  taskId: number;
}

export class MetadataTask {
  public appliedDate: DateTime;
  public fieldId: number;
  public operationTypeGroup: OperationTypeGroup;
  public produceNorms: ProduceNorm[];
  public taskId: number;

  constructor(dto: MetadataTaskDto, operationTypeGroup: OperationTypeGroup, produceNorms: ProduceNorm[]) {
    this.taskId = dto.taskId;
    this.appliedDate = DateTime.fromISO(dto.date);
    this.fieldId = dto.fieldYearId;
    this.operationTypeGroup = operationTypeGroup;
    this.produceNorms = produceNorms;
  }
}
