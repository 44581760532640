import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { HttpClient } from '@app/core/http/http-client';
import { AsAppliedOperationTypeGroupDto } from '@app/map/features/field-analysis/features/as-applied/as-applied-group.class';
import { ExecutedLocationCollectionDto } from '@app/map/features/field-analysis/features/as-applied/executed-task.class';
import { Observable, of } from 'rxjs';
import { VraPutOperationLineModelDto } from '../interfaces/vra-put-operation-line-mode.interface';
import { VraTaskDto } from '../interfaces/vra-task.interface';

export interface VraOperationTypeGroupDTO {
  id: OperationTypeGroupEnum;
  name: string;
  disabled: boolean;
  tasks: VraTaskDto[];
}

export interface VraOperationTypeGroupRepo {
  getVraOperationTypeGroupsWithFarmIds(farmIds: number[], harvestYear: number): Observable<VraOperationTypeGroupDTO[]>;

  getAppliedOperationTypeGroupsWithFarmIds(farmIds: number[], harvestYear: number): Observable<AsAppliedOperationTypeGroupDto[]>;

  getExecutedTaskById(farmIds: number[], harvestYear: number, id: number): Observable<ExecutedLocationCollectionDto>;

  deleteExecutedTask(farmIds: number[], harvestYear: number, id: number): Observable<unknown>;

  putVraTask(farmIds: number[], putOperationLineModels: VraPutOperationLineModelDto[]): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class VraOperationTypeGroupRepo implements VraOperationTypeGroupRepo {
  constructor(
    private httpClient: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public getVraOperationTypeGroupsWithFarmIds(farmIds: number[], harvestYear: number): Observable<VraOperationTypeGroupDTO[]> {
    const options = {
      withCredentials: true,
    };
    return this.httpClient.get<VraOperationTypeGroupDTO[]>(
      `${this.endpoints.bffApi}/farms/${farmIds.join(',')}/${harvestYear}/vratasks`,
      options
    );
  }

  public putVraTask(farmIds: number[], putOperationLineModels: VraPutOperationLineModelDto[]) {
    const options = {
      withCredentials: true,
    };

    return this.httpClient.put<void, VraPutOperationLineModelDto[]>(
      `${this.endpoints.bffApi}/farms/${farmIds.join(',')}/vratask`,
      putOperationLineModels,
      options
    );
  }

  public getAppliedOperationTypeGroupsWithFarmIds(farmIds: number[], harvestYear: number): Observable<AsAppliedOperationTypeGroupDto[]> {
    const options = {
      withCredentials: true,
    };
    return this.httpClient.get(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/executed/tasks`, options);
  }

  public getExecutedTaskById(farmIds: number[], harvestYear: number, id: number): Observable<ExecutedLocationCollectionDto> {
    const options = {
      withCredentials: true,
    };
    return this.httpClient.get(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/executed/tasks/${id}`, options);
  }

  public deleteExecutedTask(farmIds: number[], harvestYear: number, id: number) {
    const options = {
      withCredentials: true,
    };
    return this.httpClient.delete(`${this.endpoints.foApi}/farms/${farmIds.join(',')}/${harvestYear}/executed/tasks/${id}`, options);
  }
}
