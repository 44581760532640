<div class="component-selector">
  <kendo-dropdownlist
    [data]="componentItems$ | async"
    textField="text"
    valueField="value"
    [itemDisabled]="itemDisabled"
    [(ngModel)]="initialComponent"
    (valueChange)="onValueChange.emit($event)"
    [valuePrimitive]="true"
  >
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      {{ dataItem.text | translate }}
    </ng-template>
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      {{ dataItem?.text | translate }}
    </ng-template>
  </kendo-dropdownlist>
</div>
