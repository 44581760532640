import { Injectable } from '@angular/core';

import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { SubscriptionsValidatorService } from '@app/core/subcriptions-validator/subscriptions-validator.service';
import { Module } from '@app/core/types/module.type';
import { combineLatest, map, Observable, of } from 'rxjs';
import { Policy } from '../policies';
import { RESOURCE_POLICIES } from '../resource-policies';
import { Resource, RESOURCES } from '../resources';

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  // user 'roles'
  private _isFree$ = this.subService.isFree$;
  private _isBasic$ = this.subService.isCropManagerBasic$;
  private _isPremium$ = this.subService.isCropManagerPremium$;
  private _isNaesgaard$ = this.authService.isCurrentUserFromNaesgaard$;

  // addons
  private _hasVra$ = this.subService.isVraSubscriber$;
  private _hasAlertsPack$ = this.subService.isAlertsPackSubscriber$;

  constructor(
    private authService: BffAuthService,
    private subService: SubscriptionsValidatorService
  ) {}

  /**
   * Observable that emits the list of policies the current user has access to.
   */
  public policies$: Observable<readonly Policy[]> = combineLatest([
    this._isFree$,
    this._isBasic$,
    this._isPremium$,
    this._isNaesgaard$,
    this._hasVra$,
    this._hasAlertsPack$,
  ]).pipe(
    map(([isFree, isBasic, isPremium, isNaesgaard, hasVra, hasAlertsPack]): Policy[] => {
      const policies: Policy[] = [];

      /**
       * This is the logic that maps the user's subscriptions and addons to the list of policies.
       * The hierarchical order of subscriptions is handled here;
       * If the user has a premium subscription, they also have access to basic and free resources.
       * If the user has a basic subscription, they also have access to free resources.
       * If the user has a free subscription, they have access to free resources.
       * ! Consider making this like policy builder in .NET
       */
      if (isPremium) policies.push('free', 'basic', 'premium');
      if (isBasic) policies.push('free', 'basic');
      if (isFree && !isNaesgaard) policies.push('free');
      if (isNaesgaard) policies.push('naesgaard');
      if (hasVra) policies.push('vra');
      if (hasAlertsPack) policies.push('alerts_pack');

      return policies;
    })
  );

  /**
   * Observable that emits the list of resources the current user has access to.
   */
  public resources$: Observable<readonly Resource[]> = this.policies$.pipe(
    map((userPolicies) =>
      Object.entries(RESOURCE_POLICIES)
        // map to each resource the user has access to
        .map(([resource, policies]) => (policies.some((x) => userPolicies.includes(x)) ? resource : null))
        .filter((x): x is Resource => x !== null)
    )
  );

  /**
   * Observable that emits the list of modules the current user has access to.
   *
   * If the user does not have access to any resources in a module, the module is not included in the list.
   */
  public modules$: Observable<readonly Module[]> = this.resources$.pipe(
    map((userResources) =>
      Object.entries(RESOURCES)
        .map(([module, resources]) => (resources.some((x) => userResources.includes(x)) ? module : null))
        .filter((x): x is Module => x !== null)
    )
  );

  /**
    Checks if the current user has access to the given resource or resources.
    @param resources One or more resources to check access for. If null or empty, access is always granted.
    @param operator Operator to use when checking access to multiple resources. If 'EVERY' is passed, the user must have access to all resources. If 'ANY' is passed, the user only needs access to one of the resources. Defaults to 'EVERY'.
    @returns Observable that emits true if access is granted, false otherwise.
  */
  public hasAccessTo(resources: Resource | Resource[] | undefined | null, operator: 'EVERY' | 'ANY' = 'EVERY'): Observable<boolean> {
    // If null or undefined is passed, access is assumed
    if (!resources) {
      return of(true);
    }

    // If an empty list is passed, access is assumed
    if (Array.isArray(resources) && resources.length === 0) {
      return of(true);
    }

    // Otherwise, we check if the user has access to the resources
    return this.resources$.pipe(
      map((userResources) => {
        if (Array.isArray(resources)) {
          // If a list of resources is passed, we check if the user has access to all of them or any of them based on the operator
          if (operator === 'EVERY') {
            return resources.every((resource) => userResources.includes(resource));
          } else {
            return resources.some((resource) => userResources.includes(resource));
          }
        } else {
          // If a single resource is passed, we check if the user has access to it
          return userResources.includes(resources);
        }
      })
    );
  }
}
