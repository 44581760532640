import { Injectable } from '@angular/core';
import { IWindow } from '@app/core/interfaces/window.interface';
import keys from 'lodash-es/keys';

export interface IWindowRefService {
  nativeWindow: IWindow;
}

function getWindow(shouldDecorate: boolean): any {
  const w = window as any as IWindow;

  if (shouldDecorate && w.config) {
    const copy: { [prop: string]: any } = { ...w.config };

    keys(w.config)
      .filter((key) => ['env'].indexOf(key) === -1) // Whitelist some keys...
      .forEach((key) =>
        Object.defineProperty(w.config, key, {
          get: function () {
            return copy[key];
          },
        })
      );
  }

  return w;
}

@Injectable({
  providedIn: 'root',
})
export class WindowRefService implements IWindowRefService {
  private shouldDecorate = true;

  public get nativeWindow(): IWindow {
    const window = getWindow(this.shouldDecorate);

    this.shouldDecorate = false;

    return window;
  }

  public get localStorage() {
    return this.nativeWindow.localStorage;
  }

  public get sessionStorage() {
    return this.nativeWindow.sessionStorage;
  }

  public get location() {
    return this.nativeWindow.location;
  }
}
