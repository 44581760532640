<app-spinner *ngIf="loading$ | async" [hasBackdrop]="true"></app-spinner>
<app-side-drawer-wrapper>
  <app-side-drawer-header
    [cancelIconName]="'back'"
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
    [showMinimizeButton]="true"
    [showInfoHoverButton]="true"
    [title]="'main.yieldPrognosis.potatoBlightTitle' | translate"
  >
    <app-icon icon="info_outline" (click)="toggleInfo()"></app-icon>
  </app-side-drawer-header>
  <app-side-drawer-body>
    <app-blight-date-picker *ngIf="!!blightInfectionDataInfo" [blightInfectionDataInfo]="blightInfectionDataInfo"></app-blight-date-picker>
    <app-blight-field-info [blightInfoForField$]="blightInfoForField$"></app-blight-field-info>
  </app-side-drawer-body>
</app-side-drawer-wrapper>

<app-ol-cell-hover [mapLayerId]="hoverMapLayerId" [zoomEditThreshold]="0">
  <div *olCellFeature="let feature" class="tooltip-container">
    <div>
      <p>{{ 'main.potatoBlight.fieldInfo.infectionPressure' | translate }}</p>
      <p>{{ feature.get('pressure') }}</p>
    </div>
  </div>
</app-ol-cell-hover>

<app-ol-map-feature-select (field)="onFieldSelect($event)" [layers]="selectableMapLayers"> </app-ol-map-feature-select>

<app-legend [hidden]="hideLegend$ | async" [sideDrawerWidth]="(sideDrawerWidth$ | async)!">
  <app-blight-legend></app-blight-legend>
</app-legend>
