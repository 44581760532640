import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeedbackSideDrawerContentComponent } from './feedback-side-drawer-content.component';
import { FeedbackModule } from './feedback/feedback.module';

@NgModule({
  declarations: [FeedbackSideDrawerContentComponent],
  imports: [CommonModule, FeedbackModule],
  exports: [FeedbackSideDrawerContentComponent],
})
export class FeedbackSideDrawerContentModule {}
