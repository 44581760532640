import { Injectable } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { LanguageService } from '@app/core/language/language.service';
import { NdviService } from '@app/map/features/field-analysis/features/ndvi-feature/service/ndvi.service';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsAppliedService } from './as-applied/as-applied.service';
import { SoilSampleFeatureService } from './soil-sample-feature/soil-sample-feature.service';

@Injectable()
export class FieldAnalysisFeaturesService {
  constructor(
    private ndviService: NdviService,
    private soilSamplesMapFeatureService: SoilSampleFeatureService,
    private languageService: LanguageService,
    private accessControlService: AccessControlService
  ) {}

  /**
   * Get cover flow items for field analysis
   * @param hiddenItems Layers that should not be included in the cover flow items
   * @returns Observable of cover flow items
   */
  public getCoverFlowLayersItems(hiddenItems?: MapLayerId[]): Observable<MapCoverFlowItem[]> {
    // Combine observables to get cover flow items from multiple services
    return combineLatest([
      // Check access and get as-applied cover flow item
      this.accessControlService
        .hasAccessTo('field_analysis_as_applied')
        .pipe(
          map((hasAccess) =>
            AsAppliedService.getAsAppliedCoverFlowItem(this.languageService.getText('main.fieldmap.layers.asApplied'), !hasAccess)
          )
        ),
      // Check access and get ISOXML cover flow item
      this.accessControlService.hasAccessTo('field_analysis_isoxml').pipe(map((hasAccess) => this.getISOMapCoverFlowItem(!hasAccess))),
      // Check access and get NDVI map cover flow item
      this.accessControlService
        .hasAccessTo('field_analysis_biomass')
        .pipe(map((hasAccess) => this.ndviService.getNdviMapCoverFlowItem(!hasAccess))),
      // Check access and get soil samples map cover flow item
      this.accessControlService
        .hasAccessTo('field_analysis_soil_samples')
        .pipe(map((hasAccess) => this.soilSamplesMapFeatureService.getSoilSamplesMapCoverFlowItem(!hasAccess))),
      // Check access and get drone image import map cover flow item
      this.accessControlService
        .hasAccessTo('field_analysis_drone_images')
        .pipe(map((hasAccess) => this.getDroneImageImportMapCoverFlowItem(!hasAccess))),
    ]).pipe(
      // Filter out null or hidden items
      map((coverFlowLayersItems) =>
        coverFlowLayersItems.filter(
          (item): item is MapCoverFlowItem => !!item.mapCoverFlowLayersId && !hiddenItems?.includes(item.mapCoverFlowLayersId)
        )
      )
    );
  }

  public findMapCoverFlowIndex(mapCoverFlowItem: MapCoverFlowItem, mapCoverFlowItemList: MapCoverFlowItem[]) {
    return mapCoverFlowItem.isVisible
      ? mapCoverFlowItemList.findIndex((mapCoverFlowItm) => mapCoverFlowItm.mapCoverFlowLayersId === mapCoverFlowItem.mapCoverFlowLayersId)
      : null;
  }

  public getISOMapCoverFlowItem(isDisabled: boolean = false) {
    return new MapCoverFlowItem({
      mapCoverFlowLayersId: MapLayerId.ISOXML,
      displayName: 'main.fieldmap.layers.isoXml',
      name: 'isoxml',
      isVisible: true,
      isDisabled,
      layers: [],
    });
  }

  public getDroneImageImportMapCoverFlowItem(isDisabled: boolean = false) {
    return new MapCoverFlowItem({
      mapCoverFlowLayersId: MapLayerId.DRONE_IMAGE_IMPORT,
      displayName: 'main.fieldmap.layers.droneImageImport',
      name: 'droneImage',
      isVisible: true,
      isDisabled,
      layers: [
        {
          layerId: MapLayerId.DRONE_IMAGE_IMPORT,
          layerType: MapLayerType.TILE,
          zIndex: 2,
          useAuthHeaders: true,
          isVisible: true,
        },
      ],
    });
  }
}
