import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ProductInfoDialogComponent } from './product-info-dialog/product-info-dialog.component';
import { SubscriptionsDialogGenericComponent } from './subscriptions-dialog-confirm/subscriptions-dialog-generic.component';
import { SubscriptionsDialogComponent } from './subscriptions-dialog/subscriptions-dialog.component';
import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';

@NgModule({
  providers: [],
  declarations: [SubscriptionsDialogComponent, SubscriptionsDialogGenericComponent, ProductInfoDialogComponent, TermsDialogComponent],
  imports: [SharedModule],
})
export class SubscriptionsModule {}
