import { Injectable, OnDestroy } from '@angular/core';
import { DirtyCheckService } from '@app/core/dirty-check/dirty-check.service';
import { DroneImageFormData } from '@app/core/drone-image/drone-image-form-data.interface';
import { DroneImageUploadState } from '@app/core/drone-image/drone-image-upload-state';
import { ImageFeature } from '@app/core/drone-image/image-feature';
import { ProjectFeatureDto } from '@app/core/drone-image/project-feature-dto';
import { SolviService } from '@app/core/drone-image/solvi.service';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { ExifService } from '@app/core/exif/exif.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { MapService } from '@app/core/map/map.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { FileExtensionHelper } from '@app/helpers/file/file-extension-helper';
import { DroneImageUploadDialogComponent } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-upload-dialog/drone-image-upload-dialog.component';
import { DroneImageUploadProgressDialogComponent } from '@app/map/features/field-analysis/features/drone-image-import/drone-image-upload-progress-dialog/drone-image-upload-progress-dialog.component';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DirtyCheckDialogAction } from '@app/shared/dialog/dirty-check-dialog/dirty-check-actions.class';
import { HarvestYearPickerMapService } from '@app/shared/harvest-year/harvest-year-picker-map/harvest-year-picker-map.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, concat, forkJoin, from, NEVER, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import Feature from 'ol/Feature';

@Injectable()
export class DroneImageImportLogicService implements OnDestroy {
  private readonly MODULE_NAME = 'droneUpload';

  private _selectedFiles = new BehaviorSubject<File[]>([]);
  private _selectedFarm = new ReplaySubject<number>(1);
  private _fieldFeatures: Feature[] = [];
  private _droneFieldFeatures: Feature[] = [];
  private _selectedHarvestYear = new ReplaySubject<number>(1);
  private _loading = new BehaviorSubject<boolean>(false);
  private _fieldsWithImages = new ReplaySubject<ImageFeature[] | null>(1);
  private subscriptions = new Subscription();
  private _selectableFarms$ = this.farmStateService.selectedFarms$;
  private _selectedImageFeatures = new BehaviorSubject<ImageFeature[]>([]);
  private _imageError = new ReplaySubject<string | null>(1);
  // Total ha. Each fields ha is rounded up to nearest integer.
  public selectedHa$ = this._selectedImageFeatures.pipe(
    map((imageFeatures) => imageFeatures.reduce((sum, element) => (sum += Math.ceil(element.area)), 0))
  );
  public readonly ALLOWED_FILE_TYPES = ['jpg', 'tiff'];

  private get map() {
    return this.mapService.getMap();
  }

  constructor(
    private exifService: ExifService,
    private solviService: SolviService,
    private harvestYearPickerMapService: HarvestYearPickerMapService,
    private farmStateService: FarmStateService,
    private dirtyCheckService: DirtyCheckService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private mapService: MapService
  ) {
    this.selectedHarvestYear = this.harvestYearPickerMapService.getCurrentHarvestYear();
    this.farmStateService.selectedFarms$.pipe(first()).subscribe((farms) => (this.selectedFarm = farms[0].id));
    this.subscriptions.add(this.updateImagesOnChanges());
  }

  private updateImagesOnChanges() {
    return combineLatest([this._selectedFiles, this._selectedFarm, this._selectedHarvestYear])
      .pipe(
        filter(([files, farmId, harvestYear]) => files?.length > 0 && !!farmId && !!harvestYear),
        tap(() => {
          this._loading.next(true);
          this._selectedImageFeatures.next([]);
          this._fieldsWithImages.next([]);
        }),
        switchMap(([files, farmId, harvestYear]) =>
          this.getFieldsFromImages(files, farmId, harvestYear).pipe(finalize(() => this._loading.next(false)))
        )
      )
      .subscribe((fieldsWithImages) => this._fieldsWithImages.next(fieldsWithImages));
  }

  public ngOnDestroy(): void {
    this.dirtyCheckService.setIsFeatureDirty(this, false);
    this.subscriptions.unsubscribe();
  }

  public get selectableHarvestYears$(): Observable<number[]> {
    const year = this.harvestYearPickerMapService.getCurrentHarvestYear();
    return of([year + 1, year, year - 1, year - 2, year - 3, year - 4, year - 5]);
  }

  public get dirty() {
    return this.dirtyCheckService.isAppDirty;
  }

  public get imageError$() {
    return this._imageError.asObservable();
  }

  public set dirty(dirty: boolean) {
    this.dirtyCheckService.setIsFeatureDirty(this, dirty, this.MODULE_NAME);
  }

  public get selectedImageFeatures$(): Observable<ImageFeature[]> {
    return this._selectedImageFeatures.asObservable();
  }

  public set selectedImageFeatures(imageFeatures: ImageFeature[]) {
    this._selectedImageFeatures.next(imageFeatures);
  }

  public get selectableFarms$(): Observable<Farm[]> {
    return this._selectableFarms$;
  }

  public get selectedFiles$(): Observable<File[]> {
    return this._selectedFiles.asObservable();
  }

  public set selectedFiles(files: File[]) {
    if (!FileExtensionHelper.validateFileExtensions(files, this.ALLOWED_FILE_TYPES)) {
      this._selectedFiles.next([]);
      this._imageError.next(this.translateService.instant('main.fieldmap.droneImageImport.invalidFileTypeError'));
    } else if (!FileExtensionHelper.areExtensionsIdentical(files)) {
      this._selectedFiles.next([]);
      this._imageError.next(this.translateService.instant('main.fieldmap.droneImageImport.notIdenticalFileTypes'));
    } else {
      this._selectedFiles.next(files);

      this._imageError.next(null);
    }
  }

  public set selectedFarm(farmId: number) {
    this._selectedFarm.next(farmId);
  }

  public get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  public get selectedHarvestYear$(): Observable<number> {
    return this._selectedHarvestYear.asObservable();
  }

  public set selectedHarvestYear(harvestYear: number) {
    this._selectedHarvestYear.next(harvestYear);
  }

  public get fieldsWithImages$(): Observable<ImageFeature[] | null> {
    return this._fieldsWithImages.asObservable();
  }

  public openDirtyCheckDialog(): Observable<DirtyCheckDialogAction | undefined> {
    return this.dialogService.openDirtyCheckDialog();
  }

  public getFieldsFromImages(images: File[], farmId: number, harvestYear: number): Observable<ImageFeature[] | null> {
    return this.exifService.getGeometryMetaData(images).pipe(
      first(),
      switchMap((metaData) => {
        return this.solviService.getFieldsFromImageMetaData(farmId, harvestYear, metaData);
      }),
      catchError((error) => {
        if (error.fileWithoutGPS) {
          this._imageError.next(
            this.translateService.instant('main.fieldmap.droneImageImport.noGpsDataOnFile', { fileName: error.fileWithoutGPS })
          );
        } else if (error.invalidGpsDataOnFile) {
          this._imageError.next(
            this.translateService.instant('main.fieldmap.droneImageImport.invalidGpsDataOnFile', { fileName: error.invalidGpsDataOnFile })
          );
        } else {
          this._imageError.next(this.translateService.instant('main.fieldmap.droneImageImport.fieldsFromImagesError'));
        }
        return [];
      })
    );
  }

  public upload(formData: DroneImageFormData): Observable<[unknown]> {
    const uploadState = new DroneImageUploadState();
    return this.selectedImageFeatures$.pipe(
      first(),
      withLatestFrom(this.selectedFiles$),
      // Inform the user if no images or no image features are selected
      map(([selectedImageFeatures, selectedFiles]) => {
        if (selectedFiles.length === 0) {
          this._imageError.next(this.translateService.instant('main.fieldmap.droneImageImport.noImagesSelected'));
        } else if (selectedImageFeatures.length === 0) {
          this._imageError.next(this.translateService.instant('main.fieldmap.droneImageImport.noImageFeaturesSelected'));
        }
        return selectedImageFeatures;
      }),
      filter((selectedImageFeatures) => selectedImageFeatures?.length > 0),
      switchMap((selectedImageFeatures) => {
        return this.openConfirmDialog().pipe(
          switchMap(() =>
            this.solviService
              .createProjects(
                formData,
                selectedImageFeatures.map((i) => i.featureId)
              )
              .pipe(
                withLatestFrom(this.selectedFiles$),
                switchMap(([projectFeatures, files]) => {
                  if (!projectFeatures) return NEVER;

                  return this.uploadProjects(projectFeatures, files, selectedImageFeatures, formData.farmId, uploadState);
                }),
                takeUntil(this.openLoadingDialog(uploadState))
              )
          )
        );
      }),
      finalize(() => uploadState.completeUpload())
    );
  }

  private openConfirmDialog(): Observable<any | undefined> {
    return this.dialogService
      .openCustomDialog(DroneImageUploadDialogComponent, {
        maxWidth: '600px',
        disableClose: true,
        panelClass: 'drone-image-upload',
      })
      .afterClosed()
      .pipe(filter((denied) => !!denied));
  }

  private getFilesForImageFeature(files: File[], imageFeatures: ImageFeature[], featureId: number) {
    const feature = imageFeatures.find((imageFeature) => imageFeature.featureId === featureId);
    const imagesForField = feature?.imagesForField;

    return files.filter((file) => imagesForField?.find((image) => image.fileName === file.name));
  }

  private uploadProjects(
    projectFeatures: ProjectFeatureDto[],
    files: File[],
    selectedImageFeatures: ImageFeature[],
    farmId: number,
    uploadState: DroneImageUploadState
  ) {
    uploadState.totalFields = projectFeatures.length;
    uploadState.totalImages = selectedImageFeatures.reduce((sum, imageFeature) => (sum += imageFeature.imagesForField.length), 0);
    return forkJoin([
      concat(
        ...projectFeatures.map((featureProject) => this.uploadProject(farmId, featureProject, files, selectedImageFeatures, uploadState))
      ),
    ]);
  }

  private uploadProject(
    farmId: number,
    featureProject: ProjectFeatureDto,
    files: File[],
    selectedImageFeatures: ImageFeature[],
    uploadState: DroneImageUploadState
  ) {
    return this.solviService.beginUpload(farmId, featureProject.projectId).pipe(
      switchMap((uploadInfo) => {
        return forkJoin([
          this.solviService
            .uploadFiles(this.getFilesForImageFeature(files, selectedImageFeatures, featureProject.featureId), uploadInfo)
            .pipe(tap(() => uploadState.onImageUploaded())),
        ]);
      }),
      switchMap(() => this.solviService.completeUpload(farmId, featureProject.projectId).pipe(tap(() => uploadState.onFieldUploaded())))
    );
  }

  private openLoadingDialog(uploadState: DroneImageUploadState) {
    return this.dialogService
      .openCustomDialog(DroneImageUploadProgressDialogComponent, {
        maxWidth: '600px',
        disableClose: true,
        panelClass: 'drone-image-upload',
        data: uploadState,
      })
      .afterClosed();
  }

  private resetSelection() {
    this.dirty = false;
    this.selectedImageFeatures = [];
    this.selectedFiles = [];
  }

  public onSuccessfulUpload() {
    this.resetSelection();
    this.notificationService.showSuccess('main.fieldmap.droneImageImport.uploadSuccess', 10000);
  }

  public onFailedUpload() {
    this.notificationService.showError('main.fieldmap.droneImageImport.uploadFail');
  }

  // Map interactions

  public addFeaturesToMap(droneImageFeatureIds: number[]) {
    this._fieldFeatures = this.map.getFeaturesFromLayer(MapLayerId.FIELDS);
    this._droneFieldFeatures = this._fieldFeatures.filter((feature) => droneImageFeatureIds.includes(feature.get('field').featureId));

    if (this._droneFieldFeatures.length > 0) {
      const layer = {
        clustered: true,
        isVisible: true,
        layerId: MapLayerId.DRONE_IMAGE_IMPORT,
        layerType: MapLayerType.VECTOR,
        zIndex: 5,
      };
      this._droneFieldFeatures.forEach((droneImageFeature) => {
        droneImageFeature.set('layerId', MapLayerId.DRONE_IMAGE_IMPORT);
      });

      this.map.addOrUpdateLayerToMap(layer, this._droneFieldFeatures);

      const fieldLayer = {
        clustered: true,
        isVisible: true,
        layerId: MapLayerId.FIELDS,
        layerType: MapLayerType.VECTOR,
        zIndex: 3,
      };

      this.map.addOrUpdateLayerToMap(fieldLayer, this._fieldFeatures);
    }
  }

  public resetDroneFeaturesToFields() {
    if (this._droneFieldFeatures.length > 0) {
      this._droneFieldFeatures.forEach((droneImageFeature) => {
        droneImageFeature.set('layerId', MapLayerId.FIELDS);
      });
      this.map.deselectFeatures();
    }
  }

  /*
  ! super hacky function, should be replaced at refactoring.
  ! in the case that drone-drawer is closed while fields are hydrated with drone images...
  ! after 500 ms make sure that the features set to drone image import fields are reset to normal fields.¨
  ! only aesthetic so fields do not appear blue under other views...
  */

  public resetDroneFeaturesToFieldsOnDestroy() {
    if (this._droneFieldFeatures.length > 0) {
      setTimeout(() => {
        this._droneFieldFeatures.forEach((droneImageFeature) => {
          droneImageFeature.set('layerId', MapLayerId.FIELDS);
        });
      }, 500);
    }
  }
}
