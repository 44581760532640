import { Component, OnDestroy } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapService } from '@app/core/map/map.service';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { FieldAnalysisSideDrawerService } from '../../field-analysis-side-drawer/field-analysis-side-drawer.service';

@Component({
  selector: 'app-soil-sample-feature',
  templateUrl: './soil-sample-feature.component.html',
  styleUrls: ['./soil-sample-feature.component.scss'],
})
export class SoilSampleMapFeatureComponent implements OnDestroy {
  public sideDrawerWidth$ = this.fieldAnalysisSideDrawerService.drawerWidth$;

  public get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }

  constructor(
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    private mapService: MapService
  ) {}

  public ngOnDestroy(): void {
    this.map.deselectFeatures();
    this.map.removeLayerFromMap(MapLayerId.SOILSAMPLES);
    this.map.forceRerender();
  }
}
