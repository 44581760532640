import { Filterable } from '@app/new-map/features/cultivation-journal/filter/filterable';
import { ScopeItem } from '@app/new-map/features/cultivation-journal/filter/scope-item';
import { FilterHelper } from '@app/new-map/features/cultivation-journal/filter/util/filter-helper';
import { ColumnKey } from './column/column-key';

export class FilterItem<T> {
  public title;
  // If no scope is set, the scope is considered global
  public scope: ScopeItem[];
  public columnKey: ColumnKey;
  public value: Filterable<T>;
  // Used to identify the item, usually an id.
  public identifier: any;

  constructor(columnKey: ColumnKey, value: Filterable<T>, scope: ScopeItem[] = []) {
    this.title = value.getTitle(columnKey);
    this.scope = scope;
    this.columnKey = columnKey;
    this.value = value;
    this.identifier = value.getIdentifier(columnKey);
  }

  public isEqual(filterItem: FilterItem<T>) {
    return FilterHelper.isEqual(filterItem, this.columnKey, this.identifier);
  }
}
