import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { HotspotSubType } from '@app/core/interfaces/hotspot-sub-type-interface';
import { HotspotType } from '@app/core/interfaces/hotspot-type-interface';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { AssignHotspotGroupRelation } from '../../shape-file-import/interfaces/assign-hotspot-group-relation.interface';

export interface IHotspotRepo {
  /**
   * Gets hotspot changes for supplied farmsIds from the supplied date
   */
  get(farmIds: number[], from: DateTime): Observable<HotspotDto[] | null>;

  /**
   * Update hotspot
   */
  update(hotspot: HotspotDto): Observable<HotspotDto | null>;

  /**
   * Delete hotspot from the supplied Hotspot id
   */
  delete(id: number): Observable<number | null>;

  /**
   * Gets hotspot type changes from the supplied date
   */
  getTypes(from: DateTime): Observable<HotspotType[] | null>;

  /**
   * Gets all hotspot sub types
   */
  getSubTypes(): Observable<HotspotSubType[] | null>;

  /**
   * Add an image to hotspot
   */
  addHotspotImage(file: any, hotspotId: number): Observable<string[] | null>;

  /**
   * Delete hotspot image
   */
  deleteHotspotImage(url: string): Observable<number | null>;
  /**
   * Update hotspotgroups
   */
  postHotspotGroupRelations(assignHotspotGroupRelation: AssignHotspotGroupRelation): Observable<void | null>;
}

@Injectable()
export class HotspotRepo implements IHotspotRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  /**
   * Gets hotspots on farmtracking api for given farmids
   * @param farmIds id's of farms to get hotspots for
   */
  public get(farmIds: number[]): Observable<HotspotDto[]> {
    const farmIdsString = '?' + farmIds.map((id) => `farmIds=${id}`).join('&');
    return this.http.get<HotspotDto[]>(this.endpoints.ftApi + '/hotspots' + farmIdsString, {});
  }

  public getById(hotspotId: number): Observable<HotspotDto> {
    return this.http.get<HotspotDto>(this.endpoints.ftApi + '/hotspots/' + hotspotId, {});
  }

  public post(hotspot: HotspotDto): Observable<HotspotDto | null> {
    const options: any = {};

    // HACK: this is to map a 0 to a null, because the back end wants a null value when no notificationRange
    hotspot.notificationRangeType = hotspot.notificationRangeType === 0 ? null : hotspot.notificationRangeType;

    return this.http.post<HotspotDto, HotspotDto>(`${this.endpoints.ftApi}/hotspots`, hotspot, options);
  }

  /**
   * Update hotspot
   * @param hotspot: Hotspot entity to be updated
   */
  public update(hotspot: HotspotDto): Observable<HotspotDto | null> {
    const options: any = {
      /* */
    };

    // dto obj is needed since id and img urls must be removed, as they cannot be patched on server.
    // To avoid side effect a new object is created
    const dtoHotspot: HotspotDto = { ...hotspot };

    // Remove non-patchable properties
    delete dtoHotspot.id;
    delete dtoHotspot.imageUrls;

    // HACK: this is to map a 0 to a null, because the back end wants a null value when no notificationRange
    dtoHotspot.notificationRangeType = dtoHotspot.notificationRangeType === 0 ? null : dtoHotspot.notificationRangeType;

    return this.http.patch<HotspotDto, HotspotDto>(`${this.endpoints.ftApi}/hotspots/${hotspot.id}`, dtoHotspot, options);
  }

  /**
   * Delete Hotspot
   * @param id: Id for Hotspot to delete
   */
  public delete(id: number): Observable<number | null> {
    const options: any = {
      /* */
    };

    return this.http.delete(`${this.endpoints.ftApi}/hotspots/${id}`, options);
  }

  /**
   * Get hotspot types since a specified date
   */
  public getTypes(from: DateTime): Observable<HotspotType[]> {
    const fromString = from.setZone('utc').toISO();
    return this.http.get<HotspotType[]>(this.endpoints.ftApi + '/hotspottypes?since=' + fromString, {});
  }

  /**
   * Gets hotspot subtypes since a specified date
   */
  public getSubTypes(): Observable<HotspotSubType[]> {
    return this.http.get<HotspotSubType[]>(this.endpoints.ftApi + '/hotspotsubtypes', {});
  }

  /**
   * Add an image to a hotspot
   * @param formData: FormData containing image-data and hotspotId
   */
  public addHotspotImage(file: File | null, hotspotId?: number): Observable<string[] | null> {
    const options: any = { withCredentials: true };
    const formData = new FormData();
    formData.append('file', file!);
    if (hotspotId) formData.append('hotspotId', hotspotId.toString());

    return this.http.post<string[], FormData>(`${this.endpoints.ftApi}/images/`, formData, options);
  }

  /**
   * Delete hotspot image
   * @param url: Image url
   */
  public deleteHotspotImage(url: string): Observable<number | null> {
    const options: any = { withCredentials: true };

    return this.http.delete(url, options);
  }

  /**
   * Updates hotspot groups
   */
  public postHotspotGroupRelations(assignHotspotGroupRelation: AssignHotspotGroupRelation): Observable<void | null> {
    return this.http.post(`${this.endpoints.ftApi}/farms/HotspotGroups/assign`, assignHotspotGroupRelation);
  }

  /**
   * Update the groups that this hotspot is included in
   * @param hotspotId id of the hotspot
   * @param relations list of groups that this hotspot is included in
   */
  public postSingleHotspotGroupRelations(farmId: number, hotspotId: number, groupIds: number[]): Observable<HotspotDto | null> {
    return this.http.post(`${this.endpoints.ftApi}/farms/${farmId}/HotspotGroups/relations/set?hotspotId=${hotspotId}`, groupIds);
  }
}
