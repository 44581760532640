<app-dialog-header size="lg" [title]="'main.potatoBlight.table.info.header' | translate" (close)="close()"> </app-dialog-header>

<app-dialog-content size="lg">
  <p class="text">
    {{ 'main.potatoBlight.table.info.paragraph1' | translate }}
  </p>
  <p class="text">
    {{ 'main.potatoBlight.table.info.paragraph2' | translate }}
  </p>
  <p class="text">
    {{ 'main.potatoBlight.table.info.paragraph3' | translate }}
  </p>
  <p class="text">
    {{ 'main.potatoBlight.table.info.paragraph4' | translate }}
  </p>
  <p class="text">
    {{ 'main.potatoBlight.table.info.paragraph5' | translate }}
  </p>
  <p class="text">
    {{ 'main.potatoBlight.table.info.paragraph6' | translate }}
    <a href="{{ 'main.potatoBlight.table.info.link' | translate }}" target="_blank" rel="noopener noreferrer">{{
      'main.potatoBlight.table.info.linkText' | translate
    }}</a>
  </p>
  <p class="text cursive">
    {{ 'main.potatoBlight.table.info.paragraph7' | translate }}
  </p>
</app-dialog-content>
