<app-prognosis-picker
  *appSideIf="(shownComponent$ | async)!; index: components.PrognosisPicker; minWidth: '300px'"
  (select)="onPrognosisSelect($event)"
></app-prognosis-picker>
<app-growth-regulation
  *appSideIf="(shownComponent$ | async)!; index: components.GrowthRegulation; minWidth: '300px'"
  [prognosisFeature]="selectedPrognosisFeature"
></app-growth-regulation>
<app-corn-harvest-prognosis
  *appSideIf="(shownComponent$ | async)!; index: components.CornHarvest; minWidth: '300px'"
></app-corn-harvest-prognosis>
<app-insurance-side-drawer
  *appSideIf="(shownComponent$ | async)!; index: components.GrowthRegulationInsurance; minWidth: '300px'"
></app-insurance-side-drawer>
<app-check-out-side-drawer
  *appSideIf="(shownComponent$ | async)!; index: components.GrowthRegulationInsuranceCheckout; minWidth: '300px'"
></app-check-out-side-drawer>
<app-yield-prognosis *appSideIf="(shownComponent$ | async)!; index: components.YieldPrognosis; minWidth: '300px'"></app-yield-prognosis>
<app-yield-prognosis-details
  *appSideIf="(shownComponent$ | async)!; index: components.YieldPrognosisDetails; minWidth: '300px'"
></app-yield-prognosis-details>
<app-potato-blight *appSideIf="(shownComponent$ | async)!; index: components.PotatoBlight; minWidth: '300px'"></app-potato-blight>
