export enum VraModelNorms {
  SpotSpraying = -2,
  HighNdviLowFertilization = -1,
  NoReallocation = 0,
  FirstFertilizationWinterRape = 1,
  FirstFertilizationWinterGrain = 2,
  ThirdFertilizationWinterGrain = 3,
  FourthFertilizationWinterGrain = 5,
  StandardSeed = 4,
  StandardPlantProtection = 150,
  WeedWinterCerealSpring = 151,
  StandardGrowthRegulationPlantProtection = 180,
  NoReallocationPotassium = 1001,
  FertilizationPotatoStarch = 1010,
  NoCaliumReallocation = 2001,
  Calium = 2010,
}
