import { DateTime } from 'luxon';
import { Filterable } from '../../filter/filterable';
import { ColumnKeyBlight } from './../../filter/column/column-key-blight';
import { BlightTableSubLineDto } from './blight-table-sub-line-dto';
import { BlightTreatmentDto } from './blight-treatment-dto';

export class BlightTableSubLine extends Filterable<BlightTableSubLine> {
  public id: number;
  public farmId: number;
  public name: string;
  public number: string;
  public cropId: number;
  public risc: boolean;
  public blightRegisteredDate: DateTime;
  public latestTreatmentDate: DateTime;
  public treatments: BlightTreatment[];

  constructor(dto: BlightTableSubLineDto) {
    super();
    this.id = dto.id;
    this.farmId = dto.farmId;
    this.name = dto.cropName;
    this.number = dto.fieldNumber;
    this.risc = dto.risc;
    this.blightRegisteredDate = dto.blightRegisteredDate;
    this.latestTreatmentDate = dto.latestTreatmentDate;
    this.cropId = dto.cropId;
    this.treatments = dto.treatments.map((treatment) => new BlightTreatment(treatment));
  }
  public getTitle(columnKey: ColumnKeyBlight) {
    switch (columnKey) {
      case ColumnKeyBlight.fieldNumber:
        return this.number;
      case ColumnKeyBlight.cropName:
        return this.name;
      default:
        return super.noMatchingTitle(columnKey);
    }
  }
  public getIdentifier(columnKey: ColumnKeyBlight) {
    switch (columnKey) {
      case ColumnKeyBlight.fieldNumber:
        return this.number;
      case ColumnKeyBlight.cropName:
        return this.name;
      default:
        return super.noMatchingIdentitier(columnKey);
    }
  }

  public override getNestedFilterables(columnKey: ColumnKeyBlight): BlightTreatment[] | null {
    switch (columnKey) {
      case ColumnKeyBlight.productName:
        return this.treatments.map((treatment) => treatment);
      default:
        return super.getNestedFilterables(columnKey);
    }
  }
}

export class BlightTreatment extends Filterable<BlightTreatment> {
  public get amountWithSuffix() {
    return `${this.amount.toFixed(2)} ${this.amountUnit}`;
  }

  public date: DateTime;
  public productNormNumber: number;
  public productName: string;
  public amount: number;
  public amountUnit: string;
  public registered: boolean;

  constructor(dto: BlightTreatmentDto) {
    super();
    this.date = dto.date;
    this.productName = dto.productName;
    this.productNormNumber = dto.productNormNumber;
    this.amount = dto.amount;
    this.amountUnit = dto.amountUnit;
    this.registered = dto.registered;
  }
  public getTitle(columnKey: ColumnKeyBlight) {
    switch (columnKey) {
      case ColumnKeyBlight.productName:
        return this.productName;
      default:
        return super.noMatchingTitle(columnKey);
    }
  }

  public getIdentifier(columnKey: ColumnKeyBlight) {
    switch (columnKey) {
      case ColumnKeyBlight.productName:
        return this.productName;
      default:
        return super.noMatchingIdentitier(columnKey);
    }
  }
}
