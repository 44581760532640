import { Injectable } from '@angular/core';
import { HttpClient } from '@app/core/http/http-client';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { Observable } from 'rxjs';
import { Field } from '@app/core/interfaces/field.interface';
import { CacheService } from '@app/core/cache/cache.service';
import { YieldPrognosis } from '../interfaces/yield-prognosis';

@Injectable({
  providedIn: 'root',
})
export class YieldPrognosisRepoService {
  private yieldPrognosisCache = this.cacheService.create<YieldPrognosis>({ defaultTtl: 10000 });

  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private cacheService: CacheService
  ) {}

  // @ts-ignore - TS7006 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public getPrognosisForField(field: Field, harvestYear): Observable<YieldPrognosis> {
    const key = `${field.id}-${harvestYear}`;
    return this.yieldPrognosisCache.getOrSetAsync(key, () => {
      return this.http.get(`${this.endpoints.foApi}/farms/${field.farmId}/${harvestYear}/fields/${field.id}/yieldprognosis`, {
        params: {
          directorateCropNormNumber: field.crops[0]?.lawDK?.directorateCropNormNumber,
          legendEntries: 5,
          cropTypeNumber: field.crops[0]?.cropTypeNumber,
        },
      });
    });
  }
}
