import { NgModule } from '@angular/core';
import { ChartWrapperModule } from '@app/shared/chart-wrapper/chart-wrapper.module';
import { SharedModule } from '@app/shared/shared.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { CornHarvestPrognosisChartComponent } from './corn-harvest-prognosis-chart/corn-harvest-prognosis-chart.component';
import { CornHarvestPrognosisInfoComponent } from './corn-harvest-prognosis-chart/corn-harvest-prognosis-info/corn-harvest-prognosis-info.component';
import { CornHarvestPrognosisSettingsComponent } from './corn-harvest-prognosis-chart/corn-harvest-prognosis-settings/corn-harvest-prognosis-settings.component';
import { CornHarvestPrognosisLegendComponent } from './corn-harvest-prognosis-legend/corn-harvest-prognosis-legend.component';
import { CornHarvestPrognosisComponent } from './corn-harvest-prognosis.component';
import { CornHarvestPrognosisRepoService } from './repository/corn-harvest-prognosis-repo.service';
import { CornPrognosisService } from './service/corn-prognosis.service';

@NgModule({
  imports: [ChartsModule, ChartWrapperModule, SharedModule],
  providers: [CornPrognosisService, CornHarvestPrognosisRepoService],
  declarations: [
    CornHarvestPrognosisLegendComponent,
    CornHarvestPrognosisComponent,
    CornHarvestPrognosisChartComponent,
    CornHarvestPrognosisSettingsComponent,
    CornHarvestPrognosisInfoComponent,
  ],
  exports: [CornHarvestPrognosisLegendComponent, CornHarvestPrognosisComponent, CornHarvestPrognosisChartComponent],
})
export class CornHarvestPrognosisModule {}
