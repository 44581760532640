import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ranch-form',
  templateUrl: './ranch-form.component.html',
  styleUrls: ['./ranch-form.component.scss'],
})
export class RanchFormComponent {
  @Input() public ranchFormGroup!: UntypedFormGroup;
}
