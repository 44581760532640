/**
 * Map that returns a default value when a key is not found.
 */
export class MapWithDefault<K, V> extends Map<K, V> {
  private _default: V;

  override get(key?: K | null): V {
    if (!key || !this.has(key)) {
      return this._default;
    }

    return super.get(key)!;
  }

  constructor(defaultValue: V, entries: readonly [K, V][]) {
    super(entries);

    this._default = defaultValue;
  }
}

export class ReadonlyMapWithDefault<K, V> extends MapWithDefault<K, V> implements ReadonlyMap<K, V> {}
