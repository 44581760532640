import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DateHelper } from '@app/helpers/date/date-helper';
import { BlightRiskService } from '@app/new-map/features/cultivation-journal/blight/blight-risk.service';
import { BlightRisk } from '@app/new-map/features/cultivation-journal/blight/models/blight-risk';
import { ProtectionColorUtil } from '@app/new-map/features/cultivation-journal/blight/utils/protection-color-util';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-blight-risk',
  templateUrl: './blight-risk.component.html',
  styleUrls: ['./blight-risk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BlightRiskService],
})
export class BlightRiskComponent implements OnInit {
  public lang: string = '';
  public pastRisks$!: Observable<BlightRisk[]>;
  public prognosisRisks$!: Observable<BlightRisk[]>;
  public loading$!: Observable<boolean>;
  public isPeriodValid$!: Observable<boolean>;
  public canNavigateForward$!: Observable<boolean>;
  public canNavigateBack$!: Observable<boolean>;
  public harvestYear$!: Observable<number | undefined>;

  constructor(
    private blightRiskService: BlightRiskService,
    private harvestYearStateService: HarvestYearStateService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.lang = this.translateService.currentLang;
    this.loading$ = this.blightRiskService.loading$;
    this.isPeriodValid$ = this.blightRiskService.isPeriodValid$;
    this.canNavigateForward$ = this.blightRiskService.canNavigateForward$;
    this.canNavigateBack$ = this.blightRiskService.canNavigateBack$;
    this.pastRisks$ = this.blightRiskService.pastRisks$;
    this.prognosisRisks$ = this.blightRiskService.prognosisRisks$;
    this.harvestYear$ = this.harvestYearStateService.harvestYear$;
    this.blightRiskService.init();
  }

  public isToday(date: DateTime): boolean {
    return DateHelper.isToday(date);
  }

  public navigateBack() {
    this.blightRiskService.navigateBack();
  }

  public navigateForward() {
    this.blightRiskService.navigateForward();
  }

  public getProtectionStyle(remainingProtectionDays: number) {
    return ProtectionColorUtil.getProtectionStyle(remainingProtectionDays);
  }
}
