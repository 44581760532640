import { Injectable } from '@angular/core';
import { BasisLayer } from '@app/core/interfaces/basis-layer/basis-layer';
import { BasisLayerCategory } from '@app/core/interfaces/basis-layer/basis-layer-category';
import { BasisLayerCategoryDto } from '@app/core/interfaces/basis-layer/basis-layer-category-dto.interface';
import { BasisLayerFormvalues } from '@app/core/interfaces/basis-layer/basis-layer-formvalues';
import { NotificationService } from '@app/core/notification/notification.service';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';
import { BasisLayerStateService } from '../../basis-layer-state.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private selectedCategory?: BasisLayerCategory;

  constructor(
    private basisLayerStateService: BasisLayerStateService,
    private farmStateService: FarmStateService,
    private notificationService: NotificationService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  private onSuccessfulFunction: Function = () => {};

  public init(onSuccessFunc: Function) {
    forkJoin([
      this.basisLayerStateService.selectedBasisLayerCategory$.pipe(first()),
      this.basisLayerStateService.selectedBasisLayer$.pipe(first()),
    ])
      .pipe(
        tap(() => (this.basisLayerStateService.loading = true)),
        finalize(() => (this.basisLayerStateService.loading = false))
      )
      .subscribe(([category, basisLayer]) => {
        this.selectedCategory = category;
      });
    this.onSuccessfulFunction = onSuccessFunc;
  }

  public changeSelectedBasisLayerCategory(farmId: number): BasisLayerCategory | undefined {
    this.basisLayerStateService.basisLayerCategories$.pipe(first()).subscribe((basisLayerCategories) => {
      const basisLayerCategoryForFarmId = basisLayerCategories.find((basisLayerCategory) => {
        return (
          basisLayerCategory.farmId === farmId &&
          basisLayerCategory.basisLayerTypeNormNumber === this.selectedCategory?.basisLayerTypeNormNumber
        );
      });

      this.basisLayerStateService.selectedBasisLayerCategory = basisLayerCategoryForFarmId;
      this.selectedCategory = basisLayerCategoryForFarmId;
    });
    return this.selectedCategory;
  }

  public save(
    basisLayerCategoryValues: BasisLayerFormvalues,
    farmId: number,
    newBasisLayer: boolean,
    updateCategory: boolean,
    geometry: string
  ) {
    this.basisLayerStateService.loading = true;
    if (newBasisLayer && updateCategory) {
      this.saveCategory(basisLayerCategoryValues, farmId)
        .pipe(
          first(),
          finalize(() => (this.basisLayerStateService.loading = false)),
          switchMap(() => this.createBasisLayer(geometry, farmId))
        )
        .subscribe(() => {
          this.notificationService.showCreated('main.fieldmap.vra.edit.basisLayer.title');
          this.onSuccessfulFunction();
        });
    } else if (newBasisLayer) {
      this.createBasisLayer(geometry, farmId)
        .pipe(
          first(),
          finalize(() => (this.basisLayerStateService.loading = false))
        )
        .subscribe(() => {
          this.notificationService.showCreated('main.fieldmap.vra.edit.basisLayer.title');
          this.onSuccessfulFunction();
        });
    } else if (updateCategory) {
      this.saveCategory(basisLayerCategoryValues, farmId)
        .pipe(
          first(),
          finalize(() => (this.basisLayerStateService.loading = false)),
          switchMap(() => this.updateBasisLayer(geometry, farmId))
        )
        .subscribe(
          () => {
            this.notificationService.showUpdated('main.fieldmap.vra.edit.basisLayer.title');
            this.onSuccessfulFunction();
          },
          () => {}
        );
    } else {
      this.updateBasisLayer(geometry, farmId)
        .pipe(
          first(),
          finalize(() => (this.basisLayerStateService.loading = false))
        )
        .subscribe(
          () => {
            this.notificationService.showUpdated('main.fieldmap.vra.edit.basisLayer.title');
            this.onSuccessfulFunction();
          },
          () => {}
        );
    }
  }

  public deleteSelectedBasisLayer(farmId: number) {
    this.basisLayerStateService.selectedBasisLayer$
      .pipe(
        filterNullish(),
        first(),
        tap(() => (this.basisLayerStateService.loading = true)),
        switchMap((selectedBasisLayer) => this.basisLayerStateService.deleteBasisLayer(farmId, selectedBasisLayer.id!).pipe(first())),
        finalize(() => (this.basisLayerStateService.loading = false))
      )
      .subscribe(() => {
        this.notificationService.showDeleted('main.fieldmap.vra.edit.basisLayer.title');
        this.onSuccessfulFunction();
      });
  }

  private saveCategory(categoryValues: BasisLayerFormvalues, farmId: number): Observable<BasisLayerCategoryDto[] | null> {
    const basisLayerCategory = {
      ...this.selectedCategory,
      seedingAdjustment: categoryValues.seeding,
      fertilizerAdjustment: categoryValues.fertilization,
      weedAdjustment: categoryValues.weed,
      pestsAdjustment: categoryValues.pest,
      diseaseAdjustment: categoryValues.disease,
      growthRegulationAdjustment: categoryValues.growthregulation,
      limeAdjustment: categoryValues.limeadjustment,
    } as BasisLayerCategoryDto;

    return this.farmStateService.selectedFarmIds$.pipe(
      first(),
      switchMap((farmIds) => this.basisLayerStateService.updateCategories([basisLayerCategory], farmId))
    );
  }

  private updateBasisLayer(basisLayerGeometry: string, farmId: number): Observable<BasisLayer[] | null> {
    return combineLatest([this.farmStateService.selectedFarmIds$, this.harvestYearStateService.harvestYear$]).pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.basisLayerStateService.validateGeometry(
          [this.newBasisLayer(basisLayerGeometry, farmId, this.selectedCategory?.id)],
          farmId,
          harvestYear
        )
      ),
      switchMap(() => this.basisLayerStateService.deleteSelectedBasisLayer(farmId)),
      switchMap(() => this.createBasisLayer(basisLayerGeometry, farmId))
    );
  }

  private createBasisLayer(basisLayerGeometry: string, farmId: number): Observable<BasisLayer[] | null> {
    return combineLatest([this.farmStateService.selectedFarmIds$, this.harvestYearStateService.harvestYear$]).pipe(
      first(),
      switchMap(([farmIds, harvestYear]) =>
        this.basisLayerStateService.createBasisLayer(
          [this.newBasisLayer(basisLayerGeometry, farmId, this.selectedCategory?.id)],
          farmId,
          harvestYear
        )
      )
    );
  }

  private newBasisLayer(geometry: string, farmId: number, categoryId?: number): BasisLayer {
    return {
      farmId,
      geometry,
      categoryId,
    } as BasisLayer;
  }
}
