export enum SoilSampleShortName {
  Reaction = 'Rt',
  Potassium = 'Kt',
  Copper = 'Cut',
  Magnesium = 'Mgt',
  Boron = 'Bt',
  Phosphor = 'Pt',
  Humus = 'Humus',
  ClayPercentage = 'Ler%',
  SoilType = 'Jb',
  NitrogenPercentage = 'N%',
  CarbonPercentage = 'C%',
  OrganicCarbon = 'OrgC',
}
