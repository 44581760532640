import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EconomyModule } from '@app/economy/economy.module';
import { FilterModule } from '@app/new-map/features/cultivation-journal/filter/filter.module';
import { NToolModule } from '@app/shared/n-tool/n-tool.module';
import { SharedModule } from '@app/shared/shared.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { NToolWrapperComponent } from './n-tool-wrapper/n-tool-wrapper.component';
import { NToolsCalcDialogComponent } from './n-tools-calc-dialog/n-tools-calc-dialog.component';
import { NToolsTableComponent } from './n-tools-table/n-tools-table.component';
import { TaskStatusPipe } from './n-tools-table/util/task-status.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NToolModule,
    TreeListModule,
    DropDownListModule,
    PopupModule,
    FilterModule,
    InputsModule,
    DateInputsModule,
    EconomyModule,
  ],
  exports: [NToolWrapperComponent],
  declarations: [NToolsTableComponent, NToolWrapperComponent, TaskStatusPipe, NToolsCalcDialogComponent],
})
export class NToolsModule {}
