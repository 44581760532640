import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartWrapperModule } from '../chart-wrapper/chart-wrapper.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { FpPowerbiComponent } from './fp-powerbi.component';
import { FpPowerbiService } from './fp-powerbi.service';

@NgModule({
  imports: [CommonModule, SpinnerModule, TranslateModule, ChartWrapperModule],
  providers: [FpPowerbiService],
  declarations: [FpPowerbiComponent],
  exports: [FpPowerbiComponent],
})
export class FpPowerbiModule {}
