import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CLOUDLESS_FAKE_CLOUD_COVERAGE_NUMBER } from '@app/core/ndvis/ndvis-repo.service';
import { DateTime } from 'luxon';
import { SatelliteImageDate } from '../interfaces/ndvi-image-date.interface';

@Component({
  selector: 'app-ndvi-date-img',
  templateUrl: './ndvi-date-img.component.html',
  styleUrls: ['./ndvi-date-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdviDateImgComponent implements OnInit {
  @Input() public date!: Date;
  @Input() public dateDt!: DateTime;
  @Input() public ndviImageDatesMap!: { [key: string]: SatelliteImageDate };

  public image = '';
  protected clearSkyFakeCloudCoverageNumber = `${CLOUDLESS_FAKE_CLOUD_COVERAGE_NUMBER}`;

  public ngOnInit() {
    this.dateDt = DateTime.fromJSDate(this.date);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['ndviImageDates'] && changes['ndviImageDates'].currentValue) {
      this.image = this.getWeatherImage(this.date);
    }

    if (((changes['date'] && changes['date'].currentValue) || this.date) && this.ndviImageDatesMap) {
      this.dateDt = DateTime.fromJSDate(this.date);
      this.image = this.getWeatherImage(this.date);
    }
  }

  public getWeatherImage(date: Date) {
    const ndviImageDate = this.ndviImageDatesMap[DateTime.fromJSDate(date).toFormat('dd-MM-yyyy')];

    if (!ndviImageDate) {
      return '';
    }

    return this.getImg(ndviImageDate.cloudCoveragePct);
  }

  public getImg(cloudCoveragePct: number) {
    if (cloudCoveragePct === CLOUDLESS_FAKE_CLOUD_COVERAGE_NUMBER) {
      return this.clearSkyFakeCloudCoverageNumber;
    }
    if (cloudCoveragePct <= 5) {
      return 'assets/images/weather-symbols/01d.png';
    }
    if (cloudCoveragePct < 50) {
      return 'assets/images/weather-symbols/02d.png';
    }
    if (cloudCoveragePct < 90) {
      return 'assets/images/weather-symbols/03d.png';
    }
    if (cloudCoveragePct <= 100) {
      return 'assets/images/weather-symbols/04.png';
    } else {
      return '';
    }
  }
}
