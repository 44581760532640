import { Injectable } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { SimpleOperationLine } from '@app/core/interfaces/simple-operation-line.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { TaskEditTemplate } from '@app/core/interfaces/task-edit-template.interface';
import { OperationTypeGroupsService } from '@app/core/operation-type-groups/operation-type-groups.service';
import { ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { SimpleTaskRepo } from '@app/core/repositories/tasks/simple-task/simple-task-repo.service';
import { uniq } from 'lodash-es';
import { DateTime } from 'luxon';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
export interface ISimpleTaskService {
  createSimpleTaskOnCrops(simpleTask: SimpleTask, farmIds: number[], cropIds: number[], harvestYear: number): Observable<SimpleTask[]>;
  updateSimpleTask(simpleTask: SimpleTask): Observable<SimpleTask>;
  updateTaskEditTemplate(taskEditTemplate: TaskEditTemplate): Observable<SimpleTask[]>;
  getTasksByField(field: Field, options: any): Observable<SimpleTask[]>;
}

@Injectable({
  providedIn: 'root',
})
export class SimpleTaskService implements ISimpleTaskService {
  constructor(
    private simpleTaskRepo: SimpleTaskRepo,
    private produceNormsService: ProduceNormsService,
    private operationTypeGroupsService: OperationTypeGroupsService
  ) {}

  // TODO: Refactor to use enrichSimpleTasksWithProduceNormsAndOperationTypes
  public createSimpleTaskOnCrops(
    simpleTask: SimpleTask,
    farmIds: number[],
    cropIds: number[],
    harvestYear: number
  ): Observable<SimpleTask[]> {
    return this.simpleTaskRepo.createSimpleTaskOnCrops(simpleTask, farmIds, cropIds, harvestYear).pipe(
      switchMap((tasks) =>
        this.operationTypeGroupsService.get().pipe(
          take(1),
          map((operationTypeGroups) => ({ tasks, operationTypeGroups }))
        )
      ),
      map(({ tasks, operationTypeGroups }) => {
        return tasks.map((task) => this.addOperationTypeGroupNamesToTask(task, operationTypeGroups));
      }),
      switchMap((tasks) => {
        const produceNorms = farmIds.map((id) =>
          this.produceNormsService.getProduceNorms(id, harvestYear).pipe(map((norms) => ({ farmId: id, produceNorms: norms })))
        );

        return forkJoin(produceNorms).pipe(map((norms) => ({ produceNorms: norms, tasks })));
      }),
      map((value) => {
        return value.tasks.map((task) =>
          this.addProduceNormsToTask(task, value.produceNorms.find((norm) => norm.farmId === task.farmId)!.produceNorms)
        );
      })
    );
  }

  public updateSimpleTask(simpleTask: SimpleTask): Observable<SimpleTask> {
    return this.enrichSimpleTasksWithProduceNormsAndOperationTypes(
      this.simpleTaskRepo.updateSimpleTasks([simpleTask]),
      simpleTask.farmId,
      simpleTask.harvestYear
    ).pipe(map((taskArray) => taskArray[0]));
  }

  public updateTaskEditTemplate(taskEditTemplate: TaskEditTemplate): Observable<SimpleTask[]> {
    return this.simpleTaskRepo.updateTaskEditTemplate(taskEditTemplate).pipe(
      switchMap((simpleTasks) => {
        return this.enrichSimpleTasksWithProduceNormsAndOperationTypes(
          of(simpleTasks),
          taskEditTemplate.template.farmId,
          taskEditTemplate.template.harvestYear
        );
      })
    );
  }

  public getTasksByField(field: Field, cropId: number, options = { bustCache: false }): Observable<SimpleTask[]> {
    return this.enrichSimpleTasksWithProduceNormsAndOperationTypes(
      this.simpleTaskRepo.getTasksByField(field),
      field.farmId,
      field.harvestYear
    );
  }

  private enrichSimpleTasksWithProduceNormsAndOperationTypes(
    tasksObservable: Observable<SimpleTask[]>,
    farmId: number,
    harvestYear: number
  ): Observable<SimpleTask[]> {
    return forkJoin([
      tasksObservable,
      this.produceNormsService.getProduceNorms(farmId, harvestYear).pipe(take(1)),
      this.operationTypeGroupsService.get().pipe(take(1)),
    ]).pipe(
      map(([tasks, produceNorms, operationTypeGroups]) => {
        const tasksWithProduceNorm = tasks.map((task) => {
          // extra check to make sure we are not dealing with ISO strings.
          if (!task.date.isValid) {
            task.date = DateTime.fromISO(task.date as unknown as string);
          }
          return this.addProduceNormsToTask(task, produceNorms);
        });

        return { tasks: tasksWithProduceNorm, operationTypeGroups };
      }),
      map(({ tasks, operationTypeGroups }) => {
        return tasks.map((task) => {
          return this.addOperationTypeGroupNamesToTask(task, operationTypeGroups);
        });
      })
    );
  }

  private addOperationTypeGroupNamesToTask(task: SimpleTask, operationTypeGroups: OperationTypeGroup[]): SimpleTask {
    return {
      ...task,
      operations: task.operations.map((operation) => {
        return {
          ...operation,
          operationTypeGroup: operationTypeGroups.find((group) => group.id === operation.operationTypeGroupId),
        };
      }),
      operationTypeGroupNames: uniq(
        task.operations.map((operation) => {
          const operationTypeGroup = operationTypeGroups.find((group) => group.id === operation.operationTypeGroupId);
          return operationTypeGroup ? operationTypeGroup.name : '';
        })
      ).join(', '),
    };
  }

  private addProduceNormsToTask(task: SimpleTask, produceNorms: ProduceNorm[]) {
    const operations = task.operations;
    const reduced = operations.reduce((prev: SimpleOperationLine[], curr) => [...prev, ...curr.operationLines], []);

    const mapped = reduced.map((line: SimpleOperationLine) => {
      const produceNorm = produceNorms.find((norm) => norm.number === line.produceNormNumber);
      line.produceNorm = produceNorm;
      return produceNorm === undefined ? '' : produceNorm.name;
    });

    return {
      ...task,
      produceNormNames: mapped.join(', '),
    };
  }
}
