import { Month } from '@app/core/enums/month.enum';

export const BLIGHT_PROGNOSIS_START_DAY = 15;
export const BLIGHT_PROGNOSIS_END_DAY = 30;
// Months are index based
export const BLIGHT_PROGNOSIS_START_MONTH = Month.May;
export const BLIGHT_PROGNOSIS_END_MONTH = Month.September;

// How many days of historical and prognosis data should the prognosis show. One is added to BLIGHT_DAYS_IN_PERIOD to make up for the base day
// 20 days historical data + current day + 4 days prognosis data
export const BLIGHT_HISTORICAL_DATA_DAYS = 20;
export const BLIGHT_PROGNOSIS_DATA_DAYS = 4;
export const BLIGHT_DAYS_IN_PERIOD = BLIGHT_HISTORICAL_DATA_DAYS + BLIGHT_PROGNOSIS_DATA_DAYS + 1;

// No weather data exists prior to 2020
export const BLIGHT_EARLIEST_ALLOWED_HARVEST_YEAR = 2020;
