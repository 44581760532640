import { Injectable } from '@angular/core';
import { FilterItem } from '@app/new-map/features/cultivation-journal/filter/filter-item';
import { ScopeItem } from '@app/new-map/features/cultivation-journal/filter/scope-item';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterStateService {
  private _filterItems = new BehaviorSubject<FilterItem<any>[]>([]);

  /**
   * Returns all filter item for the given scope, and all feature item with no scope
   * If no scope is given, all filter items are returned
   * @param scope
   */
  public getFilterItems$(scope?: ScopeItem) {
    return this._filterItems.pipe(
      map((filterItems) =>
        !!scope ? filterItems.filter((filterItem) => !filterItem.scope.length || filterItem.scope.includes(scope)) : filterItems
      )
    );
  }

  public addFilterItem(filterItem: FilterItem<any>) {
    this._filterItems.pipe(first()).subscribe((filterItems) => this._filterItems.next([...filterItems, filterItem]));
  }

  public removeFilterItem(filterItem: FilterItem<any>) {
    this._filterItems
      .pipe(first())
      .subscribe((filterItems) => this._filterItems.next(filterItems.filter((item) => !item.isEqual(filterItem))));
  }

  public removeAllFilters(scope?: ScopeItem) {
    !!!scope
      ? this._filterItems.next([])
      : this._filterItems
          .pipe(first())
          .subscribe((filterItems) => this._filterItems.next(filterItems.filter((item) => !item.scope.includes(scope))));
  }
}
