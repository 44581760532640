import { Injectable } from '@angular/core';
import { GeometryType } from '@app/core/enums/hotspot-geometry-type.enum';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { MapLayerType } from '@app/core/enums/map-layer-type.enum';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { Farm } from '@app/core/interfaces/farm.interface';
import { NdviHistory } from '@app/core/interfaces/ndvi-history.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MapService } from '@app/core/map/map.service';
import { NdviRepo } from '@app/core/ndvis/ndvis-repo.service';
import { MethodTypes } from '@app/method-types.enum';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { DateTime } from 'luxon';
import Feature from 'ol/Feature';
import { map } from 'rxjs/operators';
import { SatelliteImageDate } from '../interfaces/ndvi-image-date.interface';

@Injectable()
export class NdviService {
  public static NdviImageMapDateFormat = 'DD-MM-YYYY';
  private readonly cloudCoveragePct = 100;
  constructor(
    private ndviRepo: NdviRepo,
    private mapService: MapService,
    private languageService: LanguageService
  ) {}

  public getNdviMapCoverFlowItem(isDisabled: boolean = false) {
    const ndviMapCoverFlowItem = new MapCoverFlowItem({
      mapCoverFlowLayersId: MapLayerId.REDEDGENDVI,
      displayName: 'main.fieldmap.layers.redEdgeNdvi',
      name: this.languageService.getText('main.fieldmap.layers.redEdgeNdvi'),
      isVisible: true,
      isDisabled,
      layers: [
        {
          layerId: MapLayerId.REDEDGENDVI,
          layerType: MapLayerType.TILE,
          zIndex: 5,
          useAuthHeaders: true,
          isVisible: true,
          url: undefined,
          method: MethodTypes.GET,
        },
        {
          layerId: MapLayerId.GROWTHCURVE,
          layerType: MapLayerType.VECTOR,
          isVisible: true,
          zIndex: 6,
        },
      ],
    });

    return ndviMapCoverFlowItem;
  }

  public getNDVIImageDates(farms: Farm[]) {
    return this.ndviRepo.getSatelliteImageDates(farms.map((farm) => farm.id)).pipe(
      map((ndviImageDates) => {
        if (!ndviImageDates) return null;
        return ndviImageDates
          .filter((imgDto) => imgDto.cloudCoveragePct <= this.cloudCoveragePct)
          .map<SatelliteImageDate>((imgDto) => ({ ...imgDto, date: DateTime.fromISO(imgDto.date) }));
      })
    );
  }

  public getClearSkyImageDates(farms: Farm[]) {
    return this.ndviRepo.getCloudlessImageDates(farms.map((farm) => farm.id));
  }

  public createNdviHistoryFeature(featureModel: FieldLayerItem, ndviHistory: NdviHistory) {
    const feature: Feature = this.mapService.getMapFeature(MapLayerId.GROWTHCURVE, GeometryType.POLYGON, featureModel.coordinates);

    const fieldColor = [78, 128, 141, 0.3];
    feature.set('fill', fieldColor);
    feature.set('stroke', '#ffffff');
    feature.set('text', featureModel.text);
    feature.set('ndviHistory', ndviHistory);
    feature.set('field', featureModel.field);
    feature.setId(featureModel.fieldId + featureModel.fieldBlockSubDivision);
    return feature;
  }

  public findNdviHistory(ndviHistories: NdviHistory[], featureModel: FieldLayerItem) {
    return ndviHistories.find((ndviHistory) => ndviHistory.featureId === featureModel.featureId);
  }
  public findNdviHistoryForField(ndviHistories: NdviHistory[], fieldId: number) {
    return ndviHistories.find((ndviHistory) => ndviHistory.fieldId === fieldId);
  }

  /**
   * Enables or disables feature/field selection through OpenLayer Mapservice
   *
   * @param enable - Boolean | true enables, false disables.
   */
  public featureSelection(enable: boolean) {
    enable ? this.mapService.enableFeatureSelection : this.mapService.disableFeatureSelection();
  }
}
