import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { HotspotDto } from '@app/core/interfaces/hotspot.interface';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShapeFile } from '../interfaces/shape-file.interface';

@Injectable()
export class ShapeFilesRepo {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  /**
   * Get shape files from server
   */
  public getShapeFiles(): Observable<ShapeFile[]> {
    return this.http.get<ShapeFile[]>(this.endpoints.ftApi + '/shapefiles', {});
  }

  /**
   * Get shape file by id
   */
  public getShapeFile(id: number): Observable<ShapeFile> {
    return this.http
      .get<ShapeFile>(`${this.endpoints.foApi}/shapefiles/${id}`, {})
      .pipe(catchError((error: HttpErrorResponse) => observableThrowError(error)));
  }

  /**
   * Posts a ShapeFile to the API via a MultiPartFormRequest All files must be sent as ONE zipped file
   * @param zipFile (zipped)
   */
  public createShapeFile(zipFile: File, srid: number): Observable<ShapeFile | null> {
    const options = {
      withCredentials: true,
      params: { srid },
    };
    const formData = new FormData();
    formData.append('file', zipFile);

    return this.http.post<ShapeFile, FormData>(`${this.endpoints.foApi}/shapefiles`, formData, options);
  }

  /**
   * Import ShapeFile with specified Id to specified farmId - shapefile contains field polygons
   */
  public importShapeFileWithFieldPolygons(
    id: number,
    farmId: number,
    harvestYear: number,
    fieldNumberColumn: string,
    replace: boolean
  ): Observable<ShapeFile | null> {
    const reqOptions = {
      withCredentials: true,
      params: {
        harvestYear,
        fieldNumberColumn,
        replace,
      },
    };

    return this.http.post<ShapeFile, {}>(`${this.endpoints.foApi}/farms/${farmId}/shapefiles/${id}/import`, {}, reqOptions);
  }

  /**
   * Import ShapeFile with specified Id to specified farmId - shapefile contains hotspots
   */
  public importShapeFileWithHotspots(farmId: number, shapefileId: number, hotspotTypeId: number): Observable<HotspotDto[] | null> {
    const reqOptions = { withCredentials: true };
    const shapeFileInformation = {
      farmId,
      shapefileId,
      hotspotTypeId,
    };

    return this.http.post<HotspotDto[], { farmId: number; shapefileId: number; hotspotTypeId: number }>(
      `${this.endpoints.foApi}/hotspot/import/shapefile`,
      shapeFileInformation,
      reqOptions
    );
  }

  /**
   * Import ShapeFile
   */
  public importShapeFile(
    id: number,
    harvestYear: number,
    farmIdentColumn: string,
    farmIdentType: string,
    fieldNumberColumn: string,
    replace: boolean
  ): Observable<ShapeFile | null> {
    const reqOptions = {
      withCredentials: true,
      params: {
        farmIdentColumn,
        farmIdentType,
        fieldNumberColumn,
        replace,
      },
    };

    return this.http.post<ShapeFile, {}>(`${this.endpoints.foApi}/shapefiles/${id}/import`, {}, reqOptions);
  }
}
