import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-hover',
  templateUrl: './info-hover.component.html',
  styleUrls: ['./info-hover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoHoverComponent {
  public isInfoVisible = false;
  @Input() public buttonText = '';
}
