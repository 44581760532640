import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { FileUploadModule } from '@app/shared/file-upload/file-upload.module';
import { SideDrawerModule } from '@app/shared/side-drawer/side-drawer.module';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';
import { TranslateModule } from '@app/shared/translate/translate.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { TextareaModule } from '@fe-shared/ui-components/textarea/textarea.module';
import { FeedbackComponent } from './feedback.component';
import { FeedbackRepository } from './repository/feedback.repository';
import { FeedbackService } from './service/feedback.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SpinnerModule,
    TranslateModule,
    FileUploadModule,
    ButtonsModule,
    TextareaModule,
    SideDrawerModule,
    IconModule,
  ],
  providers: [FeedbackRepository, FeedbackService],
  exports: [FeedbackComponent],
  declarations: [FeedbackComponent],
})
export class FeedbackModule {}
