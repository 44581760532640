export function getScoreColor(score: number) {
  if (score === undefined || score === null) {
    return 'rgba(255, 255, 255, 0.1)';
  } else if (score < -2) {
    return '#4F734A'; // color-green
  } else if (score > 3) {
    return '#F2464A'; // color-red
  } else if (score >= -2 && score <= 3) {
    return '#F9E067'; // color-yellow
  } else {
    return 'rgba(255, 255, 255, 0.1)';
  }
}
