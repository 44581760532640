/**
 * Standard file formats (MIME types)
 * See https://www.iana.org/assignments/media-types/media-types.xhtml for a list of applicable formats
 */
export enum FileFormats {
  Png = 'image/png',
  Jpeg = 'image/jpeg',
  Jpg = 'image/jpg',
  Bmp = 'image/bmp',
  Zip = 'application/x-zip-compressed',
  Txt = 'text/plain',
  Tiff = 'image/tiff',
}
