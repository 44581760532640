<a class="language-menu-item" (click)="languageMenuClicked($event)">
  <app-icon icon="language"></app-icon>
  <div class="text">{{ 'header.user.userInfo.language' | translate }}</div>
  <img src="&quot;../../assets/images/flags/{{ selectedLanguage?.name }}.svg" class="language-selected-img" align="middle" />
</a>
<div id="language-list" class="language-list" [hidden]="!showLanguageList" (clickOutside)="hideLanguageList()">
  <a
    class="language-list-item"
    *ngFor="let language of languages"
    id="e2e-language-{{ language.shortKey }}"
    attr.data-testid="language-{{ language.shortKey }}"
    (click)="languageClicked(language)"
  >
    <img src="&quot;../../assets/images/flags/{{ language.name }}.svg" class="language-list-item-img" align="middle" />
    <span>{{ language.name }}</span>
  </a>
</div>
