import { Injectable } from '@angular/core';
import { ICache } from '@app/helpers/cache/cache.interface';
import { TtlCache, TtlCacheConfig } from '@app/helpers/cache/ttl-cache';

export interface ICacheService {
  create<TValueType>(config?: TtlCacheConfig): ICache<TValueType>;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService implements ICacheService {
  public create<TValueType>(config?: TtlCacheConfig): ICache<TValueType> {
    return new TtlCache<TValueType>(config);
  }
}
