import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'editableGridCellValue',
  pure: false,
})
/**
 * Transforms the output value from a html input element to a table-friendly value
 */
export class EditableGridCellValuePipe implements PipeTransform {
  constructor() {}

  public transform(value: number, suffix?: string): string {
    if (isNaN(value)) {
      return ' ';
    }
    return !!suffix ? `${value} ${suffix}` : value.toString();
  }
}
