import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export class DateValidators {
  public static minDate(date: DateTime, errorMessage?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = DateTime.fromJSDate(control.value);

      if (!controlDate.isValid) {
        return null;
      }

      const validationDate = date.minus({ hours: 1 });

      return controlDate > validationDate
        ? null
        : {
            minDate: {
              minDate: validationDate,
              actual: controlDate,
              errorMessage: errorMessage ? errorMessage : '',
            },
          };
    };
  }

  public static maxDate(date: DateTime, errorMessage?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = DateTime.fromJSDate(control.value);

      if (!controlDate.isValid) {
        return null;
      }

      const validationDate = date.plus({ hours: 1 });

      return controlDate < validationDate
        ? null
        : {
            maxDate: {
              maxDate: validationDate,
              actual: controlDate,
              errorMessage: errorMessage ? errorMessage : '',
            },
          };
    };
  }
}
