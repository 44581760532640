import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLuxonDateAdapterOptions, MatLuxonDateModule, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { DateAdapter } from '@angular/material/core';
import { DatePickerComponent } from '@app/shared/date-picker/date-picker.component';
import { MaterialModule } from '@app/shared/material/material.module';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { DatePickerAdapter } from './date-picker-adapter';
@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, IconModule, MatLuxonDateModule],
  declarations: [DatePickerComponent],
  providers: [
    { provide: DateAdapter, useClass: DatePickerAdapter },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { firstDayOfWeek: 1, useUtc: true } as MatLuxonDateAdapterOptions,
    },
  ],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
