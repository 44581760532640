import { Injectable } from '@angular/core';
import { MapLayerSetting } from '@app/core/interfaces/map-layer-setting.interface';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { filterNullish } from '@app/shared/operators';
import { MapStateService } from '@app/state/services/map/map-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MapCoverFlowSelector {
  public readonly mapCoverFlowItems$: Observable<MapCoverFlowItem[] | null> = this.mapStateService.mapCoverFlowItems$;

  public get mapLayerSettings$(): Observable<MapLayerSetting[]> {
    return this.mapCoverFlowItems$.pipe(
      filterNullish(),
      map((mapCoverFlowItems) => mapCoverFlowItems.reduce((prev: MapLayerSetting[], cur: MapCoverFlowItem) => [...prev, ...cur.layers], []))
    );
  }
  constructor(private mapStateService: MapStateService) {}
}
