import { Injectable } from '@angular/core';
import { NotificationService } from '@app/core/notification/notification.service';
import { filterNullish } from '@app/shared/operators';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { MapBrowserEvent } from 'ol';
import { Subject, Subscription } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { FieldPlanSideDrawerService } from '../field-plan-side-drawer.service';
import { ShownComponentEnum } from '../shown-component-in-side-drawer.enum';

@Injectable({
  providedIn: 'root',
})
export class FieldPlanContentService {
  public readonly harvestYear$ = this.harvestYearStateService.harvestYear$;
  private clickSubscriptions: Subscription[] = [];
  private _stopWhenComponentIsDestroyed$ = new Subject<boolean>();

  constructor(
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    private notificationService: NotificationService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public set stopWhenComponentIsDestroyed(stop: boolean) {
    this._stopWhenComponentIsDestroyed$.next(stop);
  }

  public subscribeClickOnMap() {
    const pointerSubscription = this.fieldPlanSideDrawerService.getClickMapEvent$().subscribe((ev) => {
      this.onClick(ev as MapBrowserEvent<PointerEvent>);
    });

    this.clickSubscriptions.push(pointerSubscription);
  }

  public unSubscribeClickFromMap() {
    if (this.clickSubscriptions.length > 0) {
      this.clickSubscriptions.forEach((sub) => sub.unsubscribe());
      this.clickSubscriptions = [];
    }
  }

  public onClick(ev: MapBrowserEvent<PointerEvent>) {
    this.harvestYear$
      .pipe(
        first(),
        filterNullish(),
        switchMap((harvestYear) => this.fieldPlanSideDrawerService.getFieldBlockPolygon(ev, harvestYear)),
        takeUntil(this._stopWhenComponentIsDestroyed$)
      )
      .subscribe(
        (geometryString) => {
          if (geometryString && geometryString.length > 0) {
            this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.createFieldFormComponent);
            this.unSubscribeClickFromMap();
            this.fieldPlanSideDrawerService.drawFieldOnMapAndMakeFieldReadyToBeModified(geometryString);
          } else {
            this.notificationService.showError('main.fieldAdministration.createField.noBlockSelected');
          }
        },
        () => {
          this.notificationService.showError('main.fieldAdministration.createField.fieldBlockOccupiedError', 8000);
          this.fieldPlanSideDrawerService.setIsLoadingFieldPlanFeatures(false);
        },
        () => {
          this.fieldPlanSideDrawerService.setIsLoadingFieldPlanFeatures(false);
        }
      );
  }
}
