import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { fadeInNgIf } from '@app/core/animations/fade-in-ng-if.fn';
import { PresentationMode } from '@app/shared/cards-or-table/cards-or-table-toggle/cards-or-table-toggle.component';
import { ActionDefinition } from '@app/shared/cards-or-table/interfaces/action-definition.interface';
import { ActionEvent } from '@app/shared/cards-or-table/interfaces/action-event.interface';
import { FieldDefinition } from '@app/shared/cards-or-table/interfaces/field-definition.interface';
import { CardsOrTableService } from '@app/shared/cards-or-table/services/cards-or-table.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-cards-or-table',
  templateUrl: './cards-or-table.component.html',
  styleUrls: ['./cards-or-table.component.scss'],
  animations: [fadeInNgIf('reveal')],
})
export class CardsOrTableComponent implements OnInit, OnDestroy {
  public fieldDefinitions$ = new BehaviorSubject<FieldDefinition<any>[]>([]);
  public rawData$ = new BehaviorSubject<any[]>([]);
  public mappedData$ = this.cardsOrTableService.mapData$(this.fieldDefinitions$, this.rawData$);
  public data$ = new BehaviorSubject<any[]>([]);

  @Output() public action = new EventEmitter<ActionEvent<any>>();

  @Input()
  public set data(value: any[]) {
    this.rawData$.next(value);
  }
  @Input()
  public set fieldDefinitions(value: FieldDefinition<any>[]) {
    this.fieldDefinitions$.next(value);
  }

  @Input() public actionDefinitions: ActionDefinition[] = [];
  @Input() public tableRef!: TemplateRef<any>;
  @Input() public cardRef!: TemplateRef<any>;
  @Input() public hidePresentationModeSwitch = false;
  @Input() public presentationMode: PresentationMode = 'cards';

  private subscriptions: Subscription[] = [];

  constructor(protected cardsOrTableService: CardsOrTableService) {}

  public ngOnInit() {
    this.subscriptions.push(this.mappedData$.subscribe((data) => this.data$.next(data)));
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public get shouldUseCards() {
    return this.presentationMode === 'cards';
  }

  public get shouldUseTable() {
    return this.presentationMode === 'table' || this.presentationMode === undefined || this.presentationMode === null;
  }

  public forwardActionEvent(event: ActionEvent<any>) {
    this.action.emit(event);
  }

  public presentationModeChanged($event: PresentationMode) {
    this.presentationMode = $event;
  }
}
