<div class="header">
  <h1>{{ 'main.fieldmap.droneImageImport.upload-dialog.title' | translate }}</h1>
</div>
<div mat-dialog-content>
  <p>{{ 'main.fieldmap.droneImageImport.upload-dialog.paragraph1' | translate }}</p>
  <p>{{ 'main.fieldmap.droneImageImport.upload-dialog.paragraph2' | translate }}</p>
  <p>{{ 'main.fieldmap.droneImageImport.upload-dialog.paragraph3' | translate }}</p>
</div>
<div mat-dialog-actions class="dialog-actions">
  <app-square-button [buttonType]="'Primary'" (click)="onConfirmClicked()">{{
    'main.fieldmap.droneImageImport.upload-dialog.confirm' | translate | capitalize
  }}</app-square-button>
  <app-square-button [buttonType]="'Secondary'" (click)="onCancelClicked()">{{
    'main.fieldmap.droneImageImport.upload-dialog.cancel' | translate | capitalize
  }}</app-square-button>
</div>
