import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { ChartWrapperComponent } from '@app/shared/chart-wrapper/chart-wrapper.component';
import { SpinnerModule } from '@app/shared/spinner/spinner.module';
import { TranslateModule } from '@app/shared/translate/translate.module';

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonsModule, SpinnerModule],
  declarations: [ChartWrapperComponent],
  exports: [ChartWrapperComponent],
})
export class ChartWrapperModule {}
