export class StringValidator {
  /**
   * Validate input for whitespaces
   * If value has space or spaces, return true. Otherwise return false.
   * @param value String value to validate
   */
  public static hasWhitespaces(value: string): boolean {
    if (!value) {
      return false;
    }

    return value.indexOf(' ') >= 0;
  }
}
