import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { Feature } from 'ol';

export class FeatureContext {
  $implicit: Feature | undefined;
}

/**
 * Template definition for the tooltip
 * Provides the current feature in the template context
 */
@Directive({ selector: '[cellFeature]' })
export class CellFeatureDirective {
  context!: FeatureContext;

  constructor(
    public viewContainer: ViewContainerRef,
    public templateRef: TemplateRef<FeatureContext>
  ) {}
}
