import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@app/core/language/language.service';

/**
 * Super simple pipe for transforming decimal places based on language
 * Will replace commas with periods for english, and vice versa for danish
 */
@Pipe({
  name: 'simpleDecimalSeparator',
})
export class SimpleDecimalSeparatorPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value?: string | number | null): string {
    value = value?.toString();
    if (!value) {
      return value ?? '';
    }

    // Determine the decimal separator based on language
    let decimalSeparator = this.languageService.currentLanguage.shortKey === 'en' ? '.' : ',';

    // Replace the decimal separator accordingly
    if (decimalSeparator === '.') {
      return value.replace(/,/g, '.');
    } else {
      return value.replace(/\./g, ',');
    }
  }
}
