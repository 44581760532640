import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartService } from '@app/core/chart/chart.service';
import { Month } from '@app/core/enums/month.enum';
import { ScreenSize } from '@app/core/enums/screen-size.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { WetHourDto } from '@app/core/repositories/weather/leaf-wet-hours.interface';
import { getChartLegendStyle } from '@app/helpers/chart-legend-style/chart-legend-style.fn';
import { getPlotBandColor } from '@app/helpers/plot-band-colors/get-plot-band-color.fn';
import { WetHoursDataProvider } from '@app/shared/septoria/wetHoursDataProvider';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { TranslateService } from '@ngx-translate/core';
import { CategoryAxis, RenderEvent, SeriesMarkers, ValueAxis } from '@progress/kendo-angular-charts';
import { Group } from '@progress/kendo-drawing';
import { DateTime } from 'luxon';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { filterNullish } from '../operators';
import { SeptoriaChartData } from './septoria-chart.interface';
import { SeptoriaChartService } from './septoria-chart.service';

@Component({
  selector: 'app-septoria-chart',
  templateUrl: './septoria-chart.component.html',
  styleUrls: ['./septoria-chart.component.scss'],
  providers: [SeptoriaChartService],
})
export class SeptoriaChartComponent implements OnInit, OnDestroy {
  @Input() public field: Field = {} as Field;
  public aggregated: WetHourDto[] = [];
  public isLoading = false;
  public wetHoursDataProvider: WetHoursDataProvider[] = [];
  public providerControl = new FormControl<WetHoursDataProvider[]>([]);
  public isChartOpen$?: Observable<boolean>;

  public categoryAxis: CategoryAxis = {
    name: 'category',
    baseUnit: 'hours',
    type: 'date',
    majorGridLines: {
      visible: false,
    },
    majorTicks: {
      visible: false,
    },
    labels: {
      dateFormats: {
        hours: LanguageConstants.getDateFormat(this.languageService.currentLanguage.shortKey).l,
      },
      rotation: 45,
      position: 'onAxis',
    },
  };

  private readonly max = 30;
  public valueAxis: ValueAxis = {
    name: 'value',
    max: this.max,
    title: {
      text: this.septoriaChartService.getValueAxistitle(),
      margin: {
        right: 6,
      },
      rotation: -90,
    },
  };

  public seriesMarkers: SeriesMarkers = {
    visible: false,
  };

  private readonly screenSize$ = this.septoriaChartService.screenSize$;
  private subscriptions = new Subscription();

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private chartStartDate: DateTime;

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private chartEndDate: DateTime;

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private data: SeptoriaChartData;

  // @ts-ignore - TS2564 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private translations: string[];
  private forecast: WetHourDto[] = [];
  private readonly chartOpenDate = DateTime.fromObject({ month: Month.April, day: 17 });
  private selectedProviders: WetHoursDataProvider[] = [];
  private chartLineColors = ['#4E808D', '#E95D0F', '#FFD800', '#4F734A', '#A868D7'];

  constructor(
    private septoriaChartService: SeptoriaChartService,
    private languageService: LanguageService,
    private chartService: ChartService,
    private translate: TranslateService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  public ngOnInit() {
    this.isChartOpen$ = this.harvestYearStateService.harvestYear$.pipe(map((harvestYear) => this.isChartOpenDateReached(harvestYear)));
    this.isChartOpen$
      .pipe(
        take(1),
        filter((open) => open),
        switchMap(() => {
          this.isLoading = true;
          return this.setMinAndMaxCategoryValue();
        }),
        switchMap(() => {
          return forkJoin([
            this.septoriaChartService.getDataProviders().pipe(take(1)),
            this.getDmiLeafWetHoursData().pipe(take(1)),
            this.translate.get('septoriaChart.forecast'),
          ]).pipe(finalize(() => (this.isLoading = false)));
        })
      )
      .subscribe(([providers, [data, screenSize], dmiForecast]) => {
        this.onDMIChartDataReceived(data, screenSize, dmiForecast);
        this.wetHoursDataProvider.push(...providers);
      });

    this.subscriptions.add(this.screenSize$.subscribe((size) => this.calculateCategoryAxis(size)));
    this.subscriptions.add(
      this.translate.get(['septoriaChart.wetTime', 'common.hours']).subscribe((translations) => (this.translations = translations))
    );
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Checks if the charts open date is reached.
   * If harvestYear is less than the current year, true is returned
   * If harvestYear is equal to current year, and the date is after chartOpenDate, true is returned.
   * Otherwise false is returned.
   * @param harvestYear The selected harvest year
   */
  private isChartOpenDateReached(harvestYear?: number): boolean {
    const now = DateTime.now();
    return (harvestYear && harvestYear < now.year) || (harvestYear === now.year && now > this.chartOpenDate);
  }

  public legendItemVisual = (args: any) => getChartLegendStyle(args);

  public toggleInfo() {
    this.septoriaChartService.showInfoModal();
  }

  public onRender(e: RenderEvent) {
    // Check if any of the selected providers contain data
    if (this.providerControl.value && this.providerControl.value.some((provider) => provider.data && provider.data.length > 0)) {
      const chartElement = (e.sender.getPlotArea().backgroundVisual as any).chartElement;
      const group = new Group();

      this.data?.colorBands.forEach((range) => {
        const band = this.chartService.createHorizontalPlotline(range.startDate!, range.from, range.to, chartElement, getPlotBandColor(3));
        group.append?.(band);
      });

      const protections =
        this.data?.protectionPeriods.map((period) =>
          this.chartService.createVerticalPlotlineWithText(
            period.from,
            period.to,
            chartElement,
            'rgba(180, 200, 176, 0.7)',
            period.pesticides
          )
        ) ?? [];

      const clippedProtections = this.chartService.clipOutsideGraph(chartElement, protections);

      group.append(clippedProtections);

      const { wetHours, dateString } = this.data?.labelData;

      // @ts-ignore - TS7015 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      const wetTime = this.translations['septoriaChart.wetTime'];
      // @ts-ignore - TS7015 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      const commonHours = this.translations['common.hours'];

      // @ts-ignore - TS2451 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
      const sowingDateLabel = this.chartService.createDetailedLineLabel({
        title: wetTime,
        duration: wetHours,
        unit: commonHours,
        date: dateString,
        padding: 20,
        offsetY: -30,
        plotArea: chartElement,
      });

      group.append(sowingDateLabel);

      e.sender.findPaneByIndex(0).visual.insert(0, group);
    }
  }

  private getDmiLeafWetHoursData() {
    return this.septoriaChartService
      .getDmiLeafWetHours(this.field.farmId, this.field.harvestYear, this.field.id)
      .pipe(withLatestFrom(this.screenSize$));
  }

  private onDMIChartDataReceived(data: SeptoriaChartData, screenSize: ScreenSize, dmiForecastName: any) {
    this.forecast = this.setWetHoursMax(data.forecast);
    const dmiColor = this.getChartLineColor();
    const dmi = {
      name: 'DMI',
      data: this.setWetHoursMax(data.observed),
      dataType: 'observed',
      chartLineColor: dmiColor,
    } as WetHoursDataProvider;
    const dmiForecast = {
      name: dmiForecastName,
      data: this.forecast,
      dataType: 'forecast',
      chartLineColor: dmiColor,
    } as WetHoursDataProvider;
    this.wetHoursDataProvider.push(dmi);
    this.wetHoursDataProvider.push(dmiForecast);
    this.selectedProviders.push(dmi);
    this.selectedProviders.push(dmiForecast);
    this.providerControl.setValue([dmi, dmiForecast]);
    this.aggregated = [...data.forecast, ...data.observed];
    this.data = data;
    this.calculateCategoryAxis(screenSize);
  }

  /**
   * Adds the property max to each WetHourDto in wetHours
   * If accumulatedWetHours exceeds 30, max is set to 30
   * If accumulatedWetHours does not exceed 30, max is set to the value of accumulatedWetHours
   * @param wetHours Array of WetHourDto to be mutated
   */
  private setWetHoursMax(wetHours: WetHourDto[]): WetHourDto[] {
    return wetHours.map((wetHour) => {
      const max = wetHour.accumulatedWetHours > this.max ? this.max : wetHour.accumulatedWetHours;
      return { ...wetHour, max };
    });
  }

  /**
   * Sets the date span of the chart.
   */
  private setMinAndMaxCategoryValue() {
    return this.harvestYearStateService.harvestYear$.pipe(
      filterNullish(),
      take(1),
      map((year) => {
        const startDate = DateTime.fromObject({ year: year, month: Month.April, day: 27 });
        let endDate = DateTime.fromObject({ year: year, month: Month.July, day: 1 });
        // If the current year is selected as harvest year and todays date is between the 17th and 27th of April,
        // the chart span will be limited to 10 days from today

        if (DateTime.now() < startDate && this.isChartOpenDateReached(year)) {
          endDate = startDate.plus({ days: 10 });
        }
        this.setChartSpan(startDate, endDate);
      })
    );
  }

  private calculateCategoryAxis(screenSize: ScreenSize) {
    this.categoryAxis = this.septoriaChartService.calculateNumberOfStepsByScreenSize(screenSize, this.aggregated, this.categoryAxis);
  }

  private getQueryDate(): DateTime | undefined {
    const today = DateTime.now();

    if (!this.chartStartDate || !this.chartEndDate) return;

    if (today > this.chartEndDate) {
      return this.chartEndDate;
    } else if (today < this.chartStartDate) {
      return this.chartStartDate;
    } else {
      return today;
    }
  }

  private setChartSpan(start: DateTime, end: DateTime) {
    this.chartStartDate = start;
    this.chartEndDate = end;
    this.categoryAxis.min = start.toJSDate();
    this.categoryAxis.max = end.toJSDate();
  }

  /**
   * Handles change in selected WetHoursDataProviders.
   * Fetches data from SeptoriaChartService, if a new provider is selected
   * @param wetHoursDataProviders The new state of the WetHoursDataProvider dropdown
   */
  public onProvidersChange(wetHoursDataProviders: WetHoursDataProvider[]) {
    // Ignored null results and results without data changes
    if (wetHoursDataProviders) {
      // Finds a new provider, if one was selected
      const newProvider = wetHoursDataProviders.find((provider) => !this.selectedProviders.includes(provider));
      // If a new provider is selected, its data property is populated by septoriaChartService
      if (newProvider && newProvider.dataConnectionType) {
        if (!newProvider.chartLineColor) {
          newProvider.chartLineColor = this.getChartLineColor();
        }
        this.isLoading = true;
        this.septoriaChartService
          .getWeatherStationLeafWetHours(newProvider, this.chartStartDate!, this.getQueryDate()!)
          .pipe(
            take(1),
            finalize(() => (this.isLoading = false))
          )
          .subscribe((wethours) => (newProvider.data = this.setWetHoursMax(wethours)));
      }
      this.selectedProviders = [...wetHoursDataProviders];
    }
  }

  private getChartLineColor() {
    const color = this.chartLineColors[0];
    this.chartLineColors = this.chartLineColors.filter((x) => x !== color);
    this.chartLineColors.push(color);
    return color;
  }
}
