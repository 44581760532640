<p *ngIf="title">
  {{ title }}
</p>
<div class="input-wrapper">
  <div>
    <app-icon class="icon" icon="date_range"></app-icon>
    <app-date-picker
      [date]="startDateInit"
      placeholder="{{ 'intervalPicker.startDate' | translate }}"
      (dateChange)="handleStartDateChange($event)"
    ></app-date-picker>
  </div>

  <div>
    <app-icon class="icon" icon="date_range"></app-icon>
    <app-date-picker
      [date]="endDateInit"
      placeholder="{{ 'intervalPicker.endDate' | translate }}"
      (dateChange)="handleEndDateChange($event)"
    >
    </app-date-picker>
  </div>
</div>

<div class="error" [ngClass]="{ invalid: isInvalid() }">
  {{ 'intervalPicker.error' | translate }}
</div>

<div class="button-wrapper">
  <app-square-button buttonType="Secondary" (clicked)="onCancelClicked()">
    {{ 'intervalPicker.cancel' | translate }}
  </app-square-button>
  <app-square-button buttonType="Primary" [disabled]="isInvalid()" (clicked)="onConfirmClicked()">
    {{ 'intervalPicker.ok' | translate }}
  </app-square-button>
</div>
