import { Injectable } from '@angular/core';
import { MessageService } from '@app/core/messages/messages.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { forkJoin, of, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FeedbackRepository } from '../repository/feedback.repository';

@Injectable()
export class FeedbackService {
  constructor(
    private feedbackRepo: FeedbackRepository,
    private notification: NotificationService,
    private messages: MessageService
  ) {}

  public saveFeedback(description: string, files: File[]) {
    return this.postFeedback(description).pipe(
      switchMap((issueKey) => (files.length ? this.postFiles(files, issueKey) : of(files))),
      catchError((error: string) => {
        this.notification.showError(error);
        return observableThrowError(error);
      })
    );
  }

  private postFeedback(description: string) {
    // hardcoded summary as the API requires it but the user has no way of providing it yet.
    return this.feedbackRepo
      .postFeedback('Besked fra bruger', description)
      .pipe(
        catchError((error: Response) =>
          observableThrowError(this.messages.getHttpStatusMsg(error.status, this.messages.getFeedbackMsg().saveFeedbackError))
        )
      );
  }

  private postFiles(files: File[], issueKey: string) {
    return forkJoin(...files.map((file) => this.feedbackRepo.postFileToIssue(file, issueKey))).pipe(
      catchError((error) =>
        observableThrowError(this.messages.getHttpStatusMsg(error.status, this.messages.getFeedbackMsg().saveFileError))
      )
    );
  }
}
