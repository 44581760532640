import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConsentStateService } from '@app/core/consent/consent-state.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Consent, ConsentTypes } from '@app/core/repositories/consent/consent.interface';
import { UserType } from '@app/core/repositories/user/user-type.enum';
import { StorageService } from '@app/core/storage/storage.service';
import { FarmPickerStateService } from '@app/state/services/farm-picker/farm-picker-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { combineLatest, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { FarmPickerModalComponent } from './farm-picker-modal/farm-picker-modal.component';
import { FarmPickerStorageKeys } from './farm-picker-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class FarmPickerService {
  public readonly numberOfSelectedFarms$ = this.farmStateService.selectedFarms$.pipe(map((farms) => farms.length));
  private readonly selectedFarms$ = this.farmStateService.selectedFarms$;
  private readonly consents$ = this.consentStateService.consents$;
  private readonly userType$ = this.farmStateService.userType$;
  private readonly farms$ = this.farmStateService.farms$;
  private readonly isFarmsSet$ = this.farmStateService.farmsAreSelected$;
  private readonly isFarmsLoading$ = this.farmStateService.isFarmsLoading$;
  private readonly isFarmPickerModalOpen$ = this.farmPickerStateService.isFarmPickerModalOpen$;

  private farmPickerModalRef?: MatDialogRef<FarmPickerModalComponent, any>;

  constructor(
    private matDialog: MatDialog,
    private storageService: StorageService,
    private consentStateService: ConsentStateService,
    private farmStateService: FarmStateService,
    private farmPickerStateService: FarmPickerStateService
  ) {}

  public openFarmPickerModal(): void {
    this.farmPickerStateService.openFarmPickerModal();
  }

  public subscribeToFarmPickerData() {
    return this.selectedFarms$
      .pipe(
        combineLatest(
          this.userType$.pipe(filter((userType) => !!userType)),
          this.farms$.pipe(startWith([])),
          this.isFarmsSet$.pipe(filter((isFarmsSet) => !isFarmsSet)),
          this.isFarmsLoading$.pipe(filter((isFarmsLoading) => !isFarmsLoading))
        ),
        take(1),
        switchMap(([selectedFarms, userType, farms, isFarmsSet, isFarmsLoading]) =>
          this.consents$.pipe(
            this.isConsentGiven(),
            map(() => ({ selectedFarms, userType, farms }))
          )
        )
      )
      .subscribe(({ selectedFarms, userType, farms }) => {
        this.initFarmPickerModalState(userType!, selectedFarms, farms || []);
      });
  }

  public subscribeToConsents() {
    return this.consents$
      .pipe(
        this.isConsentGiven(),
        switchMap(() => this.isFarmPickerModalOpen$),
        filter((state) => {
          return state !== undefined;
        })
      )
      .subscribe((farmPickerState) => {
        setTimeout(() => {
          this.onIsModalOpenChanged(farmPickerState);
        });
      });
  }

  private initFarmPickerModalState(userType: UserType, selectedFarms: Farm[], farms: Farm[]) {
    if (!this.hasSelectedFarms(selectedFarms) && this.isFarmerOrStudent(userType)) {
      if (farms.length === 1) {
        // The farmer only has one farm and none is selected, select it.
        this.farmStateService.selectedFarms = farms;
      } else if (!this.isConsultantTeacherOrPrivateConsultant(userType) && this.isStoredFarmsValid(farms)) {
        this.setSelectedFarmsFromStorage();
      } else {
        this.farmPickerStateService.openFarmPickerModal();
      }
    } else {
      if (!selectedFarms.length) this.farmPickerStateService.openFarmPickerModal();
      else this.farmStateService.selectedFarms = selectedFarms;
    }
  }

  private onIsModalOpenChanged(isModelOpen: boolean) {
    if (isModelOpen && !this.farmPickerModalRef) {
      this.farmPickerModalRef = this.matDialog.open(FarmPickerModalComponent, {
        minWidth: '60vw',
      });
      this.farmPickerModalRef.disableClose = true;
      this.farmPickerModalRef.afterClosed().subscribe(() => {
        this.farmPickerModalRef = undefined;
        this.farmPickerStateService.closeFarmPickerModal();
      });
    } else {
      if (!isModelOpen && this.farmPickerModalRef !== undefined) {
        this.farmPickerModalRef.close();
      }
    }
  }

  /**
   * Custom RxJS operator
   */
  private isConsentGiven = () =>
    filter((consents: Consent[]) => {
      return consents != null && consents.every((consent) => consent.statusId === ConsentTypes.Given);
    });

  /**
   * Ensure that the farms from local storage are valid for the user.
   * @param farms The list of all the farms available to the user.
   */
  private isStoredFarmsValid(farms: Farm[]) {
    const storedFarms = this.storageService.getFromStorage<Farm[]>(FarmPickerStorageKeys.StoredFarmsKey);

    if (!storedFarms) {
      return false;
    }

    return storedFarms
      .map((storedFarm) => farms.findIndex((farm) => farm.id === storedFarm.id) > -1)
      .reduce((previous, current) => (previous ? current : previous));
  }

  private setSelectedFarmsFromStorage() {
    const storedFarms = this.storageService.getFromStorage<Farm[]>(FarmPickerStorageKeys.StoredFarmsKey);
    this.farmStateService.selectedFarms = storedFarms;
  }

  private hasSelectedFarms(selectedFarms: Farm[]) {
    return selectedFarms && selectedFarms.length > 0;
  }

  private isConsultantTeacherOrPrivateConsultant(userType: UserType) {
    return userType === UserType.Consultant || userType === UserType.PrivateConsultant || userType === UserType.Teacher;
  }

  private isFarmerOrStudent(userType: UserType) {
    return userType === UserType.Farmer || userType === UserType.Student;
  }
}
