import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Crop } from '@app/core/interfaces/crop.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { SimpleOperationLine } from '@app/core/interfaces/simple-operation-line.interface';
import { SimpleOperation } from '@app/core/interfaces/simple-operation.interface';
import { SimpleTask } from '@app/core/interfaces/simple-task.interface';
import { IProduceNormItemGroup, ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { LoadingState } from '@app/helpers/loading-state';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OperationForm, TaskFormService } from '../task-form.service';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationComponent implements OnInit, OnDestroy {
  @Input() public operationForm: FormGroup<OperationForm> | undefined;
  @Input() public field!: Field;
  @Input() public task!: SimpleTask;
  @Input() public disabled: boolean | null = false;
  @Input() public canWrite: boolean | null = true;
  @Input() public addTooltipText!: '';
  @Input() public addTooltipPosition: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' = 'below';
  @Input() public removeTooltipText!: '';
  @Input() public removeTooltipPosition: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' = 'below';

  @Output() public remove = new EventEmitter<SimpleOperation>();

  public produceNormGroups: IProduceNormItemGroup[] = [];
  public operation!: SimpleOperation;

  public loadingState = new LoadingState();
  public loading$ = this.loadingState.changes$;

  private subscriptions = new Subscription();

  constructor(
    private produceNormService: ProduceNormsService,
    private changeDetectorRef: ChangeDetectorRef,
    private taskFormService: TaskFormService
  ) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.getProduceNorms().subscribe((norms) => {
        this.produceNormGroups = norms;
        this.changeDetectorRef.markForCheck();
      })
    );

    this.operation = this.operationForm?.value as SimpleOperation;
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onRemoveOperationLine(operationLine: SimpleOperationLine) {
    const operation: SimpleOperation = this.operationForm?.value as SimpleOperation;
    const index = operation.operationLines.indexOf(operationLine);

    this.operationLinesFormArray?.removeAt(index);

    if (this.operationLinesFormArray?.length === 0) {
      this.remove.emit(operation);
    }
  }

  public onAddClick() {
    this.addOperationLine();
  }

  public get operationLinesFormArray() {
    return this.operationForm?.controls.operationLines;
  }

  private addOperationLine() {
    this.operationLinesFormArray?.push(this.taskFormService.createOperationLineForm(!!this.disabled || !this.canWrite));
    this.changeDetectorRef.detectChanges();
  }

  private getProduceNorms() {
    this.loadingState.start('editTask.productsAndActions.loadingProduceNorms');
    return this.produceNormService
      .getProduceNormItemGroupsByOperationTypeGroupId(
        this.field,
        this.operationTypeGroupId,
        this.CropIdFromTask?.id ?? this.mainCrop.id,
        this.operationLines.map((line) => line.produceNormNumber)
      )
      .pipe(finalize(() => this.loadingState.stop()));
  }

  private get operationLines(): SimpleOperationLine[] {
    return this.operationForm?.controls.operationLines.value as SimpleOperationLine[];
  }

  private get mainCrop() {
    return this.field.crops.reduce((prev, curr) => (prev.successionNo < curr.successionNo ? prev : curr), { id: null } as unknown as Crop);
  }

  private get CropIdFromTask() {
    return this.field.crops.find((fieldcrop) => fieldcrop.id === this.task.cropId);
  }

  private get operationTypeGroupId(): number {
    return this.operationForm?.controls.operationTypeGroupId.value as number;
  }
}
