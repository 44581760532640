import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import range from 'lodash-es/range';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-harvest-year-picker',
  templateUrl: 'harvest-year-picker.html',
  styleUrls: ['harvest-year-picker.scss'],
})
export class HarvestYearPickerComponent implements OnInit, OnDestroy {
  @Input() public disabled: boolean;
  @Input() public showLabel = true;
  public harvestYearControl = new UntypedFormControl();

  private harvestYearSubscription?: Subscription;

  private _harvestYear?: number | null;

  public selectableYears: number[] = [];
  public harvestYear$ = this.harvestYearStateService.harvestYear$;

  constructor(private harvestYearStateService: HarvestYearStateService) {
    this.disabled = false;
  }

  public ngOnInit() {
    // We only want to do this once. We don't want to keep updating the
    // list when we change the harvest year in this component.
    // .take(1) will take the last value emitted.
    this.harvestYearSubscription = this.harvestYear$.pipe(take(1)).subscribe((harvestYear) => this.onHarvestYearChangeInStore(harvestYear));

    this.harvestYearControl.valueChanges.subscribe((harvestYear: number) => (this.harvestYear = harvestYear));
  }

  public ngOnDestroy() {
    this.harvestYearSubscription?.unsubscribe();
  }

  public get harvestYear(): number | undefined | null {
    return this._harvestYear;
  }
  public set harvestYear(hYear: number | undefined | null) {
    this._harvestYear = hYear;
    this.harvestYearStateService.harvestYear = hYear!;
  }

  private onHarvestYearChangeInStore(harvestYear: number | undefined) {
    this.harvestYear = harvestYear;
    this.harvestYearControl.setValue(harvestYear);
    // Fra og med harvestYear - 5 til harvestYear + 6.
    // Giver os: Fra og med, til og med harvestYear + 5
    this.selectableYears = range(harvestYear! - 5, harvestYear! + 6);
  }
}
