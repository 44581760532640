export enum ErrorCodes {
  RequiredAccessNotGranted = 1001,
  InvalidHash = 1002,
  NoFileCorrespondingToHash = 1003,
  BadFieldPolygon = 1005,
  BadJwtToken = 1010,
  IdInUrlMustMatchIdInEntity = 1101,
  DataCannotBeChangedHarvestYearIsLocked = 1102,
  FarmIdHasToBeGreaterThan0 = 1103,
  TranslationLanguageNotFound = 1104,
  HarvestYearHasToBeGreateThanOrEqualTo2016AndLessThanOrEqualTo2100 = 1105,
  FieldIdHasToBeGreaterThan0 = 1106,
  HarvestYearHasToBeBetween2000And2100 = 1107,
  HarvestYearMustBeEqualAcrossEntities = 1108,
  FarmIdHasToBeAnInteger = 1109,
  NormNumberHasToBeGreaterThan0 = 1110,
  NoDataForQuery = 1114,
  FarmNotFound = 2001,
  FarmImageNotFound = 3001,
  FarmImageDoesNotBelongToFarm = 3002,
  ErrorReadingImageFromDatabase = 3003,
  NoImageContentInRequest = 3004,
  MissingContentTypeForFile = 3005,
  InvalidImageType = 3006,
  FieldNotFound = 4001,
  MatchFieldDoesNotBelongToHarvestYear = 4002,
  NoFieldNumber = 4003,
  InvalidFieldNumber = 4004,
  FieldAreaHasToBeGreaterThan0 = 4005,
  MultiYearIdHasToBeGreaterThan0 = 4006,
  InvalidFieldState = 4007,
  InvalidJb = 4008,
  InvalidGeometry = 4009,
  InvalidGeometryType = 4010,
  CropNotFound = 5001,
  MissingSuccessionNo = 5002,
  SuccessionNoMustBeGreaterThan0 = 5003,
  CropAreaMustBeGreaterThan0AndLessThan1000 = 5004,
  CropAreaMustBeEqualToOrLessThanFieldArea = 5005,
  MissingSubDivision = 6001,
  WrongSubDivisionFormat = 6002,
  FieldBlockAreaMustBeGreaterThan0AndLessThan1000 = 6003,
  HarvestYearHasToBeGreateThanOrEqualTo2016AndLessThanOrEqualToNextCalenderYear = 20001,
  CropNormNumberHasToBeGreaterThan0 = 20002,
  SoilTypesHasToBeBetween1And12 = 20003,
  SearchCentroidHasInvalidFormat = 20004,
  SearchDistanceHasToBeGreaterThan0 = 20005,
  MunicipallityNumberHasToBeGreaterThanOrEqualTo0 = 20006,
  RegionIdHasToBeGreaterThanOrEqualTo0AndLessThanOrEqualTo6 = 20007,
  FarmAreaMinHasToBeGreaterThanOrEqualTo0 = 20008,
  FarmAreaMaxHasToBeGreaterThan0 = 20009,
  NumberOfDecimalsHasToBeGreaterThanOrEqualTo0 = 20010,
  NoCentroidToCompareSearchDistanceTo = 20011,
  NoCropNorm = 20012,
  YieldRegionIdHasToBeGreaterThanOrEqualTo0AndLessThanOrEqualTo5 = 20013,
  FarmIdentTypeInvalid = 30001,
  MaxCloudCoveragePctNotValid = 40001,
  MapTileParametersInvalid = 40002,
  SinergiseCommunicationError = 40003,
  VraDefinitionNotFound = 50001,
  VraNoPrescriptionMapsForDefinition = 50002,
  VraFertilizerWithNPercentageMissing = 50003,
  VraExportForVraExportFileTypeNotImplemented = 50004,
  VraNoFieldPolygonForPrescriptionMap = 50005,
  VraModelUnavailableForNdviDate = 50012,
  VraNoValidPrerequisiteForModel = 50025,
  VraPrescriptionCalculationTimeOut = 50031,

  Duplicate = 100000,
}
