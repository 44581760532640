<kendo-chart>
  <kendo-chart-title [text]="chartTitle"></kendo-chart-title>
  <kendo-chart-axis-defaults-title text="{{ 'common.farms' | translate }}"></kendo-chart-axis-defaults-title>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item [labels]="yLabels">
      <kendo-chart-category-axis-item-title [text]="xAxisTitle"></kendo-chart-category-axis-item-title>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-series>
    <kendo-chart-series-item [color]="getColor" type="column" [data]="series" field="count" categoryField="lowerBound" [labels]="xLabels">
      <kendo-chart-series-item-tooltip>
        <ng-template let-dataItem="dataItem">{{ dataItem.count }}</ng-template>
      </kendo-chart-series-item-tooltip>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
