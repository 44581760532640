import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

export const HotspotStyles = {
  getIconStyle: (hotspotTypeId: number) => {
    const iconPath = HotspotStyles.getIconPath(hotspotTypeId);
    return [
      new Style({
        image: new Icon({
          src: iconPath,
          anchor: [0.5, 1],
        }),
        geometry: function (feature) {
          if (feature.get('features')) {
            return feature.get('features')[0].getGeometry();
          } else {
            return feature.getGeometry();
          }
        },
        zIndex: 2,
      }),
    ];
  },
  hotspotsStyle: [
    new Style({
      image: new Circle({
        radius: 7,
        fill: new Fill({
          color: [255, 0, 0, 0.5],
        }),
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
        lineDash: [4, 4],
      }),
      fill: new Fill({
        color: [224, 224, 212, 0.8], // color-sand
      }),
      zIndex: 1,
      geometry: function (feature) {
        if (feature.get('features')) {
          return feature.get('features')[0].getGeometry();
        } else {
          return feature.getGeometry();
        }
      },
    }),
  ],
  hotspotsDrawingStyle: [
    new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: [255, 0, 0, 0.5],
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 1,
        }),
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 2,
      }),
      fill: new Fill({
        color: [224, 224, 212, 0.8], // color-sand
      }),
      zIndex: 3,
    }),
  ],
  hotspotsSelectedStyle: [
    new Style({
      image: new Circle({
        radius: 9,
        fill: new Fill({
          color: [255, 0, 0, 1],
        }),
        stroke: new Stroke({
          width: 2,
          color: '#fff',
        }),
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
      fill: new Fill({
        color: [224, 224, 212, 0.95], // color-sand
      }),
      zIndex: 1,
      geometry: function (feature) {
        if (feature.get('features')) {
          return feature.get('features')[0].getGeometry();
        } else {
          return feature.getGeometry();
        }
      },
    }),
  ],
  clusteredStyle: (text: string): Style[] => {
    return [
      new Style({
        image: new Circle({
          radius: 10,
          stroke: new Stroke({
            color: '#000000',
            width: 1,
          }),
          fill: new Fill({
            color: [255, 0, 0, 0.5],
          }),
        }),
        text: new Text({
          text: text,
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    ];
  },
  clusteredSelectedStyle: (text: string): Style[] => {
    return [
      new Style({
        image: new Circle({
          radius: 15,
          stroke: new Stroke({
            color: '#fff',
            width: 2,
          }),
          fill: new Fill({
            color: [255, 0, 0, 1],
          }),
        }),
        text: new Text({
          text: text,
          fill: new Fill({
            color: '#fff',
          }),
        }),
      }),
    ];
  },
  getIconPath: (hotspotTypeId: number): string => {
    switch (hotspotTypeId) {
      case 1:
        return 'assets/images/hotspots/hotspot-marker-icon-weed.png';
      case 2:
        return 'assets/images/hotspots/hotspot-marker-icon-pests.png';
      case 3:
        return 'assets/images/hotspots/hotspot-marker-icon-fungus.png';
      case 4:
        return 'assets/images/hotspots/hotspot-marker-icon-well.png';
      case 5:
        return 'assets/images/hotspots/hotspot-marker-icon-drainage.png';
      case 6:
        return 'assets/images/hotspots/hotspot-marker-icon-rocks.png';
      case 7:
        return 'assets/images/hotspots/hotspot-marker-icon-misc.png';
      case 8:
        return 'assets/images/hotspots/hotspot-marker-icon-spray.png';
      default:
        return 'assets/images/hotspots/hotspot-marker-icon-misc.png';
    }
  },
};
