import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import createColormap from 'colormap';
import { GradiationLevel } from '../state/color.state';
import { Statistics } from '../state/prescription-map/prescription-map.store';

export type ColorMap = NonNullable<Parameters<typeof createColormap>['0']>['colormap'];

export interface LegendStatistics {
  min: number;
  max: number;
  avg: number;
  total: number;
}

// Define a color map for LIME operation type group
export const LIME_COLORMAP: string[] = [
  '#D20000', // 10+
  '#D61919', // 9
  '#DB3333', // 8
  '#DF4C4C', // 7
  '#E46666', // 6
  '#E87F7F', // 5
  '#ED9999', // 4
  '#F1B3B3', // 3
  '#F6CCCC', // 2
  '#FAE6E6', // 1
  '#FFFFFF', // 0
];

export const DEFAULT_COLORMAP = 'density';

export const DEFAULT_GRADIATION_LEVEL = 20;

// Return a color map for a given operation type group (or default if not provided)
export function createPrescriptionColorMap(operationTypeGroup?: OperationTypeGroupEnum | null, level?: GradiationLevel | null) {
  switch (operationTypeGroup) {
    case OperationTypeGroupEnum.Lime:
      return LIME_COLORMAP;
    default:
      return createColormap({
        colormap: DEFAULT_COLORMAP,
        nshades: level ?? DEFAULT_GRADIATION_LEVEL,
        format: 'hex',
      });
  }
}

// Return a legend statistics for a given operation type group (or default if not provided)
export function createLegendStatistics(
  quantities: number[],
  statistics: Statistics,
  operationTypeGroup?: OperationTypeGroupEnum | null
): LegendStatistics {
  switch (operationTypeGroup) {
    case OperationTypeGroupEnum.Lime:
      return {
        min: 0,
        max: 10,
        avg: statistics.avg,
        total: statistics.total,
      };
    default:
      return {
        min: quantities.reduce((acc, curr) => Math.min(acc, curr)),
        max: quantities.reduce((acc, curr) => Math.max(acc, curr)),
        avg: statistics.avg,
        total: statistics.total,
      };
  }
}

export function getDelimiter(index: number, operationTypeGroup?: OperationTypeGroupEnum | null): string {
  switch (operationTypeGroup) {
    case OperationTypeGroupEnum.Lime:
      return index === 0 ? '+' : '';
    default:
      return '';
  }
}
