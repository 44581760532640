import { Injectable } from '@angular/core';
import { MapConfig } from '@app/core/interfaces/map-config.interface';
import { endpoints } from 'environments/endpoints';
import { defaults } from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import { TileWMS } from 'ol/source';
import View from 'ol/View';

@Injectable()
export class OpenlayersMapService {
  constructor() {}

  public createMap(mapElement: any, config: MapConfig) {
    const olMap = new Map({
      layers: [],
      target: mapElement,
      view: new View({
        center: config.center ? fromLonLat(config.center) : fromLonLat([10, 56]),
        zoom: config.defaultZoom,
        minZoom: config.minZoom,
        maxZoom: config.maxZoom,
      }),
      controls: defaults({
        attribution: false,
        rotate: false,
        zoom: false,
      }),
    });

    return olMap;
  }
}
