import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserDetectorService } from '@app/core/browser-detector/browser-detector.service';
import { User } from '@app/core/interfaces/user.interface';
import { CorrelationIdService } from '@app/core/log/correlationid.service';
import { LogFields } from '@app/core/log/log-data.interface';
import { Logger } from '@app/core/log/logger';
import { UserSelector } from '@app/state/selectors/user.selector.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { environment } from 'environments/environment';
import { filter } from 'rxjs/operators';
import { EndpointsService } from '../endpoints/endpoints.service';
import { filterNullish } from '@app/shared/operators';

declare var window: any;
declare var opr: any;
declare var InstallTrigger: any;
declare var safari: any;
declare var document: any;

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private logger = new Logger(null!, null!);
  private userId!: string;
  private browserAndVendor = 'Unknown browser';
  private farmNames!: string[];

  private selectedFarms$ = this.farmStateService.selectedFarms$;

  constructor(
    private userSelector: UserSelector,
    private browserDetectorService: BrowserDetectorService,
    private correlationIdService: CorrelationIdService,
    private farmStateService: FarmStateService,
    private endpointsService: EndpointsService
  ) {
    this.browserAndVendor = this.browserDetectorService.getVendorAndVersion();
  }

  public initialize() {
    this.logger = new Logger(environment.appName, this.endpointsService.elasticSearchForward);

    this.userSelector.currentUser$.pipe(filterNullish()).subscribe((user) => this.onUserChange(user));
    this.selectedFarms$.subscribe((s) => (this.farmNames = s.map((f) => ` ${f.name}(${f.id})`)));
    // this.farmSelector.farms$.pipe(filter((farms) => !!farms)).subscribe(f => this.farmNames = f.map(fn => fn.name));
  }

  public logHttpInfo(info: any, elapsedTime: number, requestPath: string, headers: HttpHeaders) {
    const url = window.location.href;

    const strHeaders = JSON.stringify(headers);

    const logFields: LogFields = {
      requestPath,
      elapsedTime,
      url,
      uniqueUrl: this.getUniqueUrl(url),
      headers: strHeaders,
      ...this.getStandardLogFields(),
    };

    this.logger.log('Information', `${info}`, logFields);
  }

  public logError(errorMsg: string) {
    const url = window.location.href;

    const logFields: LogFields = {
      requestPath: '',
      elapsedTime: 0,
      url: url,
      uniqueUrl: this.getUniqueUrl(url),
      ...this.getStandardLogFields(),
    };

    this.logger.log('Error', errorMsg, logFields);
  }

  public logWarning(errorMsg: string) {
    const url = window.location.href;

    const logFields: LogFields = {
      requestPath: '',
      elapsedTime: 0,
      url: url,
      uniqueUrl: this.getUniqueUrl(url),
      ...this.getStandardLogFields(),
    };

    this.logger.log('Warning', errorMsg, logFields);
  }

  public logInfo(info: any) {
    const url = window.location.href;

    const logFields: LogFields = {
      requestPath: '',
      elapsedTime: 0,
      url,
      uniqueUrl: this.getUniqueUrl(url),
      ...this.getStandardLogFields(),
    };

    this.logger.log('Information', info, logFields);
  }

  private onUserChange(user: User) {
    this.userId = user.username;
  }

  private getUniqueUrl(url: string): string {
    const s = String(url);
    return s.split('?')[0];
  }

  private getStandardLogFields() {
    return {
      environment: environment.env,
      userId: this.userId,
      browser: this.browserAndVendor,
      correlationId: this.correlationIdService.correlationId,
      farmNames: this.farmNames?.toString(),
    };
  }
}
