import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { BenchmarkChartData } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';
import { BenchmarkFilters } from '@app/map/features/yield-benchmark/benchmark-sidedrawer/chart-filter/chart-filter.component';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SoilTypes } from './benchmark-chart-data-filter.interface';

export const benchmarkChartRepoModule = 'seges.cm.repositories.benchmarkChart';

interface BenchmarkParams {
  farmId?: string;
  farmIds?: string;
  harvestYear?: number;
  cropNormNumber?: number;
  soilTypes?: string;
  numberOfDecimals: number;
  searchDistance?: string;
  municipalityNumber?: number;
  regionId?: number;
  yieldRegionIds?: string;
}

export interface BenchmarkMessages {
  getError: string;
  getPriceError: string;
}

export interface IBenchmarkChartRepo {
  /**
   * Get benchmark chart data.
   */
  get(farmId: number, filters: BenchmarkFilters, numberOfDecimals: number, type: string): Observable<BenchmarkChartData>;

  getPriceData(farmId: number | number[], filters: BenchmarkFilters, numberOfDecimals: number): Observable<BenchmarkChartData | null>;

  getYieldData(farmIds: number | number[], filters: BenchmarkFilters, numberOfDecimals: number): Observable<BenchmarkChartData>;
}

@Injectable({
  providedIn: 'root',
})
export class BenchmarkChartRepo implements IBenchmarkChartRepo {
  private static YIELD_ENDPOINT = 'cropyield';
  private static PRICE_ENDPOINT = 'cropprices';

  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(farmId: number | number[], filters: BenchmarkFilters, numberOfDecimals: number, type: string): Observable<BenchmarkChartData> {
    let options: any = {};
    if (filters.crop) {
      options = {
        params: this.getParams(farmId, filters, numberOfDecimals),
      };
    }

    return this.http
      .get<BenchmarkChartData>(`${this.endpoints.foApi}/benchmark/${type}`, options)

      .pipe(
        catchError((error: HttpErrorResponse) => {
          return observableThrowError(error);
        })
      );
  }

  public getPriceData(farmId: number | number[], filters: BenchmarkFilters, numberOfDecimals: number): Observable<BenchmarkChartData> {
    return this.get(farmId, filters, numberOfDecimals, BenchmarkChartRepo.PRICE_ENDPOINT);
  }

  public getYieldData(farmIds: number | number[], filters: BenchmarkFilters, numberOfDecimals: number): Observable<BenchmarkChartData> {
    return this.get(farmIds, filters, numberOfDecimals, BenchmarkChartRepo.YIELD_ENDPOINT);
  }

  public getParams(farmIds: number | number[], filters: BenchmarkFilters, numberOfDecimals: number): BenchmarkParams {
    const params: BenchmarkParams = {
      farmIds: farmIds.toString(),
      harvestYear: filters.crop?.harvestYear,
      cropNormNumber: filters.crop?.cropNormNumber,
      soilTypes: this.getSoilTypeNumbers(filters.soilTypes) || undefined,
      numberOfDecimals,
      yieldRegionIds: filters.regions?.map((region) => region.id).toString(),
    };

    if (typeof farmIds === 'number') {
      delete params.farmIds;
      params.farmId = farmIds.toString();
    }

    return params;
  }

  public getSoilTypeNumbers(jbTypes: SoilTypes): string {
    let jbString = '';

    if (jbTypes.coarse) {
      jbString += '1,3,';
    }
    if (jbTypes.fine) {
      jbString += '2,4,';
    }
    if (jbTypes.clay) {
      jbString += '5,6,7,8,9,';
    }

    if (jbString.length) {
      jbString = jbString.substring(0, jbString.length - 1);
    }

    return jbString;
  }
}
