import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldService } from '@app/core/field/field.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { filterNullish } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { isInteger } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, filter, first, of, switchMap } from 'rxjs';
import { FieldPlanSideDrawerService } from '../../field-plan-side-drawer.service';
import { ShownComponentEnum } from '../../shown-component-in-side-drawer.enum';
import { ExportFieldsListComponent } from './export-fields-list/export-fields-list.component';

const NO_FARM_SELECTED = -1;

@Component({
  selector: 'app-export-fields',
  templateUrl: './export-fields.component.html',
  styleUrls: ['./export-fields.component.scss'],
})
export class ExportFieldsComponent implements OnInit {
  @ViewChild(ExportFieldsListComponent) exportFieldsListComponent: ExportFieldsListComponent | undefined;

  protected fields$!: Observable<Field[]>;
  protected farms$!: Observable<Farm[]>;
  protected selectedFarmID!: number;
  private _farmId$!: BehaviorSubject<number>;

  constructor(
    private fieldPlanSideDrawerService: FieldPlanSideDrawerService,
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private fieldService: FieldService,
    private notificationService: NotificationService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.fieldPlanSideDrawerService.olMapService.disableSelectInteraction();
    this._farmId$ = new BehaviorSubject(NO_FARM_SELECTED);

    this.fields$ = combineLatest([this._farmId$, this.harvestYearStateService.harvestYear$]).pipe(
      // Filter out null or undefined values
      filter(([farmId, harvestYear]) => farmId !== null && farmId !== undefined && harvestYear !== null && harvestYear !== undefined),
      // Use switchMap to switch to a new fields$ observable whenever either farmId or harvestYear changes
      switchMap(([farmId, harvestYear]) => {
        // prevent the getAllFields function from running if farmId is NO_FARM_SELECTED
        return farmId !== NO_FARM_SELECTED ? this.fieldService.getAllFields([farmId], harvestYear).pipe(filterNullish()) : of([]); // Return an empty array as the fields$ when farmId is NO_FARM_SELECTED
      })
    );

    this.farms$ = this.farmStateService.selectedFarms$;

    this.farms$.pipe(first()).subscribe((farms: Farm[]) => {
      this.selectedFarmID = farms[0].id;
    });
  }

  public farmsTrackByFn(index: number) {
    return index;
  }

  public onFarmSelect(farmid: number) {
    if (!isInteger(farmid)) return;
    this._farmId$.next(farmid);
  }

  public onDownloadClick() {
    if (this._farmId$.value === NO_FARM_SELECTED) {
      this.notificationService.showInfo(this.languageService.getText('main.fieldAdministration.exportFields.errorNoFarm'));
      return;
    }

    const featureIds = this.exportFieldsListComponent?.checkableFields
      .filter((checkable) => checkable.checked$.value === true)
      .map((checkable) => {
        // Filter out fields without geometry
        if (checkable.field.geometry === null || undefined) return undefined;

        if (checkable.field.featureId === 0) return checkable.field.fieldBlocks?.map((fb) => fb.featureId);

        return checkable.field.featureId;
      });

    const flattenedFeatureIds = featureIds?.filter((id): id is number => id !== undefined && id !== 0).flat();

    if (!featureIds || !flattenedFeatureIds || featureIds.length === 0) {
      this.notificationService.showInfo(this.languageService.getText('main.fieldAdministration.exportFields.errorNoFields'));
      return;
    }

    this.harvestYearStateService.harvestYear$.pipe(filterNullish(), first()).subscribe((harvestYear) => {
      this.fieldService.getPolygonShapefile(this._farmId$.value, flattenedFeatureIds, harvestYear).subscribe((resp) => {
        const inputString = resp.headers.get('content-disposition');

        // Regular expression pattern to match the filename value
        const regex = /filename=([^;\n]+)/;

        // Executing the regular expression on the input string
        const match = inputString?.match(regex);

        // Extracting the filename value if a match is found
        const filenameValue = match ? match[1].trim() : null;

        if (!filenameValue || !resp.body) return;

        var blob = new Blob([resp.body], { type: 'application/zip' });
        var url = window.URL.createObjectURL(blob);

        var fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.download = filenameValue;
        fileLink.click();
      });
    });
  }

  public onCloseClick() {
    this.fieldPlanSideDrawerService.showFieldBlockLayerOnMap(false);
    this.fieldPlanSideDrawerService.setShownComponentState(ShownComponentEnum.fieldAdministrationComponent);
    this.fieldPlanSideDrawerService.olMapService.enableSelectInteraction();
    this.fieldPlanSideDrawerService.destroy();
  }
}
