import { OverlayRef } from '@angular/cdk/overlay';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

export interface HideResult {
  hidden: boolean;
}

export class SideDrawerRef<TComponent, TData = unknown, TResult = unknown> {
  private _component!: TComponent;
  private _data: TData;

  public get component() {
    return this._component;
  }

  public get data() {
    return this._data;
  }

  public onClose = new EventEmitter<TResult>();
  public onHide = new EventEmitter<HideResult>();
  public backdropClicked = new EventEmitter<TResult | null>();
  public hidden!: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    public overlayRef: OverlayRef,
    data: TData
  ) {
    this._data = data;
    if (overlayRef) {
      const backdropClickSubscription = overlayRef.backdropClick().subscribe(() => {
        const hasObservers = this.backdropClicked.observers.length > 0;

        if (hasObservers) {
          this.backdropClicked.next(null);
        } else {
          this.close();
        }
      });

      this.subscriptions.push(backdropClickSubscription);
    }
  }

  public setComponent(component: TComponent) {
    this._component = component;
  }

  public close(result?: TResult) {
    const backdrop = this.backdropRef;
    if (backdrop) {
      backdrop.style.display = 'none';
    }
    this.overlayRef.addPanelClass('side-drawer-hidden');
    if (this.overlayRef) {
      setTimeout(() => {
        this.overlayRef.dispose();
      }, 250);
    }

    this.onClose.emit(result);
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public closeWithoutTimeout(result?: TResult) {
    this.overlayRef.addPanelClass('side-drawer-hidden');

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.onClose.emit(result);
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public hide() {
    const backdrop = this.backdropRef;

    if (backdrop) backdrop.style.display = 'none';

    this.overlayRef.addPanelClass('side-drawer-hidden');
    this.onHide.emit({ hidden: true });
    this.hidden = true;
  }

  public show() {
    const backdrop = this.backdropRef;

    if (backdrop) {
      backdrop.style.display = 'block';
    }

    this.overlayRef.removePanelClass('side-drawer-hidden');
    this.onHide.emit({ hidden: false });
    this.hidden = false;
  }

  private get backdropRef() {
    return document.querySelector('.cdk-overlay-backdrop') as HTMLElement | null;
  }
}
