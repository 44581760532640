import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { BasisLayerCategoryDto } from '@app/core/interfaces/basis-layer/basis-layer-category-dto.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasisLayerCategoryRepoService {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(farmIds: number[]): Observable<BasisLayerCategoryDto[]> {
    return this.http.get<BasisLayerCategoryDto[]>(`${this.endpoints.bffApi}/farms/${farmIds}/basislayercategories`);
  }

  /**
   * Creates a set of default basis layer categories for a farm.
   * Should be called first time a farm visits basis layer.
   * @param farmId
   */
  public postDefault(farmId: number): Observable<BasisLayerCategoryDto[] | null> {
    return this.http.post<BasisLayerCategoryDto[], any>(`${this.endpoints.foApi}/farms/${farmId}/basislayercategories/default`, {});
  }

  /**
   * Creates basisLayerCategories on a single farm
   * @param basisLayerCategories
   * @param farmId
   */
  public post(basisLayerCategories: BasisLayerCategoryDto[], farmId: number): Observable<BasisLayerCategoryDto[] | null> {
    return this.http.post<BasisLayerCategoryDto[], BasisLayerCategoryDto[]>(
      `${this.endpoints.bffApi}/farms/${farmId}/basislayercategories`,
      basisLayerCategories
    );
  }

  /**
   * @param basisLayerCategories
   * @param farmId
   * @param ids optional ids for existing entities that will be overwritten. If left empty, the ids from @basisLayerCategories are used
   */
  public put(basisLayerCategories: BasisLayerCategoryDto[], farmId: number, ids?: number[]) {
    const updatedIds = ids ?? basisLayerCategories.map((b) => b.id);
    return this.http.put<BasisLayerCategoryDto[], BasisLayerCategoryDto[]>(
      `${this.endpoints.bffApi}/farms/${farmId}/basislayercategories/${updatedIds}`,
      basisLayerCategories
    );
  }

  /**
   * @param basisLayerCategoryIds Ids to be deleted
   * @param farmId
   */
  public delete(basisLayerCategoryIds: number[], farmId: number): Observable<number[] | null> {
    return this.http.delete(`${this.endpoints.bffApi}/farms/${farmId}/basislayercategories/${basisLayerCategoryIds}`);
  }
}
