import { Injectable } from '@angular/core';
import { UserStateService } from '../services/user/user-state.service';

@Injectable({
  providedIn: 'root',
})
export class UserSelector {
  public currentUser$ = this.userStateService.currentUser$;
  public subscriptionLevel$ = this.userStateService.subscriptionLevel$;
  public formattedSubscriptionLevel$ = this.userStateService.formattedSubscriptionLevel$;

  public constructor(private userStateService: UserStateService) {}
}
