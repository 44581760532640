export interface CellId {
  columnId: ColumnId;
  fieldId: number;
}

export enum ColumnId {
  productionPrice = 0,
  yield = 1,
  unitCost = 2,
  machineCosts = 3,
  landLease = 4,
  otherCosts = 5,
  otherIncome = 6,
  totalCosts = 7,
  fieldscoreCost = 8,
}
