import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureBranchConfig } from '@app/shared/environment-indicator/feature-branch-config';
import { Observable } from 'rxjs';

/**
 * Adds the feature branch header to requests to the BFF
 */
@Injectable()
export class FeatureBranchInterceptor implements HttpInterceptor {
  public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ignore if not API
    if (!httpRequest.url.includes('api')) return next.handle(httpRequest);

    const savedSelectedBranch: string | null = sessionStorage.getItem(FeatureBranchConfig.StorageKey);

    if (savedSelectedBranch) return next.handle(httpRequest.clone({ setHeaders: { 'x-featurebranch': savedSelectedBranch } }));

    return next.handle(httpRequest);
  }
}
