<form [formGroup]="claasTrimbleFormGroup">
  <app-input type="text" formControlName="connectionId" [placeholder]="'main.settings.dataConnections.connectionId' | translate">
    <app-input-error *ngIf="claasTrimbleFormGroup.get('connectionId')?.hasError('required')"
      >{{ 'common.validationMsg.required' | translate }}
    </app-input-error>
  </app-input>
  <app-input type="password" formControlName="connectionCode" [placeholder]="'main.settings.dataConnections.connectionCode' | translate">
    <app-input-error *ngIf="claasTrimbleFormGroup.get('connectionCode')?.hasError('required')">
      {{ 'common.validationMsg.required' | translate }}</app-input-error
    >
  </app-input>
</form>
