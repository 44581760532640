export enum CornHarvestPrognosisErrorCodes {
  SowingDateNotFound = 60001,
  CentroideNotFound = 60002,
  DMINotResponding = 60003,
  QueryDateNotValid = 60004,
  CornModelNotImplemented = 60005,
  CornModelFailed = 60006,
  CornSortNotSupported = 60007,
  CornSortNotSupportedGrainOrCob = 60008,
}
