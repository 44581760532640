import { Injectable, OnDestroy } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { DateHelper } from '@app/helpers/date/date-helper';
import { BlightOverviewStateService } from '@app/new-map/features/cultivation-journal/blight/blight-overview-state.service';
import { BlightRepositoryService } from '@app/new-map/features/cultivation-journal/blight/blight-repository.service';
import {
  BLIGHT_DAYS_IN_PERIOD,
  BLIGHT_EARLIEST_ALLOWED_HARVEST_YEAR,
  BLIGHT_HISTORICAL_DATA_DAYS,
  BLIGHT_PROGNOSIS_DATA_DAYS,
  BLIGHT_PROGNOSIS_END_DAY,
  BLIGHT_PROGNOSIS_END_MONTH,
  BLIGHT_PROGNOSIS_START_DAY,
  BLIGHT_PROGNOSIS_START_MONTH,
} from '@app/new-map/features/cultivation-journal/blight/blight.config';
import { BlightRisk } from '@app/new-map/features/cultivation-journal/blight/models/blight-risk';
import { FieldCrop } from '@app/new-map/features/cultivation-journal/blight/models/field-crop';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest, distinctUntilChanged } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class BlightRiskService implements OnDestroy {
  private readonly ONE_HOUR_IN_MS = 1000 * 60 * 60;
  private _loading = new BehaviorSubject<boolean>(true);
  private _dateRange = new ReplaySubject<[DateTime, DateTime]>(1);
  private _blightRisks = new ReplaySubject<BlightRisk[]>(1);
  public TODAY = DateHelper.todayAsLuxon;

  private subscription = new Subscription();

  private blightRiskCache = this.cacheService.create<BlightRisk[]>({
    defaultTtl: this.ONE_HOUR_IN_MS,
  });

  constructor(
    private blightRepositoryService: BlightRepositoryService,
    private harvestYearStateService: HarvestYearStateService,
    private cacheService: CacheService,
    private blightOverviewStateService: BlightOverviewStateService
  ) {}

  public init() {
    this.initializeDateRange();

    this.subscription.add(
      combineLatest([
        this.harvestYearStateService.harvestYear$,
        this.dateRange$,
        this.isPeriodValid$.pipe(distinctUntilChanged()), // is dependent on dateRange$ so only listen to distinct changes
        this.blightOverviewStateService.selectedFieldCrop$,
      ])
        .pipe(
          // If the period is invalid, no request is sent.
          filter(([harvestYear, dateRange, isPeriodValid, field]) => isPeriodValid),
          tap(() => (this.loading = true)),
          switchMap(([harvestYear, dateRange, _, fieldCrop]) => {
            return this.blightRiskCache.getOrSetAsync(this.getBlightRiskCacheKey(harvestYear, fieldCrop, dateRange), () =>
              this.blightRepositoryService
                .getBlightRisks(fieldCrop.farmId, harvestYear, fieldCrop.fieldId, fieldCrop.cropId, dateRange[0], dateRange[1])
                .pipe(map((blightRiskDtos) => blightRiskDtos.map((blightRiskDto) => new BlightRisk(blightRiskDto))))
            );
          }),
          tap(() => (this.loading = false))
        )
        .subscribe((blightRisks) => this._blightRisks.next(blightRisks))
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  public set loading(isLoading: boolean) {
    this._loading.next(isLoading);
  }

  public get isPeriodValid$(): Observable<boolean> {
    // Period is invalid if not all dates in dateRange are truthy
    return this.dateRange$.pipe(map((dateRange) => !dateRange.includes(null as any)));
  }

  public get canNavigateBack$(): Observable<boolean> {
    return this.dateRange$.pipe(map((dateRange) => dateRange[0] > this.getEarliestAllowedStartDateInYear(dateRange[0]?.year)));
  }

  public get canNavigateForward$(): Observable<boolean> {
    return this.dateRange$.pipe(map((dateRange) => dateRange[1] < this.getLatestAllowedEndDateInYear(dateRange[1]?.year)));
  }

  public get pastRisks$(): Observable<BlightRisk[]> {
    return this._blightRisks.pipe(map((blightRisks) => blightRisks.filter((risk) => this.TODAY > DateTime.fromJSDate(risk.date))));
  }

  public get prognosisRisks$(): Observable<BlightRisk[]> {
    return this._blightRisks.pipe(map((blightRisks) => blightRisks.filter((risk) => this.TODAY <= DateTime.fromJSDate(risk.date))));
  }

  public navigateForward(): void {
    this.navigate(BLIGHT_DAYS_IN_PERIOD);
  }

  public navigateBack(): void {
    this.navigate(BLIGHT_DAYS_IN_PERIOD * -1);
  }

  private get dateRange$(): Observable<[DateTime, DateTime]> {
    return this._dateRange.asObservable();
  }

  private set dateRange(dateRange: [DateTime, DateTime]) {
    this._dateRange.next(dateRange);
  }

  private getBlightRiskCacheKey(harvestYear: number | undefined, fieldCrop: FieldCrop, dateRange: [DateTime, DateTime]): string {
    return `${fieldCrop.farmId}-${harvestYear}-${fieldCrop.fieldId}-${
      fieldCrop.cropId
    }-${dateRange[0].toISODate()}-${dateRange[1].toISODate()}`;
  }

  private initializeDateRange(): void {
    this.harvestYearStateService.harvestYear$.pipe(first()).subscribe((harvestYear) => {
      let startDate: DateTime;
      let endDate: DateTime;
      const prognosisStartDate = DateTime.fromObject({
        year: harvestYear,
        month: BLIGHT_PROGNOSIS_START_MONTH,
        day: BLIGHT_PROGNOSIS_START_DAY,
      });

      const prognosisEndDate = DateTime.fromObject({
        year: harvestYear,
        month: BLIGHT_PROGNOSIS_END_MONTH,
        day: BLIGHT_PROGNOSIS_END_DAY,
      });

      if (harvestYear! < BLIGHT_EARLIEST_ALLOWED_HARVEST_YEAR) {
        this.markDateRangeAsInvalid();
        return;
      }
      // We are in the past or the given harvest year

      if (harvestYear! <= this.TODAY.year) {
        // We are in the valid period in the selected harvest year
        // The square brackets makes isBetween also include days if they are equal to the end dates.
        if (prognosisStartDate <= this.TODAY && this.TODAY <= prognosisEndDate) {
          startDate = this.getStartDate();
          // Start date goes before the earliest allowed date, and is defined as the start date.
          if (startDate < prognosisStartDate) {
            startDate = prognosisStartDate;
          }
          endDate = this.getEndDate();
          // We are in the given harvest year, but too early in the year to show the prognosis.
        } else if (this.TODAY < prognosisStartDate) {
          this.markDateRangeAsInvalid();
          return;
          // We are in the past
        } else {
          endDate = prognosisEndDate;
          // We subtract one from the length, because the end date is included.
          startDate = endDate.minus({ days: BLIGHT_DAYS_IN_PERIOD - 1 });
        }
        // We are in the given harvest year or the future
      } else {
        this.markDateRangeAsInvalid();
        return;
      }
      this.dateRange = [startDate, endDate];
    });
  }

  private markDateRangeAsInvalid(): void {
    this.dateRange = [null as any, null as any];
  }

  private getStartDate(date: DateTime = this.TODAY): DateTime {
    return date.minus({ days: BLIGHT_HISTORICAL_DATA_DAYS });
  }

  private getEndDate(date: DateTime = this.TODAY): DateTime {
    return date.plus({ days: BLIGHT_PROGNOSIS_DATA_DAYS });
  }

  private getEarliestAllowedStartDateInYear(year: number): DateTime {
    return DateTime.fromObject({ year: year, month: BLIGHT_PROGNOSIS_START_MONTH, day: BLIGHT_PROGNOSIS_START_DAY });
  }

  private getLatestAllowedEndDateInYear(year: number): DateTime {
    return DateTime.fromObject({ year: year, month: BLIGHT_PROGNOSIS_END_MONTH, day: BLIGHT_PROGNOSIS_END_DAY });
  }

  private navigate(days: number): void {
    combineLatest([this.loading$, this.dateRange$])
      .pipe(
        first(),
        filter(([loading, dateRange]) => !loading)
      )
      .subscribe(([loading, dateRange]) => {
        const earliestAllowedStartDate = this.getEarliestAllowedStartDateInYear(dateRange[0].year);
        let startDate = dateRange[0].plus({ days: days });
        let endDate = dateRange[1].plus({ days: days });

        if (startDate < earliestAllowedStartDate) {
          startDate = earliestAllowedStartDate;
          endDate = startDate.plus({ days: BLIGHT_DAYS_IN_PERIOD });
        } else {
          const latestAllowedEndDate = this.getLatestAllowedEndDateInYear(dateRange[0].year);
          if (endDate > latestAllowedEndDate) {
            endDate = latestAllowedEndDate;
            startDate = endDate.minus({ days: BLIGHT_DAYS_IN_PERIOD });
          }
        }
        if (!startDate.hasSame(dateRange[0], 'day') || !endDate.hasSame(dateRange[1], 'day')) {
          this.dateRange = [startDate, endDate];
        }
      });
  }
}
