import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';

@Injectable()
export class FeedbackRepository {
  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public postFeedback(summary: string, description: string) {
    return this.http.post<any, { summary: string; description: string }>(`${this.endpoints.foApi}/feedback/jira/createissue`, {
      summary,
      description,
    });
  }

  public postFileToIssue(file: File, issueKey: string) {
    const formData = new FormData();

    formData.append('file', file);

    return this.http.post<any, FormData>(`${this.endpoints.foApi}/feedback/jira/issue/${issueKey}/uploadattachment`, formData);
  }
}
