export const TILESIZE = {
  STANDARD: 256,
  HIGH_RESOLUTION: 512,
  LOW_RESOLUTION: 128,
};

export const PRELOAD_VALUES = {
  OSM: Infinity,
  AIRPHOTO: Infinity,
};

/**
 * Pixel padding around viewport to preload WFS data.
 */
export const WFS_PADDING_VALUE = 2000;
