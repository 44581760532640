import { Component, Input, OnInit } from '@angular/core';
import { BenchmarkDistribution } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';
import { CategoryAxisLabels, SeriesLabels, SeriesPoint } from '@progress/kendo-angular-charts';

/*
 * A chart showing the distribution is a pile chart
 * Has been removed for now
 */

@Component({
  selector: 'app-regional-chart',
  templateUrl: './regional-chart.component.html',
  styleUrls: ['./regional-chart.component.scss'],
})
export class RegionalChartComponent implements OnInit {
  @Input() public series!: BenchmarkDistribution[];
  @Input() public avgValue!: number;
  @Input() public xAxisTitle = '';
  @Input() public chartTitle = '';
  @Input() public unit = '';
  public xLabels!: SeriesLabels;
  public yLabels!: CategoryAxisLabels;

  constructor() {}

  public ngOnInit() {
    this.xLabels = {
      visible: true,
      content: (e) => {
        const data: BenchmarkDistribution = Object.getPrototypeOf(e.dataItem);
        return `${data.lowerBound.toFixed(1)} -\n${data.upperBound.toFixed(1)}`;
      },
      align: 'column',
    };

    this.yLabels = {
      content: (e) => {
        const data: BenchmarkDistribution = Object.getPrototypeOf(e.dataItem);
        const index = this.series.indexOf(data);
        if (index === 0) {
          return `${data.lowerBound.toFixed(1)}`;
        } else if (index === this.series.length - 1) {
          return `${data.upperBound.toFixed(1)}`;
        } else {
          return '';
        }
      },
      visible: true,
      position: 'onAxis',
    };
  }

  public getColor = (point: SeriesPoint) => {
    const data: BenchmarkDistribution = Object.getPrototypeOf(point.dataItem);

    if (data.lowerBound < this.avgValue && data.upperBound > this.avgValue) {
      return '#E95D0F';
    }
    return '#076471';
  };
}
