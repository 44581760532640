import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DEFAULT_CULTURE } from '@app/core/variables';
import { TranslateModule as NgxTranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({
  exports: [NgxTranslateModule],
  imports: [NgxTranslateModule.forChild()],
  providers: [{ provide: DEFAULT_CULTURE, useValue: 'da' }, provideHttpClient(withInterceptorsFromDi())],
})
export class TranslateModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('da');
  }
}
