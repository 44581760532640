import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { latest } from '@app/shared/constants/rxjs-constants';
import { ConfirmDialogData } from '@app/shared/dialog/confirm-dialog-portal/confirm-dialog-data';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm/confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, defaultIfEmpty, delay, map, of, shareReplay, switchMap, take, withLatestFrom } from 'rxjs';
import { AdjustAmountRepository } from '../state/adjust-amount-repository';
import { BasisLayerRepository } from '../state/basis-layer.repository';
import { CellRepository } from '../state/cell.repository';
import { MinMaxRepository } from '../state/min-max.repository';
import { PrescriptionMapQuery } from '../state/prescription-map/prescription-map.query';
import { PrescriptionMapService } from '../state/prescription-map/prescription-map.service';
import { SoilSampleAdjustmentsRepository } from '../state/soil-sample-adjustments.repository';
import { SoilSampleCorrectionsRepository } from '../state/soil-sample-corrections.repository';
import { StatusService } from '../state/status.state';
import { VraRepository } from '../state/vra.repository';

@Injectable({
  providedIn: 'root',
})
export class VraDirtyCheckService {
  constructor(
    private dialog: MatDialog,
    private pmService: PrescriptionMapService,
    private cellRepo: CellRepository,
    private basisLayerRepo: BasisLayerRepository,
    private adjustAmountRepo: AdjustAmountRepository,
    private translate: TranslateService,
    private minMaxRepo: MinMaxRepository,
    private soilSampleAdjustmentsRepo: SoilSampleAdjustmentsRepository,
    private soilSampleCorrectionsRepo: SoilSampleCorrectionsRepository,
    private pmQuery: PrescriptionMapQuery,
    private statusService: StatusService,
    private accessControlService: AccessControlService,
    private vraRepository: VraRepository
  ) {}

  private _isDirty$ = combineLatest([
    this.statusService.status$.pipe(map((status) => status === 'draft')).pipe(defaultIfEmpty(false)),
    this.pmQuery.stateByActiveTask$.pipe(map((status) => status === 'calculated')).pipe(defaultIfEmpty(false)),
    this.vraRepository.activeTask$,
  ]).pipe(
    map(([isDraft, isDirty, activeTask]) => activeTask && (isDraft || isDirty)),
    shareReplay(latest)
  );

  checkDirtyAndAct(): Observable<boolean> {
    return this._isDirty$.pipe(
      take(1),
      withLatestFrom(
        this.translate.get([
          'common.confirmDialog.cancelTitle',
          'common.confirmDialog.cancelTitleDownload',
          'common.confirmDialog.cancelText',
        ]),
        this.accessControlService.hasAccessTo('vra_save_to_database')
      ),
      switchMap(([isDirty, translations, hasAccess]) =>
        isDirty
          ? this.dialog
              .open(ConfirmDialogComponent, {
                data: {
                  title: hasAccess
                    ? translations['common.confirmDialog.cancelTitle']
                    : translations['common.confirmDialog.cancelTitleDownload'],
                } as ConfirmDialogData,
              })
              .afterClosed()
              .pipe(
                map((result) => result ?? false),
                switchMap((result) => {
                  if (!result) return of(false);
                  this.pmService.clearHistory();
                  this.cellRepo.resetHistory();
                  this.basisLayerRepo.resetHistory();
                  this.adjustAmountRepo.resetHistory();
                  this.minMaxRepo.resetHistory();
                  this.soilSampleAdjustmentsRepo.resetHistory();
                  this.soilSampleCorrectionsRepo.resetHistory();
                  return of(true).pipe(delay(500)); // Delay to allow the history to be cleared before navigating
                })
              )
          : of(true)
      )
    );
  }
}
