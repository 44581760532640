/**
 * Generator function for creating unique IDs
 */
function* generateTableId() {
  // The initial value
  let x = 0;
  // On the first call, x is returned
  yield x;
  // Keeps the generator alive
  while (1) {
    // Increase ID by one for each call
    x++;
    // Return the new unique ID
    yield x;
  }
}

/**
 * Generates a new unique ID
 */
export const generator = generateTableId();
