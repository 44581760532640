import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SearchComponent } from '@app/shared/search/search.component';
import { SearchService } from '@app/shared/search/search.service';
import { TranslateModule } from '@app/shared/translate/translate.module';

@NgModule({
  imports: [CommonModule, TranslateModule, MatIconModule, MatFormFieldModule, MatInputModule],
  providers: [SearchService],
  declarations: [SearchComponent],
  exports: [SearchComponent],
})
export class SearchModule {}
