export enum GrassPrognosisErrorCodes {
  ModelNotValidForActualParameters = 'ModelNotValidForActualParameters',
  DMINotResponding = 'DMINotResponding',
  VarietyMissing = 'VarietyMissing',
  VarietyUnknown = 'VarietyUnknown',
  CutNumberNotOne = 'CutNumberNotOne',
  Error1007 = '1007',
  Error1008 = '1008',
  Error1009 = '1009',
  Error1010 = '1010',
  Error1012 = '1012',
}
