<mat-form-field>
  <input
    type="text"
    [formControl]="hotspotSubTypeIdsControl"
    matInput
    placeholder="{{ 'main.fieldmap.hotspotInfo.chooseSubTypes' | translate }}"
    aria-label="State"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="autoCompleteDisplay" (optionSelected)="onAddHotspotSubType($event)">
    <mat-option *ngFor="let subType of hotspotSubTypes" [value]="subType">{{ subType.name }}</mat-option>
  </mat-autocomplete>
</mat-form-field>

<div *ngIf="isAnySubTypeSelected">
  <app-hotspot-subtype-item
    [canRemove]="true"
    *ngFor="let subType of selectedHotspotSubTypes; trackBy: trackByFn"
    (onRemove)="onRemoveSubType(subType)"
    >{{ subType?.name }}</app-hotspot-subtype-item
  >
</div>
