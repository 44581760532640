import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SoilSampleLegend } from '@app/core/repositories/soil-samples/soil-sample-legend.class';

@Component({
  selector: 'app-jb-legend',
  templateUrl: './jb-legend.component.html',
  styleUrls: ['./jb-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JbLegendComponent {
  @Input() public legend!: SoilSampleLegend;
}
