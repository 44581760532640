import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: ['./product-info-dialog.component.scss'],
})
export class ProductInfoDialogComponent {
  public text;

  constructor(
    public dialogRef: MatDialogRef<ProductInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.text = data;
  }

  public close() {
    this.dialogRef.close();
  }
}
