<div *ngIf="visible" class="indicator">
  <ng-container *ngIf="branches$ | async as branches">
    <p *ngIf="branches.length === 0; else featureBranch">{{ environment }}</p>

    <ng-template #featureBranch>
      <div class="environment-feature-indicator">
        <div>{{ environment }}</div>
        <label>
          <select class="feature-branch-select" (change)="changeFeatureBranch($event)" [ngModel]="selectedBranch">
            <option *ngFor="let branch of branches" [value]="branch">
              {{ branch }}
            </option>
          </select>
        </label>
      </div>
    </ng-template>
  </ng-container>
</div>
