import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  host: {
    class: 'pulse', // from global animations.scss
  },
  template: ``,
  styles: [
    `
      :host {
        display: block;
        width: var(--skeleton-rect-width);
        height: var(--skeleton-rect-height);
        border-radius: 5px;
        margin-bottom: var(--skeleton-rect-margin-bottom);

        /* TODO: Make variants with different shades for different backgrounds */
        background: #d5d5d5 no-repeat;
      }
    `,
  ],
})
export class SkeletonComponent implements OnInit {
  @Input() width = '100%';
  @Input() height = '20px';
  @Input() bottomMargin = '0px';
  @Input() className?: string;

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-rect-width', this.width);
    host.style.setProperty('--skeleton-rect-height', this.height);
    host.style.setProperty('--skeleton-rect-margin-bottom', this.bottomMargin);
  }
}
