<app-benchmark-chart-filter (filterChange)="filters$.next($event)"></app-benchmark-chart-filter>

<div class="loading mt-30" *ngIf="!isReady">
  <app-spinner></app-spinner>
</div>

<ng-container *ngIf="cropName && cropName !== '' && isReady">
  <h5>{{ 'main.benchmark.regionalComparison' | translate }} {{ regionTitle }}</h5>
  <p *ngIf="chartData.avgFieldBenchmarkValue === 0" class="no-yield-text">{{ 'main.benchmark.noYieldRegistered' | translate }}</p>

  <app-regional-comparison-chart
    *ngIf="chartData.benchmarkDistributionPct.length"
    [series]="chartData.benchmarkDistributionPct"
    [unit]="chartData.unit"
    [userYeldAverage]="chartData.avgFieldBenchmarkValue"
  >
  </app-regional-comparison-chart>
  <p *ngIf="!chartData.benchmarkDistributionPct.length">{{ 'main.benchmark.insufficientData' | translate }}</p>

  <app-chart-info
    *ngIf="chartData.benchmarkValue"
    [regionalAverage]="chartData.benchmarkValue"
    [personalAverage]="chartData.avgFieldBenchmarkValue"
    [fields]="chartData.benchmarkFieldCount!"
    [area]="chartData.benchmarkFieldArea!"
    [unit]="chartData.unit"
  >
  </app-chart-info>

  <h5>{{ 'main.benchmark.ownFieldsComparison' | translate }}</h5>
  <app-farm-chart
    *ngFor="let farm of chartData.farms"
    [farm]="farm"
    [avgFieldBenchmarkValue]="chartData.avgFieldBenchmarkValue"
    yAxisTitle="{{ 'common.yield' | translate }} {{ chartData.unit }}"
  >
  </app-farm-chart>
  <p *ngIf="!chartData.farms.length">{{ 'main.benchmark.insufficientData' | translate }}</p>
</ng-container>
