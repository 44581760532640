import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DirtyState } from './dirty-state.class';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckService {
  public isAppDirty$ = new BehaviorSubject<boolean>(false);
  private _dirtyArray: DirtyState[] = [];

  public get isAppDirty() {
    return this._dirtyArray.some((item) => item.isDirty);
  }

  /**
   * Sets and adds tracking of a state to be considered when dirty checking the application.
   * @param classInstance The class that sets the state (usually 'this')
   * @param isDirty The state
   */
  public setIsFeatureDirty(classInstance: any, isDirty: boolean, moduleName = 'No module set') {
    const name = classInstance.constructor.name;
    this._dirtyArray = [...this._dirtyArray.filter((state) => state.className !== name), new DirtyState(name, isDirty, moduleName)];
    this.isAppDirty$.next(this.isAppDirty);
  }

  public isFeatureDirty(classInstance: any) {
    const name = classInstance.constructor.name;
    return !!this._dirtyArray.find((state) => state.className === name && state.isDirty);
  }

  public getDirtyModuleNames() {
    return this._dirtyArray.map((state) => state.moduleName);
  }

  /**
   * Clear all dirty states. Usefully if dirty check is performed twice.
   */
  public clearAllStates() {
    this._dirtyArray = [];
  }

  /**
   * Call this to print the currently tracked states.
   */
  public debug() {
    // eslint-disable-next-line no-console
    console.log('DirtyCheckService tracked states', this._dirtyArray);
  }
}
