export const enum WarningEnum {
  CAN_NOT_ADJUST_PLANNED_AMOUNT = 1001,
  LIME_NEED_TOO_HIGH = 1002,
  NO_USABLE_SOIL_SAMPLE = 1003,
  FIELD_TOO_SMALL = 1004,
  GEOMETRIC_ERROR = 1005,
  FALTY_DATA = 1006,
  MISSING_DATA = 1007,
  ZERO_DOSE = 1008,
}

// Warnings that still permit the user to save
export const NON_BREAKING_WARNINGS = [WarningEnum.LIME_NEED_TOO_HIGH];
