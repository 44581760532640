<div class="header-close">
  <h4>{{ 'main.growthRegulation.insurance.info.dialog.title' | translate }}</h4>
  <app-icon class="close-btn" icon="Luk" (click)="closeDialog()"></app-icon>
</div>
<mat-dialog-content>
  <p>{{ 'main.growthRegulation.insurance.info.dialog.paragraph1' | translate }}</p>
  <p>{{ 'main.growthRegulation.insurance.info.dialog.paragraph2' | translate }}</p>
  <p>{{ 'main.growthRegulation.insurance.info.dialog.paragraph3' | translate }}</p>
  <p>{{ 'main.growthRegulation.insurance.info.dialog.paragraph4' | translate }}</p>
  <p>{{ 'main.growthRegulation.insurance.info.dialog.paragraph5' | translate }}</p>
  <p>
    {{ 'main.growthRegulation.insurance.info.dialog.paragraph6' | translate }}
    <a href="https://www.landbrugsinfo.dk/public/a/4/5/plantebeskyttelse_forsikring_mod_lejesad_vinterhvede_vinterrug" target="_blank">{{
      'main.growthRegulation.insurance.info.dialog.landbrugsInfoLink' | translate
    }}</a>
  </p>
  <p></p>
  <table>
    <thead>
      <tr>
        <th class="col1">{{ 'main.growthRegulation.insurance.info.dialog.category' | translate }}</th>
        <th class="col2"></th>
        <th class="col3">{{ 'main.growthRegulation.insurance.info.dialog.green' | translate }}</th>
        <th class="col4">{{ 'main.growthRegulation.insurance.info.dialog.yellow' | translate }}</th>
        <th class="col5">{{ 'main.growthRegulation.insurance.info.dialog.red' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="col1">{{ 'main.growthRegulation.insurance.winterWheat' | translate }}</td>
        <td class="col2">{{ 'main.growthRegulation.insurance.info.dialog.pricePerHa' | translate }}</td>
        <td class="col3">{{ prices.winterWheat.green }}</td>
        <td class="col4">{{ prices.winterWheat.yellow }}</td>
        <td class="col5">{{ prices.winterWheat.red }}</td>
      </tr>
      <tr>
        <td class="col1">{{ 'main.growthRegulation.insurance.winterRye' | translate }}</td>
        <td class="col2">{{ 'main.growthRegulation.insurance.info.dialog.pricePerHa' | translate }}</td>
        <td class="col3">{{ prices.winterRye.green }}</td>
        <td class="col4">{{ prices.winterRye.yellow }}</td>
        <td class="col5">{{ prices.winterRye.red }}</td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
