<app-chart-wrapper [showSpinner]="isLoading" loadingMessage="rainChart.fetchingData">
  <app-square-button class="info-button" (click)="showInfoDialog()">
    <app-icon class="info" icon="info_outline"></app-icon>
    {{ 'rainChart.openInfo' | translate }}
  </app-square-button>
  <kendo-chart
    [transitions]="false"
    [categoryAxis]="categoryAxis"
    [valueAxis]="valueAxis"
    [pannable]="{ lock: 'y' }"
    [zoomable]="{ mousewheel: { lock: 'y' } }"
  >
    <kendo-chart-tooltip>
      <ng-template kendoChartSeriesTooltipTemplate let-value="value">
        {{ value | number: '1.2-2' }} {{ 'common.unitType.mm' | translate }} pr. {{ 'common.unitType.hour' | translate }}
      </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend position="bottom" orientation="horizontal" [item]="{ visual: legendItemVisual }"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="column"
        color="#4E808D"
        name="{{ 'rainChart.observed' | translate }}"
        [markers]="seriesMarkers"
        [data]="observed"
        field="prec"
        categoryField="dateDay"
      >
      </kendo-chart-series-item>
      <kendo-chart-series-item
        type="column"
        color="#7DA3AD"
        name="{{ 'rainChart.forecast' | translate }}"
        [markers]="seriesMarkers"
        [data]="forecast"
        field="prec"
        categoryField="dateDay"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <div *ngIf="!isLoading && observed.length + forecast.length <= 0" class="no-data">{{ 'rainChart.noData' | translate }}</div>
</app-chart-wrapper>
