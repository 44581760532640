import { Injectable } from '@angular/core';
import { DroneImageInformation } from '@app/core/interfaces/drone-image-information';
import { DroneImageInformationRepoService } from '@app/core/repositories/drone-images/drone-image-information.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest, Observable, EMPTY, ReplaySubject } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Injectable()
export class DroneImageImportStateService {
  private droneImageInformationSubject = new ReplaySubject<DroneImageInformation[]>(1);
  private selectedFieldSubject = new ReplaySubject<number | null>(1);
  private selectedFieldNameSubject = new ReplaySubject<string | null>(1);

  public get droneImageInformation$(): Observable<DroneImageInformation[]> {
    return this.droneImageInformationSubject.asObservable();
  }

  public get selectedField$(): Observable<number | null> {
    return this.selectedFieldSubject.asObservable();
  }
  public get selectedFieldName$(): Observable<string | null> {
    return this.selectedFieldNameSubject.asObservable();
  }

  public set selectedField(featureId: number) {
    this.selectedFieldSubject.next(featureId);
  }
  public set selectedFieldName(fieldName: string | null) {
    this.selectedFieldNameSubject.next(fieldName);
  }
  constructor(
    private droneImageInformationRepoService: DroneImageInformationRepoService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService
  ) {}

  public hydrateDroneImageInformation() {
    combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$])
      .pipe(
        first(),
        switchMap(([farms, harvestYear]) => {
          const ids = farms.map((f) => f.id);

          if (!harvestYear) return EMPTY;

          return this.droneImageInformationRepoService.get(ids, harvestYear);
        })
      )
      .subscribe((droneInformation) => {
        this.droneImageInformationSubject.next(droneInformation);
      });
  }

  public resetSelectedField() {
    this.selectedFieldNameSubject.next(null);
    this.selectedFieldSubject.next(null);
  }
}
