import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _specificLoadingsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public get isLoading$(): Observable<boolean> {
    return this._loadingSubject.asObservable();
  }

  public get specificLoadings$(): Observable<string[]> {
    return this._specificLoadingsSubject.asObservable();
  }

  public constructor() {}

  public isSpecificLoading$(loaderName: string): Observable<boolean> {
    return combineLatest([this.isLoading$, this._specificLoadingsSubject]).pipe(
      map(([isLoading, specificLoadings]) => {
        if (isLoading) {
          return false;
        } else {
          return specificLoadings.some((specificLoading) => specificLoading === loaderName);
        }
      })
    );
  }

  public get combinedIsLoading$(): Observable<boolean> {
    return combineLatest([this.isLoading$, this.specificLoadings$]).pipe(
      map(([isLoading, isSpecificLoading]) => {
        if (isSpecificLoading.length > 0) {
          return true;
        }

        return isLoading;
      })
    );
  }

  public loadingStarted() {
    this._loadingSubject.next(true);
  }

  public loadingCompleted() {
    this._loadingSubject.next(false);
  }

  public specificLoadingStarted(loaderName: string) {
    this._specificLoadingsSubject
      .pipe(
        first(),
        map((specificLoadings) => {
          return specificLoadings.filter((specificLoading) => specificLoading !== loaderName);
        })
      )
      .subscribe((specificLoadingsFiltered) => {
        specificLoadingsFiltered.push(loaderName);
        this._specificLoadingsSubject.next(specificLoadingsFiltered);
      });
  }

  public specificLoadingCompleted(loaderName: string) {
    this._specificLoadingsSubject
      .pipe(
        first(),
        map((specificLoadings) => {
          return specificLoadings.filter((specificLoading) => specificLoading !== loaderName);
        })
      )
      .subscribe((specificLoadingsFiltered) => {
        this._specificLoadingsSubject.next(specificLoadingsFiltered);
      });
  }
}
