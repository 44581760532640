<div class="blight-date-picker-content">
  <div>
    <div class="title">
      <h5 class="info">{{ 'main.potatoBlight.datePicker.title' | translate }}</h5>
    </div>
    <div class="seges-calendar">
      <kendo-calendar [min]="minDate" [max]="maxDate" [value]="startDate" (valueChange)="onDateSelect($event)"></kendo-calendar>
    </div>
  </div>
</div>
