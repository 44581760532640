<div class="main-wrapper">
  <app-field-analysis-feature-picker
    *appSideIf="(fieldAnalysisShownComponent$ | async)!; index: components.FieldAnalysisFeaturePickerComponent"
    [fieldAnalysisMapFeatures]="mapCoverFlowItems"
    (selectedMapFeatureChanged)="onMapFeatureSelect($event)"
  >
  </app-field-analysis-feature-picker>
  <app-drone-image-import
    *appSideIf="(fieldAnalysisShownComponent$ | async)!; index: components.DroneImageImportComponent"
  ></app-drone-image-import>
  <app-ndvi-feature *appSideIf="(fieldAnalysisShownComponent$ | async)!; index: components.NdviFeatureComponent"></app-ndvi-feature>
  <app-soil-sample-feature
    *appSideIf="(fieldAnalysisShownComponent$ | async)!; index: components.SoilSampleMapFeatureComponent"
  ></app-soil-sample-feature>
  <app-as-applied *appSideIf="(fieldAnalysisShownComponent$ | async)!; index: components.AsAppliedComponent"></app-as-applied>
</div>
