import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-drawer-content',
  templateUrl: './side-drawer-content.component.html',
  styleUrls: ['./side-drawer-content.component.scss'],
})
export class SideDrawerContentComponent implements OnInit, OnDestroy {
  @HostBinding('class.mobile') public isMobile = false;
  private subscriptions: Subscription[] = [];

  constructor(private screenSize: ScreenSizeService) {}

  public ngOnInit() {
    this.subscriptions.push(this.screenSize.isMobile().subscribe((isMobile) => (this.isMobile = isMobile)));
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }
}
