import { DateTime } from 'luxon';

/**
 * Helper class for comparing number, string and Date types
 */
export class CompareHelper {
  /**
   Compares dates to see how late or early the time is.
   Year, month and day is ignored, only time is used.
   Returns 1 if a is later or equal to b, and -1 if b is later than a.
   Returns 0 if a or b are undefined or invalid.
   Return values are reversed if isAscending is false.
   */
  public static compareTimeOfDay(a: DateTime | null | undefined, b: DateTime | null | undefined, isAscending: boolean = true): number {
    if (!a || !b) {
      return 0;
    }

    const aHours = a.hour;
    const aMinutes = a.minute;
    const aTotalMinutes = aHours * 60 + aMinutes;
    const bHours = b.hour;
    const bMinutes = b.minute;
    const bTotalMinutes = bHours * 60 + bMinutes;
    return this.compare(aTotalMinutes, bTotalMinutes, isAscending);
  }

  /**
   Compares two durations of time, created by duration between stop and start, minus pause.
   */
  public static compareTimeDuration(
    aStart: DateTime | string,
    aStop: DateTime | undefined,
    aPause: number,
    bStart: DateTime,
    bStop: DateTime,
    bPause: number,
    isAscending: boolean = true
  ): number {
    if (aStop === undefined || bStart === undefined || bStop === undefined) {
      return 0;
    }

    if (!aStop.isValid || !bStart.isValid || !bStop.isValid) {
      return 0;
    }

    if (!(aStart instanceof DateTime)) {
      aStart = DateTime.fromJSDate(new Date(aStart));

      if (!aStart.isValid) return 0;
    }

    // If pause is undefined, it is set to 0
    aPause = aPause ? aPause : 0;
    bPause = bPause ? bPause : 0;

    const aDiff = (aStop.toMillis() - aStart.toMillis()) / 1000 - aPause * 60;
    const bDiff = (bStop.toMillis() - bStart.toMillis()) / 1000 - bPause * 60;
    if (isNaN(aDiff) || isNaN(bDiff)) {
      return 0;
    }
    return this.compare(aDiff, bDiff, isAscending);
  }

  /**
   Compares two field numbers.
   Returns 0 if both are invalid.
   */
  public static compareFieldNumbers(
    fieldNumberA: string | undefined,
    fieldNumberB: string | undefined,
    isAscending: boolean = true
  ): number {
    if (fieldNumberA === undefined || fieldNumberA === null) {
      // If both a and b equals null or undefined, 0 is returned
      if (fieldNumberB === undefined || fieldNumberB === null) {
        return 0;
      }
      // If a equals undefined or null, but b doesnt, b is considered larger than a
      return -1 * (isAscending ? 1 : -1);
    }
    // If b equals undefined or null, but a doesnt, a is considered larger than b
    if (fieldNumberB === undefined || fieldNumberB === null) {
      return 1 * (isAscending ? 1 : -1);
    }

    // A valid field number contains exactly two numbers, separated by the '-' character.
    // The string are split by the '-' character.
    // Example
    // fieldNumberA = "35-11"
    // a: [35,11]
    const a = fieldNumberA.split('-');
    const b = fieldNumberB.split('-');

    if (a.length !== 2 || isNaN(+a[0]) || isNaN(+a[1])) {
      if (b.length !== 2 || isNaN(+b[0]) || isNaN(+b[1])) {
        // If both a and b are invalid, 0 is returned.
        return 0;
      }
      // If a is invalid, but b is not, b is considered larger than a.
      return -1 * (isAscending ? 1 : -1);
    }
    // If b is invalid, but a is not, a is considered larger than b.
    if (b.length !== 2 || isNaN(+b[0]) || isNaN(+b[1])) {
      return 1 * (isAscending ? 1 : -1);
    }

    // If the first number in a is larger than the first number in b, a is considered larger than b
    if (+a[0] > +b[0]) {
      return 1 * (isAscending ? 1 : -1);
    }

    // If the first number in a is equal to the first number in b, and the second number in a is larger than or equal to the second number in b, a is considered larger than b
    if (+a[0] === +b[0] && +a[1] >= +b[1]) {
      return 1 * (isAscending ? 1 : -1);
    }

    // Otherwise b is considered larger than a
    return -1 * (isAscending ? 1 : -1);
  }

  public static compareDatesWithoutTime(a: DateTime, b: DateTime, isAscending: boolean = true): number {
    return this.compare(a, b, isAscending);
  }

  /**
   Compares inputs a and b.
   Returns 1 if a is larger or equal to b, and -1 if b is larger than a.
   Returns 0 if a or b are undefined or invalid.
   Return values are reversed if isAscending is false.
   Default value for isAscending is true
   Can compare number, string, boolean and Date types
   */
  public static compare<T>(a: T, b: T, isAscending: boolean = true): number {
    // ! cannot be used, because numbers are compared.
    // If a equals null or undefined, but b doesnt, b is considered larger than a
    if (a === undefined || a === null) {
      // If both a and b equals null or undefined, 0 is returned
      if (b === undefined || b === null) {
        return 0;
      }

      return -1 * (isAscending ? 1 : -1);
    }

    // If b equals undefined or null, but a doesnt, a is considered larger than b
    if (b === undefined || b === null) {
      return 1 * (isAscending ? 1 : -1);
    }

    // if not number
    if (isNaN(+a) || isNaN(+a)) {
      return (a < b ? -1 : 1) * (isAscending ? 1 : -1);
    } else {
      // sort order
      if (isAscending) return (a as number) - (b as number);
      return (b as number) - (a as number);
    }
  }

  public static compareObjectValues(obj1: any, obj2: any): boolean {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If number of properties is different, objects are not equal
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Compare values of each key
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    // If we reached this point, objects are equal
    return true;
  }
}
