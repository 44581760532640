import { Injectable } from '@angular/core';
import { filterNullish, filterNullOrEmpty } from '@app/shared/operators';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest, distinctUntilChanged, map, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiParamsService {
  constructor(
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService
  ) {}

  private _selectedFarmIds$ = this.farmStateService.selectedFarmIds$.pipe(
    filterNullOrEmpty(),
    map((x) => x.join(',')),
    distinctUntilChanged()
  );

  private _harvestYear$ = this.harvestYearStateService.harvestYear$.pipe(filterNullish(), distinctUntilChanged());

  /**
   * Currently selected farm IDs and harvest year.
   *
   * Shared across repositories in VRA.
   */
  public farmAndHarvestYear$ = combineLatest([this._selectedFarmIds$, this._harvestYear$]).pipe(
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  public farms$ = this._selectedFarmIds$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));
}
