<form [formGroup]="form">
  <mat-card appearance="outlined" *ngIf="(selectableFarms$ | async)?.length! > 1" class="farm-selector">
    <mat-card-content>
      <app-select
        formControlName="farmId"
        [placeholder]="'main.fieldmap.hotspotInfo.farm' | translate"
        (valueChange)="OnFarmSelectionChange($event)"
      >
        <app-select-option *ngFor="let farm of selectableFarms$ | async; trackBy: trackByFn" [value]="farm.id">
          {{ farm.name }}
        </app-select-option>
      </app-select>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-title class="file-picker-title">{{ 'main.fieldmap.droneImageImport.informationOnImageSet' | translate }}</mat-card-title>
    <mat-card-content>
      <!-- Name -->
      <div class="input-line name-line">
        {{ 'main.fieldmap.droneImageImport.imageSetName' | translate }}

        <mat-form-field class="long-input">
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="2" formControlName="name"></textarea>
          <mat-error *ngIf="form.get('name')?.hasError('required') && form.get('name')?.touched">
            {{ 'main.fieldmap.droneImageImport.nameRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('name')?.hasError('maxlength') && form.get('name')?.touched">
            {{ 'main.fieldmap.droneImageImport.nameLengthExceeded' | translate }}
          </mat-error>
        </mat-form-field>

        <app-tooltip-overlay size="sm" [tooltipTemplate]="nameTooltip" (click)="$event.stopPropagation()">
          <app-icon icon="info_outline" class="info-hover"> </app-icon>
        </app-tooltip-overlay>
        <ng-template #nameTooltip>
          <div class="tooltip-wrapper">
            <h3>{{ 'main.fieldmap.droneImageImport.imageSetName' | translate }}</h3>
            <p>{{ 'main.fieldmap.droneImageImport.imageSetName-info-body' | translate }}</p>
          </div>
        </ng-template>
      </div>

      <!-- Date -->
      <div class="input-line date-line">
        {{ 'main.fieldmap.droneImageImport.imageSetDate' | translate }}

        <div>
          <app-date-picker formControlName="date" class="long-input" [simple]="true" [maxDate]="maxDate" [minDate]="minDate">
          </app-date-picker>
          <mat-error class="custom-error" *ngIf="form.get('date')?.hasError('required')">
            {{ 'main.fieldmap.droneImageImport.dateRequired' | translate }}
          </mat-error>
          <mat-error class="custom-error" *ngIf="form.get('date')?.hasError('maxDate')">
            {{ 'main.fieldmap.droneImageImport.invalidMaxDate' | translate }}
          </mat-error>
          <mat-error class="custom-error" *ngIf="form.get('date')?.hasError('minDate')">
            {{ 'main.fieldmap.droneImageImport.invalidMinDate' | translate }}
          </mat-error>
        </div>
        <app-tooltip-overlay size="sm" [tooltipTemplate]="dateTooltip" (click)="$event.stopPropagation()">
          <app-icon icon="info_outline" class="info-hover"> </app-icon>
        </app-tooltip-overlay>
        <ng-template #dateTooltip>
          <div class="tooltip-wrapper">
            <h3>{{ 'main.fieldmap.droneImageImport.imageSetDate' | translate }}</h3>
            <p>{{ 'main.fieldmap.droneImageImport.imageSetDate-info-body' | translate }}</p>
          </div>
        </ng-template>
      </div>

      <!-- Harvest year -->
      <div class="input-line">
        {{ 'main.fieldmap.droneImageImport.fieldPolygonsHarvestYear' | translate }}
        <app-select formControlName="harvestYear" class="short-input">
          <app-select-option *ngFor="let harvestYear of selectableHarvestYears$ | async" [value]="harvestYear">
            {{ harvestYear }}
          </app-select-option>
        </app-select>
        <app-tooltip-overlay size="sm" [tooltipTemplate]="harvestYearTooltip" (click)="$event.stopPropagation()">
          <app-icon icon="info_outline" class="info-hover"> </app-icon>
        </app-tooltip-overlay>
        <ng-template #harvestYearTooltip>
          <div class="tooltip-wrapper">
            <h3>{{ 'main.fieldmap.droneImageImport.fieldPolygonsHarvestYear' | translate }}</h3>
            <p>{{ 'main.fieldmap.droneImageImport.fieldPolygonsHarvestYear-info-body' | translate }}</p>
          </div>
        </ng-template>
      </div>

      <br />
      <br />
      <div class="upload-wrapper">
        <app-square-button buttonType="Primary" (clicked)="filePicker.click()">{{
          'main.fieldmap.droneImageImport.selectImages' | translate
        }}</app-square-button>
        <input
          multiple
          hidden
          #filePicker
          type="file"
          (change)="onFileInputChange($event)"
          [accept]="[fileFormats.Jpeg, fileFormats.Jpg, fileFormats.Tiff].join(',')"
        />
      </div>
      <ng-container *ngIf="imageError$ | async">
        <p class="image-button-error">{{ imageError$ | async }}</p>
      </ng-container>

      <app-field-picker> </app-field-picker>
    </mat-card-content>
  </mat-card>

  <app-price *ngIf="(totalHa$ | async)! > 0" [totalHa$]="totalHa$"></app-price>
</form>
