<mat-card appearance="outlined" *ngIf="dataItem$ | async as item" [style.min-height]="minHeight">
  <mat-card-header class="no-overflow">
    <div mat-card-avatar *ngIf="avatar$ | async as avatar" [style.background-image]="avatar$ | async" style="background-size: cover"></div>
    <mat-card-title>{{ cardTitle$ | async }}</mat-card-title>
    <mat-card-subtitle *ngIf="cardSubTitle$ | async as cardSubTitle">{{ cardSubTitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-list class="card-list">
      <mat-list-item *ngFor="let fieldDefinition of filteredFieldDefinitions$ | async">
        <h4 mat-line>{{ fieldDefinition.name! | translate }}</h4>
        <p *ngIf="fieldDefinition.templateRef; else readProperty" mat-line>
          <ng-container [ngTemplateOutlet]="fieldDefinition.templateRef" [ngTemplateOutletContext]="{ data: item }"></ng-container>
        </p>
        <ng-template #readProperty>
          <ng-container *ngIf="item[$any(fieldDefinition.property)]">
            <!-- TODO: What does this "empty" section do ? - Deleted -->
            <!--            <p mat-line [class.empty-field]="item.__empty[fieldDefinition.property]">{{ getItemProperty(item, fieldDefinition.property)-->
            <!--              | translate }}-->
            <!--            </p>-->
            <p mat-line>{{ getItemProperty(item, fieldDefinition.property) | translate }}</p>
          </ng-container>
        </ng-template>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions *ngIf="actionDefinitions?.length! > 0" align="end">
    <ng-container *ngFor="let actionDefinition of actionDefinitions">
      <div class="row" *ngIf="actionDefinition.templateRef">
        <div class="col s12">
          <ng-container [ngTemplateOutlet]="actionDefinition.templateRef" [ngTemplateOutletContext]="item"></ng-container>
        </div>
      </div>
      <button
        mat-icon-button
        *ngIf="actionDefinition.icon"
        [color]="actionDefinition.color"
        (click)="triggerAction(item, actionDefinition)"
        [title]="actionDefinition.title! | translate"
      >
        <app-icon [icon]="actionDefinition.icon"> </app-icon>
      </button>
    </ng-container>
  </mat-card-actions>
</mat-card>
