import { AfterContentInit, Component, ContentChildren, EventEmitter, forwardRef, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LanguagePickerService } from '@app/core/language-picker/language-picker.service';
import { ScreenSizeService } from '@app/core/screen-size/screen-size.service';
import { InputErrorStateMatcher } from '@app/shared/input/input-error-state-matcher.class';
import { InputErrorComponent } from '@app/shared/input/input-error/input-error.component';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: LuxonDateAdapter,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor, OnDestroy, AfterContentInit {
  @Output() public dateChange = new EventEmitter<DateTime>();
  @Input() public placeholder = '';
  @Input() public disabled = false;
  @Input() public showIcon = false;
  @Input() public simple = false;
  @Input() public noMobileKeyboard = true;
  @Input() public matchInstantly = false;
  @Input() public maxDate: DateTime | string | null = null;
  @Input() public minDate: DateTime | string | null = null;
  @Input() public readonly: boolean = false;
  @Input()
  public set date(date: DateTime | string | undefined | null) {
    if (typeof date === 'string') this._date = DateTime.fromISO(date);
    else this._date = date?.isValid ? date : (null as any);
  }
  public get date(): DateTime | null {
    return this._date!;
  }
  private _errorMsgs!: QueryList<InputErrorComponent>;
  public get errorMsgs(): QueryList<InputErrorComponent> {
    return this._errorMsgs;
  }
  @ContentChildren(InputErrorComponent)
  public set errorMsgs(v: QueryList<InputErrorComponent>) {
    this._errorMsgs = v;
  }
  private _date!: DateTime;
  public touched = false;
  public errorStateMatcher: ErrorStateMatcher | null = null;
  private subscriptions: Subscription[] = [];
  constructor(
    languagePickerService: LanguagePickerService,
    private screenSizeService: ScreenSizeService,
    private adapter: DateAdapter<LuxonDateAdapter>
  ) {
    this.subscriptions.push(
      languagePickerService.getLangShortCode$().subscribe((langShortCode) => {
        this.adapter.setLocale(langShortCode);
      })
    );
  }
  public ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.matchInstantly) {
        this.errorStateMatcher = new InputErrorStateMatcher(this.errorMsgs, true);
      } else {
        this.errorStateMatcher = new InputErrorStateMatcher(this.errorMsgs, false);
      }
    });
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  public onChange: any = (_: any) => {
    /*Empty*/
  };
  public onTouched: any = (_: any) => {};
  public onDateChange(dateEvent: MatDatepickerInputEvent<DateTime>) {
    const isSame = this.date === dateEvent.value;
    if (isSame) {
      // this is fixing setting dirty on init
      return;
    }
    this.date = dateEvent.value;
    this.onChange(dateEvent.value);
    this.onTouched();
    this.dateChange.emit(dateEvent.value!);
  }
  // CONTROL VALUE ACCESSOR
  public writeValue(value: unknown): void {
    if (!value) return;
    const val = this.parseValue(value);
    this.date = val;
    this.onChange(val);
    this.dateChange.emit(val);
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    const self = this;
    this.onTouched = (arg: any) => {
      self.touched = true;
      fn(arg);
    };
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /**
   * Prevents keyboard from working when focusing the input on portable devices.
   * Prevents input from being editable when readonly. Datepicker is still usable.
   * @param input
   */
  public onFocusIn(input: HTMLInputElement) {
    if (this.readonly) {
      input.blur();
    }

    if (this.noMobileKeyboard) {
      this.screenSizeService
        .isPortable()
        .pipe(first())
        .subscribe((isPortable) => {
          if (isPortable) {
            input.blur();
          }
        });
    }
  }
  private parseValue(value: unknown) {
    if (DateTime.isDateTime(value)) return value;

    if (typeof value === 'string') {
      // ? should we support custom formatted strings? ('10/05/2004' ex.)
      // assume ISO string
      const date = DateTime.fromISO(value);

      if (!date.isValid) throw new Error('Invalid ISO string');
      return date;
    }

    if (typeof value === 'number') return DateTime.fromSeconds(value);
    if (value instanceof Date) return DateTime.fromJSDate(value);

    throw new Error(`Unsupported value: ${value?.constructor?.name}`);
  }
}
