import { Injectable } from '@angular/core';
import { Farm } from '@app/core/interfaces/farm.interface';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { FarmPickerStateService } from '@app/state/services/farm-picker/farm-picker-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { LanguageStateService } from '@app/state/services/language/language-state.service';
import { isEqual } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skip, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { FarmService } from '../farm/farm.service';

export declare type SyncedQueryParams = SyncedQueryParam[];

export declare interface SyncedQueryParam {
  selectorToUpdateQueryParam$: Observable<unknown>; // Subscripting to this state property and update query param
  actionTriggeredOnQueryParamChange: (payload: unknown) => unknown; // Subscripting to query param key and dispatch action on every queryParamKey update
  queryKey: string; // The query param key
}

@Injectable({
  providedIn: 'root',
})
export class QuerySyncService {
  private readonly harvestYearFromStateService$ = this.harvestYearStateService.harvestYear$;
  private readonly languageFromStore$ = this.languageStateService.currentLanguage$;

  private selectedFarms$: Observable<Farm[]> = this.farmStateService.selectedFarms$;
  private farmsAreSelected$: Observable<boolean> = this.farmStateService.farmsAreSelected$;

  private readonly farmIds$ = this.selectedFarms$.pipe(
    withLatestFrom(this.queryParamService.getQueryParamNullable('cvr'), this.farmsAreSelected$),
    filter(([farms, cvrArg, farmsAreSelected]) => {
      if (!!cvrArg && farms.length === 0) {
        return false;
      }

      return true;
    }),
    map(([farms, cvrArg]) => farms.map((farm) => farm.id))
  );

  private subscriptions: Subscription[] = [];
  private syncedQueryParams: SyncedQueryParams = [
    {
      selectorToUpdateQueryParam$: this.harvestYearFromStateService$.pipe(skip(1)),
      actionTriggeredOnQueryParamChange: (payload) => typeof payload === 'number' && (this.harvestYearStateService.harvestYear = payload),
      queryKey: 'harvestYear',
    },
    {
      selectorToUpdateQueryParam$: this.farmIds$,
      actionTriggeredOnQueryParamChange: (farmIds) => {
        this.farmsAreSelected$
          .pipe(
            take(1),
            filter((farmsAreSelected) => {
              const isEmptyFarmIds = !Array.isArray(farmIds) || farmIds.length === 0;

              if (isEmptyFarmIds && !farmsAreSelected) {
                this.farmPickerStateService.openFarmPickerModal();
              } else {
                this.farmPickerStateService.closeFarmPickerModal();
              }

              return !isEmptyFarmIds || !farmsAreSelected;
            }),
            switchMap(() => this.selectedFarms$.pipe(take(1))),
            filter((selectedFarms) => Array.isArray(farmIds) && selectedFarms && selectedFarms.length === 0),
            switchMap(() => this.farmService.getFarmsFromIds(farmIds as number[])),
            map((farms) => {
              return (this.farmStateService.selectedFarms = farms);
            })
          )
          .subscribe();
      },
      queryKey: 'farmIds',
    },
    {
      selectorToUpdateQueryParam$: this.languageFromStore$.pipe(skip(1)),
      actionTriggeredOnQueryParamChange: (payload) => typeof payload === 'string' && (this.languageStateService.currentLanguage = payload),
      queryKey: 'currentLanguage',
    },
  ];

  constructor(
    private queryParamService: QueryParamService,
    private farmService: FarmService,
    private harvestYearStateService: HarvestYearStateService,
    private farmPickerStateService: FarmPickerStateService,
    private farmStateService: FarmStateService,
    private languageStateService: LanguageStateService
  ) {}

  public startSyncQueryParams(syncedParams: SyncedQueryParams = this.syncedQueryParams) {
    syncedParams.forEach((queryParamElm) => {
      this.subscriptions.push(this.subscribeToQueryAndDispatch(queryParamElm), this.subscribeToStateAndUpdateQuery(queryParamElm));
    });
  }

  public stopSyncQueryParams() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private subscribeToQueryAndDispatch(syncedQueryParamObj: SyncedQueryParam) {
    return this.queryParamService
      .getQueryParamNullable(syncedQueryParamObj.queryKey)
      .pipe(
        distinctUntilChanged((prev, cur) => {
          return isEqual(prev, cur);
        }),
        filter((queryValue) => {
          return !!queryValue;
        })
      )
      .subscribe((payload) => {
        syncedQueryParamObj.actionTriggeredOnQueryParamChange(payload);
      });
  }

  private subscribeToStateAndUpdateQuery(syncedQueryParamObj: SyncedQueryParam) {
    return syncedQueryParamObj.selectorToUpdateQueryParam$.subscribe((value) => {
      if (value !== undefined && value !== null) {
        this.queryParamService.setQueryParam(syncedQueryParamObj.queryKey, value);
      } else {
        this.queryParamService.removeQueryParam(syncedQueryParamObj.queryKey);
      }
    });
  }

  public addSyncedQueryParam(syncedParam: SyncedQueryParam) {
    this.stopSyncQueryParams();

    const exists = this.syncedQueryParams.some((param) => param.queryKey === syncedParam.queryKey);

    if (!exists) {
      this.syncedQueryParams.push(syncedParam);
    }

    this.startSyncQueryParams(this.syncedQueryParams);
  }

  public unsyncQueryParam(queryParamKey: string) {
    // HACK: this is fixing not being able to apply query params on navigate in goToVra method
    setTimeout(() => {
      this.stopSyncQueryParams();
      const syncedQueryParamIdxToRemove = this.syncedQueryParams.findIndex((param) => param.queryKey === queryParamKey);
      this.syncedQueryParams.splice(syncedQueryParamIdxToRemove, 1);
      this.startSyncQueryParams(this.syncedQueryParams);
      this.queryParamService.removeQueryParam(queryParamKey);
    }, 10);
  }
}
