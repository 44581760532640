<div class="data-connections-content">
  <div class="search">
    <app-search-field (searchFor)="onSearchTermChanged($event)"></app-search-field>
  </div>

  <h1 class="connections-title">{{ 'main.settings.dataConnections.title' | translate }}</h1>
  <app-spinner *ngIf="isLoading" [hasBackdrop]="true"></app-spinner>
  <div class="button-wrapper">
    <app-square-button [buttonType]="'Primary'" (click)="onAddSettingClicked()">
      {{ 'main.settings.dataConnections.create' | translate }}
    </app-square-button>
  </div>

  <div class="table-wrapper">
    <mat-table class="connections-table" [dataSource]="dataConnectionsGridDataSource">
      <ng-container cdkColumnDef="settingName">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.settings.dataConnections.name' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.dataConnectionSetting.name }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="settingType">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.settings.dataConnections.type' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          {{ row.dataConnectionType.name }}
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="status">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.settings.dataConnections.status' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <app-square-button
            class="check-btn"
            *ngIf="row.dataConnectionType.pingUrl || row.dataConnectionType.id === dataConnectionTypes.CNH"
            [ngClass]="{
              success: row.dataConnectionState === dataConnectionState.SUCCESS,
              failed: row.dataConnectionState === dataConnectionState.FAILED
            }"
            [disabled]="row.dataConnectionState === dataConnectionState.TESTING"
            (clicked)="getSettingState(row.dataConnectionSetting, row.dataConnectionType)"
          >
            <app-icon icon="phone" *ngIf="row.dataConnectionState === dataConnectionState.UNTESTED"></app-icon>
            <app-icon icon="search" *ngIf="row.dataConnectionState === dataConnectionState.TESTING"></app-icon>
            <app-icon
              *ngIf="row.dataConnectionState === dataConnectionState.SUCCESS || row.dataConnectionState === dataConnectionState.FAILED"
              icon="refresh"
            ></app-icon>
            <span *ngIf="row.dataConnectionState === dataConnectionState.UNTESTED">
              {{ 'main.settings.dataConnections.checkConnection' | translate }}
            </span>
            <span *ngIf="row.dataConnectionState === dataConnectionState.SUCCESS">
              {{ 'main.settings.dataConnections.connectionSuccess' | translate }}
            </span>
            <span *ngIf="row.dataConnectionState === dataConnectionState.FAILED">
              {{ 'main.settings.dataConnections.connectionError' | translate }}
            </span>
            <span *ngIf="row.dataConnectionState === dataConnectionState.TESTING">
              {{ 'main.settings.dataConnections.connectionTesting' | translate }}
            </span>
          </app-square-button>
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="edit">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.settings.dataConnections.edit' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <app-square-button (clicked)="onEditSettingClicked(row)">
            <app-icon icon="edit_outline"></app-icon>
          </app-square-button>
        </mat-cell>
      </ng-container>
      <ng-container cdkColumnDef="delete">
        <mat-header-cell *cdkHeaderCellDef>{{ 'main.settings.dataConnections.delete' | translate }}</mat-header-cell>
        <mat-cell *cdkCellDef="let row">
          <app-square-button (clicked)="onDeleteSettingClicked(row)">
            <app-icon icon="delete_outline"></app-icon>
          </app-square-button>
        </mat-cell>
      </ng-container>
      <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator #paginator [length]="numberOfSettings" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]">
    </mat-paginator>
  </div>
</div>
