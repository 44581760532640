import { Component } from '@angular/core';
import { ButtonParentComponent } from '@fe-shared/ui-components/buttons/button-parent-component';

export type ListButtonTypes = 'Add' | 'Delete' | 'Round';

@Component({
  selector: 'app-list-button',
  templateUrl: './list-button.component.html',
  styleUrls: ['./list-button.component.scss'],
})
export class ListButtonComponent extends ButtonParentComponent<ListButtonTypes> {}
