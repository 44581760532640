export class NumberValidator {
  public static regex: RegExp;

  /**
   * Validate input as integer.
   * If value is parseable to integer return true. Otherwise return false.
   */

  public static isInteger(value: string | number): boolean {
    this.regex = new RegExp('^[0-9]*$');
    if (!value) {
      return false;
    }

    if (typeof value === 'string') {
      return this.regex.test(value);
    }

    // Number.isInteger() polyfill implementation from MDN
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
    if (typeof value === 'number') {
      return isFinite(value) && value > -9007199254740992 && value < 9007199254740992 && Math.floor(value) === value;
    }

    return false;
  }

  /**
   * Validates that a value is less or equal to a number.
   *
   * @param value value to check
   * @param maxValue the maximum value
   */
  public static isMaxValue(value: number, maxValue: number) {
    if (!value || !maxValue) {
      return false;
    }

    return value <= maxValue;
  }

  /**
   * Validates that a value is greather or equal to a number
   *
   * @param value value to check
   * @param minValue the minimum value
   */
  public static isMinValue(value: number, minValue: number) {
    if (!value || !minValue) {
      return false;
    }

    return value >= minValue;
  }
}
