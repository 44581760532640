import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureService } from '@app/core/feature/feature.service';
import { FieldLayerItem } from '@app/core/feature/field-layer-item.interface';
import { CropColor } from '@app/core/interfaces/crop-color-interface';
import { Field } from '@app/core/interfaces/field.interface';
import { MapConfig } from '@app/core/interfaces/map-config.interface';
import { MapZoomStateService } from '@app/core/map-zoom-state/map-zoom-state.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { QuerySyncService } from '@app/core/query-param/query-sync.service';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { Extent } from '@app/core/types/extent.type';
import { NavigationService } from '@app/layouts/navigation/navigation.service';
import { CultivationJournalStateDispatchers } from '@app/new-map/features/cultivation-journal/cultivation-journal-state-dispatchers.service';
import { fieldIdQueryParamKey } from '@app/new-map/features/cultivation-journal/cultivation-journal.variables.ts';
import { LayerUtil } from '@app/new-map/helpers/utils/layer-utils';
import { OlMapService } from '@app/new-map/map-service/ol-map.service';
import { LayerId } from '@app/new-map/services/layer/layer.store';
import { AccessControlService } from '@app/shared/access-control/services/access-control.service';
import { cmShortcuts } from '@app/shared/directives/shortcut-key/cm-shortcuts.const';
import { FarmLockedService } from '@app/shared/farm-locked/farm-locked.service';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { MapLayerControlService } from '@app/shared/map-layer-controls/map-layer-control.service';
import { filterNullish } from '@app/shared/operators';
import { negate } from '@app/shared/operators/negate';
import { GTagEventTrackerService } from '@app/shared/pipes/gTagEventTracker/gTagEventTracker.service';
import { CultivationJournalStateService } from '@app/state/services/cultivation-journal/cultivation-journal-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { MapStateService } from '@app/state/services/map/map-state.service';
import { TranslateService } from '@ngx-translate/core';
import Feature from 'ol/Feature';
import { SelectEvent } from 'ol/interaction/Select';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { CultivationJournalComponentEnum } from './CultivationJournalComponentEnum.enum';
import { CultivationJournalOptions } from './interfaces/map/cultivation-journal-options.interface';
import { CultivationJournalMapFeatureComponent } from './map-features/cultivation-journal-map-feature.component';

@Component({
  selector: 'app-cultivation-journal',
  templateUrl: './cultivation-journal.component.html',
  styleUrls: ['./cultivation-journal.component.scss'],
})
export class CultivationJournalComponent implements OnInit, OnDestroy {
  public readonly fieldFeatures$ = this.mapStateService.fieldMapFieldFeatures$;
  public readonly fieldFeaturesExtent$ = this.mapStateService.fieldMapFieldFeaturesExtent$;
  public readonly cropColors$ = this.mapStateService.fieldMapLegendCropColors$;
  public readonly mapOptions$ = this.mapStateService.fieldMapOptions$;
  public readonly mapInitialLoad$ = this.mapStateService.fieldMapInitialLoad$;

  public selectableLayers = [LayerId.FIELDS];
  private fieldFeaturesSubject = new BehaviorSubject<Feature[]>([]);
  private field$ = this.fieldFeaturesSubject.asObservable();
  public readonly isLayerReady$ = this.field$.pipe(map((fields) => !!fields.length));

  private componentSubscription = new Subscription();
  public shortcuts = cmShortcuts;

  // loading
  private areFieldsLoading = false;
  public isMapSpinnerVisible = false;

  // for legend
  public showUnknownCrops!: boolean;
  public cropColors: CropColor[] = [];
  public numberOfResoursesLoading = 0;
  public isLegendVisible!: boolean;

  // For cover flow
  public mapCoverFlowItems: MapCoverFlowItem[] = [];
  public isSettingsVisible!: boolean;

  public selectedField!: Field;

  public mapConfig: MapConfig = {
    airphotoMaxZoomLevel: 12,
    center: this.mapZoomStateService.fieldMapCenter,
    zoom: this.mapZoomStateService.fieldMapZoom,
    maxZoom: 19,
    minZoom: 0,
    shouldShowAirphoto: true,
  };

  private cultivationJournalRef!: SideDrawerRef<CultivationJournalMapFeatureComponent, any, any> | null;

  public queryParams$ = this.navigationService.getQueryParamsWhenNavigating$();

  public showOpenSideDrawerbutton = this.cultivationJournalStateService.cultivationJournalBtnShown$;

  protected disableEditFields$ = this.accessControlService.hasAccessTo('field_plan_edit_fields').pipe(negate());

  private _subscriptions: Subscription[] = [];

  constructor(
    private mapService: OlMapService,
    private sideDrawerService: SideDrawerOverlayService,
    private cultivationJournalStateDispatchers: CultivationJournalStateDispatchers,
    private mapZoomStateService: MapZoomStateService,
    private farmStateService: FarmStateService,
    private querySyncService: QuerySyncService,
    private router: Router,
    private navigationService: NavigationService,
    private farmLockedService: FarmLockedService,
    private featureService: FeatureService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private cultivationJournalStateService: CultivationJournalStateService,
    private harvestYearStateService: HarvestYearStateService,
    private mapStateService: MapStateService,
    private accessControlService: AccessControlService,
    private gTagEventTrackerService: GTagEventTrackerService,
    private mapLayerControlService: MapLayerControlService
  ) {}

  public ngOnInit(): void {
    this._subscriptions.push(
      this.mapService.mapReady$.pipe(first()).subscribe(() => {
        this.onMapReady();
        this.mapService.setNewActivePage('cultivation-journal');
      })
    );

    this.componentSubscription.add(
      this.harvestYearStateService.harvestYear$.subscribe(() => {
        this.cultivationJournalStateService.cultivationJournalBtnShown = false;
      })
    );

    this.mapLayerControlService.setSidedrawerWidth(0);
  }

  public ngOnDestroy(): void {
    this.sideDrawerService.closeAllSideDrawers();

    this.stopFieldIdQueryParamSync();
    this._subscriptions.forEach((sub) => sub.unsubscribe());
    this.componentSubscription.unsubscribe();
  }

  public onEditFieldsClick(queryParams: any) {
    this.gTagEventTrackerService.trackEvent('Edit Fields (click)', 'Field Plan').catch((error) => {
      console.error('GTagEventTrackerService ~ trackEvent ~ error', error);
    });

    this.onCultivationJournalClose();
    this.mapCoverFlowItems.forEach(function (item) {
      item.isVisible = false;
    });

    if (this.farmLockedService.isSelectedHarvestYearUnlockedForAnyOfTheSelectedFarms()) {
      void this.router.navigate(['newmap/field-plan'], {
        queryParams: {
          harvestYear: queryParams.harvestYear,
          farmIds: JSON.stringify(queryParams.farmIds),
          currentLanguage: JSON.stringify(queryParams.currentLanguage),
        },
      });
    } else {
      this.farmLockedService.showFarmsLockedInSelectedHarvestYearError();
    }
  }

  public openSideDrawer() {
    this.cultivationJournalRef?.show();
  }
  private onMapReady() {
    setTimeout(() => {
      this.initSubscriptions();
      this.startFieldIdQueryParamSync();
    });
  }

  private openCultivationJournalInSideDrawer(field: Field) {
    if (this.cultivationJournalRef) {
      this.onCultivationJournalClose();
    }
    this.cultivationJournalRef = this.sideDrawerService.openCustomSideDrawer<CultivationJournalMapFeatureComponent, Field, void>(
      CultivationJournalMapFeatureComponent,
      {
        panelClass: 'cultivation-journal-side-drawer',
        data: field,
      }
    );
    this.cultivationJournalRef.onClose
      .pipe(
        first(),
        tap(() => this.onCultivationJournalClose())
      )
      .subscribe();
  }

  private isGroupFeature(selectedFeature: Feature) {
    return selectedFeature.get('features') !== undefined;
  }

  public onFeatureSelected(event: SelectEvent): void {
    if (event && event.selected && event.selected.length) {
      this.onCultivationJournalClose();
      const selectedFeature = event.selected[0];

      this.cultivationJournalStateService.setShownComponentState(CultivationJournalComponentEnum.CultivationJournal);
      this.openCultivationJournal(selectedFeature);
    } else {
      this.cultivationJournalStateDispatchers.setCultivationJournalField(undefined);
    }
  }

  private openCultivationJournal(selectedFeature: Feature) {
    const field: Field = this.isGroupFeature(selectedFeature)
      ? selectedFeature.get('features')[0].get('field')
      : selectedFeature.get('field');

    const { name, number, crops } = field;

    if (crops?.length > 0) {
      this.getAndSetSelectedField(field);
      this.openCultivationJournalInSideDrawer(field);
    } else {
      const fieldInfo = name ? `${number} ${name}` : `${number}`;
      this.notificationService.showError(`${this.translate.instant('main.fieldplan.fieldIsMissingCrop', { fieldInfo: fieldInfo })}`);
      this.mapService.deselectFeatures();
    }
  }

  public onCultivationJournalClose() {
    this.sideDrawerService.closeAllSideDrawers();

    this.cultivationJournalRef = null;

    this.cultivationJournalStateDispatchers.setCultivationJournalField(undefined);
    this.cultivationJournalStateService.cultivationJournalBtnShown = false;
    this.mapService.deselectFeatures();
  }

  public trackByFn(index: number, cropColor: CropColor) {
    return cropColor ? cropColor?.name : index;
  }

  private startFieldIdQueryParamSync() {
    const syncedParam = {
      selectorToUpdateQueryParam$: this.cultivationJournalStateService.field$.pipe(
        filterNullish(),
        map((field: Field) => field.id)
      ),
      actionTriggeredOnQueryParamChange: (payload: unknown) => {
        if (typeof payload !== 'number') return;

        this.field$
          .pipe(
            filter((fields) => {
              return !!fields;
            }),

            map((features: Feature[]) => features.find((feature) => feature.get('field').id === +payload)),
            filterNullish(),
            map((feature: Feature) => feature.get('field')),
            filterNullish(),
            first()
          )
          .subscribe((field: Field) => {
            this.getAndSetSelectedField(field);

            if (!this.cultivationJournalRef) {
              this.openCultivationJournalInSideDrawer(field);
            }
          });
      },
      queryKey: fieldIdQueryParamKey,
    };

    this.querySyncService.addSyncedQueryParam(syncedParam);
  }

  private stopFieldIdQueryParamSync() {
    this.querySyncService.unsyncQueryParam(fieldIdQueryParamKey);
  }

  private getAndSetSelectedField(field: Field) {
    this.cultivationJournalStateDispatchers.setCultivationJournalField(field);
  }

  private initSubscriptions(): void {
    this._subscriptions.push(
      this.farmStateService.fieldFeatures$
        .pipe(
          filterNullish(),
          tap((fieldsCollections) => {
            this.cultivationJournalStateDispatchers.setCultivationJournalLegendCropColors(
              LayerUtil.getLegendCrops(
                fieldsCollections ? fieldsCollections.fieldFeatures.map((fieldFeatures) => fieldFeatures.field!) : [],
                this.translate
              )
            );
          })
        )
        .subscribe((featureModels) =>
          this.onFieldFeaturesChange(featureModels!.fieldFeatures, featureModels!.extent as [number, number, number, number])
        )
    );

    // Subscription for map layers and settings.
    this._subscriptions.push(
      this.mapOptions$.subscribe((options: CultivationJournalOptions | null) => {
        this.isSettingsVisible = options?.isSettingsVisible ?? false;
        this.isLegendVisible = options?.isLegendVisible ?? false;
      })
    );
  }

  private onFieldFeaturesChange(featureModels: FieldLayerItem[], extent: Extent) {
    const features = this.featureService.getFieldFeatures(featureModels, LayerId.FIELDS);
    this.fieldFeaturesSubject.next(features);
    this.areFieldsLoading = this.isMapSpinnerVisible = false;
  }

  ////////////////////////////////////////////////////////// Event handlers ///////////////////////////////////////////////////////////////

  /**
   * Eventhandler for user showing / hiding map cover flow component.
   * @param isVisible whether or not settings are visible
   */
  public onSettingsToggle(isVisible: boolean): void {
    this.cultivationJournalStateDispatchers.setMapOptions(this.isLegendVisible, isVisible);
  }

  /*
   * Event handler for user clicking on tile in map cover flow component.
   * @param changedSetting Settings which has been changed
   */
  public onSettingToggle(mapCoverFlowItm: MapCoverFlowItem): void {
    const mapCoverFlowItem = this.mapCoverFlowItems.find((item) => item.mapCoverFlowLayersId === mapCoverFlowItm.mapCoverFlowLayersId);
    if (mapCoverFlowItem) {
      mapCoverFlowItem.isVisible = mapCoverFlowItm.isVisible;
    }
    this.cultivationJournalStateDispatchers.setMapCoverFlowItems(this.mapCoverFlowItems);
  }

  ////////////////////////////////////////////// HELPER FUNCTIONS //////////////////////////////////

  /**
   * Function for determining whether or not all resources are done loading.
   */
  public isDoneLoading(): boolean {
    return !this.areFieldsLoading;
  }
}
