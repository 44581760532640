<div class="content">
  <div>
    <div class="field-info" *ngIf="field && preCrops; else spinner">
      <div class="field-details">
        <div class="field">
          <b>{{ field.number }} {{ field.name }}</b>
        </div>

        <div>{{ field.area | unit: 'ha' }} {{ 'common.hectareShort' | translate }}</div>
        <div *ngFor="let crop of field.crops; first as isFirst">
          <div *ngIf="isFirst" class="main-crop">
            {{ crop.cropName }} - <span *ngIf="fieldDetails.varietyNames">{{ fieldDetails.varietyNames }}</span>
          </div>
          <div *ngIf="!isFirst" class="secondary-crop">{{ crop.cropName }}</div>
        </div>
        <div class="growth-stage" *ngIf="fieldDetails.growthStage !== undefined">
          {{ 'main.cultivationJournal.fieldDetails.growthStage' | translate }}: {{ fieldDetails.growthStage }}
        </div>
      </div>
      <div class="field-timeline">
        <div class="pre-crops">
          <div class="pre-crop-wrapper" *ngFor="let preCrop of preCrops; first as isFirst">
            <span class="point"></span>
            <span class="text" [class.current-year]="isFirst">{{ preCrop.harvestYear }} {{ preCrop.mainCropName }}</span>
          </div>
        </div>
      </div>

      <div class="field-notes" *ngIf="fieldDetails.infoTextLines && fieldDetails.infoTextLines.length > 0">
        <div class="field">
          <b>{{ 'main.cultivationJournal.sideDrawerNotes' | translate }}</b>
        </div>
        <div class="line" *ngFor="let line of fieldDetails.infoTextLines">
          {{ line }}
        </div>
      </div>
    </div>
  </div>
  <ng-template #spinner>
    <app-spinner></app-spinner>
  </ng-template>
</div>
