import { DateTime } from 'luxon';

export class CornHarvestPrognosisSettings {
  public sampleDate? = DateTime.now();
  public drymatterPct?: number | null = null;

  constructor(state?: Partial<CornHarvestPrognosisSettings>) {
    this.sampleDate = state ? state.sampleDate : DateTime.now();
    this.drymatterPct = state ? state.drymatterPct : null;
  }

  public equals(settings: CornHarvestPrognosisSettings) {
    return (
      settings.drymatterPct === this.drymatterPct &&
      settings.sampleDate?.year === this.sampleDate?.year &&
      settings.sampleDate?.month === this.sampleDate?.month &&
      settings.sampleDate?.day === this.sampleDate?.day
    );
  }
}
