import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConsentGuard } from '@app/core/consent/consent-guard.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { NaesgaardGuard } from '@app/core/guards/naesgaard.guard';
import { AccessControlRoutes } from '@app/shared/access-control/guards/access-control.guard';
import { CultivationJournalComponent } from './cultivation-journal.component';

const routes: AccessControlRoutes = [
  {
    path: '',
    component: CultivationJournalComponent,
    canActivate: [AuthGuard, ConsentGuard, NaesgaardGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CultivationJournalRoutingModule {}
