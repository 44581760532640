import Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';

export const VraStyles = {
  getVraCellStyle: (feature: Feature): Style[] => {
    const color = feature.get('color');

    return [
      new Style({
        fill: new Fill({
          color: color,
        }),
      }),
    ];
  },
};
