import { LayerId } from '@app/new-map/services/layer/layer.store';

export class LayerIDHelper {
  static getDefaultLayerLabelFromId(layerId: LayerId) {
    const translationLabel = 'main.map.layers.';
    switch (layerId) {
      case LayerId.NONE:
        return translationLabel + 'NONE';
      case LayerId.OSM:
        return translationLabel + 'OSM';
      case LayerId.AIRPHOTO_DK:
        return translationLabel + 'AIRPHOTO_DK';
      case LayerId.AIRPHOTO_FOREIGN:
        return translationLabel + 'AIRPHOTO_FOREIGN';
      case LayerId.GEO_LOCATION:
        return translationLabel + 'GEO_LOCATION';
      case LayerId.BES_NATURE:
        return translationLabel + 'BES_NATURE';
      case LayerId.BES_WATERCOURSES:
        return translationLabel + 'BES_WATERCOURSES';
      case LayerId.BES_STONES:
        return translationLabel + 'BES_STONES';
      case LayerId.BUFFER_MARGINS:
        return translationLabel + 'BUFFER_MARGINS';
      case LayerId.GLM_MEMORIES:
        return translationLabel + 'GLM_MEMORIES';
      case LayerId.GLM_LAKES:
        return translationLabel + 'GLM_LAKES';
      case LayerId.FIELD_SHRUBS:
        return translationLabel + 'FIELD_SHRUBS';
      case LayerId.BNBO_STATUS:
        return translationLabel + 'BNBO_STATUS';
      case LayerId.DIGI_SOIL:
        return translationLabel + 'DIGI_SOIL';
      case LayerId.FIELDS:
        return translationLabel + 'FIELDS';
      case LayerId.FIELD_LABELS:
        return translationLabel + 'FIELD_LABELS';
      case LayerId.FIELD_FILL:
        return translationLabel + 'FIELD_FILL';
      case LayerId.FIELD_MARKERS:
        return translationLabel + 'FIELD_MARKERS';
      case LayerId.HOTSPOTS:
        return translationLabel + 'HOTSPOTS';
      case LayerId.FIELD_BLOCKS:
        return translationLabel + 'FIELD_BLOCKS';
      case LayerId.HOTSPOT_MARKERS:
        return translationLabel + 'HOTSPOT_MARKERS';
      case LayerId.BIOMASS:
        return translationLabel + 'BIOMASS';
      case LayerId.VRA_CELLS:
        return translationLabel + 'VRA_CELLS';
      case LayerId.BASIS_LAYERS:
        return translationLabel + 'BASIS_LAYERS';
      case LayerId.HEIGHT_MAP:
        return translationLabel + 'HEIGHT_MAP';
      case LayerId.SHADOW_MAP:
        return translationLabel + 'SHADOW_MAP';
      case LayerId.SOILSAMPLE:
        return translationLabel + 'SOILSAMPLE';
      case LayerId.BLIGHT_FIELDS:
        return translationLabel + 'BLIGHT_FIELDS';
      case LayerId.BLIGHT_POLYGONS:
        return translationLabel + 'BLIGHT_POLYGONS';
      case LayerId.BLIGHT_FIELD_POLYGONS:
        return translationLabel + 'BLIGHT_FIELD_POLYGONS';
      case LayerId.VRA_PROGNOSIS:
        return translationLabel + 'VRA_PROGNOSIS';
      case LayerId.YIELD_PROGNOSIS:
        return translationLabel + 'YIELD_PROGNOSIS';
      case LayerId.CORN_PROGNOSIS:
        return translationLabel + 'CORN_PROGNOSIS';
      case LayerId.GROWTH_REGULATION:
        return translationLabel + 'GROWTH_REGULATION';

      default:
        return '';
    }
  }
}
