import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@app/core/log/log.service';
import { Observable } from 'rxjs';

// X-Deprecated-AtUtc DateTime formatted string in UTC indicating the date whence the endpoint was deprecated
const header1 = 'X-Deprecated-AtUtc';
// 2) X-Deprecated-ExpiresUtc DateTime formatted string in UTC indicating the date whence the endpoint will be shut down and start responding 410 gone to all requests.
const header2 = 'X-Deprecated-ExpiresUtc';
// 3) X-Deprecated-Message Either an url to use instead, or a description of what to do.
const header3 = 'X-Deprecated-Message';
// 4) X-Deprecated-Api Name of the API the call came from
const header4 = 'X-Deprecated-Api';

/* eslint-disable no-console */
@Injectable({
  providedIn: 'root',
})
export class LogDeprecatedInterceptor implements HttpInterceptor {
  constructor(private log: LogService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(header1) || req.headers.has(header2)) {
      const message = this.getMessage(req.headers);
      this.log.logWarning(message);
      console.warn(message);
    }

    return next.handle(req);
  }

  private getMessage(headers: HttpHeaders): string {
    const h1 = headers.get(header1);
    const h2 = headers.get(header2);
    const h3 = headers.get(header3);
    const h4 = headers.get(header4);
    return `Endpoint deprecated at: ${h1}, Expires on: ${h2}, Message: ${h3}, Api: ${h4}`;
  }
}
