import { Component, Input, OnInit } from '@angular/core';
import { SeriesLabels, SeriesMarkers } from '@progress/kendo-angular-charts';
import { BenchmarkFarm, BenchmarkField } from '@app/core/repositories/benchmark-chart-data/benchmark-chart-data.interface';

@Component({
  selector: 'app-farm-chart',
  templateUrl: './farm-chart.component.html',
  styleUrls: ['./farm-chart.component.scss'],
})
export class FarmChartComponent implements OnInit {
  @Input() public yAxisTitle = '';

  // @ts-ignore - TS7008 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  @Input() public avgFieldBenchmarkValue;
  @Input() public farm: BenchmarkFarm = {
    fields: [],
    id: 0,
    name: '',
  };

  public xLabels: SeriesLabels = {
    visible: true,
  };

  public average: number[] = [];

  public averageMarkers: SeriesMarkers = {
    visible: false,
    size: 0,
  };

  constructor() {}

  public ngOnInit() {
    if (this.farm.fields.length > 0) {
      this.farm.fields.forEach((benchmarkField: BenchmarkField) => {
        const fieldsSplit = benchmarkField.fieldNumber.split(', ').join('\n');
        benchmarkField.fieldNumber = fieldsSplit;
      });
    }
    this.average = this.getAverageList(this.farm.fields);
  }

  public getAverageList(fields: BenchmarkField[]) {
    return fields.map((field) => this.avgFieldBenchmarkValue);
  }
}
