import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { NormDtoForFarm } from '@app/core/interfaces/norm-dto-for-farm';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { LanguageConstants } from '@app/core/language/language.constants';
import { LanguageService } from '@app/core/language/language.service';
import { CropNormDTO } from '@app/core/repositories/crops/crop-norm.dto';
import { VraTaskFieldDto } from '@app/new-map/features/vra/interfaces/vra-task-field.interface';
import { DateTime } from 'luxon';
import { AsAppliedProductDto } from './as-applied-product-dto.interface';
import { TDateISO } from '@app/core/types/iso-date.type';

export interface LogFile {
  executedTaskId: number;
  date: TDateISO;
  orgininalFileName: string;
}

export interface AsAppliedTaskDto {
  id: number;
  farmId: number;
  date: string;
  fieldName: string;
  cropNormNumber: number;
  products: AsAppliedProductDto[];
  featureId: number;
  taskId: number;
  operationTypeGroupId: OperationTypeGroupEnum;
  operationTypeGroup: string;
  combineSuggestions?: LogFile[];
}
export class AsAppliedTask {
  public readonly id: number;
  public readonly farmId: number;
  public readonly date: DateTime;
  public readonly fieldName: string;
  public readonly cropNormNumber: number;
  public readonly products: AsAppliedProductDto[];
  public readonly product!: AsAppliedProductDto;
  public readonly featureId: number;
  public readonly taskId: number;
  public readonly productName: string;
  public readonly operationTypeGroupId?: OperationTypeGroupEnum;
  public readonly operationTypeGroup?: string;
  public readonly cropName?: string;
  public readonly quantity!: number;
  public readonly displayDate: string;
  public readonly fields?: VraTaskFieldDto[];
  public readonly fieldNumber?: string;
  public readonly logFiles?: LogFile[];
  public readonly logFileCount: number;

  constructor(
    dto: AsAppliedTaskDto,
    produceNormsForFarms: NormDtoForFarm<ProduceNorm[]>[],
    cropNormsForFarms: NormDtoForFarm<CropNormDTO[]>[],
    languageService: LanguageService
  ) {
    this.id = dto.id;
    this.farmId = dto.farmId;
    this.date = DateTime.fromISO(dto.date);
    this.fieldName = dto.fieldName;
    this.cropNormNumber = dto.cropNormNumber;
    this.products = dto.products;
    this.featureId = dto.featureId;
    this.taskId = dto.taskId;
    this.operationTypeGroupId = dto.operationTypeGroupId;
    this.operationTypeGroup = dto.operationTypeGroup;
    this.logFiles = dto.combineSuggestions;
    this.logFileCount = dto.combineSuggestions?.length ?? 1;
    this.displayDate = DateTime.fromISO(dto.date).toFormat(LanguageConstants.getDateFormat(languageService.currentLanguage.shortKey)['L']);
    this.cropName = cropNormsForFarms
      .find((cropNormsForFarm) => cropNormsForFarm.farmId === dto.farmId)!
      .normDtos.find((cropNormsForFarm) => cropNormsForFarm.number === dto.cropNormNumber)?.name;
    this.productName = dto.products
      .map((product) => {
        return produceNormsForFarms
          .find((cropNormsForFarm) => cropNormsForFarm.farmId === dto.farmId)!
          .normDtos.find((produceNorm) => product.produceNormNumber.toString() === produceNorm.number);
      })
      ?.map((norms) => norms!.name)
      .join(', ');
  }
}
