export class Decimals {
  constructor(
    private thousand: number,
    private hundred: number,
    private ten: number,
    private one: number,
    private zero: number
  ) {}
  public getDecimals(value: number) {
    const absValue = Math.abs(value);

    if (absValue >= 1000) {
      return this.thousand;
    } else if (absValue >= 100) {
      return this.hundred;
    } else if (absValue >= 10) {
      return this.ten;
    } else if (absValue >= 1) {
      return this.one;
    } else {
      return this.zero;
    }
  }
}
