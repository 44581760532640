import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';

@Component({
  selector: 'app-map-cover-flow',
  templateUrl: './map-cover-flow.component.html',
  styleUrls: ['./map-cover-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapCoverFlowComponent {
  @Input() public mapCoverFlowItems: MapCoverFlowItem[] = [];
  @Output() public onSettingChanged: EventEmitter<MapCoverFlowItem>;

  @Input() public isVisible?: boolean;
  @Output() public onVisibilityChanged: EventEmitter<boolean>;

  @Input() public isLoading = false;

  constructor() {
    this.onSettingChanged = new EventEmitter();
    this.onVisibilityChanged = new EventEmitter();
  }

  /**
   * Triggers 'visibilityChanged' event, signaling that user has clicked hide / show cover flow
   */
  public onVisibilityToggle(): void {
    this.isVisible = !this.isVisible;
    this.onVisibilityChanged.emit(this.isVisible);
  }

  /**
   * Triggers 'onSettingChanged'
   * @param mapCoverFlowItem the clicked setting
   */
  public onToggleCoverFlowItem(mapCoverFlowItem: MapCoverFlowItem): void {
    if (mapCoverFlowItem.isDisabled) {
      return;
    }

    mapCoverFlowItem.isVisible = !mapCoverFlowItem.isVisible;
    this.onSettingChanged.emit(mapCoverFlowItem);
  }
}
