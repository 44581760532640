<div class="side-drawer-wrapper">
  <div>
    <ng-content select="app-side-drawer-header"></ng-content>
  </div>
  <div class="side-drawer-body">
    <ng-content select="app-side-drawer-body"></ng-content>
  </div>
  <div>
    <ng-content select="app-side-drawer-footer"></ng-content>
  </div>
</div>
