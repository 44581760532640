import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-grass-prognosis-info',
  templateUrl: './grass-prognosis-info.component.html',
  styleUrls: ['./grass-prognosis-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrassPrognosisInfoComponent {
  public static isGrazing: boolean;
  public isGrazing: boolean;

  constructor(private dialogRef: MatDialogRef<GrassPrognosisInfoComponent>) {
    this.isGrazing = GrassPrognosisInfoComponent.isGrazing;
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
