export class ImageMetaData {
  public longitude: number;
  public latitude: number;
  public fileName: string;

  constructor(longitude: number, latitude: number, fileName: string) {
    this.longitude = longitude;
    this.latitude = latitude;
    this.fileName = fileName;
  }
}
