<div class="wrapper">
  <div class="listHeader">
    <h3>{{ 'main.fieldAdministration.exportFields.fieldsTitle' | translate }}</h3>
    <div>
      <span>{{ 'main.fieldAdministration.exportFields.chooseAll' | translate }}</span
      ><input type="checkbox" data-testid="all-checkbox" (change)="toggleAll($event)" />
    </div>
  </div>
  <div class="listWrapper">
    <div
      class="fieldWrapper"
      [ngClass]="{ disabledWrapper: !checkable.field.geometry }"
      *ngFor="let checkable of checkableFields; let index = index"
      [matTooltip]="checkable.field.geometry ? '' : ('main.fieldAdministration.exportFields.errorNoGeometry' | translate)"
      matTooltipPosition="above"
    >
      <input
        type="checkbox"
        attr.data-testid="checkbox-{{ index }}"
        [checked]="checkable.checked$ | async"
        (change)="toggle($event, checkable)"
        [disabled]="!checkable.field.geometry"
      />
      <div class="number">{{ checkable.field.number }}</div>
      <div class="name">{{ checkable.field.name }}</div>
      <div class="area">{{ checkable.field.area }} ha</div>
    </div>
  </div>
</div>
