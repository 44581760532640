import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@app/shared/buttons/buttons.module';
import { BrowserRecommendationComponent } from '@app/shared/notifications/browser-recommendation/browser-recommendation.component';
import { NotificationComponent } from '@app/shared/notifications/notification/notification.component';
import { IconModule } from '@fe-shared/ui-components/icon/icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './alert/alert.component';
import { LinkAlertComponent } from './link-alert/link-alert.component';
import { RouterModule } from '@angular/router';
import { DialogLinkComponent } from './dialog-link/dialog-link.component';

@NgModule({
  imports: [CommonModule, ButtonsModule, IconModule, TranslateModule.forChild(), RouterModule],
  declarations: [NotificationComponent, BrowserRecommendationComponent, AlertComponent, LinkAlertComponent, DialogLinkComponent],
})
export class NotificationsModule {}
