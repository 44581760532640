<div class="header">
  <h1>{{ 'main.fieldmap.droneImageImport.progressDialog.title' | translate }}</h1>
</div>
<div mat-dialog-content *ngIf="(fields$ | async) && (images$ | async); else loading">
  <p>
    {{ fieldsUploaded$ | async }}/{{ fields$ | async }} {{ 'main.fieldmap.droneImageImport.progressDialog.fieldsUploaded' | translate }}
  </p>
  <p>
    {{ imagesUploaded$ | async }}/{{ images$ | async }} {{ 'main.fieldmap.droneImageImport.progressDialog.imagesUploaded' | translate }}
  </p>
  <mat-progress-bar mode="determinate" value="{{ progress$ | async }}"></mat-progress-bar>
</div>
<div mat-dialog-actions class="dialog-actions">
  <app-square-button [buttonType]="'Secondary'" (click)="onCancelClicked()">{{
    'main.fieldmap.droneImageImport.progressDialog.cancelUpload' | translate | capitalize
  }}</app-square-button>
</div>

<ng-template #loading>
  <div mat-dialog-content>
    <p>{{ fieldsUploaded$ | async }}/... {{ 'main.fieldmap.droneImageImport.progressDialog.fieldsUploaded' | translate }}</p>
    <p>{{ imagesUploaded$ | async }}/... {{ 'main.fieldmap.droneImageImport.progressDialog.imagesUploaded' | translate }}</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>
