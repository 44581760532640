<div class="card">
  <app-as-applied-task-accordion
    *ngIf="metadataParents"
    [id]="'e2e-uploaded-files-accordion'"
    [data]="metadataParents"
    [noDataMessage]="'main.asApplied.noTasksForCategory'"
    [sortFn]="sortFileFn"
    [tableColumns]="uploadedFilesTableColumn"
    [tableStyles]="tableStyles"
    [title]="'main.asApplied.uploadetFiles' | translate"
    [initSortFn]="fileInitSortFn"
    [showTooltip]="true"
    [tooltipKey]="'name'"
    [charactersBeforeTooltip]="27"
    (onItemSelect)="onFileSelect($event)"
  >
  </app-as-applied-task-accordion>
</div>
