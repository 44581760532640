import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'blightTaskStatus',
})
export class BlightTaskStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(status: boolean): string {
    return status
      ? this.translateService.instant('main.potatoBlight.table.status.done')
      : this.translateService.instant('main.potatoBlight.table.status.planned');
  }
}
