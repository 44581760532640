import { Route, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { NaesgaardGuard } from '@app/core/guards/naesgaard.guard';
import { SubscriptionGuard } from '@app/core/guards/subscription-guard.service';
import { NoConsentComponent } from '@app/no-consent/no-consent.component';

export const noConsentLazyRoute: Route = {
  path: 'no-consent',
  loadChildren: () => import('./no-consent.module').then((m) => m.NoConsentModule),
};

export const noConsentRoutes: Routes = [
  {
    path: '',
    component: NoConsentComponent,
    canActivate: [AuthGuard, SubscriptionGuard, NaesgaardGuard],
    canLoad: [],
    canActivateChild: [SubscriptionGuard],
  },
];
