<p class="description">
  {{ 'main.settings.dataConnections.cnh.text1' | translate }}
  <br /><br />
  {{ 'main.settings.dataConnections.cnh.text2' | translate }}
</p>

<div class="btn-wrapper">
  <a [href]="url$ | async">
    <app-square-button class="link-btn" buttonType="Primary">
      {{ 'main.settings.dataConnections.cnh.linkText' | translate }}
    </app-square-button>
  </a>
</div>
