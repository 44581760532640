import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { OperationTypeGroupEnum } from '@app/core/enums/operation-type-groups.enum';
import { Farm } from '@app/core/interfaces/farm.interface';
import { Field } from '@app/core/interfaces/field.interface';
import { LanguageService } from '@app/core/language/language.service';
import { MapService } from '@app/core/map/map.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { DownloadHelper } from '@app/helpers/file/download-helper';
import { LocalState } from '@app/helpers/local-state';
import { AsAppliedOperationTypeGroup } from '@app/map/features/field-analysis/features/as-applied/as-applied-group.class';
import { AsAppliedShownComponentEnum } from '@app/map/features/field-analysis/features/as-applied/as-applied-shown-component.enum';
import { MetadataGeometry, MetadataParent } from '@app/map/features/field-analysis/features/as-applied/file-upload/metadata-parent';
import { AsAppliedFileUploadService } from '@app/map/features/field-analysis/features/as-applied/file-upload/services/as-applied-file-upload.service';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { DownloadManagerDialogComponent } from '@app/shared/download-manager/download-manager.component';
import { DownloadManagerData, DownloadManagerResult, ListOfLists } from '@app/shared/download-manager/download-manager.interface';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { filterNullish } from '@app/shared/operators';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { isEmptyArray } from '@app/shared/utils/utils';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { DateTime } from 'luxon';
import { Observable, ReplaySubject, Subject, Subscription, UnaryFunction, combineLatest, forkJoin, iif, of, pipe } from 'rxjs';
import { filter, first, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { FieldAnalysisShownComponentEnum } from '../../field-analysis-side-drawer/field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerContentComponent } from '../../field-analysis-side-drawer/field-analysis-side-drawer-content/field-analysis-side-drawer-content.component';
import { FieldAnalysisSideDrawerService } from '../../field-analysis-side-drawer/field-analysis-side-drawer.service';
import { AsAppliedTaskDetailsService } from './as-applied-task-details/services/as-applied-task-details.service';
import { AsAppliedTask } from './as-applied-task.class';
import { AsAppliedService } from './as-applied.service';
import { ExecutedLocationCollection } from './executed-task.class';
import { MetadataMapService } from './file-upload/services/metadata-map.service';

@Component({
  selector: 'app-as-applied',
  templateUrl: './as-applied.component.html',
  styleUrls: ['./as-applied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AsAppliedService, AsAppliedFileUploadService, MetadataMapService, AsAppliedTaskDetailsService],
})
export class AsAppliedComponent implements OnInit, OnDestroy {
  public get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }

  public get saveClicked$() {
    return this._saveClicked;
  }

  public get unspecifiedGroups$(): Observable<AsAppliedOperationTypeGroup> {
    return this._unspecifiedGroups$.asObservable();
  }
  @Output()
  public drawerContentRefChange: EventEmitter<TemplateRef<any> | null> = new EventEmitter<TemplateRef<any> | null>();
  @Output()
  public taskSelected: EventEmitter<ExecutedLocationCollection> = new EventEmitter<ExecutedLocationCollection>();
  public sideDrawerWidth$ = this.fieldAnalysisSideDrawerService.drawerWidth$;

  public readonly selectableMapLayers = [MapLayerId.FIELDS];

  public asAppliedTasksState = new LocalState<AsAppliedOperationTypeGroup[]>([]);
  public asAppliedTasks$ = this.asAppliedTasksState.changes$;

  private _unspecifiedGroups$ = new ReplaySubject<AsAppliedOperationTypeGroup>(1);

  private _saveClicked = new Subject<void>();

  public metadataParentDtos$!: Observable<MetadataParent[]>;

  public unspecifiedGroupTasks: AsAppliedTask[] = [];
  public selectedField: Field | null = null;
  public selectedTasks: ExecutedLocationCollection[] | null = null;
  public logsOnSelectedTaskField: ExecutedLocationCollection[] | null = [];

  public shownComponent$ = this.asAppliedService.shownComponent$;

  public showDownloadManager$ = this.shownComponent$.pipe(
    map(
      (shownComponent) =>
        shownComponent !== AsAppliedShownComponentEnum.AsAppliedMetaDataFile &&
        shownComponent !== AsAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent &&
        shownComponent !== AsAppliedShownComponentEnum.AsAppliedConfigFile
    )
  );

  public asAppliedShownComponentEnum = AsAppliedShownComponentEnum;

  public loadingState = this.asAppliedFileUploadService.loadingState;
  private subscriptions = new Subscription();

  constructor(
    private fieldAnalysisSideDrawerService: FieldAnalysisSideDrawerService,
    public asAppliedService: AsAppliedService,
    private asAppliedTaskDetailsService: AsAppliedTaskDetailsService,
    private mapService: MapService,
    private farmStateService: FarmStateService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>,
    private asAppliedFileUploadService: AsAppliedFileUploadService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.asAppliedService.globalStateService.specificLoadings$.subscribe((specificLoadings: string[]) => {
        if (specificLoadings && specificLoadings.length > 0) {
          this.loadingState.start(specificLoadings[specificLoadings.length - 1]);
        }
      })
    );

    this.subscriptions.add(
      this.asAppliedService.globalStateService.combinedIsLoading$.subscribe((combinedIsLoading: boolean) => {
        if (combinedIsLoading === false) {
          this.loadingState.stop();
        }
      })
    );

    this.map.disableSelectInteraction();
    if (this.asAppliedFileUploadService.isDirty()) {
      this.subscriptions.add(
        this.dialogService.openDirtyCheckDialog().subscribe((result) => {
          if (result && result.isConfirmed) {
            this.initAsAppliedComponent();
          }
        })
      );
    } else {
      this.initAsAppliedComponent();
    }
  }

  public ngOnDestroy() {
    this.drawerContentRefChange.next(null);
    this.subscriptions.unsubscribe();
    this.map.deselectFeatures();
    this.map.removeLayerFromMap(MapLayerId.AS_APPLIED);
    this.map.forceRerender();
    this.fieldAnalysisSideDrawerService.setShowLegendState(false);
  }

  public isAllShapefileGroupsNotSet(): boolean {
    const groupsToBeValidCount = this.asAppliedService.shapefileConfigInput.configsInput.length;
    if (
      this.asAppliedService.shapefileConfigInput.configsInput.filter((group) => group.quantityColumnName != null && group.srid != null)
        .length === groupsToBeValidCount
    ) {
      // all set :-)
      return false;
    }

    return true;
  }

  public onFieldSelect(field: Field) {
    this.selectedField = field;
    if (!!field) {
      this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedFieldTasksComponent);
    } else {
      this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
    }
  }

  public onCloseClick() {
    if (this.asAppliedFileUploadService.isDirty()) {
      this.dialogService.openDirtyCheckDialog().subscribe((result) => {
        if (result && result.isConfirmed) {
          this.onCloseNavigate();
        }
      });
    } else {
      this.onCloseNavigate();
    }
  }

  public onHideClick() {
    this.fieldAnalysisSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
    this.sideDrawerRef.hide();
  }

  public onMetadataParentSelect(metadataParent: MetadataParent) {
    this.asAppliedService.loading(true, 'main.asApplied.loadingMessage');
    this.asAppliedFileUploadService
      .getMetadataFile(metadataParent)
      .pipe(first())
      .subscribe((metadataFiles) => {
        this.asAppliedFileUploadService.uploadedFile = metadataFiles;
        this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedMetaDataFile);
        this.asAppliedService.loading(false, 'main.asApplied.loadingMessage');
      });
  }

  public onTaskSelect(task: AsAppliedTask) {
    const taskIds = task.logFiles?.length ?? 0 > 0 ? task.logFiles!.map((logfile) => logfile.executedTaskId) : [task.id];
    const fieldName = task.fieldName;

    const possibleTasks = this.unspecifiedGroupTasks.filter((unspecifiedTask) => unspecifiedTask.fieldName === fieldName);
    const possibleTaskIds = possibleTasks
      .flatMap((pt) => pt.logFiles?.map((logFile) => logFile.executedTaskId))
      .filter((id) => id !== undefined)
      .filter((id) => !taskIds.includes(id as number));

    const taskObservable = this.asAppliedService.getExecutedTasksByIds(task.farmId, taskIds).pipe(first());

    const possibleTasksObservable = iif(
      () => possibleTaskIds.length > 0,
      this.asAppliedService.getExecutedTasksByIds(task.farmId, possibleTaskIds as number[]).pipe(first()),
      of([]) // Return an observable of an empty array if possibleTaskIds is empty
    );

    forkJoin([taskObservable, possibleTasksObservable]).subscribe(([executedLocationCollection, additionalpossibleLocations]) => {
      this.selectedTasks = executedLocationCollection.sort((a, b) => {
        const startTimeA = this.calculateLogStartAndEnd(a.locations);
        const startTimeB = this.calculateLogStartAndEnd(b.locations);

        // Convert to DateTime if they are strings or handle them if they are already DateTime objects
        const dateTimeA = typeof startTimeA === 'string' ? DateTime.fromISO(startTimeA) : startTimeA;
        const dateTimeB = typeof startTimeB === 'string' ? DateTime.fromISO(startTimeB) : startTimeB;

        // Convert to milliseconds for comparison, treating undefined as Infinity (i.e., last)
        const timeA = dateTimeA ? dateTimeA.toMillis() : Infinity;
        const timeB = dateTimeB ? dateTimeB.toMillis() : Infinity;

        return timeA - timeB;
      });

      this.logsOnSelectedTaskField = additionalpossibleLocations.sort((a, b) => {
        const startTimeA = this.calculateLogStartAndEnd(a.locations);
        const startTimeB = this.calculateLogStartAndEnd(b.locations);

        // Convert to DateTime if they are strings or handle them if they are already DateTime objects
        const dateTimeA = typeof startTimeA === 'string' ? DateTime.fromISO(startTimeA) : startTimeA;
        const dateTimeB = typeof startTimeB === 'string' ? DateTime.fromISO(startTimeB) : startTimeB;

        // Convert to milliseconds for comparison, treating undefined as Infinity (i.e., last)
        const timeA = dateTimeA ? dateTimeA.toMillis() : Infinity;
        const timeB = dateTimeB ? dateTimeB.toMillis() : Infinity;

        return timeA - timeB;
      });

      this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskDetailsComponent);
    });
  }

  private calculateLogStartAndEnd(locations: MetadataGeometry[]): DateTime | undefined {
    const timestamps = locations.map((l) => l.time).filter((l) => l !== undefined);
    if (timestamps.length === 0) {
      return undefined;
    }

    const start = timestamps.reduce((earliest, current) => {
      return current! < earliest! ? current : earliest;
    }, timestamps[0]);

    return start as DateTime;
  }
  public getAppliedTasks() {
    const UNSPECIFIEDOPERATIONGROUPID = 99;
    this.subscriptions.add(
      this.asAppliedService.getAsAppliedTasks().subscribe((groups) => {
        this.asAppliedTasksState.setState(groups.filter((group) => !!group.id && group.id !== UNSPECIFIEDOPERATIONGROUPID));
        const unspecifiedGroup = groups.find((group) => !group.id || group.id === UNSPECIFIEDOPERATIONGROUPID);
        if (unspecifiedGroup) {
          this._unspecifiedGroups$.next(unspecifiedGroup);
          this.unspecifiedGroupTasks = unspecifiedGroup.tasks;
        }
      })
    );
  }

  public openDownloadShapeFilesDialog() {
    combineLatest([
      this.farmStateService.selectedFarms$,
      this.unspecifiedGroups$,
      this.asAppliedTasks$.pipe(
        map((groups) => groups.find((task) => task.id === OperationTypeGroupEnum.Fertilizer)),
        filterNullish()
      ),
    ])
      .pipe(
        take(1),
        this.mapToDialogData(),
        switchMap((data) =>
          // all farms contain no items
          data === null
            ? of(this.notificationService.showWarning('main.asApplied.metaData.noZipFiles'))
            : this.dialogService.openCustomDialog(DownloadManagerDialogComponent, { maxWidth: '100vw', data }).afterClosed()
        ),
        filterNullish(),
        filter((x: DownloadManagerResult) => !isEmptyArray(x.items)),
        switchMap(({ farm, items }) =>
          this.asAppliedTaskDetailsService
            .exportShapeFiles(
              farm.id,
              (items as ListOfLists).flatMap((x) => x.items).map((x) => x.id) // TODO: Do without type casting
            )
            .pipe(
              filterNullish(),
              map((file) => [farm.name, file] as const) // keep farm name
            )
        ),
        tap(([farmName, file]) => {
          DownloadHelper.downloadBlob(`${farmName.trim().replace(/\s/g, '_')}.zip`, file); // trim string & replace all white-spaces with underscores
          this.notificationService.showSuccess('main.asApplied.taskDetails.downLoadSuccess');
        })
      )
      .subscribe();
  }

  public resetState() {
    this.selectedTasks = null;
    this.fieldAnalysisSideDrawerService.setShowLegendState(false);
    this.asAppliedFileUploadService.notDirty();
  }

  public saveClicked() {
    this._saveClicked.next();
  }

  public nextClicked() {
    // set config
    this.asAppliedFileUploadService.saveAsAppliedShpConfigFile();
  }

  private initAsAppliedComponent() {
    this.getAppliedTasks();
    this.metadataParentDtos$ = this.asAppliedFileUploadService.getMetadataParentDtos().pipe(
      withLatestFrom(this.farmStateService.selectedFarms$),
      map(([metadataParentDtos, selectedFarms]) =>
        metadataParentDtos.map((metadataParentDto) => {
          const metaDataParentFarm = selectedFarms.find((farm) => farm.id === metadataParentDto.farmId);
          this.fieldAnalysisSideDrawerService.setShowLegendState(false);
          return new MetadataParent(metadataParentDto, this.languageService, metaDataParentFarm);
        })
      )
    );
    this.subscriptions.add(this.mapService.mapReady$.subscribe(() => this.mapService.showLayer(MapLayerId.FIELDS)));
  }

  private onCloseNavigate() {
    this.shownComponent$.pipe(first()).subscribe((shownComponent) => {
      switch (shownComponent) {
        case AsAppliedShownComponentEnum.AsAppliedTaskComponent:
          this.fieldAnalysisSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
          break;
        case AsAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent:
          if (this.asAppliedService.executedArchiveFileUpload.executedArchiveFileType === 2) {
            this.cleanUpMap();
            this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedConfigFile);
          } else {
            this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
          }
          break;
        default:
          this.asAppliedService.setShownComponent(AsAppliedShownComponentEnum.AsAppliedTaskComponent);
          this.resetState();
          this.map.forceRerender();
          break;
      }
    });
  }

  /**
   * Abstracted mapper operator that maps the dialog data
   */
  private mapToDialogData(): UnaryFunction<
    Observable<[Farm[], AsAppliedOperationTypeGroup, AsAppliedOperationTypeGroup]>,
    Observable<DownloadManagerData | null>
  > {
    return pipe(
      map(([farms, unspecifiedGroups, fertilizerGroups]) => {
        const data = farms.map((farm) => {
          const unspecifiedTasks = unspecifiedGroups.tasks.filter((task) => task.farmId === farm.id);
          const fertilizeTasks = fertilizerGroups?.tasks.filter((task) => task.farmId === farm.id);

          const unspecifiedTaskList = {
            id: OperationTypeGroupEnum.Unspecified,
            title: unspecifiedGroups.name,
            items: unspecifiedTasks,
          };

          const fertilizerTaskList = {
            id: OperationTypeGroupEnum.Fertilizer,
            title: fertilizerGroups?.name ?? '',
            items: fertilizeTasks ?? [],
          };

          return {
            farm,
            items: [unspecifiedTaskList, fertilizerTaskList],
          };
        });

        // all farms contain no items
        const isEmpty = data.every((farm) => farm.items.every((x) => x.items.length === 0));

        // if all farms contain no items, return null instead
        if (isEmpty) return null;
        return data;
      })
    );
  }

  // Reset state and clean up map
  private cleanUpMap() {
    this.resetState();
    this.map.cleanup();
    this.map.forceRerender();
  }
}
