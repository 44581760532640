import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { Field } from '@app/core/interfaces/field.interface';
import { MapService } from '@app/core/map/map.service';
import { getStyle } from '@app/helpers/map/map-styles/map-styles';
import Feature from 'ol/Feature';
import { SelectEvent } from 'ol/interaction/Select';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-map-feature-select',
  templateUrl: './map-feature-select.component.html',
  styleUrls: ['./map-feature-select.component.scss'],
})
export class MapFeatureSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public layers: MapLayerId[] = [];
  @Input() public toggle = false;
  @Input() public disabled = false;
  @Input() public selectedFeatures: Feature[] = [];
  @Output() public field = new EventEmitter<Field>();
  @Output() public event = new EventEmitter<SelectEvent>();

  private featureSelectSubscription = new Subscription();

  constructor(private mapService: MapService) {}

  public ngOnInit() {
    this.featureSelectSubscription = this.mapService.mapReady$
      .pipe(
        tap(() => {
          this.disabled ? this.mapService.disableFeatureSelection() : this.mapService.enableFeatureSelection();
        }),
        switchMap(() => this.getFieldSelect$())
      )
      .subscribe((event: SelectEvent) => {
        const field = event.selected[0] ? event.selected[0].get('field') : null;
        this.event.emit(event);
        this.field.emit(field);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.mapService.getMap()) {
      if (changes['disabled']) {
        changes['disabled'].currentValue ? this.mapService.disableFeatureSelection() : this.mapService.enableFeatureSelection();
      }
    }
  }

  public ngOnDestroy() {
    this.featureSelectSubscription.unsubscribe();
  }

  private getFieldSelect$() {
    const olMap = this.mapService.getMap();
    return olMap.addSelectInteraction(this.toggle, this.layers, this.selectedFeatures).pipe(
      filter((event) => !!event),
      tap((event) => {
        setTimeout(() => {
          // Selected style is sometimes not removed if the layer has been disabled and re-enabled.
          // This occurs when clicking menu points where layers are hidden and shown as needed.
          // This code ensures that deselected features will have the correct style.
          event.deselected.forEach((feature) =>
            feature.setStyle(
              getStyle(
                event.mapBrowserEvent.map.getView().getZoom()!,
                feature.get('features') ? feature.get('features').length : 1,
                feature.get('features') ? feature.get('features')[0] : feature
              )
            )
          );
        });
      })
    );
  }
}
