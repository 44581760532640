<mat-form-field>
  <input
    appNumbersOnly
    (focus)="onFocus()"
    (focusout)="onFocusOut()"
    [maxLength]="maxLength"
    matInput
    autocomplete="off"
    [style.text-align]="textAlign"
    [type]="type"
    [min]="min"
    [max]="max"
    [step]="step"
    [readonly]="readonly"
    [placeholder]="placeholder!"
    [errorStateMatcher]="errorStateMatcher!"
    (ngModelChange)="onChange($event)"
    [(ngModel)]="value"
  />
  <mat-error *ngFor="let errorMsg of errorMsgs">
    <ng-container [ngTemplateOutlet]="errorMsg.templateRef!"></ng-container>
  </mat-error>
</mat-form-field>
