<mat-form-field [ngClass]="{ simple: simple }">
  <mat-label>{{ placeholder }}</mat-label>
  <input
    #input
    matInput
    [matDatepicker]="picker"
    [disabled]="disabled"
    [ngModel]="date"
    [min]="minDate"
    [max]="maxDate"
    (dateChange)="onDateChange($event)"
    (click)="picker.open()"
    placeholder="{{ placeholder }}"
    (focusin)="onFocusIn(input)"
    autocomplete="off"
  />
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngFor="let errorMsg of errorMsgs">
    <ng-container [ngTemplateOutlet]="errorMsg.templateRef!"></ng-container>
  </mat-error>
</mat-form-field>
<app-icon *ngIf="showIcon" icon="date_range" (click)="picker.open()"></app-icon>
