import { Component } from '@angular/core';
import { DecisionSupportService } from '@app/new-map/features/cultivation-journal/map-features/decision-support/decision-support.service';

@Component({
  selector: 'app-blight-wrapper',
  templateUrl: './blight-wrapper.component.html',
  styleUrls: ['./blight-wrapper.component.scss'],
})
export class BlightWrapperComponent {
  public field$ = this.decisionSupportService.field$;
  constructor(private decisionSupportService: DecisionSupportService) {}
}
