<div class="sort-column-header" #sortHeader>
  <div class="title-area" [ngStyle]="{ cursor: columnSorter ? 'pointer' : 'default' }" (click)="sortChange.emit(columnSorter)">
    {{ title! | translate }}
  </div>

  <ng-container *ngIf="filterables$ | async as filterables">
    <app-icon
      *ngIf="filterables.length > filterHideThreshold"
      class="filter-icon"
      icon="filter"
      [ngClass]="{ active: isDropdownActive }"
      (click)="onFilterClick($event)"
    ></app-icon>
    <ng-container *ngTemplateOutlet="filterPopup; context: { $implicit: filterables }"></ng-container>
  </ng-container>
  <ng-container [ngSwitch]="columnSorter?.sortDirection">
    <span *ngSwitchCase="'asc'" class="k-icon k-i-sort-asc-sm" (click)="sortChange.emit(columnSorter)"></span>
    <span *ngSwitchCase="'desc'" class="k-icon k-i-sort-desc-sm" (click)="sortChange.emit(columnSorter)"></span>
  </ng-container>
</div>

<ng-template #filterPopup let-filterables>
  <kendo-popup *ngIf="isDropdownActive" [anchor]="sortHeader">
    <div class="input-container" *ngFor="let filterable of filterables">
      <input
        type="checkbox"
        id="{{ filterable.getIdentifier(columnKey) }}"
        class="k-checkbox"
        [checked]="filterable.isFilterActive"
        (change)="onFilterChange(filterable)"
      />
      <ng-container *ngIf="customTemplate; else defaultLabel">
        <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: filterable }"></ng-container>
      </ng-container>
      <ng-template #defaultLabel>
        <label class="k-checkbox-label" for="{{ filterable.getIdentifier(columnKey) }}">{{ filterable.getTitle(columnKey) }}</label>
      </ng-template>
    </div>
  </kendo-popup>
</ng-template>
