import { DateTime } from 'luxon';
import { SoilSampleGroupDTO } from './soil-sample-group.dto';

export class SoilSampleGroup {
  public readonly date: DateTime;
  public readonly numberOfSamples?: number;

  public readonly dateString: string;
  public readonly year: number | null;

  constructor(dto: Partial<SoilSampleGroupDTO>, dateFormat: string) {
    const date = DateTime.fromISO(dto.date!);

    this.date = date.setZone('utc');

    this.numberOfSamples = dto.numberOfSamples;

    this.year = date.year;
    this.dateString = date.toFormat(dateFormat);
  }
}
