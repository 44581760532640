import { Pipe, PipeTransform } from '@angular/core';
import { FeatureToggleService } from '@app/libraries/ng-feature-toggles/services/feature-toggle.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'featureToggle',
})
/**
 * Checks if the given feature toggle key is toggled on.
 * If the key doesnt exist, false is returned.
 *
 * Usage example:
 * <p *ngIf="'key' | featureToggle | async; else toggledOffTemplate>
 *    *toggled on text*
 * <p/>
 * <ng-template #toggledOffTemplate>
 *    <p>
 *        *toggled off text*
 *    </p>
 * </ng-template>
 */
export class FeatureTogglePipe implements PipeTransform {
  constructor(private featureToggleService: FeatureToggleService) {}

  public transform(featureKey: string): Observable<boolean> {
    return this.featureToggleService.get$(featureKey);
  }
}
