import { Injectable } from '@angular/core';
import { Field } from '@app/core/interfaces/field.interface';
import { NdviHistory } from '@app/core/interfaces/ndvi-history.interface';
import { SatelliteImageDate } from '@app/map/features/field-analysis/features/ndvi-feature/interfaces/ndvi-image-date.interface';
import { ClearSkyTileType } from '@app/map/features/field-analysis/features/ndvi-feature/ndvi-feature.component';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NdviStateService {
  private _sateliteImageDateSubject: BehaviorSubject<DateTime | null> = new BehaviorSubject<DateTime | null>(null);
  private _ndviImageDatesSubject: BehaviorSubject<SatelliteImageDate[] | null | undefined> = new BehaviorSubject<
    SatelliteImageDate[] | null | undefined
  >(null);
  private _selectedNdviHistorySubject: BehaviorSubject<NdviHistory | null> = new BehaviorSubject<NdviHistory | null>(null);
  private _selectedNdviFieldSubject: BehaviorSubject<Field | null> = new BehaviorSubject<Field | null>(null);
  private _selectedTileType: BehaviorSubject<ClearSkyTileType> = new BehaviorSubject<ClearSkyTileType>(ClearSkyTileType.ClearSkyNDVI);

  private _imagesFromClearSky = new BehaviorSubject<boolean>(true);

  public get sateliteImageDate$(): Observable<DateTime | null> {
    return this._sateliteImageDateSubject.asObservable();
  }

  public set sateliteImageDate(sateliteImageDate: DateTime | null) {
    this._sateliteImageDateSubject.next(sateliteImageDate);
  }

  public get selectedNdviHistory$() {
    return this._selectedNdviHistorySubject.asObservable();
  }

  public set selectedNdviHistory(ndviHistory: NdviHistory | null) {
    this._selectedNdviHistorySubject.next(ndviHistory);
  }

  public get selectedNdviField$() {
    return this._selectedNdviFieldSubject.asObservable();
  }

  public set selectedNdviField(field: Field | null) {
    this._selectedNdviFieldSubject.next(field);
  }

  public get ndviImageDates$() {
    return this._ndviImageDatesSubject.asObservable();
  }

  public set ndviImageDates(ndviImageDates: SatelliteImageDate[] | null | undefined) {
    this._ndviImageDatesSubject.next(ndviImageDates);
  }

  public set imagesFromClearSky(fromClearSky: boolean) {
    this._imagesFromClearSky.next(fromClearSky);
  }

  public get imagesFromClearSky$() {
    return this._imagesFromClearSky.asObservable();
  }

  public get selectedTileType$() {
    return this._selectedTileType.asObservable();
  }

  public set selectedTileType(tileType: ClearSkyTileType) {
    this._selectedTileType.next(tileType);
  }
}
