<div class="create-field-main-wrapper">
  <app-side-drawer-header
    class="flex-child"
    [title]="'main.fieldAdministration.createField.title' | translate"
    (action)="onCloseClick()"
    cancelIconName="back"
  ></app-side-drawer-header>

  <div class="create-field-content">
    <app-side-drawer-body>
      <p class="choose-method">{{ 'main.fieldAdministration.createField.chooseMethod' | translate }}</p>

      <app-square-button
        buttonType="Secondary"
        (clicked)="onSelectFieldClick()"
        [appShortcutKey]="shortcuts.chooseField"
        (shortcutPressed)="onSelectFieldClick()"
      >
        <app-icon icon="add_box"></app-icon>
        {{ 'main.fieldAdministration.createField.selectFieldBlock' | translate }}
      </app-square-button>
      <p>{{ 'main.fieldAdministration.createField.or' | translate }}</p>
      <app-square-button
        buttonType="Secondary"
        (clicked)="onDrawFieldClick()"
        [appShortcutKey]="shortcuts.drawField"
        (shortcutPressed)="onDrawFieldClick()"
      >
        <app-icon icon="edit_outline"></app-icon>
        {{ 'main.fieldAdministration.createField.drawField' | translate }}
      </app-square-button>
    </app-side-drawer-body>
  </div>
</div>
