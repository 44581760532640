<app-side-drawer-toolbar class="flex-child" [title]="'grassPrognosis.settings.title' | translate" (action)="onCloseClick()">
</app-side-drawer-toolbar>

<div class="content-wrapper">
  <app-spinner *ngIf="isLoading" [hasBackdrop]="true" [message]="loadingMessage"></app-spinner>
  <div class="buttons">
    <app-square-button [buttonType]="'Secondary'" (clicked)="onSaveClick()"
      >{{ 'grassPrognosis.settings.save' | translate }}
    </app-square-button>
  </div>
  <form [formGroup]="formGroup">
    <div class="cut-settings">
      <app-select [placeholder]="'grassPrognosis.settings.currentCut' | translate" formControlName="cutNumber">
        <app-select-option [value]="opt.value" *ngFor="let opt of cutOptions">{{ opt.text }}</app-select-option>
      </app-select>

      <div class="previous-cut-settings" [ngClass]="{ visible: cutNumberControl!.value > 1 }">
        <app-date-picker
          [disabled]="cutNumberControl!.value <= 1"
          [showIcon]="true"
          [date]="options.previousCutDate"
          [placeholder]="'grassPrognosis.settings.previousCut' | translate: previousCut"
          (dateChange)="onPreviousCutDateChange($event)"
        >
        </app-date-picker>

        <app-select [placeholder]="'grassPrognosis.settings.yield' | translate: previousCut" formControlName="previousCutYield">
          <app-select-option [value]="opt.value" *ngFor="let opt of yieldOptions">{{ opt.value }}</app-select-option>
        </app-select>
      </div>
    </div>

    <app-side-drawer-sub-header>
      {{ 'grassPrognosis.settings.sample' | translate }}
    </app-side-drawer-sub-header>

    <div class="additional-settings">
      <div class="settings-checkbox">
        <div class="checkbox-label">{{ 'grassPrognosis.settings.enableSampling' | translate }}:</div>
        <app-checkbox (onChange)="onSampleChange($event)" [checked]="options.useSampleData"></app-checkbox>
      </div>
      <div class="sample-selects" [ngClass]="{ visible: options.useSampleData }">
        <app-date-picker
          [disabled]="!options.useSampleData"
          [showIcon]="true"
          [date]="options.sampleDate"
          placeholder="{{ 'grassPrognosis.settings.sampleDate' | translate }}"
          (dateChange)="onSampleDateChange($event)"
        ></app-date-picker>

        <app-select [placeholder]="'grassPrognosis.settings.fkOrg' | translate" formControlName="sampleFKOrg">
          <app-select-option [value]="opt.value" *ngFor="let opt of fkOrgOptions">{{ opt.text }}</app-select-option>
        </app-select>

        <app-select [placeholder]="'grassPrognosis.settings.protein' | translate" formControlName="sampleRp">
          <app-select-option [value]="opt.value" *ngFor="let opt of proteinOptions">{{ opt.text }}</app-select-option>
        </app-select>

        <app-select [placeholder]="'grassPrognosis.settings.sugar' | translate" formControlName="sampleSugar">
          <app-select-option [value]="opt.value" *ngFor="let opt of sugarOptions">{{ opt.text }}</app-select-option>
        </app-select>
      </div>
    </div>
  </form>
</div>
