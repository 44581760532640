import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CoarseFodderDataSource } from '@app/core/economy/enum/coarseFodderDataSource.enum';
import { CoarseFodderEconomyDataSource } from '@app/core/economy/interface/coarsefodder';
import { FormHelper } from '@app/helpers/form/form-helper';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { filter } from 'rxjs/operators';
import { DataSourceChangeEvent } from '../events/DataSourceChangeEvent';
import { SourceUtil } from '../sourceUtil';
import { EconomyStateService } from './../../economy-state.service';
import { CellChangeEvent } from './../events/CellChangeEvent';
import { EditStateEvent, EditStateStatus } from './../events/EditStateEvent';
import { SourcepickerComponent } from './../sourcepicker/sourcepicker.component';

@Component({
  selector: 'app-editable-grid-cell',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './editable-grid-cell.component.html',
  styleUrls: ['./editable-grid-cell.component.scss'],
})
export class EditableGridCellComponent implements OnInit {
  @ViewChild('cellinput', { static: false }) public input?: ElementRef;

  public editing = false;

  @Input() public value?: number | null = null;
  @Input() public canEdit = true;
  @Input() public suffix!: string;
  @Input() public fieldName!: string;
  @Input() public cellColumnName!: string;
  @Input() public sources: CoarseFodderEconomyDataSource[] = [];
  @Input() public currentSource: CoarseFodderDataSource = CoarseFodderDataSource.Manual;
  @Output() public onEditCellComplete = new EventEmitter<CellChangeEvent>();
  @Output() public onEditStateChange = new EventEmitter<EditStateEvent>();
  @Output() public onSourceChange = new EventEmitter<DataSourceChangeEvent>();

  public showSources$ = this.economyStateService.showSources$;

  @Input() public validationFunctions: Function[] = [];
  public form!: UntypedFormGroup;
  public errors: string[] = [];

  constructor(
    private dialogService: DialogService,
    private economyStateService: EconomyStateService,
    private formBuilder: UntypedFormBuilder,
    private changeDetection: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.form = this.formBuilder.group({
      cellValue: [!this.isNaN(this.value) ? Math.round(this.value!) : null, [...this.validationFunctions]],
    });
  }

  public onInputFocus(e: Event) {
    (e.target as HTMLInputElement).select();
  }

  public onInputFocusOut(e: Event) {
    this.onEditComplete((e.target as HTMLInputElement).value);
  }

  public onKeyUpEnter(e: Event) {
    this.onEditComplete((e.target as HTMLInputElement).value);
  }

  public onValueClick() {
    if (this.canEdit) {
      this.form?.setValue({ cellValue: this.isNaN(this.value) ? null : this.value });
      this.editing = true;
      setTimeout(() => {
        this.input!.nativeElement.focus();
      });
      this.onEditStateChange.emit({
        status: EditStateStatus.Editing,
      });
    }
  }

  public onEditComplete(value: any) {
    this.validate();
    if (this.errors.length === 0) {
      this.editing = false;
      this.onEditCellComplete.emit({ value: value });
      this.onEditStateChange.emit({
        status: EditStateStatus.EditingComplete,
      });
      this.value = value;
    } else {
      this.onEditStateChange.emit({
        status: EditStateStatus.Errored,
      });
    }
  }

  public onSourceClick() {
    const dialog = this.dialogService.openSm(SourcepickerComponent, {
      data: { sources: this.sources, selectedSource: this.currentSource, fieldName: this.fieldName, columnName: this.cellColumnName },
    });
    dialog
      .afterClosed()
      .pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.currentSource = result.selectedSource;
        this.value = Math.round(this.sources.find((source) => source.dataSource === result.selectedSource)!.dataSourceValue!);
        this.onSourceChange.emit({
          source: result.selectedSource,
          sourceValue: Math.round(this.value),
        });
        this.changeDetection.detectChanges();
      });
  }

  public getStyleForSource(source: CoarseFodderDataSource) {
    const color = SourceUtil.getTabColorFromSource(source);
    return `border-top: 15px solid ${color}`;
  }

  public validate() {
    this.errors = FormHelper.getAllErrorsFromForm(this.form).map((e) => `main.economy.table.validationErrors.${e}`);
  }

  public isNaN(value: any) {
    return Number.isNaN(value);
  }
}
