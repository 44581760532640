<form [formGroup]="parentForm" [ngSwitch]="shownComponent$ | async">
  <mat-card appearance="outlined">
    <mat-card-title>
      {{ 'main.asApplied.metaData.parentTitle' | translate }}
    </mat-card-title>
    <mat-card-content>
      <!--  FILE NAME  -->
      <app-input id="fileName" formControlName="fileName" [placeholder]="'main.asApplied.metaData.fileName' | translate"> </app-input>

      <!--  FARM  -->
      <app-input id="farm" formControlName="farm" [placeholder]="'main.asApplied.metaData.farm' | translate"> </app-input>

      <!--  HARVEST YEAR -->
      <app-input id="harvestYear" formControlName="harvestYear" [placeholder]="'main.asApplied.metaData.harvestYear' | translate">
      </app-input>
    </mat-card-content>
  </mat-card>
  <br />
  <div *ngSwitchCase="asAppliedShownComponentEnum.CreateAsAppliedMetaDataComponent">
    <app-meta-data-child-form
      [@flyInOut]="'in'"
      *ngFor="let childForm of childForms"
      [form]="$any(childForm)"
      [isEditMode]="false"
      [selectableFields$]="selectableFields$"
      [selectableTasks$]="selectableTasks$"
      [maxDate]="MAX_DATE.toJSDate()"
      (onRemoveChild)="removeChildForm($event)"
      (onPointToggle)="togglePoint($event)"
      (onFieldNumberChange)="updateDataFieldOutlines()"
    >
    </app-meta-data-child-form>
    <br />
    <app-square-button class="action-btn delete-btn" (clicked)="removeClicked()">
      <span class="mat-button-wrapper">{{ 'main.asApplied.metaData.deleteTitle' | translate | capitalize }}</span>
    </app-square-button>
  </div>
</form>

<app-cell-hover [mapLayerId]="(mapLayerId$ | async)!">
  <app-as-applied-tooltip *cellFeature="let feature" [feature]="feature"></app-as-applied-tooltip>
</app-cell-hover>

<app-cell-hover [mapLayerId]="(mapLayerPointId$ | async)!">
  <app-as-applied-tooltip *cellFeature="let feature" [feature]="feature"></app-as-applied-tooltip>
</app-cell-hover>
