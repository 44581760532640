export function handleLegacyInternetExplorer() {
  const browserStrings = getBrowserStrings();

  if (parseInt(browserStrings![2], 10) < 11) {
    const element = document.getElementsByTagName('app-root')[0];

    element.parentNode!.removeChild(element);

    const elem = document.createElement('p');

    elem.innerHTML =
      'Din browser er forældet. Opdater til <a href="https://www.google.com/chrome/">Chrome</a> eller <a href="https://www.microsoft.com/da-dk/windows/microsoft-edge">Edge</a> for at sikre en god oplevelse med CropManager.';

    document.body.appendChild(elem);
  }
}

export function isInternetExplorer() {
  return getBrowserStrings()![1] === 'MSIE';
}

export function getBrowserStrings() {
  return navigator.userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i);
}
