import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BffAuthService } from '@app/core/authentication/bff-auth.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { AppLayoutService } from '@app/core/layout/app-layout.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { LanguageStateService } from '@app/state/services/language/language-state.service';
import { Observable, Subscription, noop } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss'],
  host: { 'collision-id': 'HeaderComponent' },
})
export class HeaderComponent implements OnInit, OnDestroy {
  public readonly harvestYear$: Observable<number | undefined> = this.harvestYearStateService.harvestYear$;
  public selectedFarms$!: Observable<Farm[]>;
  public menuMinimized$ = this.layoutService.menuMinimized$;
  public isNaesgaard$!: Observable<boolean | undefined>;

  private farms!: Farm[];
  private harvestYear?: number;
  private subscriptions: Subscription[] = [];
  private currentLanguage?: string | null;

  constructor(
    private router: Router,
    private layoutService: AppLayoutService,
    private farmStateService: FarmStateService,
    private harvestYearStateService: HarvestYearStateService,
    private bffAuthService: BffAuthService,
    private languageStateService: LanguageStateService
  ) {}

  public ngOnInit() {
    this.isNaesgaard$ = this.bffAuthService.isCurrentUserFromNaesgaard$;
    this.selectedFarms$ = this.farmStateService.selectedFarms$;
    this.subscriptions.push(
      this.selectedFarms$.subscribe((farms) => (this.farms = farms)),
      this.harvestYear$.subscribe((harvestYear) => (this.harvestYear = harvestYear)),

      this.languageStateService.currentLanguage$.subscribe((currentLanguage) => (this.currentLanguage = currentLanguage))
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public async navigate(andExpandMenu?: boolean) {
    andExpandMenu ? this.layoutService.toggleMenuMimized() : noop();
    await this.router.navigate(['/newmap/cultivation-journal'], {
      queryParams: {
        harvestYear: this.harvestYear,
        farmIds: JSON.stringify(this.farms.map((f) => f.id)),
        currentLanguage: JSON.stringify(this.currentLanguage),
      },
    });
  }
}
