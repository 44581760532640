import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { ClimateModels } from '@app/core/enums/climate-models.enum';
import { HttpClient } from '@app/core/http/http-client';
import { GrassPrognosisOptions } from '@app/shared/grass-prognosis/grass-prognosis-settings/grass-prognosis-settings.interface';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GrassPrognosisSampleValue } from './grass-prognosis-sample-value.interface';
import { GrassPrognosis, ResultsData } from './grass-prognosis.interface';

@Injectable({
  providedIn: 'root',
})
export class GrassPrognosisRepoService {
  private cutNumbersCache = this.cacheService.create<GrassPrognosisSampleValue[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  private fkOrgValuesCache = this.cacheService.create<GrassPrognosisSampleValue[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  private rpValuesCache = this.cacheService.create<GrassPrognosisSampleValue[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  private sugarValuesCache = this.cacheService.create<GrassPrognosisSampleValue[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  private previousCutYieldValuesCache = this.cacheService.create<GrassPrognosisSampleValue[]>({
    defaultTtl: 20 * 60 * 1000,
  });

  constructor(
    private http: HttpClient,
    private endpoints: EndpointsService,
    private cacheService: CacheService
  ) {}

  public getPrognosis(
    model: ClimateModels,
    farmId: number,
    harvestYear: number,
    featureId: number,
    cropId: number,
    settings: GrassPrognosisOptions
  ): Observable<GrassPrognosis | null> {
    const options: any = {};

    return this.http
      .post<
        GrassPrognosis,
        GrassPrognosisOptions
      >(`${this.endpoints.climateModelsApi}/models/${model}/farms/${farmId}/${harvestYear}/features/${featureId}/crops/${cropId}/results`, settings, options)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }

  public getPrognosisResults(
    model: ClimateModels,
    farmId: number,
    harvestYear: number,
    featureId: number,
    cropId: number,
    settings: GrassPrognosisOptions
  ): Observable<ResultsData | null> {
    const options: any = {};

    return this.http
      .post<
        ResultsData,
        GrassPrognosisOptions
      >(`${this.endpoints.climateModelsApi}/models/${model}/farms/${farmId}/${harvestYear}/features/${featureId}/crops/${cropId}/graphdata`, settings, options)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }

  public getOptions(model: ClimateModels): Observable<GrassPrognosisOptions> {
    return this.http
      .get<GrassPrognosisOptions>(`${this.endpoints.climateModelsApi}/models/${model}/usersettings`)
      .pipe(catchError((response: HttpErrorResponse) => throwError(response.error)));
  }

  public getCutNumbers(): Observable<GrassPrognosisSampleValue[]> {
    const key = 'grass-prognosis-cut-numbers';
    const getValue = () => {
      return this.http.get<GrassPrognosisSampleValue[]>(`${this.endpoints.climateModelsApi}/models/hayharvestprognosis/options/cutnumber`);
    };

    return this.cutNumbersCache.getOrSetAsync(key, () => getValue());
  }

  public getSampleFKOrgValues(): Observable<GrassPrognosisSampleValue[]> {
    const key = 'grass-prognosis-fk-org';
    const getValue = () => {
      return this.http.get<GrassPrognosisSampleValue[]>(
        `${this.endpoints.climateModelsApi}/models/hayharvestprognosis/options/samplefkorgvalues`
      );
    };

    return this.fkOrgValuesCache.getOrSetAsync(key, () => getValue());
  }

  public getSampleRPValues(): Observable<GrassPrognosisSampleValue[]> {
    const key = 'grass-prognosis-rp';
    const getValue = () => {
      return this.http.get<GrassPrognosisSampleValue[]>(
        `${this.endpoints.climateModelsApi}/models/hayharvestprognosis/options/samplerpvalues`
      );
    };

    return this.rpValuesCache.getOrSetAsync(key, () => getValue());
  }

  public getSampleSugarValues(): Observable<GrassPrognosisSampleValue[]> {
    const key = 'grass-prognosis-sugar';
    const getValue = () => {
      return this.http.get<GrassPrognosisSampleValue[]>(
        `${this.endpoints.climateModelsApi}/models/hayharvestprognosis/options/samplesugarvalues`
      );
    };

    return this.sugarValuesCache.getOrSetAsync(key, () => getValue());
  }

  public getPreviousCutYieldValues(): Observable<GrassPrognosisSampleValue[]> {
    const key = 'grass-prognosis-previous-cut-yield';
    const getValue = () => {
      return this.http.get<GrassPrognosisSampleValue[]>(
        `${this.endpoints.climateModelsApi}/models/hayharvestprognosis/options/previouscutyield`
      );
    };

    return this.previousCutYieldValuesCache.getOrSetAsync(key, () => getValue());
  }
}
