<div class="scale-legend legend-container" *ngIf="settings">
  <h3 data-testid="scale-header" class="scale-header">{{ settings.title }}</h3>
  <div data-testid="scale-items" class="scale-items">
    <div class="legend-item" *ngFor="let legendItem of settings.items">
      <div class="color-square" [ngStyle]="{ 'background-color': legendItem.color }">&nbsp;</div>
      <span class="text">{{ legendItem.text }}</span>
    </div>
  </div>
  <div class="summary" *ngIf="isSummaryVisible">
    <div class="legend-summary-line" *ngFor="let summaryLine of settings.summaryLines; let index = index">
      <span class="description">{{ summaryLine.description }}</span
      ><span attr.data-testid="legend-summary-line-{{ index }}"> {{ summaryLine.quantity }} {{ summaryLine.unit }}</span>
    </div>
  </div>
  <div class="legend-note">{{ settings.note }}</div>
</div>
