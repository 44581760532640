<div class="create-field-form-wrapper">
  <app-side-drawer-header
    class="flex-child"
    [title]="isEditing ? ('main.fieldAdministration.editField' | translate) : ('main.fieldAdministration.createField.title' | translate)"
    [showMinimizeButton]="true"
    cancelIconName="back"
    (action)="onCloseClick()"
    (onHide)="onHideClick()"
  >
  </app-side-drawer-header>

  <app-side-drawer-body>
    <div class="create-field-form">
      <app-spinner *ngIf="isLoading$ | async" [message]="(loadingMessage$ | async)! | translate" [hasBackdrop]="true"> </app-spinner>

      <form class="field-form" [formGroup]="formGroup">
        <div class="form-input" *ngIf="(selectedFarms$ | async)!.length > 1">
          <app-select class="form-select" [placeholder]="'Bedrift'" formControlName="farmId">
            <app-select-option *ngFor="let farm of selectedFarms$ | async" [value]="farm.id">
              {{ farm.name }}
            </app-select-option>
          </app-select>
          <div class="icon-wrapper">
            <app-icon
              class="check-icon"
              icon="check"
              *ngIf="!formGroup?.controls?.['farmId']?.errors?.['required'] && !formGroup?.errors?.['lockedHarvestYear']"
            ></app-icon>
            <app-icon class="validation-failed-icon" icon="close" *ngIf="formGroup?.errors?.['lockedHarvestYear']"> </app-icon>
          </div>
        </div>
        <span class="farm-select-error-text" *ngIf="formGroup?.errors?.['lockedHarvestYear']">{{
          formGroup.errors?.['lockedHarvestYear']
        }}</span>
        <p class="draw-field-description" data-testid="draw-field-description" *ngIf="isDescriptionVisible$ | async">
          <span *ngIf="!featureIsFromFieldBlock">{{ 'main.fieldAdministration.createField.drawFieldDescription' | translate }}</span>
          <span *ngIf="featureIsFromFieldBlock">{{ 'main.fieldAdministration.createField.selectFieldBlockDescription' | translate }}</span>
        </p>

        <p *ngIf="isRedrawVisible$ | async">
          {{ 'main.fieldAdministration.createField.selectExcludedZones' | translate }}
        </p>

        <app-square-button class="delete-btn" buttonType="Secondary" *ngIf="isEditing" (clicked)="onDeleteFieldClicked()">
          <app-icon icon="delete_outline"></app-icon>
          {{ 'main.fieldAdministration.createField.delete' | translate }}
        </app-square-button>
        <app-square-button
          class="redraw-btn"
          buttonType="Secondary"
          *ngIf="!featureIsFromFieldBlock && (isRedrawVisible$ | async) && !isEditing"
          (clicked)="onRedrawClick(true)"
        >
          {{ 'main.fieldAdministration.createField.redrawField' | translate }}
        </app-square-button>
        <app-square-button
          class="redraw-btn"
          buttonType="Secondary"
          *ngIf="!featureIsFromFieldBlock && (isRedrawVisible$ | async) && isEditing"
          (clicked)="onRedrawClick(false)"
        >
          {{ 'main.fieldAdministration.createField.redrawField' | translate }}
        </app-square-button>
        <app-square-button
          class="redraw-btn"
          buttonType="Secondary"
          *ngIf="featureIsFromFieldBlock && (isRedrawVisible$ | async) && !isEditing"
          (clicked)="onBlockReselectClick()"
        >
          {{ 'main.fieldAdministration.createField.reselectFieldBlock' | translate }}
        </app-square-button>
        <app-square-button class="cancel-drawing-btn" buttonType="Secondary" *ngIf="isDrawing$ | async" (clicked)="onCancelClick()">
          <app-icon class="check-icon" icon="close"></app-icon>
          {{ 'main.fieldAdministration.createField.cancelDrawing' | translate }}
        </app-square-button>

        <div class="info">
          <app-info-hover [buttonText]="'main.fieldAdministration.createField.info.info' | translate">
            <div class="info-box">
              <h4 class="info-box-header">{{ 'main.fieldAdministration.createField.info.headline' | translate }}</h4>
              <p>
                <strong class="info-box-field-name">{{ 'main.fieldAdministration.createField.calculatedArea' | translate }} :</strong>
                {{ 'main.fieldAdministration.createField.info.calculatedAreaDescription' | translate }}
              </p>
              <p>
                <strong class="info-box-field-name">{{ 'main.fieldAdministration.createField.registeredJbNo' | translate }} :</strong>
                {{ 'main.fieldAdministration.createField.info.registeredJbNoDescription' | translate }}
              </p>
            </div>
          </app-info-hover>
        </div>

        <div class="area">
          <p class="area-text">{{ 'main.fieldAdministration.createField.calculatedArea' | translate }}:</p>
          <div class="area-value">
            <p>
              {{ fieldArea$ | async }}<span> {{ 'common.unitType.ha' | translate }}</span>
            </p>
          </div>
          <div class="icon-wrapper">
            <app-icon class="check-icon" icon="check" *ngIf="!!(drawnPolygons$ | async)?.fieldPolygon"></app-icon>
          </div>
        </div>

        <div class="jb-no">
          <p class="select-title">{{ 'main.fieldAdministration.createField.registeredJbNo' | translate }}:</p>
          <app-select class="jb-select" formControlName="soilTypeId">
            <app-select-option *ngFor="let soilType of soilTypes$ | async" [value]="soilType.id">{{ soilType.id }} </app-select-option>
          </app-select>
          <div class="icon-wrapper">
            <app-icon class="check-icon" icon="check" *ngIf="soilTypeIdFormControl?.valid"></app-icon>
          </div>
        </div>

        <div class="form-input">
          <app-select class="form-select" [placeholder]="(preCropsSelectPlaceHolder$ | async)! | translate" formControlName="preCropId">
            <app-select-option *ngFor="let preCrop of preCrops$ | async; trackBy: trackByFn" [value]="preCrop.number">
              <span [ngClass]="{ dimmed: preCrop.number! < 0 }">{{ preCrop.name }}</span>
            </app-select-option>
          </app-select>
          <div class="icon-wrapper">
            <app-icon class="check-icon" icon="check" *ngIf="preCropIdFormControl?.valid"></app-icon>
          </div>
        </div>

        <div class="form-input">
          <app-select class="form-select" [placeholder]="(cropsSelectPlaceHolder$ | async)! | translate" formControlName="crop">
            <app-select-option *ngFor="let crop of crops$ | async; trackBy: trackByFn" [value]="crop">
              <span [ngClass]="{ dimmed: crop.number === undefined || null }">{{ crop.name }}</span>
            </app-select-option>
          </app-select>
          <div class="icon-wrapper">
            <app-icon class="check-icon" icon="check" *ngIf="cropFormControl?.valid"></app-icon>
          </div>
        </div>

        <div class="form-input">
          <app-select class="form-select" [placeholder]="(varietiesSelectPlaceholder$ | async)! | translate" formControlName="varietyId">
            <app-select-option *ngFor="let variety of varieties$ | async; trackBy: trackByFn" [value]="variety.number">
              {{ variety.name }}
            </app-select-option>
          </app-select>
          <div class="icon-wrapper">
            <app-icon class="check-icon" icon="check" *ngIf="varietyIdFormControl?.valid"></app-icon>
          </div>
        </div>

        <div class="field-no">
          <div class="input-wrapper">
            <div class="field-no-text">
              <div>{{ 'main.fieldAdministration.createField.typeFieldNo' | translate }}</div>
              <div class="field-no-error-wrapper">
                <div class="field-no-error" [class.visible]="fieldNumberFormControl?.invalid && !(isValidatingFieldNumber$ | async)">
                  <span *ngIf="isInvalidFieldNumberFormatMessageVisible">{{
                    'main.fieldAdministration.createField.fieldNoError' | translate
                  }}</span>
                  <span *ngIf="isFieldNumberTakenMessageVisible">{{
                    'main.fieldAdministration.createField.invalidFieldNumber' | translate
                  }}</span>
                </div>
              </div>
            </div>
            <app-field-number-input class="field-no-input" formControlName="fieldNumber"></app-field-number-input>
            <div class="icon-wrapper">
              <app-icon
                class="check-icon"
                icon="check"
                *ngIf="fieldNumberFormControl?.valid && !(isValidatingFieldNumber$ | async)"
              ></app-icon>
            </div>
          </div>
        </div>

        <div class="form-input">
          <app-input
            class="field-name-input"
            placeholder="{{ 'main.fieldAdministration.createField.typeName' | translate }}"
            type="text"
            formControlName="name"
          ></app-input>
          <div class="icon-wrapper">
            <app-icon class="check-icon" icon="check" *ngIf="nameFormControl?.valid"></app-icon>
          </div>
        </div>
      </form>
    </div>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button [disabled]="!(isSaveEnabled$ | async)" (clicked)="onSaveClick()"
      >{{ 'common.save' | translate }}
    </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</div>
