import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageStateService {
  private _currentLanguageSubject = new BehaviorSubject<string | null>(null);

  public get currentLanguage$() {
    return this._currentLanguageSubject.asObservable();
  }

  public set currentLanguage(currentLanguage: string) {
    this._currentLanguageSubject.next(currentLanguage);
  }
}
