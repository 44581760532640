<div class="main-wrapper">
  <app-side-drawer-header
    title="{{ 'navigation.hotspots' | translate }}"
    [showInfoHoverButton]="true"
    (action)="onCloseClick()"
    (onHide)="hideDrawer()"
    cancelIconName="back"
    [showMinimizeButton]="true"
  >
    <app-side-drawer-info-hover buttonText="info"
      ><p>{{ 'main.hotspots.hotspotInfo' | translate }}</p></app-side-drawer-info-hover
    >
  </app-side-drawer-header>
  <app-side-drawer-body>
    <app-hotspot-details-presentation
      #hotspotDetailsPresentation
      [selectedFarms]="(selectedFarms$ | async)!"
      [hotspot]="hotspot"
      [hotspotTypes]="hotspotTypes"
      [hotspotSubTypes]="hotspotSubTypes"
      (delete)="deleteClick($event)"
      (save)="saveClick($event)"
    ></app-hotspot-details-presentation>
    <app-spinner *ngIf="saving" [hasBackdrop]="true" [message]="'main.hotspots.hotSpotSaveMessage' | translate"></app-spinner>
  </app-side-drawer-body>
  <app-side-drawer-footer>
    <app-side-drawer-footer-button id="#e2e-save-hotspot" (clicked)="footerClicked({ methodName: 'saveClick', param1: $event })"
      >{{ 'common.save' | translate }}
    </app-side-drawer-footer-button>
  </app-side-drawer-footer>
</div>
