<app-spinner *ngIf="isLoading" [hasBackdrop]="false" [message]="'grassPrognosis.grazing.fetchingData.fenData' | translate"></app-spinner>

<ng-container *ngIf="!isLoading && errorMessage.length === 0">
  {{ 'grassPrognosis.grazing.titles.fenTitle' | translate }}
  <br />
  <br />
  <kendo-chart (render)="onRender($event)" data-testid="grassPrognosis.kendo-chart">
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        name="resultsCategoryAxis"
        [baseUnit]="categoryAxis.baseUnit!"
        [type]="categoryAxis.type!"
        [labels]="categoryAxis.labels!"
        [majorGridLines]="categoryAxis.majorGridLines!"
      >
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-value-axis>
      <kendo-chart-value-axis-item name="valueAxis"></kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-series>
      <!-- Forecast -->
      <kendo-chart-series-item
        type="area"
        [color]="prognosisAttributeValue.colors.forecastAreaColor"
        [data]="prognosis!.fenData.forecast"
        categoryAxis="resultsCategoryAxis"
        field="value"
        categoryField="date"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'grassPrognosis.grazing.tooltips.fen.forecastHeader' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | number: '1.2-2' }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      <kendo-chart-series-item
        categoryAxis="resultsCategoryAxis"
        [data]="prognosis!.fenData.forecast"
        field="value"
        categoryField="date"
        type="line"
        [color]="prognosisAttributeValue.colors.forecastLineColor"
        [markers]="prognosisMarkers"
        [width]="prognosisAttributeValue.lineWidths.forecastLineWidth"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'grassPrognosis.grazing.tooltips.fen.forecastHeader' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | number: '1.2-2' }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      <!-- Observed -->
      <kendo-chart-series-item
        type="area"
        [color]="prognosisAttributeValue.colors.observationAreaColor"
        [data]="prognosis!.fenData.observed"
        categoryAxis="resultsCategoryAxis"
        field="value"
        categoryField="date"
        missingValues="gap"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'grassPrognosis.grazing.tooltips.fen.observation' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | number: '1.2-2' }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
      <kendo-chart-series-item
        categoryAxis="resultsCategoryAxis"
        [data]="prognosis!.fenData.observed"
        field="value"
        categoryField="date"
        type="line"
        [color]="prognosisAttributeValue.colors.observationLineColor"
        [markers]="prognosisMarkers"
        [width]="prognosisAttributeValue.lineWidths.observationLineWidth"
        [opacity]="prognosisAttributeValue.opacities.notTansparent"
      >
        <kendo-chart-series-item-tooltip [background]="prognosisAttributeValue.colors.toolTipBackgroundColor">
          <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
            {{ 'grassPrognosis.grazing.tooltips.fen.observation' | translate }}
            <br />
            {{ category | dateFormat }}
            <br />
            {{ value | number: '1.2-2' }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
</ng-container>

<div *ngIf="errorMessage && errorMessage.length" class="error-message">
  <hr />
  <b>{{ 'grassPrognosis.grazing.titles.fenTitle' | translate }}</b>
  <p>{{ errorMessage }}</p>
</div>
