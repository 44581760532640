import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Field } from '@app/core/interfaces/field.interface';
import { Subscription } from 'rxjs';
import { FailedFields } from '../../../../../core/interfaces/FailedFields';
import { FieldCopyStateService } from './../field-copy-state.service';

@Component({
  selector: 'app-field-copy-complete-dialog',
  templateUrl: './field-copy-complete-dialog.component.html',
  styleUrls: ['./field-copy-complete-dialog.component.scss'],
})
export class FieldCopyCompleteDialogComponent implements OnInit, OnDestroy {
  public transferingFields$ = this.fieldCopyStateService.transferingFields$;
  public transferComplete = false;
  public failedTransferedFields$ = this.fieldCopyStateService.failedTransferedFields$;
  private subscription = new Subscription();
  constructor(
    private fieldCopyStateService: FieldCopyStateService,
    public dialogRef: MatDialogRef<FieldCopyCompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { updatedFields: Field[]; failedFields: FailedFields[] }
  ) {}
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    this.subscription.add(
      this.transferingFields$.subscribe((complete) => {
        this.transferComplete = !complete;
      })
    );
  }

  public onConfirmClick() {
    if (this.transferComplete) {
      this.dialogRef.close();
      this.fieldCopyStateService.resetTransferedFields();
    }
  }
}
