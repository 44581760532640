<div class="header-container">
  <div class="navigation">
    <app-icon class="nav-btn" [icon]="cancelIconName" (click)="cancel()" data-testid="cancel"> </app-icon>
    <span title="{{ title }}" class="header-title" data-testid="title">{{ title }}</span>
  </div>
  <div class="info" *ngIf="showInfoHoverButton">
    <span>
      <ng-content></ng-content>
    </span>
  </div>
  <app-icon *ngIf="showMinimizeButton" class="nav-btn minimize" [icon]="hideIconName" (click)="hide()"></app-icon>
</div>
