import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FieldPickerComponent } from '@app/map/features/field-analysis/features/drone-image-import/drone-side-drawer/field-picker/field-picker.component';
import { FilePickerComponent } from '@app/map/features/field-analysis/features/drone-image-import/drone-side-drawer/file-picker/file-picker.component';
import { SharedModule } from '@app/shared/shared.module';
import { PriceComponent } from '../price/price.component';

@NgModule({
  declarations: [FilePickerComponent, FieldPickerComponent, PriceComponent],
  imports: [CommonModule, SharedModule],
  exports: [FilePickerComponent],
})
export class FilePickerModule {}
