import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { map, withLatestFrom } from 'rxjs/operators';
import { BasisLayerShownComponentEnum } from '../basis-layer-shown-component.enum';
import { BasisLayerStateService } from '../basis-layer-state.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';

@Component({
  selector: 'app-basis-layer-side-drawer',
  templateUrl: './basis-layer-side-drawer.component.html',
  styleUrls: ['./basis-layer-side-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasisLayerSideDrawerComponent {
  public basisLayerShownComponent$ = this.basisLayerStateService.shownComponentState$.pipe(map((sc) => sc.shownComponent));
  public components = BasisLayerShownComponentEnum;
  public sideDrawerWidth = SideDrawerConfig.widthAsOpened;
  public basisLayerCategories$ = this.basisLayerStateService.basisLayerCategories$.pipe(
    withLatestFrom(this.farmStateService.selectedFarms$),
    map(([basisLayerCategories, farmIds]) => basisLayerCategories.filter((category) => category.farmId === farmIds[0].id))
  );

  constructor(
    private basisLayerStateService: BasisLayerStateService,
    private farmStateService: FarmStateService
  ) {}
}
