<mat-checkbox
  (change)="onChanged($event)"
  [disabled]="disabled"
  [indeterminate]="indeterminate"
  [checked]="checked"
  [labelPosition]="labelPosition"
  [color]="'primary'"
  [ngClass]="{ circle: isCircle }"
>
  {{ label }}
  <ng-content></ng-content>
</mat-checkbox>
