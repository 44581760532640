import { BasisLayerCategoryColors } from '@app/core/interfaces/basis-layer/basis-layer-category-colors.enum';
import { BasisLayerCategoryDto } from '@app/core/interfaces/basis-layer/basis-layer-category-dto.interface';
import { BasisLayerCategoryType } from '@app/core/interfaces/basis-layer/basis-layer-category-type.enum';
import { BasisLayerCategoryIcon } from './basis-layer-category-icon.enum';

export class BasisLayerCategory implements BasisLayerCategoryDto {
  public set basisLayerTypeNormNumber(normNumber: number | undefined) {
    this._color = BasisLayerCategory.getColorFromTypeNormNumber(normNumber!);
    this._basisLayerTypeNormNumber = normNumber;
    this._icon = BasisLayerCategory.getIconFromTypeNormNumber(normNumber!);
  }

  public get basisLayerTypeNormNumber(): number | undefined {
    return this._basisLayerTypeNormNumber;
  }

  public get color() {
    return this._color;
  }

  public get icon() {
    return this._icon;
  }

  private static getColorFromTypeNormNumber(basisLayerTypeNormNumber: number) {
    switch (basisLayerTypeNormNumber) {
      case BasisLayerCategoryType.hills:
        return BasisLayerCategoryColors.hills;
      case BasisLayerCategoryType.hollow:
        return BasisLayerCategoryColors.hollow;
      case BasisLayerCategoryType.windbreaks:
        return BasisLayerCategoryColors.windbreaks;
      case BasisLayerCategoryType.headland:
        return BasisLayerCategoryColors.headland;
      case BasisLayerCategoryType.waterlogged:
        return BasisLayerCategoryColors.waterlogged;
      case BasisLayerCategoryType.sandySpots:
        return BasisLayerCategoryColors.sandySpots;
      case BasisLayerCategoryType.other:
        return BasisLayerCategoryColors.other;
      default:
        return BasisLayerCategoryColors.other;
    }
  }

  private static getIconFromTypeNormNumber(basisLayerTypeNormNumber: number) {
    switch (basisLayerTypeNormNumber) {
      case BasisLayerCategoryType.hills:
        return BasisLayerCategoryIcon.hills;
      case BasisLayerCategoryType.hollow:
        return BasisLayerCategoryIcon.hollow;
      case BasisLayerCategoryType.windbreaks:
        return BasisLayerCategoryIcon.windbreaks;
      case BasisLayerCategoryType.headland:
        return BasisLayerCategoryIcon.headland;
      case BasisLayerCategoryType.waterlogged:
        return BasisLayerCategoryIcon.waterlogged;
      case BasisLayerCategoryType.sandySpots:
        return BasisLayerCategoryIcon.sandySpots;
      case BasisLayerCategoryType.other:
        return BasisLayerCategoryIcon.other;
      default:
        return BasisLayerCategoryIcon.other;
    }
  }

  private _basisLayerTypeNormNumber?: number;
  private _color!: string;
  private _icon!: string;
  public diseaseAdjustment?: number;
  public farmId?: number;
  public fertilizerAdjustment?: number;
  public growthRegulationAdjustment?: number;
  public id?: number;
  public name?: string;
  public pestsAdjustment?: number;
  public seedingAdjustment?: number;
  public weedAdjustment?: number;
  public limeAdjustment?: number;

  constructor(basisLayerCategory?: BasisLayerCategoryDto) {
    this.basisLayerTypeNormNumber = basisLayerCategory?.basisLayerTypeNormNumber;
    this.diseaseAdjustment = basisLayerCategory?.diseaseAdjustment;
    this.farmId = basisLayerCategory?.farmId;
    this.fertilizerAdjustment = basisLayerCategory?.fertilizerAdjustment;
    this.growthRegulationAdjustment = basisLayerCategory?.growthRegulationAdjustment;
    this.id = basisLayerCategory?.id;
    this.name = basisLayerCategory?.name;
    this.pestsAdjustment = basisLayerCategory?.pestsAdjustment;
    this.seedingAdjustment = basisLayerCategory?.seedingAdjustment;
    this.weedAdjustment = basisLayerCategory?.weedAdjustment;
    this.limeAdjustment = basisLayerCategory?.limeAdjustment;
  }
}
