import { Injectable } from '@angular/core';
import { CacheService } from '@app/core/cache/cache.service';
import { Field } from '@app/core/interfaces/field.interface';
import { OperationType } from '@app/core/interfaces/operation.type';
import { ProduceNorm } from '@app/core/interfaces/produce-norm.interface';
import { Task } from '@app/core/interfaces/task.interface';
import { OperationTypeGroupsService } from '@app/core/operation-type-groups/operation-type-groups.service';
import { OperationTypesService } from '@app/core/operation-types/operation-types.service';
import { ProduceNormsService } from '@app/core/produce-norms/produce-norms.service';
import { OperationTypeGroup } from '@app/core/repositories/operation-type-groups/operation-type-groups.interface';
import { TasksRepo } from '@app/core/repositories/tasks/tasks-repo.service';
import keyBy from 'lodash-es/keyBy';
import values from 'lodash-es/values';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export interface ITaskService {
  deleteTask(taskId: number): Observable<number>;
}

export interface ITaskEnrichmentData {
  types: { [key: string]: OperationType };
  groups: { [key: string]: OperationTypeGroup };
  produceNorms: { [key: string]: ProduceNorm };
}

/**
 * TaskService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private tasksFromRepoCache = this.cacheService.create<Task[]>();
  private tasksForFarmCache = this.cacheService.create<Task[]>();
  private enrichmentDataCache = this.cacheService.create<ITaskEnrichmentData>({
    defaultTtl: 1000 * 60 * 60 * 24,
  });

  constructor(
    private tasksRepo: TasksRepo,
    private operationTypesService: OperationTypesService,
    private operationTypeGroupsService: OperationTypeGroupsService,
    private produceNormsService: ProduceNormsService,
    private cacheService: CacheService
  ) {}

  /**
   * Adds tasks to the corresponding field.
   * @param fields
   * @param tasks
   */
  public mapTasksToField(fields: Field[], tasks: Task[]): Field[] {
    return fields.map((field) => {
      field.tasks = field.crops.map((crop) => tasks.find((task) => task.cropId === crop.id)).filter((task) => task !== undefined) as Task[];

      return field;
    });
  }

  public getTasksByField(farmId: number, harvestYear: number, fieldId: number, options = { bustCache: false }) {
    const key = `${farmId}-${harvestYear}-${fieldId}`;

    if (options.bustCache) {
      this.tasksFromRepoCache.delete(key);
    }

    return this.tasksFromRepoCache
      .getOrSetAsync(key, () => this.tasksRepo.getTasksForField(farmId, harvestYear, fieldId))
      .pipe(switchMap((tasks) => this.enrichTasks(tasks)));
  }

  public deleteTask(taskId: number) {
    return this.tasksRepo.deleteTask(taskId).pipe(tap(() => this.clearCaches()));
  }

  private clearCaches() {
    this.tasksForFarmCache.clear();
    this.tasksFromRepoCache.clear();
  }

  public createTask(task: Task) {
    this.clearCaches();
    return this.tasksRepo.createTask(task).pipe(switchMap((t) => this.enrichTask(t!)));
  }

  public updateTask(id: number, task: Task) {
    this.clearCaches();

    return this.tasksRepo.updateTask(task).pipe(switchMap((t) => this.enrichTask(t!)));
  }

  private mapToTaskEnrichmentData(
    types: OperationType[],
    groups: { [key: string]: OperationTypeGroup },
    produceNorms: ProduceNorm[]
  ): ITaskEnrichmentData {
    return {
      types: keyBy(types, 'id'),
      groups,
      produceNorms: keyBy(produceNorms, 'number'),
    };
  }

  private getOperationTypesAndGroups(farmId: number, harvestYear: number): Observable<ITaskEnrichmentData> {
    const key = `${farmId}-${harvestYear}`;

    const getValue = () =>
      combineLatest(
        this.operationTypesService.get(),
        this.operationTypeGroupsService.getByOperationId(),
        this.produceNormsService.getProduceNorms(farmId, harvestYear)
      ).pipe(map(([types, groups, produceNorms]) => this.mapToTaskEnrichmentData(types, groups, produceNorms)));

    return this.enrichmentDataCache.getOrSetAsync(key, () => getValue());
  }

  private enrichTasks(tasks: Task[]) {
    if (tasks.length < 1) {
      return of([]);
    }
    const enrichmentDataset: { [key: string]: { farmId: number; harvestYear: number } } = {};

    tasks.forEach(({ farmId, harvestYear }) => (enrichmentDataset[`${farmId}-${harvestYear}`] = { farmId, harvestYear }));

    const enrichmentDataResult$ = values(enrichmentDataset).map(({ farmId, harvestYear }) =>
      this.getOperationTypesAndGroups(farmId, harvestYear).pipe(
        map((enrichmentData) => ({
          farmId,
          harvestYear,
          enrichmentData,
        }))
      )
    );

    return combineLatest([...enrichmentDataResult$]).pipe(
      map((result) => this.mapEnrichmentData(result)),
      map((enrichmentDataMap) =>
        tasks.map((task) => {
          const actualEnrichmentData = enrichmentDataMap[task.farmId][task.harvestYear];
          return this.enrich(task, actualEnrichmentData);
        })
      ),
      map((result) => this.filterOutOperationsWithUnkownTypeForTasks(result))
    );
  }

  private filterOutOperationsWithUnkownTypeForTasks(tasks: Task[]): Task[] {
    return tasks.map((task) => this.filterOutOperationsWithUnkownType(task));
  }

  private filterOutOperationsWithUnkownType(task: Task): Task {
    return {
      ...task,
      operations: task.operations.filter(
        (operation) => operation.operationType !== undefined && operation.operationTypeGroup !== undefined
      ),
    };
  }

  private mapEnrichmentData(arr: { farmId: number; harvestYear: number; enrichmentData: ITaskEnrichmentData }[]): {
    [farmId: number]: { [harvestYear: number]: ITaskEnrichmentData };
  } {
    return arr.reduce((farms: any, { farmId, harvestYear, enrichmentData }) => {
      if (!farms[farmId]) {
        farms[farmId] = {};
      }

      if (!farms[farmId][harvestYear]) {
        farms[farmId][harvestYear] = {};
      }

      farms[farmId][harvestYear] = enrichmentData;

      return farms;
    }, {});
  }

  private enrichTask(task: Task): Observable<Task> {
    return this.enrichTasks([task]).pipe(map((tasks) => tasks[0]));
  }

  private enrich(task: Task, enrichmentData: ITaskEnrichmentData) {
    this.enrichWithOperationTypeGroups(task, enrichmentData.groups);
    this.enrichWithOperationType(task, enrichmentData.types);
    this.enrichwithProduceNorms(task, enrichmentData.produceNorms);
    return task;
  }

  private enrichWithOperationTypeGroups(task: Task, groups: { [operationTypeId: string]: OperationTypeGroup }) {
    if (groups && task.operations && task.operations.length) {
      task.operations = task.operations.map((operation) => ({
        ...operation,
        operationTypeGroup: groups[operation.operationTypeId],
      }));
    }

    return task;
  }

  private enrichWithOperationType(task: Task, operationTypes: { [operationTypeId: string]: OperationType }) {
    if (operationTypes && task.operations && task.operations.length) {
      task.operations = task.operations.map((operation) => ({
        ...operation,
        operationType: operationTypes[operation.operationTypeId],
      }));
    }

    return task;
  }

  private enrichwithProduceNorms(task: Task, produceNorms: { [produceNormNumber: string]: ProduceNorm }) {
    if (!(produceNorms && task.operations && task.operations.length)) {
      return task;
    }

    task.operations = task.operations.map((operation) => {
      if (!(operation.operationLines && operation.operationLines.length)) {
        return operation;
      }

      operation.operationLines = operation.operationLines.map((operationLine) => {
        if (operationLine.produceNormNumber && produceNorms[operationLine.produceNormNumber]) {
          operationLine.produceNorm = produceNorms[operationLine.produceNormNumber];
        } else {
          operationLine.produceNorm = {
            canRegisterDryMatterPct: false,
            defaultPrice: 0,
            defaultQuantity: 0,
            farmId: operationLine.farmId,
            harvestYear: operationLine.harvestYear,
            isHarvestedMainProduct: false,
            isMainProduct: false,
            isSelectable: false,
            name: '(Deleted)',
            number: operationLine.produceNormNumber,
            operationTypeGroupId: -1,
            operationTypeId: -1,
            unitText: undefined,
            varietyGroupName: '',
            varietyGroupNormNumber: 0,
            cropNormNumber: 0,
            yieldTypeGroupNormNumber: 0,
          };
        }

        return operationLine;
      });

      return operation;
    });

    return task;
  }
}
