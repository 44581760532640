<div class="legend-container">
  <h3 class="legend-header">{{ 'main.growthRegulation.legend.title' | translate }}</h3>
  <div>
    <div class="legend-item">
      <div class="color" [ngStyle]="{ 'background-color': getScoreColor(-100) }">&nbsp;</div>
      <span class="info-line">{{ 'main.growthRegulation.legend.lowRisk' | translate }}</span>
    </div>
    <div class="legend-item">
      <div class="color" [ngStyle]="{ 'background-color': getScoreColor(0) }">&nbsp;</div>
      <span class="info-line">{{ 'main.growthRegulation.legend.mediumRisk' | translate }}</span>
    </div>
    <div class="legend-item">
      <div class="color" [ngStyle]="{ 'background-color': getScoreColor(100) }">&nbsp;</div>
      <span class="info-line">{{ 'main.growthRegulation.legend.highRisk' | translate }}</span>
    </div>
    <div class="legend-item-top-aligned">
      <div class="color no-data">&nbsp;</div>
      <span class="info-multi-line">{{ 'main.growthRegulation.legend.noData' | translate }}</span>
    </div>
    <div class="info-text">{{ 'main.growthRegulation.legend.info' | translate }}</div>
  </div>
</div>
