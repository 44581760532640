import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Crop } from '@app/core/interfaces/crop.interface';
import { BenchmarkStateService } from '@app/state/services/benchmark/benchmark-state.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-crop-select',
  templateUrl: './crop-select.component.html',
  styleUrls: ['./crop-select.component.scss'],
})
export class CropSelectComponent implements OnInit, OnDestroy {
  public readonly validCrops$: Observable<Crop[]> = this.benchmarkStateService.validCrops$;
  @Output() public cropSelect = new EventEmitter<Crop>();

  public cropsControl = new UntypedFormControl();

  public crops: Crop[] = [];
  public placeholder = 'loading';

  // @ts-ignore - TS7008 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  private translations;

  private subscriptions: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private benchmarkStateService: BenchmarkStateService
  ) {}

  public ngOnInit() {
    this.subscriptions.push(
      this.translate.get(['main.benchmark.regionalComp.loading', 'main.benchmark.regionalComp.placeholder']).subscribe((translations) => {
        this.translations = translations;
        this.placeholder = translations['main.benchmark.regionalComp.loading'];
      }),
      this.validCrops$.subscribe((crops) => {
        if (crops && crops.length) {
          this.cropsControl.enable();
          this.crops = crops;
        } else {
          this.cropsControl.disable();
        }
        this.placeholder = this.translations['main.benchmark.regionalComp.placeholder'];
      }),
      this.cropsControl.valueChanges.subscribe((crop: Crop) => {
        if (this.cropsControl.pristine === false) {
          this.cropSelect.emit(crop);
          this.cropsControl.markAsPristine();
        } else {
          // @ts-ignore - TS2345 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
          this.cropSelect.emit(null);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
