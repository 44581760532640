import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { DateHelper } from '@app/helpers/date/date-helper';
import { DateValidators } from '@app/helpers/validators/forms/date-validators';
import {
  BLIGHT_PROGNOSIS_END_DAY,
  BLIGHT_PROGNOSIS_END_MONTH,
  BLIGHT_PROGNOSIS_START_DAY,
  BLIGHT_PROGNOSIS_START_MONTH,
} from '@app/new-map/features/cultivation-journal/blight/blight.config';
import { filterNullish } from '@app/shared/operators';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { DateTime } from 'luxon';
import { Subscription, combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-blight-registration',
  templateUrl: './blight-registration.component.html',
  styleUrls: ['./blight-registration.component.scss'],
})
export class BlightRegistrationComponent implements OnInit, OnDestroy {
  public registrationForm!: UntypedFormGroup;
  @Input() public blightDiscovered!: boolean;
  @Input() public blightDiscoveredDate!: DateTime;
  @Output() public onblightRegistration = new EventEmitter<{ blight: any; date: any }>();
  public canSave = false;
  public registrationEnabled = true;
  public upperBoundDate!: DateTime;
  public lowerBoundDate!: DateTime;
  private readonly TODAY: DateTime;
  private subscription = new Subscription();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private harvestYearStateService: HarvestYearStateService,
    private notificationService: NotificationService,
    private languageService: LanguageService
  ) {
    this.TODAY = DateHelper.today;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    this.subscription.add(
      this.harvestYearStateService.harvestYear$.pipe(filterNullish()).subscribe((harvestyear) => {
        let newStartValue = {
          year: harvestyear,
          month: BLIGHT_PROGNOSIS_START_MONTH,
          day: BLIGHT_PROGNOSIS_START_DAY,
        };

        let newEndValue = {
          year: +harvestyear!,
          month: BLIGHT_PROGNOSIS_END_MONTH,
          day: BLIGHT_PROGNOSIS_END_DAY,
        };

        this.lowerBoundDate = DateTime.fromObject(newStartValue);

        if (this.TODAY.year > +harvestyear!) {
          this.upperBoundDate = DateTime.fromObject(newEndValue);
        } else {
          let sameYear = this.TODAY.year === +harvestyear!;
          let moreThanLower = this.TODAY >= this.lowerBoundDate;
          let lessThanUpper = this.TODAY <= DateTime.fromObject(newEndValue);

          if (sameYear && moreThanLower && lessThanUpper) {
            this.upperBoundDate = this.TODAY;
          } else {
            this.upperBoundDate = this.TODAY;
            this.registrationEnabled = false;
          }
        }
        this.registrationForm = this.initForm();
        this.onChanges();
      })
    );
  }

  private initForm(): UntypedFormGroup {
    const blight = this.blightDiscovered;
    const date = (this.blightDiscoveredDate.isValid ? this.blightDiscoveredDate : this.upperBoundDate).setZone('utc', {
      keepLocalTime: true,
    });

    return this.formBuilder.group({
      blightDiscovered: [blight],
      date: [
        { value: date, disabled: !blight },
        [Validators.required, DateValidators.minDate(this.lowerBoundDate), DateValidators.maxDate(this.upperBoundDate)],
      ],
    });
  }

  private onChanges() {
    this.subscription.add(
      this.registrationForm.get('blightDiscovered')!.valueChanges.subscribe((blight) => {
        if (blight) {
          this.registrationForm.get('date')!.enable();
          return;
        }
        this.registrationForm.get('date')!.disable();
      })
    );

    this.subscription.add(
      combineLatest([
        this.registrationForm.get('blightDiscovered')!.valueChanges.pipe(startWith(true)),
        this.registrationForm.get('date')!.valueChanges,
      ]).subscribe(() => (this.canSave = true))
    );
  }

  public saveRegistration() {
    if (this.canSave) {
      this.canSave = false;
      const blight = this.registrationForm.get('blightDiscovered')!.value;
      const date = this.registrationForm.get('date')!.value;
      this.onblightRegistration.emit({ blight: blight, date: date });
    } else {
      this.notificationService.showInfo(this.languageService.getText('main.potatoBlight.registration.noChanges'));
    }
  }
}
