import { Component, OnDestroy, OnInit } from '@angular/core';
import { SideDrawerOverlayService } from '@app/core/side-drawer-overlay/side-drawer-overlay.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { FeedBackStateService } from '@app/state/services/feed-back/feed-back-state.service';
import { Observable } from 'rxjs';
import { SideDrawerConfig } from '../side-drawer/side-drawer-config';
import { FeedbackSideDrawerContentComponent } from './feedback-side-drawer-content/feedback-side-drawer-content.component';
import { FeedbackSideDrawerService } from './feedback-side-drawer.service';

@Component({
  selector: 'app-feedback-side-drawer',
  templateUrl: './feedback-side-drawer.component.html',
  styleUrls: ['./feedback-side-drawer.component.scss'],
})
export class FeedbackSideDrawerComponent implements OnInit, OnDestroy {
  public sideDrawerRef!: SideDrawerRef<FeedbackSideDrawerContentComponent, void, void>;

  // @ts-ignore - TS2322 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly isFeedbackOpen$: Observable<boolean> = this.feedBackStateService.isFeedBackOpen$;

  // @ts-ignore - TS2300 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  // @ts-ignore - TS2717 - IGNORED BY SCRIPT Jan 2023 - https://segesinnovation.atlassian.net/browse/CT2-7121
  public readonly isFeedbackOpen$: Observable<boolean | null> = this.feedBackStateService.isFeedBackOpen$;

  constructor(
    private feedbackSideDrawerService: FeedbackSideDrawerService,
    private sideDrawerService: SideDrawerOverlayService,
    private feedBackStateService: FeedBackStateService
  ) {}

  public ngOnInit() {
    this.openSideDrawer();
  }

  public ngOnDestroy() {
    this.sideDrawerRef.close();
  }

  public closeSideDrawer() {
    this.sideDrawerRef.close();
  }

  public openSideDrawer() {
    this.feedbackSideDrawerService.drawerWidth = SideDrawerConfig.widthAsOpened;

    this.sideDrawerRef = this.sideDrawerService.openCustomSideDrawer(FeedbackSideDrawerContentComponent, {
      panelClass: 'feedback-content-side-drawer',
      width: SideDrawerConfig.widthAsOpened,
    });

    this.sideDrawerRef.backdropClicked.subscribe(() => {
      return;
    });

    this.sideDrawerRef.onClose.subscribe(() => {
      this.feedbackSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
      this.setFeedbackClosed();
    });
  }

  private setFeedbackClosed = () => (this.feedBackStateService.isFeedBackOpen = false);
}
