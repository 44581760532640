import { Injectable } from '@angular/core';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { HttpClient } from '@app/core/http/http-client';
import { Observable } from 'rxjs';
import { DroneImageInformation } from './../../interfaces/drone-image-information';

@Injectable({
  providedIn: 'root',
})
export class DroneImageInformationRepoService {
  constructor(
    public http: HttpClient,
    private endpoints: EndpointsService
  ) {}

  public get(farmIds: number[], harvestYear: number): Observable<DroneImageInformation[]> {
    return this.http.get<DroneImageInformation[]>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/solvi`);
  }

  public getProcessed(farmIds: number[], harvestYear: number): Observable<DroneImageInformation[]> {
    return this.http.get<DroneImageInformation[]>(`${this.endpoints.foApi}/farms/${farmIds}/${harvestYear}/solvi/processedlist`);
  }
}
