import { Injectable } from '@angular/core';
import exifr from 'exifr';
import { from, Observable } from 'rxjs';
import { ImageMetaData } from '@app/core/exif/image-meta-data';

@Injectable({
  providedIn: 'root',
})
export class ExifService {
  constructor() {}

  public getGeometryMetaData(files: File[]): Observable<ImageMetaData[]> {
    return from(
      Promise.all(
        files.map((f) =>
          exifr.gps(f).then((data) => {
            if (!data) {
              return Promise.reject({ fileWithoutGPS: f.name });
            }
            if (data.latitude === 0 || data.longitude === 0) {
              return Promise.reject({ invalidGpsDataOnFile: f.name });
            }
            return new ImageMetaData(data.longitude, data.latitude, f.name);
          })
        )
      )
    );
  }
}
