import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MapLayerId } from '@app/core/enums/map-layer-id.enum';
import { SubscriptionLevel } from '@app/core/enums/subscription-level.enum';
import { MapService } from '@app/core/map/map.service';
import { QueryParamService } from '@app/core/query-param/query-param.service';
import { SideDrawerRef } from '@app/core/side-drawer-overlay/side-drawer-ref';
import { SIDE_DRAWER_ANIMATION_SETTING } from '@app/map/features/side-drawer-const';
import { MapCoverFlowItem } from '@app/shared/map-cover-flow/map-cover-flow-item';
import { OpenLayersMapComponent } from '@app/shared/openlayers-map/openlayers-map.component';
import { SideDrawerConfig } from '@app/shared/side-drawer/side-drawer-config';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { HarvestYearStateService } from '@app/state/services/harvest-year/harvest-year-state.service';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { fieldAnalysisMapLayerIdxQueryParamKey } from '../../features/field-analysis-features.component';
import { FieldAnalysisFeaturesService } from '../../features/field-analysis-features.service';
import { FieldAnalysisFeaturesActionsService } from '../../features/selector/field-analysis-features.actions.service';
import { FieldAnalysisFeaturesSelector } from '../../features/selector/field-analysis-features.selector';
import { FieldAnalysisPickerService } from '../../field-analysis-feature-picker/field-analysis-picker.service';
import { FieldAnalysisService } from '../../field-analysis.service';
import { FieldAnalysisShownComponentEnum } from '../field-analysis-shown-component.enum';
import { FieldAnalysisSideDrawerService } from '../field-analysis-side-drawer.service';
import { filterNullish } from '@app/shared/operators';

@Component({
  selector: 'app-field-analysis-side-drawer-content',
  templateUrl: './field-analysis-side-drawer-content.component.html',
  styleUrls: ['./field-analysis-side-drawer-content.component.scss'],
  animations: SIDE_DRAWER_ANIMATION_SETTING,
})
export class FieldAnalysisSideDrawerContentComponent implements OnInit, OnDestroy {
  public fieldAnalysisShownComponent$ = this.fieldAnalysisMapSideDrawerService.shownComponentState$.pipe(map((sc) => sc.shownComponent));
  public components = FieldAnalysisShownComponentEnum;

  // for cover flow
  public mapCoverFlowItems: MapCoverFlowItem[] = [];

  public onFieldClicked$ = this.fieldAnalysisService.onFieldClicked$;

  public get sideDrawerFeatureContentRef(): TemplateRef<any> {
    return this.fieldAnalysisMapSideDrawerService.sideDrawerFeatureContentRef;
  }

  public set sideDrawerFeatureContentRef(templateRef: TemplateRef<any>) {
    this.fieldAnalysisMapSideDrawerService.sideDrawerFeatureContentRef = templateRef;
  }

  public canSeeSelectedField?: boolean;

  public selectedMapFeature?: MapLayerId = MapLayerId.NONE;

  private get map(): OpenLayersMapComponent {
    return this.mapService.getMap();
  }

  // Subscriptions
  private subscriptions: Subscription[] = [];
  public isMapReady = false;

  constructor(
    private fieldAnalysisMapService: FieldAnalysisFeaturesService,
    private fieldAnalysisMapSideDrawerService: FieldAnalysisSideDrawerService,
    public fieldAnalysisMapSelector: FieldAnalysisFeaturesSelector,
    public fieldAnalysisActionDispatchers: FieldAnalysisFeaturesActionsService,
    public mapService: MapService,
    private harvestYearStateService: HarvestYearStateService,
    private farmStateService: FarmStateService,
    private fieldAnalysisPickerService: FieldAnalysisPickerService,
    private sideDrawerRef: SideDrawerRef<FieldAnalysisSideDrawerContentComponent, void, void>,
    private fieldAnalysisService: FieldAnalysisService,
    private queryParamService: QueryParamService
  ) {}

  public ngOnInit(): void {
    this.mapService.mapReady$.pipe(first()).subscribe(() => {
      this.handleMapReady();
    });

    const onFieldClickedSubscription = this.onFieldClicked$.subscribe((clicked) => {
      if (clicked) {
        this.sideDrawerRef.show();
      }
    });
    this.subscriptions.push(onFieldClickedSubscription);
  }

  public ngOnDestroy(): void {
    this.fieldAnalysisActionDispatchers.selectedMapCoverFlowItemIdxChanged(null);
    this.fieldAnalysisMapSideDrawerService.setShownComponentState(FieldAnalysisShownComponentEnum.FieldAnalysisFeaturePickerComponent);
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public handleMapReady() {
    setTimeout(() => {
      this.mapService.disableFeatureSelection();
      this.setupMapCoverFlowSubscriptions();
      this.isMapReady = true;
      this.initMapSubscriptions();
      this.initDeepLink();
    });
  }

  public onCloseClick() {
    this.sideDrawerRef.hide();
    this.fieldAnalysisMapSideDrawerService.drawerWidth = SideDrawerConfig.widthAsClosed;
  }

  /**
   * Init store subscriptions
   */
  private initMapSubscriptions(): void {
    this.setupSubscriptionLevelSubscriptions();

    this.setupSelectedMapCoverFlowSubscriptions();
  }

  private initDeepLink() {
    combineLatest([
      this.fieldAnalysisMapSelector.selectedMapCoverFlowItem$,
      this.queryParamService.getQueryParamNullable(fieldAnalysisMapLayerIdxQueryParamKey),
    ])
      .pipe(
        first(),
        filter(([selectedMapCoverFlowItem, idxQueryParamKey]) => idxQueryParamKey)
      )
      .subscribe(([selectedMapCoverFlowItem]) => {
        const mapLayerId = selectedMapCoverFlowItem.mapCoverFlowLayersId;
        if (mapLayerId) this.fieldAnalysisMapSideDrawerService.changeSideDrawerContent(mapLayerId);
      });
  }

  private setupSelectedMapCoverFlowSubscriptions() {
    this.subscriptions.push(
      this.fieldAnalysisMapSelector.selectedMapCoverFlowItem$.subscribe((mapCoverFlowItem) => {
        if (!mapCoverFlowItem) {
          this.mapCoverFlowItems.forEach((coverFlowItem) => (coverFlowItem.isVisible = false));
        } else {
          mapCoverFlowItem.isVisible = true;

          this.selectedMapFeature = mapCoverFlowItem.mapCoverFlowLayersId;

          this.hideOtherMapCoverFlowItems(mapCoverFlowItem);
        }
        this.map.updateMapCoverFlowLayersVisibility(this.mapCoverFlowItems);
      })
    );
  }

  private setupSubscriptionLevelSubscriptions() {
    this.subscriptions.push(
      this.fieldAnalysisMapSelector.subscriptionLevel$
        .pipe(filterNullish())
        .subscribe((level) => (this.canSeeSelectedField = level >= SubscriptionLevel.BASIC))
    );
  }

  private setupMapCoverFlowSubscriptions() {
    this.subscriptions.push(
      combineLatest([this.farmStateService.selectedFarms$, this.harvestYearStateService.harvestYear$.pipe(distinctUntilChanged())])
        .pipe(switchMap(() => this.fieldAnalysisMapService.getCoverFlowLayersItems()))
        .subscribe((coverFlowItems) => {
          this.updateMapCoverFlowItems(coverFlowItems);
        })
    );
  }

  private updateMapCoverFlowItems(coverFlowItems: MapCoverFlowItem[]) {
    this.mapCoverFlowItems = coverFlowItems;
    this.fieldAnalysisPickerService.fieldAnalysisMapFeatures = coverFlowItems;
    this.map.updateMapCoverFlowLayersVisibility(coverFlowItems);
    this.fieldAnalysisActionDispatchers.setMapCoverFlowItems(coverFlowItems);
  }

  private hideOtherMapCoverFlowItems(mapCoverFlowItem: MapCoverFlowItem) {
    this.mapCoverFlowItems
      .filter((coverFlowItem) => coverFlowItem !== mapCoverFlowItem && coverFlowItem.isVisible)
      .forEach((coverFlowItem) => (coverFlowItem.isVisible = false));
  }

  public onMapFeatureSelect(mapCoverFlowItem: MapCoverFlowItem): void {
    this.mapService.deselectFeatures();
    const selectedMapCoverFlowItemIdx = this.fieldAnalysisMapService.findMapCoverFlowIndex(mapCoverFlowItem, this.mapCoverFlowItems);

    this.fieldAnalysisActionDispatchers.selectedMapCoverFlowItemIdxChanged(selectedMapCoverFlowItemIdx);

    const mapLayerId = mapCoverFlowItem.mapCoverFlowLayersId;
    this.setFieldSelectionState(mapLayerId);
  }

  private setFieldSelectionState(mapLayerId?: MapLayerId) {
    switch (mapLayerId) {
      case MapLayerId.SOILSAMPLES:
        this.mapService.disableFeatureSelection();
        this.mapService.showLayer(MapLayerId.SOILSAMPLES);
        break;
      case MapLayerId.AS_APPLIED:
        this.mapService.enableFeatureSelection();
        this.mapService.showLayer(MapLayerId.AS_APPLIED);
        break;
      case MapLayerId.REDEDGENDVI:
        this.mapService.enableFeatureSelection();
        this.mapService.showLayer(MapLayerId.REDEDGENDVI);
        break;
      case MapLayerId.DRONE_IMAGE_IMPORT:
        this.mapService.enableFeatureSelection();
        this.mapService.showLayer(MapLayerId.DRONE_IMAGE_IMPORT);
        break;
      case MapLayerId.NONE:
        this.mapService.disableFeatureSelection();
        break;
      default:
        this.mapService.enableFeatureSelection();
        break;
    }
  }
}
