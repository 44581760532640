export enum CropModels {
  HayHarvestPrognosis = 'hayharvestprognosis',
  CornEyeSpot = 'corneyespot',
  CornHarvestPrognosis = 'cornharvestprognosis',
  WheatSeptoria = 'septoriaprognosis',
  NRequirement = 'NRequirement',
  WaterBalance = 'waterbalance',
  Grazingpotential = 'grazingpotential',
  Growthstage = 'growthstage',
  PotatoBlight = 'potatoblight',
}
