import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EndpointsService } from '@app/core/endpoints/endpoints.service';
import { FarmService } from '@app/core/farm/farm.service';
import { Farm } from '@app/core/interfaces/farm.interface';
import { LanguageService } from '@app/core/language/language.service';
import { NotificationService } from '@app/core/notification/notification.service';
import { SecureUrlService } from '@app/core/secure-url/secure-url.service';
import { IsNotIntegerValidator } from '@app/helpers/validators/forms/is-not-integer.validator';
import { NoWhitespaceValidator } from '@app/helpers/validators/forms/no-whitespace.validator';
import { DialogService } from '@app/shared/dialog/dialog.service';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-farm-picker-modal-edit-farm',
  templateUrl: './edit.component.html',
  styleUrls: ['edit.component.scss'],
})
export class FarmPickerModalEditFarmComponent implements OnInit {
  private farm!: Farm;
  public farmFormGroup!: UntypedFormGroup;
  public isLoading!: boolean;
  public loadingMessage!: string;
  public farmImageUrl!: SafeUrl;
  public imageDragOver = false;
  public isDefaultImage = true;
  public newFarmImageFile!: File | null;
  public JSON!: JSON;

  @ViewChild('fileInput', { static: false }) public fileInput?: ElementRef;

  private acceptedFileTypes = {
    'image/png': true,
    'image/jpeg': true,
    'image/gif': true,
  };

  constructor(
    private languageService: LanguageService,
    private farmStateService: FarmStateService,
    private farmService: FarmService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private secureUrlService: SecureUrlService,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private endpointService: EndpointsService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { farm: Farm },
    private matDialogRef: MatDialogRef<FarmPickerModalEditFarmComponent>
  ) {}

  public ngOnInit(): void {
    this.isLoading = false;
    this.farm = this.dialogData.farm;

    this.initFormGroup();

    this.loadExistingImage();
  }

  public initFormGroup(): void {
    this.farmFormGroup = this.formBuilder.group({
      name: [this.farm.name, [Validators.required]],
      address1: [this.farm.address1],
      address2: [this.farm.address2],
      address3: [this.farm.address3],
      postalCode: [this.farm.postalcode],
      postalDistrict: [this.farm.postalDistrict],
      telephoneNo: [this.farm.telephoneNo],
      cvr: [this.farm.cvr, [Validators.minLength(8), Validators.maxLength(8), NoWhitespaceValidator, IsNotIntegerValidator]],
    });
  }

  get invalid(): boolean {
    return this.farmFormGroup.invalid;
  }

  /**
   * Give translation for key
   */

  public getTranslation(key: string): string {
    return this.languageService.getText(key);
  }

  /**
   * Load existing image from API
   */
  public loadExistingImage(): void {
    if (this.farm.imageUrl?.length) {
      const maxWidth = 270;
      const maxHeight = 170;

      this.secureUrlService.get(this.getFarmImageUrl(this.farm.imageUrl, maxWidth, maxHeight)).subscribe(
        (url) => {
          this.farmImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);
          this.isDefaultImage = false;
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      );
    }
  }

  private getFarmImageUrl(farmImageUrl: string, maxWidth: number, maxHeight: number) {
    return `${this.endpointService.foApi}${farmImageUrl}/raw?maxWidth=${maxWidth}&maxHeight=${maxHeight}`;
  }

  /**
   * Function for handling Drop event when dragging image dropped in drop zone
   * @param event: from calling element
   */

  public onImageDrop($event: DragEvent): void {
    this.imageDragOver = false;

    $event.stopPropagation();
    $event.preventDefault();

    const files = $event.dataTransfer!.files;
    if (files && files.length > 0) {
      this.readFile(files[0]);
    }
  }

  /**
   * Function for handling DragOver event when dragging image is entering drop zone
   * @param event: from calling element
   */

  public onImageDragOver($event: DragEvent): void {
    this.imageDragOver = true;
    $event.stopPropagation();
    $event.preventDefault();
  }

  /**
   * Function for handling DragLeave event when dragging image is leaving drop zone
   * @param event: from calling element
   */

  public onImageDragLeave($event: DragEvent): void {
    this.imageDragOver = false;
  }

  /**
   * Will open file picker dialog
   */
  public showFilePicker(): void {
    this.fileInput!.nativeElement.click();
  }

  /**
   * Function for handling "Change" in File input
   * @param event: from calling element
   */

  public onFileChange($event: Event): void {
    const files = ($event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.readFile(files[0]);
    }
  }

  /**
   * Read list of image files user has selected from locale disk
   * @param files: list of files
   */
  public readFiles(files: any[]): void {
    files.forEach((file) => {
      this.readFile(file);
    });
  }

  /**
   * Read image file user has selected from locale disk
   * @param file: file to read
   */

  public readFile(file: File) {
    if (!this.acceptedFileTypes[file.type as 'image/png' | 'image/gif' | 'image/jpeg']) {
      this.notificationService.showError(`${this.languageService.getText('common.invalidFileType')}`);
      return;
    }
    if (file.size > environment.maxUploadFileSizeMB * 1000000) {
      this.notificationService.showError(`${this.languageService.getText('common.fileTooLarge')} ${environment.maxUploadFileSizeMB} MB`);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = (e) => {
      this.farmImageUrl = reader.result as SafeUrl;
      this.newFarmImageFile = file;
      this.isDefaultImage = false;
    };
    reader.readAsDataURL(file);
  }

  public onDeleteFarmImage($event: any) {
    this.isDefaultImage = true;
    this.newFarmImageFile = null;
  }

  /**
   * Function for handling "cancel" pressed in modal
   * @param  event from calling element
   */

  public onCancelClicked($event: any): void {
    this.matDialogRef.close();
  }

  /**
   * Function for handling "gem" pressed in modal
   * @param  event from calling element
   */

  public onConfirmClicked($event: any): void {
    if (!this.invalid) {
      this.updateFarm();
    }
  }

  /**
   * Updates the farm.
   */
  public updateFarm(): void {
    this.isLoading = true;

    const farmUpdate = Object.assign({}, this.farm);

    const values = this.farmFormGroup.value;

    farmUpdate.name = values.name;
    farmUpdate.address1 = values.address1;
    farmUpdate.address2 = values.address2;
    farmUpdate.address3 = values.address3;
    farmUpdate.postalcode = values.postalCode;
    farmUpdate.postalDistrict = values.postalDistrict;
    farmUpdate.telephoneNo = values.telephoneNo;
    farmUpdate.cvr = values.cvr;

    this.farmService.updateFarm(farmUpdate).subscribe(
      (farm: Farm | null) => {
        if (this.newFarmImageFile) {
          this.farmService.updateFarmImage(farm!.id, this.newFarmImageFile).subscribe(
            (imageUrl) => {
              farm!.imageUrl = imageUrl;
              this.farmStateService.updateFarm(farm!);
              this.matDialogRef.close();
            },
            (error: any) => this.onError(error)
          );
        } else if (this.farm.imageUrl && this.isDefaultImage) {
          // Image is deleted
          this.farmService.deleteFarmImage(this.farm.imageUrl).subscribe(
            () => {
              farm!.imageUrl = null;
              this.farmStateService.updateFarm(farm!);
              this.matDialogRef.close();
            },
            (error: any) => this.onError(error)
          );
        } else {
          this.farmStateService.updateFarm(farm!);
          this.matDialogRef.close();
        }
      },
      (error: any) => this.onError(error)
    );
  }

  private onError(error: any) {
    // We display the message like this because there is no errorcode from the API yet.
    // Subject to change in the future.
    this.notificationService.showError(`${this.languageService.getText('header.farmPicker.edit.updateFarmError')}`);

    this.isLoading = false;
  }

  public isReady(): boolean {
    return !this.isLoading;
  }

  public onDeleteFarmClicked($event: any): void {
    const data = {
      title: 'header.farmPicker.delete.title',
      text: 'header.farmPicker.delete.text',
    };

    this.dialogService.openConfirmDialog(data).onDialogConfirmed?.(() => {
      this.onDeleteFarmConfirmed();
    });
  }

  public onDeleteFarmConfirmed(): void {
    this.isLoading = true;
    this.loadingMessage = this.languageService.getText('header.farmPicker.edit.deletingFarm');
    this.farmService.deleteFarm(this.farm.id).subscribe(
      () => {
        this.farmStateService.deleteFarm(this.farm);
        this.farmStateService.resetFarms();
        this.notificationService.showSuccess(`${this.languageService.getText('header.farmPicker.edit.farmDeletedSucess')}`);
        this.loadingMessage = '';
        this.matDialogRef.close();
      },
      (error: any) => {
        this.notificationService.showError(`${this.languageService.getText('header.farmPicker.edit.updateFarmError')} (${error.status})`);
        this.isLoading = false;
        this.loadingMessage = '';
      }
    );
  }
}
